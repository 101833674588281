import React, { Fragment, useState, useEffect } from 'react'
import { Button, Table, Row } from 'antd'
import { getRequest } from '../../api/http';
import { apiUrl } from '../../api/api_url';
import { useHistory } from 'react-router-dom';
import { getIsPermisson, messageCustom } from '../../utils/permisstionUtil';

const VipLevelConfig = () => {
  const history = useHistory()
  const [list, setlist] = useState([]);

  useEffect(() => {
    initFansList();
  }, []);

  const initFansList = async () => {
    const res:any = await getRequest(apiUrl.fansLevelList);
    if(res && res.data) {
      setlist(res.data);
    }
  }

  const columns = [{
    title: '等级名称',
    dataIndex: 'name',
    key: 'name',
  },{
    title: '积分>',
    dataIndex: 'score',
    key: 'score',
  },{
    title: '创建时间',
    dataIndex: 'created_at',
    key: 'created_at',
  },{
    title: '操作',
    key: 'action',
    render: (rowdata: any) => {
      if(rowdata) {
        return <Button type="link" onClick={() => editLevel('edit', rowdata)}>编辑</Button>
      }
      return <Row></Row>
    }
  }];

  const editLevel = (type: string, rowdata?: any) => {
    if(!getIsPermisson('1_6_2_1')){
      messageCustom();
      return;
    }
    let data:any = { type }
    if(rowdata) {
      data = {...data, rowdata: rowdata}
    }
    history.push({
      pathname: '/sidebar/edit_vip_level',
      state: data
    })
  }

  return <Fragment>
    <div className='l_content'>
    <Button
      type="primary"
      style={{marginBottom: 30}}
      onClick={() => editLevel('add')}
    >添加等级</Button>
    <Table
      dataSource={list}
      size="middle"
      pagination={{
        total: list.length,
      }}
      rowKey={'id'}
      columns={columns}
    />;</div>
  </Fragment>
}

export default VipLevelConfig