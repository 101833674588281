import {observer} from "mobx-react";
import React from "react";
import { Table, Modal, message, } from "antd";
import { postRequest, getRequest } from '../../api/http';
import { apiUrl } from '../../api/api_url';
import { getIsPermisson, messageCustom } from "../../utils/permisstionUtil";

const key = 'updatable';

@observer
class PrinterList extends React.Component<any> {
    state = {
        productList: [],
    }

    columns = [
        {
            title: '打印机ID',
            dataIndex: 'key',
            key: 'key',
            className: 'list_center',
        },
        {
            title: '打印机名称',
            dataIndex: 'name',
            key: 'name',
            className: 'list_center',
            render: (text:any,record:any,) => (
                <div>{record.name !== '' ? record.name : '配置打印机信息后可使用'}</div>
            ),
        },
        {
            title: '打印机类型',
            dataIndex: 'type',
            key: 'type',
            className: 'list_center',
            render: (text:any,record:any,) => (
                <div>{record.type !== '' && record.type !== null ? record.type === 'all' ? '总单打印': '菜品打印' : '--'}</div>
            ),
        },
        {
            title: '关联菜品',
            dataIndex: 'dishes_num',
            key: 'dishes_num',
            className: 'list_center',
            render: (text:any,record:any,) => (
                <div>{record.dishes_num !== null ? record.dishes_num : '--'}</div>
            ),
        },
        {
            title: '打印机状态',
            dataIndex: 'online',
            key: 'online',
            className: 'list_center',
            render: (text:any,record:any,) => (
                <div>{record.online ? '在线' : '不在线'}</div>
            ),
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
            className: 'list_center',
            render: (text:any,record:any,) => (
                <div>{record.remark !== '' ? record.remark : '--'}</div>
            ),
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            className: 'list_center',
            render: (text:any,record:any,) => (
                <div className="btn_wrap">
                    <div className="list_btn" onClick={() =>{
                        if(!getIsPermisson('2_3_1_1')){
                            messageCustom();
                            return;
                          }
                        this.props.history.push({pathname:'/sidebar/printerEdit', state:{id: record.id}})
                    }}>配置</div>
                    <div className="list_btn" onClick={() => this.testPrinter(record)}>测试打印机</div>
                    {/* <div className="list_btn" onClick={() => this.deletePrinter(record)}>删除</div> */}
                </div>
            ),
        },
    ];

    componentDidMount(){
        this.onInitList();
    }

    // 初始化列表
    onInitList = async () => {
        const params:any = {
        };
        const res:any = await getRequest(apiUrl.printerList,params);
        if(res.code === 1){
            this.setState({
                productList: res.data.list,
            })
        }
    }

    // 测试打印机
    testPrinter = async (record:any) => {
        if(!getIsPermisson('2_3_1_2')){
            messageCustom();
            return;
          }
        const params = {
            id: record.id
        }
        const res:any = await postRequest(apiUrl.printerTest,params);
        if(res.code === 1){
            message.success({ content: '测试成功!', key, duration: 2 });
        }else{
            message.error({ content: '测试失败!', key, duration: 2 });
        }
    }

    // 删除打印机
    deletePrinter = (record: any) => {
        Modal.confirm({
            title: '删除该打印机',
            content: `确认删除打印机ID 「」？`,
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                const params = {
                    id: record.id
                }
                const res:any = await postRequest(apiUrl.printerDel,params);
                if(res.code === 1){
                    message.success({ content: '删除成功!', key, duration: 2 });
                }
                this.onInitList();
            },
            onCancel() {
            },
        });
    }

    searchPrinter = async () => {
        const res:any = await postRequest(apiUrl.printerSearch,{});
        if(res.code === 1){
            message.success({ content: '上报命令已经发送，请于30秒后再刷新页面，可看到更新的内容!', key, duration: 2 });
        }else{
            message.error(res.message);
        }
    }

    render(){
        return <div className='l_content'>
            {/* <Row className='mb40'>
                <Col span={24}>
                    <Button type="primary" size="large" onClick={this.searchPrinter}>
                        搜索打印机
                    </Button>
                </Col>
            </Row> */}

            <div>
                <Table dataSource={this.state.productList}  size="middle" columns={this.columns} rowKey={"id"} locale={{emptyText: '暂无数据'}} />
            </div>

        </div>;
    }
}
export default PrinterList