import axios from 'axios'
import {message} from 'antd';

let shop_id: any = localStorage.getItem('shop_id');

// if(!shop_id){
//   shop_id = ''
// }
let url = process.env.REACT_APP_BASEURL;

const handleUrl = () => {

    let url = process.env.REACT_APP_BASEURL;
    // let url = 'https://debug-siyu.shuachi.com';

    return url;
}

// console.log('url', url)
const http = axios.create({
    baseURL: process.env.REACT_APP_BASEURL,
    timeout: 60000,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json;charset=UTF-8',
        'shopid': shop_id
    },
});


http.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');

    if (config.url == '/v1/order-pay/wc-micro-pay') {
        config.timeout = 300000
    }
    let shopid = localStorage.getItem('shop_id');
    token && (config.headers.Authorization = `Bearer ` + token);
    config.headers.source = window.location.pathname
    config.headers.shopid = shopid;
    return config;
}, (error) => Promise.reject(error));


http.interceptors.response.use(
    response => {
        if (response.headers.authorization) {
            localStorage.setItem('token', response.headers.authorization.split(' ')[1])
        }
        return response
    },
    error => {
        console.log('请求状态码2：', error.response.data);
        if (error.response.status === 403) {
            message.error(`登录已过期，请重新登录！`);
            setTimeout(() => {
                window.location.href = '/login';
            }, 1000);
        }

        if (error.response.status === 422) {
            message.error(`${error.response.data['message']}`);
        }

        return Promise.reject(error)
    }
);

const getRequest = (url: string, params = {}) => {
    let shop_id = localStorage.getItem('shop_id')
    return new Promise((resolve, reject) => {
        var getUrl = handleUrl() + '/' + url;
        http.get(getUrl, {
            params: {shop_id, ...params},
        })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    })
}

const postRequest = (url: string, params: any = {}) => {
    var PostUrl = handleUrl() + '/' + url;
    let shop_id = localStorage.getItem('shop_id')
    return new Promise((resolve, reject) => {
        http.post(PostUrl, {shop_id, ...params})
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                console.log('请求错误', err);
                reject(err);
            })
    })
}

//  json 扩展 post,delete,put 
const requestWithJson = (method: any, url: any, params?: any, body?: any) => {
    let URL = handleUrl() + '/' + url;
    return new Promise((resolve, reject) => {
        // let shop_id = localStorage.getItem('shop_id')
        http({method: method, url: URL, params: {...params}, data: {...body}}).then(res => {
            resolve(res.data);
        }).catch(err => {
            console.log('请求错误', err);
            //             reject(err.data)
            reject(err);
        })
    })
}

const getRequestWithoutShopId = (url: string, params = {}) => {
    let URL = handleUrl() + '/' + url;
    return new Promise((resolve, reject) => {
        http.get(URL, {
            params: {...params},
        })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    })
}

const apiAxios = (url: string, params = {}, method = 'POST') => {
    let URL = handleUrl() + '/' + url;
    let httpDefault: any = {
        method: method,
        url: URL,
        params: method === 'GET' || method === 'DELETE' ? params : null,
        data: method === 'POST' || method === 'PUT' ? params : null,
        timeout: 10000,
        transformRequest: [function (data: any) {
            if (method == 'POST') {
                let ret = ''
                for (let it in data) {
                    ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
                }
                return ret
            }

        }],
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }
    // 注意**Promise**使用(Promise首字母大写)
    return new Promise((resolve, reject) => {
        axios(httpDefault)
        // 此处的.then属于axios
            .then((res) => {
                resolve(res)
            }).catch((response) => {
            reject(response)
        })
    })
}

export {postRequest, getRequest, http, apiAxios, requestWithJson, getRequestWithoutShopId}
