import React from 'react';
import InsideNav from '../../../component/insideNav/insideNav';
import OrderManagerOrderList from '../../order_manager/order_list/order_list';
import EquityCardOrder from '../../order_manager/equity_card_order/equity_card_order'
import './orderDetails.scss'
import CashCoupon from '../../coupon/cashCoupon';
import { collectChildNav, getIsPermisson } from '../../../utils/permisstionUtil';

type IProps = {
    history: any
}
type IState = {
    navList: any
    current_key: string
}

const navTable = [
    {
        title: '堂食订单',
        isActive: false,
        KEY: "ORDER_MANAGER_LIST",
        uni_key: '1_4_1'
    },
    {
        title: '代金劵订单',
        isActive: false,
        KEY: "CASHCOUPON",
        uni_key: '1_4_2'
    },
    {
        title: '权益卡订单',
        isActive: false,
        KEY: "EQUITY_CARD_ORDER",
        uni_key: '1_4_3'
    }
]

class OrderDetails extends React.Component<IProps,IState>{
    
    constructor(props:IProps) {
        super(props);
        this.state = {
            navList: [],
            current_key: "",
        }
    }

    componentDidMount(){
        this.initPageState();
    }

    initPageState = () =>{
        let navList = collectChildNav(navTable);
        let currentKey = localStorage.getItem('current_key') ?? navList[0].KEY;
        let isSetKey = false;
        navList.map((item:any) => {
            if(item.KEY == currentKey){
                item.isActive = true;
                isSetKey = true;
            }else{
                item.isActive = false;
            }
        })
        if(!isSetKey){
            currentKey = navList[0].KEY;
            navList[0].isActive = true;
        };
        this.setState({
            current_key: currentKey,
            navList: navList
        })
    }

    navCallBack(currentKey: string){
        localStorage.setItem('current_key',currentKey) 
        this.setState({
            current_key: currentKey
        })
    }

    render() {
        let { navList,current_key } = this.state;
        return <div>
            <InsideNav navList={navList} callBack={(key: string) =>this.navCallBack(key)}/>
            <div className="body">
                {
                    current_key === 'ORDER_MANAGER_LIST' ?  <OrderManagerOrderList />
                    :
                    current_key === 'CASHCOUPON' ?  <CashCoupon  location ={{}}/>
                    : current_key == 'EQUITY_CARD_ORDER' ? <EquityCardOrder location={{query: {day: ''}}} /> : <div />
                    
                }
            </div>
        </div>
    }

}

export default OrderDetails;