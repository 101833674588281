import React from "react"
import { getRequest } from '../../../../api/http'
import { apiUrl } from '../../../../api/api_url'
import { message, Table, Icon, Input, Upload, Checkbox, Select, Popover  } from 'antd'
import { inject, observer } from "mobx-react"
import { CompactPicker, SketchPicker } from 'react-color'

import styles from './decorationEditItem.module.scss'

import shopDecorationStore from '../../../../stores/shopDecoration.store'
import decoration_wode_dui from '../../../../assret/images/decoration_wode_dui.png'
import decoration_wode_x from '../../../../assret/images/decoration_wode_x.png'


const key_tip = 'updatable';
const QINIU_SERVER = 'https://up-z2.qiniup.com';
const key = 'updatable';
const baseUrl = 'https://ydn-siyu.cdn.shuachi.com/';

const { Option } = Select;


@observer
class DecorationEditItem extends React.Component<any, any> {

    state = {
        loading: false,
        imageUrl: '',
        updateObj: {
            key: '',
            tokens: '',
            region: '',
            'x:type': ''
        },
        color: '#000000',
        is_color: 0,
        fontSizeList: [
            {
                id: 0,
                size: 16,
                active: 1,
                name: '大(16号)'
            },
            {
                id: 1,
                size: 14,
                active: 0,
                name: '中(14号)'
            },
            {
                id: 2,
                size: 12,
                active: 0,
                name: '小(12号)'
            }
        ],
        icon_default: 1,
        path_name: null,
        linkShow: false,
    }

    componentDidMount() {
        shopDecorationStore.updateRefresh(0)
    }

    componentWillUnmount() {
        shopDecorationStore.updateObjProps('merber_menu','current_is_edit',0)
        shopDecorationStore.updateRefresh(1)
    }

    //显示和隐藏对应项
    onIsShow = (record: any, index: any) => {
        let temp_list = shopDecorationStore.shop_decoration_wode.merber_menu.module_list;
        temp_list[index]['show'] = record.show === '1' ? '0' : '1';
        this.setState({
            sortList: temp_list
        }, () => {
            shopDecorationStore.updateObjProps('merber_menu','module_list',temp_list)
        })
    }

    //删除对应的liet
    onDeleteItem = (dataObj: any, index: any) => {
        let temp_list = shopDecorationStore.shop_decoration_wode.merber_menu.module_list;
        temp_list.splice(index,1);
        this.setState({
            sortList: temp_list
        }, () => {
            shopDecorationStore.updateObjProps('merber_menu','module_list',temp_list)
        })
    }

    //关闭||展开
    onSwicth = (e: any,type: any) => {
        e.stopPropagation();
        switch (type) {
            case 'merber_info':
                let merber_info_param = {
                    ...shopDecorationStore.shop_decoration_wode.merber_info
                }
                merber_info_param.status = shopDecorationStore.shop_decoration_wode.merber_info.status === 0 ? 1 : 0
                shopDecorationStore.updateDecorationVal(type,merber_info_param)
                break;
            case 'merber_interests':
                let merber_interests_param = shopDecorationStore.shop_decoration_wode.merber_interests === 0 ? 1 : 0
                shopDecorationStore.updateDecorationVal(type,merber_interests_param)
                break;
            case 'merber_assets':
                let merber_assets_param = shopDecorationStore.shop_decoration_wode.merber_assets === 0 ? 1 : 0
                shopDecorationStore.updateDecorationVal(type,merber_assets_param)
                break;
            case 'merber_menu':
                let merber_menu_param: any = {
                    ...shopDecorationStore.shop_decoration_wode.merber_menu,
                }
                merber_menu_param.status = shopDecorationStore.shop_decoration_wode.merber_menu.status === 0 ? 1 : 0
                shopDecorationStore.updateDecorationVal(type,merber_menu_param)
                break;
            default:
                break;
        }
    }

    //修改对象指定属性
    updateObjProps = (type: any, props: any, val: any) => {
        shopDecorationStore.updateObjProps(type,props,val)
    }

    // 获取图片token
    beforeUpload = async (file:any) => {
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
            this.setState({
                loading: false,
            }, () => {
                message.error(file.name + "图片大小超出限制，请修改后重新上传");
                return;
            })
        } else {
            const res:any = await getRequest(apiUrl.getUploadToken, { type: 'food' });
            if(res && res.data) {
            const key = `food/${Math.random().toString(36).slice(-8)}${(new Date()).valueOf()}.png`
                this.setState({
                    updateObj: { token: res.data.upload_token, region: `https://up-z2.qiniu.com`, key: key, 'x:type': "food" }
                })
            }
        }
    }

    // 商品头图上传
    handleChange = (info:any) => {
        const isLt5M = info.file.size / 1024 / 1024 < 5;
        if(isLt5M) {
            if (info.file.status === 'uploading') {
                this.setState({ loading: true });
                return;
            }
            if (info.file.status === 'done') {
                this.setState({
                    imageUrl: info.file.response.url,
                    imageObj: info.file.response,
                    loading: false,
                }, () => {
                    this.modifyCurrentItem('icon', this.state.imageUrl)
                })
            }
        }
    }

    //上传导航
    onAddNavigation = () => {
        let temp_list: any = shopDecorationStore.shop_decoration_wode.merber_menu.module_list;
        temp_list.push(
            {
                background: "#ffffff",
                font_color: "#cccccc",
                font_size: 12,
                icon: "",
                name: "新导航",
                order: 0,
                path: null,
                shop_id: 1,
                show: "1",
                status: 0,
                index: temp_list.length,
                weigh: 0
            }
        )
        shopDecorationStore.updateObjProps('merber_menu','module_list',temp_list)
    }

    //编辑标题
    onChangeInput = (e: any) => {
        this.modifyCurrentItem('name', e.target.value)
    }

    //修改当前item的属性
    modifyCurrentItem = (type: any, val: any) => {
        let { merber_menu } = shopDecorationStore.shop_decoration_wode;
        let temp_list: any = merber_menu.module_list;
        temp_list[merber_menu.current_index][type] = val;
        shopDecorationStore.updateObjProps('merber_menu','module_list',temp_list)
    }

    //颜色画板
    handleColorChange = (colorCode: any) => {    
        this.setState({        
            color: colorCode.hex    
        }, () => {
            this.modifyCurrentItem('font_color', this.state.color)
        })
    }

    //字体大小
    handleSizeChange = (idx: any, size: any) => {
        let list = this.state.fontSizeList;
        list.map((item: any, index: any) => {
            item['active'] = 0;
        })
        list[idx]['active'] = 1;
        this.setState({
            fontSizeList: list
        }, () => {
            this.modifyCurrentItem('font_size', size)
        })
    }

    //标题默认样式
    onTitleDefault = (type: any,val: any) => {
        if(type === 'font_default_status') {
            if(val === 1) {
                const merber_menu: any = shopDecorationStore.shop_decoration_wode.merber_menu;
                this.modifyCurrentItem('font_size', merber_menu.module_list[merber_menu.current_index]['default_font_size'])
                this.modifyCurrentItem('font_color', merber_menu.module_list[merber_menu.current_index]['default_font_color'])
                this.modifyCurrentItem('weigh', merber_menu.module_list[merber_menu.current_index]['default_weigh'])
            }
        }

        if(type === 'icon_default_status') {
            if(val === 1) {
                const merber_menu: any = shopDecorationStore.shop_decoration_wode.merber_menu;
                this.modifyCurrentItem('icon_default', merber_menu.module_list[merber_menu.current_index]['icon_default'])
            }
        }

        this.modifyCurrentItem(type, val)
        
    }

    //链接选择
    typeChange = (e: any, name: any) => {
        this.setState({
            linkShow: false
        }, () => {
            this.modifyCurrentItem('path', e)
            shopDecorationStore.updateObjProps('merber_menu','current_name',name)
        })
    }

    popoverContent = () => {

    }

    render() {

        const { merber_info, href_path } = shopDecorationStore.shop_decoration_wode;

        const merber_menu: any = shopDecorationStore.shop_decoration_wode.merber_menu;

        const { imageUrl, linkShow, color, is_color, fontSizeList, icon_default, path_name } = this.state;

        return (
            <div className={styles.right}>
                <div className={styles.right_main}>
                    <div className={styles.right_main_tip}>导航项</div>
                    <div className={styles.right_main_list}>
                        <div className={styles.right_main_list_nickname}>
                            <div className={styles.right_main_list_nickname_left}>标题：</div>
                            <div className={styles.right_main_list_nickname_right}>
                                <Input value={merber_menu.module_list[merber_menu.current_index]['name']} className={styles.right_main_list_nickname_right_input} onChange={this.onChangeInput} />
                            </div>
                        </div>
                        <div className={styles.right_main_list_option}>
                            <div className={styles.right_main_list_option_item} style={{marginBottom: merber_menu.module_list[merber_menu.current_index]['font_default_status'] === 1 ? '0' : '20px'}}>
                                <div className={styles.right_main_list_option_item_name}>
                                    标题样式：
                                </div>
                                <div className={`${styles.right_main_list_option_item_info} ${styles.right_cursor}`} onClick={() => {this.onTitleDefault('font_default_status',1)}}>
                                    <div className={`${merber_menu.module_list[merber_menu.current_index]['font_default_status'] === 1 ? styles.right_radio : styles.right_radioHide} ${styles.right_cursor}`}></div>
                                    <div className={styles.right_radio_txt}>默认</div>
                                </div>
                                <div className={`${styles.right_main_list_option_item_info} ${styles.right_cursor}`} onClick={() => {this.onTitleDefault('font_default_status',0)}}>
                                    <div className={`${merber_menu.module_list[merber_menu.current_index]['font_default_status'] === 0 ? styles.right_radio : styles.right_radioHide} ${styles.right_cursor}`}></div>
                                    <div className={styles.right_radio_txt}>自定义</div>
                                </div>
                            </div>
                            {
                                merber_menu.module_list[merber_menu.current_index]['font_default_status'] === 0 && (
                                    <div>
                                        <div className={styles.right_main_list_option_item}>
                                            <div className={styles.right_main_list_option_item_name}>
                                                大小：
                                            </div>
                                            {
                                                fontSizeList.map((item: any, index: any) => {
                                                    return (
                                                        <div key={item.id + index}>
                                                            <div className={`${styles.right_main_list_option_item_size} ${item.active === 1 ? `${styles.right_active}` : ``} ${styles.right_cursor}`} onClick={() => {this.handleSizeChange(index, item.size)}}>
                                                                {item.name}
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className={styles.right_main_list_option_item}>
                                            <div className={styles.right_main_list_option_item_name}>
                                                颜色：
                                            </div>
                                            {
                                                is_color === 1 && (
                                                    <div style={{width: '22px', zIndex: 99, height: '22px',position: 'absolute', left: '311px', bottom: '-280px',cursor: 'pointer',color: '#5D6BFF'}} onClick={() => {this.setState({is_color: 0})}}>OK</div>
                                                )
                                            }
                                            {
                                                is_color === 1 && (
                                                    <div className={`${styles.right_main_list_option_item_colorPicker}`}>
                                                        <SketchPicker color={this.state.color} onChange={this.handleColorChange} ></SketchPicker>
                                                    </div>
                                                    
                                                )
                                            }
                                            <div className={`${styles.right_main_list_option_item_color} ${styles.right_cursor}`} style={{backgroundColor: color}} onClick={() => {this.setState({is_color: 1})}}></div>
                                        </div>
                                        <div className={styles.right_main_list_option_item}>
                                            <div className={styles.right_main_list_option_item_name}>
                                                加粗：
                                            </div>
                                            <div className={`${styles.right_main_list_option_item_info} ${styles.right_cursor}`} onClick={() => {this.modifyCurrentItem('weigh', merber_menu.module_list[merber_menu.current_index]['weigh'] === 0 ? 1 : 0)}}>
                                                {
                                                    merber_menu.module_list[merber_menu.current_index]['weigh'] === 0
                                                    ?   <img src={decoration_wode_x} className={`${styles.right_main_list_option_item_checkbox} ${styles.right_cursor}`} />
                                                    :   <img src={decoration_wode_dui} className={`${styles.right_main_list_option_item_checkbox} ${styles.right_cursor}`} />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            
                        </div>
                        <div className={styles.right_main_list_option}>
                            <div className={styles.right_main_list_option_item} style={{marginBottom: merber_menu.module_list[merber_menu.current_index]['icon_default_status'] === 1 ? '0' : '20px'}} >
                                    <div className={styles.right_main_list_option_item_name}>
                                        图标附图：
                                    </div>
                                    <div className={`${styles.right_main_list_option_item_info} ${styles.right_cursor}`} onClick={() => {this.onTitleDefault('icon_default_status',1)}}>
                                        <div className={`${merber_menu.module_list[merber_menu.current_index]['icon_default_status'] === 1 ? styles.right_radio : styles.right_radioHide} ${styles.right_cursor}`}></div>
                                        <div className={styles.right_radio_txt}>默认</div>
                                    </div>
                                    <div className={`${styles.right_main_list_option_item_info} ${styles.right_cursor}`} onClick={() => {this.onTitleDefault('icon_default_status',0)}}>
                                        <div className={`${merber_menu.module_list[merber_menu.current_index]['icon_default_status'] === 0 ? styles.right_radio : styles.right_radioHide} ${styles.right_cursor}`}></div>
                                        <div className={styles.right_radio_txt}>自定义</div>
                                    </div>
                                </div>
                                {
                                    merber_menu.module_list[merber_menu.current_index]['icon_default_status'] === 0 && (
                                        <div className={styles.right_main_list_option_item}>
                                            <Upload
                                                listType="picture-card"
                                                className="avatar-uploader"
                                                showUploadList={false}
                                                action={QINIU_SERVER}
                                                beforeUpload={this.beforeUpload}
                                                onChange={this.handleChange}
                                                data={this.state.updateObj}
                                                >
                                                {
                                                    imageUrl ?
                                                    <img src={imageUrl} alt="商品图片" style={{ width: '100%' }} />
                                                    : <div className="">
                                                        <Icon type={this.state.loading ? 'loading' : 'plus'} style={{fontSize: '40px'}}/>
                                                    </div>
                                                }
                                            </Upload>
                                        </div>
                                    )
                                }
                        </div>
                        <div className={styles.right_main_list_option}>
                            <div className={styles.right_main_list_href}>
                                <div className={styles.right_main_list_href_left}>
                                    链接：
                                </div>
                                <Popover visible={linkShow} title="链接" trigger="click" content={(
                                    <div>
                                        {
                                            href_path &&　href_path.length > 0 && href_path.map((item,index)=>{
                                                return <div key={index} className={styles.right_main_list_href_text} onClick={()=>{this.typeChange(item.path,item.name)}}>{item.name}</div>
                                            })
                                        }
                                    </div>
                                )}>
                                    <div className={styles.right_main_list_href_tipName} onClick={()=>{this.setState({linkShow: !linkShow})}}>
                                        {merber_menu.current_name ? merber_menu.current_name : '链接到的页面地址'}<Icon type="up" className="ml10" style={{color:'#5D6BFF'}}/>
                                    </div>
                                </Popover>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default DecorationEditItem