import React from 'react'
import { Input, Switch, Radio, Select,Checkbox,Button,message,Tabs,Row, Col} from 'antd';


import addCommentOption from "../../../assret/images/btn_add.png";
import addCoupon from "../../../assret/images/set_add.png";
import subCoupon from "../../../assret/images/set_reduce_disabled.png";
import editBtn from "../../../assret/images/t_edit.png";
import deleteBtn from "../../../assret/images/t_delete.png";
import {getRequest, postRequest} from "../../../api/http";
import {apiUrl} from "../../../api/api_url";
import InsideNav from '../../../component/insideNav/insideNav';

import style from "./commentcfg.module.scss"
// import style from "./commentcfg.module.scss"
import { Interface } from 'readline';
import { stringify } from 'querystring';
import Goods from '../../goods/goods';


const { Option } = Select;
const { TabPane } = Tabs;
const CheckboxGroup = Checkbox.Group;

export default class CommentCfg extends React.Component<any>{
    state = {
        // 店铺是否开启评价
        status:false,
        // 店铺是否开启评价有礼
        isComment:false,
        // isBoth:true,
        commentDefaultOption:[],
        commentOption:[],
        disabledInput:true,
        title:"",
        content:"",
        editTitle:"",
        editContent:"",
        starId :null,
        couponId :null,
        giftCouponId :null,
        coupons:[],
        gift:{
            "is_gift": false,
            "coupon_id": 0
        },
        star:{
            "is_star": false,
            "coupons": {
            }
        },
        navList: [],
        current_key: "",
        indeterminate: [],
        goodInfo:{},
        value:0,
        couponTmp:null,
        starCouponLen:0, // 按评价星级赠送项目数量
    }

    async componentDidMount(){
        // 请求优惠券数据
        const couponData:any =  await this.requestCouponDataHandler()
        let couponTmp:any = {}
        let coupons:any = []  // 优惠券信息
        let indeterminate:any = []  // 优惠券信息
        
        for(let item in couponData){
            let tmp:any ={}
            tmp.id = couponData[item].id
            tmp.name = couponData[item].name
            tmp.action = false
            coupons.push(tmp)
            couponTmp[tmp.id] = tmp.name
        }
        let obj:any = await this.requestCfgDataHandler()
        // if(obj.star.coupons)
        let comment_option:any = obj.comment_option
        for(let item in comment_option){
            comment_option[item].action = true
        }
        let navListTmp:any = []
        // 存放商品id和名称的映射
        let goodsInfo:any = this.state.goodInfo
        for(let index in obj.goods){
            let nav:any = {}

            nav.title = obj.goods[index].cat_name
            nav.KEY = obj.goods[index].cat_id
            nav.isActive = false
            // 已经选择
            let itemSel:any = []
            let goods:any = []
            for (let i in obj.goods[index].goods) {
                let itemGoods:any = obj.goods[index].goods[i]
                goodsInfo[itemGoods.goods_name] = itemGoods.goods_id
                if (itemGoods.allow){
                    itemSel.push(itemGoods.goods_name)
                    indeterminate[index] = true
                }
                goods.push(itemGoods.goods_name)
            }
            nav.itemSel = itemSel
            nav.goods = goods
            if(index === "0"){
                nav.isActive = true
                this.setState({
                    current_key:obj.goods[index].cat_id
                })
            }
            navListTmp.push(nav)
            // navList.checkAll = false
        }
        let value = 0
        if(obj.star.is_star){
            value = 2
        }
        if(obj.gift.is_gift){
            value = 1
        }
        let starCouponLen:any = 0
        if(obj.star.coupons !== null){
            starCouponLen = Object.keys(obj.star.coupons).length
        }else{
            obj.star.coupons = {}
        }
        let starId:any = "请选择星级"
        let couponId:any = "关联一张优惠券"
        let giftCouponId:any = "关联一张优惠券"
        if(obj.gift.coupon_id  != 0){
            giftCouponId = obj.gift.coupon_id
        }
        this.setState({
            navList:navListTmp,
            commentDefaultOption:obj.default_comment_option,
            commentOption:comment_option,
            coupons:coupons,
            status:obj.status,
            isComment:obj.is_comment,
            // isBoth:obj.is_both,
            star:obj.star,
            gift:obj.gift,
            value,
            couponTmp,
            starCouponLen,
            starId,
            couponId,
            giftCouponId,
            indeterminate
        })

    }

    // 请求配置信息
    requestCfgDataHandler = async ()=>{
        const res:any = await getRequest(apiUrl.getCfgComment)
        // const res:any = await getRequest("http://api.com/v1/comment/cfgcomment")
        if(res.code === 1){
           return res.data
        }else{
            // console.log(res.message)
            message.error("服务器请求数据失败")
            return 
        }
    }

     // 请求优惠券数据
     requestCouponDataHandler = async ()=>{
        const res:any = await getRequest(apiUrl.couponList,{status:2,position:3})
        // const res:any = await getRequest("http://api.com//v1/coupon/list?shop_id=1&current=1&pageSize=10&status=2&postion=3")
        if(res.code === 1){
            // console.log(res.data.list)
           return res.data.list
        }else{
            // console.log(res.message)
            return null
        }
    }

    postDataHandler = async(data:any)=>{
        const res:any = await postRequest(apiUrl.getCfgComment,data)
        // const res:any = await postRequest("http://api.com/v1/comment/cfgcomment",data)
        if(res.code === 1){
            message.success("配置成功");
           }else{
               message.error(res.message);
           }
    }

    inputOptionOnChange = (e:any)=>{
        this.setState({
            title:e.target.value
        })
    }
    inputContentOnChange = (e:any)=>{
        this.setState({
            content:e.target.value
        })
    }
    // 添加自定义评价维度
    addOption=()=>{
        let item:any= {}
        item.title = this.state.title
        if (item.title ==""){
            message.error(`维度不能为空`);
            return 
        }
        item.content = this.state.content
        item.action = true
        let itemTemp:any = this.state.commentOption
        itemTemp.push(item)
        this.setState({
            commentOption:itemTemp
        })
        this.state.title = ""
        this.state.content = ""
    }

     // 编辑维度
     editOption=(index:any)=>{
        let itemTemp:any = this.state.commentOption
        itemTemp[index].action = false
        this.setState({
            commentOption:itemTemp
        })
    }

    editOptionOnChange = (e:any,index:any)=>{
        let tmp:any = this.state.commentOption
        if (e.target.value ==""){
            message.error(`维度不能为空`);
            return 
        }
        tmp[index].title = e.target.value
        this.setState({
            commentOption:tmp
        })
    }
    editContentOnChange = (e:any, index:any)=>{
        let tmp:any = this.state.commentOption
        tmp[index].content = e.target.value
        this.setState({
            commentOption:tmp
        })
    }

    // 删除维度
    deleteOption=(index:any)=>{
        let tmp:any = this.state.commentOption
        tmp.splice(index,1)
        this.setState({
            commentOption:tmp
        })
    }

    // 是否评价有礼
    openShopStatusOnChange = (val:boolean)=>{
       this.setState({
            status:val
         })
    }

    // 是否评价有礼
    openCommentOnChange = (val:boolean)=>{
        this.setState({
            isComment:val
         })
     }

    // 是否评价即赠送
    commentOnChange = (e:any) =>{
        let gift:any = this.state.gift
        gift.is_gift = e.target.checked
        this.setState({gift})
    }

    // 评价即赠送优惠券
    commentHandleChange = (val:any)=>{
        let gift:any = this.state.gift
        gift.coupon_id = val
        this.setState({gift})
     }

    // 是否按评价星级赠送
    starOnChange = (e:any)=>{
        let star:any = this.state.star
        star.is_star = e.target.checked
        this.setState({star})
    }

     // 星级评价选择星级
     starHandleChange = (val:any)=>{
       this.setState({
           starId:val
       })
     }

    //  星级评价选择优惠券
    starCouponhandleChange = (val:any)=>{
        // console.log("-------couponId------",val)
        this.setState({
            couponId:val
        })
     }

     // 是否评价有礼和星级同时存在
    // bothOnChange = (e:any)=>{
    //     console.log(e)
    //     this.setState({
    //         isBoth:e.target.value
    //     })
    // }
    
    // 星级添加优惠券
    addCoupon=()=>{
        if(this.state.starId =="请选择星级" || this.state.couponId =="关联一张优惠券"){
            message.error(`星级和优惠券不能为空`);
            return
        }
        let star:any = this.state.star
        let coupons:any = this.state.star.coupons
        let couponId:any = this.state.couponId
        let starId :any = this.state.starId
        coupons[starId] = couponId

        let starCouponLen:any = Object.keys(star.coupons).length
        starId = "请选择星级"
        couponId = "关联一张优惠券"
        this.setState({star,starCouponLen,starId,couponId})

    }
    starEditCouponhandleChange=(val:any, key:any)=>{
       let star:any = this.state.star
       star.coupons[key] = val
       this.setState({star})
    }
    // 星级减少优惠券
    subCoupon=(key:any)=>{
        
        let star:any = this.state.star
        delete star.coupons[key]
        let starCouponLen:any = Object.keys(star.coupons).length

        let couponId:any = this.state.couponId
        let starId :any = this.state.starId
        starId = "请选择星级"
        couponId = "关联一张优惠券"
        this.setState({star,starCouponLen,starId,couponId})
    }

    submit = (e:any)=>{
        let starCouponLen:any = Object.keys(this.state.star.coupons).length
        // console.log("=================",starCouponLen)
        // 获取选择数据
        let navList:any = this.state.navList
        let goodsInfo:any = this.state.goodInfo
        let goods:any = {}
        for (let index in navList){
            let items = navList[index].itemSel
            for(let i in items){
                let goodsId = goodsInfo[items[i]]+""
                goods[goodsId] = true
            }
        }
        // console.log(this.state.couponId)
        // console.log(this.state.starId)
        if (this.state.couponId !="关联一张优惠券" && this.state.starId !=="请选择星级"){
            let starId:string = this.state.starId + ''
            let couponId:any = this.state.couponId
            let star :any = this.state.star
            star.coupons[starId] = couponId
            this.setState({
                star
            })
        }
        let obj:any = {}
        obj.default_comment_option = this.state.commentDefaultOption
        obj.comment_option = this.state.commentOption
        obj.star = this.state.star
        obj.gift = this.state.gift
        obj.status = this.state.status
        obj.is_comment = this.state.isComment
        // obj.is_both = this.state.isBoth
        obj.goods = goods
        this.postDataHandler(obj)
    }

    navCallBack = (currentKey:any)=>{
        this.setState({
            current_key: currentKey
        })
    }

    onSelChange = (val:any, index:any) => {
        let navList:any = this.state.navList
        let indeterminate:any = this.state.indeterminate
        indeterminate[index] = !!val.length && val.length < navList[index].goods.length
        navList[index].itemSel = val
        navList[index].checkAll = val.length === navList[index].goods.length
        this.setState({
            navList,
            indeterminate,
        });
    }
    
    onCheckAllChange = (e:any, index:any) => {
        let navList:any = this.state.navList
        if(e.target.checked){
            navList[index].itemSel = navList[index].goods
            navList[index].checkAll = true
        }else{
            navList[index].itemSel = []
            navList[index].checkAll = false
        }
        let indeterminate:any = this.state.indeterminate
        indeterminate[index] = false
        this.setState({
            navList,
            indeterminate,
          });
    }

    onChange = (e:any)=>{
        // console.log(e)
        let value = e.target.value
        if(value ===1){
            let gift = this.state.gift
            gift.is_gift = true
            let star = this.state.star
            star.is_star = false
            this.setState({
                gift,
                star
            })
        }else if (value ===2 ){
            let gift = this.state.gift
            gift.is_gift = false
            let star = this.state.star
            star.is_star = true
            this.setState({
                gift,
                star
            })
        }
        this.setState({
            value:e.target.value
        })
    }

    downloadQrcode = () => {
        return window.location.href = process.env.REACT_APP_BASEURL + '/v1/region-table/download-comment-xcx-code-all?token='+localStorage.getItem("token")+'&shop_id='+localStorage.getItem("shop_id");
    }

    render(){
        let coupons:any = this.state.star.coupons
        let navList:any = this.state.navList;
        let current_key:any = this.state.current_key;
        let couponTmp:any = this.state.couponTmp
        
        return <div className={style.comment_list}>
            <div className = {style.l_content}>
                <div className={style.comment_content}>
                    <div className= {style.option_title}>
                        评价维度
                    </div>
                    <div className={style.switch}>
                            <span className={style.switch_title}>评价开关  </span><Switch defaultChecked checked={this.state.status} onChange={(val:boolean)=>this.openShopStatusOnChange(val)} />
                        <Button className={style.download} onClick={this.downloadQrcode} size={'small'}>下载评价物料</Button>
                    </div>
                </div>
                {
                    this.state.commentDefaultOption.map((item :any, index:any)=>{
                        // console.log(item)
                       return <div key={index}>
                            <div className={style.comment_option_left}>
                                {item.title}
                            </div>
                            <div className={style.comment_option_right}>
                            {item.content}
                            </div>
                        </div>
                    })
                }
                {
                    this.state.commentOption.map((item:any, index:any)=>{
                        return <div key={index}>
                                    <div className={style.comment_option_left}>
                                        <Input disabled={item.action} value={item.title} onChange={(e)=>this.editOptionOnChange(e,index)}/>
                                    </div>
                                    <div className={style.comment_option_right}>
                                        <Input disabled={item.action} value={item.content} onChange={(e)=>this.editContentOnChange(e,index)}/>
                                    </div>
                                    <div className={style.edit_btn} onClick={()=>this.editOption(index)}>
                                        <img src={editBtn} />
                                    </div>
                                    <div className={style.delete_btn} onClick={()=>this.deleteOption(index)}>
                                        <img src={deleteBtn} />
                                    </div>
                                </div>
                    })
                }
                
                <div>
                    <div className={style.comment_option_left}>
                        <Input placeholder="维度" value={this.state.title} onChange={(e)=>this.inputOptionOnChange(e)}/>
                    </div>
                    <div className={style.comment_option_right}>
                        <Input placeholder="描述" value={this.state.content} onChange={(e)=>this.inputContentOnChange(e)} />
                    </div>
                    <div className={style.add_btn} onClick={this.addOption}>
                        <img src={addCoupon} />
                    </div>
                </div>
            </div>
            <div className={style.l_content_nav}>
                <InsideNav navList={navList} callBack={(key: string) =>this.navCallBack(key)} />
                <div style={{ borderBottom: '1px solid #EBEBEB' }}></div>
                <div className="body" >
                    {
                        this.state.navList.map((item:any, index:any)=>{

                            return current_key === item.KEY  ?  
                                
                                <div key= {index} >
                                    <div  >
                                        <Checkbox
                                            indeterminate={this.state.indeterminate[index]}
                                            onChange={(e:any)=>{this.onCheckAllChange(e,index)}}
                                            checked={navList[index].checkAll}
                                        >
                                        全选
                                        </Checkbox>
                                        </div>
                                        <br />
                                        <CheckboxGroup  
                                        options={item.goods}
                                        className={style.checkbox_item}
                                        value={item.itemSel}
                                        onChange={(e:any)=>{this.onSelChange(e,index)}}
                                        />
                                </div>: null
                        })
                    }
                </div>
            </div>
            <div className = {`${style.l_content} ${style.comment}`}>
            
             <div className= {style.option_title}>
                 <span>评价有礼</span>
                 <div className={style.switch}>
                    <span className={style.switch_title}>评价有礼开关</span><Switch defaultChecked checked={this.state.isComment} onChange={(val:boolean)=>this.openCommentOnChange(val)} />
                 </div>
             </div>
             <div className={style.comment}>
             <Radio.Group onChange={this.onChange} value={this.state.value}>
                    <Radio value={1}>评价即赠送</Radio>
                    {
                        this.state.couponTmp?<div className={style.comment}>
                        <Select defaultValue={this.state.giftCouponId} style={{ width: 283 }} onChange={(val:any)=>this.commentHandleChange(val)}>
                            {
                                this.state.coupons.map((item:any,index:any)=>{
                                    return <Option key={index} value={item.id}>{item.name}</Option>
                                })
                            }
                        </Select>
                    </div>:null
                    }
                    <div className={style.comment}>
                        <Radio value={2}>按评价星级赠送</Radio>
                    </div>
                    {
                this.state.starCouponLen?Object.getOwnPropertyNames(coupons).map((key:any,index:any) =>{
                    return <div key={index} className={style.comment}>
                        <Select defaultValue={key} disabled={true} style={{ width: 120 }} value={key}>
                            <Option value="5">5</Option>
                            <Option value="4">4</Option>
                            <Option value="3">3</Option>
                            <Option value="2">2</Option>
                            <Option value="1">1</Option>
                        </Select>
                        <span>  星评价赠送奖品  </span>
                        <Select defaultValue={coupons[key]} style={{ width: 283 }} value={coupons[key]}  onChange={(val:any)=>this.starEditCouponhandleChange(val,key)}>
                            {
                                this.state.coupons.map((item:any,index:any)=>{
                                    return <Option key={index} value={item.id}>{item.name}</Option>
                                })
                            }
                        </Select>
                        
                        <div className={style.star_comment_coupon}>
                            <div className={style.add_btn}  onClick={()=>this.subCoupon(key)}>
                                <img src={subCoupon} />
                            </div>
                        </div>
                    </div>
                }):null
            }
            {
                
                this.state.starCouponLen > 4?null:<div className={style.comment}>
                <Select defaultValue={this.state.starId} style={{ width: 120 }} onChange={(val:any)=>this.starHandleChange(val)} value={this.state.starId}>
                    <Option value="5">5</Option>
                    <Option value="4">4</Option>
                    <Option value="3">3</Option>
                    <Option value="2">2</Option>
                    <Option value="1">1</Option>
                </Select>
                <span>  星评价赠送奖品  </span>
                <Select defaultValue={this.state.couponId} style={{ width: 283 }} onChange={(val:any)=>this.starCouponhandleChange(val)} value={this.state.couponId}>
                    {
                        this.state.coupons.map((item:any,index:any)=>{
                            return <Option key={index} value={item.id}>{item.name}</Option>
                        })
                    }
                </Select>
                    <div className={style.star_comment_coupon}>
                        <div className={style.add_btn} onClick={this.addCoupon}>
                            <img src={addCoupon} />
                        </div>
                    </div>
            </div>
            }
            
                </Radio.Group>
             </div>
            
            <div className={style.comment}>
                {/* <Checkbox checked={this.state.star.is_star} onChange={(e)=>this.starOnChange(e)}>按评价星级赠送</Checkbox> */}
            </div>
            
            {/* <div className={style.comment}>
                <span>是否和消费有礼同时存在  </span>
                <Radio.Group onChange={(e)=>this.bothOnChange(e)} value={this.state.isBoth}>
                    <Radio value={true}>是</Radio>
                    <Radio value={false}>否</Radio>
                </Radio.Group>
            </div> */}
             </div>
            <div className={style.comment_cfg_submit}>
                <Button type="primary" onClick={(e)=>this.submit(e)}>确定</Button>
            </div>
        </div>
    }
}




