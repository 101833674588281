import { apiUrl } from './../api/api_url';
import { getRequest } from "../api/http"

// 搜索用户列表
const searchUserList = async(name: string) => {
  const res:any = await getRequest(apiUrl.searchUsers, { nickname: name });
  if(res && res.data) {
    const list: Array<string> = [];
    res.data.map((item: any) => list.push(item.nickname));
    return list;
  }
  return [];
}

//只能输入数字
const onlyNumbers = (str: any) => {
  return str.replace(/\D/g,'');
}

//只能输入汉字
const onlyStrings = (str: any) => {
  let reg = /[\d|...|……|\n|\r\n|\u3002|\uff1f|\uff01|\uff0c|\u3001|\uff1b|\uff1a|\u201c|\u201d|\u2018|\u2019|\uff08|\uff09|\u300a|\u300b|\u3008|\u3009|\u3010|\u3011|\u300e|\u300f|\u300c|\u300d|\ufe43|\ufe44|\u3014|\u3015|\u2026|\u2014|\uff5e|\ufe4f|\uffe5]/;
  let result = str.replace(new RegExp(reg, 'g'), '');
  return result;
}


export { 
  searchUserList,
  onlyNumbers,
  onlyStrings
}