import "fabric";
import dufaultAvatarUrl from '../../../assret/images/dufault_avatar_bg.png'
declare let fabric: any;

const getAvatarCircle = (radius:number) => {
  const avatarCircle = new fabric.Rect({
    rx: radius,
    ry: radius,
    width: 60,
    height: 60,
    // fill: "#fff",
    fill: "red",
    top: 0,
    left: 0
  });
  return avatarCircle;
}

export const nickNameText = new fabric.Textbox("用户昵称", {
  left: 120,
  top: 70,
  width: 100,
  height: 100,
  lineHeight: 80,
  fontSize: 16, // 字体大小
  fontWeight: 400, // 字体粗细
  textAlign: "left",
  fill: "#444", // 字体颜色
  hasControls: false,
  borderColor: "orange",
  selectable: true,
  editable: false,
  backgroundColor: "rgba(255,255,255,.7)",
  selectionBackgroundColor: "rgba(255,255,255,.7)",
  selectionColor: "red",
  editingBorderColor: "blue" // 点击文字进入编辑状态时的边框颜色
});

const qrBox = new fabric.Rect({
  left: 0,
  top: 0,
  width: 70,
  height: 70,
  fill: "#fff"
});

const qrText = new fabric.Textbox("二维码", {
  left: 0,
  top: 27,
  width: 70,
  height: 80,
  lineHeight: 80,
  fontSize: 16, // 字体大小
  fontWeight: 400, // 字体粗细
  textAlign: "center",
  fill: "#444", // 字体颜色
  hasControls: false,
  borderColor: "orange",
  selectable: true,
  editable: false,
  selectionColor: "red",
  editingBorderColor: "blue" // 点击文字进入编辑状态时的边框颜色
});

// 方形头像
const rectAvatar = () => {
  return new fabric.Image.fromURL(dufaultAvatarUrl, (img:any) => {
    img.set({
      width : 70,
      height : 70
    });
  })
}

// 添加文字后，如下图
export const getAvatarGroup = (radius = 100) => {
  return new fabric.Group([getAvatarCircle(radius), rectAvatar()], {
    left: 50,
    top: 50,
    angle: 0
  });
}

// 二维码组
export const getQrGroup = (top = 400, left = 50,) => {
  return new fabric.Group([qrBox, qrText], {
    left: left,
    top: top,
    angle: 0
  });
}


// export const loadImg = function loadImg(src:any) {
//   const paths = Array.isArray(src) ? src : [src];
//   const promise:any = [];
//   paths.forEach((path) => {
//     promise.push(new Promise((resolve, reject) => {
//         const img = new Image();
//         img.onload = () => {
//             resolve({
//                 path,
//                 status: 'ok',
//             });
//         };
//         img.onerror = () => {
//           resolve({path,status: 'error',});
//         };
//         img.src = path;
//     }));
//   });
//   return Promise.all(promise);
// };