import React, { Fragment, useEffect, useState } from 'react'
import { Table, Row, Drawer, Typography, Col, Avatar } from 'antd'
import { getRequest } from '../../../../api/http';
import { apiUrl } from '../../../../api/api_url';
import Item from 'antd/lib/list/Item';
import { Add } from '../../../../utils/numUtils';

interface IOrderPaddingProps {
  showPaddingOrder: boolean
  changeVisitity(status: boolean): void
  code: string
}

const { Text, Title } = Typography;

interface IorderData {
  desk: string
  amount: number
  price: string
  price_vip: string
  code: string
  status: number
  status_display: string
  discounts_fee_total: string
  no_discounts_fee_total: string
  discount_name: string
  exchange_coupon_name: string
  exchange_coupon_num: number
  wechat_pay: string
  user_amount: number
  dishes: Array<{
    mark: string,
    dishes: Array<{
      id: string
      name: string
      count: number
      price: string
      main_pic: string
      standard: string
      price_vip: string
    }>,
    created_at: string
    fan: {
      headimgurl: string,
      nickname: string
    }
  }>
  list: Array<{

  }>
  created_at: string
  paid_at: string
  fan: {
    headimgurl: string,
    nickname: string
  } | null
  actual_fee: string | null,
  platform_coupon_list: any,
  third_coupon_list: any,
  ali_pay: any,
  charge_fee: any,
  pay_result_detail: any
}

const OrderPadding = (props: IOrderPaddingProps) => {

  const titleWidth = 80;
  const [orderData, setorderData] = useState<IorderData>({
    desk: '',
    amount: 0,
    price: '',
    price_vip: '',
    code: '',
    status: 0,
    status_display: '',
    dishes: [],
    list: [],
    created_at: '',
    paid_at: '',
    fan: null,
    discounts_fee_total: '0.0',
    no_discounts_fee_total: '0.0',
    actual_fee: null,
    discount_name: '',
    exchange_coupon_name: '',
    exchange_coupon_num: 0,
    wechat_pay: '',
    user_amount: 0,
    platform_coupon_list: [],
    third_coupon_list: [],
    ali_pay: 0,
    charge_fee: 0,
    pay_result_detail: []
  });

  useEffect(() => {
   
    if(props.showPaddingOrder) {
      getOrderDetai()
    }
  }, [props.code])

  const getOrderDetai = async () => {
    if(props.code) {
      const parms = {
        code: props.code
      }
      const res: any = await getRequest(apiUrl.foodOrderDetail, parms);
      if(res && res.data) {
        if(res.data.zhuli_dish){
          let obj = res.data.zhuli_dish;
          obj.refund_num = 0
          obj.order_present_num = 0
          obj.order_redeem_num = 0
          obj.desc = '助'
          res.data.list.push(res.data.zhuli_dish)
        }
        setorderData(res.data)
      }
    }
  }

  const columns = [{
    title: '商品名称',
    key: "name",
    render: (rowdata: any) => {
      if(rowdata) {
      return <Row style={{textAlign: "left"}}>{rowdata.name}</Row>
      }
      return <Row></Row>
    }
  },{
    title: '规格',
    key: "standard",
    render: (rowdata: any) => {
      if(rowdata) {
        return <Row>{rowdata.standard || rowdata.dish_weigh_name ? `${rowdata.standard?rowdata.standard:''}  ${rowdata.dish_weigh_name ? rowdata.dish_weigh_name : ''}` : '--'}</Row>
      }
      return <Row></Row>
    }
  },
  // {
  //   title: '退菜数',
  //   dataIndex: "order_dish_refund_num",
  //   key: "order_dish_refund_num",
  // },
  {
    title: '小计(元)',
    // dataIndex: "count",
    key: "total",
    render: (rowdata:any) => {
      if(rowdata) {
        return <Row>{(Number(rowdata['count']) * Number(rowdata.is_vip_dish ? rowdata['price_vip'] : rowdata['price'])).toFixed(2)}</Row>
      }
      return <Row></Row>
    }
  }];

  const totalColumns = [{
    title: '商品名称',
    key: 'name',
    render: (rowdata: any) => {
      if(rowdata) {
        return <Row style={{textAlign: "left", display:'flex'}}>
          <Col>{rowdata.name}</Col>
          {rowdata.refund_num && parseFloat(rowdata.refund_num) > 0 ? <Col style={{color: 'red'}}>（退）</Col> : null}
          {rowdata.order_redeem_num && parseFloat(rowdata.order_redeem_num) > 0 ? <Col style={{color: 'red'}}>（兑）</Col> : null}
          {rowdata.order_present_num && parseFloat(rowdata.order_present_num) > 0 ? <Col style={{color: 'red'}}>（赠）</Col> : null}
          {rowdata.desc && <Col style={{color: 'red'}}>（{rowdata.desc}）</Col>}
        </Row>
      }
      return <Row></Row>
    }
  },{
    title: '规格',
    key: "standard",
    render: (rowdata: any) => {
      if(rowdata) {
        return <Row>{rowdata.standard || rowdata.dish_weigh_name ? `${rowdata.standard?rowdata.standard:''}  ${rowdata.dish_weigh_name ? rowdata.dish_weigh_name : ''}` : '--'}</Row>
      }
      return <Row></Row>
    }
  },{
    title: '加料',
    key: "material_name",
    render: (rowdata: any) => {
      if(rowdata) {
        return <Row>{rowdata.material_name ? `${rowdata.material_name}` : '--'}</Row>
      }
      return <Row></Row>
    }
  },{
    title: '已点份数',
    dataIndex: "count",
    key: "count",
  },{
    title: '退菜份数',
    dataIndex: "refund_num",
    key: "refund_num",
  },
  {
    title: '兑菜份数',
    dataIndex: "order_redeem_num",
    key: "order_redeem_num",
  },
  {
    title: '赠菜份数',
    dataIndex: "order_present_num",
    key: "order_present_num",
  },
  {
    title: '小计(元)',
    key: "total",
    render: (rowdata:any) => {
      if(rowdata && rowdata.desc && rowdata.desc === '助') {
        return <Row>0.00</Row>
      }else{
        return <Row>{((Number(rowdata['count']) - Number(rowdata['refund_num']) - Number(rowdata['order_redeem_num']) - Number(rowdata['order_present_num'])) * Number(rowdata.is_vip_dish ? rowdata.material_price ? Add(rowdata.material_price,rowdata['price_vip']) :rowdata['price_vip']  : rowdata.material_price ? Add(rowdata.material_price,rowdata['price']) : rowdata['price'])).toFixed(2)}</Row>
      }
    }
  }];

  const orderTableFooter = (tip: string) => <Row>备注: {tip}</Row>

  return  <Fragment>
    <Drawer
        title="订单信息"
        placement={`left`}
        closable={false}
        onClose={() => props.changeVisitity(false)}
        width={700}
        visible={props.showPaddingOrder}
      >
        <Row type="flex" style={{marginBottom: 10}}>
          <Col style={{width: titleWidth, textAlign: "left", marginRight: 10}}>
            <Text strong >订单号</Text>
          </Col>
          <Text >{props.code}</Text>
        </Row>
        <Row type="flex" style={{marginBottom: 10}}>
          <Col style={{width: titleWidth, textAlign: "left", marginRight: 10}}>
            <Text strong >餐台号</Text>
          </Col>
          <Text >{orderData.desk}</Text>
        </Row>
        <Row type="flex" style={{marginBottom: 10}}>
          <Col style={{width: titleWidth, textAlign: "left", marginRight: 10}}>
            <Text strong >用餐人数</Text>
          </Col>
          <Text >{orderData.user_amount}</Text>
        </Row>
        <Row type="flex" style={{marginBottom: 10}}>
          <Col style={{width: titleWidth, textAlign: "left", marginRight: 10}}>
            <Text strong >订单状态</Text>
          </Col>
          <Text >{orderData.status_display}</Text>
        </Row>

        <Title level={4}>已下单商品信息</Title>

        {
          orderData.dishes.map((item, idx: number) => {
            return <Row key={item.created_at}>
              <Row style={{height: 20}}/>
              <Text>第{idx + 1}次下单到厨房：</Text>
              <Row style={{height: 10}}/>
              <Row type="flex" align="middle" style={{marginBottom: 10}}>
                <Text>下单用户</Text>
                <Row>
                  <Avatar src={item.fan.headimgurl} size={30} style={{marginLeft: 30, marginRight: 10}} />
                  <Text>{item.fan.nickname}</Text>
                </Row>
              </Row>

              <Table columns={columns}
                dataSource={item.dishes}
                pagination={false}
                rowKey={'id'}
                size="small"
                footer={() => orderTableFooter(item.mark)}
                />
            </Row>
          })
        }

        <Title level={4} style={{marginTop: 30}}>已下单商品信息汇总</Title>
        {
          orderData.list && <Table
          rowKey={'id'}
          columns={totalColumns}
          dataSource={orderData.list}
          pagination={false}
          size="small"
          footer={
            () => <Row>

              <Row style={{textAlign: "right"}}>可优惠金额：￥{Number(orderData.discounts_fee_total).toFixed(2)}, 不可优惠金额：￥{Number(orderData.no_discounts_fee_total).toFixed(2)}</Row>
              { orderData.discount_name && <Row style={{textAlign: "right"}}>{orderData.discount_name}</Row> }
              { orderData.exchange_coupon_num && <Row style={{textAlign: "right"}}>{orderData.discount_name} x {orderData.exchange_coupon_num}</Row> }
              {
                orderData.platform_coupon_list.map((item:any,index:number) =>{
                  return <Row style={{textAlign: "right"}} key={index}>{item.name}：{item.price}</Row>
                })
              }
              {
                orderData.third_coupon_list.map((item:any,index:number) =>{
                  return <Row style={{textAlign: "right"}} key={index}>{item.name}：{item.price}</Row>
                })
              }
              <Row style={{textAlign: "right"}}>应收金额：￥{orderData.price}</Row>
              {
                orderData.pay_result_detail && orderData.pay_result_detail.map((item:any) => {
                  return <Row style={{textAlign: "right"}}>{item.name}：￥{item.pay_amount}</Row>
                })
              }
              {/* { Number(orderData.wechat_pay) > 0 && <Row style={{textAlign: "right"}}>微信支付：￥{orderData.wechat_pay}</Row> }
              { Number(orderData.ali_pay) > 0 && <Row style={{textAlign: "right"}}>支付宝支付：￥{orderData.ali_pay}</Row>} */}
              { orderData.actual_fee && <Row style={{textAlign: "right"}}>实际支付：￥{orderData.actual_fee}</Row> }
              { orderData.charge_fee && <Row style={{textAlign: "right"}}>充值金额：￥{orderData.charge_fee}</Row> }
            </Row>
          } />
        }
      </Drawer>
</Fragment>

}

export default OrderPadding