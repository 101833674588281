import React, { useEffect, useState } from 'react'
import { Modal, Input, message } from 'antd'
import { getRequest, postRequest } from '../../../../api/http'
import { apiUrl } from '../../../../api/api_url'

type Iprops = {
    visible:boolean,
    onClose():void,
}

const SmsEditModal = (props:Iprops)=>{

    const { visible, onClose } = props
    const [ sign, setSign ] = useState('')
    const [ info, setInfo ] = useState<any>({})

    useEffect(()=>{
        //查询签名申请信息
        getSmsInfo()
    },[visible])

    const getSmsInfo = async() => {
        const res:any = await getRequest(apiUrl.getSmsInfo);
        if(res&&res.code==1){
            setInfo(res.data)
            setSign(res.data.sign)
        }
    }

    const handleOk = () => {
        if(!sign){
            message.error('请输入短信签名')
            return false
        }

        if(info.status==1){
            Modal.info({
                okText: '确定', 
                // icon: '',
                title: '审核中',
                content: info.sign_remark?info.sign_remark:'提交后等待运营审核（1个工作日内），即可查看审核状态'
            })
            return false
        }

        Modal.confirm({
            title: null,
            icon:null,
            content: '是否确认提交申请？',
            okText: '确认',
            cancelText: '取消',
            onOk: async() => {
                const res:any = await postRequest(apiUrl.getSmsEditSign, {sign:sign});
                if(res&&res.code==1){
                    message.success('提交成功')
                    onClose()
                }else{
                    message.error(res.message||'提交失败')
                }
            },
            onCancel() {
            },
        })
    }

    const onChangeVal = (value:string) => {
        var regex = /\[|\]|【|】/g
        setSign(value.replace(regex,'').trim())
    }

    return(
        <Modal
            title='短信签名修改'
            visible={visible}
            onOk={handleOk}
            onCancel={onClose}
            width={455}
            className="sms_edit_modal"
            closable={false}
        >
           <Input placeholder="请输入短信签名" value={sign} className="mb20" onChange={(e)=>{onChangeVal(e.target.value)}} disabled={info.status==1?true:false}/>
           <div className="color999 mb10">每个自然月修改2次</div>
           <div className="color_red mb10">*短信签名建议为店铺名/品牌名/网站名/公司名。其他签名可能会导致签名审核不通过</div>
           <div className="color_red">*若签名侵犯到第三方权益须获得第三方的真实授权。</div>
        </Modal> 
    )
}

export default SmsEditModal