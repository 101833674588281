import React from "react";
import { Row, Col, Table, message, Modal, Input, Icon, Button } from "antd";
import { getRequest, postRequest } from '../../../api/http';
import { apiUrl } from '../../../api/api_url';
import { Add, Minus, Multiply, keepTwoNum } from '../../../utils/numUtils'
import './settleAccounts.scss'

import NumChangeDialog from "../../orderingFood/numChangeDialog";
import { getIsPermisson, messageCustom } from "../../../utils/permisstionUtil";
import user_check from '../../../assret/images/user_check.png'
import arrow_up from '../../../assret/images/arrow_up.png'
import arrow_down from '../../../assret/images/arrow_down.png'

const { Search } = Input;
interface SettleAccounts {
    history: any,
    location: any,
    textInput: any,
    setTextInputRef: any,
    focusTextInput: any
}
class SettleAccounts extends React.Component<SettleAccounts> {
    constructor(props: any) {
        super(props);

        this.textInput = null;

        this.setTextInputRef = (element: any) => {
            this.textInput = element;
        };

        this.focusTextInput = () => {
            if (this.textInput) this.textInput.focus();
        };
    }

    columns = [
        {
            title: '菜品',
            dataIndex: 'name',
            key: 'name',
            className: 'list_center settle_table settelAccount_table',
            render: (text: string, record: any) => {
                return <div style={{ fontSize: 20 }}>
                    <div>{record.name}</div>
                    {record.material_key ? <div>{record.material_name}</div> : null }
                    {
                        record.desk_num
                            ? <div style={{ fontSize: 10, color: '#333333' }}>{record.desk_num}</div>
                            : null
                    }
                </div>
            }
        },
        {
            title: '单价',
            dataIndex: 'price',
            key: 'price',
            className: 'list_center settle_table settelAccount_table',
            render: (text: string, record: any) => {
                return <div style={{ fontSize: 20 }}>
                    {
                        record.is_vip_dish
                        ? <div style={{textDecoration: 'line-through'}}>{this.getRealPrice(record)}</div>
                        : <div>{this.getRealPrice(record)}</div>
                    }
                </div>
            }
        },
        {
            title: '会员价',
            dataIndex: 'price_vip',
            key: 'price_vip',
            className: 'list_center settle_table settelAccount_table',
            render: (text: string, record: any) => {
                return <div style={{ fontSize: 20 }}>
                    {
                        record.is_vip_dish
                        ? <div>{this.getRealPriceVip(record)}</div>
                        : '--'
                    }
                </div>
            }
        },
        {
            title: '数量',
            dataIndex: 'count',
            key: 'count',
            className: 'list_center settle_table settelAccount_table',
        },
        {
            title: '退回',
            dataIndex: 'refund_num',
            key: 'refund_num',
            className: 'list_center settle_table settelAccount_table',
            render: (text: string, record: any) => {
                return <div>
                    {
                        record.order_dish_refund_num !== undefined
                            ? <div>{record.order_dish_refund_num}</div>
                            : <div>0</div>
                    }
                </div>
            }
        },
        {
            title: '金额',
            dataIndex: 'totalprice',
            key: 'totalprice',
            className: 'list_center settle_table settelAccount_table',
            render: (text: any, record: any) => {
                const num = Number(record.count - (record.order_dish_refund_num !== undefined ? record.order_dish_refund_num : 0));
                return <div>{
                    <div>{keepTwoNum(Multiply(record.is_vip_dish ? this.getRealPriceVip(record) : this.getRealPrice(record), num))}</div>
                }</div>
            }
        },
    ];

    columnBack = [
        {
            title: '菜品',
            dataIndex: 'name',
            key: 'name',
            className: 'list_center',
            render: (text: string, record: any) => {
                return <div>
                    <div>{record.name}</div>
                    {record.material_key ? <div>{record.material_name}</div> : null }
                    {
                        record.desk_num
                            ? <div style={{ fontSize: 10, color: '#999' }}>{record.desk_num}</div>
                            : null
                    }
                </div>
            }
        },
        {
            title: '单价',
            dataIndex: 'price',
            key: 'price',
            className: 'list_center',
            render: (text: string, record: any) => {
                return <div style={{ fontSize: 20 }}>
                    {
                        record.is_vip_dish
                        ? <div style={{textDecoration: 'line-through'}}>{this.getRealPrice(record)}</div>
                        : <div>{this.getRealPrice(record)}</div>
                    }
                </div>
            }
        },
        {
            title: '会员价',
            dataIndex: 'price_vip',
            key: 'price_vip',
            className: 'list_center',
            render: (text: string, record: any) => {
                return <div style={{ fontSize: 20 }}>
                    {
                        record.is_vip_dish
                        ? <div>{this.getRealPriceVip(record)}</div>
                        : '--'
                    }
                </div>
            }
        },
        {
            title: '数量',
            dataIndex: 'count',
            key: 'count',
            className: 'list_center',
        },
        {
            title: '退回',
            dataIndex: 'refund_num',
            key: 'refund_num',
            className: 'list_center',
            render: (text: string, record: any) => {
                return <div>
                    {
                        record.order_dish_refund_num !== undefined
                            ? <div>{record.order_dish_refund_num}</div>
                            : <div>0</div>
                    }
                </div>
            }
        },
        {
            title: '金额',
            dataIndex: 'totalPrice',
            key: 'totalPrice',
            className: 'list_center',
            render: (text: any, record: any) => {
                return <div>{
                    <div>￥{keepTwoNum(Multiply((Number(record.count) - Number(record.order_dish_refund_num)), record.is_vip_dish ? this.getRealPriceVip(record) : this.getRealPrice(record)))}</div>
                }</div>
            },
        },
        {
            title: '退菜数量',
            dataIndex: 'back',
            key: 'back',
            className: 'list_center',
            render: (text: any, record: any, index: number) => (
                <div className='back_wrap'>
                    <div className={`back_btn reduce_btn ${record.newCount <= 1 ? '' : 'active'}`} onClick={() => this.reduceDish(record, index)}>-</div>
                    <div className='good_num'>{record.newCount}</div>
                    <div className={`back_btn add_btn ${(record.count - record.order_dish_refund_num) <= record.newCount ? '' : 'active'}`} onClick={() => this.addDish(record, index)}>+</div>
                </div>
            ),
        },
    ];

    columnBackDiscount = [
        {
            title: '菜品',
            dataIndex: 'name',
            key: 'name',
            className: 'list_center',
            render: (text: string, record: any) => {
                return <div>
                    <div>{record.name}</div>
                    {record.material_key ? <div>{record.material_name}</div> : null }
                    {
                        record.desk_num
                            ? <div style={{ fontSize: 10, color: '#999' }}>{record.desk_num}</div>
                            : null
                    }
                </div>
            }
        },
        {
            title: '单价',
            dataIndex: 'price',
            key: 'price',
            className: 'list_center',
            render: (text: string, record: any) => {
                return <div style={{ fontSize: 20 }}>
                    {
                        record.is_vip_dish
                        ? <div style={{textDecoration: 'line-through'}}>{this.getRealPrice(record)}</div>
                        : <div>{this.getRealPrice(record)}</div>
                    }
                </div>
            }
        },
        {
            title: '会员价',
            dataIndex: 'price_vip',
            key: 'price_vip',
            className: 'list_center',
            render: (text: string, record: any) => {
                return <div style={{ fontSize: 20 }}>
                    {
                        record.is_vip_dish
                        ? <div>{this.getRealPriceVip(record)}</div>
                        : '--'
                    }
                </div>
            }
        },
        {
            title: '数量',
            dataIndex: 'count',
            key: 'count',
            className: 'list_center',
        },
        {
            title: '退回',
            dataIndex: 'refund_num',
            key: 'refund_num',
            className: 'list_center',
            render: (text: string, record: any) => {
                return <div>
                    {
                        record.order_dish_refund_num !== undefined
                            ? <div>{record.order_dish_refund_num}</div>
                            : <div>0</div>
                    }
                </div>
            }
        },
        {
            title: '金额',
            dataIndex: 'totalPrice',
            key: 'totalPrice',
            className: 'list_center',
            render: (text: any, record: any) => {
                return <div>{
                    <div>{keepTwoNum(Multiply((Number(record.count) - Number(record.order_dish_refund_num)), record.is_vip_dish ? this.getRealPriceVip(record) : this.getRealPrice(record)))}</div>
                }</div>
            },
        },
        {
            title: '参与打折',
            dataIndex: 'back',
            key: 'back',
            className: 'list_center',
            render: (text: any, record: any, index: number) => (
                <div className=''>
                    {
                         record.is_no_discount
                         ? '否'
                         : '是'
                    }
                </div>
            ),
        },
    ];

    // 当前退菜桌号
    backDesk = '';
    // 预支付保存后台优惠
    discount_back_key = '';
    // manual_fee = '';   //改价
    // pay_value = '';

    //toBePadid,始终是需要支付多少价格，不相关调价
    //manual_fee_sure,展示调价金额
    //manual_fee_show,计算器展示金额

    state = {
        fullVisible: false,
        backVisible: false,
        couponVisible: false,
        payVisible: false,
        chargeShow: false,
        payChangeVisible:false,
        charge_amount:0,
        present_amount:0,
        change_pay_type:'',
        payStatus: 0,   //1---付款中，2---付款成功，3---付款失败
        payList: [
            {
                'pay_type': 1,
                'active': true,
                'name': '微信/支付宝条码支付',
                'color': '#3384FF'
            },
            {
                'pay_type': 6,
                'active': false,
                'name': '余额支付',
                'color': '#fff'
            },
            {
                'pay_type': 2,
                'active': false,
                'name': '人民币支付',
                'color': '#fff'
            },
            {
                'pay_type': 2,
                'active': false,
                'name': '多种支付方式',
                'color': '#fff'
            }
        ],
        couponList: [],
        platList: [],
        list: [],
        presentFoods: [],
        backList: [],
        valueTemp: '',   //支付code
        tableInfo: {
            'desk': '',
            'amount': 0,
            'user_amount': 0,
            'price': 0,
            'code': '',
            'no_discounts_fee_total': 0,
            'discounts_fee_total': 0,
            'zhuli_dish':{
                'name':'',
                'price': 0,
                'price_vip': 0,
                'is_vip_dish':false,
                'count': 0
            }
        },
        thirdDiscount: {
            'id': 0,
            'count': 0,
            'name': "",
            'origin_price': "0",
            'real_price': "0",
            'channel': 0,
        },
        discountArr: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        toBePaid: 0,   //待支付金额
        platDiscount: {   //整单打折，折扣
            'name': '',
            'discount': 0,
            'id': 0
        },
        manual_fee: '',
        manual_fee_sure: '',
        manual_fee_show: '',
        canUseCoupon: 1,
        backPage: 1,  //退菜当前页数
        inputCouponCount: 0,
        clickCouponNum: 0,

        thirdPay: [],
        morePay: false,
        thirdShow: false,

        input_fee_sure: '', //多种支付输入金额
        payVariedVisible: false,
        varied_pay: '', //多种支付-支付方式
        own_varied_pay_list: [], //当前多种支付方式列表
        varied_pay_ok: false, //多种支付-完成支付
        remain_amount: '', // 多种支付-剩余付款金额
        varied_pay_tobepaid: 0, //多种支付-待支付金额
        varied_pay_tobepaid_index: 0,
        varied_pay_alipay_status: false, //多种支付-支付宝
        varied_pay_wechat_status: false, //多种支付-微信
        manual_fee_log_list: [], //调价当前列表
        manual_fee_log_list_result: [], //调价实时总列表
        yetOrderPage: [], //菜品修改
        reloadPrintActionSatatus: false, //菜品单个打折
        reloadPrintActionName: '', //当前选中打折的菜品名称
        orderNotesDefault: [

        ], //当前折扣信息列表
        discount_of_dishes: false, //菜品参与打折
        copy_discount_of_dishes: [], //copy一个菜品列表，过滤退菜
        copy_discount_of_dishes_nongid: [], //参与打折选中的菜品

        manual_fee_log_start_page: 1, //调价分页-当前页
        manual_fee_log_end_page: 3, //调价分页-每页显示多少条
        manual_fee_log_is_end: false, //调价分页，是否到最后一页
        payVariedVisibleInput: false, //等待用户扫码

        user_info: {
            "id": 0,//用户id
            "nickname": "",//用户昵称
            "headimgurl": "",//用户头像
            "member_level": "",//会员等级
            "member_score": 0,//会员积分
            "balance": "0.00",//用户余额
            "eat_times": 0,//就餐次数
            "comsumer_times": 0,//消费次数（买单次数）
            "actual_fee": "0",//累计消费金额
            "create_time": "0",//注册时间
            "last_comsumer_time": "0",//最后一次消费时间
            "phone_number": '', //手机号
            'vip_num': '无',  //会员类型
            'sex': '未知',
            'member_type': 0,
            'member_type_desc': '',
            'vip_type':0,
        }, //搜索用户信息

        user_info_status: false,

        varied_pay_list: [],
        editNumber:{
            visible: false
        },
        userList: [],
        fans_id: 0,
        fansVisible: false,
        thirdItem: {},   //第三方当前支付方式
        thirdVisible: false, //第三方支付弹窗,
        no_discount_fee: 0,
    }

    componentDidMount() {
        this.dishInfo();
        this.thirdCoupon();
        this.platCoupon();
        this.thirdPay();

        this.onPayChannelListMultiple()
        this.focusTextInput();

        var code = "";
        var lastTime: any, nextTime;
        var lastCode: any, nextCode;

        window.addEventListener('keypress', (e) => {
            if (this.state.payVisible || this.state.morePay || this.state.payChangeVisible) {
                nextCode = e.which;
                nextTime = new Date().getTime();
                if (lastCode != null && lastTime != null && nextTime - lastTime <= 30) {
                    code += String.fromCharCode(lastCode);
                } else if (lastCode != null && lastTime != null && nextTime - lastTime > 100) {
                    code = "";
                }
                lastCode = nextCode;
                lastTime = nextTime;
                if (e.which == 13) {
                    console.log('code111:', code)
                    code = code.replace(/[^0-9]/ig,"");
                    console.log('val222', code);
                    // return;
                    console.log(this.state.morePay)
                    if (this.state.payChangeVisible) { //多种支付方式
                        //console.log('code:',code)
                        this.setState({
                            valueTemp: code,
                            payStatus: 1,
                            change_pay_type: 'one'
                        }, () => {
                            let { varied_pay_list } = this.state;
                            varied_pay_list.forEach((item: any) => {
                                if (item.active) {
                                    this.chargePayOrder();
                                }
                            })
                        })
                        code = "";
                    }else if (this.state.morePay) { //多种支付方式
                        //console.log('code:',code)
                        this.setState({
                            valueTemp: code,
                            payStatus: 1
                        }, () => {
                            let { varied_pay_list } = this.state;
                            varied_pay_list.forEach((item: any) => {
                                if (item.active) {
                                    this.varieadPayOrder(this.state.varied_pay);
                                }
                            })
                        })
                        code = "";
                    } else {
                        this.setState({
                            valueTemp: code,
                            payStatus: 1
                        }, () => {
                            let { payList } = this.state;
                            payList.forEach((item: any) => {
                                if (item.active) {
                                    this.payOrder(item.pay_type);
                                }
                            })
                        })
                        code = "";
                    }

                }
            }
        })
    }

    // 订单详情
    dishInfo = async () => {
        let { backPage } = this.state;
        let param = {
            'code': this.props.location.state.code
        }
        if (this.props.location.state.codes) {
            param.code = this.props.location.state.codes;
        }
        const res: any = await getRequest(apiUrl.orderDishDetail, param)
        if (res.code === 1) {

            if (res.data.status === 3) {
                Modal.confirm({
                    title: '提示',
                    content: `用户正在支付中，是否取消支付？请和用户确认后再操作！`,
                    okText: '是',
                    cancelText: '否',
                    onOk: async () => {
                        const res: any = await getRequest(apiUrl.cancelOrder, { code: param.code });
                        if (res.code === 1) {
                            message.success("该订单已恢复");
                            setTimeout(() => {
                                this.props.history.goBack()
                            }, 100)
                        } else {
                            message.error(res.message)
                        }
                    },
                    onCancel: () => {
                        this.props.history.goBack()
                    },
                });
                return null;
            }
            let info: any = {
                'desk': res.data.desk,
                'amount': res.data.amount,
                'user_amount': res.data.user_amount,
                'price': res.data.price,
                'code': res.data.code,
                'no_discounts_fee_total': res.data.no_discounts_fee_total,
                'discounts_fee_total': res.data.discounts_fee_total,
                'zhuli_dish': res.data.zhuli_dish
            }
            let newList: any = [];
            if (res.data.group_key) {
                info.group_key = res.data.group_key;
                let _obj = res.data.foods;
                let _meal_list: any = res.data.set_meal;
                if(_meal_list.length > 0) {
                    _obj = res.data.set_meal.concat(_obj);
                }
                _obj.forEach((item: any, index: number) => {
                    //newList[index] = res.data.foods[index];
                    item.newCount = 0;
                    if(!item.order_dish_refund_num) {
                        item.order_dish_refund_num = 0;
                    }
                })
                newList = _obj
            } else {
                let _obj = res.data.list;
                let _meal_list: any = res.data.set_meal;
                if(_meal_list.length > 0) {
                    _obj = res.data.set_meal.concat(_obj);
                }
                console.log('_obj:',_obj)
                _obj.forEach((item: any, index: number) => {
                    //newList[index] = res.data.list[index];
                    //console.log('res.data.list[index].order_dish_refund_num:',res.data.list[])
                    if (item.count == item.order_dish_refund_num) {
                        item.newCount = 0;
                    } else {
                        item.newCount = 1;
                    }
                    if(!item.order_dish_refund_num) {
                        item.order_dish_refund_num = 0;
                    }
                })
                newList = _obj
            }

            console.log('newList:', newList)

            // 折扣信息
            if (res.data.orders_discount_info) {
                let discountInfo = res.data.orders_discount_info;

                if (discountInfo.discount_type === 1) {
                    let couponInfo = {
                        'id': discountInfo.exchange_coupon_id,
                        'count': discountInfo.exchange_coupon_num,
                        'name': discountInfo.name,
                        'origin_price': discountInfo.exchange_origin_price,
                        'real_price': discountInfo.exchange_real_price,
                    };

                    let toBePaid = discountInfo.pay_value;
                    if (discountInfo.manual_fee != 0) {
                        this.setState({
                            manual_fee: discountInfo.manual_fee,
                            manual_fee_sure: discountInfo.pay_fee_actual,
                        })
                        this.payTips(info.desk, discountInfo.pay_fee_actual);
                    }else{
                        this.setState({
                            manual_fee_sure: '',
                        })
                        this.payTips(info.desk, toBePaid);
                    }

                    this.setState({
                        thirdDiscount: couponInfo,
                        toBePaid
                    })
                } else if (discountInfo.discount_type === 2) {
                    let platInfo = {
                        'id': discountInfo.discount_id,
                        'discount': discountInfo.discount,
                        'name': discountInfo.name,
                    };
                    let toBePaid = discountInfo.pay_value   //pay_value不包含调价，pay_fee_actual，调价之后

                    if (discountInfo.manual_fee != 0) {
                        this.setState({
                            manual_fee: discountInfo.manual_fee,
                            manual_fee_sure: discountInfo.pay_fee_actual,
                        })
                        this.payTips(info.desk, discountInfo.pay_fee_actual);
                    }else{
                        this.setState({
                            manual_fee_sure: '',
                        })
                        this.payTips(info.desk, toBePaid);
                    }
                    this.setState({
                        platDiscount: platInfo,
                        toBePaid
                    })
                } else if (discountInfo.discount_type === 4) {
                    let toBePaid = discountInfo.pay_value;
                    this.setState({
                        toBePaid,
                        manual_fee: discountInfo.manual_fee,
                        manual_fee_sure: discountInfo.pay_fee_actual,
                    })
                    this.payTips(info.desk, discountInfo.pay_fee_actual);
                }

                this.discount_back_key = discountInfo.discount_back_key;
            } else {
                this.setState({
                    toBePaid: res.data.price,
                    manual_fee_sure: '',
                    thirdDiscount: {
                        'id': 0,
                        'count': 0,
                        'name': "",
                        'origin_price': "0",
                        'real_price': "0",
                        'channel': 0,
                    }, //待支付金额
                    platDiscount: {   //整单打折，折扣
                        'name': '',
                        'discount': 0,
                        'id': 0
                    },
                }, () => {
                    console.log('toBe:',this.state.toBePaid)
                })

                this.payTips(info.desk, res.data.price);
            }

            let newBackList = JSON.parse(JSON.stringify(newList));
            let backList = newBackList.splice((backPage - 1) * 10, 10);

            console.log('backList:', backList)
            console.log('newBackList:', newBackList)

            // if (res.data.set_meal && res.data.set_meal.length > 0) {
            //     let setList = res.data.set_meal;
            //     newList = setList.concat(newList)
            // }
            console.log('list:', newList)
            //复制一个list给。修改菜品使用
            let copy_new_list: any = [];
            let copy_discount_of_dishes: any = [];
            newList.map((item: any, index: any) => {
                copy_new_list.push(Object.assign(item, { edit: false }))
                if (item.order_dish_refund_num && item.order_dish_refund_num > 0) {

                }
            })
            if(res.data.group_key) {
                res.data.foods.map((item: any, index: any) => {
                    let count = item.count;
                    let sel_count = count - item.order_dish_refund_num;
                    if (sel_count > 0) {
                        copy_discount_of_dishes.push(item)
                    }
                })
            } else {
                res.data.list.map((item: any, index: any) => {
                    let count = item.count;
                    let sel_count = count - item.order_dish_refund_num;
                    if (sel_count > 0) {
                        copy_discount_of_dishes.push(item)
                    }
                })
            }

            //复制一个list，过滤退菜的
            console.log('copy_new_list:', copy_new_list)
            this.setState({
                list: newList,
                yetOrderPage: copy_new_list,
                tableInfo: info,
                backList,
                copy_discount_of_dishes
            },() => {
                //执行调价列表
                this.onGetManualFeeLogList();
            })
            if (res.data.present_foods.length > 0) {
                let presentFoods: any = [];
                res.data.present_foods.map((item: any) => {

                    if (item.foods.length > 0) {
                        item.foods.map((food: any) => {
                            if (res.data.group_key) {
                                food.desk_num = item.table_name;
                            }
                            presentFoods.push(food)
                        })
                    }
                })
                this.setState({
                    presentFoods
                })
            }
        }
    }

    // 第三方渠道优惠券
    thirdCoupon = async () => {
        const res: any = await getRequest(apiUrl.orderExchange, {})
        if (res.code === 1) {
            this.setState({
                couponList: res.data
            })
        }
    }

    // 平台优惠券列表
    platCoupon = async () => {
        const res: any = await getRequest(apiUrl.orderDiscount, {})
        if (res.code === 1) {
            let _obj: any = [];
            res.data.map((item: any, index: any) => {
                _obj.push(Object.assign(item, { active: false }))
            })
            this.setState({
                platList: res.data,
                orderNotesDefault: _obj,
            })
        }
    }

    // 第三方支付方式
    thirdPay = async () => {
        const res: any = await getRequest(apiUrl.payChannelList)
        if (res.code == 1) {
            this.setState({
                thirdPay: res.data
            })
        }
    }

    // 预结单
    preorderForm = async () => {
        let tableInfo: any = this.state.tableInfo;
        let params: any = {
            'code': tableInfo.code
        }
        if (tableInfo.group_key) {
            params = {
                'group_key': tableInfo.group_key
            }
        }
        const res: any = await postRequest(apiUrl.prepayOrder, params)
        if (res.code === 1) {
            message.success('打印成功');
        }
    }

    // 使用第三方优惠
    userThirdDiscount = (item: any) => {
        if(!getIsPermisson('1_11_11_9')){
            messageCustom();
            return;
          }
        let manual_fee_log_list = this.state.manual_fee_log_list;
        if(manual_fee_log_list.length >= 1) {
            message.warn('调价后不能使用优惠，请清除调价后再操作！')
            return false;
        }

        let { tableInfo } = this.state;
        // let canUseCoupon = parseInt((tableInfo.discounts_fee_total / item.origin_price).toString());
        let canUseCoupon = 99;

        let info = {
            'count': 0,
            'name': item.name,
            'origin_price': item.origin_price,
            'real_price': item.real_price,
            'channel': item.channel,
            'id': item.id,
        }
        this.setState({
            couponVisible: true,
            thirdDiscount: info,
            toBePaid: tableInfo.price,
            canUseCoupon
        })
    }

    // 优惠券输入张数
    useCouponNum = (e: any) => {
        let num = e.target.value;
        if (num <= 0) {
            message.error('请输入正确的张数');
            return;
        }

        // if(num > canUseCoupon){
        //     message.error(`最多可使用${canUseCoupon}张优惠券`);
        //     return;
        // }

        this.setState({
            inputCouponCount: num,
            clickCouponNum: 0
        })
    }

    // 点击选择张数  优惠券使用多少张
    couponCount = (count: number) => {
        this.setState({
            clickCouponNum: count,
            inputCouponCount: 0
        })
    }

    // 确定使用多少张优惠券
    sureCouponCount = async () => {
        let tableInfo: any = this.state.tableInfo;
        let { thirdDiscount, toBePaid, clickCouponNum, inputCouponCount, canUseCoupon, manual_fee } = this.state;
        let realCount = 0;
        if (inputCouponCount !== 0 && inputCouponCount !== undefined) {
            realCount = Number(inputCouponCount);
        } else {
            realCount = clickCouponNum;
        }

        if (realCount > canUseCoupon) {
            message.error(`最多可使用${canUseCoupon}张优惠券`);
            return;
        }

        thirdDiscount.count = realCount;
        let discount = keepTwoNum(Multiply(thirdDiscount.origin_price, thirdDiscount.count));
        let discount2 = keepTwoNum(Minus(tableInfo.discounts_fee_total, discount));
        let discount3 = keepTwoNum(Add(discount2, tableInfo.no_discounts_fee_total))

        toBePaid = discount3;

        // toBePaid = Number(tableInfo.discounts_fee_total) - (Number(thirdDiscount.origin_price) * thirdDiscount.count) + Number(tableInfo.no_discounts_fee_total);
        let info = {
            'name': '',
            'discount': 0
        }

        let params: any = {
            mp_food_orders_code: this.props.location.state.code,
            exchange_coupon_id: thirdDiscount.id,
            exchange_coupon_num: thirdDiscount.count,
        }

        if (manual_fee !== '') {
            params.manual_fee = keepTwoNum(Minus(manual_fee, toBePaid))
        }

        if (tableInfo.group_key) {
            params.group_key = tableInfo.group_key;
        }

        let res: any = await postRequest(apiUrl.prepayDiscountSave, params)
        if (res.code === 1) {
            this.discount_back_key = res.data.discount_back_key;

            let max = keepTwoNum(Math.max(tableInfo.no_discounts_fee_total, Number(toBePaid)))
            this.setState({
                couponVisible: false,
                thirdDiscount,
                toBePaid: max,
                platDiscount: info,
            }, () => {
                this.payTips(tableInfo.desk, max);
            })
        } else {
            message.error(res.message)
        }
    }

    // 取消使用第三方优惠
    cancelCoupon = async () => {
        if(!getIsPermisson('1_11_11_9')){
            messageCustom();
            return;
          }
        let { tableInfo, manual_fee_sure } = this.state;
        let info = {
            'count': 0,
            'name': "",
            'origin_price': "0",
            'real_price': "0",
            'channel': 0,
            'id': 0
        }

        let params: any = {
            discount_back_key: this.discount_back_key,
            discount_type: 1
        }

        if (manual_fee_sure !== '') {
            params.manual_fee = keepTwoNum(Minus(manual_fee_sure, tableInfo.price))
        } else {
            params.manual_fee = '0'
        }

        let toBePaid = tableInfo.price;
        if (manual_fee_sure !== '') {
            toBePaid = Number(manual_fee_sure);
        }

        let res: any = await postRequest(apiUrl.prepayDiscountDel, params)
        if (res.code === 1) {
            this.setState({
                thirdDiscount: info,
                toBePaid,
                canUseCoupon: 1,
                clickCouponNum: 0,
                inputCouponCount: 0
            })
        } else {
            message.error(res.message)
        }
    }

    // 整单打折
    fullDiscount = async (item: any) => {
        let tableInfo: any = this.state.tableInfo;
        let { toBePaid, manual_fee } = this.state;

        let discount = keepTwoNum(Minus(1, item.discount));
        let discount2 = keepTwoNum(Multiply(tableInfo.discounts_fee_total, discount));
        let discount3 = keepTwoNum(Minus(tableInfo.discounts_fee_total, discount2));

        toBePaid = Add(discount3, tableInfo.no_discounts_fee_total)

        let info = {
            'count': 0,
            'name': "",
            'origin_price': "0",
            'real_price': "0",
            'channel': 0,
        }
        toBePaid = keepTwoNum(toBePaid);
        let params: any = {
            mp_food_orders_code: this.props.location.state.code,
            discount_id: item.id,
        }

        if (manual_fee !== '') {
            params.manual_fee = keepTwoNum(Minus(manual_fee, toBePaid))
        }

        if (tableInfo.group_key) {
            params.group_key = tableInfo.group_key;
        }

        let res: any = await postRequest(apiUrl.prepayDiscountSave, params)
        if (res.code === 1) {
            this.discount_back_key = res.data.discount_back_key;
            this.setState({
                platDiscount: item,
                fullVisible: false,
                toBePaid: toBePaid,
                thirdDiscount: info,
            })
            this.payTips(tableInfo.desk, toBePaid);
        } else {
            message.error(res.message)
        }
    }

    // 取消整单打折优惠
    cancelFull = async () => {
        let { tableInfo, manual_fee_sure } = this.state;
        let info = {
            'name': '',
            'discount': 0,
            'id': 0
        }
        let params: any = {
            discount_back_key: this.discount_back_key,
            discount_type: 2
        }

        if (manual_fee_sure !== '') {
            params.manual_fee = keepTwoNum(Minus(manual_fee_sure, tableInfo.price))
        } else {
            params.manual_fee = '0'
        }

        let toBePaid = tableInfo.price;
        if (manual_fee_sure !== '') {
            toBePaid = Number(manual_fee_sure);
        }

        let res: any = await postRequest(apiUrl.prepayDiscountDel, params)
        if (res.code === 1) {
            this.setState({
                platDiscount: info,
                toBePaid
            })
        } else {
            message.error(res.message)
        }

    }

    // 退菜-减少菜品
    reduceDish = (record: any, index: number) => {
        let backList: any = this.state.backList;
        let list: any = this.state.list;

        if (backList[index].newCount <= 1) {
            return;
        }
        if (record.desk_num) {
            if (this.backDesk == '') {
                this.backDesk = record.desk_num
            } else {
                if (this.backDesk !== record.desk_num) {
                    message.error('一次只能退同一桌的菜品');
                    return;
                }
            }
        }

        backList[index].newCount--;
        list.map((item: any, idx: number) => {
            if (item.id === backList[index].id) {
                item.newCount = backList[index].newCount
            }
        })

        this.setState({
            backList,
            list
        })
    }

    // 修改退菜数量
    addDish = (record: any, index: number) => {
        let backList: any = this.state.backList;
        let list: any = this.state.list;
        console.log(record);

        if (backList[index].newCount >= (backList[index].count - backList[index].order_dish_refund_num)) {
            return;
        }

        if (record.desk_num) {
            if (this.backDesk == '') {
                this.backDesk = record.desk_num
            } else {
                if (this.backDesk !== record.desk_num) {
                    message.error('一次只能退同一桌的菜品');
                    return;
                }
            }
        }

        backList[index].newCount++;
        list.map((item: any, idx: number) => {
            if (item.id === backList[index].id) {
                list[idx].newCount = backList[index].newCount
            }
        })

        this.setState({
            backList,
            list
        })
    }

    chargePayOrder = async () => {
        let {  charge_amount, present_amount, change_pay_type, valueTemp} = this.state;
        if(charge_amount == 0){
            message.warn('请输入充值金额')
            return;
        }
        let params:any = {
            vip_type: this.state.user_info.vip_type,
            phone_number:this.state.user_info.phone_number,
            nickname:this.state.user_info.nickname,
            charge_amount,
            present_amount
        }
        if(this.state.user_info.vip_type == 1){
            params.fans_id = this.state.user_info.id
        }

        if(change_pay_type !== ''){
            params.pay_type = change_pay_type;
        }
        if (change_pay_type === 'one') {
            params.auth_code = valueTemp;
        }
        console.log('params',params)
        let res:any = await postRequest(apiUrl.cashierUserCharge, params)
        .then((res:any) => {
            if(res.code == 1){
                message.success(res.message)
                // this.onInitList()
                this.onSearch(this.state.user_info.phone_number);
                if (res.sub_code && res.sub_code === 500) {
                    if (change_pay_type === 'one') {
                        message.error(res.message);
                        this.setState({
                            payStatus: 0,
                            valueTemp: '',
                            payVisible: false,
                            payChangeVisible:false,
                        })
                    }
                }else{
                    this.setState({
                        config_id: 0,
                        chargeShow: false,
                        valueTemp: '',
                        payVisible: false,
                        payChangeVisible:false,
                        payStatus: 0
                    })
                }
            }
        }).catch((e) => {
            message.success('支付超时，请重新扫码');
            this.setState({
                payStatus: 0,
                valueTemp: '',
                payChangeVisible:false,
            })
        })
    }

    // 人民币支付
    moneyPay = () => {
        Modal.confirm({
            title: '人民币支付',
            content: `确定已使用现金支付？`,
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                this.setState({
                    change_pay_type: 'money'
                }, () => {
                    this.chargePayOrder();
                })
            },
        });
    }

    // 银行卡支付
    bankPay = () => {
        Modal.confirm({
            title: '银行卡支付',
            content: `确定已使用银行卡支付？`,
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                this.setState({
                    change_pay_type: 'bank'
                }, () => {
                    this.chargePayOrder();
                })
            },
        });
    }

    inputChange = (e:any) => {
        let name = e.target.name;
        let value = e.target.value;
        this.setState({
            [name]: value,
        })
    }

    // 退菜
    changeOrder = async () => {
        let tableInfo: any = this.state.tableInfo;
        let { list } = this.state;
        let foods: any = [];
        let foods_set_meal: any = [];
        let code = tableInfo.code;

        let params: any = {};

        if (tableInfo.group_key) {
            let codes = this.props.history.location.state.codes.split(',');
            let desks = this.props.history.location.state.desks;
            desks.map((item: any, index: number) => {
                if (item === this.backDesk) {
                    code = codes[index];
                }
            })
            params.group_key = tableInfo.group_key;
        }

        params.code = code;

        list.forEach((item: any) => {
            if (item.select && item.newCount != 0) {
                if(!item.dish_unique_key) {
                    foods_set_meal.push(item.id)
                } else {
                    foods.push({
                        'dish_id': item.id,
                        'standard': item.standard,
                        'change': -item.newCount,
                        'dish_weigh_value_id': item.dish_weigh_value_id ? item.dish_weigh_value_id : 0,
                        'material_key': item.material_key ? item.material_key : null
                    })
                }

            }
        })
        if (foods.length <= 0 && foods_set_meal.length <= 0) {
            message.info('请选择菜品')
            return;
        }
        //退菜品
        if(foods.length > 0) {
            params.update = {
                'foods': foods
            }
            const res: any = await postRequest(apiUrl.foodOrderUpdate, params)
            if (res.code === 1) {
                message.success('操作成功')
                this.backDesk = '';
                this.setState({
                    backVisible: false
                })
                this.dishInfo()
            } else {
                message.error('退菜品失败')
            }
        }
        //退套餐
        if(foods_set_meal.length > 0) {
            params.set_meal_ids = JSON.stringify(foods_set_meal)
            const res: any = await postRequest(apiUrl.refundSetMeal, params)
            if (res.code === 1) {
                //message.success('退菜成功')
                this.backDesk = '';
                this.setState({
                    backVisible: false
                })
                this.dishInfo()
            } else {
                message.error('退套餐失败')
            }
        }
    }

    // 免单
    freeOrder = () => {
        Modal.confirm({
            title: '免单',
            content: `此单是否确定免单`,
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                this.payOrder(4);
            },
            onCancel() {
            },
        });
    }

    // 加菜
    addOrder = () => {
        this.props.history.push({
            pathname: '/sidebar/orderingFood',
            state: this.props.location.state
        });
    }

    // 支付方式改变
    payTypeChange = (index: number) => {
        if(!getIsPermisson('1_11_11_7')){
            messageCustom();
            return;
          }
        let { payList } = this.state;
        payList.forEach((item: any) => {
            item.active = false;
        })
        payList[index].active = true;
        this.setState({
            payList
        }, () => {
            if (payList[index].name === '人民币支付') {
                Modal.confirm({
                    title: '人民币支付',
                    content: `确定已使用现金支付？`,
                    okText: '确认',
                    cancelText: '取消',
                    onOk: async () => {
                        this.payOrder(2);
                    },
                    onCancel() {
                    },
                });
            } if (payList[index].name === '多种支付方式') {
                //多种支付方式，已支付列表
                this.onPayTrueListWithCode()
                this.setState({
                    morePay: true
                })
            } else if (payList[index].name === '微信/支付宝条码支付') {
                this.setState({
                    payVisible: true
                })
            }else if (payList[index].name === '余额支付'){
                this.orderFans()
            }
        })
    }

    // 就餐余额用户列表
    orderFans = async () => {
        let tableInfo: any = this.state.tableInfo;
        let params: any = {
            code: this.props.location.state.code,
        }
        if (tableInfo.group_key) {
            params.group_key = tableInfo.group_key;
        }
        if(this.discount_back_key){
            params.discount_back_key = this.discount_back_key;
        }
        let res:any = await getRequest(apiUrl.fansProperty, params)
        if(res.code == 1){
            // if(res.data && res.data.length <= 0){
            //     message.warn('请输入会员昵称/手机号/会员卡号')
            //     return;
            // }
            this.setState({
                userList: res.data,
                fansVisible: true
            })
        }
    }

    // 支付方式改变
    variedPayTypeChange = (index: number) => {
        let varied_pay_alipay_status: any = this.state.varied_pay_alipay_status;
        let varied_pay_wechat_status: any = this.state.varied_pay_wechat_status;
        let varied_pay_list: any = this.state.varied_pay_list;
        varied_pay_list.forEach((item: any) => {
            item.active = false;
        })
        varied_pay_list[index].active = true;
        this.setState({
            varied_pay_list
        }, () => {
            let input_fee_sure: any = this.state.input_fee_sure;
            //判断微信支付，是否支付过了
            if (varied_pay_list[index].name === '微信支付') {
                if (varied_pay_wechat_status) {
                    return false;
                }
            }
            //判断支付宝支付，是否支付过了
            if (varied_pay_list[index].name === '支付宝支付') {
                if (varied_pay_alipay_status) {
                    return false;
                }
            }
            if (input_fee_sure == '' || input_fee_sure == 0) {
                message.error('请输入支付金额！')
                return false;
            }
            if (varied_pay_list[index].name === '人民币支付') {
                Modal.confirm({
                    title: '人民币支付',
                    content: `确定已使用现金支付？`,
                    okText: '确认',
                    cancelText: '取消',
                    onOk: async () => {
                        this.varieadPayOrder('money');
                    },
                    onCancel() {
                    },
                });
                this.setState({
                    varied_pay: 'money'
                })
            } else if (varied_pay_list[index].name === '微信支付' || varied_pay_list[index].name === '支付宝支付') {
                this.setState({
                    payVariedVisibleInput: true
                }, () => {
                    this.focusTextInput();
                })

                if (varied_pay_list[index].name === '微信支付') {
                    if (varied_pay_wechat_status) {
                        return false;
                    }
                    this.setState({
                        varied_pay: 'wechat',
                    }, () => {
                        message.warn('等待用户微信扫码！')
                    })
                }
                if (varied_pay_list[index].name === '支付宝支付') {
                    if (varied_pay_alipay_status) {
                        return false;
                    }
                    this.setState({
                        varied_pay: 'alipay',
                    }, () => {
                        message.warn('等待用户支付宝扫码！')
                    })
                }
            } else {
                Modal.confirm({
                    title: '第三方支付',
                    content: `确定已使用第三方支付？`,
                    okText: '确认',
                    cancelText: '取消',
                    onOk: async () => {
                        this.setState({
                            varied_pay: varied_pay_list[index]['id']
                        }, () => {
                            this.varieadPayOrder('other', varied_pay_list[index]['id']);
                        })
                    },
                    onCancel() {
                    },
                });
            }
        })
    }

    vipChange = async () => {
        this.setState({
            chargeShow:true
        })
    }

    payOrder = async (type: number, pay_id?: number) => {
        // pay_type	int	10	支付方式 1-微信支付 2-人民币 3-支付宝 4-免单  6-余额	是	无

        let tableInfo: any = this.state.tableInfo;
        let { thirdDiscount, platDiscount, toBePaid, valueTemp, manual_fee_sure, no_discount_fee, user_info} = this.state;

        const params: any = {
            'mp_food_orders_code': tableInfo.code,
            'pay_fee_actual': toBePaid    //只跟优惠有关，跟调价无关
        };
        if (thirdDiscount.count > 0) {
            params.exchange_coupon_id = thirdDiscount.id;
            params.exchange_coupon_num = thirdDiscount.count;
        }
        if (platDiscount.name !== '') {
            params.discount_id = platDiscount.id;
        }

        if(type === 1 || type === 3){
            params.pay_type = 0;
        }else{
            params.pay_type = type;
        }

        if ((type === 1 || type === 3) && valueTemp !== '') {
            params.auth_code = valueTemp;
        }
        if (manual_fee_sure !== '') {
            params.manual_fee = keepTwoNum(Minus(manual_fee_sure, toBePaid))
        }
        if (tableInfo.group_key) {
            params.group_key = tableInfo.group_key;
        }
        if (type == 5) {
            params.pay_channel_id = pay_id;
            if(no_discount_fee){
                params.no_discount_fee = no_discount_fee;
            }
        }
        if(type === 6){
            if(this.state.fans_id != 0){
                params.fans_id = this.state.fans_id;
            }

            if(user_info && user_info.member_type == 2){
                params.phone_number = user_info.phone_number;
                params.member_type = user_info.member_type
            }
        }

        console.log('参数', params);
        // return;
        let res: any = await postRequest(apiUrl.orderPay, params)
            .then((res: any) => {
                if (res.code === 1) {
                    if (res.sub_code && res.sub_code === 500) {
                        if (type === 1 || type === 3) {
                            message.error(res.message);
                            this.setState({
                                payStatus: 0,
                                valueTemp: '',
                                payVisible: false,
                                fansVisible: false
                            })
                        }
                    } else {
                        if (type === 1 || type === 3) {
                            this.setState({
                                payStatus: 2
                            }, () => {
                                this.props.history.push('/sidebar/windowManage');
                            })
                        } else {
                            message.success('支付成功');
                            this.props.history.push('/sidebar/windowManage');
                        }
                    }
                }
            }).catch((e) => {
                message.success('支付超时，请重新扫码');
                this.setState({
                    payStatus: 0,
                    valueTemp: '',
                    payVisible: false,
                    fansVisible: false
                })
            });
    }

    // 输入框改变
    feeChange = (e: any) => {
        let val = e.target.value.trim();
        if (val.indexOf('.') > 0) {
            let str = val.split('.');
            if (str[1].length > 2) {
                let str1 = str[1].substring(0,2);
                val = str[0] + '.' + str1;
            }
        }
        this.setState({ manual_fee_show: val })
    }

    // 手动改价
    changeFee = (e: any) => {
        let manual_fee = this.state.manual_fee_show;
        console.log('改价', manual_fee.indexOf('.'));
        if (manual_fee.indexOf('.') > 0 && e === '.') {
            // manual_fee = manual_fee;
            return;
        }

        if (e === 10) {
            manual_fee = Add(manual_fee, 10).toString()
        } else if (e === 50) {
            manual_fee = Add(manual_fee, 50).toString()
        } else if (e === 100) {
            manual_fee = Add(manual_fee, 100).toString()
        } else if (e === 'c') {
            manual_fee = '';
        } else {
            if (manual_fee.indexOf('.') > 0) {
                let str = manual_fee.split('.');
                if (str[1].length === 2) {
                    return;
                }
                manual_fee = manual_fee + e + '';
            } else {
                manual_fee = manual_fee + e + '';
            }
        }
        this.setState({
            manual_fee_show: manual_fee
        })
    }

    // 确定支付
    surePay = async () => {
        let tableInfo: any = this.state.tableInfo;
        let thirdDiscount: any = this.state.thirdDiscount;
        let platDiscount: any = this.state.platDiscount;
        let { manual_fee_sure, toBePaid, manual_fee_show } = this.state;
        if (manual_fee_show !== '') {
            manual_fee_sure = keepTwoNum(manual_fee_show);
        }

        if (manual_fee_show !== '') {
            let params: any = {
                mp_food_orders_code: this.props.location.state.code,
                manual_fee: keepTwoNum(Minus(manual_fee_sure, toBePaid))
            }

            if (thirdDiscount.count !== 0) {
                params.exchange_coupon_id = thirdDiscount.id;
                params.exchange_coupon_num = thirdDiscount.count;
            }

            if (platDiscount.id !== 0 && platDiscount.name !== '') {
                params.discount_id = platDiscount.id;
            }

            if (tableInfo.group_key) {
                params.group_key = tableInfo.group_key;
            }

            let res: any = await postRequest(apiUrl.prepayDiscountSave, params)
            if (res.code === 1) {
                this.discount_back_key = res.data.discount_back_key;
                this.payTips(tableInfo.desk, manual_fee_sure);
                this.setState({
                    manual_fee_sure: manual_fee_sure
                })
            }
        } else {
            let params = {
                discount_back_key: this.discount_back_key,
                discount_type: 4,
                manual_fee: '0'
            }

            let res: any = await postRequest(apiUrl.prepayDiscountDel, params)
            if (res.code === 1) {
                this.payTips(tableInfo.desk, toBePaid);
                this.setState({
                    manual_fee_show: '',
                    manual_fee_sure: '',
                    manual_fee: '',
                    toBePaid: toBePaid
                })
            } else {
                message.error(res.message)
            }
        }
    }

    // 退菜选中
    rowSelection: any = {
        onSelect: (record: any, selected: any, selectedRows: any) => {
            let list: any = this.state.list;
            list.map((item: any) => {
                if (item.id === record.id) {
                    item.select = selected;
                }
            })
            this.setState({
                list
            })
        },
        onSelectAll: (selected: any, selectedRows: any, changeRows: any) => {
            let backPage = this.state.backPage;
            let list: any[] = this.state.list;
            let j = (backPage - 1) * 10;

            for (let i = j; i < 10; i++) {
                if (list[i]) {
                    list[i].select = selected;
                }
            }

            this.setState({
                list
            })
        },
    };

    // 参与打折选中
    discountRowSelection: any = {
        onChange: (selectedRowKeys: any, selectedRows: any) => {
            //console.log('selectedRowKeys-111:', Array.isArray(selectedRowKeys))
            this.setState({
                copy_discount_of_dishes_nongid: selectedRowKeys
            })
            console.log(`selectedRowKeys`, selectedRowKeys);
        },
    };

    // 退菜分页
    pageChange = (page: number) => {
        let newBackList = JSON.parse(JSON.stringify(this.state.list));
        newBackList = newBackList.splice((page - 1) * 10, 10);

        this.setState({
            backPage: page,
            backList: newBackList
        })
    }

    payTips = (tableName: string, money: any) => {
        console.log('0元结账弹窗', money);
        if (money > 0) {
            return;
        }
        Modal.confirm({
            title: '提示',
            content: `${tableName}桌，\n 账单金额为0，是否0元结账`,
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                this.payOrder(2);
            },
            onCancel() {
            },
        });
    }

    // 使用第三方支付
    useThirdPay = (item: any) => {
        if(!getIsPermisson('1_11_11_8')){
            messageCustom();
            return;
          }
        let price = this.state.tableInfo.price;

        if(item.channel_type == 'bank'){
            Modal.confirm({
                title: `${item.name}`,
                content: (
                    <div>确定已使用{item.name}？</div>
                ),
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    this.payOrder(5, item.id);
                },
                onCancel() {
                },
            });
        }else{
            let obj:any = item;
            if(item.discount_type == 1){
                let discount = keepTwoNum(item.discount / 100)
                if (item.discount != 0) {
                    price = keepTwoNum(Multiply(price, discount))
                }
                obj.toPayed = price;
            }else if(item.discount_type == 3){
                if(price >= parseFloat(item.full_money)){
                    let num = Math.floor(price / parseFloat(item.full_money));
                    if(item.reduce_num != 0 && num > parseFloat(item.reduce_num)){
                        obj.toPayed = keepTwoNum(Minus(price, Multiply(parseFloat(item.reduce_money), item.reduce_num)))
                    }else{
                        obj.toPayed = keepTwoNum(Minus(price, Multiply(parseFloat(item.reduce_money), num)))
                    }
                }else{
                    obj.toPayed = price;
                }
            }
            this.setState({
                thirdItem: obj,
                thirdVisible: true
            })
            // Modal.confirm({
            //     title: `${item.name}`,
            //     content: (
            //         <div>确定已使用{item.name}
            //             <div style={{ fontSize: '18px', color: '#000' }}>￥{price} ？</div>
            //             <div>使用{item.name}，只按添加支付方式全单打折计算,其他优惠将不计算</div></div>
            //     ),
            //     okText: '确认',
            //     cancelText: '取消',
            //     onOk: () => {
            //         // pay_channel_id
            //         this.payOrder(5, item.id);
            //     },
            //     onCancel() {
            //     },
            // });
        }
    }

    // 第三方支付，不可优惠金额
    thirdDiscount = (e:any) => {
        let thirdItem: any = this.state.thirdItem;
        let value = e.target.value;
        let tableInfo:any = this.state.tableInfo;
        if(parseFloat(value) > parseFloat(tableInfo.price)){
            return;
        }
        let price = tableInfo.price - e.target.value;
        thirdItem.toPayed = keepTwoNum(Add(Multiply(price,thirdItem.discount)/100, value));

        this.setState({
            no_discount_fee: value,
            thirdItem
        })
    }

    thirdPayBtn = () => {
        let thirdItem: any = this.state.thirdItem;
        this.payOrder(5, thirdItem.id);
    }

    //调价
    deleteZero = async (type: any, price='0') => {
        let { manual_fee_sure, toBePaid, manual_fee_show } = this.state;
        console.log('manual_fee_show', manual_fee_show, 'manual_fee_sure', manual_fee_sure, 'toBePaid', toBePaid)

        let tableInfo: any = this.state.tableInfo;
        let thirdDiscount: any = this.state.thirdDiscount;
        let platDiscount: any = this.state.platDiscount;
        // let { manual_fee_sure, toBePaid, manual_fee_show } = this.state;
        // if(manual_fee_show !== ''){
        //
        //manual_fee_show = String(toBePaid).split('.')[0] + '.00'
        //manual_fee_sure = keepTwoNum(manual_fee_show);
        // }
        // if(manual_fee_show !== ''){
        let params: any = {
            type: type,
            mp_food_orders_code: this.props.location.state.code,
        }
        if (type == 1) {
            let number: any = Number(manual_fee_sure !== '' ? manual_fee_sure : toBePaid);
            if(parseInt(String(number)) == number) {
                return false;
            }
            manual_fee_show = String(toBePaid).split('.')[0] + '.00'
            //manual_fee_sure = keepTwoNum(Minus(keepTwoNum(manual_fee_show), toBePaid))
            manual_fee_sure = '-0.' + String(number).split('.')[1];
        }
        if (type == 2) {

            if(manual_fee_sure != ''){
                console.log(parseFloat(manual_fee_sure) - parseFloat(manual_fee_show))
                if(parseFloat(manual_fee_sure) - parseFloat(manual_fee_show) < 0){
                    message.warning('支付金额不得小于0');
                    return;
                }
            }else{
                console.log(toBePaid - parseFloat(manual_fee_show))
                if(toBePaid - parseFloat(manual_fee_show) < 0){
                    message.warning('支付金额不得小于0');
                    return;
                }
            }
            manual_fee_sure = '-' + manual_fee_show;
        }
        if (type == 3) {
            manual_fee_sure = manual_fee_show;
        }
        if (type == 4) {
            manual_fee_sure = manual_fee_show;
            params.old_manual_fee_sure = price;
        }
        params.manual_fee = manual_fee_sure;
        if (thirdDiscount.count !== 0) {
            params.exchange_coupon_id = thirdDiscount.id;
            params.exchange_coupon_num = thirdDiscount.count;
        }

        if (platDiscount.id !== 0 && platDiscount.name !== '') {
            params.discount_id = platDiscount.id;
        }

        if (tableInfo.group_key) {
            params.group_key = tableInfo.group_key;
        }

        let res: any = await postRequest(apiUrl.prepayDiscountSave, params)
        if (res.code === 1) {
            this.onGetManualFeeLogList();
            this.discount_back_key = res.data.discount_back_key;
            //this.payTips(tableInfo.desk, manual_fee_sure);
            this.dishInfo()
            this.setState({
                //manual_fee_sure: res.data.pay_fee_actual,
                manual_fee_show: '',
            })
        }
    }

    //调价列表
    onGetManualFeeLogList = async () => {
        let tableInfo: any = this.state.tableInfo;
        let params: any = {
            code: this.props.location.state.code,
        }
        if (tableInfo.group_key) {
            params.group_key = tableInfo.group_key;
        }
        console.log('apiUrl.getManualFeeLogList:', apiUrl.manualFeeLogList)
        let res: any = await getRequest(apiUrl.manualFeeLogList, params)
        if (res.code == 1) {
            let result: any = res.data;
            this.setState({
                manual_fee_log_list: result.slice(0,3),
                manual_fee_log_list_result: res.data
            })

        } else {
            message.error(res.message)
        }

    }

    //取消调价
    onDelManualFeeLogList = async (item: any) => {
        if(!getIsPermisson('1_11_11_13')){
            messageCustom();
            return;
          }
        let tableInfo: any = this.state.tableInfo;
        let params: any = {
            code: this.props.location.state.code,
            discount_back_key: this.discount_back_key,
            id: item.id,
        }
        if (tableInfo.group_key) {
            params.group_key = tableInfo.group_key;
        }
        let res: any = await postRequest(apiUrl.delManualFeeLog, params)
        if (res.code == 1) {
            this.onGetManualFeeLogList()

            this.setState({
                //manual_fee_sure: '',
            }, () => {
                //this.dishInfo()
            })
            this.dishInfo()
            // if(item.type == 2) {
            //     let manual_fee_sure: any = this.state.manual_fee_sure;
            //     let { toBePaid, manual_fee_show } = this.state;
            //     console.log('manual_fee_show',manual_fee_show, 'manual_fee_sure',manual_fee_sure, 'toBePaid',toBePaid)
            //     this.setState({
            //         manual_fee_sure: Number(manual_fee_sure) + Number(Math.abs(item.manual_fee)) + '.00'
            //     })
            // } else {
            //     let manual_fee_sure: any = this.state.manual_fee_sure;
            //     this.setState({
            //         manual_fee_sure: Number(manual_fee_sure) - Number(Math.abs(item.manual_fee)) + '.00'
            //     })
            // }
        } else {
            message.error(res.message)
        }

    }

    //多种支付--下单
    varieadPayOrder = async (type: any, pay_id?: number) => {

        let tableInfo: any = this.state.tableInfo;
        let { thirdDiscount, platDiscount, toBePaid, valueTemp, manual_fee_sure, input_fee_sure } = this.state;

        const params: any = {
            'mp_food_orders_code': this.props.location.state.code,
            'pay_type': type,
            total_amount: manual_fee_sure !== '' ? manual_fee_sure : toBePaid,
            pay_amount: input_fee_sure,
        };

        if (type == 'other') {
            params.pay_channel_id = pay_id

        } else if (type == 'money') {

        } else {
            params.auth_code = valueTemp
        }

        if (tableInfo.group_key) {
            params.group_key = tableInfo.group_key;
        }

        if (this.discount_back_key) {
            params.discount_back_key = this.discount_back_key
        }


        console.log('参数', params);
        // return;
        let res: any = await postRequest(apiUrl.mixDiversePay, params)
            .then((res: any) => {
                if (res.code === 1) {
                    if (res.sub_code && res.sub_code === 500) {
                        if (type === 'wechat' || type === 'alipay') {
                            message.error(res.message);
                            this.setState({
                                payStatus: 0,
                                valueTemp: '',
                            })
                        }
                    } else {
                        if (type === 'wechat' || type === 'alipay') {
                            this.setState({
                                payStatus: 2,
                                payVariedVisibleInput: false,
                                valueTemp: ''
                            })
                        } else {
                            message.success('支付成功');
                        }
                        //支付记录
                        if (res.data.detail) {
                            this.setState({
                                own_varied_pay_list: res.data.detail
                            })
                        }
                        //是否可以完成结账
                        if (res.data.remain_amount == 0) {
                            this.setState({
                                varied_pay_ok: true
                            })
                        }
                        //记录使用支付方式
                        if (type === 'alipay') {
                            this.setState({
                                varied_pay_alipay_status: true
                            })
                        }
                        if (type === 'wechat') {
                            this.setState({
                                varied_pay_wechat_status: true
                            })
                        }
                        this.setState({
                            varied_pay_tobepaid: res.data.remain_amount,
                            varied_pay_tobepaid_index: 1,
                            payVariedVisibleInput: false,
                            input_fee_sure: ''
                        })
                    }
                }
            }).catch((e) => {
                if (type === 'wechat' || type === 'alipay') {
                    message.error('支付超时，请重新扫码');
                }
                this.setState({
                    payStatus: 0,
                    valueTemp: '',
                })
            });


    }

    //多种支付方式-撤销单个支付
    ownVariedPay = async (item: any) => {
        let param: any = {
            pay_type: item.type,
            mp_food_orders_code: this.props.location.state.code
        }
        if (item.type == 'other') {
            param.pay_channel_id = item.pay_channel_id
        }
        let res: any = await postRequest(apiUrl.refundMixDiverse, param)
        if (res.code == 1) {
            let own_varied_pay_list: any = this.state.own_varied_pay_list;
            own_varied_pay_list.map((varitem: any, index: any) => {
                if (varitem.type == item.type) {
                    own_varied_pay_list.splice(index, 1)
                }
            })
            //恢复宝支付
            if (item.type == 'alipay') {
                this.setState({
                    varied_pay_alipay_status: false
                })
            }
            //恢复微信
            if (item.type == 'wechat') {
                this.setState({
                    varied_pay_wechat_status: false
                })
            }
            this.setState({
                own_varied_pay_list,
                varied_pay_tobepaid: Number(this.state.varied_pay_tobepaid) + Number(item.pay_amount)
            }, () => {
                message.success(res.message)
                //撤销成功，调用多种支付，刷新页面
                this.onPayTrueListWithCode()
            })
        } else {
            message.error(res.message)
        }
    }

    //获取多种支付页面-渠道支付方式列表
    onPayChannelListMultiple = async () => {
        let res: any = await getRequest(apiUrl.payChannelListMultiple)
        if (res.code === 1) {
            let name = [
                {
                    'pay_type': 1,
                    'active': true,
                    'name': '微信支付',
                    'color': '#1BD600',
                    type: 'wechat'
                },
                {
                    'pay_type': 3,
                    'active': false,
                    'name': '支付宝支付',
                    'color': '#1D76FF',
                    type: 'alipay'
                },
                {
                    'pay_type': 2,
                    'active': false,
                    'name': '人民币支付',
                    'color': '#FF4A61',
                    type: 'money'
                }
            ]
            let obj = res.data;
            obj.map((item: any, index: any) => {
                if (item['name'].substring(0, 2) == '美团') {
                    obj[index]['color'] = '#ffa14a';
                    obj[index]['type'] = 'other';
                } else {
                    obj[index]['color'] = '#3384ff';
                    obj[index]['type'] = 'other';
                }
            })
            name.push(...obj);
            this.setState({
                varied_pay_list: name
            })
        }
    }

    //多种支付-取消
    onCancelVariedPay = async () => {
        if (this.state.own_varied_pay_list.length == 0) {
            let params: any = {
                mp_food_orders_code: this.props.location.state.code
            }
            let tableInfo: any = this.state.tableInfo;
            if (this.discount_back_key) {
                params.discount_back_key = this.discount_back_key
            }
            if (tableInfo.group_key) {
                params.group_key = tableInfo.group_key;
            }
            let res: any = await postRequest(apiUrl.cancelMixDiverse, params)
            if(res.code == 1) {
                this.setState({ morePay: false, payStatus: 0 })
            } else {
                message.error(res.message)
            }
        } else {
            message.error('订单支付中，请删除已支付记录后取消!')
        }
    }

    //多种支付-完成结账
    onVariedPayOk = async () => {
        if (this.state.varied_pay_ok) {
            let param: any = {
                mp_food_orders_code: this.props.location.state.code
            }
            let tableInfo: any = this.state.tableInfo;
            if (this.discount_back_key) {
                param.discount_back_key = this.discount_back_key
            }
            if (tableInfo.group_key) {
                param.group_key = tableInfo.group_key;
            }
            let res: any = await postRequest(apiUrl.payBillComplete, param)
            if (res.code == 1) {
                this.setState({
                    varied_pay_ok: true
                }, () => {
                    message.success(res.message)
                    this.props.history.push('/sidebar/windowManage');
                })
            } else {
                message.error(res.message)
            }
        }
    }

    //多种支付-已支付列表
    onPayTrueListWithCode = async () => {
        let manual_fee_sure: any = this.state.manual_fee_sure;
        let toBePaid: any = this.state.toBePaid;
        let tableInfo: any = this.state.tableInfo;
        let param: any = {
            mp_food_orders_code: this.props.location.state.code,
            total_amount: manual_fee_sure !== '' ? manual_fee_sure : toBePaid,
        }
        if (this.discount_back_key) {
            param.discount_back_key = this.discount_back_key
        }
        if (tableInfo.group_key) {
            param.group_key = tableInfo.group_key;
        }
        let res: any = await postRequest(apiUrl.payTrueListWithCode, param)
        if (res.code == 1) {
            let pay_list = res.data.detail;
            pay_list.map((item: any, index: any) => {
                //恢复宝支付
                if (item.type == 'alipay') {
                    this.setState({
                        varied_pay_alipay_status: true
                    })
                }
                //恢复微信
                if (item.type == 'wechat') {
                    this.setState({
                        varied_pay_wechat_status: true
                    })
                }
            })
            this.setState({
                own_varied_pay_list: res.data.detail,
                varied_pay_tobepaid: res.data.remain_amount
            })
        } else {
            //message.error(res.message)
        }
    }

    //多种支付-输入价格
    onChangeVarieadPrice = (e: any) => {
        let val = e.target.value;
        if (val.indexOf('.') > 0) {
            let str = val.split('.');
            if (str[1].length > 2) {
                let str1 = str[1].substring(0,2);
                val = str[0] + '.' + str1;
            }
        }

        this.setState({
            input_fee_sure: val
        })
    }

    //已点菜修改
    onChangeOrderEdit = (AIndex: number) => {
        console.log('AIndex:', AIndex)
        let yetOrderPage: any = this.state.yetOrderPage;
        if (yetOrderPage[AIndex]['content']) {
            return false;
        }
        //退菜不能点开，限制。
        // if (yetOrderPage[AIndex]['order_dish_refund_num'] && yetOrderPage[AIndex]['order_dish_refund_num'] > 0) {
        //     return false;
        // }
        yetOrderPage.map((itemA: any, indexA: any) => {
            yetOrderPage[indexA]['edit'] = false
        })
        yetOrderPage[AIndex].edit = true;
        console.log('yetOrderPage:', yetOrderPage)
        this.setState({
            yetOrderPage
        })
    }

    //弹窗-打折
    orderReloadPrintAction = (item: any) => {
        if (item.discount_id != 0) {
            let list: any = this.state.orderNotesDefault;
            list.map((val: any, index: any) => {
                if (val.id == item.discount_id) {
                    list[index]['active'] = true;
                }
            })
            this.setState({
                reloadPrintActionSatatus: true,
                reloadPrintActionName: item.name,
                orderNotesDefault: list
            })
        } else {
            this.setState({
                reloadPrintActionSatatus: true,
                reloadPrintActionName: item.name
            })
        }
    }

    //打折-默认列表
    onChangeOrderNote = (val: any) => {
        let list: any = this.state.orderNotesDefault;
        list.map((item: any, index: any) => {
            if (item.name == val.name) {
                if (item.active) {
                    list[index]['active'] = false;
                } else {
                    list[index]['active'] = true;
                }
            } else {
                list[index]['active'] = false;
            }
        })
        this.setState({
            orderNotesDefault: list
        })
    }

    // 参与打折
    discountChangeOrder = async (val: any) => {
        let tableInfo: any = this.state.tableInfo;
        let copy_discount_of_dishes: any = this.state.copy_discount_of_dishes_nongid;
        let list: any = this.state.list;
        let foods: any = [];

        let params: any = {
            code: this.props.location.state.code,
            type: '1',
        }

        console.log(list)
        list.map((item: any, index: any) => {
            copy_discount_of_dishes.map((sub_item: any, sub_index: any) => {
                if (item.dish_unique_key == sub_item) {
                    if (val == 2) {
                        let _obj = {
                            id: list[index]['id'],
                            standard_id: list[index]['standard_id'],
                            discount: false,
                            dish_weigh_value_id: list[index].dish_weigh_value_id ? list[index].dish_weigh_value_id : 0,
                            material_key: list[index].material_key ? list[index].material_key : null
                        }
                        foods.push(_obj);
                    } else {
                        let _obj = {
                            id: list[index]['id'],
                            standard_id: list[index]['standard_id'],
                            discount: true,
                            dish_weigh_value_id: list[index].dish_weigh_value_id ? list[index].dish_weigh_value_id : 0,
                            material_key: list[index].material_key ? list[index].material_key : null
                        }
                        foods.push(_obj);
                    }
                }
            })
        })

        if (tableInfo.group_key) {
            params.group_key = tableInfo.group_key;
        }

        if (copy_discount_of_dishes.length <= 0) {
            message.info('请选择菜品！')
            return;
        } else {
            params.dishes = JSON.stringify(foods)
        }
        const res: any = await postRequest(apiUrl.discountByCode, params)
        if (res.code === 1) {
            message.success('操作成功！')
            this.backDesk = '';
            this.setState({
                backVisible: false
            })
            this.dishInfo()
        }
    }

    //确认-打折
    onOrderNotesSubmit = async () => {
        let tableInfo: any = this.state.tableInfo;
        let list: any = this.state.orderNotesDefault;
        let yetOrderPage: any = this.state.yetOrderPage;
        let own_select: any = {};

        let params: any = {
            code: this.props.location.state.code,
            type: '2',
        }

        let submit_data: any = {};
        //当前选中的折扣信息
        list.map((item: any, index: any) => {
            if (item.active) {
                submit_data = item
            }
        })

        //查找当前选中的菜品信息
        yetOrderPage.map((item: any, index: any) => {
            if (item.edit) {
                own_select = item;
            }
        })

        let dishes: any = {
            id: own_select.id,
            standard_id: own_select.standard_id,
            price: own_select.price,
            discount_id: submit_data.id || 0,
            dish_weigh_value_id: own_select.dish_weigh_value_id ? own_select.dish_weigh_value_id : 0,
            material_key: own_select.material_key ? own_select.material_key : null
        };


        params.dishes = JSON.stringify(dishes);


        if (tableInfo.group_key) {
            params.group_key = tableInfo.group_key;
        }

        let res: any = await postRequest(apiUrl.discountByCode, params)
        if (res.code == 1) {
            list.map((val: any, index: any) => {
                list[index]['active'] = false;
            })
            this.setState({
                reloadPrintActionSatatus: false,
                orderNotesDefault: list
            })
            this.dishInfo();
            message.success('操作成功')
        } else {
            message.error(res.message)
        }

    }

    //取消-打折
    onOrderNotesCancel = () => {
        let list: any = this.state.orderNotesDefault;
        list.map((val: any, index: any) => {
            list[index]['active'] = false;
        })
        this.setState({
            reloadPrintActionSatatus: false,
            orderNotesDefault: list
        })
    }

    //调价翻页
    manual_fee_log_page = (type: any) => {
        let { manual_fee_log_start_page, manual_fee_log_end_page } = this.state;
        let manual_fee_log_list_result: any = this.state.manual_fee_log_list_result;

        if(type == 'up') {
            if(manual_fee_log_start_page == 1) {
                return false;
            }
            let start_index = Number(manual_fee_log_start_page - 1);
            let res_list = this.pagination(start_index,manual_fee_log_end_page,manual_fee_log_list_result)
            //实时调整向下按钮样式
            if(res_list.length < 3) {
                this.setState({
                    manual_fee_log_is_end: true
                })
            } else {
                this.setState({
                    manual_fee_log_is_end: false
                })
            }
            this.setState({
                manual_fee_log_list: res_list,
                manual_fee_log_start_page: --manual_fee_log_start_page
            })

        }  else {
            let start_index = Number(manual_fee_log_start_page + 1);
            let res_list = this.pagination(start_index,manual_fee_log_end_page,manual_fee_log_list_result)
            if(res_list.length == 0) {
                return false;
            }
            //实时调整向下按钮样式
            if(res_list.length < 3) {
                this.setState({
                    manual_fee_log_is_end: true
                })
            } else {
                this.setState({
                    manual_fee_log_is_end: false
                })
            }
            this.setState({
                manual_fee_log_list: res_list,
                manual_fee_log_start_page: ++manual_fee_log_start_page
            })
        }
    }

    pagination = (pageNo: any, pageSize: any, array: any) => {
        let offset = (pageNo - 1) * pageSize;
        return (offset + pageSize >= array.length) ? array.slice(offset, array.length) : array.slice(offset, offset + pageSize);
    }

    // 退菜
    returnDishes = (itemA: any) => {
        let tableInfo: any = this.state.tableInfo;
        let obj = {
            title: '退菜',
            visible: true,
            onOk: async (re: any) => {
                if (re) {
                    if(re > itemA.count - itemA.order_dish_refund_num){
                        message.warning(`退菜数量不能大于${itemA.count - itemA.order_dish_refund_num}` )
                        return;
                    }

                    let params: any = {
                        code: this.props.location.state.code,
                        type: '2',
                    }
                    let foods = [];
                    foods.push({
                        'dish_id': itemA.id,
                        'standard': itemA.standard,
                        'change': -(parseFloat(re)),
                        'dish_weigh_value_id': itemA.dish_weigh_value_id ? itemA.dish_weigh_value_id : 0,
                        'material_key': itemA.material_key ? itemA.material_key : null
                    })
                    params.update = {
                        'foods': foods
                    };
                    if (tableInfo.group_key) {
                        params.group_key = tableInfo.group_key;
                    }
                    const res: any = await postRequest(apiUrl.foodOrderUpdate, params)
                    if(res.code === 1){
                        message.success('退菜成功')
                        this.dishInfo();
                    }else{
                        message.warning(res.message)
                    }
                }
                this.setState({
                    editNumber:{visible: false}
                })
            },
            onCancel: () => {
                this.setState({
                    editNumber:{visible: false}
                })
            },
        }
        this.setState({
            editNumber: obj
        })
    }

    //整单打折
    onFullDiscount = () => {
        let manual_fee_log_list = this.state.manual_fee_log_list;
        if(manual_fee_log_list.length >= 1) {
            message.warn('调价后不能使用优惠，请清除调价后再操作！')
        } else {
            this.setState({ fullVisible: true })
        }
    }

    //搜索会员
    onSearch = async (e: any) => {
        let params = {
            key_words: e,
        }
        if(!e) {
            this.setState({
                user_info_status: false,
            })
            return false
        }
        let res: any = await getRequest(apiUrl.fansSearchWithPhone, params)
        if(res && res.code == 1) {
            if(JSON.stringify(res.data) != '[]') {
                this.setState({
                    user_info: res.data,
                    user_info_status: true,
                })
            } else {
                this.setState({
                    user_info_status: false,
                }, () => {
                    message.error('未搜索到用户！')
                })
            }
        } else {
            message.error(res.message)
        }
    }

    onChangeSearch = (e: any) => {
        if(!e.target.value) {
            this.setState({
                key_words: e.target.value,
                user_info_status: false,
                user_info: {
                    "id": 0,//用户id
                    "nickname": "",//用户昵称
                    "headimgurl": "",//用户头像
                    "member_level": "",//会员等级
                    "member_score": 0,//会员积分
                    "balance": "0.00",//用户余额
                    "eat_times": 0,//就餐次数
                    "comsumer_times": 0,//消费次数（买单次数）
                    "actual_fee": "0",//累计消费金额
                    "create_time": "0",//注册时间
                    "last_comsumer_time": "0",//最后一次消费时间
                    "phone_number": '', //手机号
                    'vip_num': '无',  //会员类型
                    'sex': '未知',
                    'member_type': 0
                }
            })
            return false
        }
    }

    // 设置或取消会员价
    setCancelVip = async (action: string) => {
        let user_info = this.state.user_info;
        if(action === 'set') {
            let manual_fee_log_list = this.state.manual_fee_log_list;
            let platDiscount = this.state.platDiscount;
            let thirdDiscount = this.state.thirdDiscount;
            console.log('thirdDiscount:',thirdDiscount)
            if(manual_fee_log_list.length >= 1) {
                message.warn('调价后不能享受菜品会员价，请清除调价后再操作！')
                return false;
            }
            if(platDiscount.discount != 0) {
                message.warn('整单打折后不能享受菜品会员价，请清除整单打折后再操作！')
                return false;
            }
            if(thirdDiscount.count != 0) {
                message.warn('第三方优惠后不能享受菜品会员价，请清除第三方优惠后再操作！')
                return false;
            }
        }
        let yetOrderPage:any = this.state.yetOrderPage;
        console.log(yetOrderPage);
        if(action === 'set' && yetOrderPage[0].is_vip_dish){
            message.warning('已享受会员价')
            return;
        }
        let tableInfo :any = this.state.tableInfo;
        let params:any = {
            code: this.props.location.state.code,
            type: action === 'set' ? 1 : 2
        }
        if(user_info.phone_number){
            params.phone_number = user_info.phone_number
        }
        if(tableInfo.group_key){
            params.group_key = tableInfo.group_key;
        }
        let res:any = await postRequest(apiUrl.setOrCancelVip, params)
        if(res.code == 1){
            this.dishInfo();
            message.success(res.message)
        }else{
            message.warning(res.message)
        }
    }

    getRealPrice = (item:any) => {
        let price:any;
        price = item.material_price ? keepTwoNum(Add(item.material_price, item.price)) : item.price
        return price;
    }

    getRealPriceVip = (item:any) => {
        let price:any;
        price = item.material_price ? keepTwoNum(Add(item.material_price, item.price_vip)) : item.price_vip
        return price;
    }

    // 搜索用户余额支付
    property = () => {
        if(!getIsPermisson('1_11_11_7')){
            messageCustom();
            return;
          }
        let { manual_fee_sure, toBePaid, user_info } = this.state;
        let price = manual_fee_sure !== '' ? manual_fee_sure : toBePaid
        if(price > parseFloat(user_info.balance)){
            message.warn('用户余额不足')
            return;
        }
        // 弹出个你
        Modal.confirm({
            title: `余额支付`,
            content: (
                <div>是否使用余额支付{price}？</div>
            ),
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                this.setState({
                    fans_id: user_info.id
                }, () => {
                    this.payOrder(6)
                })
            },
            onCancel() {
            },
        });
    }

    render(){
        const {
            thirdDiscount,
            tableInfo,
            payList,
            couponList,
            platList,
            fullVisible,
            backVisible,
            couponVisible,
            payVisible,
            list,
            discountArr,
            toBePaid,
            platDiscount,
            payStatus,
            valueTemp,
            manual_fee_sure,
            manual_fee_show,
            presentFoods,
            canUseCoupon,
            backList,
            backPage,
            clickCouponNum,
            inputCouponCount,
            thirdPay,
            thirdShow,
            varied_pay_list,
            payVariedVisible,
            input_fee_sure,
            own_varied_pay_list,
            varied_pay_ok,
            varied_pay_tobepaid,
            varied_pay_wechat_status,
            varied_pay_alipay_status,
            manual_fee_log_list,
            manual_fee_log_list_result,
            reloadPrintActionSatatus,
            reloadPrintActionName,
            orderNotesDefault,
            editNumber,
            discount_of_dishes,
            copy_discount_of_dishes,
            copy_discount_of_dishes_nongid,
            manual_fee_log_start_page,
            manual_fee_log_is_end,
            payVariedVisibleInput,
            user_info,
            user_info_status,
            thirdVisible
        } = this.state;
        let thirdItem:any = this.state.thirdItem;
        return <div className='settleAccounts_main'>
            <NumChangeDialog data={editNumber} />
            <Row className="settleAccounts_main_left">
                <div className="settleAccounts_main_left_tip">
                    <span className="settleAccounts_main_left_tip_back" onClick={() => { this.props.history.goBack(); }}>
                        &lt; 返回
                    </span>
                    <span className="fontSize18">餐桌号：{tableInfo.desk}</span>
                    <span className="fontSize18">用餐人数：{tableInfo.user_amount}</span>
                    <span className="fontSize18">单号：{tableInfo.code}</span>
                </div>
                <div className="settleAccounts_main_left_dish">
                    <div>
                        <div className="content flex">
                            <div className="" key='tabB' style={{flex: '1 1'}}>
                                <div className="tableHeader settleAccounts_main_left_dish_tabheader">
                                    <div className="tableA textCenter">菜品</div>
                                    <div className="tableB textCenter">售价</div>
                                    <div className="tableB textCenter">会员价</div>
                                    <div className="tableL textCenter">数量</div>
                                    <div className="tableD textCenter">退菜</div>
                                    <div className="tableD textCenter">金额</div>
                                </div>
                                {
                                    this.state.yetOrderPage
                                    ?    this.state.yetOrderPage.map((itemA: any, indexA: number) => {
                                            return (
                                                <div onClick={() => {this.onChangeOrderEdit(indexA)}} key={`B${indexA}`} style={{ backgroundColor: itemA.edit ? 'rgba(255, 227, 227, 1)' : 'white' }}>
                                                    <div className="tableBody cursorPointer fontSize14">
                                                        <div className="tableA settleAccountsTabelGroup" style={{paddingLeft: '30px'}}>
                                                            <div>
                                                                <div style={{position:'relative', display:'inline-block'}}>
                                                                    {itemA.name}
                                                                    {itemA.material_name ? <span>  ({itemA.material_name})</span> : null }
                                                                    {
                                                                        itemA.dish_discount_name != ''
                                                                        ?   <span className="fontSize14" style={{marginLeft: '10px', color: '#D9001B', position: 'absolute'}}>{itemA.dish_discount_name}</span>
                                                                        :   ''
                                                                    }
                                                                    {
                                                                        itemA.order_dish_refund_num && itemA.order_dish_refund_num > 0
                                                                        ? <span className={`${itemA.dish_discount_name != '' ? 'settleAccount_dish_back settleAccount_more_tag' : 'settleAccount_dish_back'}`}>退</span>
                                                                        : null
                                                                    }
                                                                </div>
                                                            </div>

                                                            {
                                                                itemA.desk_num != ''
                                                                ?   <div className="settleAccountsTabelGroup_desk_num">{itemA.desk_num}</div>
                                                                :   ''
                                                            }
                                                        </div>

                                                        {
                                                            itemA.is_vip_dish
                                                            ? <div className="tableB textCenter settleAccountsTabelGroup" style={{textDecoration: 'line-through'}}>{this.getRealPrice(itemA)}</div>
                                                            : <div className="tableB textCenter settleAccountsTabelGroup">{this.getRealPrice(itemA)}</div>
                                                        }
                                                        <div className="tableB textCenter settleAccountsTabelGroup">{itemA.is_vip_dish ? this.getRealPriceVip(itemA) : '--'}</div>
                                                        <div className="tableL textCenter settleAccountsTabelGroup">x {itemA.count}</div>
                                                        <div className="tableD textCenter settleAccountsTabelGroup">{itemA.order_dish_refund_num}</div>
                                                        <div className="tableD textCenter settleAccountsTabelGroup">￥{keepTwoNum(Multiply((Number(itemA.count) - Number(itemA.order_dish_refund_num)), itemA.is_vip_dish ? this.getRealPriceVip(itemA) : this.getRealPrice(itemA)))}</div>
                                                    </div>
                                                    {
                                                        itemA.edit ?
                                                            <div className="tableEdit fontSize14">
                                                                <div onClick={() => {
                                                                    if(!getIsPermisson('1_11_11_1')){
                                                                        messageCustom();
                                                                        return;
                                                                        }
                                                                    this.returnDishes(itemA)
                                                                }}>退菜</div>
                                                                <div className="tableEdit_line"></div>
                                                                <div onClick={() => {
                                                                    if(!getIsPermisson('1_11_11_2')){
                                                                        messageCustom();
                                                                        return;
                                                                        }
                                                                    this.orderReloadPrintAction(itemA)
                                                                }}>打折</div>
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                            )
                                    })
                                    : null
                                }
                            </div>
                        </div>
                        {
                            presentFoods.length > 0
                            ?   <div className='settleAccounts_main_left_dish_send_title'>赠送菜品</div>
                            : ''
                        }

                        {
                            presentFoods.length > 0
                            ?   <div className="content flex">
                                    <div className="" key='tabB' style={{flex: '1 1'}}>
                                        <div className="tableHeader settleAccounts_main_left_dish_tabheader">
                                            <div className="tableA textCenter">菜品</div>
                                            <div className="tableB textCenter">售价</div>
                                            <div className="tableB textCenter">会员价</div>
                                            <div className="tableL textCenter">数量</div>
                                            <div className="tableD textCenter">退菜</div>
                                            <div className="tableD textCenter">金额</div>
                                        </div>
                                            {
                                                presentFoods.map((itemA: any, indexA: number) => {
                                                        return (
                                                            <div onClick={() => {this.onChangeOrderEdit(indexA)}} key={`B${indexA}`} style={{ backgroundColor: itemA.edit ? 'rgba(255, 227, 227, 1)' : 'white' }}>
                                                                <div className="tableBody cursorPointer fontSize14">
                                                                    <div className="tableA textCenter settleAccountsTabelGroup">
                                                                        <div>{itemA.name}
                                                                            {
                                                                                itemA.dish_discount_name != ''
                                                                                ?   <span style={{marginLeft: '10px', color: '#D9001B', position: 'absolute'}}>{itemA.dish_discount_name}</span>
                                                                                :   ''
                                                                            }
                                                                            {
                                                                                itemA.order_dish_refund_num && itemA.order_dish_refund_num > 0
                                                                                ? <span className={`${itemA.dish_discount_name != '' ? 'settleAccount_dish_back settleAccount_more_tag' : 'settleAccount_dish_back'}`}>退</span>
                                                                                : null
                                                                            }
                                                                        </div>
                                                                        {itemA.material_name ? <span>{itemA.material_name}</span> : null }

                                                                        {
                                                                            itemA.desk_num != ''
                                                                            ?   <span className={`settleAccountsTabelsong_desk_num`}>{itemA.desk_num}</span>
                                                                            :   ''
                                                                        }
                                                                    </div>
                                                                    {
                                                                        itemA.is_vip_dish
                                                                        ? <div className="tableB textCenter settleAccountsTabelGroup" style={{textDecoration: 'line-through'}}>{this.getRealPrice(itemA)}</div>
                                                                        : <div className="tableB textCenter settleAccountsTabelGroup">{this.getRealPrice(itemA)}</div>
                                                                    }
                                                                    <div className="tableB textCenter settleAccountsTabelGroup">{itemA.is_vip_dish ? this.getRealPriceVip(itemA) : '--'}</div>
                                                                    <div className="tableL textCenter settleAccountsTabelGroup">x {itemA.count}</div>
                                                                    <div className="tableD textCenter settleAccountsTabelGroup">{itemA.order_dish_refund_num || 0}</div>
                                                                    <div className="tableD textCenter settleAccountsTabelGroup">￥{keepTwoNum(Multiply(itemA.count, itemA.is_vip_dish ? this.getRealPriceVip(itemA) : this.getRealPrice(itemA)))}</div>
                                                                </div>
                                                            </div>

                                                        )
                                                })
                                        }
                                    </div>
                                </div>
                            : ''
                        }

                         {
                            tableInfo && tableInfo.zhuli_dish && tableInfo.zhuli_dish.name
                            ? <div className='settleAccounts_main_left_dish_send_title'>助力菜品</div>
                            : ''
                        }

                        {
                            tableInfo.zhuli_dish && tableInfo.zhuli_dish.name
                            ?
                            <div className="content flex">
                                    <div className="" key='tabB' style={{flex: '1 1'}}>
                                        <div className="tableHeader settleAccounts_main_left_dish_tabheader">
                                            <div className="tableA textCenter">菜品</div>
                                            <div className="tableB textCenter">售价</div>
                                            <div className="tableB textCenter">会员价</div>
                                            <div className="tableL textCenter">数量</div>
                                            <div className="tableD textCenter">退菜</div>
                                            <div className="tableD textCenter">金额</div>
                                        </div>
                                        <div>
                                            <div className="tableBody fontSize14" style={{background:'#fff'}}>
                                                <div className="tableA textCenter settleAccountsTabelGroup">
                                                    <div>{tableInfo.zhuli_dish.name}
                                                        <span className='settleAccount_dish_back settleAccount_more_tag'>助</span>
                                                    </div>
                                                </div>
                                                {
                                                    tableInfo.zhuli_dish.is_vip_dish
                                                    ? <div className="tableB textCenter settleAccountsTabelGroup" style={{textDecoration: 'line-through'}}>{tableInfo.zhuli_dish.price}</div>
                                                    : <div className="tableB textCenter settleAccountsTabelGroup">{tableInfo.zhuli_dish.price}</div>
                                                }
                                                <div className="tableB textCenter settleAccountsTabelGroup">{tableInfo.zhuli_dish.is_vip_dish ? tableInfo.zhuli_dish.price_vip : '--'}</div>
                                                <div className="tableL textCenter settleAccountsTabelGroup">x {tableInfo.zhuli_dish.count}</div>
                                                <div className="tableD textCenter settleAccountsTabelGroup">0</div>
                                                <div className="tableD textCenter settleAccountsTabelGroup">￥0.00</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            : ''
                        }
                    </div>
                </div>
            </Row>
            <Row className="settleAccounts_main_right">
                <Row className="settleAccounts_main_right_top">
                    <Col>
                        <div className='pay_price'><div>待支付：</div>{manual_fee_sure !== '' ? manual_fee_sure : toBePaid}</div>
                    </Col>
                </Row>
                <Row className="settleAccounts_main_right_bottom">
                    <div className="settleAccounts_main_right_bottom_left">
                        <div>
                            {
                                manual_fee_log_list.map((item: any, index: any) => {
                                    return (
                                        <div key={index} className="settleAccounts_main_right_bottom_left_reload">
                                            <div className="settleAccounts_main_right_bottom_left_reload_item">
                                                <div>{item.desc}</div>
                                                <div className="settleAccounts_main_right_bottom_left_reload_item_right">
                                                    <div>{item.manual_fee}</div>
                                                    <Icon type="delete" className="settleAccounts_main_right_bottom_left_reload_item_right_icon" onClick={() => { this.onDelManualFeeLogList(item) }} />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })

                            }
                            {
                                manual_fee_log_list_result.length > 3
                                    ? <div className="settleAccounts_main_right_bottom_left_btn">
                                        <div onClick={() => {this.manual_fee_log_page('up')}} className={`${manual_fee_log_start_page == 1 ? 'settleAccounts_main_right_bottom_left_btn_up settleAccount_no_click' : 'settleAccounts_main_right_bottom_left_btn_up'}`}>向上</div>
                                        <div onClick={() => {this.manual_fee_log_page('down')}} className={`${manual_fee_log_is_end ? 'settleAccount_no_click' : ''}`}>向下</div>
                                    </div>
                                    : ''
                            }
                        </div>
                        <div className='digit_wrap' style={{ backgroundColor: '#ffffff' }}>
                            <div className='input-text bgColorPink'>
                                <input type="number" onChange={this.feeChange} value={manual_fee_show} />
                                {/* <Input onChange={this.feeChange} value={manual_fee_show} type='number' style={{border:'none',background:'transparent'}} /> */}
                            </div>
                            <div className='flex digit_col'>
                                <div className='diagit_item' onClick={() => this.changeFee(7)}>7</div>
                                <div className='diagit_item' onClick={() => this.changeFee(8)}>8</div>
                                <div className='diagit_item' onClick={() => this.changeFee(9)}>9</div>
                                <div className='diagit_item settleAccounts_main_fontW' onClick={() => {
                                     if(!getIsPermisson('1_11_11_3')){
                                        messageCustom();
                                        return;
                                      }
                                    this.deleteZero(1)
                                }}>抹零</div>
                            </div>
                            <div className='flex digit_col'>
                                <div className='diagit_item' onClick={() => this.changeFee(4)}>4</div>
                                <div className='diagit_item' onClick={() => this.changeFee(5)}>5</div>
                                <div className='diagit_item' onClick={() => this.changeFee(6)}>6</div>
                                <div className='diagit_item settleAccounts_main_fontW' onClick={() => {
                                    if(!getIsPermisson('1_11_11_4')){
                                        messageCustom();
                                        return;
                                      }
                                    this.deleteZero(2)
                                    }}>减价</div>
                            </div>
                            <div className='flex digit_col'>
                                <div className='diagit_item' onClick={() => this.changeFee(1)}>1</div>
                                <div className='diagit_item' onClick={() => this.changeFee(2)}>2</div>
                                <div className='diagit_item' onClick={() => this.changeFee(3)}>3</div>
                                <div className='diagit_item settleAccounts_main_fontW' onClick={() => {
                                    if(!getIsPermisson('1_11_11_5')){
                                        messageCustom();
                                        return;
                                      }
                                    this.deleteZero(3)
                                    }}>加价</div>
                            </div>
                            <div className='flex digit_col'>
                                <div className='diagit_item' onClick={() => this.changeFee('0')}>0</div>
                                <div className='diagit_item' onClick={() => this.changeFee('.')}>.</div>
                                <div className='diagit_item settleAccounts_main_fontW' onClick={() => this.changeFee('c')}>清除</div>
                                <div className='diagit_item settleAccounts_main_fontW' onClick={() => {
                                     if(!getIsPermisson('1_11_11_6')){
                                        messageCustom();
                                        return;
                                      }
                                    this.deleteZero(4, String(manual_fee_sure !== '' ? manual_fee_sure : toBePaid))
                                    }}>改价</div>
                            </div>
                        </div>
                    </div>
                    <div className="settleAccounts_main_right_bottom_right">
                        <div className="settleAccounts_main_right_bottom_right_searchinp">
                            <Search placeholder="请输入会员昵称/手机号/会员卡号" onSearch={this.onSearch} onChange={this.onChangeSearch} style={{ width: '100%' }} allowClear />
                            {
                                user_info && user_info.phone_number && !user_info_status
                                ?  <div className='expand_btn' onClick={() => {this.setState({user_info_status: true})}}><img src={arrow_down} /></div>
                                : null
                            }
                            {
                                user_info_status
                                ?   <div className='settleAccounts_main_right_bottom_right_searchinp_main'>
                                        <div>
                                            <span>会员昵称：</span><span className='settleAccounts_main_right_bottom_right_searchinp_main_img'><img src={user_info.headimgurl} style={{width: '16px', borderRadius: '50%'}} alt=""/>{user_info.nickname}</span>
                                        </div>
                                        <div>
                                            <span>会员卡号：</span><span>{user_info.vip_num}</span>
                                        </div>
                                        <div>
                                            <span>会员手机号：</span><span>{user_info.phone_number}</span>
                                        </div>
                                        <div>
                                            <span>性别：</span><span>{user_info.sex}</span>
                                        </div>
                                        <div>
                                            <span>会员等级：</span><span>{user_info.member_level}</span>
                                        </div>
                                        <div>
                                            <span>会员类型：</span><span>{user_info.member_type_desc}</span>
                                        </div>
                                        <div>
                                            <span>会员积分：</span><span>{user_info.member_score}</span>
                                        </div>
                                        <div>
                                            <span>会员余额：</span><span>{user_info.balance}</span>
                                        </div>
                                        <div>
                                            <span>就餐次数：</span><span>{user_info.eat_times}</span>
                                        </div>
                                        <div>
                                            <span>买单次数：</span><span>{user_info.comsumer_times}</span>
                                        </div>
                                        <div>
                                            <span>累计消费金额：</span><span>{user_info.actual_fee}</span>
                                        </div>
                                        <div>
                                            <span>最后一次消费时间：</span><span>{user_info.last_comsumer_time}</span>
                                        </div>
                                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <div className='set_vip' onClick={() => this.setCancelVip('set')}>享受菜品会员价</div>
                                            <div className='set_vip' onClick={() => this.setCancelVip('cancel')}>取消菜品会员价</div>
                                            <div className='set_vip charge' onClick={() => this.property()}>余额支付</div>
                                            <div className='set_vip charge' onClick={() => this.vipChange()}>会员充值</div>
                                            
                                        </div>
                                        <div className='fold_btn' onClick={() => {this.setState({user_info_status: false})}}>
                                            <img src={arrow_up} />
                                        </div>
                                    </div>
                                :   ''
                            }
                        </div>
                        <div>
                            <div className='settleAccounts_main_right_bottom_right_tip'>选择支付方式&操作</div>
                            <div className='settleAccounts_main_right_bottom_right_pay'>
                                {
                                    payList.map((item: any, index: number) => {
                                        return <div className={`settleAccounts_main_right_bottom_right_pay_new_item ${item.pay_type === 1 ? 'bgColor' : ''}`} key={item.name} onClick={() => this.payTypeChange(index)}>{item.name}</div>
                                    })
                                }
                            </div>
                            <div className='settleAccounts_main_right_bottom_right_third'>
                                <div className='third_title' onClick={() => { this.setState({ thirdShow: !thirdShow }) }}>
                                    <span>其它渠道支付</span>
                                    <Icon style={{ color: '#000', width: '30px', fontSize: '22px'}} type="caret-down" />
                                </div>
                                {
                                    thirdShow
                                        ? <div className='settleAccounts_main_right_bottom_right_third_main'>
                                            {
                                                thirdPay.map((item: any, index: number) => {
                                                    return <div className='settleAccounts_main_right_bottom_right_third_main_item' style={{ backgroundColor: `${item.name.substring(0, 2) == '口碑' ? '#3384FF' : '#FFB33D'}` }}><div className='' key={item.name} onClick={() => this.useThirdPay(item)}>{item.name}</div></div>
                                                })
                                            }
                                        </div>
                                        : null
                                }
                            </div>
                            {
                                couponList.map((item: any) => {
                                    return <div className='bgColorOrange pay_label' key={item.id} onClick={() => this.userThirdDiscount(item)}>{item.name}</div>
                                })
                            }
                        </div>
                    </div>
                </Row>
            </Row>

            <Row style={{ position: 'fixed', bottom: 0, zIndex: 2 }} className="width_info">

                <div className="settleAccounts_main_left_info">
                    <div className='settleAccounts_main_left_info_price'>
                        <div className='settleAccounts_main_left_info_price_left'>
                            <div className='settleAccounts_main_left_info_price_left_subtotal'>
                                <div className=''>应收金额：{tableInfo.price}</div>
                            </div>
                            <div className='settleAccounts_main_left_info_price_left_coupon'>
                                <div className='' style={{ marginRight: '10px' }}>可用券：{tableInfo.discounts_fee_total} </div>
                                <div className=''> 不可用券：{tableInfo.no_discounts_fee_total}</div>
                            </div>
                            {/* 第三方渠道优惠 */}
                            {
                                thirdDiscount.count !== 0
                                    ? <div className='settleAccounts_main_left_info_price_left_discount'>
                                        <div className='' style={{ marginRight: '40px' }}>{thirdDiscount.name}: X{thirdDiscount.count}</div>
                                        <div className='' style={{ display: 'flex', alignItems: 'center' }}>
                                            <div>{Number(thirdDiscount.origin_price) * thirdDiscount.count}</div>
                                            <Icon type="delete" style={{ fontSize: 20, color: 'red', marginLeft: 10 }} onClick={this.cancelCoupon} />
                                        </div>
                                    </div>
                                    : null
                            }
                            {/* 整单打折优惠 */}
                            {
                                platDiscount.name !== ''
                                    ? <div className='settleAccounts_main_left_info_price_left_discount'>
                                        <div className='' style={{ marginRight: '40px' }}>折扣：{platDiscount.name}</div>
                                        <div className='' style={{ display: 'flex', alignItems: 'center' }}>
                                            <div>{keepTwoNum(Multiply(tableInfo.discounts_fee_total, keepTwoNum(Minus(1, platDiscount.discount))))}</div>
                                            <Icon type="delete" style={{ fontSize: 20, color: 'red', marginLeft: 10 }} onClick={this.cancelFull} />
                                        </div>
                                    </div>
                                    : null
                            }
                            <div className='settleAccounts_main_left_info_price_left_amountReceivable'>
                                <div className=''>待支付：</div>
                                <div className=''>{manual_fee_sure !== '' ? manual_fee_sure : toBePaid}</div>
                            </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div className='settleAccounts_main_left_info_price_right' onClick={() => {
                                if(!getIsPermisson('1_11_11_12')){
                                    messageCustom();
                                    return;
                                  }
                                this.preorderForm()
                            }}>预结单</div>
                            <div className='settleAccount_border settleAccounts_main_left_info_price_right' onClick={() => {
                                if(!getIsPermisson('1_11_11_2')){
                                    messageCustom();
                                    return;
                                  }
                                this.onFullDiscount()
                                }}>整单打折</div>
                            <div className='settleAccount_border settleAccounts_main_left_info_price_right' onClick={() => {
                                 if(!getIsPermisson('1_11_11_1')){
                                    messageCustom();
                                    return;
                                  }
                                this.setState({ backVisible: true })
                                }}>退 菜</div>
                            <div className='settleAccount_border settleAccounts_main_left_info_price_right' onClick={this.addOrder}>加 菜</div>
                            <div className='settleAccount_border settleAccounts_main_left_info_price_right' onClick={() => {
                                if(!getIsPermisson('1_11_11_10')){
                                    messageCustom();
                                    return;
                                  }
                                this.freeOrder()
                                }}>免 单</div>
                            <div className='settleAccount_border settleAccounts_main_left_info_price_right width190' onClick={() => {
                                 if(!getIsPermisson('1_11_11_11')){
                                    messageCustom();
                                    return;
                                  }
                                this.setState({ discount_of_dishes: true })
                                }}>菜品参与折扣</div>
                        </div>
                    </div>
                </div>

            </Row>

            {/* 菜品参与打折 */}
            <Modal
                visible={discount_of_dishes}
                title='菜品参与打折'
                footer={null}
                width="70vw"
                onCancel={() => {
                    this.setState({
                        copy_discount_of_dishes_nongid: [],
                        discount_of_dishes: false
                    })
                }}
            >
                <div className=''>
                    <Table dataSource={copy_discount_of_dishes}
                        size="middle"
                        columns={this.columnBackDiscount}
                        rowKey={"dish_unique_key"}
                        locale={{ emptyText: '暂无数据' }}
                        rowSelection={{
                            ...this.discountRowSelection,
                            copy_discount_of_dishes_nongid: copy_discount_of_dishes_nongid
                        }}
                        pagination={{
                            current: backPage,
                            total: list.length,
                            pageSize: 10,
                            hideOnSinglePage: true,
                            onChange: this.pageChange,
                            size: 'default'
                        }} />
                </div>

                <div className='cancel_wrap' style={{ marginTop: list.length <= 10 ? 50 : 0 }}>
                    <div className="sure_btn" onClick={() => { this.discountChangeOrder(2) }}>批量打折</div>
                    <div className="sure_btn" style={{ background: 'transparent', color: '#ff5a69', border: '1px solid #ff5a69' }} onClick={() => { this.discountChangeOrder(1) }}>取消批量打折</div>
                </div>
            </Modal>

            {/* 打折弹窗 */}
            <Modal
                visible={fullVisible}
                title='整单打折'
                closable={false}
                footer={null}
                width="70vw"
            >
                <div className='full_disount'>
                    {
                        platList.length > 0
                            ? platList.map((item: any) => {
                                return <div key={item.id} className='plat_coupon' onClick={() => this.fullDiscount(item)}>{item.name}</div>
                            })
                            : <div className='no_coupon'>暂无优惠</div>
                    }
                </div>

                <Button onClick={() => { this.props.history.push({pathname: '/sidebar/discountCoupon',state: {showBack: true}}) }} type='primary'>编辑打折信息</Button>

                <div className='cancel_wrap mt50'>
                    <div className="cancel_btn" onClick={() => { this.setState({ fullVisible: false }) }}>取消</div>
                </div>
            </Modal>

            {/* 退菜弹窗 */}
            <Modal
                visible={backVisible}
                title='退菜'
                closable={false}
                footer={null}
                width="70vw"
            >
                <div className=''>
                    <Table dataSource={backList}
                        size="middle"
                        columns={this.columnBack}
                        rowKey={"dish_unique_key"}
                        locale={{ emptyText: '暂无数据' }}
                        rowSelection={this.rowSelection}
                        pagination={{
                            current: backPage,
                            total: list.length,
                            pageSize: 10,
                            hideOnSinglePage: true,
                            onChange: this.pageChange,
                            size: 'default'
                        }} />
                </div>

                <div className='cancel_wrap' style={{ marginTop: list.length <= 10 ? 50 : 0 }}>
                    <div className="cancel_btn" onClick={() => { this.setState({ backVisible: false }) }}>取消</div>
                    <div className="sure_btn" onClick={this.changeOrder}>确定</div>
                </div>
            </Modal>

            {/* 券使用张数 */}
            <Modal
                visible={couponVisible}
                title='选择使用张数'
                closable={false}
                footer={null}
                width="70vw"
            >
                <div className='num_wrap'>
                    {
                        canUseCoupon > 0
                            ? discountArr.map((item, index) => {
                                return <div className={`${(index + 1) === clickCouponNum ? 'active' : ''} num_item`} onClick={() => this.couponCount(item)} key={item}>{item}</div>
                            })
                            : '暂无可用优惠'
                    }
                </div>

                <div className='coupon_input'>
                    <span>使用更多张：</span>
                    <Input type='number' onChange={this.useCouponNum} min='0' value={inputCouponCount}></Input>
                </div>

                <div className='cancel_wrap mt50'>
                    <div className="cancel_btn" onClick={() => { this.setState({ couponVisible: false, clickCouponNum: 0, inputCouponCount: '' }) }}>取消</div>
                    <div className="sure_btn" onClick={this.sureCouponCount}>确定</div>
                </div>
            </Modal>

            <Modal
                title='会员充值'
                visible={this.state.chargeShow}
                footer={null}
                onCancel={() => {this.setState({chargeShow: false, config_id: 0, charge_amount: 0, present_amount: 0, phone_number: '',name: ''})}}>
                    <Row className="mb30" type='flex' align='middle'>
                        <Col span={4}>
                            <div>
                                <span className="star"></span>
                                <span className="color_black">手机号</span>
                            </div>
                        </Col>
                        <Col span={20}>
                            <Input name='phone_number' disabled={true} value={user_info.phone_number} />
                        </Col>
                    </Row>

                    <Row className="mb30" type='flex' align='middle'>
                        <Col span={4}>
                            <div>
                                <span className="color_black">用户昵称</span>
                            </div>
                        </Col>
                        <Col span={20}>
                            <Input name='name' value={user_info.nickname} />
                        </Col>
                    </Row>

                    <Row className="mb30" type='flex' align='middle'>
                        <Col span={4}>
                            <div>
                                <span className="star"></span>
                                <span className="color_black">充值金额</span>
                            </div>
                        </Col>
                        <Col span={20}>
                            <Input name='charge_amount' value={this.state.charge_amount}  onChange={(e) => this.inputChange(e)} />
                        </Col>
                    </Row>

                    <Row className="mb30" type='flex' align='middle'>
                        <Col span={4}>
                            <div>
                                <span className="color_black">赠送金额</span>
                            </div>
                        </Col>
                        <Col span={20}>
                            <Input name='present_amount' value='' />
                        </Col>
                    </Row>
                    
                    <div className='pay_con mb30'>
                        <div className='' onClick={() => {this.setState({payChangeVisible: true, chargeShow: false})}}>微信/支付宝条码支付</div>
                        <div className='' onClick={() => this.bankPay()}>银行卡支付</div>
                        <div onClick={() => this.moneyPay()}>人民币支付</div>
                    </div>
                    <div style={{color: 'red', fontSize: '14px'}}>收银台会员储值暂不支持退款，请仔细确认后再操作，谢谢！</div>
                </Modal>

            {/* 支付状态 */}
            <Modal
                visible={this.state.payChangeVisible}
                title='充值扫码支付'
                closable={false}
                footer={null}
                // width="70vw"
            >
                <div style={{ textAlign: 'center' }}>
                    <div className='pay_number'>支付： {this.state.charge_amount}</div>
                    {
                        this.state.payChangeVisible
                            ? <Input style={{ width: '200px', margin: '30px auto' }}
                                autoFocus={true}
                                value={valueTemp}
                                readOnly
                                placeholder='等待用户扫码'
                                onBlur={() => { console.log('失去焦点'); }}
                                onFocus={() => { console.log('获取焦点'); }} />
                            : null
                    }

                    {
                        payStatus === 1
                            ? <div className='pay_status success_status'>付款中...</div>
                            : payStatus === 2
                                ? <div className='pay_status success_status'>付款成功</div>
                                : payStatus === 3
                                    ? <div className='pay_status fail_status'>付款失败，请重新扫码</div>
                                    : null
                    }
                </div>

                {
                    payStatus === 0
                        ? <div className='cancel_wrap mt50'>
                            <div className="cancel_btn" onClick={() => { this.setState({ payChangeVisible: false }) }}>取消</div>
                        </div>
                        : null
                }

            </Modal>

            {/* 支付状态 */}
            <Modal
                visible={payVisible}
                title='扫码支付'
                closable={false}
                footer={null}
                width="70vw"
            >
                <div style={{ textAlign: 'center' }}>
                    <div className='pay_number'>支付： {manual_fee_sure !== '' ? manual_fee_sure : toBePaid}</div>
                    {
                        payVisible
                            ? <Input style={{ width: '200px', margin: '30px auto' }}
                                autoFocus={true}
                                value={valueTemp}
                                readOnly
                                placeholder='等待用户扫码'
                                onBlur={() => { console.log('失去焦点'); }}
                                onFocus={() => { console.log('获取焦点'); }} />
                            : null
                    }

                    {
                        payStatus === 1
                            ? <div className='pay_status success_status'>付款中...</div>
                            : payStatus === 2
                                ? <div className='pay_status success_status'>付款成功</div>
                                : payStatus === 3
                                    ? <div className='pay_status fail_status'>付款失败，请重新扫码</div>
                                    : null
                    }
                </div>

                {
                    payStatus === 0
                        ? <div className='cancel_wrap mt50'>
                            <div className="cancel_btn" onClick={() => { this.setState({ payVisible: false }) }}>取消</div>
                        </div>
                        : null
                }

            </Modal>

            {/* 多种支付状态 */}
            <Modal
                visible={payVariedVisible}
                title='扫码支付'
                closable={false}
                footer={null}
                width="70vw"
            >
                <div style={{ textAlign: 'center' }}>
                    <div className='pay_number'>支付： {input_fee_sure}</div>
                    {
                        payVariedVisible
                            ? <Input style={{ width: '200px', margin: '30px auto' }}
                                autoFocus={true}
                                value={valueTemp}
                                readOnly
                                placeholder='等待用户扫码'
                                onBlur={() => { console.log('失去焦点'); }}
                                onFocus={() => { console.log('获取焦点'); }} />
                            : null
                    }

                    {
                        payStatus === 1
                            ? <div className='pay_status success_status'>付款中...</div>
                            : payStatus === 2
                                ? <div className='pay_status success_status'>付款成功</div>
                                : payStatus === 3
                                    ? <div className='pay_status fail_status'>付款失败，请重新扫码</div>
                                    : null
                    }
                </div>

                {
                    payStatus === 0
                        ? <div className='cancel_wrap mt50'>
                            <div className="cancel_btn" onClick={() => { this.setState({ payVariedVisible: false, payStatus: 0 }) }}>取消</div>
                        </div>
                        : null
                }

            </Modal>

            {/* 多种支付方式 */}
            <Modal
                visible={this.state.morePay}
                title='多种支付方式'
                width="694px"
                closable={false}
                footer={null}
            >
                <div>
                    <div className="settleAccounts_main_varied">
                        <div className="settleAccounts_main_varied_left">
                            <div className="settleAccounts_main_varied_left_price">
                                <div className="settleAccounts_main_varied_left_price_txt">输入支付金额</div>
                                <div className="settleAccounts_main_varied_left_price_inp"><Input onChange={(e: any) => { this.onChangeVarieadPrice(e) }} type="number" value={input_fee_sure} placeholder="请输入支付金额" /></div>
                            </div>
                            <div className='settleAccounts_main_right_bottom_right_pay' style={{ marginBottom: '37px' }}>
                                {
                                    varied_pay_list.map((item: any, index: number) => {
                                        return (
                                            item.type == 'alipay'
                                                ? <div className="settleAccounts_main_right_bottom_right_pay_item" style={{ backgroundColor: `${varied_pay_alipay_status ? '#ccc' : item.color}` }} key={item.name} onClick={() => this.variedPayTypeChange(index)}>{item.name}</div>
                                                : item.type == 'wechat'
                                                    ? <div className="settleAccounts_main_right_bottom_right_pay_item" style={{ backgroundColor: `${varied_pay_wechat_status ? '#ccc' : item.color}` }} key={item.name} onClick={() => this.variedPayTypeChange(index)}>{item.name}</div>
                                                    : <div className="settleAccounts_main_right_bottom_right_pay_item" style={{ backgroundColor: item.color }} key={item.name} onClick={() => this.variedPayTypeChange(index)}>{item.name}</div>
                                        )
                                    })
                                }
                            </div>
                            <div className="settleAccounts_main_varied_left_allprice">
                                {
                                    input_fee_sure
                                    ?   `支付：${input_fee_sure}`
                                    :   ''
                                }
                                {/* 支付：{
                                    varied_pay_tobepaid_index == 0
                                        ? manual_fee_sure !== '' ? manual_fee_sure : toBePaid
                                        : varied_pay_tobepaid
                                } */}
                            </div>
                            <div className="settleAccounts_main_varied_left_paying">
                                {
                                    payVariedVisibleInput
                                    ?   <Input style={{ width: '200px' }}
                                        ref={this.setTextInputRef}
                                        value={valueTemp}
                                        readOnly
                                        placeholder='等待用户扫码'
                                        onBlur={() => { console.log('失去焦点'); }}
                                        onFocus={() => { console.log('获取焦点'); }} />
                                    : ''
                                }
                            </div>
                        </div>
                        <div className="settleAccounts_main_varied_right">
                            <div className="settleAccounts_main_varied_right_tobepaid">
                                订单待支付 ：{
                                    varied_pay_tobepaid
                                }
                            </div>
                            <div className="settleAccounts_main_varied_right_main">
                                {
                                    own_varied_pay_list.map((item: any, index: any) => {
                                        return (
                                            <div key={index} className="settleAccounts_main_varied_right_main_item">
                                                <div>{item.name}</div>
                                                <div className="settleAccounts_main_varied_right_main_item_price">
                                                    <div>{item.pay_amount}</div>
                                                    <Icon type="delete" style={{ fontSize: 17, color: 'red', marginLeft: 10 }} onClick={() => { this.ownVariedPay(item) }} />
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className='cancel_wrap' style={{ marginTop: '80px' }}>
                        <div className="cancel_btn" onClick={() => { this.onCancelVariedPay() }}>取消</div>
                        {
                            varied_pay_ok
                                ? <div className="sure_btn" onClick={this.onVariedPayOk}>完成结账</div>
                                : <div className="sure_btn" style={{ background: '#ccc', cursor: 'no-drop' }}>完成结账</div>
                        }
                    </div>
                </div>
            </Modal>
            <Modal
                visible={reloadPrintActionSatatus}
                title={`${reloadPrintActionName}-打折`}
                width="694px"
                onOk={() => { this.onOrderNotesSubmit() }}
                onCancel={() => { this.onOrderNotesCancel() }}
            >
                <div className="orderingFood_notes_btn">
                    {
                        orderNotesDefault.map((item: any, index: any) => {
                            return (
                                <div onClick={() => { this.onChangeOrderNote(item) }} className={`${item.active ? 'orderingFood_notes_btn_active' : 'orderingFood_notes_btn_item'}`}>{item.name}</div>
                            )
                        })
                    }
                </div>

            </Modal>

            <Modal
                visible={this.state.fansVisible}
                title={`余额支付`}
                width="860px"
                onOk={() => {
                    if(this.state.userList.length > 0){
                        if(this.state.fans_id !== 0){
                            this.payOrder(6)
                        }else{
                            message.warn('请选择某一个用户')
                        }
                    }else{
                        this.setState({ fansVisible: false })
                    }}}
                onCancel={() => { this.setState({ fansVisible: false }) }}
            >
                <div className="orderingFood_notes_btn">
                    <div className='user_wrap'>
                        {
                            this.state.userList.length > 0?
                            this.state.userList.map((item:any, index: any ) => {
                                return <div className={`user_item ${item.is_pay ? '' : 'user_disable'}`} onClick={() => {
                                    let list:any = this.state.userList;
                                    if(!list[index].is_pay){
                                        message.warn('该用户余额不足')
                                        return;
                                    }
                                    list.map((user:any) => {
                                        user.select = false
                                    })
                                    list[index].select = true;
                                    this.setState({
                                        userList: list,
                                        fans_id: list[index].id
                                    })
                                }}>
                                        {
                                            item.select
                                            ? <img src={user_check} className='select_btn' />
                                            : null
                                        }
                                        <div className='user_info'>
                                            <span className='user_l'>用户昵称：</span>
                                            <span className='user_r'>{item.nickname}</span>
                                        </div>
                                        <div className='user_info'>
                                            <span className='user_l'>手机号：</span>
                                            <span className='user_r'>{item.phone_number}</span>
                                        </div>
                                        <div className='user_info'>
                                            <span className='user_l'>余额：</span>
                                            <span className='user_r'>{item.property}</span>
                                        </div>
                                        <div className='user_info'>
                                            <span className='user_l'>预计剩余余额：</span>
                                            <span className='user_r'>{item.over_property}</span>
                                        </div>
                                    </div>
                            })
                            : <div style={{textAlign:'center', width:'100%'}}>该订单暂无系统会员点餐或余额不足，如需使用余额支付，请搜索用户昵称/手机号/会员卡号</div>
                        }

                    </div>
                </div>
            </Modal>

            <Modal visible={thirdVisible}
                title={`${thirdItem.title}`}
                width="487px"
                onOk={() => this.thirdPayBtn()}
                onCancel={() => {this.setState({thirdVisible: false, thirdItem:{},no_discount_fee:0 }) }}
            >
                <div className='item_disabled'>消费总金额：￥{tableInfo.price}</div>
                {
                    thirdItem.discount_type == 3
                    ? <div className='item_disabled'>优惠类型：每满{thirdItem.full_money}减{thirdItem.reduce_money}</div>
                    :   thirdItem.discount_type == 1 ? <div className='item_input'>
                            <div className='item_input_l'>不可享受优惠金额</div>
                            <Input placeholder='输入不参与优惠的金额' className='item_input_r' name='no_discount_fee' value={this.state.no_discount_fee} onChange={(e) => this.thirdDiscount(e)}></Input>
                        </div> : null
                }
                <div className='item_disabled'>美团实收：￥{thirdItem.toPayed}</div>
            </Modal>
        </div>;
    }
}
export default SettleAccounts