import { Row, Button, Table, message, Tag } from "antd"
import React, { useEffect, useState } from "react"
import VoucherFilterRow from "./components/voucher_filter"
import { useHistory } from "react-router-dom"
import { getRequest, postRequest } from "../../api/http"
import { apiUrl } from "../../api/api_url"
import { getIsPermisson, messageCustom } from "../../utils/permisstionUtil"

type ItableData = {
  total: number,
  list: Array<any>
}

const VouchersList = () => {

  const history = useHistory();
  const [page, setpage] = useState(1);
  const [loadingData, setloadingData] = useState(true);

  useEffect(() => {
    initList();
  }, []);

  const [tableData, settableData] = useState<ItableData>({
    total: 0,
    list: []
  })

  const initList = async (parms = {}) => {
    setloadingData(true);
    const data = {
      type: 'cash_coupon',
      current: page,
      pageSize: 15,
      ...parms
    }
    const res: any = await getRequest(apiUrl.couponList, data);
    if(res && res.data) {
      settableData(res.data);
    }
    setloadingData(false);
  }

  const searchList = (key: any) => {
    const _key = JSON.parse(JSON.stringify(key));
    Object.keys(_key).map((el) => {
      if(_key[el] === "") delete _key[el];
    })
    initList({ page: 1, ..._key });
  }

  // 修改抵扣券状态
  const changeVoucherStatus = async (id: string, status: number) => {
    if(!getIsPermisson('1_12_2_3')){
      messageCustom();
      return;
    }
    const pmars = {
      id: id,
      status: status,
    }
    const res: any = await postRequest(apiUrl.changeVoucherStatus, pmars);
    if(res && res.code === 1) {
      console.log('res', res);
      message.success("操作成功");
      initList({ page });
    } else {
      message.error(res.message);
    }
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '优惠名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '有效期',
      dataIndex: 'expire_text',
      key: 'expire_text',
    },{
      title: '状态',
      // dataIndex: 'status_text',
      key: 'status_text',
      render: (rowdata: any) => {
        if(rowdata) {
          return <Tag color={rowdata.status === 2 ? 'green' : 'red'}>{rowdata.status_text}</Tag>;
        }
        return <Row></Row>;
      }
    },{
      title: '发行数量',
      dataIndex: 'amount',
      key: 'amount',
    },{
      title: '已领取',
      dataIndex: 'receive_amount',
      key: 'receive_amount',
    },{
      title: '剩余',
      dataIndex: 'stock',
      key: 'stock',
    },{
      title: '已使用',
      dataIndex: 'used_count',
      key: 'used_count',
    },{
      title: '创建时间',
      dataIndex: 'created_at',
      key: 'created_at',
    },{
      title: "操作",
      key: "action",
      render: (rowdata: any) => {
        return <Row>
          <Button type="link" onClick={() => {
            if(!getIsPermisson('1_12_2_2')){
              messageCustom();
              return;
            }
            history.push({pathname: "/sidebar/add_vouchers", state: { type: 'edit', id: rowdata.id }})
          }}>编辑</Button>
          { rowdata.status === 0 &&  <Button type="link" onClick={() => changeVoucherStatus(rowdata.id, 2)}>启用</Button> }
          { rowdata.status === 2 &&  <Button type="link" onClick={() => changeVoucherStatus(rowdata.id, 0)}>停用</Button> }
        </Row>
      }
    }
  ];


  return <Row className='l_content'>
    <Row type="flex">
      <Button type="primary" style={{marginRight: 20}} onClick={() => {
         if(!getIsPermisson('1_12_2_1')){
          messageCustom();
          return;
        }
        history.push('/sidebar/add_vouchers')
        }}>添加代金券</Button>
      <VoucherFilterRow search={(key) => searchList(key)} />
    </Row>

    <Table size="small" loading={loadingData} rowKey={'id'} columns={columns} dataSource={tableData.list} pagination={{ total: tableData.total, onChange: (page) =>  initList({page})}} style={{marginTop: 30}} />
  </Row>
}

export default VouchersList