import { observable, action } from "mobx";
import { toJS } from 'mobx'
class shopDecorationStore {

    @observable
    shop_decoration_wode_preview = 0 //是否正在预览：0：否  1：是

    @observable
    refresh = 1 //执行，1：执行，0不执行

    @observable
    shop_decoration_wode = {    //装修门店-我的
        id: 0,
        merber_info: {
            status: 1, //当前菜单项，是否展开 0：展开  1：收起
            background: '',  //背景头像
            photo_position: 1, //1：局左  2：中  3：右
            default: 1, //1：默认 0：自定义
        },
        merber_interests: 1, //1: 小程序显示  0: 小程序不显示
        merber_interests_open: 1, //0：展开  1：收起
        merber_assets: 1,   //1: 小程序显示  0: 小程序不显示
        merber_assets_open: 1, //0：展开  1：收起
        merber_menu: {
            status: 1, //当前菜单项，是否展开 0：展开  1：收起
            module_style: 1, //1:宫格  2:列表
            module_column: 3,// 列数,默认3列
            current_index: 0, //表示module_list的当前操作的索引
            current_is_edit: 0, //表示当前菜单是否有编辑 0:否 1:是
            current_name: '', //表示当前module_list的当前操作的名称
            module_list: [
                {
                    name: '',
                    show: '1', //是否显示1:显示，0：不显示
                    order: 0, //排序
                    status: 0,
                    is_edit: 0, //是否正在编辑，0：否  1：是
                }
            ]
        },
        href_path: [  //链接地址
            {
                name: '',
                path: ''
            }
        ]
    }

    @observable
    shop_decoration_wode_default = {    //装修门店-我的  默认数据
        id: 0,
        merber_info: {
            status: 1, //当前菜单项，是否展开 0：展开  1：收起
            background: '',  //背景头像
            photo_position: 1, //1：局左  2：中  3：右
            default: 1, //1：默认 0：自定义
        },
        merber_interests: 1, //1: 小程序显示  0: 小程序不显示
        merber_interests_open: 1, //0：展开  1：收起
        merber_assets: 1,   //1: 小程序显示  0: 小程序不显示
        merber_assets_open: 1, //0：展开  1：收起
        merber_menu: {
            status: 1, //当前菜单项，是否展开 0：展开  1：收起
            module_style: 1, //1:宫格  2:列表
            module_column: 3,// 列数,默认3列
            current_index: 0, //表示module_list的当前操作的索引
            current_is_edit: 0, //表示当前菜单是否有编辑 0:否 1:是
            module_list: [
                {
                    name: '',
                    show: '1', //是否显示1:显示，0：不显示
                    order: 0, //排序
                    status: 0,
                    is_edit: 0, //是否正在编辑，0：否  1：是
                }
            ]
        },
        href_path: [  //链接地址
            {
                name: '',
                path: ''
            }
        ]
    }

    @observable
    shop_decoration_home_preview = 0 //是否正在预览：0：否  1：是
    // shop_decoration_home_Template = 1 //当前模版 1or2

    //店铺装修首页(其他变量)
    @observable
    shop_decoration_home_other:any = {
        module:'' , //当前选中模版
        showRight:false , //是否显示右侧编辑
        current: -1, //当前板块
        hoverCurrent: -1, //鼠标悬停模块
        editMofangCurrent: -1 , //当前编辑的魔方导航
        hoverEditMofangCurrent: -1 , //当前悬停的编辑的魔方导航
        editMutiCurrent: 0 , //当前编辑的多图文导航
        hoverMutiCurrent: -1, //当前悬停编辑的多图文导航
        // imgCurrent: -1, //记录多图文图片点击
        // hoverImgCurrent: -1, //记录多图文图片悬停
    }
    //店铺装修首页数据(模版1)
    @observable
    shop_decoration_home:any = [
        //多图文-轮播图
        {
            module: 'muti',
            moduleName: '多图文',
            title: '多图文', //模块名称
            showTitle: false, //是否显示模块
            subTitle: '', //副标题
            showSubTitle: false, //是否显示副标题
            type:1, //模块样式 1为轮播图，2为横向滚动，3为竖排
            imgList: [
                {
                    imgUrl:'https://ydn-siyu.cdn.shuachi.com/j0m9p7t41597657516406.png',
                    desc:'测试名称', //描述（名称）
                    subDesc:'测试价格', //二级描述（价格）
                },
                {
                    imgUrl:'https://ydn-siyu.cdn.shuachi.com/j0m9p7t41597657516406.png',
                    desc:'测试名称1', //描述（名称）
                    subDesc:'测试价格2', //二级描述（价格）
                },
                {
                    imgUrl:'https://ydn-siyu.cdn.shuachi.com/l1qghcr31605230734932.png',
                    defaultImgUrl:'https://ydn-siyu.cdn.shuachi.com/l1qghcr31605230734932.png',
                    desc:'测试名称2', //描述（名称）
                    subDesc:'测试价格3', //二级描述（价格）
                },
                {
                    imgUrl:'https://ydn-siyu.cdn.shuachi.com/roejlc9r1595497362213.png',
                    defaultImgUrl:'https://ydn-siyu.cdn.shuachi.com/roejlc9r1595497362213.png',
                    desc:'测试名称2', //描述（名称）
                    subDesc:'测试价格4', //二级描述（价格）
                }
            ],
        },
        //排队
        {
            module: 'action',
            moduleName: '排队点餐操作',
            title:'排队点餐',
            disbled:true, //不可以删除，只能排序
        },
        //地址
        {
            module: 'address',
            moduleName: '商家信息',
            title:'商家信息'
        },
        //权益卡
        {
            module: 'equityCard',
            moduleName: '权益卡',
            title:'权益卡',
            type: 0, //模块样式， 0为默认，1为自定义
            imgUrl:'https://ydn-siyu.cdn.shuachi.com/equityCard.jpg',
            defaultImgUrl:'https://ydn-siyu.cdn.shuachi.com/equityCard.jpg',
        },
        //魔方-模版2
        {
            module: 'moFang',
            moduleName: '魔方导航',
            title: '魔方导航', //模块名称
            showTitle: false, //是否显示模块
            subTitle: '', //副标题
            showSubTitle: false, //是否显示副标题
            type:2, //模块样式  1为横排，2为异形排布
            column: 3, //列数
            list: [
                {
                    name:'余额',
                    type:'',
                    show:1,
                    styleType: 0, //标题样式 0默认，1自定义
                    style: {
                        fontSize: 1, //字体 ，1为16号，2为14号， 3为14号
                        color: 'rgba(0,0,0)', //字体颜色
                        fontWeight: false, //字体是否加粗
                    },
                    imgType: 0, //图标附图 0默认，1自定义
                    imgUrl:'https://ydn-siyu.cdn.shuachi.com/card_charge.png' , //图标附图url,
                    defaultImgUrl:'https://ydn-siyu.cdn.shuachi.com/card_charge.png' , //图标附图url,
                    linkUrl: '',
                    linkName: '',
                },
                {
                    name:'优惠卡券',
                    type:'',
                    show:1,
                    styleType: 0, //标题样式 0默认，1自定义
                    style: {
                        fontSize: 1, //字体 ，1为16号，2为14号， 3为14号
                        color: 'rgba(0,0,0)', //字体颜色
                        fontWeight: false, //字体是否加粗
                    },
                    imgType: 0, //图标附图 0默认，1自定义
                    imgUrl:'https://ydn-siyu.cdn.shuachi.com/card_coupon.png' , //图标附图url,
                    defaultImgUrl:'https://ydn-siyu.cdn.shuachi.com/card_coupon.png' , //图标附图url,
                    linkUrl: '',
                    linkName: '',
                },
                {
                    name:'兑换好礼',
                    type:'',
                    show:1,
                    styleType: 0, //标题样式 0默认，1自定义
                    style: {
                        fontSize: 1, //字体 ，1为16号，2为14号， 3为14号
                        color: 'rgba(0,0,0)', //字体颜色
                        fontWeight: false, //字体是否加粗
                    },
                    imgType: 0, //图标附图 0默认，1自定义
                    imgUrl:'https://ydn-siyu.cdn.shuachi.com/card_score.png' , //图标附图url,
                    defaultImgUrl:'https://ydn-siyu.cdn.shuachi.com/card_score.png' , //图标附图url,
                    linkUrl: '',
                    linkName: '',
                },
            ]
        },
        //邀请
        {
            module: 'invitation',
            moduleName: '邀请有礼',
            title:'邀请有礼',
            imgUrl:'https://ydn-siyu.cdn.shuachi.com/lprer4491607074173495.png',
            defaultImgUrl:'https://ydn-siyu.cdn.shuachi.com/lprer4491607074173495.png',
            type: 0, //模块样式， 0为默认，1为自定义
        },
        //富文本
        // {
        //     module: 'richText',
        //     moduleName: '富文本',
        //     title: '多图文', //模块名称
        //     showTitle: false, //是否显示模块
        //     subTitle: '', //副标题
        //     showSubTitle: false, //是否显示副标题
        //     richText:'',
        // },
    ]
    // shop_decoration_home:any = [
    //     //地址
    //     {
    //         module: 'address',
    //         moduleName: '商家信息',
    //         title:'商家信息'
    //     },
    //     //多图文-轮播图
    //     {
    //         module: 'muti',
    //         moduleName: '多图文',
    //         title: '多图文', //模块名称
    //         showTitle: true, //是否显示模块
    //         subTitle: '3123', //副标题
    //         showSubTitle: true, //是否显示副标题
    //         type:1, //模块样式 1为轮播图，2为横向滚动，3为竖排
    //         imgList: [
    //             {
    //                 imgUrl:'https://ydn-siyu.cdn.shuachi.com/j0m9p7t41597657516406.png',
    //                 desc:'测试名称', //描述（名称）
    //                 subDesc:'测试价格', //二级描述（价格）
    //             },
    //             {
    //                 imgUrl:'https://ydn-siyu.cdn.shuachi.com/e3elc6k51606892048799.png',
    //                 desc:'测试名称1', //描述（名称）
    //                 subDesc:'测试价格2', //二级描述（价格）
    //             },
    //             {
    //                 imgUrl:'https://ydn-siyu.cdn.shuachi.com/l1qghcr31605230734932.png',
    //                 desc:'测试名称2', //描述（名称）
    //                 subDesc:'测试价格3', //二级描述（价格）
    //             },
    //             {
    //                 imgUrl:'https://ydn-siyu.cdn.shuachi.com/roejlc9r1595497362213.png',
    //                 desc:'测试名称2', //描述（名称）
    //                 subDesc:'测试价格4', //二级描述（价格）
    //             }
    //         ],
    //     },
    //     //魔方-模版2
    //     {
    //         module: 'moFang',
    //         moduleName: '魔方导航',
    //         title: '魔方导航', //模块名称
    //         showTitle: false, //是否显示模块
    //         subTitle: '', //副标题
    //         showSubTitle: false, //是否显示副标题
    //         type:2, //模块样式  1为横排，2为异形排布
    //         column: 3, //列数
    //         list: [
    //             {
    //                 name:'余额',
    //                 show:1,
    //                 type:'',
    //                 styleType: 0, //标题样式 0默认，1自定义
    //                 style: {
    //                     fontSize: 1, //字体 ，1为16号，2为14号， 3为12号
    //                     color: 'rgba(0,0,0)', //字体颜色
    //                     fontWeight: false, //字体是否加粗
    //                 },
    //                 imgType: 0, //图标附图 0默认，1自定义
    //                 imgUrl:'https://ydn-siyu.cdn.shuachi.com/card_charge.png' , //图标附图url,
    //                 defaultImgUrl:'https://ydn-siyu.cdn.shuachi.com/card_charge.png' , //图标附图url,
    //                 linkUrl: '',
    //                 linkName: '',
    //             },
    //             {
    //                 name:'优惠卡券',
    //                 type:'',
    //                 show:1,
    //                 styleType: 0, //标题样式 0默认，1自定义
    //                 style: {
    //                     fontSize: 2, //字体 ，1为16号，2为14号， 3为14号
    //                     color: 'rgba(0,0,0)', //字体颜色
    //                     fontWeight: true, //字体是否加粗
    //                 },
    //                 imgType: 0, //图标附图 0默认，1自定义
    //                 imgUrl:'https://ydn-siyu.cdn.shuachi.com/card_coupon.png' , //图标附图url,
    //                 defaultImgUrl:'https://ydn-siyu.cdn.shuachi.com/card_coupon.png' , //图标附图url,
    //                 linkUrl: '',
    //                 linkName: '',
    //             },
    //             {
    //                 name:'兑换好礼',
    //                 type:'',
    //                 show:1,
    //                 styleType: 0, //标题样式 0默认，1自定义
    //                 style: {
    //                     fontSize: 3, //字体 ，1为16号，2为14号， 3为14号
    //                     color: 'rgba(0,0,0)', //字体颜色
    //                     fontWeight: false, //字体是否加粗
    //                 },
    //                 imgType: 0, //图标附图 0默认，1自定义
    //                 imgUrl:'https://ydn-siyu.cdn.shuachi.com/card_score.png' , //图标附图url,
    //                 defaultImgUrl:'https://ydn-siyu.cdn.shuachi.com/card_score.png' , //图标附图url,
    //                 linkUrl: '',
    //                 linkName: '',
    //             },
    //         ]
    //     },        
    //     //排队
    //     {
    //         module: 'action',
    //         moduleName: '排队点餐操作',
    //         title:'排队点餐',
    //         disbled:true, //不可以删除，只能排序
    //     },

    //     //权益卡
    //     {
    //         module: 'equityCard',
    //         moduleName: '权益卡',
    //         title:'权益卡',
    //         imgUrl:'https://ydn-siyu.cdn.shuachi.com/equityCard.jpg',
    //         defaultImgUrl:'https://ydn-siyu.cdn.shuachi.com/equityCard.jpg',
    //         type: 0, //模块样式， 0为默认，1为自定义
    //     },
    //     //多图文-轮播图
    //     {
    //         module: 'muti',
    //         moduleName: '多图文',
    //         title: '多图文', //模块名称
    //         showTitle: true, //是否显示模块
    //         subTitle: '', //副标题
    //         showSubTitle: true, //是否显示副标题
    //         type:3, //模块样式 1为轮播图，2为横向滚动，3为竖排
    //         imgList: [
    //             {
    //                 imgUrl:'https://ydn-siyu.cdn.shuachi.com/j0m9p7t41597657516406.png',
    //                 desc:'测试名称', //描述（名称）
    //                 subDesc:'测试价格', //二级描述（价格）
    //             },
    //             {
    //                 imgUrl:'https://ydn-siyu.cdn.shuachi.com/e3elc6k51606892048799.png',
    //                 desc:'测试名称1', //描述（名称）
    //                 subDesc:'测试价格2', //二级描述（价格）
    //             },
    //             {
    //                 imgUrl:'https://ydn-siyu.cdn.shuachi.com/l1qghcr31605230734932.png',
    //                 desc:'测试名称2', //描述（名称）
    //                 subDesc:'测试价格3', //二级描述（价格）
    //             },
    //             {
    //                 imgUrl:'https://ydn-siyu.cdn.shuachi.com/roejlc9r1595497362213.png',
    //                 desc:'测试名称2', //描述（名称）
    //                 subDesc:'测试价格4', //二级描述（价格）
    //             }
    //         ],
    //         desc:'', //描述（名称）
    //         subDesc:'', //二级描述（价格）
    //     },
    // ]

    //店铺首页操作数据历史
    @observable 
    shop_decoration_home_history_prev:any = []
    @observable 
    shop_decoration_home_history_next:any = []

    @action
    updateRefresh = (val: any) => {
        this.refresh = val
    }

    @action
    updateObjProps = (type: any, props: any, val: any) => {
        let temp_obj: any = this.shop_decoration_wode;
        temp_obj[type][props] = val;
        this.shop_decoration_wode = temp_obj;
    }

    @action
    updatePreview = (val: any) => {
        this.shop_decoration_wode_preview = val
    }

    @action
    updateObjPropsDefault = (type: any, props: any, val: any) => {
        let temp_obj: any = this.shop_decoration_wode_default;
        temp_obj[type][props] = val;
        this.shop_decoration_wode_default = temp_obj;
    }

    @action
    updateShopDecorationWode = (obj: any) => {
        this.shop_decoration_wode = obj
    }

    @action
    updateShopDecorationWodeDefault = (obj: any) => {
        this.shop_decoration_wode_default = obj
    }

    @action
    updateDecorationVal = (type: any, val: any) => {
        switch (type) {
            case 'merber_info':
                this.shop_decoration_wode.merber_info = val
                break;
            case 'merber_interests':
                this.shop_decoration_wode.merber_interests = val
                break;
            case 'merber_assets':
                this.shop_decoration_wode.merber_assets = val
                break;
            case 'merber_interests_open':
                this.shop_decoration_wode.merber_interests_open = val
                break;
            case 'merber_assets_open':
                this.shop_decoration_wode.merber_assets_open = val
                break;
            case 'merber_menu':
                this.shop_decoration_wode.merber_menu = val
                break;
            default:
                break;
        }
    }
    //更新部分店铺首页数据
    @action
    updateShopDecorationHomeObjProps = (index: any, props: any, val: any) => {
        console.log('------------------updateShopDecorationHomeObjProps------------------')
        this.shop_decoration_home_history_prev.push({list:JSON.parse(JSON.stringify(this.shop_decoration_home))}) // //记录操作历史
        // console.log('----this.shop_decoration_home----',toJS(this.shop_decoration_home_history_prev))
        this.shop_decoration_home[index][props] = val
    }
    //更新全部店铺首页数据, historyFlag不传则记录历史记录
    @action
    updateShopDecorationHome = (obj: any, historyFlag:boolean=true) => {
        if(historyFlag){
            //记录操作历史
            this.shop_decoration_home_history_prev.push({list:JSON.parse(JSON.stringify(this.shop_decoration_home))})
        }
        this.shop_decoration_home = obj
        console.log('------------------updateShopDecorationHome------------------')
    }
    //更新全部店铺其他数据
    @action
    updateShopDecorationHomeOther = (obj: any) => {
        this.shop_decoration_home_other = obj
    }
    //更新部分全部店铺其他数据
    @action
    updateShopDecorationHomeOtherObjProps = (props: any, val: any) => {
        let temp_obj: any = JSON.parse(JSON.stringify(this.shop_decoration_home_other))
        temp_obj[props] = val
        console.log('temp_obj',temp_obj)
        this.shop_decoration_home_other = temp_obj
    }
    @action
    updateHomePreview = (val: any) => {
        this.shop_decoration_home_preview = val
    }
    @action
    updateShopDecorationHomeHistoryPrev = (obj: any) => {
        this.shop_decoration_home_history_prev.push({list:obj})
    }
    @action
    updateShopDecorationHomeHistoryNext = (obj: any) => {
        this.shop_decoration_home_history_next.push({list:obj})
    }
    //更新全部店铺其他数据
    // @action
    // updateHomeTemplate = (val: any) => {
    //     this.shop_decoration_home_Template = val
    // }

}

export default new shopDecorationStore()