import React from "react"
import styles from './decorationHomeHeaderPreview.module.scss'
import shopDecorationStore from '../../../../stores/shopDecoration.store'
import { postRequest } from '../../../../api/http'
import { apiUrl } from '../../../../api/api_url'
import { message } from 'antd'
import { useHistory } from "react-router-dom"

interface IProps {
    dataObj: {
        left: string,
        right: Array<{
            type: number,
            txt: string
        }>
    },
    template: number
}

interface IState {

}

const DecorationHomeHeaderPreview = (props:IProps) => {

    const history = useHistory()
    const { dataObj, template } = props;
    //点击事件
    const onClickSubmit = async (type: any) => {
        switch (type) {
            case '编辑页面':
                shopDecorationStore.updateHomePreview(0)
                break;
            case '使用':
                let params = {
                    template
                }
                let res: any = await postRequest(apiUrl.applyHomepage, params)
                if(res.code === 1) {
                    message.success('操作成功！')
                } else {
                    message.error(res.message||'操作失败')
                }
                break;
            case '返回列表':
                history.goBack();
                setTimeout(() => {
                    shopDecorationStore.updateHomePreview(0)
                },500)
                break;
        
            default:
                break;
        }
    }

    return (
        <div className={styles.header}>
            <div className={styles.header_left}>
                <div className={styles.header_left_list}>
                    {
                        dataObj.left
                    }
                </div>
            </div>
            <div className={styles.header_right}>
                {
                    dataObj.right && dataObj.right.length > 0 && dataObj.right.map((item: any, index: number) => {
                        return (
                            item.type == 1
                            ?   <div key={item.txt + index} className={styles.header_right_list} onClick={() => {onClickSubmit(item.txt)}}>
                                    {
                                        item.txt
                                    }
                                </div>
                            :   <div key={item.txt + index} className={styles.header_right_item} onClick={() => {onClickSubmit(item.txt)}}>
                                    {
                                        item.txt
                                    }
                                </div>
                        )
                    })
                }
            </div>
            
        </div>
    )
}

export default DecorationHomeHeaderPreview