import React from 'react';
import { Row, Col } from 'antd';
import '../management.scss';

import winAddCourtesy1 from '../../../../../../assret/images/winAddCourtesy1.png';
import winAddCourtesy2 from '../../../../../../assret/images/winAddCourtesy2.png';
import winAddCourtesy3 from '../../../../../../assret/images/winAddCourtesy3.png';
import winAddCourtesy4 from '../../../../../../assret/images/winAddCourtesy4.png';
import winAddCourtesy5 from '../../../../../../assret/images/winAddCourtesy5.png';

interface HelpMbaCourtesy {
    history: any,
}

class HelpMbaCourtesy extends React.Component<HelpMbaCourtesy> {

    render() {
        return  <Row className="typeDetail">
                    <Col className="typeDetail-title">一、功能介绍</Col>
                    <Col className="typeDetail-col">分享好友帮忙助力，吸引流量促进下单。</Col>
                    <Col className="typeDetail-title">二、功能路径</Col>
                    <Col className="typeDetail-col">电脑端登录后台-营销管理-助力有礼列表-添加助力有礼。</Col>
                    <Col className="typeDetail-col">
                        <img src={winAddCourtesy1} />
                    </Col>
                    <Col className="typeDetail-title">三、操作流程</Col>
                    <Col className="typeDetail-col">1、<span className="txt-bold">添加助力有礼</span>，在“助力有礼列表”界面下点击“添加助力有礼”</Col>
                    <Col className="typeDetail-col">
                        <img src={winAddCourtesy2} />
                    </Col>
                    <Col className="typeDetail-col">2、助力类型：助力送菜</Col>
                    <Col className="typeDetail-col">3、活动名称：填写名称</Col>
                    <Col className="typeDetail-col">4、有效期：可以填写固定日期或领取后使用日期</Col>
                    <Col className="typeDetail-col">5、活动礼品：选择助力完成之后，赠送的菜品</Col>
                    <Col className="typeDetail-col">6、参与对象：当选择新用户，则还未注册的用户或已经注册还没有下单的用户，可参与该活动，当选择老顾客，则交易次数>=1次的交易次数用户，可参与该活动，当全部选择时，则所有用户，可参与该活动</Col>
                    <Col className="typeDetail-col">7、状态：启用和停用两种状态，默认选择启用，当选择停用，则用户不能参与该活动</Col>
                    <Col className="typeDetail-col">
                        <img src={winAddCourtesy3} />
                    </Col>
                    <Col className="typeDetail-col">8、用餐人数：填写用餐人数，例如：用餐人数填写为2，当用户就餐人数等于2时可参与该活动，不满足条件则不能参与该活动</Col>
                    <Col className="typeDetail-col">9、需要助力人数：填写需要助力人数；例如需要助力人数填写为4，就餐用户要邀请4个新用户才能拿到该活动礼品</Col>
                    <Col className="typeDetail-col">10、可获得份数：填写可获得份数，例如可获得份数填写为4，用户可领取4份活动礼品</Col>
                    <Col className="typeDetail-col">
                        <img src={winAddCourtesy4} />
                    </Col>
                    <Col className="typeDetail-col">11、助力类型：选择助力拆券</Col>
                    <Col className="typeDetail-col">12、活动名称：填写名称</Col>
                    <Col className="typeDetail-col">13、活动礼品：选择助力完成之后，获得的优惠券</Col>
                    <Col className="typeDetail-col">14、参与对象：当选择新用户，则还未注册的用户或已经注册还没有下单的用户，可参与该活动，当选择老顾客，则交易次数>=1次的交易次数用户，可参与该活动，当全部选择时，则所有用户，可参与该活动</Col>
                    <Col className="typeDetail-col">15、状态：启用和停用两种状态，默认选择启用，当选择停用，则用户不能参与该活动</Col>
                    <Col className="typeDetail-col">16、需要助力人数：填写需要助力人数；例如需要助力人数填写为4，就餐用户要邀请4个新用户才能拿到该活动礼品</Col>
                    <Col className="typeDetail-col">
                        <img src={winAddCourtesy5} />
                    </Col>
                    <Col className="typeDetail-col">17、切换参与用户，在“充值免单列表”界面下点击“参与用户”，可查看那些用户参与该活动</Col>
                </Row>
    }
}

export default HelpMbaCourtesy;