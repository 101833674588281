import React, {useEffect, useState} from "react";
import {Icon, Select, Tooltip, Radio, Button, Input, Table, Avatar, message} from "antd";

import i_statistic_icon from '../../assret/images/i_statistic_icon.jpg'

import './joinFans.scss'
import {getRequest} from "../../api/http";
import {apiUrl} from "../../api/api_url";

const {Option} = Select;
const { Search } = Input;

interface IProps {
    location: any
}

const JoinFans = (props:IProps)=>{
    const [fansType,setFansType] = useState(1);        // 粉丝类型
    const [accounts,setAccounts] = useState([]);       // 公众号数组
    const [taskList,setTaskList] = useState([]);       // 任务数组
    const [taskId,setTaskId] = useState('');           // 选中的任务id
    const [tableData,setTableData] = useState([]);
    const [tableDataCount,setTableCount] = useState(0); // 总数量
    const [currentPage,setCurrentpage] = useState(1);
    const [keywords,setKeyWords] = useState('');
    const [statictisParam,setStatictisParam] = useState({
        join_num: 0,
        finish_num: 0,
        receive_num: 0
    });
    let page = 1;
    let page_size = 10;
    const columns:any = [
        {
            title: '排名',
            dataIndex: 'index',
            key: 'index',
            align: "center",
            render:  (text: string,record:any,index:number) =><div>{index+1}</div>
        },
        {
            title: '用户',
            dataIndex: 'nickName',
            key: 'nickName',
            align: "center",
            render: (text: string,record:any,index:number) =>
                <div className="colum_1">
                    <Avatar src={record.headimgurl} />
                    <div>{record.nickname}</div>
                </div>
        },
        {
            title: '关注状态',
            dataIndex: 'subscribe',
            key: 'subscribe',
            align: "center",
            render:  (text: string,record:any,index:number) =>
                <div>
                    {
                        record.subscribe === 0 ? '未订阅':'已订阅'
                    }
                </div>
        },
        {
            title: '当前助力人数',
            dataIndex: 'has_help_num',
            key: 'has_help_num',
            align: "center",
        },
        {
            title: '历史最高助力人数',
            key: 'max_help_num',
            dataIndex: 'max_help_num',
            align: "center",
        },
        {
            title: '所属公众号',
            key: 'mp_account_name',
            dataIndex: 'mp_account_name',
            align: "center",
        },
        {
            title: '任务完成状态',
            key: 'is_complete',
            dataIndex: 'is_complete',
            align: "center",
            render:  (text: string,record:any,index:number) =>
                <div>
                    {
                        record.is_complete ? '完成':'未完成'
                    }
                </div>
        },
        {
            title: '是否已点击领奖',
            key: 'is_receive',
            dataIndex: 'is_receive',
            align: "center",
            render:  (text: string,record:any,index:number) =>
                <div>
                    {
                        record.is_receive ? '已领取':'未领取'
                    }
                </div>
        },
        {
            title: '用户订单',
            key: 'order_num',
            dataIndex: 'order_num',
            align: "center",
        }
    ];
    // 相当于 componentDidMount 和 componentDidUpdate:
    useEffect(() =>{
        getAccountList();       // 获取公众号
        getTaskList();          // 获取任务列表
        return cleanUp();
    }, []);

    const cleanUp = () =>{
      //这里执行组件销毁 清除一些监听等
    };

    // 统计组的 组件
    const statisticView =(num: number,tipTitle: string)=>{
        return (
            <div className="statisticView">
                <img src={i_statistic_icon} alt='' className="statistic_icon" />
                <div className="view_right">
                    <div className="statistic_num">{num}</div>
                    <div>{tipTitle}</div>
                </div>
                <div className="statis_tip">
                    <Tooltip placement="topLeft" title={tipTitle}>
                        <Icon type="exclamation-circle" theme="filled" />
                    </Tooltip>
                </div>
            </div>
        )
    };

    // 获取公众号列表
    const getAccountList = async () =>{
        const res:any = await getRequest(apiUrl.authAccountList,{page: 1,page_size: 20});
        if(res.code === 1){
            setAccounts(res.data.list);
        }else{
            message.error('服务器异常'+res.message);
        }
    };

    // 获取任务列表
    const getTaskList = async () =>{
        const res:any = await getRequest(apiUrl.taskList,{page: 1,page_size: 20,mp_id: 0});
        if(res.code === 1){
            const list:any = res.data.list;
            setTaskList(list);
            if(list.length > 0){
                let id = props.location.state ? props.location.state.id:list[0].id;
                setTaskId(id);
                getJoinFans(id);
            }
        }else{
            message.error('服务器异常'+res.message);
        }
    };

    // 获取参与用户
    const getJoinFans = async (taskId:number | string, currentPage?:number,value ?: string) =>{
      const param = {
        page: currentPage ? currentPage:page,
        page_size: page_size,
        mp_id: 0,
        mid: taskId,
        keywords: value ? value:keywords
      };
      const res:any = await getRequest(apiUrl.getFansList,param);
       if(res.code === 1){
           setTableData(res.data.list);
           setTableCount(res.data.count);
           setStatictisParam(res.data.statistics);
       }else{
           message.error('服务器异常'+res.message);
       }
    };

  return (
      <div className="joinFans l_content">
          <div className="select_view">
              <div className="select_item">
                  <div>
                      当前公众号：
                  </div>
                  <div>
                      <Select style={{ width: 122 }} defaultValue="0">
                          <Option value="0">全部</Option>
                          {
                              accounts && accounts.map((item:any,index) => <Option key={index} value={item.id}>{item.nick_name}</Option>)
                          }

                      </Select>
                  </div>
              </div>
              <div className="select_item">
                  <div>
                      当前任务：
                  </div>
                  <div>
                      <Select style={{ width: 122 }} value={taskId} onChange={(e:any) =>{
                          setTaskId(e);
                          getJoinFans(e)
                      }}>
                          {
                              taskList && taskList.map((item:any,index) => <Option key={index} value={item.id}>{item.name}</Option>)
                          }
                      </Select>
                  </div>
              </div>
          </div>
          <div className="statistic_group">
              {statisticView(statictisParam.join_num,'参与活动人数')}
              {statisticView(statictisParam.finish_num,'完成任务人数')}
              {statisticView(statictisParam.receive_num,'发放奖品人数')}
          </div>
          <div className="filter_view">
              <Radio.Group value={fansType} onChange={e =>{setFansType(e.target.value)}}>
                  <Radio.Button value={1}>参与用户</Radio.Button>
                  <Radio.Button value={2} disabled>黑名单</Radio.Button>
              </Radio.Group>
              <div className="filter_right">
                  <Search
                      placeholder="请输入用户昵称"
                      enterButton="Search"
                      onSearch={value => {
                          setKeyWords(value);
                          getJoinFans(taskId,page,value)
                      }}
                      style={{ width: 234 ,marginRight: 20}}
                  />
                  <Button icon="file-markdown" type='primary'>导出</Button>
              </div>
          </div>
          <div className="table_view">
              <Table columns={columns} size="middle" dataSource={tableData} rowKey={"id"} pagination={ {
                  simple: false,
                  current: currentPage,
                  total: tableDataCount,
                  pageSize: page_size,
                  onChange: (page:number) =>{
                      setCurrentpage(page);
                      getJoinFans(taskId,page);
                  }
              }} />
          </div>
      </div>
  )

};

export default JoinFans;