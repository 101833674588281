import React, { useEffect, useState } from 'react'
import { message, Row, Button, Select } from 'antd'
import { useHistory } from 'react-router-dom'
import { getRequest, postRequest } from '../../../api/http'
import { apiUrl } from '../../../api/api_url'

import './index.scss'

const { Option } = Select
const DepositPrintSetting = (props:any)=>{
    const history = useHistory()
    const [ printList, setPrintList ] = useState<any>([])
    const [ info, setinfo ] = useState<any>({})
    const [ print_config, setPrintConfig ] = useState<any>({})
    const [ loading, setLoading ] = useState(false)

    useEffect(()=>{
        getInfo()
        getList()
    },[])

    const getInfo = async() => {
        const res:any = await getRequest(apiUrl.queryStorageSms);
        if(res.code === 1){
            setinfo(res.data)
            setPrintConfig({
                pull_print_id: res.data.storage_remind_switch.print_config.pull_print_id||-1,
                push_print_id: res.data.storage_remind_switch.print_config.push_print_id||-1,
            })
        }else{
            message.error(res.message||'获取单据打印设置信息失败')
        }
    }

    const getList = async() => {
        const res:any = await getRequest(apiUrl.printerList);
        if(res.code === 1){
            setPrintList(res.data.list)
        }else{
            message.error(res.message||'获取打印机信息失败')
        }
    }

    const changeFieldVal = (e:any, field:string) => {
        let fieldObj:any = {}
        fieldObj = { [field]: e }
        setPrintConfig({
            ...print_config,
            ...fieldObj
        })
    }

    const onsubmit = async() =>{
        if(print_config.push_print_id === -1){
            message.error('请选择存酒单打印机')
            return false
        }
        if(print_config.pull_print_id === -1){
            message.error('请选择取酒单打印机')
            return false
        }
        const params = {
            value:JSON.stringify({
                official:info.storage_remind_switch.official,
                sms:info.storage_remind_switch.sms,
                print_config:print_config
            })
        }
        setLoading(true)
        const res:any = await postRequest(apiUrl.setStorage,params)
        setLoading(false)
        if(res.code === 1){
            message.success('保存成功')
            history.goBack()
        }else{
            message.error(res.message||'保存失败')
        }
    }

    const goBack = () =>{
        history.goBack()
    }
    
    return (
        <div className="deposit_print_setting">
            <div className="form">
                <div className="form_title">单据打印设置</div>
                <Row type="flex" align="middle" className="mb20">
                    <div className="form_label">存酒单打印机 ：</div>
                    <Select value={print_config.push_print_id} className="form_select" onChange={(e:any)=>{changeFieldVal(e,'push_print_id')}}>
                        <Option value={-1}>未设置</Option>
                        {
                            printList.map((item:any,index:number)=>{
                                return <Option value={item.id} key={index}>{item.name}</Option>
                            })
                        }
                    </Select>
                </Row>
                <Row type="flex" align="middle" className="mb20">
                    <div className="form_label">取酒单打印机 ：</div>
                    <Select value={print_config.pull_print_id} className="form_select"onChange={(e:any)=>{changeFieldVal(e,'pull_print_id')}}>
                        <Option value={-1}>未设置</Option>
                        {
                            printList.map((item:any,index:number)=>{
                                return <Option value={item.id} key={index}>{item.name}</Option>
                            })
                        }
                    </Select>
                </Row>
            </div>
            <Row type="flex" align="middle" justify="center" className="footer">
                <Button type="primary" className="mr20" onClick={()=>{onsubmit()}} loading={loading}>保 存</Button>
                <Button onClick={()=>{goBack()}}>取 消</Button>
            </Row>
        </div>
    )
}

export default DepositPrintSetting
