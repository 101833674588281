import {observer} from "mobx-react";
import React from "react";
import { Table, Modal, message,Popover, Button  } from "antd";
import { postRequest, getRequest } from '../../api/http';
import { apiUrl } from '../../api/api_url';
import { Ellipse } from "fabric/fabric-impl";
import { getIsPermisson, messageCustom } from "../../utils/permisstionUtil";

const key = 'PrinterMsgList';

@observer
class PrinterMsgList extends React.Component<any> {
    state = {
        productList: [],
        pageSize: 10,
        productCount: 0
    }

    columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            className: 'list_center',
        },
        {
            title: '内容',
            dataIndex: 'msg',
            key: 'msg',
            className: 'list_center',
            width: '400px',
            render: (text: string, record: any) => {
                const content = (
                    <div>
                      <p>{record.msg}</p>
                    </div>
                );
                return <div style={{width: '400px', overflow:'hidden'}}>
                    <Popover content={content}>
                    <div style={{width: '400px',overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis'}}>{record.msg}</div>
                </Popover>
                </div>
            }
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            className: 'list_center',
            render: (text:any,record:any,) => (
                <div>{
                    record.status === 0
                    ? '发送失败'
                    : record.status === 1
                        ? '发送成功'
                        : '已发送'}</div>
            ),
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            className: 'list_center',
            render: (text:any,record:any,) => (
                <div className="btn_wrap">
                    {
                    record.status === 0
                    ? <div className="list_btn" onClick={() => this.sendPrinter(record)}>再次发送</div>
                    : null}
                    <div className="list_btn" onClick={() => this.deletePrinter(record)}>删除</div>
                </div>
            ),
        },
    ];

    componentDidMount(){
        this.onInitList();
        this.onInitCount()
    }

    // 初始化列表
    onInitList = async () => {
        const params:any = {
            current: 1,
            pageSize: 10
        };
        const res:any = await getRequest(apiUrl.printerMsgList,params);
        if(res.code === 1){
            this.setState({
                productList: res.data.list,
            })
        }
    }

    onInitCount = async () => {
        const res:any = await getRequest(apiUrl.printerMsgCount);
        if(res.code === 1){
            this.setState({
                productCount: res.data.num,
            })
        }
    }

    pageChange = async (page: number) => {
        const params:any = {
            current: page,
            pageSize: 10
        };
        const res:any = await getRequest(apiUrl.printerMsgList,params);
        if(res.code === 1){
            this.setState({
                productList: res.data.list,
            })
        }
    }

    // 删除打印机
    deletePrinter = (record: any) => {
        if(!getIsPermisson('2_3_2_1')){
            messageCustom();
            return;
          }
        Modal.confirm({
            title: '删除该打印机消息',
            content: `确认删除打印机ID 「${record.id}」？`,
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                const params = {
                    id: record.id
                }
                const res:any = await postRequest(apiUrl.printerMsgDel,params);
                if(res.code === 1){
                    message.success({ content: '删除成功!', key, duration: 2 });
                }
                this.onInitList();
            },
            onCancel() {
            },
        });
    }

    sendPrinter = async (record: any) => {
        if(!getIsPermisson('2_3_2_2')){
            messageCustom();
            return;
          }
        const params = {
            id: record.id
        }
        const res:any = await postRequest(apiUrl.printerMsgSend,params);
        if(res.code === 1){
            message.success({ content: '发送成功!', key, duration: 2 });
        }
        this.onInitList();
    }

    render(){
        return <div className='l_content'>
            <div>
                <Table dataSource={this.state.productList}  size="middle" columns={this.columns} rowKey={"id"} locale={{emptyText: '暂无数据'}} pagination={{
                    showQuickJumper:true,
                    defaultCurrent: 1,
                    total: this.state.productCount,
                    pageSize: this.state.pageSize,
                    hideOnSinglePage: true,
                    onChange: this.pageChange
                }} />
            </div>

        </div>;
    }
}
export default PrinterMsgList