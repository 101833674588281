import React from 'react';
import { Row, Col } from 'antd';
import '../management.scss';

import winAddBirthday1 from '../../../../../../assret/images/winAddBirthday1.png';
import winAddBirthday2 from '../../../../../../assret/images/winAddBirthday2.png';

interface HelpMbaBirthday {
    history: any,
}

class HelpMbaBirthday extends React.Component<HelpMbaBirthday> {

    render() {
        return  <Row className="typeDetail">
                    <Col className="typeDetail-title">一、功能介绍</Col>
                    <Col className="typeDetail-col">在顾客生日送优惠。</Col>
                    <Col className="typeDetail-title">二、功能路径</Col>
                    <Col className="typeDetail-col">电脑端登录后台-营销管理-生日礼物列表-添加生日礼物。</Col>
                    <Col className="typeDetail-col">
                        <img src={winAddBirthday1} />
                    </Col>
                    <Col className="typeDetail-title">三、操作流程</Col>
                    <Col className="typeDetail-col">1、<span className="txt-bold">添加生日礼物</span>，在“生日有礼列表”界面下点击“添加生日礼物”</Col>
                    <Col className="typeDetail-col">
                        <img src={winAddBirthday2} />
                    </Col>
                    <Col className="typeDetail-col">1、活动名称：填写名称</Col>
                    <Col className="typeDetail-col">2、活动礼品：选择相关优惠券或菜品券，当没有优惠券或菜品券时，去创建优惠券选择礼包渠道</Col>
                    <Col className="typeDetail-col">3、参与对象：当选择新用户，则还未注册的用户或已经注册还没有下单的用户，可在相关渠道看到该优惠券，当选择老顾客，则交易次数>=1次的交易次数用户，可在相关渠道看到该优惠券，当全部选择时，则所有用户，可在相关渠道看到该优惠券</Col>
                    <Col className="typeDetail-col">4、发放时间：选择生日前几天发到用户账号上，例如想在用户生日前3天发到用户账号上，选择前3天</Col>
                    <Col className="typeDetail-col">5、状态：启用和停用两种状态，默认选择启用，当选择停用，则用户在生日当天系统不自动发放优惠到用户账号上</Col>
                </Row>
    }
}

export default HelpMbaBirthday;