import React, { useEffect, useState } from 'react'
import { Button, Table, Tooltip } from 'antd'
import { useHistory, useLocation } from 'react-router-dom';
import { getRequest, postRequest } from '../../../../../../../api/http'
import { apiUrl } from '../../../../../../../api/api_url'
import t_member from '../../../../../../../assret/images/t_member.png'
import t_manage from '../../../../../../../assret/images/t_manage.png'
import t_manage_g from '../../../../../../../assret/images/t_manage_g.png'
import t_data from '../../../../../../../assret/images/t_data.png'
import t_data_g from '../../../../../../../assret/images/t_data_g.png'
import './recommend.scss'

type Iprops = {
    currentTab: string,
}

const CommunityRecommend = (props:Iprops)=>{

    const { currentTab } = props
    const history = useHistory()
    const location:any = useLocation()
    const [data, setData] = useState([]);
    const [paramsObj, setParamsObj] = useState<any>({
        current: 1,
        pageSize: 10,
    });

    useEffect(() => {
        if(currentTab=='recommend'){
            if(location.state&&location.state.channel_id) paramsObj.channel_id = location.state.channel_id
            getGroupList({...paramsObj})
        }
    }, [currentTab])

    const getGroupList = async (data:any = {}) => {
        let param = {
            ...data
        }
        if(param.order_by)param.order_by=param.order_by=='ascend'?'asc':'desc'
        Object.keys(param).map(el => {
            if(!param[el]) delete param[el]
        })
        setParamsObj({...param})
        param.type = 1 //社群推荐分组为0
        const res:any = await postRequest(apiUrl.usergroupList, param);
        if(res && res['code'] === 1) {
            setData(res.data.group_list);
        }
    }

    const creatTable = ()=>{
        const columns:any = [
            {
                title: '分组名称',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '定义',
                dataIndex: 'remark',
                key: 'remark',
            },
            {
                title: '客户数量',
                dataIndex: 'member_num',
                key: 'member_num',
            },
            {
                title: '上月客户数量',
                dataIndex: 'last_month_member_num',
                key: 'last_month_member_num',
            },
            {
                title: '创建时间',
                dataIndex: 'created_at',
                key: 'created_at',
            },
            {
                title: '更新时间',
                dataIndex: 'udpate_time',
                key: 'udpate_time',
            },
            {
                title: '操作',
                dataIndex: 'option',
                key: 'option',
                render: ( text:string, record:any, index:number )=>
                    <>
                        <Tooltip placement="bottomRight" title={'查看人员'}>
                        <img src={t_member} className='table_icon' onClick={()=>{history.push('/sidebar/userGroupDetail',{id:record.id})}}></img>
                        </Tooltip>

                        {/* <Tooltip placement="bottomRight" title={'去运营'}>
                        <img src={!record.operation_id ? t_manage : t_manage_g} className='table_icon' onClick={() => {if(!record.operation_id) history.push('/sidebar/marketingAdd',{group_id:record.id,name:record.name})}}></img>
                        </Tooltip>
                        <Tooltip placement="bottomRight" title={'查看数据'}>
                        <img src={!record.operation_id ? t_data_g : t_data} className='table_icon' onClick={() => record.operation_id&&history.push({pathname: '/sidebar/marketingDetail', state: { id: record.operation_id}})}></img>
                        </Tooltip>                     */}
                        {/* <Button type="link" onClick={()=>{history.push('/sidebar/memberList',{recommend_group_id:record.id})}}>查看人员</Button>
                        <Button type="link" onClick={() => history.push('/sidebar/marketingAdd',{group_id:record.id,name:record.name})} disabled={record.operation_id?true:false}>去运营</Button>
                        <Button type="link" className={!record.operation_id?'color999':''} onClick={() => record.operation_id&&history.push({pathname: '/sidebar/marketingDetail', state: { id: record.operation_id}})}>查看数据</Button> */}
                    </>
            },
        ];
        
        return <Table 
                    dataSource={data} 
                    columns={columns} 
                    rowKey={'id'}     
                    pagination={false}                
                />
    }

    return(
        <div className="community_recommend">
            {
                creatTable()
            }
        </div>
    )
}

export default CommunityRecommend