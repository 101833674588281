import React from 'react';

import { Select, Row, Col, Radio, Table, Tooltip, Avatar, Typography, Icon } from 'antd';
import ReactEcharts from "echarts-for-react";
import { getRequest } from '../../api/http';
import { apiUrl } from '../../api/api_url';

import './index.scss'

import workbench from '../../assret/images/workbench.png'
import news from '../../assret/images/xiaoxi.png'
import goodAmind from '../../assret/images/goodAmind.png'
import coupon from '../../assret/images/coupon.png'
import circular from '../../assret/images/circular.png'
import addUser from '../../assret/images/addUser.png'
import close from '../../assret/images/close.png'

import index1 from '../../assret/images/index1.png'
import index2 from '../../assret/images/index2.png'
import index3 from '../../assret/images/index3.png'
import index4 from '../../assret/images/index4.png'
import index5 from '../../assret/images/index5.png'
import { getIsPermisson, messageCustom } from '../../utils/permisstionUtil';

const { Option } = Select;
const { Text } = Typography;

interface IndexHomeProps {
    history: any,
}

const columns: any = [
    {
        title: '菜品名称',
        dataIndex: 'name',
        key: 'name',
        ellipsis: {
            showTitle: false,
        },
        render: (name:any) => (
            <Tooltip placement="topLeft" title={name}>
              {name}
            </Tooltip>
        ),
    },
    {
        title: '销量',
        dataIndex: 'all_count',
        key: 'all_count',
        ellipsis: {
            showTitle: false,
        },
        render: (address:any) => (
            <Tooltip placement="topLeft" title={address}>
              {address}
            </Tooltip>
        ),
    },
    {
        title: '实收金额',
        dataIndex: 'price_all',
        key: 'price_all',
        ellipsis: {
            showTitle: false,
        },
        render: (address:any) => (
            <Tooltip placement="topLeft" title={address}>
              {address}
            </Tooltip>
        ),
    }
];

const user_consume_columns: any = [
    {
        title: '用户名称',
        key: 'nickname',
        ellipsis: {
            showTitle: false,
        },
        render: (userinfo: any ) => {
            if(userinfo) {
              return <Row type="flex" justify="start" align="middle">
                <Avatar src={userinfo.headimgurl} style={{marginRight: 10}} />
                <Text>{userinfo.nickname}</Text>
              </Row>
            }
            return <Row>--</Row>
        }
    },
    {
        title: '消费金额',
        dataIndex: 'consume_total',
        key: 'consume_total',
        ellipsis: {
            showTitle: false,
        },
        render: (address:any) => (
            <Tooltip placement="topLeft" title={address}>
              {address}
            </Tooltip>
        ),
    }
];

const user_change_columns: any = [
    {
        title: '用户名称',
        key: 'nickname',
        ellipsis: {
            showTitle: false,
        },
        render: (userinfo: any ) => {
            if(userinfo) {
              return <Row type="flex" justify="start" align="middle">
                <Avatar src={userinfo.headimgurl} style={{marginRight: 10}} />
                <Text>{userinfo.nickname}</Text>
              </Row>
            }
            return <Row>--</Row>
        }
    },
    {
        title: '充值金额',
        dataIndex: 'charge_total',
        key: 'charge_total',
        ellipsis: {
            showTitle: false,
        },
        render: (name:any) => (
            <Tooltip placement="topLeft" title={name}>
              {name}
            </Tooltip>
        ),
    }
];

class IndexHome extends React.Component<IndexHomeProps>{

    state = {
        quick: [
            {
                src: workbench,
                path: '/sidebar/windowManage',
                name: '桌面管理'
            },
            {
                src: news,
                path: '/sidebar/order_times',
                name: '实时消息'
            },
            {
                src: goodAmind,
                path: '/sidebar/goodsList',
                name: '商品管理'
            },
            {
                src: circular,
                path: '/sidebar/shopTable',
                name: '桌台管理'
            },
            {
                src: coupon,
                path: '/sidebar/volumeCenter',
                name: '营销工具'
            }
        ],
        option_new : { //折线图，店内实际收入
            tooltip: {
                trigger: 'axis'
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: ['06.22', '06.23', '06.24', '06.25', '06.26', '06.27', '06.29']
            },
            yAxis: {
                type: 'value'
            },
            series: [
                {
                    name: '店内实际收入',
                    type: 'line',
                    stack: '总量',
                    itemStyle: {
                        normal: {
                            color: '#1890FF',
                            lineStyle: {
                                color: '#1890FF'
                            }
                        }
                    },
                    data: [3412, 600, 800, 1135, 4564, 20, 500]
                },
            ]
        },
        option_num : { //折线图，订单数
            tooltip: {
                trigger: 'axis'
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: ['06.22', '06.23', '06.24', '06.25', '06.26', '06.27', '06.29']
            },
            yAxis: {
                type: 'value'
            },
            series: [
                {
                    name: '订单数',
                    type: 'line',
                    stack: '总量',
                    itemStyle: {
                        normal: {
                            color: '#1890FF',
                            lineStyle: {
                                color: '#1890FF'
                            }
                        }
                    },
                    data: [3412, 600, 800, 1135, 4564, 20, 500]
                },
            ]
        },
        initVal: 1,
        actual_sum: "0.00",//店内实际收入
        income_true: "0.00",//店内订单实收
        discount_today: "0.00",//今日优惠额
        order_today: 0,//今日订单数
        user_inc_today: 0,//今日新增普通用户数
        use_days: 0, //共使用*天
        create_time: "",//使用时间
        dish_sale_rank: [], //商品销量排行
        user_consume_rank: [], //用户消费金额排行
        user_charge_rank: [], //用户充值金额排行
        preferential: '今日优惠额',
        order: '今日订单数',
        new_user: '今日新增普通用户',
        add_store: true, //添加商品
        add_desk: true, //添加餐桌
        add_user: true, //添加员工
        open_store: false, //开店指南
        dish_status: false, //添加菜品状态
        table_status: false, //添加餐桌状态
        user_status: false, //添加用户状态
        own_shop: {
            is_open: true,
        }, //当前店铺信息
    }

    onGoPage(item: any) {
        var uni_key = '';
        switch(item.name){
            case '桌面管理':
                uni_key = '1_2_2_1';
                break;
            case '实时消息':
                uni_key = '1_2_2_2';
                break;
            case '商品管理':
                uni_key = '1_2_2_3';
                break;
            case '桌台管理':
                uni_key = '1_2_2_4';
                break;
            case '营销工具':
                uni_key = '1_2_2_5';
                break;
            case '添加商品':
                uni_key = '1_2_2_6';
                break;
            case '添加区域':
                uni_key = '1_2_2_10';
                break;
            case '添加餐桌':
                uni_key = '1_2_2_7';
                break;
            case '添加角色':
                uni_key = '1_2_2_9';
                break;
            case '添加账号':
                uni_key = '1_2_2_8';
                break;
        }
        if(getIsPermisson(uni_key)){
            this.props.history.push(item.path)
        }else{
            messageCustom()
        }
    }

    //折线图 按钮点击切换
    onLineStatus = (e: any) => {
        this.setState({
            initVal: e
        })
    }

    componentDidMount() {
        this._initInfo(0)
        this.onOpenStore()
    }

    //初始化，数据
    async _initInfo(type:any) {
        let params = {
            start_time: this.formatDate(Number(type)),
            end_time: this.formatDate(0)
        }
        if(type === 1) {
            params.end_time = this.formatDate(Number(type))
        }
        let a:any = {};
        let res:any = await getRequest(apiUrl.indexHomeList, params);
        if(res.code === 1){
            let { create_time, use_days, survey: { actual_sum, income_true, discount_today, order_today, user_inc_today }, dish_sale_rank, user_consume_rank, user_charge_rank } = res.data;
            console.log('bbb:',Array.isArray(a))
            console.log('aaa:',res.data.line.length > 0 ? res.data.line.line1.x.data : [])
            let line1: any = {};
            let line2: any = {};
            if(!Array.isArray(res.data.line)) {
                if(res.data.line) {
                    line1 = res.data.line.line1 ? res.data.line.line1 : [];
                    line2 = res.data.line.line2 ? res.data.line.line2 : [];
                }
            }
            //折线图  数据
            let option_new = {
                tooltip: {
                    trigger: 'axis',
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: line1.x.data,
                    axisLine: {
                        lineStyle: {
                            color: "#9B9B9B",
                        }
                    }
                },
                yAxis: {
                    type: 'value',
                    axisLine:   { 
                        show: false, 
                        lineStyle: {
                            color: "#9B9B9B",
                        }
                    }, 
                    axisTick: {
                        show: false
                    },
                    splitLine:  {    //网格线
                        lineStyle:{
                            type:'dashed'    //设置网格线类型 dotted：虚线   solid:实线
                        },
                        show:true //隐藏或显示
                    }
                },
                series: [
                    {
                        name: '店内实际收入（元）',
                        type: 'line',
                        stack: '总量',
                        //smooth: true,波浪图
                        itemStyle: {
                            normal: {
                                color: '#978AFB',
                                lineStyle: {
                                    color: '#978AFB'
                                }
                            }
                        },
                        data: line1.y.data
                    },
                ]
            }

            let option_num = {
                tooltip: {
                    trigger: 'axis'
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: line2.x.data,
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        name: '订单数',
                        type: 'line',
                        stack: '总量',
                        itemStyle: {
                            normal: {
                                color: '#978AFB',
                                lineStyle: {
                                    color: '#978AFB'
                                }
                            }
                        },
                        data: line2.y.data,
                    },
                ]
            }

            this.setState({
                actual_sum,
                income_true,
                discount_today,
                order_today,
                user_inc_today,
                create_time,
                use_days,
                dish_sale_rank,
                user_consume_rank,
                user_charge_rank,
                option_new,
                option_num
            })
        }
    }

    //开店指南
    async onOpenStore() {
        let res:any = await getRequest(apiUrl.stepStatus);
        let { dish_status, table_status, user_status } = res.data;
        if(res.code == 1) {
            this.setState(() => {
                return {
                    dish_status,
                    table_status,
                    user_status,
                }
            })
        }

    }

    //时间处理函数
    formatDate(type: number) {
        let date = new Date();
        let year:any = '';
        let month:any = '';
        let day:any = '';
        if(type === 1) {
            date.setTime(date.getTime()-24*60*60*1000);
            year = date.getFullYear();
            month = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : '0' + (date.getMonth() + 1);
            day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();

        } else if(type === 7) {
            date.setTime(date.getTime()-((24*60*60*1000) * 6));
            year = date.getFullYear();
            month = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : '0' + (date.getMonth() + 1);
            day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();

        } else {
            year = date.getFullYear();
            month = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : '0' + (date.getMonth() + 1);
            day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();
        }

        return year + '-' + month + '-' + day
    }

    handleChange = (value: any) => {
        this._initInfo(Number(value))
        this.onSelectUpdateStatus(Number(value))
    }

    //根据选择日期更改时间
    onSelectUpdateStatus(type:any) {
        if(type === 0) {
            this.setState({
                preferential: '今日优惠额',
                order: '今日订单数',
                new_user: '今日新增普通用户'
            })
        } else if(type === 1) {
            this.setState({
                preferential: '昨日优惠额',
                order: '昨日订单数',
                new_user: '昨日新增普通用户'
            })
        } else if(type === 7) {
            this.setState({
                preferential: '近七日优惠额',
                order: '近七日订单数',
                new_user: '近七日新增普通用户'
            })
        }
    }

    //获取当前店铺的状态
    onGetShopStatus() {
        let shop_list: any = localStorage.getItem('shop_list');
        let shop_id = localStorage.getItem('shop_id');
        let own_shop = JSON.parse(shop_list).filter((item: any) => item['shop_id'] == shop_id);
        this.setState({
            own_shop
        })
    }

    render() {
        const { own_shop, dish_status, table_status, user_status, open_store, add_store, add_desk, add_user, quick, initVal, actual_sum, income_true, discount_today, order_today, user_inc_today, create_time, use_days, dish_sale_rank, user_consume_rank, user_charge_rank, option_new, option_num, preferential, order, new_user  } = this.state;
        return <div className='index'>
            <Row style={{display: 'flex'}}>
                <Col span={16} className='index-left'>
                    <div className='index-left-status'>
                        <div className='index-left-status-date'>实时数据：</div>
                        {/* <div>

                        </div> */}
                        <Select defaultValue='0' className='index-left-status-select' onChange={this.handleChange}>
                            <Option value='0'>今天</Option>
                            <Option value='1'>昨天</Option>
                            <Option value='7'>近七天</Option>
                        </Select>
                    </div>
                    <div className='index-left-title'>
                        <Row justify="start" className='index-left-title-item'>
                            <Col className='index-left-title-item-list'>
                                <div className='index-left-title-main'>
                                    <div className='index-left-title-main-top'>¥{actual_sum}</div>
                                    <div className='index-left-title-main-bottom'>店内实际收入</div>
                                    <div className='index-left-title-main-icon'>
                                        <img className='index-left-title-main-icon-img' src={index1} />
                                    </div>
                                </div>

                            </Col>
                            <Col className='index-left-title-item-list'>
                                <div className='index-left-title-main'>
                                <div className='index-left-title-main-top'>¥{income_true}</div>
                                    <div className='index-left-title-main-bottom'>店内订单实收</div>
                                    <div className='index-left-title-main-icon2'>
                                        <img className='index-left-title-main-icon2-img' src={index2} />
                                    </div>
                                </div>
                            </Col>
                            <Col className='index-left-title-item-list'>
                                <div className='index-left-title-main'>
                                    <div className='index-left-title-main-top'>¥{discount_today}</div>
                                    <div className='index-left-title-main-bottom'>{preferential}</div>
                                    <div className='index-left-title-main-icon3'>
                                        <img className='index-left-title-main-icon3-img' src={index3} />
                                    </div>
                                </div>
                            </Col>
                            <Col className='index-left-title-item-list'>
                                <div className='index-left-title-main'>
                                    <div className='index-left-title-main-top'>{order_today}</div>
                                    <div className='index-left-title-main-bottom'>{order}</div>
                                    <div className='index-left-title-main-icon4'>
                                        <img className='index-left-title-main-icon4-img' src={index4} />
                                    </div>
                                </div>
                            </Col>
                            <Col className='index-left-title-item-list'>
                                <div className='index-left-title-main'>
                                    <div className='index-left-title-main-top'>{user_inc_today}</div>
                                    <div className='index-left-title-main-bottom'>{new_user}</div>
                                    <div className='index-left-title-main-icon5'>
                                        <img className='index-left-title-main-icon5-img' src={index5} />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className='index-left-line'>
                        <div className='index-left-line-tip'>
                            <div className='index-left-line-tip-left'>
                                <div className='index-left-line-tip-left-btn'>
                                    <div className={`index-left-line-tip-left-btn-left ${initVal == 1 ? 'index-left-line-tip-left-btn-active' : ''}`} onClick={() => this.onLineStatus(1)}>实收金额（元）</div>
                                    <div className={`index-left-line-tip-left-btn-right ${initVal == 2 ? 'index-left-line-tip-left-btn-active' : ''}`} onClick={() => this.onLineStatus(2)}>订单笔数</div>
                                </div>
                                {/* <Radio.Group defaultValue={initVal} onChange={this.onLineStatus}>
                                    <Radio.Button value={1}>店内实际收入（元）</Radio.Button>
                                    <Radio.Button value={2}>订单数</Radio.Button>
                                    
                                </Radio.Group> */}
                            </div>
                            <div className='index-left-line-tip-right'>
                                <span style={{width: '22px', height: '2px', backgroundColor: '#4A4A4A', display: 'inline-block',marginRight: '10px'}}></span>
                                近七天
                            </div>
                        </div>
                        <div style={{ height: '350px', width: '100%', paddingTop: '10px' }}>
                            <ReactEcharts option={initVal == 1 ? option_new : option_num} style={{ height: '350px', width: '100%' }} className='react_for_echarts' />
                        </div>
                    </div>
                    <div className='index-left-icon'>
                        <div className='index-left-icon-tip'>快捷功能</div>
                        <div className='index-left-icon-content'>
                            {
                                quick.map((item, index) => {
                                    return (
                                        <div className='index-left-icon-content-li' key={'item' + index} onClick={() => { this.onGoPage(item) }}>
                                            <div>
                                                <img className='index-left-icon-content-li-img' src={item.src} alt={item.name} />
                                            </div>
                                            <div className='index-left-icon-content-li-txt'>
                                                {item.name}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className='index-left-rank'>
                        <div className='index-left-rank-item'>
                        {/* <div className='index-left-rank-title'>排行榜</div> */}
                            <div className='index-left-rank-item-list'>
                                <div className='index-left-rank-item-list-top'>
                                    <span className='index-left-rank-item-list-top-left'>排行榜</span>
                                    <span className='index-left-rank-item-list-top-right'>商品销量（个）</span>
                                </div>
                                <div className='index-left-rank-item-list-tab'>
                                    <Table columns={columns} rowKey={"id"} dataSource={dish_sale_rank} scroll={{y: 270}} pagination={ false } />
                                </div>
                            </div>
                            <div className='index-left-rank-item-list'>
                                <div className='index-left-rank-item-list-top'>
                                    <span className='index-left-rank-item-list-top-left'>排行榜</span>
                                    <span className='index-left-rank-item-list-top-right'>用户消费金额（元）</span>
                                </div>
                                <div>
                                    <Table columns={user_consume_columns} rowKey={"id"} dataSource={user_consume_rank} scroll={{y: 270}} pagination={ false } />
                                </div>
                            </div>
                            <div className='index-left-rank-item-list'>
                                <div className='index-left-rank-item-list-top'>
                                    <span className='index-left-rank-item-list-top-left'>排行榜</span>
                                    <span className='index-left-rank-item-list-top-right'>用户充值金额（元）</span>
                                </div>
                                <div>
                                    <Table columns={user_change_columns} rowKey={"id"} dataSource={user_charge_rank} scroll={{y: 270}} pagination={ false } />
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col className='index-right'>
                    <div className='index-right-top'>
                        <div className='index-right-top-usedate'>使用时间</div>
                        <div className='index-right-top-owndate'>{create_time}</div>
                    </div>
                    <div className='index-right-alluse'>
                        <div>
                            共使用：<span className='index-right-alluse-days'>{use_days}天</span>
                        </div>
                        <div>
                            店铺状态：
                            <span className='index-right-alluse-days'>
                                {
                                    own_shop.is_open
                                    ?   '正常营业'
                                    :   '已打烊'
                                }
                            </span>
                        </div>
                    </div>
                </Col>
                {/* 开店指南 */}
                <div className="openBtn" onClick={() => {this.setState({open_store: true})}}>
                    <div>开店</div>
                    <div>指引</div>
                </div>
                {
                    open_store
                    ? <div className="openStore">
                        <div className="openStore-main">
                            <div className="openStore-main-title">
                                开店指南（门店）
                            </div>
                            <div className="openStore-main-tip">
                                按步骤操作可让你快速熟悉系统！
                            </div>
                            <div className="openStore-main-icon">
                                <div className="openStore-main-icon-list" onMouseEnter={() => {this.setState({add_store: false})}} onMouseLeave={() => {this.setState({add_store: true})}}>
                                    {
                                        add_store
                                        ? <div>
                                                <div className="openStore-main-icon-list-photo"><img src={goodAmind} /></div>
                                                <span>添加商品</span>
                                            </div>
                                        :   dish_status
                                                ? <div className="openStore-main-icon-list-hover">
                                                        已完成
                                                    </div>
                                                : <div className="openStore-main-icon-list-hover" onClick={() => {this.onGoPage({path: '/sidebar/goodsList',name: '添加商品'})}}>
                                                        添加菜品
                                                    </div>

                                    }
                                </div>
                                <div className="openStore-main-icon-list" onMouseEnter={() => {this.setState({add_desk: false})}} onMouseLeave={() => {this.setState({add_desk: true})}}>
                                    {
                                        add_desk
                                        ? <div>
                                                <div className="openStore-main-icon-list-photo"><img src={circular} /></div>
                                                <span>添加餐桌</span>
                                            </div>
                                        : table_status
                                            ?   <div className="openStore-main-icon-list-hover">
                                                    已完成
                                                </div>
                                            :   <div>
                                                    <div className="openStore-main-icon-list-hover" onClick={() => {this.onGoPage({path: '/sidebar/shopRegion',name: '添加区域'})}}>
                                                        添加区域
                                                    </div>
                                                    <div className="openStore-main-icon-list-hover" onClick={() => {this.onGoPage({path: '/sidebar/shopTable',name: '添加餐桌'})}}>
                                                        添加餐桌
                                                    </div>
                                                </div>
                                    }
                                </div>
                                <div className="openStore-main-icon-list" onMouseEnter={() => {this.setState({add_user: false})}} onMouseLeave={() => {this.setState({add_user: true})}}>
                                    {
                                        add_user
                                        ? <div>
                                            <div className="openStore-main-icon-list-photo"><img src={addUser} /></div>
                                            <span>添加员工</span>
                                        </div>
                                        : user_status
                                            ?   <div className="openStore-main-icon-list-hover">
                                                    已完成
                                                </div>
                                            :   <div>
                                                    <div className="openStore-main-icon-list-hover" onClick={() => {this.onGoPage({path: '/sidebar/systemRole',name: '添加角色'})}}>
                                                        添加角色
                                                    </div>
                                                    <div className="openStore-main-icon-list-hover" onClick={() => {this.onGoPage({path: '/sidebar/manageUser',name: '添加账号'})}}>
                                                        添加账号
                                                    </div>
                                                </div>

                                    }
                                </div>
                            </div>
                            <div className="openStore-main-close" onClick={() => {this.setState({open_store: false})}}>
                                <img src={close} alt=""/>
                            </div>
                        </div>
                    </div>
                    : ''
                }
            </Row>
        </div>
    }
}

export default IndexHome;