import React from 'react';
import { Table, } from 'antd'
import { RangePickerValue } from "antd/lib/date-picker/interface";
import { getRequest } from '../../api/http';
import { apiUrl } from '../../api/api_url';
import './newbusinessData.scss'
import { getIsPermisson, messageCustom } from '../../utils/permisstionUtil';
import goodsExport from '../../assret/images/goodsExport.png'

interface OtherCapitalProps {
    history: any,
}

class OtherCapital extends React.Component<OtherCapitalProps> {
    column = [
        {
            title: "日期",
            dataIndex: "day_desc",
            key: "day_desc",
        },
        {
            title: "人民币充值",
            dataIndex: "money_charge",
            key: "money_charge",
        },
        {
            title: "银行卡充值",
            dataIndex: "bank_charge",
            key: "bank_charge",
        },
        {
            title: '更新时间',
            dataIndex: 'updated_at',
            key: 'updated_at'
        }
    ];

    state = {
        list: [],
        preview: {},
        current: 1,
        pageSize: 10,
        count: 0,
        start_time: "",
        end_time: "",

        dataList: [
            {
                title: '人民币充值',
                value: 0,
            },
            {
                title: '银行卡充值',
                value: 0,
            },
        ]
    };

    componentDidMount() {
        this.setState({
            start_time: this.props.history.location.state.open_at,
            end_time: this.props.history.location.state.close_at
        }, () => {
            this._initInfo();
        })
    }

    // 初始化数据
    _initInfo = async () => {
        let { pageSize, current, start_time, end_time, dataList } = this.state;
        let params: any = {
            current,
            pageSize
        };
        if (start_time !== "") {
            params.start_time = start_time;
            params.end_time = end_time;
        }
        let res: any = await getRequest(apiUrl.newBusinessCapital, params);
        if (res.code === 1) {
            let list: any = res.data.list;
            dataList[0].value = res.data.money_charge_total;
            dataList[1].value = res.data.bank_charge_total;
            this.setState({
                count: res.data.count ? res.data.count : 0,
                list: list,
            });
        }
    };

    pageChange = (page: number) => {
        this.setState({
            current: page
        }, () => {
            this._initInfo();
        }
        );
    };

    // 导出
    exportTable = async () => {
        if(!getIsPermisson('1_3_2_1')){
            messageCustom();
            return;
          }
        const token = localStorage.getItem("token");
        const shop_id = localStorage.getItem("shop_id");
        window.open(`${process.env.REACT_APP_BASEURL}${apiUrl.newBusinessCapitalExport}?token=${token}&start_time=${this.state.start_time}&end_time=${this.state.end_time}&shop_id=${shop_id}`
        );
    };

    render() {
        let {
            list,
            current,
            pageSize,
            start_time,
            end_time,
            count,
            dataList
        } = this.state;

        return (
            <div className="income" style={{padding: '20px'}}>
                <div className='charge_title mb20'>其它资金</div>
                <div style={{display: 'flex', alignItems:'center', justifyContent: 'space-between', marginBottom:'20px'}}>
                    <div style={{fontSize: '18px', color: '#000'}}>
                        时间：{start_time}至{end_time}
                    </div>
                    <div className='export_btn' onClick={this.exportTable}>
                        <img src={goodsExport} />导出
                    </div>
                </div>

                <div className='business_wrap'>
                    {
                        dataList.map((item:any) => {
                            return <div className='business_item' key={item.title} style={{justifyContent:'center', alignItems:'center'}}>
                                <div className='business_top' style={{flex:'none'}}>
                                    <div className='business_title'>{item.title}</div>
                                </div>
                                <div className='business_value'><span>￥</span>{item.value}</div>
                            </div>
                        })
                    }
                </div>


            <div style={{background:'#fff'}} className='finance_table_wrap'>
                <Table
                    bordered
                    dataSource={list}
                    size="middle"
                    columns={this.column}
                    rowKey={"day"}
                    locale={{ emptyText: "暂无数据" }}
                    pagination={{
                        current,
                        total: count,
                        pageSize,
                        onChange: this.pageChange,
                        showQuickJumper: true,
                        showSizeChanger: true,
                        showTotal: (total: number) => `共${total}条`,
                        onShowSizeChange: (current: number, size: number) => { this.setState({ pageSize: size }, () => {  }) }
                    }}
                >
                </Table></div>


            </div>
        );
    }
}

export default OtherCapital;