import React from 'react';
import InsideNav from '../../../component/insideNav/insideNav';
import { collectChildNav } from '../../../utils/permisstionUtil';
import PrinterDepart from '../../printerManager/printerDepart';
import PrinterList from '../../printerManager/printerList';
import PrinterMsgList from '../../printerManager/printerMsgList';
import './printManage.scss'
import TiketStyle from './ticketStyle/ticketStyle';

type IProps = {
    history: any
}
type IState = {
    navList: any
    current_key: string
}
const navTable = [
    {
        title: '打印机列表',
        isActive: false,
        KEY: "PRINTER",
        uni_key: '2_3_1'
    },
    {
        title: '打印信息列表',
        isActive: false,
        KEY: "PRINTER_MSG_LIST",
        uni_key: '2_3_2'
    },
    {
        title: '打印部门',
        isActive: false,
        KEY: "PRINTER_DEPART",
        uni_key: '2_3_3'
    },
    {
        title: '小票样式',
        isActive: false,
        KEY: "TICKET_STYLE",
        uni_key: '2_3_4'
    }
]
class PrintManage extends React.Component<IProps,IState>{
    
    constructor(props:IProps) {
        super(props);
        this.state = {
            navList: [],
            current_key: "",
        }
    }

    componentDidMount(){
        this.initPageState();
    }

    initPageState = () =>{
        let navList = collectChildNav(navTable);
        let currentKey = localStorage.getItem('current_key') ?? navList[0].KEY;
        let isSetKey = false;
        navList.map((item:any) => {
            if(item.KEY == currentKey){
                item.isActive = true;
                isSetKey = true;
            }else{
                item.isActive = false;
            }
        })
        if(!isSetKey){
            currentKey = navList[0].KEY;
            navList[0].isActive = true;
        };
        this.setState({
            current_key: currentKey,
            navList: navList
        })
    }

    navCallBack(currentKey: string){
        localStorage.setItem('current_key',currentKey) 
        this.setState({
            current_key: currentKey
        })
    }

    render() {
        let { navList,current_key } = this.state;
        return <div>
            <InsideNav navList={navList} callBack={(key: string) =>this.navCallBack(key)}/>
            <div className="body">
                {
                    current_key === 'PRINTER'  ?  <PrinterList history={this.props.history}/>
                    :
                    current_key === 'PRINTER_MSG_LIST' ?  <PrinterMsgList />
                    :
                    current_key === 'PRINTER_DEPART' ?  <PrinterDepart history={this.props.history}/>
                    :
                    current_key === 'TICKET_STYLE' ?  <TiketStyle />
                    : <></>
                    
                }
            </div>
        </div>
    }

}

export default PrintManage;