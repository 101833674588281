import React, { useEffect, useState } from "react";
import { Row, Input, Select, DatePicker } from "antd";
import { getRequest } from "../../../../../api/http";
import { apiUrl } from "../../../../../api/api_url";

const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

type IfliterData = {
  [key: string]: number
}

// let fliterData: IfliterData = {
//   member_level: 0,
//   member_group: 0,
//   order_status: 0,
// }

type Iprops = {
  search(key: IfliterData):void
  currentTab: number
}

type ImemberLevelListItem = {
  created_at: string
  id: number
  name: string
  score: number
}

type ImemberGroupList = {
  id: number
  is_default: boolean
  is_difference_treat: boolean
  member_num: number
  name: string
  new_count: number
}

const FilterRow = ({search, currentTab}: Iprops) => {

  const [memberLevelList, setmemberLevelList] = useState<Array<ImemberLevelListItem>>([]);
  const [memberGroupList, setmemberGroupList] = useState<Array<ImemberGroupList>>([]);
  const [fliterData, setfliterData] = useState({
    member_level: 0,
    member_group: 0,
    order_status: 0,
  });
  
  useEffect(() => {
    getMemberLevelList();
    getMemberGroupList();
  }, []);

  useEffect(() => {
    setfliterData({
      member_level: 0,
      member_group: 0,
      order_status: 0,
    })
  },[currentTab])

  // 获取会员等级列表
  const getMemberLevelList = async() => {
    const res:any = await getRequest(apiUrl.fansLevelList);
    if(res && res['code'] === 1) {
      setmemberLevelList(res.data)
    }
  }
  
  // 获取分组信息
  const getMemberGroupList = async() => {
    const res: any = await getRequest(apiUrl.memberGroupList);
    if(res && res['code'] === 1) {
      setmemberGroupList(res.data.member_group_list)
    }
  }
  
  // 筛选会员等级
  const onLevelChange = (value: any) => {
    const data = {
      ...fliterData,
      member_level: value
    }
    setfliterData(data);
    search(data);
  }
  
  // 筛选分组
  const onMemberChange = (value: any) => {
    const data = {
      ...fliterData,
      member_group: value
    }
    setfliterData(data);
    search(data);
  }
  
  // 筛选订单状态
  const onOrderStatusChange = (value: any) => {
    const data = {
      ...fliterData,
      order_status: value
    }
    setfliterData(data);
    search(data);
  }
  

  return (
    <Row type="flex">
      <Row style={{ marginRight: 20 }}>
        用户等级：
        <Select
          showSearch
          style={{ width: 200 }}
          placeholder="用户等级"
          optionFilterProp="children"
          onChange={onLevelChange}
          allowClear
          value={fliterData.member_level}
          filterOption={(input, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=0 }
        >
          <Option value={0}>全部</Option>
          {
            memberLevelList.map((el) => <Option key={el.id} value={el.id}>{el.name}</Option>)
          }
        </Select>
      </Row>
      <Row style={{ marginRight: 20 }}>
      用户分组：
        <Select
          showSearch
          allowClear
          style={{ width: 200 }}
          placeholder="用户分组"
          optionFilterProp="children"
          onChange={onMemberChange}
          value={fliterData.member_group}
          filterOption={(input, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=0 }
        >
          <Option value={0}>全部</Option>
          {
            memberGroupList.map((el) => {
              return <Option key={el.id} value={el.id}>{el.name}</Option>
            })
          }
        </Select>
      </Row>
      <Row>
        订单状态：
        <Select
          showSearch
          allowClear
          style={{ width: 200 }}
          placeholder="订单状态"
          optionFilterProp="children"
          onChange={onOrderStatusChange}
          value={fliterData.order_status}
          filterOption={(input, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=0 }
        >
          <Option value={0}>全部</Option>
          <Option value={1}>用餐中</Option>
          {/* <Option value={3}>支付中</Option> */}
          <Option value={5}>已完成</Option>
        </Select>
      </Row>
    </Row>
  );
};

export default FilterRow;
