import React, { Component } from 'react';
import {observer} from "mobx-react";
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { Button, Row, Col, Table, Modal, message, Tooltip, Icon, Spin } from "antd";
import arrayMove from 'array-move';
import { getRequest, postRequest } from '../../api/http';
import { apiUrl } from '../../api/api_url';
import { Link } from "react-router-dom";
import btn_add from '../../assret/images/btn_add.png'
import t_delete from '../../assret/images/t_delete.png'
import t_edit from '../../assret/images/t_edit.png'
import './goodsSort.scss'
import { getIsPermisson, messageCustom } from '../../utils/permisstionUtil';

const DragHandle = SortableHandle(() => (
    <div className="">
        <div style={{height: '10px'}}><Icon type="caret-up" style={{height: '10px', color: '#000', fontSize: '20px'}} /></div>
        <div><Icon type="caret-down" style={{height: '10px', color: '#000', fontSize: '20px'}} /></div>
    </div>
));

const key_tip = 'updatable';

const SortableItem = SortableElement((props: any) => <tr {...props} />);
const SortableContainerTab = SortableContainer((props: any) => <tbody {...props} />);
interface GoodsState {
    history: any,
}
@observer
class SortableTable extends React.Component<GoodsState> {
    state = {
        sortList: [],
        currentPage: 1,
        pageSize: 200,
        count: 0,
    };

    columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            className: 'list_center drag-visible',
        },
        {
            title: '分类名称',
            dataIndex: 'name',
            key: 'name',
            className: 'list_center drag-visible',
        },
        // {
        //     title: '分类排序',
        //     dataIndex: 'order_by',
        //     key: 'order_by',
        //     className: 'list_center',
        // },
        {
            title: '关联商品',
            dataIndex: 'dishes_num',
            key: 'dishes_num',
            className: 'list_center',
        },
        {
            title: '创建时间',
            dataIndex: 'created_at',
            key: 'created_at',
            className: 'list_center',
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            className: 'list_center',
            render: (text:any,record:any,) => (
                <div className="btn_wrap">
                    <Tooltip placement="bottomRight" title={'编辑'}>
                        <img src= {t_edit} className='table_icon' onClick={()=>{
                            if(!getIsPermisson('2_2_2_2')){
                                messageCustom();
                                return;
                              }
                            this.props.history.push('/sidebar/goodsSortEdit',{id: record.id })}
                        }></img>
                    </Tooltip>
                    <Tooltip placement="bottomRight" title={'删除'}>
                        <img src={t_delete} className='table_icon' onClick={() => this.deleteGood(record.id, record.dishes_num, record.name)}></img>
                    </Tooltip>
                    {/* <Link className="list_btn" to={{pathname:'/sidebar/goodsSortEdit', state:{id: record.id}}}>编辑</Link>
                    <div style={{cursor: 'pointer'}} onClick={() => this.deleteGood(record.id, record.dishes_num, record.name)}>删除</div> */}
                </div>
            ),
        },
        {
            title: () => {
                return (
                    <div>
                        <span>排序</span>
                        <Tooltip title={'拖动这一列的图标进行排序'} className="tip">
                            <Icon type="question-circle" theme="filled" />
                        </Tooltip>
                    </div>
                )
            },
            className: 'list_center',
            render: () => <DragHandle />,
        },
    ];

    componentDidMount() {
        this.onInitList();
    }

    // 初始化列表
    onInitList = async () => {
        this.searchAction()
        this._initCount();
    }

    searchAction = async () => {
        const { pageSize } = this.state;
        const params: any = {
            current: 1,
            pageSize,
        };
        const res: any = await getRequest(apiUrl.goodSort, params);
        if (res.code === 1) {
            let temp = res.data;
            let new_temp = temp.map((item: any, idx:any) => {
                item.index = idx
                return (
                    item
                )
            })
            console.log('new_temp11:',new_temp)
            this.setState({
                sortList: new_temp,
            })
        }
    }

    _initCount = async () => {
        const res: any = await getRequest(apiUrl.goodSortCount, {});
        if (res.code === 1) {
            this.setState({
                count: res.data.num,
            })
        }
    }
    deleteGood = async (id:number,goodNum: number,name: string) => {
        if(!getIsPermisson('2_2_2_3')){
            messageCustom();
            return;
          }
        Modal.confirm({
            title: '删除分类',
            content: '确认删除分类' + name,
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                const params = {
                    id
                }
                const res:any = await postRequest(apiUrl.goodSortDelete,params);
                if(res.code === 1){
                    message.success({ content: '删除成功!', key_tip, duration: 2 });
                    this.searchAction();
                }
            },
            onCancel() {
            },
            });
    }

    //排序接口
    onSortTable = async (arrayList: any) => {
        let count = arrayList.length;
        let temp = arrayList.map((item: any, index: any) => {
            let obj: any = {};
            obj.id = item.id;
            obj.order_by = count - index;
            return (
                obj
            )
        })
        console.log(temp)
        console.log(typeof temp)
        const res:any = await getRequest(apiUrl.setCatSort,{arr:JSON.stringify(temp)});
        if(res.code === 1){

        }
    }

    pageChange = async (page:number) => {

        const { pageSize, } = this.state;
        const params:any = {
            current: page,
            pageSize: pageSize,
        };
        const res:any = await getRequest(apiUrl.goodSort,params);
        if(res.code === 1){
            let temp = res.data;
            let new_temp = temp.map((item: any, idx:any) => {
                item.index = idx
                return (
                    item
                )
            })
            this.setState({
                sortList: new_temp
            })
        }
    }

    onSortEnd = ({ oldIndex, newIndex }: any) => {
        if(!getIsPermisson('2_2_2_4')){
            messageCustom();
            return;
          }
        const dataSource: any = this.state.sortList;
        if (oldIndex !== newIndex) {
            const newData = arrayMove([].concat(dataSource), oldIndex, newIndex).filter(el => !!el);
            console.log('Sorted items: ', newData);
            this.onSortTable(newData)
            this.setState({ sortList: newData });
        }
    };

    DraggableBodyRow = ({ className, style, ...restProps }: any) => {
        console.log('DraggableBodyRow')
        const dataSource: any  = this.state.sortList;
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = dataSource.findIndex((x: any) => x.index === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };

    onGoPage = (url: any) => {
        this.props.history.push(url);
    }

    render() {
        const DraggableContainer = (props: any) => (
            
            <SortableContainerTab
                useDragHandle
                helperClass="row-dragging"
                onSortEnd={this.onSortEnd}
                {...props}
            />
        );
        return (
            <div className='padding20'>
                <div className='goods_btn'>
                    <div className='new_btn mb30 margin_right_20' onClick={() => {
                        if(!getIsPermisson('2_2_2_1')){
                            messageCustom();
                            return;
                        }
                        this.onGoPage(`/sidebar/GoodsAdd`)}
                        }>
                        <img src={btn_add}></img>
                        添加分类
                    </div>
                </div>
                <div className='padding15 background_fff border_radius_10'>
                    {/* <Row>
                        <Col span={24}>
                            <Link to={'/sidebar/GoodsAdd'}>
                                <Button type="primary" size="large" icon="plus">
                                    添加分类
                                </Button>
                            </Link>
                        </Col>
                    </Row> */}
                    <Table
                        pagination={false}
                        // pagination={{
                        //     defaultCurrent: 1,
                        //     total: this.state.count,
                        //     pageSize: this.state.pageSize,
                        //     onChange: this.pageChange,
                        //     showQuickJumper: true,
                        //     showSizeChanger: true,
                        //     showTotal: (total: number) => `共${total}条`,
                        //     onShowSizeChange: (current:number, size: number) => {this.setState({pageSize: size}, () => {this.onInitList();})}

                        // }}
                            dataSource={this.state.sortList}
                            columns={this.columns}
                            rowKey="index"
                            components={{
                                body: {
                                    wrapper: DraggableContainer,
                                    row: this.DraggableBodyRow,
                                },
                            }}
                        />
                </div>
            </div>
        );
    }
}

export default SortableTable