import { Row, Col, DatePicker, message, Avatar, Tooltip, Button, Table, Switch } from 'antd';
import React, { Component } from 'react';
import ReactEcharts from "echarts-for-react";
import { apiUrl } from '../../../api/api_url';
import { getRequest, postRequest } from '../../../api/http';
import { getIsPermisson, messageCustom } from '../../../utils/permisstionUtil';

import t_member from '../../../assret/images/t_member.png'
import t_manage from '../../../assret/images/t_manage.png'
import t_manage_g from '../../../assret/images/t_manage_g.png'

import styles from './comsumeBehavior.module.scss';


interface ComsumeBehaviorInfo {
    history?: any,
    user?: any,
    type_title?: string,
    current?: number,
    own_date?: any,
}

class ComsumeBehavior extends Component<ComsumeBehaviorInfo> {

    state = {
        option : {
            tooltip: {
                show:false,
                trigger: 'item',
                formatter: '{a} <br/>{b}: {c} ({d}%)'
            },
            legend: {
                orient: 'vertical',
                right: '0%',
                top: '144',
                icon: 'circle',
                itemGap: 20,
                data: [],
                textStyle: { //图例文字的样式
                    color: '#8D8D8D',
                    fontSize: 18,
                }
            },
            
            width: '100%',
            height: '100%',
            series: [
                {
                    name: '访问来源',
                    type: 'pie',
                    selectedMode: 'single',
                    radius: ['80%','100%'],
                    center: ['50%', '50%'],
        
                    label: {
                        position: 'inner'
                    },
                    labelLine: {
                        show: false
                    },
                },
                {
                    name: '',
                    type: 'pie',
                    radius: ['38%', '60%'],
                    label: {
                        formatter: '{b|{b}}\n{per|{c}%}',
                        backgroundColor: '#fff',
                        
                        borderColor: '#fff',
                        //borderWidth: 1,
                        borderRadius: 10,
                        width:70,
                        height:30,
                        shadowBlur:5,
                        //shadowOffsetX: 2,
                        // shadowOffsetY: 2,
                        //shadowColor: '#dad6fe',
                        // padding: [0, 7],
                        verticalAlign: 'middle',
                        align:'center',
                        fontSize: 14,
                        padding:[10,-70],
                        center: ['50%','50%'],
                        //lineHeight:10,
                        rich: {
                            a: {
                                color: 'red',
                                lineHeight: 22,
                                align: 'center',
                            },
                            b: {
                                fontWeight: 600,
                                align:'center',
                                 fontSize: 14,
                                 lineHeight:20,
                            },
                            per: {
                                align:'center',
                                fontWeight: 600,
                                 fontSize: 14,
                            }
                        }
                    },
                    data: [
                       
                    ]
                }
            ],
            graphic:[
                {
                    type: 'text',
                    left: 'center',
                }
            ],
        },
        memberList: [],
        total: 0,
        paramsObj: {
            page: 1,
            pageSize: 5,
        },
        page: 1,
        pageSize: 5,
        userList: [],
        pull_new_active_group: [],
        pull_new_active_group_total: 0,
        isLoading: false,
        pageSizeOptions: [
            '5','10','20','40'
        ],
        count: 0,
        rowSpans: [],
        data: [],
        cateList: [],
        tempObj: {
            start_at: '',
            end_at: ''
        },
        table_user_list: [], //列表
        current_tabble: 1,
    }

    componentDidMount() {
        this.setState({
            tempObj: this.props.own_date
        }, () => {
            console.log('tempObj:',this.state.tempObj)
            this._initDate(1)
            this.getRecommendGroupList()
            this.onRePurchaseUserList()
        })
    }

    componentWillReceiveProps(nextProps: any) {
        let current  = this.state.current_tabble;
        let tempObj = this.state.tempObj;
        console.log('nextProps.props:',this.props)
        console.log('nextProps.current:',nextProps.own_date)
        if(nextProps.current != current) {
            if(nextProps.current == 2) {
                this.onRePurchaseUserList()
                this.setState({
                    current_tabble: 2
                }, () => {
                    this._initDate(2)
                })
            } else {
                this.setState({
                    current_tabble: 1
                }, () => {
                    this._initDate(1)
                })
            }
        }
        console.log('sj:',tempObj.start_at != nextProps.own_date.start_at)
        if(tempObj.start_at != nextProps.own_date.start_at) {
            this.setState({
                tempObj: nextProps.own_date
            }, () => {
                this._initDate(current)
            })
        }
    }

    _initDate = async (current: any) => {
        let { tempObj } = this.state;
        let url = '';
        if(current == 1) {
            url = apiUrl.rePurchaseCountChart
        } else {
            url = apiUrl.rePurchaseGroupChart
        }
        let res: any = await getRequest(url,tempObj);
        if(res.code == 1) {
            let colums_list: any = [];
            res.data.list.map((item: any, index: any) => {
                colums_list.push(item.name)
            })

            let option: any = {
                tooltip: {
                    show:false,
                    trigger: 'item',
                    formatter: '{a} <br/>{b}: {c} ({d}%)'
                },
                legend: {
                    orient: 'vertical',
                    right: '0%',
                    top: '144',
                    icon: 'circle',
                    itemGap: 30,
                    data: colums_list,
                    textStyle: { //图例文字的样式
                        color: '#8D8D8D',
                        fontSize: 18,
                    }
                },
                width: '100%',
                height: '100%',
                color: ['#00149E','#7B72FF','#FFC372','#72D8FF','#FF3939','#FF5FFC','#72FF7C','#FFCD00'],
                series: [
                    {
                        name: '访问来源',
                        type: 'pie',
                        selectedMode: 'single',
                        radius: ['80%','100%'],
                        center: ['50%', '0%'],
            
                        label: {
                            position: 'inner'
                        },
                        labelLine: {
                            show: false
                        },
                    },
                    {
                        name: '',
                        type: 'pie',
                        radius: ['38%', '60%'],
                        label: {
                            formatter: '{b|{b}}\n{per|{d}%}',
                            backgroundColor: '#fff',
                            borderColor: '#fff',
                            //borderWidth: 1,
                            borderRadius: 10,
                            width:50,
                            height:30,
                            shadowBlur:1,
                            //shadowOffsetX: 2,
                            // shadowOffsetY: 2,
                            shadowColor: '#ccc',
                            // padding: [0, 7],
                            verticalAlign: 'middle',
                            align:'center',
                            fontSize: 14,
                            padding:[10,-70],
                            center: ['50%','50%'],
                            fontWeight: 600,
                            //lineHeight:10,
                            rich: {
                                a: {
                                    color: 'red',
                                    lineHeight: 22,
                                    align: 'center',
                                },
                                b: {
                                    fontWeight: 600,
                                    align:'center',
                                    fontSize: 14,
                                    lineHeight:20,
                                },
                                per: {
                                    align:'center',
                                    fontWeight: 600,
                                     fontSize: 14,
                                }
                            }
                        },
                        data: res.data.list
                    }
                ],
                graphic:[
                    {
                        type: 'text',
                        left: 'center',
                    }
                ],
            }
            this.setState({
                option,
                table_user_list: res.data.list
            }, () => {
                console.log('option:',option)
            })

        } else {
            message.error(res.message)
        }
    }

    //用户列表
    onRePurchaseUserList = async () => {
        let { page, pageSize, userList, tempObj } = this.state;
        let params = {
            page,
            pageSize,
            ...tempObj
        }
        let res: any = await getRequest(apiUrl.rePurchaseUserList, params);
        if(res.code == 1) {
            // this.setState({
            //     pull_new_active_group: res.data.list,
            //     pull_new_active_group_total: res.data.total
            // })
            let list;
            
            if(page == 1){
                list = res.data.list;
            }else{
                list = userList.concat(res.data.list)
            }
            if(res.data.list && res.data.list.length> 0){
                page++;
            }
            this.setState({
                userList: list,
                pull_new_active_group: list,
                pull_new_active_group_total: res.data.total,
                page
            })

        } else {
            message.error(res.message)
        } 
    }

    creatTableUserInfo = ()=>{
        const columns:any = [
            {
                title: '用户昵称',
                dataIndex: 'nickname',
                key: 'nickname',
                className: 'list_center',
            },
            {
                title: '用户等级',
                dataIndex: 'level',
                key: 'level',
                className: 'list_center',
            },
            {
                title: '用户类型',
                dataIndex: 'type',
                key: 'type',
                className: 'list_center',
            },
            {
                title: '消费次数',
                dataIndex: 'payTime',
                key: 'payTime',
            },
            {
                title: '复购活动',
                dataIndex: 'activity',
                key: 'activity',
            },
            {
                title: '活动内容',
                dataIndex: 'activity_type',
                key: 'activity_type',
            },
            {
                title: '消费金额',
                dataIndex: 'origin_fee',
                key: 'origin_fee',
            },
            {
                title: '累计消费金额',
                dataIndex: 'count_origin_fee',
                key: 'count_origin_fee',
            },
            {
                title: '最后消费时间',
                dataIndex: 'last_pay_time',
                key: 'last_pay_time',
            },
            // {
            //     title: '区别对待',
            //     dataIndex: 'is_difference_treat',
            //     key: 'is_difference_treat',
            //     className: 'list_center',
            //     render: (text:string, rowdata: any) => {
            //         return rowdata ? <Row>
            //             {rowdata.is_difference_treat ? "是" : "否"}
            //         </Row>
            //         : <Row></Row>
            //     }
            // },
            {
                title: '操作',
                dataIndex: 'option',
                key: 'option',
                className: 'list_center',
                render: ( text:string, record:any)=>
                    <Row type="flex" align='middle' justify='center'>
                        <Tooltip placement="bottomRight" title={'查看详情'}>
                            <img src={t_member} className='table_icon' onClick={()=>{
                                if(!getIsPermisson('1_6_5_2') && !getIsPermisson('1_6_00_4')){
                                    messageCustom();
                                    return;
                                  }
                                    this.props.history.push('/sidebar/memberList',{nickname:record.nickname})}
                                }></img>
                        </Tooltip>

                        {/* <Tooltip placement="bottomRight" title={'去运营'}>
                            <img src={!record.operation_id ? t_manage : t_manage_g} className='table_icon' onClick={() => {
                                if(!getIsPermisson('1_6_5_3') && !getIsPermisson('1_6_00_5')){
                                    messageCustom();
                                    return;
                                  }
                                 if(!record.operation_id){
                                    this.props.history.push('/sidebar/marketingAdd',{group_id:record.id,name:record.name,is_disabled:true})
                                 }
                            }}></img>
                        </Tooltip> */}
                        {/* <Tooltip placement="bottomRight" title={'查看数据'}>
                            <img src={!record.operation_id ? t_data_g : t_data} className='table_icon' onClick={() => record.operation_id&&history.push({pathname: '/sidebar/marketingDetail', state: { id: record.operation_id, name: record.name }})}></img>
                        </Tooltip> */}

                        {/* <span className="action_button" onClick={() => setGroupTreat(record)}>
                            <Switch checked={record.is_difference_treat ? true : false} size='small' style={{marginRight: '5px'}} />
                            {record.is_difference_treat ? "取消区别对待" : "区别对待"}
                        </span> */}

                    </Row>
            },
        ];

        const setGroupTreat = async (rowdata: any) => {
            if(!getIsPermisson('1_6_5_6') && !getIsPermisson('1_6_00_8')){
                messageCustom();
                return;
              }
            const res:any = await getRequest(apiUrl.setGroupTreat, { id: rowdata.id,  is_difference_treat: !rowdata.is_difference_treat });
            if(res && res.code === 1) {
                message.success('设置成功');
                let paramsObj: any = this.state.paramsObj;
                this.getRecommendGroupList({...paramsObj});
            } else {
                message.error(res.msg);
            }
        }

        return (
            <Table
                dataSource={this.state.pull_new_active_group}
                columns={columns}
                onChange={this.handleTableChange}
                rowKey={'id'}
                pagination={{
                    defaultCurrent: 1,
                    current: this.state.paramsObj.page,
                    pageSize: this.state.paramsObj.pageSize,
                    total: this.state.pull_new_active_group_total,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    pageSizeOptions: this.state.pageSizeOptions,
                    showTotal: (total: number) => `共${total}条`,
                }}
            />
        );
    }

    creatTable = ()=>{
        const columns:any = [
            {
                title: '复购营销活动',
                dataIndex: 'name',
                key: 'name',
                className: 'list_center',
            },
            {
                title: '活动分类',
                dataIndex: 'activity_num',
                key: 'activity_num',
                className: 'list_center',
            },
            {
                title: '优惠券分类',
                dataIndex: 'coupons_num',
                key: 'coupons_num',
                className: 'list_center',
            },
            {
                title: '触达人数',
                dataIndex: 'touch_up',
                key: 'touch_up',
                sorter:true,
            },
            {
                title: '已领数量',
                dataIndex: 'receive',
                key: 'receive',
                sorter:true,
            },
            {
                title: '复购转化人数',
                dataIndex: 'repurchase_num',
                key: 'repurchase_num',
                sorter:true,
            },
            {
                title: '复购转化率',
                dataIndex: 'repurchase_rate',
                key: 'repurchase_rate',
                sorter:true,
            },
            {
                title: '操作',
                dataIndex: 'option',
                key: 'option',
                className: 'list_center',
                render: ( text:string, record:any)=>
                    <Row type="flex" align='middle' justify='center'>
                        <Tooltip placement="bottomRight" title={'查看详情'}>
                            <img src={t_member} className='table_icon' onClick={()=>{
                                if(!getIsPermisson('1_6_5_2') && !getIsPermisson('1_6_00_4')){
                                    messageCustom();
                                    return;
                                  }
                                    this.props.history.push(record.path,{recommend_group_id:record.id})}
                                }></img>
                        </Tooltip>

                        {/* <Tooltip placement="bottomRight" title={'去运营'}>
                            <img src={!record.operation_id ? t_manage : t_manage_g} className='table_icon' onClick={() => {
                                if(!getIsPermisson('1_6_5_3') && !getIsPermisson('1_6_00_5')){
                                    messageCustom();
                                    return;
                                  }
                                 if(!record.operation_id){
                                    this.props.history.push('/sidebar/marketingAdd',{group_id:record.id,name:record.name,is_disabled:true})
                                 }
                            }}></img>
                        </Tooltip> */}
                        {/* <Tooltip placement="bottomRight" title={'查看数据'}>
                            <img src={!record.operation_id ? t_data_g : t_data} className='table_icon' onClick={() => record.operation_id&&history.push({pathname: '/sidebar/marketingDetail', state: { id: record.operation_id, name: record.name }})}></img>
                        </Tooltip> */}

                        {/* <span className="action_button" onClick={() => setGroupTreat(record)}>
                            <Switch checked={record.is_difference_treat ? true : false} size='small' style={{marginRight: '5px'}} />
                            {record.is_difference_treat ? "取消区别对待" : "区别对待"}
                        </span> */}

                    </Row>
            },
        ];

        const setGroupTreat = async (rowdata: any) => {
            if(!getIsPermisson('1_6_5_6') && !getIsPermisson('1_6_00_8')){
                messageCustom();
                return;
              }
            const res:any = await getRequest(apiUrl.setGroupTreat, { id: rowdata.id,  is_difference_treat: !rowdata.is_difference_treat });
            if(res && res.code === 1) {
                message.success('设置成功');
                let paramsObj: any = this.state.paramsObj;
                this.getRecommendGroupList({...paramsObj});
            } else {
                message.error(res.msg);
            }
        }

        return (
            <Table
                dataSource={this.state.table_user_list}
                columns={columns}
                onChange={this.handleTableChange}
                rowKey={'id'}
                pagination={false}
                // pagination={{
                //     defaultCurrent: 1,
                //     current: this.state.paramsObj.page,
                //     pageSize: this.state.paramsObj.pageSize,
                //     total: this.state.total,
                //     showQuickJumper: true,
                //     showSizeChanger: true,
                //     pageSizeOptions: this.state.pageSizeOptions,
                //     showTotal: (total: number) => `共${total}条`,
                // }}
            />
        );
    }

    getRecommendGroupList = async (data:any = {}) => {
        let param = {
            ...data,
            label_id: '3.00'
        }
        if(param.sortType)param.sortType=param.sortType=='ascend'?'asc':'desc'
        if(param.pageSize) {
            this.setState({
                paramsObj: param,
                isLoading: true
            })
        }
        const res:any = await getRequest(apiUrl.getRecommendGroup, param);
        if(res && res['code'] === 1) {
            this.setState({
                memberList: res.data.list,
                total: res.data.total,
                isLoading: false,
                data: this.dataInit(res.data.list)
            })
            // if(!data.label_id){
            //     this.getGroupCate(res.data.total)
            // }
        }
    }

    // getGroupCate = async (total: number) => {
    //     let res:any = await getRequest(apiUrl.groupCate)
    //     if(res && res.code == 1){
    //         let list = [{label_id: 0, label_name: '全部分组', active: true, count: total}]
    //         list = list.concat(res.data)
    //         this.setState({
    //             cateList: list
    //         })
    //     }
    // }

    dataInit = (data:any)=>{
        let dataArr:any = []
        let i = 0
        data.map((item:any,index:number)=>{
            if(item.items&&item.items.length>1){
                item.items.map((itemG:any,indexG:number)=>{
                    dataArr[i] = itemG
                    dataArr[i].label = item.label
                    dataArr[i].label_id = item.label_id
                    i++
                })
            }else{
                dataArr[i] = item.items[0]
                dataArr[i].label = item.label
                dataArr[i].label_id = item.label_id
                i++
            }
        })
        this.setState({
            rowSpans: this.getRowSpans(dataArr, 'label_id')
        })
        return dataArr
    }

    getRowSpans = (arr:any, key:string) => {
        let sameValueLength = 0;
        const rowSpans = [];
        for(let i = arr.length - 1; i >= 0; i--){
            if(i === 0) {
                rowSpans[i] = sameValueLength + 1;
                continue;
            }
            if(arr[i][key] === arr[i-1][key]) {
                rowSpans[i] = 0;
                sameValueLength++;
            } else {
                rowSpans[i] = sameValueLength + 1;
                sameValueLength = 0;
            }
        }
        return rowSpans;
    };

    handleTableChange = (pagination:any, filters:any, sorter:any) => {
        let paramsObj: any = this.state.paramsObj;
        this.getRecommendGroupList({
            ...paramsObj,
            page:pagination.current,
            pageSize:pagination.pageSize,
            sortField:sorter.field,
            sortType:sorter.order
        })
    };

    render() {

        let { option, current_tabble } = this.state;
        let { user, type_title } = this.props;

        return (
            <Row className={styles.comsumeBehavior}>
                <Col className={styles.comsumeBehavior_echatrs}>
                    <div className={styles.comsumeBehavior_echatrs_tip}>{type_title}</div>
                    <div>
                        <ReactEcharts option={ option } style={{height: '7rem',top: '-1rem',left: '-1rem'}} className={styles.comsumeBehavior_echatrs_react_for_echarts} />
                    </div>
                    <div className={styles.comsumeBehavior_userinfo}>
                        <div className={styles.comsumeBehavior_userinfo_top}>用户总数</div>
                        <div className={styles.comsumeBehavior_userinfo_bottom}>{user}</div>
                    </div>
                </Col>
                <Col className={styles.comsumeBehavior_table}>
                    {
                        current_tabble == 1
                        ?   this.creatTable()
                        :    this.creatTableUserInfo()
                    }
                    {/* {this.creatTable()} */}
                </Col>
            </Row>
        )
    }

}

export default ComsumeBehavior