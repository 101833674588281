import {observer} from "mobx-react";
import React from "react";
import {Button, Row, Col, Input, Table, Modal, message } from "antd";
import { getRequest, postRequest } from '../../../api/http';
import { apiUrl } from '../../../api/api_url';
import { getIsPermisson, messageCustom } from "../../../utils/permisstionUtil";

const key = 'region';

@observer
class ShopRegion extends React.Component<any> {
    regionId = -1;
    state = {
        visible: false,
        region: '',
        regionList: [],
        page: 1,
        pageSize: 10,
        total: 10
    }

    columns = [
        {
            title: '区域',
            dataIndex: 'name',
            key: 'name',
            className: 'list_center',
        },
        {
            title: '餐台数量',
            dataIndex: 'table_num',
            key: 'table_num',
            className: 'list_center',
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            className: 'list_center',
            render: (text:any,record:any,) => (
                <div className="btn_wrap">
                    <div className="list_btn" onClick={() => this.editList(record)}>编辑</div>
                    <div className="list_btn" onClick={() => this.deleteList(record)}>删除</div>
                </div>
            ),
        },
    ];

    componentDidMount(){
        this.onInitList();
    }

    // 初始化列表
    onInitList = async () => {
        let {page, pageSize} = this.state;
        let params = {
            page,
            pageSize
        }
        const res:any = await getRequest(apiUrl.regionList,params);
        if(res.code === 1){
            this.setState({
                regionList: res.data.list,
                total: res.data.total
            })
        }
    }

    // 删除列表
    deleteList = async (record:any) => {
        if(!getIsPermisson('2_8_2_4')){
            messageCustom();
            return;
          }
        Modal.confirm({
            title: '删除区域',
            content: `确认删除区域${record.name}`,
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk: async () => {
                const res:any = await postRequest(apiUrl.regionDel,{region_id: record.id});
                if(res.code === 1){
                    message.success({ content: '删除成功!', key, duration: 2 });
                }else{
                    message.error({ content: '删除失败!', key, duration: 2 });
                }
                this.onInitList();
            },
            onCancel() {
            },
        });
    }

    handleCancel = () => {
        const { visible } = this.state;
        this.setState({
            visible: !visible
        })
    }

    regionChange = (e:any) => {
        this.setState({
            region: e.target.value.trim()
        })
    }

    // 添加区域
    handleOk = async () => {
        if(!getIsPermisson('2_8_2_1')){
            messageCustom();
            return;
          }
        const { region } = this.state;
        console.log(this.regionId);

        if(this.regionId !== -1){
            const res:any = await postRequest(apiUrl.regionEdit,{name: region, region_id: this.regionId});
            if(res.code === 1){
                this.setState({
                    visible: false
                })
                message.success({ content: '编辑成功!', key, duration: 2 });
                this.regionId = -1;
            }else{
                message.success({ content: '编辑失败!', key, duration: 2 });
                this.regionId = -1;
            }
        }else{
            const res:any = await postRequest(apiUrl.regionAdd,{name: region});
            if(res.code === 1){
                this.setState({
                    visible: false
                })
                message.success({ content: '添加成功!', key, duration: 2 });
            }else{
                message.success({ content: '添加失败!', key, duration: 2 });
            }
        }
        this.onInitList();
    }

    // 编辑区域
    editList = (record:any) => {
        if(!getIsPermisson('2_8_2_3')){
            messageCustom();
            return;
          }
        this.setState({
            visible: true,
            region: record.name,
        })
        this.regionId = record.id
    }

    orderChange = (page: any) => {
        this.setState({
            page: page
        }, () => {
            this.onInitList()
        })
    }

    render(){
        const { visible, region } = this.state;

        return <div className='l_content'>
            <Row className="mb30">
                <Col span={24}>
                    <Button type="primary" size="large" icon="plus" onClick={this.handleCancel}>
                        添加区域
                    </Button>
                </Col>
            </Row>

            <div>
                <Table dataSource={this.state.regionList} size="middle" columns={this.columns} rowKey={"id"} locale={{emptyText: '暂无数据'}} pagination={{
                            current: this.state.page,
                            showQuickJumper:true,
                            defaultCurrent: 1,
                            total: this.state.total,
                            pageSize: this.state.pageSize,
                            hideOnSinglePage: true,
                            onChange: this.orderChange
                        }}/>
            </div>

            <Modal
                title='添加区域'
                visible={visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                centered
                okText="确认"
                cancelText="取消"
                >
                    <Row type="flex" align="middle">
                        <Col span={4}>
                            <span>区域名称</span>
                        </Col>
                        <Col span={10}>
                            <Input placeholder="请输入区域名称" value={region} onChange={(e) => this.regionChange(e)}/>
                        </Col>
                    </Row>
            </Modal>
        </div>;
    }
}
export default ShopRegion