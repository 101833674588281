import React, { useEffect, useState } from 'react'
import { Table, Badge } from 'antd'
import { getRequest } from '../../../../api/http'
import { apiUrl } from '../../../../api/api_url'

type Iprops={
    actionRecharge:boolean
}

const SmsRechargeList = (props:Iprops)=>{

    const { actionRecharge } = props
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [paramsObj, setParamsObj] = useState<any>({
        page: 1,
        pageSize: 10,
    });
    const statusList:any = { 1:'待支付', 2:'支付成功', 3:'支付失败'}
    const channelList:any = { 1:'微信', 2:'支付宝', 3:'余额抵扣' }
    const badgeList:any = { 1:'default', 2:'success', 3:'error' }

    useEffect(()=>{
        getSmsList({...paramsObj})
    },[actionRecharge])

    const getSmsList = async (data:any = {}) => {
        let param = {
            ...data
        }
        if(param.sortType)param.sortType=param.sortType=='ascend'?'asc':'desc'
        setParamsObj({...param})
        const res:any = await getRequest(apiUrl.getSmsRechargeList, param);
        if(res && res['code'] === 1) {
            setData(res.data.data);
            setTotal(res.data.total)
        }
    }

    const handleTableChange = (pagination:any, filters:any, sorter:any) => {
        getSmsList({
            ...paramsObj,
            page:pagination.current,
            pageSize:pagination.pageSize,
            sortField:sorter.field,
            sortType:sorter.order
        })
    }

    const creatTable = ()=>{
        const columns:any = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: '充值时间',
                dataIndex: 'created_at',
                key: 'created_at',
            },
            {
                title: '充值条数',
                dataIndex: 'num',
                key: 'num',
            },
            {
                title: '充值金额',
                dataIndex: 'amount',
                key: 'amount',
            },
            {
                title: '充值来源',
                dataIndex: 'pay_channel',
                key: 'pay_channel',
                render: (text:string,record:any,index:number) => channelList[text]
            },
            {
                title: '充值状态',
                dataIndex: 'status',
                key: 'status',
                render: (text:string,record:any,index:number) =>
                    <>
                        <Badge status={badgeList[text]} />
                        <span>{statusList[text]}</span>
                    </>
            },
        ];

        return (
            <Table
                dataSource={data}
                columns={columns}
                onChange={handleTableChange}
                rowKey={'id'}
                pagination={{
                    defaultCurrent: 1,
                    current:paramsObj.page,
                    pageSize: paramsObj.pageSize,
                    total: total,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    showTotal: (total: number) => `共${total}条`,
                }}
            />
        );
    }

    return(
        <div className='l_content'>
            <div className="fz16 color333 fweight mb20 ">充值记录</div>
            {
                creatTable()
            }
        </div>
    )
}

export default SmsRechargeList