import React from 'react';
import { Row, Col } from 'antd';
import '../management.scss';

import winAddDeskList from '../../../../../../assret/images/winAddDeskList.png';
import winAddDeskToast from '../../../../../../assret/images/winAddDeskToast.png';

interface ShopManagementDetail {
    history: any,
}

class ShopManagementDetail extends React.Component<ShopManagementDetail> {

    render() {
        return  <Row className="typeDetail">
                    <Col className="typeDetail-title">一、功能介绍</Col>
                    <Col className="typeDetail-col">通过位置管理，可按区域分配服务员，还有可以让服务员快速知道该餐桌具体在哪个位置。</Col>
                    <Col className="typeDetail-title">二、功能路径</Col>
                    <Col className="typeDetail-col">电脑端登录后台-桌台管理-位置管理-添加位置。</Col>
                    <Col className="typeDetail-col">
                        <img src={winAddDeskList} />
                    </Col>
                    <Col className="typeDetail-title">三、操作流程</Col>
                    <Col className="typeDetail-col">1、<span className="txt-bold">添加区域</span>，根据系统提示设置区域名称</Col>
                    <Col className="typeDetail-col">
                        <img src={winAddDeskToast} />
                    </Col>
                </Row>
    }
}

export default ShopManagementDetail;