import React, { useEffect, useState } from 'react'
import { message, Row, Input, Radio, DatePicker, Icon, Button, Table, Tooltip } from 'antd'
import { useHistory,  } from 'react-router-dom'
import { postRequest, getRequest } from '../../../api/http'
import { apiUrl } from '../../../api/api_url'
import del_icon from '../../../assret/images/goodEditDel.png'
import GoodsModal from '../components/goodsModal'
import { getDate } from '../../../utils/common'
import moment from 'moment'

import './index.scss'
const { RangePicker } = DatePicker;
const DepositAdd = (props:any)=>{

    const history = useHistory()

    const [ currentTab, setCurrentTab ] = useState(-1)
    const timeTab = [ '7天', '15天', '30天', '60天', '90天', '选择日期' ]
    const [ visible ,setVisible ] = useState(false)
    const [ timeType, setTimeType ] = useState(0)
    const [ dataSource, setDataSource ] = useState<any>([])
    const [ formVal, setFormVal ] = useState<any>({
        // mobile: '' , // | 手机号
        realname: '' , // | 寄存人姓名
        dishes: '' , // | 菜品 （示例：[{"id":7829,"name":"荷花浊酒","num":2},{"id":8324,"name":"虾饺 半份","num":2},{"id":7830,"name":"桃花酒","num":2}] id为菜品id，num是寄存数量）
        pushd_at: '',
        expired_at: '' , // | 过期时间，永久则不传，示例：2020-12-30 23:59:59
    })

    const [ mobile, setMobile ] = useState('')
    const [ loading, setLoading ] = useState(false)
    const columns = [
        {
            title: '菜品名称',
            dataIndex: 'name',
            render:(text:any,record:any)=> text+(record.sepc?'('+record.sepc+')':'')
        },
        {
            title: '寄存数量',
            dataIndex: 'num',
            render: (text:any,record:any,index:any) => <>
                <Row type="flex" align="middle" justify="center">
                    <Icon type="minus-circle" style={{color: '#ff3f3f', fontSize: 25}}  onClick={() => reduceNum(index)} />
                    <Input value={record.num} style={{width:50, border:0,textAlign:'center'}} onChange={(e)=>{changenumber(e.currentTarget.value,index)}}/>
                    <Icon type="plus-circle" theme="filled" style={{color: '#ff3f3f', fontSize: 25}} onClick={() => addNum(index)} />
                </Row>
            </>            
        },
        {
            title: '操作',
            dataIndex: 'action',
            render: ( text:string, record:any, index:number)=><Row type="flex" align='middle' justify='center'>
                <Tooltip placement="bottomRight" title={'删除'}>
                    <img src={del_icon} className='table_icon' onClick={()=>{del(index)}}></img>
                </Tooltip>
            </Row>
        },
    ];

    useEffect(()=>{

    },[])

    const changeFieldVal = (e:any, field:string) => {
        console.log('changeFieldVal----')
        console.log(e)
        console.log(field)
        let fieldObj:any = {}
        if(field === 'times'){
            if(e[0]){
                fieldObj['pushd_at'] = e[0]
                fieldObj['expired_at'] = e[1]
            }else{
                fieldObj['pushd_at'] = ''
                fieldObj['expired_at'] = ''
            }
        }else{
            fieldObj = { [field]: e }
            
        }
        setFormVal({
            ...formVal,
            ...fieldObj
        })
    }

    const reduceNum = (index:number) => {
        let dataSourceTemp = JSON.parse(JSON.stringify(dataSource))
        let number = dataSourceTemp[index].num
        if(number <= 1){
            return
        }
        number--
        dataSourceTemp[index].num = number
        setDataSource(dataSourceTemp)
    }

    const addNum = (index:number) =>{
        let dataSourceTemp = JSON.parse(JSON.stringify(dataSource))
        let number = dataSourceTemp[index].num
        number++;
        dataSourceTemp[index].num = number
        setDataSource(dataSourceTemp)
    }

    const changenumber = (value:any,index:any) => {
        let dataSourceTemp = JSON.parse(JSON.stringify(dataSource))
        dataSourceTemp[index].num = value
        setDataSource(dataSourceTemp)
    }

    const del = (index:any) => {
        let dataSourceTemp = JSON.parse(JSON.stringify(dataSource))
        dataSourceTemp.splice(index,1)
        setDataSource(dataSourceTemp)
    }

    const onsubmit = async() =>{
        if(!mobile){
            message.error('请输入手机号')
            return false
        }
        let regex = /^1[3|4|5|6|7|8|9][0-9]{9}$/
        if (!regex.test(mobile)) {
            message.error('请输入正确的手机号')
            return false
        }
        if(!formVal.realname){
            message.error('请输入寄存人姓名')
            return false
        }
        if(dataSource.length === 0){
            message.error('请选择商品')
            return false
        }
        if(timeType === 1){
            if(!formVal.pushd_at||!formVal.expired_at){
                message.error('请选择有效时间')
                return false
            }
        }

        let formValTemp = JSON.parse(JSON.stringify(formVal))
        formValTemp.dishes = JSON.stringify(dataSource)
        formValTemp.mobile = mobile
        if(timeType!==1){
            delete formValTemp.pushd_at
            delete formValTemp.expired_at
        }else{
            formValTemp.pushd_at = formValTemp.pushd_at + ' 00:00:00'
            formValTemp.expired_at = formValTemp.expired_at + ' 23:59:59'
        }
        setLoading(true)
        const res:any = await postRequest(apiUrl.pushStorageDishs,formValTemp)
        setLoading(false)
        if(res.code === 1){
            message.success('保存成功')
            history.goBack()
        }else{
            message.error(res.message||'保存失败')
        }
    }

    const goBack = () =>{
        history.goBack()
    }
    
    const selectGoods = (data:any) => {
        let dataSourceTemp = JSON.parse(JSON.stringify(dataSource))
        //去重

        dataSourceTemp = dataSourceTemp.concat(data.filter((item:any)=>{
            let specName = item.specs&&item.specs.length>0&&item.specs.filter((subItem:any)=>subItem.checked).length>0?item.specs.filter((subItem:any)=>subItem.checked)[0].name:''
            // let specName = spec[item.id]&&spec[item.id].value.filter((subItem:any)=>subItem.checked).length>0?spec[item.id].value.filter((subItem:any)=>subItem.checked)[0].value:''
            let index = dataSourceTemp.findIndex((subItem:any)=>subItem.id===item.id)
            return index === -1 || dataSourceTemp.findIndex((item:any)=>item.sepc===specName) === -1
        }))
        setDataSource(dataSourceTemp.map((item:any)=>{
            // let specName = spec[item.id]&&spec[item.id].value.filter((subItem:any)=>subItem.checked).length>0?spec[item.id].value.filter((subItem:any)=>subItem.checked)[0].value:''
            // let specIndex = spec[item.id]?spec[item.id].value.findIndex((subItem:any)=>subItem.checked):0
            let specIndex = item.specs&&item.specs.length>0&&item.specs.findIndex((item:any)=>item.checked)
            let specName = item.specs&&item.specs.length>0&&item.specs.filter((item:any)=>item.checked).length>0?item.specs.filter((item:any)=>item.checked)[0].name:''
            return {
                only_id:item.only_id?item.only_id:item.id+'-'+specIndex,
                id:item.id,
                name:item.name,
                num:item.num?item.num:1,
                sepc:item.sepc?item.sepc:specName, 
            }
        }))
    }

    const openModal = () => {
        setVisible(true)
    }

    const closeModal = () => {
        setVisible(false)
    }

    const timeTabClick = (index:number) => {
        if(index === currentTab) return false
        setCurrentTab(index)

        let end = ''
        let timer = {
            start_time:'',
            end_time: ''
        }
        switch(index){
            case 0:
                end = getDate(new Date(getDate()).getTime() + 1000 * 60 * 60 * 24 * 6)
                timer = {
                    start_time: getDate(),
                    end_time: end,
                }                    
                break;
            case 1:
                end = getDate(new Date(getDate()).getTime() + 1000 * 60 * 60 * 24 * 14)
                timer = {
                    start_time: getDate(),
                    end_time: end,
                }                         
                break;
            case 2:
                end = getDate(new Date(getDate()).getTime() + 1000 * 60 * 60 * 24 * 29)
                timer = {
                    start_time: getDate(),
                    end_time: end,
                }                         
                break;
            case 3:
                end = getDate(new Date(getDate()).getTime() + 1000 * 60 * 60 * 24 * 59)
                timer = {
                    start_time: getDate(),
                    end_time: end,
                }                         
                break;
            case 4:
                end = getDate(new Date(getDate()).getTime() + 1000 * 60 * 60 * 24 * 89)
                timer = {
                    start_time: getDate(),
                    end_time: end,
                }                         
                break;
            case 5:
                break;
        }
        if(index !== 5){
            changeFieldVal([timer.start_time,timer.end_time],'times')
        }
    }

    const changeRadio = (e:any) => {
        setTimeType(e.target.value)
    }

    const changeMobile = async(value:any) => {
        let regex = /^[0-9]*$/
        if(value.length>11||!regex.test(value)){
            setMobile(mobile)
        }else{
            setMobile(value)
        }
        if(value.length === 11){
            let regex = /^1[3|4|5|6|7|8|9][0-9]{9}$/
            if (!regex.test(value)) {
                message.error('请输入正确的手机号')
                return false
            }
            const res:any = await getRequest(apiUrl.getNickByMibile,{mobile:value})
            if(res.code === 1){
                changeFieldVal(res.data.nickname,'realname')
            }
        }
    }

    const disabledDate = (current:any) => {
        console.log(current)
        // Can not select days before today and today
        return current && current < moment(new Date()).add(-1, 'days');
    }

    return (
        <div className="deposit_add">
            <div className="title_text">添加寄存</div>
            <div className="card">
                <div className="sub_title">寄存人信息</div>
                <Row className="card_item" type="flex" align="middle">
                    <div className="card_item_left">寄存人手机号：</div>
                    <div className="card_item_right">
                        <Row type="flex" align="top">
                            <Input value={mobile} maxLength={11} placeholder="请输入寄存人手机号" style={{width:284}} className="mr20" onChange={(e)=>{changeMobile(e.currentTarget.value)}}/>
                            {/* <Input value={formVal.realname} placeholder="请输寄存人姓名" style={{width:284}} onChange={(e)=>{changeFieldVal(e.currentTarget.value,'realname')}}/> */}
                        </Row>
                    </div>
                </Row>
                <Row className="card_item" type="flex" align="middle">
                    <div className="card_item_left">寄存人姓名：</div>
                    <div className="card_item_right">
                        <Row type="flex" align="top">
                            {/* <Input value={mobile} maxLength={11} placeholder="请输入手机号" style={{width:284}} className="mr20" onChange={(e)=>{changeMobile(e.currentTarget.value)}}/> */}
                            <Input value={formVal.realname} placeholder="请输入寄存人姓名" style={{width:284}} onChange={(e)=>{changeFieldVal(e.currentTarget.value,'realname')}}/>
                        </Row>
                    </div>
                </Row>
            </div>
            <div className="card">
                <div className="sub_title">寄存的商品</div>
                <Row className="card_item" type="flex" align="top">
                    <div className="card_item_left card_item_select_goods"><span>*</span>选择商品：</div>
                    <div className="card_item_right">
                        <div className="card_item_add" onClick={()=>{openModal()}}><Icon type="plus" /></div>
                        {
                            dataSource.length>0&&<Table 
                                style={{width:550}}
                                dataSource={dataSource} 
                                columns={columns} 
                                pagination={false}
                                rowKey={'only_id'}
                            />
                        }
                    </div>
                </Row>
                <Row className="card_item" type="flex" align="top">
                    <div className="card_item_left"><span>*</span>有效时间：</div>
                    <div className="card_item_right">
                        <Radio.Group className="card_item_radio" value={timeType} onChange={(e)=>{changeRadio(e)}}>
                            <Radio value={0}>永久</Radio>
                            <Radio value={1}>自定义</Radio>
                        </Radio.Group>
                        {
                            timeType === 1 &&(
                                <Row type="flex" align="middle" className="time_tab">
                                    {
                                        timeTab.map((item,index)=>{
                                            return <div key={index} className={`tab_item ${currentTab===index?'tab_item_active':''}`} onClick={()=>{timeTabClick(index)}}>{item}</div>
                                        })
                                    }
                                    {
                                        currentTab===5&&(
                                            <RangePicker 
                                                disabledDate={disabledDate}
                                                style={{width:230}}
                                                // value={[moment(statisticsParams.pushd_at, 'YYYY-MM-DD'),moment(statisticsParams.end_at, 'YYYY-MM-DD')]}
                                                onChange={(dates,dateString)=>{changeFieldVal(dateString,'times')}}
                                            />  
                                        )
                                    }
                                </Row>                                
                            )
                        }
                    </div>
                </Row>
            </div>
            <Row type="flex" align="middle" justify="center" className="footer">
                <Button type="primary" className="mr20" onClick={()=>{onsubmit()}} loading={loading}>保 存</Button>
                <Button onClick={()=>{goBack()}}>取 消</Button>
            </Row>
            {
                visible&&<GoodsModal visible={visible} selectGoods={(data:any)=>{selectGoods(data)}} closeModal={()=>{closeModal()}} checkData={dataSource}/>
            }
        </div>
    )
}

export default DepositAdd
