import React, { Fragment, useEffect, useState } from 'react'
import { Row, Table, Avatar, Form, Button, Col, Select, DatePicker, Spin } from 'antd'
import DataSummaryItem from '../distribution/distribution_order/order_list/components/data_summary_item'
import { useHistory } from 'react-router-dom'
import { getRequest } from '../../api/http'
import { apiUrl } from '../../api/api_url'
import { searchUserList } from '../../utils/utils'
import { locale } from 'moment'

interface IOverview {
    name:string, // 店铺名称
    id: number,      // 店铺id
    paid:string, // 支付收入
    refund:string, // 退款支出
    deposit:string, // 充值收入
    withdraw:string, // 提现支出
}

interface Ilists {
  items: Array<{
    id: number
    bill_type: number
    relation_type: number
    relation_id: number
    amount: string
    mark: string
    user_id: number
    created_at: string
    updated_at: string
    asset_type: string
    mp_id: number
    shop_id: number
    user: {
      id: number
      headimgurl: string
      nickname: string
      phone_number: string
    }
    bill_type_display: string
    relation_type_display: string
    code: string
  }>
  total: number
}

const { Option } = Select;
const { RangePicker } = DatePicker;

let formPmars = {
  created_start: '', // 开始时间
  created_end: '', // 结束时间
  relation_type: 0,  // 订单类型  1 购买商品 2商品退款 3提现 4提现手续费 5用户充值 6佣金
  bill_type: 0,     // 收支类型  1 收入 2支出
  user_id: ''
};

const FinancialManagement = () => {
  const history:any = useHistory();
  const [pageSize, setpageSize] = useState(10);
  const [fetching, setfetching] = useState(false);
  const [overView, setoverView] = useState<IOverview>({
    name: '',
    id: 0,
    paid: '0.00',
    refund: '0.00',
    deposit: '0.00',
    withdraw: '0.00',
  });
  const [lists, setlists] = useState<Ilists>({
    items: [],
    total: 0
  })
  const [searchUsersTipsList, setsearchUsersTipsList] = useState<Array<{nickname: string, id: number}>>([]);

  useEffect(() => {
    if(history.location.state.id) {
      getShopDetail();
    }
  }, []);

  const getShopDetail = async (curr = 1, data = {}) => {
    let parms = {
      current: curr,
      pageSize: pageSize,
      id: history.location.state.id,
      ...data
    }
    const res:any = await getRequest(apiUrl.shopBalanceBillList, parms);
    if(res && res.data) {
      setoverView(res.data.overview);
      setlists({
        total: res.data.total,
        items: [...res.data.items]
      })
    }
  }

  const columns = [{
    title: "用户昵称",
    key: "avatar",
    render: (rowdata: any) => {
      if(rowdata && rowdata.user) {
        return <Row type="flex" align="middle">
          <Avatar src={rowdata.user.headimgurl} />
          <Row>{rowdata.user.nickname}</Row>
        </Row>
      }
      return <Row></Row>
    }
  },{
    title: "收支类型",
    dataIndex: "bill_type_display",
    key: "bill_type_display",
  },{
    title: "账单类型",
    dataIndex: "relation_type_display",
    key: "relation_type_display",
  },{
    title: "账单金额（元）",
    dataIndex: "amount",
    key: "amount",
  },{
    title: "订单号",
    dataIndex: "code",
    key: "code",
  },{
    title: "生成时间",
    dataIndex: "created_at",
    key: "created_at",
  },]

  const bill_type = [{
    text: '全部',
    type: 0,
  },{
    text: '收入',
    type: 1
  },{
    text: '支出',
    type: 2
  }]
  const relation_type = [{
    text: '全部',
    type: 0,
  },{
    text: '购买商品',
    type: 1
  },{
    text: '商品退款',
    type: 2
  },{
    text: '提现',
    type: 3
  },{
    text: '提现手续费',
    type: 4
  },{
    text: '用户充值',
    type: 5
  },{
    text: '佣金',
    type: 6
  }]

  const onFormChange = (key: string, val: any) => {
    if(key === 'time') {
      formPmars['created_start'] = val[0].format('YYYY-MM-DD HH:mm:ss')
      formPmars['created_end'] = val[1].format('YYYY-MM-DD HH:mm:ss')
    } else if(key === 'user') {
      formPmars['user_id'] = val.key;
    }else {
      formPmars = {...formPmars, [key]: val};
    }
    const _data = JSON.parse(JSON.stringify(formPmars));
    Object.keys(_data).map((key: string) => {
      if(!_data[key]) {
        delete _data[key]
      }
    })
    getShopDetail(1, _data)
  }

  const pageOnChange = (e: any) => {
    const _data = JSON.parse(JSON.stringify(formPmars));
    Object.keys(_data).map((key: string) => {
      if(!_data[key]) {
        delete _data[key]
      }
    })
    getShopDetail(e, _data)
  }

  const getUserList = async (name: string) => {
    if(!name) return;
    setfetching(true);
    const res:any = await getRequest(apiUrl.searchUsers, { nickname: name });
    if(res && res.data) {
      setsearchUsersTipsList(res.data);
    }
    setfetching(false);
  }

  return <Fragment>
      <Row>
        <DataSummaryItem
          // tips="当前活动总收款笔数"
          mainTitle={`${overView.paid}`}
          subTitle="点餐实付总收入（元）"
        />
        <DataSummaryItem
          // tips="当前活动总收款金额"
          mainTitle={`${overView.deposit}`}
          subTitle="用户充值总收入（元）"
        />
        <DataSummaryItem
          // tips="当前活动一二级返现总金额"
          mainTitle={`${overView.withdraw}`}
          subTitle="用户佣金提现总额（元）"
        />
        <DataSummaryItem
          // tips="当前活动扣除返现之后的实收收益"
          mainTitle={`${overView.refund}`}
          subTitle="用户退款总额（元）"
        />
      </Row>
      <Button type="primary">导出</Button>
      <Form>
        <Row type="flex">
          <Form.Item style={{ minWidth: 250 }}>
            <Row type="flex">
              <Col span={7}>收支类型：</Col>
              <Col span={12}>
                <Select onChange={(e: any) => onFormChange("bill_type", e)} defaultValue={0}>
                  {bill_type.map(item => {
                    return (<Option key={item.text} value={item.type}>{item.text}</Option>);
                  })}
                </Select>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item>
            <Row type="flex" style={{width: 300}}>
              <Col span={7}>账单类型：</Col>
              <Col span={12}>
                <Select onChange={(e: any) => onFormChange("relation_type", e)} defaultValue={0}>
                  {relation_type.map(item => {
                    return (<Option key={item.text} value={item.type}>{item.text}</Option>);
                  })}
                </Select>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item>
            <Row type="flex" style={{width: 300}}>
              <Col span={7}>用户昵称：</Col>
              <Col span={12}>
                {/* <AutoComplete 
                  onSelect={(e) => onFormChange('user_id', e)} 
                  onChange={(e) => getUserList(e.toString())}
                  dataSource={searchUsersTipsList}
                /> */}
                <Select
                  labelInValue
                  showSearch
                  placeholder="搜索用户"
                  autoClearSearchValue={false}
                  notFoundContent={fetching ? <Spin size="small" /> : null}
                  filterOption={false}
                  onSearch={getUserList}
                  onChange={(e:any) => onFormChange('user', e)}
                  style={{ width: '100%' }}
                >
                  {searchUsersTipsList.map(d => (
                    <Option key={d.id}>{d.nickname}</Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item>
            <Row type="flex" style={{width: 300}}>
              <Col span={7} offset={1}>生成时间：</Col>
              <Col span={12} offset={0}>
                <RangePicker
                  locale={locale}
                  style={{width: 400}}
                  showTime={true}
                  onChange={(e) => onFormChange('time', e)}
                  format="YYYY-MM-DD HH:mm:ss"
                />
              </Col>
            </Row>
          </Form.Item>
        </Row>
      </Form>

      <Table
       size="middle"
      rowKey={"id"}
      dataSource={lists.items}
      columns={columns}
      pagination={{ 
        pageSize: 10, 
        total: lists.total, 
        onChange: (e: number) => pageOnChange(e) 
      }}
    />
      
  </Fragment>
}

export default FinancialManagement