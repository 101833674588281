import React from 'react';
import { Row, Col } from 'antd';
import '../management.scss';

import winAddMeal from '../../../../../../assret/images/winAddMeal.png';
import winAddMealImg from '../../../../../../assret/images/winAddMealImg.png';
import winAddMealMust from '../../../../../../assret/images/winAddMealMust.png';
import winAddMealNun from '../../../../../../assret/images/winAddMealNun.png';

interface MealManagementDetail {
    history: any,
}

class MealManagementDetail extends React.Component<MealManagementDetail> {

    render() {
        return  <Row className="typeDetail">
                    <Col className="typeDetail-title">一、功能介绍</Col>
                    <Col className="typeDetail-col">通过添加套餐发布，您可以将套餐上架到点餐小程序或只展示给商家看。</Col>
                    <Col className="typeDetail-title">二、功能路径</Col>
                    <Col className="typeDetail-col">电脑端登录后台-商品管理-套餐管理-添加套餐。</Col>
                    <Col className="typeDetail-col">
                        <img src={winAddMeal} />
                    </Col>
                    <Col className="typeDetail-title">三、操作流程</Col>
                    <Col className="typeDetail-col">1、<span className="txt-bold">选择套餐图片</span>，根据系统提示设置套餐编号、套餐名称、套餐分类、商品口味、套餐售价、会员价、套餐菜品，“ <span style={{color: '#f00'}}>*</span> ” 为必填项。套餐图建议尺寸：若分类展示样式为横图，建议上传封面图宽高为470*206；若分类展示样式为竖图，建议上传封面图宽高为230*270</Col>
                    <Col className="typeDetail-col"><img src={winAddMealImg} /></Col>
                    <Col className="typeDetail-col">2、<span className="txt-bold">套餐渠道</span>，当选择小程序时，商家后台点餐页和点餐小程序同时显示，当选择其他渠道时，只在商家点餐页显示</Col>
                    <Col className="typeDetail-col">3、<span className="txt-bold">添加分类</span>，当没有分类时，点击添加分类，创建新的分类</Col>
                    <Col className="typeDetail-col">4、<span className="txt-bold">套餐菜品</span>，点击添加分组时，添加套餐菜品</Col>
                    <Col className="typeDetail-col">5、<span className="txt-bold">展开更多设置、是否参与折扣、是否开台必点</span></Col>
                    <Col className="typeDetail-col"><span className="txt-bold">是否参与折扣：</span>用户买单该套餐可以使用优惠券优惠</Col>
                    <Col className="typeDetail-col">
                        <img src={winAddMealMust} />
                    </Col>
                    <Col className="typeDetail-col">6、<span className="txt-bold">添加分组</span>，种类名称、消费者可选数量、菜品数量</Col>
                    <Col className="typeDetail-col"><span className="txt-bold">种类名称</span>：例如添加的菜全部是锅底，填写锅底</Col>
                    <Col className="typeDetail-col"><span className="txt-bold">消费者可选数量</span>：例如添加了10个菜，你只想给用户选择3个菜品，则填写3</Col>
                    <Col className="typeDetail-col"><span className="txt-bold">数量</span>：当填写该菜品数量为2时，用户点该菜品数量为2</Col>
                    <Col className="typeDetail-col"><span className="txt-bold">是否为必填：</span>当选择该菜品时必填时，用户下单该套餐，必点该菜品</Col>
                    <Col className="typeDetail-col">
                        <img src={winAddMealNun} />
                    </Col>
                </Row>
    }
}

export default MealManagementDetail;