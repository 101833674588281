import React from 'react';
import InsideNav from '../../../component/insideNav/insideNav';
import { collectChildNav, getIsPermisson } from '../../../utils/permisstionUtil';
import IndexHome from '../../index';
import ProductIntroduction from '../../index/components/productIntroduction/productIntroduction';
import './home.scss'

type IProps = {
    history: any
}
type IState = {
    navList: any
    current_key: string
}

const navTable = [
    {
        title: '产品介绍',
        isActive: false,
        KEY: "PRODUCT_INTRODUCTION",
        uni_key: '1_2_1'
    },
    {
        title: '概况',
        isActive: false,
        KEY: "INDEX",
        uni_key: '1_2_2'
    }
]

class Home extends React.Component<IProps,IState>{
    
    constructor(props:IProps) {
        super(props);
        this.state = {
            navList: [],
            current_key: "",
        }
    }

    componentDidMount(){
        this.initPageState();
    }

    initPageState = () =>{
        let navList = collectChildNav(navTable);
        let currentKey = localStorage.getItem('current_key') ?? navList[0].KEY;
        let isSetKey = false;
        navList.map((item:any) => {
            if(item.KEY == currentKey){
                item.isActive = true;
                isSetKey = true;
            }else{
                item.isActive = false;
            }
        })
        if(!isSetKey){
            currentKey = navList[0].KEY;
            navList[0].isActive = true;
        };
        this.setState({
            current_key: currentKey,
            navList: navList
        })
    }

    navCallBack(currentKey: string){
        localStorage.setItem('current_key',currentKey) 
        this.setState({
            current_key: currentKey
        })
    }
    render() {
        let { navList,current_key } = this.state;
        return <div>
            <InsideNav navList={navList} callBack={(key: string) =>this.navCallBack(key)}/>
            <div className="body">
                {
                    current_key === 'PRODUCT_INTRODUCTION' ?  <ProductIntroduction history={this.props.history} />
                    :
                    current_key === 'INDEX' ?  <IndexHome history={this.props.history}  /> : <div />
                   
                }
            </div>
        </div>
    }

}

export default Home;