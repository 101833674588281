import React, { useState,useEffect } from 'react'
import { List, Tree } from 'antd';

import './treeCom.scss'
const { TreeNode } = Tree;

type ParamType = {
  title: string
  allPermissions: any
  permissions: any
}

type IProps =  {
    callBack: Function,
    params: ParamType
}


const TreeCom = (props: IProps) =>{
    const [checkedKeys, setCheckedKeys] = useState<any>([]);
    const [halfCheckedKeys,setHalfCheckedKeys] = useState([]);

    useEffect(()=>{
      initComState()
    },[props.params])

    const initComState = () =>{
        console.log("props.params.permissions",props.params.permissions)
      setCheckedKeys(props.params.permissions)
    }

    const onCheck = (checkedKeys:any, e:any) => {
        setHalfCheckedKeys(e.halfCheckedKeys);
        setCheckedKeys(checkedKeys);
        // var allPermssions = [...e.halfCheckedKeys].concat(checkedKeys)
        var allPermissions = {
            root_pc_web: e.halfCheckedKeys,
            pc_web: checkedKeys
        }
        props.callBack("PCWEB",allPermissions)
    };

    
    // 生成 数节点
    const renderTreeNodes = (data: any) =>
        data.map((item: any) => {
            if (item.routers) {
                return (
                    <TreeNode title={item.name+'-'+item.id} key={item.id} dataRef={item}>
                        {renderTreeNodes(item.routers)}
                    </TreeNode>
                );
            }
            return <TreeNode title={item.name+'-'+item.id} key={item.id}/>;
        });

    return (
        <div className='tree_com'>
            <div className="com_title">{props.params.title}</div>
            <div className="tree_content">
                {
                    props.params.allPermissions.length > 0 ?
                    <Tree
                        checkable
                        checkedKeys={checkedKeys}
                        onCheck={onCheck}
                        >
                        {renderTreeNodes(props.params.allPermissions)}
                    </Tree>
                    :
                    <div/>
                }
           
            </div>
        </div>
    )
}

export default TreeCom;