import React, { useEffect, useState, useRef } from 'react'
import ReactEcharts from "echarts-for-react";
import './lineChart.scss'

type Iprops = {
    color1:String,
    color2:String,
    bg1:String,
    bg2:String,
    current:any,
    prev:any,
    bottomLeftTitle:String, 
    bottomRightTitle:String,
}

const LineChart = (props:Iprops)=>{
    
    const { color1, color2, bg1, bg2, current, prev, bottomLeftTitle, bottomRightTitle} = props

    const [option, setOption] = useState<any>({
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985'
                }
            }
        },
        legend: {
            data: [bottomLeftTitle, bottomRightTitle],
            bottom: '15px',
            itemHeight: 15,
            borderColor :'yellow',
            icon:'circle'
        },
        color : [ color1, color2],
        grid: {
            left: '3%',
            right: '4%',
            bottom: '60px',
            top: '8%',
            containLabel: true,
            show:true,
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: false,
                data: [0, 1, 2, 3, 4, 5, 6],
            },
        ],
        yAxis: [
            {
                type: 'value',
            }
        ],        
        series: [
            {
                name: bottomLeftTitle,
                type: 'line',
                // stack: '总量',
                areaStyle: {type: 'default',color:bg1},//内容颜色
                symbol: 'none', // 拐点类型
                smooth: true,
                data: [0,0,0,0,0,0,0,],
            },
            {
                name: bottomRightTitle,
                type: 'line',
                areaStyle: {type: 'default',color:bg2},//内容颜色
                symbol: 'none', // 拐点类型
                smooth: true,
                data: [0,0,0,0,0,0,0,],
            },
        ],
    });

    useEffect(()=>{
        if(current&&prev){
            //初始化数据
            let optionTemp:any = JSON.parse(JSON.stringify(option))
            let xData:any = []
            let data1:any = []
            let data2:any = []
            current.items.map((item:any,index:number)=>{
                xData.push(item.key)
                data1.push(item.value)
            })
            prev.items.map((item:any,index:number)=>{
                data2.push(item.value)
            })
            optionTemp.xAxis[0].data = xData
            optionTemp.series[0].data = data1
            optionTemp.series[1].data = data2
            optionTemp.series[0].name = bottomLeftTitle
            optionTemp.series[1].name = bottomRightTitle
            optionTemp.legend.data = [bottomLeftTitle, bottomRightTitle]
            setOption(optionTemp)
        }
    },[current])

    const progress:any = {
        num: 0.8,
        text: '6439.68',
    }

    return (
        <div className="arcProgress">
            <div style={{ height: '350px', width: '100%' }}>
                <ReactEcharts option={option} style={{ height: '100%', width: '100%' }} className='react_for_echarts' />
            </div> 
        </div>
    )
}

export default LineChart



