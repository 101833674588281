import React from 'react'
import { Row, Col, Select, Tooltip, Radio, Input, Table, Icon, Modal} from 'antd'
import {getRequest} from "../../../api/http";
import {apiUrl} from "../../../api/api_url";
import i_statistic_icon from '../../../assret/images/i_statistic_icon.jpg'
import './turnplatePerson.scss';

const { Search } = Input;



interface TurnplatePersonProps {
    location:any
}

interface TurnplatePersonState {

}

class TurnplatePerson extends React.Component<TurnplatePersonProps, TurnplatePersonState> {
    id = -1;

    state = {
        visible: false,
        pageSize: 10,
        mpList: [
            {
                'id': 0,
                'nick_name': "",//公众号名字
                'head_img': "",//公众号头像
                'service_type_info':- {
                    'id': 0 //公众号类型 0-订阅号 1-由历史老帐号升级后的订阅号 2-服务号 小程序默认值为0
                },
                'verify_type_info':- {
                    'id': 0//公众号或小程序认证类型 (小程序：-1-未认证 0-微信认证) (公众号：-1-未认证 0-微信认证 1-新浪微博认证 2-腾讯微博认证 3-已资质认证通过但还未通过名称认证 4-已资质认证通过、还未通过名称认证，但通过了新浪微博认证 5-已资质认证通过、还未通过名称认证，但通过了腾讯微博认证)
                },
                'create_time': 0
            }
        ],
        numList:[
            {
                'num': 0,
                'text': '参与活动人数',
                'tips': '当前活动总收款笔数'
            },
            {
                'num': 0,
                'text': '受邀总人数',
                'tips': '当前活动总收款笔数'
            },
            {
                'num': 0,
                'text': '发放奖品人数',
                'tips': '当前活动总收款笔数'
            }
        ],
        personList: [],
        awardList: []
    }

    columns = [
        {
          title: '用户',
          dataIndex: 'user',
          key: 'user',
          className: 'list_center',
          render: (text:any, record:any) => {
            return <div className="btn_wrap">
                <img src={record.avatar} alt="商品头图" className="img_list"/>
                <div className="name_list">{record.nickname}</div>
            </div>
          }
        },
        {
            title: '所属活动',
            dataIndex: 'luck_name',
            key: 'luck_name',
            className: 'list_center',
        },
        {
            title: '所属公众号',
            dataIndex: 'mp_name',
            key: 'mp_name',
            className: 'list_center',
        },
        {
            title: '关注状态',
            dataIndex: 'subscribe',
            key: 'subscribe',
            className: 'list_center',
            render: (text:any,record:any,) => (
                <div className="btn_wrap color_grey">
                    {record.subscribe === 0 ? '未关注' : '已关注'}
                </div>
            ),
        },
        {
            title: '邀请人数',
            dataIndex: 'invite_people_num',
            key: 'invite_people_num',
            className: 'list_center',
        },
        {
            title: '已领取奖品',
            dataIndex: 'luck_num',
            key: 'luck_num',
            className: 'list_center',
            render: (text:any,record:any,) => (
                <div className="btn_wrap" onClick={() => this.handleModel(record.wx_user_id)}>
                    {record.luck_num}
                </div>
            ),
        },
        // {
        //     title: '操作',
        //     dataIndex: 'action',
        //     key: 'action',
        //     className: 'list_center',
        //     render: (text:any,record:any,) => (
        //         <div className="btn_wrap">
        //             <div className="list_btn" onClick={() => this.addList(record)}>加入黑名单</div>
        //             <div className="list_btn" onClick={() => this.removeList(record)}>移出黑名单</div>
        //         </div>
        //     ),
        // },
    ];

    columnsList = [
        {
          title: '所属活动',
          dataIndex: 'luck_name',
          key: 'luck_name',
          className: 'list_center',
        },
        {
            title: '奖品名称',
            dataIndex: 'award_name',
            key: 'award_name',
            className: 'list_center',
        },
        {
            title: '领取时间',
            dataIndex: 'created_at',
            key: 'created_at',
            className: 'list_center',
        },
        {
            title: '核销状态',
            dataIndex: 'status',
            key: 'status',
            className: 'list_center',
            render: (text:any,record:any,) => (
                <div className="btn_wrap color_grey">
                    {record.status}
                </div>
            ),
        },
    ];

    componentDidMount(): void {
        this._initMpList();
        // this.propsParam = this.props.location.state;
        if(this.props.location.state != undefined){
            this.id = this.props.location.state.id;
            this._initUserList(this.props.location.state.id);
            this.staticStatus(this.props.location.state.id);
        }
    }

    // 参与人员
    _initUserList = async (id: number) => {
        const param = {
            id: id
        };
        const res:any = await getRequest(apiUrl.joinPeople,param);
        if(res && res.code === 1){
            this.setState({
                personList: res.data
            })
        }
    }

    // 统计情况
    staticStatus = async (id: number) => {
        const { numList } = this.state;
        const param = {
            id: id
        };
        const res:any = await getRequest(apiUrl.luckStatistics,param);
        if(res && res.code === 1){
            numList[0].num = res.data.join_people_num;
            numList[1].num = res.data.invite_people_num;
            numList[2].num = res.data.luck_people_num;
            this.setState({ numList });
        }
    }

    // 搜索用户昵称
    changeName = async (name: string) => {
        const param = {
            id: this.id,
            nickname: name
        };
        const res:any = await getRequest(apiUrl.joinPeople,param);
        if(res && res.code === 1){
            this.setState({
                personList: res.data
            })
        }
    }

    // 公众号列表
    _initMpList = async () => {
        const param = {
            pageSize: 100
        };
        const res:any = await getRequest(apiUrl.authAccountList,param);
        if(res && res.code === 1){
            this.setState({
                mpList: res.data.list
            })
        }
    }

    // 公众号筛选
    mpChange = async (id:number) => {
        // const param = {
        //     page: 1,
        //     mp_id: id
        // };
        // const res:any = await getRequest(apiUrl.luckList,param);
        // if(res && res.code === 1){
        //     this.setState({
        //         activityList: res.data
        //     })
        // }
    }

    addList = (e:any) => {

    }

    removeList = (e:any) => {

    }

    // 当前公众号选择
    handleChange = () => {

    }

    pageChange = () => {

    }

    getList = async (userId: number) => {
        const param = {
            id: this.id,
            wx_user_id: userId
        };
        const res:any = await getRequest(apiUrl.awardList,param);
        if(res && res.code === 1){
            this.setState({
                awardList: res.data
            })
        }
    }

    // 已领取奖品
    handleModel = (id: number) => {
        const visible = this.state.visible;
        if(!visible){
            this.getList(id);
        }
        this.setState({
            visible: !visible
        })
    }

    cancelModel = () => {
        const visible = this.state.visible;
        this.setState({
            visible: !visible
        })
    }

    render() {
        const { numList, personList, pageSize, visible, awardList} = this.state;

        return (
            <div className='l_content'>
                <Row type="flex" align="middle">
                    {/* <Col>
                        <div className="col_title">当前公众号：</div>
                    </Col> */}
                    {/* <Col span={3}>
                        <Select placeholder='全部' style={{ width: 120 }} onChange={this.mpChange}>
                            {
                                mpList.map((item,idx) => {
                                    return <Option value={item.id} key={item.id}>{item.nick_name}</Option>
                                })
                            }
                        </Select>
                    </Col> */}

                    {/* <Col span={2}>
                        <div className="col_title">当前活动：</div>
                    </Col>
                    <Col span={3}>
                        <Select defaultValue="lucy" style={{ width: 120 }} onChange={this.handleChange}>
                            <Option value="jack">Jack</Option>
                            <Option value="lucy">Lucy</Option>
                            <Option value="Yiminghe">yiminghe</Option>
                        </Select>
                    </Col> */}
                </Row>
                <div className="person_wrap">
                    <Row gutter={[16, 16]}>
                        {
                            numList.map((item, index) => {
                                return (
                                    <Col span={8} key={index}>
                                        <div className="activity_person">
                                            <div>
                                                <img src={i_statistic_icon} alt="" className="person_avatar" />
                                            </div>
                                            <div className="num_wrap">
                                                <div className="person_num">{item.num}</div>
                                                <div className="person_tips">{item.text}</div>
                                            </div>
                                            <Tooltip placement="leftTop" title={item.tips}>
                                                <div className="tips_btn">
                                                    <Icon type="exclamation-circle" theme="filled" />
                                                </div>
                                            </Tooltip>
                                        </div>
                                    </Col>
                                )
                            })
                        }

                    </Row>
                </div>
                <div className="table_wrap">
                    <div className="table_top">
                        <Row type="flex" justify="space-between">
                            <Col span={3}>
                                <Radio.Group defaultValue="a" buttonStyle="solid">
                                    <Radio.Button value="a">参与用户</Radio.Button>
                                    <Radio.Button value="b">黑名单</Radio.Button>
                                </Radio.Group>
                            </Col>
                            <Col span={5}>
                                <div className="top_r">
                                    <Search placeholder="请输入用户昵称" onSearch={(value) => this.changeName(value)}/>
                                    {/* <Button type="primary" icon="download">
                                        导出
                                    </Button> */}
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Table dataSource={personList} size="middle" columns={this.columns} rowKey={"wx_user_id"} locale={{emptyText: '暂无数据'}} pagination={{
                            showQuickJumper:true,
                            defaultCurrent: 1,
                            // total: 10,
                            pageSize: pageSize,
                            hideOnSinglePage: true,
                            onChange: this.pageChange
                        }}/>
                    </div>
                </div>

                <Modal
                    visible={visible}
                    title={null}
                    onCancel={this.cancelModel}
                    footer={null}
                    width="70vw"
                >
                    <div className="model_head">
                        <div className="head_title">已领取奖品</div>
                    </div>
                    <Table dataSource={awardList} size="middle" columns={this.columnsList} rowKey={"created_at"} locale={{emptyText: '暂无数据'}} pagination={{
                            showQuickJumper:true,
                            defaultCurrent: 1,
                            // total: 10,
                            pageSize: pageSize,
                            hideOnSinglePage: true,
                            // onChange: this.pageChange
                        }}/>
                </Modal>
            </div>
        );
    }
}

export default TurnplatePerson;