import { observer } from "mobx-react";
import React from "react";
import { Button, Input, message, Row, Col, Modal, Radio, Icon, Table, Upload, Tooltip, Select, Spin, Switch, Pagination, List } from "antd";
import { postRequest, getRequest } from '../../../api/http';
import TextArea from 'antd/lib/input/TextArea';
import { RangePickerValue } from "antd/lib/date-picker/interface";
import BraftEditor from 'braft-editor'
import { apiUrl } from '../../../api/api_url';
import newClose from '../../../assret/images/newclose.png';
import clsoe from '../../../assret/images/close.png'

import "fabric";
declare let fabric: any;
const { Option } = Select;

const key = 'NewPeopleEdit';

// 画布 内容 唯一标识
const uniqueKeys = {
	nickName: "nickName",
	avatarUrl: "avatarUrl",
	qrCode: "qrCode",
	backImage: "backImage"
};

@observer
class WeappDistriutionAdd extends React.Component<any> {

	columnNum: any = [
		{
			title: '优惠券名称',
			dataIndex: 'name',
			key: 'name',
			align: "center",
		},
		{
			title: '券类型',
			dataIndex: 'type',
			key: 'type',
			align: "center",
			render: (text: any, record: any,) => (
				<div className="color">{record.info.type === 'discount' ? '折扣券' : record.info.type === 'voucher' ? '优惠券' : '兑换券'}</div>
			),
		},
		{
			title: '券有效期',
			dataIndex: 'created_at',
			key: 'created_at',
			align: "center",
			render: (text: any, record: any,) => {
				let info: any = record.expire_info;
				return <div className="color">{info.type === 'time' ? info.start_time + '至' + info.end_time : '自领取日' + info.day + '天领取'}</div>
			}
		},
		{
			title: '券份数',
			dataIndex: 'num',
			key: 'num',
			align: "center",
			render: (text: any, record: any,) => {
				return <div className="back_wrap">
					{/* <div className="back_btn reduce_btn active" onClick={() => this.reduceCoupon(record.id)}>-</div> */}
					<div className="good_num">{record.coupon_num}</div>
					{/* <div className="back_btn add_btn active" onClick={() => this.addCoupon(record.id)}>+</div> */}
				</div>
			}
		},
		{
			title: '操作',
			align: "center",
			render: (text: string, record: any, index: number) => (
				<div className="table_action" onClick={() => this.unBindShop(index)}>移除</div>
			)
		},
	];

	state = {
		editorState: BraftEditor.createEditorState(null),
		loading: false,
		name: '',
		status: 1,
		visible: false,
		couponList: [],
		listNum: 0,
		current: 1,
		selectCoupon: [],
		type: 'new_people',
		selectDisabled: false,
		typeName: '',
		user_type: [],
		pageSize: 100,
		selectList: [],
		start_time: '',
		end_time: '',
		position: 1,
		channel: 2,
		is_bothway: false,
		edit: false,
		groupList: [],
		data: [],
		value: [],
		fetching: false,
		invitation_type: 'all',
		group_id: [],
		fans_id: [],
		QINIU_SERVER: 'https://up-z2.qiniup.com',
		token: '',
		imageKey: '',
		formData: {
			id: 0,
			rules: '',
			first_order_percent: '',
			order_percent: '',
			poster: '',
			xcx: '刷吃'
		},
		myCanvasList: [], //模版对象
		canvasConfig: {
			width: 340,
			height: 600
		},
		own_can: 0,
		rechargeStatus: true,
		select_poster: false,
		poster_list: [],
		poster_status: 1,
		poster_total: 0, //总页数
	}

	lastFetchId = 0;

	componentWillMount() {
		// const { location } = this.props;
		// console.log(location)
		// if (location.state !== undefined) {
		// 	this.initEdit(location.state.id,location.state.welfare_id);
		// }
	}

	componentDidMount() {
		this.onInitList();
		this._initGroup();
		this.onGetSystemPoster()
	}

	//获取系统海报
	async onGetSystemPoster() {
		let param = {
			page: 1,
			pageSize: 3
		}
		const res: any = await getRequest(apiUrl.getTemplate, param);
		if (res.code === 1) {
			let res_list = res.data.list;
			let _list: any = [];
			res_list.map((item: any, index: any) => {
				_list.push(Object.assign(item, {select: false}))
			})
			this.setState({
				poster_list: _list,
				poster_total: res.data.total
			}, () => {
				//必须先获取海报，才能填充数据
				const { location } = this.props;
				//console.log(location)
				if (location.state !== undefined) {
					this.initEdit(location.state.id,location.state.welfare_id);
				}
			})
		}
	}

	// 初始化canvas
	initCanvas = (canvas: any) => {
		canvas.setWidth(this.state.canvasConfig.width);
		canvas.setHeight(this.state.canvasConfig.height);
		const defaultQrCodeUrl = "http://ydn-siyu.cdn.shuachi.com/poster/default_qr_code.png";

		const defaultQrCode = new Image();
		defaultQrCode.src = defaultQrCodeUrl;
		defaultQrCode.crossOrigin = 'anonymous';
		defaultQrCode.onload = () => {
			const imgW = defaultQrCode.width;
			const avatarW = 70;
			const _scale = imgW > avatarW ? avatarW / imgW : imgW / avatarW;
			fabric.Image.fromURL(defaultQrCodeUrl, (imgQrcode: any) => {
				imgQrcode.scale(_scale).set({
					left: 50,
					top: 100,
					angle: 0,
					crossOrigin: 'anonymous'
				});
				//imgQrcode.set('selectable', false); //禁止拖动
				imgQrcode["uniqueKey"] = uniqueKeys.qrCode; // 添加画布二维码唯一标识
				canvas.add(imgQrcode);
			});

		};
		defaultQrCode.onerror = e => {
			console.log("二维码加载错误", e);
		};
	};

	// 初始化优惠券列表
	onInitList = async () => {
		let param = {
			current: 1,
			pageSize: 100,
			position: 3
		}
		const res: any = await getRequest(apiUrl.couponList, param);
		if (res.code === 1) {
			this.setState({
				couponList: res.data.list,
				listNum: res.data.total,
				current: 1,
				selectList: res.data.list.slice(0, 10)
			})
		}
	}

	// 初始化编辑
	initEdit = async (id: number, welfare_id: number) => {
		//分销编辑
		const res_active: any = await getRequest(apiUrl.shareActiviteDetail, {id: id});
		let formData: any = this.state.formData;
		formData.first_order_percent = Number(res_active.data.first_order_percent) * 100;
		formData.order_percent = Number(res_active.data.order_percent) * 100;
		formData.rules = res_active.data.rules;
		formData.id = res_active.data.id;
		formData.poster = res_active.data.poster;
		//console.log('formData:',formData)
		//console.log('order:',Number(res_active.data.first_order_percent) * 100)
		if(res_active.code === 1) {
			this.setState({
				formData: formData,
				first_order_percent: 10,
				order_percent:  Number(res_active.data.order_percent) * 100,
				status: res_active.data.status
			}, () => {
				console.log(JSON.parse(this.state.formData.poster))
				//填充海报
				let flag = true; //默认是系统海报
				let img_list: any = JSON.parse(res_active.data.poster);
				let _list: any = [];
				img_list.map((item: any, index: any) => {
					console.log(item['objects']);
					if(item.type) {
						item['objects'].map((s_item: any, s_index: any) => {
							if(s_item['type'] == 'image') {
								console.log(s_item['src']);
								console.log(item.type);
								let obj = {
									src: s_item['src'],
									type: item.type
								}
								_list.push(obj)
							}
						})
					} else {
						flag = false;
						//自定义海报
						let obj = {
							src: item['backgroundImage']['src'],
							type: 'init'
						}
						_list.push(obj)
					}
				})
				//拼接title和label
				if(flag) {
					let list_last: any = [];
					(this.state.poster_list).map((item: any, index: any) => {
						_list.map((s_item: any, s_index: any) => {
							if(item['type'] == s_item['type']) {
								list_last.push(Object.assign(s_item, {title: item['title'], label: item['label']}))
							}
						})
					})
					this.onEditImgToCanvas(list_last)
				} else {
					console.log(_list)
					console.log((_list.length - 1))
					this.setState({
						own_can: (_list.length - 1),
						poster_status: 2
					}, () => {
						this.onEditImgToCanvas(_list)
					})
				}
			})
		}
		//福利编辑
		const res_fuli: any = await getRequest(apiUrl.welfareEditInfo, {id: welfare_id});
		if (res_fuli.code === 1) {
			this.setState({
				edit: true,
				name: res_fuli.data.name,
				//rechargeStatus: res_fuli.data.status,
				selectCoupon: res_fuli.data.coupon,
				position: res_fuli.data.position,
				channel: res_fuli.data.channel,

			})
			if(res_fuli.data.is_bothway){
				this.setState({
					is_bothway: res_fuli.data.is_bothway
				})
			}
			if (res_fuli.data.expire_info) {
				this.setState({
					start_time: res_fuli.data.expire_info.start_time,
					end_time: res_fuli.data.expire_info.end_time,
				})
			}
			if (res_fuli.data.user_type) {
				this.setState({
					user_type: res_fuli.data.user_type,
				})
			}
			if (res_fuli.data.channel == 2) {
				this.setState({
					invitation_type: res_fuli.data.invitation_type
				})
				if (res_fuli.data.invitation_type == 'group') {
					this.setState({
						group_id: res_fuli.data.invitation_target_id
					})
				}
				if (res_fuli.data.invitation_type == 'designated_user') {
					this.setState({
						fans_id: res_fuli.data.invitation_target_id
					})
				}
			}
		}
	}

	addCoupon = (id: any) => {
		let selectCoupon = this.state.selectCoupon;
		let { edit } = this.state;
		if (edit) {
			return;
		}
		selectCoupon.map((item: any) => {
			if (item.id === id) {
				item.coupon_num++;
			}
		})
		this.setState({
			selectCoupon
		})
	}

	reduceCoupon = (id: any) => {
		let { edit } = this.state;
		if (edit) {
			return;
		}
		let selectCoupon = this.state.selectCoupon;
		selectCoupon.map((item: any) => {
			if (item.id === id) {
				if (item.coupon_num === 1) {
					return;
				}
				item.coupon_num--;
			}
		})
		this.setState({
			selectCoupon
		})
	}

	nameChange = (e: any) => {
		let name = e.target.name;
		let value = e.target.value;
		this.setState({
			[name]: value
		})
	}

	onCheck = (checkedKeys: any, info: any) => {
		let keyList = checkedKeys;
		let user_type: any[] = [];
		keyList.map((item: any) => {
			if (item !== '4') {
				user_type.push(item)
			}
		})
		this.setState({
			user_type
		})
	};

	handleSubmit = async () => {

		const { location } = this.props;
		const { poster_status, status, rechargeStatus, group_id, fans_id, is_bothway, formData, type, own_can, selectCoupon, user_type, start_time, end_time, position, channel, invitation_type } = this.state;
		var tableInfo: any = {};
		let coupon: any[] = [];

		if (location.state !== undefined) {
			tableInfo.id = location.state.welfare_id;
		} else {

			if (formData.first_order_percent === '' || formData.order_percent === '') {
				message.warning({ content: '请填写奖励比例!', key, duration: 2 });
				return;
			}

			if (formData.rules === '') {
				message.warning({ content: '请填写邀请规则!', key, duration: 2 });
				return;
			}

			if (own_can === 0) {
				message.warning({ content: '请选择模版!', key, duration: 2 });
				return;
			}

			// if(rechargeStatus) {
			// 	if(selectCoupon.length === 0){
			// 		message.warning({ content: '请关联优惠券!', key, duration: 2 });
			// 		return;
			// 	}
			// }

		}

		// if(selectCoupon.length === 0){
		// 	message.warning({ content: '请关联优惠券!', key, duration: 2 });
		// 	return;
		// }

		//福利状态
		console.log('status:',status)
		if(status == 1) {
			tableInfo.status = 1;
			tableInfo.rechargeStatus = 1;
		} else {
			tableInfo.status = 0;
			tableInfo.rechargeStatus = 2;
		}

		//获取canvas数据
		let submit_canvas: any = this.state.myCanvasList;
		let res_canvas: any = [];
		console.log('submit_canvas:',submit_canvas);
		console.log('poster_status:',poster_status);
		submit_canvas.map( async (item: any, index: any) => {
			if(poster_status == 1) {
				let can_obj = item.val.toJSON();
				let obj: any = (item.val.toJSON()).objects;
				let res_img_path: any = await postRequest(apiUrl.uploadPic,{Upload_img: item['val'].toDataURL('png')});
				if(res_img_path.code == 1) {
					//console.log(obj);
					obj.map((s_item: any, s_index: any) => {
						if(s_item['type'] == 'image') {
							obj[s_index].backgroundColor = res_img_path.data.imgsrc;
							can_obj['backgroundImage'] = {
								src: res_img_path.data.imgsrc
							};
						}
					});
					//console.log(obj);
				}
				can_obj.objects = obj;
				can_obj.type = item.type;
				console.log('can_obj:',can_obj)
				res_canvas.push(can_obj)
			} else {
				let can_obj = item.val.toJSON();
				res_canvas.push(can_obj)
			}
		})

		console.log('res_canvas111:',res_canvas)
		tableInfo.type = type;
		tableInfo.position = position;
		tableInfo.channel = channel;
		if (channel == 1) {
			tableInfo.user_type = user_type;
		} else {
			tableInfo.is_bothway = is_bothway;
			tableInfo.invitation_type = invitation_type
			console.log('提交', invitation_type, group_id, fans_id);
			if (invitation_type == 'group') {
				tableInfo.invitation_target_id = group_id
			}
			if (invitation_type == 'designated_user') {
				tableInfo.invitation_target_id = fans_id
			}
		}

		selectCoupon.forEach((item: any) => {
			if (item.limit == undefined) {
				item.limit = '';
			}
			coupon.push({
				id: item.id,
				limit: item.limit,
				coupon_num: item.coupon_num,
				people_num: item.people_num
			})
		})

		tableInfo.coupon = coupon;
		if (location.state !== undefined) {
			message.loading({ content: '发布中', key });
			//分两步发布，第一步提交分销数据，在提交福利数据
			const res: any = await postRequest(apiUrl.welfareEdit, tableInfo);
			if (res['code'] === 1) {
				//2，提交分销数据
				console.log('res_canvas:',res_canvas)
				if(res_canvas.length == 0) {
					setTimeout( async () => {
						const first = Number(formData.first_order_percent) / 100;
						const order_percent = Number(formData.order_percent) / 100;
						const res_share: any = await postRequest(apiUrl.shareActiviteUpdate, {
							id: location.state.id,
							update: {
								first_order_percent: first ? first : formData.first_order_percent,
								order_percent: order_percent ? order_percent : formData.order_percent,
								rules: formData.rules,
								poster: JSON.stringify(res_canvas),
								welfare_id: tableInfo.id,
								status: tableInfo.rechargeStatus
							}
						});
						if(res_share['code'] === 1) {
							message.success({ content: '修改成功!', key, duration: 2 });
							this.goBack()
						} else {
							message.error({ content: '修改失败!', key, duration: 2 });
						}
					}, 1000);
				} else {
					const first = Number(formData.first_order_percent) / 100;
					const order_percent = Number(formData.order_percent) / 100;
					const res_share: any = await postRequest(apiUrl.shareActiviteUpdate, {
						id: location.state.id,
						update: {
							first_order_percent: first ? first : formData.first_order_percent,
							order_percent: order_percent ? order_percent : formData.order_percent,
							rules: formData.rules,
							poster: JSON.stringify(res_canvas),
							welfare_id: tableInfo.id,
							status: tableInfo.rechargeStatus
						}
					});
					if(res_share['code'] === 1) {
						message.success({ content: '修改成功!', key, duration: 2 });
						this.goBack()
					} else {
						message.error({ content: '修改失败!', key, duration: 2 });
					}
				}
			} else {
				message.error({ content: '修改失败!', key, duration: 2 });
			}
		} else {
			message.loading({ content: '发布中', key });
			//分两步发布，第一步提交福利数据，在提交分销数据
			const first = Number(formData.first_order_percent) / 100;
			const order_percent = Number(formData.order_percent) / 100;
			//1，提交福利数据
			console.log('tableInfo:',tableInfo)
			const res: any = await postRequest(apiUrl.welfareAdd, tableInfo);
			if (res['code'] === 1) {
				//2，提交分销数据
				console.log(res_canvas)
				const res_share: any = await postRequest(apiUrl.mpShareCreate, {
					first_order_percent: first ? first : formData.first_order_percent,
					order_percent: order_percent ? order_percent : formData.order_percent,
					rules: formData.rules,
					poster: JSON.stringify(res_canvas),
					welfare_id: res.data.id,
					status: tableInfo.rechargeStatus
				});
				if(res_share['code'] === 1) {
					message.success({ content: '发布成功!', key, duration: 2 });
					this.goBack()
				}
			} else {
				message.error({ content: '发布失败!', key, duration: 2 });
			}
		}
	};

	// 返回上一页
	goBack = () => {
		this.props.history.goBack();
	}

	changePage = async (page: number) => {
		let { couponList } = this.state;
		let list = couponList.slice((page - 1) * 10, (10 * page));
		this.setState({
			selectList: list,
			current: page
		})
	}

	// 关联
	handleBindShop = (id: number) => {
		let { type } = this.state;
		let selectCoupon: any[] = this.state.selectCoupon;
		let couponList: any[] = this.state.couponList;
		couponList.map((item) => {
			if (item.id === id) {
				item.selected = true;
				item.coupon_num = 1;
				selectCoupon.push(item)
			}
		})
		console.log(selectCoupon);
		this.setState({
			selectCoupon,
			couponList
		})
	}

	// 解除关联
	unBindShop = (index: number) => {

		let { couponList, edit } = this.state;
		// if (edit) {
		// 	return;
		// }
		let selectCoupon: any = this.state.selectCoupon;
		couponList.map((item: any) => {
			if (item.id === selectCoupon[index].id) {
				item.selected = false;
			}
		})
		selectCoupon.splice(index, 1)
		this.setState({
			couponList,
			selectList: couponList.slice(0, 10),
			current: 1,
			selectCoupon
		})
	}

	limitChange = (e: any, index: any) => {
		let selectCoupon: any = this.state.selectCoupon;
		if (e) {
			selectCoupon[index].limit = 'zhuli';
		} else {
			selectCoupon[index].limit = '';
		}

		this.setState({
			selectCoupon
		})
	}

	// 助力人数
	numChange = (e: any, index: number) => {
		let selectCoupon: any = this.state.selectCoupon;

		selectCoupon[index].people_num = e;

		this.setState({
			selectCoupon
		})
	}

	handleOk = () => {
		this.setState({
			visible: false
		})
	}

	// 选择海报
	posterHandleOk = () => {
		//获取选中的海报
		let _list = this.state.poster_list;
		let new_list = _list.filter((item: any) => item.select);
		this.onDeleteNode('canMain')
		this.onImageUrlToCanvas(new_list);
		this.setState({
			select_poster: false
		})
	}

	/**
	 *
	 * @param id 删除指定id下所有子节点
	 */
	onDeleteNode(id: any) {
		let el: any = document.getElementById(id);
		let childs = el.childNodes;
		for(let i = childs .length - 1; i >= 0; i--) {
			el.removeChild(childs[i]);
		}
	}

	/**
	 *
	 * @param imgList 根据img_url往canvas添加内容,传入一个数组
	 */
	async onImageUrlToCanvas(imgList: any) {
		if(!Array.isArray(imgList)) return;
		let canvas_list: any = [];
		let shop_info:any = localStorage.getItem('shop_info');
		shop_info = JSON.parse(shop_info);
		imgList.map(async (item: any, index: any) => {
			this.onSysTemTemplatePushDom('canMain','canvas',index);
			const myCanvas = new fabric.Canvas(`can${index}`, {
				backgroundColor: "#eef0f3"
			});
			myCanvas.setWidth(this.state.canvasConfig.width);
			myCanvas.setHeight(this.state.canvasConfig.height);
			let canvasConfig: any = this.state.canvasConfig;
			const img = new Image();
			img.setAttribute("crossOrigin",'anonymous');
			img.src = item['background'];
			let scaleImg: any = 0;
			img.onload = () => {
				myCanvas.setWidth(canvasConfig.width);
				scaleImg =
					img.width > canvasConfig.width
						? canvasConfig.width / img.width
						: img.width / canvasConfig.width;
				//const _height = img.height * scaleImg;
				const imgInstance = new fabric.Image(img, {
					left: 0, // 图片相对画布的左侧距离
					top: 0, // 图片相对画布的顶部距离
					angle: 0, // 图片旋转角度
					opacity: 1, // 图片透明度
					scaleX: scaleImg,
					scaleY: scaleImg,
					hasControls: false,
					selectable: false
				});
				myCanvas.add(imgInstance);
				if(item.type == 1) {
					//电话
					const text = new fabric.Text(`电话：${shop_info.phone}`,{
						left: 30,
						top: 530,
						fontSize: 12,
						fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
					})
					text.set('fill', '#540c09')
					myCanvas.add(text)
					//地址
					let addr_loca = `地址：${(shop_info.address).substring(0,22)}\n${(shop_info.address).substring(22)}`;
					const addr = new fabric.Text(addr_loca,{
						left: 30,
						top: 550,
						fontSize: 12,
						fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
					})
					addr.set('fill', '#540c09')
					myCanvas.add(addr)
					//提示title
					const title = new fabric.Text(item['title'],{
						left: 82,
						top: 340,
						fontSize: 14,
						fontWeight: 'bold',
						fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
					})
					title.set('fill', '#540c09')
					title.set({
						left: (340 - (title.get('width'))) / 2
					})
					myCanvas.add(title)
					//提示label
					const label = new fabric.Text(item['label'],{
						left: 65,
						top: 370,
						fontSize: 18,
						fontWeight: 'bold',
						fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
					})
					label.set('fill', '#540c09')
					label.set({
						left: (340 - (label.get('width'))) / 2
					})
					myCanvas.add(label)
					//店铺名称
					const shop_name = new fabric.Text(`${shop_info.shop_name}`,{
						left: 65,
						top: 90,
						fontSize: 18,
						fontWeight: 'bold',
						textAlign: 'center',
						charSpacing: 10,
						fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
					})
					shop_name.set('fill', '#540c09')
					shop_name.set({
						left: (340 - (shop_name.get('width'))) / 2
					})
					myCanvas.add(shop_name)
				}
				if(item.type == 2) {
					//电话
					const text = new fabric.Text(`电话：${shop_info.phone}`,{
						left: 30,
						top: 540,
						fontSize: 12,
						fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
					})
					text.set('fill', '#ffffff')
					myCanvas.add(text)
					//地址
					let addr_loca = `地址：${(shop_info.address).substring(0,22)}\n${(shop_info.address).substring(22)}`;
					const addr = new fabric.Text(addr_loca,{
						left: 30,
						top: 560,
						fontSize: 12,
						fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
					})
					addr.set('fill', '#ffffff')
					myCanvas.add(addr)
					//提示title
					const title = new fabric.Text(item['title'],{
						left: 82,
						top: 430,
						fontSize: 14,
						fontWeight: 'bold',
						fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
					})
					title.set('fill', '#000000')
					title.set({
						left: (340 - (title.get('width'))) / 2
					})
					myCanvas.add(title)
					//提示label
					const label = new fabric.Text(item['label'],{
						left: 65,
						top: 460,
						fontSize: 18,
						fontWeight: 'bold',
						fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
					})
					label.set('fill', '#000000')
					label.set({
						left: (340 - (label.get('width'))) / 2
					})
					myCanvas.add(label)
					//店铺名称
					let shop_name_loca = `${(shop_info.shop_name).substring(0,5)}\n${(shop_info.shop_name).substring(5)}`;
					const shop_name = new fabric.Text(`${shop_name_loca}`,{
						left: 20,
						top: 120,
						fontSize: 18,
						fontWeight: 'bold',
						textAlign: 'center',
						fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
					})
					shop_name.set('fill', '#ffffff')
					myCanvas.add(shop_name)
				}
				if(item.type == 3) {
					//电话
					const text = new fabric.Text(`电话：${shop_info.phone}`,{
						left: 30,
						top: 540,
						fontSize: 12,
						fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
					})
					text.set('fill', '#ffffff')
					myCanvas.add(text)
					//地址
					let addr_loca = `地址：${(shop_info.address).substring(0,22)}\n${(shop_info.address).substring(22)}`;
					const addr = new fabric.Text(addr_loca,{
						left: 30,
						top: 560,
						fontSize: 12,
						fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
					})
					addr.set('fill', '#ffffff')
					myCanvas.add(addr)
					//提示title
					const title = new fabric.Text(item['title'],{
						left: 82,
						top: 420,
						fontSize: 14,
						fontWeight: 'bold',
						fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
					})
					title.set('fill', '#000000')
					title.set({
						left: (340 - (title.get('width'))) / 2
					})
					myCanvas.add(title)
					//提示label
					const label = new fabric.Text(item['label'],{
						left: 65,
						top: 450,
						fontSize: 18,
						fontWeight: 'bold',
						fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
					})
					label.set('fill', '#000000')
					label.set({
						left: (340 - (label.get('width'))) / 2
					})
					myCanvas.add(label)
					//店铺名称
					const shop_name = new fabric.Text(`${shop_info.shop_name}`,{
						left: 20,
						top: 90,
						fontSize: 18,
						fontWeight: 'bold',
						textAlign: 'center',
						fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
					})
					shop_name.set('fill', '#ffffff')
					shop_name.set({
						left: (340 - (shop_name.get('width'))) / 2
					})
					myCanvas.add(shop_name)
				}
			}
			canvas_list.push({id: `can${index}`, val: myCanvas, type: item.type})
		})
		this.setState(() => {
			return {
				own_can: imgList.length,
				myCanvasList: canvas_list
			}
		})
	}

	/**
	 *
	 * @param imgList 编辑的填充海报
	 */
	async onEditImgToCanvas(imgList: any) {
		if(!Array.isArray(imgList)) return;
		let canvas_list: any = [];
		let shop_info:any = localStorage.getItem('shop_info');
		shop_info = JSON.parse(shop_info);
		imgList.map(async (item: any, index: any) => {
			//console.log('2131312asdas:',item)
			this.onSysTemTemplatePushDom('canMain','canvas',index);
			const myCanvas = new fabric.Canvas(`can${index}`, {
				backgroundColor: "#eef0f3"
			});
			myCanvas.setWidth(this.state.canvasConfig.width);
			myCanvas.setHeight(this.state.canvasConfig.height);
			let canvasConfig: any = this.state.canvasConfig;
			const img = new Image();
			img.setAttribute("crossOrigin",'anonymous');
			img.src = item['src'];
			let scaleImg: any = 0;
			img.onload = () => {
				myCanvas.setWidth(canvasConfig.width);
				scaleImg =
					img.width > canvasConfig.width
						? canvasConfig.width / img.width
						: img.width / canvasConfig.width;
				//const _height = img.height * scaleImg;
				const imgInstance = new fabric.Image(img, {
					left: 0, // 图片相对画布的左侧距离
					top: 0, // 图片相对画布的顶部距离
					angle: 0, // 图片旋转角度
					opacity: 1, // 图片透明度
					scaleX: scaleImg,
					scaleY: scaleImg,
					hasControls: false,
					selectable: false
				});
				myCanvas.add(imgInstance);
				//自定义海报
				if(item.type == 'init') {
					//添加二维码
					let defaultQrCodeUrl = "http://ydn-siyu.cdn.shuachi.com/poster/default_qr_code.png";
					let defaultQrCode = new Image();
					defaultQrCode.src = defaultQrCodeUrl;
					defaultQrCode.crossOrigin = 'anonymous';
					defaultQrCode.onload = function() {
						const imgW = defaultQrCode.width;
						const avatarW = 120;
						const _scale = imgW > avatarW ? avatarW / imgW : imgW / avatarW;
						console.log(_scale)
						fabric.Image.fromURL(defaultQrCodeUrl, function(img: any) {
							img.scale(_scale).set({
								left: 110,
								top: 180,
								angle: 0,
								crossOrigin: 'anonymous'
							});
							img.set('selectable', false); //禁止二维码拖动
							myCanvas.add(img)
						})

						myCanvas.setBackgroundImage(
							imgInstance,
							myCanvas.renderAll.bind(myCanvas),
							{
								scaleX: scaleImg,
								scaleY: scaleImg,
								crossOrigin: 'anonymous'
							}
						);
						myCanvas.renderAll();
					}
				}
				if(item.type == 1) {
					//电话
					const text = new fabric.Text(`电话：${shop_info.phone}`,{
						left: 30,
						top: 530,
						fontSize: 12,
						fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
					})
					text.set('fill', '#540c09')
					myCanvas.add(text)
					//地址
					let addr_loca = `地址：${(shop_info.address).substring(0,22)}\n${(shop_info.address).substring(22)}`;
					const addr = new fabric.Text(addr_loca,{
						left: 30,
						top: 550,
						fontSize: 12,
						fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
					})
					addr.set('fill', '#540c09')
					myCanvas.add(addr)
					//提示title
					const title = new fabric.Text(item['title'],{
						left: 82,
						top: 340,
						fontSize: 14,
						fontWeight: 'bold',
						fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
					})
					title.set('fill', '#540c09')
					title.set({
						left: (340 - (title.get('width'))) / 2
					})
					myCanvas.add(title)
					//提示label
					const label = new fabric.Text(item['label'],{
						left: 65,
						top: 370,
						fontSize: 18,
						fontWeight: 'bold',
						fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
					})
					label.set('fill', '#540c09')
					label.set({
						left: (340 - (label.get('width'))) / 2
					})
					myCanvas.add(label)
					//店铺名称
					const shop_name = new fabric.Text(`${shop_info.shop_name}`,{
						left: 65,
						top: 90,
						fontSize: 18,
						fontWeight: 'bold',
						textAlign: 'center',
						charSpacing: 10,
						fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
					})
					shop_name.set('fill', '#540c09')
					shop_name.set({
						left: (340 - (shop_name.get('width'))) / 2
					})
					myCanvas.add(shop_name)
				}
				if(item.type == 2) {
					//电话
					const text = new fabric.Text(`电话：${shop_info.phone}`,{
						left: 30,
						top: 540,
						fontSize: 12,
						fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
					})
					text.set('fill', '#ffffff')
					myCanvas.add(text)
					//地址
					let addr_loca = `地址：${(shop_info.address).substring(0,22)}\n${(shop_info.address).substring(22)}`;
					const addr = new fabric.Text(addr_loca,{
						left: 30,
						top: 560,
						fontSize: 12,
						fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
					})
					addr.set('fill', '#ffffff')
					myCanvas.add(addr)
					//提示title
					const title = new fabric.Text(item['title'],{
						left: 82,
						top: 430,
						fontSize: 14,
						fontWeight: 'bold',
						fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
					})
					title.set('fill', '#000000')
					title.set({
						left: (340 - (title.get('width'))) / 2
					})
					myCanvas.add(title)
					//提示label
					const label = new fabric.Text(item['label'],{
						left: 65,
						top: 460,
						fontSize: 18,
						fontWeight: 'bold',
						fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
					})
					label.set('fill', '#000000')
					label.set({
						left: (340 - (label.get('width'))) / 2
					})
					myCanvas.add(label)
					//店铺名称
					let shop_name_loca = `${(shop_info.shop_name).substring(0,5)}\n${(shop_info.shop_name).substring(5)}`;
					const shop_name = new fabric.Text(`${shop_name_loca}`,{
						left: 20,
						top: 120,
						fontSize: 18,
						fontWeight: 'bold',
						textAlign: 'center',
						fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
					})
					shop_name.set('fill', '#ffffff')
					myCanvas.add(shop_name)
				}
				if(item.type == 3) {
					//电话
					const text = new fabric.Text(`电话：${shop_info.phone}`,{
						left: 30,
						top: 540,
						fontSize: 12,
						fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
					})
					text.set('fill', '#ffffff')
					myCanvas.add(text)
					//地址
					let addr_loca = `地址：${(shop_info.address).substring(0,22)}\n${(shop_info.address).substring(22)}`;
					const addr = new fabric.Text(addr_loca,{
						left: 30,
						top: 560,
						fontSize: 12,
						fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
					})
					addr.set('fill', '#ffffff')
					myCanvas.add(addr)
					//提示title
					const title = new fabric.Text(item['title'],{
						left: 82,
						top: 420,
						fontSize: 14,
						fontWeight: 'bold',
						fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
					})
					title.set('fill', '#000000')
					title.set({
						left: (340 - (title.get('width'))) / 2
					})
					myCanvas.add(title)
					//提示label
					const label = new fabric.Text(item['label'],{
						left: 65,
						top: 450,
						fontSize: 18,
						fontWeight: 'bold',
						fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
					})
					label.set('fill', '#000000')
					label.set({
						left: (340 - (label.get('width'))) / 2
					})
					myCanvas.add(label)
					//店铺名称
					const shop_name = new fabric.Text(`${shop_info.shop_name}`,{
						left: 20,
						top: 90,
						fontSize: 18,
						fontWeight: 'bold',
						textAlign: 'center',
						fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
					})
					shop_name.set('fill', '#ffffff')
					shop_name.set({
						left: (340 - (shop_name.get('width'))) / 2
					})
					myCanvas.add(shop_name)
				}
			}
			canvas_list.push({id: `can${index}`, val: myCanvas, type: item.type})
		})
		console.log('canvas_list:',canvas_list)
		this.setState(() => {
			return {
				own_can: imgList.length,
				myCanvasList: canvas_list
			}
		})
	}

	// 创建 商品列表
	columns: any = [
		{
			title: '优惠券名称',
			dataIndex: 'name',
			key: 'name',
			align: "center",
		},
		{
			title: '券类型',
			dataIndex: 'type',
			key: 'id',
			align: "center",
			render: (text: any, record: any,) => (
				<div className="color">{record.info.type === 'discount' ? '折扣券' : record.info.type === 'voucher' ? '优惠券' : '兑换券'}</div>
			),
		},
		{
			title: '券有效期',
			dataIndex: 'created_at',
			key: 'created_at',
			align: "center",
			render: (text: any, record: any,) => {
				let info: any = record.expire_info;
				return <div className="color">{info.type === 'time' ? '自' + info.start_time + '至' + info.end_time : '自领取日' + info.day + '天内有效'}</div>
			}
		},
		{
			title: '操作',
			align: "center",
			render: (text: string, record: any, index: number) => {
				return <div>
					{
						record.selected
							? '已关联'
							: <div className="table_action" onClick={() => this.handleBindShop(record.id)}>关联</div>
					}
				</div>
			}
		},
	];

	handleEditorChange = (editorState: any) => {
		this.setState({ editorState })
	}

	// 显示关联
	showTable = () => {
		let { couponList, selectCoupon, edit } = this.state;
		// if (edit) {
		// 	return;
		// }
		couponList.map((coupon: any) => {
			selectCoupon.map((select: any) => {
				if (coupon.id === select.id) {
					coupon.selected = true;
				}
			})
		})
		this.setState({
			couponList,
			selectList: couponList.slice(0, 10),
			current: 1,
			visible: true
		})
	}

	timeChange = (time: RangePickerValue, timeString: [string, string]) => {
		this.setState({
			start_time: timeString[0],
			end_time: timeString[1]
		})
	}

	// 分组列表
	_initGroup = async () => {
		const res: any = await getRequest(apiUrl.memberGroup);
		if (res.code === 1) {
			this.setState({
				groupList: res.data
			})
		}
	}

	// 用户分组
	groupChange = (e: any) => {
		this.setState({
			group_id: e
		})
	}

	fetchUser = async (value: any) => {
		this.lastFetchId += 1;
		const fetchId = this.lastFetchId;
		this.setState({ data: [], fetching: true });

		const res: any = await getRequest(apiUrl.getMemberList, { keywords: value, page_size: 100 });
		if (res.code === 1) {
			if (fetchId !== this.lastFetchId) {
				return;
			}
			this.setState({ data: res.data.list, fetching: false });
		}
	};

	handleChange = (value: any) => {
		console.log(value);
		let fans_id: any = [];
		if (value.length > 0) {
			value.map((item: any) => {
				fans_id.push(item.key);
			})
		}
		this.setState({
			value,
			fans_id,
			data: [],
			fetching: false,
		});
	};

	handlebeforeUpload = async () => {
		const res: any = await getRequest(apiUrl.getUploadToken, {
			type: "poster"
		});
		if (res && res.data) {
			const key = `${Math.random().toString(36).slice(-8)}${new Date().valueOf()}.png`;
			this.setState({
				token: res.data.upload_token,
				imageKey: key
			})
			//setimageKey(key);
			//setToken(res.data.upload_token);
		}
	};

	uploadChange = (e: any) => {
		if (e.file && e.file.status === "done") {
			console.log("上传成功:",e.file.response.url);
			this.drawImage(e.file.response.url);
		}
	};

	drawImage = (url: any) => {
		this.onPushDom('canMain','canvas');
		console.log('tianjia:',this.state.own_can)
		const myCanvas = new fabric.Canvas(`can${this.state.own_can}`, {
			backgroundColor: "#eef0f3"
		});
		myCanvas.setWidth(this.state.canvasConfig.width);
		myCanvas.setHeight(this.state.canvasConfig.height);
		let canvasConfig: any = this.state.canvasConfig;
		const img = new Image();
		img.src = url;
		let scaleImg: any = 0;
		img.onload = () => {
			myCanvas.setWidth(canvasConfig.width);
			scaleImg =
				img.width > canvasConfig.width
					? canvasConfig.width / img.width
					: img.width / canvasConfig.width;
			//const _height = img.height * scaleImg;
			const imgInstance = new fabric.Image(img, {
				left: 0, // 图片相对画布的左侧距离
				top: 0, // 图片相对画布的顶部距离
				angle: 0, // 图片旋转角度
				opacity: 1, // 图片透明度
				scaleX: scaleImg,
				scaleY: scaleImg,
				hasControls: false,
				selectable: false
			});
			myCanvas.add(imgInstance);
			//添加二维码
			let defaultQrCodeUrl = "http://ydn-siyu.cdn.shuachi.com/poster/default_qr_code.png";
			let defaultQrCode = new Image();
			defaultQrCode.src = defaultQrCodeUrl;
			defaultQrCode.crossOrigin = 'anonymous';
			defaultQrCode.onload = function() {
				const imgW = defaultQrCode.width;
				const avatarW = 120;
				const _scale = imgW > avatarW ? avatarW / imgW : imgW / avatarW;
				console.log(_scale)
				fabric.Image.fromURL(defaultQrCodeUrl, function(img: any) {
					img.scale(_scale).set({
						left: 110,
						top: 180,
						angle: 0,
						crossOrigin: 'anonymous'
					});
					img.set('selectable', false); //禁止二维码拖动
					myCanvas.add(img)
				})

				myCanvas.setBackgroundImage(
					imgInstance,
					myCanvas.renderAll.bind(myCanvas),
					{
						scaleX: scaleImg,
						scaleY: scaleImg,
						crossOrigin: 'anonymous'
					}
				);
				myCanvas.renderAll();
			}
		}
		let canvas_list: any = this.state.myCanvasList;
		canvas_list.push({id: `can${this.state.own_can}`, val: myCanvas})
		this.setState({
			own_can: ++this.state.own_can,
			myCanvasList: canvas_list
		})
	}

	//往指定dom元素下追加元素
	/**
	 *
	 * @param id 追加到指定id值得，元素下
	 * @param type 创建元素的类型
	 */
	onPushDom(id: any, type: any) {
		let _div = document.createElement('div');
		let _canvas = document.createElement(type);
		let _img = document.createElement('img');
		_img.src = newClose;
		_img.style.position = 'absolute';
		_img.style.right = '-24px';
		_img.style.top = '-24px';
		_img.style.width = '48px';
		_img.style.height = '48px';
		_img.style.cursor = 'pointer';
		_img.onclick = () => {
			this.onDelCanvas(_img)
		}
		_canvas.style.width = '340px';
		_canvas.style.height = '600px';
		_canvas.setAttribute('id', `can${this.state.own_can}`);
		_div.style.marginLeft = '30px';
		_div.style.position = 'relative';
		_div.style.width = '340px';
		_div.style.height = '600px';
		_div.setAttribute('id',`div_can${this.state.own_can}`);
		_div.appendChild(_canvas);
		_div.appendChild(_img);
		document.getElementById(`${id}`)?.appendChild(_div);
	}

	//往指定dom元素下追加元素
	/**
	 *
	 * @param id 追加到指定id值得，元素下
	 * @param type 创建元素的类型
	 * @param idx 索引
	 */
	onSysTemTemplatePushDom(id: any, type: any, idx: any) {
		let _div = document.createElement('div');
		let _canvas = document.createElement(type);
		let _img = document.createElement('img');
		_img.src = newClose;
		_img.style.position = 'absolute';
		_img.style.right = '-24px';
		_img.style.top = '-24px';
		_img.style.width = '48px';
		_img.style.height = '48px';
		_img.style.cursor = 'pointer';
		_img.onclick = () => {
			this.onDelCanvas(_img)
		}
		_canvas.style.width = '340px';
		_canvas.style.height = '600px';
		_canvas.setAttribute('id', `can${idx}`);
		_div.style.marginLeft = '30px';
		_div.style.position = 'relative';
		_div.style.width = '340px';
		_div.style.height = '600px';
		_div.setAttribute('id',`div_can${idx}`);
		_div.appendChild(_canvas);
		_div.appendChild(_img);
		document.getElementById(`${id}`)?.appendChild(_div);
	}

	//删除canvas
	onDelCanvas(e: any) {
		e.parentNode.parentNode.removeChild(e.parentNode);
		let my_canvas_list: any = this.state.myCanvasList;
		let id = (e.parentNode.getAttribute('id')).replace('div_','');
		let new_canvas_list = my_canvas_list.filter((item: any) => item.id != id);
		this.setState(() => {
			return {
				own_can: --this.state.own_can,
				myCanvasList: new_canvas_list
			}
		})
	}

	// 修改首单提成
	firstOrderPercent = (val: string) => {
		let formData: any  = this.state.formData;
		formData.first_order_percent = val;
		this.setState({
			formData,
		})
	}

	// 修改订单提成
	orderPercent = (val: string) => {
		let formData: any  = this.state.formData;
		formData.order_percent = val;
		this.setState({
			formData,
		})
	}

	rulesChange = (val: string) => {
		let formData: any  = this.state.formData;
		formData.rules = val;
		this.setState({
			formData
		})
	}

	welfareChange = (val: any) => {
		console.log(val)
		this.setState({
			rechargeStatus: val
		})
	}

	showSelect = () => {
		this.setState({
			select_poster: true
		})
	}

	tempChange = (e: any, item: any) => {
		let poster_list: any = this.state.poster_list;
		poster_list[e]['select'] = !poster_list[e]['select'];
		this.setState(() => {
			return {
				poster_list,
			}
		})
	}

	//海报切换
	posterChange = (e: any) => {
		//console.log(e.target.value)
		this.onDeleteNode('canMain')
		if(e.target.value == 2) {
			this.setState({
				poster_status: e.target.value,
				own_can: 0,
				myCanvasList: [],
			})
		} else {
			this.setState({
				poster_status: e.target.value,
				own_can: 0,
				myCanvasList: [],
			})
		}

	}

	render() {
		const { poster_total, poster_status, poster_list, select_poster, status, rechargeStatus, is_bothway, channel, imageKey, token, QINIU_SERVER, formData, visible, selectCoupon, user_type, selectList, current, listNum, start_time, end_time } = this.state;
		const { groupList, data, value, fetching, invitation_type } = this.state;
		return <div className='l_content'>
			<div className="edit_title">分销配置信息</div>
			{/* <input type='file' /> */}
			<Row className="mb30" type="flex" align="middle">
				<Col span={2}>
					<div>
						<span className="star"></span>
						<span className="color_black">关联小程序</span>
					</div>
				</Col>
				<Col span={22}>
					<div>刷吃</div>
				</Col>
			</Row>

			<Row className="mb30" type="flex" align="middle">
				<Col span={2}>
					<div>
						<span className="star"></span>
						<span className="color_black">奖励比例</span>
					</div>
				</Col>
				<Col span={22} style={{display: 'flex',alignItems: 'center',}}>
					好友下单首单奖金比例
					<Input
						style={{ width: 80, textAlign: 'center',marginLeft: '10px', marginRight: '10px' }}
						suffix="%"
						value={Number(formData.first_order_percent)}
						onChange={(e) => this.firstOrderPercent(e.target.value)} />
					<Col>，之后每单 </Col>
					<Input
						style={{ width: 80, textAlign: 'center',marginLeft: '10px', marginRight: '10px' }}
						suffix="%"
						value={Number(formData.order_percent)}
						onChange={(e) => this.orderPercent(e.target.value)} />
				</Col>
			</Row>

			<Row type="flex" className="mb30">
				<Col span={2}>
					<div>
						<span className="star"></span>
						<span className="color_black">邀请规则</span>
					</div>
				</Col>
				<Col>
					<TextArea
						rows={8}
						style={{ width: 500, padding: '10px' }}
						value={formData.rules}
						onChange={(e) => this.rulesChange(e.target.value)} />
				</Col>
			</Row>

			<Row className="mb30" type="flex" align="middle">
				<Col span={2}>
					<div>
						<span className="star"></span>
						<span className="color_black">邀请海报</span>
					</div>
				</Col>
				<Col span={22}>
					<Radio.Group
						name='poster_status'
						value={poster_status}
						onChange={(e) => this.posterChange(e)}
					>
						<Radio value={1}>系统海报</Radio>
						<Radio value={2}>自定义海报</Radio>
					</Radio.Group>
				</Col>
			</Row>

			<Row type="flex" className="mb30">
				<Col span={2}>
					<div style={{paddingTop: '8px'}}>
						<span className="star"></span>
						<span className="color_black">选择模版</span>
						<Tooltip placement="topLeft" title={"建议尺寸1080， 请勿上传超过2M的图片"}>
							<Icon type="question-circle" theme="filled" />
						</Tooltip>
					</div>
				</Col>
				<Col span={22} style={{display: 'flex', overflowX: (this.state.own_can > 2) ? 'scroll' : 'visible', paddingTop: '24px'}}>
					{
						poster_status == 1
						? <div style={{
							width: '155px',
							height: '186px',
							borderRadius: '4px',
							border: '1px solid #d9d9d9',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							cursor: 'pointer',
							marginRight: '30px'
							}}
							onClick={() => {this.showSelect()}}
						>
							<div style={{width: '40px',height: '4px',backgroundColor: '#d9d9d9'}}></div>
							<div style={{width: '40px',height: '4px',backgroundColor: '#d9d9d9',display: 'flex',transform: 'rotate(90deg)', position: 'absolute'}}></div>
						</div>
						: <Upload
							showUploadList={false}
							action={QINIU_SERVER}
							accept="image/*"
							beforeUpload={this.handlebeforeUpload}
							onChange={e => this.uploadChange(e)}
							data={{
								token,
								region: `https://up-z2.qiniu.com`,
								key: imageKey,
								"x:type": "poster"
							}}
						>
							<div style={{
								width: '155px',
								height: '186px',
								borderRadius: '4px',
								border: '1px solid #d9d9d9',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								cursor: 'pointer',
								marginRight: '30px'
							}}>
								<div style={{width: '40px',height: '4px',backgroundColor: '#d9d9d9'}}></div>
								<div style={{width: '40px',height: '4px',backgroundColor: '#d9d9d9',display: 'flex',transform: 'rotate(90deg)', position: 'absolute'}}></div>
							</div>
						</Upload>

					}
						<Row style={{ display: 'flex' }} id="canMain">
							{/* <div style={{display: 'flex'}}>
								<canvas
									id="can0"
								></canvas>
								<img src={newClose} style={{
									position: 'absolute', right: '-24px', top: '-24px', width: '48px', height: '48px', zIndex: 3, cursor: 'pointer'
								}} />
							</div> */}
						</Row>
				</Col>
			</Row>

			{/* <Row className="mb30" type="flex" align="middle">
				<Col span={2}>
					<div>
						<span className="star"></span>
						<span className="color_black">状态</span>
					</div>
				</Col>
				<Col span={22}>
					<Radio.Group
						name='status'
						value={status}
						onChange={(e) => this.nameChange(e)}
					>
						<Radio value={1}>开启</Radio>
						<Radio value={2}>暂停</Radio>
					</Radio.Group>
				</Col>
			</Row> */}

			{/* <Row className="mb30" type="flex" align="middle">
				<Col span={2}>
					<div>
						<span className="star"></span>
						<span className="color_black">邀请奖励开关</span>
					</div>
				</Col>
				<Col span={22} style={{display: 'flex', alignItems: 'center', height: '40px',fontSize: '16px'}}>
					<Switch checked={rechargeStatus} onChange={(e) => this.welfareChange(e)} checkedChildren="开启" unCheckedChildren="关闭" />
					<span style={{marginLeft: '10px', fontSize: '14px'}}>开启邀请奖励开关，可设置受邀请人获得奖品</span>
				</Col>
			</Row> */}

			{
				rechargeStatus
				? <Row>
					<Row className="mb30" type="flex" align="middle">
						<Col span={2}>
							<div>
								<span className="star"></span>
								<span className="color_black">邀请特权</span>
								<Tooltip placement="topLeft" title={"邀请特权，指定某些人邀请用户才能获得奖励"}>
									<Icon type="question-circle" theme="filled" />
								</Tooltip>
							</div>
						</Col>
						<Col span={22}>
							<Radio.Group
								name='invitation_type'
								value={invitation_type}
								onChange={(e) => this.nameChange(e)}
								disabled={this.state.edit}
							>
								<Radio value={'all'}>所有人</Radio>
								<Radio value={'group'}>指定用户分组</Radio>
								<Radio value={'designated_user'}>指定用户</Radio>
							</Radio.Group>
						</Col>
					</Row>
					{
						invitation_type == 'group'
							? <div>
								<Row className="mb30" type="flex" align="middle">

									<Col span={2}>
										<div>
											<span className="star"></span>
											<span className="color_black">发放用户分组</span>
										</div>
									</Col>
									<Col span={10}>
										<Select placeholder='请选择用户分组' disabled={this.state.edit} mode="multiple" style={{ width: '100%' }} onChange={(e) => this.groupChange(e)} optionFilterProp="children">
											{
												groupList.map((item: any) => {
													return <Option value={item.id} key={item.id}>{item.name}</Option>
												})
											}
										</Select>
									</Col>
								</Row>

							</div>
							: invitation_type == 'designated_user' ? <Row className="mb30" type="flex" align="middle">
								<Col span={2}>
									<div>
										<span className="star"></span>
										<span className="color_black">发放用户</span>
									</div>
								</Col>
								<Col span={6}>
									<Select
										placeholder='请选择用户'
										showSearch
										mode="multiple"
										labelInValue
										value={value}
										notFoundContent={fetching ? <Spin size="small" /> : null}
										filterOption={false}
										onSearch={this.fetchUser}
										onChange={this.handleChange}
										style={{ width: '100%' }}
										disabled={this.state.edit}
									>
										{
											data.map((item: any) => {
												return <Option value={item.id} key={item.id}>{item.nickname}</Option>
											})
										}
									</Select>
								</Col>
							</Row>
								: null
					}
					<Row className="mb30" type="flex" align="middle">
						<Col span={2}>
							<div>
								<span className="star"></span>
								<span className="color_black">领券方式</span>
							</div>
						</Col>
						<Col span={22}>
							<Radio.Group
								name='is_bothway'
								value={is_bothway}
								onChange={(e) => this.nameChange(e)}
							>
								<Radio value={false}>仅受邀请人获得</Radio>
								<Radio value={true}>邀请人和受邀请人同时获得</Radio>
							</Radio.Group>
						</Col>
					</Row>


					<Row className="mb30" type="flex" align="middle">
						<Col span={2}>
							<div>
								<span className="star"></span>
								<span className="color_black">活动礼品</span>
							</div>
						</Col>
						<Col span={16} style={{ filter: `${this.state.edit ? 'grayscale(100%)' : ''}` }}>
							<div onClick={this.showTable}>
								<Icon type="plus-circle" style={{ fontSize: '20px', marginRight: '10px' }} />
									关联优惠券
								</div>

							{
								selectCoupon.length > 0
									? <Table
										size="middle"
										rowKey={"id"}
										dataSource={selectCoupon}
										columns={this.columnNum}
										style={{ marginTop: '20px' }}
										pagination={
											{
												hideOnSinglePage: true,
												total: selectCoupon.length,
											}
										}
									/>
									: null
							}

						</Col>
					</Row>
				</Row>
				: ''
			}


			<Row className="mb30" type="flex" align="middle">
				<Col span={2}>
					<div>
						<span className="star"></span>
						<span className="color_black">状态</span>
					</div>
				</Col>
				<Col span={22}>
					<Radio.Group
						name='status'
						value={status}
						onChange={(e) => this.nameChange(e)}
					>
						<Radio value={1}>开启</Radio>
						<Radio value={2}>暂停</Radio>
					</Radio.Group>
				</Col>
			</Row>



			<Row>
				<Col span={12}>
					<div className="bottom_wrap">
						<Button type="primary" ghost onClick={this.goBack}>
							取消
                            </Button>
						<Button type="primary" onClick={this.handleSubmit}>
							保存
                            </Button>
					</div>
				</Col>
			</Row>

			<Modal
				title={null}
				visible={visible}
				onOk={this.handleOk}
				onCancel={() => { this.setState({ visible: false }) }}
				width="70vw"
			>
				<div className="model_head">
					<div className="head_title">关联优惠券</div>
				</div>
				<Table
					size="middle"
					rowKey={"id"}
					dataSource={selectList}
					columns={this.columns}
					pagination={
						{
							current,
							total: listNum,
							onChange: this.changePage,
							pageSize: 10
						}
					}
				/>
			</Modal>

			<Modal
				title={null}
				visible={select_poster}
				onOk={this.posterHandleOk}
				onCancel={() => { this.setState({ select_poster: false }) }}
				width="890px"
			>
				<div className="model_head">
					<div className="head_title">选择模板</div>
				</div>
				<div style={{display: 'flex', marginBottom: '26px', cursor: 'pointer'}}>
					{
						poster_list.map((item: any, index: any) => {
							return (
								<div style={{flexBasis: '33.33%', justifyContent: 'center', display: 'flex', alignItems: 'center', flexDirection: 'column'}} onClick={() => {this.tempChange(index,item)}}>
									<div><img style={{width: '204px', height: '363px'}} src={item.background} /></div>
									<div style={{marginTop: '20px', display: 'flex', alignItems: 'center'}}>
										{
											item.select
											? <span style={{width: '15px', height: '15px', marginRight: '5px' ,border: '1px solid #064CFF', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
												<span style={{width: '7px', height: '7px', borderRadius: '50%', display: 'flex', backgroundColor: '#064CFF'}}></span>
											</span>
											: <span style={{width: '15px', height: '15px', marginRight: '5px' ,border: '1px solid #064CFF', borderRadius: '50%', display: 'flex'}}></span>
										}
										<span style={{color: '#333333', fontSize: '14px'}}>模版{index+1}</span>
									</div>
								</div>
							)
						})
					}
				</div>
				<Pagination style={{display: 'flex', justifyContent: 'flex-end'}} size="middle" pageSize={3} showQuickJumper current={current} onChange={this.changePage} defaultCurrent={1} total={poster_total} />
				{/* <Table
					size="middle"
					showSizeChanger
					rowKey={"id"}
					dataSource={poster_list}
					columns={this.columns_poster}
					showHeader={false}
					style={{display: 'flex'}}
					pagination={
						{
							current,
							total: listNum,
							onChange: this.changePage,
							pageSize: 3
						}
					}
				/> */}
			</Modal>

		</div>;
	}

}
export default WeappDistriutionAdd