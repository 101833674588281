import React, { useEffect, useState } from 'react'
import { Row, Input, Table, message, Modal, Tag, Tooltip, Switch, Col } from 'antd'
import { useHistory,  } from 'react-router-dom'
import { getRequest } from '../../api/http'
import { apiUrl } from '../../api/api_url'
import t_member from '../../assret/images/t_member.png'
import t_manage from '../../assret/images/t_manage.png'
import t_manage_g from '../../assret/images/t_manage_g.png'
import t_edit from '../../assret/images/t_edit.png'
import t_delete from '../../assret/images/t_delete.png'
import btn_add from '../../assret/images/btn_add.png'
import t_data from '../../assret/images/t_data.png'
import t_data_g from '../../assret/images/t_data_g.png'
import t_invite from '../../assret/images/t_invite.png'

import styles from './customGroup.module.scss'
import { getIsPermisson, messageCustom } from '../../utils/permisstionUtil'

const { Search } = Input;
const { confirm } = Modal;
type ImemberGroup = {
  id: number,
  name: string,
  member_num: number,
  members: Array<{}>
}

type Iprops = {
  currentTab: string,
}

const VipGroup = (props:Iprops) => {

  const history = useHistory();
  const { currentTab } = props
  const [memberGroup, setmemberGroupList] = useState<Array<ImemberGroup>>([]);
  const [total, setTotal] = useState(0);
  const [paramsObj, setParamsObj] = useState<any>({
      page: 1,
      pageSize: 10,
  });

  useEffect(() => {
    if(currentTab=='custom'){
      getMerberGroupList({...paramsObj})
    }
  }, [currentTab])

  const getMerberGroupList = async (data:any = {}) => {
    let param = {
      groupType: 1, //分组类型
      ...data
    }
    if(param.sortType)param.sortType=param.sortType=='ascend'?'asc':'desc'
    setParamsObj({...param})
    const res:any = await getRequest(apiUrl.getGroupListNew, param);
    if(res && res['code'] === 1) {
      setmemberGroupList(res.data.list);
      setTotal(res.data.total)
    }
  }

  const addGroup = () => {
    if(!getIsPermisson('1_6_00_3')){
      messageCustom();
      return;
    }
    history.push({
      pathname: '/sidebar/memberGroupCustomAdd'
    })
  }

  // 删除分组
  const deleteGroup = async (rowdata: any) => {
    confirm({
      title: '删除',
      content: '删除后，将解散改分组的用户，是否删除？',
      okText: "删除",
      cancelText: "取消",
      onOk: async () => {
        const res:any = await getRequest(apiUrl.memberGroupDelete, { group_id: rowdata['id'] });
        if(res && res['code'] === 1) {
          message.success("删除成功");
          getMerberGroupList({...paramsObj});
        } else {
          message.error(`${res.message}`);
        }
      },
      onCancel() {},
    });
  }

  const searchGroup = async (val: string) => {
    const arr:any = [];
    getMerberGroupList({
        ...paramsObj,
        name:val,
        page:1,
    })
  }

  const columns = [
  {
      title: '序号',
      dataIndex: 'id',
      render:(text: any,record: any,index:number)=>`${index+1}`,
  },
  {
    title: '分组名称',
    dataIndex: 'name',
  },
  {
    title: '客户数量',
    dataIndex: 'member_num',
    sorter:true,
  },
  // {
  //   title: '上月客户数量',
  //   dataIndex: 'last_month_member_num',
  //   sorter:true,
  // },
  {
    title: '创建时间',
    dataIndex: 'create_time',
  },
  {
    title: '更新时间',
    dataIndex: 'update_time',
  },
  {
    title: '创建人',
    dataIndex: 'nickname',
    render:(text: any,record: any,index:number)=>text?text:'系统默认',
  },
  {
    title: '区别对待',
    render: (rowdata: any) => {
      return rowdata ? <Row>{rowdata.is_difference_treat ? "是" : "否"}</Row>:null
    }
  },
  {
    title: '操作',
    key: 'action',
    width:500,
    render: (rowdata: any) => {
      return <Row type="flex" align='middle' justify='center'>
        <Tooltip placement="bottomRight" title={'查看人员'}>
          <img src={t_member} className={styles.table_icon} onClick={() => {
            if(!getIsPermisson('1_6_00_4')){
              messageCustom();
              return;
            }
            history.push({pathname: '/sidebar/memberGroupVipList', state: {title: `${rowdata.name}`, id: rowdata.id, is_default: rowdata.is_default}})
          }}></img>
        </Tooltip>

        <Tooltip placement="bottomRight" title={'去运营'}>
          <img src={!rowdata.operation_id ? t_manage : t_manage_g} className={styles.table_icon} onClick={() => {
            if(!getIsPermisson('1_6_00_5')){
              messageCustom();
              return;
            }
            if(!rowdata.operation_id) history.push('/sidebar/marketingAdd',{group_id:rowdata.id,name:rowdata.name,is_disabled:true})}
          }></img>
        </Tooltip>

        <Tooltip placement="bottomRight" title={'编辑'}>
          <img src={t_edit} className={styles.table_icon} onClick={() => {
            if(!getIsPermisson('1_6_00_6')){
              messageCustom();
              return;
            }
            history.push({pathname: "/sidebar/memberGroupCustomAdd", state: { rowdata }})
          }}></img>
        </Tooltip>
        {
          rowdata.system_id <= 0 && <Tooltip placement="bottomRight" title={'删除'}>
                                      <img src={t_delete} className={styles.table_icon} onClick={() => {
                                        if(!getIsPermisson('1_6_00_9')){
                                          messageCustom();
                                          return;
                                        }
                                        deleteGroup(rowdata)
                                      }}></img>
                                    </Tooltip>
        }
        {/* <Tooltip placement="bottomRight" title={'查看数据'}>
          <img src={!rowdata.operation_id ? t_data_g : t_data} className='table_icon' onClick={() => rowdata.operation_id&&history.push({pathname: '/sidebar/marketingDetail', state: { id: rowdata.operation_id, name: rowdata.name}})}></img>
        </Tooltip> */}
        {
          !rowdata.is_default&&<Tooltip placement="bottomRight" title={'邀请进组'}>
            <img src={t_invite} className={styles.table_icon} onClick={() => {
              if(!getIsPermisson('1_6_00_7')){
                messageCustom();
                return;
              }
              getQrcode(rowdata.id,rowdata.name)
            }}></img>
          </Tooltip>
        }
        <span className={styles.action_button} onClick={() => {
          setGroupTreat(rowdata)
        }}>
          <Switch checked={rowdata.is_difference_treat ? true : false} size='small' style={{marginRight: '5px'}} />
          {rowdata.is_difference_treat ? "取消区别对待" : "区别对待"}
        </span>
      </Row>
    }
  },]

  let getQrcodeLoing = true
  const getQrcode = async(id:any,title:string) => {
    try{
      if(!getQrcodeLoing) return false
      getQrcodeLoing = false
      const res:any = await getRequest(apiUrl.getJoinGroupQrCode, { group_id: id })
      getQrcodeLoing = true
      if(res.code==1&&res.data){
          Modal.info({
              okText: '',
              icon: '',
              title: title,
              centered:true,
              className: styles.invite_group_modal,
              content:
                <div style={{textAlign:'center',marginRight:38}}>
                  <img src={res.data.url} style={{width:200,height:200}}/>
                  <div className="fz16">请截图发给目标用户扫码加入</div>
                </div>
          })
      }else{
        message.error(res.message||'获取二维码失败')
      }
    }catch(err){
      getQrcodeLoing = true
    }
  }

  const setGroupTreat = async (rowdata: any) => {
    if(!getIsPermisson('1_6_00_8')){
      messageCustom();
      return;
    }
    const res:any = await getRequest(apiUrl.setGroupTreat, { id: rowdata.id,  is_difference_treat: !rowdata.is_difference_treat });
    if(res && res.code === 1) {
      message.success('设置成功');
      getMerberGroupList({...paramsObj});
    } else {
      message.error(res.msg);
    }
  }

  const handleTableChange = (pagination:any, filters:any, sorter:any) => {
    getMerberGroupList({
        ...paramsObj,
        page:pagination.current,
        pageSize:pagination.pageSize,
        sortField:sorter.field,
        sortType:sorter.order
    })
  }

  return <Row style={{padding:'15px'}} className={`${styles.vip_group}`}>
    <Row type="flex" justify='space-between' align='middle'>
        <div className={styles.new_btn} onClick={addGroup}>
          <img src={btn_add}></img>
          新建分组
        </div>
        <Search
          placeholder="请输入分组名称"
          allowClear
          style={{width: '300px'}}
          onSearch={value => searchGroup(value)}
        />
    </Row>
    <Table size="middle" rowKey={"id"} columns={columns}  dataSource={memberGroup}
      style={{marginTop: '30px'}}
      onChange={handleTableChange}
      pagination={{
        defaultCurrent: 1,
        current:paramsObj.page,
        pageSize: paramsObj.pageSize,
        total: total,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total: number) => `共${total}条`,
      }}
     />
  </Row>
}

export default VipGroup