import React from 'react';
import {Table, Tooltip, Icon, Button, message, Select} from 'antd'
import { getRequest, postRequest } from '../../../../api/http';
import { apiUrl } from '../../../../api/api_url';
import equal_icon from '../../../../assret/images/equal_icon.png'
import reduce_icon from '../../../../assret/images/reduce_icon.png'
import add_icon from '../../../../assret/images/add_icon.png'
import new_detail from '../../../../assret/images/new_detail.png'
import print_select from '../../../../assret/images/print_select.png'
import deep_add from '../../../../assret/images/deep_add.png'
import deep_del from '../../../../assret/images/deep_del.png'
import new_detail_white from '../../../../assret/images/new_detail_white.png'
import { getIsPermisson, messageCustom } from '../../../../utils/permisstionUtil';
import OrderPadding from '../components/order_padding_detail';
import styles from './recharge_record.module.scss';
const { Option } = Select;

class FinanceDetail extends React.Component<any> {
    column = [
        {
            title: "订单号",
            dataIndex: "code",
            key: "code",
        },
        {
            title: '订单状态',
            dataIndex: "status_display",
            key: "status_display",
        },
        {
            title: "餐台号",
            dataIndex: "desk",
            key: "desk",
        },
        {
            title: "用餐人数",
            dataIndex: "user_amount",
            key: "user_amount",
        },
        {
            title: "已点（份）",
            dataIndex: "foods_num",
            key: "foods_num",
        },
        {
            title: "下单金额",
            dataIndex: "origin_fee",
            key: "origin_fee",
        },
        {
            title: "付款用户",
            dataIndex: "fan",
            key: "fan",
            render: (text: string, record: any) => {
                return <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={record.fan.headimgurl} style={{width: '20px', height: '20px', borderRadius: '50%', marginRight: '5px'}} />
                    <div>{record.fan.nickname}</div>
                </div>
            }
        },
        {
            title: "优惠类型",
            dataIndex: "discount_type",
            key: "discount_type",
            render: (text: string, record: any) => {
                return <div>
                    {
                        record.discount_type ? record.discount_type.map((item:any) => {
                            return <div key={item}>{item}</div>
                        }): '--'
                    }
                </div>
            }
        },
        {
            title: "优惠名称",
            dataIndex: "discount_name",
            key: "discount_name",
            render: (text: string, record: any) => {
                return <div>
                    {
                        record.discount_name ? record.discount_name.map((item:any) => {
                            return <div key={item}>{item}</div>
                        }): '--'
                    }
                </div>
            }
        },
        {
            title: "优惠金额",
            dataIndex: "discount_fee",
            key: "discount_fee",
            render: (text: string, record: any) => {
                return <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {
                        record.discount_fee ? record.discount_fee: '--'
                    }
                </div>
            }
        },
        {
            title: "余额支付",
            dataIndex: "over_pay",
            key: "over_pay",
            render: (text: string, record: any) => {
                return <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {
                        record.over_pay ? record.over_pay: '--'
                    }
                </div>
            }
        },
        {
            title: "实付金额",
            dataIndex: "actual_fee",
            key: "actual_fee",
        },
        {
            title: "支付类型",
            dataIndex: "pay_type",
            key: "pay_type",
        },
        {
            title: "创建时间",
            dataIndex: "created_at",
            key: "created_at",
        },
        {
            title: "支付时间",
            dataIndex: "paid_at",
            key: "paid_at",
        },
        {
            title: "操作",
            dataIndex: "updated_at",
            key: "updated_at",
            className: "list_center",
            render: (text: string, rowdata:any) => {
                return <div style={{display: 'flex', alignItems: 'center'}}>
                    <Tooltip placement="bottomRight" title={'详情'}>
                        <img src={new_detail} className='table_icon' onClick={() => this.showOrderDetail(rowdata)}></img>
                    </Tooltip>
                    <Tooltip placement="bottomRight" title={'补打印结账单'}>
                        <img src={print_select} className='table_icon' onClick={() => this.printStatement(rowdata)}></img>
                    </Tooltip>
                </div>
            }
        },
    ];

    state = {
        list: [],
        preview: {},
        current: 1,
        pageSize: 10,
        count: 0,
        showPaddingOrderDrawer: false,
        currentDrawerData: {
            desk: '',
            dishes: [],
            amount: 0,
            price: '',
            price_vip: '',
            code: '',
            status_display: '',
        },
        incomePreview: {
            "order_dish_refund_detail": {//退款退菜明细项
                "dish_pay_refund": "0.00",//退款金额
                "dish_order_refund": "287.90"//退菜金额
            },
        },
        pay_type: undefined
    };

    componentDidMount() {
        this._initInfo()
        console.log(this.props.location.state.from);
        if(this.props.location.state && this.props.location.state.from === 'income'){
            this._initIncomePreview()
        }else{
            this._initPreview()
        }
    }

    // 初始化数据
    _initInfo = async (data={}) => {
        let start_time = this.props.location.state && this.props.location.state.open_at;
        let end_time = this.props.location.state && this.props.location.state.close_at;

        let { pageSize, current, pay_type} = this.state;
        let params: any = {
            current,
            pageSize,
            pay_type
        };
        if(pay_type){
            params.pay_type = pay_type;
        }
        if (start_time !== "") {
            params.start_time = start_time;
            params.end_time = end_time;
        }
        console.log('start_time', start_time);
        let res: any = await getRequest(apiUrl.orderFinance, params);
        if (res.code === 1) {
            let list: any = res.data.list;
            this.setState({
                count: res.data.count,
                list: list,
            });
        }
    };

    _initPreview = async () => {
        let start_time = this.props.location.state && this.props.location.state.open_at;
        let end_time = this.props.location.state && this.props.location.state.close_at;
        let params: any = {};
        if (start_time !== "") {
            params.start_time = start_time;
            params.end_time = end_time;
        }
        let res: any = await getRequest(apiUrl.financeReport, params);
        if (res.code === 1) {
            this.setState({
                preview: res.data.preview ? res.data.preview : {}
            })
        }
    }

    _initIncomePreview = async () => {
        let start_time = this.props.location.state && this.props.location.state.open_at;
        let end_time = this.props.location.state && this.props.location.state.close_at;
        let params:any = {}
        if(start_time !== ''){
            params.start_time = start_time;
            params.end_time = end_time;
        }
        let res:any = await getRequest(apiUrl.shopReport, params);
        if(res.code === 1){
            this.setState({
                incomePreview: res.data.preview
            })
        }
    }

    pageChange = (page: number) => {
        this.setState({
            current: page
        }, () => {
            this._initInfo();
        });
    };

    payTypeChange = (e: any) => {
        this.setState({
            pay_type: e
        }, () => {
            this._initInfo({
                pay_type:  e
            })
        })
    }

     // 打印结账单
  printStatement = async (rowdata: any) => {
    if(!getIsPermisson('1_4_1_3')){
      messageCustom();
      return;
    }
    console.log('row', rowdata);
    const res:any = await postRequest(apiUrl.printBill, { code: rowdata.code, group_key: rowdata.group_key, has_invoice: false});
    if(res && res['code'] === 1) {
      message.success('打印成功');
    }
  }

 // 显示订单详情
 showOrderDetail = async (rowdata: any) => {
    if(!getIsPermisson('1_4_1_2')){
      messageCustom();
      return;
    }
    this.setState({
        currentDrawerData: rowdata,
        showPaddingOrderDrawer: true
    })
  }

  // 详情明细
  toCouponDetail = () => {
    let start_time = this.props.location.state && this.props.location.state.open_at;
    let end_time = this.props.location.state && this.props.location.state.close_at;
    this.props.history.push({
        pathname: "/sidebar/incomeDetail",
        state: {
            open_at: start_time,
            close_at: end_time
        }
    });
}

    render() {
        let {
            list,
            current,
            pageSize,
            count,
        } = this.state;
        let preview: any = this.state.preview;
        let incomePreview:any = this.state.incomePreview;
        return (
            <div className="income" style={{padding: '20px'}}>
                <div className='charge_title mb20'>{this.props.location.state && this.props.location.state.from !== 'income' ? '财务收入详情' : '营业收入明细'}
                    <div className={styles.rechargeRecord_query_list} style={{display: 'inline-block', 'marginLeft': '100px'}}>
                        <div className={styles.rechargeRecord_query_list_title} style={{display:'inline'}}>支付方式：</div>
                        <Select placeholder="请选择支付类型" value={this.state.pay_type} style={{ width: 160 }} onChange={this.payTypeChange} allowClear>
                            <Option value={'wechat'}>微信</Option>
                            <Option value={'alipay'}>支付宝</Option>
                            <Option value={'money'}>现金</Option>
                            <Option value={'no-pay'}>免单</Option>
                            <Option value={'over_pay'}>余额支付</Option>
                            <Option value={'meituan'}>美团支付</Option>
                            <Option value={'koubei'}>口碑支付</Option>
                            <Option value={'bank'}>银行卡支付</Option>
                        </Select>
                    </div>
                </div>
                { this.props.location.state && this.props.location.state.from !== 'income'
                    ? <div className='data_wrap mb30'>
                    <div className='data_shadow'>
                        <div className='data_title'>
                            <div className='data_title_l'>系统实收<Tooltip title={`已打款金额：${preview.settle_account_true}元和未打款${preview.settle_account_false}元`}>
                                <div className="finance_tip"><Icon type="question-circle" theme="filled" /></div></Tooltip>
                                </div>
                            <div className='data_title_r'>￥{preview.sys_actual_total}</div>
                        </div>
                        <div className="data_content">
                            <div className="data_con_item">
                                <div className='data_item_title'>
                                    <div className='data_item_title_t'>微信</div>
                                    <div className='data_item_title_b'>￥{preview.wechat}</div>
                                </div>
                                <img src={reduce_icon} className='action_icon_small_reduce' />
                                <div className='data_item_title'>
                                    <div className='data_item_title_t'>服务费<Tooltip title={`服务费是微信官方收取${preview.service_charge_rate}手续费`}>
                                    <div className="finance_tip">
                                        <Icon type="question-circle" theme="filled" />
                                    </div>
                                </Tooltip></div>
                                    <div className='data_item_title_b'>￥{preview.wechat_service_fee}</div>
                                </div>
                                <img src={equal_icon} className='action_icon_small_equal' />
                                <div className='data_item_title'>
                                    <div className='data_item_title_t'>实收</div>
                                    <div className='data_item_title_b'>￥{preview.wechat_actual}</div>
                                </div>
                            </div>
                            <div className="data_con_item">
                                <div className='data_item_title'>
                                    <div className='data_item_title_t'>支付宝</div>
                                    <div className='data_item_title_b'>￥{preview.ali}</div>
                                </div>
                                <img src={reduce_icon} className='action_icon_small_reduce' />
                                <div className='data_item_title'>
                                    <div className='data_item_title_t'>服务费<Tooltip title={`服务费是支付宝官方收取${preview.service_charge_rate}手续费`}>
                                    <div className="finance_tip">
                                        <Icon type="question-circle" theme="filled" />
                                    </div>
                                </Tooltip></div>
                                    <div className='data_item_title_b'>￥{preview.ali_service_fee}</div>
                                </div>
                                <img src={equal_icon} className='action_icon_small_equal' />
                                <div className='data_item_title'>
                                    <div className='data_item_title_t'>实收</div>
                                    <div className='data_item_title_b'>￥{preview.ali_actual}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src={add_icon} className='action_icon_add' />
                    <div className='data_shadow'>
                        <div className='data_title'>
                            <div className='data_title_l'>第三方平台<Tooltip title={'第三方平台总收入，扣除第三方手续费'}>
                                <div className="finance_tip">
                                    <Icon type="question-circle" theme="filled" />
                                </div>
                            </Tooltip></div>
                            <div className='data_title_r'>￥{preview.third_actual_total_fee}</div>
                        </div>
                        <div className="data_content">
                            {
                                preview.others && preview.others.map((item:any) => {
                                    return <div className="data_con_item">
                                        <div className='data_item_title'>
                                            <div className='data_item_title_t'>{item.name}</div>
                                        </div>
                                        <div className='data_item_title'>
                                            <div className='data_item_title_b'>￥{item.total_fee_actual}</div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                    <img src={add_icon} className='action_icon_add' />
                    <div className='data_shadow'>
                        <div className='data_title'>
                            <div className='data_title_l'>现金实收</div>
                            <div className='data_title_r'>￥{preview.money}</div>
                        </div>
                        <div className='data_title'>
                            <div className='data_title_l'>银行卡实收</div>
                            <div className='data_title_r'>￥{preview.bank}</div>
                        </div>
                    </div>
                    <img src={equal_icon} className='action_icon_equal' />
                    <div className='data_shadow'>
                        <div className='data_total'>
                            <div className="data_total_name">商家应得<Tooltip title={'商家应得=系统实收+第三方平台+现金实收+银行卡实收'}>
                                <div className="finance_tip">
                                    <Icon type="question-circle" theme="filled" />
                                </div>
                            </Tooltip></div>
                            <div className="data_total_value">￥{preview.shop_total_amount}</div>
                        </div>
                    </div>
                </div>
                :
                <div className='mb20 data_wrap'>
                        <div className='data_shadow income_item'>
                            <div className='income_total'>
                                <div className='income_total_l'>应收账款</div>
                                <div className='income_total_r'>￥{incomePreview.origin_total}</div>
                            </div>
                            <div className="income_info">
                                <div className='income_info_l'>日均销售额</div>
                                <div className='income_info_r'>￥{incomePreview.origin_day_aver}</div>
                            </div>
                            <div className='income_rate'>
                                <div className='income_rate_l'>
                                    <Tooltip title={`用户消费后应该付的原价金额，未扣除优惠和退款`}>
                                        <Icon type="question-circle" theme="filled" />
                                    </Tooltip>
                                </div>
                                <div className='income_rate_r'>
                                    环比<img src={incomePreview.origin_is_up ? deep_add : deep_del} />{incomePreview.origin_chain_ratio}
                                </div>
                            </div>
                        </div>
                        <img src={reduce_icon} className='income_icon_reduce' />
                        <div className='data_shadow income_item'>
                            <div className='income_total borderB'>
                                <div className='income_total_l'>优惠金额</div>
                                <div className='income_total_r'>￥{incomePreview.discount_total}</div>
                            </div>
                            <div className='income_con' style={{paddingBottom: '20px'}}>
                                {
                                    incomePreview.discount_detail && incomePreview.discount_detail.map((item:any) => {
                                        return <div className="income_info" key={item.name}>
                                            <div className='income_info_l'>{item.name}</div>
                                            <div className='income_info_r'>￥{item.value}</div>
                                        </div>
                                    })
                                }
                                <div style={{width:'100%', display: 'flex', justifyContent:'flex-end'}}>
                                    <div className='detail_btn' onClick={this.toCouponDetail}>
                                        <img src={new_detail_white} />
                                        详情明细
                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* <img src={reduce_icon} className='income_icon_reduce' />
                        <div className='data_shadow income_item'>
                            <div className='income_total borderB'>
                                <div className='income_total_l'>退款退菜</div>
                                <div className='income_total_r'>￥{incomePreview.order_dish_refund_total}</div>
                            </div>
                            <div className='income_con' style={{paddingBottom: '20px'}}>
                                <div className="income_info">
                                    <div className='income_info_l'>退款金额</div>
                                    <div className='income_info_r'>￥{incomePreview.order_dish_refund_detail.dish_pay_refund}</div>
                                </div>
                                <div className="income_info">
                                    <div className='income_info_l'>退菜金额</div>
                                    <div className='income_info_r'>￥{incomePreview.order_dish_refund_detail.dish_order_refund}</div>
                                </div>
                            </div>
                        </div> */}
                        <img src={equal_icon} className='income_icon_equal' />
                        <div className='data_shadow income_item'>
                            <div className='income_total'>
                                <div className='income_total_l'>实收账款</div>
                                <div className='income_total_r'>￥{incomePreview.actual_total}</div>
                            </div>
                            <div className="income_info">
                                <div className='income_info_l'>日均实收金额</div>
                                <div className='income_info_r'>￥{incomePreview.actual_day_aver}</div>
                            </div>
                            <div className='income_rate'>
                                <div className='income_rate_l'>
                                    <Tooltip title={`实收账款=应收账款-优惠金额-退款退菜`}>
                                        <Icon type="question-circle" theme="filled" />
                                    </Tooltip>
                                </div>
                                <div className='income_rate_r'>
                                    环比<img src={incomePreview.actual_is_up ? deep_add : deep_del} />{incomePreview.actual_chain_ratio}
                                </div>
                            </div>
                        </div>
                    </div>

                }

                <div style={{background:'#fff'}} className='finance_table_wrap'>
                <Table
                    bordered
                    dataSource={list}
                    size="middle"
                    columns={this.column}
                    rowKey={"day"}
                    locale={{ emptyText: "暂无数据" }}
                    pagination={{
                        current,
                        total: count,
                        pageSize,
                        onChange: this.pageChange,
                        showQuickJumper: true,
                        showSizeChanger: true,
                        showTotal: (total: number) => `共${total}条`,
                        onShowSizeChange: (current: number, size: number) => { this.setState({ pageSize: size }, () => { this._initInfo(); }) }
                    }}
                >
                </Table></div>

                {/* 订单详情 */}
      <OrderPadding
        showPaddingOrder={this.state.showPaddingOrderDrawer}
        changeVisitity={(v) => {this.setState({showPaddingOrderDrawer:false})}}
        code={this.state.currentDrawerData.code ? this.state.currentDrawerData.code : ''} />
            </div>
        );
    }
}

export default FinanceDetail;