import React from "react";
import {Button, Row, Col, Input, DatePicker, Table, Modal, Select, message, Icon, Popconfirm} from "antd";
import { postRequest, getRequest } from '../../../api/http';
import { apiUrl } from '../../../api/api_url';
import Sortablejs from 'sortablejs';
import arrayMove from 'array-move';
import './shopSort.scss';

let sort: any = {};

export default class ShopSortRight extends React.Component<any,any> {

    constructor(props: any){
        super(props)
        this.state = {
            pageSize: 200,
            timeArray: [],
            currentPage: 1,
            is_up: null,
            order_by: undefined,
            cat_id: 0,
            searchName: '',
            productList: [],
            copy_productList: [],
            index: 0,
            splic_list: [
               
            ],
        }
        this.props.listEvent(this)
    }

    componentDidMount() {
        this.onSort()
    }

    //拖动排序
    onSort() {
        let el:any = document.getElementById('shop-right');
        sort = Sortablejs.create(el, {
            handle: "div",//设置div支持拖拽
            onStart: (evt: any) => {
                //console.log('onStart:',evt)
            },
            onEnd: (evt: any) => {
                //console.log('onEnd:',evt)
                this.onSortEnd(evt.oldIndex, evt.newIndex)
            }
        })
    }

    onSortEnd = ( oldIndex: any, newIndex : any) => {
        let dataSource: any = [];
        if(this.state.index == 0) {
            dataSource = this.state.productList;
        } else {
            dataSource = this.state.copy_productList;
        }
        if (oldIndex !== newIndex) {
            console.log('Sorted items1: ', dataSource);
            console.log('oldIndex: ', oldIndex);
            console.log('newIndex: ', newIndex);
            const newData = arrayMove([].concat(dataSource), oldIndex, newIndex).filter(el => !!el);
            this.props.listEventValue(newData)
            console.log('Sorted items2: ', newData);
            //1，先判断是否有数据，有则修改，没有则添加
            let splic_list: any = this.state.splic_list;
            let cat_id: any = this.state.cat_id;
            if(splic_list.length == 0) {
                this.setState({
                    copy_productList: newData,
                    splic_list: newData,
                    index: 2
                }, () => {
                    console.log('copy_productList:',this.state.copy_productList)
                    console.log('splic_list:',this.state.splic_list)
                });
            } else {
                this.setState({
                    copy_productList: newData,
                    splic_list: newData
                })
                //标识，用于判断整个数组，是否有指定id的对象。
                // let flah = false;
                // let idx: any = 0; //记录等于true的索引值
                // splic_list.map((item: any, index: any) => {
                //     // console.log('item:',item)
                //     // console.log('cat_id:',cat_id)
                //     //console.log(item['cat_id'] == cat_id)
                //     if(item['cat_id'] == cat_id) { //存在。修改
                //         //splic_list[index]['data'] = newData;
                //         flah = true;
                //         idx = index;
                //     } else { //不存在，添加
                //         flah = false;
                //         //拼接数据
                //         // let obj = {
                //         //     cat_id: cat_id,
                //         //     data: newData
                //         // }
                //         // splic_list.push(obj)
                //     }
                // })
                // console.log('flah:',flah)
                // if(flah) {
                //     splic_list[idx]['data'] = newData;
                // } else {
                //     let obj = {
                //         cat_id: cat_id,
                //         data: newData
                //     }
                //     splic_list.push(obj)
                // }
                // this.setState({
                //     copy_productList: newData,
                //     splic_list,
                //     index: 2
                // }, () => {
                //     this.props.listEventValue(this.state.splic_list)
                //     console.log('splic_list:',this.state.splic_list)
                // });
            }
        }
    };

    //排序接口
    onSortTable = async (arrayList: any) => {
        let count = arrayList.length;
        let temp = arrayList.map((item: any, index: any) => {
            let obj: any = {};
            obj.id = item.id;
            obj.order_by = count - index;
            return (
                obj
            )
        })
        console.log('调用接口排序：',temp)
        const res:any = await getRequest(apiUrl.setDishesSort,{arr:JSON.stringify(temp)});
        if(res.code === 1){
            //this.onMenuList()
        }
    }

    _initList = async (id: any) => {

        const { pageSize, currentPage } = this.state;
        const params: any = {
            current: currentPage,
            cat_id: id,
            pageSize: pageSize
        };

        const res: any = await getRequest(apiUrl.goodList, params);
        if (res.code === 1) {
            //this.props.listEventValue(res.data)
            console.log('_initList:',this.state.splic_list)
            //切换分类就把上一次的商品进行排序
            if(this.state.splic_list.length > 0) {
                this.onSortTable(this.state.splic_list)
            }
            this.setState({
                productList: res.data,
                copy_productList: res.data
            })
        }
    }

    addRecord = (item: any) => {
        console.log('分类id：',item)
        this.setState({
            cat_id: item,
            index: 2,
        }, () => {
            //this.forceUpdate();
            this._initList(item)
        })
    }

    render() {

        const  {productList}  = this.state;

        return (
            <div className="shop-right l_content" id="shop-right">
                {
                   productList.map((item: any, index: any) => {
                        return (
                            <div key={item.id} className="shop-right-list">
                                <div className="shop-right-list-photo">
                                    <img src={item.main_pic} className="shop-right-list-photo-img" />
                                </div>
                                <div className="shop-right-list-name">
                                    { item.name }
                                </div>
                                <div className="shop-right-list-price">
                                    <div>
                                        <span className="shop-right-list-price-left">¥</span>
                                        <span className="shop-right-list-price-right">{item.price}起</span>
                                    </div>
                                    <div>
                                        <Icon type="drag" style={{marginLeft: '10px'}} />
                                    </div>
                                </div>
                                {
                                    item.right_top_tips.tips
                                    ?   <div className="shop-right-list-like">
                                            <Icon type="like" className="shop-right-list-like-icon" />
                                            <span className="shop-right-list-like-num">{item.right_top_tips.tips}人推荐</span>
                                        </div>
                                    : ''
                                }
                            </div>
                        )
                    })
                }
            </div>
        )

    }
}