import React, { Fragment, useState, useEffect, useRef, createElement } from 'react'
import { Row, Typography, Col, Input, Form, Tooltip, Icon, Upload, Button, message } from 'antd'
import TextArea from 'antd/lib/input/TextArea';
import { getRequest, postRequest } from '../../../api/http';
import { apiUrl } from '../../../api/api_url';
import "fabric";
import { useHistory } from 'react-router-dom';
import './weapp_distriution_config.scss';
import closeIcon from '../../../assret/images/close_icon.png'
declare let fabric: any;

const { Title } = Typography;
// 画布 内容 唯一标识
const uniqueKeys = {
  nickName: "nickName",
  avatarUrl: "avatarUrl",
  qrCode: "qrCode",
  backImage: "backImage"
};

let myCanvas: any;

interface IFormData {
  id: number
  rules: string
  first_order_percent: string
  order_percent: string
  poster: string
  xcx: string
}

let upLoadIndex: any = [];
let canvasNum: any = 0;
let ownPoster: any = [];

const key = 'WeappDistriutionManage';

const WeappDistriutionManage = () => {

  const QINIU_SERVER = "https://up-z2.qiniup.com";
  const [imageKey, setimageKey] = useState("");
  const [token, setToken] = useState("");
  const canvasRef = useRef(null);
  const canvasConfig = {
    width: 360,
    height: 340
  };
  const history:any = useHistory();
  const [formData, setformDataValue] = useState<IFormData>({
    id: 0,
    rules: '',
    first_order_percent: '',
    order_percent: '',
    poster: '',
    xcx: ''
  });

  const [inputValue, setinputValue] = useState({
    first_order_percent: '',
    order_percent: ''
  });

  //const [canvasNum,setCanvasNum] = useState(0)

  //记录当前海报索引
  //const [upLoadIndex, setUpLoadIndex] = useState<any>([])

  //当前海报引用对象
  //const [ownPoster, setOwnPoster] = useState<any>([]);

  useEffect(() => {
    
    if(history.location.state && history.location.state.id) {
      inintDetail();
    }
    return () => {
      upLoadIndex = [];
      canvasNum = 0;
      ownPoster = [];
    }
  }, []);

  // //清除变量值
  // const onClear = () => {
  //   upLoadIndex = [];
  //   canvasNum = 0;
  //   ownPoster = [];
  //}

  const inintDetail = async () => {
    const res:any = await getRequest(apiUrl.shareActiviteDetail, { id: history.location.state.id });
    const newval =  {...formData, ...res.data};
    setformDataValue(newval);
    //循环渲染多张海报
    const canvasData = JSON.parse(res.data.poster);
    console.log('canvasData:',canvasData)
    canvasData.map(async (item: any, index:any) => {
      let temp_index = await createCanvas();
      const canvas = new fabric.Canvas(`canvas${temp_index}`, {
        backgroundColor: "#eef0f3",
        width: item.width,
      });
      myCanvas = canvas;
      if(!res.data.poster) {
        initCanvas(canvas);
      } else {
        myCanvas = new fabric.Canvas(`canvas${temp_index}`, {
          backgroundColor: "#eef0f3",
          width: item.width,
          height: item.height
        });
        myCanvas.loadFromJSON(item,() => {
          myCanvas.renderAll();
          //upLoadIndex.push(temp_index)
          //记录当前对象
          ownPoster.push(myCanvas);
        });
      }
    })
  }

  // 初始化canvas
  const initCanvas = (canvas: any) => {
    canvas.setWidth(canvasConfig.width);
    canvas.setHeight(576);
    const defaultQrCodeUrl = "http://ydn-siyu.cdn.shuachi.com/poster/default_qr_code.png";

    const defaultQrCode = new Image();
    defaultQrCode.src = defaultQrCodeUrl;
    defaultQrCode.crossOrigin = 'anonymous';
    defaultQrCode.onload = () => {
      const imgW = defaultQrCode.width;
      const avatarW = 70;
      //const _scale = imgW > avatarW ? avatarW / imgW : imgW / avatarW;
      const _scale = 0.39;
      console.log('_scale:',_scale)
      fabric.Image.fromURL(defaultQrCodeUrl, (imgQrcode: any) => {
        imgQrcode.scale(_scale).set({
          left: 120,
          top: 193,
          // left: 50,
          // top: 100,
          angle: 0,
          crossOrigin: 'anonymous'
        });
        imgQrcode["uniqueKey"] = uniqueKeys.qrCode; // 添加画布二维码唯一标识
        canvas.add(imgQrcode);
      });
    };
    defaultQrCode.onerror = e => {
      console.log("二维码加载错误", e);
    };
  };

  const handlebeforeUpload = async () => {
    const res: any = await getRequest(apiUrl.getUploadToken, {
      type: "poster"
    });
    if (res && res.data) {
      const key = `${Math.random().toString(36).slice(-8)}${new Date().valueOf()}.png`;
      setimageKey(key);
      setToken(res.data.upload_token);
    }
  };

  const handleChange = (e: any) => {
    message.loading({content: '上传中', key})
    if (e.file && e.file.status === "done") {
      console.log("上传成功");
      drawImage(e.file.response.url);
    }
  };

  //动态创建canvas
  const createCanvas = () => {
    return new Promise((resolve, reject) => {
      console.log('canvasNum:',canvasNum)
      let temp_num = canvasNum + 1;
      // 创建canvas
      let create_div: any = document.createElement('div');
      let create_canvas: any = document.createElement('canvas');
      let create_img: any = document.createElement('img');
      create_img.setAttribute('class','qrcode_close_img');
      create_img.onclick = function () {
        deletePoster(temp_num)
      };
      create_img.setAttribute('src',closeIcon);
      create_div.setAttribute('class','qrcode_back_item');
      create_div.id = 'qrcode_back_item' + temp_num;
      create_canvas.id = 'canvas' + temp_num;
      create_div.appendChild(create_canvas);
      create_div.appendChild(create_img);
      document.getElementById('parset-canvas')?.appendChild(create_div);
      canvasNum = temp_num;

      //记录当前canvas索引值
      //console.log('create_index:',temp_index)
      upLoadIndex.push(temp_num)
      resolve(temp_num)
    })
    // console.log('canvasNum:',canvasNum)
    // let temp_num = canvasNum + 1;
    // // 创建canvas
    // let create_div: any = document.createElement('div');
    // let create_canvas: any = document.createElement('canvas');
    // let create_img: any = document.createElement('img');
    // create_img.setAttribute('class','qrcode_close_img');
    // create_img.onclick = function () {
    //   deletePoster(temp_num)
    // };
    // create_img.setAttribute('src',closeIcon);
    // create_div.setAttribute('class','qrcode_back_item');
    // create_div.id = 'qrcode_back_item' + temp_num;
    // create_canvas.id = 'canvas' + temp_num;
    // create_div.appendChild(create_canvas);
    // create_div.appendChild(create_img);
    // document.getElementById('parset-canvas')?.appendChild(create_div);
    // canvasNum = temp_num;

    // //记录当前canvas索引值
    // //console.log('create_index:',temp_index)
    // upLoadIndex.push(temp_num)
    // //console.log('new_create_index:',upLoadIndex)
    // return temp_num
  }

  const drawImage = async (url: string) => {
    //console.log("这里在执行");
    console.log(formData)
    let temp_index = await createCanvas();
    const myCanvas = new fabric.Canvas(`canvas${temp_index}`, {
      backgroundColor: "#eef0f3",
      width: JSON.parse(formData.poster)[0].width,
      height: JSON.parse(formData.poster)[0].height
      // width: 576,
      // height: 360
    });
    // const index = getCanvasItemIndex(uniqueKeys.backImage);
    // if (index !== -1) {
    //   myCanvas.remove(myCanvas.item(index));
    // }
    const img = new Image();
    img.src = url;
    //图片添加二维码
    initCanvas(myCanvas);
    img.onload = () => {
      // if(canvas) {
      //   canvas.width = img.width;
      //   canvas.height = img.height;
      // }
      myCanvas.setWidth(canvasConfig.width);
      const scaleImg =
        img.width > canvasConfig.width
          ? canvasConfig.width / img.width
          : img.width / canvasConfig.width;
      const scale_x = 0.39;
      const scale_y = 0.37;

      //const _height = img.height * scaleImg;

      // const index = getCanvasItemIndex(uniqueKeys.qrCode);
      // if (index !== -1) {
      //   // console.log(myCanvas.item(index));
      //   console.log(' _height - 70 - 50',  _height - 70 - 50)
      //   // qrCodeObg.set({top : 20, selectable: true, hasControls: true});
      //   myCanvas.renderAll();
      // }

      // myCanvas.setHeight(_height);

      const imgInstance = new fabric.Image(img, {
        left: 0, // 图片相对画布的左侧距离
        top: 0, // 图片相对画布的顶部距离
        angle: 0, // 图片旋转角度
        opacity: 1, // 图片透明度
        scaleX: scaleImg,
        scaleY: scaleImg,
        // scaleX: scale_x,
        // scaleY: scale_y,
        hasControls: false,
        selectable: false
      });

      imgInstance["uniqueKey"] = uniqueKeys.backImage;
      // myCanvas.add(imgInstance);  // item00 背景图
      myCanvas.setBackgroundImage(
        imgInstance,
        myCanvas.renderAll.bind(myCanvas),
        {
          scaleX: scaleImg,
          scaleY: scaleImg,
          // scaleX: scale_x,
          // scaleY: scale_y,
          crossOrigin: 'anonymous'
        }
      );
      myCanvas.renderAll();
      // myCanvas.moveTo(imgInstance, 0);
       //记录当前对象
       ownPoster.push(myCanvas);
       //myCanvas.moveTo(imgInstance, 0);
       message.success({content: '上传成功', key, duration: 2})
    };
  };

  // 获取 当前操作 内容在画布下的下标
  const getCanvasItemIndex = (uniqueKey: string) => {
    const items = myCanvas._objects;
    let index = -1;
    for (let i = 0; i < items.length; i++) {
      let flag: boolean = items[i].hasOwnProperty("uniqueKey");
      console.log("是否存在该属性" + flag);
      if (flag) {
        if (items[i].uniqueKey === uniqueKey) {
          index = i;
          break;
        }
      }
    }
    return index;
  };
  
  // 修改首单提成
  const firstOrderPercent = (val: string) => {
    setinputValue({...inputValue, first_order_percent: val})
  }
  // 修改订单提成
  const orderPercent = (val: string) => {
    setinputValue({...inputValue, order_percent: val});
  }
  const rulesChange = (val: string) => {
    setformDataValue({...formData, rules: val})
  }

  //删除海报
  const deletePoster = (index: any) => {
    const canvas = new fabric.Canvas(`canvas${index}`);
    canvas.remove(canvas.item(index));
    canvas.renderAll()

    //删除海报索引
    //记录当前canvas索引值
    //console.log('upLoadIndex:',upLoadIndex)
    let temp_index = upLoadIndex;
    let new_index =  temp_index.indexOf(index);
    //console.log('index:',index)
    //console.log('new_index:',new_index)
    upLoadIndex.splice(new_index,1);


    document.getElementById(`qrcode_back_item${index}`)?.remove();

    //删除对应海报引用对象
    //console.log('ownPoster:',ownPoster)
    ownPoster.splice(ownPoster.indexOf(index), 1);
    //console.log('ownPosterNew:',ownPoster)

  }
  
  // 提交修改
  const submiteForm = async() => {

    if(upLoadIndex.length == 0) {
      message.error('至少保存一张海报')
      return;
    }
    //console.log('formData', formData);
    //let qrCodeIndex = getCanvasItemIndex(uniqueKeys.qrCode);
    //console.log('qrCodeIndex', qrCodeIndex);
    //const canvasData = myCanvas.toJSON();
    const first = Number(inputValue.first_order_percent) / 100;
    const order_percent = Number(inputValue.order_percent) / 100;
    // const canvsdom:any = document.getElementById('canvas1');
    // canvasData.width = canvsdom.width;
    // canvasData.height = canvsdom.height;
    // console.log('canvasData', canvasData)

    //console.log('ownPoster:',ownPoster)
    //console.log('upLoadIndex:',upLoadIndex)

    //循环获取多张海报
    const new_canvans_data: any = [];
    ownPoster.map((item: any, index: any) => {
      let temp_item = item.toJSON();
      let canvsdom:any = document.getElementById('canvas' + upLoadIndex[index]);
      temp_item.width = canvsdom.width;
      temp_item.height = canvsdom.height;
      new_canvans_data.push(temp_item)
    })

    const data = {
      first_order_percent: first ? first : formData.first_order_percent,
      order_percent: order_percent ? order_percent : formData.order_percent,
      poster: JSON.stringify(new_canvans_data),
      rules: formData.rules,
    }
    const res:any = await postRequest(apiUrl.shareActiviteUpdate, {
      id: formData.id,
      update: data
    });
    if(res && res.data) {
       console.log('res', res);
       //message.success(res.data.message);
       history.goBack();
    }
  }

  return formData.xcx ? <Fragment>
    <Form>
      <Title level={4}>分销配置信息</Title>  
      <Form.Item>
        <Row type="flex">
          <Col>关联的小程序: </Col>
          <Col style={{marginLeft: 10}}>{formData.xcx}</Col>
        </Row>
      </Form.Item>
      <Form.Item>
        <Row type="flex" align="middle">
          <Col>奖励比例:</Col>
          <Col style={{marginLeft: 10, width: 500, display: 'flex'}}>
            好友下单首单奖金比例
            <Input 
              style={{width: 80, textAlign: 'center'}} 
              suffix="%"
              defaultValue={Number(formData.first_order_percent) > 0 ? Number(formData.first_order_percent) * 100 : ''}
              onChange={(e) => firstOrderPercent(e.target.value)} /> 
            <Col>，之后每单 </Col>
            <Input 
              style={{width: 80, textAlign: 'center'}} 
              suffix="%"
              defaultValue={Number(formData.order_percent) > 0 ? Number(formData.order_percent) * 100 : '' }
              onChange={(e) => orderPercent(e.target.value)} />
          </Col>
        </Row>
      </Form.Item>
      <Form.Item>
        <Row type="flex">
          <Col>邀请规则：</Col>
          <Col>
            <TextArea 
              rows={8} 
              style={{width: 500}} 
              value={formData.rules}
              onChange={(e) => rulesChange(e.target.value)} />
          </Col>
        </Row>
      </Form.Item>
      <Form.Item>
        <Row type="flex" style={{overflowX: "scroll"}}>
          <Col>
            海报图片
            <Tooltip placement="topLeft" title={"建议尺寸1080， 请勿上传超过2M的图片"}>
              <Icon type="question-circle" />
            </Tooltip>：
          </Col>
          <Col style={{marginRight: 30}}>
          <Upload
              showUploadList={false}
              action={QINIU_SERVER}
              accept="image/*"
              beforeUpload={handlebeforeUpload}
              onChange={e => handleChange(e)}
              data={{
                token,
                region: `https://up-z2.qiniu.com`,
                key: imageKey,
                "x:type": "poster"
              }}
            >
              <Button
                type="primary"
                style={{ marginLeft: 20 }}
              >
                上传海报
              </Button>
            </Upload>
          </Col>
          <Row style={{width: 500, height: 600}}>
            <div className="qrcode_back" id="parset-canvas">
              {/* <div className="qrcode_back_item" id="qrcode_back_item1">
                <canvas
                  id="canvas1"
                  ref={canvasRef}
                ></canvas>
                <img src={closeIcon} className="qrcode_close_img" onClick={() => { deletePoster(1) }} />
              </div> */}
            </div>
          </Row>
        </Row>
      </Form.Item>
    </Form>
    <Row type="flex" justify="center" style={{marginTop: 50, marginBottom:50}}>
      <Button type="default" onClick={() => { history.goBack() }}>取消</Button>
      <Button type="primary" style={{marginLeft: 50}} onClick={submiteForm}>保存</Button>
    </Row>
  </Fragment>
  : <Row></Row>
}

export default WeappDistriutionManage