import React from "react";
import {Button, Row, Col, Input, DatePicker, Table, Modal, Select, message,} from "antd";
import { getRequest } from '../../api/http';
import { apiUrl } from '../../api/api_url';
import "../goods/goods.scss";
import { RangePickerValue } from "antd/lib/date-picker/interface";
import { Link } from "react-router-dom";
import { getIsPermisson, messageCustom } from "../../utils/permisstionUtil";

const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

const key = 'BuyAndGive';

class BuyAndGive extends React.Component<any> {
    state = {
        name: '',
        visible: false,
        productCount: 0,
        productList: [],
        currentPage: 1,
        pageSize: 10,
        searchName: '',
        timeArray: [],
        status: ''
    }

    columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            className: 'list_center',
       },
        {
          title: '优惠名称',
          dataIndex: 'name',
          key: 'name',
          className: 'list_center',
        },
        {
            title: '有效期',
            dataIndex: 'time_type',
            key: 'time_type',
            className: 'list_center',
            render: (text:any,record:any,) => (
                <div>
                    {record.time_type === 1 ? record.start_time + ' ——— ' + record.end_time : '永久'}
                </div>
            ),
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            className: 'list_center',
            render: (text:any,record:any,) => (
                <div>
                    {record.status ? '启用中' : '停用中'}
                </div>
            ),
        },
        {
            title: '创建时间',
            dataIndex: 'created_at',
            key: 'created_at',
            className: 'list_center',
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            className: 'list_center',
            render: (text:any,record:any,) => (
                <div className="btn_wrap">
                    <div className="list_btn" onClick={() =>{
                        if(!getIsPermisson('1_12_3_2')){
                            messageCustom();
                            return;
                          }
                        this.props.history.push({pathname:'/sidebar/buyAndGiveEdit', state:{id: record.id}})
                    }}>编辑</div>
                    <div className="list_btn" onClick={() => this.noDiscountList(record)}>{record.status ? '停用' : '开启'}</div>
                </div>
            ),
        },
    ];

    componentDidMount(){
        this._initList();
    }

    _initList = async () => {

        const {pageSize, timeArray, currentPage, status, searchName,} = this.state;
        const params:any = {
            page: currentPage,
            pageSize: pageSize
        };
        if(searchName !== ''){
            params.name = searchName;
        }
        params.status = status;

        if(timeArray.length > 0 && timeArray[0] !== ''){
            params.create_begin_time = (Date.parse(timeArray[0]).toString()).substr(0, 10);
            params.create_end_time = (Date.parse(timeArray[1]).toString()).substr(0, 10);
        }

        const res:any = await getRequest(apiUrl.saleList,params);
        if(res.code === 1){
            this.setState({
                productList: res.data.list,
                productCount: res.data.count,
                currentPage: currentPage
            })
        }
    }

    searchAction  = async (name:string) => {
        this.setState({
            searchName: name,
            currentPage: 1
        },() => {
            this._initList()
        })
    }

    // 状态选择
    typeChange = async (status:any) => {
        this.setState({
            status,
            currentPage: 1
        },() => {
            this._initList()
        })
    }

    timeChange = async (time: RangePickerValue, timeString: [string, string]) => {
        this.setState({
            timeArray: timeString,
            currentPage: 1
        },() => {
            this._initList()
        })
    }

    pageChange = (page: number) => {
        this.setState({
            currentPage: page
        },() => {
            this._initList()
        })
    }

    // 设为开启或停用
    noDiscountList = async (record: any) => {
        if(!getIsPermisson('1_12_3_3')){
            messageCustom();
            return;
          }
        Modal.confirm({
            title: '提示',
            content: `此项是否设置为${record.status ? '停用' : '开启'}`,
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                let res:any = await getRequest(apiUrl.saleStatus + `/${record.id}`,{status: !record.status})
                if(res.code === 1){
                    message.success({ content: '设置成功!', key, duration: 2 });
                    this._initList();
                }
            },
            onCancel() {
            },
        });
    }

    render(){
        const { productList } = this.state;
        return <div className='l_content'>
            <Row>
                <Col span={24}>
                <Button type="primary" size="large" icon="plus" onClick={() =>{
                     if(!getIsPermisson('1_12_3_1')){
                        messageCustom();
                        return;
                      }
                    this.props.history.push('/sidebar/buyAndGiveEdit')
                }}>
                    添加买赠
                </Button>
                </Col>
            </Row>
            <Row type="flex" align="middle" justify="start" className="mt20 mb40" >
                <Col className="mr30">
                    <span>优惠名称：</span>
                    <Search placeholder="请输入优惠名称" onSearch={this.searchAction} style={{ width: 234 }}/>
                </Col>
                <Col className="mr30">
                    <span>状态：</span>
                    <Select style={{ width: 150 }} onChange={this.typeChange} placeholder="请选择优惠状态" allowClear>
                        <Option value='true'>开启中</Option>
                        <Option value='false'>停用中</Option>
                    </Select>
                </Col>
                <Col>
                    <span>创建时间：</span>
                    <RangePicker onChange={this.timeChange} placeholder={["开始时间","结束时间"]} format="YYYY-MM-DD"/>
                </Col>
            </Row>

            <div>
                <Table
                dataSource={productList}
                columns={this.columns}
                rowKey={"id"}
                size="middle"
                locale={{emptyText: '暂无数据'}}
                pagination={{
                    current: this.state.currentPage,
                    defaultCurrent: 1,
                    total: this.state.productCount,
                    pageSize: this.state.pageSize,
                    onChange: this.pageChange,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    showTotal: (total: number) => `共${total}条`,
                    onShowSizeChange: (current:number, size: number) => {this.setState({pageSize: size}, () => {this._initList()}); },
                }}/>
            </div>
        </div>;
    }
}
export default BuyAndGive