import React from 'react';
import { Row, Col } from 'antd';
import '../management.scss';

interface Meal {
    history: any,
}

class Meal extends React.Component<Meal> {

    state = {
        name: '营销工具',
        list: [
            {
                id: 0,
                title: '如何添加优惠券？',
                time: '2020-08-24 15:59:26',
                detail: '/helpCenter/helpMbaDiscount',
            },
            {
                id: 1,
                title: '如何添加代金券？',
                time: '2020-08-24 15:59:26',
                detail: '/helpCenter/helpMbaCoupon',
            },
            {
                id: 2,
                title: '如何添加买赠？',
                time: '2020-08-24 15:59:26',
                detail: '/helpCenter/helpMbaBuyFree',
            },
            {
                id: 3,
                title: '如何添加兑换菜品？',
                time: '2020-08-24 15:59:26',
                detail: '/helpCenter/helpMbaExchange',
            },
            {
                id: 4,
                title: '如何添加权益卡？',
                time: '2020-08-24 15:59:26',
                detail: '/helpCenter/helpMbaInterests',
            },
            {
                id: 5,
                title: '如何直接发券？',
                time: '2020-08-24 15:59:26',
                detail: '/helpCenter/HelpMbaSecurities',
            },
            {
                id: 6,
                title: '如何添加生日有礼？',
                time: '2020-08-24 15:59:26',
                detail: '/helpCenter/HelpMbaBirthday',
            },
            {
                id: 7,
                title: '如何添加进店有礼？',
                time: '2020-08-24 15:59:26',
                detail: '/helpCenter/HelpMbaWelcome',
            },
            {
                id: 8,
                title: '如何添加充值免单？',
                time: '2020-08-24 15:59:26',
                detail: '/helpCenter/HelpMbaChargeFree',
            },
            {
                id: 9,
                title: '如何添加助力有礼？',
                time: '2020-08-24 15:59:26',
                detail: '/helpCenter/HelpMbaCourtesy',
            },
        ]
    }

    onGoPage(path: string) {
        this.props.history.push(path)
    }

    render() {
        const { list, name } = this.state;
        return  <Row>
                    <Col className="name">{name}</Col>
                    {
                        list.map((item: any, index: any) => {
                            return (
                                <Row key={index + item} className="border" onClick={() => { this.onGoPage(item.detail) }}>
                                    <Col className="title">{item.title}</Col>
                                    <Col>{item.time}</Col>
                                </Row>
                            )
                        })
                    }
                </Row>
    }
}

export default Meal;