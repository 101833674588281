import React, { useEffect, useState, useRef } from 'react'
import ReactEcharts from "echarts-for-react";
import './lineChart.scss'

type Iprops = {
    lineData:any,
    lineTab:number
}

const UserDetailLineChart = (props:Iprops)=>{
    
    const { lineData, lineTab } = props

    const colorList = [ 'rgba(255,90,110,1)', 'rgba(35,207,75,1)', 'rgba(255, 183, 65, 1)', 'rgba(95, 90, 255, 1)', 'rgba(25, 227, 255, 1)', 'rgba(252, 65, 255, 1)', 'rgba(235, 229, 30, 1)', ]

    const bgList = [ 'rgba(255,90,110,0.2)', 'rgba(35,207,75,0.2)', 'rgba(255, 183, 65, 0.2)', 'rgba(95, 90, 255, 0.2)', 'rgba(25, 227, 255, 0.2)', 'rgba(252, 65, 255, 1)', 'rgba(235, 229, 30, 0.2)', ]

    const tabList = [ '月实收金额', '月订单' ]

    const [option, setOption] = useState<any>({
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985'
                }
            }
        },
        legend: {
            data: ['社群1'],
            bottom: '15px',
            itemHeight: 15,
            borderColor :'yellow',
            icon:'circle'
        },
        color : [ 'rgba(255,90,110,1)'],
        grid: {
            left: '3%',
            right: '4%',
            bottom: '60px',
            top: '8%',
            containLabel: true,
            show:true,
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: false,
                data: [0, 1, 2, 3, 4, 5, 6],
            },
        ],
        yAxis: [
            {
                type: 'value',
            }
        ],        
        series: [
            {
                name: '社群1',
                type: 'line',
                // stack: '总量',
                areaStyle: {type: 'default',color:'rgba(255,90,110,0.2)'},//内容颜色
                symbol: 'none', // 拐点类型
                smooth: true,
                data: [0,0,0,0,0,0,0,],
            },
        ],
    });

    useEffect(()=>{
        if(lineData){
            //初始化数据
            let optionTemp:any = JSON.parse(JSON.stringify(option))
            optionTemp.xAxis[0].data = lineData.xLine
            optionTemp.series = []
            optionTemp.color = []
            lineData.groups.map((item:any,index:number)=>{
                optionTemp.series.push({
                    name: item.name+tabList[lineTab],
                    type: 'line',
                    areaStyle: {type: 'default',color:bgList[index]},//内容颜色
                    symbol: 'none', // 拐点类型
                    smooth: true,
                    data: lineData.yLine[index].map((subItem:any)=>{return lineTab===0?subItem.actual_fee:subItem.orders}),
                })
                optionTemp.color.push(colorList[index])
            })
            optionTemp.legend.data = lineData.groups.map((item:any)=>{return item.name+tabList[lineTab]})
            setOption(optionTemp)
        }
    },[lineData,lineTab])

    return (
        <div className="user_detail_lineChart">
            <div style={{ height: '350px', width: '100%' }}>
                <ReactEcharts option={option} style={{ height: '100%', width: '100%' }} className='react_for_echarts' />
            </div> 
        </div>
    )
}

export default UserDetailLineChart



