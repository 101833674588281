import React, { useState, useEffect, useLayoutEffect } from "react";
import {Drawer,Button,Input,Form,Radio, message, Select, Spin, DatePicker} from 'antd'
import {messageTemplateSearch,messageUserSearch,messageTaskAdd,messageTaskEdit} from './components/request'
import useForm from 'rc-form-hooks'
import {taskFrom,messageTaskType,searchTemplateData,searchUserRequestData,searchTemplateRequestData} from './components/dataType'
import debounce from "lodash/debounce";
import "./message.scss"
import moment from "moment";
const { TextArea } = Input;
const { Option } = Select;
function MessageTaskAdd (props:taskFrom) {

    const [data,setData] = useState(props.data)
    const { getFieldDecorator, validateFields, resetFields, setFieldsValue, getFieldValue } = useForm<messageTaskType>();

    const [msgType,setMsgType] = useState(1)

    const [pushType,setPushType] = useState(1)

    useEffect(()=>{
        resetFields()
        if(data && data.msg_type){
            setMsgType(data.msg_type)
        }else{
            setMsgType(1)
        }
        if(data && data.push_type){
            setPushType(data.push_type)
        }else{
            setPushType(1)
        }

        if(data && data.fans_list){
            let list:any[] = [];
            data.fans_list.map((item: any) => {
                list.push({
                    key: item.id,
                    label: item.nickname
                })
            })

            console.log('1111', list);

            setUserValue(list)
        }


    },[data])

    useLayoutEffect(()=> {
        setData(props.data)
    })

    useLayoutEffect(()=> {
        // if(props.data && props.data.msg_type){
        //     setMsgType(props.data.msg_type!!)
        // }
        // if(props.data && props.data.template_id){
        //     setTemplateValue(props.data.template_id!!)
        // }
        // if(props.data && props.data.push_type){
        //     setPushType(props.data.push_type!!)
        // }
    },[])


    const [templateFetching,setTemplateFetching] = useState(false)
    const [templateDate,setTemplateData] = useState<searchTemplateData[]>([])
    const [templateValue,setTemplateValue] = useState()
    const templateHandleChange = (value:any) => {
        console.log(value);
        setTemplateValue(value)
        setTemplateData([])
        setTemplateFetching(false)
    };

    const templateSearch = (value:any) => {
        setTemplateData([])
        setTemplateFetching(true)
        let reg=/^\d{1,}$/
        let pattern=new RegExp(reg);
        let param:searchTemplateRequestData = {}
        if(pattern.test(value)){
            param['id'] = value
        }else{
            param['title'] = value
        }
        messageTemplateSearch(param).then((res) => {
            let data:Array<searchTemplateData> = [];
            if(res.code === 1){
                res.data.map((item:any) => {
                    data.push({value:item.id,text:item.title})
                })
            }
            setTemplateData(data)
            setTemplateFetching(false)
        })
    }


    const [userFetching,setUserFetching] = useState(false)
    const [userDate,setUserData] = useState<searchTemplateData[]>([])
    const [userValue,setUserValue] = useState<number[]>()
    const userHandleChange = (value:any) => {
        console.log(value);
        if(value.length > 0){
            let fans_ids: any = [];
            value.map((item: any) => {
                fans_ids.push(item.key)
            })
            setFieldsValue({fans_ids:fans_ids.join(',')})
        }

        setUserValue(value)
        setUserData([])
        setUserFetching(false)
    };

    const userSearch = (value:any) =>{
        setUserData([])
        setUserFetching(true)
        let reg=/^\d{1,}$/
        let pattern=new RegExp(reg);
        let param:searchUserRequestData = {}
        if(pattern.test(value)){
            param['fans_id'] = value
        }else{
            param['nickname'] = value
        }
        messageUserSearch(param).then((res) => {

            let data:Array<searchTemplateData> = [];
            if(res.code === 1){
                res.data.map((item:any) => {
                    data.push({value:item.id,text:item.nickname})
                })
            }else{
                data.push({value:'0',text:'全部用户'})
            }
            // data.push({value:'0',text:'全部用户'})
            setUserData(data)
            setUserFetching(false)
        }).catch((e)=>{
            let data:Array<searchTemplateData> = [];
            data.push({value:'0',text:'全部用户'})
            setUserData(data)
            setUserFetching(false)
        })
    }



    const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 4 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 20 },
        },
    };

    const handleSubmit = (e:any)=>{
        e.preventDefault();
        validateFields().then((data)=>{
            if(data.msg_type === 2){
                if(data.content !== undefined ){
                    delete data.content
                }
                if(data.title !== undefined ){
                    delete data.title
                }
                if(data.jump_url !== undefined ){
                    delete data.jump_url
                }
            }
            if(data.msg_type === 1){
                if(data.template_id !== undefined ){
                    delete data.template_id
                }

            }
            delete data.fans_ids_select
            if(data.fans_ids_select === undefined){
                delete data.fans_ids_select
            }else{
                // let fans_ids:any = []
                // data.fans_ids.map((item:any) => {
                //     fans_ids.push(item.key)
                // })
                // data.fans_ids=fans_ids.join(',')
            }
            delete data.template_id_select
            if(data.template_id_select === undefined){

            }else{
                // data.template_id=data.template_id.key
            }
            // if(data.template_id){
            //     data.template_id=data.template_id
            // }
            // data.template_id
            if(data.jump_url === undefined){
                delete data.jump_url
            }
            if(data.push_at){
                data.push_at = moment(data.push_at).format('YYYY-MM-DD hh:mm:ss')
            }
            if(props.data !== undefined && props.data.id != undefined){
                if(data.push_at){
                    data.notice_at = data.push_at
                    delete data.push_at
                }
                messageTaskEdit(data).then((res) => {
                    if(res.code === 1){
                        message.info('编辑成功')
                        props.setVisible(false,true);
                        resetFields();
                    }else{
                        console.error(res.message)
                    }
                })
            }else{
                messageTaskAdd(data).then((res) => {
                    if(res.code === 1){
                        message.info('添加成功')
                        props.setVisible(false,true);
                        resetFields();
                    }else{
                        console.error(res.message)
                    }
                })
            }
        })
    }


    return <div>
        <Drawer
            title={props.data !== undefined && props.data.id != undefined ? '编辑任务' : '添加任务'}
            placement="right"
            closable={false}
            onClose={()=>{
                props.setVisible(false,false);
                resetFields();
            }}
            visible={props.visible}
            width={900}
        >
            <Form {...formItemLayout} className="login-form" onSubmit={handleSubmit}>

                <Form.Item label="消息类型">
                    {getFieldDecorator('msg_type', {
                        initialValue: data && data.msg_type ? data.msg_type : 1,
                        rules: [{ required: true, message: '设置通知类型!' }]
                    })(<Radio.Group onChange={(e) => {
                        setMsgType(e.target.value)
                        setFieldsValue({msg_type:e.target.value})
                    }} >
                        <Radio value={1}>自定义消息</Radio>
                        <Radio value={2}>模板消息</Radio>
                        </Radio.Group>)}
                </Form.Item>

                {
                    msgType === 1 ?
                    <div>
                        <Form.Item label="消息标题">
                            {
                                getFieldDecorator('title', {
                                    initialValue: data !== undefined ? data.content : '',
                                    rules: [{ required: true, message: '填写消息标题!' }]
                                })(<Input placeholder="填写消息标题"  />)
                            }
                        </Form.Item>

                        <Form.Item label="推送内容">
                            {
                                getFieldDecorator('content', {
                                    initialValue: data !== undefined ? data.content : undefined,
                                    rules: [{ required: true, message: '填写推送内容!' }]
                                })(<TextArea rows={4} placeholder="填写推送内容" />)
                            }
                        </Form.Item>

                        <Form.Item label="跳转url">
                            {
                                getFieldDecorator('jump_url', {
                                    initialValue: data !== undefined ? data.jump_url : '',
                                    rules: [{ required: false, message: '填写跳转url!' }]
                                })(<Input placeholder="填写跳转url"  />)
                            }
                        </Form.Item>
                    </div>
                    : msgType === 2 ?
                    <Form.Item label="模板">
                        {getFieldDecorator('template_id_select', {
                            initialValue: undefined,
                            rules: [{ required: false, message: '选择模板!' }]
                        })(<Select
                            showSearch
                            labelInValue
                            value={templateValue}
                            placeholder="输入模板标题或id"
                            notFoundContent={templateFetching ? <Spin size="small" /> : null}
                            filterOption={false}
                            onSearch={debounce(templateSearch,500)}
                            onChange={templateHandleChange}
                            style={{ width: '100%' }}
                        >
                            {templateDate.map(d => (
                            <Option key={d.value}>{d.text}</Option>
                            ))}

                        </Select>)}
                    </Form.Item>
                    : <div></div>
                }

                <div className='none'>
                    <Form.Item label="模板id">
                        {
                            getFieldDecorator('template_id', {
                                initialValue: data && data.template_id ? data.template_id : '',
                                rules: [{ required: false, message: '填写模板id!' }]
                            })(<Input placeholder="填写模板id"  />)
                        }
                    </Form.Item>
                </div>



                <Form.Item label="通知类型">
                    {getFieldDecorator('type', {
                        initialValue: data && data.type ? data.type : 1,
                        rules: [{ required: true, message: '设置通知类型!' }]
                    })(<Radio.Group >
                        <Radio value={1}>系统通知</Radio>
                        <Radio value={2}>优惠通知</Radio>
                        </Radio.Group>)}
                </Form.Item>

                <Form.Item label="推送类型">
                    {getFieldDecorator('push_type', {
                        initialValue: data && data.push_type ? data.push_type : 1,
                        rules: [{ required: true, message: '推送类型!' }]
                    })(<Radio.Group onChange={(e) => {
                        setPushType(e.target.value)
                        setFieldsValue({push_type:e.target.value})

                    }} >
                        <Radio value={1}>新人注册后推送</Radio>
                        <Radio value={2}>立即推送</Radio>
                        <Radio value={3}>指定时间点推送</Radio>
                        </Radio.Group>)}
                </Form.Item>

                {
                    pushType === 3 ?
                    <Form.Item label="推送时间">
                    {
                        getFieldDecorator('push_at', {
                            initialValue: data !== undefined ? moment(data.notice_at) : '',
                            rules: [{ required: true, message: '选择推送时间' }]
                        })(<DatePicker showTime showToday placeholder="选择推送时间" format="YYYY-MM-DD HH:mm:ss" onChange={(e:any)=>{
                            // setFieldsValue({push_at:`${e.format('YYYY-MM-DD, h:mm:ss')}`})
                        }} onOk={(e:any)=>{console.log(e)}} />)
                    }
                    </Form.Item>
                    : <div></div>
                }

                <Form.Item label="用户id">
                    {getFieldDecorator('fans_ids_select', {
                        initialValue: userValue,
                        rules: [{ required: false, message: '选择用户!' }]
                    })(<Select
                        showSearch
                        labelInValue
                        value={userValue}
                        mode="multiple"
                        placeholder="输入昵称或id"
                        notFoundContent={userFetching ? <Spin size="small" /> : null}
                        filterOption={false}
                        onSearch={debounce(userSearch,500)}
                        onChange={userHandleChange}
                        // onSelect={userSelect}
                        style={{ width: '100%' }}
                      >
                          {
                              userDate ?
                              userDate.map(d => (
                                <Option key={d.value}>{d.text}</Option>
                              ))
                              :
                              <Option key={0}>全部用户</Option>
                          }
                      </Select>)}
                </Form.Item>

                <div className='none'>
                    <Form.Item label="用户id">
                        {
                            getFieldDecorator('fans_ids', {
                                initialValue: data !== undefined ? data.fans_ids : '',
                                rules: [{ required: true, message: '填写跳转url!' }]
                            })(<Input placeholder="填写跳转url"  />)
                        }
                    </Form.Item>
                </div>

                <div className='none'>
                    <Form.Item label="店铺id">
                        {getFieldDecorator('shop_id', {
                            initialValue: data && data.shop_id ? data.shop_id : 1,
                            rules: [{ required: true, message: '填写店铺id!' }]
                        })(<Input placeholder="填写店铺id"  />)}
                    </Form.Item>

                </div>
                {
                    props.data !== undefined && props.data.id != undefined ?
                    <div className='none'>
                        <Form.Item label="id">
                            {
                                getFieldDecorator('id', {
                                    initialValue: data !== undefined ? data.id : '',
                                    rules: [{ required: true, message: 'id' }]
                                })(<Input placeholder="id"  />)
                            }
                        </Form.Item>
                    </div>
                    :
                    <div></div>
                }

                <Button type="primary" htmlType="submit"  className="login-form-button" >
                    提交
                </Button>

            </Form>
        </Drawer>
    </div>
}


export default MessageTaskAdd
