import React from "react";
import { Table, Modal, message, Row, Col, Button, Icon, Tooltip, Typography,} from "antd";
import { postRequest, getRequest } from '../../api/http';
import { apiUrl } from '../../api/api_url';
import { Link } from "react-router-dom";
const { Text } = Typography;

const key = 'updatable';

class WelfareList extends React.Component {
    state = {
        welfareList: [],
        current: 1,
        pageSize: 10,
        total_count: 0
    }

    tips:string[] = [
        '被邀请的新用户注册后可获得新人福利优惠券',
        '用户在领券中心内填写生日后，可在生日当天获得生日优惠券',
        '领券中心内的日常领券福利，用户需分享给其他用户进行助力，助力成功可获得该优惠券'
    ];

    columns = [
        {
            title: '福利名称',
            dataIndex: 'name',
            key: 'name',
            className: 'list_center',
            render: (text:any,record:any,) => (
                <div>
                    <Text style={{marginRight: '10px'}}>{record.name}</Text>
                    <Tooltip placement="right" title={record.type === 'new_people' ? this.tips[0] : record.type === 'birthday' ? this.tips[1] : this.tips[2]}>
                        <Icon type="exclamation-circle" theme="filled" />
                    </Tooltip>
                </div>
            ),
        },
        {
            title: '关联优惠券',
            dataIndex: 'coupon',
            key: 'coupon',
            className: 'list_center',
            render: (text:any,record:any,) => (
                <div>{
                    record.coupon.map((item: any, index: number) => {
                        return <Text key={index}>{item.name}{index === record.coupon.length - 1 ? '' : '/'}</Text>
                    })
                }</div>
            ),
        },
        {
            title: '福利状态',
            dataIndex: 'status',
            key: 'status',
            className: 'list_center',
            render: (text:any,record:any,) => (
                <div>{record.status === 1 ? '开启中' : '暂停中'}</div>
            ),
        },
        {
            title: '福利类型',
            dataIndex: 'type',
            key: 'type',
            className: 'list_center',
            render: (text:any,record:any,) => (
                <div>{record.type === 'normal' ? '常规福利'
                        : record.type === 'birthday' ? '生日福利'
                            : record.type === 'multi_coupon' ? '批量送优惠券' :
                                record.type === 'equity_card' ? '权益卡' : '新人福利'}</div>
            ),
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            className: 'list_center',
            render: (text:any,record:any,) => (
                <div className="btn_wrap">
                    {
                        record.status === 1
                        ? <div className="list_btn" onClick={() => this.pauseList(record)}>暂停该福利</div>
                        : <div className="list_btn" onClick={() => this.startList(record)}>开启该福利</div>
                    }
                    <Link className="list_btn" to={{pathname:'/sidebar/welfareEdit', state:{id: record.id}}}>编辑该福利</Link>
                    {
                        record.type === 'normal' || record.type === 'multi_coupon'
                        ? <div className="list_btn" onClick={() => this.deleteList(record)}>删除该福利</div>
                        : null
                    }
                    {
                        record.type === 'multi_coupon' && record.welfare_url !== ''
                        ? <a href={record.welfare_url} download={record.welfare_url}>下载二维码</a>
                        : null
                    }
                </div>
            ),
        },
    ];

    componentDidMount(){
        this.onInitList();
    }

    // 初始化列表
    onInitList = async () => {
        let { pageSize } = this.state;
        const params:any = {
            current: 1,
            pageSize
        };
        const res:any = await getRequest(apiUrl.welfareList,params);
        if(res.code === 1){
            this.setState({
                welfareList: res.data.list,
                total_count: res.data.total_count,
                current: 1
            })
        }
    }

    pageChange = async (page:number) => {
        let { pageSize } = this.state;
        const params:any = {
            current: page,
            pageSize
        };
        const res:any = await getRequest(apiUrl.welfareList,params);
        if(res.code === 1){
            this.setState({
                welfareList: res.data.list,
                total_count: res.data.total_count,
                current: page
            })
        }
    }

    deleteList = async (record: any) => {
        Modal.confirm({
            title: '删除该福利',
            content: `是否确认删除该福利？`,
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                const params:any = {
                    id: record.id
                };
                const res:any = await postRequest(apiUrl.welfareDelete,params);
                if(res.code === 1){
                    message.success({ content: '删除成功!', key, duration: 2 });
                    this.onInitList();
                }
            },
            onCancel() {
            },
        });
    }

    // 暂停福利
    pauseList = (record:any) => {
        Modal.confirm({
            title: '暂停该福利',
            content: `设为暂停后，用户将无法领取该福利优惠券，是否确认暂停该福利？`,
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                const params = {
                    id: record.id
                }
                const res:any = await postRequest(apiUrl.welfareStart ,params);
                if(res.code === 1){
                    message.success({ content: '暂停成功!', key, duration: 2 });
                }
                this.onInitList();
            },
            onCancel() {
            },
        });
    }

    // 开启福利
    startList = (record:any) => {
        Modal.confirm({
            title: '开启该福利',
            content: `设为开启后，用户可领取该福利优惠券，是否确认开启该福利？`,
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                const params = {
                    id: record.id
                }
                const res:any = await postRequest(apiUrl.welfareStart,params);
                if(res.code === 1){
                    message.success({ content: '开启成功!', key, duration: 2 });
                }
                this.onInitList();
            },
            onCancel() {
            },
        });
    }

    render(){
        let { welfareList, total_count, pageSize, current} = this.state;
        return <div className='l_content'>
             <Row className="mb30">
                <Col span={24}>
                    <Link to={'/sidebar/welfareEdit'}>
                        <Button type="primary" size="large" icon="plus">
                            添加
                        </Button>
                    </Link>
                </Col>
            </Row>

            <div>
                <Table dataSource={welfareList}  size="middle" columns={this.columns} rowKey={'id'} locale={{emptyText: '暂无数据'}} pagination={{
                    total: total_count,
                    hideOnSinglePage: true,
                    pageSize,
                    onChange: this.pageChange,
                    current,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    showTotal: (total: number) => `共${total}条`,
                    onShowSizeChange: (current:number, size: number) => {this.setState({pageSize: size}, () => {this.onInitList();})}

                }} />
            </div>
        </div>;
    }
}
export default WelfareList