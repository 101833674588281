import React from "react";
import "./taskSetting.scss"
import {Col, Input, Row,DatePicker} from "antd";
const { RangePicker } = DatePicker;
interface IProps {
    callbackParent(type:number,param:any):void,
    param ?: any,
}

interface paramType {
    name?:string,
    activityTime ?: [string,string],
    taskWriteOffTime?: [string,string],
    introduction: string
}
const { TextArea } = Input;
class TaskSetting extends React.Component<IProps>{

    // 表单参数

    state = {
        param: {} as paramType,
    };

    componentDidMount(): void {
        this.initPageParams();
    }

    // 初始化页面参数
    initPageParams =() =>{
        let that = this;
        let paramTem = this.props.param;
        if(Object.keys(paramTem).length !== 0){
            that.setState({
                param: paramTem
            })
        }
    };

    // 监听活动名称变化
    handleChangeTaskName =(e:any)=>{
        let paramTem = this.state.param;
        paramTem.name = e.target.value.trim();
        this.setState({param: paramTem});
        this.submitParentParams();
    };

    // 监听活动时间 变化
    handleChangeActivityDate =(time:any,timeString:[string,string])=>{
        let paramTem = this.state.param;
        paramTem.activityTime = timeString;
        this.setState({param: paramTem});
        this.submitParentParams();
    };

    // 监听到店核销时间 变化
    handleWriteOffChangeDate =(time:any,timeString:[string,string])=>{
        let paramTem = this.state.param;
        paramTem.taskWriteOffTime = timeString;
        this.setState({param: paramTem});
        this.submitParentParams();
    };

    // 监听任务内容
    handleChangeTaskDesc = (value:string) =>{
        let paramTem = this.state.param;
        paramTem.introduction = value;
        this.setState({param: paramTem});
        this.submitParentParams();

    };
    // 提交参数给 父级组件
    submitParentParams =()=>{
        this.props.callbackParent(1,this.state.param);
    };
    render(){
        let {param} = this.state;
        return (
            <div className="task_setting_view l_content">
                <div className="task_title">
                    活动信息
                </div>
                <div className="setting_inputs">
                    <Row>
                        <Col span={12}>
                            <Row>
                                <Col span={4} className="small_title">
                                    活动名称：
                                </Col>
                                <Col span={20}>
                                    <Input placeholder="请输入活动名称" value={param.name} size="default" onChange={this.handleChangeTaskName} />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={4} className="small_title">
                                    活动时间：
                                </Col>
                                <Col span={20}>
                                    <RangePicker
                                        showTime={true}
                                        style={{width: '100%'}}
                                        size='default'
                                        placeholder={param.activityTime !== null ? param.activityTime:["开始时间","结束时间"]}
                                        onChange={this.handleChangeActivityDate}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={4} className="small_title">
                                    到店核销时间：
                                </Col>
                                <Col span={20}>
                                    <RangePicker
                                        showTime={true}
                                        style={{width: '100%'}}
                                        size='default'
                                        placeholder={param.taskWriteOffTime !== null ? param.taskWriteOffTime:["开始时间","结束时间"]}
                                        onChange={this.handleWriteOffChangeDate}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={4} className="small_title">
                                    任务描述：
                                </Col>
                                <Col span={20}>
                                    <TextArea placeholder="任务内容" value={param.introduction} onChange={(e) => this.handleChangeTaskDesc(e.target.value)} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

export default TaskSetting;