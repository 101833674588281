import React, { useEffect, useState } from 'react'
import { message, Modal, Input, Row, Table, Collapse, Icon, Col } from 'antd'
import { useHistory,  } from 'react-router-dom'
import { getRequest, postRequest } from '../../../../api/http'
import { apiUrl } from '../../../../api/api_url'

import './index.scss'

const TakeOutModal = (props:any)=>{

    const { visible, takeOut, closeModal, info } = props

    const history = useHistory()

    const [ number, setNumber ] = useState(1)

    useEffect(()=>{
        setNumber(info.surplus_num)
    },[visible])

    const handleOk = async() => {
        if(number<0){
            message.error('已超出最大取出数量')
            return false
        }
        const params = {
            value: JSON.stringify([{
                id:info.id,
                num: number,
            }])
        }
        const res:any = await postRequest(apiUrl.pullStorageDishs,params)
        if(res.code){
            message.success('取出成功')
            takeOut()
            closeModal()
        }else{
            message.error(res.message||'取出失败')
        }
    }

    const handleCancel = () => {
        closeModal()
    }

    const reduceNum = () => {
        let numberTemp = number
        if(numberTemp <= 1){
            return
        }
        numberTemp--
        setNumber(numberTemp)
    }

    const addNum = () =>{
        let numberTemp = number
        if(numberTemp>=info.surplus_num){
            return
        }
        numberTemp++;
        setNumber(numberTemp)
    }

    const changenumber = (value:any) => {
        var rule = /^[0-9]*[.]?[0-9]*$/
        console.log(rule.test(value))
        if(!rule.test(value)||value>info.surplus_num) return false
        setNumber(parseFloat(value))
    }

    return (
        <Modal
          title="取出寄存商品"
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          className="deposit_take_out_modal"
          width={560}
        >
            <Row className="mb30" type="flex" align="middle">
                <div className="modal_left">商品名称：</div>
                <div>{info.dishe_name}</div>
            </Row>
            <Row className="mb30" type="flex" align="middle">
                <div className="modal_left">剩余数量：</div>
                <div>{info.surplus_num}{info.definition}</div>
            </Row>
            <Row className="mb30" type="flex" align="middle">
                <div className="modal_left">到期时间：</div>
                <div>{info.expired_at}</div>                
            </Row>
            <Row type="flex" align="middle">
                <div className="modal_left">取出数量： </div>
                <Row type="flex" align="middle">
                    <Icon type="minus-circle" style={{color: '#ff3f3f', fontSize: 25}}  onClick={() => reduceNum()} />
                    <Input value={number} style={{width:50, border:0,textAlign:'center'}} onChange={(e)=>{changenumber(e.currentTarget.value,)}}/>
                    <Icon type="plus-circle" theme="filled" style={{color: '#ff3f3f', fontSize: 25}} onClick={() => addNum()} />
                </Row>                
            </Row>
        </Modal>
    )
}

export default TakeOutModal
