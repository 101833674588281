import {Row, Col, DatePicker, message, Avatar, Tooltip, Button, Table, Select, Icon} from 'antd';
import React, { Component } from 'react';
import ReactEcharts from "echarts-for-react";
import { apiUrl } from '../../../api/api_url';
import { getRequest, postRequest } from '../../../api/http';
import { getIsPermisson, messageCustom } from '../../../utils/permisstionUtil';

import Search from 'antd/lib/input/Search';

import styles from './recharge_record.module.scss';

const { Option } = Select;

interface IPorps {
    history?: any
    start_time?: any
    end_time?: any
    onSearchShopNmae?: any
    own_shop_name_id?: any
}

interface IState {
    option_new: any
    memberList: Array<any>
    paramsObj: any
    total: number
    isLoading: boolean
    pageSizeOptions: Array<string>
    count: number
    haviorData: Array<any>
    data_id_list: Array<any>
    start_time: any
    end_time: any
    tel_number: any
	search_user_name: any
    recharge_number: any
    shop_info_list: any
}

class RechargeRecord extends Component<IPorps, any> {
    constructor(props: IPorps) {
        super(props)
        this.state = {
            option_new: {
                //height: 500,
                color: ['#FF4141'],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                        type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    top: '60px',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        data: ['普通用户', '黄金用户', '黄金用户', '钻石用户'],
                        axisTick: true,
                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: '#4A4A4A',
                                fontSize: '20'
                            }
                        },
                        axisLine:{
                            lineStyle:{
                                color:'#aaabb1',
                                  width:1,   //这里是坐标轴的宽度,可以去掉
                            }
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: '#A3A3A3',
                                fontSize:'16'
                            }
                        },
                        axisLine:{
                            lineStyle:{
                                color:'#aaabb1',
                                  width:1,   //这里是坐标轴的宽度,可以去掉
                            }
                        }
                    }
                ],
                series: [
                    {
                        name: '普通用户',
                        type: 'bar',
                        barWidth: '60px',
                        data: []
                    }
                ]
            },
            memberList: [],
            total: 0,
            paramsObj: {
                page: 1,
                pageSize: 5,
            },
            isLoading: false,
            pageSizeOptions: [
                '5','10','20','40'
            ],
            count: 0,
            haviorData: [],
            data_id_list: [],
            start_time: '',
            end_time: '',
            tel_number: '',//手机号码
            search_user_name: '',//用户昵称
            recharge_number: '',//充值单号
            shop_info_list: [
                {
                    value: 'wechat',
                    name: '微信'
                },
                {
                    value: 'alipay',
                    name: '支付宝'
                },
                {
                    value: 'money',
                    name: '人民币'
                },
                {
                    value: 'bank',
                    name: '银行卡'
                }
            ],
            props_shop_id: 0,
            charge_type: undefined,
            pay_type: undefined
        }
    }

    componentDidMount() {
        let { start_time, end_time } = this.props;

        this.setState({
            start_time,
            end_time
        }, () => {
            this.requestGetMemberList({start_time, end_time})
        })
    }

    componentWillReceiveProps(nextProps: any) {
        this.setState({
            start_time: nextProps.start_time,
            props_shop_id: nextProps.own_shop_name_id,
            end_time: nextProps.end_time
        }, () => {
            this.requestGetMemberList()
        })
    }

    _initDate = async () => {
        let res: any = await getRequest(apiUrl.userRunLevel);
        if (res.code == 1) {
            let option_new: any = {
                color: ['#FF4141'],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                        type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                    },
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    top: '60px',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        data: res.data.xLine,
                        axisTick: true
                    }
                ],
                yAxis: [
                    {
                        type: 'value'
                    }
                ],
                series: [
                    {
                        name: '人数',
                        type: 'bar',
                        barWidth: '60px',
                        data: res.data.yLine,
                    }
                ]
            }
            this.setState({
                option_new,
                haviorData: res.data.xLine,
                data_id_list: res.data.xId
            })

        } else {
            message.error(res.message)
        }
    }

    requestGetMemberList = async (data = {}) => {
        let { start_time, end_time, props_shop_id, pay_type, charge_type } = this.state;
        let param: any = {
            ...data,
            type: 'charge',
            start_time,
            end_time,
        }
        let { search_user_name, tel_number, recharge_number } = this.state;
        if(search_user_name !== '') {
            if(param.nickname) {
                param.nickname = search_user_name
            }
        }
        if(tel_number !== '') {
            if(param.phone_number) {
                param.phone_number = tel_number
            }
        }
        if(recharge_number !== '') {
            if(param.order_number) {
                param.order_number = recharge_number
            }
        }
        if(!param.search_shop_id) {
            param.search_shop_id = props_shop_id
        }
        if(pay_type){
            param.pay_type = pay_type
        }
        if(charge_type){
            param.charge_type = charge_type
        }
        if (param.sortType) param.sortType = param.sortType == 'ascend' ? 'asc' : 'desc'
        Object.keys(param).map(el => {
            if (!param[el]) delete param[el]
        })
        if(param.pageSize) {
            this.setState({
                paramsObj: param,
                isLoading: true
            })
        }
        let group_arr = []
        if (param.group) {
            group_arr.push(param.group)
            delete param.group
        }
        if (param.recommend_group) {
            group_arr.push(param.recommend_group)
            delete param.recommend_group
        }
        if (group_arr.length > 0) param.group = group_arr.join(',')
        param.page_size = param.pageSize;
        param.current = param.page;
        const res: any = await getRequest(apiUrl.memberOfBill, param);
        if (res.code === 1) {
            this.setState({
                memberList: res.data.list,
                count: res.data.count,
                isLoading: false
            })
        }
    }

    createTable = () => {
        const columns: any = [
            {
                title: '订单编号',
                dataIndex: 'order_num',
                key: 'order_num',
                className: 'list_center',
            },
            {
                title: '用户昵称',
                dataIndex: 'nickname',
                key: 'nickname',
                className: 'list_center',
                render: (text: string, record: any, index: number) =>
                    <Row className="" justify="start" align="middle">
                        <div>{record.nickname}</div>
                    </Row>
            },
            {
                title: '手机号码',
                dataIndex: 'phone_number',
                className: 'list_center',
                key: 'phone_number'
            },
            {
                title: '充值金额',
                dataIndex: 'charge_fee',
                className: 'list_center',
                key: 'charge_fee'
            },
            {
                title: '赠送金额',
                dataIndex: 'present_fee',
                key: 'present_fee',
                className: 'list_center',
            },
            {
                title: '充值来源',
                dataIndex: 'charge_from',
                key: 'charge_from',
                className: 'list_center',
            },
            {
                title: '充值时间',
                dataIndex: 'created_at',
                key: 'created_at',
                className: 'list_center',
            },
            {
                title: '支付方式',
                dataIndex: 'pay_type',
                key: 'pay_type',
                className: 'list_center',
            },
            {
                //title: '可用余额',
                title: () => {
                    return <div>剩余可用余额<Tooltip title={`用户本次充值到账后,账户剩余可用余额`}>
                        <div className="finance_tip">
                            <Icon type="question-circle" theme="filled" />
                        </div>
                    </Tooltip></div>
                },
                dataIndex: 'can_use_amount',
                key: 'can_use_amount',
                className: 'list_center',
            },
        ];

        return <Table
            dataSource={this.state.memberList}
            columns={columns}
            rowKey={"id"}
            loading={this.state.isLoading}
            size="middle"
            onChange={this.handleTableChange}
            pagination={{
                total: this.state.count,
                current: this.state.paramsObj.page,
                pageSize: this.state.paramsObj.pageSize,
                showQuickJumper: true,
                showSizeChanger: true,
                pageSizeOptions: this.state.pageSizeOptions,
                showTotal: (total: number) => `共${total}条`,
            }}
        />;
    }

    handleTableChange = (pagination:any, filters:any, sorter:any) => {
        let paramsObj: any = this.state.paramsObj;
        this.requestGetMemberList({
            ...paramsObj,
            page:pagination.current,
            pageSize:pagination.pageSize,
            sortField:sorter.field,
            sortType:sorter.order
        })
    };

    onByModelClick = (event: any) => {
        // console.log('onByModelClick: e=', event);
        let ip = event.name; //获取当前点击的列名称
        let data_id_list = this.state.data_id_list; //所有id
        let haviorData: any = this.state.haviorData;  //所有的列名
        let res_index = haviorData.indexOf(ip); //根据名称去找对应的idx
        let level_id = data_id_list[res_index]; //根据获取的idx去找id

        let paramsObj = this.state.paramsObj;
        this.setState({
            paramsObj: Object.assign(paramsObj,{level: level_id})
        }, () => {
            this.requestGetMemberList({
                level: level_id
            })
        })
    }

    onClickByModelEvents = {
        'click': this.onByModelClick
    }

    inputValuechange = (e: any) => {
		let name = e.target.name;
		let val = e.target.value;
		this.setState({
			[name]: val
		})
    }

    payTypeChange = (e: any) => {
        this.setState({
            pay_type: e
        }, () => {
            this.requestGetMemberList({
                pay_type:  e
            })
        })
    }

    chargeTypeChange = (e: any) => {
        this.setState({
            charge_type: e
        }, () => {
            this.requestGetMemberList({
                charge_type:  e
            })
        })
    }

    render() {

        let { shop_info_list, search_user_name  } = this.state;

        return (
            <Row className={styles.rechargeRecord}>
                <Col className={styles.rechargeRecord_table}>
                    <div className={styles.rechargeRecord_query}>
                        <div className={styles.rechargeRecord_query_list}>
                            <div className={styles.rechargeRecord_query_list_title}>用户昵称：</div>
                            <Search
                                placeholder="请输入用户昵称/手机号"
                                allowClear
                                name='search_user_name'
                                value={search_user_name}
                                onChange={this.inputValuechange}
                                onSearch={(value) => {this.requestGetMemberList({nickname: value})}}
                                style={{ width: 200 }}
                            />
                        </div>
                        <div className={styles.rechargeRecord_query_list}>
                            <div className={styles.rechargeRecord_query_list_title}>充值来源：</div>
                            <Select placeholder="请选择充值来源" value={this.state.charge_type} style={{ width: 150 }} onChange={this.chargeTypeChange} allowClear>
                                <Option value={1}>充值免单/折扣</Option>
                                <Option value={2}>直接充值</Option>
                                <Option value={3}>收银台充值</Option>
                            </Select>
                        </div>
                        <div className={styles.rechargeRecord_query_list}>
                            <div className={styles.rechargeRecord_query_list_title}>支付方式：</div>
                            <Select placeholder="请选择支付方式" style={{ width: 150 }} value={this.state.pay_type} onChange={this.payTypeChange} allowClear>
                                {
                                    shop_info_list && shop_info_list.length > 0 && shop_info_list.map((item: any) => {
                                        return (
                                            <Option key={item.value} value={item.value}>{item.name}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </div>
                    </div>
                    {this.createTable()}
                </Col>
            </Row>
        )
    }

}

export default RechargeRecord
