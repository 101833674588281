import React from 'react';

import { useEffect,useState } from "react";
import { Radio } from 'antd';

import './styleSetting.scss'

const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
    fontSize: '16px',
    margin: '10px 0'
  };

const radios = [
    {name: '宽度*1  高度*1',value: 1},
    {name: '宽度*1  高度*2',value: 2},
    {name: '宽度*2  高度*1',value: 3},
    {name: '宽度*2  高度*2',value: 4}
]

const StyleSetting =(props) =>{
    const [item,setItem] = useState(props.params)

    useEffect(() =>{
        setItem(props.params)
    },[props.params]);

    // 更改字体样式
    const onChange =(e) =>{
        setItem({...item,size: e.target.value})
        props.callBack('FONT',{...item,size: e.target.value})
    } 
    // 空行控制 ADD DEL
    const onHandleEmpty =(type) =>{
        let emptyLine = 0;
        if(type == 'ADD'){
            // 添加空行
            emptyLine = item.EmptyLine + 1;

        }else{
            // 删除空行
            emptyLine = item.EmptyLine - 1;
            emptyLine = emptyLine > 0 ? emptyLine: 0;
        }
        setItem({...item,EmptyLine: emptyLine})
        props.callBack('EMPTY',{...item,EmptyLine: emptyLine})
    }

     // 分割线控制
     const onHandleDivid =() =>{
        setItem({...item,divingLine: !item.divingLine})
        props.callBack('DIVID',{...item,divingLine: !item.divingLine})
        
    }

    return item ? <div className="style_setting">
        <div className={`style_body ${item.key == 'CUSTOM_TEXT' || item.key == 'PAY_IMAGE' ? 'disable_style':''} `}>
            <div className="title_style">样式编辑</div>
            <Radio.Group onChange={(e) => onChange(e)} value={item.size} disabled={item.key == 'CUSTOM_TEXT' || item.key == 'PAY_IMAGE' }>
                {radios.map((item,index) => <Radio style={radioStyle} key={index} value={item.value}>{item.name}</Radio>)}
            </Radio.Group>
        </div>
        {
            item.key == 'ATTRIBUTE' ? <></>:
            <div className="style_body">
                <div className="button_style" onClick={() => onHandleEmpty('ADD')}>上方插入空行</div>
                <div className="button_style" onClick={() => onHandleEmpty('DEL')}>移除上方空行</div>
                <div className="button_style" onClick={() => onHandleDivid()}>{item.divingLine ? '移除下方分割线':'插入下方分割线'}</div>
            </div>
        }
    </div>
    : <div className="style_setting"></div>

}

export default StyleSetting;