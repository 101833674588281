import React from "react";
import "../turnplateCreate.scss"
import {Col, Input, Row, Radio} from "antd";
interface IProps {
    callbackParent(type:number,param:any):void,
    param ?: any,
}

interface paramType {
    people_luck_num_free?:string,
    people_luck_num_all_max?: string,
    people_luck_num_all_max_limit?: boolean,
    people_luck_num_day_max?: string,
    people_luck_num_day_max_limit?: boolean,
    subscribe_reward?: string
}
class CreateSend extends React.Component<IProps>{

    // 表单参数
    state = {
        param: {} as paramType,
    };

    componentDidMount(): void {
        this.initPageParams();
    }

    // 初始化页面参数
    initPageParams =() =>{
        let that = this;
        let paramTem = this.props.param;
        console.log(paramTem);
        if(Object.keys(paramTem).length !== 0){
            that.setState({
                param: paramTem
            })
        }
    };

    // 监听起始抽奖数变化
    handleNumFree = (e:any) => {
        let paramTem = this.state.param;
        paramTem.people_luck_num_free = e.target.value.trim();
        this.setState({param: paramTem});
        this.submitParentParams();
    }

    // 参与显示
    handleAllLimit = (e:any) => {
        let paramTem = this.state.param;
        paramTem.people_luck_num_all_max_limit = e;
        this.setState({param: paramTem});
        this.submitParentParams();
    }

    handleDayLimit = (e:any) => {
        let paramTem = this.state.param;
        paramTem.people_luck_num_day_max_limit = e;
        this.setState({param: paramTem});
        this.submitParentParams();
    }

    // 拉新可增长数
    handleRewardNum = (e:any) => {
        let paramTem = this.state.param;
        paramTem.subscribe_reward = e.target.value.trim();
        this.setState({param: paramTem});
        this.submitParentParams();
    }

    addPerson = (e:any) => {
        let paramTem = this.state.param;
        paramTem.people_luck_num_all_max = e.target.value.trim();
        this.setState({param: paramTem});
        this.submitParentParams();
    }

    partNumber = (e: any) => {
        let paramTem = this.state.param;
        paramTem.people_luck_num_day_max = e.target.value.trim();
        this.setState({param: paramTem});
        this.submitParentParams();
    }

    // 提交参数给 父级组件
    submitParentParams =()=>{
        this.props.callbackParent(3,this.state.param);
    };

    render(){
        let {param} = this.state;
        return (
            <div className="task_setting_view l_content">
                <div className="task_title">
                    抽奖限制
                </div>
                <div className="setting_inputs">
                    <Row>
                        <Col span={24}>
                            <Row className="mb30" type="flex" align="middle">
                                <Col span={3} className="input_label">
                                    起始抽奖数：
                                </Col>
                                <Col span={5}>
                                    <Input placeholder='1' addonAfter="次" value={param.people_luck_num_free} onChange={this.handleNumFree}></Input>
                                </Col>
                            </Row>
                            <Row className="mb30" type="flex" align="middle">
                                <Col span={3} className="input_label">
                                    总抽奖次数：
                                </Col>
                                <Col span={3}>
                                    <Radio.Group
                                        value={param.people_luck_num_all_max_limit}
                                        style={{ marginLeft: 20 }}
                                        onChange={(e) => this.handleAllLimit(e.target.value)}
                                    >
                                        <Radio value={false}>不限</Radio>
                                        <Radio value={true}>限制</Radio>
                                    </Radio.Group>
                                </Col>
                                <Col span={14}>
                                    <Row type="flex">
                                        <Col>
                                            <div style={{lineHeight: '35px',marginRight: '10px'}}>每人最多有</div>
                                        </Col>
                                        <Col><Input type="number" placeholder='0' value={param.people_luck_num_all_max}  onChange={this.addPerson} addonAfter="次"  /></Col>
                                    </Row>

                                </Col>
                            </Row>
                            <Row type="flex" align="middle" className='mb30'>
                                <Col span={3} className="input_label">
                                    每日抽奖机会：
                                </Col>
                                <Col span={3}>
                                    <Radio.Group
                                        value={param.people_luck_num_day_max_limit}
                                        style={{ marginLeft: 20 }}
                                        onChange={(e) => this.handleDayLimit(e.target.value)}
                                    >
                                        <Radio value={false}>不限</Radio>
                                        <Radio value={true}>限制</Radio>
                                    </Radio.Group>
                                </Col>
                                <Col span={14}>
                                    <Row type="flex">
                                        <Col><div style={{lineHeight: '35px',marginRight: '10px'}}>每人每日有</div></Col>
                                        <Col><Input type="number" placeholder='0' value={param.people_luck_num_day_max} onChange={this.partNumber} addonAfter="次"  /></Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={3} className="input_label">
                                    拉新可增加：
                                </Col>
                                <Col span={5}>
                                    <Input placeholder='1' value={param.subscribe_reward} addonAfter="次" onChange={this.handleRewardNum}></Input>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

export default CreateSend;