import React, { useEffect, useRef, memo, forwardRef, Ref } from "react"
import { Carousel, Row, Icon, message } from 'antd';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { toJS } from 'mobx'
import { Observer } from 'mobx-react';
import arrayMove from 'array-move';
import DecorationHomeCenterPreview from './decorationHomeCenterPreview'
import shopDecorationStore from '../../../../stores/shopDecoration.store'
import eidt_icon from '../../../../assret/images/decoration_edit.png'
import decoration_back_icon from '../../../../assret/images/decoration_back_icon.png'
import decoration_back_disabled_icon from '../../../../assret/images/decoration_back_disabled_icon.png'
import decoration_forward_icon from '../../../../assret/images/decoration_forward_icon.png'
import decoration_forward_disabled_icon from '../../../../assret/images/decoration_forward_disabled_icon.png'
import Swiper from "swiper";
import "swiper/swiper-bundle.css";
import  './decorationHomeCenter.scss'

interface IProps {
    template:number
}

interface IState {

}
const DecorationHomeCenter = memo(forwardRef((props:IProps,ref: Ref<any>) => {

    let template = props.template
    const content:any = useRef(null)
    const SortableItem = SortableElement((props:any) => 
        <div 
            key={props.indexT} 
            onClick={(e)=>{
                e.stopPropagation();
                (shopDecorationStore.shop_decoration_home_other.current!==props.indexT||shopDecorationStore.shop_decoration_home_other.module==='moFang')&&changeSelect(props)
            }} 
            onMouseEnter={()=>{onMouseEnterFunc(props.indexT)}} 
            onMouseLeave={()=>{onMouseLeaveFunc(props.indexT)}} 
            style={{position:'relative',zIndex:99}}
        >
            {(shopDecorationStore.shop_decoration_home_other.hoverCurrent === props.indexT || shopDecorationStore.shop_decoration_home_other.current === props.indexT)&&floatAction(props.indexT)}
            {props.children}
        </div>)
    const SortableContainerDiv = SortableContainer((props:any) => {
      return (
        <div className="template" style={{paddingTop:template===1?0:85}} {...props} ref={content}>
            <div style={{margin:"0 24px"}}>
                {props.children}
            </div>
        </div>
      );
    });   
    

    useEffect(()=>{

    },[])

    const changeSelect = (item:any)=>{
        shopDecorationStore.updateShopDecorationHomeOther({
            module:item.module , //当前选中模版
            showRight:true , //是否显示右侧编辑
            current: item.indexT, //当前板块
            hoverCurreny: -1,
            editMofangCurrent: -1 , //当前编辑的魔方导航
            hoverEditMofangCurrent: -1 , //当前悬停的编辑的魔方导航
            editMutiCurrent: 0 , //当前编辑的多图文导航
            hoverMutiCurrent: -1, //当前悬停编辑的多图文导航
        })
    }

    const onMouseEnterFunc = (index:number)=>{
        if(shopDecorationStore.shop_decoration_home_other.hoverCurrent !== index){
            shopDecorationStore.updateShopDecorationHomeOtherObjProps('hoverCurrent',index)
        }
    }

    const onMouseLeaveFunc = (index:number)=>{
        shopDecorationStore.updateShopDecorationHomeOtherObjProps('hoverCurrent',-1)
    }

    const onMouseEnterMoFangFunc = (index:number)=>{
        shopDecorationStore.updateShopDecorationHomeOtherObjProps('hoverEditMofangCurrent',index)
    }

    const onMouseLeaveMoFangFunc = (index:number)=>{
        shopDecorationStore.updateShopDecorationHomeOtherObjProps('hoverEditMofangCurrent',-1)
    }    

    const clickMoFangMenu = (e:any,index:number,pIndex:number,module:string) => {
        e.stopPropagation()
        shopDecorationStore.updateShopDecorationHomeOther({
            module:module , //当前选中模版
            showRight:true , //是否显示右侧编辑
            current:pIndex, //当前板块
            hoverCurrent: -1, //鼠标悬停模块
            editMofangCurrent:index , //当前编辑的魔方导航
            hoverEditMofangCurrent: index , //当前悬停的编辑的魔方导航
            editMutiCurrent: 0 , //当前编辑的多图文导航
            hoverMutiCurrent: -1, //当前悬停编辑的多图文导航            
        })
    }

    const initHtmls = () => {
        console.log('-------initHtmls------initHtmls')
        let htmlsTemp:any = []
        shopDecorationStore.shop_decoration_home.map((item:any,index:number)=>{
            switch(item.module){
                case 'muti':
                    if(item.type === 1){
                        if(template === 1){
                            htmlsTemp.push(templateCarousel({...item,index:index}))
                        }else{
                            htmlsTemp.push(templateOldCarousel({...item,index:index}))
                        }
                    }else if(item.type === 2){
                        setTimeout(()=>{
                            new Swiper(".swiper-container-"+index, {
                                slidesPerView: 2.3,
                                // slidesPerView: 'auto',
                                centeredSlides: false,
                                virtual: {
                                    slides: item.imgList,
                                },
                                nested:true,
                                // touchStartPreventDefault: true,
                                // touchStartForcePreventDefault: true,
                                // touchMoveStopPropagation: true,
                                // preventInteractionOnTransition: true
                                // observer:true,//修改swiper自己或子元素时，自动初始化swiper
                                // observeParents:true,//修改swiper的父元素时，自动初始化swiper
                            });
                        },100)
                        htmlsTemp.push(templateMultiScroll({...item,index:index}))
                    }else{
                        htmlsTemp.push(templateMulti({...item,index:index}))
                    }

                    break;
                case 'action':
                    if(template === 1){
                        htmlsTemp.push(templateAction({...item,index:index}))
                    }else{
                        htmlsTemp.push(templateOldAction({...item,index:index}))
                    }
                    break;
                case 'address':
                    if(template === 1){
                        htmlsTemp.push(templateAddress({...item,index:index}))
                    }else{
                        htmlsTemp.push(templateOldAddress({...item,index:index}))
                    }
                    break;
                case 'equityCard':
                    htmlsTemp.push(templateEquityCard({...item,index:index}))
                    break;
                case 'moFang':
                    if(item.type===2){
                        htmlsTemp.push(templateMoFang2({...item,index:index}))
                    }else{
                        htmlsTemp.push(templateMoFang1({...item,index:index}))
                    }
                    break;
                case 'invitation':
                    htmlsTemp.push(templateInvitation({...item,index:index}))
                    break;
                case 'richText':
                    htmlsTemp.push(templateRichText({...item,index:index}))
                    break;
            }
        })
        return htmlsTemp
    }

    //右上角排序删除
    const floatAction = (index:number) => {
        return(
            <div className="template_floatAction">
                <Icon type="caret-up" style={{fontSize:18}} onClick={(e)=>{templateSort(e,index,'asc')}}/>
                <Icon type="caret-down" style={{fontSize:18}} onClick={(e)=>{templateSort(e,index,'desc')}}/>
                <img src={require('../../../../assret/images/decoration_delete.png')} onClick={(e)=>{templateDel(e,index)}}/>
            </div>
        )
    }

    const templateSort = (e:any,index:number,type:string) => {
        e.stopPropagation()
        let data = JSON.parse(JSON.stringify(shopDecorationStore.shop_decoration_home))
        if((data.length-1) === index && type === 'desc'){
            //最后一个不能降序
            message.error('不能降序，已经是最后一个了!')
            return false
        }
        if(template === 1){
            if(index === 2 && type === 'asc'){
                //第一个不能升序
                message.error('不能升序，已经是第一个了!')
                return false
            }
        }else{
            if(index === 2 && type === 'asc'){
                //第一个不能升序
                message.error('不能升序，已经是第一个了!')
                return false
            }
        }
        if(type === 'asc'){
            let temp = data[index]
            data[index] = data[index-1]
            data[index-1] = temp
        }else{
            let temp = data[index]
            data[index] = data[index+1]
            data[index+1] = temp
        }
        shopDecorationStore.updateShopDecorationHome(data)
        shopDecorationStore.updateShopDecorationHomeOtherObjProps('current',type === 'asc' ? (index-1) : (index+1))
        shopDecorationStore.updateShopDecorationHomeOtherObjProps('hoverCurrent',type === 'asc' ? (index-1) : (index+1))        
    }

    const templateDel = (e:any,index:number) => {
        e.stopPropagation()
        let data = JSON.parse(JSON.stringify(shopDecorationStore.shop_decoration_home))
        data.splice(index,1)
        shopDecorationStore.updateShopDecorationHomeOther({
            module:'' , //当前选中模版
            showRight:false , //是否显示右侧编辑
            current: -1, //当前板块
            hoverCurrent: -1, //鼠标悬停模块
            editMofangCurrent: -1 , //当前编辑的魔方导航
            hoverEditMofangCurrent: -1 , //当前悬停的编辑的魔方导航
            editMutiCurrent: 0 , //当前编辑的多图文导航
            hoverMutiCurrent: -1, //当前悬停编辑的多图文导航
        })
        shopDecorationStore.updateShopDecorationHome(data)
    }

    //多图文-轮播图-模版1
    const templateCarousel = (props:any) => {
        return props.index === 0 ? (
            <div key={props.index}>
                {
                    (shopDecorationStore.shop_decoration_home_other.hoverCurrent === props.index || shopDecorationStore.shop_decoration_home_other.current === props.index) && <div className="dash_wrap_other"></div>
                }
                <Carousel autoplay className="template_carousel">
                    {
                        props.imgList.map((item:any,index:number)=>{
                            return <div className="template_carousel_item" key={index}>
                                <img src={item.imgUrl} alt=""/>
                            </div>
                        })
                    }
                </Carousel>             
            </div>
        ):(
            <SortableItem key={props.index} {...props} indexT={props.index}>
                {
                    (shopDecorationStore.shop_decoration_home_other.hoverCurrent === props.index || shopDecorationStore.shop_decoration_home_other.current === props.index) && <div className="dash_wrap_other"></div>
                }
                <Carousel autoplay className="template_carousel">
                    {
                        props.imgList.map((item:any,index:number)=>{
                            return <div className="template_carousel_item" key={index}>
                                <img src={item.imgUrl} alt=""/>
                            </div>
                        })
                    }
                </Carousel> 
            </SortableItem>
        )
    }
    //排队点餐操作-模版1
    const templateAction = (props:any) => {
        return props.index === 1 ? (
            <div key={props.index}>
                <Row type="flex" align="middle" className="template_action">
                    {
                        (shopDecorationStore.shop_decoration_home_other.hoverCurrent === props.index || shopDecorationStore.shop_decoration_home_other.current === props.index) && <div className="dash_wrap"></div>
                    }
                    <div className="template_action_item">
                        <img src="https://ydn-siyu.cdn.shuachi.com/line_up.png" alt=""/>
                        <div>排队取号</div>
                    </div>
                    <div className="template_action_item">
                        <img src="https://ydn-siyu.cdn.shuachi.com/dishes_icon.png" alt=""/>
                        <div>提前点餐</div>
                    </div>
                </Row>             
            </div>
        ):(
            <SortableItem key={props.index} {...props} indexT={props.index}>
                <Row type="flex" align="middle" className="template_action">
                    {
                        (shopDecorationStore.shop_decoration_home_other.hoverCurrent === props.index || shopDecorationStore.shop_decoration_home_other.current === props.index) && <div className="dash_wrap"></div>
                    }
                    <div className="template_action_item">
                        <img src="https://ydn-siyu.cdn.shuachi.com/line_up.png" alt=""/>
                        <div>排队取号</div>
                    </div>
                    <div className="template_action_item">
                        <img src="https://ydn-siyu.cdn.shuachi.com/dishes_icon.png" alt=""/>
                        <div>提前点餐</div>
                    </div>
                </Row>   
            </SortableItem>
        )
    }
    //商家信息-地址-模版1
    const  templateAddress = (props:any) => {
        return (
            <SortableItem key={props.index} {...props} indexT={props.index}>
                <Row type="flex" align="middle" justify="space-between" className="template_address">
                    {
                        (shopDecorationStore.shop_decoration_home_other.hoverCurrent === props.index || shopDecorationStore.shop_decoration_home_other.current === props.index) && <div className="dash_wrap"></div>
                    }
                    <div>{'拈江湖鸭肠火锅深圳店 >'}</div>
                    <div>
                        <img src="https://ydn-siyu.cdn.shuachi.com/home_location.png" alt=""/>
                        距离您9.75km
                        {/* <DragHandle /> */}
                    </div>
                </Row> 
            </SortableItem>
        )
    }
    //商家信息-地址-模版2
    const  templateOldAddress = (props:any) => {
        return props.index === 0 ? (
            <div key={props.index}>
                <div className="template_oldAddress">
                    {
                        (shopDecorationStore.shop_decoration_home_other.hoverCurrent === props.index || shopDecorationStore.shop_decoration_home_other.current === props.index) && <div className="dash_wrap_other"></div>
                    }
                    <div className="template_oldAddress_name">{'拈江湖鸭肠火锅深圳店 >'}</div>
                    <Row>
                        <img src="https://ydn-siyu.cdn.shuachi.com/home_location.png" alt=""/>
                        距离您9.75km
                    </Row>
                </div>             
            </div>
        ):(
            <SortableItem key={props.index} {...props} indexT={props.index}>
                <div className="template_oldAddress">
                    {
                        (shopDecorationStore.shop_decoration_home_other.hoverCurrent === props.index || shopDecorationStore.shop_decoration_home_other.current === props.index) && <div className="dash_wrap_other"></div>
                    }
                    <div className="template_oldAddress_name">{'拈江湖鸭肠火锅深圳店 >'}</div>
                    <Row>
                        <img src="https://ydn-siyu.cdn.shuachi.com/home_location.png" alt=""/>
                        距离您9.75km
                    </Row>
                </div> 
            </SortableItem>
        )
    }
    //多图文-轮播图-模版2
    const templateOldCarousel = (props:any) => {
        return props.index === 1 ? (
            <div key={props.index}>
                {
                    (shopDecorationStore.shop_decoration_home_other.hoverCurrent === props.index || shopDecorationStore.shop_decoration_home_other.current === props.index) && <div className="dash_wrap_other"></div>
                }
                <Carousel autoplay className="template_oldCarousel">
                    {
                        props.imgList.map((item:any,index:number)=>{
                            return <div className="template_oldCarousel_item" key={index}>
                                <img src={item.imgUrl} alt=""/>
                            </div>
                        })
                    }
                </Carousel>             
            </div>
        ):(
            <SortableItem key={props.index} {...props} indexT={props.index}>
                {
                    (shopDecorationStore.shop_decoration_home_other.hoverCurrent === props.index || shopDecorationStore.shop_decoration_home_other.current === props.index) && <div className="dash_wrap_other"></div>
                }
                <Carousel autoplay className="template_oldCarousel">
                    {
                        props.imgList.map((item:any,index:number)=>{
                            return <div className="template_oldCarousel_item" key={index}>
                                <img src={item.imgUrl} alt=""/>
                            </div>
                        })
                    }
                </Carousel> 
            </SortableItem>
        )
    }
    //排队点餐操作-模版2
    const templateOldAction = (props:any) => {
        return (
            <SortableItem key={props.index} {...props} indexT={props.index}>
                <Row type="flex" align="middle" className="template_oldAction">
                    {
                        (shopDecorationStore.shop_decoration_home_other.hoverCurrent === props.index || shopDecorationStore.shop_decoration_home_other.current === props.index) && <div className="dash_wrap"></div>
                    }
                    <Row type="flex" align="middle" justify="center" className="template_oldAction_item">
                        <img src="https://ydn-siyu.cdn.shuachi.com/line_up_2.jpg" alt=""/>
                        <div>排队取号</div>
                    </Row>
                    <Row type="flex" align="middle" justify="center" className="template_oldAction_item">
                        <img src="https://ydn-siyu.cdn.shuachi.com/dishes_icon.png" alt=""/>
                        <div>提前点餐</div>
                    </Row>
                </Row>  
            </SortableItem> 
        )
    }
    //多图文-横向拖拽
    const templateMultiScroll = (props:any) => {
        return(
            <SortableItem key={props.index} {...props} indexT={props.index}>
                <div className="template_module">
                    {
                        (shopDecorationStore.shop_decoration_home_other.hoverCurrent === props.index || shopDecorationStore.shop_decoration_home_other.current === props.index) && <div className="dash_wrap"></div>
                    }
                    {
                        props.showTitle&&<div className="template_module_title"><img src={require('../../../../assret/images/decoration_title_line.png')} alt=""/>{props.title}</div>
                    }
                    {
                        props.showSubTitle&&<div className="template_module_subTitle">{props.subTitle}</div>     
                    }
                    <div
                        className={`swiper-container-${props.index}`}
                        style={{
                            width: "100%",
                            overflow:'hidden'
                        }}
                        onTouchEnd = {(e)=>{e.stopPropagation()}}
                        onTouchMove = {(e)=> {e.stopPropagation()}}
                        onTouchStart = {(e)=> {e.stopPropagation()}}
                    >
                        <div className="swiper-wrapper">
                            {props.imgList.map((item:any,index:number) => {
                                return (
                                    <div className="swiper-slide" key={index}>
                                        <div className="template_module_swiper_item">
                                            <div className="template_module_swiper_img">
                                                <img
                                                    src={item.imgUrl}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="template_module_swiper_title">{item.desc}</div>
                                            <div className="template_module_swiper_desc">{item.subDesc}</div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </SortableItem>
        )
    }
    //多图文-竖排
    const templateMulti = (props:any) => {
        return(
            <SortableItem key={props.index} {...props} indexT={props.index}>
                <div className="template_module">
                    {
                        (shopDecorationStore.shop_decoration_home_other.hoverCurrent === props.index || shopDecorationStore.shop_decoration_home_other.current === props.index) && <div className="dash_wrap"></div>
                    }
                    {
                        props.showTitle&&<div className="template_module_title"><img src={require('../../../../assret/images/decoration_title_line.png')} alt=""/>{props.title}</div>
                    }
                    {
                        props.showSubTitle&&<div className="template_module_subTitle">{props.subTitle}</div> 
                    }
                    <Row type="flex" align="middle" className="template_module_muti">
                        {props.imgList.map((item:any,index:number) => {
                            return (
                                <Row type="flex" align="middle" justify="center" style={{width:'33%'}}>
                                    <div className="template_module_muti_item" key={index}>
                                        <div className="template_module_muti_img">
                                            <img
                                                src={item.imgUrl}
                                                alt=""
                                            />
                                        </div>
                                        <div className="template_module_muti_title">{item.desc}</div>
                                        <div className="template_module_muti_desc">{item.subDesc}</div>
                                    </div>
                                </Row>
                            );
                        })}
                    </Row>
                </div>
            </SortableItem>
        )
    }

    //魔方1-横排
    const templateMoFang1 = (props:any) =>{
        return (
            <SortableItem key={props.index} {...props} indexT={props.index}>
                <div className="template_module">
                    {
                        (shopDecorationStore.shop_decoration_home_other.hoverCurrent === props.index || shopDecorationStore.shop_decoration_home_other.current === props.index) && <div className="dash_wrap"></div>
                    }
                    {
                        props.showTitle&&<div className="template_module_title"><img src={require('../../../../assret/images/decoration_title_line.png')} alt=""/>{props.title}</div>
                    }
                    {
                        props.showSubTitle&&<div className="template_module_subTitle">{props.subTitle}</div>
                    }
                    {
                        props.list.findIndex((item:any)=>item.show) !== -1 ? (
                            <Row type="flex" align="middle" className="template_module_list">
                                {
                                    props.list.map((item:any,index:number)=>{
                                        const fontSize = item.style.fontSize === 1 ? 16 : item.style.fontSize === 2 ? 14 : 12
                                        const color = item.style.color ? item.style.color : '#333333'
                                        const fontWeight = item.style.fontWeight ? 'bold' : 'normal' 
                                        return item.show === 1 &&(
                                            <div 
                                                className="template_module_list_item" 
                                                key={index} 
                                                onMouseEnter={()=>{onMouseEnterMoFangFunc(index)}} 
                                                onMouseLeave={()=>{onMouseLeaveMoFangFunc(index)}}
                                                style={{width:(props.column?100/props.column:25)+'%'}}
                                            >
                                                <img src={item.imgType === 0 ? item.defaultImgUrl : item.imgUrl} alt=""/>  
                                                {
                                                    shopDecorationStore.shop_decoration_home_other.hoverEditMofangCurrent === index && <img src={eidt_icon} alt="" className="template_module_list_item_edit" onClick={(e)=>{clickMoFangMenu(e,index,props.index,props.module)}}/> 
                                                }
                                                <div style={{fontSize:fontSize,color:color,fontWeight:fontWeight}}>{item.name}</div> 
                                            </div>
                                        )
                                    })
                                }
                            </Row>
                        ): (
                            <div style={{width:'100%',height:40,backgroundColor:'#fff',marginBottom:10}}></div>
                        ) 
                    }

                </div>  
            </SortableItem>          
        )
    }
    //魔方2-异性排布-嵌套
    const templateMoFang2Child = (props:any) => {
        let htmls:any = []
        let { list } = props   
        let listTemp:any = []
        list.map((item:any,index:number)=>{
            if(item.show === 1){
                item.key = index
                listTemp.push(item)
            }
        })  
        if(listTemp.length === 0){
            htmls.push(<div style={{width:'100%',height:40,backgroundColor:'#fff',marginBottom:10}}></div>)
        }else{
            listTemp.map((item:any,index:number)=>{
                const fontSize = item.style.fontSize === 1 ? 16 : item.style.fontSize === 2 ? 14 : 12
                const color = item.style.color ? item.style.color : '#333333'
                const fontWeight = item.style.fontWeight ? 'bold' : 'normal' 
        
                switch(index%3){
                    case 0 : 
                        listTemp[index].show === 1 &&htmls.push(
                            <div key={index} className="template_card_left" onMouseEnter={()=>{onMouseEnterMoFangFunc(item.key)}} onMouseLeave={()=>{onMouseLeaveMoFangFunc(item.key)}}>
                                {/* <div className="template_card_tip">充值500送50</div> */}
                                <div className="template_card_num">0</div>
                                <div className="template_card_title" style={{fontSize:fontSize,color:color,fontWeight:fontWeight}}>{listTemp[index].name}</div>
                                <img src={listTemp[index].imgType === 0 ? listTemp[index].defaultImgUrl : listTemp[index].imgUrl} alt=""/>    
                                {
                                    shopDecorationStore.shop_decoration_home_other.hoverEditMofangCurrent === item.key && <img src={eidt_icon} alt="" className="template_card_left_edit" onClick={(e)=>{clickMoFangMenu(e,item.key,props.index,props.module)}}/>  
                                }                            
                            </div>
                        )
                        break
                    case 1 : 
                        htmls.push(
                            <div key={index} className="template_card_right">
                                {
                                    listTemp[index].show === 1 &&<div className="template_card_right_item" onMouseEnter={()=>{onMouseEnterMoFangFunc(item.key)}} onMouseLeave={()=>{onMouseLeaveMoFangFunc(item.key)}}>
                                        <div className="template_card_num">0</div>
                                        <div className="template_card_title" style={{fontSize:fontSize,color:color,fontWeight:fontWeight}}>{listTemp[index].name}</div>
                                        <img src={listTemp[index].imgType === 0 ? listTemp[index].defaultImgUrl : listTemp[index].imgUrl} alt=""/>   
                                        {
                                            shopDecorationStore.shop_decoration_home_other.hoverEditMofangCurrent === item.key && <img src={eidt_icon} alt="" className="template_card_right_item_edit" onClick={(e)=>{clickMoFangMenu(e,item.key,props.index,props.module)}}/>     
                                        }   
                                    </div>
                                }
                                {
                                    listTemp[index+1]&&listTemp[index+1].show === 1 &&<div className="template_card_right_item" onMouseEnter={()=>{onMouseEnterMoFangFunc(listTemp[index+1].key)}} onMouseLeave={()=>{onMouseLeaveMoFangFunc(listTemp[index+1].key)}}>
                                        {/* <div className="template_card_num">10<span>积分</span></div> */}
                                        <div className="template_card_num">0</div>
                                        <div 
                                            className="template_card_title" 
                                            style={{
                                                fontSize:listTemp[index+1].style.fontSize === 1 ? 16 : listTemp[index+1].style.fontSize === 2 ? 14 : 12,
                                                color:listTemp[index+1].style.color ? listTemp[index+1].style.color : '#333333',
                                                fontWeight:listTemp[index+1].style.fontWeight ? 'bold' : 'normal',
                                            }}>
                                            {listTemp[index+1].name}
                                        </div>
                                        <img src={listTemp[index+1].imgType === 0 ? listTemp[index+1].defaultImgUrl : listTemp[index+1].imgUrl} alt=""/> 
                                        {
                                            shopDecorationStore.shop_decoration_home_other.hoverEditMofangCurrent === listTemp[index+1].key && <img src={eidt_icon} alt="" className="template_card_right_item_edit" onClick={(e)=>{clickMoFangMenu(e,listTemp[index+1].key,props.index,props.module)}}/>   
                                        }                                 
                                    </div>
                                }
                            </div>
                        )
                        break
                }
            })
        }
        return htmls
    } 

    //魔方2-异性排布
    const templateMoFang2 = (props:any) =>{
        return  (
            <SortableItem key={props.index} {...props} indexT={props.index}>
                <Row type="flex" align="middle" justify="space-between" className="template_card">
                    {
                        (shopDecorationStore.shop_decoration_home_other.hoverCurrent === props.index || shopDecorationStore.shop_decoration_home_other.current === props.index) && <div className="dash_wrap"></div>
                    }
                    {
                        templateMoFang2Child(props)
                    }
                </Row>
            </SortableItem>
        )

    }

    //富文本
    const templateRichText = (props:any) =>{
        return (
            <SortableItem key={props.index} {...props} indexT={props.index}>
                <div className="template_module">
                    {
                        (shopDecorationStore.shop_decoration_home_other.hoverCurrent === props.index || shopDecorationStore.shop_decoration_home_other.current === props.index) && <div className="dash_wrap"></div>
                    }
                    {
                        props.showTitle&&<div className="template_module_title"><img src={require('../../../../assret/images/decoration_title_line.png')} alt=""/>{props.title}</div>
                    }
                    {
                        props.showSubTitle&&<div className="template_module_subTitle">{props.subTitle}</div>
                    }                    
                    <div className="template_editor" dangerouslySetInnerHTML={{__html:props.richText?props.richText:'富文本输入，最多可输入8000字'}}>
                    </div>
                </div>   
            </SortableItem>            
        )
    }
    //权益卡
    const templateEquityCard = (props:any) =>{
        return(
            <SortableItem key={props.index} {...props} indexT={props.index}>
                <div className="template_banner">
                    {
                        (shopDecorationStore.shop_decoration_home_other.hoverCurrent === props.index || shopDecorationStore.shop_decoration_home_other.current === props.index) && <div className="dash_wrap"></div>
                    }
                    <img src={props.type === 0 ? props.defaultImgUrl:props.imgUrl} alt="邀请好友"/>     
                </div>
            </SortableItem>
        )
    }
    //邀请
    const templateInvitation = (props:any) =>{
        return(
            <SortableItem key={props.index} {...props} indexT={props.index}>
                <div className="template_banner">
                    {
                        (shopDecorationStore.shop_decoration_home_other.hoverCurrent === props.index || shopDecorationStore.shop_decoration_home_other.current === props.index) && <div className="dash_wrap"></div>
                    }
                    <img src={props.type === 0 ? props.defaultImgUrl:props.imgUrl} alt="邀请好友"/>     
                </div>
            </SortableItem>
        )
    }    

    const onSortEnd = ({ oldIndex, newIndex }: any) => {
        if (oldIndex !== newIndex) {
            let dataTemp = JSON.parse(JSON.stringify(shopDecorationStore.shop_decoration_home))      
            const newData = arrayMove([].concat(dataTemp), oldIndex, newIndex).filter(el => !!el);
            const obj:any = newData[newIndex]
            changeSelect({...obj,indexT:newIndex})
            shopDecorationStore.updateShopDecorationHome(newData)
        }
    };

    const goPrev = () => {
        let prevData = JSON.parse(JSON.stringify(shopDecorationStore.shop_decoration_home_history_prev))
        if(prevData.length === 0){
            return false
        }
        let data = prevData[prevData.length-1]
        prevData.splice(prevData.length-1,1)
        console.log('---goPrev---',data)
        shopDecorationStore.shop_decoration_home_history_prev = prevData
        shopDecorationStore.updateShopDecorationHomeHistoryNext(shopDecorationStore.shop_decoration_home)
        shopDecorationStore.updateShopDecorationHome(data.list,false)
    }

    const goNext = () => {
        let nextData = JSON.parse(JSON.stringify(shopDecorationStore.shop_decoration_home_history_next))
        if(nextData.length === 0){
            return false
        }
        let data = nextData[nextData.length-1]
        nextData.splice(nextData.length-1,1)
        console.log('---goNext---',data)
        shopDecorationStore.shop_decoration_home_history_next = nextData
        shopDecorationStore.updateShopDecorationHomeHistoryPrev(shopDecorationStore.shop_decoration_home)
        shopDecorationStore.updateShopDecorationHome(data.list,false)
    }

    return (<Observer render={() => 
        <>
            {
                shopDecorationStore.shop_decoration_home_preview === 1 ? <DecorationHomeCenterPreview template={template}/> : (
                    <div className="decoration_home_center" ref={ref}>
                        <div className="decoration_home_center_action">
                            <div className="decoration_home_center_action_prev" style={{cursor:shopDecorationStore.shop_decoration_home_history_prev.length>0?'pointer':'not-allowed'}} onClick={()=>{goPrev()}}>
                                {
                                    shopDecorationStore.shop_decoration_home_history_prev.length>0?(
                                        <img src={decoration_back_icon} alt=""/>
                                    ):(
                                        <img src={decoration_back_disabled_icon} alt=""/>
                                    )
                                }
                            </div>
                            <div className="decoration_home_center_action_next" style={{cursor:shopDecorationStore.shop_decoration_home_history_next.length>0?'pointer':'not-allowed'}} onClick={()=>{goNext()}}>
                                {
                                    shopDecorationStore.shop_decoration_home_history_next.length>0?(
                                        <img src={decoration_forward_icon} alt=""/>
                                    ):(
                                        <img src={decoration_forward_disabled_icon} alt=""/>
                                    )
                                }                                
                            </div>
                        </div>
                        <div className="decoration_home_center_wrap">
                            <div className="decoration_home_center_boxShow"/>
                            <div  className={`decoration_home_center_seat ${template === 1 ? 'decoration_home_center_seat_hidden' : ''}`}>
                                <Row type="flex" align="middle" justify="space-between" className="mb15 mt15">
                                <div className="decoration_home_center_seat_date">9:16</div>
                                    <img src={require('../../../../assret/images/decoration_wode_signal_block.png')} alt="" className="decoration_home_center_seat_signal"/>
                                </Row>
                                <img src={require('../../../../assret/images/decoration_wode_icon.png')} alt="" className="decoration_home_center_seat_icon"/>
                            </div>
                            <SortableContainerDiv 
                                onSortEnd={onSortEnd} 
                                // useDragHandle
                                distance = {10}
                                // useWindowAsScrollContainer
                                // lockToContainerEdges
                                // getContainer
                            >
                                {
                                    initHtmls()
                                }
                            </SortableContainerDiv>
                            <div className={'template_footer'}>
                                <img className="template_footer_img" src="https://ydn-siyu.cdn.shuachi.com/wechat_tabbar.pic.jpg" alt=""/>
                            </div>
                        </div>
                    </div>
                )
            }        
        </>
    }/>)
}))

export default DecorationHomeCenter