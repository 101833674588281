import React from 'react';
import { Row, Tabs, Col, Table, Modal, Input, message, Avatar } from 'antd';
import DataSummaryItem from './components/data_summary_item';
import Search from 'antd/lib/input/Search';
import { getRequest } from '../../api/http';
import { apiUrl } from '../../api/api_url';
import { getIsPermisson, messageCustom } from '../../utils/permisstionUtil';
const { TabPane } = Tabs;
const { TextArea } = Input;

class XcxWithdraw extends React.Component{
  columnOne = [
    {
      title: '序号',
      dataIndex: 'order_by',
      key: 'order_by',
      className: 'list_center',
      render: (text: string, record:any, index: number) => {
          return <div className="">
              {index+1}
          </div>
      }
    },
    {
      title: "用户名称",
      dataIndex: "nickname",
      key: "nickname",
      render: (text: string, userinfo: any ) => {
          if(userinfo) {
            return <Row type="flex" justify="center" align="middle">
              <Avatar src={userinfo.headimgurl} style={{marginRight: 10}} />
              <div>{userinfo.nickname}</div>
            </Row>
          }
          return <Row>--</Row>
      }
    },
    {
      title: "提现订单号",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "提现金额",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "提交时间",
      dataIndex: "created_at",
      key: "created_at"
    },
    {
      title: "操作",
      key: "action",
      render:(rowdata:any,record:any,idx:number) => {
        return (
            <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                  <div className='btn_green mr30' onClick={() => {
                    if(!getIsPermisson('1_9_3_1')){
                      messageCustom();
                      return;
                    }
                    this.successDialog(record)
                  }}>审核通过</div>
                  <div className='btn_green' onClick={() => {
                    if(!getIsPermisson('1_9_3_2')){
                      messageCustom();
                      return;
                    }
                    this.errorDialog(record)
                  }}>审核失败</div>
            </div>
        )
    }
    },
  ];

  columnTwo = [
    {
      title: '序号',
      dataIndex: 'order_by',
      key: 'order_by',
      className: 'list_center',
      render: (text: string, record:any, index: number) => {
          return <div className="">
              {index+1}
          </div>
      }
    },
    {
      title: "用户名称",
      dataIndex: "nickname",
      key: "nickname",
      render: (text: string, userinfo: any ) => {
          if(userinfo) {
            return <Row type="flex" justify="center" align="middle">
              <Avatar src={userinfo.headimgurl} style={{marginRight: 10}} />
              <div>{userinfo.nickname}</div>
            </Row>
          }
          return <Row>--</Row>
      }
    },
    {
      title: "提现订单号",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "提现金额",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "提交时间",
      dataIndex: "created_at",
      key: "created_at"
    },
    {
      title: "审核时间",
      dataIndex: "audit_at",
      key: "audit_at"
    },
    {
      title: "操作人",
      dataIndex: "operate_name",
      key: "operate_name"
    },
  ];

  columnThree = [
    {
      title: '序号',
      dataIndex: 'order_by',
      key: 'order_by',
      className: 'list_center',
      render: (text: string, record:any, index: number) => {
          return <div className="">
              {index+1}
          </div>
      }
    },
    {
      title: "用户名称",
      dataIndex: "nickname",
      key: "nickname",
      render: (text: string, userinfo: any ) => {
          if(userinfo) {
            return <Row type="flex" justify="center" align="middle">
              <Avatar src={userinfo.headimgurl} style={{marginRight: 10}} />
              <div>{userinfo.nickname}</div>
            </Row>
          }
          return <Row>--</Row>
      }
    },
    {
      title: "提现订单号",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "提现金额",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "提交时间",
      dataIndex: "created_at",
      key: "created_at"
    },
    {
      title: "审核时间",
      dataIndex: "audit_at",
      key: "audit_at"
    },
    {
      title: "操作人",
      dataIndex: "operate_name",
      key: "operate_name"
    },
    {
      title: "操作",
      key: "action",
      render:(rowdata:any,record:any,idx:number) => {
        return (
            <div className='btn_green' onClick={() => this.showError(record.error_desc)}>失败原因</div>
        )
    }
    },
  ];

  state = {
    dataSource: {
      "amount": '0.00',//总奖励金额
      "can_withdraw_amount": "0.00",//可提现金额
      "free_amount": '0.00',//冻结金额
      "withdrawed_amount": "0.00",//已提现金额
      "wait_audit_amount": "0.00",//待审核金额  // "wait_count": 1,//待审核数
      data:{
        total:0,
        list: []
      }
    },
    status: '1',
    page: 1,
    page_size: 10,
    nickname: '',
    visible: false,
    error: ''
  }

  componentDidMount(){
    this._init();
  }

  _init = async () => {
    let { page, page_size, status, nickname} = this.state;
    let params: any = {
      page,
      page_size,
      status,
    }

    if(nickname != ''){
      params.nickname = nickname;
    }

    let res:any = await getRequest(apiUrl.withdrawList, params)
    if(res.code == 1){
      this.setState({
        dataSource: res.data
      })
    }
  }

  tabChange = (status: any) => {
    this.setState({
      status,
      page: 1,
      page_size: 10
    }, () => {
      this._init()
    })
  }

  searchUser = (name: string) => {
    this.setState({
      nickname: name
    }, () => {
      this._init();
    })
  }

  pageOnChange = (page: number) => {
    this.setState({
      page
    }, () => {
      this._init()
    })
  }

  // 审核通过弹窗
  successDialog = (record: any) => {
    Modal.confirm({
      title: '审核通过',
      content: `是否确定${record.nickname}的申请提现${record.amount}元？确认后${record.amount}元将到达${record.nickname}的微信零钱`,
      okText: '确认',
      cancelText: '取消',
      onOk: async () => {
        let res: any = await getRequest(apiUrl.withdrawAudit, {out_trade_no: record.code,status: 1})
        if(res.code == 1){
          message.success('审核通过')
          this._init();
        }
      },
      onCancel() {
      },
    });
  }
  code = ''

  errorDialog = (record: any) => {
    this.setState({visible: true})
    this.code = record.code;
  }

  errorSure = async () => {
    if(this.state.error == ''){
      message.error('请输入失败原因')
      return;
    }

    let res: any = await getRequest(apiUrl.withdrawAudit, {out_trade_no: this.code,status: 15,error_desc: this.state.error})
    if(res.code == 1){
      this.setState({visible: false})
      message.success('已操作成功')
      this._init();
    }
  }

  // 展示失败原因
  showError = (text: string) => {
    Modal.error({
      title: '失败原因',
      content: `${text}`,
      okText: '确认',
    });
  }

  render() {
    let { dataSource, status, page_size, page } = this.state;

    return <div className='l_content'>
      <Row>
        <DataSummaryItem
          tips="全部合伙人获得佣金总和"
          mainTitle={`${dataSource.amount ? dataSource.amount : '0'}元`}
          subTitle="总获得奖励"
        />
        <DataSummaryItem
          tips="合伙人可提现总金额"
          mainTitle={`${dataSource.can_withdraw_amount ? dataSource.can_withdraw_amount : '0'}元`}
          subTitle="可提现金额"
        />
        <DataSummaryItem
          tips="所谓冻结金额就是合伙人邀请的用户，正在用餐还未结账邀请获得佣金系统先冻结"
          mainTitle={`${dataSource.free_amount ? dataSource.free_amount : '0'}元`}
          subTitle="冻结金额"
        />
        <DataSummaryItem
          tips="合伙人已提现的总金额"
          mainTitle={`${dataSource.withdrawed_amount ? dataSource.withdrawed_amount : '0'}元`}
          subTitle="已提现金额"
        />
        <DataSummaryItem
          tips="还未审核的总金额"
          mainTitle={`${dataSource.wait_audit_amount ? dataSource.wait_audit_amount : '0'}元`}
          subTitle="待审核金额"
        />
      </Row>

      <Tabs defaultActiveKey={status} onChange={this.tabChange}>
        <TabPane tab="待审核" key="1">
          <Row type="flex" align="middle" justify="start" className="mt20 mb40">
            <Col className="mr30">
                <span>用户名：</span>
                <Search placeholder="请输入用户名" onSearch={this.searchUser} style={{ width: 234 }}/>
            </Col>
          </Row>

          <Table
            rowKey={"id"}
            columns={this.columnOne}
            dataSource={dataSource.data.list}
            size="middle"
            pagination={{
              current: page,
              pageSize: page_size,
              total: dataSource.data.total,
              onChange: this.pageOnChange,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total: number) => `共${total}条`,
              onShowSizeChange: (current:number, size: number) => {this.setState({page_size: size}, () => {this._init();})}

            }}
          />
        </TabPane>
        <TabPane tab="审核通过" key="11">
          <Row type="flex" align="middle" justify="start" className="mt20 mb40">
              <Col className="mr30">
                  <span>用户名：</span>
                  <Search placeholder="请输入用户名" onSearch={this.searchUser} style={{ width: 234 }}/>
              </Col>
            </Row>

            <Table
              rowKey={"id"}
              columns={this.columnTwo}
              dataSource={dataSource.data.list}
              size="middle"
              pagination={{
                current: page,
                pageSize: page_size,
                total: dataSource.data.total,
                onChange: this.pageOnChange,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (total: number) => `共${total}条`,
                onShowSizeChange: (current:number, size: number) => {this.setState({page_size: size}, () => {this._init();})}

              }}
            />
        </TabPane>
        <TabPane tab="审核失败" key="15">
          <Row type="flex" align="middle" justify="start" className="mt20 mb40">
              <Col className="mr30">
                  <span>用户名：</span>
                  <Search placeholder="请输入用户名" onSearch={this.searchUser} style={{ width: 234 }}/>
              </Col>
            </Row>

            <Table
              rowKey={"id"}
              columns={this.columnThree}
              dataSource={dataSource.data.list}
              size="middle"
              pagination={{
                current: page,
                pageSize: page_size,
                total: dataSource.data.total,
                onChange: this.pageOnChange,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (total: number) => `共${total}条`,
                onShowSizeChange: (current:number, size: number) => {this.setState({page_size: size}, () => {this._init();})}

              }}
            />
        </TabPane>
      </Tabs>


      <Modal
        visible={this.state.visible}
        title='审核失败'
        okText='确定'
        cancelText='取消'
        onOk={this.errorSure}
        onCancel={() => {this.setState({visible: false})}}
        >
          <div>
            <div style={{marginBottom:'10px'}}>请输入原因。如申请金额太少</div>
            <TextArea rows={4} onChange={(e) => {this.setState({error: e.target.value})}} />
          </div>
        </Modal>
    </div>
  }
}

export default XcxWithdraw


// import React, { Fragment, useState, } from 'react'
// import { Row } from 'antd'
// import DataSummaryItem from './components/data_summary_item'

// import ListTable from './components/data_list'

// import {dataSource} from './components/dataType'

// const XcxWithdraw = () => {

//   const[dataSource,setdataSource] = useState({
//     withdraw_fee_total:0,
//     withdraw_fee_no:0,
//     withdraw_fee_ok:0,
//     withdraw_fee_deal:0,
//   })

//   return <Fragment>


//   {/* <Button type="primary" onClick={toApplyWithdrawal} style={{marginLeft: 20, marginBottom: 20}}>申请提现</Button> */}

//   <ListTable updateOverview={(data:dataSource) => setdataSource(data)} />
// </Fragment>
// }

// export default XcxWithdraw