import React, { useState, useEffect } from "react";
import "./volumeCenter.scss";

import volume_icon from "../../assret/images/volume_icon.jpg";
import other from "../../assret/images/other.png";
import buy_icon from "../../assret/images/buy_icon.png";
import help from "../../assret/images/help.png";
import coupon_other from "../../assret/images/coupon_other.png";
import change_icon from '../../assret/images/change_icon.png'
import equity_icon from '../../assret/images/equity_icon.png'
import send_icon from '../../assret/images/send_icon.png'
import birth_icon from '../../assret/images/birth_icon.png'
import store_icon from '../../assret/images/store_icon.png'
import charge from '../../assret/images/charge.png'
import consumption from '../../assret/images/consumption.png'
import { useHistory } from "react-router-dom";
import { getIsPermisson, messageCustom } from "../../utils/permisstionUtil";

type VolumeListItem = {
  title: string,
  desc: string,
  path: string,
  icon: string,
  uni_key: string
}

const VolumeCenter = () => {
  const history: any = useHistory();

  // 生成 优惠券
  const createVolum = (list: any) => {
    return (
      <div className="volume_list">
        {list.map((item: any, index: number) => {
          return (
            <div
              className="base-coupons"
              key={index}
              onClick={() => naviGatePage(item)}
            >
              <img src={item.icon} className="volume_icon" alt="" />
              <div className="base_right">
                <div className="volume_title">{item.title}</div>
                <div className="volume_desc">{item.desc}</div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  // 卡券中心
  const volume_list:Array<VolumeListItem> = [
    {
      title: "优惠券",
      desc: "多种优惠券，拉新促活促复购",
      icon: volume_icon,
      path: "/sidebar/coupon",
      uni_key: '1_12_1'
    },
    {
      title: "代金券",
      desc: "向顾客提供购买后使用的代金券",
      icon: coupon_other,
      path: "/sidebar/vouchers_list",
      uni_key: '1_12_2'
    },
    {
      title: "买赠",
      desc: "买指定活动商品可以获得赠送商品 例：买一赠一",
      icon: buy_icon,
      path: '/sidebar/buyAndGive',
      uni_key: '1_12_3'
    },
    {
      title: "兑换菜品",
      desc: "刺激用户消费积极性",
      icon: change_icon,
      path: '/sidebar/changeDishList',
      uni_key: '1_12_4'
    },
  ];

  // 会员营销
  const market_list = [
    {
      title: "权益卡",
      desc: "为了满足商家快速回笼资金，拉新获客等多元化经营需要",
      icon: equity_icon,
      path: '/sidebar/equityList',
      uni_key: '1_12_5'
    },
    {
      title: "直接发券",
      desc: "自主选定客户群，进行精准化营销",
      icon: send_icon,
      path: '/sidebar/sendCoupon',
      uni_key: '1_12_6'
    },
    {
      title: "生日礼物",
      desc: "在顾客生日送去祝福",
      icon: birth_icon,
      path: '/sidebar/birthList',
      uni_key: '1_12_7'
    },
    {
      title: "注册有礼",
      desc: "门店营销送礼，拉新促活增收",
      icon: store_icon,
      path: '/sidebar/newPeople',
      uni_key: '1_12_8'
    },
    {
      title: "充值免单",
      desc: "充值并买单，实现留存顾客",
      icon: charge,
      path: '/sidebar/chargeFree',
      uni_key: '1_12_9'
    },
    {
      title: "消费有礼",
      desc: "消费可送券/余额，促进客户多次消费转化",
      icon: consumption,
      path: '/sidebar/consumption',
      uni_key: '1_12_10'
    },
    {
      title: "积分商城",
      desc: "让客户用积分兑换礼品",
      icon: consumption,
      path: '/sidebar/scoreIndex',
      uni_key: '1_12_11'
    }
  ]

  // 吸引粉丝
  const fans_list = [
    {
      title: "助力有礼",
      desc: "分享好友帮忙助力，吸引流量促进下单",
      icon: help,
      path: '/sidebar/helpCenter',
      uni_key: '1_12_12'
    },
  ];

  // 商家工具
  const shopOther_list = [
    {
      title: "商家收银",
      desc: "便捷支付，提高客单价",
      icon: other,
      path: '/sidebar/couponOther',
      uni_key: '1_12_13'
    },
  ];

  // 商家券
  const shopVoucher_list = [
    {
      title: "优惠券",
      desc: "多种优惠券，拉新促活促复购",
      icon: volume_icon,
      path: '/sidebar/merchantVoucherCoupon',
      uni_key: '1_12_13'
    },
    {
      title: "兑换菜品",
      desc: "刺激用户会员消费积极性",
      icon: change_icon,
      path: '/sidebar/merchantVoucherDishList',
      uni_key: '1_12_13'
    },
    {
      title: "注册有礼",
      desc: "门店营销送礼，拉新促活增收",
      icon: store_icon,
      path: '/sidebar/merchantVoucherRegister',
      uni_key: '1_12_13'
    },
    {
      title: "消费有礼",
      desc: "消费可送券/余额/积分，促进客户多次消费转化",
      icon: consumption,
      path: '/sidebar/merchantVoucherConsumption',
      uni_key: '1_12_13'
    },
  ];

  // 跳转页面
  const naviGatePage = (item: VolumeListItem) => {
    if(!getIsPermisson(item.uni_key)){
      messageCustom();
      return;
    }
    item.path && history.push(item.path);
  };
  return (
    <div className='l_content'>
      <h3>卡券中心</h3>
      {createVolum(volume_list)}
      <h3>会员营销</h3>
      {createVolum(market_list)}
      <h3>吸引粉丝</h3>
      {createVolum(fans_list)}
      <h3>商家买单工具</h3>
      {createVolum(shopOther_list)}
      {/* <h3>商家券</h3>
      <h5>领取后同步至微信卡包,商家券优先显示</h5>
      {createVolum(shopVoucher_list)} */}
    </div>
  );
};

export default VolumeCenter;
