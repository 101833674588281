import React, { useEffect, useState } from 'react'
import { Modal, message } from 'antd'
import './editOrderNumber.scss'
import { postRequest } from '../../api/http'
import { apiUrl } from '../../api/api_url'
const EditOrderNumber = (props: any)=>{
  const [data,setdata] = useState<any>({})
  useEffect(()=>{
      setdata(props.data)
  })

  const editAction = async ()=>{
  //   {
  //     dish_id:int // 菜id
  //     change:int  // 变化 +1， -1， -2
  //     standard:string  // 规格
  // }

    let changeN:any = 0
    let editCount = data.editCount ? data.editCount : data.count
    if(data.type === 'edit'){
      if(data.count > editCount) {
        changeN = -(data.count - editCount)
      }
      if(editCount > data.count){
        changeN = editCount - data.count
      }
      if(editCount === data.count){
        changeN = 0
      }
    }
    if(data.type === 'retreat'){
      changeN = data.editCount ? -editCount : -1
    }


    let params = {
        code:data.code,
        update:{
            foods:[
              {
                dish_id:data.id,
                standard:data.standard,
                change:changeN,
              }
            ]
        }
    }
    // return true
    const res:any = await postRequest(`${apiUrl.foodOrderUpdate}`,params);
    if(res.code === 1){
      message.info('修改成功');
      return true
    }else{
      return false
    }
  }

  const editMinus = ()=>{
    let newData = data
    if(data.type === 'edit'){
      if(newData.hasOwnProperty('editCount')){
        if(newData.editCount >= 1){
          newData.editCount = newData.editCount-1
        }
      }else{
        if(newData.count >= 1){
          newData.editCount = newData.count-1
        }
      }
    }
    if(data.type === 'retreat'){
      if(newData.hasOwnProperty('editCount')){
          newData.editCount = Math.max(1,newData.editCount-1)
      }else{
          newData.editCount = 1
      }
    }

    setdata(newData)
    refreshAction()
  }

  const editAdd = ()=>{
    let newData = data
    if(data.type === 'edit'){
      if(newData.hasOwnProperty('editCount')){
        data.editCount = data.editCount+1
      }else{
        data.editCount = data.count+1
      }
    }
    if(data.type === 'retreat'){
      if(newData.hasOwnProperty('editCount')){
        data.editCount = Math.min(data.editCount+1,data.count)
      }else{
        data.editCount = Math.min(2,data.count)
      }
    }

    // data.editCount = data.editCount ? data.editCount+1 : data.count+1
    setdata(data)
    refreshAction()
  }


  //用于刷新的方法
  const [refresh,setRefresh] = useState(false);
  const refreshAction = () => {
    if(refresh === true){
      setRefresh(false)
    }else{
      setRefresh(true)
    }
  }
  useEffect(()=>{

  },[refresh])

  return (
    <Modal
        title={data.title ? data.title : ""}
        visible={data.visible ? data.visible : false}
        onOk={data.onOk ? ()=>{data.onOk(editAction())} : ()=>{}}
        onCancel={data.onCancel ? data.onCancel : ()=>{}}
      >
        <div className="editOrderNumber">
          <div onClick={editMinus} className="back_btn reduce_btn active">-</div>
          <div className="content">{
            data.type && data.type==='edit' ?
              data.hasOwnProperty('editCount') ? data.editCount : data.count
            : data.type && data.type==='retreat' ?
              data.hasOwnProperty('editCount') ? data.editCount : 1
            : data.count
          }</div>
          <div onClick={editAdd} className="back_btn add_btn active">+</div>
        </div>
    </Modal>
  )
}

export default EditOrderNumber