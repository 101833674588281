import React, { Fragment, useEffect, useState } from 'react'
import { Button, Table, Row, Tag, message, Tabs } from 'antd'
import { useHistory } from 'react-router-dom'
import { getRequest, postRequest } from '../../../api/http';
import { apiUrl } from '../../../api/api_url';
import InviteHelpUsers from '../invite_help_users/invite_help_users';

interface IconfigList {
  total: number,
  items: Array<{
    id: number
    shop_user_id: number
    shop_id: number
    eat_users: number
    need_users: number
    dish: {
      id: number, 
      name: string, 
      main_pic: string,
      printer_key: string, 
      price: string,
      standard: string
    }
    status: number
    created_at: string
    updated_at: string
    count: number
  }>
}

const { TabPane } = Tabs;

const InviteHelpConfigurationList = () => {

  const history:any = useHistory();

  const [configList, setconfigList] = useState<IconfigList>({
    total: 0,
    items: []
  })

  const [loadingData, setloadingData] = useState(false);
  const [currentTab, setcurrentTab] = useState(1);

  useEffect(() => {
    initList();
  }, []);

  const initList = async () => {
    setloadingData(true);
    const res:any = await getRequest(apiUrl.dishesHelpConfigList);
    if(res && res.data) {
      setconfigList(res.data);
    }
    setloadingData(false);
  }
  
  // 添加配置
  const addConfiguration = () => {
    history.push({
      pathname: '/sidebar/add_invite_help_configuration'
    })
  }

  const editConfig = (rowdata: any) => {
    history.push({
      pathname: '/sidebar/add_invite_help_configuration',
      state: {
        edit: rowdata
      }
    })
  }
  
  // 修改活动状态
  const changeSuatus = async (rowdata: any, status: 1 | 2) => {
    const parms = {
      id: rowdata.id,
      update: {
        status: status
      }
    }
    const res: any = await postRequest(apiUrl.dishesHelpConfigEdit, parms);
    if(res && res.data) {
      message.info('修改成功');
      initList();
    }
  }

  const columns = [
    {
      title: '用餐人数',
      dataIndex: 'eat_users',
      key: 'eat_users',
    },
    {
      title: '需助力人数',
      dataIndex: 'need_users',
      key: 'need_users',
    },
    {
      title: '可获得菜品',
      dataIndex: 'dish.name',
      key: 'id',
    },{
      title: '可获得份数',
      dataIndex: 'count',
      key: 'count',
    },{
      title: '状态',
      // dataIndex: 'status',
      key: 'status',
      render: (rowdata: any) => {
        if(rowdata) {
          if(rowdata.status === 2) {
            return <Tag color="red">未生效</Tag>
          }
          if(rowdata.status === 1) {
            return <Tag color="green">已生效</Tag>
          }
        }
        return <Row></Row>
      }
    },{
      title: '创建时间',
      dataIndex: 'created_at',
      key: 'created_at',
    },{
      title: '操作',
      key: 'action',
      render: (rowdata: any) => {
        if(rowdata) {
          return <Row type="flex">
            {
              rowdata.status === 1 && <Button size="small" onClick={() => changeSuatus(rowdata, 2)}>设置不生效</Button>
            }
            {
              rowdata.status === 2 && <Button size="small" onClick={() => changeSuatus(rowdata, 1)}>设置生效</Button>
            }
            
            <Button size="small" style={{marginLeft: 20}} onClick={() => editConfig(rowdata)}>编辑</Button>
          </Row>
        }
        return <Row></Row>
      }
    }
  ];

  const callback = (key: any) => setcurrentTab(key);
  
  return <Fragment>
    <Tabs defaultActiveKey={'1'} onChange={callback} animated={false}>
      <TabPane tab="助力配置" key="1">
        <Button type="primary" style={{marginBottom: 20}} onClick={addConfiguration}>添加配置</Button>
        <Table columns={columns} loading={loadingData} size="middle" dataSource={configList.items} rowKey={`id`}/>
      </TabPane>
      <TabPane tab="参与用户" key="2">
        <InviteHelpUsers tabIdx={currentTab} />
      </TabPane>
    </Tabs>
    
  </Fragment>
}

export default InviteHelpConfigurationList