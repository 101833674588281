import { observer } from "mobx-react";
import React from "react";
import { Button, Input, message, Row, Col, Radio, Select, Spin, Tree, Icon, Tooltip, Modal, Table, } from "antd";
import { postRequest, getRequest } from '../../api/http';
import { apiUrl } from '../../api/api_url';
const { Option } = Select;

const key = 'updatable';
const { TreeNode } = Tree;
const { confirm } = Modal;


@observer
class SendCouponEdit extends React.Component<any> {
    columnNum:any = [
        {
            title: '优惠券名称',
            dataIndex: 'name',
            key: 'name',
            align: "center",
        },
        {
            title: '券类型',
            dataIndex: 'type',
            key: 'type',
            align: "center",
            render: (text:any,record:any,) => (
                <div className="color">{record.info.type === 'discount' ? '折扣券' : record.info.type === 'voucher' ? '优惠券' : '兑换券'}</div>
            ),
        },
        {
            title: '券有效期',
            dataIndex: 'created_at',
            key: 'created_at',
            align: "center",
            render: (text:any,record:any,) => {
                let info:any = record.expire_info;
                return <div className="color">{info.type === 'time' ?  info.start_time + '至' + info.end_time : '自领取日'+info.day + '天领取'}</div>
            }
        },
        {
            title: '券份数',
            dataIndex: 'num',
            key: 'num',
            align: "center",
            render: (text:any,record:any,) => {
                return <div className="back_wrap">
                        <div className="back_btn reduce_btn active" onClick={() => this.reduceCoupon(record.id)}>-</div>
                        <div className="good_num">{record.coupon_num}</div>
                        <div className="back_btn add_btn active" onClick={() => this.addCoupon(record.id)}>+</div>
                    </div>
            }
        },
        {
            title: '操作',
            align: "center",
            render: (text: string,record:any,index:number)=>(
                <div className="table_action" onClick={() => this.unBindShop(index)}>移除</div>
            )
        },
    ];

    // 创建 商品列表
    columns:any = [
        {
            title: '优惠券名称',
            dataIndex: 'name',
            key: 'name',
            align: "center",
        },
        {
            title: '券类型',
            dataIndex: 'price',
            key: 'id',
            align: "center",
            render: (text:any,record:any,) => (
                <div className="color">{record.info.type === 'discount' ? '折扣券' : record.info.type === 'voucher' ? '优惠券' : '兑换券'}</div>
            ),
        },
        {
            title: '券有效期',
            dataIndex: 'created_at',
            key: 'created_at',
            align: "center",
            render: (text:any,record:any,) => {
                let info:any = record.expire_info;
                return <div className="color">{info.type === 'time' ?  '自'+info.start_time + '至' + info.end_time : '自领取日'+info.day + '天内有效'}</div>
            }
        },
        {
            title: '操作',
            align: "center",
            render: (text: string,record:any,index:number)=>{
                return <div>
                    {
                        record.selected
                        ? '已关联'
                        : <div className="table_action" onClick={() => this.handleBindShop(record.id)}>关联</div>
                    }
                </div>
            }
        },
    ];

    state = {
        loading: false,
        send_type: 'group',
        couponList: [],
        customerList: [],
        userList: [],
        groupList: [],
        name: '',
        timerId:null,
        group_id: [],
        num: 0,
        coupon_id: 0,
        fans_id: [],

        data: [],
        value: [],
        fetching: false,
        user_type: [],
        taskFormVisible: false,
        visible: false,
        placement: 'left',
        numCustomer: [], //客服号列表
        customer_id: 0, //选中的客服号

        // 礼包
        selectCoupon: [],
        couponVisible: false,
        current: 1,
        selectList: [],
        listNum: 0,
        giftCouponList: [],
        type: 1,
        can_trans: true
    }

    lastFetchId = 0;

    componentDidMount() {
        this._initCoupon();
        this._initGetCustomer();
        this._initGroup();
        this.onInitList()
    }

    fetchUser = async (value: any) => {
        this.lastFetchId += 1;
        const fetchId = this.lastFetchId;
        this.setState({ data: [], fetching: true });

        const res: any = await getRequest(apiUrl.getMemberListSendCoupon, { keywords: value, page_size: 100 });
        if (res.code === 1) {
            if (fetchId !== this.lastFetchId) {
                return;
            }
            this.setState({ data: res.data.list, fetching: false });
        }
    };

    handleChange = (value: any) => {
        let fans_id: any = [];
        if (value.length > 0) {
            value.map((item: any) => {
                fans_id.push(item.key);
            })
        }
        this.setState({
            value,
            fans_id,
            data: [],
            fetching: false,
        });
    };

    // 优惠券列表
    _initCoupon = async () => {
        const res: any = await getRequest(apiUrl.couponList, { pageSize: 100, position: 5 });
        if (res.code === 1) {
            this.setState({
                couponList: res.data.list
            })
        }
    }

    // 初始化优惠券列表
    onInitList = async () => {
        let param = {
            current: 1,
            pageSize: 100,
            position: 3
        }
        const res:any = await getRequest(apiUrl.couponList, param);
        if(res.code === 1){
            this.setState({
                giftCouponList: res.data.list,
                listNum: res.data.total,
                current: 1,
                selectList: res.data.list.slice(0, 10)
            })
        }
    }

    //客服号列表
    _initGetCustomer = async () => {
        const res: any = await getRequest(apiUrl.getCustomer);
        if (res.code === 1) {
            this.setState({
                customerList: res.data
            })
        }
    }

    // 分组列表
    _initGroup = async () => {
        const res: any = await getRequest(apiUrl.memberGroup);
        if (res.code === 1) {
            this.setState({
                groupList: res.data
            })
        }
    }

    inputChange = (e: any) => {
        let name = e.target.name;
        let value = e.target.value;
        this.setState({
            [name]: value
        })
    }

    statusChange = (e: any) => {
        this.setState({
            send_type: e
        })
    }

    // 用户分组
    groupChange = (e: any) => {
        this.setState({
            group_id: e
        })
    }

    // 优惠券选择
    couponChange = (e: any) => {
        this.setState({
            coupon_id: e
        })
    }

    //客服号选择
    onCusmomerChange = (e: any) => {
        this.setState({
            customer_id: e
        })
    }

    numChange = (e: any) => {
        this.setState({
            num: e.target.value.trim()
        })
    }

    handleSubmit = async () => {
        message.loading({ content: '发布中', key });

        const { location } = this.props;
        const { send_type, coupon_id, group_id, fans_id, num, name, user_type, value, type, can_trans, selectCoupon } = this.state;
        var tableInfo: any = {};

        if (location.state !== undefined) {
            tableInfo.id = location.state.id;
        }
        if (type == 2 && name === '') {
            message.warning({ content: '请输入活动名称!', key, duration: 2 });
            return;
        }

        tableInfo.send_type = send_type;
        tableInfo.type = type;

        if (send_type === 'group') {
            if (group_id.length === 0) {
                message.warning({ content: '请选择用户分组!', key, duration: 2 });
                return;
            }
            tableInfo.group_id = group_id;
        } else if (send_type === 'single') {
            if (fans_id.length === 0) {
                message.warning({ content: '请选择用户!', key, duration: 2 });
                return;
            }
            tableInfo.fans_id = fans_id;
        } else if(send_type === 'customer') {
            if (value.length === 0) {
                message.warning({ content: '请选择客服号!', key, duration: 2 });
                return;
            }
            tableInfo.send_type = 'single';
            tableInfo.fans_id = value;
        } else {
            if (user_type.length === 0) {
                message.warning({ content: '请选择发放用户!', key, duration: 2 });
                return;
            }
            tableInfo.user_type = user_type;
        }

        if (type == 1 && coupon_id === 0) {
            message.warning({ content: '请选择优惠券!', key, duration: 2 });
            return;
        }
        if(type == 2 && selectCoupon.length == 0){
            message.warning({ content: '请选择优惠券!', key, duration: 2 });
            return;
        }
        if(type == 1){
            tableInfo.coupon_id = coupon_id;
        }else{
            let arr:any = [];
            selectCoupon.map((item:any) => {
                arr.push({
                    coupon_id: item.id,
                    num: item.coupon_num
                })
            })
            tableInfo.coupon_id = arr
        }

        if (num === 0) {
            message.warning({ content: '请输入优惠券个数!', key, duration: 2 });
            return;
        }
        if(type == 2){
            tableInfo.name = name;
            tableInfo.can_trans = can_trans;
        }
        tableInfo.num = num;

        if (location.state !== undefined) {
            const res: any = await postRequest(apiUrl.couponSend, tableInfo);
            if (res['code'] === 1) {
                message.success({ content: '修改成功!', key, duration: 2 });
                this.goBack()
            } else {
                message.error({ content: '修改失败!', key, duration: 2 });
            }
        } else {
            const res: any = await postRequest(apiUrl.couponSend, tableInfo);
            if (res['code'] === 1) {
                message.success({ content: '发布成功!', key, duration: 2 });
                this.goBack()
            } else {
                message.error({ content: '发布失败!', key, duration: 2 });
            }
        }
    };

    // 返回上一页
    goBack = () => {
        this.props.history.goBack();
    }

    // 可见用户
    onCheck = (checkedKeys: any, info: any) => {
        let keyList = checkedKeys;
        let user_type: any[] = [];
        keyList.map((item: any) => {
            if (item !== '4') {
                user_type.push(item)
            }
        })
        this.setState({
            user_type
        })
    };

    numCustomer = (e: any) => {
        this.setState({
            numCustomer: e.target.value.trim()
        })
    }

    //添加或删除客服号
    async onDelCustomer(index: any, type: any) {
        let { customerList } = this.state;
        if(type === 'del') {
            customerList.splice(index,1)
            confirm({
                title: '删除',
                content: '是否删除该客服号？',
                okText: "删除",
                cancelText: "取消",
                onOk: async () => {
                    const res: any = await getRequest(apiUrl.setCustomer, {is_customer: false, ids: [customerList[index]['id']]});
                    if (res && res['code'] === 1) {
                        message.success("删除成功");
                        this.setState(() => {
                            return {
                                customerList: customerList
                            }
                        })
                    } else {
                        message.error(`${res.message}`);
                    }

                },
                onCancel() { },
            });
        } else {
            const res: any = await getRequest(apiUrl.setCustomer, {is_customer: true, ids: [...this.state.fans_id]});
            if (res && res['code'] === 1) {
                this._initGetCustomer()
                this.setState({
                    data: [],
                    value: []
                })
            } else {
                message.error(`${res.message}`);
            }
        }
    }

    showDrawer = () => {
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
        });
    };

    onChange = (e: any) => {
        this.setState({
            placement: e.target.value,
        });
    };

    //搜索用户去防抖
    selectUser = (value: any)=> {
        this.debounce(this.selectUser(value),1000)
        
    }
    onDebounceKeyUpClick = (e:any) => {//加入防抖动后 在频繁输入后 不会发送请求
        let debounceAjax = this.debounce(this.fetchUser, 500)
        debounceAjax(e)
    }

    debounce = (fn:any, delay = 3000) => {
        //期间间隔执行 节流
        return (...rest:any) => { //箭头函数是没有arguments的 所以用...rest 来代替
          let args = rest;
          // @ts-ignore
          if (this.state.timerId) clearTimeout(this.state.timerId);//要用this.timerId 而不能直接定义var timerId=null;
          // @ts-ignore
          this.state.timerId = setTimeout(() => {
            fn.apply(this, args)
          }, delay)
        }
      }

    // 显示关联
    showTable = () => {
        let { giftCouponList, selectCoupon} = this.state;
        giftCouponList.map((coupon: any) => {
            selectCoupon.map((select: any) => {
                if(coupon.id === select.id){
                    coupon.selected = true;
                }
            })
        })
        this.setState({
            giftCouponList,
            selectList: giftCouponList.slice(0, 10),
            current: 1,
            couponVisible: true
        })
    }

    addCoupon = (id: any) => {
        let selectCoupon = this.state.selectCoupon;
        selectCoupon.map((item: any) => {
            if(item.id === id){
                item.coupon_num++;
            }
        })
        this.setState({
            selectCoupon
        })
    }

    reduceCoupon = (id: any) => {
        let selectCoupon = this.state.selectCoupon;
        selectCoupon.map((item: any) => {
            if(item.id === id){
                if(item.coupon_num === 1){
                    return;
                }
                item.coupon_num--;
            }
        })
        this.setState({
            selectCoupon
        })
    }

    // 关联
    handleBindShop = (id:number) => {
        // let { type } = this.state;
        let selectCoupon:any[] = this.state.selectCoupon;
        let giftCouponList:any[] = this.state.giftCouponList;
        giftCouponList.map((item) => {
            if(item.id === id){
                item.selected = true;
                item.coupon_num = 1;
                selectCoupon.push(item)
            }
        })
        console.log(selectCoupon);
        this.setState({
            selectCoupon,
            giftCouponList
        })
    }

    // 解除关联
    unBindShop = (index: number) => {
        let { giftCouponList } = this.state;
        let selectCoupon: any = this.state.selectCoupon;
        giftCouponList.map((item: any) => {
            if(item.id === selectCoupon[index].id){
                item.selected = false;
            }
        })
        selectCoupon.splice(index, 1)
        this.setState({
            giftCouponList,
            selectList: giftCouponList.slice(0, 10),
            current: 1,
            selectCoupon
        })
    }

    changePage = async (page: number) => {
        let { giftCouponList } = this.state;
        let list = giftCouponList.slice((page-1)*10, (10*page));
        this.setState({
            selectList: list,
            current: page
        })
    }

    render() {
        const { name, send_type, couponList, groupList, num, fetching, data, value, user_type, customerList, loading, visible, numCustomer } = this.state;

        const { giftCouponList, selectCoupon, selectList, current, listNum, couponVisible, type, can_trans } = this.state;
        return <div className='l_content'>
            <div className="edit_title">发放优惠券</div>

            <Row className="mb30" type="flex" align="middle">
                <Col span={2}>
                    <div>
                        <span className="star"></span>
                        <span className="color_black">发放方式</span>
                    </div>
                </Col>
                <Col span={22}>
                    <Radio.Group
                        value={type}
                        name='type'
                        onChange={(e) => this.inputChange(e)}
                    >
                        <Radio value={1}>单独发券</Radio>
                        <Radio value={2}>发放礼包</Radio>
                    </Radio.Group>
                </Col>
            </Row>

            {
                type == 2
                ? <Row className="mb30" type="flex" align="middle">
                        <Col span={2}>
                            <div>
                                <span className="star"></span>
                                <span className="color_black">礼包名称</span>
                            </div>
                        </Col>
                        <Col span={6}>
                            <Input placeholder='请输入礼包名称' name='name' value={name} onChange={(e) => this.inputChange(e)} />
                        </Col>
                    </Row>
                : null
            }

            {
                type == 2
                ? <Row className="mb30" type="flex" align="middle">
                        <Col span={2}>
                            <div>
                                <span className="star"></span>
                                <span className="color_black">活动礼品</span>
                            </div>
                        </Col>
                        <Col span={16}>
                            <div onClick={this.showTable}>
                                    <Icon type="plus-circle" style={{fontSize: '20px',marginRight: '10px'}} />
                                    关联优惠券
                            </div>

                            {
                                selectCoupon.length > 0
                                ? <Table
                                    size="middle"
                                    rowKey={"id"}
                                    dataSource={selectCoupon}
                                    columns={this.columnNum}
                                    style={{marginTop: '20px'}}
                                    pagination={
                                        {
                                            hideOnSinglePage: true,
                                            total: selectCoupon.length,
                                        }
                                    }
                                />
                                : null
                            }

                        </Col>
                    </Row>
                : null
            }

            <Row className="mb30" type="flex" align="middle">
                <Col span={2}>
                    <div>
                        <span className="star"></span>
                        <span className="color_black">发放类型</span>
                    </div>
                </Col>
                <Col span={22}>
                    <Radio.Group
                        value={send_type}
                        onChange={(e) => this.statusChange(e.target.value)}
                    >
                        <Radio value={'group'}>指定用户分组</Radio>
                        <Radio value={'single'}>指定用户</Radio>
                        <Radio value={'user_type'}>新老用户</Radio>
                        <Radio value={'customer'}>
                            <span style={{ marginRight: '10px' }}>客服号</span>
                            <Tooltip title={'客服号不计算券有效期，客服发出去用户领取开始计算券有效期'}>
                                <Icon type="question-circle" theme="filled" />
                            </Tooltip>
                        </Radio>
                    </Radio.Group>
                </Col>
            </Row>

            {
                send_type === 'group'
                    ? <div>
                        <Row className="mb30" type="flex" align="middle">

                            <Col span={2}>
                                <div>
                                    <span className="star"></span>
                                    <span className="color_black">发放用户</span>
                                </div>
                            </Col>
                            <Col span={6}>
                                <Select placeholder='请选择用户分组' mode="multiple" style={{ width: '100%' }} onChange={(e) => this.groupChange(e)} optionFilterProp="children">
                                    {
                                        groupList.map((item: any) => {
                                            return <Option value={item.id} key={item.id}>{item.name}</Option>
                                        })
                                    }
                                </Select>
                            </Col>
                        </Row>

                    </div>
                    : send_type === 'single' ? <Row className="mb30" type="flex" align="middle">
                        <Col span={2}>
                            <div>
                                <span className="star"></span>
                                <span className="color_black">发放用户</span>
                            </div>
                        </Col>
                        <Col span={6}>
                            <Select
                                placeholder='请选择用户'
                                showSearch
                                mode="multiple"
                                labelInValue
                                value={value}
                                notFoundContent={fetching ? <Spin size="small" /> : null}
                                filterOption={false}
                                onSearch={this.onDebounceKeyUpClick}
                                onChange={this.handleChange}
                                style={{ width: '100%' }}
                                optionLabelProp="label"
                            >
                                {
                                    data.map((item: any) => {
                                        return <Option value={item.id} key={item.id} label={item.nickname}>{item.nickname}{item.phone_number}</Option>
                                    })
                                }
                            </Select>
                        </Col>
                    </Row>
                        : send_type === 'customer'
                            ? <Row className="mb30" type="flex" align="middle">
                                <Col span={2}>
                                    <div>
                                        <span className="star"></span>
                                        <span className="color_black">选择客服号</span>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <Select placeholder='请选择客服号' mode="multiple" style={{ width: '60%' }} onChange={(e) => this.handleChange(e)} optionFilterProp="children">
                                        {
                                            customerList.map((item: any) => {
                                                return <Option value={item.id} key={item.id}>{item.nickname}</Option>
                                            })
                                        }
                                    </Select>
                                    {/* <Select placeholder='请选择客服号' style={{ width: '80%' }} onChange={this.onCusmomerChange}>
                                        {
                                            customerList.map((item: any) => {
                                                return <Option value={item.id} key={item.id}>{item.nickname}</Option>
                                            })
                                        }
                                    </Select> */}
                                    {/* <Select
                                        placeholder='请选择客服号'
                                        showSearch
                                        mode="multiple"
                                        labelInValue
                                        value={value}
                                        notFoundContent={fetching ? <Spin size="small" /> : null}
                                        filterOption={false}
                                        onSearch={this.fetchUser}
                                        onChange={this.handleChange}
                                        style={{ width: '100%' }}
                                    >
                                        {
                                            data.map((item: any) => {
                                                return <Option value={item.id} key={item.id}>{item.nickname}</Option>
                                            })
                                        }
                                    </Select> */}
                                    <span style={{ marginLeft: '10px', color: '#2589ff', cursor: 'pointer' }} onClick={() => { this.setState({ visible: true }) }}>添加客服号</span>
                                </Col>
                            </Row>

                            : <Row className="mb30" type="flex" align="top">
                                <Col span={2}>
                                    <div>
                                        <span className="star"></span>
                                        <span className="color_black">发放用户</span>
                                    </div>
                                </Col>
                                <Col span={6}>
                                    <Tree
                                        checkable
                                        showIcon
                                        onCheck={this.onCheck}
                                        defaultSelectedKeys={user_type}
                                        checkedKeys={user_type}
                                    >
                                        <TreeNode title="新用户" key="1" icon={
                                            <Tooltip placement="topLeft" title={"还未注册的用户或已经注册还没有下单的用户"}>
                                                <Icon type="question-circle" />
                                            </Tooltip>
                                        }>
                                        </TreeNode>
                                        <TreeNode title="老顾客" key="4" icon={
                                            <Tooltip placement="topLeft" title={"交易次数>=1次的交易次数"}>
                                                <Icon type="question-circle" />
                                            </Tooltip>
                                        }>
                                            <TreeNode title="充值用户" key="2" />
                                            <TreeNode title="非充值用户" key="3" />
                                        </TreeNode>
                                    </Tree>
                                </Col>
                            </Row>
            }

            {
                type == 1
                ? <Row className="mb30" type="flex" align="middle">
                        <Col span={2}>
                            <div>
                                <span className="star"></span>
                                <span className="color_black">选择发放券</span>
                            </div>
                        </Col>
                        <Col span={6}>
                            <Select placeholder='请选择优惠券' style={{ width: '100%' }} onChange={this.couponChange}>
                                {
                                    couponList.map((item: any) => {
                                        return <Option value={item.id} key={item.id}>{item.name}  / {item.expire_info.type == 'day' ? '自领取日'+ item.expire_info.day + '天内' : item.expire_info.start_time + '-' + item.expire_info.end_time}</Option>
                                    })
                                }
                            </Select>
                        </Col>
                    </Row>
                : null
            }

            <Row className="mb30" type="flex" align="middle">
                <Col span={2}>
                    <div>
                        <span className="star"></span>
                        <span className="color_black">发放数量</span>
                    </div>
                </Col>
                <Col span={6}>
                    <Input placeholder="请输入发放数量" maxLength={10} value={num} onChange={(e) => this.numChange(e)} />
                </Col>
            </Row>

            {
                type == 2
                ? <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">是否支持赠送</span>
                        </div>
                    </Col>
                    <Col span={22}>
                        <Radio.Group
                            value={can_trans}
                            name='can_trans'
                            onChange={(e) => this.inputChange(e)}
                        >
                            <Radio value={false}>不支持赠送</Radio>
                            <Radio value={true}>支持赠送</Radio>
                        </Radio.Group>
                    </Col>
                </Row>
                : null
            }


            <Row>
                <Col span={12}>
                    <div className="bottom_wrap">
                        <Button type="primary" ghost onClick={this.goBack}>
                            取消
                            </Button>
                        <Button type="primary" onClick={this.handleSubmit}>
                            保存
                            </Button>
                    </div>
                </Col>
            </Row>
            {/* 添加客服号 */}
            {
                <Modal
                    title="添加客服号"
                    onCancel={this.onClose}
                    // onOk={this.handleOk}
                    visible={visible}
                    width={400}
                    style={{height: '500px'}}
                    footer={[
                        <Button key="submit" type="primary" loading={loading} onClick={() => {this.onDelCustomer(1,'add')}}>
                            添加客服号
                        </Button>
                    ]}
                >
                    <Row>
                        <Col span={24}>
                            <div>
                                {
                                    customerList.map((item: any, index) => {
                                        return (
                                            <div key={index} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', alignItems: 'center', lineHeight: '24px' }}>
                                                <div style={{ fontSize: '16px' }}>{item.nickname}</div>
                                                <div onClick={() => { this.onDelCustomer(index, 'del') }} style={{ paddingTop: '3px', cursor: 'pointer' }}><Icon type='delete' style={{ fontSize: 20 }} /></div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        {/* <Col span={12}>
                            <div>
                                <Input placeholder="请输入客服号" value={numCustomer} onClick={this.numCustomer} />
                            </div>
                        </Col> */}
                        <Col span={24}>
                            <Select
                                placeholder='请选择用户'
                                showSearch
                                mode="multiple"
                                labelInValue
                                value={value}
                                notFoundContent={fetching ? <Spin size="small" /> : null}
                                filterOption={false}
                                onSearch={
                                    this.selectUser
                                }
                                onChange={this.handleChange}
                                style={{ width: '100%' }}
                            >
                                {
                                    data.map((item: any) => {
                                        return <Option value={item.id} key={item.id}>{item.nickname}</Option>
                                    })
                                }
                            </Select>
                        </Col>
                    </Row>
                </Modal>
            }

            <Modal
                title={null}
                visible={couponVisible}
                onOk={() => {this.setState({couponVisible: false})}}
                onCancel={()=>{this.setState({couponVisible: false})}}
                width="70vw"
            >
                <div className="model_head">
                    <div className="head_title">关联优惠券</div>
                </div>
                <Table
                    size="middle"
                    rowKey={"id"}
                    dataSource={selectList}
                    columns={this.columns}
                    pagination={
                        {
                            current,
                            total: listNum,
                            onChange: this.changePage,
                            pageSize: 10
                        }
                    }
                />
            </Modal>
        </div>;
    }

}
export default SendCouponEdit