import React from "react";
import {Button, Input, message, Row, Col, Select, Modal} from "antd";
import { postRequest, getRequest } from '../../../api/http';
import { apiUrl } from '../../../api/api_url';

const { Option } = Select;

interface ShopTableEdit{
}

const key = 'updatable';

class ShopTableEdit extends React.Component<any> {

    state = {
        loading: false,
        name: '',
        regionList: [],
        region_name: '',
        region_id: -1,
        qrcode: '',
        people_num: 0,
        sort: 0, //排序id
    }

    componentDidMount () {
        const {location} = this.props;
        if(location.state !== undefined){
            this.initEdit(location.state.id);
        }
        this.onInitList();
    }

    // 初始化列表
    onInitList = async () => {
        const res:any = await getRequest(apiUrl.regionList,{});
        if(res.code === 1){
            this.setState({
                regionList: res.data.list,
            })
        }
    }

    // 初始化编辑
    initEdit = async (id:number) => {
        const params = {
            id: id
        };
        const res:any = await getRequest(apiUrl.tableInfo,params);
        if(res.code === 1){
            this.setState({
                name: res.data.name,
                region_name: res.data.region_name,
                people_num: res.data.people_num,
                region_id: res.data.region_id,
                qrcode: res.data.qrcode,
                sort: res.data.sort,
            })
        }
    }

    nameChange = (e:any) => {
        let name = e.target.value.trim();
        this.setState({
            name
        })
    }

    sortChange = (e:any) => {
        let sort = e.target.value.trim();
        this.setState({
            sort
        })
    }

    // 就餐人数修改
    numChange = (e:any) => {
        this.setState({
            people_num: e.target.value
        })
    }

    // 券类型
    typeChange = (e:string) => {
        this.setState({
            region_type: e,
            region_id: e
        })
    }


    handleSubmit = async (e:any) => {
        message.loading({ content: '发布中', key });
        e.preventDefault();

        const {location} = this.props;
        const { sort, name, region_id, people_num } = this.state;

        var tableInfo:any = {};

        if(location.state !== undefined){
            tableInfo.id = location.state.id;
        }

        if(name === ''){
            message.warning({ content: '请输入餐台名称!', key, duration: 2 });
            return;
        }

        if(people_num === 0){
            message.warning({ content: '请输入可就餐人数!', key, duration: 2 });
            return;
        }

        if(region_id === -1){
            message.warning({ content: '请选择区域!', key, duration: 2 });
            return;
        }

        tableInfo.name = name;
        tableInfo.people_num = people_num;
        tableInfo.region_id = region_id;
        tableInfo.sort = sort;

        if(location.state !== undefined){
            const res:any = await postRequest(apiUrl.tableEdit,tableInfo);
            if(res['code'] === 1){
                message.success({ content: '修改成功!', key, duration: 2 });
                this.props.history.push('/sidebar/shopTable');
            }else{
                message.error({ content: '修改失败!', key, duration: 2 });
            }
        }else{
            const res:any = await postRequest(apiUrl.tableAdd,tableInfo);
            if(res['code'] === 1){
                message.success({ content: '发布成功!', key, duration: 2 });
                this.props.history.push('/sidebar/shopTable')
            }else{
                message.error({ content: '发布失败!', key, duration: 2 });
            }
        }
    };

    // 返回上一页
    goBack = () => {
        this.props.history.push('/sidebar/shopTable');
    }

    // 重新生成
    reCreate = () => {
        const {location} = this.props;
        const { name, region_id} = this.state;
        Modal.confirm({
            title: '重新生成二维码',
            content: `重新生成餐台二维码后，旧的二维码将无法进行扫码点餐。确认重新生成？`,
            okText: '重新生成',
            cancelText: '取消',
            onOk: async () => {
                const res:any = await postRequest(apiUrl.rebuildQrcode,{region_id: region_id, name: name, id: location.state.id});
                if(res.code === 1){
                    this.setState({
                        qrcode: res.data.qrcode
                    })
                    message.success({ content: '生成成功!', key, duration: 2 });
                }else{
                    message.error({ content: '生成失败!', key, duration: 2 });
                }
            },
            onCancel() {
            },
        });
    }

    render(){
        const { sort, name, regionList, region_name, qrcode, people_num} = this.state;

        return <div className='l_content'>
                <div className="edit_title">餐台信息</div>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">餐台号</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <Input placeholder="请输入餐台号" maxLength={10} value={name} onChange={(e) => this.nameChange(e)}/>
                    </Col>
                </Row>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">可就餐人数</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <Input placeholder="请输入就餐人数" maxLength={10} value={people_num} onChange={(e) => this.numChange(e)} type='number'/>
                    </Col>
                </Row>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">所属区域</span>
                        </div>
                    </Col>
                    <Col span={14}>
                        <Select style={{ width: 120 }} onChange={this.typeChange} placeholder={region_name !== '' ? region_name: '选择区域'}>
                            {
                                regionList.map((item:any) => {
                                    return <Option value={item.id} key={item.id}>{item.name}</Option>
                                })
                            }
                        </Select>
                    </Col>
                </Row>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div style={{marginLeft: '11px'}}>
                            <span className="color_black">排序</span>
                        </div>
                    </Col>
                    <Col span={7} style={{display: 'flex', alignItems: 'center'}}>
                        <Input style={{width: '80%', marginRight: '10px'}} type="number" placeholder="请输入数字" maxLength={10} value={sort} onChange={(e) => this.sortChange(e)}/>
                        <span style={{color: '#4A4A4A', fontSize: '12px'}}>排序越大越靠前</span>
                    </Col>
                </Row>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">餐台二维码</span>
                        </div>
                    </Col>
                    <Col span={2}>
                        <div>
                            <img src={qrcode} className='qrcode' style={{width: '100px'}} alt=''/>
                        </div>
                    </Col>
                    {
                        this.props.location.state !== undefined ?
                        <Col span={12}>
                            <Button onClick={this.reCreate}>重新生成</Button>
                        </Col>
                        : null
                    }
                </Row>

                <Row>
                    <Col span={12}>
                        <div className="bottom_wrap">
                            <Button type="primary" ghost onClick={this.goBack}>
                                取消
                            </Button>
                            <Button type="primary" onClick={this.handleSubmit}>
                                保存
                            </Button>
                        </div>
                    </Col>
                </Row>

        </div>;
    }

}
export default ShopTableEdit