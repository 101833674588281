import React, { useEffect, useState } from "react";
import { Row, Input, Select, DatePicker } from "antd";
import { getRequest } from "../../../api/http";
import { apiUrl } from "../../../api/api_url";

const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

type IfliterData = {
  [key: string]: string
}

let fliterData: IfliterData = {
  name: '',
  status: '',
  start_time: '',
  end_time: ''
}

type Iprops = {
  search(key: IfliterData):void
}

type ImemberLevelListItem = {
  created_at: string
  id: number
  name: string
  score: number
}

const VoucherFilterRow = (props: Iprops) => {
  
  useEffect(() => {

  }, [])

  // 过滤日期
  const dateChange = (date:any, dateString:any) => {
    fliterData = {
      ...fliterData,
      start_time: dateString[0],
      end_time: dateString[1]
    }
    props.search(fliterData);
  };

  // 搜索
  const searchKey = async (val: string) => {
    fliterData = {
      ...fliterData,
      name: val
    }
    props.search(fliterData);
  }
  
  // 筛选会员等级
  const onLevelChange = (value: any) => {
    fliterData = {
      ...fliterData,
      status: value
    }
    props.search(fliterData);
  }

  return (
    <Row type="flex">
      <Row>
        代金券名称：
        <Search
          placeholder="请输入代金券名称"
          onSearch={searchKey}
          allowClear
          style={{ width: 200, marginRight: 20 }}
        />
      </Row>
      
      <Row style={{ marginRight: 20 }}>
        状态：
        <Select
          allowClear
          showSearch
          style={{ width: 200 }}
          placeholder="代金券状态"
          optionFilterProp="children"
          onChange={onLevelChange}
          filterOption={(input, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=0 }
        >
          <Option value={2}>启用</Option>
          <Option value={0}>停用</Option>
          <Option value={4}>到期结束</Option>
        </Select>
      </Row>
      <Row>
        创建时间：
        <RangePicker onChange={dateChange} placeholder={['开始时间', '结束时间']} />
      </Row>
    </Row>
  );
};

export default VoucherFilterRow;
