import React, { useEffect, useState } from 'react'
import { Button, Table } from 'antd'
import './guide.scss'

const WechatSmsGuide = ()=>{

    return(
        <div className="wechat_sms_guide l_content">
            <div className="title_text">
                操作指引
            </div>
            <div className="step_title mb20">1、登录微信公众平台，从我的模板库中进行添加消息模板</div>
            <div className="step_img mb20">
                <img src={require('../../../assret/images/wechatSmsGuide/step1.png')} />
            </div>
            <div className="step_title mb20">2、搜索，系统提供模板标题，找到相应模板编号，标题，查看详情，进行添加</div>
            <div className="step_img mb20">
                <img src={require('../../../assret/images/wechatSmsGuide/step2.png')} />
            </div>
            <div className="step_title mb20">3、添加模板消息</div>
            <div className="step_img mb20">
                <img src={require('../../../assret/images/wechatSmsGuide/step3.png')} />
            </div>
            <div className="step_title mb20">4、在我的模板库中，找到相应的模板标题，复制模板ID至系统内</div>
            <div className="step_img mb20">
                <img src={require('../../../assret/images/wechatSmsGuide/step4.png')} />
            </div>
        </div>
    )
}

export default WechatSmsGuide