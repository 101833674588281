import React, { useEffect, useState } from "react";
import { Divider, Table, Card, Button, Modal, Input, Row, Col, InputNumber, message } from 'antd';
import EditModal from './modal/edit'
import DelayModal from './modal/delay'
import LineUpModal from './modal/lineUp'
import { postRequest, getRequest } from '../../api/http';
import { apiUrl } from '../../api/api_url';

import './list.scss'
import { getIsPermisson, messageCustom } from "../../utils/permisstionUtil";

const QueueList = ()=>{

    const [ page, setpage ] = useState(1)
    // const [ page_size, setpage_size ] = useState(10)
    // const [ count, setCount ] = useState();                  // 总数量
    const [ editFlag, setEditFlag] = useState(false)  //编辑弹窗
    const [ lineUpFlag, setLineUpFlag] = useState(false) //排队号码设置弹窗
    const [ delayFlag, setDelayFlag] = useState(false) //过号弹窗(延桌设置)
    const [ dataSource, setDataSource ] = useState([])
    const [ currentTable, setCurrentTable] = useState({}) //保存当前桌形信息

    useEffect(()=>{
        getList()
    },[])

    const columns = [
        {
            title: '桌形',
            dataIndex: 'type',
        },
        {
            title: '人数',
            dataIndex: 'min_num',
            render: (text: any, record: any,) => record.min_num+'~'+record.max_num
        },
        {
            title: '叫号标志',
            dataIndex: 'tag',
        },
        {
            title: '操作',
            dataIndex: 'action',
            render: (text: any, record: any,) => (
                <div className="btn_wrap">
                    <div className="list_btn" onClick={()=>{editTable(record)}}>编辑</div>
                    <Divider type="vertical" />
                    <div className="list_btn" onClick={()=>{changeTablesStatus(record.id,record.is_disabled)}}>{record.is_disabled==1?'启用':'禁用'}</div>
                </div>
            ),
        },
    ]

    //编辑
    const editTable = (record:object) => {
        if(!getIsPermisson('2_6_3')){
            messageCustom();
            return;
          }
        setCurrentTable(record)
        setEditFlag(true)
    }

    //禁用
    const changeTablesStatus = (id:number,is_disabled:number) => {
        if(!getIsPermisson('2_6_4')){
            messageCustom();
            return;
          }
        const param = {
            id,
            status: is_disabled == 1 ? 0 : 1
        }
        const title = is_disabled == 1 ? '启用' : '禁用'
        Modal.confirm({
            title: title,
            content: '是否确认'+title+'该桌形？',
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                postRequest(apiUrl.changeTableStatus,param).then((res:any)=>{
                    console.log(res)
                    if(res.code==1){
                        setDataSource(res.data)
                        getList()
                    }else{
                        message.error(res.message?res.message:title+'失败')
                    }
                }).catch((err)=>{
                    message.error('err:'+err)
                })
            },
            onCancel() {

            },
        });
    }

    const pageChange = (e: any) => {
        setpage(e)
        console.log('pagechange', e)
        getList()
    }

    const getList = ()=>{
        getRequest(apiUrl.getTableList).then((res:any)=>{
            console.log(res)
            if(res.code==1){
                setDataSource(res.data)
            }
        })
    }

    return (
        <div className="queue-list l_content">
            <Card bordered={false} title={'排队管理'}>
                <Button type="primary" className="mr20" onClick={()=>{
                    if(!getIsPermisson('2_6_1')){
                        messageCustom();
                        return;
                      }
                    setDelayFlag(true)
                }}>过号设置</Button>
                <Button type="primary" onClick={()=>{
                     if(!getIsPermisson('2_6_2')){
                        messageCustom();
                        return;
                      }
                    setLineUpFlag(true)}
                    }>排队号码设置</Button>
            </Card>
            <Table dataSource={dataSource} size="middle" rowKey={'id'} columns={columns} pagination={{
                showQuickJumper: true,
                defaultCurrent: 1,
                // total: count,
                // pageSize: page_size,
                hideOnSinglePage: true,
                onChange: pageChange
            }} />
            {
                editFlag&&<EditModal editFlg={editFlag} onClose={()=>{setEditFlag(false)}} currentTable={currentTable} getList={()=>{getList()}} dataSource={dataSource}/>
            }
            {
                delayFlag&&<DelayModal delayFlg={delayFlag} onClose={()=>{setDelayFlag(false)}}/>
            }
            {
                lineUpFlag&&<LineUpModal lineUpFlag={lineUpFlag} onClose={()=>{setLineUpFlag(false)}}/>
            }
        </div>
    )
}

export default QueueList