import {observer} from "mobx-react";
import React from "react";
import {Button, Input, message, Row, Col, Modal, Radio, Icon, Table, DatePicker} from "antd";
import { postRequest, getRequest } from '../../api/http';
import { RangePickerValue } from "antd/lib/date-picker/interface";
import BraftEditor from 'braft-editor'
// 引入编辑器样式
import 'braft-editor/dist/index.css'
import { apiUrl } from '../../api/api_url';
const { RangePicker } = DatePicker;

const key = 'NewPeopleEdit';

@observer
class NewPeopleEdit extends React.Component<any> {

    columnNum:any = [
        {
            title: '优惠券名称',
            dataIndex: 'name',
            key: 'name',
            align: "center",
        },
        {
            title: '券类型',
            dataIndex: 'type',
            key: 'type',
            align: "center",
            render: (text:any,record:any,) => (
                <div className="color">{record.info.type === 'discount' ? '折扣券' : record.info.type === 'voucher' ? '优惠券' : '兑换券'}</div>
            ),
        },
        {
            title: '券有效期',
            dataIndex: 'created_at',
            key: 'created_at',
            align: "center",
            render: (text:any,record:any,) => {
                let info:any = record.expire_info;
                return <div className="color">{info.type === 'time' ?  info.start_time + '至' + info.end_time : '自领取日'+info.day + '天领取'}</div>
            }
        },
        {
            title: '券份数',
            dataIndex: 'num',
            key: 'num',
            align: "center",
            render: (text:any,record:any,) => {
                return <div className="back_wrap">
                        {/* <div className="back_btn reduce_btn active" onClick={() => this.reduceCoupon(record.id)}>-</div> */}
                        <div className="good_num">{record.coupon_num}</div>
                        {/* <div className="back_btn add_btn active" onClick={() => this.addCoupon(record.id)}>+</div> */}
                    </div>
            }
        },
        {
            title: '操作',
            align: "center",
            render: (text: string,record:any,index:number)=>(
                <div className="table_action" onClick={() => this.unBindShop(index)}>移除</div>
            )
        },
    ];

    state = {
        editorState: BraftEditor.createEditorState(null),
        loading: false,
        name: '',
        status: 1,
        visible: false,
        couponList: [],
        listNum: 0,
        current: 1,
        selectCoupon: [],
        type: 'new_people',
        selectDisabled: false,
        typeName: '',
        user_type: [],
        pageSize: 100,
        selectList: [],
        start_time: '',
        end_time: '',
        position: 1,
        greeting: '',
        channel: 1,
        is_bothway: false,
        edit: false,
        groupList: [],
        data: [],
        value: [],
        fetching: false,
        invitation_type: 'all',
        group_id: [],
        fans_id: []
    }

    lastFetchId = 0;

    componentDidMount () {
        const {location} = this.props;
        if(location.state !== undefined){
            this.initEdit(location.state.id);
        }
        this.onInitList();
        this._initGroup();
    }

    // 初始化优惠券列表
    onInitList = async () => {
        let param = {
            current: 1,
            pageSize: 100,
            position: 3
        }
        const res:any = await getRequest(apiUrl.couponList, param);
        if(res.code === 1){
            this.setState({
                couponList: res.data.list,
                listNum: res.data.total,
                current: 1,
                selectList: res.data.list.slice(0, 10)
            })
        }
    }

    // 初始化编辑
    initEdit = async (id:number) => {
        const params = {
            id: id
        };
        const res:any = await getRequest(apiUrl.welfareEditInfo,params);
        if(res.code === 1){
            this.setState({
                edit: true,
                name: res.data.name,
                status: res.data.status,
                selectCoupon: res.data.coupon,
                // greeting: res.data.greeting,
                // position: res.data.position,
                // channel: res.data.channel,
                editorState: BraftEditor.createEditorState(res.data.tips),

            })
            if(res.data.expire_info){
                this.setState({
                    start_time: res.data.expire_info.start_time,
                    end_time: res.data.expire_info.end_time,
                })
            }
            // if(res.data.user_type){
            //     this.setState({
            //         user_type: res.data.user_type,
            //     })
            // }
            // if(res.data.is_bothway){
            //     this.setState({
            //         is_bothway: res.data.is_bothway
            //     })
            // }
            // if(res.data.channel == 2){
            //     this.setState({
            //         invitation_type: res.data.invitation_type
            //     })
            //     if(res.data.invitation_type == 'group'){
            //         this.setState({
            //             group_id: res.data.invitation_target_id
            //         })
            //     }
            //     if(res.data.invitation_type == 'designated_user'){
            //         this.setState({
            //             fans_id: res.data.invitation_target_id
            //         })
            //     }
            // }
        }
    }

    addCoupon = (id: any) => {
        let selectCoupon = this.state.selectCoupon;
        let { edit } = this.state;
        if(edit){
            return;
        }
        selectCoupon.map((item: any) => {
            if(item.id === id){
                item.coupon_num++;
            }
        })
        this.setState({
            selectCoupon
        })
    }

    reduceCoupon = (id: any) => {
        let { edit } = this.state;
        if(edit){
            return;
        }
        let selectCoupon = this.state.selectCoupon;
        selectCoupon.map((item: any) => {
            if(item.id === id){
                if(item.coupon_num === 1){
                    return;
                }
                item.coupon_num--;
            }
        })
        this.setState({
            selectCoupon
        })
    }

    nameChange = (e:any) => {
        let name = e.target.name;
        let value = e.target.value;
        this.setState({
            [name]: value
        })
    }

    onCheck = (checkedKeys: any, info: any) => {
        let keyList = checkedKeys;
        let user_type: any[] = [];
        keyList.map((item: any) => {
            if(item !== '4'){
                user_type.push(item)
            }
        })
        this.setState({
            user_type
        })
    };

    handleSubmit = async () => {
        message.loading({ content: '发布中', key });

        const {location} = this.props;
        const { group_id, fans_id, is_bothway, name, type, status, selectCoupon, user_type,start_time, end_time, position, greeting, channel, invitation_type } = this.state;
        var tableInfo:any = {};
        let coupon:any[] = [];

        if(name === ''){
            message.warning({ content: '请输入活动名称!', key, duration: 2 });
            return;
        }

        if(start_time === ''){
            message.warning({ content: '请选择活动时间!', key, duration: 2 });
            return;
        }

        tableInfo.name = name;
        tableInfo.expire_info = {
            type: 'time',
            start_time,
            end_time
        }
        tableInfo.status = status;

        if(location.state !== undefined){
            tableInfo.id = location.state.id;
        }else{
            if(selectCoupon.length === 0){
                message.warning({ content: '请关联优惠券!', key, duration: 2 });
                return;
            }

            // if(channel == 1 && user_type.length === 0){
            //     message.warning({ content: '请选择可见用户!', key, duration: 2 });
            //     return;
            // }

            // if(greeting === ''){
            //     message.warning({ content: '请输入弹窗欢迎词!', key, duration: 2 });
            //     return;
            // }
        }

        tableInfo.type = type;
        // tableInfo.position = position;
        // tableInfo.greeting = greeting;
        // tableInfo.channel = channel;
        // if(channel == 1){
        //     tableInfo.user_type = user_type;
        // }else{
        //     tableInfo.is_bothway = is_bothway;
        //     tableInfo.invitation_type = invitation_type
        //     console.log('提交', invitation_type, group_id, fans_id);
        //     if(invitation_type == 'group'){
        //         tableInfo.invitation_target_id = group_id
        //     }
        //     if(invitation_type == 'designated_user'){
        //         tableInfo.invitation_target_id = fans_id
        //     }
        // }


        const htmlContent = this.state.editorState.toHTML();
        tableInfo.tips = htmlContent;

        selectCoupon.forEach((item:any) => {
            if(item.limit == undefined){
                item.limit = '';
            }
            coupon.push({
                id: item.id,
                limit: item.limit,
                coupon_num: item.coupon_num,
                people_num: item.people_num
            })
        })

        tableInfo.coupon = coupon;

        if(location.state !== undefined){
            const res:any = await postRequest(apiUrl.welfareEdit,tableInfo);
            if(res['code'] === 1){
                message.success({ content: '修改成功!', key, duration: 2 });
                this.goBack()
            }else{
                message.error({ content: '修改失败!', key, duration: 2 });
            }
        }else{
            const res:any = await postRequest(apiUrl.welfareAdd,tableInfo);
            if(res['code'] === 1){
                message.success({ content: '发布成功!', key, duration: 2 });
                this.goBack()
            }else{
                message.error({ content: '发布失败!', key, duration: 2 });
            }
        }
    };

    // 返回上一页
    goBack = () => {
        this.props.history.goBack();
    }

    changePage = async (page: number) => {
        let { couponList } = this.state;
        let list = couponList.slice((page-1)*10, (10*page));
        this.setState({
            selectList: list,
            current: page
        })
    }

    // 关联
    handleBindShop = (id:number) => {
        let { type } = this.state;
        let selectCoupon:any[] = this.state.selectCoupon;
        let couponList:any[] = this.state.couponList;
        couponList.map((item) => {
            if(item.id === id){
                item.selected = true;
                item.coupon_num = 1;
                selectCoupon.push(item)
            }
        })
        console.log(selectCoupon);
        this.setState({
            selectCoupon,
            couponList
        })
    }

    // 解除关联
    unBindShop = (index: number) => {

        let { couponList,edit } = this.state;
        if(edit){
            return;
        }
        let selectCoupon: any = this.state.selectCoupon;
        couponList.map((item: any) => {
            if(item.id === selectCoupon[index].id){
                item.selected = false;
            }
        })
        selectCoupon.splice(index, 1)
        this.setState({
            couponList,
            selectList: couponList.slice(0, 10),
            current: 1,
            selectCoupon
        })
    }

    limitChange = (e:any,index:any) => {
        let selectCoupon:any = this.state.selectCoupon;
        if(e){
            selectCoupon[index].limit = 'zhuli';
        }else{
            selectCoupon[index].limit = '';
        }

        this.setState({
            selectCoupon
        })
    }

    // 助力人数
    numChange = (e: any,index:number) => {
        let selectCoupon:any = this.state.selectCoupon;

        selectCoupon[index].people_num = e;

        this.setState({
            selectCoupon
        })
    }

    handleOk = () => {
        this.setState({
            visible: false
        })
    }

    // 创建 商品列表
    columns:any = [
        {
            title: '优惠券名称',
            dataIndex: 'name',
            key: 'name',
            align: "center",
        },
        {
            title: '券类型',
            dataIndex: 'type',
            key: 'id',
            align: "center",
            render: (text:any,record:any,) => (
                <div className="color">{record.info.type === 'discount' ? '折扣券' : record.info.type === 'voucher' ? '优惠券' : '兑换券'}</div>
            ),
        },
        {
            title: '券有效期',
            dataIndex: 'created_at',
            key: 'created_at',
            align: "center",
            render: (text:any,record:any,) => {
                let info:any = record.expire_info;
                return <div className="color">{info.type === 'time' ?  '自'+info.start_time + '至' + info.end_time : '自领取日'+info.day + '天内有效'}</div>
            }
        },
        {
            title: '操作',
            align: "center",
            render: (text: string,record:any,index:number)=>{
                return <div>
                    {
                        record.selected
                        ? '已关联'
                        : <div className="table_action" onClick={() => this.handleBindShop(record.id)}>关联</div>
                    }
                </div>
            }
        },
    ];

    handleEditorChange = (editorState:any) => {
        this.setState({ editorState })
    }

    // 显示关联
    showTable = () => {
        let { couponList, selectCoupon, edit} = this.state;
        if(edit){
            return;
        }
        couponList.map((coupon: any) => {
            selectCoupon.map((select: any) => {
                if(coupon.id === select.id){
                    coupon.selected = true;
                }
            })
        })
        this.setState({
            couponList,
            selectList: couponList.slice(0, 10),
            current: 1,
            visible: true
        })
    }

    timeChange = (time: RangePickerValue, timeString: [string, string]) => {
        this.setState({
            start_time: timeString[0],
            end_time: timeString[1]
        })
    }

    // 分组列表
    _initGroup = async () => {
        const res:any = await getRequest(apiUrl.memberGroup);
        if(res.code === 1){
            this.setState({
                groupList: res.data
            })
        }
    }

    // 用户分组
    groupChange = (e :any) => {
        this.setState({
            group_id: e
        })
    }

    fetchUser = async (value:any) => {
        this.lastFetchId += 1;
        const fetchId = this.lastFetchId;
        this.setState({ data: [], fetching: true });

        const res:any = await getRequest(apiUrl.getMemberList, {keywords: value, page_size: 100});
        if(res.code === 1){
            if (fetchId !== this.lastFetchId) {
                return;
            }
            this.setState({ data: res.data.list, fetching: false });
        }
      };

      handleChange = (value: any) => {
        console.log(value);
        let fans_id:any = [];
        if(value.length > 0){
            value.map((item:any) => {
                fans_id.push(item.key);
            })
        }
        this.setState({
          value,
          fans_id,
          data: [],
          fetching: false,
        });
      };

    render(){
        const {is_bothway, channel, position, editorState, name, status, visible, selectCoupon, user_type, selectList, current, listNum, start_time, end_time, greeting } = this.state;
        const { groupList, data, value, fetching, invitation_type } = this.state;
        return <div className='l_content'>
                <div className="edit_title">活动基本信息</div>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">活动名称</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <Input placeholder="请输入活动名称" name='name' value={name} onChange={(e) => this.nameChange(e)}/>
                    </Col>
                </Row>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">活动时间</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <RangePicker onChange={this.timeChange} format="YYYY-MM-DD" placeholder={start_time !== '' ? [start_time, end_time] :["开始时间","结束时间"]} />
                    </Col>
                </Row>

                {/* <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">推广渠道</span>
                        </div>
                    </Col>
                    <Col span={22}>
                        <Radio.Group
                            name='channel'
                            value={channel}
                            onChange={(e) => this.nameChange(e)}
                            disabled={this.state.edit}
                        >
                            <Radio value={1}>自然流量</Radio>
                            <Radio value={2}>邀请</Radio>
                        </Radio.Group>
                    </Col>
                </Row> */}

                {/* {
                    channel == 2
                    ? <Row className="mb30" type="flex" align="middle">
                        <Col span={2}>
                            <div>
                                <span className="star"></span>
                                <span className="color_black">邀请特权</span>
                                <Tooltip placement="topLeft" title={"邀请特权，指定某些人邀请用户才能获得奖励"}>
                                    <Icon type="question-circle" theme="filled" />
                                </Tooltip>
                            </div>
                        </Col>
                        <Col span={22}>
                            <Radio.Group
                                name='invitation_type'
                                value={invitation_type}
                                onChange={(e) => this.nameChange(e)}
                                disabled={this.state.edit}
                            >
                                <Radio value={'all'}>所有人</Radio>
                                <Radio value={'group'}>指定用户分组</Radio>
                                <Radio value={'designated_user'}>指定用户</Radio>
                            </Radio.Group>
                        </Col>
                    </Row>
                    : null
                } */}

                {/* {
                    invitation_type == 'group'
                    ? <div>
                        <Row className="mb30" type="flex" align="middle">

                            <Col span={2}>
                                <div>
                                    <span className="star"></span>
                                    <span className="color_black">发放用户分组</span>
                                </div>
                            </Col>
                            <Col span={10}>
                                <Select placeholder='请选择用户分组' mode="multiple" disabled={this.state.edit} style={{ width: '100%' }} onChange={(e) => this.groupChange(e)} optionFilterProp="children">
                                    {
                                        groupList.map((item: any) => {
                                            return <Option value={item.id} key={item.id}>{item.name}</Option>
                                        })
                                    }
                                </Select>
                            </Col>
                        </Row>

                    </div>
                    :  invitation_type == 'designated_user' ? <Row className="mb30" type="flex" align="middle">
                            <Col span={2}>
                                <div>
                                    <span className="star"></span>
                                    <span className="color_black">发放用户</span>
                                </div>
                            </Col>
                            <Col span={6}>
                                <Select
                                    placeholder='请选择用户'
                                    showSearch
                                    mode="multiple"
                                    labelInValue
                                    value={value}
                                    notFoundContent={fetching ? <Spin size="small" /> : null}
                                    filterOption={false}
                                    onSearch={this.fetchUser}
                                    onChange={this.handleChange}
                                    style={{ width: '100%' }}
                                    disabled={this.state.edit}
                                    >
                                    {
                                        data.map((item:any) => {
                                            return <Option value={item.id} key={item.id}>{item.nickname}</Option>
                                        })
                                    }
                                </Select>
                            </Col>
                        </Row>
                        : null
                } */}

                {/* {
                    channel == 2
                    ? <Row className="mb30" type="flex" align="middle">
                        <Col span={2}>
                            <div>
                                <span className="star"></span>
                                <span className="color_black">领券方式</span>
                            </div>
                        </Col>
                        <Col span={22}>
                            <Radio.Group
                                name='is_bothway'
                                value={is_bothway}
                                onChange={(e) => this.nameChange(e)}
                                disabled={this.state.edit}
                            >
                                <Radio value={false}>仅受邀请人获得</Radio>
                                <Radio value={true}>邀请人和受邀请人同时获得</Radio>
                            </Radio.Group>
                        </Col>
                    </Row>
                    : null
                } */}

                {/* {
                    channel == 1
                    ? <Row className="mb30" type="flex" align="top">
                            <Col span={2}>
                                <div>
                                    <span className="star"></span>
                                    <span className="color_black">参与对象</span>
                                </div>
                            </Col>
                            <Col span={6}>
                                <Tree
                                    checkable
                                    showIcon
                                    onCheck={this.onCheck}
                                    defaultSelectedKeys={user_type}
                                    checkedKeys={user_type}
                                    disabled={this.state.edit}
                                >
                                    <TreeNode title="新用户" key="1" icon={
                                        <Tooltip placement="topLeft" title={"还未注册的用户或已经注册还没有下单的用户"}>
                                        <Icon type="question-circle" />
                                        </Tooltip>
                                    }>
                                    </TreeNode>
                                    <TreeNode title="老顾客" key="4" icon={
                                        <Tooltip placement="topLeft" title={"交易次数>=1次的交易次数"}>
                                        <Icon type="question-circle" />
                                        </Tooltip>
                                    }>
                                        <TreeNode title="充值用户" key="2" />
                                        <TreeNode title="非充值用户" key="3" />
                                    </TreeNode>
                                </Tree>
                            </Col>
                        </Row>
                    : null
                } */}


                {/* <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">推送页面</span>
                        </div>
                    </Col>
                    <Col span={22}>
                        <Radio.Group
                            name='position'
                            value={position}
                            onChange={(e) => this.nameChange(e)}
                            disabled={this.state.edit}
                        >
                            <Radio value={1}>首页</Radio>
                            <Radio value={2}>点餐页面</Radio>
                            <Radio value={3}>领券中心</Radio>
                        </Radio.Group>
                    </Col>
                </Row> */}

                <div className="wrap">
                    <Row>
                        <Col span={2}>
                            <div>
                                <span className="star"></span>
                                <span className="color_black">使用须知</span>
                            </div>
                        </Col>
                        <Col span={14}>
                            <div className="editor_wrap">
                                <BraftEditor
                                    value={editorState}
                                    onChange={this.handleEditorChange}
                                    excludeControls={['media']}
                                    readOnly={this.state.edit}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">活动礼品</span>
                        </div>
                    </Col>
                    <Col span={16} style={{ filter: `${this.state.edit ? 'grayscale(100%)' : ''}` }}>
                        <div onClick={this.showTable}>
                             <Icon type="plus-circle" style={{fontSize: '20px',marginRight: '10px'}} />
                             关联优惠券
                        </div>

                        {
                            selectCoupon.length > 0
                            ? <Table
                                size="middle"
                                rowKey={"id"}
                                dataSource={selectCoupon}
                                columns={this.columnNum}
                                style={{marginTop: '20px'}}
                                pagination={
                                    {
                                        hideOnSinglePage: true,
                                        total: selectCoupon.length,
                                    }
                                }
                            />
                            : null
                        }

                    </Col>
                </Row>
                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">状态</span>
                        </div>
                    </Col>
                    <Col span={22}>
                        <Radio.Group
                            name='status'
                            value={status}
                            onChange={(e) => this.nameChange(e)}
                        >
                            <Radio value={1}>开启</Radio>
                            <Radio value={0}>暂停</Radio>
                        </Radio.Group>
                    </Col>
                </Row>

                {/* <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">弹窗欢迎词</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <Input placeholder="请输入弹窗欢迎词" disabled={this.state.edit} name='greeting' value={greeting} onChange={(e) => this.nameChange(e)}/>
                    </Col>
                </Row> */}

                <Row>
                    <Col span={12}>
                        <div className="bottom_wrap">
                            <Button type="primary" ghost onClick={this.goBack}>
                                取消
                            </Button>
                            <Button type="primary" onClick={this.handleSubmit}>
                                保存
                            </Button>
                        </div>
                    </Col>
                </Row>

            <Modal
                title={null}
                visible={visible}
                onOk={this.handleOk}
                onCancel={()=>{this.setState({visible: false})}}
                width="70vw"
            >
                <div className="model_head">
                    <div className="head_title">关联优惠券</div>
                </div>
                <Table
                    size="middle"
                    rowKey={"id"}
                    dataSource={selectList}
                    columns={this.columns}
                    pagination={
                        {
                            current,
                            total: listNum,
                            onChange: this.changePage,
                            pageSize: 10
                        }
                    }
                />
            </Modal>

        </div>;
    }

}
export default NewPeopleEdit