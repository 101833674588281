import React, { useEffect, useState } from "react";
import { Modal, Input, Row, Col, Switch, Select, message } from 'antd';
import { postRequest, getRequest } from '../../../api/http';
import { apiUrl } from '../../../api/api_url';

import './delay.scss'

const { Option } = Select;

type Eprops = {
    delayFlg: boolean,
    onClose(): void,
}

const DelayModal = (props:Eprops)=>{

    const { delayFlg, onClose } = props
    const [ confirmLoading, setConfirmLoading] = useState(false) //过号弹窗(延桌设置)
    const [ info, setInfo] = useState<any>({}) //过号弹窗(延桌设置)

    var tables:number[] = new Array(10)
    var tablesLast:number[] = new Array(20)
    useEffect(()=>{
        init()
    },[])

    const init = async() => {
        const res:any = await getRequest(apiUrl.getLineUpConfig)
        console.log(res)
        if( res.code == 1 ) {
            res.data.map((item:any)=>{
                info[item.key] = item.value[0]
            })
            setInfo(JSON.parse(JSON.stringify(info)))
            console.log(info)
        }
    }

    const handleOk = async()=>{

        let params:any = {}

        Object.keys(info).map((item)=>{
            if(item=='open_queueing_deferred'){
                params[item] = [info[item]==true?1:0]
            }else{
                params[item] = [info[item]]
            }
        })
        setConfirmLoading(true)
        const res:any = await postRequest(apiUrl.changeLineUpConfig,{data:JSON.stringify(params)})
        setConfirmLoading(false)
        if( res.code == 1 ){
            message.success('设置成功')
            onClose()
        }else{
            message.error(res.message||'设置失败')
        }

    }

    const onChangeSwitch = (e:any)=>{
        info.open_queueing_deferred = e
        setInfo(JSON.parse(JSON.stringify(info)))
    }

    const onChangeSelect = (e:any)=>{
        info.queueing_deferred = e
        setInfo(JSON.parse(JSON.stringify(info)))
    }

    const onChangeSelectLast = (e:any)=>{
        info.queueing_deferred_max = e
        setInfo(JSON.parse(JSON.stringify(info)))
    }

    return (
        <Modal
            title="过号设置"
            visible={delayFlg}
            onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={onClose}
            className="line-delay-modal"
        >
            <Row type="flex" align="middle" className="mb20">
                <Col span={10}>
                    <span>开启过号延桌功能</span>
                </Col>
                <Col span={14}>
                    <Switch checked={info.open_queueing_deferred==1?true:false} onChange={onChangeSwitch} />
                </Col>
            </Row>  
            {
                info.open_queueing_deferred==1&&(
                    <>
                        <Row type="flex" align="middle" className="mb20">
                            <Col span={10}>
                                <span>过号后顺延桌数</span>
                            </Col>
                            <Col span={14}>
                                <Select style={{ width: 120 }} onChange={onChangeSelect} value={info.queueing_deferred}>
                                    {
                                        tables.toString().split(',').map((item,index)=>{
                                            return <Option value={(index+1)+''} key={index}>{index+1}桌</Option>
                                        })
                                    }
                                </Select>                    
                            </Col>
                        </Row>  
                        <Row type="flex" align="middle" className="mb20">
                            <Col span={10}>
                                <span>过号可延桌最多过号数</span>
                            </Col>
                            <Col span={14}>
                                <Select value={info.queueing_deferred_max} style={{ width: 120 }} onChange={onChangeSelectLast}>
                                    <Option value={'0'}>不限</Option>
                                    {
                                        tablesLast.toString().split(',').map((item,index)=>{
                                            return <Option value={(index+1)+''} key={index}>{index+1}桌</Option>
                                        })
                                    }
                                </Select> 
                            </Col>
                        </Row>  
                    </>
                )
            }
            <div className="tip">
                <h3>功能说明</h3>
                <p>设置过号后顺延桌号后，当该排号过号后，撤销此号码时，会插入当前叫号后顺延桌数的对应位置。</p>
                <p>当已过号的排号已经超过过号可延桌最多过号数，此号码则并不可以再进行撤销操作。</p>
            </div>
        </Modal>        
    )
}

export default DelayModal