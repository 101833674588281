import { Input, message, Spin,Upload} from 'antd';
import React, { useState } from 'react'

import { useEffect } from "react";
import { postRequest,getRequest } from '../../../../../api/http';
import HeadNav from '../component/headNav/headNav';
import SliderBar from '../component/sliderBar/sliderBar';
import StyleSetting from '../component/styleSetting/styleSetting';
import {printData} from  '../../../../../utils/print_data';
import { apiUrl } from '../../../../../api/api_url';

import './editTicket.scss'
import { useHistory } from 'react-router-dom';


const QINIU_SERVER = "https://up-z2.qiniup.com";  // 七牛上传地址

const EditTicket = (props) =>{
    const history = useHistory();
    // const currentIndex = 5;
    // const [sliders,setSliders] = useState(printData[`sliders${currentIndex}`]);

    // const [divArr,setDivArr] = useState(printData[`divArr${currentIndex}`]);

    const [loading,setLoading] = useState(false);

    const [hoverkey,setHoverKey] = useState('');  // 鼠标当前hover的key

    const [currentKey,setCurrentKey] = useState(''); // 当前点击的key
    const [currentItem,setCurrentItem] = useState(null); // 当前点击的对象
    const [qiniuParam,setQiniuParam] = useState({token:'',imageKey:''});

 
    const [sliders,setSliders] = useState([]);

    const [divArr,setDivArr] = useState([]);
    const [type,setType] = useState(-1);
    const [name,setName] = useState('');


    useEffect(() =>{
      
        initComState();
    },[])

    const initComState =() =>{
        let routerParams = props.location.state.routerParams;
        if(routerParams){
            setSliders(JSON.parse(routerParams.show)  ?? [])
            setDivArr(JSON.parse(routerParams.style)  ?? [])
            setType(routerParams.type)
            setName(routerParams.name)
        }else{
            message.error("缺少必传参数")
            history.goBack();
        }

    }


    // 模块控制回调
    const callBackSlider = (arrSlider) =>{
        let arr = JSON.parse(JSON.stringify(arrSlider))
        setSliders(arr)
    }

    // 样式控制回调 FONT 设置字体 DIVID 分割线 EMPTY 设置空行
    const callBackStyle = (type,obj) =>{
        let arr = JSON.parse(JSON.stringify(divArr));
        for(var i = 0;i<arr.length;i++){
            let item = arr[i];
            if(item.key == obj.key){
                if(type == 'FONT') item.detail.size = obj.size;
                if(type == 'DIVID') item.divingLine = obj.divingLine;
                if(type == 'EMPTY') item.EmptyLine = obj.EmptyLine
                break;
            }else if(item.type == 'TABLE'){
                if(item.detail.coloums.key == obj.key){ // 设置表头
                    if(type == 'FONT') item.detail.coloums.size = obj.size;
                    if(type == 'DIVID') item.detail.coloums.divingLine = obj.divingLine;
                    if(type == 'EMPTY') item.detail.coloums.EmptyLine = obj.EmptyLine;
                    break;
                }else if(item.detail.data.key == obj.key){
                    if(type == 'FONT') item.detail.data.size = obj.size;
                    if(type == 'DIVID') item.detail.data.divingLine = obj.divingLine;
                    if(type == 'EMPTY') item.detail.data.EmptyLine = obj.EmptyLine;
                    break;
                }
                let attrs = item.detail.data.values[0].attrs ?? null;
                if(attrs && attrs.key == obj.key){
                    if(type == 'FONT') item.detail.data.values[0].attrs.size = obj.size;
                    if(type == 'DIVID') item.detail.data.values[0].attrs.divingLine = obj.divingLine;
                    if(type == 'EMPTY') item.detail.data.values[0].attrs.EmptyLine = obj.EmptyLine;
                    break;
                }

            }else if(item.type == 'SETTLEMENT_TABLE'){
                if(item.detail.coloums.key == obj.key){ // 设置表头
                    if(type == 'FONT') item.detail.coloums.size = obj.size;
                    if(type == 'DIVID') item.detail.coloums.divingLine = obj.divingLine;
                    if(type == 'EMPTY') item.detail.coloums.EmptyLine = obj.EmptyLine;
                    break;
                }
                let tempData = item.detail.data ?? [];
                let _index = tempData.findIndex(_item => _item.key == obj.key );
                if(_index != -1){
                    if(type == 'FONT') item.detail.data[_index].size = obj.size;
                    if(type == 'DIVID') item.detail.data[_index].divingLine = obj.divingLine;
                    if(type == 'EMPTY') item.detail.data[_index].EmptyLine = obj.EmptyLine;
                }

            }
        }
        setDivArr(arr);
        
    }

    // 点击布局修改样式
    const handleStyleClick = (item,type = '') => {
       let key = '';
       var obj = {
        size: 3,
        EmptyLine: 0,
        divingLine: false,
        key: ''
       }
        if(type == 'HEAD'){
            key = item.detail.coloums.key;
            obj.size = item.detail.coloums.size;
            obj.EmptyLine = item.detail.coloums.EmptyLine;
            obj.divingLine = item.detail.coloums.divingLine;
            obj.key = item.detail.coloums.key;
            
        }else if(type == 'VALUE'){
            key = item.detail.data.key;
            obj.size = item.detail.data.size;
            obj.EmptyLine = item.detail.data.EmptyLine;
            obj.divingLine = item.detail.data.divingLine;
            obj.key = item.detail.data.key;
        }else if(type == 'D_VALUE'){
            key = item.key;
            obj.size = item.size;
            obj.EmptyLine = item.EmptyLine;
            obj.divingLine = item.divingLine;
            obj.key = item.key;
        }else if(type == 'ATTRIBUTE'){
            key = item.attrs.key;
            obj.size = item.attrs.size;
            obj.EmptyLine = item.attrs.EmptyLine;
            obj.divingLine = item.attrs.divingLine;
            obj.key = item.attrs.key;
        }else if(type == 'UPLOAD'){
           
        }else{
            key = item.key;
            obj.size = item.detail.size;
            obj.EmptyLine = item.EmptyLine;
            obj.divingLine = item.divingLine;
            obj.key = item.key;
        }
        if(key == currentKey){
            setCurrentKey('')
            setCurrentItem(null)
        }else{
            setCurrentKey(key)
            setCurrentItem(obj)
        }
        
    }

    const hoverFun = (key) => setHoverKey(key);

   

    // 查找当前布局隐藏/显示
    const findHideSet = (key) =>{
        var find_item = null;
        sliders.map(item =>{
            item.child.map(c_item =>{
                if(c_item.key == key){
                    find_item = c_item;
                    return;
                }
            })
            if(find_item != null) return;
        })

        if(find_item){
            return find_item.hide;
        }
        return false;
    }

    // 获取自定义文字的值
    const getCustomText = () =>{
        let str = '';
        divArr.map(item =>{
            if(item.key == 'CUSTOM_TEXT'){
                str = item.detail.hitText;
            }
        })

        return str;
    }

    // 设置自定义的值
    const handleCustom =(e) =>{
        let arr = JSON.parse(JSON.stringify(divArr))
        arr.map(item =>{
            if(item.key == 'CUSTOM_TEXT'){
                item.detail.hitText = e.target.value;
            }
        })
        setDivArr(arr)
    }

    // 自定义文字是否显示
    const findCustomSetting = () =>{
        let showInput = false;
        sliders.map(item => {
            let _childArr = item.child ?? [];
            _childArr.map(c_item => {
                if(c_item.key == 'CUSTOM_TEXT'){
                    showInput = !c_item.hide
                    return;
                }
            })
        })

        return showInput;
    }

     // 上传图片前
     const handleBeforeUpload = async (file) => {
         if(file.size / 1024 > 50){
             message.error('图片大小不超过50KB');
             return false;
         }else{
            const res = await getRequest(apiUrl.getUploadToken, {
                type: "poster"
            });
            if (res && res.data) {
                const key = `${Math.random().toString(36).slice(-8)}${new Date().valueOf()}.png`;
                let qiniuParamTemp = {};
                qiniuParamTemp.imageKey = key;
                qiniuParamTemp.token = res.data.upload_token;
                setQiniuParam(qiniuParamTemp);
            }
         }
    };

    // 海报改变监听
    const handleUploadChange = (e) => {
        if (e.file && e.file.status === "done") {
            let arr = JSON.parse(JSON.stringify(divArr))
            arr.map(item =>{
                if(item.key == 'CUSTOMER_LOGO'){
                    item.detail.url = e.file.response.url;
                }
            })
            setDivArr(arr)
        }
    };

    //恢复默认设置
    const recoveryDefalut = async () =>{
        setLoading(true)
        //设置默认值
        const res = await getRequest(apiUrl.getPrintStyleDefalut,{id: type})
        console.log(res)
        if(res.code == 1){
            setCurrentKey('');
            setCurrentItem(null);
            setSliders(JSON.parse(res.data.show))
            setDivArr(JSON.parse(res.data.style))
        }else{
            message.error(res.message ?? '服务器异常');
        }
        setLoading(false)
    }

    //放弃编辑并返回
    const cancel =() => history.goBack();

    // 提交修改
    const submit = async () =>{
        let url = '';
        if(!findHideSet('CUSTOMER_LOGO')){
            let customerLogo = divArr.find(item => item.key == 'CUSTOMER_LOGO');
            if(customerLogo && customerLogo.detail.url == ''){
                console.log(customerLogo)
                message.error('请上传自定义图片,或者移除');
                return;
            }else{
                url = customerLogo ? customerLogo.detail.url : '';
            }
        }
        if(findCustomSetting()){ // 自定义文字
            if(getCustomText() == ''){
                message.error('请输入自定义文字,或者移除');
                return;
            }
        }
    
        setLoading(true)
        let params = {
            type: type,
            // id: currentIndex,
            name: name,
            style: JSON.stringify(divArr),
            show: JSON.stringify(sliders),
            print_format: collectPrintData()
        }
        if(url != '') params.images = JSON.stringify([url]);
        let res = await postRequest(apiUrl.pringStyleEdit,params)
        // let res = await postRequest('v2/print_style/default_edit',params)
        setLoading(false)
        if(res.code == 1){
            message.success('编辑成功!')
        }else{
            message.error(res.message ?? '编辑失败,服务器异常')
        }
       
    }

    const getStrSize = (size) =>  size == 1 ? '1,1':size == 2 ? '1,2': size == 3 ? '2,1':'2,2';

    // 整理提交数据
    const collectPrintData = () =>{
        let endPringtData = [];
        for(let i = 0;i< divArr.length;i++){
            if(divArr[i].type == 'UPLOAD'){ // 上传图片
                if(!findHideSet(divArr[i].key)){
                    endPringtData.push({
                        type: 'image',
                        key: divArr[i].key,
                        value: divArr[i].detail.url,
                        content: '',
                        float: divArr[i].justifyContent,
                        line: divArr[i].divingLine,
                        space: divArr[i].EmptyLine
                    })
                }
            } else if(divArr[i].type == 'TEXT'){ // 普通文本
                if(!findHideSet(divArr[i].key)){
                    endPringtData.push({
                        type: 'text',
                        key:  divArr[i].key,
                        content: '',
                        value: '',
                        float: divArr[i].justifyContent,
                        size: getStrSize(divArr[i].detail.size),
                        line: divArr[i].divingLine,
                        space: divArr[i].EmptyLine
                    })
                }
            } else if(divArr[i].type == 'TABLE'){ // 表格
                let {coloums,data} = divArr[i].detail;
                // 处理表格头部
                let contents = []; // 整理后的列表头部
                coloums.values.map(item => {
                    if(!findHideSet(item.key)){
                        contents.push({
                            key: item.key,
                            content: item.title,
                        })
                    }
                })

                endPringtData.push({
                    type: 'table',
                    key:  coloums.key,
                    content: contents,
                    value: '',
                    float: 'start',
                    size: getStrSize(coloums.size),
                    line: coloums.divingLine,
                    space: coloums.EmptyLine
                })

                 // 处理列表数据
                 let attrs = '';
                 if(data.key == 'TABLE_VALUE'){
                    let attr = data.values[0].attrs;
                    if(!findHideSet(attr.key)){
                        attrs = {
                            type: 'text',
                            key: attr.key,
                            content: '',
                            value: '',
                            float: 'start',
                            size: getStrSize(attr.size),
                            line: false,
                            space: 0
                        }
                    }
                    
                }
                    endPringtData.push({
                        type: 'table',
                        key: data.key,
                        content: '',
                        value: '',
                        attr: attrs,
                        float: 'start',
                        size: getStrSize(data.size),
                        line: data.divingLine,
                        space: data.EmptyLine
                    })
            }else if(divArr[i].type == 'SETTLEMENT_TABLE'){
                let {coloums,data} = divArr[i].detail;
                if(!findHideSet(coloums.key)){
                      // 处理表格头部
                    let contents = []; // 整理后的列表头部
                    coloums.values.map((c_item,c_index) => {
                        contents.push({
                            key: coloums.key+'_'+(c_index+1),
                            content: c_item
                        })
                    })
                    endPringtData.push({
                        type: 'table',
                        key:  coloums.key,
                        content: contents,
                        value: '',
                        float: 'start',
                        size: getStrSize(coloums.size),
                        line: coloums.divingLine,
                        space: coloums.EmptyLine
                    })
                }

                data.map((c_item) =>{
                    if(!findHideSet(c_item.key)){
                        endPringtData.push({
                            type: 'table',
                            key: c_item.key,
                            content: '',
                            value: '',
                            float: 'start',
                            size: getStrSize(c_item.size),
                            line: c_item.divingLine,
                            space: c_item.EmptyLine
                        })
                    }
                })
            }else if(divArr[i].type == 'OTHER_TEXT'){ // 其他文本
                if(!findHideSet(divArr[i].key)){
                    endPringtData.push({
                        type: 'text',
                        key:  divArr[i].key,
                        content: '',
                        value: '',
                        float: divArr[i].justifyContent,
                        size: getStrSize(divArr[i].detail.size),
                        line:  divArr[i].divingLine,
                        space:  divArr[i].EmptyLine
                    })
                }
            }else if(divArr[i].type == 'TEXT_CUSTORM'){ //  自定义文本
                if(!findHideSet(divArr[i].key)){
                    endPringtData.push({
                        type: 'text',
                        key:  divArr[i].key,
                        content: '',
                        value: divArr[i].detail.hitText,
                        float: divArr[i].justifyContent,
                        size: getStrSize(divArr[i].detail.size),
                        line:  divArr[i].divingLine,
                        space:  divArr[i].EmptyLine
                    })
                }
                
            }else if(divArr[i].type == 'QRCODE'){
                if(!findHideSet(divArr[i].key)){
                    endPringtData.push({
                        type: 'image',
                        key:  divArr[i].key,
                        content: '',
                        value: '',
                        float: divArr[i].justifyContent,
                        size: getStrSize(divArr[i].detail.size),
                        line: divArr[i].divingLine,
                        space: divArr[i].EmptyLine
                    })
                }
            }
        }
        console.log('endPringtData',endPringtData);
        return JSON.stringify(endPringtData);
    }

     // 按需返回布局
     const itemBody = (item,index) =>{
        let child = <div  key = {index} />
        let detail =  item.detail;
        switch(item.type){
            case "TEXT":
                if(findHideSet(item.key)) break;
                child = <div
                        key = {index}
                        className={`row_class ${item.justifyContent}  ${item.divingLine ? 'divingLine': ''} `}
                        style={{marginTop: item.EmptyLine * 20+'px'}}
                        onClick={() => handleStyleClick(item)}
                        >
                        <span className={`${item.justifyContent} size${detail.size} ${item.key == hoverkey ? 'border': 'border_hover'} ${item.key == currentKey ? 'bg_choosed': 'bg'} `}>
                            {detail.hitText}
                        </span>
                    </div>
                break;
            case "OTHER_TEXT":
                if(findHideSet(item.key)) break;
                let isMargin = detail.size == 2 || detail.size == 4;
                child = <div
                        key = {index}
                        style={{marginTop: item.EmptyLine * 20 + (isMargin ? 10 : 0)+'px', marginBottom: (isMargin ? 12: 0) +'px'}}
                        className={`other_text row_class ${item.justifyContent} size${detail.size} ${item.divingLine ? 'divingLine': ''} ${item.key == hoverkey ? 'border': 'border_hover'} ${item.key == currentKey ? 'bg_choosed': 'bg'}`}
                        onClick={() => handleStyleClick(item)}
                        >
                            {detail.values.map(item => <div>{item}</div>)}
                    </div>
                break;
            case "TEXT_CUSTORM":
                if(findHideSet(item.key)) break;
                child = <div
                        key = {index}
                        style={{marginTop: item.EmptyLine * 20+'px'}}
                        className={`row_class ${item.justifyContent} ${item.divingLine ? 'divingLine': ''} `}
                        onClick={() => handleStyleClick(item)}
                        >
                     <span className={`${item.justifyContent} size${detail.size} ${item.key == hoverkey ? 'border': 'border_hover'} ${item.key == currentKey ? 'bg_choosed': 'bg'}`}>
                        {detail.hitText != '' ? detail.hitText :'请输入自定义文字'}
                     </span>
                </div>
                break;
            case "TABLE":
                if(item.key && findHideSet(item.key)) break;
                let coloums = item.detail.coloums;
                let data = item.detail.data;
                child = <div
                        className={`row_class table_body ${item.key == hoverkey ? 'border': 'border_hover'}`}
                        key = {index}
                        >
                    <div
                        className={`table_head tableHead${coloums.size} ${coloums.divingLine ? 'divingLine': ''} ${coloums.key == currentKey ? 'bg_choosed': 'bg'}`}
                        style={{marginTop: coloums.EmptyLine * 20+'px'}}
                        onClick={() => handleStyleClick(item,'HEAD')}
                        >
                        {
                            coloums.values.map((item,index) =>{
                                let hidden = findHideSet(item.key);
                                if(hidden) return <></>
                                return <div
                                    key={index}
                                    className={`head_item ${index == 0 ? 'w90': ''} ${item.key == hoverkey ?'border': 'border_hover'}`}
                                >
                                    <span className={`size${coloums.size}`}>{item.title}</span>
                                </div>
                            })
                        }
                    </div>
                    <div
                        className={`table_data tableData${data.size} ${data.divingLine ? 'divingLine': ''} ${data.key == currentKey ? 'bg_choosed': 'bg'} `}
                        style={{marginTop: data.EmptyLine * 20+'px'}}
                        onClick={() => handleStyleClick(item,'VALUE')}
                            >
                        {
                            data.values.map((item,index) =>{
                                let hidden = findHideSet(item.key);
                                if(hidden) return <></>
                                let isPading = data.size == 3 || data.size == 4;
                                let isPl = isPading && index == (data.values.length -1) && item.title.length > 8;
                                return <div
                                        key={index}
                                        className={`data_item ${index == 0 ? 'w90': ''} ${item.key == hoverkey ? 'border': 'border_hover'}`}>
                                        <div className={` size${data.size} ${isPl ? 'pL10':""} `}>{item.title}</div>
                                       
                                        {
                                            item.attrs && !findHideSet(item.attrs.key) &&
                                            <div
                                                className={`${item.attrs.key == hoverkey ? 'border': 'border_hover'} ${item.attrs.key == currentKey ? 'bg_choosed': 'bg'} size${item.attrs.size} attr ${isPading ? item.attrs.size > 2 ? 'ml7':'ml_9': item.attrs.size > 2 ? 'ml16' : 'ml1'}`}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleStyleClick(item,'ATTRIBUTE')
                                                }}
                                                >
                                                    {
                                                        item.attrs.values.map((c_item,c_index) => <div key={index+c_index}>{c_item}</div>)
                                                    }
                                            </div>
                                        }
                                       
                                        
                                </div>
                            })
                        }
                    </div>
                </div>
                break;
            case "UPLOAD":
                if(findHideSet(item.key)) break;
                child =  <div
                            className={`row_class center upload_body ${item.key == hoverkey ? 'border': 'border_hover'}`}
                            key = {index}
                            onClick={() => handleStyleClick(item,'UPLOAD')}
                        >
                        <Upload
                            className='uploadStyle'
                            showUploadList={false}
                            action={QINIU_SERVER}
                            accept="image/*"
                            beforeUpload={handleBeforeUpload}
                            onChange={handleUploadChange}
                            data={{
                                token: qiniuParam.token,
                                region: `http://up-z2.qiniu.com`,
                                key: qiniuParam.imageKey,
                                "x:type": "poster"
                            }}
                        > 
                            <div className ="upload_div">
                                {
                                     item.detail.url != ''  ?   <img src={item.detail.url} className="custom_logo" />
                                     :
                                     <img src='https://ydn-siyu.cdn.shuachi.com/custom_img.jpg' className="custom_logo_1" />
                                }
                               
                            </div>
                            {
                                item.detail.url != ''  ? <></> : <div>上传图片</div>
                            }
                        </Upload>
                        {
                             item.detail.url != '' ? <></> :  <div className="timg_tip">(图片大小不超过50KB,建议上传比例 1:1)</div> 
                        }
                       
                    </div>
                break;
            case "SETTLEMENT_TABLE":
                let coloums2 = item.detail.coloums;
                let data2 = item.detail.data;
                let hidden = findHideSet(coloums2.key);
                child = <div
                            key={index}
                            className={`table_body`}
                            >
                       {
                           hidden ? <></> :
                           <div
                                className={`table_head tableHead${coloums2.size} ${coloums2.divingLine ? 'divingLine': ''} ${coloums2.key == hoverkey ?'border': 'border_hover' }  ${coloums2.key == currentKey ? 'bg_choosed': 'bg'}`}
                                style={{marginTop: coloums2.EmptyLine * 20+'px'}}
                                onClick={() => handleStyleClick(item,'HEAD')}
                                >
                                {
                                    coloums2.values.map((_item,_index) =>{
                                        return <div key={_index} className={`${_index == 0 ? 'w90': ''} `}>
                                            <span className={`size${coloums2.size}`}>{_item}</span>
                                        </div>
                                    })
                                }
                            </div>
                       }
                        {
                            data2.map((d_item,d_index) =>{
                                let hidden = findHideSet(d_item.key);
                                if(hidden) return <></>
                                let isPadding = d_item.size == 3 || d_item.size == 4;
                                return <div
                                    key={d_index}
                                    className={`table_data ${isPadding ? 'd_data_slider': ''}   ${d_item.key == hoverkey ? 'border': 'border_hover'}  ${d_item.divingLine ? 'divingLine': ''} ${d_item.key == currentKey ? 'bg_choosed': 'bg'} `}  
                                    style={{marginTop: d_item.EmptyLine * 20+'px'}}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleStyleClick(d_item,'D_VALUE')
                                    }}
                                >
                                    {
                                      d_item.values.map((c_item,c_index) =>{
                                          return <div key={c_index} className={`d_sizeBo${d_item.size} ${c_index == 0 ? 'w90': ''}`}>
                                                    <span className={`size${d_item.size}`}>{c_item}</span>
                                              </div>
                                      })
                                    }
                            </div>
                            })
                        }
                    </div>
                break;
            case 'QRCODE': 
            if(findHideSet(item.key)) break;
            child = <div
                        className={`qrcode flex-${item.justifyContent} ${item.divingLine ? 'divingLine': ''} ${item.key == hoverkey ?'border': 'border_hover' }  ${item.key == currentKey ? 'bg_choosed': 'bg'}`}
                        style={{marginTop: item.EmptyLine * 20+'px'}}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleStyleClick(item)
                        }}
                    >
                <img src={item.detail.url} className="qrcode_img"></img>
            </div>
        }

        return child
    }

    return <Spin spinning={loading} size="large" tip="努力加载中..." style={{color: '#333'}}>
        <div className="edit_ticket">
            <HeadNav okCallBack = {submit} cancelCallBack = {cancel} recoveryCallBack={recoveryDefalut}/>
        <div className="edit_body">
            <div>
            <SliderBar
                arrList={sliders} 
                callBackSlider={callBackSlider}
                propsHover={hoverFun}
                />
                {
                    findCustomSetting() ?  <div className="custom_input">
                            <div>自定义文字</div>
                            <Input
                                placeholder="自定义文字"
                                className="input_body"
                                value={getCustomText()}
                                onChange={e => handleCustom(e)}
                                />
                        </div>
                        : <></>
                }
            
            </div>
            <div className="edit_context">
                {divArr.map((item,index) =>  itemBody(item,index))}
                <div className="fixed_tip">样式预览</div>
            </div>
            <div className="seat_div"></div>
            <StyleSetting params={currentItem} callBack={callBackStyle} />
        </div>
        </div>
    </Spin>

}

export default EditTicket;