import React from "react"
import styles from './decorationLeft.module.scss'

import workbench from '../../../../assret/images/decoration_wode_left_bg.png'

interface IProps {
    type: number
    dataObj?: {
        title: string,
        list: Array<{
            icon: string
            txt: string
        }>
    }
    history: any
}

interface IState {

}

class DecorationLeft extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {

        }
    }

    render() {
        const { type, dataObj } = this.props;

        return (
            <div className={styles.left}>
                {
                    type === 1
                    ?   <div className={styles.left_main}>
                            <img src={workbench} className={styles.left_main_img} />
                            <div className={styles.left_main_tip}>
                                <span className={styles.left_main_tip_info}>本页面不支持添加模块。</span>
                                <span className={styles.left_main_tip_home} onClick={() => {this.props.history.goBack();}}>回到首页</span>
                            </div>
                        </div>
                    :   null
                }
                
            </div>
        )
    }
}

export default DecorationLeft