import React from "react";
import {Button, Input, Popover, message, Modal} from "antd";
import i_edit_icon from "./../../../../../assret/images/i_edit_icon.png"
import i_fans_icon from "./../../../../../assret/images/i_fans_icon.png"
import i_more_icon from "./../../../../../assret/images/i_more_icon.png"
import i_paihang_icon from "./../../../../../assret/images/i_paihang_icon.png"
import copy from 'copy-to-clipboard';
import "./taskSinger.scss"
import moment from "moment";
import {getRequest} from "../../../../../api/http";
import {apiUrl} from "../../../../../api/api_url";

const QRCode = require('qrcode.react');
interface IProps{
    taskItem: any,
    callbackParent():void,
    history: any
}

class TaskSinger extends React.Component<IProps>{

    state = {
        visible: false,
        promoteParam: {
            title: '',
            promoteUrl: ''
        }
    };

    handleModel =(item:string) =>{
        switch (item) {
            case '开启活动':
                break;
            case  '删除':
                this.confirmModel();
                break;
            case '人气排行榜':
                this.handlePromote('人气排行榜');
                break;
            case '推广':
                this.handlePromote('推广');
                break;
            case '暂停':
                this.requestStartOrStopTask();
                break;
            case '开启':
                this.requestStartOrStopTask();
                break;
            case '编辑':
                let statusStr = this.handleCollect();
                if(statusStr === '即将开始'){
                    let task = this.props.taskItem;
                    this.props.history.push({pathname: '/sidebar/newTask',state: {id:task.id}});
                }else{
                    message.warning("任务只有在开始前可以编辑哦");
                }
                break;
            case '粉丝':
                let task = this.props.taskItem;
                this.props.history.push({pathname: '/sidebar/joinFans',state: {id:task.id}});
                break;
            default:
                message.success('敬请期待')
        }
    };

    handlePromote =(title: string) =>{
        let that =this;
        let param = this.state.promoteParam;
        let taskItem = this.props.taskItem;
        param.title = title;
        switch (title) {
            case '人气排行榜':
                param.promoteUrl = `${process.env.REACT_APP_BASEURL}/v1/jump/h5-go?jump_url=${encodeURI(`rankPage/${taskItem.str_id}/1?alias_id=${taskItem.mp_alias_id}`)}`;
                break;
            case '推广':
                param.promoteUrl = `${process.env.REACT_APP_BASEURL}/v1/jump/h5-go?jump_url=${encodeURI(`taskDetail/${taskItem.str_id}?alias_id=${taskItem.mp_alias_id}`)}`;
                break;
            default:
                message.success('敬请期待')
        }
        that.setState({
            promoteUrl: param
        },()=>{
            that.handlePromoteModal();
        })
    };

    // 暂停 或开启活动
    requestStartOrStopTask = async () =>{
        let task = this.props.taskItem;
        var nowTime = (new Date()).getTime(); // 获取当前时间戳
        var utc = nowTime - task.start_time*1000;
        var utc2 = nowTime - task.end_time*1000;
        if(utc > 0 && utc2 < 0){
            const res:any = await getRequest(apiUrl.pauseTask+'/'+task.id);
            if(res.code === 1){
                if(task.status === 0){
                    task.status = 1;
                    message.info('暂停成功');
                }else{
                    task.status = 0;
                    message.info('开启成功');
                }
                this.setState({
                    taskItem: task
                })
            }else{
                message.error('服务器异常'+ res.message);
            }
        }else{
            message.warning("任务未开始或已结束,不可以执行该操作！")
        }

    };

    // 控制推广弹窗 显示隐藏
    handlePromoteModal = () =>{
        let visible = this.state.visible;
        this.setState({visible : !visible})
    };

    // 危险动作提示
    confirmModel =() =>{
        let that =this;
        let taskItem = this.props.taskItem;
        Modal.confirm({
            title: '删除活动',
            content: `确认删除活动 [${taskItem.name}] 吗?`,
            okText: '确认',
            cancelText: '取消',
            onOk: () =>that.handleRemove()
        });
    };

    // 删除活动
    handleRemove = async () =>{
        let task = this.props.taskItem;
        // 获取 认证公众号列表
        const res: any = await getRequest(apiUrl.deleteTask+`/${task.id}`);
        if(res.code === 1){
            message.success("删除成功");
            this.props.callbackParent();
        }else{
            message.error(res.message);
        }

    };

    // 处理活动开始状态
    handleCollect =()=>{
        let task = this.props.taskItem;
        var nowTime = (new Date()).getTime(); // 获取当前时间戳
        var utc = nowTime - task.start_time*1000;
        var utc2 = nowTime - task.end_time*1000;
        if(utc < 0){
            return '即将开始';
        }else if(utc2 > 0){
            return '活动已结束';
        }else if(task.status === 1){
            return '任务暂停中'
        }
        return '';
    };

    getDate = (diffValue:any) => {

        var nowTime = (new Date()).getTime(); // 获取当前时间戳
        var utc = nowTime - diffValue;
        if(utc/(60*1000) > 60){
            if(utc/(60*60*1000)> 24){
                return parseInt((utc/(24*60*60*1000)).toString())+ '天';
            }else{
                return  parseInt((utc/(60*60*1000)).toString())+ '小时';
            }
        }else{
            return  parseInt((utc/(60*1000)).toString())+ '分钟';
        }
    };

    render(){
        const {visible,promoteParam} = this.state;
        const item = this.props.taskItem;
        const labels = ['推广','人气排行榜','暂停','删除'];
        labels[2] =item.status === 0 ? '暂停':'开启';
        const content = (
            <div>
                {
                    labels.map((item,index) =>
                        <p key={index} onClick={this.handleModel.bind(this,item)} className="action_labels">{item}</p>
                    )
                }
            </div>
        );
        return <div className="task_item_view l_content">
            <div className="task_top_view">
                <img src={item.prize_url} className="task_image" alt="" />
            </div>
            <div className="task_bottom_view">
                <div className="task_name">{item.name}</div>
                <div className="task_create_time">创建于{moment(item.create_time*1000).format('YYYY-MM-DD HH:mm')}</div>
                <div className="task_action_button">
                    {
                        this.handleCollect() === '' ?
                            <div className="action_left">
                                已进行{this.getDate(item.start_time*1000)}
                            </div>
                            :
                            <div className="action_left">
                                {this.handleCollect()}
                            </div>
                    }
                    <div className="action_right">
                        <img src={i_paihang_icon} className="i_icon" alt="" onClick={this.handleModel.bind(this,'')} />
                        <img src={i_fans_icon} className="i_icon" alt="" onClick={this.handleModel.bind(this,'粉丝')}/>
                        <img src={i_edit_icon} className="i_icon" alt="" onClick={this.handleModel.bind(this,'编辑')}/>
                        <Popover content={content} title={null} trigger="hover">
                            <img src={i_more_icon} className="i_icon" alt="" />
                        </Popover>

                    </div>
                </div>
            </div>
            <Modal
                visible={visible}
                footer={null}
                title={null}
                onCancel={this.handlePromoteModal}
            >
                <div className="model_view">
                    <div className='model_title'>{promoteParam.title}</div>
                    <div className="model_input" style={{width: '90%'}}>
                        <Input value={promoteParam.promoteUrl} disabled={true} width="100%"/>
                        <Button type="primary" style={{marginLeft: '10px'}} onClick={() =>{
                            copy(promoteParam.promoteUrl);
                            message.success("链接已复制");
                        }}>复制</Button>
                    </div>
                    <QRCode value={promoteParam.promoteUrl} size={150} />
                    <div className='model_result_tips'>可用手机扫码查看排行榜</div>
                </div>
            </Modal>
        </div>;
    }
}

export default TaskSinger;