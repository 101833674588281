import React, { useEffect, useState } from 'react'
import { Row, Button, Input, Table, message, Modal, Tag, Tabs, DatePicker, Select, Tooltip,Switch, Icon } from 'antd'
import { useHistory } from 'react-router-dom';
import { getRequest } from '../../api/http'
import { apiUrl } from '../../api/api_url'
import './index.scss'
import btn_create from '../../assret/images/btn_create.png'
import t_end from '../../assret/images/t_end.png'
import t_edit from '../../assret/images/t_edit.png'
import t_data from '../../assret/images/t_data.png'
import t_copy from '../../assret/images/t_copy.png'
import { getIsPermisson, messageCustom } from '../../utils/permisstionUtil';


const { RangePicker } = DatePicker;
const { Option } = Select;
const { Search } = Input

const SmartPushTicket = ()=>{

    const history = useHistory()
    const [data, setData] = useState([]);
    const [dataItem, setdataItem] = useState<any>([]);
    const [total, setTotal] = useState(0);
    const [paramsObj, setParamsObj] = useState<any>({
        page: 1,
        pageSize: 10,
    })
    const statusList = [ '已结束', '未开始', '进行中' ]

    useEffect(() => {
        getSmartPushTicketList({...paramsObj})
    }, [])

    const getSmartPushTicketList = async (data:any = {}) => {
        let param = {
            ...data
        }
        if(param.desc)param.desc=param.desc=='ascend'?'asc':'desc'
        Object.keys(param).map(el => {
            if(!param[el]) delete param[el]
        })
        setParamsObj({...param})
        const res:any = await getRequest(apiUrl.SmartPushTicketList, param)
        if(res && res['code'] === 1) {
            console.log(res)
            setData(res.data.list);
            setdataItem(res.data);
            setTotal(res.data.list.length)
            if(res.data.new_open == 5){
                setnewPushState(true)
            }
            if(res.data.old_open == 5){
                setoldPushState(true)
            }
        }
    }

    const handleTableChange = (pagination:any, filters:any, sorter:any) => {
        getSmartPushTicketList({
            ...paramsObj,
            page:pagination.current,
            pageSize:pagination.pageSize,
            sort:sorter.field,
            desc:sorter.order
        })
    }

    const endActivity = async(id:number) => {
        const res:any = await getRequest(apiUrl.saveStopOperation,{id:id})
        if(res.code == 1){
            message.success('结束运营活动成功')
            getSmartPushTicketList({...paramsObj})
        }else{
            message.error(res.message||'结束运营活动失败')
        }
    }

    const creatTable = ()=>{

        const columns:any = [
            // {
            //     title: 'ID',
            //     dataIndex: 'id',
            //     key: 'id',
            //     fixed:'left',
            //     width:100,
            // },
            {
                title: '优惠券名称',
                dataIndex: 'name',
                key: 'name',
                fixed:'left',
                // width:200,
            },
            {
                title:
                <div>
                    渠道
                    <Tooltip placement='bottom' title='新顾客还是老顾客？'>
                        <Icon type="question-circle" theme="filled" />
                    </Tooltip>
                </div>,
                dataIndex: 'user_desc',
                key: 'user_desc',
                // fixed:'left',
                // width:200,
            },
            {
                title:
                <div>
                    计划通知人数
                    <Tooltip placement='bottom' title='模板消息计划通知人数'>
                        <Icon type="question-circle" theme="filled" />
                    </Tooltip>
                </div>,
                dataIndex: 'notice_plan_count',
                key: 'notice_plan_count',
                sorter: true,
                // width: 100,
                // render:( text:string, record:any, index:number )=> <>{record.activity_start_time}至{record.activity_end_time}</>
            },
            {
                title:
                <div>
                    实际通知人数
                    <Tooltip placement='bottom' title='模板消息实际通知到达的人数'>
                        <Icon type="question-circle" theme="filled" />
                    </Tooltip>
                </div>,
                dataIndex: 'arrive_count',
                key: 'arrive_count',
                sorter: true,
                // width: 100,
                // render:( text:string, record:any, index:number )=> <>{record.activity_start_time}至{record.activity_end_time}</>
            },
            {
                title:
                <div>
                    通知成功率
                    <Tooltip placement='bottom' title='实际通知人数/计划通知人数'>
                        <Icon type="question-circle" theme="filled" />
                    </Tooltip>
                </div>,
                dataIndex: 'arrive_rate',
                key: 'arrive_rate',
                sorter: true,
                // width: 100,
                // render:( text:string, record:any, index:number )=> <>{record.activity_start_time}至{record.activity_end_time}</>
            },
            {
                title:
                <div>
                    查看人数
                    <Tooltip placement='bottom' title='实际有多少人点击查看'>
                        <Icon type="question-circle" theme="filled" />
                    </Tooltip>
                </div>,
                dataIndex: 'read_count',
                key: 'read_count',
                sorter:true,
                // width: 100,
            },
            {
                title:
                <div>
                    查看率
                    <Tooltip placement='bottom' title='查看人数/实际通知人数'>
                        <Icon type="question-circle" theme="filled" />
                    </Tooltip>
                </div>,
                dataIndex: 'read_rate',
                key: 'read_rate',
                sorter:true,
                // width: 100,
            },
            {
                title:
                <div>
                    消费订单数
                    <Tooltip placement='bottom' title='消费了多少订单'>
                        <Icon type="question-circle" theme="filled" />
                    </Tooltip>
                </div>,
                dataIndex: 'use_count',
                key: 'use_count',
                sorter:true,
                render:( text:string, record:any, index:number )=> <Button type="link"  onClick={()=>{history.push('/sidebar/couponGroup',{id: record.coupon_id,type:'use',activity_id:record.activity_id})}}>{text}</Button>
                // width: 100,
            },
            {
                title:
                <div>
                    转化率
                    <Tooltip placement='bottom' title='消费订单数/查看人数'>
                        <Icon type="question-circle" theme="filled" />
                    </Tooltip>
                </div>,
                dataIndex: 'conversion_rate',
                key: 'conversion_rate',
                // sorter:true,
                // width: 100,
            },
            {
                title:
                <div>
                    消费金额
                    <Tooltip placement='bottom' title='所有消费订单的金额汇总'>
                        <Icon type="question-circle" theme="filled" />
                    </Tooltip>
                </div>,
                dataIndex: 'consume_fee',
                key: 'consume_fee',
                sorter:true,
                // width: 100,
            },

        ];

        return (
            <Table
                dataSource={data}
                columns={columns}
                onChange={handleTableChange}
                rowKey={'id'}
                // scroll={{ x: 1900 }}
                pagination={{
                    defaultCurrent: 1,
                    current:paramsObj.page,
                    pageSize: paramsObj.pageSize,
                    total: total,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    showTotal: (total: number) => `共${total}条`,
                }}
            />
        )
    }

    const [newPushState,setnewPushState] = useState(false)
    const newPushStateAction = async (e:any)=>{

        const res:any = await getRequest(apiUrl.SmartPushTicketOpen,{id:dataItem.new_id,open:e?5:1})
        if(res.code == 1){
            message.success(res.message)
            getSmartPushTicketList({...paramsObj})
            setnewPushState(e)
        }else{
            message.error(res.message||'修改失败')
        }
    }
    const [oldPushState,setoldPushState] = useState(false)
    const oldPushStateAction = async (e:any)=>{

        const res:any = await getRequest(apiUrl.SmartPushTicketOpen,{id:dataItem.old_id,open:e?5:1})
        if(res.code == 1){
            getSmartPushTicketList({...paramsObj})
            message.success(res.message)
            setoldPushState(e)
        }else{
            message.error(res.message||'修改失败')
        }

    }


    return(
        <div className="marketing_index">
            <div className="top_view">
                <div className="edit_view">
                    <div className='new_btn mr10' onClick={()=>{
                        if(!getIsPermisson('1_6_6_1')){
                            messageCustom();
                            return;
                        }
                        history.push({pathname:'/sidebar/smartPushTicketAdd',state:{user_type:1,id:dataItem.new_id}})}
                        }>
                        <img src={t_edit}></img>
                        配置新用户规则
                    </div>

                    <Switch onChange={newPushStateAction} checked={newPushState? true : false} />
                </div>
                <div className="edit_view">
                    <div className='new_btn' onClick={()=>{
                        if(!getIsPermisson('1_6_6_1')){
                            messageCustom();
                            return;
                        }
                        history.push({pathname:'/sidebar/smartPushTicketAdd',state:{user_type:2,id:dataItem.old_id}})}
                        }>
                        <img src={t_edit}></img>
                        配置老用户规则
                    </div>
                    <Switch onChange={oldPushStateAction}  checked={oldPushState ? true : false} />
                </div>

            </div>
            <div className="top_show">
                <div className="item">
                    <div>{dataItem.arrive_count_total ?? 0}<br /><b>送达人数</b></div>
                </div>
                <div className="item">
                    <div>{dataItem.read_count_total ?? 0}<br /><b>查看人数</b></div>
                </div>
                <div className="item">
                    <div>{dataItem.read_rate_total ?? 0}<br /><b>查看率</b></div>
                </div>
                <div className="item">
                    <div>{dataItem.consume_count_total ?? 0}<br /><b>消费人数</b></div>
                </div>
                <div className="item">
                    <div>{dataItem.consume_fee_total ?? 0}<br /><b>消费金额</b></div>
                </div>
                <div className="item">
                    <div>{dataItem.conversion_rate_total ?? 0}<br /><b>转化率</b></div>
                </div>
            </div>

            <div style={{background: '#fff', padding: '20px'}}>
                <Row type="flex" align="middle" className="mb30">
                    <span className="mr10">创建时间</span>
                    <RangePicker
                        className="mr10"
                        format={`YYYY-MM-DD`}
                        showTime={{ format: 'YYYY-MM-DD' }}
                        onChange={(e:any, dateString) => {
                            getSmartPushTicketList({...paramsObj,page:1,start_time:dateString[0],end_time:dateString[1]})
                        }}
                        placeholder={['开始时间', '结束时间']} />
                </Row>
                {
                    creatTable()
                }
            </div>
        </div>
    )
}

export default SmartPushTicket