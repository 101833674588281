import React from "react";
import "../turnplateCreate.scss"
import BraftEditor from 'braft-editor'
import {Col, Input, Row, DatePicker, Radio} from "antd";
const { RangePicker } = DatePicker;


interface IProps {
    callbackParent(type:number,param:any):void,
    param ?: any,
}

interface paramType {
    name?:string,
    activityTime ?: [string,string],
    detail: string,
    join_people_num_show?: boolean,
    join_people_num_fictitious?: number,
    join_people_num_limit?: boolean,
    join_people_num_max?: number,
    look_num_fictitious?: number,
    looking_num_fictitious?: number,

}

class CreateActivity extends React.Component<IProps>{

    // 表单参数
    state = {
        param: {} as paramType,
        editorState: BraftEditor.createEditorState(null),
    };

    componentDidMount(): void {
        this.initPageParams();
    }

    // 初始化页面参数
    initPageParams =() =>{
        let that = this;
        let paramTem = that.props.param;
        if(Object.keys(paramTem).length !== 0){
            const editor = BraftEditor.createEditorState(paramTem.detail)
            that.setState({
                param: paramTem,
                editorState: editor
            })
        }
    };

    // 监听活动名称变化
    handleChangeTaskName =(e:any)=>{
        let paramTem = this.state.param;
        paramTem.name = e.target.value.trim();
        this.setState({param: paramTem});
        this.submitParentParams();
    };

    // 监听活动时间 变化
    handleChangeActivityDate =(time:any,timeString:[string,string])=>{
        let paramTem = this.state.param;
        paramTem.activityTime = timeString;
        this.setState({param: paramTem});
        this.submitParentParams();
    };

    // 监听任务内容
    handleChangeTaskDesc = (value:string) =>{
        let paramTem = this.state.param;
        paramTem.detail = value;
        this.setState({param: paramTem});
        this.submitParentParams();
    };

    // 参与显示
    partShow(value:boolean){
        let paramTem = this.state.param;
        paramTem.join_people_num_show = value;
        this.setState({param: paramTem});
        this.submitParentParams();
    }

    partPerson(value:boolean){
        let paramTem = this.state.param;
        paramTem.join_people_num_limit = value;
        this.setState({param: paramTem});
        this.submitParentParams();
    }

    partPersonLimit = (e: any) => {
        let paramTem = this.state.param;
        paramTem.join_people_num_max = e.target.value;
        this.setState({param: paramTem});
        this.submitParentParams();
    }

    // 提交参数给 父级组件
    submitParentParams =()=>{
        this.props.callbackParent(1,this.state.param);
    };

    // 在实际人数增加
    addPerson = (e:any) => {
        let paramTem = this.state.param;
        paramTem.join_people_num_fictitious = e.target.value.trim();
        this.setState({param: paramTem});
        this.submitParentParams();
    }

    handleEditorChange = (editorState:any) => {
        let paramTem = this.state.param;
        paramTem.detail = editorState.toHTML();
        this.setState({param: paramTem, editorState});
        this.submitParentParams();
    }

    readChange = (e:any) => {
        let paramTem = this.state.param;
        paramTem.look_num_fictitious = e.target.value.trim();
        this.setState({param: paramTem});
        this.submitParentParams();
    }

    lookChange = (e:any) => {
        let paramTem = this.state.param;
        paramTem.looking_num_fictitious = e.target.value.trim();
        this.setState({param: paramTem});
        this.submitParentParams();
    }


    render(){
        let {param, editorState} = this.state;
        return (
            <div className="task_setting_view l_content">

                <div className="task_title">
                    活动信息
                </div>
                <div className="setting_inputs">
                    <Row>
                        <Col span={12}>
                            <Row type="flex" align="middle" justify="start" className="mb30">
                                <Col className="input_label">
                                    活动名称：
                                </Col>
                                <Col span={20}>
                                    <Input placeholder="请输入活动名称" value={param.name} size="default" onChange={this.handleChangeTaskName} />
                                </Col>
                            </Row>
                            <Row type="flex" align="middle" justify="start" className="mb30">
                                <Col className="input_label">
                                    活动时间：
                                </Col>
                                <Col span={20}>
                                    <RangePicker
                                        style={{width: '100%'}}
                                        size='default'
                                        placeholder={param.activityTime !== null ? param.activityTime:["开始时间","结束时间"]}
                                        onChange={this.handleChangeActivityDate}
                                        format="YYYY-MM-DD HH:mm:ss"
                                    />
                                </Col>
                            </Row>
                            <Row type="flex" align="top" justify="start" className="mb30">
                                <Col className="input_label">
                                    内容详情：
                                </Col>
                                <Col span={20}>
                                    <div style={{"borderRadius":'10px',border:"1px solid #efefef",height: "400px"}}>
                                    <BraftEditor
                                        value={editorState}
                                        onChange={this.handleEditorChange}
                                        placeholder="请输入内容详情"
                                    />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <div className="task_title">
                    参与限制
                </div>
                <div className="setting_inputs">
                    <Row>
                        <Col span={24}>
                            <Row type="flex" align="middle" justify="start" className="mb30">
                                <Col className="input_label">
                                    参与显示：
                                </Col>
                                <Col>
                                    <Radio.Group
                                        value={param.join_people_num_show}
                                        style={{ marginLeft: 20 }}
                                        onChange={(e) => this.partShow(e.target.value)}
                                    >
                                        <Radio value={false}>隐藏</Radio>
                                        <Radio value={true}>显示</Radio>
                                    </Radio.Group>
                                </Col>
                                <Col span={10}>
                                    <Row type="flex">
                                        <Col span={6}>
                                            <div style={{lineHeight: '35px'}}>并在实际人数上增加 </div>
                                        </Col>
                                        <Col><Input type="number" placeholder='0' onChange={this.addPerson} value={param.join_people_num_fictitious !== null ?  param.join_people_num_fictitious : ''} /></Col>
                                        <Col><div style={{lineHeight: '35px'}}> 人</div></Col>
                                    </Row>

                                </Col>
                            </Row>
                            <Row type="flex" align="middle" justify="start" className="mb30">
                                <Col className="input_label">
                                    参与人员限制：
                                </Col>
                                <Col span={3}>
                                    <Radio.Group
                                        value={param.join_people_num_limit}
                                        style={{ marginLeft: 20 }}
                                        onChange={(e) => this.partPerson(e.target.value)}
                                    >
                                        <Radio value={false}>不限</Radio>
                                        <Radio value={true}>限制</Radio>
                                    </Radio.Group>
                                </Col>
                                <Col span={10}>
                                    <Row type="flex">
                                        <Col>
                                            <Input type="number" placeholder='0' value={param.join_people_num_max !== null ?  param.join_people_num_max : ''} onChange={this.partPersonLimit} />
                                        </Col>
                                        <Col><div style={{lineHeight: '35px'}}> 人参与</div></Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row type="flex" align="middle" justify="start" className="mb30">
                                <Col className="input_label">
                                    阅读虚拟数：
                                </Col>
                                <Col span={10}>
                                    <Row type="flex">
                                        <Col><div style={{lineHeight: '35px'}}>在实际人数上增加</div></Col>
                                        <Col>
                                            <Input type="number" placeholder='0' value={param.look_num_fictitious !== null ?  param.look_num_fictitious : ''} onChange={this.readChange} />
                                        </Col>

                                    </Row>
                                </Col>
                            </Row>

                            <Row type="flex" align="middle" justify="start" className="mb30">
                                <Col className="input_label">
                                    ”在看“虚拟人数：
                                </Col>
                                <Col span={10}>
                                    <Row type="flex">
                                        <Col><div style={{lineHeight: '35px'}}>在实际人数上增加</div></Col>
                                        <Col>
                                            <Input type="number" placeholder='0' value={param.looking_num_fictitious !== null ?  param.looking_num_fictitious : ''} onChange={this.lookChange} />
                                        </Col>

                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

export default CreateActivity;