import React from 'react';
import './desktopDisplay.scss'
import { Row, Col, message, Input, Button } from 'antd';
import { getRequest, postRequest } from '../../../api/http';
import { apiUrl } from '../../../api/api_url';
import { SketchPicker } from 'react-color'
import table_check from '../../../assret/images/table_check.png'

type IProps = {
    // history: any
}
type IState = {
    diaplayList: any
    colorList: any
    status_display_colour: any,
    line_show_table_count: number,
    displayColorPicker: boolean,
    status_corner_marker: any,
    defaultColor: string
}
class DesktopDisplay extends React.Component<IProps,IState>{

    constructor(props:IProps) {
        super(props);
        this.state = {
            diaplayList: [
                {
                    active: true,
                    text: '每行显示5桌',
                    value: 5
                },
                {
                    active: false,
                    text: '每行显示6桌',
                    value: 6
                },
                {
                    active: false,
                    text: '每行显示7桌',
                    value: 7
                },
                {
                    active: false,
                    text: '每行显示8桌',
                    value: 8
                }
            ],
            line_show_table_count: 5,
            colorList:['#92E537', '#F71F3C', '#FAC654', '#F86F36', '#5CD7FF', '#7480FE', '#A4A4A4'],
            status_display_colour: [
                // {
                //     name: '空台',
                //     value: '#92E537'
                // },
                // {
                //     name: '占台',
                //     value: '#F71F3C'
                // },
                // {
                //     name: '区别对待',
                //     value: '#FAC654'
                // }
            ],
            displayColorPicker: false,
            defaultColor: '#fff',
            status_corner_marker: [//状态角标设置
                // {
                //     "name": "点餐中",// 名称
                //     "config_id": 1,//配置id
                //     "display_name": "点餐中",//显示名称
                //     "display_status": true,//是否已配置 true-显示 false-不显示（false为未选中）
                //     "value": null//是否有输入值 没有值则为null
                // },
                // {
                //     "name": "充值用户",
                //     "config_id": 4,
                //     "display_name": "充值用户",
                //     "display_status": false,
                //     "value": null
                // },
                // {
                //     "name": "付款中",
                //     "config_id": 3,
                //     "display_name": "付款中",
                //     "display_status": true,
                //     "value": null
                // },
                // {
                //     "name": "1次顾客",
                //     "config_id": 6,
                //     "display_name": "1次顾客",
                //     "display_status": false,
                //     "value": null
                // },
                // {
                //     "name": "2次顾客",
                //     "config_id": 7,
                //     "display_name": "2次顾客",
                //     "display_status": false,
                //     "value": null
                // },
                // {
                //     "name": "3~5次顾客",
                //     "config_id": 8,
                //     "display_name": "3~5次顾客",
                //     "display_status": false,
                //     "value": null
                // },
                // {
                //     "name": "6次以上顾客",
                //     "config_id": 9,
                //     "display_name": "6次以上顾客",
                //     "display_status": false,
                //     "value": null
                // },
                // {
                //     "name": "未消费用户",
                //     "config_id": 10,
                //     "display_name": "未消费用户",
                //     "display_status": false,
                //     "value": null
                // },
                // {
                //     "name": "就餐显示'n小时'",
                //     "config_id": 2,
                //     "display_name": "小时",
                //     "display_status": true,
                //     "value": 1
                // },
                // {
                //     "name": "充值XX元，显示'充值高额'",
                //     "config_id": 5,
                //     "display_name": "充值高额",
                //     "display_status": false,
                //     "value": 100
                // }
            ]
        }
    }

    componentDidMount(){
        this._initInfo()
    }

    _initInfo = async () => {
        let {diaplayList} = this.state;
        let res:any = await getRequest(apiUrl.tableDisplayInfo)
        if(res.code == 1){
            diaplayList.map((item:any) => {
                item.active = false;
                if(item.value === res.data.line_show_table_count){
                    item.active = true;
                }
            })
            let status_corner_marker = res.data.status_corner_marker;
            status_corner_marker.map((item: any) => {
                if(item.config_id === 5){
                    item.new_name = item.name.split('XX')[0]
                    item.sub_name = item.name.split('XX')[1]
                }
            })
            this.setState({
                diaplayList,
                status_display_colour: res.data.status_display_colour,
                status_corner_marker: status_corner_marker,
                line_show_table_count: res.data.line_show_table_count
            })
        }
    }

    colNumChange = (v:any) => {
        let {diaplayList} = this.state;
        diaplayList.map((item:any) => {
            item.active = false;
            if(item.value === v){
                item.active = true;
            }
        })
        this.setState({
            diaplayList,
            line_show_table_count: v
        })
    }

    chooseStatus = (index: number) => {
        let {status_display_colour} = this.state;
        status_display_colour.map((item:any, idx:number) => {
            item.active = false;
            if(index === idx){
                item.active = true;
            }
        })
        this.setState({
            status_display_colour
        })
    }

    changeColor = (c:any) => {
        let {status_display_colour} = this.state;
        let flag = 0;
        let set = true;
        status_display_colour.map((item:any) => {
            if(!item.active){
                flag++;
            }
            if(!item.active && item.value === c){
                set = false;
            }
        })

        if(flag === status_display_colour.length){
            message.warning('请先选择一个状态')
            return;
        }
        if(!set){
            message.warning('不能设置同一个颜色')
            return;
        }

        status_display_colour.map((item:any) => {
            if(item.active){
                item.value = c;
            }
        })

        this.setState({
            status_display_colour,
            defaultColor: c
        })
    }

    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false })
    };

    markerChange = (index:number) => {
        let {status_corner_marker} = this.state;
        if(!status_corner_marker[index].is_operate){
            message.warning('此项不可编辑')
            return;
        }
        status_corner_marker[index].display_status = !status_corner_marker[index].display_status;
        this.setState({
            status_corner_marker
        })
    }

    inputChange = (e:any, index:number) => {
        let {status_corner_marker} = this.state;
        status_corner_marker[index].value = e.target.value
        this.setState({
            status_corner_marker
        })
    }

    handleSubmit = async () => {
        let {line_show_table_count, status_display_colour, status_corner_marker} = this.state;
        let params = {
            line_show_table_count,
            status_display_colour,
            status_corner_marker
        }
        let res:any = await postRequest(apiUrl.tableDisplaySet,params)
        if(res.code == 1){
            message.success(res.message)
            this._initInfo()
        }else{
            message.error(res.message)
            this._initInfo()
        }
    }

    render() {
        let { diaplayList, colorList, status_display_colour, status_corner_marker } = this.state;
        return <div className='desktop_wrap'>
            <div className='desktop_title mb20'>餐桌显示</div>
            <Row type='flex' align='middle' className='mb20'>
                <Col className='display_title'>桌台显示设置：</Col>
                <Col className='col_wrap'>
                    {
                        diaplayList.map((item:any) => {
                            return <div className='col_item' onClick={() => this.colNumChange(item.value)} key={item.value}>
                                <div className={`col_radio ${item.active ? 'col_active' : ''}`}></div>
                                <div className='col_text'>{item.text}</div>
                            </div>
                        })
                    }
                </Col>
            </Row>

            <Row type='flex' align='middle' className='mb20'>
                <Col className='display_title'>状态颜色设置：</Col>
                <Col className='col_wrap'>
                    {
                        colorList.map((item:any) => {
                            return <div className='color_item' style={{background: item}} key={item} onClick={() => this.changeColor(item)}></div>
                        })
                    }
                    <div style={{position: 'relative'}}>
                        <div className='color_custom' onClick={this.handleClick}>自定义</div>

                        { this.state.displayColorPicker ? <div style={{position: 'absolute',zIndex: 2}}>
                            <div style={{position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px'}} onClick={ this.handleClose }/>
                                <SketchPicker color={this.state.defaultColor} onChangeComplete={(color) => this.changeColor(color.hex)} />
                            </div> : null }
                    </div>

                </Col>
            </Row>
            <Row type='flex' align='middle' className='mb20'>
                <Col className='display_title' style={{color: 'transparent'}}>状态颜色设置：</Col>
                <Col className='col_wrap'>
                    {
                        status_display_colour.map((item:any, index: number) => {
                            return  <div key={item.name} style={{position: 'relative'}}>
                                {
                                    item.active
                                    ? <img src={table_check} style={{position: 'absolute', top: '-15px', left: '145px', width: '30px',height: '30px'}} />
                                    : null
                                }
                                <div className={`status_item`} style={{background: item.value}} onClick={() => this.chooseStatus(index)}>{item.name}</div>
                            </div>
                        })
                    }
                </Col>
            </Row>

            <Row type='flex' align='top' className='mb20' justify='start'>
                <Col className='display_title'>状态显示设置：</Col>
                <Col className='col_wrap' span={20}>
                    {
                        status_corner_marker.map((item:any, index:number) => {
                            return <div key={item.config_id}>{item.config_id === 5 ? <div className='col_item mb20'  style={{width:'280px',marginRight:0}}>
                                <div className={`col_check ${item.display_status ? 'col_check_active' : ''}`} onClick={() => this.markerChange(index)}></div>

                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
                                    <div className='col_text'>{item.new_name}</div>
                                    <Input placeholder='请输入' style={{width: '94px',margin: '0 5px'}} value={item.value} onChange={(e) => this.inputChange(e,index)} />
                                    <div className='col_text'>{item.sub_name}</div>
                                </div>
                            </div>
                            : <div className='col_item mb20' onClick={() => this.markerChange(index)} style={{width:'250px',marginRight:0}}>
                                    <div className={`col_check ${item.display_status ? 'col_check_active' : ''}`}></div>
                                    <div className='col_text'>{item.name}</div>
                                </div>
                        }</div>
                        })
                    }
                </Col>
            </Row>

            <Row>
                <Col span={20}>
                    <div className="bottom_wrap">
                        <Button type="primary" onClick={this.handleSubmit}>
                            保存
                        </Button>
                    </div>
                </Col>
            </Row>
        </div>
    }

}

export default DesktopDisplay;