import React, { useEffect, useState } from 'react'
import './memberList.scss'
import { Row, Col, Button, Select, DatePicker, Table, Avatar,Tooltip, Modal, Radio, Input, message } from 'antd';
import Search from 'antd/lib/input/Search';
import { getRequest, postRequest } from '../../../api/http';
import { apiUrl } from '../../../api/api_url';
import { useHistory, useLocation } from 'react-router-dom';
import { getIsPermisson, messageCustom } from '../../../utils/permisstionUtil';

const { Option } = Select;
const { RangePicker } = DatePicker;
interface IProps {
    callBack?: Function
    onVipListPage?: Function
    onRef?: Function
}

/**
 * 会员列表
 */
// let times:any = [];      // 时间区间
const MemberList = (props: IProps) => {
    const history = useHistory()
    const location:any = useLocation()

    const [memberList,setMemberList] = useState([]);       // 公众号数组
    const [count,setCount] = useState(0);                  // 总数量
    const [levelList,setLevelList] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
    const [groupList,setGroupList] = useState([]);
    const [recommendGroupList,setRecommendGroupList] = useState([]);
    const [siyuGroupList,setSiyuGroupList] = useState([]);
    const [userOver, setUserOver] = useState(false); //是否有调整余额权限
    const [userOverPop, setUserOverPop] = useState(false); //是否有调整余额权限
    const [adjustment, setAdjustment] = useState('add'); //调整金额
    const [fullFilling, setFullFilling] = useState(''); //调整金额
    const [userInfo, setUserInfo] = useState<any>({}); //用户信息对象
    const [changeRechargeFee, setChangeRechargeFee] = useState(''); //调整实充
    const [changePresentFee, setChangePresentFee] = useState(''); //调整赠送
    const [changeCommissionFee, setChangeCommissionFee] = useState(''); //调整奖励
    const [changeReason, setChangeReason] = useState(''); //调整原因
    const [nicknameParma, setNickname] = useState('')
    const [ownParams, setOwnParams] = useState<any>({}) //当前参数
    const [paramsObj, setParamsObj] = useState<any>({
        page: 1,
        pageSize: 10,
    });

  // 相当于 componentDidMount 和 componentDidUpdate:
    useEffect(() =>{
        if(location.state&&location.state.recommend_group_id) paramsObj.recommend_group = location.state.recommend_group_id
        if(location.state&&location.state.siyu_group) paramsObj.siyu_group = location.state.siyu_group
        if(location.state&&location.state.nickname) {
            paramsObj.nickname = location.state.nickname;
            setNickname(location.state.nickname)
        }
        requestGetMemberList({...paramsObj});
        getMemberLeverList();
        getGroupList();
        getRecommendGroupList();
        getSiyuGroupList();
        props.onRef && props.onRef()

        return () => {
            setNickname('')
        }

    },[]);
   // 获取会员列表
   const requestGetMemberList = async (data = { }) => {

        setIsLoading(true);
       let param:any = {
           ...data
       }
       if(param.sortType) param.sortType = param.sortType=='ascend'?'asc':'desc'
       Object.keys(param).map(el => {
           if(!param[el]) delete param[el]
       })
       setParamsObj({...param})
       let group_arr = []
       if(param.group){
            group_arr.push(param.group)
            delete param.group
       }
       if(param.recommend_group){
            group_arr.push(param.recommend_group)
            delete param.recommend_group
       }
    //    if(param.siyu_group) {
    //         group_arr.push(param.siyu_group)
    //         delete param.siyu_group
    //    }
       if(group_arr.length>0) param.group = group_arr.join(',')
        // setMemberList([])
        const res:any = await getRequest(apiUrl.fanList,param);
        if(res.code === 1){
            setMemberList(res.data.list);
            setCount(res.data.total);
            setIsLoading(false);
            setUserOver(res.data.user_over)
            setOwnParams(param)
        }
   };

   // 获取自定义分组列表
   const getGroupList = async() =>{
       const res:any = await getRequest(apiUrl.groupList,{groupType:1});
       if(res.code === 1){
           setGroupList(res.data.list);
       }
   }
   // 获取推荐分组列表
   const getRecommendGroupList = async() =>{
       const res:any = await getRequest(apiUrl.groupList,{groupType:2});
       if(res.code === 1){
            setRecommendGroupList(res.data.list);
       }
   }
   // 获取私域流量分组列表
   const getSiyuGroupList = async() =>{
       const res:any = await getRequest(apiUrl.getAllPrivate);
       if(res.code === 1){
            setSiyuGroupList(res.data);
       }
   }
   // 获取会员等级列表
   const getMemberLeverList =  async () =>{
       const res: any = await getRequest(apiUrl.fansLevelList);
       if(res.code === 1){
        setLevelList(res.data);
       }
   };

   //导出
   const exportTable = async () => {
    if(!getIsPermisson('1_6_1_1')){
        messageCustom();
        return;
    }
     const token = localStorage.getItem("token");
     const shop_id = localStorage.getItem("shop_id");
     let url = `${process.env.REACT_APP_BASEURL}/v2/fans/list?export=1&token=${token}&shop_id=${shop_id}`
    //  Object.keys(paramsObj).map((key:any)=>{
    //     if(key!='page'&&key!='pageSize'&&key!='group'&&key!='recommend_group'){
    //         url+='&'+key+'='+paramsObj[key]
    //     }
    //  })

     let group_arr = []
    //  if(paramsObj.group){
    //       group_arr.push(paramsObj.group)
    //  }
     if(location.state&&location.state.recommend_group_id){
          group_arr.push(location.state.recommend_group_id)
     }
    //  if(location.state&&location.state.siyu_group) {
    //       group_arr.push(location.state.siyu_group)
    //  }
     if(group_arr.length>0) url+='&group='+group_arr.join(',')
     let ownParamsTemp = {...ownParams};
     ownParamsTemp.page = 1;
     ownParamsTemp.pageSize = count;
     if(JSON.stringify(ownParamsTemp) != '{}') {
        Object.keys(ownParamsTemp).map((key: any) => {
            url += `&${key}=${ownParamsTemp[key]}`
        })
     }

    window.open(url);
   };

    const exportTable1 = async () => {
        if(!getIsPermisson('1_6_1_1')){
            messageCustom();
            return;
        }
        const token = localStorage.getItem("token");
        const shop_id = localStorage.getItem("shop_id");
        let url = `${process.env.REACT_APP_BASEURL}/v2/fans/list?export=2&token=${token}&shop_id=${shop_id}`
        //  Object.keys(paramsObj).map((key:any)=>{
        //     if(key!='page'&&key!='pageSize'&&key!='group'&&key!='recommend_group'){
        //         url+='&'+key+'='+paramsObj[key]
        //     }
        //  })

        let group_arr = []
        //  if(paramsObj.group){
        //       group_arr.push(paramsObj.group)
        //  }
        if(location.state&&location.state.recommend_group_id){
            group_arr.push(location.state.recommend_group_id)
        }
        //  if(location.state&&location.state.siyu_group) {
        //       group_arr.push(location.state.siyu_group)
        //  }
        if(group_arr.length>0) url+='&group='+group_arr.join(',')
        let ownParamsTemp = {...ownParams};
        ownParamsTemp.page = 1;
        ownParamsTemp.pageSize = count;
        if(JSON.stringify(ownParamsTemp) != '{}') {
            Object.keys(ownParamsTemp).map((key: any) => {
                url += `&${key}=${ownParamsTemp[key]}`
            })
        }

        window.open(url);
    };


    const handleTableChange = (pagination:any, filters:any, sorter:any) => {
        requestGetMemberList({
            ...paramsObj,
            page:pagination.current,
            pageSize:pagination.pageSize,
            sortField:sorter.field,
            sortType:sorter.order
        })
    };

    const createTable = () =>{
        const columns:any = [
            // {
            //     title: '序号',
            //     render:(text: any,record: any,index:number)=>`${index+1}`,
            //     fixed: 'left',
            //     width: 50,
            // },
            {
                title: '用户ID',
                dataIndex: 'id',
                key: 'id',
                fixed: 'left',
                width: 100,
            },
            {
                title: '用户昵称',
                dataIndex: 'nickname',
                key: 'nickname',
                fixed: 'left',
                width: 120,
                render: (text: string,record:any,index:number) =>
                <Row className="" justify="start" align="middle">
                    <div><Avatar src={record.headimgurl} /></div>
                    <div>{record.nickname}</div>
                </Row>
            },
            {
                title: '用户等级',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '会员类型',
                dataIndex: 'vip_id',
                key: 'vip_id',
                render: (text: string,record:any,index:number) =>
                <div>
                   <span>
                        {
                            record.vip_id 
                            ?   <span style={{color: '#2589ff', cursor: 'pointer'}} 
                                    onClick={() => {
                                        props.onVipListPage && props.onVipListPage(record.phone_number, 'VIP_LIST')
                                    }}>
                                    VIP会员
                                </span> 
                            : '普通会员'
                        }
                    </span>
                </div>
            },
            {
                title: '手机号码',
                dataIndex: 'phone_number',
                key: 'phone_number'
            },
            {
                title: '性别',
                dataIndex: 'sex',
                render: (text: string,record:any,index:number) =>
                <div>
                   {record.sex == 0 ? '未知' : record.sex ===1 ? '男': '女'}
                </div>
            },
            {
                title: '用户分组',
                dataIndex: 'group_name',
                key: 'group_name',
                render: (text: any,record:any,index:number) =>{
                    return text ?
                    <Tooltip title={text.join('、')}>
                        {
                            text[0]+'...'
                        }
                    </Tooltip>
                    : '--'}
                // <div className="colum_w">
                //    {
                //        record.group_name.map((item:any,index:number) =>{
                //            return <div key={index}>
                //                {item}
                //            </div>
                //        })
                //    }
                // </div>

            },
            {
                title: '就餐次数',
                dataIndex: 'eat_times',
                key: 'eat_times',
                sorter:true
            },
            {
                title: '消费次数',
                dataIndex: 'comsumer_times',
                key: 'comsumer_times',
                sorter:true
            },
            {
                title: '累计消费（元）',
                dataIndex: 'actual_fee',
                key: 'actual_fee',
                sorter:true
            },
            {
                title: '积分',
                dataIndex: 'score',
                key: 'score',
                sorter:true
            },
            {
                title: '充值次数',
                dataIndex: 'recharge_num',
                key: 'recharge_num',
                sorter:true
            },
            {
                title: '账户余额（元）',
                dataIndex: 'balance',
                key: 'balance',
                sorter:true
            },
            {
                title: '成功邀请人',
                dataIndex: 'invite_num',
                key: 'invite_num',
                sorter:true
            },
            {
                title: '邀请奖励金额',
                dataIndex: 'invite_money',
                key: 'invite_money',
                sorter:true
            },
            {
                title: '注册时间',
                dataIndex: 'create_time',
                key: 'create_time',
                sorter:true
            },
            {
                title: '上次消费时间',
                dataIndex: 'last_comsumer_time',
                key: 'last_comsumer_time',
                sorter:true
            },
            {
                title: '操作',
                dataIndex: 'option',
                key: 'option',
                fixed: 'right',
                width: 150,
                render: (text: string, record:any, index:number) =>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        {
                           userOver
                           ?    record.phone_number
                                ?   <Button
                                        type="link"
                                        onClick={() => {
                                            if(!getIsPermisson('1_6_1_2')){
                                                messageCustom();
                                                return;
                                            }
                                            onChangePricePop(record)
                                        }}
                                    >调整余额</Button>
                                : ''
                           : ''
                        }
                        <Button
                            type="link"
                            onClick={() => {
                                if(!getIsPermisson('1_6_1_3')){
                                    messageCustom();
                                    return;
                                }
                                history.push({
                                    pathname: '/sidebar/memberDetail',
                                    state: {id:record.id}
                                })
                            }}
                        >详情</Button>
                    </div>
            },
        ];

        return <Table
            dataSource={memberList}
            columns={columns}
            rowKey={"id"}
            loading={isLoading}
            size="middle"
            onChange={handleTableChange}
            scroll={{ x: 2000 }}
            pagination={{
                total: count,
                current:paramsObj.page,
                pageSize: paramsObj.pageSize,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (total: number) => `共${total}条`,
            }}
        />;
    }

    //调整余额提交
    const closeStoreActionOK = async (e:any) => {
        let param: any = { fan_id: userInfo.id, status: adjustment }

        if(changeRechargeFee == '' && changePresentFee == '' && changeCommissionFee == '') {
            message.warn('请填写正确的数据！')
            return false;
        }

        if(changeRechargeFee != '' && Number(changeRechargeFee) <= 0) {
            message.warn('输入的金额必须大于0！')
            return false;
        }
        if(changeReason == '') {
            message.warn('请输入调整原因！')
            return false;
        }
        if(changePresentFee != '' && Number(changePresentFee) <= 0) {
            message.warn('输入的金额必须大于0！')
            return false;
        } else {
            param.present_fee = changePresentFee;
        }
        if(changeCommissionFee != '' && Number(changeCommissionFee) <= 0) {
            message.warn('输入的金额必须大于0！')
            return false;
        } else {
            param.commission_fee = changeCommissionFee;
        }

        param.recharge_fee = changeRechargeFee;
        param.reason = changeReason;

        const res: any = await postRequest(apiUrl.setUserProperty, param)
        if(res.code == 1) {
            setUserOverPop(false)
            setChangeRechargeFee('')
            setChangePresentFee('')
            setChangeCommissionFee('')
            setChangeReason('')
            message.success("调整成功")
        } else {
            message.warn(res.message)
        }
    }

    const changeAdjustment = (e: any) => {
        setAdjustment(e)
    }

    //调整余额弹窗
    const onChangePricePop = async (e: any) => {
        if(!getIsPermisson('1_6_1_2')){
            messageCustom();
            return;
        }
        setUserOverPop(true)
        console.log(e)
        const res: any = await getRequest(apiUrl.getUserProperty, { fan_id: e.id })
        let obj = Object.assign(e,res.data);
        setUserInfo({...obj});
    }


    // 就餐人数修改
    const numChange = (e:any) => {
        let name: any = e.target.name;
        let val: any = e.target.value;
        if(name == 'recharge_fee') {
            setChangeRechargeFee(val)
        }
        if(name == 'present_fee') {
            setChangePresentFee(val)
        }
        if(name == 'commission_fee') {
            setChangeCommissionFee(val)
        }
        if(name == 'reason') {
            setChangeReason(val)
        }
    }

    //tab切换
    const [ currentTab, setCurrentTab ] = useState('custom')
    const onChangeTabs = (e:any)=>{
        setCurrentTab(e)
    }

    return <div className='group_index'>
        <div className="member_view l_content">
        {/* <div className="title_text">用户列表</div> */}
        <div className="select_group">
            <Row className="mb20">
                <Col span={4}>
                    <Search
                        allowClear
                        value={nicknameParma == '' ? '' : nicknameParma}
                        placeholder="请输入用户昵称/手机号"
                        onChange={e => {
                            let tel = e.target.value;
                            setNickname(tel)
                        }}
                        onSearch={value => {
                            requestGetMemberList({ ...paramsObj, page:1, nickname: value });
                        }}
                    />
                </Col>
                <Col span={5} >
                    <div className="select_item">
                        <div>自定义分组：</div>
                        <Select allowClear style={{ width: 150 }} placeholder="请选择分组" onChange={(e: any) => {
                            requestGetMemberList({ ...paramsObj, page:1, group: e });
                        }}>
                            {
                                groupList.map((item:any,index) =>{
                                    return <Option value={item.id} key={item.id+index}>{item.name}</Option>
                                })
                            }
                        </Select>
                    </div>
                </Col>
                <Col span={5} >
                    <div className="select_item">
                        <div>推荐分组：</div>
                        <Select allowClear style={{ width: 150 }} placeholder="请选择分组" onChange={(e: any) => {
                            requestGetMemberList({ ...paramsObj, page:1, recommend_group: e });
                        }}>
                            {
                                recommendGroupList.map((item:any,index) =>{
                                    return <Option value={item.id} key={item.id+index}>{item.name}</Option>
                                })
                            }
                        </Select>
                    </div>
                </Col>
                {/* <Col span={5} >
                    <div className="select_item">
                        <div>私域流量分组：</div>
                        <Select style={{ width: 150 }} defaultValue={location.state&&location.state.siyu_group?location.state.siyu_group:0} onChange={(e: any) => {
                            requestGetMemberList({ ...paramsObj, page:1, siyu_group: e });
                        }}>
                            <Option value={0}>全部</Option>
                            {
                                siyuGroupList.map((item:any,index) =>{
                                    return (
                                        <Option value={item.group_id} key={item.group_id}>
                                            <Tooltip title={item.channel_name+'-'+item.group_name}>
                                                {item.channel_name}-{item.group_name}
                                            </Tooltip>
                                        </Option>
                                    )
                                })
                            }
                        </Select>
                    </div>
                </Col> */}
            </Row>
            <Row>
                <Col span={6}>
                    <div className="select_item" style={{justifyContent:'flex-start'}}>
                        <div>注册时间：</div>
                        <RangePicker
                            format={`YYYY-MM-DD`}
                            // showTime={{ format: 'YYYY-MM-DD' }}
                            showTime={false}
                            onChange={(e:any, dateString) => {
                                requestGetMemberList({...paramsObj, page:1, start_time:dateString[0], end_time:dateString[1]});
                            }}
                            placeholder={['开始时间', '结束时间']} />
                    </div>
                </Col>
                <Col span={6}>
                    <div className="select_item">
                        <div>最近就餐时间：</div>
                        <RangePicker
                            format={`YYYY-MM-DD`}
                            showTime={false}
                            onChange={(e:any, dateString) => {
                                requestGetMemberList({...paramsObj, page:1, last_start_time:dateString[0], last_end_time:dateString[1]});
                            }}
                            placeholder={['开始时间', '结束时间']} />
                    </div>
                </Col>
                <Col span={3} >
                    <div className="select_item">
                        <div>用户等级：</div>
                        <Select allowClear style={{ width: 150 }} placeholder="请选择用户等级" onChange={(e: any) => {
                            requestGetMemberList({ ...paramsObj, page:1, level: e });
                        }}>
                            {
                                levelList.map((item:any,index) =>{
                                    return <Option value={item.id} key={item.id+index}>{item.name}</Option>
                                })
                            }
                        </Select>
                    </div>
                </Col>
                <Col span={3} >
                    <div className="select_item">
                        <div>会员类型：</div>
                        <Select allowClear style={{ width: 150 }} placeholder="请选择会员类型" onChange={(e: any) => {
                            requestGetMemberList({ ...paramsObj, page:1, vip_level: e });
                        }}>
                            <Option value='vip'>VIP会员</Option>
                            <Option value='comman'>普通会员</Option>
                        </Select>
                    </div>
                </Col>
                {/* <Col span={2}>
                    <Button icon="file-markdown" type='primary' onClick={()=>{requestGetMemberList({...paramsObj})}}>搜索</Button>
                </Col>     */}
                <Col span={2}>
                    <Button icon="file-markdown" type='primary' onClick={()=>{exportTable()}}>导出所有用户</Button>
                </Col>

                <Col span={1}>
                    <Button icon="file-markdown" type='primary' onClick={()=>{exportTable1()}}>仅导出有余额的用户</Button>
                </Col>
            </Row>
        </div>
        <div className='all_user'>
            总用户数：{count}
        </div>
        {createTable()}
        <Modal
            title="调整余额"
            visible={userOverPop}
            onOk={closeStoreActionOK}
            onCancel={() => {setUserOverPop(false)}}
            okText="确认"
            cancelText="取消"
            closable={false}
        >
            <div className="member_list_main">
                <div className="member_list_main_top mb10">
                    <div className="member_list_main_top_img">
                        <img style={{width: '75px', height: '75px'}} src={userInfo.headimgurl} />
                    </div>
                    <div className="member_list_main_top_right">
                        <div className="member_list_main_top_right_name">{userInfo.nickname}</div>
                        <div className="member_list_main_top_right_phone">手机号：{userInfo.phone_number}</div>
                        <div className="member_list_main_top_right_price">可用余额：{userInfo.balance} （实充：{userInfo.recharge_fee}元，赠送{userInfo.present_fee}元，奖励{userInfo.commission_fee}元）</div>
                    </div>
                </div>
                <Row style={{display: 'flex', alignItems: 'center'}} className="mb10">
                    <Col span={4}>
                        <div>
                            <span className="color_black">选择方式</span>
                        </div>
                    </Col>
                    <Col span={20}>
                    <Radio.Group
                            value={adjustment}
                            onChange={(e) => changeAdjustment(e.target.value)}
                        >
                            <Radio value={'add'}>增加金额</Radio>
                            <Radio value={'reduce'}>减少金额</Radio>
                        </Radio.Group>
                    </Col>
                </Row>
                <Row style={{display: 'flex', alignItems: 'center'}} className="mb10">
                    <Col span={4}>
                        <div style={{marginLeft: '11px'}}>
                            <span className="color_black">调整实充</span>
                        </div>
                    </Col>
                    <Col span={20}>
                        <div>
                            <Input style={{width: '90%', marginRight: '5%'}} value={changeRechargeFee} name="recharge_fee" placeholder="" maxLength={10} onChange={(e) => numChange(e)} type='number'/>元
                        </div>
                    </Col>
                </Row>
                <Row style={{display: 'flex', alignItems: 'center'}} className="mb10">
                    <Col span={4}>
                        <div style={{marginLeft: '11px'}}>
                            <span className="color_black">调整赠送</span>
                        </div>
                    </Col>
                    <Col span={20}>
                        <div>
                            <Input style={{width: '90%', marginRight: '5%'}} value={changePresentFee} name="present_fee" placeholder="" maxLength={10} onChange={(e) => numChange(e)} type='number'/>元
                        </div>
                    </Col>
                </Row>
                <Row style={{display: 'flex', alignItems: 'center'}} className="mb10">
                    <Col span={4}>
                        <div style={{marginLeft: '11px'}}>
                            <span className="color_black">调整奖励</span>
                        </div>
                    </Col>
                    <Col span={20}>
                        <div>
                            <Input style={{width: '90%', marginRight: '5%'}} value={changeCommissionFee} name="commission_fee" placeholder="" maxLength={10} onChange={(e) => numChange(e)} type='number'/>元
                        </div>
                    </Col>
                </Row>
                <Row style={{display: 'flex', alignItems: 'center'}} className="mb10">
                    <Col span={4}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">调整原因</span>
                        </div>
                    </Col>
                    <Col span={20}>
                        <div>
                            <Input style={{width: '90%', marginRight: '5%'}} name="reason" value={changeReason} placeholder="" maxLength={10} onChange={(e) => numChange(e)}/>
                        </div>
                    </Col>
                </Row>
            </div>
        </Modal>
    </div>
    </div>
}

export default MemberList;
