import React, { useEffect, useState } from 'react'
import { Row, Input, Table, Tooltip, Switch, Select, DatePicker, Modal, message } from 'antd';
import { getRequest, postRequest } from '../../api/http';
import { apiUrl } from '../../api/api_url';
import { stores } from '../../stores/index.store'
import btn_add from '../../assret/images/btn_add.png'
import t_edit from '../../assret/images/t_edit.png'
import copy_icon from '../../assret/images/fission_select.png'

import './index.scss'
import { useHistory } from 'react-router-dom';
import { getIsPermisson, messageCustom } from '../../utils/permisstionUtil';

const { Search } = Input
const { Option } = Select
const { RangePicker } = DatePicker

const ScoreIndex = (props:any)=>{
    const history = useHistory()
    const [ currentTab, setCurrentTab ] = useState(0)
    const [ userList, setUserList ] = useState([])
    const [ loading, setLoading ] = useState(false)
    const tabList = [
        '进行中',
        '已领完',
        '已停用',
        '已过期',
    ]
    const typeList = [
        '优惠券',
        '折扣券',
        '代金券',
        '兑菜券',
    ]
    const [ dataSource, setDataSource ] = useState<any>([]);
    const [ total, setTotal ] = useState(0);
    const [ paramsObj, setParamsObj ] = useState<any>({
        page: 1,
        pageSize: 10,
    });

    useEffect(()=>{
        setCurrentTab(stores.appStore.scoreTab)
        getList({...paramsObj,status:stores.appStore.scoreTab})
    },[])
        
    const getList = async (data:any = {}) => {
        let param = {
            ...data
        }
        if(param.sortType) param.sortType=param.sortType=='ascend'?'asc':'desc'
        Object.keys(param).map(el => {
            if(!param[el]&&el!=='status') delete param[el]
        })
        setParamsObj({...param})
        setLoading(true)
        const res:any = await getRequest(apiUrl.getScoreIndex, param);
        setLoading(false)
        if(res && res['code'] === 1) {
            setDataSource(res.data.list.data);
            setTotal(res.data.list.total)
            if(paramsObj.page===1){
                setUserList(res.data.operate_users)
            }
        }
    }

    const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
    },
    {
        title: '关联券',
        dataIndex: 'name',
        // render: (text:any,record:any) => text.join(',')
    },
    {
        title: '类型',
        dataIndex: 'type',
        render: (text:any,record:any) => typeList[text-1]
    },
    {
        title: '需要积分',
        dataIndex: 'score',
    },
    {
        title: '发行数量',
        dataIndex: 'num',
    },
    {
        title: '已兑换',
        dataIndex: 'exchange_num',
        render: (rowData:any, record: any) => {
            return <div className='btn_green' onClick={() => {addGroup(rowData, record, 'exchange')}}>{rowData}</div>
        }
    },
    {
        title: '剩余数量',
        dataIndex: 'rest_num',
        render: (text:any,record:any) => record.num-record.exchange_num,
    },
    {
        title: '已使用',
        dataIndex: 'use_num',
        render: (rowData:any, record: any) => {
            return <div className='btn_green' onClick={() => {addGroup(rowData, record, 'use')}}>{rowData}</div>
        }
    },
    {
        title: '操作时间',
        dataIndex: 'updated_at',
        render: (text:any,record:any) => record.updated_at ? record.updated_at : record.created_at,
    },
    {
        title: '状态',
        dataIndex: 'status',
        render: (text:any,record:any) => tabList[text]
    },
    {
        title: '操作人',
        dataIndex: 'operate_name',
    },
    {
        title: '操作',
        key: 'action',
        render: (rowdata: any) => {
        return <Row type="flex" align='middle' justify="center">
            <Tooltip placement="bottomRight" title={'复制活动'}>
                <img src={copy_icon} className='table_icon'  onClick={() => {
                    if(!getIsPermisson('1_12_11_2')){
                        messageCustom();
                        return;
                    }
                    history.push({pathname: "/sidebar/ScoreAdd", state: { ...rowdata,is_copy:true }})
                }}></img>
            </Tooltip>
            <Tooltip placement="bottomRight" title={'编辑'}>
                <img src={t_edit} className='table_icon' onClick={() => {
                     if(!getIsPermisson('1_12_11_3')){
                        messageCustom();
                        return;
                    }
                    history.push({pathname: "/sidebar/ScoreAdd", state: { ...rowdata }})}}></img>
            </Tooltip>
            {
                rowdata.status==0||rowdata.status==2?(
                    <span className="action_button" onClick={() => {
                        if(!getIsPermisson('1_12_11_4')){
                            messageCustom();
                            return;
                        }
                        setStatus(rowdata.id,rowdata.status===2?0:2)
                    }}>
                        <Switch checked={rowdata.status!==2 ? true : false} size='small' style={{marginRight: '5px'}} />
                        {rowdata.status===2 ? "启用" : "停用"}
                    </span>
                ):null
            }
        </Row>
        }
    },]

    // 跳转添加分组页面
    const addGroup = (row:any, record: any, type: string) => {
        if(row > 0){
            history.push({
                pathname: '/sidebar/couponGroup',
                state: {
                    id: record.coupon[0].id,
                    exchange_id: record.id,
                    type
                }
            });
        }
    }

    //tab切换
    const onChangeTabs = (e:any)=>{
        setCurrentTab(e)
        stores.appStore.setScoreTab(e)
        getList({...paramsObj,status:e})
    }

    const setStatus = async(id:number,status:number) => {
        let params = {
            id:id,
            status:status
        }
        const res:any = await postRequest(apiUrl.setScoreStatus, params);
        if(res && res['code'] === 1) {
            message.success('操作成功')
            getList({...paramsObj})
        }else{
            message.error('操作失败')
        }
    }

    const addScore = () => {
        if(!getIsPermisson('1_12_11_1')){
            messageCustom();
            return;
        }
        history.push("/sidebar/ScoreAdd")
    }

    const handleTableChange = (pagination:any, filters:any, sorter:any) => {
        getList({
            ...paramsObj,
            page:pagination.current,
            pageSize:pagination.pageSize,
            sortField:sorter.field,
            sortType:sorter.order
        })
    }

    return(
        <div className="score_index">
            <div className='group_tab_wrap mb30'>
                {
                    tabList.map((item,index)=>{
                        return <div className={`group_tab_item ${currentTab === index ? 'group_tab_active' : ''}`} key={index} onClick={() => {onChangeTabs(index)}}>{item}</div>
                    })
                }
            </div>
            <Row style={{padding:'15px'}} className="l_content">
                <Row type="flex" justify='space-between' align='middle'>
                    <div className='new_btn' onClick={addScore}>
                        <img src={btn_add}></img>
                            添加积分兑换
                    </div>
                    <div className="right_title">
                        <span className="right_title_label">名称：</span>
                        <Search
                            placeholder="请输入名称"
                            allowClear
                            style={{width: '259px'}}
                            onSearch={value => getList({...paramsObj,page:1,keyword:value})}
                        />
                        <span className="right_title_label">类型：</span>
                        <Select placeholder="请选择类型" allowClear style={{width:'145px'}} onChange={(e)=>{getList({...paramsObj,page:1,type:e})}}>
                            {
                                typeList.map((item,index)=>{
                                    return <Option value={index+1} key={index}>{item}</Option>
                                })
                            }
                        </Select>
                        <span className="right_title_label">创建者 ：</span>
                        <Select placeholder="请选择创建者" allowClear style={{width:'145px'}} onChange={(e)=>{getList({...paramsObj,page:1,operate_id:e})}}>
                            {
                                userList.map((item:any,index:number)=>{
                                    return <Option value={item.id} key={index}>{item.nickname}</Option>
                                })
                            }
                        </Select>
                        <span className="right_title_label">创建时间：</span>
                        <RangePicker
                            format={`YYYY-MM-DD`}
                            showTime={{ format: 'YYYY-MM-DD' }}
                            onChange={(e:any, dateString:any) => {
                                getList({...paramsObj,page:1,start_time:dateString[0],end_time:dateString[1]})
                            }}
                            placeholder={['开始时间', '结束时间']} />                        
                    </div>
                </Row>
                <Table size="middle" rowKey={"id"} columns={columns}  dataSource={dataSource}
                    style={{marginTop: '30px'}}
                    onChange={handleTableChange}
                    loading={loading}
                    pagination={{
                        defaultCurrent: 1,
                        current:paramsObj.page,
                        pageSize: paramsObj.pageSize,
                        total: total,
                        showQuickJumper: true,
                        showSizeChanger: true,
                        showTotal: (total: number) => `共${total}条`,
                    }}
                />
            </Row>            
        </div>
    )

}

export default ScoreIndex