import React, { useEffect, useState, useRef } from 'react'
import ArcProgress from 'react-arc-progress';
import styles from './arcProgress.module.scss'

type Iprops = {
    rate: any,
    value: any,
}

const SiyuArcProgressNew = (props:Iprops)=>{

    const { rate, value, loading }:any = props

    const box1:any = useRef(null)
    const box1Inner:any = useRef(null)
    const box2:any = useRef(null)
    const box2Inner:any = useRef(null)

    useEffect(()=>{
        var style = document.createElement('style');
        style.type = 'text/css';
        console.log('rate：',rate)
        console.log('value',value)
        let deg:any = parseFloat(rate)*360/100
        //console.log('deg:',deg)
        var keyFrames = '\
        @keyframes spin{\
            to{transform: rotate('+deg+'deg);}\
        }\
        @keyframes spin-reverse{\
            to{\
                transform:rotate(-'+deg+'deg);\
            }\
        }';
        style.innerHTML = keyFrames;
        document.getElementsByTagName('head')[0].appendChild(style);
        // setTimeout(()=>{
        //     if(box1.current) box1.current.style="transform:rotate("+deg+"deg)"
        //     if(box1Inner.current) box1Inner.current.style="transform:rotate(-"+deg+"deg)"
        //     if(box2.current) box2.current.style="transform:rotate(300deg)"
        //     if(box2Inner.current) box2Inner.current.style="transform:rotate(-300deg)"
        // },3000)
    },[rate, value])

    const progress:any = {
        num: 0.8,
        text: '100',
    }

    //单位换算,千改为k，万改为W
    const changeUnit = (number:any) => {
        if(number<10000){
            return (number/1000).toFixed(2)+'k'
        }else{
            return (number/10000).toFixed(2)+'w'
        }
    }

    return (
        <div className={styles.arc_progress_wrap}>
            <div>社群总实收金额</div>
            <div className={styles.percentage}>占总实收金额：{rate}%</div>
            <div className={styles.arc_progress_box}>
                <ArcProgress
                    progress={parseFloat(rate)/100}
                    text={value?value+'':'0'}
                    textStyle={{
                        size: '26px',
                        color:'#4A4A4A',
                        x:86+30,
                        y:76+30,
                    }}
                    customText={[{
                        text:'社群总实收金额(元)',
                        size: '15px',
                        color:'#4A4A4A',
                        x:86+30,
                        y:106+30,
                    },{
                        text:'',
                        size: '22px',
                        color:'#4A4A4A',
                        x:106+30,
                        y:76+30,
                    }]}
                    size={232}
                    // customText={["aasas","aasas"]}
                    observer={(current) => {
                        const { percentage, currentText } = current;
                        // console.log('observer:', percentage, currentText);
                    }}
                    animationEnd={({ progress, text }) => {
                        // console.log('animationEnd', progress, text);
                    }}
                    thickness={30} //设置圆环进度条的厚度
                    arcStart={-90}
                    arcEnd={270}
                    animation={3000}
                    fillColor={{gradient: ['#FF97E5','#516EFF']}}
                    //emptyColor={'transparent'}
                    className="arc_progress"
                />  
                <div className={`${styles.box1} ${styles.box}`}>
                    <div className={styles.ball} ref={box1} style={{animation:'spin 3s 1 linear forwards'}}>
                        <div className={styles.inner} ref={box1Inner} style={{animationName:'spin-reverse'}}>
                            <span className={styles.item_tip}>社群总实收金额</span>
                            <span className={styles.item_rate}>占总实收金额:{rate}%</span>
                        </div>
                    </div>
                </div>   
            </div>
        </div>
    )
}

export default SiyuArcProgressNew



