import React, { useState, } from "react";
import {Drawer,Button,Input,Form,Radio, message} from 'antd'
import {messageTemplateAdd} from './../components/request'
import useForm from 'rc-form-hooks'
import {templateFrom,templateFromType} from './../components/dataType'
const { TextArea } = Input;

function TemplateFrom (props:templateFrom) {
    const [data,setData] = useState(props.data)
    const { getFieldDecorator, validateFields, errors, values,resetFields,setFieldsValue,getFieldValue } = useForm<templateFromType>();

    const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 4 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 20 },
        },
    };

    const handleSubmit = (e:any)=>{
        e.preventDefault();
        validateFields().then((data)=>{
            
            if(data.jump_url === undefined){
                delete data.jump_url
            }
            

            messageTemplateAdd(data).then((res) => {
                if(res.code === 1){
                    message.info('添加成功');
                    props.setVisible(false)
                    
                }else{
                    message.error(res.message)
                }
            })
            
        })
    }

    

    return <div>
        <Drawer
            title={props.data !== undefined && props.data.id != undefined ? '编辑模板' : '添加模板'}
            placement="right"
            closable={false}
            onClose={()=>{
                props.setVisible(false);
                resetFields();
            }}
            visible={props.visible}
            width={900}
        >
            <Form {...formItemLayout} className="login-form" onSubmit={handleSubmit}>
                <Form.Item label="消息标题">
                    {   
                        getFieldDecorator('title', {
                            initialValue: data !== undefined ? data.content : '',
                            rules: [{ required: true, message: '填写消息标题!' }]
                        })(<Input placeholder="填写消息标题"  />)
                    }
                </Form.Item>

                <Form.Item label="推送内容">
                    {   
                        getFieldDecorator('content', {
                            initialValue: data !== undefined ? data.content : '',
                            rules: [{ required: true, message: '填写推送内容!' }]
                        })(<TextArea rows={4} placeholder="填写推送内容" />)
                    }
                </Form.Item>

                

                <Form.Item label="是否启用">
                    {getFieldDecorator('is_effect', {
                        initialValue: data && data.is_effect ? data.is_effect : 1,
                        rules: [{ required: true, message: '设置是否启用!' }]
                    })(<Radio.Group >
                        <Radio value={1}>启用</Radio>
                        <Radio value={2}>不启用</Radio>
                        </Radio.Group>)}
                </Form.Item>

                <Form.Item label="店铺id">
                    {getFieldDecorator('shop_id', {
                        initialValue: data && data.shop_id ? data.shop_id : 1,
                        rules: [{ required: true, message: '填写店铺id!' }]
                    })(<Input placeholder="填写店铺id"  />)}
                </Form.Item>

                <Form.Item label="跳转url">
                    {   
                        getFieldDecorator('jump_url', {
                            initialValue: data !== undefined ? data.jump_url : '',
                            rules: [{ required: false, message: '填写跳转url!' }]
                        })(<Input placeholder="填写跳转url"  />)
                    }
                </Form.Item>

                <Form.Item label="通知类型">
                    {getFieldDecorator('type', {
                        initialValue: data && data.type ? data.type : 1,
                        rules: [{ required: true, message: '设置通知类型!' }]
                    })(<Radio.Group >
                        <Radio value={1}>系统通知</Radio>
                        <Radio value={2}>优惠通知</Radio>
                        </Radio.Group>)}
                </Form.Item>

                <Button type="primary" htmlType="submit"  className="login-form-button" >
                    提交
                </Button>

            </Form>
        </Drawer>
    </div>
}


export default TemplateFrom
