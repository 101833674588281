import React from 'react';
import { Table, Modal, message, Tooltip, Icon } from 'antd'
import { RangePickerValue } from "antd/lib/date-picker/interface";
import { getRequest } from '../../api/http';
import { apiUrl } from '../../api/api_url';
import './newbusinessData.scss'
import { getIsPermisson, messageCustom } from '../../utils/permisstionUtil';
import CustomDatePicker from '../datePicker';
import goodsExport from '../../assret/images/goodsExport.png'
import new_detail_white from '../../assret/images/new_detail_white.png'

interface NewBusinessDataProps {
    history: any,
    callBack: any
}

const nowDate = new Date();
const yesterday = new Date(nowDate.getTime() - 24 * 60 * 60 * 1000)
const beforeYesterday = new Date(nowDate.getTime() - 24 * 2 * 60 * 60 * 1000)

class NewBusinessData extends React.Component<NewBusinessDataProps> {
    column = [
        {
            title: "日期",
            dataIndex: "day_desc",
            key: "day_desc",
        },
        {
            title: "订单数",
            dataIndex: "paid_count_total",
            key: "paid_count_total",
        },
        {
            title: "应收金额",
            dataIndex: "origin_total",
            key: "origin_total",
        },
        {
            title: "订单实收",
            dataIndex: "actual_total",
            key: "actual_total",
        },
        {
            title: "收款渠道",
            children: [
                {
                    title: "系统收款",
                    dataIndex: "sys_total",
                    key: "sys_total",
                    className: "btn_green",
                    render: (text: any, record: any) => {
                        return (
                            <div
                                onClick={() => {
                                    this.toDetail('系统收款', record.day, record.day);
                                }}
                            >
                                <div>{record.sys_total}</div>
                                <div>查看明细</div>
                            </div>
                        );
                    }
                },
                {
                    title: "余额支付",
                    dataIndex: "over_total",
                    key: "over_total",
                },
                {
                    title: "第三方平台",
                    dataIndex: "third_actual_total",
                    key: "third_actual_total",
                    className: "btn_green",
                    render: (text: any, record: any) => {
                        return (
                            <div
                                onClick={() => {
                                    this.toDetail('第三方平台', record.day, record.day);
                                }}
                            >
                                <div>{record.third_actual_total}</div>
                                <div>查看明细</div>
                            </div>
                        );
                    }
                },
                {
                    title: "银行卡",
                    dataIndex: "bank",
                    key: "bank",
                },
                {
                    title: "现金",
                    dataIndex: "money",
                    key: "money",
                }
            ]
        },
        {
            title: "系统打款",
            children: [
                {
                    title: "系统收款",
                    dataIndex: "sys_total_bak",
                    key: "sys_total_bak",
                },
                {
                    title: "其它收入",
                    dataIndex: "sys_other",
                    key: "sys_other",
                    className: "btn_green",
                    render: (text: any, record: any) => {
                        return (
                            <div
                                onClick={() => {
                                    this.toDetail('其它收入', record.day, record.day);
                                }}
                            >
                                <div>{record.sys_other}</div>
                                <div>查看明细</div>
                            </div>
                        );
                    }
                },
                {
                    title: "手续费",
                    dataIndex: "service_fee_total",
                    key: "service_fee_total",
                },
                {
                    title: "未打款",
                    dataIndex: "settle_account_false",
                    key: "settle_account_false",
                },
                {
                    title: "实际打款",
                    dataIndex: "settle_account_true",
                    key: "settle_account_true",
                }
            ]
        },
        {
            title: "其它资金",
            dataIndex: "other_income",
            key: "other_income",
            className: "list_center btn_green",
            render: (text: any, record: any) => {
                return (
                    <div
                        onClick={() => {
                            this.toDetail('其它资金', record.day, record.day);
                        }}
                    >
                        <div>{record.other_income}</div>
                        <div>查看明细</div>
                    </div>
                );
            }
        },
        {
            title: "商家应得",
            dataIndex: "shop_total_amount",
            key: "shop_total_amount",
        },
        {
            title: "操作",
            dataIndex: "action",
            key: "action",
            className: "list_center",
            render: (text: string, record: any) => {
                return <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div onClick={() => this.toInfo(record)} className='mr30 btn_green'>明细</div>
                    <div onClick={() => this.refresh(record)} className='btn_green'>刷新</div>
                </div>
            }
        },
        {
            title: "更新时间",
            dataIndex: "updated_at",
            key: "updated_at",
            className: "list_center"
        },
    ];

    state = {
        list: [],
        preview: {},
        current: 1,
        pageSize: 10,
        count: 0,
        start_time: "",
        end_time: "",
        record: {},

        dataList: [
            {
                title: '订单数',
                value: 0,
                tips: '用户消费的订单数',
            },
            {
                title: '应收金额',
                value: 0,
                tips: '用户消费后应付的原价金额，未扣除优惠',
            },
            {
                title: '订单实收',
                value: 0,
                tips: '订单实收包含系统收款、余额支付、第三方平台、银行卡、现金；不包含会员充值、权益卡、代金券',
            },
            {
                title: '系统收款',
                value: 0,
                tips: '系统收款是订单使用支付宝和微信支付总和，不包含会员充值、权益卡、代金券',
                btn_text: '查看明细',
                url: ''
            },
            {
                title: '余额支付',
                value: 0,
                tips: '用户使用余额支付的金额',
            },
            {
                title: '第三方平台',
                value: 0,
                tips: '第三方平台包含美团、口碑套餐和代金券，系统只做记录',
                btn_text: '查看明细',
                url: ''
            },
            {
                title: '银行卡',
                value: 0,
                tips: '用户使用银行卡支付，系统只做记录',
            },
            {
                title: '现金',
                value: 0,
                tips: '用户使用现金支付，系统只做记录',
            },
            {
                title: '其它收入',
                value: 0,
                tips: '其他包含会员充值、权益卡、代金券',
                btn_text: '查看明细',
                url: ''
            },
            {
                title: '其它资金',
                value: 0,
                tips: '用户充值使用银行卡或人民币支付的总和属于其他资金',
                btn_text: '查看明细',
                url: ''
            },
            {
                title: '实际打款',
                value: 0,
                tips: '自然日内微信支付和支付宝支付总和-手续费+未打款金额',
            },
            {
                title: '商家应得',
                value: 0,
                tips: '商家应得=订单实收+其他收入+其他资金-手续费',
            }
        ]
    };

    componentDidMount() {
        this.getTimeData("currentMonth");
    }

    // 初始化数据
    _initInfo = async () => {
        let { pageSize, current, start_time, end_time, dataList } = this.state;
        let params: any = {
            current,
            pageSize
        };
        if (start_time !== "") {
            params.start_time = start_time;
            params.end_time = end_time;
        }
        let res: any = await getRequest(apiUrl.newBusiness, params);
        if (res.code === 1) {
            let list: any = res.data.list;
            dataList[0].value = res.data.paid_count_total;
            dataList[1].value = res.data.origin_total;
            dataList[2].value = res.data.actual_total;
            dataList[3].value = res.data.sys_total;
            dataList[4].value = res.data.over_total;
            dataList[5].value = res.data.third_total;
            dataList[6].value = res.data.bank;
            dataList[7].value = res.data.money;
            dataList[8].value = res.data.sys_other_total;
            dataList[9].value = res.data.other_income;
            dataList[10].value = res.data.settle_account_true;
            dataList[11].value = res.data.shop_total_amount;
            this.setState({
                count: res.data.count ? res.data.count : 0,
                list: list,
                dataList
            });
        }
    };

    pageChange = (page: number) => {
        this.setState({
            current: page
        }, () => {
            this._initInfo();
        }
        );
    };

    //   刷新
    refresh = async (record: any) => {
        if(!getIsPermisson('1_3_2_3')){
            messageCustom();
            return;
          }
        let res: any = await getRequest(apiUrl.freshReport, { date: record.day })
        if (res.code == 1) {
            message.success('刷新成功')
            this.getTimeData("currentMonth");
        }
    }

    // 导出
    exportTable = async () => {
        if(!getIsPermisson('1_3_2_1')){
            messageCustom();
            return;
          }
        const token = localStorage.getItem("token");
        const shop_id = localStorage.getItem("shop_id");
        window.open(`${process.env.REACT_APP_BASEURL}/v1/shop-report/export?token=${token}&start_time=${this.state.start_time}&end_time=${this.state.end_time}&shop_id=${shop_id}`
        );
    };

    getTimeData = (
        type:
            | "today"
            | "yesterday"
            | "beforeYesterday"
            | "beforeMonth"
            | "beforeWeek"
            | "currentMonth"
    ) => {
        switch (type) {
            case "currentMonth":
                let currMonthStart = `${nowDate.getFullYear()}-${this.formatData(
                    nowDate.getMonth() + 1
                )}-01`;
                let currMonthEnd = `${nowDate.getFullYear()}-${this.formatData(
                    nowDate.getMonth() + 1
                )}-${this.formatData(nowDate.getDate())}`;
                this.setState(
                    {
                        start_time: currMonthStart,
                        end_time: currMonthEnd
                    },
                    () => {
                        this._initInfo();
                    }
                );
                break;
            case "beforeWeek":
                var d = new Date();
                var end = d.getTime() - 1000 * 60 * 60 * 24 * d.getDay();
                let start = d.getTime() - 1000 * 60 * 60 * 24 * (d.getDay() + 6);
                let startDay =
                    new Date(start).getFullYear() +
                    "-" +
                    this.formatData(new Date(start).getMonth() + 1) +
                    "-" +
                    this.formatData(new Date(start).getDate());
                let endDay =
                    new Date(end).getFullYear() +
                    "-" +
                    this.formatData(new Date(end).getMonth() + 1) +
                    "-" +
                    this.formatData(new Date(end).getDate());

                this.setState(
                    {
                        start_time: startDay,
                        end_time: endDay
                    },
                    () => {
                        this._initInfo();
                    }
                );
                break;
            case "beforeMonth":
                let beforeMonthStart =
                    nowDate.getFullYear() +
                    "-" +
                    this.formatData(nowDate.getMonth()) +
                    "-01";
                let currentMonth =
                    nowDate.getFullYear() +
                    "-" +
                    this.formatData(nowDate.getMonth() + 1) +
                    "-01";
                let num = new Date(
                    new Date(currentMonth).getTime() - 24 * 60 * 60 * 1000
                );
                let beforeMonthEnd = `${num.getFullYear()}-${this.formatData(
                    num.getMonth() + 1
                )}-${this.formatData(num.getDate())}`;
                this.setState(
                    {
                        start_time: beforeMonthStart,
                        end_time: beforeMonthEnd
                    },
                    () => {
                        this._initInfo();
                    }
                );
                break;
            case "beforeYesterday":
                let beforeYesDay = `${beforeYesterday.getFullYear()}-${this.formatData(
                    beforeYesterday.getMonth() + 1
                )}-${this.formatData(beforeYesterday.getDate())}`;
                this.setState(
                    {
                        start_time: beforeYesDay,
                        end_time: beforeYesDay
                    },
                    () => {
                        this._initInfo();
                    }
                );
                break;
            case "yesterday":
                let yesDay = `${yesterday.getFullYear()}-${this.formatData(
                    yesterday.getMonth() + 1
                )}-${this.formatData(yesterday.getDate())}`;
                this.setState(
                    {
                        start_time: yesDay,
                        end_time: yesDay
                    },
                    () => {
                        this._initInfo();
                    }
                );
                break;

            case "today":
                let toDay = `${nowDate.getFullYear()}-${this.formatData(
                    nowDate.getMonth() + 1
                )}-${this.formatData(nowDate.getDate())}`;
                this.setState(
                    {
                        start_time: toDay,
                        end_time: toDay
                    },
                    () => {
                        this._initInfo();
                    }
                );
                break;
        }
    };

    formatData = (num: number) => {
        return num > 9 ? num : `0${num}`;
    };

    toDetail = (name: any, start:any, end:any) => {
        console.log('name', name, name === '第三方平台')
        // let {start_time, end_time} = this.state;
        if(!getIsPermisson('1_3_2_2')){
            messageCustom();
            return;
        }
        if(name === '系统收款'){
            this.props.history.push({
                pathname: "/sidebar/sysData",
                state: {
                    open_at: start,
                    close_at: end
                }
            });
        }else if(name === '第三方平台'){
            this.props.callBack('FINANCE_OTHER')
        }else if(name === '其它收入'){
            this.props.history.push({
                pathname: "/sidebar/otherIncome",
                state: {
                    open_at: start,
                    close_at: end
                }
            });
        }else if(name === '其它资金'){
            this.props.history.push({
                pathname: "/sidebar/otherCapital",
                state: {
                    open_at: start,
                    close_at: end
                }
            });
        }
    };

    //   详情
    toInfo = (record: any) => {
        if(!getIsPermisson('1_3_2_4')){
            messageCustom();
            return;
        }
        this.props.history.push({
            pathname: "/sidebar/financeDetail",
            state: {
                open_at: record.day,
                close_at: record.day
            }
        });
    };

    onCallback = (list:any) => {
        console.log('list', list);
        this.setState(
            {
                start_time: list[0],
                end_time: list[1]
            },
            () => {
                this._initInfo();
            }
        );
    }



    render() {
        let {
            list,
            current,
            pageSize,
            start_time,
            end_time,
            count,
            dataList
        } = this.state;
        let preview: any = this.state.preview;

        return (
            <div className="income" style={{padding: '20px'}}>
                <div style={{display: 'flex', alignItems:'center', justifyContent: 'space-between', paddingRight:'50px'}}>
                    <div>
                        <CustomDatePicker callback={(list: any) => { this.onCallback(list) }} current={4} currentDate={[start_time, end_time]}></CustomDatePicker>
                    </div>
                    <div className='export_btn' onClick={this.exportTable}>
                        <img src={goodsExport} />导出
                    </div>
                </div>

                <div className='business_wrap'>
                    {
                        dataList.map((item:any) => {
                            return <div className='business_item' key={item.title}>
                                <div className='business_top'>
                                    <div className='business_title'>{item.title}</div>
                                    {
                                        item.tips
                                        ? <div className='business_tip'>
                                            <Tooltip placement="topLeft" title={item.tips}>
                                                <Icon type="question-circle"  theme="filled" />
                                            </Tooltip>
                                        </div>
                                        : null
                                    }
                                </div>
                                <div className='business_value'><span>￥</span>{item.value}</div>
                                <div className='business_bottom'>
                                    {
                                        item.btn_text
                                        ?   <div className='business_btn' onClick={() => this.toDetail(item.title, this.state.start_time, this.state.end_time)}>
                                                <img src={new_detail_white} />
                                                <div>{item.btn_text}</div>
                                            </div>
                                        : null
                                    }
                                </div>
                            </div>
                        })
                    }
                </div>


            <div style={{background:'#fff'}} className='finance_table_wrap'>
                <Table
                    bordered
                    dataSource={list}
                    size="middle"
                    columns={this.column}
                    rowKey={"day"}
                    locale={{ emptyText: "暂无数据" }}
                    pagination={{
                        current,
                        total: count,
                        pageSize,
                        onChange: this.pageChange,
                        showQuickJumper: true,
                        showSizeChanger: true,
                        showTotal: (total: number) => `共${total}条`,
                        onShowSizeChange: (current: number, size: number) => { this.setState({ pageSize: size }, () => { this.getTimeData("currentMonth"); }) }
                    }}
                >
                </Table></div>


            </div>
        );
    }
}

export default NewBusinessData;