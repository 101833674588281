import React from 'react'
import { useEffect } from "react";

import './headNav.scss'

const HeadNav = (props) =>{

    useEffect(() =>{

    },[])

    return <div className="head_nav">
        <div className="nav_title">编辑小票样式</div>
        <div className="nav_right">
            <div className="cancel_button" onClick={props.recoveryCallBack}>恢复默认设置</div>
            <div className="cancel_button" onClick={props.cancelCallBack}>放弃编辑并返回</div>
            <div className="ok_button" onClick={props.okCallBack}>保存</div>
        </div>
    </div>
}

export default HeadNav;