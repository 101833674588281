import React from "react"
import styles from './decorationHeaderPreview.module.scss'
import { inject, observer } from "mobx-react"
import shopDecorationStore from '../../../../stores/shopDecoration.store'
import { getRequest, postRequest } from '../../../../api/http'
import { apiUrl } from '../../../../api/api_url'
import { message, Table, Icon, Tooltip, Upload } from 'antd'

interface IProps {
    dataObj: {
        left: string,
        right: Array<{
            type: number,
            txt: string
        }>
    },
    history?: any
}

interface IState {

}

@observer
class DecorationHeaderPreview extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            
        }
    }

    //点击事件
    onClickSubmit = async (type: any) => {
        const { merber_info, merber_interests, merber_assets, merber_menu, id } = shopDecorationStore.shop_decoration_wode;
        switch (type) {
            case '编辑页面':
                shopDecorationStore.updatePreview(0)
                break;
            case '使用':
                let params = {
                    id
                }
                let res: any = await postRequest(apiUrl.applyProfile, params)
                if(res.code === 1) {
                    message.success('操作成功！')
                } else {
                    message.error(res.message)
                }
                break;
            case '返回列表':
                this.props.history.goBack();
                setTimeout(() => {
                    shopDecorationStore.updatePreview(0)
                },500)
                break;
        
            default:
                break;
        }
    }

    render() {
        const { dataObj } = this.props;

        return (
            <div className={styles.header}>
                <div className={styles.header_left}>
                    <div className={styles.header_left_list}>
                        {
                            dataObj.left
                        }
                    </div>
                </div>
                <div className={styles.header_right}>
                    {
                        dataObj.right && dataObj.right.length > 0 && dataObj.right.map((item: any, index: number) => {
                            return (
                                item.type == 1
                                ?   <div key={item.txt + index} className={styles.header_right_list} onClick={() => {this.onClickSubmit(item.txt)}}>
                                        {
                                            item.txt
                                        }
                                    </div>
                                :   <div key={item.txt + index} className={styles.header_right_item} onClick={() => {this.onClickSubmit(item.txt)}}>
                                        {
                                            item.txt
                                        }
                                    </div>
                            )
                        })
                    }
                </div>
                
            </div>
        )
    }
}

export default DecorationHeaderPreview