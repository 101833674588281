import React, { useState, useCallback, useRef, useEffect } from 'react'
import { Modal, Button,Input, Radio, message } from "antd"
import { getRequest, postRequest } from '../../../api/http'
import { apiUrl } from '../../../api/api_url'

import './smsSetting.scss'

const { TextArea } = Input

type Iprops = {
    visible: boolean,
    onClose(): void,
    onOpen(): void,
    onOpenRechargeModal(): void,
    smsRefresh: boolean,
    smsSettingType: number
}

const SmsSetting = (props:Iprops)=>{

    const { visible, onClose, onOpen, smsRefresh, onOpenRechargeModal, smsSettingType } = props
    const [ smsInfo, setSmsInfo ] = useState<any>({})
    // const [ smsContent, setSmsContent ] = useState<any>('')
    const [ radioCheck, setRadioCheck ] = useState(false)

    useEffect(()=>{
        //查询签名申请信息
        getSmsInfo()        
    },[smsRefresh])

    const getSmsInfo = async() => {
        const res:any = await getRequest(apiUrl.getSmsInfo);
        if(res&&res.code==1){
            console.log(props)
            setSmsInfo(res.data)
            //签名模板替换
            // let template = res.data.template.replace('#coupon_name#',currentCoupon.name).replace('#day#',currentCoupon.expire_info.day).replace('#link#','www.baidu.com')
            // setSmsContent(template)
        }
    }

    const handleOk = async() => {

        if(!radioCheck&&smsSettingType!=1){
            message.error('请同意短信服务协议')
            return false
        }
        onClose()
        // const res:any = await getRequest(apiUrl.getSmsInfo);
        // if(res&&res.code==1){
        //     onClose()
        // }else{
        //     message.error(res.message||'配置短信通知失败')
        // }
    }

    return(
        <Modal
          title={null}
          visible={visible}
          onOk={handleOk}
          onCancel={onClose}
          width={455}
          className="sms_setting_modal"
          closable={false}
        >
            <div className="top">
                <span>剩余<span className="color_red">{smsInfo.number}条</span>短信{smsInfo.number<=100&&'，请尽快充值'}</span>
                {
                    smsInfo.number<=100&&<Button type="primary" onClick={()=>{onOpenRechargeModal()}}>去充值</Button>
                }
                {/* <Button type="primary" onClick={()=>{onOpenRechargeModal()}}>去充值</Button> */}
            </div>
            {
                smsSettingType!=1&&(
                    <>
                        <div className="title">短信设置</div>
                        <div className="row_view mb20">
                            <div className="sub_title" style={{marginTop:10}}>短信模板：</div>
                            <div style={{width:'100%'}}>
                                <TextArea rows={4} disabled placeholder="请输入短信内容" value={smsInfo.template} className="mb10"/>
                                {/* <div className="color_ccc">已输入10字(含签名和尾缀)，按1条短信计费</div> */}
                            </div>
                        </div>
                        <div className="row_view">
                            <div className="sub_title">短信签名：</div>
                            <div>
                                <div className="mb10">【{smsInfo.status==2?smsInfo.sign:'有点牛'}】<span className="edit" onClick={()=>{onOpen()}}>修改</span></div>
                                <div className="color_ccc mb20">每个自然月修改2次</div>
                                <Radio className="color333" checked={radioCheck} onClick={()=>{setRadioCheck(!radioCheck)}}>同意《短信服务协议》</Radio>
                            </div>
                        </div>
                    </>
                )
            }
        </Modal>
    )
}

export default SmsSetting