import React from 'react';
import InsideNav from '../../../component/insideNav/insideNav';
import { collectChildNav, getIsPermisson } from '../../../utils/permisstionUtil';
import FinanceBalance from '../../financeBalance';
import FinanceCash from '../../financeCash';
import FinanceForm from '../../financeForm';
import IncomeForm from '../../incomeForm';
import Recharge from '../../recharge/recharge';
import VegetableSaleTable from '../../vegetable_sale_table/vegetable_sale_table';
import FinanceOther from '../../financeOther/index';
import NewBusinessData from '../../newBusinessData/newbusinessData';

type IProps = {
    history: any
}
type IState = {
    navList: any
    current_key: string
}

const navTable = [
    {
        title: '新经营数据',
        isActive: false,
        KEY: "NEWBUSINESS",
        uni_key: '1_3_8'
    },
    {
        title: '营业收入',
        isActive: false,
        KEY: "INCOMEFORM",
        uni_key: '1_3_1'
    },
    {
        title: '财务收入',
        isActive: false,
        KEY: "FINANCEFPRM",
        uni_key: '1_3_2'
    },
    {
        title: '充值数据',
        isActive: false,
        KEY: "RECHARGE",
        uni_key: '1_3_3'
    },
    {
        title: '菜品销量',
        isActive: false,
        KEY: "VEGETABLESALE_TABLE",
        uni_key: '1_3_4'
    },
    {
        title: '会员对账',
        isActive: false,
        KEY: "FINANCE_BALANCE",
        uni_key: '1_3_5'
    },
    {
        title: '代金劵消费对账',
        isActive: false,
        KEY: "FINANCE_CASH",
        uni_key: '1_3_6'
    },
    {
        title: '第三方数据',
        isActive: false,
        KEY: "FINANCE_OTHER",
        uni_key: '1_3_7'
    },
]

class FinaceAndData extends React.Component<IProps,IState>{

    constructor(props:IProps) {
        super(props);
        this.state = {
            navList: [],
            current_key: "",
        }
    }

    componentDidMount(){
        this.initPageState();
    }

    initPageState = () =>{
        let navList = collectChildNav(navTable);
        let currentKey = localStorage.getItem('current_key') ?? navList[0].KEY;
        let isSetKey = false;
        navList.map((item:any) => {
            if(item.KEY == currentKey){
                item.isActive = true;
                isSetKey = true;
            }else{
                item.isActive = false;
            }
        })
        if(!isSetKey){
            currentKey = navList[0].KEY;
            navList[0].isActive = true;
        };
        this.setState({
            current_key: currentKey,
            navList: navList
        })
    }

    navCallBack(currentKey: string){
        this.setState({
            current_key: currentKey,
        })
    }

    navChange(){
        let _navList = JSON.parse(JSON.stringify(this.state.navList));
        _navList.map((item:any) => item.isActive = false);
        _navList[7].isActive = true;
        this.setState({
            navList: _navList,
            current_key: 'FINANCE_OTHER'
        })
    }

    render() {
        let { navList,current_key } = this.state;
        return <div>
            <InsideNav navList={navList} callBack={(key: string) =>this.navCallBack(key)}/>
            <div className="body">

                {
                    current_key === 'INCOMEFORM' ?  <IncomeForm history={this.props.history}  />
                    :
                    current_key === 'FINANCEFPRM' ?  <FinanceForm history={this.props.history}  />
                    :
                    current_key === 'RECHARGE' ?  <Recharge location={{}} />
                    :
                    current_key === 'VEGETABLESALE_TABLE' ?  <VegetableSaleTable />
                    :
                    current_key === 'FINANCE_BALANCE' ?  <FinanceBalance history={this.props.history}/>
                    :
                    current_key === 'FINANCE_CASH' ?  <FinanceCash history={this.props.history} />
                    :
                    current_key === 'FINANCE_OTHER' ?  <FinanceOther history={this.props.history}/>
                    :
                    current_key === 'NEWBUSINESS' ? <NewBusinessData history={this.props.history} callBack={(key: string, other:string) =>this.navChange()} />
                    : <div />
                }
            </div>
        </div>
    }

}

export default FinaceAndData;