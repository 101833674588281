import React from 'react';
import { Row, Col } from 'antd';
import '../management.scss';

import winAddChargefee1 from '../../../../../../assret/images/winAddChargefee1.png';
import winAddChargefee2 from '../../../../../../assret/images/winAddChargefee2.png';

interface HelpMbaChargeFree {
    history: any,
}

class HelpMbaChargeFree extends React.Component<HelpMbaChargeFree> {

    render() {
        return  <Row className="typeDetail">
                    <Col className="typeDetail-title">一、功能介绍</Col>
                    <Col className="typeDetail-col">充值并买单，实现留存顾客。</Col>
                    <Col className="typeDetail-title">二、功能路径</Col>
                    <Col className="typeDetail-col">电脑端登录后台-营销管理-充值免单列表-添加充值免单。</Col>
                    <Col className="typeDetail-col">
                        <img src={winAddChargefee1} />
                    </Col>
                    <Col className="typeDetail-title">三、操作流程</Col>
                    <Col className="typeDetail-col">1、<span className="txt-bold">添加充值免单</span>，在“充值免单列表”界面下点击“添加免单”</Col>
                    <Col className="typeDetail-col">
                        <img src={winAddChargefee2} />
                    </Col>
                    <Col className="typeDetail-col">1、优惠名称：填写名称</Col>
                    <Col className="typeDetail-col">2、有效期：可以填写固定日期或永久日期</Col>
                    <Col className="typeDetail-col">3、使用消费条件：消费满多少元，充值多少倍免单，例如：设置消费满100元，充值3倍免单，当用户买单时，实付价格满足条件时，可选择充值3倍获得免单，当用户选择余额支付则不能获得充值免单活动资格</Col>
                    <Col className="typeDetail-col">4、单人参与次数：默认选择无限，当选择单人最多参与，填写最多参与次数；参与次数就是用户能参加多少次这个活动</Col>
                    <Col className="typeDetail-col">5、活动用户：可选择所有用户或部分用户，部分用户中可选择新用户或老用户</Col>
                    <Col className="typeDetail-col">6、状态：启用和停用两种状态，默认选择启用，当选择停用，则用户不能参与该活动</Col>
                </Row>
    }
}

export default HelpMbaChargeFree;