import React, { useEffect, useState } from 'react'
import { Modal, Row, Avatar, Table, message, Icon, Input, Col, DatePicker } from 'antd'
import { memberColumns } from '../group_members'
import { getRequest, postRequest } from '../../../../../../api/http'
import { apiUrl } from '../../../../../../api/api_url'

const { Search } = Input;
const { RangePicker } = DatePicker;

type IProps = {
    groupId: number,
    showEditGroup: boolean
    hideModal(show: boolean): void
}

type ImemberItem = {
    balance: string
    create_time: string
    headimgurl: string
    id: number
    level: number
    mp_id: number
    mp_type: number
    nick_name: string
    nickname: string
    order_count: number
    phone_number: string
    score: number
}

type ImemberData = {
    count: number,
    list: Array<ImemberItem>
}

type IfliterData = {
    [key: string]: string
}

let fliterData: IfliterData = {
    keyword: '',
}

let param: any = {
    low_times: '',
    high_times: '',
    low_dat: '',
    high_date: ''
}

let times:any = [];      // 时间区间
let keywords = "";  //用户昵称
let low_times = ''; //最低次数
let high_times = ''; //最高次数
let low_date = ""; //非必传
let high_date = ""; //非必传

const EditGroutMembers = (props: IProps) => {
    const { showEditGroup, hideModal, groupId } = props;
    const [currSelectedRow, setcurrSelectedRow] = useState<Array<ImemberItem>>([]);
    const [memberData, setmemberData] = useState<ImemberData>({
        count: 0,
        list: []
    });
    const [selectedRowKeys, setselectedRowKeys] = useState<Array<any>>([]);
    useEffect(() => {
        getMemberList();
        //console.log('噢噢噢噢噢噢噢噢哦哦哦哦哦哦哦哦哦')
    }, [props.showEditGroup]);

    const getMemberList = async (page = 1) => {
        const data = {
            page_size: 8,
            page: page
        }
        const res: any = await getRequest(`${apiUrl.membersNoInGroup}${groupId}`, data);
        if (res && res['code'] === 1) {
            setmemberData(res.data);
        }
    }

    // 把会员添加到分组
    const addMemberToGroup = async () => {
        const res: any = await postRequest(`${apiUrl.addMemberToGroup}${props.groupId}`, { member_ids: selectedRowKeys.toString() });
        if (res && res['code'] === 1) {
            message.success('添加成功');
            setselectedRowKeys([]);
            setcurrSelectedRow([]);
            getMemberList();
            hideModal(false);
        }
    }

    const tapAvatar = (id: number) => {
        const idx = currSelectedRow.findIndex((item) => item.id === id);
        if (idx > -1) {
            currSelectedRow.splice(idx, 1);
            selectedRowKeys.splice(idx, 1)
        }
        setselectedRowKeys([...selectedRowKeys]);
        setcurrSelectedRow([...currSelectedRow]);
    }

    const onModalCancel = (show: boolean) => {
        hideModal(show);
        setcurrSelectedRow([]);
        setselectedRowKeys([]);
    }

    // 搜索
    const searchKey = async (val = {}) => {
        console.log(times)
        if(times.length > 0){
            param.low_date = times[0];
            param.high_date = times[1]
        }
        Object.keys(param).map(el => {
            if(!param[el]) delete param[el]
        })
        
        const data = {
            ...param,
            keywords,
            page_size: 8,
            page: 1
        }

        Object.keys(data).map(el => {
            if(!data[el]) delete data[el]
        })
        
        console.log(data)
        const res: any = await getRequest(`${apiUrl.membersNoInGroup}${groupId}`, data);
        if (res && res['code'] === 1) {
            setmemberData(res.data);
        }
    }

    return <Modal
        title="编辑会员"
        visible={showEditGroup}
        onOk={addMemberToGroup}
        onCancel={() => onModalCancel(false)}
        destroyOnClose={true}
        style={{
            height: '80vh'
        }}
        width="80vw"
    >
        <Row style={{ marginRight: 20, marginTop: 20, marginBottom: 20 }}>
            <Col span={6}>
                用户昵称或手机号：
                {/* <Search
                    placeholder="请输入用户昵称或手机号"
                    onSearch={value => {
                        keyword = value;
                        searchKey({ nickname: value });

                    }}
                    style={{ width: 200, marginRight: 20 }}
                /> */}
                <Search
                    placeholder="请输入用户昵称或手机号"
                    onSearch={value => {
                        keywords = value
                        searchKey({ keywords: value });
                    }}
                    style={{ width: 200, marginRight: 20 }}
                />  
            </Col>
            <Col span={10}>
                消费次数：
                <Search
                    placeholder="请输入消费次数"
                    onSearch={value => {
                        param.low_times = value;
                        searchKey({ low_times: value, high_times: value });
                    }}
                    onChange={num => {
                        param.low_times = num.target.value;
                    }}
                    style={{ width: 200, marginRight: 10 }}
                />—
                <Search
                    placeholder="请输入消费次数"
                    onSearch={value => {
                        param.high_times = value;
                        searchKey({ low_times: low_times, high_times: value });

                    }}
                    onChange={num => {
                        param.high_times = num.target.value;
                    }}
                    style={{ width: 200, marginLeft: 10, marginRight: 20 }}
                />
            </Col>
            <Col span={8}>
                最近消费时间：
                <RangePicker
                    format={`YYYY-MM-DD`}
                    showTime={{ format: 'YYYY-MM-DD' }}
                    onChange={(e:any, dateString) => {
                        times = dateString;
                        searchKey();
                    }}
                    placeholder={['开始时间', '结束时间']} />
            </Col>
        </Row>
        <Row style={{ background: '#eee', padding: 10, flexWrap: "wrap" }} type="flex">
            {
                currSelectedRow.map((el: ImemberItem) => {
                    return <Row key={el.create_time} style={{ textAlign: "center", marginRight: 10, }} onClick={() => tapAvatar(el.id)}>
                        <Row style={{ position: "relative" }}>
                            <Avatar src={el.headimgurl} />
                            <Icon type="close-circle"
                                style={{
                                    position: "absolute",
                                    top: 0, left: '50%',
                                    transform: "translate(-50%, -50%)",
                                    fontSize: 20,
                                    color: "#fff",
                                    background: "rgba(0,0,0,.5)",
                                    borderRadius: "50%",
                                }} />
                        </Row>
                        <Row>{el.nickname}</Row>
                    </Row>
                })
            }

        </Row>

        <Table
            style={{ marginTop: 30 }}
            rowKey={"id"}
            pagination={{
                pageSize: 8,
                total: memberData.count,
                onChange: (e) => getMemberList(e)
            }}
            rowSelection={{
                type: "checkbox",
                selectedRowKeys: selectedRowKeys,
                onChange: (e, rows) => {
                    setselectedRowKeys(e);
                    setcurrSelectedRow(rows);
                }
            }}
            columns={memberColumns}
            dataSource={memberData.list}
            size="small"
        />
    </Modal>
}

export default EditGroutMembers