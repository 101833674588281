import React, { useEffect, useState } from "react";

import { getRequest } from "./../../../api/http";
import { apiUrl } from "./../../../api/api_url";
import {Row,Col,Table, Popconfirm, Tooltip, Switch, message, } from 'antd'
import { useHistory } from "react-router";

import t_delete from '../../../assret/images/t_delete.png'
import system_select from '../../../assret/images/system_select.png'

import styles from './recharge_manage.module.scss'
import { getIsPermisson, messageCustom } from "../../../utils/permisstionUtil";

const RechargeManage = ()=>{

    const history = useHistory()

    const [rechargeList,setRechargeList] = useState<any>([])

    const [tabList, setTabList] = useState(10);

    const [addEditShow,setAddEditShow] = useState(false)

    const [rechargeStatus, setRechargeStatus] = useState(false)

    const [allStatus, setallStatus] = useState(false)

    const [recharge_config, setrecharge_config] = useState('')
    const [member_type, setmember_type] = useState(0)
    const [charge_value_least, setcharge_value_least] = useState(0)

    useEffect(()=>{
        getRechargeList()
        onGetSelfRecharge()
    },[])

    //获取自定义充值状态
    const onGetSelfRecharge = async () => {
        const res:any = await getRequest(`${apiUrl.getSelfRecharge}`);
        if(res.code == 1) {
            setRechargeStatus(res.data.status)
            setallStatus(res.data.charge_status == 1 ? true : false)
        }
    }

    // 删除
    const deleteAction = (item:any) => {
        if(!getIsPermisson('1_6_4_5')){
            messageCustom();
            return;
          }
        let param = {
            id: item.id,
        }
        getRequest(`${apiUrl.welfareConfigDelete}`,param).then((res:any) => {
            if(res.code === 1){
                getRechargeList()
                onGetSelfRecharge()
            }
        });
    }

    // 暂停开启
    const stopAction = (item:any) => {
        if(!getIsPermisson('1_6_4_4')){
            messageCustom();
            return;
          }
        let param = {
            id: item.id,
            is_effect: item.is_effect == 1 ? 2 : 1
        }
        getRequest(`${apiUrl.welfareConfigStatus}`,param).then((res:any) => {
            if(res.code === 1){
                getRechargeList()
                onGetSelfRecharge()
            }
        });
    }

    const getRechargeList = async (page=1)=>{
        let param = {
            page: page,
            pageSize: 10
        }
        const res:any = await getRequest(`${apiUrl.welfareConfigList}`,param).then((res:any) => {
            setRechargeList((data:any) => data = res.data.list)
            setTabList((data) => data = res.data.total)
            setrecharge_config((data) => data = res.data.recharge_config)
            setmember_type((data) => data = res.data.member_type)
            setcharge_value_least((data) => data = res.data.charge_value_least)
        });
        return res;
    }

    // 翻页
    const pageOnChange = (page: number) => getRechargeList(page)

    const columns = [
        {
            title: '档位',
            dataIndex: 'id',
            key: 'id',
            width: 40,
            className: `${styles.mr20}`,
            render: (rowData: any, record: any, index: number ) => {
                return <div>第{index+1}档</div>
            }
        },
        {
            title: '充值金额',
            dataIndex: 'charge_fee',
            key: 'charge_fee',
            width: 80,
        },
        {
            title: '赠送金额',
            dataIndex: 'present_fee',
            key: 'present_fee',
            width: 80,
        },
        {
            title: '赠送券',
            dataIndex: 'coupon',
            key: 'coupon',
            width: 80,
            render: (rowData: any, record: any) => {
                return <div>
                    {rowData && rowData.length > 0 ?
                    rowData.map((item: any) => {
                        return <div key={item.id}>{item.name}</div>
                    }):'——'}
                </div>
            }
        },
        {
            title: '状态',
            dataIndex: 'is_effect',
            key: 'is_effect',
            width: 80,
            render:(is_effect:any,record:any,idx:number) => {
                return (
                    <div>
                        {
                            is_effect == 1
                            ? <div>已开启</div>
                            : <div>已停用</div>
                        }
                    </div>
                )
            }
        },
        {
            title: '可用门店',
            dataIndex: 'shops',
            key: 'shops',
            width: 80,
            render: (rowData: any,  record: any) => {
                return <div>
                    {rowData && rowData.length > 0 ?
                     rowData.map((item: any) => {
                        return <div key={item.id}>{item.name}</div>
                    }): '——'}
                </div>
            }
        },
        {
            title: '创建时间',
            dataIndex: 'created_at',
            key: 'created_at',
            width: 80,
        },
        {
            title: '创建人',
            dataIndex: 'nickname',
            key: 'nickname',
            width: 80,
        },
        {
            title: '操作',
            key: 'action',
            width: 80,
            render:(rowdata:any,record:any,idx:number) => {
                return (
                    <div>
                        <Popconfirm
                            placement="bottomRight"
                            title={rowdata.is_effect == 1 ? '是否停用？' : '是否启用？'}
                            onConfirm={()=>{stopAction(rowdata)}}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Switch checked={rowdata.is_effect == 1} checkedChildren="开启" unCheckedChildren="停用" />
                        </Popconfirm>
                        <Popconfirm
                            placement="bottomRight"
                            title={'是否删除？'}
                            onConfirm={()=>{deleteAction(rowdata)}}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Tooltip placement="bottomRight" title={'删除'}>
                                <img src={t_delete} className='table_icon'></img>
                            </Tooltip>
                        </Popconfirm>
                    </div>
                )
            }
        },
    ]

    //设置自定义充值
    const onChange = async (checked: any) => {
        if(!getIsPermisson('1_6_4_3')){
            messageCustom();
            return;
          }
        const res:any = await getRequest(`${apiUrl.setSelfRecharge}`);
        if(res.code == 1) {
            setRechargeStatus(checked)
        }
    }

    const toEdit = () => {
        if(!getIsPermisson('1_6_4_1')){
            messageCustom();
            return;
          }
        history.push({pathname: '/sidebar/rechargeAddOrEdit',state: {rechargeList: rechargeList, recharge_config: recharge_config, member_type: member_type, charge_value_least: charge_value_least}})
    }

    const allStatusChange = async () => {
        if(!getIsPermisson('1_6_4_2')){
            messageCustom();
            return;
          }
        let param = {is_effect: allStatus ? 2 : 1}
        let res:any = await getRequest(apiUrl.welfareConfigStatusAll, param)
        if(res.code == 1){
            message.success(res.message)
            getRechargeList()
            onGetSelfRecharge()
        }else{
            message.error(res.message)
        }
    }

    return (
        <div style={{margin: '20px'}} className={styles.charge}>
            <div className={styles.charge_top}>
                <div className={styles.charge_l}>
                    <div className='new_btn' onClick={toEdit}>
                        <img src={system_select} />
                        编辑配置
                    </div>
                    <div>
                        <div className={styles.charge_rule}>
                            余额使用规则：{rechargeList[0] && rechargeList[0].freeze_percentage && rechargeList[0].freeze_percentage != 0 ? `有限制 ”充值的当天” 不可使用金额的${rechargeList[0].freeze_percentage}%，其余部分均可以使用` : '无限制' }</div>
                        {
                            rechargeList.length <= 0
                            ? <div className={styles.charge_no_data}>提示：您的充值配置还未配置，请尽快编辑规则进行补充</div>
                            : null
                        }
                    </div>
                </div>

                <div className={styles.charge_status}>
                    <div className={styles.charge_status} style={{marginRight: '30px'}}>充值配置功能开关：  <Switch checked={allStatus} onChange={allStatusChange} checkedChildren="开启" unCheckedChildren="关闭" /></div>
                    <div className={styles.charge_status}>自定义充值： <Switch checked={rechargeStatus} onChange={onChange} checkedChildren="开启" unCheckedChildren="关闭" /></div>
                </div>
            </div>

            <div style={{display: `${!addEditShow ? 'block' : 'none'}`}} className='l_content'>
                <Row>
                    <Col span={24}>
                        <Table
                            dataSource={rechargeList} rowKey={'id'}
                            columns={columns}
                            size="middle"
                            pagination={{
                                pageSize: 10,
                                total: tabList,
                                onChange: (e: number) => pageOnChange(e)
                            }}
                        />
                    </Col>
                </Row>
           </div>
        </div>
    )
}

export default RechargeManage