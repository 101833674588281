import { observable, action } from "mobx";

export interface IUserGroupStore {
  list: any,
  setList(list: any):void
}


export class UserGroupStore implements IUserGroupStore {
  @observable
  list = [];

  @action
  setList = (list:any) => this.list = list;
}