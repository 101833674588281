import React, { useEffect, useState } from 'react'
import { Tabs } from 'antd';
import { getRequest, postRequest } from '../../../api/http';
import { apiUrl } from '../../../api/api_url';
import VipGroup from './sub/vip_group/vip_group'
import RecommendGroup from './sub/recommend_group'
import { stores } from '../../../stores/index.store'

import './index.scss'

const { TabPane } = Tabs

const GroupIndex = (props:any)=>{
    // console.log('props.appStore.groupTab:',stores.appStore.groupTab)
    const [ currentTab, setCurrentTab ] = useState('recommend')

    useEffect(()=>{
        setCurrentTab(stores.appStore.groupTab)
    },[])

    //tab切换
    const onChangeTabs = (e:any)=>{
        setCurrentTab(e)
        stores.appStore.setGroupTab(e)
    }
 
    return(
        <div className="group_index">
            {/* <div className="title_text">用户分组</div> */}
            <div className='group_tab_wrap mb30'>
                <div className={`group_tab_item ${stores.appStore.groupTab == 'custom' ? 'group_tab_active' : ''}`} onClick={() => {onChangeTabs('custom')}}>自定义分组</div>
                <div className={`group_tab_item ${stores.appStore.groupTab == 'recommend' ? 'group_tab_active' : ''}`} onClick={() => {onChangeTabs('recommend')}}>推荐分组</div>
            </div>

            {
                currentTab == 'custom'
                ? <VipGroup currentTab={currentTab}/>
                : <RecommendGroup currentTab={currentTab}/>
            }
            {/* <Tabs defaultActiveKey={stores.appStore.groupTab} onChange={onChangeTabs} type="card">
                <TabPane tab="自定义分组" key="custom">
                    <VipGroup currentTab={currentTab}/>
                </TabPane>
                <TabPane tab="推荐分组" key="recommend">
                    <RecommendGroup currentTab={currentTab}/>
                </TabPane>
            </Tabs> */}
        </div>
    )

}

export default GroupIndex