import React, { useEffect, useState } from 'react'
import { getRequest } from '../../../api/http'
import { apiUrl } from '../../../api/api_url'
import { message, Row, Col, Table, Tag, Popconfirm, Button } from 'antd'
import { getIsPermisson, messageCustom } from '../../../utils/permisstionUtil'
const MessagePushList = ()=>{

    const[tableSource,setTableSource] = useState([])

    useEffect(()=>{
        getList()
    },[])

    const getList = async()=>{
        const res:any = await getRequest(`${apiUrl.messagePushLog}`,{pageSize:10,current:1});
        if(res.code === 1){
            setTableSource(res.data)
        }else{
            message.error(res.message)
        }
    }


    const deleteAction = async (id:any) => {
        if(!getIsPermisson('2_4_2_1')){
            messageCustom();
            return;
          }
        const res:any = await getRequest(`${apiUrl.messagePushLogDelete}`,{id:id});
        if(res.code === 1){
            getList();
            message.info('删除成功');
        }else{
            message.error(res.message)
        }
    }

    const columns = [
        {
          title: '编号',
          dataIndex: 'id',
          key: 'id',
          width: 80,
        },
        {
            title: '推送任务ID',
            dataIndex: 'task_id',
            key: 'task_id',
            width: 80,
        },
        {
            title: '小程序用户ID',
            dataIndex: 'fans_id',
            key: 'fans_id',
            width: 80,
            render:(rowdata:any,record: any, idx: number)=>{
                return (
                    <div>{rowdata === 1 ? <Tag color="#87d068">所有用户</Tag> : rowdata}</div>
                )
            }
        },
        {
            title: '推送标题',
            dataIndex: 'title',
            key: 'title',
            width: 80,
        },
        {
            title: '推送内容',
            dataIndex: 'content',
            key: 'content',
            width: 80,
        },
        {
            title: '通知类型',
            dataIndex: 'type',
            key: 'type',
            width: 80,
            render:(rowdata:any,record: any, idx: number)=>{
                return (
                    <div>{
                        rowdata === 1 ? <Tag color="#87d068">系统通知</Tag> :
                        rowdata === 2 ? <Tag color="#ff0000">优惠通知</Tag> : <p></p>
                    }</div>
                )
            }
        },
        {
            title: '推送状态',
            dataIndex: 'status',
            key: 'status',
            width: 80,
            render:(rowdata:any,record: any, idx: number)=>{
                return (
                    <div>{
                        rowdata === 1 ? <Tag color="#87d068">推送成功</Tag> :
                        rowdata === 2 ? <Tag color="#ff0000">推送失败</Tag> : <p></p>
                    }</div>
                )
            }
        },
        {
            title: '推送类型',
            dataIndex: 'msg_type',
            key: 'msg_type',
            width: 80,
            render:(rowdata:any,record: any, idx: number)=>{
                return (
                    <div>{
                        rowdata === 1 ? <Tag color="#87d068">自定义消息</Tag> :
                        rowdata === 2 ? <Tag color="#ff0000">模板消息</Tag> : <p></p>
                    }</div>
                )
            }
        },
        {
            title: '推送说明',
            dataIndex: 'explain',
            key: 'explain',
            width: 80,
        },
        {
            title: '是否有有效期',
            dataIndex: 'is_expire',
            key: 'is_expire',
            width: 80,
            render:(rowdata:any,record: any, idx: number)=>{
                return (
                    <div>{
                        rowdata === 1 ? <Tag color="#87d068">有</Tag> :
                        rowdata === 2 ? <Tag color="#ff0000">无</Tag> : <p></p>
                    }</div>
                )
            }
        },
        {
            title: '创建时间',
            dataIndex: 'created_at',
            key: 'created_at',
            width: 80,
        },
        {
            title: '推送时间',
            dataIndex: 'push_at',
            key: 'push_at',
            width: 80,
        },
        {
            title: '消息过期时间',
            dataIndex: 'expire_at',
            key: 'expire_at',
            width: 80,
        },
        {
            title: '跳转地址',
            dataIndex: 'jump_url',
            key: 'jump_url',
            width: 80,
        },
        {
            title: '操作',
            key: 'action',
            width: 80,
            render:(rowdata:any,record:any,idx:number) => {
                return (
                    <div>
                        <Popconfirm
                            placement="bottomRight"
                            title={'是否删除？'}
                            onConfirm={()=>{deleteAction(rowdata.id)}}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button>删除</Button>
                        </Popconfirm>
                    </div>
                )
            }
        },
    ]

    return (
        <div className='l_content'>
            <Row>
                <Col span={24}>
                    <Table dataSource={tableSource} size="middle" rowKey={'id'} columns={columns} />
                </Col>
            </Row>
        </div>
    )
}

export default MessagePushList
