import React, { useState, useEffect } from 'react'
import { Row, Typography, Input, Button, message, Select, Form } from 'antd'
import { useHistory } from 'react-router-dom';
import { postRequest, getRequest } from '../../../api/http';
import { apiUrl } from '../../../api/api_url';

import './add_vip_group.scss'

const { Title } = Typography;
const { Option } = Select;


const AddVipGroup = () => {

  const history = useHistory();
  const [groupName, setgroupName] = useState('');
  const [searchData, setSearchData] = useState<any>({
    condition: '1',
    config: [
      [],
      {day: "", low_limit: "", high_limit: "", state: false},
      [],
      {day: "", low_limit: "", high_limit: "", state: false},
    ]
  })
  const [groupDetailList, setGroupDetailList] = useState<any>( {
    condition: '1',
    config: [
      [],
      {low_limit: "", high_limit: "", state: false},
      [],
      {low_limit: "", high_limit: "", state: false},
    ]
  });
  const [consumptiontimes, setConsumptiontimes] = useState([
    {day: "", low_limit: "", high_limit: "", state: false},
  ])
  const [consumptionamount, setConsumptionamount] = useState([
    {day: "", low_limit: "", high_limit: "", state: false},
  ])
  const [tagList, setTagList] = useState([{ type: false, txt: '消费次数' }, { type: false, txt: '单次消费' }, { type: false, txt: '累计消费金额' }, { type: false, txt: '账户余额' }]);

  const goBack = () => history.goBack()

  const [selectState, setSelectState] = useState(1)

  const [rowData, setrowData] = useState<any>();

  useEffect(() => {
    const state: any = history.location.state;
    if (state && state.rowdata) {
      setrowData(state.rowdata);
      setgroupName(state.rowdata.name);
      ongetGroupDetail(state.rowdata.id);
    }
  }, [])

  //获取详情
  const ongetGroupDetail = async (id: any) => {
    let params = {
      id: id
    }
    const res: any = await getRequest(apiUrl.groupDetail, params);
    if (res.code === 1) {
      setSelectState(res.data.condition)
      if(JSON.stringify(res.data.config) == '[]') {
        let temp:any = {
          condition: '1',
          config: [
            {day: "", low_limit: "", high_limit: "", state: false},
            {low_limit: "", high_limit: "", state: false},
            {day: "", low_limit: "", high_limit: "", state: false},
            {low_limit: "", high_limit: "", state: false},
          ]
        }
        setGroupDetailList(temp);  
      } else {
        if(res.data.config[0].length > 0) {
          setConsumptiontimes(res.data.config[0]);
        }
        if(res.data.config[2].length > 0) {
          setConsumptionamount(res.data.config[2]); 
        }
        //处理后台返回的数据，全部统一转化
        let temp:any = [];
        let arr_temp:any = [];
        let tag_list = tagList;
        res.data.config.map((item:any, index:any) => {
          if(JSON.stringify(res.data.config[index]) == '[]') {
            if(index === 0 || index === 2) {
              temp[index] = [];
            } else {
              temp[index] = {low_limit: "", high_limit: "", state: false}
            }
          } else {
            item.day = '';
            item.state = false;
            temp[index] = item;
            if(item.low_limit) {
              tag_list[index]['type'] = true;
            }
            if(index === 0 || index === 2) {
              tag_list[index]['type'] = true;
            }
          }
        })
        //searchData['config'] = temp;
        setGroupDetailList({condition:res.data.condition,config: temp});
        setSearchData({condition: res.data.condition,config: temp});
        setTagList([...tag_list]);
        //searchData['condition'] = res.data.condition;
      }
    }
  }

  const saveMemberGroup = async () => {
    let flag = false;
    let array_empty = false;
    let last_empty = true;
    if (!groupName) {
      message.error('请填写分组名称');
      return;
    }
    let param: any = {
      name: groupName,
      ...searchData
    }
    try {
      param.id = rowData.id
    } catch (error) {
      
    };
    param.config.forEach(async (element: any, index:any) => {
      if(tagList[index]['type']) {
        if(isObjEmpty(element,index)) {
          flag = true;
        }
      }
      if(index == 1 || index == 3) {
        delete param.config[index]['state']
        if(element['low_limit'] == '') {
          array_empty = true;
        } else {
          last_empty = false;
        }
      }
      if(index == 0 || index == 2) {
        if(JSON.stringify(element) == '[]') {
          array_empty = true;
        } else {
          last_empty = false;
        }
      }
    });
    if(!flag) {
      param.config = JSON.stringify(param.config);
      //判断如果没有条件，就上传空的config
      if(array_empty && last_empty) {
        param.config = '[]';
      }
      const res: any = await postRequest(apiUrl.groupSave, param);
      if(res && res['code'] === 1) {
        message.success('保存成功');
        goBack();
      }
    }
  }

  //判断对象是否有空值
  const isObjEmpty = (obj: any,idx: any) => {
    let falg = false;
    let is_obj = false;
    let is_obj_size = false;
    //判断是消费次数或者累计消费金额
    if(idx === 0 || idx === 2) {
      if(idx === 0) {
        consumptiontimes.forEach((item:any, idx) => {
          delete item.state;
          if(item.high_limit !== '-1') {
            if(Number(item.low_limit) > Number(item.high_limit)) {
              is_obj_size = true;
            }
          }
          Object.keys(item).forEach((e, i) => {
            if(!item[e]) {
              is_obj = true;
            }
          })
        })
      }
      if(idx === 2) {
        consumptionamount.forEach((item:any, idx) => {
          delete item.state;
          if(item.high_limit !== '-1') {
            if(Number(item.low_limit) > Number(item.high_limit)) {
              is_obj_size = true;
            }
          }
          Object.keys(item).forEach((e, i) => {
            if(!item[e]) {
              is_obj = true;
            }
          })
        })
      }
      if(is_obj) {
        if(idx === 0) {
          message.error('请填写完整的消费次数！')
          return true;
        }
        if(idx === 2) {
          message.error('请填写完整的累计消费金额！')
          return true;
        }
      } else {
        if(idx === 0) {
          if(is_obj_size) {
            message.error('开始消费次数不能大于结束消费次数！')
            return true;
          } else {
            return false;
          }
        }
        if(idx === 2) {
          if(is_obj_size) {
            message.error('开始累计消费不能大于结束累计消费！')
            return true;
          } else {
            return false;
          }
        }
      }
    } else {
      //console.log(obj)
    }
    delete obj.state;
    Object.keys(obj).forEach((element,i) => {
      if(element !== 'day') {
        if(!obj[element]) {
          falg = true;
        }
      }
    })
    if(falg) {
      if(idx === 1) {
        message.error('请填写完整的单次消费！')
        return true;

      } else if(idx === 3) {
        message.error('请填写完整的账户余额！')
        return true;
      }
    } else {
      if(idx === 1) {
        if(Number(obj.low_limit) >= Number(obj.high_limit)) {
          message.error('单次消费开始金额不能大于结束金额！')
          return true;
        } else {
          return false;
        }
      } else if(idx === 3) {
        if(Number(obj.low_limit) >= Number(obj.high_limit)) {
          message.error('开始账户余额不能大于结束账户余额！')
          return true;
        } else {
          return false;
        }
        
      }
    }
  }

  //判断数组是否为空
  const isArrayEmpty = (arr:any, idx:any) => {
    let flah = false;
    if(arr) {
      flah = true
    } else {
      arr.forEach((element:any) => {
        Object.keys(element).forEach((item) => {
          if(!element[item]) {
            flah = true
          }
        })
      });
    }
    return flah
  }

  // 选择的条件
  const inputValuechange = async (key: string, type: string, val: any, valkey: string, idx:any) => {
    if(idx === -1 || idx === -3) {
      searchData[key][type][valkey] = val;
      searchData[key][type]['day'] = 0;
      setSearchData({...searchData});
      setGroupDetailList({...searchData})
    } else {
      if(type === '0') {
        let temp_arr:any = consumptiontimes;
        temp_arr[idx][valkey] = val;
        let temp_search = searchData;
        temp_search['config'][0] = temp_arr;
        let config = temp_search.config;
        setSearchData({condition: temp_search.condition,config:config});
        setConsumptiontimes(temp_arr)
      }
      if(type === '2') {
        let temp_arr:any = consumptionamount;
        temp_arr[idx][valkey] = val;
        let temp_search = searchData;
        temp_search['config'][2] = temp_arr;
        let config = temp_search.config;
        setSearchData({condition: temp_search.condition,config:config});
        setConsumptionamount(temp_arr)
      }
    }
    
  }

  const onCondition = (e: any) => {
    searchData['condition'] = e;
    setSelectState(e)
  }

  const onTag = (index: any) => {
    if(tagList[index].type) {
      if(index === 0 || index === 2) {
        setConsumptiontimes([])
        setConsumptionamount([])
      } else {
          let list:any = groupDetailList;
          list['config'][index] = {low_limit: "", high_limit: "", state: false}  
          setSearchData({condition: list.condition,config: list['config']});
      }
    }
    let temp_consumptiontimes:any = consumptiontimes;
    let temp_consumptionamount:any = consumptionamount;
    setConsumptiontimes(temp_consumptiontimes)
    setConsumptionamount(temp_consumptionamount)
    tagList[index].type = !tagList[index].type;
    setTagList([...tagList])
  }

  //消费次数新增
  const consumptionTimeAdd = () => {
    let temp_consumptiontimes:any = consumptiontimes;
    temp_consumptiontimes.push({day: "", low_limit: "", high_limit: "", state: false})
    setConsumptiontimes([...temp_consumptiontimes])
  }

  const consumptionTimeDel = (idx:any) => {
    if(consumptiontimes.length > 1) {
      let temp_consumptiontimes:any = consumptiontimes;
      temp_consumptiontimes.splice(idx,1)
      setConsumptiontimes([...temp_consumptiontimes])
    } else {
      tagList[idx].type = !tagList[idx].type;
      setTagList([...tagList])
    }
  }

  //累计消费金额
  const consumptionAmoutAdd = () => {
    let temp_consumptionamount:any = consumptionamount;
    temp_consumptionamount.push({day: "", low_limit: "", high_limit: "", state: false})
    setConsumptionamount([...temp_consumptionamount])
  }

  //
  const consumptionAmoutDel = (idx:any) => {
    if(consumptionamount.length > 1) {
      let temp_consumptiontimes:any = consumptionamount;
      temp_consumptiontimes.splice(idx,1)
      setConsumptionamount([...temp_consumptiontimes])
    } else {
      if(idx === 0) {
        tagList[2].type = !tagList[2].type;
        setTagList([...tagList])
      } else {
        tagList[idx].type = !tagList[idx].type;
        setTagList([...tagList])
      }
    }
  }

  return <Row>
    <div className='group-name'>
      <div className='group-name-title'>分组名称：</div>
      <Input className='group-name-inp' placeholder="请输入分组名称" value={groupName} onChange={(e) => setgroupName(e.target.value)} style={{ width: '300px' }} />
    </div>
    <div className='group-condition'>
      <div className='group-condition-title'>分组条件：</div>
      <div className='group-condition-left'>
        <div className='group-condition-left-tip'>请选择需要标签条件</div>
        <div className='group-condition-left-tag'>
          <div className='group-condition-left-tag-left'>交易数据：</div>
          <div className='group-condition-left-tag-right'>
            {
              tagList.map((item, index) => {
                return (
                  <div key={index} className='group-condition-left-tag-right-list' onClick={() => { onTag(index) }}>
                    <div className={item.type ? 'group-condition-left-tag-right-list-item active' : 'group-condition-left-tag-right-list-item'}>{item.txt}</div>
                  </div>
                )
              })
            }
          </div>
        </div>
        <div className='group-condition-left-bottom'>
          <div className='group-condition-left-bottom-left'>满足以下：</div>
          <div className='group-condition-left-bottom-center'>
            <Select value={String(selectState)} className='group-condition-left-bottom-center-select' onChange={onCondition}>
              <Option value='1'>所有</Option>
              <Option value='0'>任意</Option>
            </Select>
          </div>
          <div className='group-condition-left-bottom-right'>条件的用户</div>
        </div>
      </div>
    </div>
    <div className='group-additional'>
      <div>
            {
              consumptiontimes.map((item,idx) => {
                return (
                  tagList[0]['type']
                    ? <div key={idx} style={{display: 'flex'}}>
                        <div className='group-additional-consumption'>
                          <div className='group-additional-consumption-num'>消费次数：</div>
                          <div className='group-additional-consumption-daynum'>
                            <Input type='text' placeholder={item['day']} onChange={value => inputValuechange('config', '0', value.target.value, 'day',idx)} />
                          </div>
                          <div className='group-additional-consumption-daytxt'>天内累计消费</div>
                          <div className='group-additional-consumption-start'><Input type='text' placeholder={item['low_limit']} onChange={value => inputValuechange('config', '0', value.target.value, 'low_limit',idx)} /></div>
                          <div className='group-additional-consumption-reach'>至</div>
                          <div className='group-additional-consumption-end'><Input type='text' placeholder={item['high_limit']} onChange={value => inputValuechange('config', '0', value.target.value, 'high_limit',idx)} /></div>
                          <div className='group-additional-consumption-company'>次</div>
                          <div className='group-additional-consumption-unlimited'>注：-1表示无限制</div>
                          <div className="new_del" onClick={() => consumptionTimeDel(idx)}>
                            删除
                          </div>
                        </div>
                    </div>
                    : ''
                )
              })
            }
            {
              tagList[0]['type'] && consumptiontimes.length >= 1
              ? <div className="new_add" onClick={() => consumptionTimeAdd()}>
                 新增
              </div>
              : ''
            }
            {
              groupDetailList['config'][1]['state'] || tagList[1]['type']
                ? <div className='group-additional-single'>
                    <div className='group-additional-single-num'>单次消费：</div>
                    <div className='group-additional-single-start'><Input type='text' placeholder={searchData['config'][1]['low_limit']} onChange={value => inputValuechange('config', '1', value.target.value, 'low_limit', -1)} /></div>
                    <div className='group-additional-single-reach'>至</div>
                    <div className='group-additional-single-end'><Input type='text' placeholder={searchData['config'][1]['high_limit']} onChange={value => inputValuechange('config', '1', value.target.value, 'high_limit', -1)} /></div>
                    <div className='group-additional-single-company'>元</div>
                    <div className='group-additional-single-unlimited'>注：-1表示无限制</div>
                  </div>
                : ''
            }
            {
              consumptionamount.map((item, idx) => {
                return (
                  tagList[2]['type']
                    ? <div key={idx} style={{display: 'flex'}}>
                          <div>
                            <div className='group-additional-cumulative'>
                            <div className='group-additional-cumulative-num'>累计消费金额：</div>
                            <div className='group-additional-cumulative-daynum'>
                              <Input type='text' placeholder={item['day']} onChange={value => inputValuechange('config', '2', value.target.value, 'day', idx)} />
                            </div>
                            <div className='group-additional-cumulative-daytxt'>天内累计消费</div>
                            <div className='group-additional-cumulative-start'><Input type='text' placeholder={item['low_limit']} onChange={value => inputValuechange('config', '2', value.target.value, 'low_limit', idx)} /></div>
                            <div className='group-additional-cumulative-reach'>至</div>
                            <div className='group-additional-cumulative-end'><Input type='text' placeholder={item['high_limit']} onChange={value => inputValuechange('config', '2', value.target.value, 'high_limit', idx)} /></div>
                            <div className='group-additional-cumulative-company'>元</div>
                            <div className='group-additional-cumulative-unlimited'>注：-1表示无限制</div>
                            <div className="new_del" onClick={() => consumptionAmoutDel(idx)}>
                              删除
                            </div>
                          </div>
                        </div>
                      </div>
                    : ''
                )
              })
            }
            {
              tagList[2]['type'] && consumptionamount.length >= 1
              ? <div className="new_add" onClick={() => consumptionAmoutAdd()}>
                 新增
              </div>
              : ''
            }
            {
              groupDetailList['config'][3]['state'] || tagList[3]['type']
                ? <div className='group-additional-balance'>
                  <div className='group-additional-balance-account'>账户余额：</div>
                  <div className='group-additional-balance-start'><Input type='text' placeholder={searchData['config'][3]['low_limit']} onChange={value => inputValuechange('config', '3', value.target.value, 'low_limit', -1)} /></div>
                  <div className='group-additional-balance-reach'>至</div>
                  <div className='group-additional-balance-end'><Input type='text' placeholder={searchData['config'][3]['high_limit']} onChange={value => inputValuechange('config', '3', value.target.value, 'high_limit', -1)} /></div>
                  <div className='group-additional-balance-company'>元</div>
                  <div className='group-additional-balance-unlimited'>注：-1表示无限制</div>
                </div>
                : ''
            }
        </div>
    </div>
    {/* <Title level={4}>分组名称</Title>
    <Input placeholder="请输入分组名称" value={groupName} onChange={(e) => setgroupName(e.target.value)} style={{width: '300px'}} /> */}

    <Row style={{ marginTop: 300 }} type="flex" justify="center">
      <Button style={{ marginRight: 100 }} onClick={goBack}>取消</Button>
      <Button type="primary" onClick={saveMemberGroup}>保存</Button>
    </Row>
  </Row>
}

export default AddVipGroup