import React, { useEffect, useState } from 'react'
import { Button } from 'antd'
import SmsList from './components/sms'
import RechargeList from './components/recharge'
import RechargeModal from './components/recharge_modal'
import RechargeCodeModal from './components/recharge_code_modal'
import RechargeSuccessModal from './components/recharge_success_modal'
import SmsEditModal from './components/sms_edit_modal'
import { getRequest } from '../../../api/http'
import { apiUrl } from '../../../api/api_url'

import './index.scss'
import { getIsPermisson, messageCustom } from '../../../utils/permisstionUtil'

const SmsIndex = ()=>{
    const [ action, setAction ] = useState(1)
    const [ actionRecharge, setActionRecharge ] = useState(false)
    const [ smsTotal, setSmsTotal ] = useState(0)
    const [ codeInfo, setCodeInfo] = useState<any>({}) //选择套餐后生成的二维码以及订单号
    const [ visible, setVisible ] = useState({
        rechargeModal:false,
        rechargeCodeModal:false,
        rechargeSuccessModal:false,
        smsEditModal:false,
    })

    useEffect(()=>{
        _init()
    },[])

    const _init = async() => {
        const res:any = await getRequest(apiUrl.getSmsInfo);
        if(res&&res.code==1){
            setSmsTotal(res.data.number)
        }
    }

    const closeModal = (key:string) => {
        setVisible({
            ...visible,
            [key]:false
        })
    }

    const openModal = (key:string,info:any={}) => {
        if(key == 'rechargeModal' || key == 'rechargeCodeModal'){
            if(!getIsPermisson('2_4_4_1')){
                messageCustom();
                return;
              }
            setCodeInfo(info)
        }else{
            if(!getIsPermisson('2_4_4_3')){
                messageCustom();
                return;
              }
        }
        setVisible({
            rechargeModal:false,
            rechargeCodeModal:false,
            rechargeSuccessModal:false,
            smsEditModal:false,
            [key]:true
        })
    }

    const closeRechargeSuccessModal = () => {
        closeModal('rechargeSuccessModal')
        setAction(2)
        setActionRecharge(true) //产生变化触发充值列表刷新
    }

    return(
        <div className="sms_index l_content">
            <div className="title_text">{action==1?'短信群发':'充值记录'}</div>
            <div className="mb20 top">
                <div>
                    <span className="fz16 fweight color333 mr20">{smsTotal}</span><span className="fz16 fweight color333 mr30">剩余短信</span>
                    <Button type="primary" className="mr30" onClick={()=>{openModal('rechargeModal')}}>短信充值</Button>
                    <Button type="primary" className="mr30" onClick={()=>{
                        if(!getIsPermisson('2_4_4_2')){
                            messageCustom();
                            return;
                          }
                        setAction(action==1?2:1)
                    }}>{action==1?'充值记录':'短信记录'}</Button>
                </div>
                <Button type="primary" className="mr30" onClick={()=>{openModal('smsEditModal')}}>修改短信签名</Button>
            </div>
            {
                action === 1 ? <SmsList /> : <RechargeList actionRecharge={actionRecharge}/>
            }
            {
                visible.rechargeModal&&<RechargeModal visible={visible.rechargeModal} onClose={()=>{closeModal('rechargeModal')}} onOpen={(info:any)=>{openModal('rechargeCodeModal',info)}}/>
            }
            {
                visible.rechargeCodeModal&&<RechargeCodeModal visible={visible.rechargeCodeModal} onClose={()=>{closeModal('rechargeCodeModal')}} onOpen={()=>{openModal('rechargeSuccessModal')}} codeInfo={codeInfo}/>
            }
            {
                visible.rechargeSuccessModal&&<RechargeSuccessModal visible={visible.rechargeSuccessModal} onClose={()=>{closeRechargeSuccessModal()}}/>
            }
            {
                visible.smsEditModal&&<SmsEditModal visible={visible.smsEditModal} onClose={()=>{closeModal('smsEditModal')}}/>
            }
        </div>
    )
}

export default SmsIndex