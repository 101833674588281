import React, { useEffect, useState, useRef } from 'react'
import { Select, Row, Tooltip, Table, Button,Modal, message, Input, DatePicker } from 'antd'
import { useHistory,  } from 'react-router-dom'
import btn_add from '../../../../assret/images/btn_add.png'
import sms_setting_icon from '../../../../assret/images/sms_setting_icon.png'
import print_select from '../../../../assret/images/print_select.png'
import { getRequest, postRequest } from '../../../../api/http'
import { apiUrl } from '../../../../api/api_url'
import t_edit from '../../../../assret/images/t_edit.png'
import TakeOutModal from '../takeOutModal'

import './index.scss'
import { getIsPermisson, messageCustom } from '../../../../utils/permisstionUtil'

const { Search } = Input
const { RangePicker } = DatePicker;

const DepositMain = (props:any)=>{

    const { currentTab } = props

    const history = useHistory()
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [ rowSpans, setRowSpans ] = useState<any>([])
    const [paramsObj, setParamsObj] = useState<any>({
        page: 1,
        page_size: 10,
        status: 1,
    });

    const [ visible ,setVisible ] = useState(false)
    const [ currentInfo, setCurrentInfo ] = useState<any>({})

    useEffect(()=>{
        getList({...paramsObj})
    },[ currentTab ])

    const getList = async(data:any = {}) => {
        let param = {
            ...data
        }
        if(param.sortType)param.sortType=param.sortType=='ascend'?'asc':'desc'
        Object.keys(param).map(el => {
            if(!param[el]) delete param[el]
        })
        setParamsObj({...param})
        const res:any = await getRequest(apiUrl.storageOrder, {...param,page_size:param.page_size});
        if(res && res['code'] === 1) {
            setTotal(res.data.total)
            setData(dataInit(res.data.items))
        }
    }

    //数据源格式化成多条数据
    const dataInit = (data:any)=>{
        let dataArr:any = []
        let i = 0
        data.map((item:any,index:number)=>{
            if(item.items&&item.items.length>1){
                item.items.map((itemG:any,indexG:number)=>{
                    dataArr[i] = itemG
                    dataArr[i].realname = item.realname
                    dataArr[i].mobile = item.mobile
                    dataArr[i].created_at = item.created_at
                    dataArr[i].expired_at = item.expired_at?item.expired_at:'永久'
                    dataArr[i].operation_nickname = item.operation_nickname
                    dataArr[i].label_id = item.order_sn+'-'+itemG.id //唯一id
                    dataArr[i].order_sn = item.order_sn
                    i++
                })
            }else{
                dataArr[i] = item.items[0]
                dataArr[i].realname = item.realname
                dataArr[i].mobile = item.mobile
                dataArr[i].created_at = item.created_at
                dataArr[i].expired_at = item.expired_at?item.expired_at:'永久'
                dataArr[i].operation_nickname = item.operation_nickname
                dataArr[i].order_sn = item.order_sn
                dataArr[i].label_id = item.order_sn+'-'+item.items[0].id
                i++
            }
        })
        setRowSpans(getRowSpans(dataArr, 'order_sn'))
        return dataArr
    }
    
    //获取合并行
    const getRowSpans = (arr:any, key:string) => {
        let sameValueLength = 0;
        const rowSpans = [];
        for(let i = arr.length - 1; i >= 0; i--){
            if(i === 0) {
                rowSpans[i] = sameValueLength + 1;
                continue;
            }
            if(arr[i][key] === arr[i-1][key]) {
                rowSpans[i] = 0;
                sameValueLength++;
            } else {
                rowSpans[i] = sameValueLength + 1;
                sameValueLength = 0;
            }
        }
        return rowSpans;
    };

    //表格值初始化
    const renderContent = ( value:any, _:any, index:number)=>{
        const obj:any = {
            children: value,
            props: {},
        };
        obj.props.rowSpan = rowSpans[index];
        return obj;
    }

    //取出
    const takeOut = () => {
        if(!getIsPermisson('1_11_7_4')){
            messageCustom();
            return;
        }
        getList({...paramsObj})
    }
    

    const openModal = (info:any) => {
        setVisible(true)
        setCurrentInfo(info)
    }

    const closeModal = () => {
        setVisible(false)
    }

    const creatTable = ()=>{
        const columns:any = [
            {
                title: '寄存单号',
                dataIndex: 'order_sn',
                key: 'order_sn',
                render: renderContent     
            },
            {
                title: '寄存人',
                dataIndex: 'realname',
                key: 'realname',
                render: renderContent     
            },
            {
                title: '手机号',
                dataIndex: 'mobile',
                key: 'mobile',
                render: renderContent     
            },
            {
                title: '菜品名称',
                dataIndex: 'dishe_name',
                key: 'dishe_name',
                render: (text:any,record:any) => text+(record.sepc?'('+record.sepc+')':'')
            },
            {
                title: '寄存数量',
                dataIndex: 'storage_num',
                key: 'storage_num',
                // render: (text:any,record:any) => ((record.finish_target/record.target)*100).toFixed(2)+'%'
            },
            {
                title: '剩余数量',
                dataIndex: 'surplus_num',
                key: 'surplus_num',
                // render: renderContent     
            },
            {
                title: '寄存时间',
                dataIndex: 'created_at',
                key: 'created_at',
                render: renderContent     
            },
            {
                title: '到期时间',
                dataIndex: 'expired_at',
                key: 'expired_at',
                render: renderContent     
            },
            {
                title: '操作人',
                dataIndex: 'operation_nickname',
                key: 'operation_nickname',
                render: renderContent     
            },
            {
                title: '操作',
                dataIndex: 'option',
                key: 'option',
                render: ( text:string, record:any)=><div className="take_out_icon" onClick={()=>{openModal(record)}}>取</div>
            },
        ];


        return (
            <Table
                dataSource={data}
                columns={columns}
                onChange={handleTableChange}
                rowKey={'label_id'}
                bordered
                pagination={{
                    defaultCurrent: 1,
                    current:paramsObj.page,
                    pageSize: paramsObj.page_size,
                    total: total,
                    showQuickJumper: true,
                    showSizeChanger: false,
                    showTotal: (total: number) => `共${total}条`,
                }}
            />
        );
    }


    const handleTableChange = (pagination:any, filters:any, sorter:any) => {
        getList({
            ...paramsObj,
            page:pagination.current,
            page_size:pagination.pageSize,
            sortField:sorter.field,
            sortType:sorter.order
        })
    }

    const goPage = (url:string) => {
        history.push(url)
    }

    const changeRangePicker = (dateString:any,field:string) => {
        if(dateString[0]){
            getList({...paramsObj,[field]:dateString[0]+'|'+dateString[1]})
          }else{
            getList({...paramsObj,[field]:''})
          }
    }

    return (
        <div className="deposit_main">
            <Row type="flex" align="middle" justify="space-between" className="deposit_main_top">
                <Row type="flex" align="middle" className="mb20">
                    <div className='new_btn' onClick={()=>{
                        if(!getIsPermisson('1_11_7_1')){
                            messageCustom();
                            return;
                        }
                        goPage('/sidebar/depositAdd')
                    }}>
                        <img src={btn_add}></img>
                        添加寄存
                    </div>
                    <div className='new_btn' onClick={()=>{
                        if(!getIsPermisson('1_11_7_2')){
                            messageCustom();
                            return;
                        }
                        goPage('/sidebar/depositmsgSetting')
                    }}>
                        <img src={sms_setting_icon}></img>
                        信息提醒设置
                    </div>
                    <div className='new_btn' onClick={()=>{
                        if(!getIsPermisson('1_11_7_3')){
                            messageCustom();
                            return;
                        }
                        goPage('/sidebar/depositPrintSetting')
                    }}>
                        <img src={print_select}></img>
                        单据打印设置
                    </div>
                </Row>
                <Row type="flex" align="middle" className="mb20">
                    <Search placeholder="寄存单号/寄存人/手机号" style={{ width: 200 }} className="mr20" onSearch={(e)=>{getList({...paramsObj,keyword:e})}} allowClear/>
                    寄存时间 ：<RangePicker 
                        className="mr20" 
                        style={{width:230}}
                        onChange={(dates,dateString)=>{changeRangePicker(dateString,'created_at')}}
                    />                     
                    到期时间 ：<RangePicker 
                        style={{width:230}}
                        onChange={(dates,dateString)=>{changeRangePicker(dateString,'expired_at')}}
                    />  
                </Row>
            </Row>

            <div className="dashboard_list_table">
                {
                    creatTable()
                }
            </div>
            <TakeOutModal visible={visible} takeOut={()=>{takeOut()}} closeModal={()=>{closeModal()}} info={currentInfo}/>
        </div>
    )
}

export default DepositMain
