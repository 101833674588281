import React, { useEffect, useState } from 'react'

import './insideNav.scss'
type IProps ={
    navList: any,
    callBack: Function
}
const InsideNav = (props : IProps) => {
    const [navList,setNavList] = useState([])

    const [activeIndex,setActiveIndex] = useState(-1)

  

    useEffect(() =>{
        initComState()
    },[props.navList])

    const initComState =() =>{
        setNavList(props.navList ?? [])
        let chooseIndex = props.navList.findIndex((item:any) => item.isActive);
        setActiveIndex(chooseIndex);
    }

    const handleTab = (index: number) =>{
        var _navList = JSON.parse(JSON.stringify(navList))
        _navList.map((item:any) => item.isActive = false);
        _navList[index].isActive = true;
        setActiveIndex(index);
        setNavList(_navList);
        props.callBack(_navList[index].KEY)
    }

    return  <div className="inside_nav">
        <div className="nav_list">
            {
                navList.map((item:any,index:number) =>{
                    return <div key={index} className={`nav_body ${item.isActive ? 'nav_body_active': ''}`} onClick={() => handleTab(index)}>
                        <div
                            className={` 
                                nav_item 
                                ${item.isActive ? 'item_active': 'item'}
                                ${activeIndex - 1 == index ? 'left_ratius': ''}
                                ${activeIndex + 1 == index ? 'right_ratius': ''}
                                `}
                            >
                            {item.title}
                        </div>
                    </div>
                })
            }
        </div>
    </div>

}

export default InsideNav;