import { Row, Col, DatePicker, message } from 'antd';
import React, { Component } from 'react';

import UserLevel from './components/userLevel/userLevel';
import ConsumptionTimes from './components/consumptionTimes/consumptionTimes';
import RunComsunmeMonth from './components/runComsumeMonth/runComsumeMonth';
import EatMonth from './components/eatMonth/eatMonth';
import CustomGroup from './customGroup';
import ComsumeBehavior from './components/comsumeBehavior/comsumeBehavior';


import styles from './deepOperation.module.scss';

import see from '../../assret/images/see.png';
import deep_add from '../../assret/images/deep_add.png';
import { apiUrl } from '../../api/api_url';
import { getRequest } from '../../api/http';

const { RangePicker } = DatePicker;

const nowDate = new Date();
const yesterday = new Date(nowDate.getTime() - 24 * 60 * 60 * 1000)
const beforeYesterday = new Date(nowDate.getTime() - 24 * 2 * 60 * 60 * 1000)

var now:any = new Date(); //当前日期
var nowDayOfWeek = now.getDay(); //今天本周的第几天
var nowDay = now.getDate(); //当前日
var nowMonth = now.getMonth(); //当前月
var nowYear = now.getYear(); //当前年
nowYear += (nowYear < 2000) ? 1900 : 0; //
var lastMonthDate:any = new Date(); //上月日期
lastMonthDate.setDate(1);
lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);
var lastYear = lastMonthDate.getYear();
var lastMonth = lastMonthDate.getMonth();

interface IProps {
	history?: any
}

  interface IState {
	top_icon_list: any,
	recommend_icon_list: any,

}
class DeepOperation extends Component<IProps, any> {

	constructor(props: IProps) {
		super(props);
		this.state = {
			top_icon_list: [
				{
					title: '用户总数',
					value: 0,
					new: 0
				},
				{
					title: '消费用户',
					value: 0,
					new: 0
				},
				{
					title: 'VIP充值用户总数',
					value: 0,
					new: 0
				},
				{
					title: '充值金额',
					value: 0,
					new: 0
				}
			],
			recommend_icon_list: [
				{
					id: 1,
					name: '用户等级',
					active: true,
				},
				{
					id: 2,
					name: '消费次数',
					active: false,
				},
				{
					id: 3,
					name: '消费月份',
					active: false,
				},
				{
					id: 4,
					name: '消费行为',
					active: false,
				},
				{
					id: 5,
					name: '就餐月份',
					active: false,
				}
			],
			recommend_icon_idx: 1,
			totalUser: 0,//总用户
			newUser: 0,//新用户
			vipUser: 0,//vip用户
			newVip: 0,//新vip
			singlePrice: 0,//平均桌单价
			priceTrend: 0,//价格趋势
			rechargeAmount: "0.00",//充值金额
			presentAmount: "0.00",//充值赠送金额
			start_at: '',
			end_at: '',
			currentTitleIdx: 1,
		}
	}

	componentDidMount() {
        this._initDate(0)
    }

	//
	_initDate = async (type: any) => {
		let params = {
            start_at: this.formatDate(Number(type)),
            end_at: this.formatDate(0)
		}
        if(type === 1) {
            params.end_at = this.formatDate(Number(type))
		}
		if(type == 2) {
			// this.setState({
			// 	start_at
			// })
			params.start_at= this.state.start_at;
            params.end_at = this.state.end_at;
		}
		let res:any = await getRequest(apiUrl.userRunIndex, params);
		if(res.code == 1) {
			let top_icon_list: any = this.state.top_icon_list;
			let { totalUser, newUser, vipUser, newVip, singlePrice, priceTrend, rechargeAmount, presentAmount } = res.data;
			this.setState({
				top_icon_list: res.data.list,
				totalUser
			})
		} else {
			message.error(res.message)
		}
	}

	//时间处理函数
    formatDate = (type: number) => {
        let date = new Date();
        let year:any = '';
        let month:any = '';
        let day:any = '';
        if(type === 1) {
            date.setTime(date.getTime()-24*60*60*1000);
            year = date.getFullYear();
            month = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : '0' + (date.getMonth() + 1);
            day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();

        } else if(type === 7) {
            date.setTime(date.getTime()-((24*60*60*1000) * 6));
            year = date.getFullYear();
            month = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : '0' + (date.getMonth() + 1);
            day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();

        } else {
            year = date.getFullYear();
            month = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : '0' + (date.getMonth() + 1);
            day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();
        }

        return year + '-' + month + '-' + day
    }

    //选择时间
    onChangeTime = async (start_time: any, end_time: any) => {
		console.log(start_time, end_time)
		let params = {
			start_at: start_time,
            end_at: end_time
		}
		if(!start_time) {
			params.start_at = this.formatDate(0)
            params.end_at = this.formatDate(0)
		}
		let res:any = await getRequest(apiUrl.userRunIndex, params);
		if(res.code == 1) {
			let top_icon_list: any = this.state.top_icon_list;
			let { totalUser, newUser, vipUser, newVip, singlePrice, priceTrend, rechargeAmount, presentAmount } = res.data;
			this.setState({
				top_icon_list: res.data.list
			})
		} else {
			message.error(res.message)
		}
	}

	//推荐分组切换
	onGroupRecommend = (item: any, index: any) =>{
		let recommend_icon_list: any = this.state.recommend_icon_list;
		recommend_icon_list.map((sub_item: any, sub_index: any) => {
			if(item.id == sub_item.id) {
				recommend_icon_list[index]['active'] = true;
			} else {
				recommend_icon_list[sub_index]['active'] = false;
			}
		})
		this.setState({
			recommend_icon_idx: item.id,
			recommend_icon_list
		})

	}

	handleChange = (value: any) => {
        this._initDate(Number(value))
	}

	getTimeData = (type: "today" | "yesterday" | 'beforeYesterday' | 'beforeMonth' | 'beforeWeek' | 'currentMonth' | 'own' | 'quarter' | 'lastQuarter' | 'ownMonth') => {
        switch (type) {
            case 'currentMonth':
                let currMonthStart = `${nowDate.getFullYear()}-${this.formatData(nowDate.getMonth() + 1)}-01`;
                let currMonthEnd = `${nowDate.getFullYear()}-${this.formatData(nowDate.getMonth() + 1)}-${this.formatData(nowDate.getDate())}`;
                this.setState({
                    start_at: currMonthStart,
                    end_at: currMonthEnd,
                }, () => {
                    this._initDate(2);
                })
                break;
            case "beforeWeek":
                var d = new Date();
                var end = d.getTime() - 1000 * 60 * 60 * 24 * d.getDay();
                let start = d.getTime() - 1000 * 60 * 60 * 24 * (d.getDay() + 6)
                let startDay = new Date(start).getFullYear() + '-' + this.formatData(new Date(start).getMonth() + 1) + '-' + this.formatData(new Date(start).getDate());
                let endDay = new Date(end).getFullYear() + '-' + this.formatData(new Date(end).getMonth() + 1) + '-' + this.formatData(new Date(end).getDate());

                this.setState({
                    start_at: startDay,
                    end_at: endDay
                }, () => {
                    this._initDate(2);
                })
                break;
            case "beforeMonth":
                let beforeMonthStart = nowDate.getFullYear() + '-' + this.formatData(nowDate.getMonth()) + '-01';
                let currentMonth = nowDate.getFullYear() + '-' + this.formatData(nowDate.getMonth() + 1) + '-01'
                let num = new Date(new Date(currentMonth).getTime() - 24 * 60 * 60 * 1000);
                let beforeMonthEnd = `${num.getFullYear()}-${this.formatData(num.getMonth() + 1)}-${this.formatData(num.getDate())}`;
				beforeMonthStart = `${num.getFullYear()}-${this.formatData(num.getMonth() + 1)}-01`
				this.setState({
                    start_at: beforeMonthStart,
                    end_at: beforeMonthEnd
                }, () => {
                    this._initDate(2);
                })
                break;
            case "beforeYesterday":
                let beforeYesDay = `${beforeYesterday.getFullYear()}-${this.formatData(beforeYesterday.getMonth() + 1)}-${this.formatData(beforeYesterday.getDate())}`;
                this.setState({
                    start_at: beforeYesDay,
                    end_at: beforeYesDay
                }, () => {
                    this._initDate(2);
                })
                break;
            case "yesterday":
                let yesDay = `${yesterday.getFullYear()}-${this.formatData(yesterday.getMonth() + 1)}-${this.formatData(yesterday.getDate())}`;
                this.setState({
                    start_at: yesDay,
                    end_at: yesDay
                }, () => {
                    this._initDate(2);
                })
                break;

            case "today":
                let toDay = `${nowDate.getFullYear()}-${this.formatData(nowDate.getMonth() + 1)}-${this.formatData(nowDate.getDate())}`;
                this.setState({
                    start_at: toDay,
                    end_at: toDay,
                }, () => {
                    this._initDate(2);
				})
				break;
			case "own":
				let own = `${nowDate.getFullYear()}-${this.formatData(nowDate.getMonth() + 1)}-${this.formatData(nowDate.getDate())}`;
				this.setState({
					start_at: '1970-01-01',
					end_at: own,
				}, () => {
					this._initDate(2);
				})
				break;
			case "quarter":
				this.setState({
					start_at: this.getQuarterStartDate(),
					end_at: this.getQuarterEndDate(),
				}, () => {
					this._initDate(2);
				})
				break;
			case "lastQuarter":
				this.setState({
					start_at: this.getLastQuarterStartDate(),
					end_at: this.getLastQuarterEndDate(),
				}, () => {
					this._initDate(2);
				})
				break;
			case "ownMonth":
				this.setState({
					start_at: this.getLastMonth().last,
					end_at: this.getLastMonth().now,
				}, () => {
					this._initDate(2);
				})
				break;
        }
	}

	//近一个月
	getLastMonth() {
		var now:any = new Date();
		var year = now.getFullYear();
		var month = now.getMonth() + 1;//0-11表示1-12月
		var day = now.getDate();
		var dateObj: any = {};
		dateObj.now = year + '-' + month + '-' + day;
		var nowMonthDay = new Date(year, month, 0).getDate();    //当前月的总天数
		if(month - 1 <= 0){ //如果是1月，年数往前推一年<br>　　　　
			dateObj.last = (year - 1) + '-' + 12 + '-' + day;
		}else{
			var lastMonthDay = new Date(year, (parseInt(month) - 1), 0).getDate();
			if(lastMonthDay < day){    //1个月前所在月的总天数小于现在的天日期
				if(day < nowMonthDay){        //当前天日期小于当前月总天数
					dateObj.last = year + '-' + (month - 1) + '-' + (lastMonthDay - (nowMonthDay - day));
				}else{
					dateObj.last = year + '-' + (month - 1) + '-' + lastMonthDay;
				}
			}else{
				dateObj.last = year + '-' + (month - 1) + '-' + day;
			}
		}
		return dateObj;
	}

	//获得本季度的开始月份
	getQuarterStartMonth() {
		var quarterStartMonth = 0;
		if (nowMonth < 3) {
			quarterStartMonth = 0;
		}
		if (2 < nowMonth && nowMonth < 6) {
			quarterStartMonth = 3;
		}
		if (5 < nowMonth && nowMonth < 9) {
			quarterStartMonth = 6;
		}
		if (nowMonth > 8) {
			quarterStartMonth = 9;
		}
		return quarterStartMonth;
	}

	//获得上季度的开始月份
	getLastQuarterStartMonth() {
		var quarterStartMonth = 0;
		if (nowMonth < 3) {
			quarterStartMonth = 0;
		}
		if (2 < nowMonth && nowMonth < 6) {
			quarterStartMonth = 3;
		}
		if (5 < nowMonth && nowMonth < 9) {
			quarterStartMonth = 6;
		}
		if (nowMonth > 8) {
			quarterStartMonth = 9;
		}
		return quarterStartMonth;
	}

	//获得上季度季度的开始日期
	getLastQuarterStartDate() {
		var quarterStartDate = new Date(nowYear, this.getLastQuarterStartMonth() - 2, 1);
		return this.newFormatDate(quarterStartDate);
	}

	//获得上季度的结束日期
	getLastQuarterEndDate() {
		var quarterEndMonth = this.getLastQuarterStartMonth();
		var quarterStartDate = new Date(nowYear, quarterEndMonth,this.getMonthDays(quarterEndMonth));
		return this.newFormatDate(quarterStartDate);
	}

	//获得本季度的开始日期
	getQuarterStartDate() {
		var quarterStartDate = new Date(nowYear, this.getQuarterStartMonth(), 1);
		return this.newFormatDate(quarterStartDate);
	}

	//或的本季度的结束日期
	getQuarterEndDate() {
		var quarterEndMonth = this.getQuarterStartMonth() + 2;
		var quarterStartDate = new Date(nowYear, quarterEndMonth,this.getMonthDays(quarterEndMonth));
		return this.newFormatDate(quarterStartDate);
	}

	//获得某月的天数
	getMonthDays(myMonth: any) {
		var monthStartDate: any = new Date(nowYear, myMonth, 1);
		var monthEndDate: any = new Date(nowYear, myMonth + 1, 1);
		var days = (monthEndDate - monthStartDate) / (1000 * 60 * 60 * 24);
		return days;
	}

	newFormatDate(date: any) {
		var myyear = date.getFullYear();
		var mymonth = date.getMonth() + 1;
		var myweekday = date.getDate();
		if (mymonth < 10) {
			mymonth = "0" + mymonth;
		}
		if (myweekday < 10) {
			myweekday = "0" + myweekday;
		}
		return (myyear + "-" + mymonth + "-" + myweekday);
	}

    formatData = (num: number) => {
      return num > 9 ? num : `0${num}`
    }


    render() {

		let { totalUser, currentTitleIdx, singlePrice, rechargeAmount, recommend_icon_idx, top_icon_list, recommend_icon_list } = this.state;

        return (
            <Row className={styles.deepOperation}>
                <Col className={styles.deepOperation_userdate}>用户数据</Col>
                <Col className={styles.deepOperation_select}>
                    <div className={styles.deepOperation_select_left}>
                        <div className={`${styles.deepOperation_select_left_near} ${currentTitleIdx == 1 ? styles.user_ifon_active : ''}`} onClick={() => {this.handleChange('0');this.setState({currentTitleIdx: 1})}}>今日</div>
                        <div className={`${styles.deepOperation_select_left_near} ${currentTitleIdx == 2 ? styles.user_ifon_active : ''}`} onClick={() => {this.handleChange('7');this.setState({currentTitleIdx: 2})}}>近七天</div>
						<div className={`${styles.deepOperation_select_left_near} ${currentTitleIdx == 3 ? styles.user_ifon_active : ''}`} onClick={() => {this.getTimeData('ownMonth');this.setState({currentTitleIdx: 3})}}>近30天</div>
						<div className={`${styles.deepOperation_select_left_near} ${currentTitleIdx == 4 ? styles.user_ifon_active : ''}`} onClick={() => {this.getTimeData("currentMonth");this.setState({currentTitleIdx: 4})}}>本月</div>
						<div className={`${styles.deepOperation_select_left_near} ${currentTitleIdx == 5 ? styles.user_ifon_active : ''}`} onClick={() => {this.getTimeData("beforeMonth");this.setState({currentTitleIdx: 5})}}>上月</div>
						<div className={`${styles.deepOperation_select_left_near} ${currentTitleIdx == 6 ? styles.user_ifon_active : ''}`} onClick={() => {this.getTimeData('quarter');this.setState({currentTitleIdx: 6})}}>本季度</div>
						<div className={`${styles.deepOperation_select_left_near} ${currentTitleIdx == 7 ? styles.user_ifon_active : ''}`} onClick={() => {this.getTimeData('lastQuarter');this.setState({currentTitleIdx: 7})}}>上季度</div>
						<div className={`${styles.deepOperation_select_left_near} ${currentTitleIdx == 8 ? styles.user_ifon_active : ''}`} onClick={() => {this.getTimeData('own');this.setState({currentTitleIdx: 8})}}>累计</div>
                        <div className={styles.deepOperation_select_left_selecttime}>
                            <RangePicker
                                format={`YYYY-MM-DD`}
                                showTime={false}
                                onChange={(e:any, dateString) => {
                                    this.onChangeTime(dateString[0], dateString[1]);
                                }}
                                placeholder={['开始时间', '结束时间']} />
                        </div>
                    </div>
                    {/* <div className={styles.deepOperation_select_right} onClick={() => {this.props.history.push('/sidebar/deepCustomerOperate')}}>
                        <img src={see} alt="" className={styles.deepOperation_select_right_img}/>
                        <span className={styles.deepOperation_select_right_all}>查看全部</span>
                    </div> */}
                </Col>
                <Col className={styles.deepOperation_panel}>
					{
						top_icon_list.map((item: any, index: any) => {
							return (
								<div key={item + index} className={styles.deepOperation_panel_item}>
									<div className={styles.deepOperation_panel_item_top}>
										<div className={styles.deepOperation_panel_item_top_left}>
											<div className={styles.deepOperation_panel_item_top_left_user}>{item.title}</div>
											<div className={styles.deepOperation_panel_item_top_left_num}>{item.value}</div>
										</div>
										<div className={styles.deepOperation_panel_item_top_right}><img src={item.icon} className={styles.deepOperation_panel_item_top_right_img} /></div>
									</div>
									{
										item.title !== '充值金额'
										?	<div className={styles.deepOperation_panel_item_bottom}>
												<img src={deep_add} className={styles.deepOperation_panel_item_bottom_img} />
												<span className={styles.deepOperation_panel_item_bottom_txt}>新增：{item.new}</span>
											</div>
										:	<div className={styles.deepOperation_panel_item_bottom}>
												{/* <img src={item.tip_path} className={styles.deepOperation_panel_item_bottom_img} /> */}
												<span className={styles.deepOperation_panel_item_bottom_txt}>赠送金额：{item.new}</span>
											</div>
									}
								</div>
							)
						})
					}
                </Col>
				<Col className={styles.deepOperation_recommendGroup}>推荐分组运营</Col>
				<Col className={styles.deepOperation_recommendIcon}>
					{
						recommend_icon_list.map((item: any, index: any) => {
							return (
								<div key={item + index} onClick={() => {this.onGroupRecommend(item, index)}} className={`${!item.active ? styles.deepOperation_recommendIcon_active : styles.deepOperation_recommendIcon_list}`}>
									{item.name}
								</div>
							)
						})
					}
				</Col>
				<Col>
					{
						recommend_icon_idx == 1
						?	<UserLevel history={this.props.history}/>
						:	recommend_icon_idx == 2
							?	<ConsumptionTimes history={this.props.history}/>
							:	recommend_icon_idx == 3
								?	<RunComsunmeMonth history={this.props.history}/>
								:	recommend_icon_idx == 4
									?	<ComsumeBehavior user={totalUser} history={this.props.history}/>
									:	recommend_icon_idx == 5
										?	<EatMonth history={this.props.history}/>
										:	null
					}
				</Col>
				<Col className={styles.deepOperation_recommendGroup}>自定义分组运营</Col>
				<Col className={styles.deepOperation_customGroup}>
					<CustomGroup currentTab={'custom'} />
				</Col>
            </Row>
        )
    }

}

export default DeepOperation