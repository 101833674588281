import React, { useState, useRef, useEffect } from 'react'
import { Row, message } from 'antd'
import { useLocation } from 'react-router-dom'
import { getRequest } from "../../../api/http";
import { Observer } from 'mobx-react';
// import { toJS } from 'mobx'
import ReactDOM from 'react-dom';
import DecorationHomeHeader from './components/decorationHomeHeader'
import DecorationHomeHeaderPreview from './components/decorationHomeHeaderPreview'
import DecorationHomeLeft from './components/decorationHomeLeft'
import DecorationHomeCenter from './components/decorationHomeCenter'
import DecorationHomeRight from './components/decorationHomeRight'
import shopDecorationStore from '../../../stores/shopDecoration.store'

import { apiUrl } from '../../../api/api_url';

import styles from './main.module.scss'

const DecorationHomeMain = (props:any)=>{
    const centerRef= useRef(null);
    const location:any = useLocation()
    const [ pathList, setPathList ] = useState<any>([])
    const headerList:any = {
        left: [
            {
                type: 1,
                txt: '返回'
            }
        ],
        right: [
            {
                type: 1,
                txt: '预览'
            },
            {
                type: 1,
                txt: '恢复默认设置'
            },
            {
                type: 1,
                txt: '保存'
            },
            {
                type: 2,
                txt: '保存并运用'
            }
        ]
    }
    const headerPreview:any = {
        left: '预览：手机版',
        right: [
            {
                type: 1,
                txt: '返回列表'
            },
            {
                type: 1,
                txt: '编辑页面'
            },
            {
                type: 2,
                txt: '使用'
            }
        ]
    }

    useEffect(()=>{
        shopDecorationStore.updateShopDecorationHomeOther({
            module:'' , //当前选中模版
            showRight:false , //是否显示右侧编辑
            current: -1, //当前板块
            hoverCurrent: -1,
            editMofangCurrent: -1 , //当前编辑的魔方导航
            hoverEditMofangCurrent: -1 , //当前悬停的编辑的魔方导航
            editMutiCurrent: 0 , //当前编辑的多图文导航
            hoverMutiCurrent: -1, //当前悬停编辑的多图文导航
        })
        initData(location.state.template_id)
    },[])

    const initData = async(index:number) => {
        let params = {
            template: index
        }
        const res:any = await getRequest(apiUrl.getHomepage,params)
        if(res.code === 1 && res.data){
            shopDecorationStore.updateShopDecorationHome(res.data.list,false)
            setPathList(res.data.path||[])
        }else{
            message.error(res.message||'模版信息获取失败')
        }
    }

    const mainClick = () => {
        shopDecorationStore.updateShopDecorationHomeOther({
            module:'' , //当前选中模版
            showRight:false , //是否显示右侧编辑
            current: -1, //当前板块
            editMofangCurrent: -1 , //当前编辑的魔方导航
            hoverEditMofangCurrent: -1 , //当前悬停的编辑的魔方导航
            editMutiCurrent: 0 , //当前编辑的多图文导航
            hoverMutiCurrent: -1, //当前悬停编辑的多图文导航
        })
    }

    const scrollToBottom = () => {
        console.log(ReactDOM.findDOMNode(centerRef.current)?.childNodes[0])
        let content:any = ReactDOM.findDOMNode(centerRef.current)?.childNodes[1].childNodes[2]
        const scrollHeight = content.scrollHeight;//里面div的实际高度  2000px
        const height = content.clientHeight;  //网页可见高度  200px
        const maxScrollTop = scrollHeight - height; 
        content.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
        content.scrollIntoView({ behavior: "smooth" });
    }


    return (<Observer render={() => 
        <div className={styles.decorationMainHome} onClick={()=>{mainClick()}}>
            <Row type="flex" align="middle" className={styles.decorationMainHome_header}>
                {
                    shopDecorationStore.shop_decoration_home_preview === 0
                    ?   <DecorationHomeHeader  template={location.state.template_id} dataObj={headerList} ></DecorationHomeHeader>
                    :   <DecorationHomeHeaderPreview  template={location.state.template_id} dataObj={headerPreview} ></DecorationHomeHeaderPreview>
                }
            </Row>
            <Row type="flex" justify="space-between" className={styles.decorationMainHome_content}>
                {
                    shopDecorationStore.shop_decoration_home_preview === 0 && (
                        <DecorationHomeLeft scrollToBottom={()=>{scrollToBottom()}}></DecorationHomeLeft>
                    )
                }
                <DecorationHomeCenter template={location.state.template_id} ref={centerRef}></DecorationHomeCenter>
                {
                    shopDecorationStore.shop_decoration_home_preview === 0 && (
                        <DecorationHomeRight pathList={pathList}></DecorationHomeRight>
                    )
                }
            </Row>
        </div>    
    }/>)
}

export default DecorationHomeMain