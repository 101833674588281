import React, { useEffect, useState } from 'react'
import { Tabs } from 'antd'
// import { useHistory, useLocation } from 'react-router-dom';
// import { getRequest, postRequest } from '../../../../../api/http'
// import { apiUrl } from '../../../../../api/api_url'
import OfficialAccountRecommend from './components/official_account/recommend'

const { TabPane } = Tabs

const OfficialAccountDetail = ()=>{

    useEffect(() => {
        // if(location.state&&location.state.channel_id) paramsObj.channel_id = location.state.channel_id
        // _init()
        // getGroupList({...paramsObj})
    }, [])

    // const _init = async() => {
    //     const res:any = await getRequest(apiUrl.getPrivateGroup)
    //     if(res&&res.code==1&&res.data){
    //         const arr:any = location.state&&location.state.channel_id?res.data.filter((item:any)=>item.channel_id==location.state.channel_id):[]
    //         if(arr.length>0) setTitle(arr[0].name)
    //     }
    // }

    return(
        <div className="official_account_detail l_content">
            <div className="title_text">公众号用户</div>
            <Tabs>
                <TabPane tab="推荐分组" key="custom">
                    <OfficialAccountRecommend />
                </TabPane>
            </Tabs>            
        </div>
    )
}

export default OfficialAccountDetail