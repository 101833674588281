import React, { useEffect, useState, } from "react";

import { getRequest, postRequest } from "./../../api/http";
import { apiUrl } from "./../../api/api_url";
import {Row,Col,Table, message, Modal, Input, Select, DatePicker, Tooltip, Switch} from 'antd'
import btn_add from '../../assret/images/btn_add.png'
import t_edit from '../../assret/images/t_edit.png'
import t_delete from '../../assret/images/t_delete.png'
import './setMeal.scss'
import { getIsPermisson, messageCustom } from "../../utils/permisstionUtil";

const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

let times:any = [];      // 时间区间
let param: any = {
    start_time: '',
    end_time: '',
    status: 0,
    name: '',
}

interface Iprops {
    history: any,
}

const key = 'SetMeal';

const SetMeal = (props:Iprops)=>{

    const [rechargeList,setRechargeList] = useState([])

    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [productCount, setProductCount] = useState(0)
    const [pageSize, setPageSize] = useState(10)

    useEffect(()=>{
        initData()
    },[pageSize])

    const initData = async ()=>{
        getRechargeList().then((res) => {
            setRechargeList((data) => data = res.data)
        })
    }

    const getRechargeList = async (val = {})=>{

        if(times.length > 0){
            param.start_time = times[0];
            param.end_time = times[1]
        }
        Object.keys(param).map(el => {
            if(!param[el]) delete param[el]
        })

        const data = {
            ...param,
            page_size: pageSize,
            page: currentPage
        }

        Object.keys(data).map(el => {
            if(!data[el]) delete data[el]
            if(data[el] == 0) delete data[el]
        })

        const res:any = await getRequest(`${apiUrl.setMealList}`,data);
        setRechargeList((data) => data = res.data)
        return res;
    }

    const columns = [
        {
            title: '套餐编号',
            dataIndex: 'id',
            key: 'id',
            width: 40,
        },
        {
            title: '套餐名',
            dataIndex: 'name',
            key: 'name',
            width: 80,
        },
        {
            title: '商品分类',
            width: 80,
            render: (text:any,record:any, index: number) => (
                <div className='back_wrap'>
                    套餐
                </div>
            ),
        },
        {
            title: '套餐价格',
            dataIndex: 'price',
            key: 'price',
            width: 80,
        },
        {
            title: '已售数量',
            dataIndex: 'purchased_count',
            key: 'purchased_count',
            width: 50,
        },
        {
            title: '是否在小程序显示',
            width: 80,
            render: (text:any,record:any, index: number) => (
                <div className='back_wrap'>
                    {text.show_mini_program == 2 ? "是" : "否"}
                </div>
            ),
        },
        {
            title: '参与打折',
            dataIndex: 'discount',
            key: 'discount',
            width: 80,
            render: (text: string, record:any) => {
                return <div className="">{record.discount ? "是" : '否' }</div>
            }
        },
        {
            title: '商品状态',
            dataIndex: 'status_txt',
            key: 'status_txt',
            width: 80,
        },
        {
            title: '创建时间',
            dataIndex: 'update_time',
            key: 'update_time',
            width: 80,
        },
        {
            title: '编辑',
            key: 'edit',
            width: 80,
            render: (text:any,record:any, index: number) => (
                <div className='back_wrap'>
                    <Tooltip placement="bottomRight" title={'编辑'}>
                        <img src= {t_edit} className='table_icon' onClick={()=>{
                            if(!getIsPermisson('2_2_3_3')){
                                messageCustom();
                                return;
                              }
                            props.history.push('/sidebar/setMealAdd',{id: record.id})}
                        }></img>
                    </Tooltip>
                    <Tooltip placement="bottomRight" title={'删除'}>
                        <img src={t_delete} className='table_icon' onClick={() => deleteSetmeal(record.id, record.name)}></img>
                    </Tooltip>
                    <div onClick={() => statusChange(record)}>
                        <Switch checked={record.status == 1 ? true : false} size='small' style={{marginRight: '5px'}} />
                        {record.status == 1 ? '停止售卖' : '开启售卖'}
                        {/* 1在售，3下架 */}
                    </div>
                </div>
            ),
        },

    ]

    const deleteSetmeal = async (id: number, name: string) => {
        if(!getIsPermisson('2_2_3_4')){
            messageCustom();
            return;
          }
        Modal.confirm({
            title: '提示',
            content: `确认删除套餐「${name}」？`,
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                const res:any = await postRequest(apiUrl.setMealDelete, {ids:[id]})
                if(res.code === 1){
                    message.success('删除成功')
                    initData()
                }
            },
          });
    }

    const statusChange = async (record: any) => {
        if(!getIsPermisson('2_2_3_5')){
            messageCustom();
            return;
          }
        Modal.confirm({
            title: '提示',
            content: `确认是否 ${record.status == 1 ? '停止':'开启'}售卖「${record.name}」？`,
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                const res:any = await postRequest(apiUrl.setmealStatus, {id: record.id, status: record.status == 1 ? 3 : 1})
                if(res.code === 1){
                    message.success('操作成功')
                    initData()
                }
            },
        });
    }

    const rowSelection: any = {
        onChange: (selectedRowKeys: any, selectedRows: any) => {
            setSelectedRowKeys(selectedRowKeys)
        }
    };

    //批量打折
    const onDiscountMust = async (type: any) => {
        if(!getIsPermisson('2_2_3_2')){
            messageCustom();
            return;
          }
        if(!selectedRowKeys) {
            return;
        }

        if(type == 'silent') {
            Modal.confirm({
                title: '提示',
                content: `确认批量打折当前所选套餐？`,
                okText: '确认',
                cancelText: '取消',
                onOk: async () => {
                    let param = {
                        ids: selectedRowKeys,
                        discount: true,
                    }
                    console.log(param)
                    let res: any = await postRequest(apiUrl.changeDiscount, param)
                    if (res.code === 1) {
                        message.success({ content: '设置成功!', key, duration: 2 });
                        initData();
                    }
                },
            });
        } else {
            Modal.confirm({
                title: '提示',
                content: `确认批量取消打折当前所选套餐？`,
                okText: '确认',
                cancelText: '取消',
                onOk: async () => {
                    let param = {
                        ids: selectedRowKeys,
                        discount: false,
                    }
                    let res: any = await postRequest(apiUrl.changeDiscount, param)
                    if (res.code === 1) {
                        message.success({ content: '设置成功!', key, duration: 2 });
                        initData();
                    }
                },
            });
        }
    }

    //批量删除
    const onDeleteMust = async () => {
        if(!getIsPermisson('2_2_3_4')){
            messageCustom();
            return;
          }
        if(!selectedRowKeys) {
            return;
        }

        Modal.confirm({
            title: '提示',
            content: `确认删除当前所选套餐？`,
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                let param = {
                    ids: selectedRowKeys,
                }
                let res: any = await postRequest(apiUrl.setMealDelete, param)
                if(res.code === 1){
                    message.success('删除成功')
                    initData()
                }
            },
        });
    }

    // 状态选择
    const typeChange = async (is_up: any) => {
        param.status = is_up;
        getRechargeList()
    }

    const onGoPage = (url: any) => {
        props.history.push(url);
    }

    const onTableChange = (e: any) => {
        setCurrentPage(e.current)
        initData()
    }


    return (
        <div className='padding20'>
            <div className='goods_btn'>
                    <div className='new_btn mb30 margin_right_20' onClick={() => {
                         if(!getIsPermisson('2_2_3_1')){
                            messageCustom();
                            return;
                          }
                        onGoPage(`/sidebar/setMealAdd`)
                    }}>
                        <img src={btn_add}></img>
                        添加套餐
                    </div>
                </div>
            <div className='goods_main padding15 background_fff border_radius_10'>
                <Row type="flex" align="middle" justify="start" className="mt20 mb40" >
                    <Col className="mr30">
                        <span>套餐名称：</span>
                        <Search placeholder="请输入套餐名称" onSearch={value => {
                            param.name = value;
                            getRechargeList({ name: value });
                        }} style={{ width: 200 }} />
                    </Col>
                    <Col className="mr30">
                        <span>套餐状态：</span>
                        <Select style={{ width: 150 }} onChange={typeChange} placeholder="请选择套餐状态" allowClear>
                            <Option value="0">全部</Option>
                            <Option value="1">售卖中</Option>
                            <Option value="3">停售</Option>
                        </Select>
                    </Col>
                    <Col>
                        <span>创建时间：</span>
                        <RangePicker style={{width: 280}} onChange={(e:any, dateString) => {
                            times = dateString;
                            getRechargeList();
                        }} placeholder={["开始时间", "结束时间"]} format="YYYY-MM-DD" />
                    </Col>
                </Row>
                <Row className="goods_tip">
                    <Col className="goods_tip_list background_7480FD border_radius_18 margin_right_20" onClick={() => {onDiscountMust('silent')}}>批量打折</Col>
                    <Col className="goods_tip_list background_7480FD border_radius_18 margin_right_20" onClick={() => {onDiscountMust('nosilent')}}>批量取消打折</Col>
                    <Col className="goods_tip_list background_FF5166 border_radius_18 margin_right_20" onClick={() => {onDeleteMust()}}>批量删除</Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Table rowSelection={rowSelection} onChange={onTableChange} dataSource={rechargeList} size="middle" rowKey={'id'} columns={columns}
                            pagination={{
                                current: currentPage,
                                defaultCurrent: 1,
                                total: productCount,
                                pageSize: pageSize,
                                showQuickJumper: true,
                                showSizeChanger: true,
                                showTotal: (total: number) => `共${total}条`,
                                onShowSizeChange: (current: number, size: number) => { setPageSize(size) }

                            }}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default SetMeal