import React, { useState, useEffect, useRef } from 'react'
import {Tabs, Row, Table, Avatar, Button, Col, DatePicker, Select } from 'antd'
import { RangePickerValue } from "antd/lib/date-picker/interface";
import Search from 'antd/lib/input/Search';
import { getRequest } from '../../../api/http';
import { apiUrl } from '../../../api/api_url';

const { TabPane } = Tabs;
const { RangePicker } = DatePicker
const { Option } = Select;
const nowDate = new Date();
let start_time = '';
let end_time = '';
let nickname = ''; // 最近参与名称
let all_name = ''; // 总参与名称
let type = 1; //1：最近参与，2：总参与
const yesterday = new Date(nowDate.getTime() - 24 * 60 * 60 * 1000)
const beforeYesterday = new Date(nowDate.getTime() - 24 * 2 * 60 * 60 * 1000)

interface IListItem {
    id: number
    nickname: string
    avatar: string
    phone_number: string
    commission: string
    register: number
    spent: number
    nospent: number
    withdraw: string
}

interface IList {
    total: number
    items: Array<IListItem>
}

const WeappDistriutionUsers = () => {
    const [page, setpage] = useState(1);
    const [loadingData, setloadingData] = useState(true);
    const [pageSize, setpageSize] = useState(10);
    const [list, setlist] = useState<IList>({
        total: 0,
        items: [
            // {
            //   id: 0,
            //   nickname: '测试名称',
            //   avatar: 'http://iph.href.lu/100x100',
            //   phone_number: '18576709668',
            //   commission: '200.00',
            //   register: 100,
            //   spent: 20,
            //   nospent: 30,
            //   withdraw: '50.0'
            // },{
            //   id: 0,
            //   nickname: '测试名称',
            //   avatar: 'http://iph.href.lu/100x100',
            //   phone_number: '18576709668',
            //   commission: '200.00',
            //   register: 100,
            //   spent: 20,
            //   nospent: 30,
            //   withdraw: '50.0'
            // }
        ]
    });

    //const [start_time, setStart_time] = useState('');
    //const [end_time, setEnd_time] = useState('');

    useEffect(() => {
        getTimeData('today')
        initList();
        return () => {
            start_time = '';
            end_time = '';
            nickname = '';
            all_name = '';
        }
    }, []);

    const initList = async (curr = 1, pageSize = 10) => {
        let parms: any = {
            pageSize: pageSize,
            page: curr
        }
        if(type == 1) {
            if(start_time !== ''){
                parms.start_time = start_time;
                parms.end_time = end_time;
            }
            if(nickname !== ''){
                parms.nickname = nickname;
            }
        } else {
            if(all_name !== ''){
                parms.nickname = all_name;
            }
        }
        const res: any = await getRequest(apiUrl.shareUserList, parms);
        if (res && res.data) {
            setlist(res.data);
            setloadingData(false)
        }
    }

    const columns = [
        {
            title: '用户昵称',
            dataIndex: 'nickname',
            key: 'xcx',
            render: (text: string, record: any, index: number) =>
                <Row className="" justify="start" align="middle">
                    <div><Avatar src={record.avatar} /></div>
                    <div>{record.nickname}</div>
                </Row>
        },
        // {
        //     title: '用户昵称',
        //     key: 'xcx',
        //     render: (rowdata: any) => {
        //         if (rowdata) {
        //             return <Row type="flex" align="middle">
        //                 <Avatar src={rowdata.avatar} />
        //                 <Row>{rowdata.nickname}</Row>
        //             </Row>
        //         }
        //         return <Row></Row>
        //     }
        // },
        {
            title: '手机号',
            dataIndex: 'phone_number',
            key: 'phone_number',
        }, {
            title: '成功邀请（人）',
            dataIndex: 'register',
            key: 'register',
        }, {
            title: '已消费（人）',
            dataIndex: 'spent',
            key: 'spent',
        }, {
            title: '未消费（人）',
            dataIndex: 'nospent',
            key: 'nospent',
        }, {
            title: '累计得奖励（元）',
            dataIndex: 'commission',
            key: 'commission',
        }, {
            title: '当前可提现现金（元）',
            dataIndex: 'withdraw',
            key: 'withdraw',
        },
    ]

    const pageOnChange = (e: any) => {
        setpage(e)
        initList(e);
    }

    const timeChange = (time: RangePickerValue, timeString: [string, string]) => {
        start_time = timeString[0];
        end_time = timeString[1];
        setloadingData(true)
        initList();
    }

    const getTimeData = (type: "today" | "yesterday" | 'beforeYesterday' | 'beforeMonth' | 'beforeWeek' | 'currentMonth') => {
        setloadingData(true)
        switch (type) {
            case 'currentMonth':
                let currMonthStart = `${nowDate.getFullYear()}-${formatData(nowDate.getMonth() + 1)}-01`;
                let currMonthEnd = `${nowDate.getFullYear()}-${formatData(nowDate.getMonth() + 1)}-${formatData(nowDate.getDate())}`;
                start_time = (currMonthStart);
                end_time = (currMonthEnd);
                initList();
                break;
            case "beforeWeek":
                var d = new Date();
                var end = d.getTime() - 1000 * 60 * 60 * 24 * d.getDay();
                let start = d.getTime() - 1000 * 60 * 60 * 24 * (d.getDay() + 6)
                let startDay = new Date(start).getFullYear() + '-' + formatData(new Date(start).getMonth() + 1) + '-' + formatData(new Date(start).getDate());
                let endDay = new Date(end).getFullYear() + '-' + formatData(new Date(end).getMonth() + 1) + '-' + formatData(new Date(end).getDate());
                start_time = (startDay)
                end_time = (endDay)
                initList();
                break;
            case "beforeMonth":
                let beforeMonthStart = nowDate.getFullYear() + '-' + formatData(nowDate.getMonth()) + '-01';
                let currentMonth = nowDate.getFullYear() + '-' + formatData(nowDate.getMonth() + 1) + '-01'
                let num = new Date(new Date(currentMonth).getTime() - 24 * 60 * 60 * 1000);
                let beforeMonthEnd = `${num.getFullYear()}-${formatData(num.getMonth() + 1)}-${formatData(num.getDate())}`;
                beforeMonthStart = `${num.getFullYear()}-${formatData(num.getMonth() + 1)}-01`
                start_time = (beforeMonthStart)
                end_time = (beforeMonthEnd)
                initList();
                break;
            case "beforeYesterday":
                let beforeYesDay = `${beforeYesterday.getFullYear()}-${formatData(beforeYesterday.getMonth() + 1)}-${formatData(beforeYesterday.getDate())}`;
                start_time = (beforeYesDay)
                end_time = (beforeYesDay)
                initList();
                break;
            case "yesterday":
                let yesDay = `${yesterday.getFullYear()}-${formatData(yesterday.getMonth() + 1)}-${formatData(yesterday.getDate())}`;
                start_time = (yesDay)
                end_time = (yesDay)
                initList();
                break;

            case "today":
                let toDay = `${nowDate.getFullYear()}-${formatData(nowDate.getMonth() + 1)}-${formatData(nowDate.getDate())}`;
                start_time = (toDay)
                end_time = (toDay)
                initList();
                break;
        }
    }

    const formatData = (num: number) => {
        return num > 9 ? num : `0${num}`
    }

    const callback = async (key: any) => {
        if(key == 2) {
            type = 2;
            let parms: any = {
                pageSize: 10,
                page: 1
            }
            if(all_name !== ''){
                parms.nickname = all_name;
            }
            const res: any = await getRequest(apiUrl.shareUserList, parms);
            if (res && res.data) {
                setlist(res.data);
                setloadingData(false)
            }
        } else {
            type = 1;
            initList();
        }
    };

    return <Row className='l_content'>
        <Tabs defaultActiveKey="1" onChange={callback} animated={false}>
            <TabPane tab="最近参与" key="1">
                <div style={{marginBottom: '20px'}}>
                    <Row type='flex' align='middle'>
                        <Col span={3} style={{ marginRight: 20 }}>
                            <Search
                                placeholder="请输入用户昵称"
                                onSearch={value => {
                                    nickname = value;
                                    type = 1;
                                    setloadingData(true)
                                    initList();
                                }}
                            />
                        </Col>
                        <Button type="ghost" style={{ marginRight: 20 }} onClick={() => getTimeData("today")}>今日</Button>
                        <Button type="ghost" style={{ marginRight: 20 }} onClick={() => getTimeData("yesterday")}>昨日</Button>
                        <Button type="ghost" style={{ marginRight: 20 }} onClick={() => getTimeData("beforeYesterday")}>前日</Button>
                        <Button type="ghost" style={{ marginRight: 20 }} onClick={() => getTimeData("beforeWeek")}>上周</Button>
                        <Button type="ghost" style={{ marginRight: 20 }} onClick={() => getTimeData("beforeMonth")}>上月</Button>
                        <Button type="ghost" style={{ marginRight: 20 }} onClick={() => getTimeData("currentMonth")}>本月</Button>
                        <Col>
                            <RangePicker onChange={timeChange} placeholder={start_time !== '' ? [start_time, end_time] :["开始时间","结束时间"]} />
                        </Col>
                    </Row>
                </div>
                <Table
                    key={1}
                    size="middle"
                    rowKey={'id'}
                    dataSource={list.items}
                    loading={loadingData}
                    pagination={{
                        current: page,
                        total: list.total,
                        pageSize: pageSize,
                        onChange: (e) => pageOnChange(e),
                        showQuickJumper: true,
                        showSizeChanger: true,
                        showTotal: (total: number) => `共${total}条`,
                        onShowSizeChange: (current: number, size: number) => { setpageSize(size); initList(page, size) }

                    }}
                    columns={columns} />
            </TabPane>
            <TabPane tab="总参与" key="2">
                <div style={{marginBottom: '20px'}}>
                    <Row type='flex' align='middle'>
                        <Col span={3} style={{ marginRight: 20 }}>
                            <Search
                                placeholder="请输入用户昵称"
                                onSearch={value => {
                                    all_name = value;
                                    type = 2;
                                    setloadingData(true)
                                    initList();
                                }}
                            />
                        </Col>
                    </Row>
                </div>
                <Table
                    key={2}
                    rowKey={'code'}
                    size="middle"
                    dataSource={list.items}
                    loading={loadingData}
                    columns={columns}
                    pagination={{
                        current: page,
                        pageSize: pageSize,
                        total: list.total,
                        onChange: (e) => pageOnChange(e),
                        showQuickJumper: true,
                        showSizeChanger: true,
                        showTotal: (total: number) => `共${total}条`,
                        onShowSizeChange: (current: number, size: number) => { setpageSize(size); initList(page, size) }
                    }}
                />
            </TabPane>
        </Tabs>
    </Row>
}

export default WeappDistriutionUsers