import React from "react";
import {Button, Row, Col, Table, Modal, message } from "antd";
import { postRequest, getRequest } from '../../../api/http';
import { apiUrl } from '../../../api/api_url';
import { Link } from "react-router-dom";
import btn_add from '../../../assret/images/btn_add.png'
import down_icon from '../../../assret/images/goodsImport.png'
import t_edit from '../../../assret/images/t_edit.png'
import t_delete from '../../../assret/images/t_delete.png'
import t_code from '../../../assret/images/t_code.png'
import { getIsPermisson, messageCustom } from "../../../utils/permisstionUtil";

const key = 'table';

class ShopTable extends React.Component<any> {
    state = {
        tableList: [],
        pageSize: 10,
        count: 0,
        currentPage: 1,
    }

    columns = [
        {
            title: '排序',
            dataIndex: 'sort',
            key: 'sort',
            className: 'list_center',
        },
        {
            title: '餐台号',
            dataIndex: 'name',
            key: 'name',
            className: 'list_center',
        },
        {
            title: '所属区域',
            dataIndex: 'region_name',
            key: 'region_name',
            className: 'list_center',
        },
        {
            title: '餐台订单',
            dataIndex: 'order_num',
            key: 'order_num',
            className: 'list_center',
        },
        {
            title: '餐台状态',
            dataIndex: 'is_eating',
            key: 'is_eating',
            className: 'list_center',
            render: (text:any,record:any,) => (
                <div>
                    {record.is_eating ? '用餐中' : '空桌'}
                </div>
            ),
        },
        {
            title: '当前key',
            dataIndex: 'dine_key',
            key: 'dine_key',
            className: 'dine_key',
        },
        {
            title: '操作',
            width: 300,
            dataIndex: 'action',
            key: 'action',
            className: 'list_center',
            render: (text:any,record:any,) => (
                <div className="btn_wrap" style={{display:'flex', justifyContent:'flex-start'}}>
                    <div className="list_btn" onClick={() =>{
                         if(!getIsPermisson('2_8_1_3')){
                            messageCustom();
                            return;
                          }
                        this.props.history.push({pathname:'/sidebar/shopTableEdit', state:{id: record.id}})
                    }}>
                        <img src={t_edit} style={{width: '32px', height: '32px'}}></img>
                    </div>

                    <a href={record.qrcode} download={record.qrcode}>
                        <img src={t_code} style={{width: '21px', height: '21px'}}></img>
                    </a>
                    <div className="list_btn" onClick={() => this.deleteList(record)}>
                        <img src={t_delete} style={{width: '32px', height: '32px'}}></img>
                    </div>
                    <div className="list_btn" onClick={() => this.setEating(record)} style={{display: 'flex', alignItems:'center'}}>
                        <span style={{display: 'block', width: '17px', height:'17px',borderRadius: '10px', background: !record.is_eating ? '#93E539' : '#D8D8D8', marginRight: '7px'}}></span>
                        {record.is_eating ? '标为空桌' : '标为用餐中'}
                    </div>
                </div>
            ),
        },
    ];

    componentDidMount(){
        this.onInitList();
        this._initCount();
    }

    _initCount = async () => {
        const res:any = await getRequest(apiUrl.tableCount);
        if(res.code === 1){
            this.setState({
                count: res.data.num
            })
        }
    }

    // 初始化列表
    onInitList = async () => {
        const { pageSize, currentPage } = this.state;
        const params:any = {
            pageSize,
            current: currentPage
        };
        const res:any = await getRequest(apiUrl.tableList,params);
        if(res.code === 1){
            this.setState({
                tableList: res.data
            })
        }
    }

    pageChange = async (page: number) => {
        this.setState({
            currentPage: page
        }, () => {
            this.onInitList()
        })
    }

    // 删除列表
    deleteList = (record:any) => {
        if(!getIsPermisson('2_8_1_4')){
            messageCustom();
            return;
          }
        Modal.confirm({
            title: '删除餐台号',
            content: `确认删除餐台号「${record.name}」`,
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                const params = {
                    id: record.id
                }
                const res:any = await postRequest(apiUrl.tableDelete,params);
                if(res.code === 1){
                    message.success({ content: '删除成功!', key, duration: 2 });
                }
                this.onInitList();
                this._initCount();
            },
            onCancel() {
            },
        });
    }

    // 设置空桌或者用餐中
    setEating = async (record: any) => {
        if(!getIsPermisson('2_8_1_6')){
            messageCustom();
            return;
          }
        const params = {
            id: record.id
        }
        const res:any = await postRequest(apiUrl.tableUse,params);
        if(res.code === 1){
            message.success({ content: '设置成功!', key, duration: 2 });
        }
        this.onInitList();
        this._initCount();
    }

    // 批量导出餐桌码
    downAll = async () => {
        if(!getIsPermisson('2_8_1_2')){
            messageCustom();
            return;
          }
        const token = localStorage.getItem('token');
        const shop_id = localStorage.getItem('shop_id')
        window.open(`${process.env.REACT_APP_BASEURL}${apiUrl.downAll}?token=${token}&shop_id=${shop_id}`)
    }

    render(){
        const { pageSize, count, currentPage, } = this.state;
        return <div className='padding20'>

            <div style={{display: 'flex'}} className="mb30 margin_right_20">

                <div className='goods_btn'>
                    <div className='new_btn margin_right_20' onClick={() =>{
                        if(!getIsPermisson('2_8_1_1')){
                            messageCustom();
                            return;
                          }
                        this.props.history.push("/sidebar/shopTableEdit");
                    }}>
                        <img src={btn_add}></img>
                        添加餐台
                    </div>
                    <div className='new_btn margin_right_20' style={{width: '174px'}}  onClick={this.downAll} >
                        <img src={down_icon}></img>
                        批量导出餐桌码
                    </div>
                </div>
            </div>

            <div className="background_fff padding15 border_radius_10">
                <Table dataSource={this.state.tableList} size="middle" columns={this.columns} rowKey={"id"} locale={{emptyText: '暂无数据'}} pagination={{
                    current: currentPage,
                    total: count,
                    pageSize,
                    hideOnSinglePage: true,
                    onChange: this.pageChange,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    showTotal: (total: number) => `共${total}条`,
                    onShowSizeChange: (current:number, size: number) => {this.setState({pageSize: size}, () => {this.onInitList();})}
                }}/>
            </div>
        </div>;
    }
}
export default ShopTable