import React from 'react';
import { Row, Col } from 'antd';
import '../management.scss';

import winAddBusiness1 from '../../../../../../assret/images/winAddBusiness1.png';
import winAddBusiness2 from '../../../../../../assret/images/winAddBusiness2.png';
import winAddBusiness3 from '../../../../../../assret/images/winAddBusiness3.png';
import winAddBusiness4 from '../../../../../../assret/images/winAddBusiness4.png';
import winAddBusiness5 from '../../../../../../assret/images/winAddBusiness5.png';

interface HelpMbaBusiness {
    history: any,
}

class HelpMbaBusiness extends React.Component<HelpMbaBusiness> {

    render() {
        return  <Row className="typeDetail">
                    <Col className="typeDetail-title">一、功能介绍</Col>
                    <Col className="typeDetail-col">商家后台结账使用，添加抵扣券或结账打折，抵扣券主要记录第三方平台核销，结账打折主要是设置后台服务员结账时使用。</Col>
                    <Col className="typeDetail-title">二、功能路径</Col>
                    <Col className="typeDetail-col">电脑端登录后台-营销管理-商家工具列表-添加新抵扣券。</Col>
                    <Col className="typeDetail-col">
                        <img src={winAddBusiness1} />
                    </Col>
                    <Col className="typeDetail-title">三、操作流程</Col>
                    <Col className="typeDetail-col">1、<span className="txt-bold">添加新券抵扣</span>，在“抵扣券列表”界面下点击“添加新券抵扣”</Col>
                    <Col className="typeDetail-col">
                        <img src={winAddBusiness2} />
                    </Col>
                    <Col className="typeDetail-col">2、抵扣券名称：填写名称</Col>
                    <Col className="typeDetail-col">
                        <img src={winAddBusiness3} />
                    </Col>
                    <Col className="typeDetail-col">3、抵扣券渠道：选择或新建渠道，当没有渠道时，可输入渠道名称，点击添加，添加后下次可直接选择，当有渠道时，可直接选择渠道</Col>
                    <Col className="typeDetail-col">4、购买金额：渠道购买的价格，例如美团代金券69抵100，则填写购买金额为69</Col>
                    <Col className="typeDetail-col">5、抵扣金额：渠道抵扣的价格，例如美团代金券69抵100，则填写抵扣金额为100</Col>
                    <Col className="typeDetail-title">三、功能路径</Col>
                    <Col className="typeDetail-col">电脑端登录后台-营销管理-商家工具列表-添加新打折。</Col>
                    <Col className="typeDetail-col">
                        <img src={winAddBusiness4} />
                    </Col>
                    <Col className="typeDetail-title">五、操作流程</Col>
                    <Col className="typeDetail-col">1、<span className="txt-bold">添加新券抵扣</span>，在“折扣列表”界面下点击“添加新折扣”</Col>
                    <Col className="typeDetail-col">
                        <img src={winAddBusiness5} />
                    </Col>
                    <Col className="typeDetail-col">2、结账打折名称：填写名称</Col>
                    <Col className="typeDetail-col">3、打多少折：填写打多少折，例如8折填写8</Col>
                </Row>
    }
}

export default HelpMbaBusiness;