import React, { useEffect, useState } from 'react'
import { Row, Button, Input, Table, message, Tag, Tooltip, Switch } from 'antd'
import { getRequest } from '../../../../../api/http'
import { apiUrl } from '../../../../../api/api_url'
import { useHistory,  } from 'react-router-dom'
import t_member from '../../../../../assret/images/t_member.png'
import t_manage from '../../../../../assret/images/t_manage.png'
import t_manage_g from '../../../../../assret/images/t_manage_g.png'
import t_data from '../../../../../assret/images/t_data.png'
import t_data_g from '../../../../../assret/images/t_data_g.png'

import './index.scss'
import { getIsPermisson, messageCustom } from '../../../../../utils/permisstionUtil'

const { Search } = Input;

type Iprops = {
    currentTab: string,
}

const RecommendGroupIndex = (props:Iprops)=>{

    const { currentTab } = props
    const history = useHistory()
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [ rowSpans, setRowSpans ] = useState<any>([])
    const [cateList, setcateList] = useState<any>([])
    const [paramsObj, setParamsObj] = useState<any>({
        page: 1,
        pageSize: 10,
    });

    useEffect(() => {
        if(currentTab=='recommend'){
            getRecommendGroupList({...paramsObj})
        }
    }, [currentTab])

    const getRecommendGroupList = async (data:any = {}) => {
        let param = {
            ...data
        }
        if(param.sortType)param.sortType=param.sortType=='ascend'?'asc':'desc'
        setParamsObj({...param})
        const res:any = await getRequest(apiUrl.getRecommendGroup, param);
        if(res && res['code'] === 1) {
            setTotal(res.data.total)
            setData(dataInit(res.data.list))
            if(!data.label_id){
                getGroupCate(res.data.total)
            }
        }
    }

    const getGroupCate = async (total: number) => {
        let res:any = await getRequest(apiUrl.groupCate)
        if(res && res.code == 1){
            let list = [{label_id: 0, label_name: '全部分组', active: true, count: total}]
            list = list.concat(res.data)
            setcateList(list)
        }
    }

    //数据源格式化成多条数据
    const dataInit = (data:any)=>{
        let dataArr:any = []
        let i = 0
        data.map((item:any,index:number)=>{
            if(item.items&&item.items.length>1){
                item.items.map((itemG:any,indexG:number)=>{
                    dataArr[i] = itemG
                    dataArr[i].label = item.label
                    dataArr[i].label_id = item.label_id
                    i++
                })
            }else{
                dataArr[i] = item.items[0]
                dataArr[i].label = item.label
                dataArr[i].label_id = item.label_id
                i++
            }
        })
        setRowSpans(getRowSpans(dataArr, 'label_id'))
        return dataArr
    }

    //获取合并行
    const getRowSpans = (arr:any, key:string) => {
        let sameValueLength = 0;
        const rowSpans = [];
        for(let i = arr.length - 1; i >= 0; i--){
            if(i === 0) {
                rowSpans[i] = sameValueLength + 1;
                continue;
            }
            if(arr[i][key] === arr[i-1][key]) {
                rowSpans[i] = 0;
                sameValueLength++;
            } else {
                rowSpans[i] = sameValueLength + 1;
                sameValueLength = 0;
            }
        }
        return rowSpans;
    };


    const handleTableChange = (pagination:any, filters:any, sorter:any) => {
        getRecommendGroupList({
            ...paramsObj,
            page:pagination.current,
            pageSize:pagination.pageSize,
            sortField:sorter.field,
            sortType:sorter.order
        })
    }

    const searchGroup = (value:string) => {
        getRecommendGroupList({
            ...paramsObj,
            name:value,
            page:1,
        })
    }

    //表格值初始化
    const renderContent = ( value:any, _:any, index:number)=>{
        const obj:any = {
            children: value,
            props: {},
        };
        obj.props.rowSpan = rowSpans[index];
        return obj;
    }

    const cateChange = (item: any,index: number) => {
        cateList.map((cate:any) => {
            cate.active = false;
        })
        cateList[index].active = true
        setcateList(cateList)
        getRecommendGroupList({
            ...paramsObj,
            label_id:cateList[index].label_id,
            page:1,
        })
    }

    const creatTable = ()=>{
        const columns:any = [
            {
                title: '分组名称',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '定义',
                dataIndex: 'remark',
                key: 'remark',
                width: 300,
            },
            {
                title: '客户数量',
                dataIndex: 'member_num',
                key: 'member_num',
                sorter:true,
            },
            // {
            //     title: '上月客户数量',
            //     dataIndex: 'last_month_member_num',
            //     key: 'last_month_member_num',
            //     sorter:true,
            // },
            {
                title: '区别对待',
                dataIndex: 'is_difference_treat',
                key: 'is_difference_treat',
                render: (text:string, rowdata: any) => {
                    return rowdata ? <Row>
                        {rowdata.is_difference_treat ? "是" : "否"}
                    </Row>
                    : <Row></Row>
                }
            },
            {
                title: '操作',
                dataIndex: 'option',
                key: 'option',
                render: ( text:string, record:any)=>
                    <Row type="flex" align='middle' justify='center'>
                        <Tooltip placement="bottomRight" title={'查看人员'}>
                            <img src={t_member} className='table_icon' onClick={()=>{
                                if(!getIsPermisson('1_6_5_2')){
                                    messageCustom();
                                    return;
                                  }
                                history.push('/sidebar/memberList',{recommend_group_id:record.id})}
                                }></img>
                        </Tooltip>

                        <Tooltip placement="bottomRight" title={'去运营'}>
                            <img src={!record.operation_id ? t_manage : t_manage_g} className='table_icon' onClick={() => {
                                if(!getIsPermisson('1_6_5_3')){
                                    messageCustom();
                                    return;
                                  }
                                 if(!record.operation_id){
                                    history.push('/sidebar/marketingAdd',{group_id:record.id,name:record.name,is_disabled:true})
                                 }
                            }}></img>
                        </Tooltip>
                        {/* <Tooltip placement="bottomRight" title={'查看数据'}>
                            <img src={!record.operation_id ? t_data_g : t_data} className='table_icon' onClick={() => record.operation_id&&history.push({pathname: '/sidebar/marketingDetail', state: { id: record.operation_id, name: record.name }})}></img>
                        </Tooltip> */}

                        <span className="action_button" onClick={() => setGroupTreat(record)}>
                            <Switch checked={record.is_difference_treat ? true : false} size='small' style={{marginRight: '5px'}} />
                            {record.is_difference_treat ? "取消区别对待" : "区别对待"}
                        </span>

                    </Row>
            },
        ];

        const setGroupTreat = async (rowdata: any) => {
            if(!getIsPermisson('1_6_5_6')){
                messageCustom();
                return;
              }
            const res:any = await getRequest(apiUrl.setGroupTreat, { id: rowdata.id,  is_difference_treat: !rowdata.is_difference_treat });
            if(res && res.code === 1) {
                message.success('设置成功');
                getRecommendGroupList({...paramsObj});
            } else {
                message.error(res.msg);
            }
        }

        return (
            <Table
                dataSource={data}
                columns={columns}
                onChange={handleTableChange}
                rowKey={'id'}
                pagination={{
                    defaultCurrent: 1,
                    current:paramsObj.page,
                    pageSize: paramsObj.pageSize,
                    total: total,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    showTotal: (total: number) => `共${total}条`,
                }}
            />
        );
    }

    return(
        <div className="recommend_group_index l_content">
            <div className="mb30" style={{display: 'flex'}}>
                <div className='group_cate'>
                    {
                        cateList.map((item: any, index: number) => {
                            return <div key={item.label_id} className={`cate_item ${item.active ? 'cate_active' : ''}`} onClick={() => cateChange(item, index)}>{item.label_name}（{item.count}）</div>
                        })
                    }
                </div>
                <Search
                    placeholder="请输入分组名称"
                    allowClear
                    style={{width: '300px'}}
                    onSearch={value => searchGroup(value)}
                />
            </div>
            {
                creatTable()
            }
        </div>
    )
}

export default RecommendGroupIndex