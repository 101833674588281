import React from "react";
import {Button, Input, message, Row, Col, Select, Modal, Radio, Icon, Tooltip} from "antd";
import { postRequest, getRequest } from '../../api/http';
import { apiUrl } from '../../api/api_url';

const { Option } = Select;

interface PaymentAdd{
}

const key = 'updatable';

class PaymentAdd extends React.Component<any> {

    state = {
        loading: false,
        name: '',
        channelList: [],
        channel_type: undefined,
        is_effect: 1,
        discount: 0,
        discount_type: 1, //打折的类型，1-全单打折，2-扣除不参与打折金额后打折
        full_money: 0,  //	numeric	10	满减最低金额，优惠类型为满减此参数必传	否	无
        reduce_money: 0, //	numeric	10	满减扣减金额，优惠类型为满减此参数必传	否	无
        reduce_num: 0,
        highNum: 1
    }

    componentDidMount () {
        const {location} = this.props;
        if(location.state !== undefined){
            this.initEdit(location.state);
        }
        this.onInitList();
    }

    // 初始化列表
    onInitList = async () => {
        const res:any = await getRequest(apiUrl.channelList,{});
        if(res.code === 1){
            this.setState({
                channelList: res.data,
            })
        }
    }

    // 初始化编辑
    initEdit = async (record: any) => {
        let {name, discount, discount_type, is_effect, channel_type, full_money, reduce_money, reduce_num } = record;
        console.log(record);
            if(discount_type == 3){
                this.setState({
                    full_money,
                    reduce_money,
                    reduce_num,
                    highNum: reduce_num == 0 ? 1 : 2
                })
            }else{
                this.setState({
                    discount
                })
            }
            this.setState({
                name,
                discount_type,
                is_effect,
                channel_type
            })
    }

    inputChange = (e:any) => {
        let name = e.target.name;
        let value = e.target.value;
        this.setState({
            [name]: value
        })
    }

    // 券类型
    typeChange = (e:string) => {
        if(e === 'bank'){
            this.setState({
                discount_type: 1
            })
        }
        this.setState({
            channel_type: e,
        })
    }

    handleSubmit = async (e:any) => {
        message.loading({ content: '发布中', key });
        e.preventDefault();

        const {location} = this.props;
        const { name, channel_type, discount, is_effect, discount_type, full_money, reduce_money, reduce_num, highNum } = this.state;

        var tableInfo:any = {};

        if(location.state !== undefined){
            tableInfo.id = location.state.id;
        }

        if(name === ''){
            message.warning({ content: '请输入支付名称!', key, duration: 2 });
            return;
        }

        if(channel_type === ''){
            message.warning({ content: '请选择支付渠道!', key, duration: 2 });
            return;
        }

        if(discount_type == 3 && (!full_money || !reduce_money)){
            message.warning({ content: '请输入满减条件!', key, duration: 2 });
            return;
        }

        if(highNum == 2 && !reduce_num){
            message.warning({ content: '请输入最高减次数!', key, duration: 2 });
            return;
        }

        tableInfo.name = name;
        tableInfo.channel_type = channel_type;
        if(discount){
            tableInfo.discount = discount;
        }
        tableInfo.is_effect = is_effect;
        tableInfo.discount_type = discount_type;
        if(discount_type == 3){
            tableInfo.full_money = full_money;
            tableInfo.reduce_money = reduce_money;
            if(highNum === 1){
                tableInfo.reduce_num = 0;
            }else{
                tableInfo.reduce_num = reduce_num;
            }
        }

        if(location.state !== undefined){
            const res:any = await postRequest(apiUrl.updatePayList,tableInfo);
            if(res['code'] === 1){
                message.success({ content: '修改成功!', key, duration: 2 });
                this.props.history.goBack()
            }else{
                message.error({ content: '修改失败!', key, duration: 2 });
            }
        }else{
            const res:any = await postRequest(apiUrl.addPayList,tableInfo);
            if(res['code'] === 1){
                message.success({ content: '发布成功!', key, duration: 2 });
                this.props.history.goBack()
            }else{
                message.error({ content: '发布失败!', key, duration: 2 });
            }
        }
    };

    // 返回上一页
    goBack = () => {
        this.props.history.goBack()
    }

    render(){
        const { name, channelList, is_effect, discount, discount_type, channel_type, highNum, full_money, reduce_money, reduce_num} = this.state;

        return <div className='l_content'>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">支付名称</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <Input placeholder="请输入支付名称" name={'name'} value={name} onChange={(e) => this.inputChange(e)}/>
                    </Col>
                </Row>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">支付渠道</span>
                        </div>
                    </Col>
                    <Col span={14}>
                        <Select style={{ width: 150 }} onChange={this.typeChange} placeholder={'请选择支付渠道'} value={channel_type}>
                            {
                                channelList.map((item:any) => {
                                    return <Option value={item.type} key={item.type}>{item.name}</Option>
                                })
                            }
                        </Select>
                    </Col>
                </Row>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">活动类型</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <Select style={{ width: 120, marginRight: '8px' }} placeholder={'请选择活动类型'} value={discount_type} disabled={channel_type === 'bank' ? true : false} onChange={(e:any) => {this.setState({discount_type: e})}}>
                            <Option value={3} key={3}>满减</Option>
                            <Option value={1} key={1}>全单打折</Option>
                        </Select>
                    </Col>
                </Row>
                {
                    discount_type == 3
                    ?   <Row type='flex' align='middle' className='mb30'>
                            <Col offset={2}>每满￥</Col>
                            <Col><Input style={{width: 50,margin:'0 5px'}} name='full_money' value={full_money} onChange={(e) => this.inputChange(e)} /></Col>
                            <Col> 减￥</Col>
                            <Col><Input style={{width: 50,margin:'0 5px'}} name='reduce_money' value={reduce_money} onChange={(e) => this.inputChange(e)} /></Col>
                        </Row>
                    : null
                }

                {
                    discount_type == 3
                    ?   <Row type='flex' align='middle' className='mb30'>
                            <Col span={2}>
                                <div>
                                    <span className="color_black">最高金额</span>
                                </div>
                            </Col>
                            <Col span={14}>
                                <Radio.Group
                                        value={highNum}
                                        name='highNum'
                                        onChange={(e) => this.inputChange(e)}
                                    >
                                        <Radio value={1}>无限</Radio>
                                        <Radio value={2}>最高减<Input style={{width: 50, margin:'0 5px'}} name='reduce_num' value={reduce_num} onChange={(e) => this.inputChange(e)} />次</Radio>
                                    </Radio.Group>
                            </Col>
                        </Row>
                    : null
                }

                {
                    discount_type == 1
                    ?   <Row className="mb30" type="flex" align="middle">
                            <Col span={2}>
                                <div>
                                    <span className="color_black">打多少折</span>
                                </div>
                            </Col>
                            <Col span={6}>
                                <Input placeholder="例填写90表示打9折，不填写则不打折" name={'discount'} value={discount} onChange={(e) => this.inputChange(e)} type='number'/>
                            </Col>
                        </Row>
                    : null
                }

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">状态</span>
                        </div>
                    </Col>
                    <Col span={14}>
                        <Radio.Group
                                value={is_effect}
                                name='is_effect'
                                onChange={(e) => this.inputChange(e)}
                            >
                                <Radio value={1}>启用</Radio>
                                <Radio value={2}>停用</Radio>
                            </Radio.Group>
                    </Col>
                </Row>

                <Row>
                    <Col span={12}>
                        <div className="bottom_wrap">
                            <Button type="primary" ghost onClick={this.goBack}>
                                取消
                            </Button>
                            <Button type="primary" onClick={this.handleSubmit}>
                                保存
                            </Button>
                        </div>
                    </Col>
                </Row>

        </div>;
    }

}
export default PaymentAdd