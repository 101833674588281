import React, { useEffect, useState, useRef } from 'react'
import ReactEcharts from "echarts-for-react";
import './lineChart.scss'

type Iprops = {
    color1?:String,
    color2?:String,
    bg1?:String,
    bg2?:String,
    current?:any,
    prev?:any,
    bottomLeftTitle?:String, 
    bottomRightTitle?:String,
    data?:any,
    xLine?:any,
    color3?:any,
    legendList?: any
}

const LineChart = (props:Iprops)=>{
    
    const { legendList, color3, xLine, data, color1, color2, bg1, bg2, current, prev, bottomLeftTitle, bottomRightTitle} = props

    const [option, setOption] = useState<any>({
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985'
                }
            }
        },
        legend: {
            data: legendList,
            bottom: '15px',
            itemHeight: 15,
            borderColor :'yellow',
            icon:'circle'
        },
        color : color3,
        grid: {
            left: '3%',
            right: '4%',
            bottom: '60px',
            top: '8%',
            containLabel: true,
            show:true,
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: false,
                data: xLine,
            },
        ],
        yAxis: [
            {
                type: 'value',
            }
        ],        
        series: data,
    });

    useEffect(()=>{
            //初始化数据
            let optionTemp:any = JSON.parse(JSON.stringify(option))
            optionTemp.xAxis[0].data = xLine
            optionTemp.series = data
            optionTemp.legend.data = legendList
            console.log('optionTemp:',optionTemp)
            setOption(optionTemp)
    },[data])

    const progress:any = {
        num: 0.8,
        text: '6439.68',
    }

    return (
        <div className="arcProgress">
            <div style={{ height: '350px', width: '100%' }}>
                <ReactEcharts option={option} style={{ height: '100%', width: '100%' }} className='react_for_echarts' />
            </div> 
        </div>
    )
}

export default LineChart



