import React, { Fragment, useEffect, useState } from 'react'
import { Typography, Row, Form, Input, Button, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { postRequest } from '../../api/http';
import { apiUrl } from '../../api/api_url';

const { Title } = Typography;

const EditVipLevel = () => {

  const history:any = useHistory();
  const [levelData, setlevelData] = useState({
    name: '',
    score: ''
  });

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    const { type } = history.location.state;
    if(type === 'edit') {
      console.log('编辑')
      setlevelData({
        ...history.location.state.rowdata
      })
    }

    if(type === 'add') {
      console.log('添加')
    }
  }

  const nameChange = (e: string) => {
    setlevelData({...levelData, name: e});
  }

  const scoreChange = (e: string) => {
    setlevelData({...levelData, score: e});
  }

  const saveLevel = async () => {
    const { type } = history.location.state;
    if(type === 'edit') {
      const res:any = await postRequest(apiUrl.fansLevelEdit, levelData);
      if(res && res.data) {
        message.success(res.message);
        history.goBack();
      }
    }

    if(type === 'add') {
      if(!levelData.name) {
        message.warn('请输入正确的等级昵称');
        return;
      }
      if(!Number(levelData.score)) {
        message.warn('请输入正确的积分数量');
        return;
      }
      const res:any = await postRequest(apiUrl.fansLevelAdd, levelData);
      if(res && res.data) {
        message.success(res.message);
        history.goBack();
      }
    }
  }

  return <Fragment>
    <div className='l_content'>
    <Title level={4} style={{marginBottom: 20}}>{history.location.state.type === 'edit' ? '编辑等级' : '添加等级'}</Title>

    <Row>
      <Row type="flex" align="middle">
        <Row>等级名称</Row>
        <Input placeholder="输入等级名称" value={levelData.name} onChange={(e) => nameChange(e.target.value)} style={{width: 300, marginLeft: 10}} />
      </Row>
      <Row type="flex" align="middle" style={{marginTop: 20}}>
        <Row>积分大于</Row>
        <Input placeholder="输入积分数量" value={levelData.score} onChange={(e) => scoreChange(e.target.value)}  style={{width: 300, marginLeft: 10}} />
      </Row>
    </Row>

    <Row type="flex" justify="center" style={{marginTop: 50}}>
      <Button onClick={() => history.goBack()}>取消</Button>
      <Button type="primary" style={{marginLeft: 40}} onClick={saveLevel}>保存</Button>
    </Row></div>
  </Fragment>
}

export default EditVipLevel