import React from "react";
import { Row, Col, DatePicker, Table, Button, Modal, Input, message, } from "antd";
import { getRequest } from "../../api/http";
import { apiUrl } from "../../api/api_url";
import QRCode  from 'qrcode.react';
import { getIsPermisson, messageCustom } from "../../utils/permisstionUtil";
// import QRCode from "../qRCode/qRCode";
// import QRCode  from 'qrcode.react';
const { RangePicker } = DatePicker;


class StoreCharge extends React.Component<{}> {
    state = {
        name: '',
        productCount: 0,
        productList: [],
        currentPage: 1,
        pageSize: 10,
        timeArray: [],
        recharge_balance: '0.00',
        remain_balance: '0.00',
        visible: false,
        num: 0,
        imgUrl: '',
        code: ''
    }

    columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            className: 'list_center',
       },
        {
          title: '充值用户',
          dataIndex: 'nickname',
          key: 'nickname',
          className: 'list_center',
          render: (text: string, record:any) => {
                return <div className="">
                    <img src={record.headimgurl} style={{width: '30px',height: '30px', borderRadius:'50%', display: 'inline-block'}}></img>
                    <div className='' style={{display: 'inline-block', marginLeft: '10px'}}>{record.nickname}</div>
                </div>
            }
        },
        {
            title: '充值金额（元）',
            dataIndex: 'amount',
            key: 'amount',
            className: 'list_center',
        },
        {
            title: '交易单号',
            dataIndex: 'out_trade_no',
            key: 'out_trade_no',
            className: 'list_center',
        },
        {
            title: '充值时间',
            dataIndex: 'created_at',
            key: 'created_at',
            className: 'list_center',
        },
    ];

    componentDidMount(){
        this._initList();
    }

    _initList = async () => {

        const {pageSize, timeArray, currentPage, } = this.state;
        const params:any = {
            page: currentPage,
            page_size: pageSize
        };

        if(timeArray.length > 0){
            params.start_at = timeArray[0];
            params.end_at = timeArray[1];
        }

        const res:any = await getRequest(apiUrl.rechargeList,params);
        if(res.code === 1){
            this.setState({
                productList: res.data.data.list,
                productCount:  res.data.data.total,
                recharge_balance: res.data.recharge_balance,
                remain_balance: res.data.remain_balance
            })
        }
    }

    timeChange = async (time: any, timeString: [string, string]) => {
        this.setState({
            timeArray: timeString,
            currentPage: 1
        },() => {
            this._initList()
        })
    }

    pageChange = (page: number) => {
        this.setState({
            currentPage: page
        },() => {
            this._initList()
        })
    }

    // 获取充值订单号
    // rechargCode
    getCode = async () => {
        let res:any = await getRequest(apiUrl.rechargCode)
        if(res.code == 1){
            this.setState({
                code: res.data.out_trade_no,
                visible: true
            })
        }
    }
    inter:any = '';

    // 生成充值二维码
    recharge = async () => {
        let { num, code } = this.state;
        let res:any = await getRequest(apiUrl.rechargePay, {total_fee: num, out_trade_no:code })
        if(res.code == 1){
            this.setState({
                imgUrl: res.data.code_url
            })

            this.inter = setInterval(() => {
                this.getStatus()
            }, 1000)
        }
    }

    getStatus = async () => {
        let { code } = this.state;
        let res:any = await getRequest(apiUrl.rechargeStatus, {out_trade_no:code })
        .then((res: any) => {
            if(res.code == 1){
                if(res.data.status == 1){
                    message.success('支付成功')
                    this.setState({
                        visible: false,
                        imgUrl: '',
                        num: 0
                    })
                    this._initList();
                    this.inter && clearInterval(this.inter)
                }else if(res.data.status == 2 || res.data.status == 0){
                    message.error('支付失败, 请重新扫码')
                    this.inter && clearInterval(this.inter)
                    this.reRequest();
                }
            }
        })
        .catch((err) => {
            message.success('支付出错，请重新扫码');
            this.reRequest();
        })
    }

    reRequest = async () => {
        let { num } = this.state;
        if(num < 1000){
            message.warning('最低充值1000元')
            return;
        }
        this.inter && clearInterval(this.inter)
        let res:any = await getRequest(apiUrl.rechargCode)
        if(res.code == 1){
            this.setState({
                code: res.data.out_trade_no,
            },() => {
                this.recharge();
            })
        }
    }

    render(){

        const { productList, recharge_balance, remain_balance, imgUrl } = this.state;
        return <div className='l_content'>
            <Row type="flex" align="middle" justify="start" className="mt20 mb40" >
                <Col>
                    <span>充值时间：</span>
                    <RangePicker onChange={this.timeChange} placeholder={["开始时间","结束时间"]} format="YYYY-MM-DD"/>
                </Col>
                <Col offset={1}>
                    <Button type='primary' onClick={() => {
                        if(!getIsPermisson('1_9_5_1')){
                            messageCustom();
                            return;
                          }
                        this.setState({visible: true})
                    }}>充值</Button>
                </Col>
            </Row>

            <div style={{display:'flex'}}><div className='mb40' style={{fontWeight: 'bold', fontSize: '16px'}}>总充值：{recharge_balance}元</div>
            <div className='mb40' style={{fontWeight: 'bold', fontSize: '16px',marginLeft: '20px'}}>剩余金额：{remain_balance}元</div></div>

            <div>
                <Table
                dataSource={productList}
                columns={this.columns}
                rowKey={"id"}
                size="middle"
                locale={{emptyText: '暂无数据'}}
                pagination={{
                    current: this.state.currentPage,
                    total: this.state.productCount,
                    pageSize: this.state.pageSize,
                    hideOnSinglePage: true,
                    onChange: this.pageChange,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    showTotal: (total: number) => `共${total}条`,
                    onShowSizeChange: (current:number, size: number) => {this.setState({pageSize: size}, () => {this._initList();})}
                }}/>
            </div>

            <Modal
                title='充值二维码'
                footer={null}
                visible={this.state.visible}
                onCancel={() => {this.inter && clearInterval(this.inter);this.setState({visible: false, imgUrl: '', num: 0})}}
            >
                <div className='input_wrap' style={{display:'flex',marginTop:'20px'}}>
                    <Input placeholder='最低充值1000元' type='number' value={this.state.num} onChange={(e) => {this.setState({num: e.target.value})}}/>
                    <Button onClick={this.reRequest}>充值</Button>
                </div>
                <div style={{width: '158px', height: '158px', margin:'20px auto', border:'1px solid #eee', display:'flex', alignItems: 'center',justifyContent: 'center'}}>
                    {imgUrl ? <QRCode
                                    value={this.state.imgUrl}  //value参数为生成二维码的链接
                                    size={158} //二维码的宽高尺寸
                                    fgColor="#000000"  //二维码的颜色
                                /> : '此处生成充值二维码'}
                </div>
                <div className='' style={{textAlign:'center',marginBottom:'20px'}}>微信扫一扫支付</div>
            </Modal>
        </div>;
    }
}
export default StoreCharge