import React from 'react';

import './addManageUser.scss';
import { Input, Button, Transfer, message, Radio } from 'antd';
import { apiUrl } from '../../../../api/api_url';
import { getRequest, postRequest } from '../../../../api/http';
type IProps = {
    history: any,
    location: any
}

type IState = {
    userName: string,
    userPhone: string,
    mockData: any,
    targetKeys: any,
    userStatus: number,
    userPassword: string,
    user_id: any,
    isShowPassword: boolean
}

class AddManageUser extends React.Component<IProps,IState>{

    constructor(props:IProps){
        super(props);
        this.state = {
            userName: '',
            userPhone: '',
            mockData: [],
            targetKeys: [],
            userStatus: 1,
            userPassword: '',
            user_id: 0,
            isShowPassword: true,
        }
    }
    user_id = 0;
    // 监听用户名称
    handleUserName =(userName: any) =>{
        this.setState({userName});
    }
    // 监听用户手机号填写
    handlePhone =(phone: any) =>{
        this.setState({userPhone: phone});
    }
    // 监听用户密码改变
    handlePassword = (password:any) =>{
        this.setState({userPassword: password});
    }

    componentDidMount() {
        if(this.props.location.state){
            let user = this.props.location.state.user;
            // 更新
            this.user_id = user.user_id;
            this.setState({
              userName: user.nickname,
              userPhone: user.mobile,
              userStatus: user.status,
              user_id:user.user_id,
              isShowPassword: false
            });
          }
          this.getMock();

    }
    getMock =  async () => {
        let targetKeys:any = [];
        let mockData:any = [];
        const res:any = await getRequest(apiUrl.roleList,{current: 1,
            pageSize: 10000
        });
        if(res.code == 1){
            let roles = res.data.list;
            if(this.user_id != 0){
                const res2:any = await getRequest(apiUrl.getManageRoles,{user_id: this.user_id });
                if(res2.code == 1){
                    res2.data.map((item:any) => targetKeys.push(item.id.toString()));
                }else{
                    message.error("服务器异常"+res2.message);
                }
            }
            for (let i = 0; i < roles.length; i++) {
                console.log(roles[i]);
                if(!roles[i].is_super_admin){
                    const data = {
                        key: roles[i].role_id.toString(),
                        title: roles[i].display_name
                    };
                    mockData.push(data);
                }

            }
            this.setState({ mockData, targetKeys });
        }else{
            message.error('服务器异常'+ res.message);
        }
    };
    // 修改 管理者状态
    onChange = (e:any) => {
        this.setState({
            userStatus: e.target.value,
        });
    };

    // 筛选角色
    filterOption = (inputValue:any, option:any) => option.title.indexOf(inputValue) > -1;

    // 穿梭框改变
    handleChange = (targetKeys: any) => {
        this.setState({ targetKeys });
    };

    // 提交用户信息
    submitMangerUser = async () =>{
        let {userName,userPhone,targetKeys,userStatus,userPassword} = this.state;
        if(!userName){
            message.warn('请填写用户姓名！');
            return;
        }

        var strTemp = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
        if (!strTemp.test(userPhone)) {
            message.warn('请核对手机号!');
            return;
        }
        if(this.user_id == 0 && !userPassword){
            message.warn('请给管理者设置密码！');
            return;
        }
        if(targetKeys.length == 0){
            message.warn('请给用户添加权限！');
            return;
        };
        let params: any = {
            nickname: userName,
            mobile: userPhone,
            role_arr: targetKeys,
            status: userStatus
        }

        if(this.user_id != 0 )params.user_id = this.user_id;
        if(userPassword) {
            if(this.vCodeChange(userPassword)) {
                return message.warning('密码不能输入中文！');
            }
            params.password = userPassword;
        }
        const res:any = await postRequest(this.user_id == 0 ? apiUrl.addManageUser:apiUrl.updateManageAccount,params);
        if(res.code == 1){
            let strInfo = this.user_id == 0 ?'管理者添加成功': '管理者更新成功';
            message.success(strInfo);
            this.props.history.goBack();
        }else{
            message.error('服务器异常'+ res.message);
        }
    }

    //中文验证
    vCodeChange = (e: any) => {
        const reg = /\p{Unified_Ideograph}/ug;	// 以任意数字开头和结尾，且中间出现零个或多个数字
        return reg.test(e)
    }

    render() {
        let {userName,userPhone,mockData, targetKeys,userStatus,userPassword,user_id,isShowPassword} = this.state;
        return (
            <div className="store_user l_content">
                <div className="head_title">添加用户</div>
                <div className="base_info">
                    <div className="input_view">
                        <div className="view_text">
                            商家端用户姓名：
                        </div>
                        <div className="input_class">
                            <Input placeholder="输入角色名称" value={userName}  onChange={(e) => this.handleUserName(e.target.value)} />
                        </div>
                    </div>
                    <div className="input_view">
                        <div className="view_text">
                            用户登陆手机号：
                        </div>
                        <div className="input_class">
                            <Input placeholder="输入角色描述"  value={userPhone} onChange={(e) => this.handlePhone(e.target.value)} />
                        </div>
                    </div>
                    <div className="input_view">
                        <div className="view_text">
                            账户状态：
                        </div>
                        <Radio.Group onChange={this.onChange} value={userStatus}>
                            <Radio value={1}>正常</Radio>
                            <Radio value={2}>冻结</Radio>
                        </Radio.Group>
                    </div>
                    {
                        user_id == 0 || isShowPassword ?
                        <div className="input_view">
                            <div className="view_text">
                                用户密码：
                            </div>
                            <div className="input_class">
                                <Input placeholder="Password"  value={userPassword} onChange={(e) => this.handlePassword(e.target.value)}/>

                            </div>
                            <Button type="danger" onClick={() => { this.setState({isShowPassword: false})}}>取消</Button>
                        </div>
                        :
                        <div className="input_view">
                            <Button type="danger" onClick={() => { this.setState({isShowPassword: true})}}>重新设置密码</Button>
                        </div>
                    }

                </div>
                <div className="addRole_view">
                    <Transfer
                        titles={['全部角色', '已选角色']}
                        dataSource={mockData}
                        showSearch
                        className="transfer_class"
                        filterOption={this.filterOption}
                        targetKeys={targetKeys}
                        onChange={this.handleChange}
                        render={(item:any) => item.title}
                    />
                </div>
                <div className="button_group">
                  <Button className="canel_action" onClick={() => {this.props.history.goBack()}}>取消</Button>
                  <Button type="primary" onClick={() => this.submitMangerUser()}>确认</Button>
                </div>
            </div>
        );
    }

}

export default AddManageUser;