import React, { useEffect, useState } from 'react'
import { getRequest, postRequest } from '../../api/http'
import { apiUrl } from '../../api/api_url'
import { useHistory } from 'react-router-dom';
import './orderingFood.scss'
import { Button, Input, Badge, Pagination, message, Icon, Modal, Checkbox } from 'antd';

import AddAttributeDich from './addAttributeDich'
import SelectHumanNumber from './selectHumanNumber'
import EditOrderNumber from './editOrderNumber'
import PresentFood from './presentFood'
import OrderReloadPrint from './orderReloadPrint'
import NumChangeDialog from './numChangeDialog'
import CancelPresent from './cancelPresent'
import stock_over from '../../assret/images/stock_over_2.png'
import { keepTwoNum, Multiply, Add, Minus } from '../../utils/numUtils';
import style from './setMeal.module.scss'
import { getIsPermisson, messageCustom } from '../../utils/permisstionUtil';

const { TextArea } = Input;
const { Search } = Input;

//选规格start
interface attributeDataType {
    visible: boolean,
    onOk: Function,
    onCancel: Function,
    data: any,
    is_vip_fans?: any,
    systemConfig?:any,
}

const OrderingFood = () => {
    const history: any = useHistory();
    //所有菜品
    const [foodAll, setfoodAll] = useState<any>([])
    const [originFoodAll, setoriginFoodAll] = useState<any>([])
    const [is_vip_fans, setis_vip_fans] = useState<any>(false)

    const [isWait,setisWait] = useState(false)
    const [waitSelect,setwaitSelect] = useState(false)

    const waitSelecteAction = (e:any)=>{
        setwaitSelect(e.target.checked)
    }

    useEffect(() => {

        if (history.location.state) {
            if (history.location.state.dineKey) { settableKey(history.location.state.dineKey) }
            if (history.location.state.code) { settableCode(history.location.state.code) }
            if (history.location.state.is_eating === false) {
                openWindowAction()
            } else {
                startup(undefined)
            }
            if (history.location.state.userAmount) {
                setwindowUserAmount(history.location.state.userAmount)
            }
            if (history.location.state.is_vip_fans) {
                setis_vip_fans(history.location.state.is_vip_fans)
            }
            if (history.location.state.systemConfig) {
                if(history.location.state.systemConfig.wait_call_open === 5){
                    setisWait(true)
                }
                
            }
        }
        controPermisson()
        setMealListRequest()
        getZeroOrder()
    }, [])

    const controPermisson =() => {
        if(getIsPermisson('1_11_6_1')){
            setonTab(0)
        }else{
            if(getIsPermisson('1_11_6_2_8')){
                setonTab(1)
            }
        }
    }

    //初始执行方法
    const startup = async (code: undefined | string) => {
        const allDishList: any = await allDishListRequest();
        const yetOrder: any = await yetOrderRequest(code ? code : history.location.state.code);
        if (yetOrder !== null) {
            setTimeout(() => {
                yetOrderRefresh(yetOrder.data, allDishList.data)
            }, 100)
        }
    }

    const allDishListRequest = async () => {
        let param = {}
        const res: any = await getRequest(`${apiUrl.getDishLists}`, param);
        if (res.code === 1) {
            res.data && res.data.map((item:any) => {
                item.dish_list.map((dish:any) => {
                    if(!dish.has_specs){
                        dish.old_price = dish.price
                        dish.old_price_vip = dish.price_vip
                    }
                })
            })
            setfoodAll(res.data)
            setoriginFoodAll(JSON.parse(JSON.stringify(res.data)))
            currentPageListRefresh(res.data)
            return res
        } else {
            return null
        }
    }

    // 订单备注
    const [orderNotes, setOrderNotes] = useState('');
    const [orderNotesDefault, setOrderNotesDefault] = useState([
        {
            name: '少放辣',
            active: false,
        },
        {
            name: '多放辣',
            active: false,
        },
        {
            name: '不吃葱',
            active: false,
        },
        {
            name: '不吃香菜',
            active: false,
        },
        {
            name: '不吃蒜',
            active: false,
        },
    ]); // 订单备注默认列表
    const [orderNotesStatus, setOrderNotesStatus] = useState(false); //订单备注状态

    //餐桌相关参数
    const [tableKey, settableKey] = useState()
    const [tableCode, settableCode] = useState()
    const [tableName, settableName] = useState('')
    const [windowUserAmount, setwindowUserAmount] = useState(0)

    //关闭选择人数弹窗
    const closeHumanNumberWindow = () => {
        setselectHumanNumberData({ visible: false })
    }

    //开空桌弹窗
    const openWindowAction = () => {
        setselectHumanNumberData({
            visible: true,
            onCancel: () => {
                closeHumanNumberWindow()
                history.push({
                    pathname: '/sidebar/windowManage'
                })
            },
            onOk: (humanNumber: number) => {
                openWindow(history.location.state.tableId, humanNumber)
            },
            onSelectMeal: (id: number, index: number, info: any) => {
                info.dishes.map((item: any) => {
                    let num = 0;
                    item.attr = [];
                    item.dishes.map((item2: any) => {
                        item2.active = false
                        if(!item2.has_specs){
                            num++
                        }
                    })
                    if(item.dishes.length == item.num && item.dishes.length == num){
                        item.choose = true
                        item.dishes.map((item2: any) => {
                            item2.active = true;
                        })
                    }
                })
                setmealInfo(info)
                setMealVisible(true)
            },
            tableName: history.location.state.tableName
        })
    }

    //开空桌
    const openWindow = async (table_id: any, user_amount: any) => {
        let param = {
            user_amount: user_amount,
            table_id: table_id
        }
        const res: any = await getRequest(`${apiUrl.openDeskWithEmpty}`, param);
        if (res.code === 1) {
            message.info('开桌成功，请点菜');
            settableKey(res.data.data.table_key)
            settableCode(res.data.data.code)
            setwindowUserAmount(user_amount)
            closeHumanNumberWindow()
            startup(res.data.data.code)
        }
    }

    // 修改用餐人数弹窗
    const editDineNumberAction = () => {
        if(!getIsPermisson('1_11_6_2_5')){
            messageCustom();
            return;
          }
        setselectHumanNumberData({
            visible: true,
            onCancel: () => { closeHumanNumberWindow() },
            onOk: (humanNumber: number, setList: any) => {
                editDineNumberRequest(history.location.state.dineKey ? history.location.state.dineKey : tableKey, humanNumber)
                setsetMealListData(setList)
            },
            tableName: history.location.state.tableName,
            title: '修改用餐人数',
            hideSet: true
        })
    }

    //修改用餐人数
    const editDineNumberRequest = async (table_key: any, user_amount: any) => {
        let param = {
            user_amount: user_amount,
            table_key: table_key
        }
        const res: any = await getRequest(`${apiUrl.editUsersByDineKey}`, param);
        if (res.code === 1) {
            message.info('修改人数成功');
            setwindowUserAmount(user_amount)
            yetOrderRequest(tableCode)
            closeHumanNumberWindow()
        }
    }

    const [selectHumanNumberData, setselectHumanNumberData] = useState<any>({ visible: false })

    const [selectTypeId, setselectTypeId] = useState(0)
    const selectTypeIdAction = (id: number) => {
        setcurrentPage(1)
        setselectTypeId(id)
        setotherView(false)
        setotherSelected(undefined)
    }

    //用于刷新的方法
    const [refresh, setRefresh] = useState(false);
    const refreshAction = () => {
        setRefresh((re) => !re)
    }

    useEffect(() => {
        orderTotal()
        currentPageListRefresh(foodAll)
    }, [refresh])

    //点击菜品操作
    const orderAddAction = (id: number) => {
        let allFood = foodAll
        allFood.map((item: any, index: number) => {
            let typeNum = 0
            item.dish_list.map((dishListItem: any, dishListIndex: number) => {
                if (dishListItem.id === id) {
                    if(originFoodAll[index].dish_list[dishListIndex].is_up){
                        if(!zeroOrder) {
                            if (originFoodAll[index].dish_list[dishListIndex].is_stock_over && (originFoodAll[index].dish_list[dishListIndex].residue == 0 || dishListItem.residue === 0)) {
                                message.info('剩余数量不够')
                                return
                            }
                        }
                    }else{
                        if (originFoodAll[index].dish_list[dishListIndex].is_stock_over && (originFoodAll[index].dish_list[dishListIndex].residue == 0 || dishListItem.residue === 0)) {
                            message.info('剩余数量不够')
                            return
                        }
                    }
                    allFood[index].dish_list[dishListIndex].residue--
                    allFood[index].dish_list[dishListIndex].currentFood = true
                    allFood[index].dish_list[dishListIndex].currentCount = allFood[index].dish_list[dishListIndex].currentCount ? Add(allFood[index].dish_list[dishListIndex].currentCount, 1) : 1
                    allFood[index].dish_list[dishListIndex].count = allFood[index].dish_list[dishListIndex].count ? Add(allFood[index].dish_list[dishListIndex].count, 1) : 1
                }
                if(allFood[index].dish_list[dishListIndex].count){
                    typeNum = Add(typeNum, allFood[index].dish_list[dishListIndex].count)
                }else{
                    typeNum = Add(typeNum, 0)
                }
            })
            allFood[index].count = typeNum
        })
        setfoodAll(allFood)
        refreshAction()
    }

    //添加菜品操作
    const orderAddMinusAction = (type: string, A: number, B: number) => {
        let allFood = foodAll

        if (type === 'add') {
            if(originFoodAll[A].dish_list[B].is_up){
                if(!zeroOrder) {
                    if (originFoodAll[A].dish_list[B].is_stock_over && (originFoodAll[A].dish_list[B].residue == 0 || allFood[A].dish_list[B].residue === 0)) {
                        message.info('剩余数量不够')
                        return
                    }
                }
            }else{
                if (originFoodAll[A].dish_list[B].is_stock_over && (originFoodAll[A].dish_list[B].residue == 0 || allFood[A].dish_list[B].residue === 0)) {
                    message.info('剩余数量不够')
                    return
                }
            }

            allFood[A].dish_list[B].currentFood = true
            allFood[A].dish_list[B].currentCount = allFood[A].dish_list[B].currentCount ? allFood[A].dish_list[B].currentCount + 1 : 1
            allFood[A].dish_list[B].count = allFood[A].dish_list[B].count ? allFood[A].dish_list[B].count + 1 : 1
            allFood[A].dish_list[B].residue--

            allFood[A].dish_list[B].currentCount = parseFloat(allFood[A].dish_list[B].currentCount.toFixed(2))
            allFood[A].dish_list[B].count = parseFloat(allFood[A].dish_list[B].count.toFixed(2))
        }
        if (type === 'minus') {
            allFood[A].dish_list[B].currentFood = true
            allFood[A].dish_list[B].currentCount = allFood[A].dish_list[B].currentCount && allFood[A].dish_list[B].currentCount > 1 ? allFood[A].dish_list[B].currentCount - 1 : 0
            allFood[A].dish_list[B].count = allFood[A].dish_list[B].count && allFood[A].dish_list[B].count > 1 ? allFood[A].dish_list[B].count - 1 : 0

            allFood[A].dish_list[B].currentCount = parseFloat(allFood[A].dish_list[B].currentCount.toFixed(2))
            allFood[A].dish_list[B].count = parseFloat(allFood[A].dish_list[B].count.toFixed(2))

            if (allFood[A].dish_list[B].currentCount <= 0) {
                allFood[A].dish_list[B].currentFood = false
            }
            allFood[A].dish_list[B].residue++
        }
        setfoodAll(allFood)
        updateBadge()
    }

    // 删除点菜列表无规格
    const deleteOrder = (A: number, B: number) => {
        foodAll[A].dish_list[B].count = foodAll[A].dish_list[B].originCount ? foodAll[A].dish_list[B].originCount : 0
        foodAll[A].dish_list[B].currentCount = 0
        foodAll[A].dish_list[B].currentFood = false
        foodAll[A].dish_list[B].residue = originFoodAll[A].dish_list[B].residue
        setTimeout(() => {
            updateBadge()
        }, 100)
    }

    // 删除带规格菜品
    const deleteAttrOrder = (A: number, B: number, C: number, type: string) => {
        let list = foodAll;
        list[A].dish_list[B].count = Minus(list[A].dish_list[B].count, list[A].dish_list[B].selectedDish[C].count)
        list[A].dish_list[B].residue = originFoodAll[A].dish_list[B].residue
        list[A].count = Minus(list[A].count, list[A].dish_list[B].selectedDish[C].count)
        list[A].dish_list[B].selectedDish.splice(C, 1)
        setfoodAll(list)
        refreshAction()
    }

    //添加带规格的菜品
    const orderAttrAction = (A: number, B: number, C: number, type: string) => {
        let list = foodAll;

        if (type === 'add') {
            if(originFoodAll[A].dish_list[B].is_up){
                if(!zeroOrder) {
                    if (originFoodAll[A].dish_list[B].is_stock_over && (originFoodAll[A].dish_list[B].residue == 0 || list[A].dish_list[B].residue === 0)) {
                        message.warning('剩余数量不够')
                        return
                    }
                }
            }else{
                if (originFoodAll[A].dish_list[B].is_stock_over && (originFoodAll[A].dish_list[B].residue == 0 || list[A].dish_list[B].residue === 0)) {
                    message.warning('剩余数量不够')
                    return
                }
            }

            if (originFoodAll[A].dish_list[B].residue > 0) {
                list[A].dish_list[B].residue--
            }
            list[A].dish_list[B].selectedDish[C].count++
            list[A].dish_list[B].selectedDish[C].currentCount++

            list[A].dish_list[B].selectedDish[C].currentCount = parseFloat(list[A].dish_list[B].selectedDish[C].currentCount.toFixed(2))
            list[A].dish_list[B].selectedDish[C].count = parseFloat(list[A].dish_list[B].selectedDish[C].count.toFixed(2))
        }
        if (type === 'minus') {
            if (list[A].dish_list[B].selectedDish[C].currentCount > 1) {
                list[A].dish_list[B].selectedDish[C].count--
                list[A].dish_list[B].selectedDish[C].currentCount--

                list[A].dish_list[B].selectedDish[C].currentCount = parseFloat(list[A].dish_list[B].selectedDish[C].currentCount.toFixed(2))
                list[A].dish_list[B].selectedDish[C].count = parseFloat(list[A].dish_list[B].selectedDish[C].count.toFixed(2))
            } else {
                let selectedDishList = list[A].dish_list[B].selectedDish
                selectedDishList.splice(C, 1)
                list[A].dish_list[B].selectedDish = selectedDishList
            }
            if (originFoodAll[A].dish_list[B].residue > 0) {
                list[A].dish_list[B].residue++
            }
        }
        setfoodAll(list)
        updateBadge()
    }

    // 点菜总价
    const [listTotalCount, setlistTotalCount] = useState(0)
    const [listTotalPrice, setlistTotalPrice] = useState(0)

    const orderTotal = () => {
        let total = 0
        let price = 0
        foodAll.map((item: any) => {
            item.dish_list.map((itemA: any) => {
                if (itemA.selectedDish) {
                    itemA.selectedDish.map((itemB: any) => {
                        if (itemA.currentCount) {
                            total += itemB.currentCount ? itemB.currentCount : 0
                            if(itemB.is_vip_set && is_vip_fans){
                                price = Add(price, Multiply(parseFloat(itemB.price_vip) , itemB.currentCount))
                            }else{
                                price = Add(price, itemB.price ? Multiply(parseFloat(itemB.price) , itemB.currentCount) : 0)
                            }
                        }
                    })
                } else {
                    if (itemA.currentCount) {
                        total += itemA.currentCount ? itemA.currentCount : 0
                        if(itemA.is_vip_set && is_vip_fans){
                            price = Add(price, Multiply(parseFloat(itemA.price_vip), itemA.currentCount))
                        }else{
                            price = Add(price, itemA.price ? Multiply(parseFloat(itemA.price), itemA.currentCount) : 0)
                        }
                    }
                }
            })
        })
        total = parseFloat(parseFloat(String(total)).toFixed(2))
        price = keepTwoNum(price)
        setlistTotalCount(total)
        setlistTotalPrice(price)
    }

    //下单
    const placeOrder = async () => {
        if(!getIsPermisson('1_11_6_2_4')){
            messageCustom();
            return;
          }
        let price = 0
        let list: any = []
        let presenterList: any = []
        let set_meal_list: any = []
        foodAll.map((item: any) => {
            item.dish_list.map((itemA: any) => {
                if (itemA.selectedDish) {
                    itemA.selectedDish.map((itemB: any) => {
                        console.log('下单',itemB);
                        if(itemB.material_id && itemB.material_id.length > 0){
                            let arr:any = [];
                            itemB.material_id.forEach((material:any) => {
                                arr.push(material + '_1')
                            })
                            itemB.material_id = arr
                        }
                        if (itemB.presenter && itemB.currentCount) {
                            presenterList.push({
                                id: itemA.id,
                                num: itemB.currentCount,
                                dishes_attribute_id: itemB.id ? itemB.id : 0,
                                pack_num: itemB.presenter ? itemB.currentCount : 0,
                                dish_weigh_value_id: itemB.weigh_value_id ? itemB.weigh_value_id : 0,
                                material_key: itemB.material_id && itemB.material_id.length > 0 ? itemB.material_id.join(',') : null
                             })
                        } else {
                            if (itemB.currentCount) {
                                list.push({
                                    id: itemA.id,
                                    num: itemB.currentCount,
                                    dishes_attribute_id: itemB.id ? itemB.id : 0,
                                    pack_num: itemB.pack ? itemB.currentCount : 0,
                                    dish_weigh_value_id: itemB.weigh_value_id ? itemB.weigh_value_id : 0,
                                    material_key: itemB.material_id && itemB.material_id.length > 0 ? itemB.material_id.join(',') : null
                                 })
                                price = Add(price, itemB.price ? Multiply(parseFloat(itemB.price) , itemB.currentCount) : 0)
                            }
                        }
                    })
                } else {
                    if (itemA.presenter && itemA.currentCount) {
                        presenterList.push({
                            id: itemA.id,
                            num: itemA.currentCount,
                            dishes_attribute_id: 0,
                            dish_weigh_value_id: 0,
                            pack_num: itemA.pack ? itemA.currentCount : 0,
                            material_key: null
                        })
                    } else {
                        if (itemA.currentCount) {
                            list.push({
                                id: itemA.id,
                                num: itemA.currentCount,
                                dishes_attribute_id: 0,
                                dish_weigh_value_id: 0,
                                pack_num: itemA.pack ? itemA.currentCount : 0,
                                material_key: null
                             })
                            price = Add(price, itemA.price ? Multiply(parseFloat(itemA.price) , itemA.currentCount) : 0)
                        }
                    }
                }
            })
        })
        // return;
        console.log(orderSetmeal);
        orderSetmeal.map((itemA: any, indexA: number) => {
            let arr: any = [];
            itemA.newdishes.map((dish: any) => {
                arr.push({
                    id: dish.dish_id,
                    num: dish.num,
                    attribute_id: dish.has_specs && dish.attribute_id ? dish.attribute_id : 0,
                    dish_weigh_value_id: dish.dish_weigh_value_id ? dish.dish_weigh_value_id : 0
                })
            })
            set_meal_list.push({
                id: itemA.id,
                num: itemA.count,
                dishes: arr
            })
        })
        console.log('套餐',set_meal_list);

        let param: any = {
            user_amount: windowUserAmount,
            table_key: tableKey,
            dish_list: list,
            order_price: keepTwoNum(price),
            presents: presenterList,
            set_meal: set_meal_list,
        }

        if(orderNotes) {
            param.mark = orderNotes
        }
        if(waitSelect){
            param.is_wait_call = 5
        }
        console.log('param',param)
        const res: any = await postRequest(`${apiUrl.orderCommitToKitchen}`, param);
        if (res.code === 1) {
            message.info('下单成功');
            settableCode(res.data.code)
            setlistTotalCount(0)
            setlistTotalPrice(0)
            yetOrderRequest(tableCode)
            setTimeout(() => {
                history.goBack()
            }, 100)
        } else {
            message.error(res.message);
        }
    }


    const waitCallAction = async ()=>{
        let param:any = {
            "code": tableCode
        }
        const res: any = await postRequest(`${apiUrl.codeCallToKitchen}`, param);
        if (res.code === 1) {
            message.info(res.message && '叫起成功');
        } else {
            message.error(res.message);
        }
    }

    //获取沽清后，收银台是否可点
    const [zeroOrder, setZeroOrder] = useState(false);
    const getZeroOrder = async () => {
        let res: any = await getRequest(apiUrl.getZeroOrder)
        if(res.code == 1) {
            let flag = res.data.zero_order;
            setZeroOrder(flag)
        }
    }

    const [attributeData, setattributeData] = useState<attributeDataType>({ visible: false, onOk: () => { }, onCancel: () => { }, data: {}, is_vip_fans: is_vip_fans })

    //添加带规格菜品
    const selectAttributeAction = async (id: number, type: undefined | string) => {
        let attrData: any = ''
        foodAll.map((itemA: any) => {
            itemA.dish_list.map((itemB: any) => {
                if (itemB.id === id) {
                    attrData = itemB
                }
            })
        })
        console.log('attrData', attrData)
        setattributeData({
            visible: true,
            onOk: (aaa: any) => {
                if (aaa === false) {
                    return
                }
                selectAttributeFunction(id, aaa, null, null)
            },
            onCancel: () => { closeAttributeWindow() },
            data: attrData,
            is_vip_fans: is_vip_fans
        })
    }

    //添加带规格菜品Function
    const selectAttributeFunction = (id: number, attribute: any, weigh: any, material_info: null) => {
        foodAll.map((item: any, index: number) => {
            item.dish_list.map((dishListItem: any, dishListIndex: number) => {
                if (dishListItem.id === id) {
                    if(foodAll[index].dish_list[dishListIndex].is_up){
                        if(!zeroOrder) {
                            if (dishListItem.is_stock_over && (foodAll[index].dish_list[dishListIndex].residue == 0 || dishListItem.residue == 0)) {
                                message.info('剩余数量不足')
                                return
                            }
                        }
                    }else{
                        if (dishListItem.is_stock_over && (foodAll[index].dish_list[dishListIndex].residue == 0 || dishListItem.residue == 0)) {
                            message.info('剩余数量不足')
                            return
                        }
                    }
                }

                if (dishListItem.id === id) {
                    let newNum = 1
                    let newSelectedDish = JSON.parse(JSON.stringify(attribute))
                    if(!attribute){
                        newSelectedDish = {};
                    }

                    newSelectedDish.count = 1
                    newSelectedDish.currentCount = 1
                    newSelectedDish.currentFood = true

                    // if(weigh){
                    //     newSelectedDish.weigh_id = weigh[0].id;
                    //     newSelectedDish.weigh_value_id = weigh[0].values[0].id;
                    //     newSelectedDish.weigh_name = weigh[0].values[0].name;
                    //     newSelectedDish.price = weigh[0].values[0].price;
                    //     newSelectedDish.price_vip = weigh[0].values[0].price_vip;
                    // }
                    let newData = dishListItem.selectedDish ? dishListItem.selectedDish : []
                    if(newData.length == 0){
                        newData.push(newSelectedDish)
                    }else{
                        let flag = 0;
                        newData.map((item: any, index: any) => {
                            let id1 = item.material_id && item.material_id.toString();
                            let id2 = newSelectedDish.material_id && newSelectedDish.material_id.toString();
                            if(item.id == newSelectedDish.id && item.weigh_value_id == newSelectedDish.weigh_value_id && id1 == id2){
                                item.count++
                                item.currentCount++
                            }else{
                                flag++
                                if(flag == newData.length){
                                    newData.push(newSelectedDish)
                                }
                            }
                        })
                    }

                    // let originSD = attributeFormate(newData, dishListItem.attribute, weigh, material_info)
                    foodAll[index].dish_list[dishListIndex].selectedDish = newData //originSD
                    foodAll[index].dish_list[dishListIndex].currentCount = newNum
                    console.log('foodAll[index].dish_list[dishListIndex]', foodAll[index].dish_list[dishListIndex])
                }
            })
        })
        setfoodAll(foodAll)
        updateBadge()
        closeAttributeWindow()
    }

    //修改带规格菜品
    const editAttributeAction = (A: number, B: number, C: number) => {
        let attrData: any = ''
        foodAll.map((itemA: any, ) => {
            itemA.dish_list.map((itemB: any) => {
                if (itemB.id === foodAll[A].dish_list[B].id) {
                    if(itemB.selectedDish && itemB.selectedDish.length > 0){
                        itemB.price = itemB.selectedDish[C].price
                        itemB.price_vip = itemB.selectedDish[C].price_vip
                    }
                    itemB.material_info && itemB.material_info.map((t:any) => {
                        t.sel = false
                        itemB.selectedDish[C].material_id && itemB.selectedDish[C].material_id.map((id:any) => {
                            if(t.id == id){
                                t.sel = true
                            }
                        })
                    })
                    attrData = itemB
                }
            })
        })
        setattributeData({
            visible: true,
            onOk: (aaa: any) => {
                if (aaa === false) {
                    return
                }
                foodAll[A].dish_list[B].selectedDish[C].name = aaa.name
                foodAll[A].dish_list[B].selectedDish[C].name_id = aaa.name_id
                foodAll[A].dish_list[B].selectedDish[C].id = aaa.id
                foodAll[A].dish_list[B].selectedDish[C].price = aaa.price
                foodAll[A].dish_list[B].selectedDish[C].price_vip = aaa.price_vip
                foodAll[A].dish_list[B].selectedDish[C].weigh_value_id = aaa.weigh_value_id
                foodAll[A].dish_list[B].selectedDish[C].weigh_name = aaa.weigh_name
                foodAll[A].dish_list[B].selectedDish[C].material_name = aaa.material_name;
                foodAll[A].dish_list[B].selectedDish[C].material_id = aaa.material_id;
                foodAll[A].dish_list[B].selectedDish = attributeFormate(foodAll[A].dish_list[B].selectedDish, foodAll[A].dish_list[B].attribute, foodAll[A].dish_list[B].weigh, foodAll[A].dish_list[B].material_info)
                orderTotal();
                closeAttributeWindow()
            },
            onCancel: () => { closeAttributeWindow() },
            data: attrData,
            is_vip_fans: is_vip_fans
        })
    }

    //规格菜品数据格式化
    const attributeFormate = (selectedDish: any, attribute: any, weigh:any, material_info: any) => {
        console.log('规格菜品数据格式化', selectedDish, attribute, weigh)
        let newData: any = []
        let attributeData:any = JSON.parse(JSON.stringify(attribute));
        let weighData:any = JSON.parse(JSON.stringify(weigh));
        let materialData:any = JSON.parse(JSON.stringify(material_info));


        if(attribute){
            attributeData && attributeData.map((item: any, index: number) => {
                selectedDish && selectedDish.map((itemA: any) => {
                    if (itemA.id === item.id) {
                        attributeData[index].count = attributeData[index].count ? attributeData[index].count + itemA.count : itemA.count
                        attributeData[index].currentCount = attributeData[index].currentCount ? attributeData[index].currentCount + itemA.currentCount : itemA.currentCount
                        if(weigh){
                            attributeData[index].weigh_value_id = itemA.weigh_value_id;
                            attributeData[index].weigh_name = itemA.weigh_name;
                            attributeData[index].price = itemA.price;
                            attributeData[index].price_vip = itemA.price_vip;
                        }
                        if(materialData){
                            attributeData[index].material_id = itemA.material_id;
                            attributeData[index].material_name = itemA.material_name;
                        }
                    }
                })
            })
            attributeData.map((item: any) => {
                if (item.count && item.currentCount) {
                    newData.push(item)
                }
            })
        }else{
            weighData && weighData[0].values.map((item: any, index: number) => {
                selectedDish && selectedDish.map((itemA: any, indexA: number) => {
                    if (itemA.weigh_value_id === item.id) {
                        weighData[0].values[index].count = weighData[0].values[index].count ? weighData[0].values[index].count + itemA.count : itemA.count
                        weighData[0].values[index].currentCount = weighData[0].values[index].currentCount ? weighData[0].values[index].currentCount + itemA.currentCount : itemA.currentCount
                        if(weigh){
                            weighData[0].values[index].weigh_value_id = itemA.weigh_value_id;
                            weighData[0].values[index].weigh_name = itemA.weigh_name;
                            weighData[0].values[index].price = itemA.price;
                            weighData[0].values[index].price_vip = itemA.price_vip;
                        }
                        if(materialData){
                            weighData[index].material_id = itemA.material_id;
                            weighData[index].material_name = itemA.material_name;
                        }
                    }
                })
            })

            weighData && weighData[0].values.map((item: any) => {
                if (item.count && item.currentCount) {
                    item.id = null;
                    item.name = ''
                    newData.push(item)
                }
            })
        }

        if(!attributeData && !weigh && material_info){
            if(materialData){
                selectedDish.map((item: any) => {
                    if (item.count && item.currentCount) {
                        newData.push(item)
                    }
                })
            }
        }

        console.log('newData', newData)
        return newData
    }

    //修改完数据后更新角标
    const updateBadge = () => {
        let totalA = 0
        let totalB = 0
        let totalC = 0
        foodAll.map((itemA: any, indexA: number) => {
            if (itemA.dish_list.length <= 0) {
                return
            }
            itemA.dish_list.map((itemB: any, indexB: number) => {
                if (itemB.has_specs || itemB.material_info) {
                    if (itemB.selectedDish) {
                        itemB.selectedDish.map((itemC: any, indexC: number) => {
                            if (itemC.currentCount === 0) {
                                foodAll[indexA].dish_list[indexB].selectedDish[indexC].presenter = false
                                foodAll[indexA].dish_list[indexB].selectedDish[indexC].pack = false
                                foodAll[indexA].dish_list[indexB].selectedDish[indexC].attrbute = false
                                foodAll[indexA].dish_list[indexB].selectedDish[indexC].edit = false
                            }
                            // totalC += parseFloat(itemC.currentCount)
                            // totalB += parseFloat(itemC.currentCount)
                            // totalA += parseFloat(itemC.currentCount)
                            totalC = Add(totalC, parseFloat(itemC.currentCount))
                            totalB = Add(totalB, parseFloat(itemC.currentCount))
                            totalA = Add(totalA, parseFloat(itemC.currentCount))
                        })
                    }
                } else {
                    if (itemB.currentCount) {
                        totalB = Add(totalB, parseFloat(itemB.currentCount))
                        totalA = Add(totalA, parseFloat(itemB.currentCount))
                        totalC = Add(totalC, parseFloat(itemB.currentCount))
                    } else {
                        foodAll[indexA].dish_list[indexB].presenter = false
                        foodAll[indexA].dish_list[indexB].pack = false
                        foodAll[indexA].dish_list[indexB].attrbute = false
                        foodAll[indexA].dish_list[indexB].edit = false
                    }
                }
                if (itemB.originCount) {
                    totalB += parseFloat(itemB.originCount)
                    totalA += parseFloat(itemB.originCount)
                }
                if (originFoodAll[indexA].dish_list[indexB].residue > 0) {
                    foodAll[indexA].dish_list[indexB].residue = originFoodAll[indexA].dish_list[indexB].residue - totalC
                }

                foodAll[indexA].dish_list[indexB].count = totalB
                totalB = 0
                totalC = 0
            })
            foodAll[indexA].count = totalA
            totalA = 0
        })
        refreshAction()
    }

    //关闭规格弹窗
    const closeAttributeWindow = () => {
        setattributeData({
            visible: false,
            onOk: () => { },
            onCancel: () => { },
            data: {},
            is_vip_fans: is_vip_fans
        })
    }
    //选规格end

    //返回桌面
    const orderingFoodReturn = () => {
        history.goBack()
    }

    //点餐和已点切换
    const [onTab, setonTab] = useState(-1)
    const onTabAction = (tabId: number) => {
       if(tabId === 0){
        if(!getIsPermisson('1_11_6_1')){
            messageCustom();
            return;
          }
       }
       if(tabId === 1){
        if(!getIsPermisson('1_11_6_2_8')){
            messageCustom();
            return;
          }
       }
       setonTab(tabId)
    }

    useEffect(() => {
        refreshAction()
    }, [onTab])

    //已点列表
    const [yetOrder, setyetOrder] = useState<any>({})
    // 已点列表分页
    const [yetOrderPage, setyetOrderPage] = useState<any>([])
    //合并角标
    const yetOrderRefresh = async (yetOrder: any, foodAll: any) => {
        if (yetOrder.list.length > 0 && foodAll.length > 0) {
            let yetOrderData = yetOrder.list
            let list = foodAll
            list.map((itemA: any, indexA: number) => {
                let numA = 0
                itemA.dish_list.map((itemB: any, indexB: number) => {
                    if (!itemA.has_specs) {
                        list[indexA].dish_list[indexB].count = 0
                        list[indexA].dish_list[indexB].originCount = 0
                        yetOrderData.map((yetItemA: any) => {
                            // let specs = 0;
                            if (itemB.id === parseInt(yetItemA.id)) {
                                let numB = yetItemA.order_dish_refund_num ? Minus(yetItemA.count , yetItemA.order_dish_refund_num) : yetItemA.count;
                                if (list[indexA].dish_list[indexB].count) {
                                    list[indexA].dish_list[indexB].count += parseFloat(numB)
                                    list[indexA].dish_list[indexB].originCount += parseFloat(numB)
                                } else {
                                    list[indexA].dish_list[indexB].count = numB
                                    list[indexA].dish_list[indexB].originCount = numB
                                }
                                let order_dish_refund_num = yetItemA.order_dish_refund_num ? yetItemA.order_dish_refund_num : 0;
                                let newCount = Minus(yetItemA.count , order_dish_refund_num);
                                numA = Add(numA, newCount);
                            }
                        })

                        list[indexA].count = numA
                        list[indexA].originCount = numA

                    }
                })
            })

            list.map((item: any) => {
                if (item.presentCount) {
                    item.count = keepTwoNum(item.count + item.presentCount);
                }
            })

            setfoodAll(list)
            refreshAction()
        }
    }

    const yetOrderRequest = async (inCode: any) => {
        let param: any = {}
        if (inCode) {
            param.code = inCode ? inCode : tableCode ? tableCode : history.location.state.code
        } else {
            param.table_key = history.location.state.dineKey
        }
        const res: any = await getRequest(`${apiUrl.orderedDetail}`, param);
        if (res.code === 1 && res.data !== null) {
            if (res.data.status === 3) {
                if(!getIsPermisson('1_11_6_2_9')){
                    messageCustom();
                    return;
                  }
                Modal.confirm({
                    title: '提示',
                    content: `用户正在支付中，是否取消支付？请和用户确认后再操作！`,
                    okText: '是',
                    cancelText: '否',
                    onOk: async () => {
                        const res: any = await getRequest(apiUrl.cancelOrder, { code: param.code });
                        if (res.code === 1) {
                            message.success("该订单已恢复");
                            setTimeout(() => {
                                history.goBack()
                            }, 100)
                        } else {
                            message.error(res.message)
                        }
                    },
                    onCancel() {
                        history.goBack()
                    },
                });
                return null;
            }
            setwindowUserAmount(res.data.user_amount)
            settableName(res.data.desk)
            setyetOrder(res.data)
            let pageList = res.data.list.slice(0, 60);
            setyetOrderPage(pageList)
            setyetPage(1)
            yetOrderRefresh(res.data, foodAll)
            return res
        } else {
            return null
        }
    }

    const orderPay = () => {
        if(!getIsPermisson('1_11_10_6')){
            messageCustom();
            return;
        }
        history.push({
            pathname: '/sidebar/settleAccounts',
            state: { dineKey: tableKey, code: tableCode }
        })
    }

    

    //赠菜
    const [presentFood, setpresentFood] = useState<any>({})
    const presentFoodAction = (itemA: any, ) => {
        if(!getIsPermisson('1_11_6_2_1')){
            messageCustom();
            return;
          }
        let obj = {
            title: `赠送${itemA.name}`,
            visible: true,
            onOk: async (re: any) => {
                if (re) {
                    re = keepTwoNum(re)
                    let maxNum = itemA.count - itemA.order_dish_refund_num - itemA.order_present_num - itemA.order_redeem_num;
                    if(re > maxNum){
                        message.warning(`赠送数量不能大于${maxNum}`)
                        return;
                    }

                    console.log('itemA', itemA);

                    let params = {
                        code: tableCode,
                        is_present:true,
                        update:{
                            foods:[
                                {
                                    dish_id:itemA.id,
                                    standard:itemA.standard,
                                    change: -re,
                                    dish_weigh_value_id: itemA.dish_weigh_value_id ? itemA.dish_weigh_value_id : 0,
                                    material_key: itemA.material_key ? itemA.material_key : null
                                }
                            ]
                        }
                    }
                    const res:any = await postRequest(`${apiUrl.foodOrderUpdate}`,params);
                    if(res.code === 1){
                        let foods = []
                        foods.push({
                            id:itemA.id,
                            num: parseFloat(re),
                            dishes_attribute_id:itemA.standard_id ? itemA.standard_id : 0,
                            dish_weigh_value_id: itemA.dish_weigh_value_id ? itemA.dish_weigh_value_id : 0,
                            material_key: itemA.material_key ? itemA.material_key : null
                        })
                        const res1: any = await postRequest(apiUrl.orderPresentDishes,{'code': tableCode, 'presents': foods})
                        if(res1.code === 1){
                            message.success('赠送成功')
                            yetOrderRequest(tableCode)
                        }else{
                            message.warning(res.message)
                        }
                    }else{
                        message.warning(res.message)
                    }
                }
                seteditNumber({ visible: false })
            },
            onCancel: () => {
                seteditNumber({ visible: false })
            },
        }
        seteditNumber(obj)
    }

    //已点菜修改
    const orderEdit = (AIndex: number) => {

        let newyetOrder = yetOrderPage
        newyetOrder.map((itemA: any, indexA: any) => {
            newyetOrder[indexA].edit = false
        })
        newyetOrder[AIndex].edit = true
        setyetOrderPage(newyetOrder)
        refreshAction()
    }

    //重新打印
    const [orderReloadPrint, setorderReloadPrint] = useState<any>({})

    const orderReloadPrintAction = (count: number, standard: string, standard_id: number, name: string, id: number, key: any) => {
        let obj = {
            visible: true,
            onOk: () => {
                setorderReloadPrint({ visible: false })
            },
            onCancel: () => {
                setorderReloadPrint({ visible: false })
            },
            title: `重新打印`,
            count: count,
            standard: standard,
            standard_id: standard_id,
            code: tableCode,
            id: id,
            material_key: key
        }
        setorderReloadPrint(obj)
    }

    const [editOrder, seteditOrder] = useState<any>({})

    const [currentPageTotal, setcurrentPageTotal] = useState(0)
    const [currentPage, setcurrentPage] = useState(1)
    const [currentPageList, setcurrentPageList] = useState<any>([])

    const [yetPage, setyetPage] = useState(1)

    const currentPageListRefresh = (data: any) => {
        let list: any = []
        let setList = []
        let pageSize = 12

        if (data.length < 0) {
            return
        }
        data.map((itemA: any, indexA: number) => {
            if (itemA.dish_list) {
                itemA.dish_list.map((itemB: any, indexB: number) => {
                    if (searchInputText === '') {
                        if (selectTypeId === 0) {
                            list.push(itemB)
                        }
                        if (itemA.cat_id === selectTypeId) {
                            list.push(itemB)
                        }

                    } else {
                        if (searchFoodName(searchInputText, itemB.name)) {
                            list.push(itemB)
                        }
                    }
                })
            }
        })

        if (currentPage === 1) {
            setList = list.slice(0, pageSize)
        } else {
            setList = list.slice(((currentPage - 1) * pageSize), (currentPage * pageSize))
        }

        setcurrentPageList(setList)
        setcurrentPageTotal(list.length)
    }

    const pageAction = (e: number) => {
        setcurrentPage(e)
    }

    const yetPageAction = (e: number) => {
        let pageList = yetOrder.list.slice((e - 1) * 60, (e * 60));
        setyetOrderPage(pageList)
        setyetPage(e)
    }
    useEffect(() => {
        currentPageListRefresh(foodAll)
    }, [currentPage, selectTypeId, yetPage])


    const isChinese = (s: string) => {
        return /[\u4e00-\u9fa5]/.test(s);
    }
    const ch2Unicdoe = (str: string) => {
        if (!str) {
            return;
        }
        var unicode = '';
        for (var i = 0; i < str.length; i++) {
            var temp = str.charAt(i);
            if (isChinese(temp)) {
                unicode += '\\u' + temp.charCodeAt(0).toString(16);
            }
            else {
                unicode += temp;
            }
        }
        return unicode;
    }

    const searchFoodName = (searchText: string, text: string) => {
        let test = ch2Unicdoe(searchText)
        var pattern2 = `/[${test}]+/g`;
        var reg = RegExp(eval(pattern2))
        return reg.test(text)
    }

    const [searchInputText, setsearchInputText] = useState('')
    const searchAction = (text: string) => {
        setcurrentPage(1)
        setsearchInputText(text)
        refreshAction()
    }
    //打包
    const orderPackAction = (indexA: number, indexB: number, indexC: number | undefined) => {
        if(!getIsPermisson('1_11_6_2_2')){
            messageCustom();
            return;
          }
        let list = foodAll
        if (indexC === undefined) {
            list[indexA].dish_list[indexB].attrbute = true
            list[indexA].dish_list[indexB].pack = true
        } else {
            list[indexA].dish_list[indexB].selectedDish[indexC].attrbute = true
            list[indexA].dish_list[indexB].selectedDish[indexC].pack = true
        }
        setfoodAll(list)
        setTimeout(() => {
            refreshAction()
        }, 100)
    }

    //修改数量
    const [editNumber, seteditNumber] = useState<any>({ visible: false })

    const editNumberAction = (indexA: number, indexB: number, indexC: number | undefined) => {
        let obj = {
            title: '改数量',
            visible: true,
            onOk: (re: any) => {
                if (re) {
                    let list = foodAll
                    re = parseFloat(keepTwoNum(re))
                    if (indexC === undefined) {
                        if(!zeroOrder) {
                            if (originFoodAll[indexA].dish_list[indexB].residue !== 0 && re > originFoodAll[indexA].dish_list[indexB].residue) {
                                message.warning('剩余数量不足')
                                return
                            }
                        }
                        list[indexA].dish_list[indexB].currentCount = re
                        list[indexA].dish_list[indexB].count = list[indexA].dish_list[indexB].originCount ? parseFloat(list[indexA].dish_list[indexB].originCount) + parseFloat(re) : re
                    } else {
                        if (originFoodAll[indexA].dish_list[indexB].residue !== 0) {
                            let total = 0
                            foodAll[indexA].dish_list[indexB].selectedDish.map((mapItemC: any, mapIndexC: number) => {
                                total += mapItemC.currentCount
                            })
                            total = total - foodAll[indexA].dish_list[indexB].selectedDish[indexC].currentCount
                            if(!zeroOrder) {
                                if (parseFloat(re) > (originFoodAll[indexA].dish_list[indexB].residue - total)) {
                                    message.warning('剩余数量不足')
                                    return
                                }
                            }
                        }
                        list[indexA].dish_list[indexB].selectedDish[indexC].currentCount = re
                        list[indexA].dish_list[indexB].count = parseFloat(list[indexA].dish_list[indexB].originCount) + parseFloat(re)
                    }
                    setfoodAll(list)
                    updateBadge()
                }
                seteditNumber({ visible: false })
                setTimeout(() => {
                    orderTotal()
                }, 100)
            },
            onCancel: () => {
                seteditNumber({ visible: false })
            },
        }
        seteditNumber(obj)
    }

    const orderedEditAction = (itemA: any) => {
        let obj = {
            title: '改数量',
            visible: true,
            onOk: async (re: any) => {
                if (re) {
                    let foods = []
                    foods.push({
                        'dish_id': itemA.id,
                        'standard': itemA.standard,
                        'change': keepTwoNum(re - itemA.count),
                        'dish_weigh_value_id': itemA.dish_weigh_value_id ? itemA.dish_weigh_value_id : 0,
                        'material_key': itemA.material_key ? itemA.material_key : null
                    })
                    const res: any = await postRequest(apiUrl.foodOrderUpdate,{'code': tableCode, 'update': {'foods': foods}})
                    if(res.code === 1){
                        message.success('修改成功')
                        yetOrderRequest(tableCode)
                    }else{
                        message.warning(res.message)
                    }
                }
                seteditNumber({ visible: false })
            },
            onCancel: () => {
                seteditNumber({ visible: false })
            },
        }
        seteditNumber(obj)
    }

    //点菜->赠菜
    const presenterAction = (indexA: number, indexB: number, indexC: number | undefined) => {
        if(!getIsPermisson('1_11_6_2_1')){
            messageCustom();
            return;
          }
        let list = foodAll
        if (indexC === undefined) {
            list[indexA].dish_list[indexB].attrbute = true
            list[indexA].dish_list[indexB].presenter = true
        } else {
            list[indexA].dish_list[indexB].selectedDish[indexC].attrbute = true
            list[indexA].dish_list[indexB].selectedDish[indexC].presenter = true
        }
        setfoodAll(list)
        setTimeout(() => {
            refreshAction()
        }, 100)
    }

    // 重打点菜单
    const reprintAction = async () => {
        if(!getIsPermisson('1_11_6_2_6')){
            messageCustom();
            return;
          }
        let param = {
            code: tableCode
        }
        const res: any = await getRequest(`${apiUrl.reprintOrder}`, param);
        if (res.code === 1) {
            message.success('重新打印成功')
        }
    }

    // 重打制作单
    const reprintMakeAction = async () => {
        if(!getIsPermisson('1_11_6_2_6')){
            messageCustom();
            return;
        }
        console.log('ssssss')
        let param = {
            code: tableCode
        }
        const res: any = await getRequest(`${apiUrl.reprintOrderMake}`, param);
        if (res.code === 1) {
            message.success('重新打印成功')
        }
    }

    //取消赠送
    const [cancelPresent, setcancelPresent] = useState<any>({ visible: false })
    const cancelPresentAction = (itemA: any) => {
        if(!getIsPermisson('1_11_6_2_1')){
            messageCustom();
            return;
          }
        let obj = {
            title: `取消赠送`,
            visible: true,
            onOk: async (re: any) => {
                if (re) {
                    let maxNum = itemA.order_present_num;
                    if(re > maxNum){
                        message.warning(`取消赠送数量不能大于${maxNum}`)
                        return;
                    }

                    let foods = []
                    foods.push({
                        dish_id:itemA.id,
                        change: -keepTwoNum(re),
                        standard:itemA.standard ? itemA.standard : '',
                        dish_weigh_value_id: itemA.dish_weigh_value_id ? itemA.dish_weigh_value_id : 0,
                        material_key: itemA.material_key ? itemA.material_key : null
                    })
                    const res: any = await postRequest(apiUrl.cancelPresentDishes,{'code': tableCode, 'presents': foods})
                    if(res.code === 1){
                        message.success('取消赠送成功')
                        yetOrderRequest(tableCode)
                    }else{
                        message.warning(res.message)
                    }
                }
                seteditNumber({ visible: false })
            },
            onCancel: () => {
                seteditNumber({ visible: false })
            },
        }
        seteditNumber(obj)
    }

    //选择类别区分显示
    const [otherView, setotherView] = useState(false)

    const otherDataObj = [
        { title: '套餐', name: 'setMeal' }
    ]
    const [otherData, setotherData] = useState(otherDataObj)
    const [otherSelected, setotherSelected] = useState<any>()

    //选择其它项
    useEffect(() => {
        console.log('otherSelected', otherSelected)
        otherAction(otherSelected)
    }, [otherSelected])

    //根据其它项的选择来加载内容
    const otherAction = (otherSelected: string) => {
        if (otherSelected === 'setMeal') {
        }
    }
    const [setMealListData, setsetMealListData] = useState<any>([])
    //套餐列表
    const setMealListRequest = async () => {
        let param = {
            status: 1
        }
        const res: any = await getRequest(`${apiUrl.setMealList}`, param);

        if (res.code === 1) {
            setsetMealListData(res.data)
        }
    }

    const [orderSetmeal, setorderSetmeal] = useState<any>([])
    const [MealVisible, setMealVisible] = useState<any>(false)
    const [mealInfo,setmealInfo] = useState<any>({})

    //添加套餐到点菜
    const addSetMealAction = async (id: number, index: number) => {
        let info = setMealListData[index];
        info.dishes.map((item: any) => {
            let num = 0;
            item.attr = [];
            item.dishes.map((item2: any) => {
                item2.active = false
                if(!item2.has_specs){
                    num++
                }
            })
            if(item.dishes.length == item.num && item.dishes.length == num){
                item.choose = true
                item.dishes.map((item2: any) => {
                    item2.active = true;
                })
            }
        })
        setmealInfo(info)
        setMealVisible(true)
    }

    const chooseItem = (index: number, index2: number) => {
        let setInfo:any = mealInfo;
        let item:any = setInfo.dishes[index].dishes[index2];
        let num = 0;

        if(item.active){
            item.active = false;
        }else{
            setInfo.dishes[index].dishes.map((item2:any) => {
                if(item2.active){
                    num++;
                }
            })
            if(num >= setInfo.dishes[index].num){
                message.warning(`最多选择${setInfo.dishes[index].num}`)
                return;
            }else{
                item.active = true;
                if(setInfo.dishes[index].dishes[index2].has_specs && setInfo.dishes[index].dishes[index2].default != 0){
                    setInfo.dishes[index].attr = setInfo.dishes[index].dishes[index2].attribute;
                    setInfo.dishes[index].attr_idx = index2;
                }else{
                    setInfo.dishes[index].attr = null;
                    setInfo.dishes[index].attr_idx = null;
                }
            }
        }
        setmealInfo(setInfo)
        refreshAction()
    }

    // 选择规格
    const chooseAttr = (index: number, id:number, idx: number, name: string) => {
        let setInfo:any = mealInfo;
        let dishList: any = setInfo.dishes;
        dishList[index].dishes[dishList[index].attr_idx].attribute_id = id;
        dishList[index].dishes[dishList[index].attr_idx].attribute_name = name;
        dishList[index].attr.map((item: any) => {
            item.active = false;
        })

        dishList[index].attr[idx].active = true;
        setmealInfo(setInfo)
        refreshAction()
    }

    const addSetmeal = () => {
        let flag = true;
        let setInfo:any = mealInfo;
        let submitInfo:any = JSON.parse(JSON.stringify(setInfo));
        let arr:any[] = [];

        setInfo.dishes.map((item: any) => {
            let num = 0;
            item.dishes && item.dishes.map((item2: any, index: number) => {
                if(item2.active){
                    num++;
                    arr.push(item2)
                }
                if(item2.active && item2.has_specs && item2.attr && !item2.attribute_id){
                    message.warning(item2.name + '请选择口味')
                    flag = false;
                }
            })
            if(num < item.num ){
                message.warning(item.name + '可选' + item.num)
                flag = false;
                return;
            }
        })
        submitInfo.newdishes = arr;

        if(flag){
            if(orderSetmeal.length > 0){
                let num = 0;
                let num1 = 0;
                orderSetmeal.map((item: any) => {
                    if(item.id != submitInfo.id){
                        num++;
                    }else{
                        if(JSON.stringify(item.newdishes) != JSON.stringify(submitInfo.newdishes)){
                            num1++
                        }else{
                            item.count++;
                        }
                    }
                })
                if(num  == orderSetmeal.length){
                    submitInfo.count = 1;
                    orderSetmeal.push(submitInfo)
                }
                if(num1 == orderSetmeal.length){
                    submitInfo.count = 1;
                    orderSetmeal.push(submitInfo)
                }
            }else{
                submitInfo.count = 1;
                orderSetmeal.push(submitInfo)
            }
            setMealListData.map((item:any) => {
                item.count = 0;
                orderSetmeal.map((item2: any) => {
                    if(item.id == item2.id){
                        item.count += item2.count
                    }
                })
            })
            setorderSetmeal(orderSetmeal)
            console.log(setMealListData)
            setsetMealListData(setMealListData)
            setMealVisible(false)
        }
    }

    // 删除已点套餐
    const deleteSetmeal = (e:any, index: number) => {
        orderSetmeal.splice(index,1)
        setMealListData.map((item:any) => {
            item.count = 0;
            orderSetmeal.map((item2: any) => {
                if(item.id == item2.id){
                    item.count += item2.count
                }
            })
        })
        orderSetmeal.map((iten: any, indexA: number) => {
            orderSetmeal[indexA].edit = false
        })
        setsetMealListData(setMealListData)
        setorderSetmeal(orderSetmeal)

        setTimeout(() => {
            refreshAction()
        }, 100)
        e.stopPropagation()
    }

    //修改套餐规格
    const setMealAttributeAction = async (id: number, A: number, B: number) => {
        let attrData: any = []
        foodAll.map((itemA: any, indexA: number) => {
            itemA.dish_list.map((itemB: any) => {
                if (itemB.id === id) {
                    attrData = itemB
                    attrData.weigh = null;
                    console.log(attrData);
                }
            })
        })
        setattributeData({
            visible: true,
            onOk: (aaa: any) => {
                if (aaa === false) {
                    return
                }
                orderSetmeal[A].newdishes[B].attribute_id = aaa.id
                orderSetmeal[A].newdishes[B].attribute_name = aaa.name
                orderSetmeal[A].newdishes[B].price = aaa.price
                refreshAction()
                closeAttributeWindow()
            },
            onCancel: () => { closeAttributeWindow() },
            data: attrData,
            is_vip_fans: is_vip_fans
        })
    }

    //退套餐
    const onRefundSetMeal = async (e: any, id: any) => {
        e.stopPropagation()
        Modal.confirm({
            title: '确定退掉当前套餐',
            content: `当前所有同名套餐都会被退掉？`,
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                let param: any = {
                    code: history.location.state.code,
                    set_meal_ids: JSON.stringify([id])
                }
                const res: any = await postRequest(`${apiUrl.refundSetMeal}`, param);

                if (res.code === 1) {
                    message.success('操作成功！')
                    startup(history.location.state.code);
                } else {
                    message.warn(res.message)
                }
            },
            onCancel() {
            },
        });
    }

    //订单备注默认列表
    const onChangeOrderNote = (val: any) => {
        let list = orderNotesDefault;
        list.map((item: any, index: any) => {
            if(item.name == val.name) {
                if(item.active) {
                    list[index]['active'] = false;
                } else {
                    list[index]['active'] = true;
                }
            }
        })
        setOrderNotesDefault([...list])
    }

    //订单备注-输入
    const onChangeOrderNotes = (e: any) => {
        setOrderNotes(e.target.value);
    }

    //提交订单备注
    const onOrderNotesSubmit = () => {
        let list = orderNotesDefault;
        let notes = [];
        list.map((item: any, index: any) => {
            if(item.active) {
                notes.push(list[index]['name'])
            }
        })
        notes.push(orderNotes)
        let new_notes: any = notes.join()
        setOrderNotes(new_notes)
        setOrderNotesStatus(false)
    }

    //取消订单备注
    const onOrderNotesCancel = () => {
        setOrderNotes('')
        setOrderNotesStatus(false)
    }

    // 退菜
    const returnDishes = (itemA: any) => {
        let obj = {
            title: '退菜',
            visible: true,
            onOk: async (re: any) => {
                if (re) {
                    let maxNum = itemA.count - itemA.order_dish_refund_num - itemA.order_present_num - itemA.order_redeem_num;
                    if(re > maxNum){
                        message.warning(`退菜数量不能大于${maxNum}`)
                        return;
                    }
                    let foods = []
                    foods.push({
                        'dish_id': itemA.id,
                        'standard': itemA.standard,
                        'change': -(keepTwoNum(re)),
                        'dish_weigh_value_id': itemA.dish_weigh_value_id ? itemA.dish_weigh_value_id : 0,
                        'material_key': itemA.material_key ? itemA.material_key : null
                    })
                    const res: any = await postRequest(apiUrl.foodOrderUpdate,{'code': tableCode, 'update': {'foods': foods}})
                    if(res.code === 1){
                        message.success('退菜成功')
                        yetOrderRequest(tableCode)
                    }else{
                        message.warning(res.message)
                    }
                }
                seteditNumber({ visible: false })
            },
            onCancel: () => {
                seteditNumber({ visible: false })
            },
        }
        seteditNumber(obj)
    }

    const mergeTable = () => {
        if(!getIsPermisson('1_11_6_2_7')){
            messageCustom();
            return;
          }
        history.push({
            pathname: '/sidebar/mergeTable',
            state: {
                tableKey: tableKey,
                code: tableCode,
                tableName: history.location.state.tableName,
                table_id: history.location.state.tableId,
                tableId: tableKey,
                is_eating: history.location.state.is_eating,
                userAmount: windowUserAmount,
                dineKey: tableKey
            }
        })
    }

    // 换桌
    const changeTable = () => {
        if(!getIsPermisson('1_11_6_2_6')){
            messageCustom();
            return;
          }
        history.push({
            pathname: '/sidebar/changeTable',
            state: {
                code: tableCode,
                tableName: history.location.state.tableName,
                table_id: history.location.state.tableId,
            }
        })
    }

    // 合并订单
    const mergeOrder = () => {
        if(!getIsPermisson('1_11_6_2_7')){
            messageCustom();
            return;
          }

          history.push({
            pathname: '/sidebar/mergeTable',
            state: {
                tableKey: tableKey,
                code: tableCode,
                tableName: history.location.state.tableName,
                table_id: history.location.state.tableId,
                tableId: tableKey,
                is_eating: history.location.state.is_eating,
                userAmount: windowUserAmount,
                dineKey: tableKey,
                type: 'order'
            }
        })

        //   Modal.confirm({
        //     title: '提示',
        //     content: `确认合并？订单合并后不可撤销，请谨慎操作！`,
        //     okText: '确认',
        //     cancelText: '取消',
        //     onOk: async () => {
        //         const codes = [history.location.state.code];
        //         const res:any = await getRequest(`${apiUrl.mergeOrder}`,{
        //             code: tableCode,
        //             codes
        //         });
        //         if(res.code === 1){
        //             history.push({
        //                 // 跳到实时桌面
        //                 pathname: '/sidebar/windowManage'
        //             })
        //         }
        //     },
        //     onCancel() {
        //     },
        // });
    }

    return (
        <div className="orderingFood l_content" key='orderingFood'>
            <EditOrderNumber data={editOrder} />
            <SelectHumanNumber data={selectHumanNumberData} list={setMealListData} />
            <AddAttributeDich attributeData={attributeData} />
            <PresentFood data={presentFood} />
            <OrderReloadPrint data={orderReloadPrint} />
            <NumChangeDialog data={editNumber} />
            <CancelPresent data={cancelPresent} />
            <Modal
                title={'菜品'}
                visible={MealVisible}
                onOk={()=>{addSetmeal()}}
                onCancel={() => { setMealVisible(false) }}> <div>
                    <div className='set_name'>套餐名称：{mealInfo.name}</div>
                    <div className='set_price'>套餐价格：￥{mealInfo.price}</div>
                    {
                        mealInfo.dishes && mealInfo.dishes.map((item: any, index: number) => {
                            return <div>
                                <div className={style.cate_name}>{item.name}{item.dishes.length}选{item.num}</div>
                                {
                                    !item.choose
                                    ? <div className={style.cate_con}>
                                        {
                                            item.dishes.map((item2: any, index2: number) => {
                                                return !item2.active ? <div className={style.dish_item} onClick={() => chooseItem(index,index2)}>{item2.name}{item2.num > 1 ? `x${item2.num}` : ''}</div>
                                                                    : <div className={style.active} onClick={() => chooseItem(index,index2)}>{item2.name}{item2.num > 1 ? `x${item2.num}` : ''}</div>
                                            })
                                        }
                                    </div>
                                    : <div>
                                        {
                                            item.dishes.map((item2: any, idx: number) => {
                                                return <div>{item2.name} x{item2.num}</div>
                                            })
                                        }
                                    </div>
                                }

                                {
                                    item.attr && item.attr.length > 0 ? <div>口味</div> : null
                                }
                                <div className={style.cate_con}>
                                    {
                                        item.attr && item.attr.map((item2: any, idx:number) => {
                                            return !item2.active ? <div className={style.dish_item} onClick={() => chooseAttr(index, item2.id, idx, item2.name)}>{item2.name}</div>
                                                                : <div className={style.active} onClick={() => chooseAttr(index, item2.id, idx, item2.name)}>{item2.name}</div>
                                        })
                                    }
                                </div>

                            </div>
                        })
                    }
                </div>
                </Modal>

            <div className="top">
                <Button type='primary' icon='left' size='large' className='mr30' style={{ fontWeight: 'bold' }} onClick={orderingFoodReturn}>返回</Button>
                <div className='table_info mr30' >餐桌号：{tableName}</div>
                <div className='table_info mr30'>用餐人数：{windowUserAmount}人</div>
                <Button type="primary" size='large' className='mr30' onClick={editDineNumberAction}>改人数</Button>
                <Button type="primary" size='large' className='mr30' onClick={changeTable}>换桌</Button>
                <Button type="primary" size='large' className='mr30' onClick={mergeOrder}>合并订单</Button>
                <Button type="primary" size='large' className='mr30' onClick={mergeTable}>合桌结账</Button>
                {
                    isWait ?
                        <Button type="primary" size='large' className='mr30' onClick={waitCallAction}>叫起</Button>
                    : ""

                }
            </div>
            {
                onTab === 0
                    ? <div className="container">
                        <div className="left">
                            <div className="tab">
                                <div className="ing sel" onClick={() => { onTabAction(0) }}>点菜</div>
                                <div className='yet' onClick={() => { onTabAction(1) }}>已点</div>
                            </div>
                            <div className="content">
                                <div className="tabA">
                                    <div className="tableHeader">
                                        <div className="tableA">菜品</div>
                                        <div className="tableB">售价</div>
                                        <div className="tableB">会员价</div>
                                        <div className="tableC">数量</div>
                                        <div className="tableD">金额</div>
                                        <div className="tableE">操作</div>
                                    </div>
                                    <div className="setMeal">
                                        {
                                            orderSetmeal.map((item: any, index: number) => {
                                                return (
                                                    <div className="body" key={item.id + index}>
                                                        <div className="tableBody setMealList" onClick={() => {

                                                            if (orderSetmeal[index].edit) {
                                                                orderSetmeal.map((iten: any, indexA: number) => {
                                                                    orderSetmeal[indexA].edit = false
                                                                })
                                                            } else {
                                                                orderSetmeal.map((iten: any, indexA: number) => {
                                                                    orderSetmeal[indexA].edit = false
                                                                })
                                                                orderSetmeal[index].edit = true
                                                            }
                                                            refreshAction()
                                                        }}>
                                                            <div className="tableA">{item.name}</div>
                                                            <div className="tableB">{item.price}</div>
                                                            <div className="tableB">--</div>
                                                            <div className="tableC">{item.count}</div>
                                                            <div className="tableD">￥{Multiply(item.count, item.price)}</div>
                                                            <div className="tableE"><Icon type="delete" style={{ fontSize: 24, color: '#ff0004' }} onClick={(e) => deleteSetmeal(e, index)} /></div>
                                                        </div>
                                                        {
                                                            item.edit ?
                                                                <div className="setMealDetail">
                                                                    {
                                                                        item.newdishes.map((itemB: any, indexA: number) => {
                                                                            return (
                                                                                <div className="tableBody" key={`setMealDetail_${indexA}`}>
                                                                                    <div className="tableA">{itemB.name}{`${itemB.has_specs && itemB.attribute_name ? '(' + itemB.attribute_name + ')' : ''}`}</div>
                                                                                    <div className="tableB"></div>
                                                                                    <div className="tableC">{item.num}</div>
                                                                                    <div className="tableD"></div>
                                                                                    <div className="tableE">
                                                                                        {
                                                                                            itemB.has_specs && itemB.default != 0?
                                                                                                <Button onClick={() => {
                                                                                                    setMealAttributeAction(itemB.dish_id, index, indexA)
                                                                                                }}>改口味</Button>
                                                                                                : null
                                                                                        }
                                                                                    </div>
                                                                                </div>

                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                                : null
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    {
                                        foodAll.map((itemA: any, indexA: number) => {
                                            return (
                                                itemA.dish_list.map((itemB: any, indexB: number) => {
                                                    return (
                                                        itemB.currentCount > 0 ?
                                                            itemB.has_specs || itemB.material_info ?
                                                                itemB.selectedDish ?
                                                                    itemB.selectedDish.map((itemC: any, indexC: number) => {
                                                                        return (
                                                                            <div
                                                                                key={`dc_${indexC}`}
                                                                                onClick={() => {
                                                                                    let list = foodAll
                                                                                    list.map((itemA: any, indexA: any) => {
                                                                                        itemA.dish_list.map((itemB: any, indexB: any) => {
                                                                                            if (itemB.selectedDish) {
                                                                                                itemB.selectedDish.map((itemC: any, indexC: number) => {
                                                                                                    list[indexA].dish_list[indexB].selectedDish[indexC].edit = false
                                                                                                })
                                                                                            }
                                                                                        })
                                                                                    })

                                                                                    if (list[indexA].dish_list[indexB].selectedDish[indexC].edit) {
                                                                                        list[indexA].dish_list[indexB].selectedDish[indexC].edit = false
                                                                                    } else {
                                                                                        list[indexA].dish_list[indexB].selectedDish[indexC].edit = true
                                                                                    }
                                                                                    setfoodAll(list)
                                                                                    refreshAction()
                                                                                }}>
                                                                                <div className={itemC.attrbute ? "tableBody ispack" : "tableBody"} key={itemC.id} >
                                                                                    <div className="tableA">
                                                                                        <div>{itemB.name}</div>
                                                                                        <div style={{color:'#333', fontSize: '12px'}}>{itemC.name}  {itemC.weigh_name ? `${itemC.weigh_name}` : ''}  {itemC.material_name ? `${itemC.material_name}` : ''}</div>
                                                                                    </div>
                                                                                    {
                                                                                        !itemB.is_vip_set || !is_vip_fans
                                                                                        ? <div className="tableB">￥{itemC.price}</div>
                                                                                        : <div className="tableB" style={{textDecoration: 'line-through'}}>￥{itemC.price}</div>
                                                                                    }
                                                                                    <div className="tableB">{itemB.is_vip_set && is_vip_fans ? `￥${itemC.price_vip}` : '--'}</div>

                                                                                    <div className="tableC">
                                                                                        <div className="back_btn reduce_btn active" onClick={(e) => { orderAttrAction(indexA, indexB, indexC, 'minus'); e.stopPropagation(); }}>-</div>
                                                                                        <div className="num">{itemC.currentCount}</div>
                                                                                        <div className="back_btn add_btn active" onClick={(e) => { orderAttrAction(indexA, indexB, indexC, 'add'); e.stopPropagation(); }}>+</div>
                                                                                    </div>
                                                                                    <div className="tableD">￥{keepTwoNum(Multiply(itemC.currentCount, itemB.is_vip_set && is_vip_fans ? itemC.price_vip : itemC.price))}</div>
                                                                                    <div className='tableE' onClick={(e) => { deleteAttrOrder(indexA, indexB, indexC, 'minus'); e.stopPropagation(); }}><Icon type="delete" style={{ fontSize: 24, color: '#ff0004' }} /></div>
                                                                                    <div className="tableF">
                                                                                        {itemC.pack ? <div className="pack">包</div> : <div></div>}
                                                                                        {itemC.presenter ? <div className="pack">赠</div> : <div></div>}
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    itemC.edit ?
                                                                                        <div className="tableEdit">
                                                                                            <div onClick={() => {
                                                                                                presenterAction(indexA, indexB, indexC)
                                                                                            }}>赠送</div>
                                                                                            <div className="tableEdit_line"></div>
                                                                                            <div onClick={() => {
                                                                                                editNumberAction(indexA, indexB, indexC)
                                                                                            }}>改数量</div>
                                                                                            <div className="tableEdit_line"></div>
                                                                                            <div onClick={() => {
                                                                                               editAttributeAction(indexA, indexB, indexC)
                                                                                            }}>改规格</div>
                                                                                            <div className="tableEdit_line"></div>

                                                                                            <div onClick={() => {
                                                                                                orderPackAction(indexA, indexB, indexC)
                                                                                            }}>打包</div>
                                                                                        </div>
                                                                                        :
                                                                                        <div></div>
                                                                                }
                                                                            </div>
                                                                        )
                                                                    })
                                                                    : null
                                                                :
                                                                <div onClick={() => {
                                                                    let list = foodAll
                                                                    list.map((itemA: any, indexA: any) => {
                                                                        itemA.dish_list.map((itemB: any, indexB: any) => {
                                                                            list[indexA].dish_list[indexB].edit = false
                                                                        })
                                                                    })
                                                                    if (list[indexA].dish_list[indexB].edit) {
                                                                        list[indexA].dish_list[indexB].edit = false
                                                                    } else {
                                                                        list[indexA].dish_list[indexB].edit = true
                                                                    }
                                                                    setfoodAll(list)
                                                                    refreshAction()
                                                                }}>
                                                                    <div className={itemB.attrbute ? "tableBody ispack" : "tableBody"}>
                                                                        <div className="tableA">{itemB.name}</div>
                                                                        {
                                                                            !itemB.is_vip_set || !is_vip_fans
                                                                            ? <div className="tableB">￥{itemB.price}</div>
                                                                            : <div className="tableB" style={{textDecoration: 'line-through'}}>￥{itemB.price}</div>
                                                                        }
                                                                        <div className="tableB">{itemB.is_vip_set && is_vip_fans ? `￥${itemB.price_vip}` : '--'}</div>
                                                                        <div className="tableC">
                                                                            <div className="back_btn reduce_btn active" onClick={(e) => { orderAddMinusAction('minus', indexA, indexB); e.stopPropagation(); }}>-</div>
                                                                                  <div className="num">{itemB.currentCount}</div>
                                                                            <div className="back_btn add_btn active" onClick={(e) => { orderAddMinusAction('add', indexA, indexB); e.stopPropagation(); }}>+</div>
                                                                        </div>
                                                                        <div className="tableD">￥{keepTwoNum(Multiply(itemB.currentCount, itemB.is_vip_set && is_vip_fans ? itemB.price_vip : itemB.price))}</div>
                                                                        <div className='tableE' onClick={() => deleteOrder(indexA, indexB)}><Icon type="delete" style={{ fontSize: 24, color: '#ff0004' }} /></div>
                                                                        <div className="tableF">
                                                                            {itemB.pack ? <div className="pack">包</div> : <div></div>}
                                                                            {itemB.presenter ? <div className="pack">赠</div> : <div></div>}
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        itemB.edit
                                                                        ?   <div className="tableEdit">
                                                                                <div onClick={() => {
                                                                                    presenterAction(indexA, indexB, undefined)
                                                                                }}>赠送</div>
                                                                                <div className="tableEdit_line"></div>
                                                                                <div onClick={() => {
                                                                                    editNumberAction(indexA, indexB, undefined)
                                                                                }}>改数量</div>
                                                                                <div className="tableEdit_line"></div>

                                                                                <div onClick={() => {
                                                                                    orderPackAction(indexA, indexB, undefined)
                                                                                }}>打包</div>
                                                                            </div>
                                                                        : null
                                                                    }
                                                                </div>
                                                            : null
                                                    )
                                                })
                                            )
                                        })
                                    }
                                    <div className="bottom">
                                        <div className="bottom_left">共：{listTotalCount}件   合计：¥ {keepTwoNum(listTotalPrice)}</div>
                                        <div className="orderingFood_orderinfo">
                                            <div className="wait_view">
                                                {
                                                    isWait ? 
                                                    <Checkbox onChange={waitSelecteAction}>等叫</Checkbox>
                                                    :
                                                    <div></div>
                                                }
                                            </div>
                                            <div className='remarks' onClick={() => {
                                                 if(!getIsPermisson('1_11_6_2_3')){
                                                    messageCustom();
                                                    return;
                                                  }
                                                setOrderNotesStatus(true)
                                            }}>订单备注</div>
                                            <div className='orderingFood_orderinfo_right' onClick={() => { placeOrder() }}>下单</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="right">
                            <div className="foodList">
                                <div className="search">
                                    <div className="text">菜名</div>
                                    <Search
                                        placeholder="输入菜品名"
                                        onSearch={(value: any) => { searchAction(value) }}
                                        style={{ width: 200 }}
                                        allowClear={true}
                                    />
                                </div>
                                {
                                    !otherView ?
                                        <div className="list">
                                            {
                                                currentPageList.map((item: any) => {
                                                    return (
                                                        <div className={(item.is_up && item.is_stock_over && item.residue == 0) ? 'item sel stock_over' : 'item sel'} key={item.id}
                                                            onClick={item.has_specs || item.material_info ? () => { selectAttributeAction(item.id, 'default') } : () => { orderAddAction(item.id) }}>
                                                            <img src={stock_over} className='stock_logo' />
                                                            <div className="box">
                                                                <Badge count={item.count ? item.count : 0}></Badge>
                                                            </div>
                                                            <div className='one'>
                                                                <div className=''>{item.name}</div>
                                                            </div>
                                                            <div className={item.residue > 0 || (item.is_up && item.is_stock_over) ? 'residue' : 'none'}>剩{item.residue > 0 ? item.residue : 0}</div>
                                                            {!item.has_specs ? <div className='price' style={{display:'flex', alignItems:'center'}}>
                                                                {
                                                                    item.is_vip_set
                                                                    ? is_vip_fans
                                                                        ? <div style={{display: 'flex', alignItems:'center'}}>
                                                                            <div>会员:{item.old_price_vip}元/份 </div>
                                                                            <div style={{textDecoration: 'line-through', color:'#9B9B9B', fontSize: '10px', marginLeft: '5px'}}>{item.old_price}元/份 </div>
                                                                        </div>
                                                                        : <div style={{display: 'flex', alignItems:'center'}}>
                                                                            <div>{item.old_price}元/份 </div>
                                                                            <div style={{color:'#9B9B9B', fontSize: '10px', marginLeft: '5px'}}>会员:{item.old_price_vip}元/份 </div>
                                                                        </div>
                                                                    : <div>{item.old_price}元/份 </div>
                                                                }

                                                            </div> : null}

                                                        </div>
                                                    )
                                                })
                                            }
                                            {
                                                currentPageTotal > 11
                                                ?   <div className="pageView">
                                                        <Pagination defaultCurrent={1} total={currentPageTotal} onChange={(a) => { pageAction(a) }} pageSize={12} />
                                                    </div>
                                                : null
                                            }
                                        </div>
                                        :
                                        <div className="list">
                                            {
                                                setMealListData.map((item: any, index: number) => {
                                                    return (
                                                        <div className='item' key={`setMeal_${index}`} onClick={() => {
                                                            addSetMealAction(item.id, index)
                                                        }}>
                                                            <div className="box">
                                                                <Badge count={item.count ? item.count : 0}></Badge>
                                                            </div>
                                                            <div className='one'>
                                                                <div className=''>{item.name}</div>
                                                            </div>
                                                            <div className='price'>{item.price}</div>
                                                        </div>

                                                    )
                                                })
                                            }
                                        </div>
                                }

                                <div className="reprint">
                                    <div className="submit" onClick={reprintAction}>重打点菜单 </div>
                                </div>
                            </div>
                            <div className="selectType">
                                <div className="content">
                                    <div className="item prev" onClick={() => {
                                        setotherView(false)
                                        setotherSelected(undefined)
                                    }}>
                                        <Icon type="up" style={{ fontSize: 20, color: '#ffffff' }} />
                                    </div>
                                    {
                                        <div className="selectView selectFoodType">

                                            <div className={selectTypeId === 0 ? 'item sel' : 'item'} onClick={() => { selectTypeIdAction(0) }}>全部</div>
                                            {
                                                otherData.map((item: any, index: number) => {
                                                    return (
                                                        <div className={otherSelected === item.name ? 'item sel' : 'item'} key={`otherData${index}`} onClick={() => {
                                                            setotherSelected(item.name)
                                                            setotherView(true)
                                                            setselectTypeId(-1)
                                                        }}>
                                                            {item.title}
                                                        </div>
                                                    )
                                                })
                                            }
                                            {
                                                foodAll.map((item: any) => {
                                                    return (
                                                        <div className={selectTypeId === item.cat_id ? 'item sel' : 'item'} onClick={() => { selectTypeIdAction(item.cat_id) }} key={item.cat_id}>
                                                            <Badge count={item.count ? item.count : 0}>
                                                                <div className="text">{item.cat_name}</div>
                                                            </Badge>
                                                        </div>
                                                    )
                                                })
                                            }

                                        </div>
                                    }
                                    <div className="item next" onClick={() => { setotherView(true) }}>
                                        <Icon type="down" style={{ fontSize: 20, color: '#ffffff' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    onTab === 1 ?
                    <div className="ordered">
                        <div className="tab">
                            <div className='ing' onClick={() => { onTabAction(0) }}>点菜</div>
                            <div className="yet sel" onClick={() => { onTabAction(1) }}>已点</div>
                        </div>

                        <div className="content flex">
                            <div className="tabB" key='tabB'>
                                <div className="tableHeader">
                                    <div className="tableA">菜品</div>
                                    <div className="tableB">售价</div>
                                    <div className="tableB">会员价</div>
                                    <div className="tableC">数量</div>
                                    <div className="tableD">退菜数量</div>
                                    <div className="tableD">赠菜数量</div>
                                    <div className="tableD">兑菜数量</div>
                                    <div className="tableD">追单数量</div>
                                    <div className="tableE">追单时长</div>
                                    <div className="tableD">金额</div>
                                    <div className="tableE">下单时长</div>
                                </div>

                                <div className="setMeal">
                                    {
                                        yetPage == 1 && yetOrder.set_meal_list && yetOrder.set_meal_list.map((item: any, index: number) => {
                                            if (item.currentCount > 0) {
                                                return (
                                                    <div className="body" key={`setMealList${index}`}>
                                                        <div className="tableBody setMealList" key={`setMealList${index}`} onClick={() => {
                                                            yetOrder.set_meal_list.map((iten: any, indexA: number) => {
                                                                yetOrder.set_meal_list[indexA].edit = false
                                                            })
                                                            if (!yetOrder.set_meal_list[index].edit) {
                                                                yetOrder.set_meal_list[index].edit = true
                                                            }
                                                            refreshAction()
                                                        }}>
                                                            <div className="tableA">{item.name}</div>
                                                            <div className="tableB">{item.price}</div>
                                                            <div className="tableB">--</div>
                                                            <div className="tableC">x {item.count}</div>
                                                            <div className="tableC">0</div>
                                                            <div className="tableD">0</div>
                                                            <div className="tableD">0</div>
                                                            <div className="tableD">0</div>
                                                            <div className="tableE">0</div>
                                                            <div className="tableD">￥{keepTwoNum(Multiply(item.currentCount, item.price))}</div>
                                                            <div className="tableE">{item.order_time}</div>
                                                            <div className="orderingFoodTui" onClick={(e: any) => { onRefundSetMeal(e, item.id) }}>退</div>
                                                        </div>

                                                        {
                                                            item.edit
                                                            ?   <div className="setMealDetail">
                                                                    {
                                                                        item.content.map((itemB: any, indexA: number) => {
                                                                            return (
                                                                                <div className="tableBody" key={itemB.id}>
                                                                                    <div className="tableA">{itemB.name}{itemB.has_specs && itemB.default_name ? `(${itemB.default_name})` : ''}{itemB.dish_weigh_name ? `(${itemB.dish_weigh_name})` : ''}</div>
                                                                                    <div className="tableB"></div>
                                                                                    <div className="tableC">x {itemB.count}</div>
                                                                                    <div className="tableD"></div>
                                                                                    <div className="tableE"></div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            : null
                                                        }
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>

                                <div className="setMeal">
                                    {
                                        yetPage == 1 && yetOrder.zhuli_dish && yetOrder.zhuli_dish.name && [yetOrder.zhuli_dish].map((item: any, index: number) => {
                                            return (
                                                <div className="body" key={`setMealList${index}`}>
                                                    <div className="tableBody" key={`setMealList${index}`}>
                                                        <div className="tableA">
                                                            <div className='dish_name'>
                                                                {item.name}<div className='dish_status'>助</div>
                                                            </div>
                                                        </div>
                                                        <div className="tableB">{item.price}</div>
                                                        <div className="tableB">--</div>
                                                        <div className="tableC">x {item.count ? item.count : 1}</div>
                                                        <div className="tableC">0</div>
                                                        <div className="tableD">0</div>
                                                        <div className="tableD">0</div>
                                                        <div className="tableD">0</div>
                                                        <div className="tableE">0</div>
                                                        <div className="tableD">￥0.00</div>
                                                        <div className="tableE">{item.order_time || '--'}</div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                {
                                    yetOrderPage ?
                                    yetOrderPage.map((itemA: any, indexA: number) => {
                                        return (
                                            <div onClick={() => {
                                                orderEdit(indexA)
                                            }} key={`B${indexA}`} style={{ backgroundColor: itemA.edit ? 'rgba(255, 227, 227, 1)' : 'white' }}>
                                                <div className="tableBody">
                                                    <div className="tableA">
                                                        <div className='dish_name'>
                                                            <div>{itemA.name}{itemA.standard ? `(${itemA.standard})` : ''}  {itemA.dish_weigh_name ? `(${itemA.dish_weigh_name})` : ''} {itemA.material_name ? `(${itemA.material_name})` : ''}</div>
                                                            {
                                                                itemA.order_dish_refund_num && itemA.order_dish_refund_num > 0
                                                                    ? <div className='dish_status dish_back'>退</div>
                                                                    : null
                                                            }

                                                            {
                                                                itemA.order_present_num && itemA.order_present_num > 0
                                                                    ? <div className='dish_status'>赠</div>
                                                                    : null
                                                            }

                                                            {
                                                                itemA.order_redeem_num && itemA.order_redeem_num > 0
                                                                    ? <div className='dish_status'>兑</div>
                                                                    : null
                                                            }

                                                            {
                                                                itemA.last_order_count && itemA.last_order_count > 0
                                                                    ? <div className='dish_status'>加</div>
                                                                    : null
                                                            }
                                                        </div>
                                                        <div>{itemA.table_name ? `${itemA.table_name}` : ''}</div>
                                                    </div>
                                                    {
                                                        !itemA.is_vip_dish
                                                        ? <div className="tableB">￥{itemA.material_price ? keepTwoNum(Add(itemA.material_price, itemA.price)) : itemA.price}</div>
                                                        : <div className="tableB" style={{textDecoration: 'line-through'}}>￥{itemA.material_price ? keepTwoNum(Add(itemA.material_price, itemA.price)) : itemA.price}</div>
                                                    }
                                                    <div className="tableB">{itemA.is_vip_dish ? '￥'+ itemA.material_price ? keepTwoNum(Add(itemA.material_price, itemA.price_vip)) : itemA.price_vip : '--'}</div>
                                                    <div className="tableC">x {itemA.count}</div>
                                                    <div className="tableC">{itemA.order_dish_refund_num}</div>
                                                    <div className="tableD">{itemA.order_present_num}</div>
                                                    <div className="tableD">{itemA.order_redeem_num}</div>
                                                    <div className="tableD">{itemA.last_order_count}</div>
                                                    <div className="tableE">{itemA.last_order_time}</div>
                                                    <div className="tableD">￥{keepTwoNum(Multiply((itemA.count - itemA.order_dish_refund_num - itemA.order_present_num - itemA.order_redeem_num) , itemA.is_vip_dish ? itemA.material_price ? keepTwoNum(Add(itemA.material_price, itemA.price_vip)) : itemA.price_vip : itemA.material_price ? keepTwoNum(Add(itemA.material_price, itemA.price)) : itemA.price))}</div>
                                                    <div className='tableE'>{itemA.order_time}</div>
                                                </div>
                                                {
                                                    itemA.edit ?
                                                        <div className="tableEdit">
                                                            <div onClick={() => {
                                                                presentFoodAction(itemA,)
                                                            }}>赠送</div>
                                                            <div className="tableEdit_line"></div>
                                                            {
                                                                itemA.order_present_num > 0
                                                                    ? <div onClick={() => {
                                                                        cancelPresentAction(itemA)
                                                                    }}>取消赠送</div>
                                                                    : null
                                                            }
                                                            {
                                                                itemA.order_present_num > 0
                                                                    ? <div className="tableEdit_line"></div>
                                                                    : null
                                                            }
                                                            <div onClick={() => {
                                                                orderedEditAction(itemA)
                                                            }}>改数量</div>
                                                            <div className="tableEdit_line"></div>
                                                            <div onClick={() => {
                                                                returnDishes(itemA)
                                                            }}>退菜</div>
                                                            <div className="tableEdit_line"></div>
                                                            <div onClick={() => {
                                                                orderReloadPrintAction(itemA.count, itemA.standard, itemA.standard_id, itemA.name, itemA.id, itemA.material_key)
                                                            }}>厨房重新打印</div>
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                        )
                                    })
                                    : null
                                }

                                {/* 已点分页 */}
                                {
                                    yetOrder.list && yetOrder.list.length > 60
                                        ? <div className="pageView" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
                                            <Pagination defaultCurrent={1} total={yetOrder.list.length} onChange={(a) => { yetPageAction(a) }} pageSize={60} current={yetPage} />
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                        {
                            yetOrder.list ?
                                <div className="bottom">
                                    <div className="bottom_left">共：{yetOrder.amount}件   合计：¥ {yetOrder.price}</div>
                                    <div className='bottom_r_wrap'>
                                        <div className="bottom_btn ghost mr30" style={{width: '100px'}} onClick={() => { onTabAction(0) }}>加菜</div>
                                        <div className="bottom_btn ghost mr30" onClick={() => { reprintAction() }}>重打点菜单</div>
                                        <div className="bottom_btn ghost mr30" onClick={() => { reprintMakeAction() }}>重打制作单</div>
                                        <div className="bottom_btn" onClick={() => { orderPay() }}>结账</div>
                                    </div>
                                </div>
                                : null
                        }
                    </div>
                    : <div />
        }

            <Modal
                visible={orderNotesStatus}
                title='订单备注'
                width="694px"
                onOk={() => { onOrderNotesSubmit() }}
                onCancel={() => {onOrderNotesCancel()}}
            >
                <div className="orderingFood_rule">口味备注</div>
                <div className="orderingFood_notes_btn">
                    {
                        orderNotesDefault.map((item: any, index: any) => {
                            return (
                                <div onClick={() => { onChangeOrderNote(item) }} className={`${item.active ? 'orderingFood_notes_btn_active' : 'orderingFood_notes_btn_item'}`}>{item.name}</div>
                            )
                        })
                    }
                </div>
                <div className="orderingFood_notes_btn_context">
                    <TextArea onChange={(e: any) => { onChangeOrderNotes(e) }} value={orderNotes}></TextArea>
                </div>
            </Modal>
        </div>
    )
}

export default OrderingFood