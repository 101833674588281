import React from 'react'
import { Row, Col, Input } from 'antd'
import { IApplyWithdrawalConfig } from '../apply_withdrawal'

interface IApplyBottomBar {
  amountChange(num: number):void
  withDrawalConfig: IApplyWithdrawalConfig,
  applyType: number,
  withdrawableRMB: string
}

const ApplyBottomBar = (props: IApplyBottomBar) => {
  
  const amountChange = (e: string) => {
    props.amountChange(Number(e))
  }

  const getFee = () => {
    if(props.applyType === 1) {
      return Number(props.withDrawalConfig.wechat.shop_rate) * 100
    }
    if(props.applyType === 2) {
      return Number(props.withDrawalConfig.bank.shop_rate) * 100
    }
  }

  const getMinFee = () => {
    if(props.applyType === 1) {
      return Number(props.withDrawalConfig.wechat.shop_min_fee)
    }
    if(props.applyType === 2) {
      return Number(props.withDrawalConfig.bank.shop_min_fee)
    }
  }

  const getLimit = () => {
    if(props.applyType === 1) {
      return Number(props.withDrawalConfig.wechat.shop_max_day)
    }
    if(props.applyType === 2) {
      return Number(props.withDrawalConfig.bank.shop_max_day)
    }
  }

  return <Row type="flex" justify="center" style={{borderTop: '20px solid #f9f9ff', marginTop: 50}}>
      <Row style={{width: 400, margin: '0 auto'}}>
      <Row type="flex" align="middle" style={{marginTop: 20}}>
        <Col>可提现金额：</Col>
        <Col>{props.withdrawableRMB}元</Col>
      </Row>
      <Row type="flex" align="middle"  style={{marginTop: 20}}>
        <Col>提现金额：</Col>
        <Col>
          <Input prefix="￥" suffix="RMB" onChange={(e) => amountChange(e.target.value)} />
        </Col>
      </Row>
      <Row type="flex" align="middle"  style={{marginTop: 20}}>
        <Col>提现到微信零钱：提现手续费费率{getFee()}%，最低费率{getMinFee()}元，每日限额{getLimit()}元</Col>
      </Row>
    </Row>
  </Row>
}

export default ApplyBottomBar