import React, { useEffect, useState } from 'react'
import { Row, Button, Input, Table, message, Modal, Tag } from 'antd'
import { useHistory } from 'react-router-dom';
import { getRequest } from '../../../api/http';
import { apiUrl } from '../../../api/api_url';
const { Search } = Input;
const { confirm } = Modal;
type ImemberGroup = {
  id: number,
  name: string,
  member_num: number,
  members: Array<{}>
}

const VipGroup = () => {

  const history = useHistory();
  const [tabList, setTabList] = useState(10);
  const [pageSize, setPageSize] = useState(10);
  const [memberGroup, setmemberGroupList] = useState<Array<ImemberGroup>>([]);
  const [copymemberGroup, setcopymemberGroupList] = useState<Array<ImemberGroup>>([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getMerberGroupList()
  }, [])

  const getMerberGroupList = async (currenPageParam?: number, data:any = {}) => {
    if (currenPageParam === undefined) {
      currenPageParam = 1
    }
    let param = {
      page: currenPageParam,
      pageSize: pageSize,
      ...data
    }
    const res:any = await getRequest(apiUrl.groupList, param);
    if(res && res['code'] === 1) {
      setmemberGroupList(res.data.list);
      setcopymemberGroupList(res.data.list);
      setTabList(res.data.total)
    }
  }

  const addGroup = () => {
    history.push({
      pathname: '/sidebar/add_vip_group'
    })
  }

  // 删除分组
  const deleteGroup = async (rowdata: any) => {
    confirm({
      title: '删除',
      content: '删除后，将解散改分组的用户，是否删除？',
      okText: "删除",
      cancelText: "取消",
      onOk: async () => {
        const res:any = await getRequest(apiUrl.memberGroupDelete, { group_id: rowdata['id'] });
        if(res && res['code'] === 1) {
          message.success("删除成功");
          getMerberGroupList();
        } else {
          message.error(`${res.message}`);
        }
      },
      onCancel() {},
    });
  }

  const searchGroup = async (val: string) => {
    const arr:any = [];
    copymemberGroup.map((el) => {
      if(el.name.includes(val)) arr.push(el);
    })
    setmemberGroupList([...arr]);
  }

  const columns = [
  {
      title: '序号',
      render:(text: any,record: any,index:number)=>`${index+1}`,
      width: 40
  },
  {
    title: '分组名称',
    dataIndex: 'name',
    key: 'name',
    width: 80,
  },
  {
    title: '用户数量',
    dataIndex: 'member_num',
    key: 'member_num',
    width: 80
  },
  {
    title: '创建时间',
    dataIndex: 'create_time',
    key: 'create_time',
    width: 80,
  },
  {
    title: '更新时间',
    dataIndex: 'update_time',
    key: 'update_time',
    width: 80
  },
  {
    title: '区别对待',
    // dataIndex: 'is_difference_treat',
    key: "is_difference_treat",
    width: 40,
    render: (rowdata: any) => {

      return rowdata ? <Row>
        <Tag color={rowdata.is_difference_treat ? "green" : "red"}>{rowdata.is_difference_treat ? "是" : "否"}</Tag>
        {/* <Tag color="green">{rowdata.is_difference_treat ? "取消区别对待" : "设置为区别对待"}</Tag> */}
        {/* <Button>{rowdata.is_difference_treat ? "取消区别对待" : "区别对待"}</Button> */}
      </Row>
      : <Row></Row>
    }
  },
  {
    title: '创建人',
    dataIndex: 'nickname',
    key: 'nickname',
    width: 80
  },
  {
    title: '操作',
    key: 'action',
    width: 160,
    render: (rowdata: any) => {
      return <Row>
        {/* {
          (!rowdata['is_default']) && <>
            <Button type="link" onClick={() => history.push({pathname: "/sidebar/add_vip_group", state: { rowdata }})}>编辑</Button>
            <Button type="link" onClick={() => history.push({pathname: '/sidebar/group_members', state: {title: `${rowdata.name}`, id: rowdata.id}})}>管理</Button>
            <Button type="link" onClick={() => deleteGroup(rowdata)}>删除</Button>
          </>
        } */}
        <Button type="link" onClick={() => history.push({pathname: '/sidebar/group_members', state: {title: `${rowdata.name}`, id: rowdata.id, is_default: rowdata.is_default}})}>管理</Button>
        <Button type="link" onClick={() => history.push({pathname: "/sidebar/add_vip_group", state: { rowdata }})}>编辑</Button>
        <Button type="link" onClick={() => deleteGroup(rowdata)}>删除</Button>
        <Button type="link" onClick={() => setGroupTreat(rowdata)}>{rowdata.is_difference_treat ? "取消区别对待" : "设为区别对待"}</Button>
      </Row>
    }
  },]

  const setGroupTreat = async (rowdata: any) => {
    const res:any = await getRequest(apiUrl.setGroupTreat, { id: rowdata.id,  is_difference_treat: !rowdata.is_difference_treat });
    if(res && res.code === 1) {
      message.success('设置成功');
      getMerberGroupList();
    } else {
      message.error(res.msg);
    }
  }

  const getRechargeList = async (page=1)=>{
    let param = {
        page: page,
        pageSize: 10
    }
    const res:any = await getRequest(`${apiUrl.groupList}`,param).then((res:any) => {
      setmemberGroupList(res.data.list);
      setcopymemberGroupList(res.data.list);
      setTabList(res.data.total)
    });
    return res;
}

  // 翻页
  const pageOnChange = (page: number) => getRechargeList(page)

  const pageChange = (e: any) => {
    console.log(e)
    setCurrentPage(e)
    getMerberGroupList(e)

}

  return <Row>
    <Row type="flex">
      <Button type="primary" style={{marginRight: 200}} onClick={addGroup}>新建分组</Button>
      <Search
        placeholder="搜索分组"
        enterButton="搜索"
        allowClear
        style={{width: '300px'}}
        onSearch={value => searchGroup(value)}
      />
    </Row>
    <Table size="middle" rowKey={"id"} columns={columns}  dataSource={memberGroup}
      style={{marginTop: '30px'}}
      pagination={{
        defaultCurrent: 1,
        pageSize: pageSize,
        total: tabList,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total: number) => `共${total}条`,
        onChange: pageChange,
        onShowSizeChange: (current: number, size: number) => { 
          setPageSize(size); setCurrentPage(1);getMerberGroupList(1, {current: 1, pageSize: size})
        }
      }}
     />
  </Row>
}

export default VipGroup