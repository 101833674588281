import React from 'react';
import { Row, Col } from 'antd';
import '../management.scss';

import winAddBuyFree1 from '../../../../../../assret/images/winAddBuyFree1.png';
import winAddBuyFree2 from '../../../../../../assret/images/winAddBuyFree2.png';

interface HelpMbaBuyFree {
    history: any,
}

class HelpMbaBuyFree extends React.Component<HelpMbaBuyFree> {

    render() {
        return  <Row className="typeDetail">
                    <Col className="typeDetail-title">一、功能介绍</Col>
                    <Col className="typeDetail-col">所有需要投放的买赠，必须要在买赠管理列表中添加买赠，买指定活动商品可以获得赠送商品 例：买一赠一。</Col>
                    <Col className="typeDetail-title">二、功能路径</Col>
                    <Col className="typeDetail-col">电脑端登录后台-营销管理-买赠列表-添加买赠。</Col>
                    <Col className="typeDetail-col">
                        <img src={winAddBuyFree1} />
                    </Col>
                    <Col className="typeDetail-title">三、操作流程</Col>
                    <Col className="typeDetail-col">1、<span className="txt-bold">添加买赠</span>，在“买赠列表”界面下点击“添加”</Col>
                    <Col className="typeDetail-col">
                        <img src={winAddBuyFree2} />
                    </Col>
                    <Col className="typeDetail-col">1、买赠名称：填写名称</Col>
                    <Col className="typeDetail-col">2、有效期：可以填写固定日期或永久日期</Col>
                    <Col className="typeDetail-col">3、每购买以下菜品：添加某个菜品，购买份数，当用户满足条件，则赠送菜品</Col>
                    <Col className="typeDetail-col">4、可赠送以下菜品：满足购买以下菜品条件所送的菜品</Col>
                </Row>
    }
}

export default HelpMbaBuyFree;