import React, { useState } from 'react'
import { Row, Col, Input, Button, Typography, DatePicker, } from 'antd'
import { IFormData, ICurrentProduct } from '../create_active';
import moment from 'moment';
import OpenBindProductModel from './open_bind_product_model'

const { Text } = Typography;
const { RangePicker } = DatePicker;
const { TextArea } = Input;

interface IProps {
  update(d: IFormData): void,
  formData: IFormData,
  bindProduct: ICurrentProduct,
  setcurrentProduct(product:object):void
}

const CreateStep01 = (props: IProps) => {
  const dateFormat = 'YYYY-MM-DD HH:mm:ss';
  const { formData } = props;

  const activeNameChange = (value: string) => props.update({...props.formData, name: value});
  const buyPriceChange = (value: string) => props.update({...props.formData, buy_price: Number(value)});
  const stockNumChange = (value: string) => props.update({...props.formData, stock_num: Number(value)});
  const updateProductId = (product: object) => {
    props.setcurrentProduct(product);
  }
  
  // 活动开始结束时间
  const onActiveTimeChange = (e:any) => {
    props.update({...props.formData, start_at: e[0].format(dateFormat), end_at: e[1].format(dateFormat)})
  }
  // 核销开始结束时间
  const onWriteOffChange = (e:any) => {
    props.update({...props.formData, verify_start_at: e[0].format(dateFormat), verify_end_at: e[1].format(dateFormat)})
  }

  const onBuydescChange = (e:string) => {
    props.update({...props.formData, buy_desc: e})
  }

  const [showProductModel, setshowProductModel] = useState(false);

  return (
    <div>
      <Row type="flex" align="middle" style={{marginTop: 30}}>
        <Col span={3} style={{textAlign: 'right'}}>活动标题：</Col>
        <Col span={6}>
          <Input placeholder="输入活动标题" defaultValue={formData.name} onChange={(e) => activeNameChange(e.target.value)} />
        </Col>
      </Row>

      <Row type="flex" align="middle" style={{marginTop: 30}}>
      <Col span={3} style={{textAlign: 'right'}}>分销商品：</Col>
      { 
        props.bindProduct 
        ? <Row type="flex" align="middle" style={{cursor: 'pointer'}} onClick={() => setshowProductModel(true)}>
            <div style={{width: '44px', height: '44px', marginRight: 10, backgroundImage: `url(${props.bindProduct.first_pic.url})`}}></div>
            <div>{props.bindProduct.name}</div>
          </Row>
        : <Button type="primary" icon="plus" onClick={() => setshowProductModel(true)}>绑定商品</Button>
      }
      </Row>
      
      <Row type="flex" align="middle" style={{marginTop: 30}}>
        <Col span={3} style={{textAlign: 'right'}}>活动购买价格：</Col>
        <Col span={6} style={{marginRight: 10}}>
          <Input prefix="￥" suffix="元" defaultValue={formData.buy_price < 0 ? '' : formData.buy_price} onChange={(e) => buyPriceChange(e.target.value)} />
        </Col> 
          {/* <Text>( 活动期间的售价，过了活动时间若用户再次点击则只能通过原价购买 )</Text> */}
      </Row>
      
      <Row type="flex" align="middle" style={{marginTop: 30}}>
        <Col span={3} style={{textAlign: 'right'}}>活动库存：</Col>
        <Col span={3} style={{marginRight: 10}}>
          <Input suffix="件" defaultValue={formData.stock_num === 0 ? '' : formData.stock_num} onChange={(e) => stockNumChange(e.target.value)} />
        </Col> 
        <Text>( 不写则为不限 )</Text>
      </Row>

      <Row type="flex" align="middle" style={{marginTop: 30}}>
        <Col span={3} style={{textAlign: 'right'}}>活动时间：</Col>
        <Col span={12} style={{marginRight: 10}}>
          <RangePicker 
            format="YYYY-MM-DD HH:mm:ss" 
            showTime={{ format: 'HH:mm' }}
            onChange={(e) => onActiveTimeChange(e)} 
            defaultValue={ formData.start_at ? [moment(formData.start_at, dateFormat), moment(formData.end_at, dateFormat)] : []} />
        </Col>
      </Row>

      <Row type="flex" align="middle" style={{marginTop: 30}}>
        <Col span={3} style={{textAlign: 'right'}}>到店核销时间：</Col>
        <Col span={8} style={{marginRight: 10}}>
          <RangePicker 
            format="YYYY-MM-DD HH:mm:ss" 
            showTime={{format: 'HH:mm' }}
            onChange={(e) => onWriteOffChange(e)} 
            defaultValue={ formData.verify_start_at ? [moment(formData.verify_start_at, dateFormat), moment(formData.verify_end_at, dateFormat)] : []}/>
        </Col>
      </Row>

      <Row type="flex" align="top" style={{marginTop: 30}}>
        <Col span={3} style={{textAlign: 'right'}}>购买须知(非必填)：</Col>
         <Col span={8} style={{marginRight: 10}}>
           <TextArea rows={4} placeholder="购买须知" defaultValue={formData.buy_desc} onChange={(e) => onBuydescChange(e.target.value)} />
        </Col>
      </Row>

      <OpenBindProductModel 
        visible={showProductModel} 
        closeModel={() => setshowProductModel(false)} 
        updateProductId={(product: object) => updateProductId(product)}
         />
    </div>
  );
}

export default CreateStep01
