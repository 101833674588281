import React from 'react';
import InsideNav from '../../../component/insideNav/insideNav';
import { collectChildNav } from '../../../utils/permisstionUtil';
import ShopList from '../../shop/shopList';
import BindApplet from '../../store_accout/bind_applet/bind_applet';
import BindPublic from '../../store_accout/bind_pulbic/bind_public';
import StoreAuth from '../../store_accout/store_auth/store_auth';
import ShopDecorationMain from '../../shop/shopDecorationMain';
import './storeManage.scss'

type IProps = {
    history: any
}
type IState = {
    navList: any
    current_key: string
}

const navTable = [
    {
        title: '门店列表',
        isActive: false,
        KEY: "SHOP_LIST",
        uni_key: '2_7_1'
    },
    {
        title: '小程序绑定',
        isActive: false,
        KEY: "BIND_APPLET",
        uni_key: '2_7_2'
    },
    {
        title: '公众号绑定',
        isActive: false,
        KEY: "BIND_PUBLIC",
        uni_key: '2_7_3'
    },
    {
        title: '门店装修',
        isActive: false,
        KEY: "STORE_DECORATION",
        uni_key: '2_7_5'
    },
    // {
    //     title: '商家认证',
    //     isActive: false,
    //     KEY: "STORE_AUTH",
    //     uni_key: '2_7_4'
    // }
]

class StoreManage extends React.Component<IProps,IState>{
    
    constructor(props:IProps) {
        super(props);
        this.state = {
            navList: [],
            current_key: "SHOP_LIST",
        }
    }

    componentDidMount(){
        this.initPageState();
    }

    initPageState = () =>{
        let navList = collectChildNav(navTable);
        let currentKey = localStorage.getItem('current_key') ?? navList[0].KEY;
        let isSetKey = false;
        navList.map((item:any) => {
            if(item.KEY == currentKey){
                item.isActive = true;
                isSetKey = true;
            }else{
                item.isActive = false;
            }
        })
        if(!isSetKey){
            currentKey = navList[0].KEY;
            navList[0].isActive = true;
        };
        this.setState({
            current_key: currentKey,
            navList: navList
        })
    }

    navCallBack(currentKey: string){
        localStorage.setItem('current_key',currentKey) 
        this.setState({
            current_key: currentKey
        })
    }

    render() {
        let { navList,current_key } = this.state;
        return <div>
            <InsideNav navList={navList} callBack={(key: string) =>this.navCallBack(key)}/>
            <div className="body">
                {
                    current_key === 'SHOP_LIST'  ?  <ShopList />
                    :
                    current_key === 'BIND_APPLET' ?  <BindApplet />
                    :
                    current_key === 'BIND_PUBLIC' ?  <BindPublic />
                    :
                    current_key === 'STORE_DECORATION' ?  <ShopDecorationMain history={this.props.history} />
                    : <></>
                    
                }
            </div>
        </div>
    }

}

export default StoreManage;