import {observer} from "mobx-react";
import React from "react";
import {Button, Input, message, Row, Col, Modal, Radio, Icon, Table, Tree, Tooltip, Select, DatePicker} from "antd";
import {postRequest, getRequest} from '../../api/http';
import {RangePickerValue} from "antd/lib/date-picker/interface";
import {apiUrl} from '../../api/api_url';

import './chargeFree.scss'
import {type} from "os";

const {TreeNode} = Tree;
const {Option} = Select;
const {RangePicker} = DatePicker;

const key = 'chargeFreeEdit';

@observer
class chargeFreeEdit extends React.Component<any> {

    state = {
        loading: false,
        nickname: '', //优惠名称
        status: 2, //状态
        typeUser: 1, //活动用户类型
        EffectiveTime: 1, //有效时间
        consumption: '', //消费满
        chargeFee: '', //充值满
        singlePerson: '', //单人参与次数
        visible: false,
        couponList: [],
        listNum: 0,
        current: 1,
        selectCoupon: [],
        type: 'new_people',
        selectDisabled: false,
        typeName: '',
        user_type: [],
        pageSize: 100,
        selectList: [],
        start_time: '',
        end_time: '',
        position: 1,
        greeting: '',
        channel: 1,
        is_bothway: false,
        edit: false,
        typeCharge: 1, //充值类型
        discount: 0,
        freeze: 1,
        freeze_percentage: 0
    }

    // //例如规则
    onToastRul = () => {
        return (
            <div>
                <div>余额冻结说明</div>
                <div>
                    示例说明:
                    <div>1、当设置【使用消费条件】为【充值免单】时，假设某用户消费前已有剩余余额50元，本次消费100元，参与充值3倍免单， 设置冻结比例30%，
                        那么此用户参与消费免单活动后，可使用余额为50+300*70%=260元，剩余300*30%=90元第二天到账</div>
                    <div>2、当设置【使用消费条件】为【充值折扣时】，假设某用户消费前已有剩余余额50元，本次消费100元，参与充值3倍本单5折，设置冻结比例30%，
                        那么此用户参与消费免单活动后，可使用余额为50+（300-100*50%）*70%=225元，剩余（300-100*50%）*30%=75元第二天到账
                    </div>
                    <div>2、若冻结比例设置100%，则此次充值金额将全部冻结，充值金额第二天到账
                    </div>
                </div>
            </div>
        )
    }

    componentDidMount() {
        const {location} = this.props;
        console.log(location)
        if (location.state !== undefined) {
            this.initEdit(location.state.id);
        }
    }

    async initEdit(id: any) {
        const res: any = await getRequest(apiUrl.orderActivityGetInfo, {id: id});
        let {EffectiveTime, start_time, end_time, channel, singlePerson, typeUser, freeze, freeze_percentage} = this.state;
        if (res.code === 1) {
            let {name, expire_info, participate_time, status, use_limit, user_type, type, discount, is_freeze, freeze_percent} = res.data;
            if (expire_info.type == 'permanent') {
                EffectiveTime = 1
            } else {
                EffectiveTime = 0;
                start_time = expire_info.start_time;
                end_time = expire_info.end_time;
            }
            if (participate_time == -1) {
                channel = 1
            } else {
                channel = 2;
                singlePerson = participate_time
            }
            if (user_type.length < 3) {
                typeUser = 0
            }
            if (is_freeze == 2) {
                freeze = 2;
                freeze_percentage = freeze_percent;
            } else {
                freeze = 1;
                freeze_percentage = 0;
            }
            this.setState({
                nickname: name,
                start_time,
                end_time,
                consumption: use_limit.achieve,
                chargeFee: use_limit.mulriple,
                singlePerson,
                channel,
                status,
                user_type,
                typeUser,
                EffectiveTime,
                typeCharge: type,
                discount,
                freeze,
                freeze_percentage
            })
        }
    }

    nameChange = (e: any) => {
        let name = e.target.name;
        let value = e.target.value;
        this.setState({
            [name]: value
        })
    }

    onCheck = (checkedKeys: any, info: any) => {
        let keyList = checkedKeys;
        let user_type: any[] = [];
        keyList.map((item: any) => {
            if (item !== '4') {
                user_type.push(item)
            }
        })
        this.setState({
            user_type
        })
    };

    inputChange = (e: any) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name === 'freeze_percentage') {
            if (value > 100) {
                return;
            }
        }
        this.setState({
            [name]: value
        })
    }

    handleSubmit = async () => {

        const {location} = this.props;
        const {discount, nickname, typeCharge, type, status, EffectiveTime, typeUser, singlePerson, user_type, start_time, end_time, position, greeting, channel, consumption, chargeFee, freeze, freeze_percentage} = this.state;
        var tableInfo: any = {};
        var productInfo: any = {};

        if (location.state !== undefined) {
            tableInfo.id = location.state.id;
        }

        if (nickname === '') {
            message.warning({content: '请输入优惠名称!', key, duration: 2});
            return;
        }

        if (EffectiveTime == 0) {
            if (start_time === '') {
                message.warning({content: '请选择有效时间!', key, duration: 2});
                return;
            }
            tableInfo.expire_info = {
                type: 'time',
                start_time,
                end_time
            }
        } else {
            tableInfo.expire_info = {
                type: 'permanent',
                value: ''
            }
        }

        if (consumption == '') {
            message.warning({content: '请输入消费金额!', key, duration: 2});
            return;
        }

        if (chargeFee == '') {
            message.warning({content: '请输入消费金额倍数!', key, duration: 2});
            return;
        }

        if (channel == 2) {
            if (singlePerson === '') {
                message.warning({content: '请输入单人参与次数!', key, duration: 2});
                return;
            }
            tableInfo.participate_time = singlePerson;
        } else {
            tableInfo.participate_time = -1;
        }

        tableInfo.name = nickname;
        tableInfo.use_limit = {
            achieve: consumption,
            mulriple: chargeFee
        }
        if (typeCharge == 1) {
            tableInfo.discount = 0
        } else {
            if (discount == 0) {
                message.warn('折扣不能为0')
                return false;
            }
            tableInfo.discount = discount
        }

        tableInfo.status = status;
        tableInfo.type = typeCharge;

        if (typeUser === 0) {
            if (user_type.length === 0) {
                message.warning({content: '请选择部分用户!', key, duration: 2});
                return;
            }
        }

        tableInfo.user_type = user_type;
        tableInfo.freeze = freeze;
        tableInfo.freeze_percentage = freeze_percentage;

        message.loading({content: '发布中', key});
        if (location.state !== undefined) {
            //console.log(tableInfo)
            const res: any = await postRequest(apiUrl.orderActivityEdit, tableInfo);
            if (res['code'] === 1) {
                message.success({content: '修改成功!', key, duration: 2});
                this.goBack()
            } else {
                message.error({content: '修改失败!', key, duration: 2});
            }
        } else {
            //console.log(tableInfo)
            const res: any = await postRequest(apiUrl.orderActivityAdd, tableInfo);
            if (res['code'] === 1) {
                message.success({content: '发布成功!', key, duration: 2});
                this.goBack()
            } else {
                message.error({content: '发布失败!', key, duration: 2});
            }
        }
    };

    // 返回上一页
    goBack = () => {
        this.props.history.goBack();
    }

    timeChange = (time: RangePickerValue, timeString: [string, string]) => {
        this.setState({
            start_time: timeString[0],
            end_time: timeString[1]
        })
    }

    render() {
        const {discount, typeCharge, typeUser, channel, nickname, EffectiveTime, status, visible, consumption, user_type, selectList, current, listNum, start_time, end_time, chargeFee, singlePerson, freeze, freeze_percentage} = this.state;

        return <div className='l_content'>
            <div className="edit_title">优惠基本信息</div>

            <Row className="mb30" type="flex" align="middle">
                <Col span={2}>
                    <div>
                        <span className="star"></span>
                        <span className="color_black">优惠名称</span>
                    </div>
                </Col>
                <Col span={6}>
                    <Input placeholder="请输入优惠名称" name='nickname' value={nickname} onChange={(e) => this.nameChange(e)}/>
                </Col>
            </Row>

            <Row className="mb30" type="flex" align="middle">
                <Col span={2}>
                    <div>
                        <span className="star"></span>
                        <span className="color_black">有效时间</span>
                    </div>
                </Col>
                <Col span={22}>
                    <Radio.Group
                        name='EffectiveTime'
                        value={EffectiveTime}
                        onChange={(e) => this.nameChange(e)}
                    >
                        <Radio value={1}>永久</Radio>
                        <Radio value={0}>固定时间</Radio>
                        {
                            EffectiveTime == 0
                                ? <RangePicker onChange={this.timeChange} format="YYYY-MM-DD"
                                               placeholder={start_time !== '' ? [start_time, end_time] : ["开始时间", "结束时间"]}/>
                                : ''
                        }
                    </Radio.Group>
                </Col>
            </Row>

            <Row className="mb30" type="flex" align="middle">
                <Col span={2}>
                    <div>
                        <span className="star"></span>
                        <span className="color_black">使用消费条件</span>
                    </div>
                </Col>
                <Col span={22}>
                    <Radio.Group
                        name='typeCharge'
                        value={typeCharge}
                        onChange={(e) => this.nameChange(e)}
                        style={{display: 'flex', alignItems: 'center'}}
                    >
                        <Radio value={1}>充值免单</Radio>
                        <Radio value={2}>充值折扣</Radio>
                        {
                            typeCharge == 1
                                ? <Row type='flex'>
                                    <Col><span className='marR10'>消费满</span><Input placeholder="输入金额" name='consumption'
                                                                                   value={consumption}
                                                                                   onChange={(e) => this.nameChange(e)}
                                                                                   style={{width: 100}} className='marR10'/><span>元，</span></Col>
                                    <Col><span className='marR10'>充值</span><Input placeholder="输入倍数" name='chargeFee'
                                                                                  value={chargeFee}
                                                                                  onChange={(e) => this.nameChange(e)}
                                                                                  style={{width: 100}}
                                                                                  className='marR10'/><span>倍免单</span></Col>
                                </Row>
                                : <Row type='flex'>
                                    <Col><span className='marR10'>消费满</span><Input placeholder="输入金额" name='consumption'
                                                                                   value={consumption}
                                                                                   onChange={(e) => this.nameChange(e)}
                                                                                   style={{width: 100}} className='marR10'/><span>元，</span></Col>
                                    <Col><span className='marR10'>充值</span><Input placeholder="输入倍数" name='chargeFee'
                                                                                  value={chargeFee}
                                                                                  onChange={(e) => this.nameChange(e)}
                                                                                  style={{width: 100}}
                                                                                  className='marR10'/><span>倍</span></Col>
                                    <Col><span className='marR10'>享受</span><Input placeholder="输入折扣" name='discount'
                                                                                  value={discount}
                                                                                  onChange={(e) => this.nameChange(e)}
                                                                                  style={{width: 100}}
                                                                                  className='marR10'/><span>折，</span><span
                                        style={{color: '#aaa'}}>例：填写90表示打9折</span></Col>
                                </Row>
                        }
                    </Radio.Group>
                </Col>
            </Row>


            <Row className="mb30" type="flex" align="middle">
                <Col span={2}>
                    <div>
                        <span className="star"></span>
                        <span className="color_black">单人参与次数</span>
                    </div>
                </Col>
                <Col span={22}>
                    <Radio.Group
                        name='channel'
                        value={channel}
                        onChange={(e) => this.nameChange(e)}
                        disabled={this.state.edit}
                    >
                        <Radio value={1}>无限</Radio>
                        <Radio value={2}>单人最多参与</Radio>
                        {
                            channel == 2
                                ? <Input placeholder="输入次数" name='singlePerson' value={singlePerson}
                                         onChange={(e) => this.nameChange(e)} style={{width: 100}} className='marR10'/>
                                : ''
                        }
                    </Radio.Group>
                </Col>
            </Row>

            <Row className="mb30" type="flex" align="middle">
                <Col span={2}>
                    <div>
                        <span className="star"></span>
                        <span className="color_black">余额使用规则：</span>
                    </div>
                </Col>
                <Col>
                    <Radio.Group value={freeze} onChange={this.inputChange} name='freeze'>
                        <Radio value={1}>无限制</Radio>
                        <Radio value={2}>限制</Radio>
                    </Radio.Group>
                </Col>
                <Col>
                    {
                        freeze == 2
                            ? <div>本次实际充值金额的
                                <Input onChange={this.inputChange} value={freeze_percentage} type="text"
                                       name='freeze_percentage' style={{
                                    width: '100px',
                                    textAlign: 'center',
                                    marginLeft: '5px',
                                    marginRight: '5px'
                                }}/> %当天不可使用，其余部分均可以使用（注：设置冻结比例后,参与此充值活动的消费订单支持反结账操作）。
                                <Tooltip title={this.onToastRul}>
                                    <span style={{color: '#02A7F0'}}>例如</span>
                                </Tooltip>
                            </div>
                            : ''
                    }
                </Col>
            </Row>

            <div className="edit_title">使用设置</div>

            <Row className="mb30" type="flex" align="middle">
                <Col span={2}>
                    <div>
                        <span className="color_black">活动用户</span>
                    </div>
                </Col>
                <Col span={6}>
                    <Radio.Group
                        name='typeUser'
                        value={typeUser}
                        onChange={(e) => this.nameChange(e)}
                    >
                        <Radio value={1}>所有用户</Radio>
                        <Radio value={0}>部分用户</Radio>
                    </Radio.Group>
                    {
                        typeUser == 0
                            ? <Tree
                                checkable
                                showIcon
                                onCheck={this.onCheck}
                                defaultSelectedKeys={user_type}
                                checkedKeys={user_type}
                            >
                                <TreeNode title="新用户" key="1" icon={
                                    <Tooltip placement="topLeft" title={"还未注册的用户或已经注册还没有下单的用户"}>
                                        <Icon type="question-circle"/>
                                    </Tooltip>
                                }>
                                </TreeNode>
                                <TreeNode title="老用户" key="4" icon={
                                    <Tooltip placement="topLeft" title={"交易次数>=1次的交易次数"}>
                                        <Icon type="question-circle"/>
                                    </Tooltip>
                                }>
                                    <TreeNode title="充值用户" key="2"/>
                                    <TreeNode title="非充值用户" key="3"/>
                                </TreeNode>
                            </Tree>
                            : ''
                    }
                </Col>
            </Row>

            <Row className="mb30" type="flex" align="middle">
                <Col span={2}>
                    <div>
                        <span className="color_black">状态</span>
                    </div>
                </Col>
                <Col span={22}>
                    <Radio.Group
                        name='status'
                        value={status}
                        onChange={(e) => this.nameChange(e)}
                    >
                        <Radio value={2}>启用</Radio>
                        <Radio value={1}>停用</Radio>
                    </Radio.Group>
                </Col>
            </Row>

            <Row>
                <Col span={12}>
                    <div className="bottom_wrap">
                        <Button type="primary" ghost onClick={this.goBack}>
                            取消
                        </Button>
                        <Button type="primary" onClick={this.handleSubmit}>
                            保存
                        </Button>
                    </div>
                </Col>
            </Row>
        </div>;
    }

}

export default chargeFreeEdit