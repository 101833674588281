import React, { useState, useEffect, } from 'react'
import { Steps, Row, Col, Button, message,} from 'antd'
import { withRouter, RouteComponentProps } from 'react-router-dom';

import CreateStep01 from './components/create_step01';
import CreateStep02 from './components/create_step02';
import CreateStep03 from './components/create_step03';
const { Step } = Steps;

export interface IFormData {
  name: string,
  food_id: number,
  buy_price: number,
  start_at: string,
  end_at: string,
  verify_start_at: string,
  verify_end_at: string,
  buy_desc: string,
  hierarchy: number,
  profit_type: number,
  profit_value_1: number,
  profit_value_2: number,
  bind_type: number,
  poster: string,
  stock_num: number,
  food: object,
  shop: object,
  id: number,
  updated_at: string,
}


export interface ICurrentProduct {
  id: number,
  name: string,
  first_pic: {
    url: string
  }
}

interface IProps extends RouteComponentProps {
  acticityData: IFormData
}

const CreateActive = (props: IProps) => {

  const [workStep, setworkStep] = useState(1);
  const [formData, setformData] = useState({
    name: '',  // 活动名称
    food_id: 0, // 绑定的商品
    buy_price: -1, // 购买价格
    start_at: '', // 活动开始时间
    end_at: '',  // 活动结束时间
    verify_start_at: '',  // 核销开始时间
    verify_end_at: '', // 核销结束时间
    buy_desc: '',  // 购买须知
    hierarchy: 1,  // 分销层级，目前只能值只能是 1，2
    profit_type: 1, // 分销奖励类型 1 固定收益  2 比例收益
    profit_value_1: -1,  // 一级分销奖励
    profit_value_2: -1,  // 二级分销奖励
    bind_type: 1,   // 分销绑定类型  1 支付绑定 2 邀请绑定
    poster: '', 
    stock_num: 0,  // 库存
    food: {},
    shop: {},
    id: -1,
    updated_at: ''
  });
  const [currentProduct, setcurrentProduct] = useState<any>();
  const [canJumpStep, setcanJumpStep] = useState(false);

  useEffect(() => {
    setworkStep(0);
    if(props.location.state) {
      const formdata:any = props.location.state;
      delete formdata.shop_user_id;
      delete formdata.views;
      delete formdata.status_display;
      delete formdata.is_show;
      delete formdata.status;
      // delete formdata.id;
      delete formdata.deleted_at;
      delete formdata.stock_over;
      delete formdata.created_at;
      setformData(formdata);
      setcurrentProduct(formdata.food);
      setcanJumpStep(true);
    }
  }, [props]);

  useEffect(() => {
    if(currentProduct){
      setformData({...formData, food_id: currentProduct['id']});
      setcurrentProduct(currentProduct);
    }
  }, [currentProduct])

  
  const prevStep = () => {
    setworkStep(workStep - 1);
  }

  const nextStep = () => {
    // 第一步
    if(workStep === 0) {
      if(formData.name === '') {
        message.error('活动名称不能为空');
        return;
      }
      if(formData.food_id === 0) {
        message.error('绑定的商品不能为空');
        return;
      }
      if(formData.buy_price === -1) {
        message.error('购买价格必须填写');
        return;
      }
      if(formData.start_at === '' || formData.end_at === '') {
        message.error('开始结束时间必须填写');
        return;
      }
      if(formData.verify_start_at === '' || formData.verify_end_at === '') {
        message.error('核销时间必须填写');
        return;
      }
    }

    if(workStep === 1) {
      if(formData.profit_value_1 === -1) {
        message.error('分销奖励必须填写');
        return;
      }
    }

    setworkStep(workStep + 1);
  }

  const updateFormData = (data: IFormData) => setformData(data);
  const jumpStep = (idx: number) => {
    if(canJumpStep) setworkStep(idx);
  }

  const cancelCreateActive = () => {
    props.history.push('/sidebar/distribution');
  }

  return (
    <div>
      <Row>
        <Col span={12}>
          <Steps  size="small" current={workStep}>
            {
              ['活动设置', '分销设置', '海报设置'].map((item, idx) => {
                return <Step title={item} key={item} onClick={() => jumpStep(idx)} />
              })
            }
          </Steps>
        </Col>
      </Row>
      
      {workStep === 0 && <CreateStep01 update={(d:IFormData) => updateFormData(d)} 
                                       formData={formData} 
                                       setcurrentProduct={(product: object) => setcurrentProduct(product)}
                                       bindProduct={currentProduct} />}
      {workStep === 1 && <CreateStep02 update={(d:IFormData) => updateFormData(d)} formData={formData} />}
      {workStep === 2 && <CreateStep03 update={(d:IFormData) => updateFormData(d)} 
                                       formData={formData} workStep={workStep} 
                                       setworkStep={setworkStep}
                                       isEdit={canJumpStep}
                                       />}
      
      <Row type="flex" align="middle" justify="center">
        <Col span={8} style={{marginTop: 80}}>
          {
            workStep === 0 && <Button type="primary" onClick={cancelCreateActive}>取消</Button>
          }
          {
           (workStep > 0 && workStep < 2) && <Button type="primary" onClick={prevStep}>上一步</Button>
          }
          {
            ((workStep > 0 || workStep === 0) && workStep < 2) && <Button type="primary" style={{marginLeft: 50}} onClick={nextStep}>下一步</Button>
          }
        </Col>
      </Row>
    </div>    
  )
}

export default withRouter(CreateActive)