import { Row, Col, DatePicker, message, Select } from 'antd';
import React, { Component } from 'react';
import LineChart from './components/lineChart';
import LineChartActive from './components/lineChartActive';
import InsideNav from '../../component/insideNav/insideNav';
import ComsumeBehavior from './components/comsumeBehavior';
import ArcProgress from './components/arcProgress';

import styles from './repurchaseOperation.module.scss';

import system from '../../assret/images/system.png';
import { apiUrl } from '../../api/api_url';
import { getRequest } from '../../api/http';

const { RangePicker } = DatePicker;
const { Option } = Select;

const nowDate = new Date();
const yesterday = new Date(nowDate.getTime() - 24 * 60 * 60 * 1000)
const beforeYesterday = new Date(nowDate.getTime() - 24 * 2 * 60 * 60 * 1000)

var now:any = new Date(); //当前日期
var nowDayOfWeek = now.getDay(); //今天本周的第几天
var nowDay = now.getDate(); //当前日
var nowMonth = now.getMonth(); //当前月
var nowYear = now.getYear(); //当前年
nowYear += (nowYear < 2000) ? 1900 : 0; //
var lastMonthDate:any = new Date(); //上月日期
lastMonthDate.setDate(1);
lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);
var lastYear = lastMonthDate.getYear();
var lastMonth = lastMonthDate.getMonth();

interface IProps {
	history?: any
}

  interface IState {
	top_icon_list: any,
	recommend_icon_list: any,

}
class RepurchaseOperation extends Component<IProps, any> {

	constructor(props: IProps) {
		super(props);
		this.state = {
			top_icon_list: [
				{
					title: '复购率',
					rate: 0,
                    icon: 'https://ydn-siyu.cdn.shuachi.com/deep_user_total.png'
				},
				{
					title: '复购频次',
					rate: 0,
                    icon: 'https://ydn-siyu.cdn.shuachi.com/repurchase.png'
				},
			],
			recommend_icon_list: [
				{
					id: 1,
					name: '用户等级',
					active: true,
				},
				{
					id: 2,
					name: '消费次数',
					active: false,
				},
				{
					id: 3,
					name: '消费月份',
					active: false,
				},
				{
					id: 4,
					name: '消费行为',
					active: false,
				},
				{
					id: 5,
					name: '就餐月份',
					active: false,
				}
			],
			recommend_icon_idx: 1,
			totalUser: 0,//总用户
			newUser: 0,//新用户
			vipUser: 0,//vip用户
			newVip: 0,//新vip
			singlePrice: 0,//平均桌单价
			priceTrend: 0,//价格趋势
			rechargeAmount: "0.00",//充值金额
			presentAmount: "0.00",//充值赠送金额
			start_at: '',
			end_at: '',
            currentTitleIdx: 1,
            navList: [
                {
                    title: '复购活动分析',
                    isActive: true,
                    KEY: "USER_DATA",
                    uni_key: '1_6_00'
                },
                {
                    title: '复购用户分析',
                    isActive: false,
                    KEY: "USER_LIST",
                    uni_key: '1_6_1'
                },
            ],
            operation_line_color: ['#FF5369','#6F74FF','#FF6BEE','#00E641','#FFD55C','#71d9fc','#ff8b5e'],//折线图颜色
            current_tabble: 1,
            operation_line_color_content: [], //复购活动分析
            operation_line_color_content_active: [], // 复购用户分析
            xLine: [],//复购活动分析
            xLine_active: [],// 复购用户分析
            legend: [],
            legend_active: [],
            month_info: {
                user: '',
                rate: ''
            },
            activity_list: [], //复购活动配置列表
            nearly_year: [],
            line_date_obj: [], //折线图时间
            own_all_data: {},
            progress_loading: false,
		}
	}

	componentDidMount() {
        this.getTimeData('lastqiday')
        this.rePurchaseActivity()
        this.onNearlyAYear()
        this.onRePurchaseChart({
            start_at: nowYear + '-' + this.formatData(nowMonth+1) + '-' + '01',
            end_at: nowYear + '-' + this.formatData(nowMonth+1) + '-' + '01'
        })
    }

	//
	_initDate = async (type: any) => {
        this.setState(({
            progress_loading: false,
        }))
		let params = {
            start_at: this.formatDate(Number(type)),
            end_at: this.formatDate(0)
		}
        if(type === 1) {
            params.end_at = this.formatDate(Number(type))
		}
		if(type == 2) {
			// this.setState({
			// 	start_at
			// })
			params.start_at= this.state.start_at;
            params.end_at = this.state.end_at;
		}
		let res:any = await getRequest(apiUrl.rePurchaseIndex, params);
		if(res.code == 1) {
			let { totalUser, newUser, vipUser, newVip, singlePrice, priceTrend, rechargeAmount, presentAmount } = res.data;
			this.setState({
                top_icon_list: res.data.list,
                own_all_data: {'pull_rate': Number(res.data.monthInfo.rate) >= 100 ? 100 : Number(res.data.monthInfo.rate), 'pull_new_count': Number(res.data.monthInfo.user)},
                progress_loading: true,
                month_info: res.data.monthInfo
			})
		} else {
			message.error(res.message)
		}
    }

    //复购活动列表
    rePurchaseActivity = async () => {
        let res: any = await getRequest(apiUrl.rePurchaseActivity)
        if(res.code == 1) {
            this.setState({
                activity_list: res.data
            })

        } else {
            message.error(res.message)
        }
    }

    //复购活动折线图
    onRePurchaseChart = async (dateObj: any) => {
        let current_tabble: any = this.state.current_tabble;
        let operation_line_color: any = this.state.operation_line_color;
        let params = {
            ...dateObj,
            type: current_tabble
        }
        let res:any = await getRequest(apiUrl.rePurchaseChart, params);
		if(res.code == 1) {
			let _data_list = res.data;
            let yData: any = [];
            let legend: any = [];
            _data_list.yLine.map((item: any, index: any) => {
                let data: any = [];
                item.map((sub_item: any, sub_index: any) => {
                    data.push(sub_item.re_num)
                })
                let obj: any = {};
                obj.name = item[0].activity;
                obj.type = 'line';
                obj.data = data;
                obj.areaStyle = {type: 'default',color:operation_line_color[index]};//内容颜色
                obj.symbol = 'none'; // 拐点类型
                obj.smooth = true;
                obj.color = operation_line_color[index];
                legend.push(item[0].activity);
                yData.push(obj)
            })
            if(current_tabble == 1) {
                this.setState({
                    operation_line_color_content: yData,
                    xLine: res.data.xLine,
                    legend: legend,
                })
            } else {
                this.setState({
                    operation_line_color_content_active: yData,
                    xLine_active: res.data.xLine,
                    legend_active: legend,
                })
            }
		} else {
			message.error(res.message)
		}
    }

	//时间处理函数
    formatDate = (type: number) => {
        let date = new Date();
        let year:any = '';
        let month:any = '';
        let day:any = '';
        if(type === 1) {
            date.setTime(date.getTime()-24*60*60*1000);
            year = date.getFullYear();
            month = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : '0' + (date.getMonth() + 1);
            day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();

        } else if(type === 7) {
            date.setTime(date.getTime()-((24*60*60*1000) * 6));
            year = date.getFullYear();
            month = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : '0' + (date.getMonth() + 1);
            day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();

        } else {
            year = date.getFullYear();
            month = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : '0' + (date.getMonth() + 1);
            day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();
        }

        return year + '-' + month + '-' + day
    }

    //选择时间
    onChangeTime = async (start_time: any, end_time: any) => {
		console.log(start_time, end_time)
		let params = {
			start_at: start_time,
            end_at: end_time
		}
		if(!start_time) {
			params.start_at = this.formatDate(0)
            params.end_at = this.formatDate(0)
		}
		let res:any = await getRequest(apiUrl.rePurchaseIndex, params);
		if(res.code == 1) {
			let top_icon_list: any = this.state.top_icon_list;
			let { totalUser, newUser, vipUser, newVip, singlePrice, priceTrend, rechargeAmount, presentAmount } = res.data;
			this.setState({
				top_icon_list: res.data.list
			})
		} else {
			message.error(res.message)
		}
	}

	//推荐分组切换
	onGroupRecommend = (item: any, index: any) =>{
		let recommend_icon_list: any = this.state.recommend_icon_list;
		recommend_icon_list.map((sub_item: any, sub_index: any) => {
			if(item.id == sub_item.id) {
				recommend_icon_list[index]['active'] = true;
			} else {
				recommend_icon_list[sub_index]['active'] = false;
			}
		})
		this.setState({
			recommend_icon_idx: item.id,
			recommend_icon_list
		})

	}

	handleChange = (value: any) => {
        this._initDate(Number(value))
	}

	getTimeData = (type: "lastqiday" | "today" | "yesterday" | 'beforeYesterday' | 'beforeMonth' | 'beforeWeek' | 'currentMonth' | 'own' | 'quarter' | 'lastQuarter' | 'ownMonth') => {
        switch (type) {
            case 'lastqiday':
                console.log(this.getDay(-7));
                let toDayDate = `${nowDate.getFullYear()}-${this.formatData(nowDate.getMonth() + 1)}-${this.formatData(nowDate.getDate())}`;
                this.setState({
                    start_at: this.getDay(-7),
                    end_at: toDayDate,
                }, () => {
                    this._initDate(2);
                })
                break;
            case 'currentMonth':
                let currMonthStart = `${nowDate.getFullYear()}-${this.formatData(nowDate.getMonth() + 1)}-01`;
                let currMonthEnd = `${nowDate.getFullYear()}-${this.formatData(nowDate.getMonth() + 1)}-${this.formatData(nowDate.getDate())}`;
                this.setState({
                    start_at: currMonthStart,
                    end_at: currMonthEnd,
                }, () => {
                    this._initDate(2);
                })
                break;
            case "beforeWeek":
                var d = new Date();
                var end = d.getTime() - 1000 * 60 * 60 * 24 * d.getDay();
                let start = d.getTime() - 1000 * 60 * 60 * 24 * (d.getDay() + 6)
                let startDay = new Date(start).getFullYear() + '-' + this.formatData(new Date(start).getMonth() + 1) + '-' + this.formatData(new Date(start).getDate());
                let endDay = new Date(end).getFullYear() + '-' + this.formatData(new Date(end).getMonth() + 1) + '-' + this.formatData(new Date(end).getDate());

                this.setState({
                    start_at: startDay,
                    end_at: endDay
                }, () => {
                    this._initDate(2);
                })
                break;
            case "beforeMonth":
                let beforeMonthStart = nowDate.getFullYear() + '-' + this.formatData(nowDate.getMonth()) + '-01';
                let currentMonth = nowDate.getFullYear() + '-' + this.formatData(nowDate.getMonth() + 1) + '-01'
                let num = new Date(new Date(currentMonth).getTime() - 24 * 60 * 60 * 1000);
                let beforeMonthEnd = `${num.getFullYear()}-${this.formatData(num.getMonth() + 1)}-${this.formatData(num.getDate())}`;
                beforeMonthStart = `${num.getFullYear()}-${this.formatData(num.getMonth() + 1)}-01`
                this.setState({
                    start_at: beforeMonthStart,
                    end_at: beforeMonthEnd
                }, () => {
                    this._initDate(2);
                })
                break;
            case "beforeYesterday":
                let beforeYesDay = `${beforeYesterday.getFullYear()}-${this.formatData(beforeYesterday.getMonth() + 1)}-${this.formatData(beforeYesterday.getDate())}`;
                this.setState({
                    start_at: beforeYesDay,
                    end_at: beforeYesDay
                }, () => {
                    this._initDate(2);
                })
                break;
            case "yesterday":
                let yesDay = `${yesterday.getFullYear()}-${this.formatData(yesterday.getMonth() + 1)}-${this.formatData(yesterday.getDate())}`;
                this.setState({
                    start_at: yesDay,
                    end_at: yesDay
                }, () => {
                    this._initDate(2);
                })
                break;

            case "today":
                let toDay = `${nowDate.getFullYear()}-${this.formatData(nowDate.getMonth() + 1)}-${this.formatData(nowDate.getDate())}`;
                this.setState({
                    start_at: toDay,
                    end_at: toDay,
                }, () => {
                    this._initDate(2);
				})
				break;
			case "own":
				let own = `${nowDate.getFullYear()}-${this.formatData(nowDate.getMonth() + 1)}-${this.formatData(nowDate.getDate())}`;
				this.setState({
					start_at: '1970-01-01',
					end_at: own,
				}, () => {
                    this._initDate(2);
				})
				break;
			case "quarter":
				this.setState({
					start_at: this.getQuarterStartDate(),
					end_at: this.getQuarterEndDate(),
				}, () => {
                    this._initDate(2);
				})
				break;
			case "lastQuarter":
				this.setState({
					start_at: this.getLastQuarterStartDate(),
					end_at: this.getLastQuarterEndDate(),
				}, () => {
                    this._initDate(2);
				})
				break;
			case "ownMonth":
				this.setState({
					start_at: this.getLastMonth().last,
					end_at: this.getLastMonth().now,
				}, () => {
                    this._initDate(2);
				})
				break;
        }
	}

	//近一个月
	getLastMonth() {
		var now:any = new Date();
		var year = now.getFullYear();
		var month = now.getMonth() + 1;//0-11表示1-12月
		var day = now.getDate();
		var dateObj: any = {};
		dateObj.now = year + '-' + month + '-' + day;
		var nowMonthDay = new Date(year, month, 0).getDate();    //当前月的总天数
		if(month - 1 <= 0){ //如果是1月，年数往前推一年<br>　　　　
			dateObj.last = (year - 1) + '-' + 12 + '-' + day;
		}else{
			var lastMonthDay = new Date(year, (parseInt(month) - 1), 0).getDate();
			if(lastMonthDay < day){    //1个月前所在月的总天数小于现在的天日期
				if(day < nowMonthDay){        //当前天日期小于当前月总天数
					dateObj.last = year + '-' + (month - 1) + '-' + (lastMonthDay - (nowMonthDay - day));
				}else{
					dateObj.last = year + '-' + (month - 1) + '-' + lastMonthDay;
				}
			}else{
				dateObj.last = year + '-' + (month - 1) + '-' + day;
			}
		}
		return dateObj;
    }

    //获取最近一年的，年月
    onNearlyAYear() {
        let nearly_year: any = [];
        let now: any = new Date();
        let year: any = now.getFullYear();
        let month = now.getMonth() + 1;//0-11表示1-12月
        for(let i = 0; i <= 11; i++) {
            if(month == 12) {
                nearly_year.push(`${year}年${this.formatData(12 - i)}月`)
            } else {
                if(month - i > 0) {
                    nearly_year.push(`${year}年${this.formatData(month - i)}月`)
                } else {
                    let upYear: any = year - 1;
                    nearly_year.push(`${upYear}年${this.formatData(i - month == 0 ? 12 : i - month)}月`)
                }

            }
        }
        this.setState({
            nearly_year
        })
    }

	//获得本季度的开始月份
	getQuarterStartMonth() {
		var quarterStartMonth = 0;
		if (nowMonth < 3) {
			quarterStartMonth = 0;
		}
		if (2 < nowMonth && nowMonth < 6) {
			quarterStartMonth = 3;
		}
		if (5 < nowMonth && nowMonth < 9) {
			quarterStartMonth = 6;
		}
		if (nowMonth > 8) {
			quarterStartMonth = 9;
		}
		return quarterStartMonth;
	}

	//获得上季度的开始月份
	getLastQuarterStartMonth() {
		var quarterStartMonth = 0;
		if (nowMonth < 3) {
			quarterStartMonth = 0;
		}
		if (2 < nowMonth && nowMonth < 6) {
			quarterStartMonth = 3;
		}
		if (5 < nowMonth && nowMonth < 9) {
			quarterStartMonth = 6;
		}
		if (nowMonth > 8) {
			quarterStartMonth = 9;
		}
		return quarterStartMonth;
	}

	//获得上季度季度的开始日期
	getLastQuarterStartDate() {
		var quarterStartDate = new Date(nowYear, this.getLastQuarterStartMonth() - 2, 1);
		return this.newFormatDate(quarterStartDate);
	}

	//获得上季度的结束日期
	getLastQuarterEndDate() {
		var quarterEndMonth = this.getLastQuarterStartMonth();
		var quarterStartDate = new Date(nowYear, quarterEndMonth,this.getMonthDays(quarterEndMonth));
		return this.newFormatDate(quarterStartDate);
	}

	//获得本季度的开始日期
	getQuarterStartDate() {
		var quarterStartDate = new Date(nowYear, this.getQuarterStartMonth(), 1);
		return this.newFormatDate(quarterStartDate);
	}

	//或的本季度的结束日期
	getQuarterEndDate() {
		var quarterEndMonth = this.getQuarterStartMonth() + 2;
		var quarterStartDate = new Date(nowYear, quarterEndMonth,this.getMonthDays(quarterEndMonth));
		return this.newFormatDate(quarterStartDate);
	}

	//获得某月的天数
	getMonthDays(myMonth: any) {
		var monthStartDate: any = new Date(nowYear, myMonth, 1);
		var monthEndDate: any = new Date(nowYear, myMonth + 1, 1);
		var days = (monthEndDate - monthStartDate) / (1000 * 60 * 60 * 24);
		return days;
	}

	newFormatDate(date: any) {
		var myyear = date.getFullYear();
		var mymonth = date.getMonth() + 1;
		var myweekday = date.getDate();
		if (mymonth < 10) {
			mymonth = "0" + mymonth;
		}
		if (myweekday < 10) {
			myweekday = "0" + myweekday;
		}
		return (myyear + "-" + mymonth + "-" + myweekday);
	}

    formatData = (num: number) => {
      return num > 9 ? num : `0${num}`
    }

    navCallBack(currentKey: string){
        console.log(currentKey)
        let { line_date_obj } = this.state;
        if(currentKey == 'USER_LIST') {
            this.setState({
                current_tabble: 2
            }, () => {
                this.onRePurchaseChart(line_date_obj)
            })
        } else {
            this.setState({
                current_tabble: 1
            }, () => {
                this.onRePurchaseChart(line_date_obj)
            })
        }
    }

    handleChangeLineDate = (value: any) => {
        let date_obj: any = (value.replace(/([^\u0000-\u00FF])/g, '-')) + '01';
        date_obj = {
            start_at: date_obj,
            end_at: date_obj
        }
        this.setState({
            line_date_obj: date_obj
        },() => {
            this.onRePurchaseChart(date_obj);
        })
    }

    /**
     *
     * @param day 获取多少天前：-7近七天
     */
    getDay(day: any){

     　　var today = new Date();

     　　var targetday_milliseconds=today.getTime() + 1000*60*60*24*day;

     　　today.setTime(targetday_milliseconds); //注意，这行是关键代码

     　　var tYear = today.getFullYear();

     　　var tMonth = today.getMonth();

        var tDate = today.getDate();

     　　tMonth = this.doHandleMonth(tMonth + 1);

     　　tDate = this.doHandleMonth(tDate);

 　　    return tYear+"-"+tMonth+"-"+tDate;

    }

    doHandleMonth(month: any){

        　　var m = month;

        　　if(month.toString().length == 1){

        　　　　m = "0" + month;

        　　}

        　　return m;

    }

    render() {

		let { progress_loading, own_all_data, nearly_year, activity_list, month_info, current_tabble, legend, legend_active, operation_line_color, operation_line_color_active, xLine, xLine_active, navList, operation_line_color_content, operation_line_color_content_active, currentTitleIdx, singlePrice, rechargeAmount, recommend_icon_idx, top_icon_list, recommend_icon_list } = this.state;

        return (
            <Row className={styles.deepOperation}>
                <Col className={styles.deepOperation_userdate}>用户数据</Col>
                <Col className={styles.deepOperation_select}>
                    <div className={styles.deepOperation_select_left}>
                    <div className={`${styles.deepOperation_select_left_near} ${currentTitleIdx == 1 ? styles.user_ifon_active : ''}`} onClick={() => {this.getTimeData("lastqiday");this.setState({currentTitleIdx: 1})}}>近7天</div>
                    <div className={`${styles.deepOperation_select_left_near} ${currentTitleIdx == 2 ? styles.user_ifon_active : ''}`} onClick={() => {this.getTimeData("ownMonth");this.setState({currentTitleIdx: 2})}}>近30天</div>
						<div className={`${styles.deepOperation_select_left_near} ${currentTitleIdx == 3 ? styles.user_ifon_active : ''}`} onClick={() => {this.getTimeData("currentMonth");this.setState({currentTitleIdx: 3})}}>本月</div>
						<div className={`${styles.deepOperation_select_left_near} ${currentTitleIdx == 4 ? styles.user_ifon_active : ''}`} onClick={() => {this.getTimeData("beforeMonth");this.setState({currentTitleIdx: 4})}}>上月</div>
						<div className={`${styles.deepOperation_select_left_near} ${currentTitleIdx == 5 ? styles.user_ifon_active : ''}`} onClick={() => {this.getTimeData('quarter');this.setState({currentTitleIdx: 5})}}>本季度</div>
						<div className={`${styles.deepOperation_select_left_near} ${currentTitleIdx == 6 ? styles.user_ifon_active : ''}`} onClick={() => {this.getTimeData('own');this.setState({currentTitleIdx: 6})}}>累计</div>
                        <div className={styles.deepOperation_select_left_selecttime}>
                            <RangePicker
                                format={`YYYY-MM-DD`}
                                showTime={false}
                                onChange={(e:any, dateString) => {
                                    this.onChangeTime(dateString[0], dateString[1]);
                                }}
                                placeholder={['开始时间', '结束时间']} />
                        </div>
                    </div>
                </Col>
                <Col className={styles.deepOperation_panel}>
					<div className={styles.deepOperation_panel_topLeft}>
                        {
                            top_icon_list.map((item: any, index: any) => {
                                return (
                                    <div key={item + index} className={styles.deepOperation_panel_item}>
                                        <div className={styles.deepOperation_panel_item_top}>
                                            <div className={styles.deepOperation_panel_item_top_left}>
                                                <div className={styles.deepOperation_panel_item_top_left_user}>{item.title}</div>
                                                <div className={styles.deepOperation_panel_item_top_left_num}>{item.title == '复购率' ?  `${item.rate}%` : `${item.rate}`}</div>
                                            </div>
                                            <div className={styles.deepOperation_panel_item_top_right}><img src={item.icon} className={styles.deepOperation_panel_item_top_right_img} /></div>
                                        </div>
                                        <div className={styles.deepOperation_panel_item_bottom}>
                                            <span className={styles.deepOperation_panel_item_bottom_txt}></span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {/* 复购顾客 */}
                    <div className={styles.deepOperation_panel_topcontent}>
                        <div className={styles.deepOperation_panel_topcontent_item}>
                            <div className={styles.deepOperation_panel_topright_item_title}>
                                复购顾客
                            </div>
                            <div className={styles.deepOperation_panel_topright_item_progress}>
                                { progress_loading && <ArcProgress type={1} target={own_all_data.pull_rate} value={own_all_data.pull_new_count}></ArcProgress>}
                            </div>
                        </div>
                    </div>
                    {/* 复购活动配置 */}
                    <div className={styles.deepOperation_panel_topright}>
                        <div className={styles.deepOperation_panel_topright_item}>
                            <div className={styles.deepOperation_panel_topright_item_title}>
                                复购活动配置
                            </div>
                            <div className={styles.deepOperation_panel_topright_item_main}>
                                {
                                    activity_list.map((item: any, index: any) => {
                                        return (
                                            <div className={styles.deepOperation_panel_topright_item_main_list} key={item.from_type + index}>
                                                <div className={styles.deepOperation_panel_topright_item_main_list_left}>
                                                    <div className={styles.deepOperation_panel_topright_item_main_list_left_title}>{item.activity}</div>
                                                    <div className={styles.deepOperation_panel_topright_item_main_list_left_desc}>{item.desc}</div>
                                                </div>
                                                <div className={styles.deepOperation_panel_topright_item_main_list_right} onClick={() => {this.props.history.push(`${item.path}`)}}>
                                                    <img src={system} className={styles.deepOperation_panel_topright_item_main_list_right_img}></img>
                                                    <div className={styles.deepOperation_panel_topright_item_main_list_right_system}>进入设置</div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </Col>
                {/* 折线图 */}
                <Col className={styles.deepOperation_lineChart}>
                    <div className={styles.deepOperation_lineChart_tabbar}>
                        <InsideNav navList={navList} callBack={(key: string) =>this.navCallBack(key)}/>
                    </div>
                    <div className={styles.deepOperation_lineChart_activeTitle}>
                        <div>
                            {
                                current_tabble == 1
                                ?   '复购活动效果对比'
                                :   '月复购人数'
                            }
                        </div>
                        {
                            nearly_year.length > 0
                            ?   <Select defaultValue={nearly_year[0]} style={{width:130}} onChange={(e: any) => {this.handleChangeLineDate(e)}}>
                                    {
                                        nearly_year.map((item: any, index: any) => {
                                            return (
                                            <Option value={item} key={item + index}>{item}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            : null
                        }
                    </div>
                    {
                        current_tabble == 1
                        ?   <LineChart legendList={legend} xLine={xLine} color3={operation_line_color} data={operation_line_color_content}></LineChart>
                        :   <LineChartActive legendList={legend_active} xLine={xLine_active} color3={operation_line_color} data={operation_line_color_content_active}></LineChartActive>
                    }
                </Col>
				<Col className={styles.deepOperation_customGroup}>
                    {
                        this.state.start_at
                        ?   <ComsumeBehavior
                                own_date={
                                    {
                                        start_at: this.state.start_at,
                                        end_at: this.state.end_at
                                    }
                                } user={month_info.user} current={current_tabble} type_title={current_tabble == 1 ? '活动效果占比' : '复购用户占比'} history={this.props.history}/>
                        :   null
                    }
				</Col>
            </Row>
        )
    }

}

export default RepurchaseOperation