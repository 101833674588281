import React from "react";
import "./taskManage.scss"
import {Button, DatePicker, Select, Pagination, Input, Empty, Modal} from "antd";
import TaskSinger from "./components/taskSinger/taskSinger";
import {Link, RouteComponentProps} from "react-router-dom";
import {getRequest} from "../../../api/http";
import {apiUrl} from "../../../api/api_url";
import moment from "moment";
const { Option } = Select;
const { RangePicker } = DatePicker;
const {Search} = Input;

interface IProps extends RouteComponentProps{

}

class TaskManage extends React.Component<IProps> {
    page_size = 8;
    param = {
        status: 0,            // 活动状态 正常-0，进行中-1，暂停中-2，已结束-3，即将开始-4，
        keywords: '',         // 关键字
        start_time: 0,        // 活动开始时间
        end_time: 0,           // 活动结束时间
        mp_id: 0              // 公众号——id
    };
    state = {
      tasks: [],
      accounts: [],
      count: 0,
      page: 1,
      visible: false,
    };

    componentDidMount(): void {
        this.requestAuthAccount();
        this.requestTaskLists();
    }

    updateList =()=>{
        this.setState({
            page: 1
        },() =>{
            this.requestTaskLists();
        });
    };

    // 获取 认证公众号列表
    requestAuthAccount = async () =>{
        const res: any = await getRequest(apiUrl.authAccountList,{page: 1,page_size: 30});
        if(res.code === 1){
            let list = res.data.list;
            if(list.length > 0){
                this.setState({
                    accounts: list
                });
            }
        }else{
            console.log(res.message)
        }
    };

    onShowSizeChange = (page: number) =>{
        this.setState({
            page: page
        },() =>{
            this.requestTaskLists();
        });
    };

    // 获取 任务列表
    requestTaskLists = async () =>{
        const {status,keywords,start_time,end_time,mp_id} = this.param;
        const {page_size} = this;
        let param:any = {
            page: this.state.page,
            page_size: page_size,
            status: status,
            mp_id: mp_id
        };
        if(keywords !== ''){
            param.keywords = keywords;
        }
        if(start_time !== 0){
            param.start_time = moment(start_time).valueOf()/1000;
            param.end_time = moment(end_time).valueOf()/1000;
        }
        const res:any = await getRequest(apiUrl.taskList,param);
        if(res.code === 1){
            this.setState({
                tasks: res.data.list,
                count: res.data.count
            })
        }
    };

    // 监听公众号 变化
    handleChangeAccount =(value:string)=>{
        this.param.mp_id = parseInt(value);
        this.setState({
            page: 1
        },() =>{
            this.requestTaskLists();
        });
    };

    // 监听活动类型 变化
    handleActivityType =(value:string)=>{
        this.param.status = parseInt(value);
        this.setState({
            page: 1
        },() =>{
            this.requestTaskLists();
        });
    };

    // 监听活动名称 变化
    handleChangeName =(value:any)=>{
        // if(value.trim() === '') return;
        this.param.keywords = value;
        this.setState({
            page: 1
        },() =>{
            this.requestTaskLists();
        });
    };

    // 监听时间 变化
    handleChangeDate =(time:any,timeString:[string,string])=>{
        this.param.start_time = moment(timeString[0]).valueOf();
        this.param.end_time = moment(timeString[1]).valueOf();
        this.setState({
            page: 1
        },() =>{
            this.requestTaskLists();
        });
    };

    // 新建任务
    createNewTask = () =>{
        let {accounts} = this.state;
        if(accounts.length > 0){
            this.props.history.push({pathname: '/sidebar/newTask'});
        }else{
            this.setState({visible: true})
        }
    };

    // 创建 弹窗
    createModel =() =>{
        let {visible} = this.state;
      return (
          <Modal
              visible={visible}
              title="提示"
              closable={true}
              width={400}
              onCancel={() =>{this.setState({visible: false})}}
              footer={null}
              centered={true}
          >
              <div className="model_body">
                  <div>新建任务前，请先授权微信公众号</div>
                  <Link to='/sidebar/accountAuthView'>
                      <Button type='primary' className=''>去授权</Button>
                  </Link>
              </div>
          </Modal>
      )
    };

    render(){
        const {tasks,accounts,count,page} = this.state;
        // 空状态
        const emptyStatus = <div className="empty_view">
            <div className='empty_tips'>你还没创建过活动</div>
            <Button type="primary">新建活动</Button>
        </div>;
        if(tasks.length < 0) return  emptyStatus;

        return <div className="task_body l_content">
            <Button type="primary" icon="plus" onClick={this.createNewTask}>新建任务</Button>
            <div className="select_view">
                <div className='select_item'>
                    <div className='select_title'>公众号：</div>
                    <Select style={{ width: 120 }} defaultValue="0" onChange={this.handleChangeAccount}>
                        <Option value="0" key={-1} >全部</Option>
                        {
                            accounts.map((item:any,index) =>
                                <Option value={item.id} key={index}>{item.nick_name}</Option>
                            )
                        }
                    </Select>
                </div>
                <div className='select_item'>
                    <div className='select_title'>任务状态：</div>
                    <Select defaultValue="正常" style={{ width: 120 }} onChange={this.handleActivityType}>
                        <Option value="0">正常</Option>
                        <Option value="1">进行中</Option>
                        <Option value="2">暂停</Option>
                        <Option value="3">已结束</Option>
                        <Option value="4">即将开始</Option>
                    </Select>
                </div>
                <div className='select_item'>
                    <div className='select_title'>任务名称：</div>
                    <Search placeholder="活动名称" onSearch={(value:string) => this.handleChangeName(value)} />
                </div>
                <div className='select_item'>
                    <div className='select_title'>起止时间：</div>
                    <RangePicker size='default' placeholder={["开始时间","结束时间"]} onChange={this.handleChangeDate} />
                </div>
            </div>
            <div className='task_list_view'>
                {
                    tasks.length === 0 ?
                        <div className='empty_view_task'>
                            <Empty description='暂无数据' />
                        </div>
                        :
                    tasks.map((item,index) =><TaskSinger callbackParent={this.updateList} key={index} taskItem={item} history={this.props.history}/>)
                }
            </div>
            <div className="page_view"><Pagination defaultCurrent={1} current={page} total={count} pageSize={this.page_size} onChange={this.onShowSizeChange}/></div>
            {this.createModel()}
        </div>;
    }
}

export default TaskManage;