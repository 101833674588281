import React, { useState, useEffect } from 'react'
import { Row, Col ,Input, Radio, Button, message} from 'antd';

import './addStoreUser.scss'
import { postRequest } from '../../../api/http';
import { apiUrl } from '../../../api/api_url';
import { useHistory } from 'react-router-dom';

type IProps = {

}

const AddStoreUser = (props:IProps) =>{

    const history:any = useHistory();

    const [userStatus,setUserStatus] = useState(1);
    const [userName,setUserName] = useState('');
    const [phoneNumber,setPhoneNumber] = useState('');

    // 相当于 componentDidMount 和 componentDidUpdate:
    useEffect(() =>{
       
     },[]);

     // 监听手机号
    const handlePhoneInput =(e:any) =>{
        setPhoneNumber(e.target.value);
    }
    // 监听用户名称
    const handleUserNameInput =(e:any) =>{
        setUserName(e.target.value);
    }

    // 保存添加
    const submitSaveStoreUser = async () =>{
        let params:any = {
            nickname: userName,
            phone_number: phoneNumber,
            status: userStatus,
        }
        const response:any = await postRequest(apiUrl.employeeSave,params);
        if(response.code === 1){
            message.success('保存成功');
            history.goBack();
        }else{
            message.error('保存失败'+response.message);
        }

    }
     return (
         <div className="addUser_page">
             <h3>添加用户</h3>
             <Row>
                 <Col span={3}>*商家端用户姓名</Col>
                 <Col span={4}><Input placeholder="输入真实姓名" onChange={(e) => handleUserNameInput(e)} /></Col>
             </Row>
             <Row>
                 <Col span={3}>*用户登陆手机号</Col>
                 <Col span={4}><Input placeholder="输入登陆手机号" onChange={(e) => handlePhoneInput(e)}/></Col>
             </Row>
             <Row>
                 <Col span={3}>*用户状态</Col>
                 <Col span={6}>
                 <Radio.Group onChange={(e:any) => {setUserStatus(e.target.value)}} value={userStatus}>
                    <Radio value={1}>正常</Radio>
                    <Radio value={2}>冻结</Radio>
                </Radio.Group>
                 </Col>
             </Row>
             <Row style={{marginTop: 100}}>
                 <Col span={2} offset={2}>
                     <Button type="default" onClick={() => {history.goBack()}}>取消</Button>
                 </Col>
                 <Col span={2}>
                     <Button type="primary" onClick={() => submitSaveStoreUser()}>保存</Button>
                 </Col>
             </Row>
         </div>
     );
}

export default AddStoreUser;