import React, { useEffect, useState } from 'react'
import './memberDetail.scss'
import { Row, Avatar, message, Descriptions,Tag } from 'antd';
import { getRequest } from '../../../api/http';
import { apiUrl } from '../../../api/api_url';
import { useLocation, useHistory } from 'react-router-dom';

const MemberDetail = (props:any)=>{

    const location:any = useLocation()
    const history = useHistory()
    const [ info, setInfo] = useState<any>({})

    useEffect(() =>{
        getMemberDetail();
    },[]);

    const getMemberDetail = async()=>{
        if(!location.state||!location.state.id){
            history.goBack()
            return false
        }
        const res: any = await getRequest(apiUrl.getFansDetail, {id: location.state.id});
        if (res.code === 1) {
            console.log(res)
            setInfo(res.data)
        }else{
            message.success(res.message||"信息获取失败");
        }
    }

    return(
        <div className="member_detail l_content">
            <div className="title_text">用户详情</div>
            <Row type="flex" align="middle">
                <Avatar className="mr20" size="large" src={info.headimgurl}/>
                <span className="mr20">{info.nickname}</span>
                {
                    info.group_name&&info.group_name.map((item:any,index:number)=>{
                        return <Tag color="#628DFF" className="tag mr20">{item}</Tag>
                    })
                }
            </Row>
            <Descriptions title="基本信息" bordered className="mt20">
                <Descriptions.Item label="用户等级">{info.level}</Descriptions.Item>
                <Descriptions.Item label="手机号">{info.phone_number}</Descriptions.Item>
                <Descriptions.Item label="注册时间">{info.create_time}</Descriptions.Item>
                <Descriptions.Item label="微信号">{info.wx_id}</Descriptions.Item>
                <Descriptions.Item label="地区">{info.province}{info.city}</Descriptions.Item>
                <Descriptions.Item label="生日">{info.birthday} </Descriptions.Item>
                <Descriptions.Item label="性别">{info.sex==0?'未知':info.sex==1?'男':'女'}</Descriptions.Item>
                <Descriptions.Item label="备注">{info.remark_name}</Descriptions.Item>
                <Descriptions.Item label="--">--</Descriptions.Item>
            </Descriptions>
            <Descriptions title="资产信息" bordered className="mt20">
                <Descriptions.Item label="储值余额">{info.balance}</Descriptions.Item>
                <Descriptions.Item label="充值次数">{info.recharge_num}</Descriptions.Item>
                <Descriptions.Item label="累计充值金额">{info.recharge_money}</Descriptions.Item>
                <Descriptions.Item label="积分">{info.score}</Descriptions.Item>
                <Descriptions.Item label="--">--</Descriptions.Item>
                <Descriptions.Item label="--">--</Descriptions.Item>
            </Descriptions>
            <Descriptions title="交易统计" bordered className="mt20">
                <Descriptions.Item label="累计消费金额">{info.comsumer_money}</Descriptions.Item>
                <Descriptions.Item label="累计消费订单数">{info.comsumer_num}</Descriptions.Item>
                <Descriptions.Item label="最近就餐时间">{info.last_comsumer_time}</Descriptions.Item>
                <Descriptions.Item label="就餐次数">{info.eat_times}</Descriptions.Item>
                <Descriptions.Item label="用券次数">{info.user_coupon_times}</Descriptions.Item>
                <Descriptions.Item label="总优惠金额">{info.user_coupon_price}</Descriptions.Item>
            </Descriptions>
            <Descriptions title="推荐奖励" bordered className="mt20">
                <Descriptions.Item label="成功邀请人数">{info.invite_num}</Descriptions.Item>
                <Descriptions.Item label="邀请金额">{info.invite_money}</Descriptions.Item>
                <Descriptions.Item label="邀请人">{info.invited_fan&&info.invited_fan.nickname}</Descriptions.Item>
            </Descriptions>
        </div>
    )

}

export default MemberDetail