import React, { Fragment, useState, useEffect } from "react";
import { Typography, Row, Input, Form, Button, Radio, Modal, Select, Col, Avatar, message } from "antd";
import { apiUrl } from "../../../api/api_url";
import { getRequest, postRequest } from "../../../api/http";
import './add_configuration.scss'
import { useHistory } from "react-router-dom";

const { Title } = Typography;
const { Option } = Select;

const layout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 }
};

const AddConfiguration = () => {
  const history:any = useHistory();
  const [isON, setisON] = useState(1);

  const takeEffect = (e: any) => setisON(e.target.value);
  const [showBindDishes, setshowBindDishes] = useState(false);
  const [curtList, setcurtList] = useState<any>();
  const [dishesList, setdishesList] = useState<any>();
  const [showSelectModal, setshowSelectModal] = useState<boolean>(false);
  const [currModalDishes, setcurrModalDishes] = useState<any>();
  const [userData, setuserData] = useState<{[key: string]: any}>({
    eat_users: '',
    need_users: '',
    count: '',
  });

  useEffect(() => {
    initData();
    getDishesList();
  }, [])

  const initData = () => {
    if(history.location.state && history.location.state.edit) {
      const { edit } = history.location.state;
      setuserData({
        eat_users: edit.eat_users,
        need_users: edit.need_users,
        count: edit.count
      });
      setcurrModalDishes(edit.dish);
      setisON(edit.status);
    }
  }

  const handleDishesTypeChange = (value: any) => {
    setcurtList(dishesList[value])
  }

  // 获取菜品列表
  const getDishesList = async () => {
    const res: any = await getRequest(apiUrl.dishesList);
    if (res && res.data) {
      const _pageData = res.data;
      Object.keys(_pageData).map(el => {
        Object.keys(_pageData[el]['dishes']).map(inEl => {
          _pageData[el]['dishes'][inEl]['count'] = 0;
          if (_pageData[el]['dishes'][inEl]['types']) {
            _pageData[el]['dishes'][inEl]['$_count'] = 1;
            Object.keys(_pageData[el]['dishes'][inEl]['types']).map((typeEl) => {
              _pageData[el]['dishes'][inEl]['types']['$_' + typeEl] = _pageData[el]['dishes'][inEl]['types'][typeEl][0]
              if (!_pageData[el]['dishes'][inEl]['types']['$_list']) {
                _pageData[el]['dishes'][inEl]['types']['$_list'] = [typeEl]
              } else {
                _pageData[el]['dishes'][inEl]['types']['$_list'].push(typeEl)
              }
            })
          }
        })
      })
      console.log('dishesList', _pageData)
      setcurtList(_pageData[Object.keys(_pageData)[0]]);
      setdishesList(_pageData);
    }
  }

  // 打开选择规格弹层
  const openTypesModal = (key: any) => {
    console.log('key', key)
    setshowSelectModal(true);
    if (curtList) {
      setcurrModalDishes(curtList['dishes'][key]);
    }
  }

  // 切换规格
  const changeSelectType = (e: any) => {
    console.log(e);
    const _currModalDishes = currModalDishes;
    _currModalDishes['types']['$_' + e.target['data-ty']] = e.target['data-tyEl'];
    setcurrModalDishes(_currModalDishes);
  }

  // 选择规格绑定
  const sureSelectType = () => {
    setshowSelectModal(false);
    setshowBindDishes(false);
    console.log('currModalDishes', currModalDishes)
  }

  // 修改就餐人数
  const changeUserData = (num: any, type: "eat_users" | "need_users" | "count") => {
    const _num = Number(num);
    if(_num && _num > 0) {
      setuserData({...userData, [type]: num})
    } else {
      message.info('请输入正确的数字！');
      setuserData({...userData, [type]: ''})
    }
  }

  // 保存配置
  const saveConfig = async () => {
    let right = true;
    const arr = Object.keys(userData);
    for (let i = 0; i < arr.length; i++) {
      if(!userData[arr[i]]) {
        // message.warning('填写错误');
        right = false;
        break;
      }
    }
    
    if(!right) {
      message.warning('填写错误');
      return;
    }

    if(!currModalDishes) {
      message.warning('请绑定菜品');
      return;
    }
    console.log('history', history);
      if(history.location.state && history.location.state.edit) {
        editSaveRequest();
      } else {
        addSave();
      }
  }

  const editSaveRequest = async () => {
    const { edit } = history.location.state;
    const _userData = JSON.parse(JSON.stringify(userData));
    delete _userData.count;
    let parms:any = {
      id: edit.id,
      update: {
        ..._userData,
        dish: {
          dish_id: currModalDishes['id'],
          standard: getCurrentDisheTypes()
        }
      },
      count: userData.count
    }
    if(history.location.state && history.location.state.edit) {
      parms['status'] = isON;
    }
    const res: any = await postRequest(apiUrl.dishesHelpConfigEdit, parms);
    if(res && res.data) {
      message.success('保存成功');
      history.goBack();
    }
  }
  
  // 获取当前菜品的规格
  const getCurrentDisheTypes = (): String => {
    let type = '';
    if(currModalDishes['types']) {
      Object.keys(currModalDishes['types']).map((item) => {
        if(!item.includes('$_')) {
          type += `$_${item}`
        }
      })
      type = currModalDishes['types'][type]
    }
    return type;
  }

  const addSave = async () => {
    

    let parms:any = {
      ...userData,
      count: userData.count,
      dish: {
        dish_id: currModalDishes['id'],
        standard: getCurrentDisheTypes()
      }
    }
    const res: any = await postRequest(apiUrl.dishesHelp, parms);
    if(res && res.data) {
      message.success('保存成功');
      history.goBack();
    }
  }

  // 绑定没有规格的菜品
  const bindCurrentDishes = (key: any) => {
    setcurrModalDishes(curtList["dishes"][key]);
    setshowBindDishes(false);
  }

  return (
    <Fragment>
      <Title level={4} style={{ marginBottom: 20 }}>
        {
          (history.location.state && history.location.state.edit) ? '编辑配置' : '添加配置'
        }
        
      </Title>
      <Row type="flex" style={{width: '100%'}}>
        <Form {...layout} layout="horizontal">
          <Form.Item label="用餐人数">
            <Input value={userData.eat_users ? userData.eat_users : ''} onChange={(e) => changeUserData(e.target.value, "eat_users")} />
          </Form.Item>
          <Form.Item label="需要助力人数">
            <Input value={userData.need_users ? userData.need_users : ''} onChange={(e) => changeUserData(e.target.value, "need_users")} />
          </Form.Item>
          <Form.Item label="可获得菜品：">
            <Row type="flex" align="middle" 
              onClick={() => setshowBindDishes(true)}
              style={{
                width: '500px', 
                border: currModalDishes ? '1px solid rgba(0,0,0, .2)' : 'none', 
                borderRadius: '10px',
                paddingLeft: 20,
              }}>
              {
                currModalDishes ? <Row type="flex" align="middle">
                  <Avatar size={64} src={currModalDishes.main_pic} style={{marginRight: 20}} />
                  <Row >
                    <Row type="flex">
                      {currModalDishes.name}
                      {currModalDishes.types ? currModalDishes['types']['$_list'].map((eType: any) => {
                        const xx = currModalDishes['types'][`$_${eType}`];
                        return <Row key={eType}>{xx}</Row>
                      }) : ''}
                    </Row>
                    <Row>原价： {currModalDishes['price']}</Row>
                  </Row>
                </Row>
                  : <Button icon="plus" onClick={() => setshowBindDishes(true)}>绑定菜品</Button>
              }
            </Row>
          </Form.Item>

          <Form.Item label="可获得份数">
            <Input value={userData.count ? userData.count : ''} onChange={(e) => changeUserData(e.target.value, "count")} />
          </Form.Item>

          <Form.Item label="配置状态">
            <Radio.Group onChange={takeEffect} value={isON}>
              <Radio value={1}>生效</Radio>
              <Radio value={2}>不生效</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Row>

      <Row type="flex" justify="center">
        <Button >取消</Button>
        <Button type="primary" style={{marginLeft: 40}} onClick={() => saveConfig()}>保存</Button>
      </Row>

      <Modal
        title="Basic Modal"
        visible={showBindDishes}
        footer={null}
        // onOk={}
        onCancel={() => setshowBindDishes(false)}
      >
        <Row type="flex" justify="end">
          {
            dishesList && <Select
              defaultValue={dishesList[Object.keys(dishesList)[0]]['name']}
              style={{ width: 120 }}
              onChange={handleDishesTypeChange}>
              {
                Object.keys(dishesList).map((el) => {
                  return <Option value={el} key={dishesList[el]['name']}>{dishesList[el]['name']}</Option>
                })
              }
            </Select>
          }
        </Row>
        <Row className="table_box">
          <Col span={8} className="title_item">菜品名称</Col>
          <Col span={8} className="title_item">价格</Col>
          <Col span={8} className="title_item">操作</Col>
        </Row>

        {
          curtList && Object.keys(curtList['dishes']).map((key) => {
            return <Row key={curtList["dishes"][key]["name"]} style={{ height: 40, lineHeight: '40px' }}>
              <Col span={8}>{curtList["dishes"][key]["name"]}</Col>
              <Col span={8}>{curtList["dishes"][key]["price"]}</Col>
              <Col span={8}>
                {
                  curtList["dishes"][key]["types"]
                    ? <Button size="small" onClick={() => openTypesModal(key)}>选规格</Button>
                    : <Button size="small" onClick={() => bindCurrentDishes(key)}>绑定</Button>
                }
              </Col>
            </Row>
          })
        }

      </Modal>

      {
        currModalDishes && showSelectModal && <Modal
          title={currModalDishes['name']}
          visible={showSelectModal}
          onCancel={() => setshowSelectModal(false)}
          onOk={() => sureSelectType()}
        >
          {
            currModalDishes['types'] && Object.keys(currModalDishes['types']).map(ty => {
              return ty.includes('$_') ? <Row key={ty}></Row>
                : <Row>
                  <Row>{ty}</Row>
                  <Row>
                    <Radio.Group
                      onChange={(e) => changeSelectType(e)}
                      name="radiogroup"
                      defaultValue={currModalDishes['types']['$_' + ty]}>
                      {
                        currModalDishes['types'][ty].map((tyEl: any) => {
                          return <Radio value={tyEl} data-ty={ty} data-tyEl={tyEl}>{tyEl}</Radio>
                        })
                      }
                    </Radio.Group>
                  </Row>
                </Row>
            })
          }
        </Modal>
      }


    </Fragment>
  );
};

export default AddConfiguration;
