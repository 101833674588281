import React from "react";
import {Button, Input, message, Row, Col, Radio, DatePicker, Modal, Tree, Icon, Tooltip, Table, Checkbox, TimePicker } from "antd";
import { RangePickerValue } from "antd/lib/date-picker/interface";
import BraftEditor from 'braft-editor'
// 引入编辑器样式
import 'braft-editor/dist/index.css'

import { getRequest, postRequest } from '../../api/http';
import { apiUrl } from '../../api/api_url';
import moment from 'moment';
const { RangePicker } = DatePicker;
const { TreeNode } = Tree;

const key = 'CouponAdd';

class CouponAdd extends React.Component<any> {
    state = {
        loading: false,
        editorState: BraftEditor.createEditorState(null),
        type: 'voucher',
        name: '',
        validText: 'time',
        day: 0,
        custom: 0,
        start_time: '',
        end_time: '',
        amount: 0,
        limit_value: 1,
        position: 1,
        sort: 0,
        status: 2,
        can_trans: true,
        user_type: [],
        shop_type: 1,
        receive_type: 1,
        receive_limit: 0,
        is_other_coupon: 0, //0:不可以同时享用，1:可以享受其他所有优惠，2:可享受部分优惠，详询商家
        is_currency: 0, //全场通用
        is_currency_only: '',
        is_currency_nextDay: '',
        conditions: '',//使用条件
        termOfValidity: '', //有效期
        unavailableDate: '', //不可用日期
        availableStores: '', //可用门店
        shop_ids: [],
        weeks:[{
            name: '周一',
            value: 1
        },
        {
            name: '周二',
            value: 2
        },
        {
            name: '周三',
            value: 3
        },
        {
            name: '周四',
            value: 4
        },
        {
            name: '周五',
            value: 5
        },
        {
            name: '周六',
            value: 6
        },
        {
            name: '周日',
            value: 0
        }],
        weeks_string_list: [],
        week: [],
        festival_list: ['元旦','春节','清明节','劳动节','端午节','中秋节','国庆节'],
        festival: [
            {
                name: '法定节假日（元旦、春节、清明节、劳动节、端午节、中秋节、国庆节）',
                date: [{start: '2021-01-01', end:'2021-01-03', fix: false},
                {start: '2021-02-11', end:'2021-02-17', fix: false},
                {start: '2021-04-03', end:'2021-04-05', fix: false},
                {start: '2021-05-01', end:'2021-05-05', fix: false},
                {start: '2021-06-12', end:'2021-06-14', fix: false},
                {start: '2021-09-19', end: '2021-09-21', fix: false},
                {start: '2021-10-01', end: '2021-10-07', fix: false}
             ],
            },
            {
                name: '元旦',
                date: [{start: '2021-01-01', end:'2021-01-03', fix: false}]
            },
            {
                name: '春节',
                date: [{start: '2021-02-11', end:'2021-02-17', fix: false}]
            },
            {
                name: '元宵节',
                date: [{start: '2021-02-26', end:'2021-02-26', fix: true}]
            },
            {
                name: '情人节',
                date: [{start: '2021-02-14', end:'2021-02-14', fix: true}]
            },
            {
                name: '清明节',
                date: [{start: '2021-04-03', end:'2021-04-05', fix: false}]
            },
            {
                name: '劳动节',
                date: [{start: '2021-05-01', end:'2021-05-05', fix: false}]
            },
            {
                name: '母亲节',
                date: [{start: '2021-05-09', end:'2021-05-09', fix: true}]
            },
            {
                name: '儿童节',
                date: [{start: '2021-06-01', end:'2021-06-01', fix: true}]
            },
            {
                name: '父亲节',
                date: [{start: '2021-06-20', end:'2021-06-20', fix: true}]
            },
            {
                name: '端午节',
                date: [{start: '2021-06-12', end:'2021-06-14', fix: false}]
            },
            {
                name: '七夕节',
                date: [{start: '2021-08-14', end:'2021-08-14', fix: true}]
            },
            {
                name: '中秋节',
                date: [{start: '2021-09-19', end: '2021-09-21', fix: false}]
            },
            {
                name: '国庆节',
                date: [{start: '2021-10-01', end: '2021-10-07', fix: false}]
            },
            {
                name: '万圣节',
                date: [{start: '2021-10-31', end:'2021-10-31', fix: true}]
            },
            {
                name: '感恩节',
                date: [{start: '2021-11-25', end:'2021-11-25', fix: true}]
            },
            {
                name: '圣诞节',
                date: [{start: '2021-12-25', end:'2021-12-25', fix: true}]
            },
        ],
        dateList:[],
        allDate: true,

        money: 0,
        limit: false,
        discount: 0,

        visible: false,
        sortList: [],
        currentPage: 1,
        pageSize: 10,
        productList: [],
        productCount: 0,
        cat_id: 0,
        searchName: '',
        showProductList: [],
        favour: [],
        keyList: [],
        limit_part: 0,
        can_use_total_num: 0,
        edit: false,
        notice_day: 0,
        can_use_time: 0,
        valid_day: 0
    }

    componentDidMount () {
        const {location} = this.props;
        if(location.state !== undefined){
            this.initEdit(location.state.id);
            if(location.state.edit){
                this.setState({
                    edit: true
                })
            }
        }
        this._initList();
    }

    // 初始化编辑
    initEdit = async (id:number) => {
        const params = {
            id: id
        };
        const res:any = await postRequest(apiUrl.couponInfo,params);
        if(res.code === 1){
            this.setState({
                name: res.data.name,
                type: res.data.info.type,
                discount: res.data.info.favour,
                stock: res.data.stock,
                amount: res.data.amount,
                limit_value: res.data.limit_value,
                user_type: res.data.user_type,
                notice_day: res.data.notice_day,
            });
            if(res.data.info.limit !== null){
                this.setState({
                    limit: true,
                    money: res.data.info.limit.money,
                });
            }

            if(res.data.can_use_time){
                this.setState({
                    valid_day: res.data.expire_info.day
                })
            }else{
                if(res.data.expire_info.type === 'day'){
                    this.setState({
                        validText: res.data.expire_info.type,
                        day: res.data.expire_info.day
                    });
                }else{
                    this.setState({
                        validText: res.data.expire_info.type,
                        start_time: res.data.expire_info.start_time,
                        end_time: res.data.expire_info.end_time
                    });
                }
            }

            if(res.data.disabled_use_day && (res.data.disabled_use_day.date || res.data.disabled_use_day.week)){
                this.setState({
                    allDate: false,
                    dateList: res.data.disabled_use_day.date,
                    week: res.data.disabled_use_day.week
                })
            }

            if(res.data.shop_type){
                this.setState({
                    shop_type: res.data.shop_type
                })
            }

            if(res.data.shop_type == 2){
                let ids:any[] = [];
                res.data.shop_id.map((item: any) => {
                    ids.push(item.id)
                })
                this.setState({
                    shop_ids: ids,
                    shop_type: 2,
                    favour: res.data.shop_id
                })
            }
        }
    }

    // 输入类型
    inputChange = (e:any) => {
        let name = e.target.name;
        let value = e.target.value;
        this.setState({
            [name]: value
        })
    }

    // 优惠券券类型  单选类型
    radioChange = (e:any) => {
        let name = e.target.name;
        let value = e.target.value;
        this.setState({
            [name]: value
        })
    }

    // 有效时间区间
    timeChange = (time: RangePickerValue, timeString: [string, string]) => {
        this.setState({
            start_time: timeString[0],
            end_time: timeString[1]
        })
    }

    // 可见用户
    onCheck = (checkedKeys: any, info: any) => {
        let keyList = checkedKeys;
        let user_type: any[] = [];
        keyList.map((item: any) => {
            if(item !== '4'){
                user_type.push(item)
            }
        })
        this.setState({
            user_type
        })
    };

    // 星期选择
    weekChange = (checkedValues: any) => {
        let weeks_string_list: any = this.state.weeks_string_list;
        let copy_weeks_string_list: any = [...weeks_string_list];
        let weeks: any = this.state.weeks;
        let cache: any = [];
        if(checkedValues.length === 0) {
            weeks_string_list.length > 0 && weeks_string_list.map((item: any, index: any) => {
                if(item[0] === '\u5468') {
                    console.log(item)
                    weeks_string_list.splice(0, 1)
                }
            })
            //再合并
            cache = cache.concat(weeks_string_list)
        } else {
            //清除之前选中的
            copy_weeks_string_list.length > 0 && copy_weeks_string_list.map((item: any, index: any) => {
                if(item[0] === '\u5468') {
                    weeks_string_list.splice(0, 1)
                }
            })
            let temp = checkedValues.sort();
            if(temp.indexOf(0) !== -1 ) { //因为0是周日，所以要先删除掉，然后在追加到结尾
                temp.splice(temp.indexOf(0), 1)
                temp.push(0)
            }
            temp.map((item: any, index: any) => {
                if(item !== 0) {
                    cache.splice(index, 0, weeks[item-1]['name'])
                } else {
                    cache.splice(index, 0, weeks[6]['name'])
                }

            })
            //再合并
            cache = cache.concat(weeks_string_list)
        }
        this.setState({
            week: checkedValues,
            weeks_string_list: cache
        })
    }

    handleSubmit = async (e:any) => {
        // message.loading({ content: '正在保存...', });

        e.preventDefault();

        const {location} = this.props;
        const {
            amount,
            limit_value,
            position,
            shop_ids,
            user_type,
            week,
            dateList,
            shop_type,
            notice_day,
            can_use_time,
            valid_day,
            is_other_coupon,
            is_currency,
            is_currency_only,
            is_currency_nextDay,
            weeks_string_list,
            favour,
            name, type, money, limit, discount, validText, day, custom, start_time, end_time, allDate } = this.state;

        var productInfo:any = {};

        if(location.state !== undefined && !location.state.type){
            productInfo.id = location.state.id;
        }

        if(name === ''){
            message.warning({ content: '请输入商品名称!', key, duration: 2 });
            return;
        }

        if(!can_use_time || can_use_time == 0){
            if(validText === 'day'){
                if(day === 0){
                    message.warning({ content: '请输入有效天数!', key, duration: 2 });
                    return;
                }
            } else if(validText === 'custom'){
                if(custom === 0){
                    message.warning({ content: '请输入有效天数!', key, duration: 2 });
                    return;
                }
            }else{
                if(start_time === ''){
                    message.warning({ content: '请选择有效区间!', key, duration: 2 });
                    return;
                }
            }
        }else{
            if(!valid_day || valid_day == 0){
                message.warning({ content: '请输入券有效期!', key, duration: 2 });
                return;
            }
        }

        if(discount === 0){
            message.warning({ content: '请输入优惠金额或折扣!', key, duration: 2 });
            return;
        }

        if(limit){
            if((money === undefined || money === 0)){
                message.warning({ content: '请输入折扣金额!', key, duration: 2 });
                return;
            }
            productInfo.info = {
                'type': type,
                'favour': discount,
                'limit':{
                    "type": 'full',
                    "money": money
                }
            }
        }else{
            productInfo.info = {
                'type': type,
                'favour': discount,
                "limit": null
            }
        }

        if(position != 3 && position != 5) {
            if(amount === 0){
                message.warning({ content: '请输入发行数量!', key, duration: 2 });
                return;
            }

            if(limit_value === 0){
                message.warning({ content: '请输入每人领取上限!', key, duration: 2 });
                return;
            }

            if(user_type.length == 0){
                message.warning({ content: '请选择可见用户!', key, duration: 2 });
                return;
            }
            productInfo.amount = amount;
            productInfo.limit_value = limit_value;
            //productInfo.sort = sort;
        } else {
            productInfo.amount = 0;
            productInfo.limit_value = 0;
        }

        if(!allDate && (!can_use_time || can_use_time == 0)){
            let newDate:any[] = [];
            dateList.map((date: any) => {
                if(date.start){
                    newDate.push({start: date.start, end: date.end ? date.end : date.start})
                }
            })
            productInfo.disabled_use_day = {
                week,
                date: newDate
            }
        }

        //是否可享受店内其他优惠
        productInfo.is_other_coupon = is_other_coupon;

        //使用条件
        productInfo.conditions = limit ? `满${money}元可使用` : '无门槛';

        //不可用日期
        productInfo.unavailableDate = weeks_string_list.join('、');

        //可用门店
        let availableStores = '';
        favour.length > 0 && favour.map((item: any, index: any) => {
            if(index === 0) {
                availableStores = item.name
            } else {
                availableStores += '、' + item.name
            }
        })
        productInfo.availableStores = availableStores


        //适用范围
        if(is_currency === 1) {
            productInfo.is_currency = '全场通用'
        } else if(is_currency === 2) {
            productInfo.is_currency = `仅限适用${is_currency_only}`
        } else if(is_currency === 3) {
            productInfo.is_currency = `除${is_currency_nextDay}外全场通用`
        }

        //can_use_time && (productInfo.can_use_time = can_use_time) //券有效时间

        productInfo.name = name;
        productInfo.type = type;
        //productInfo.can_trans = can_trans;
        productInfo.shop_type = shop_type;
        //productInfo.status = status;
        productInfo.shop_ids = shop_ids;
        //推广渠道
        // productInfo.position = position;
        if(position != 3 && position != 5){
            productInfo.user_type = user_type;
        }
        // if(receive_type === 1){
        //     productInfo.receive_type = {
        //         type: 1,
        //         limit: null
        //     }
        // }else if(receive_type === 2){
        //     productInfo.receive_type = {
        //         type: 2,
        //         limit: receive_limit
        //     }
        // }else{
        //     productInfo.receive_type = {
        //         type: 3,
        //         limit: null
        //     }
        // }

        if(!can_use_time || can_use_time == 0){
            if(validText === 'day'){
                productInfo.expire_info = {
                    type: validText,
                    day: day
                }
                productInfo.termOfValidity = `领劵当日起${custom}天内使用`
            }else if(validText === 'custom'){
                productInfo.expire_info = {
                    type: validText,
                    day: custom
                }
                productInfo.termOfValidity = `领劵次日起${custom}天内使用`
            }else{
                productInfo.expire_info = {
                    type: validText,
                    end_time,
                    start_time
                }
                productInfo.termOfValidity = `${start_time}至${end_time}`
            }
        }else{
            productInfo.expire_info = {
                type: 'day',
                day: valid_day
            }
        }

        if(notice_day){
            productInfo.notice_day = notice_day;
        }
        console.log('productInfo:',productInfo)
        //productInfo.tips = htmlContent;
        if(location.state !== undefined && !location.state.type){
            const res:any = await postRequest(apiUrl.merchantCouponAdd,productInfo);
            if(res['code'] === 1){
                message.success({ content: '修改成功!', key, duration: 2 });
                this.goBack()
            }else{
                message.error({ content: '修改失败!', key, duration: 2 });
            }
        }else{
            const res:any = await postRequest(apiUrl.merchantCouponAdd,productInfo);
            if(res['code'] === 1){
                message.success({ content: '发布成功!', key, duration: 2 });
                this.goBack()
            }else{
                message.error({ content: '发布失败!', key, duration: 2 });
            }
        }
    };

    handleEditorChange = (editorState:any) => {
        this.setState({ editorState })
    }

    // 返回上一页
    goBack = () => {
        this.props.history.goBack();
    }

    _initList = async () => {
        const res:any = await getRequest(apiUrl.getShopList);
        if(res.code === 1){
            this.setState({
                productList: res.data,
            },() => {
                this.filterList();
            })
        }
    }

    isChinese = (s:string) =>{
        return /[\u4e00-\u9fa5]/.test(s);
    }

    ch2Unicdoe =(str:string) =>{
        if(!str){
          return;
        }
        var unicode = '';
        for (var i = 0; i <  str.length; i++) {
          var temp = str.charAt(i);
          if(this.isChinese(temp)){
            unicode += '\\u' +  temp.charCodeAt(0).toString(16);
          }
          else{
            unicode += temp;
          }
        }
          return unicode;
      }

    searchFoodName = (searchText:string,text:string) => {
        let test = this.ch2Unicdoe(searchText)
        var pattern2 = `/[${test}]+/g`;
        var reg = RegExp(eval(pattern2))
        return reg.test(text)
    }

    filterList = () => {
        let { productList, searchName } = this.state;
        let newList:any = [];
        let newListTow:any = [];

        productList.map((item:any) => {
            if(searchName !== ''){
                if(this.searchFoodName(searchName, item.name)){
                    newList.push(item)
                }
            }else{
                newList.push(item)
            }
        })

        newListTow = JSON.parse(JSON.stringify(newList));

        this.setState({
            showProductList: newList,
            productCount: newListTow.length
        })
    }

    //商品名搜索
    searchAction = (e:any) => {
        this.setState({
            searchName: e.target.value.trim(),
            currentPage: 1
        }, () => {
            this.filterList()
        })
    }
    // 分页
    orderChange = (page: number) => {
        this.setState({
            currentPage: page
        }, () => {
            this.filterList()
        })
    }

    handleOk = (e:any) => {
        console.log(this.state.shop_ids);
        let productList:any[] = this.state.productList;
        let favour: any = [];
        productList.map((item:any) => {
            if(item.select){
                favour.push(item)
            }
        })
        this.setState({
          visible: false,
          favour
        });
    };

    handleCancel = (e:any) => {
        this.setState({
          visible: false,
        });
    };

    showTable = () => {
        let { productList, favour } = this.state;
        productList.map((item: any) => {
            favour.map((item2: any) => {
                if(item.id === item2.id){
                    item.select = true;
                }
            })
        })
        this.setState({
            productList,
            visible: true
        }, () => {
            this.filterList()
        })
    }

    deleteDish = (id: number) => {
        let { favour, productList, shop_ids } = this.state;
        favour.map((item:any, index: number) => {
            if(item.id === id){
                favour.splice(index,1)
            }
        })

        productList.map((item: any) => {
            if(item.id === id){
                item.select = false;
            }
        })

        shop_ids.map((item: any, index:any) => {
            if(item === id){
                shop_ids.splice(index, 1)
            }
        })

        this.setState({
            favour,
            productList,
            shop_ids
        })
    }

    onCalendarChange = (dates: any, dateStrings: any, index: any, text: string) => {
        let dateList:any[]= this.state.dateList;
        console.log(dateList, dates, dateStrings)
        if(text === 'start'){
            dateList[index].start = dateStrings
        }else{
            dateList[index].end = dateStrings
        }

        this.setState({
            dateList
        })
    }

    addDate = (item?: any) => {
        let dateList: any = this.state.dateList;
        let weeks_string_list: any = this.state.weeks_string_list;
        let festival_list: any = this.state.festival_list;
        if(dateList.length == 0){
            dateList = dateList.concat(item.date);
            if(item.date.length > 1) {
                festival_list.map((item3: any, index3: any) => {
                    weeks_string_list.push(item3)
                })
            } else {
                weeks_string_list.push(item['name'])
            }

        }else{
            if(item.date.length > 1){
                dateList.map((item1: any) => {
                    let count = 0;
                    item.date.map((item2: any) => {
                        if(item1.start != item2.start && item1.end != item2.end){
                            count++;
                            if(count == dateList.length){
                                console.log(count, dateList.length, item1.start, item2.start);
                                dateList.push(item2)
                            }
                        }
                    })
                })
                //判断之前是否添加过法定节假日，有则当次不添加
                festival_list.map((item3: any, index3: any) => {
                    if(weeks_string_list.length > 0) {
                        weeks_string_list.map((item4: any, index4: any) => {
                            if(item3 === item4) {
                                weeks_string_list.splice(index4,1)
                            }
                        })
                        weeks_string_list.push(item3)
                    } else {
                        weeks_string_list.push(item3)
                    }
                })
            }else{
                let newcount = 0;
                dateList.map((item1: any) => {
                    item.date.map((item2: any) => {
                        if(item1.start != item2.start && item1.end != item2.end){
                            newcount++;
                            if(newcount == dateList.length){
                                console.log(newcount, dateList.length, item1.start, item2.start);
                                dateList.push(item2)
                                weeks_string_list.push(item.name)
                            }
                        }
                    })
                })
            }
        }
        this.setState({
            dateList,
            weeks_string_list
        })
    }

    addNull = () => {
        let dateList: any = this.state.dateList;
        dateList.push({start: null,end: null,fix: true});
        this.setState({
            dateList
        })
    }

    deleteDate = (idx: number) => {
        let dateList: any = this.state.dateList;
        let weeks_string_list = this.state.weeks_string_list;
        let temp: any = [];
        let date_list: any = [];
        dateList.splice(idx, 1)
        weeks_string_list.map((item: any, index: any) => {
            if(item[0] === '\u5468') {
                temp.push(item)
            } else {
                date_list.push(item)
            }
        })
        date_list.splice(idx, 1)
        temp = temp.concat(date_list);
        this.setState({
            dateList,
            weeks_string_list: temp
        })
    }

    render(){
        const {
            name,
            type,
            user_type,
            amount,
            limit_value,
            position,
            sort,
            status,
            shop_type,
            receive_type,
            receive_limit,
            shop_ids,
            weeks,
            festival,
            dateList,
            allDate,
            week,
            edit,
            editorState,
            can_use_time,
            notice_day,
            valid_day,
            custom,
            is_other_coupon,
            is_currency,
            is_currency_only,
            is_currency_nextDay,
            conditions,
            termOfValidity,
            unavailableDate,
            availableStores,
            limit, money, discount, can_trans, validText, day, start_time, end_time,  favour,
         } = this.state;

        let weeks_string_list: any = this.state.weeks_string_list;

        let columns = [
            {
                title: '门店名称',
                dataIndex: 'name',
                key: 'name',
                className: 'list_center',
            },
            {
                title: '门店地址',
                dataIndex: 'address',
                key: 'address',
                className: 'list_center',
            }
        ];

        let rowSelection: any = {
            selectedRowKeys: shop_ids,
            onSelect: (record: any, selected: any, selectedRows: any) => {
                let productList:any = this.state.productList;
                productList.map((item: any) => {
                    if(item.id == record.id){
                        item.select = selected;
                    }
                })
                this.setState({
                    productList
                })
            },
            onSelectAll: (selected: any, selectedRows: any, changeRows: any) => {
                let productList:any[] = this.state.productList;
                productList.map((item: any) => {
                    changeRows.map((item2: any) => {
                        if(item.id == item2.id){
                            item.select = selected;
                        }
                    })
                })

                this.setState({
                    productList
                })
            },
            onChange: (selectedRowKeys:any, selectedRows: any) => {
                this.setState({
                    shop_ids: selectedRowKeys
                })
            },
            type: 'checkbox',
        };

        return <div className='l_content'>
                <div className="edit_title">优惠基本信息</div>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">券类型</span>
                        </div>
                    </Col>
                    <Col>
                        <Radio.Group
                            name='type'
                            value={type}
                            onChange={(e) => this.radioChange(e)}
                            disabled={edit}
                        >
                            <Radio value='voucher'>优惠券</Radio>
                            <Radio value='discount'>折扣券</Radio>
                        </Radio.Group>
                    </Col>
                </Row>

                {/* <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">推广渠道</span>
                        </div>
                    </Col>
                    <Col span={14}>
                        <Row type="flex" align="middle">
                            <Col>
                                <Radio.Group
                                    name='position'
                                    value={position}
                                    onChange={(e) => this.radioChange(e)}
                                    disabled={edit}
                                >
                                    <Radio value={1}>领券中心</Radio>
                                    <Radio value={2}>领券中心</Radio>
                                    <Radio value={3}>礼包渠道</Radio>
                                    <Radio value={5}>内部发行</Radio>
                                </Radio.Group>
                            </Col>
                        </Row>
                    </Col>
                </Row> */}

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">券名称</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <Input placeholder="请输入券名称" maxLength={10} name='name' value={name} onChange={(e) => this.inputChange(e)}/>
                    </Col>
                </Row>

                {
                    type === 'voucher'
                    ? <Row className="mb30" type="flex" align="middle">
                        <Col span={2}>
                            <div>
                                <span className="star"></span>
                                <span className="color_black">优惠金额</span>
                            </div>
                        </Col>
                        <Col span={2}>
                            <Input placeholder="请输入" name='discount' disabled={edit} value={discount} type="number" onChange={(e) => this.inputChange(e)} addonAfter="元" />
                        </Col>
                    </Row>
                    : <Row className="mb30" type="flex" align="middle">
                        <Col span={2}>
                            <div>
                                <span className="star"></span>
                                <span className="color_black">折扣额度</span>
                            </div>
                        </Col>
                        <Col span={2}>
                            <Input placeholder="请输入" name='discount' value={discount} type="number" onChange={(e) => this.inputChange(e)}/>
                        </Col>
                        <div className='tips mlr10'>例：填写90表示打9折</div>
                    </Row>
                }

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">使用消费条件</span>
                        </div>
                    </Col>
                    <Col span={14}>
                        <Row type="flex" align="middle">
                            <Col>
                                <Radio.Group
                                    name='limit'
                                    value={limit}
                                    onChange={(e) => this.radioChange(e)}
                                    disabled={edit}
                                >
                                    <Radio value={false}>无门槛</Radio>
                                    <Radio value={true}>有门槛</Radio>
                                </Radio.Group>
                            </Col>
                            {
                                limit
                                ? <Col>
                                        <div className="limit_tip">
                                            <span>满</span>
                                            <Input placeholder="0" disabled={edit} addonAfter="元" style={{ width: 120 }} name='money' value={money} className="mlr10" type="number" onChange={(e) => this.inputChange(e)} />
                                            <span>可使用</span>
                                        </div>
                                    </Col>
                                : null
                            }
                        </Row>
                    </Col>
                </Row>

                {/* <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="color_black">用券时间</span>
                        </div>
                    </Col>
                    <Col span={14}>
                        <Row type="flex" align="middle">
                            <span>领取</span>
                            <Input addonAfter="天" style={{ width: 120 }} name='can_use_time' type="number" className="mlr10" value={can_use_time} onChange={this.inputChange} />
                            <span>后使用</span>
                            <span className="mlr10" >券有效期</span>
                            <Input addonAfter="天" style={{ width: 120 }} name='valid_day' type="number" value={valid_day} onChange={this.inputChange} />
                        </Row>
                    </Col>
                </Row> */}

                {/* {
                    !can_use_time || can_use_time == 0
                    ? <Row className="mb30" type="flex" align="middle">
                        <Col span={2}>
                            <div>
                                <span className="star"></span>
                                <span className="color_black">有效时间</span>
                            </div>
                        </Col>
                        <Col span={14}>
                        <Radio.Group
                                name='validText'
                                value={validText}
                                onChange={(e) => this.radioChange(e)}
                            >
                                <Radio value='time'>固定时间</Radio>
                                <Radio value='day'>相对时间</Radio>
                                <Radio value='custom'>相对时间</Radio>
                            </Radio.Group>
                        </Col>
                    </Row>
                    :null
                } */}

                    <Row className="mb30" type="flex" >
                        <Col span={2}>
                            <div>
                                <span className="star"></span>
                                <span className="color_black">有效时间</span>
                            </div>
                        </Col>
                        <Col span={14}>
                            <Radio.Group
                                style={{display: 'flex', flexDirection: 'column'}}
                                name='validText'
                                value={validText}
                                onChange={(e) => this.radioChange(e)}
                            >
                                <Radio value='time' style={{display: 'flex', alignItems: 'center', marginBottom: '24px'}}>
                                    <Row type="flex" align="middle">
                                        <RangePicker onChange={this.timeChange} format="YYYY-MM-DD HH:mm:ss" placeholder={start_time !== '' ? [start_time, end_time] :["开始时间","结束时间"]} />
                                    </Row>
                                </Radio>
                                <Radio value='day' style={{display: 'flex', alignItems: 'center', marginBottom: '24px'}}>
                                    <Row type="flex" align="middle">
                                        <span>领券当日起</span>
                                        <Input placeholder="0" maxLength={10} addonAfter="天" style={{ width: 120 }} name='day' type="number" className="mlr10" value={day} onChange={this.inputChange} />
                                        <span>天内使用</span>
                                    </Row>
                                </Radio>
                                <Radio value='custom' style={{display: 'flex', alignItems: 'center'}}>
                                    <Row type="flex" align="middle">
                                        <span>领券次日起</span>
                                        <Input placeholder="0" maxLength={10} addonAfter="天" style={{ width: 120 }} name='custom' type="number" className="mlr10" value={custom} onChange={this.inputChange} />
                                        <span>天内使用</span>
                                    </Row>
                                </Radio>
                            </Radio.Group>
                            {/* <Row type="flex" align="middle">
                                <RangePicker onChange={this.timeChange} format="YYYY-MM-DD HH:mm:ss" placeholder={start_time !== '' ? [start_time, end_time] :["开始时间","结束时间"]} />
                            </Row>
                            <Row className="mb30" type="flex" align="middle">
                                <Col span={2}></Col>
                                <Col span={14}>
                                    <Row type="flex" align="middle">
                                        <span>领券当日起</span>
                                        <Input placeholder="0" maxLength={10} addonAfter="天" style={{ width: 120 }} name='day' type="number" className="mlr10" value={day} onChange={this.inputChange} />
                                        <span>天内使用</span>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="mb30" type="flex" align="middle">
                                <Col span={2}></Col>
                                <Col span={14}>
                                    <Row type="flex" align="middle">
                                        <span>领券次日起</span>
                                        <Input placeholder="0" maxLength={10} addonAfter="天" style={{ width: 120 }} name='day' type="number" className="mlr10" value={day} onChange={this.inputChange} />
                                        <span>天内使用</span>
                                    </Row>
                                </Col>
                            </Row> */}
                        </Col>
                    </Row>

                {/* {
                    !can_use_time || can_use_time == 0 ?
                        validText === 'time' ?
                        <Row className="mb30" type="flex" align="middle">
                            <Col span={2}></Col>
                            <Col span={14}>
                                <Row type="flex" align="middle">
                                    <RangePicker onChange={this.timeChange} format="YYYY-MM-DD HH:mm:ss" placeholder={start_time !== '' ? [start_time, end_time] :["开始时间","结束时间"]} />
                                </Row>
                            </Col>
                        </Row>
                        : validText === 'day'
                            ?   <Row className="mb30" type="flex" align="middle">
                                    <Col span={2}></Col>
                                    <Col span={14}>
                                        <Row type="flex" align="middle">
                                            <span>领券当日起</span>
                                            <Input placeholder="0" maxLength={10} addonAfter="天" style={{ width: 120 }} name='day' type="number" className="mlr10" value={day} onChange={this.inputChange} />
                                            <span>天内使用</span>
                                        </Row>
                                    </Col>
                                </Row>
                            :   <Row className="mb30" type="flex" align="middle">
                                    <Col span={2}></Col>
                                    <Col span={14}>
                                        <Row type="flex" align="middle">
                                            <span>领券当日起</span>
                                            <Input placeholder="0" maxLength={10} addonAfter="天" style={{ width: 120 }} name='day' type="number" className="mlr10" value={day} onChange={this.inputChange} />
                                            <span>次内使用</span>
                                        </Row>
                                    </Col>
                                </Row>
                    : null
                } */}

                {
                    position != 3 && position != 5
                    ? <Row className="mb30" type="flex" align="middle">
                        <Col span={2}>
                            <div>
                                <span className="star"></span>
                                <span className="color_black">发行数量</span>
                            </div>
                        </Col>
                        <Col span={2}>
                            <Input name='amount' disabled={edit} value={amount} onChange={(e) => this.inputChange(e)} addonAfter='张'/>
                        </Col>
                        <Col>
                            <div className='tips mlr10'>创建成功后不能修改，请谨慎操作</div>
                        </Col>
                    </Row>
                    : ''
                }
                {
                    position != 3 && position != 5
                    ? <Row className="mb30" type="flex" align="middle">
                        <Col span={2}>
                            <div>
                                <span className="star"></span>
                                <span className="color_black">每人领取</span>
                            </div>
                        </Col>
                        <Col span={2}>
                            <Input name='limit_value' value={limit_value} onChange={(e) => this.inputChange(e)} addonAfter='张'/>
                        </Col>
                        <Col>
                            <div className='tips mlr10'>每个用户的领券上限，默认为1张</div>
                        </Col>
                    </Row>
                    : ''
                }

                {/* {
                    position != 3 && position != 5
                    ? <Row className="mb30" type="flex" align="middle">
                            <Col span={2}>
                                <div>
                                    <span className="star"></span>
                                    <span className="color_black">排序</span>
                                </div>
                            </Col>
                            <Col span={2}>
                                <Input name='sort' value={sort} onChange={(e) => this.inputChange(e)} />
                            </Col>
                            <Col>
                                <div className='tips mlr10'>数字越大，排序越靠前</div>
                            </Col>
                        </Row>
                    : null
                } */}

                {/* <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">状态</span>
                        </div>
                    </Col>
                    <Col span={14}>
                        <Row type="flex" align="middle">
                            <Col>
                                <Radio.Group
                                    name='status'
                                    value={status}
                                    onChange={(e) => this.radioChange(e)}
                                >
                                    <Radio value={2}>启用</Radio>
                                    <Radio value={0}>停用</Radio>
                                </Radio.Group>
                            </Col>
                        </Row>
                    </Col>
                </Row> */}

                <div className="edit_title">使用设置</div>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="color_black">可用门店</span>
                        </div>
                    </Col>
                    <Col span={14}>
                        <Row type="flex" align="middle">
                            <Col>
                                <Radio.Group
                                    name='shop_type'
                                    value={shop_type}
                                    onChange={(e) => this.radioChange(e)}
                                >
                                    <Radio value={1}>全部门店</Radio>
                                    <Radio value={2}>部分门店</Radio>
                                </Radio.Group>
                            </Col>
                            {
                                shop_type === 2
                                ? <Col>
                                        <Button icon='plus' onClick={() => {this.setState({visible: true})}}>选择门店</Button>
                                    </Col>
                                : null
                            }
                        </Row>
                    </Col>
                </Row>
                {
                    shop_type === 2 && favour.length > 0
                    ? <Row>
                        <Col span={12} offset={2}>
                            <Table
                                dataSource={this.state.favour}
                                columns={[
                                    {
                                        title: '门店名称',
                                        dataIndex: 'name',
                                        key: 'name',
                                        className: 'list_center',
                                    },
                                    {
                                        title: '门店地址',
                                        dataIndex: 'address',
                                        key: 'address',
                                        className: 'list_center',
                                    },
                                    {
                                        title: '操作',
                                        dataIndex: 'action',
                                        key: 'action',
                                        className: 'list_center',
                                        render: (rowdata:any, record: any, idx: number) => {
                                            return <div className='list_btn' onClick={() => this.deleteDish(record.id)}>删除</div>
                                        }
                                    }
                                ]}
                                rowKey={"id"}
                                size="middle"
                                locale={{emptyText: '暂无数据'}}
                                pagination={{
                                    showQuickJumper:true,
                                    defaultCurrent: 1,
                                    pageSize: 20,
                                }}/>
                        </Col>
                    </Row>
                    : null
                }

                {
                    !can_use_time || can_use_time == 0
                    ?   <Row className="mb30" type="flex" align="middle">
                            <Col span={2}>
                                <div>
                                    <span className="color_black">不可用日期</span>
                                </div>
                            </Col>
                            <Col span={14}>
                                <Row type="flex" align="middle">
                                    <Col>
                                        <Radio.Group
                                            name='allDate'
                                            value={allDate}
                                            onChange={(e) => this.radioChange(e)}
                                        >
                                            <Radio value={true}>所有日期都可用</Radio>
                                            <Radio value={false}>部分日期不可用</Radio>
                                        </Radio.Group>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    :null
                }

                {
                    !allDate
                    ? <Row>
                        <Col offset={2}>
                            <Row>
                                <Checkbox.Group style={{ width: '100%' }} onChange={this.weekChange} value={week}>
                                    <Row>
                                        {weeks && weeks.map((item: any, index: any) => {
                                            return <Col span={1} key={index}>
                                                <Checkbox value={item.value}>{item.name}</Checkbox>
                                            </Col>
                                        })}
                                    </Row>
                                </Checkbox.Group>
                            </Row>
                            <div className='sub_title'>指定不可用时间（点击某个节日后，相应日期会自动被添加到下方日期栏中。）</div>
                            <Row>
                                <Col span={10}>
                                    <Row type='flex'>
                                        {
                                            festival.map((item: any, index:number) => {
                                                return <Col key={index} span={index > 0 ? 4 : 20} className='festival_item' onClick={() => this.addDate(item)}>{item.name}</Col>
                                            })
                                        }
                                    </Row>
                                </Col>
                            </Row>


                        <div className='sub_title'>手动添加</div>
                            {

                                dateList && dateList.map((day: any, idx: number) => {
                                    return  <Row type='flex' align='middle' style={{margin:'15px 0'}} key={JSON.stringify(day)}>
                                            <Col>
                                                <DatePicker
                                                    onChange={(date, dateString) => this.onCalendarChange(date, dateString, idx, 'start')}
                                                    placeholder='选择日期'
                                                    disabled={!day.fix}
                                                    defaultValue={day.start ? moment(day.start, 'YYYY-MM-DD') : null}
                                                    />
                                            </Col>
                                            <Col className='mlr10'>至</Col>
                                            <Col>
                                                <DatePicker
                                                    onChange={(date, dateString) => this.onCalendarChange(date, dateString, idx, 'end')}
                                                    placeholder='选择日期'
                                                    disabled={!day.fix}
                                                    defaultValue={day.end ? moment(day.end, 'YYYY-MM-DD') : null}
                                                    />
                                            </Col>
                                            <Col className='mlr10' onClick={() => this.deleteDate(idx)}>删除</Col>
                                        </Row>
                                    })
                            }
                        </Col>
                        <Col offset={2} span={2}><div className='add_btn' onClick={this.addNull}>新增</div></Col>
                    </Row>
                    : null
                }

                {/* {
                    position == 5
                    ? ''
                    :  <Row className="mb30" type="flex" align="middle">
                            <Col span={2}>
                                <div>
                                    <span className="color_black">消费继续领券</span>
                                </div>
                            </Col>
                            <Col span={14}>
                                <Row type="flex" align="middle">
                                    <Col>
                                        <Radio.Group
                                            name='receive_type'
                                            value={receive_type}
                                            onChange={(e) => this.radioChange(e)}
                                            style={{display: 'flex',alignItems:'center'}}
                                        >
                                            <Radio value={1}>每次消费</Radio>
                                            <Radio value={2}>消费满</Radio>
                                            {
                                                receive_type === 2
                                                ?   <Col style={{display:'inline-flex', marginRight:'10px'}}>
                                                        <div className="limit_tip">
                                                            <Input placeholder="0" name='receive_limit' addonAfter="元" style={{ width: 120 }} value={receive_limit} type="number" onChange={(e) => this.inputChange(e)} />
                                                            <span>可继续领取</span>
                                                        </div>
                                                    </Col>
                                                : null
                                            }
                                            <Radio value={3}>一次性领取</Radio>
                                        </Radio.Group>
                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                } */}

                {/* <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="color_black">是否支持赠送</span>
                        </div>
                    </Col>
                    <Col span={14}>
                        <Row type="flex" align="middle">
                            <Col>
                                <Radio.Group
                                    name='can_trans'
                                    value={can_trans}
                                    onChange={(e) => this.radioChange(e)}
                                >
                                    <Radio value={false}>不支持赠送</Radio>
                                    <Radio value={true}>支持赠送</Radio>
                                </Radio.Group>
                            </Col>
                        </Row>
                    </Col>
                </Row> */}

                {/* <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="color_black">过期提醒</span>
                        </div>
                    </Col>
                    <Col span={14}>
                        <Row type="flex" align="middle">
                            <Col>优惠券过期前 </Col>
                            <Col> <Input style={{width: 100, margin: '0 10px'}} value={notice_day} name='notice_day' onChange={(e) => {this.inputChange(e)}}></Input> </Col>
                            <Col> 天提醒</Col>
                        </Row>
                    </Col>
                </Row> */}

                {
                    position != 3 && position != 5
                    ? <Row className="mb30" type="flex" align="top">
                        <Col span={2}>
                            <div>
                                <span className="star"></span>
                                <span className="color_black">可见用户</span>
                            </div>
                        </Col>
                        <Col span={6}>
                            <Tree
                                checkable
                                showIcon
                                onCheck={this.onCheck}
                                defaultSelectedKeys={user_type}
                                checkedKeys={user_type}
                            >
                                <TreeNode title="新用户" key="1" icon={
                                    <Tooltip placement="topLeft" title={"还未注册的用户或已经注册还没有下单的用户"}>
                                    <Icon type="question-circle" />
                                    </Tooltip>
                                }>
                                </TreeNode>
                                <TreeNode title="老顾客" key="4" icon={
                                    <Tooltip placement="topLeft" title={"交易次数>=1次的交易次数"}>
                                    <Icon type="question-circle" />
                                    </Tooltip>
                                }>
                                    <TreeNode title="充值用户" key="2" />
                                    <TreeNode title="非充值用户" key="3" />
                                </TreeNode>
                            </Tree>
                        </Col>
                    </Row>
                    : null
                }

                {/* <div className="wrap">
                    <Row>
                        <Col span={2}>
                            <div>
                                <span className="star"></span>
                                <span className="color_black">使用须知</span>
                            </div>
                        </Col>
                        <Col span={14}>
                            <div className="editor_wrap">
                                <BraftEditor
                                    value={editorState}
                                    onChange={this.handleEditorChange}
                                    excludeControls={['media']}
                                />
                            </div>
                        </Col>
                    </Row>
                </div> */}

                <div className="edit_title">使用须知</div>
                <Row className="mb30" type="flex" align="top">
                    <Col span={3}>
                        <div>
                            <span className="color_black">是否可享受店内其他优惠：</span>
                        </div>
                    </Col>
                    <Col span={14}>
                        <Row type="flex" align="middle">
                            <Col>
                                <Radio.Group
                                    name='is_other_coupon'
                                    value={is_other_coupon}
                                    onChange={(e) => this.radioChange(e)}
                                >
                                    <Radio value={1}>不可以同时享用</Radio>
                                    <Radio value={2}>可以享受其他所有优惠</Radio>
                                    <Radio value={3}>可享受部分优惠，详询商家</Radio>
                                </Radio.Group>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="mb30" type="flex" align="top">
                    <Col span={3}>
                        <div>
                            <span className="color_black">适用范围：</span>
                        </div>
                    </Col>
                    <Col span={21}>
                        <Row type="flex" align="middle">
                            <Col>
                                <Radio.Group
                                    style={{display: 'flex',flexDirection: 'column'}}
                                    name='is_currency'
                                    value={is_currency}
                                    onChange={(e) => this.radioChange(e)}
                                >
                                    <Radio value={1} style={{marginBottom: '24px'}}>全场通用</Radio>
                                    <Radio value={2} style={{display: 'flex', alignItems: 'center',marginBottom: '24px'}}>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            仅限适用
                                            <Input name="is_currency_only" value={is_currency_only} onChange={(e) => this.inputChange(e)} style={{width: '200px', margin: '0 10px'}} type='text'></Input>
                                        </div>
                                    </Radio>
                                    <Radio value={3} style={{display: 'flex', alignItems: 'center'}}>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            除
                                            <Input name="is_currency_nextDay" value={is_currency_nextDay} onChange={(e) => this.inputChange(e)} type='text' style={{width: '200px', margin: '0 10px'}}></Input>
                                            外全场通用
                                        </div>
                                    </Radio>
                                </Radio.Group>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="mb30" type="flex" align="top">
                    <Col span={21} offset={3}>
                        <Row type="flex" align="middle">
                            <Col span={2}>
                                使用条件：
                            </Col>
                            <Col>
                                {
                                    limit
                                    ?   `满${money}元可使用`
                                    :   '无门槛'
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="mb30" type="flex" align="top">
                    <Col span={21} offset={3}>
                        <Row type="flex" align="middle">
                            <Col span={2}>
                                有效期：
                            </Col>
                            <Col>
                                {
                                    validText === 'custom'
                                    ?   `领券次日起${custom}天内使用`
                                    :   validText === 'day'
                                        ?   `领券当日起${day}天内使用`
                                        :   validText === 'time'
                                            ?   `${start_time}至${end_time}`
                                            :   '无'
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="mb30" type="flex" align="top">
                    <Col span={21} offset={3}>
                        <Row type="flex" align="middle">
                            <Col span={2}>
                                不可用日期：
                            </Col>
                            <Col>
                                {
                                    weeks_string_list.length > 0
                                    ?   weeks_string_list.join('、')
                                    :   '无'
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="mb30" type="flex" align="top">
                    <Col span={21} offset={3}>
                        <Row type="flex" align="middle">
                            <Col span={2}>
                                可用门店：
                            </Col>
                            <Col>
                                {
                                    favour.length > 0
                                    ?   favour.map((item: any, index: any) => {
                                            return (
                                                index === 0
                                                ?   item.name
                                                :   '、' + item.name
                                            )
                                        })
                                    :   '全部可用'
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Col span={18}>
                        <div className="bottom_wrap">
                            <Button type="primary" ghost onClick={this.goBack}>
                                取消
                            </Button>
                            <Button type="primary" onClick={(e) => this.handleSubmit(e)}>
                                保存
                            </Button>
                        </div>
                    </Col>
                </Row>


                <Modal
                    title="指定门店"
                    visible={this.state.visible}
                    okText='确定'
                    cancelText='取消'
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    >
                    <Row type="flex" align="middle" justify="start" className="mb20" >
                        <Col className="mr30">
                            <Input placeholder="请输入关键字" onChange={this.searchAction} style={{ width: 234 }}/>
                        </Col>
                    </Row>

                    <div>
                        <Table
                        dataSource={this.state.showProductList}
                        columns={columns}
                        rowKey={"id"}
                        size="middle"
                        locale={{emptyText: '暂无数据'}}
                        rowSelection={rowSelection}
                        pagination={{
                            current: this.state.currentPage,
                            showQuickJumper:true,
                            defaultCurrent: 1,
                            total: this.state.productCount,
                            pageSize: this.state.pageSize,
                            hideOnSinglePage: true,
                            onChange: this.orderChange
                        }}/>
                    </div>
                </Modal>

        </div>;
    }

}
export default CouponAdd