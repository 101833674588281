import React from 'react';
import InsideNav from '../../../component/insideNav/insideNav';
import './shopManage.scss'
import Goods from '../../goods/goods';
import SortableTable from '../../goods/goodsSort';
import SetMeal from '../../setMeal/setMeal';
import Material from '../../material/materialManage';
import { collectChildNav } from '../../../utils/permisstionUtil';

type IProps = {
    history: any
}
type IState = {
    navList: any
    current_key: string
}
const navTable = [
    {
        title: '商品管理',
        isActive: false,
        KEY: "GOODS",
        uni_key: '2_2_1'
    },
    {
        title: '分类管理',
        isActive: false,
        KEY: "GOODS_SORT",
        uni_key: '2_2_2'
    },
    {
        title: '套餐管理',
        isActive: false,
        KEY: "SET_MEAL",
        uni_key: '2_2_3'
    },
    {
        title: '加料管理',
        isActive: false,
        KEY: "MATERIAL",
        uni_key: '2_2_4'
    }
]
class ShopManage extends React.Component<IProps,IState>{
    
    constructor(props:IProps) {
        super(props);
        this.state = {
            navList: [],
            current_key: "",
        }
    }

    componentDidMount(){
        this.initPageState();
    }

    initPageState = () =>{
        let navList = collectChildNav(navTable);
        let currentKey = localStorage.getItem('current_key') ?? navList[0].KEY;
        let isSetKey = false;
        navList.map((item:any) => {
            if(item.KEY == currentKey){
                item.isActive = true;
                isSetKey = true;
            }else{
                item.isActive = false;
            }
        })
        if(!isSetKey){
            currentKey = navList[0].KEY;
            navList[0].isActive = true;
        };
        this.setState({
            current_key: currentKey,
            navList: navList
        })
    }

    navCallBack(currentKey: string){
        localStorage.setItem('current_key',currentKey) 
        this.setState({
            current_key: currentKey
        })
    }

    render() {
        let { navList,current_key } = this.state;
        return <div>
            <InsideNav navList={navList} callBack={(key: string) =>this.navCallBack(key)}/>
            <div className="body">
                {
                    current_key === 'GOODS'  ?  <Goods history={this.props.history}/>
                    :
                    current_key === 'GOODS_SORT' ?  <SortableTable history={this.props.history} />
                    :
                    current_key === 'SET_MEAL' ?  <SetMeal history={this.props.history} />
                    : 
                    current_key === 'MATERIAL' ?  <Material history={this.props.history} />
                    : <></>
                    
                }
            </div>
        </div>
    }

}

export default ShopManage;