import React, { Fragment, useEffect, useState } from 'react'
import { Table, Row, Button } from 'antd'
import { getRequest } from '../../../api/http';
import { apiUrl } from '../../../api/api_url';
import { useHistory } from 'react-router-dom';


interface Ishoplist {
  items: Array<{
    name:string, // 店铺名称
    id: number,      // 店铺id
    paid:string, // 支付收入
    refund:string, // 退款支出
    deposit:string, // 充值收入
    withdraw:string, // 提现支出
  }>
  total: number
}

const ShopManagement = () => {
  const history = useHistory()
  const [shoplist, setshoplist] = useState<Ishoplist>({
    items: [
      // {
      //   name: '陈林的店铺', // 店铺名称
      //   id: 1,      // 店铺id
      //   paid: '555', // 支付收入
      //   refund: '666', // 退款支出
      //   deposit: '8656', // 充值收入
      //   withdraw: '2233', // 提现支出
      // }
    ],
    total: 0
  })

  useEffect(() => {
    ininShopList();
  }, [])

  const ininShopList = async () => {
    const res:any = await getRequest(apiUrl.shopBalanceList);
    if(res && res.data) {
      setshoplist(res.data);
    }
  }

  const columns = [{
    title: "店铺名称",
    dataIndex: "name",
    key: "name",
  },{
    title: "点餐实付总收入（元）",
    dataIndex: "paid",
    key: "paid",
  },{
    title: "总用户佣金提现总额",
    dataIndex: "withdraw",
    key: "withdraw",
  },{
    title: "用户退款总额（元）",
    dataIndex: "refund",
    key: "refund",
  },{
    title: "操作",
    key: "user",
    render: (rowdata: any) => {
      if(rowdata) {
        return <Button type="link" size="small" onClick={() => toDatail(rowdata.id)}>详情</Button>
      }
      return <Row></Row>
    }
  }]

  const toDatail = (id: any) => {
    history.push({
      pathname: '/sidebar/financial_management',
      state: {
        id: id
      }
    })
  }

  return <Fragment>
    <Table
       size="middle"
      rowKey={"id"}
      dataSource={shoplist.items}
      columns={columns}
      pagination={{ 
        pageSize: 10, 
        total: shoplist.total, 
        // onChange: (e: number) => pageOnChange(e) 
      }}
    />
  </Fragment>
}

export default ShopManagement