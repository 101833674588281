import React, { useEffect, useState } from 'react'

import { getRequest } from '../../api/http'
import { apiUrl } from '../../api/api_url'

import { Modal, message} from 'antd'

import './windowManage.scss'
import { useHistory } from 'react-router-dom';
import WindowTable from './components/windowTable';
import WindowTableTop from './components/windowTableTop';

import select_people from '../../assret/images/select_people.png';
import select_price from '../../assret/images/select_price.png';
import select_time from '../../assret/images/select_time.png';

import win_dish from '../../assret/images/win_dish.png'
import win_order from '../../assret/images/win_order.png'
import win_price from '../../assret/images/win_price.png'
import win_shop from '../../assret/images/win_shop.png'
import win_msg from '../../assret/images/win_msg.png'
import win_storage from '../../assret/images/win_storage.png'
import win_member from '../../assret/images/win_member.png'
import { getIsPermisson, messageCustom } from '../../utils/permisstionUtil'

let new_region_list: any = [];
const WindowManage = ()=>{
    const history:any = useHistory()
    const [windowList,setWindowList] = useState<any>([])
    const [businessMode,setBusinessMode] = useState<any>(1)
    const [windowListAll,setWindowAllList] = useState<any>([])
    const [errorVisible,seterrorVisible] = useState(false)
    const [errorInfo,seterrorInfo] = useState({id: 0, table_name: '', printer_name: '', msg:''})

    const buildType = process.env.REACT_APP_ChromeApp;

    useEffect(()=> {
        printerOff();
        shopInfoRequest()
        let pay = sessionStorage.getItem('statusPay')
        if(pay){
            windowListLoop(false,true)
            windowListRequest(2)
            setstatusUse(false)
            setstatusPay(true)
        }else{
            windowListLoop(statusUse,statusPay)
        }

        systemConfigRequest();

        return ()=> {
            if(intervalLoop[0].interval !== undefined){
                clearInterval(intervalLoop[0].interval)
            }
        }
    },[])

    const [showVersion,setshowVersion] = useState<any>(false)
    const [notice_title,setnotice_title] = useState<any>('')
    const [notice_list,setnotice_list] = useState<any>([])
    const versionDialog = () => {
        let notice_id = localStorage.getItem('notice_id');
        let notice_list = localStorage.getItem('notice_list');
        let notice_title = localStorage.getItem('notice_title');
        if(notice_id && notice_id != 'null'){
            setshowVersion(true)
            setnotice_title(notice_title)
            setnotice_list(notice_list ? JSON.parse(notice_list) : [])
        }
    }

    // 版本更新提醒
    const hideVersion = async () => {
        let notice_id = localStorage.getItem('notice_id');
        let res:any = await getRequest(apiUrl.versionRead, {notice_id: notice_id})
        if(res.code == 1){
            localStorage.removeItem('notice_id');
            localStorage.removeItem('notice_title');
            localStorage.removeItem('notice_list');
            setshowVersion(false)
        }
    }
    

    // 打印失败，重新打印
    const _printerFail = async () => {
        const res: any = await getRequest(apiUrl.printerFail,{page: 1, pageSize: 10, type: 1})
        if(res.code == 1){
            if(res.data && res.data.list.length > 0){
                seterrorInfo(res.data.list[0]);
                seterrorVisible(true)
            }
            localStorage.setItem('noti', res.data.total)
        }
    }

    // 重新打印
    const reprint = async () => {
        const res: any = await getRequest(apiUrl.reprinter+errorInfo.id)
        if(res.code == 1){
            message.success('重新打印成功')
            seterrorVisible(false)
            _printerFail();
        }else{
            message.error(res.message)
        }
    }

    // 稍后处理
    const delay_btn = async () => {
        const res: any = await getRequest(apiUrl.delayPrinter+errorInfo.id)
        if(res.code == 1){
            message.success('已处理')
            seterrorVisible(false)
            _printerFail();
        }else{
            message.error(res.message)
        }
    }

    // 打印机离线弹窗
    const printerOff = async () => {
        const res: any = await getRequest(apiUrl.printerOff)
        if(res.code == 1){
            let names = '';
            if(res.data && res.data.length > 0){
                res.data.map((item:any) => {
                    names += item.name+ ','
                })
                Modal.error({
                    title: '系统提醒',
                    content: names + '已断线，请检查打印机',
                    okText: '知道了',
                });
            }
        }
    }

    const [intervalLoop,setintervalLoop] = useState<any>([{interval:undefined}])

    const windowListLoop = (A:any,B:any)=> {
        _printerFail();
        if(A || B){
            windowListRequest(2)
        }else{
            windowListRequest(0)
        }
        if(intervalLoop[0].interval !== undefined){
            clearInterval(intervalLoop[0].interval)
        }
        intervalLoop[0].interval = setInterval(()=> {
            _printerFail();
            versionDialog()
            if(A || B){
                windowListRequest(2)
            }else{
                windowListRequest(0)
            }
        },8000)
    }
    const [divisible_num, setDivisible_num] = useState(6); //每一排显示的个数
    const [divisible_num_class, setDivisible_num_class] = useState('window_5'); //每一排显示的个数--对应的类名
    const [display_legends, setdisplay_legends] = useState([])
    //餐桌list
    const windowListRequest = async (status:number) => {

        let param:any = {status:status,pageSize:100};
        if(new_region_list != '[]'){
            param.region_id = new_region_list;
        }
        const res:any = await getRequest(`${apiUrl.regionTableList}`,param);
        if(res.code === 1){
            let all_data = res.data.list;
            let table_count = res.data.line_show_table_count;

            //设置营业模式
            setBusinessMode(res.data.business_mode)

            setWindowAllList([...all_data])

            setdisplay_legends(res.data.display_legends)


            //根据餐桌来自动调整每一排显示个数
            if(table_count == 5) {
                setDivisible_num(5)
                if(buildType === '1') {
                    setDivisible_num_class('window_5_waiter')
                } else {
                    setDivisible_num_class('window_5')
                }

                let _list: any = onListSplice(all_data, 5)
                setWindowList([..._list])
            } else if(table_count == 6) {
                setDivisible_num(6)
                if(buildType === '1') {
                    setDivisible_num_class('window_6_waiter')
                } else {
                    setDivisible_num_class('window_6')
                }
                let _list: any = onListSplice(all_data, 6)
                setWindowList([..._list])
            } else if(table_count == 7) {
                setDivisible_num(7)
                if(buildType === '1') {
                    setDivisible_num_class('window_7_waiter')
                } else {
                    setDivisible_num_class('window_7')
                }
                let _list: any = onListSplice(all_data, 7)
                setWindowList([..._list])
            } else {
                setDivisible_num(8)
                if(buildType === '1') {
                    setDivisible_num_class('window_8_waiter')
                } else {
                    setDivisible_num_class('window_8')
                }
                let _list: any = onListSplice(all_data, 8)
                setWindowList([..._list])
            }
        }
    }

    //区域筛选
    const onOwnSelectTable = async(ids: any, status: any) => {
        new_region_list = JSON.stringify(ids)
        let param: any = { status: status, pageSize: 100};

        if(JSON.stringify(ids) != '[]') {
            param.region_id = JSON.stringify(ids)
        }
        if(statusPay || statusUse) {
            param.status = 2
        }
        const res:any = await getRequest(`${apiUrl.regionTableList}`, param);
        if(res.code === 1){

            let all_data = res.data.list;
            let table_count = res.data.line_show_table_count;
            setWindowAllList([...all_data])

            setdisplay_legends(res.data.display_legends)

            //根据餐桌来自动调整每一排显示个数
            if(table_count == 5) {
                setDivisible_num(5)
                if(buildType === '1') {
                    setDivisible_num_class('window_5_waiter')
                } else {
                    setDivisible_num_class('window_5')
                }

                let _list: any = onListSplice(all_data, 5)
                setWindowList([..._list])
            } else if(table_count == 6) {
                setDivisible_num(6)
                if(buildType === '1') {
                    setDivisible_num_class('window_6_waiter')
                } else {
                    setDivisible_num_class('window_6')
                }
                let _list: any = onListSplice(all_data, 6)
                setWindowList([..._list])
            } else if(table_count == 7) {
                setDivisible_num(7)
                if(buildType === '1') {
                    setDivisible_num_class('window_7_waiter')
                } else {
                    setDivisible_num_class('window_7')
                }
                let _list: any = onListSplice(all_data, 7)
                setWindowList([..._list])
            } else {
                setDivisible_num(8)
                if(buildType === '1') {
                    setDivisible_num_class('window_8_waiter')
                } else {
                    setDivisible_num_class('window_8')
                }
                let _list: any = onListSplice(all_data, 8)
                setWindowList([..._list])
            }

        }
    }

    //数据格式处理，对数组进行分割，能被num整除的数
    const onListSplice = (_arr: any, divisor: any) => {
        let list: any = _arr;
        if(_arr && _arr.length > 0){
            let num = _arr.length % divisor;
            if(num > 0) {
                for(let i=0; i< divisor-num;i++){
                    list.push({'active': true})
                }
            }
            return list
        } else {
            return []
        }
    }

    // 营业时间
    const [openAt,setopenAt] = useState('')

    //店铺详情
    const shopInfoRequest = async () => {
        const res:any = await getRequest(`${apiUrl.shopInfo}`);
        if(res.code === 1){
            setisCloseStore(res.data ? res.data.is_open: false)
            setisCloseVisible(res.data && res.data.is_open)
            if(res.data.open_at){
                setopenAt(res.data.open_at)
            }
        }
    }

    //开启或关店
    const closeOpenStore = async () => {
        const res:any = await getRequest(`${apiUrl.shopOpenClose}`);
        if(res.code === 1){
            shopInfoRequest()
        }
    }

    //打烊 start
    const [closeStoreActionVisible,setcloseStoreActionVisible] = useState(false)
    const [isCloseStore,setisCloseStore] = useState(true)
    const [isCloseVisible, setisCloseVisible] = useState(true)
    const closeStoreActionOK = (e:any) => {
        setcloseStoreActionVisible(false)
        console.log('closeStoreActionOK',e)
        closeOpenStore()
    }

    const closeStoreActionCancel = (e:any) => {
        setcloseStoreActionVisible(false)
        console.log('closeStoreActionCancel',e)
    }
    const openStoreOK = (e:any) => {
        console.log('openStoreOK',e)
        openStoreAction()
    }

    //开店逻辑
    const openStoreAction = () => {
        closeOpenStore()
    }
    //店铺打烊
    const closeStoreAction = () => {
        if(!getIsPermisson('1_11_5')){
            messageCustom();
            return;
          }
        sessionStorage.removeItem('statusPay')
        if(isCloseVisible){
            setcloseStoreActionVisible(true)
        }else{
            setisCloseStore(false)
        }

    }
    //打烊 end


    //点菜状态
    const [statusUse,setstatusUse] = useState(false)
    const statusUseAction = () => {
        windowListLoop(true,false)
        windowListRequest(2)
        setstatusPay(false)
        setstatusUse(true)
        sessionStorage.removeItem('statusPay')
    }

    //收银状态
    const [statusPay,setstatusPay] = useState(false)
    const statusPayAction = () => {
        windowListLoop(false,true)
        windowListRequest(2)
        setstatusUse(false)
        setstatusPay(true)
    }

    const statusAllWindow = () => {
        sessionStorage.removeItem('statusPay')
        windowListLoop(false,false)
        setstatusUse(false)
        setstatusPay(false)
        windowListRequest(0)
    }

    const [systemConfig,setsystemConfig] = useState({})

    const systemConfigRequest = async ()=>{
        const res: any = await getRequest(apiUrl.systemConfig)
        console.log('systemConfigRequest res',res)
        if(res.code == 1){
            setsystemConfig(res.data)
        }

    }

    const clickWindow = (item: any, tableKey:any,is_eating:any,tableName:any,code:any,userAmount:any,dineKey:any)=> {
        if(code === null && dineKey === null && is_eating === true){
            message.info('当前桌用户正在点餐')
            return
        }
        if(statusPay === true){
            if(!getIsPermisson('1_11_11')){
                messageCustom();
                return;
            }
            sessionStorage.removeItem('statusPay')
            history.push({
                pathname: '/sidebar/settleAccounts',
                state: {tableId:tableKey,is_eating:is_eating,tableName:tableName,userAmount:userAmount,code:code,dineKey:dineKey, is_vip_fans: item.is_vip_fans}
            })
        }else{
            if(!getIsPermisson('1_11_6_1')){
                messageCustom();
                return;
            }
            history.push({
                pathname: '/sidebar/orderingFood',
                state: {tableId:tableKey,is_eating:is_eating,tableName:tableName,userAmount:userAmount,code:code,dineKey:dineKey, is_vip_fans: item.is_vip_fans,systemConfig:systemConfig}
            })
        }
    }

    const outWindowList = (item: any) => {
        if(!isCloseVisible){
            setisCloseStore(false)
            return
        }
        if(businessMode == 2 && item.food_order_status == 2) {
            if(!getIsPermisson('1_11_6_2')){
                messageCustom();
                return;
              }
            Modal.confirm({
                title: '提示',
                content: `当前营业模式为餐前支付，用户正在点餐中，请与用户确认后是否需要取消订单，是否立即取消？`,
                okText: '确认',
                cancelText: '取消',
                onOk: async () => {
                    let res: any = await getRequest(apiUrl.cancelOrderWithWaiter, { code: item.code, table_id: item.id })
                    if (res.code === 1) {
                        message.success('取消成功！');
                        window.location.reload()
                    } else {
                        message.success('取消失败！');
                    }
                },
                onCancel() {
                },
            });

        } else {
            if(!item.active) {
                clickWindow(item, item.id,item.is_eating,item.name,item.code,item.user_amount,item.dine_key)
            }
        }
    }

    return (
        <div className="windowManage windowManage_index">
            <div className='windowManage_group_tab_wrap mb30'>
                <WindowTableTop onStatusAllWindow={() => {statusAllWindow()}} onStatusPayAction={() => {statusPayAction()}} onStatusUseAction={() => {statusUseAction()}} onTab={(row: any, status: any) => {onOwnSelectTable(row, status)}}/>
            </div>
            <div className="window_manage_l_content">
                <div className="top">
                    <div className="left">
                        <WindowTable onStatusAllWindow={() => {statusAllWindow()}} onStatusPayAction={() => {statusPayAction()}} onStatusUseAction={() => {statusUseAction()}} onTab={(row: any, status: any) => {onOwnSelectTable(row, status)}}/>
                    </div>
                    <div className="right">
                        {
                            display_legends.map((item:any) => {
                                return <div className="windowManage_empty" key={item.name}>
                                    {
                                        item.name !== '全部桌台'
                                        ? <div className="windowManage_empty_color" style={{background: item.value}}></div>
                                        : null
                                    }
                                    <div className="windowManage_empty_txt">{item.name}（{item.count}）</div>
                                </div>
                            })
                        }
                        <div className="windowManage_empty">
                            <div className="windowManage_empty_txt">营业时间：{openAt}</div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    {
                        windowList.map((item:any,index:number) => {
                            return (
                                <div style={{background: item.corlour_value}} className={
                                        item.is_eating
                                            ? `${divisible_num_class} full`
                                            : item.active
                                                ? `${divisible_num_class} window_empty window_default`
                                                : `${divisible_num_class}`} onClick={()=>{outWindowList(item)}} key={`window_${index}`}>
                                    <div className="table">
                                        <div className="left">{item.name}</div>
                                    </div>
                                    {
                                        item.is_eating ?
                                        <div className="" style={{display: 'flex',alignItems:'center',}}>
                                            <div className="price">
                                                <div style={{display: 'flex',alignItems:'center',}}>
                                                    <img src={select_price} className="windowManage_icon" />
                                                    <div className="windowManage_line_height">{item.origin_fee}</div>
                                                </div>
                                            </div>
                                        </div>
                                        :   <div className="people_num" style={{display: 'flex', justifyContent: 'space-between', width:'100%'}}>
                                                <div style={{display: 'flex',alignItems:'center',}}>
                                                    <img src={select_people} className="windowManage_icon" />
                                                    <div className="windowManage_line_height" style={{color: '#fff'}}>{item.user_amount}/{item.people_num}</div>
                                                </div>
                                            </div>
                                    }
                                    {
                                        item.is_eating ?
                                        <div className="" style={{display: 'flex', justifyContent: 'space-between', width:'100%'}}>
                                            <div className="people_num" style={{display: 'flex',alignItems:'center',}}>
                                                <img src={select_people} className="windowManage_icon" />
                                                <div className="windowManage_line_height" style={{color: '#fff'}}>{item.user_amount}/{item.people_num}</div>
                                            </div>
                                            <div className="time">
                                                <img src={select_time} className="windowManage_icon" />
                                                <div className="time windowManage_line_height">{item.eat_time}</div>
                                            </div>
                                        </div>
                                        : null
                                    }

                                    {
                                        item.corner_marker
                                        ? <div className='time_text'>{item.corner_marker}</div>
                                        : null
                                    }

                                    {/* {
                                        item.food_order_status == 3
                                        ?   <div className='time_paying'>付款中</div>
                                        : null
                                    }

                                    {
                                        item.food_order_status == 2
                                            ? <div className='time_paying'>点餐中</div>
                                            : null
                                    } */}

                                </div>
                            )
                        })
                    }
                </div>

                <div className="header_bar_top_icon">
                    <div className="header_bar_top_icon_item" onClick={() => {
                        if(!getIsPermisson('1_11_1')){
                            messageCustom();
                            return;
                          }
                            sessionStorage.removeItem('statusPay')
                            history.push('/sidebar/order_times')
                        }}>
                        <img src={win_msg} className="header_bar_top_icon_item_img" />
                        <div className="header_bar_top_icon_item_txt">实时消息</div>
                    </div>
                    <div className="header_bar_top_icon_item" onClick={() => {
                        if(!getIsPermisson('1_11_2')){
                            messageCustom();
                            return;
                          }
                            sessionStorage.removeItem('statusPay')
                            history.push('/sidebar/order_manager_orderlist')
                        }}>
                        <img src={win_order} className="header_bar_top_icon_item_img" />
                        <div className="header_bar_top_icon_item_txt">订单管理</div>
                    </div>
                    <div className="header_bar_top_icon_item" onClick={() => {
                         if(!getIsPermisson('1_11_3')){
                            messageCustom();
                            return;
                          }
                            sessionStorage.removeItem('statusPay')
                            history.push('/sidebar/incomeForm')
                        }}>
                        <img className="header_bar_top_icon_item_img" src={win_price} />
                        <div className="header_bar_top_icon_item_txt">营收报表</div>
                    </div>
                    <div className="header_bar_top_icon_item" onClick={() => {
                        if(!getIsPermisson('1_11_4')){
                            messageCustom();
                            return;
                          }
                            sessionStorage.removeItem('statusPay')
                            history.push('/sidebar/stockout')
                        }}>
                        <img className="header_bar_top_icon_item_img" src={win_dish} />
                        <div className="header_bar_top_icon_item_txt">菜品沽清</div>
                    </div>
                    <div className="header_bar_top_icon_item" onClick={() => {
                        if(!getIsPermisson('1_11_7')){
                            messageCustom();
                            return;
                          }
                            sessionStorage.removeItem('statusPay')
                            history.push('/sidebar/depositIndex')
                        }}>
                        <img className="header_bar_top_icon_item_img" src={win_storage} />
                        <div className="header_bar_top_icon_item_txt">存酒/存物</div>
                    </div>
                    <div className="header_bar_top_icon_item" onClick={closeStoreAction}>
                        <img className="header_bar_top_icon_item_img" src={win_shop} />
                        <div className="header_bar_top_icon_item_txt">门店打烊</div>
                    </div>

                    <div className="header_bar_top_icon_item" onClick={() => {
                        if(!getIsPermisson('1_11_8')){
                            messageCustom();
                            return;
                          }
                            sessionStorage.removeItem('statusPay')
                            history.push('/sidebar/memberManage')
                        }}>
                        <img className="header_bar_top_icon_item_img" src={win_member} />
                        <div className="header_bar_top_icon_item_txt">会员管理</div>
                    </div>
                </div>
            </div>

            <Modal
                title={<div style={{color: '#FF4A61',fontSize:'16px'}}>{notice_title}</div>}
                visible={showVersion}
                closable={false}
                footer={null}
                width={'40%'}
            >
                <div>{notice_list.map((item:any) => {
                    return <div className='mb30'>
                        <div style={{marginBottom:'5px',display:'flex'}}><div style={{fontSize:'14px', color:'#000',fontWeight:'bold', width: '80px'}}>更新项目：</div>{item.name}</div>
                        <div style={{marginBottom:'5px', display:'flex'}}><div style={{fontSize:'14px', color:'#000',fontWeight:'bold', width: '80px'}}>版本号：</div>{item.version}</div>
                        <div style={{display:'flex'}}><div style={{fontSize:'14px', color:'#000',fontWeight:'bold',marginBottom:'5px', width: '80px'}}>更新内容：</div>
                        <div style={{flex: 1}}>{item.list.map((item2:any) => {
                            return <div style={{color: '#646464', fontSize: '14px'}}>{item2}</div>
                        })}</div></div>
                    </div>
                })}</div>
                <div style={{display:'flex'}}>
                    <div className='btn sys_btn' onClick={hideVersion}>知道了</div>
                </div>
            </Modal>
            <Modal
                title="提示"
                visible={closeStoreActionVisible}
                onOk={closeStoreActionOK}
                onCancel={closeStoreActionCancel}
                okText="确认"
                cancelText="取消"
                closable={false}
            >
                <p>确定设置为今日打烊？</p>
            </Modal>
            <Modal
                title="门店已打烊"
                visible={!isCloseStore}
                onOk={openStoreOK}
                onCancel={() => {setisCloseStore(true)}}
                okText="开店"
                closable={false}
            >
                <p>门店已打烊是否开启？</p>
            </Modal>

            {/* 不提交的代码 */}
            <Modal visible={errorVisible}
                title='系统提醒'
                // centered
                onCancel={() => {seterrorVisible(false)}}
                footer={null}>
                    <div className='sys_content' dangerouslySetInnerHTML={{__html:errorInfo.msg}}></div>
                    <div style={{display:'flex'}}>
                        <div className='btn' onClick={delay_btn}>稍后处理</div>
                        <div className='btn sys_btn' onClick={reprint}>重新打印</div>
                    </div>

            </Modal>
        </div>
    )
}

export default WindowManage
