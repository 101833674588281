import { message } from 'antd';
import React, { useEffect, useState } from 'react'

import './sliderBar.scss'

const SliderBar = (props) =>{
    const [arrList,setArrList] =  useState([]);

    useEffect(() =>{
        setArrList(props.arrList)
    },[props.arrList])

    const handleClick =(index,c_index) =>{
        let item = arrList[index].child[c_index];
        if(item.key == 'CARIETY_DISHES'){
            message.warn('菜品一栏不可以取消哦！')
            return;
        }
        arrList[index].child[c_index].hide = !item.hide;
        props.callBackSlider(arrList)
    }
    const handleOnMouseEnter =(key) => props.propsHover(key)
    const handleOnMouseLeave = () => props.propsHover('')

    return <div className="sliderBar">
        <div className="bar_head">模块控制：点击模块控制是否显示</div>
        <div className="bar_body">
            {
                arrList.map((item,index) =>{
                    let c_arr = item.child ?? [];
                    return <div className="item_body" key={index}>
                        <div className="bar_title">{item.title}</div>
                        <div className="c_body">
                        {
                            c_arr.map((c_item,c_index) =>{
                                return <div
                                    className={`c_item_body ${c_item.hide ? '': 'choosed_class'}`}
                                    key={c_index + index}
                                    onClick={() => handleClick(index,c_index)}
                                    onMouseEnter={() => handleOnMouseEnter(c_item.key)}
                                    onMouseLeave={() => handleOnMouseLeave()}
                                >
                                    {c_item.title}
                                </div>
                            })
                        }
                        </div>
                        
                    </div>
                })
            }
        </div>
    </div>

} 
export default SliderBar