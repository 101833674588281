import React, { useEffect, useState } from 'react'
import { Modal, Input, Row, Col, InputNumber, message } from 'antd'
import { postRequest, getRequest } from '../../../api/http';
import { apiUrl } from '../../../api/api_url';

import './edit.scss'

type EProps = {
    editFlg: boolean,
    currentTable: any,
    onClose(): void,
    getList(): void,
    dataSource: any,
}

const EditModal = (props:EProps)=>{

    const { editFlg, onClose, currentTable, getList, dataSource } = props
    const [ confirmLoading, setConfirmLoading] = useState(false) //过号弹窗(延桌设置)
    const [ tableInfo, setTableInfo] = useState<any>({}) //过号弹窗(延桌设置)
    // const [ tableType, setTableType] = useState('') //过号弹窗(延桌设置)
    useEffect(()=>{
        let tempTableInfo = JSON.parse(JSON.stringify(currentTable))
        setTableInfo(tempTableInfo)
        // setTableType(currentTable.type)
    },[currentTable])

    const onChangeMinNum = (e:any)=>{
        tableInfo.min_num = e
        setTableInfo(JSON.parse(JSON.stringify(tableInfo)))
    }
    const onChangeMaxNum = (e:any)=>{
        tableInfo.max_num = e
        setTableInfo(JSON.parse(JSON.stringify(tableInfo)))
    }

    const onChange = (e:any)=>{
        tableInfo.type = e.currentTarget.value
        setTableInfo(JSON.parse(JSON.stringify(tableInfo)))
    }

    const handleOk = async()=>{
        if(tableInfo.max_num<tableInfo.min_num){
            message.error('容纳人数最大人数不能小于最小人数')
            return false
        }
        //判断容纳人数是否在其他桌的容纳人数区间
        let array = dataSource.filter((item:any)=>tableInfo.id!=item.id&&tableInfo.min_num>=item.min_num&&tableInfo.min_num<=item.max_num)
        if(array.length>0){
            message.error('最小容纳人数处于'+array[0].type+'的可容纳人数区间')
            return false
        }
        //判断容纳人数是否在其他桌的容纳人数区间
        array = dataSource.filter((item:any)=>tableInfo.id!=item.id&&tableInfo.max_num>=item.min_num&&tableInfo.max_num<=item.max_num)
        if(array.length>0){
            message.error('最大容纳人数处于'+array[0].type+'的可容纳人数区间')
            return false
        }
        const param = {
            id: tableInfo.id,
            min_num: tableInfo.min_num,
            max_num: tableInfo.max_num,
            tag: tableInfo.tag,
            status: 0,
        }
        setConfirmLoading(true)
        const res:any = await postRequest(apiUrl.changeTableConfig,param)
        setConfirmLoading(false)
        if( res.code == 1 ){
            message.success('编辑成功')
            getList()
            onClose()
        }else{
            message.error(res.message||'编辑失败')
        }
    }

    return (
        <Modal
            title="编辑"
            visible={editFlg}
            onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={onClose}
            className="line-edit-modal"
        >
            <Row type="flex" align="middle" className="mb20">
                <Col span={6}>
                    <span>桌形名称（必填）</span>
                </Col>
                <Col span={18}>
                    <Input placeholder="请输入桌形名称" style={{width:150}} value={tableInfo.type} onChange={onChange}/>
                </Col>
            </Row>  
            <Row type="flex" align="middle">
                <Col span={6}>
                    <span>容纳人数（必填）</span>
                </Col>
                <Col span={18} className="flex-row">
                    <InputNumber min={1} max={100} value={tableInfo.min_num} onChange={onChangeMinNum} style={{width:60}} /><span className="mlr5">至</span>
                    <InputNumber min={1} max={100} value={tableInfo.max_num} onChange={onChangeMaxNum} style={{width:60}} /><span className="mlr5">人</span>
                </Col>
            </Row>  
        </Modal>        
    )
}

export default EditModal