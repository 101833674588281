import React, { useEffect, useState } from 'react'
import { Row, Button, Input, message, Modal, DatePicker, Radio, Checkbox, Select, Col, TimePicker, Icon, AutoComplete } from 'antd'
import DragTable from "./components/dragTable" //拖拽组件
import SmsSetting from "./components/smsSetting" //短信设置组件
import SmsEditModal from "./../message/sms/components/sms_edit_modal" //短信签名修改组件
import RechargeModal from './../message/sms/components/recharge_modal'
import RechargeCodeModal from './../message/sms/components/recharge_code_modal'
import RechargeSuccessModal from './../message/sms/components/recharge_success_modal'
import moment from 'moment'
import { getRequest,postRequest } from '../../api/http'
import { apiUrl } from '../../api/api_url'
import { useHistory, useLocation } from 'react-router-dom'

import './add.scss'

const { RangePicker } = DatePicker;
const { Option } = Select;

const MarketingAdd = ()=>{

    const history = useHistory()
    const location:any = useLocation()
    const dateFormat = 'YYYY-MM-DD HH:mm';
    const weekList = ['周一','周二','周三','周四','周五','周六','周日']
    const dayList = ()=>{
        let data = []
        for(var i=1; i<=31; i++){
            data.push(i)
        }
        return data
    }

    const [ visible, setVisible ] = useState<any>({
        smsSettingModal:false,
        smsEditModal:false,
        rechargeModal:false,
        rechargeCodeModal:false,
        rechargeSuccessModal:false,
    })
    const [ loading, setLoading ] = useState(false)
    const [ allGroup, setAllGroup ] = useState<any>({})
    const [ allGroupTemp, setAllGroupTemp ] = useState<any>({})
    const [ couponList, setCouponList ] = useState<any>([])
    // const [ currentCoupon, setCurrentCoupon ] = useState<any>([])
    const [ smsRefresh, setSmsRefresh ] = useState(false) //刷新短信配置字段
    const [ smsSettingType, setSmsSettingType ] = useState(0) //短信配置弹窗类型，1为仅有充值按钮
    const [ list, setList ] = useState([])
    const channel = [{id:1,index:1,text:'微信模板消息'},{id:2,index:2,text:'小程序订阅消息'},{id:3,index:3,text:'短信通知'}]
    const [ codeInfo, setCodeInfo] = useState<any>({}) //选择套餐后生成的二维码以及订单号
    // const [ isFilter, setIsFilter ] = useState('1') //是否过滤用户
    const [ formVal, setFormVal ] = useState<any>({
        name: null,
        group_id: null,
        group_id_temp: null,
        notice_type: 1,
        cycle_type: 1,
        coupon_id: null,
        coupon_id_temp: [''],
        channel: null,
        time: null,
        abnormal: null,
        start_time : moment(new Date().getTime()).format(dateFormat),
        end_time : moment(new Date().getTime()+30*60*60*24*1000).format(dateFormat),
        id: null,
        cycle_day_temp: [0],
        cycle_week: 0,
        is_siyu: false
    }) //表单值对象

    useEffect(() => {
        // console.log(moment(new Date().getTime()).format('YYYY-MM-DD HH:mm:ss'))
        // console.log(moment(new Date().getTime()+30*60*60*24*1000).format('YYYY-MM-DD HH:mm:ss'))
        // _initGroup()
        getRequest(apiUrl.getAllGroup).then((res:any)=>{
            if (res && res.code === 1) {
                let arr = res.data.map((item:any,index:number)=>{
                    item.name = item.type_name+'-'+item.name+'-总人数:'+item.member_num
                    return item
                })
                setAllGroup(arr)
                setAllGroupTemp(arr)
                // _initCoupon()
                getRequest(apiUrl.couponList, { pageSize: 100, position: 5, status:2 }).then((res:any)=>{
                    if (res.code === 1) {
                        setCouponList(res.data.list)
                        if(location.state&&location.state.group_id){
                            formVal.name = location.state.name
                            formVal.group_id = location.state.group_id+''
                            formVal.group_id_temp = location.state.group_id+(location.state.is_siyu?'siyu':'')
                            formVal.is_siyu = location.state.is_siyu
                            setFormVal({...formVal})
                        }
                        if(location.state&&location.state.id){
                            _initInfo()
                        }
                    }
                });
            }
        });
    }, [])

    //获取编辑的信息
    const _initInfo = async()=>{
        let params:any = {}
        if(location.state.id) params.id = location.state.id
        if(location.state.group_id ) params.group_id  = location.state.group_id
        const res: any = await getRequest(apiUrl.getGroupOperationInfo,params);
        if (res && res.code === 1 && res.data) {
            //设置表单初始值
            let params:any = {
                abnormal: res.data.abnormal?JSON.stringify(res.data.abnormal):null,
                end_time: res.data.activity_end_time,
                start_time: res.data.activity_start_time,
                channel: res.data.channel,
                coupon_id: res.data.coupon_id,
                coupon_id_temp: res.data.coupons?res.data.coupons:[''],
                group_id: res.data.group_id,
                group_id_temp:res.data.group_id+(res.data.is_siyu?'siyu':''),
                name: res.data.name,
                time: res.data.notice_hour,
                notice_type: res.data.notice_type,
                cycle_day_temp: res.data.cycle_type==1?[0]:res.data.cycle_day.split(','),
                cycle_type: res.data.cycle_type,
                cycle_week: res.data.cycle_type==1?res.data.cycle_day:0,
                is_siyu: res.data.is_siyu,
            }
            if(location.state.status!=1) params.id = res.data.id

            setFormVal(params)

            //通知渠道数据初始化
            if(res.data.channel){
                let channelArr:any = []
                let i = 1
                res.data.channel.split(',').map((item:string)=>{
                    let temp:any = channel.filter((subItem:any)=>item==subItem.id)
                    if(channel.filter((subItem:any)=>item==subItem.id).length>0){
                        temp[0].index = i
                        channelArr.push(temp[0])
                        i++
                    }
                })
                setList(channelArr)
            }
        }
    }

    // 分组列表
    // const _initGroup = async () => {
    //     const res: any = await getRequest(apiUrl.getAllGroup);
    //     if (res && res.code === 1) {
    //         let arr = res.data.map((item:any,index:number)=>{
    //             item.name = item.type_name+'-'+item.name+'-总人数:'+item.member_num
    //             return item
    //         })
    //         setAllGroup(arr)
    //         setAllGroupTemp(arr)
    //     }
    // }
    // 优惠券列表
    const _initCoupon = async () => {
        // const res: any = await getRequest(apiUrl.couponList, { pageSize: 100, position: 5 });
        const res: any = await getRequest(apiUrl.couponList, { pageSize: 100, position: 5 });
        if (res.code === 1) {
            setCouponList(res.data.list)
        }
    }

    const columns = [
        {
            title: "通知优先级",
            dataIndex: 'index',
            key: 'index',
        },
        {
            title: "通知渠道",
            dataIndex: 'text',
            key: 'text',
        },
        {
            title: "排序",
            dataIndex: 'sort',
            key: 'sort',
            render:(text:string,record:any,index:number)=><Icon type="unordered-list" />
        },
    ]

    //通知渠道选择
    const channelChange = (e:any) => {
        let arr:any = channel.filter((item,index)=>e.some((subItem:number)=>index==subItem-1))
        let channelArr:any = []
        let arrTemp = arr.map((item:any,index:number)=>{
            item.index = index+1
            channelArr.push(item.id)
            return item
        })
        changeFieldVal(channelArr.join(','),'channel')
        setList(arrTemp)
    }

    const closeModal = (key:string) => {
        setVisible({
            ...visible,
            [key]:false
        })
    }

    const openModal = (key:string,info:any={}) => {
        if(key == 'rechargeCodeModal'){
            setCodeInfo(info)
        }
        setVisible({
            ...visible,
            rechargeModal:false,
            rechargeCodeModal:false,
            rechargeSuccessModal:false,
            [key]:true
        })
    }

    //拖拽后新列表生成
    // const handleTableDrag = (list:any) => {
    //     let channelArr:any = []
    //     let arrTemp = list.map((item:any,index:number)=>{
    //         item.index = index+1
    //         channelArr.push(item.id)
    //         return item
    //     })
    //     changeFieldVal(channelArr.join(','),'channel')
    //     setList(arrTemp)
    // }  

    //监听字段变化
    const changeFieldVal = ( e:any, field:string ) => {
                // console.log(moment(new Date().getTime()).format('YYYY-MM-DD HH:mm:ss'))
        // console.log(moment(new Date().getTime()+30*60*60*24*1000).format('YYYY-MM-DD HH:mm:ss'))
        let fieldObj:any = {}
        let formVal_abnormal = formVal.abnormal?JSON.parse(formVal.abnormal):''
        let start_time:any
        let end_time:any
        switch(true){
            case field==='abnormal_channel'||field==='abnormal_mobile' :
                    if(field==='abnormal_channel'){
                        fieldObj.abnormal  = { ...formVal_abnormal,'channel': e, 'mobile':'' }
                    }else{
                        fieldObj.abnormal  = { ...formVal_abnormal,'mobile': e }
                    }
                    fieldObj.abnormal = JSON.stringify(fieldObj.abnormal)
                    break
            case field==='between_times' :
                    fieldObj  = { 'start_time': e[0], 'end_time': e[1] }
                    break
            case field==='notice_type' :
                    //持续推送，每周活动开始时间默认当天，每周活动结束时间默认30天后
                    //持续推送，每月活动开始时间默认当天，每月活动结束时间默认90天后
                    start_time = moment(new Date().getTime()).format(dateFormat)
                    end_time = moment(new Date().getTime()+30*60*60*24*1000).format(dateFormat)
                    if(e==1){
                        end_time = moment(new Date().getTime()+90*60*60*24*1000).format(dateFormat)
                    }            
                    fieldObj  = { [field]: e, coupon_id_temp:[''],'start_time': start_time, 'end_time': end_time, cycle_day_temp: [0], cycle_week : 0 }                
                    break
            case field==='cycle_type' :
                    //持续推送，每周活动开始时间默认当天，每周活动结束时间默认30天后
                    //持续推送，每月活动开始时间默认当天，每月活动结束时间默认90天后
                    start_time = moment(new Date().getTime()).format(dateFormat)
                    end_time = moment(new Date().getTime()+30*60*60*24*1000).format(dateFormat)
                    if(e==2){
                        end_time = moment(new Date().getTime()+90*60*60*24*1000).format(dateFormat)
                    }
                    fieldObj = { [field]: e, 'start_time': start_time, 'end_time': end_time, cycle_day_temp: [0], cycle_week : 0}                
                    break
            case field==='group_id_temp' :
                    console.log(e)
                    console.log(e.indexOf('siyu')!==-1)
                    if(e.indexOf('siyu')!==-1){
                        fieldObj = { 'group_id_temp': e, 'group_id': e.substr(0,e.length-4), 'is_siyu': true,}
                    }else{
                        fieldObj = { 'group_id_temp': e, 'group_id': e, 'is_siyu': false,}
                    }                
                    break
            default:
                    fieldObj = { [field]: field==='coupon_id_temp'||field==='cycle_day_temp'||field==='cycle_week'?e:e.trim() }
                    break
        }
        setFormVal({
            ...formVal,
            ...fieldObj
        })
    }

    //选择优惠券
    const changeCoupon = (index:number,e:any)=>{
        console.log(e)
        formVal.coupon_id_temp[index] = e;
        changeFieldVal(formVal.coupon_id_temp,'coupon_id_temp')
    }

    //添加优惠券
    const addCoupon = ()=>{
        formVal.coupon_id_temp.push('');
        changeFieldVal(formVal.coupon_id_temp,'coupon_id_temp')
    }

    //删除优惠券
    const delCoupon = (index:number)=>{
        formVal.coupon_id_temp.splice(index,1);
        changeFieldVal(formVal.coupon_id_temp,'coupon_id_temp')
    }

    //选择每月哪天
    const changeDay = (index:number,e:any)=>{
        console.log(formVal.cycle_day_temp.filter((item:any)=>item==e))
        if(formVal.cycle_day_temp.filter((item:any)=>item==e).length>0&&e!=0){
            message.error('已存在相同的日期')
            e = 0 
        }
        formVal.cycle_day_temp[index] = e;
        changeFieldVal(formVal.cycle_day_temp,'cycle_day_temp')
    }

    //添加每月哪天
    const addDay = ()=>{
        formVal.cycle_day_temp.push(0);
        changeFieldVal(formVal.cycle_day_temp,'cycle_day_temp')
    }

    //删除每月哪天
    const delDay = (index:number)=>{
        formVal.cycle_day_temp.splice(index,1);
        changeFieldVal(formVal.cycle_day_temp,'cycle_day_temp')
    }
    //选择每周哪天
    const changeWeek = (e:any)=>{
        changeFieldVal(e,'cycle_week')
    }


    //表单提交
    const onSubmit = async() => {
        let flag = false
        Object.keys(formVal).map((item)=>{
            switch(item){
                case 'name':
                    if(!formVal[item]){
                        message.error('活动名称不能为空')
                        flag = true
                    }
                    break;
                case 'group_id':
                    if(!formVal[item]){
                        message.error('请选择发放用户')
                        flag = true
                    }
                    break;
                case 'notice_type':
                    if(formVal[item]==1){
                        if(formVal.cycle_type==1){
                            if(!formVal.cycle_week||!formVal.start_time||!formVal.end_time){
                                message.error('请选择每周活动时间')
                                flag = true
                            }
                        }else{
                            if(formVal.cycle_day_temp.filter((item:any)=>item==0).length>0||!formVal.start_time||!formVal.end_time){
                                message.error('请选择每月活动时间')
                                flag = true
                            }
                        }                        
                        // if(!formVal.start_time||!formVal.end_time){
                        //     message.error('请选择开始时间和结束时间')
                        //     flag = true
                        // }
                        //持续需要选择推送每天通知时间
                        if(!formVal['time']){
                            message.error('请设置通知时间')
                            flag = true
                        }
                    }else if(formVal[item]==2){
                        // if(!formVal.start_time){
                        //     message.error('请选择指定时间')
                        //     flag = true
                        // }
                    }else{
                        // formVal.start_time = ''
                        // formVal.end_time = ''                      
                    }
                    break;
                case 'coupon_id_temp':
                    if(formVal[item].filter((item:any)=>item==0).length>0){
                        message.error('请选择发放券')
                        flag = true
                    }
                    break;
                case 'channel':
                    if(!formVal[item]){
                        message.error('请选择至少选择一个通知渠道')
                        flag = true
                    }
                    break;
                case 'abnormal':
                    if(formVal[item]){
                        if(JSON.parse(formVal[item]).channel&&JSON.parse(formVal[item]).channel.indexOf('3')!==-1){
                            if(!JSON.parse(formVal[item]).mobile){
                                message.error('请填写接受通知的手机')
                                flag = true
                            }else{
                                if(!(/^1[3456789]\d{9}$/.test(JSON.parse(formVal[item]).mobile))){
                                    message.error('请填写正确的手机')
                                    flag = true
                                }
                            }

                        }
                    }
                    break;
                }
        })

        if(flag){
            return false
        }

        if(formVal.channel.indexOf('3')!==-1){
            //勾选短信通知，判断是否还有短信
            const res:any = await getRequest(apiUrl.getSmsInfo);
            if(res.code==1&&res.data){
                setSmsSettingType(0)
                if(res.data.number==0){
                    setSmsSettingType(1)
                    openModal('smsSettingModal')
                    return false
                }
            }else{
                message.error(res.message||'获取短信配置失败')
                return false
            }
        }

        formVal.coupon_id = formVal.coupon_id_temp.join(',')
        formVal.cycle_day = formVal.cycle_day_temp.join(',')
        if(formVal.cycle_type==1){
            formVal.cycle_day = formVal.cycle_week
        }

        if(formVal.id){
            if(formVal.notice_type!=1){
                //查询重复用户
                var res:any = await getRequest(apiUrl.queryOtherOperationCoupon, {coupon_id:formVal.coupon_id})
                if(res.code==1&&res.data){
                    if(res.data.repeat_fans.length!=0){
                        filterTip(2,res.data.repeat_fans)        
                    }else{
                        submitTip()
                    }
                }else{
                    message.error(res.message||'获取重复用户失败')
                    return
                }
            }else{
                submitTip()
            }
        }else{
            try{
                if(formVal.notice_type!=1){
                    //查询重复用户
                    var res:any = await getRequest(apiUrl.queryOtherOperationCoupon, {coupon_id:formVal.coupon_id})
                    if(res.code==1&&res.data){
                        if(res.data.repeat_fans.length!=0){
                            filterTip(1,res.data.repeat_fans)                   
                        }else{
                            setLoading(true)
                            message.loading({ content: '正在提交...', });
                            var res:any = await postRequest(apiUrl.saveGroupOperation, formVal)
                            setLoading(false)
                            if(res.code == 1){
                                message.success('提交成功')
                                history.replace('/sidebar/marketingIndex')
                            }else{
                                message.error(res.message||'提交失败')
                            }
                        }
                    }else{
                        message.error(res.message||'获取重复用户失败')
                        return
                    }
                }else{
                    setLoading(true)
                    message.loading({ content: '正在提交...', });
                    var res:any = await postRequest(apiUrl.saveGroupOperation, formVal)
                    setLoading(false)
                    if(res.code == 1){
                        message.success('提交成功')
                        history.replace('/sidebar/marketingIndex')
                    }else{
                        message.error(res.message||'提交失败')
                    }
                }
            }catch(err){
                setLoading(false)
                console.log(err)
            }
        }
    }

    const filterTip = async(type:number=1,repeat_fans:any) => {
        let isFilter = '1';
        const modal = Modal.confirm({
            title: '提示',
            icon: null,
            content: 
                <div>
                    <div className="mb20">有 {repeat_fans.length} 用户，参与其他运营活动，是否过滤该用户参与此活动</div>
                    <Radio.Group value={isFilter} onChange={(e:any)=>{updateContent(e.target.value)}}>
                        <Radio value="1">确认过滤</Radio>
                        <Radio value="2">不过滤用户</Radio>
                    </Radio.Group>
                </div>,
            okText: '确认',
            cancelText: '取消',
            width:550,
            onOk:async()=>{
                if(isFilter == '1') formVal.filter_fan_ids = repeat_fans.join(',')
                if(type==1){
                    submitFinjish()
                }else{
                    submitTip()
                }
            },
        }); 
        const updateContent = (value:any) => {
            isFilter = value
            modal.update({  // 改变后的modal
            content: 
                <div>
                    <div className="mb20">有 {repeat_fans.length} 用户，参与其他运营活动，是否过滤该用户参与此活动</div>
                    <Radio.Group value={value} onChange={(e:any)=>{updateContent(e.target.value)}}>
                        <Radio value="1">确认过滤</Radio>
                        <Radio value="2">不过滤用户</Radio>
                    </Radio.Group>
                </div>,
            })
        }                    
        
    }

    const submitTip = ()=>{
        Modal.confirm({
            title: '提示',
            icon: null,
            content: formVal.notice_type==3?'修改后，新的通知将立即生效':'通知时间修改后，新的通知将于（'+formVal.start_time+'）开始生效。',
            okText: '确认',
            cancelText: '取消',
            onOk:()=>{
                submitFinjish()
            }
        }); 
    }

    const submitFinjish = async()=>{
        message.loading({ content: '正在提交...', });
        var res:any = await postRequest(apiUrl.saveGroupOperation, formVal)
        if(res.code == 1){
            message.success('提交成功')
            history.replace('/sidebar/marketingIndex')
        }else{
            message.error(res.message||'提交失败')
        }
    }

    // const getAllGroup = (e:any) =>{
    //     setAllGroup(allGroupTemp.filter((item:any) => item.name.indexOf(e)!==-1))
    // }

    return(
        <div className="marketing_add l_content">
            <div className="title_text">发放优惠券</div>
            <div className="fz16 color333 mb15 fzweight">基础信息</div>
            <div className="row_view mbt16">
                <div className="sub_title">活动名称：</div>
                <Input className="sub_unInput" value={formVal.name} placeholder="请输入活动名称" onChange={(e)=>{changeFieldVal(e.currentTarget.value,'name')}}/>
            </div>
            <div className="row_view mbt16">
                <div className="sub_title">发放用户 :</div>
                {
                    formVal.group_id_temp?(
                        <AutoComplete
                            placeholder="请选择"
                            disabled={location.state&&location.state.is_disabled?true:false}
                            defaultValue={formVal.group_id_temp?formVal.group_id_temp+'':''}
                            // value={formVal.group_id_temp?formVal.group_id_temp+'':''}
                            onChange={(e) => !e && changeFieldVal('','group_id_temp')}
                            filterOption={(inputValue, option:any) =>
                                option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            }
                            onSelect={(e, option) => { changeFieldVal(e,'group_id_temp') }}
                            className="sub_unInput"
                            key={1}
                        >
                            {
                                allGroup&&allGroup.length>0&&allGroup.map((item:any,index:number)=>{
                                    return <AutoComplete.Option key={index} value={item.id+(item.is_siyu?'siyu':'')}>{item.name}</AutoComplete.Option>
                                })
                            }   
                        </AutoComplete>
                    ):(
                        <AutoComplete
                            placeholder="请选择"
                            disabled={location.state&&location.state.group_id?true:false}
                            defaultValue={formVal.group_id_temp?formVal.group_id_temp+'':''}
                            filterOption={(inputValue, option:any) =>
                                option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            }
                            onSelect={(e) => { changeFieldVal(e,'group_id_temp') }}
                            className="sub_unInput"
                            key={2}
                        >
                            {
                                allGroup&&allGroup.length>0&&allGroup.map((item:any,index:number)=>{
                                    return <AutoComplete.Option key={index} value={item.id+(item.is_siyu?'siyu':'')}>{item.name}</AutoComplete.Option>
                                })
                            }   
                        </AutoComplete>                        
                    )
                }
                {/* <Select
                    mode="multiple"
                    allowClear
                    // className="sub_unInput"
                    style={{width:400}}
                    // mode="multiple"
                    placeholder="请选择"
                    // defaultValue={0}
                    value={formVal.group_id_temp?formVal.group_id_temp:0}
                    onChange={(e:any)=>{changeFieldVal(e,'group_id_temp')}}
                    disabled={location.state&&location.state.group_id?true:false}
                >
                    <Option value={0}>请选择</Option>
                    {
                        allGroup&&allGroup.length>0&&allGroup.map((item:any,index:number)=>{
                            return <Option key={index} value={item.id+(item.is_siyu?'siyu':'')}>{item.type_name}-{item.name}-总人数:{item.member_num}</Option>
                        })
                    }
                </Select> */}
                {/* <Input className="sub_unInput" value={formVal.shop_name} placeholder="请选择" onChange={(e)=>{changeFieldVal(e.currentTarget.value,'shop_name')}}/> */}
            </div>
            <div className="row_view mbt16 align_start">
                <div className="sub_title">执行时间 :</div>
                <div>
                    <Radio.Group value={formVal.notice_type!=1?3:1} onChange={(e)=>{changeFieldVal(e.target.value,'notice_type')}}>
                        <div>
                            <Radio className="mb10" value={1}>持续推送</Radio>
                        </div>
                        <div className="mb10 color_ccc">达到该组满足条件的新增用户，会自动收到营销通知</div>
                        {
                            formVal.notice_type==1&&(
                                <Radio.Group className="ml20" value={formVal.cycle_type} onChange={(e)=>{changeFieldVal(e.target.value,'cycle_type')}}>
                                    <div className="mb20">
                                        <Radio value={1} className="row_radio">每周</Radio>
                                        <Select
                                            style={{width:200}}
                                            value={formVal.cycle_week?formVal.cycle_week+'':0}
                                            onChange={(e:any)=>{changeWeek(e)}}
                                        >
                                            <Option value={0}>请选择</Option>
                                            {
                                                weekList.map((item,index)=>{
                                                    return <Option value={index+1+''} key={index}>{item}</Option>
                                                })
                                            }
                                        </Select> 
                                    </div>
                                    {
                                        formVal.cycle_type==1&&(
                                            <div className="mb20">
                                                <span className="row_label">活动时间</span>
                                                <RangePicker
                                                    style={{width:400}}
                                                    className="mr30"
                                                    format={dateFormat}
                                                    showTime={{ format: dateFormat }}
                                                    // defaultValue={formVal.start_time?[moment(formVal.start_time, dateFormat), moment(formVal.end_time, dateFormat)]:[]}
                                                    value = {formVal.start_time&&formVal.end_time?[moment(formVal.start_time, dateFormat), moment(formVal.end_time, dateFormat)]:[]}
                                                    onChange={(e:any, dateString) => {
                                                        changeFieldVal(dateString,'between_times')
                                                    }}
                                                    placeholder={['开始时间', '结束时间']} />
                                                (自动延期30天)                                              
                                            </div>                                            
                                        )
                                    }
                                    <div className="mb20">
                                        <Radio value={2} className="row_radio">每月</Radio>
                                        {
                                            formVal.cycle_day_temp&&formVal.cycle_day_temp.length>0&&formVal.cycle_day_temp.map((pItem:any,pIndex:number)=>{
                                                return <span className="mr30" key={pIndex}>
                                                    <Select
                                                        className="mr10"
                                                        style={{width:100}}
                                                        value={pItem?pItem+'':0}
                                                        onChange={(e:any)=>{changeDay(pIndex,e)}}
                                                    >
                                                        <Option value={0}>请选择</Option>
                                                        {
                                                            dayList().map((item,index)=>{
                                                                return <Option value={index+1+''} key={index}>{item}</Option>
                                                            })
                                                        }
                                                    </Select> 
                                                    日、
                                                </span>
                                            })
                                        }
                                        {
                                            formVal.cycle_day_temp.length<4&&<span className="add_day" onClick={()=>{addDay()}}>添加</span>
                                        }
                                        {
                                            formVal.cycle_day_temp.length>1&&<span className="del_day" onClick={()=>{delDay(formVal.cycle_day_temp.length-1)}}>删除</span>
                                        }
                                    </div>
                                    {
                                        formVal.cycle_type==2&&(
                                            <div className="mb20">
                                                <span className="row_label">活动时间</span>
                                                <RangePicker
                                                    style={{width:400}}
                                                    className="mr30"
                                                    format={dateFormat}
                                                    showTime={{ format: dateFormat }}
                                                    // defaultValue={formVal.start_time?[moment(formVal.start_time, dateFormat), moment(formVal.end_time, dateFormat)]:[]}
                                                    value = {formVal.start_time&&formVal.end_time?[moment(formVal.start_time, dateFormat), moment(formVal.end_time, dateFormat)]:[]}
                                                    onChange={(e:any, dateString) => {
                                                        changeFieldVal(dateString,'between_times')
                                                    }}
                                                    placeholder={['开始时间', '结束时间']} />
                                                (自动延期90天)                                              
                                            </div>
                                        )
                                    }
                                </Radio.Group>
                            )
                        }
                        <div className="mb10">
                            <Radio value={3}>指定时间</Radio>     
                        </div> 
                        <div className="mb20 color_ccc">在指定时间向计划人群发送营销通知</div>   
                        {
                            formVal.notice_type!=1&&(
                                <Radio.Group className="ml20" value={formVal.notice_type} onChange={(e)=>{changeFieldVal(e.target.value,'notice_type')}}>

                                    <Radio className="mb10" value={3}>立即推送</Radio>
                                    <div>
                                        <Radio value={2}>指定时间</Radio>
                                        <DatePicker
                                            showTime
                                            showToday={false}
                                            format={dateFormat}
                                            value={formVal.start_time?moment(formVal.start_time, dateFormat):null}
                                            onChange={(e:any, dateString) => {
                                                changeFieldVal(dateString,'start_time')
                                            }}
                                        />
                                    </div>
                                </Radio.Group>
                            )
                        }
                    </Radio.Group>
                </div>
            </div>
            <div className="fz16 color333 mb15 fzweight">优惠礼包</div>
            <div className="row_view mbt16 align_start">
                <div className="sub_title">发放券：</div>
                <div className="column_center">
                    <div>
                        <div className="color_red mb20">同一个客户近7天只能收到一条运营通知，活动时间内优惠券循环推送</div>
                        {
                            formVal.coupon_id_temp&&formVal.coupon_id_temp.length>0&&formVal.coupon_id_temp.map((fItem:any,fIndex:any)=>{
                                return <div className="mb20" key={fIndex}>
                                    {
                                        fItem?(
                                            <AutoComplete
                                                placeholder="请选择"
                                                defaultValue={fItem?fItem+'':''}
                                                filterOption={(inputValue, option:any) =>
                                                    option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                }
                                                onChange={(e) => !e && changeCoupon(fIndex,'')}
                                                onSelect={(e) => { changeCoupon(fIndex,e) }}
                                                className="sub_unInput"
                                                key={fItem+'defalut'}
                                            >
                                                {
                                                    couponList&&couponList.length>0&&couponList.map((item:any,index:number)=>{
                                                        return <AutoComplete.Option key={index} value={item.id+''}>
                                                            {`${item.name}--${item.expire_info.type == 'day' ? '自领取日'+ item.expire_info.day + '天内' : item.expire_info.start_time + '-' + item.expire_info.end_time}`}
                                                            {/* {item.name} --{item.expire_info.type == 'day' ? '自领取日'+ item.expire_info.day + '天内' : item.expire_info.start_time + '-' + item.expire_info.end_time} */}
                                                        </AutoComplete.Option>
                                                    })
                                                }   
                                            </AutoComplete>                                     
                                        ):(
                                            <AutoComplete
                                                placeholder="请选择"
                                                filterOption={(inputValue, option:any) =>
                                                    option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                }
                                                onSelect={(e) => { changeCoupon(fIndex,e) }}
                                                className="sub_unInput"
                                                key={fItem}
                                            >
                                                {
                                                    couponList&&couponList.length>0&&couponList.map((item:any,index:number)=>{
                                                        return <AutoComplete.Option key={index} value={item.id+''}>
                                                            {`${item.name}--${item.expire_info.type == 'day' ? '自领取日'+ item.expire_info.day + '天内' : item.expire_info.start_time + '-' + item.expire_info.end_time}`}
                                                            {/* {item.name} --{item.expire_info.type == 'day' ? '自领取日'+ item.expire_info.day + '天内' : item.expire_info.start_time + '-' + item.expire_info.end_time} */}
                                                        </AutoComplete.Option>
                                                    })
                                                }   
                                            </AutoComplete>                                     

                                        )
                                    }
                                    {/* <Select value={fItem?fItem:0} className="mb10" style={{width:400}} onChange={(e:any)=>{changeCoupon(fIndex,e)}}>
                                        <Option value={0}>请选择</Option>
                                        {
                                            couponList.map((item:any,index:number)=>{
                                                return <Option key={item.id} value={item.id}>
                                                    {item.name} --{item.expire_info.type == 'day' ? '自领取日'+ item.expire_info.day + '天内' : item.expire_info.start_time + '-' + item.expire_info.end_time}
                                                </Option>
                                            })
                                        }
                                    </Select> */}
                                    <div className="color_ccc">{fIndex>=1?'上一张优惠券未使用的用户持续推送':'发放优惠券能有效刺激转化（优惠券将直接发给客户，不需要用户点击领取）'}</div>   
                                </div>  
                            })
                        }
                    </div>
                        {
                            formVal.notice_type==1&&(
                                <>
                                    <div className="aciton_bt">
                                        {
                                            formVal.coupon_id_temp.length<4&&<span className="add_day" onClick={()=>{addCoupon()}}>继续添加</span>
                                        }
                                        {
                                            formVal.coupon_id_temp.length>1&&<span className="del_day" onClick={()=>{delCoupon(formVal.coupon_id_temp.length-1)}}>删除</span>
                                        }
                                    </div>
                                    <div className="color_333 mt10">*持续推送可设置4张不同优惠信息循环推送</div>
                                </>
                            )
                        }
                </div>
            </div>
            <div className="fz16 color333 mb15 fzweight">通知配置</div>
            <div className="row_view mbt16 align_start">
                <div className="sub_title">通知渠道：</div>
                <Checkbox.Group onChange={(e)=>{channelChange(e)}} value={formVal.channel?formVal.channel.split(','):[]} >
                    <Checkbox className="mb10" value={'1'}>服务号模板消息</Checkbox>
                    <div className="mb30 color_ccc">频繁发送营销模板消息存在被封禁的风险</div>
                    {/* <Checkbox className="mb10" value={'2'}>小程序订阅消息</Checkbox>
                    <div className="mb30 color_ccc">用户需按次订阅，订阅后才可发送消息</div> */}
                    <Checkbox className="mb10" value={'3'}>短信通知</Checkbox>
                    <div className="color_ccc">
                        扣除短信条数，为了发送成功，请确保在消息推送中的设置了自定义签名
                        <Button
                            type="link"
                            onClick={()=>{
                                // if(!formVal.coupon_id) {
                                //     message.error('请选择发放的优惠券')
                                //     return false
                                // }
                                // let couponInfo = couponList.filter((item:any) => item.id==formVal.coupon_id)
                                // setCurrentCoupon(couponInfo.length>0?couponInfo[0]:{})
                                openModal('smsSettingModal')
                        }}>
                            配置短信通知
                        </Button>
                    </div>
                </Checkbox.Group>
            </div> 
            {/* <div className="row_view mbt16 align_start">
                <div className="sub_title">通知规则</div>
                <div>
                    <div className="mb10 color_red">同一个客户近7天只能收到一条运营通知</div>
                    <div className="mb10">仅通知一个渠道</div>
                    <div className="mb10 color_ccc">同一个客户仅通知优先级最高的渠道，拖拽可更改排序</div>
                    <DragTable
                        rowKey="id"
                        dataSource={list}
                        columns={columns}
                        pagination={false}
                        onChange={handleTableDrag}
                    />
                </div>
            </div>  */}
            {
                formVal.notice_type==1&&(
                    <div className="row_view mbt16 align_start">
                        <div className="sub_title">时间设置：</div>
                        <div>
                            <div className="mb10">
                                每天
                                {
                                    formVal.time?(
                                        <TimePicker
                                            className="ml10 mr10"
                                            format={'HH:mm'}
                                            value={formVal.time?moment(formVal.time, 'HH:mm:ss'):moment('09:00:00', 'HH:mm:ss')}
                                            onChange={(e:any, timeString) => {
                                                changeFieldVal(timeString,'time')
                                            }}
                                            disabledHours={()=>[0,1,2,3,4,5,6,7,8,23]}
                                        />
                                    ):(
                                        <TimePicker
                                            className="ml10 mr10"
                                            format={'HH:mm'}
                                            onChange={(e:any, timeString) => {
                                                changeFieldVal(timeString,'time')
                                            }}
                                            disabledHours={()=>[0,1,2,3,4,5,6,7,8,23]}
                                        />
                                    )
                                }

                                开始发送通知
                            </div>
                            <div className="color_ccc times_tip">为避免骚扰用户，通知时间范围 09:00-22:00</div>
                        </div>
                    </div>
                )
            }
            <div className="fz16 color333 mb15 fzweight">异常通知</div>
            <div className="row_view mbt16 align_start">
                <div className="sub_title">提醒方式：</div>
                <div style={{width:'100%'}}>
                    <div className="mb10">在活动即将到期、优惠礼包不足和短信条数不足的情况下提醒</div>
                    <Checkbox.Group style={{width:'100%'}} onChange={(e)=>{changeFieldVal(e.join(','),'abnormal_channel')}}  value={formVal.abnormal?JSON.parse(formVal.abnormal).channel.split(','):[]}>
                        {/* <Checkbox className="mb10" value={'1'}>通过公众号推送通知我</Checkbox> */}
                        <Row>
                            <Checkbox className="mb10" value={'3'}>通过短信通知我</Checkbox>
                        </Row>
                        {
                            formVal.abnormal&&JSON.parse(formVal.abnormal).channel.indexOf('3')!==-1&&(
                                <Row className="ml20">
                                    <Col span={2} className="mt6">
                                        接受通知的手机号
                                    </Col>
                                    <Col span={12}>
                                        <Input maxLength={11} value={JSON.parse(formVal.abnormal).mobile} placeholder="请输入手机号" className="sub_unInput mb10" onChange={(e)=>{changeFieldVal(e.currentTarget.value,'abnormal_mobile')}}/>
                                        <div className="color_ccc">请注意，短信通知将扣除门店短信的条数</div>
                                    </Col>
                                </Row>
                            )
                        }
                    </Checkbox.Group>
                </div>
            </div>
            <Button type="primary" onClick={()=>{onSubmit()}} style={{marginLeft:200}} loading={loading}>提交</Button>
            {
                visible.smsSettingModal&&<SmsSetting visible={visible.smsSettingModal} smsSettingType={smsSettingType} onClose={()=>{closeModal('smsSettingModal')}} onOpen={()=>{setSmsRefresh(false);openModal('smsEditModal')}} onOpenRechargeModal={()=>{openModal('rechargeModal')}} smsRefresh={smsRefresh}/>
            }
            <SmsEditModal visible={visible.smsEditModal} onClose={()=>{setSmsRefresh(true);closeModal('smsEditModal')}}/>
            {
                visible.rechargeModal&&<RechargeModal visible={visible.rechargeModal} onClose={()=>{closeModal('rechargeModal')}} onOpen={(info:any)=>{setSmsRefresh(false);openModal('rechargeCodeModal',info)}}/>
            }
            {
                visible.rechargeCodeModal&&<RechargeCodeModal visible={visible.rechargeCodeModal} onClose={()=>{closeModal('rechargeCodeModal')}} onOpen={()=>{openModal('rechargeSuccessModal')}} codeInfo={codeInfo}/>
            }
            {
                visible.rechargeSuccessModal&&<RechargeSuccessModal visible={visible.rechargeSuccessModal} onClose={()=>{setSmsRefresh(true);closeModal('rechargeSuccessModal')}}/>
            }

        </div>
    )
}

export default MarketingAdd