import React, { useEffect, useState } from 'react'
import {messageTaskType} from './components/dataType'
import { getRequest } from '../../api/http'
import { apiUrl } from '../../api/api_url'
import {messageTaskAudit, messagePush, messageTaskDelete} from './components/request'
import { message, Col, Row, Button, Table, Tag, Popconfirm, Modal, Radio } from 'antd'
import MessageTaskFrom from './messageTaskFrom'
import { getIsPermisson, messageCustom } from '../../utils/permisstionUtil'

const Message = ()=>{

    const[tableSource,setTableSource] = useState([])
    // const[tableCount,setTableCount] = useState([])

    const[auditVisible,setAuditVisible] = useState(false)
    const[auditStatus,setAuditStatus] = useState(5)

    const [currenPage,setcurrenPage] = useState(1)
    const [pageSize,setpageSize] = useState(10)


    const [tableTotal,settableTotal] = useState(10)



    const[itemEditData,setItemEditData] = useState<messageTaskType>({})

    const [taskFormVisible,setTaskFormVisible] = useState(false)

    useEffect(()=>{
        getList(currenPage)
    },[])


    const pageChange = (e:any) => {
        setcurrenPage(e);
        getList(e);

    }

    const getList = async(currenPageParam?:number, page_size?: number)=>{
        if(currenPageParam === undefined){
            currenPageParam = 1
        }
        const res:any = await getRequest(`${apiUrl.messageTaskList}`,{pageSize:page_size ? page_size : pageSize,current:currenPageParam});
        if(res.code === 1){
            settableTotal(res.data.count)
            setTableSource(res.data.list)
        }else{
            message.error(res.message)
        }
    }

    const editAction = (data:messageTaskType) => {
        if(!getIsPermisson('2_4_1_2')){
            messageCustom();
            return;
          }
        setItemEditData(data)
        setTaskFormVisible(true)
    }

    const addAction = ()=> {
        if(!getIsPermisson('2_4_1_1')){
            messageCustom();
            return;
          }
        setItemEditData({})
        setTaskFormVisible(true)
    }

    const deleteAction = (id:number) => {
        if(!getIsPermisson('2_4_1_5')){
            messageCustom();
            return;
          }
        let param = {
            id:id,
        }
        messageTaskDelete(param).then((res) => {
            if(res.code === 1){
                message.info('删除成功')
                getList();
            }else{
                message.error(res.message)
            }
        }).catch((e) => {
            message.error(e)
        })
    }

    const auditAction = (data:messageTaskType) => {
        if(!getIsPermisson('2_4_1_3')){
            messageCustom();
            return;
          }
        setItemEditData(data)
        setAuditVisible(true)
        // messageTaskAudit({shop_id:1,id:data.id,})
    }

    const auditActionRequest = () => {
        messageTaskAudit({id:itemEditData.id,status:auditStatus}).then((res) => {
            if(res.code === 1){
                message.info('审核成功')
                getList();
            }else{
                message.error(res.message)
            }
        }).finally(() => {
            setAuditStatus(5)
            setAuditVisible(false)
        })
    }

    const closeAndRefresh=(data:boolean,isRefresh:boolean|undefined) => {
        setTaskFormVisible(data)
        if(isRefresh){
            getList();
        }
    }

    const immediatelyAction = (data:messageTaskType) => {
        if(!getIsPermisson('2_4_1_4')){
            messageCustom();
            return;
          }
        let param = {
            id:data.id,
        }
        messagePush(param).then((res) => {
            if(res.code === 1){
                message.info('发送成功')
            }else{
                message.error(res.message)
            }
        }).catch((e) => {
            message.error(e)
        })
    }


    const columns = [
        {
          title: '编号',
          dataIndex: 'id',
          key: 'id',
          width: 80,
        },
        {
            title: '推送给谁',
            dataIndex: 'fans_ids',
            key: 'fans_ids',
            width: 80,
            render:(rowdata:any,record: any, idx: number)=>{
                return (
                    <div>
                    {
                        rowdata === 0
                        ? <Tag color="#87d068">所有用户</Tag>
                        : <div>
                            {record.fans_list.map((item:any) => {
                                return <div key={item.id}>{item.nickname}</div>
                                })
                            }
                            </div>
                    }
                    </div>
                )
            }
        },
        {
            title: '模板id',
            dataIndex: 'template_id',
            key: 'template_id',
            width: 80,
        },
        {
            title: '处理状态',
            dataIndex: 'status',
            key: 'status',
            width: 80,
            render:(rowdata:any,record: any, idx: number)=>{
                return (
                    <div>{
                        rowdata === 1 ? <Tag color="#87d068">待审核</Tag> :
                        rowdata === 5 ? <Tag color="#87d068">待处理</Tag> :
                        rowdata === 10 ? <Tag color="#87d068">处理成功</Tag> :
                        rowdata === 15 ? <Tag color="#87d068">处理失败</Tag> :
                        rowdata === 20 ? <Tag color="#87d068">已取消</Tag> :  <p></p>
                    }</div>
                )
            }
        },
        {
            title: '用户数量',
            dataIndex: 'user_count',
            key: 'user_count',
            width: 80,
        },
        {
            title: '标题',
            dataIndex: 'title',
            key: 'title',
            width: 80,
        },
        {
            title: '消息内容',
            dataIndex: 'content',
            key: 'content',
            width: 80,
        },
        {
            title: '是否有有效期',
            dataIndex: 'is_expire',
            key: 'is_expire',
            width: 80,
            render:(rowdata:any,record: any, idx: number)=>{
                return (
                    <div>{
                        rowdata === 1 ? <Tag color="#87d068">有</Tag> :
                        rowdata === 2 ? <Tag color="#ff0000">无</Tag> : <p></p>
                    }</div>
                )
            }
        },
        {
            title: '创建时间',
            dataIndex: 'created_at',
            key: 'created_at',
            width: 80,
        },
        {
            title: '指定通知时间',
            dataIndex: 'notice_at',
            key: 'notice_at',
            width: 80,
        },
        {
            title: '消息过期时间',
            dataIndex: 'expire_at',
            key: 'expire_at',
            width: 80,
        },
        {
            title: '是否生效',
            dataIndex: 'is_effect',
            key: 'is_effect',
            width: 80,
            render:(rowdata:any,record: any, idx: number)=>{
                return (
                    <div>{
                        rowdata === 1 ? <Tag color="#87d068">已生效</Tag> :
                        rowdata === 2 ? <Tag color="#ff0000">未生效</Tag> : <p></p>
                    }</div>
                )
            }
        },
        {
            title: '跳转url',
            dataIndex: 'jump_url',
            key: 'jump_url',
            width: 80,
        },
        {
            title: '消息类型',
            dataIndex: 'msg_type',
            key: 'msg_type',
            width: 80,
            render:(rowdata:any,record: any, idx: number)=>{
                return (
                    <div>{
                        rowdata === 1 ? <Tag color="#87d068">系统通知</Tag> :
                        rowdata === 2 ? <Tag color="#ff0000">优惠通知</Tag> : <p></p>
                    }</div>
                )
            }
        },
        {
            title: '推送类型',
            dataIndex: 'push_type',
            key: 'push_type',
            width: 80,
            render:(rowdata:any,record: any, idx: number)=>{
                return (
                    <div>{
                        rowdata === 1 ? <Tag color="#87d068">-新人注册后推送</Tag> :
                        rowdata === 2 ? <Tag color="#87d068">-立即推送</Tag> :
                        rowdata === 3 ? <Tag color="#ff0000">指定时间点推送</Tag> : <p></p>
                    }</div>
                )
            }
        },
        {
            title: '通知类型',
            dataIndex: 'type',
            key: 'type',
            width: 80,
            render:(rowdata:any,record: any, idx: number)=>{
                return (
                    <div>{
                        rowdata === 1 ? <Tag color="#87d068">系统通知</Tag> :
                        rowdata === 2 ? <Tag color="#ff0000">优惠通知</Tag> : <p></p>
                    }</div>
                )
            }
        },
        {
            title: '操作',
            key: 'action',
            width: 80,
            render:(rowdata:any,record:any,idx:number) => {
                return (
                    <div>
                        <Button type="primary" onClick={() => {editAction(rowdata)}}>编辑</Button>
                        <Button type="primary" onClick={() => {auditAction(rowdata)}}>审核</Button>
                        <Popconfirm
                            placement="bottomRight"
                            title={'是否立即发送？'}
                            onConfirm={()=>{immediatelyAction(rowdata)}}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button>直接发送</Button>
                        </Popconfirm>
                        <Popconfirm
                            placement="bottomRight"
                            title={'是否删除？'}
                            onConfirm={()=>{deleteAction(rowdata.id)}}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button>删除</Button>
                        </Popconfirm>
                    </div>
                )
            }
        },
    ]

    return (
        <div className='l_content'>
            <MessageTaskFrom visible={taskFormVisible} setVisible={(data:boolean,isRefresh?:boolean)=>{closeAndRefresh(data,isRefresh)}} data={itemEditData}></MessageTaskFrom>
            <Modal
                title="审核"
                visible={auditVisible}
                onOk={auditActionRequest}
                onCancel={()=>{setAuditVisible(false)}}
                >
                    <Radio.Group defaultValue={5} onChange={(e:any) => {
                        setAuditStatus(e.target.value)
                    }}>
                        <Radio value={5}>待处理</Radio>
                        <Radio value={20}>取消任务</Radio>
                    </Radio.Group>
            </Modal>
            <Row>
                <Col span={24}>
                    <Button type="primary" size="large" icon="plus" onClick={addAction}>创建任务</Button>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Table dataSource={tableSource} size="middle" rowKey={'id'} columns={columns} pagination={{
                        defaultCurrent: 1,
                        total: tableTotal,
                        pageSize: pageSize,
                        onChange: pageChange,
                        showQuickJumper: true,
                        showSizeChanger: true,
                        showTotal: (total: number) => `共${total}条`,
                        onShowSizeChange: (current:number, size: number) => {setpageSize(size); getList(currenPage, size)}
                    }} />
                </Col>
            </Row>
        </div>
    )
}

export default Message
