import React from "react";
import {Button, Col, Input, Row, Modal, Table, message, Avatar, DatePicker} from "antd";
import moment from 'moment'
import {getRequest} from "../../../../api/http";
import {apiUrl} from "../../../../api/api_url";
import '../turnplateCreate.scss'
import {Link} from "react-router-dom";
const { TextArea } = Input;

const { RangePicker } = DatePicker;

interface IProps {
    callbackParent(type:number,param:any):void,
    param ?: any,
}
interface paramType {
    param: [
        {
            'name'?: any,
            'stock'?: any,
            'award_type': 'food',
            'award_id'?: any,
            'probability'?: any,
            'exchange_time_start'?: any,
            'exchange_time_end'?: any,
            'award_info'?: any,
            'tips'?: any
        }
    ]
}

const dateFormat = 'YYYY-MM-DD HH:mm:ss' || undefined;

class CreateReward extends React.Component<IProps>{
    re = /^[1-9]+[0-9]*]*$/;            // 正整数校验
    pageSize= 10;
    state = {
        visible: false,
        shopList: [],
        listNum: 0,
        current: 1,
        rewardIdx: 0,
        param: [
            {
                'name': '',
                'stock': '',
                'award_type': 'food',
                'award_id': '',
                'probability': '',
                'exchange_time_start': '',
                'exchange_time_end': '',
                'award_info': {
                    'id': 0,
                    'name': '',
                    'first_pic': {
                        'url': '',
                        'key': ''
                    }
                },
                'tips': ''
            }
        ]
    };

    componentDidMount(): void {
        this.initPageParams();
    }

    // 初始化页面参数
    initPageParams =() =>{
        let that = this;
        let paramAward = that.props.param;
        if(paramAward.length !== 0){
            that.setState({
                param: paramAward,
                rewardIdx: 0
            })
        }
    };

    add = () => {
        let obj = {
            'name': '',
            'award_type': 'food',
            'award_id': '',
            'stock': '',
            'probability': '',
            'exchange_time_start': '',
            'exchange_time_end': '',
            'award_info': {
                'id': 0,
                'name': '',
                'first_pic': {
                    'url': '',
                    'key': ''
                }
            },
            'tips': ''
        }
        var sendList = this.state.param;
        sendList.push(obj);
        this.setState({
            param: sendList
        })
    }

    delete = () => {
        let rewardIdx = this.state.rewardIdx;
        var sendList = this.state.param;
        if(sendList.length <= 0){
            message.error('至少保留一个');
        }else{
            sendList.splice(rewardIdx, 1);
            this.setState({
                param: sendList,
                rewardIdx: 0
            })
        }
    }

    changeIdx = (idx:number) => {
        this.setState({
            rewardIdx: idx
         });
    }



    handleModel =() =>{
        let {visible} = this.state;
        if(!visible){
            this.requestShopList();
            this.requestShopListNum();
        }
        this.setState({visible: !visible});
    };

    // 奖项等级名称
    handleTitle = (e:any) => {
        let rewardIdx = this.state.rewardIdx;
        let value = e.target.value.trim();
        let paramTemp: any = this.state.param;
        paramTemp[rewardIdx].name = value;
        this.setState({param: paramTemp});
        this.props.callbackParent(4, paramTemp);
    };

    // 绑定商品
    handleBindShop = (item:any) =>{
        let rewardIdx = this.state.rewardIdx;
        let paramTemp: any = this.state.param;
        paramTemp[rewardIdx].award_info = item;
        this.setState({
            param:paramTemp
        });
        this.handleModel();
        this.props.callbackParent(4, paramTemp);
    };

    // 库存数监听
    handleStockNum =(e:any) =>{
        let rewardIdx = this.state.rewardIdx;
        let value = e.target.value;
        let paramTemp: any = this.state.param;
        if(isNaN(value)){
            message.error("请输入整数!");
        }else{
            paramTemp[rewardIdx].stock = this.re.test(value) ? parseInt(value):'';
        }
        this.setState({param: paramTemp});
        this.props.callbackParent(4, paramTemp);
    };

    handleProbability = (e:any) => {
        let rewardIdx = this.state.rewardIdx;
        let value = e.target.value;
        let paramTemp: any = this.state.param;
        if(isNaN(value)){
            message.error("请输入整数!");
        }else{
            paramTemp[rewardIdx].probability = this.re.test(value) ? parseInt(value):'';
        }
        this.setState({param: paramTemp});
        this.props.callbackParent(4, paramTemp);
    }

    // 兑奖有效期
    handleChangeActivityDate = (time: any, timeStr:[string,string]) => {
        let rewardIdx = this.state.rewardIdx;
        let paramTemp: any = this.state.param;
        paramTemp[rewardIdx].exchange_time_start = timeStr[0];
        paramTemp[rewardIdx].exchange_time_end = timeStr[1];
        this.setState({param: paramTemp});
        this.props.callbackParent(4, paramTemp);
    }

    textChange = (e:any) => {
        let rewardIdx = this.state.rewardIdx;
        let value = e.target.value.trim();
        let paramTemp: any = this.state.param;
        paramTemp[rewardIdx].tips = value;
        this.setState({param: paramTemp});
        this.props.callbackParent(4, paramTemp);
    }

    // 获取 商品列表
    requestShopList = async () =>{
        let {current} = this.state;
        const res: any = await getRequest(apiUrl.productList,{current: current,pageSize: this.pageSize});
        if(res.code === 1){
            this.setState({shopList:res.data})
        }else{
            console.log(res.msg)
        }
    };

    // 获取 商品总数
    requestShopListNum = async () =>{
        const res: any = await getRequest(apiUrl.productCount);
        if(res.code === 1){
            this.setState({listNum:res.data.num})
        }else{
            console.log(res.msg)
        }
    };

    // 分页
    changePage = (page:number) => {
        this.setState({
            current: page,
        }, () => {
            this.requestShopList();
        })
    };

    // 创建 商品列表
    createShopTable = () =>{
        let{shopList,listNum,current} = this.state;
        const dataSource = shopList;
        const columns:any = [
            {
                title: '商品名称',
                dataIndex: 'name',
                key: 'name',
                align: "center",
            },
            {
                title: '商品价格',
                dataIndex: 'price',
                key: 'id',
                align: "center",
            },
            {
                title: '创建时间',
                dataIndex: 'created_at',
                key: 'created_at',
                align: "center",
            },
            {
                title: '操作',
                align: "center",
                render: (text: string,record:any,index:number)=>(
                    <div className="table_action" onClick={this.handleBindShop.bind(this,record)}>绑定</div>
                )
            },
        ];
        return  <Table
            rowKey={"id"}
            dataSource={dataSource}
            columns={columns}
            size="middle"
            pagination={
                {
                    simple: true,
                    current: current,
                    total: listNum,
                    onChange: this.changePage,

                }
            }
        />

    };

    render(){
        let {param, rewardIdx, visible} = this.state;
        return (
            <div className="reward_setting_view l_content">
                <div className="step_title_name">请设置活动需要派发的奖项</div>
                <div className="tab_wrap">
                    <div className="tab_wrap_l">
                        {
                            param.map((item, idx) => {
                                return <div className={`award_item ${rewardIdx === idx ? 'active' : null}`} key={idx} onClick={() => this.changeIdx(idx)}>奖项{idx+1}</div>
                            })
                        }
                    </div>
                    <div className="tab_wrap_r">
                        <Button type="primary" onClick={this.add}>添加</Button>
                        <Button type="primary" onClick={this.delete}>删除</Button>
                    </div>
                </div>

                {/* <AwardComponent key={rewardIdx} param={param[rewardIdx]} index={rewardIdx} callbackParent={this.handleAward.bind(this)}/> */}


                <div className="reward_setting_view">
                <div className="reward_form">
                    <Row align="middle">
                        <Col>
                            <Row className="middle_view">
                                <Col span={2}>
                                    <div>奖项等级名称：</div>
                                </Col>
                                <Col span={3} className="people_number_input">
                                    <Input value={param[rewardIdx].name} placeholder="请输入活动标题" onChange={this.handleTitle}/>
                                </Col>
                            </Row>
                            <Row className="middle_view">
                                <Col span={2}>
                                    <div>奖品：</div>
                                </Col>
                                <Col span={4}>

                                    {
                                        Object.keys(param[rewardIdx].award_info).length === 0 || param[rewardIdx].award_info.first_pic.url === ''?
                                            <Button icon="plus" type="default" onClick={this.handleModel}>绑定商品</Button>
                                            :
                                            <div className="bindShopClass" onClick={this.handleModel}>
                                                <Avatar shape="square" size={60} src={param[rewardIdx].award_info.first_pic.url} />
                                                <div className="bindShopName">{param[rewardIdx].award_info.name}</div>
                                            </div>
                                    }
                                </Col>
                            </Row>
                            <Row className="middle_view">
                                <Col span={2}>
                                    <div>奖品数量：</div>
                                </Col>
                                <Col span={3}>
                                    <Input type="number" value={param[rewardIdx].stock} onChange={this.handleStockNum}  addonAfter="件" />
                                </Col>
                            </Row>
                            <Row className="middle_view">
                                <Col span={2}>
                                    <div>该奖项中奖几率：</div>
                                </Col>
                                <Col span={3}>
                                    <Input type="number" value={param[rewardIdx].probability} onChange={this.handleProbability} />
                                </Col>
                            </Row>
                            <Row className="middle_view">
                                <Col span={2}>
                                    <div>该奖项兑奖有效期：</div>
                                    {/* {param[rewardIdx].exchange_time_start===undefined||param[rewardIdx].exchange_time_end===undefined||param[rewardIdx].exchange_time_start===""||param[rewardIdx].exchange_time_end===""? 'true': 'false'} */}
                                </Col>
                                <Col span={3}>
                                    <RangePicker
                                            style={{width: '100%'}}
                                            size='default'
                                            // value={[]}
                                            value={param[rewardIdx].exchange_time_start===undefined||param[rewardIdx].exchange_time_end===undefined||param[rewardIdx].exchange_time_start===""||param[rewardIdx].exchange_time_end===""?[]:[moment(param[rewardIdx].exchange_time_start, dateFormat), moment(param[rewardIdx].exchange_time_end, dateFormat)]}
                                            placeholder={["开始时间","结束时间"]}
                                            onChange={this.handleChangeActivityDate}
                                            format={dateFormat}
                                        />
                                </Col>
                            </Row>
                            <Row className="middle_view" type="flex" align="top">
                                <Col span={2}>
                                    <div>温馨提示：</div>
                                    <div>(兑奖须知)</div>
                                </Col>
                                <Col span={3}>
                                    <div className='tip_wrap'>
                                        <TextArea value={param[rewardIdx].tips} onChange={this.textChange} style={{height: '100%'}}></TextArea>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <Modal
                    visible={visible}
                    title={null}
                    onCancel={this.handleModel}
                    footer={null}
                    width="70vw"
                >
                    <div className="model_head">
                        <div className="head_title">选择商品</div>
                        <Link to='/sidebar/ProductEdit'>
                            <Button type="primary" icon="plus" className="add_shop_button">新增商品</Button>
                        </Link>
                    </div>
                    {this.createShopTable()}
                </Modal>
            </div>
            </div>
        )
    }
}

export default CreateReward;