import React from "react"
import { getRequest, postRequest } from '../../../../api/http'
import { apiUrl } from '../../../../api/api_url'
import { message } from 'antd'
import { useHistory } from "react-router-dom"
import shopDecorationStore from '../../../../stores/shopDecoration.store'
import styles from './decorationHomeHeader.module.scss'

interface IProps {
    dataObj: {
        left: Array<{
            type: number,
            txt: string
        }>,
        right: Array<{
            type: number,
            txt: string
        }>
    },
    template: number
}

interface IState {

}
const DecorationHomeHeader = (props:IProps) => {

        const history = useHistory()

        const { dataObj, template } = props;
        //点击事件
        const onClickSubmit = async (type: any) => {
            let data = JSON.parse(JSON.stringify(shopDecorationStore.shop_decoration_home));
            data = data.map((item:any,index :number)=>{
                item.sort = data.length-index
                return item
            })
            let params = {
                template,
                apply: 0,
                data
            }
            let res:any
            switch (type) {
                case '保存':
                    res = await postRequest(apiUrl.saveHomepage,params)
                    if(res.code === 1){
                        shopDecorationStore.updateShopDecorationHome(res.data.list,false)
                        message.success('保存成功')
                    }else{
                        message.error(res.message||'保存失败')
                    }
                    break;
                case '保存并运用':
                    params.apply = 1
                    res = await postRequest(apiUrl.saveHomepage,params)
                    if(res.code === 1){
                        shopDecorationStore.updateShopDecorationHome(res.data.list,false)
                        message.success('保存并运用成功')
                    }else{
                        message.error(res.message||'保存并运用失败')
                    }
                    break;
                    break;
                case '恢复默认设置':
                    res = await getRequest(apiUrl.getDefalutHomepage,{template})
                    //清空操作历史
                    shopDecorationStore.shop_decoration_home_history_prev = []
                    shopDecorationStore.shop_decoration_home_history_next = []
                    if(res.code === 1 && res.data){
                        shopDecorationStore.updateShopDecorationHome(res.data.list,false)
                        message.success('操作成功！')
                    }else{
                        message.error(res.message||'模版信息获取失败')
                    }
                    break;
                case '返回':
                    history.goBack();
                    break;
                case '预览':
                    shopDecorationStore.updateHomePreview(1)
                    break;
            
                default:
                    break;
            }
        }

        return (
            <div className={styles.header}>
                <div className={styles.header_left}>
                    {
                        dataObj.left && dataObj.left.length > 0 && dataObj.left.map((item: any, index: number) => {
                            return (
                                item.type == 1
                                ?   <div key={item.txt + index} className={styles.header_left_list} onClick={() => {onClickSubmit(item.txt)}}>
                                        {
                                            item.txt
                                        }
                                    </div>
                                :   <div key={item.txt + index} className={styles.header_left_item} onClick={() => {onClickSubmit(item.txt)}}>
                                        {
                                            item.txt
                                        }
                                    </div>
                            )
                        })
                    }
                </div>
                <div className={styles.header_right}>
                    {
                        dataObj.right && dataObj.right.length > 0 && dataObj.right.map((item: any, index: number) => {
                            return (
                                item.type == 1
                                ?   <div key={item.txt + index} className={styles.header_right_list} onClick={() => {onClickSubmit(item.txt)}}>
                                        {
                                            item.txt
                                        }
                                    </div>
                                :   <div key={item.txt + index} className={styles.header_right_item} onClick={() => {onClickSubmit(item.txt)}}>
                                        {
                                            item.txt
                                        }
                                    </div>
                            )
                        })
                    }
                </div>
                
            </div>
        )
}

export default DecorationHomeHeader