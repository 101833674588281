
const add0 = (num:any) => {
   return num>9?num:'0'+num
} 

//获取当前月天数
const getMonthNum = (year:any,month:any,start:any) =>{
  // var year = 2012;
  // var month = 3;
  // var date = new Date();
  // date.setFullYear(year,month,start);
  // var endDate = date.getDate(); //获取当前月份天数
  const start_time = year+'/'+add0(month)+'/'+start
  const end_time = year+'/'+add0(month)+'/'+new Date(year,month,0).getDate()
  console.log(start_time)
  console.log(end_time)
  const endDate =  (new Date(end_time).getTime()-new Date(start_time).getTime())/1000/60/60/24+1
  return endDate
}

/*获取当前月星期几的数量
  year:年,
  month:月,
  start:开始日,
  week:星期几
*/
const getMonthWeekNum = (year:number,month:number,start:number,week:number) =>{
  // var year = 2012;
  // var month = 3;
  var count = 0;
  var date = new Date();
  date.setFullYear(year,month,0);
  var endDate = date.getDate(); //获取当前月份天数
  for(var i=start;i<=endDate;i++){
    date.setFullYear(year,month-1,i);
    if(date.getDay()==week){
      count++;
    }
  }
  return count
}

const toParseFloat = (data:any) => {
  for(var d in data){
      data[d] = parseFloat(data[d]);
  }
  return data
}

//获取日期
const getDate = (times:any='') => {
  // 获取当前日期
  let date = times ? new Date(times) : new Date();

  // 获取当前月份
  let nowMonth:any = date.getMonth() + 1;

  // 获取当前是几号
  let strDate:any = date.getDate();

  // 添加分隔符“-”
  const seperator = "-";

  // 对月份进行处理，1-9月在前面添加一个“0”
  if (nowMonth >= 1 && nowMonth <= 9) {
    nowMonth = "0" + nowMonth;
  }

  // 对月份进行处理，1-9号在前面添加一个“0”
  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }

  // 最后拼接字符串，得到一个格式为(yyyy-MM-dd)的日期
  const nowDate = date.getFullYear() + seperator + nowMonth + seperator + strDate;

  return nowDate;
}

export { 
  getMonthNum, 
  getMonthWeekNum,
  toParseFloat,
  getDate 
}