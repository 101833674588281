import React, { useEffect, useState } from 'react'
import { Row, Button, Input, Table, message, Modal, Tag, Tabs, DatePicker, Select, Tooltip, Col,Switch, InputNumber, Radio, Checkbox, Popconfirm } from 'antd'
import { useHistory, useLocation } from 'react-router-dom';
import { getRequest, postRequest } from '../../../api/http'
import { apiUrl } from '../../../api/api_url'
import './smartPushTicketAdd.scss'


const { RangePicker } = DatePicker;
const { Option } = Select;
const { Search } = Input
//v1/coupon/list  优惠券列表
const SmartPushTicketAdd = ()=>{
    const history = useHistory()
    const location:any = useLocation()
    const [reload,setreload] = useState<any>(true)

    const reloadFun = ()=>{
        setreload((value:any) => {
            return !value
        })
    }

    const [isOldUser,setisOldUser] = useState(false)


    const [pushDays,setpushDays] = useState(1)
    const [expectedUserCounts,setexpectedUserCounts] = useState(0)

    const onPushDays = async(e:any)=>{
        setpushDays(e)
        let ls:any = (userCounts / e).toFixed(0);
        setexpectedUserCounts(ls)
    }

    useEffect(() => {
        getCouponList()
        if(location.state.user_type == 1){
            setisOldUser(true)
        }
        
        if(location.state.id != null){
            getInfo()   
            
        }
        if(location.state.user_type == 2 && location.state.id == null){
            getUserCountRequest()
        }
        
    }, [])

    const getUserCountRequest = async()=>{
        const param = {
        }
        const res:any = await getRequest(apiUrl.SmartPushTicketUserCount, param)
        if(res && res['code'] === 1) {
            setuserCounts(res.data)
            let ls:any = (res.data / 1).toFixed(0);
            setexpectedUserCounts(ls)
        }
    }

    const getInfo = async() =>{
        const param = {
            id:location.state.id
        }
        if(!location.state.id && location.state.user_type == 2){
            getUserCountRequest()
        }
        const res:any = await getRequest(apiUrl.SmartPushTicketIndex, param)
        if(res && res['code'] === 1) {
            console.log('getInfo',res)
            let ls:any = []
            res.data.coupons.map((item:any)=>{
                ls.push([{key:item.coupon_id,label:item.name}])
            })
            console.log(ls)
            setcouponSelectValue(ls)

            res.data.notice_channel.map((item:any) => {
                if(item == "official"){
                    setwechatPush(true)
                }
                if(item == "sms"){
                    setmessagePush(true)
                }
            })

            if(res.data.diff_days){
                setdiffDays(res.data.diff_days)
            }
            if(res.data.user_counts){
                setuserCounts(res.data.user_counts)
            }
            if(res.data.pushed_count){
                setpushedCount(res.data.pushed_count)
            }
            if(res.data.remain_count){
                setremainCount(res.data.remain_count)
            }
            if(res.data.complete_days){
                
                setpushDays(res.data.complete_days)
                let lsa:any = (res.data.user_counts / res.data.complete_days).toFixed(0);
                // console.log('--------------asdasd',lsa);
                setexpectedUserCounts(lsa)
            }
            if(res.data.is_loop){
                setisLoop(res.data.is_loop == 1 ? true : false)
            }


            reloadFun()

            // let ls:any = []
            // res.data.list.map((item:any) => {
            //     ls.push(<Option key={item.id}>{item.name}</Option>)
            // })
            // setcouponList(ls)
            // addCouponSelect()
        }
    }

    const [couponList,setcouponList] = useState<any>([])
    const getCouponList = async()=>{
        const param = {
            page: 1,
            pageSize: 100,
            position:5,
            status:2,
        }
        const res:any = await getRequest(apiUrl.couponList, param)
        if(res && res['code'] === 1) {
            console.log('getCouponList',res)
            let ls:any = []
            res.data.list.map((item:any) => {
                ls.push(<Option key={item.id}>{item.name}</Option>)
            })
            setcouponList(ls)
            if(location.state.id == null){
                addCouponSelect()
            }

            
            // addCouponSelect()
        }
    }

    //优惠券规则列表
    // const [couponSelectList,setcouponSelectList] = useState<any>([])
    const [couponSelectValue,setcouponSelectValue] = useState<any>([])

    const PushTicketonChange = (e:any)=>{
        console.log(`是否打开智能推送 ${e}`);
    }

    const PushTicketonChangeSave = ()=>{
        console.log(`保存`);
    }

    const handleChange = ()=>{
        console.log('couponSelectValue',couponSelectValue)
    }

    const couponSelectView = ()=>{
        
        return couponSelectValue.map((item:any,index:any) => {
            // let isdisabled = false
            // console.log('---------------item',item)
            const selectValue = (e:any)=>{
                console.log('selectValueA',index,e.length)
                if(e.length > 1){
                    message.success('选择多张券只有第一张生效！')
                    return
                }
                let ls = couponSelectValue
                ls[index] = e
                setcouponSelectValue(ls)
                // reloadFun()
                console.log('selectValueB',ls)
            }
            const filterFun = (inputValue:any, option:any) =>{
                console.log(inputValue,option)
                return false
            }
            let defaultValue:any = undefined;
            if(item != 1){
                defaultValue = item
                // value = item
            }
            // console.log('defaultValue',defaultValue)
            return(
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mb10">
                    <Col span={3}>
                        券{index+1}
                    </Col>
                    <Col span={6}>
                        <Select
                            filterOption={filterFun}
                            mode="tags"
                            style={{ width: '100%' }}
                            labelInValue={true}
                            placeholder="选择优惠券"
                            defaultValue={defaultValue}
                            allowClear={true}
                            // optionLabelProp={}
                            // value={undefined}
                            onChange={selectValue}
                        >
                            {couponList}
                        </Select>
                    </Col>
                </Row>
            );
        })
    }

    const addCouponSelect = ()=>{
        console.log(`addCouponSelect`);
        
        setcouponSelectValue((item:any) => {
            
            return [...item,1]
        })
    }

    useEffect(()=>{
        console.log('检测couponSelectList变化')
    },[couponSelectValue])

    const addCouponSubmitActionConfirm = ()=>{
        if(expectedUserCounts > 1000){
            setpopconfirm(true)
            return
        }
        addCouponSubmitAction()

    }
    const addCouponSubmitAction = async ()=>{
        console.log('couponSelectValue',couponSelectValue)
        
        let isEdit = location.state.id == null ? false : true
        let param:any = {}
        let editParam:any = {}

        let coupons:any = [];
        couponSelectValue.map((item:any) => {
            if(item[0] != undefined){
                coupons.push(item[0].key)
            }
            
        })

        let notice_channel:any = [];
        if(messagePush === true){
            notice_channel.push('sms')
        }

        if(wechatPush === true){
            notice_channel.push('official')
        }

        param['diff_days'] = diffDays
        param['coupons'] = coupons
        param['user_type'] = location.state.user_type
        param['notice_channel'] = notice_channel
        param['complete_days'] = pushDays
        param['is_loop'] = isLoop ? 1 : 2

        console.log('------------',param)

        if(isEdit){
            param['id'] = location.state.id
        }
        

        const res:any = await postRequest(isEdit ? apiUrl.SmartPushTicketEdit : apiUrl.SmartPushTicketAdd,param)
        if(res && res['code'] === 1) {
            message.success(res.message||'添加成功');
            history.goBack()
        }
        message.success(res.message||'添加错误');



    }

    const [diffDays,setdiffDays] = useState(0)

    const onDiffDays = (e:any) => {
        setdiffDays(e)
        // console.log(diffDays)
    }
    
    const [messagePush,setmessagePush] = useState(false)
    const onMessagePush = (e:any)=>{
        setmessagePush(e.target.checked)
        // console.log(e.target.checked)

    }

    const [wechatPush,setwechatPush] = useState(true)
    const onWechatPush = (e:any) =>{
        // setwechatPush(e.target.checked)
        // console.log(e.target.checked)
    }
    const onisLoop = (e:any) =>{
        setisLoop(e.target.checked)
        // console.log(e.target.checked)
    }


    const [userCounts,setuserCounts] = useState<any>(0)
    const [pushedCount,setpushedCount] = useState(0)
    const [remainCount,setremainCount] = useState(0)
    const [isLoop,setisLoop] = useState(false)
    const [popconfirm,setpopconfirm] = useState(false)
    const [isConfirm,setisConfirm] = useState(false)
    const submitConfirm = (e:any)=>{
        setisConfirm(false)
        addCouponSubmitAction()
    }
    const submitCancel = (e:any)=>{
        setpopconfirm(false)
    }

    return(
        <div className="smartPushTicketAdd">
            {
                location.state.user_type == 1 ?
                <div className="title">
                    <div>基本规则</div>
                    <div>1、发券间隔：从创建活动开始算；例如1号开始创建活动，发券间隔10天，那么11号自动推券一优惠券给用户1号就餐用户,21号自动推11号收到优惠券，但未到店消费用户，以此类推</div>
                    <div>2、推送时间：例如推送为1号用户，用户1号下午17:25:59就餐，第二天17:25:59用户收到该推送券，超过22:00就餐用户第二09:00推送</div>
                </div>
                : location.state.user_type == 2 ?
                <div className="title">
                    <div>基本规则</div>
                    <div>1、创建活动前：从创建活动前，例如1月25号开始创建活动，那么24号之前包括24号所有就餐用户推送</div>
                    <div>2、多少天推送完：例如1000人设置5天，那么每天推送200人</div>
                    <div>3、推送规则：从最先就餐或付款用户开始推送，按时间倒序推送</div>
                    <div>4、推送时间：用户之前17:25:59就餐，推送时间17:25:59用户收到该推送券，超过22:00就餐用户第二天09:00推送</div>
                    <div>5、发券间隔：例如设置5天，那么已推送的用户，还未到店用户，5天后推送券二优惠券</div>
                </div>
                :
                <div></div>
            }
            
            <div className="content content_a">
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mb10">
                    <Col span={24} className="tips_cont">
                        <div className="tips">
                            <div className="tipsA">{userCounts}</div>
                            <div className="tipsB">总用户数</div>
                        </div>
                        <div className="tips">
                            <div className="tipsA">{pushedCount}</div>
                            <div className="tipsB">已推送用户数</div>
                        </div>
                        <div className="tips">
                            <div className="tipsA">{remainCount}</div>
                            <div className="tipsB">剩余待处理用户数</div>
                        </div>
                    </Col>
                    
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mb10">
                    <Col span={3}>
                        发送间隔(天)
                    </Col>
                    <Col span={6}>
                        <InputNumber min={1} max={999} defaultValue={diffDays} value={diffDays} onChange={onDiffDays} />
                    </Col>
                </Row>
                {
                    location.state.user_type == 2 ?
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mb10">
                        <Col span={3}>
                            
                        </Col>
                        <Col span={6}>
                            <InputNumber min={1} max={999} defaultValue={pushDays} value={pushDays} onChange={onPushDays} />
                            天推完，预计每天推送{expectedUserCounts}人
                        </Col>
                    </Row>
                    :
                    <div></div>
                }
                
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mb10">
                    <Col span={3}>
                        <h3>优惠券规则</h3>
                    </Col>
                </Row>
                {
                    couponSelectView()
                }
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mb10">
                    <Col span={3}></Col>
                    <Col span={18}>
                        <Button type="primary" onClick={addCouponSelect}>继续添加力度券</Button>
                    </Col>
                </Row>

                {/* <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mb10">
                    <Col span={3}>
                        
                    </Col>
                    <Col span={6}>
                        用户成为流失用户之后是否需要重复发券？<Switch onChange={PushTicketonChange} />
                    </Col>
                </Row> */}
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mb10">
                    <Col span={3}>
                        
                    </Col>
                    <Col span={6}>
                    
                    </Col>
                </Row>          
            </div>
            <div className="content content_b mt10">
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mb10">
                    <Col span={3}>
                        <h3>推送设置</h3>
                    </Col>
                    <Col span={6}>
                    </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mb10">
                    <Col span={3}>
                        是否循环推送
                    </Col>
                    <Col span={18}>
                        <Checkbox onChange={onisLoop} checked={isLoop}></Checkbox>当用户接收完配置的优惠券之后，再继续重新持续循环推送，可能会对用户造成持续的提醒（骚扰）
                    </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mb10">
                    <Col span={3}>
                        推送渠道
                    </Col>
                    <Col span={18}>
                        <Checkbox onChange={onWechatPush} checked={wechatPush}></Checkbox>服务号模板消息
                        <div>频繁发送营销模板消息存在被封禁的风险</div>
                    </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mb10">
                    <Col span={3}>
                    </Col>
                    <Col span={18}>
                        <Checkbox onChange={onMessagePush} checked={messagePush} disabled={true}></Checkbox>短信通知
                        <div>扣除短信条数，为了发送成功，请确保在消息推送中的设置了自定义签名</div>
                    </Col>
                </Row>
            </div>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt10">
                <Col span={3}>
                    <h3></h3>
                </Col>
                <Col span={6}>
                    <Popconfirm
                        title="每天推送的人数超过1000，模板消息可能会被封，是否继续？"
                        onConfirm={submitConfirm}
                        onCancel={submitCancel}
                        visible={popconfirm}
                        // onVisibleChange={popconfirm}
                        
                        okText="是"
                        cancelText="否"
                    >
                        <Button type="primary" size={'large'} onClick={addCouponSubmitActionConfirm}>提交</Button>
                    </Popconfirm>
                    
                </Col>
            </Row>
            
            
        </div>
    );
}

export default SmartPushTicketAdd

