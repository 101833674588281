import React, { useEffect, useState } from 'react'
import { Row, Col, Table, Avatar } from 'antd';
import Search from 'antd/lib/input/Search';
import { getRequest } from '../../../../../../api/http';
import { apiUrl } from '../../../../../../api/api_url';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

import './index.scss'

interface IProps {

}
/**
 * 会员列表
 */
// let times:any = [];      // 时间区间
const OfficialMemberIndex = (props: IProps) => {
    const location:any = useLocation()
    const [memberList,setMemberList] = useState([]);       // 公众号数组
    const [count,setCount] = useState(0);                  // 总数量
    const [isLoading,setIsLoading] = useState(false);
    const [paramsObj, setParamsObj] = useState<any>({
        page: 1,
        pageSize: 10,
    });

  // 相当于 componentDidMount 和 componentDidUpdate:
    useEffect(() =>{
        if(location.state&&location.state.group_id) paramsObj.group_id = location.state.group_id
        requestGetMemberList({...paramsObj});
    },[]);
   // 获取会员列表
   const requestGetMemberList = async (data = { }) => {
       
        setIsLoading(true);
       let param:any = {
           ...data
       }
       if(param.sortType) param.sortType = param.sortType=='ascend'?'asc':'desc'
       Object.keys(param).map(el => {
           if(!param[el]) delete param[el]
       })
       setParamsObj({...param})
        const res:any = await getRequest(apiUrl.getOffiaccountMemberList,param);
        if(res.code === 1){
            setMemberList(res.data.data);
            setCount(res.data.total);
            setIsLoading(false);
        }
   };


    const handleTableChange = (pagination:any, filters:any, sorter:any) => {
        console.log('sorter:',sorter)
        requestGetMemberList({
            ...paramsObj,
            page:pagination.current,
            pageSize:pagination.pageSize,
            sortField:sorter.field,
            sortType:sorter.order
        })
    };

    const createTable = () =>{
        const columns:any = [
            {
                title: '用户ID',
                dataIndex: 'id',
                key: 'id',
                fixed: 'left',
                width: 100,
            },
            {
                title: '用户昵称',
                dataIndex: 'nickname',
                key: 'nickname',
                fixed: 'left',
                width: 120,
                render: (text: string,record:any,index:number) =>
                <Row className="" justify="start" align="middle">
                    <div><Avatar src={record.headimgurl} /></div>
                    <div>{record.nickname}</div>
                </Row>
            },
            {
                title: '性别',
                dataIndex: 'sex',
                render: (text: string,record:any,index:number) =>
                <div>
                   {record.sex == 0 ? '未知' : record.sex ===1 ? '男': '女'}
                </div>
            },
            {
                title: '国家',
                dataIndex: 'country',
                key: 'country',
            },
            {
                title: '省',
                dataIndex: 'province',
                key: 'province',
            },
            {
                title: '城市',
                dataIndex: 'city',
                key: 'city',
            },
            {
                title: '关注时间',
                dataIndex: 'subscribe_time',
                key: 'subscribe_time',
                render: (text: number,record:any,index:number) => moment(text*1000).format('YYYY-MM-DD HH:mm:ss')
            },
        ];
        
        return <Table
            dataSource={memberList}
            columns={columns}
            rowKey={"id"}
            loading={isLoading}
            size="middle"
            onChange={handleTableChange}    
            pagination={{
                total: count,
                current:paramsObj.page,
                pageSize: paramsObj.pageSize,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (total: number) => `共${total}条`,
            }}
        />;
    }

    return <div className="official_member_index l_content">
        <div className="title_text">
            {location.state&&location.state.title?location.state.title+'-':''}用户列表：{count}
        </div>
        <div className="select_group">
            <Row className="mb20">
                <Col span={4}>
                    <Search
                        placeholder="请输入用户昵称"
                        onSearch={value => {
                            requestGetMemberList({ ...paramsObj, page:1, name: value });
                        }}
                    />
                </Col>
            </Row>  
        </div>
        {createTable()}
    </div>
}

export default OfficialMemberIndex;
