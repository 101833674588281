import React from "react";
import {Button, Row, Col, Input, Table, Select, DatePicker, message, } from "antd";
import { getRequest, postRequest } from '../../api/http';
import { apiUrl } from '../../api/api_url';
import { IUserGroupStore } from '../../stores/userGroup.store';
import { inject, observer } from "mobx-react"
const { Option } = Select;

const { Search } = Input;
const { RangePicker } = DatePicker;

@inject('userGroupStore')
@observer
class CouponGroup extends React.Component<any> {
    state = {
        visible: false,
        couponList: {
          total: 0,
          list: []
        },
        currentPage: 1,
        pageSize: 10,
        level: '',
        name: '',
        userList: [],
        start_time: '',
        end_time: '',
        add_user: 0,
        group: '',
        ids: [],
        selectRows: [],
        allGroup: [],
        levelList: [],
        activity_id:0,
    }

    columns = [
        {
            title: '序号',
            dataIndex: 'index',
            key: 'index',
            className: 'list_center',
            render: ( text: string, record: any, index: number) => {
                return <div>{index+1}</div>
            }
        },
        {
            title: '用户ID',
            dataIndex: 'fan_id',
            key: 'fan_id',
            className: 'list_center',
        },
        {
            title: '用户昵称',
            dataIndex: 'nickname',
            key: 'nickname',
            className: 'list_center',
            render: (text: string, record: any,) => {
                return <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <img src={record.head_img_url} style={{width:50,height: 50, borderRadius: '50%', marginRight: 10}} />
                    {record.nickname}
                </div>
            }
        },
        {
            title: '用户等级',
            dataIndex: 'level',
            key: 'level',
            className: 'list_center',
        },
        {
            title: '手机号',
            dataIndex: 'phone_number',
            key: 'phone_number',
            className: 'list_center',
        },
        {
            title: '性别',
            dataIndex: 'sex',
            key: 'sex',
            className: 'list_center',
        },
        {
            title: '用户分组',
            dataIndex: 'groups',
            key: 'groups',
            className: 'list_center',
            render: (rowData: any, record: any) => {
                return <div>
                    {rowData.map((item:any) => {
                        return <div key={item}>{item}</div>
                    })}
                </div>
            }
        },
        {
            title: '消费次数',
            dataIndex: 'consume_count',
            key: 'consume_count',
            className: 'list_center',
        },
        {
            title: '累计消费',
            dataIndex: 'consume_money',
            key: 'consume_money',
            className: 'list_center',
        },
        {
            title: '券有效期',
            dataIndex: 'useful_day',
            key: 'useful_day',
            className: 'list_center',
        },
    ];

    componentDidMount(){
        this.onInitList();
        this._initGroup()
        this._initLevel()
        if(this.props.location.state.type == 'use'){
            this.columns[9] = {
                title: '使用时间',
                dataIndex: 'used_at',
                key: 'used_at',
                className: 'list_center',
                render: (rowData: any, record: any,) => {
                    return <div> {rowData ? rowData : '-'} </div>
                }
            }
        }
    }

    onInitList  = async () => {
        const {currentPage, pageSize, level, start_time, end_time, group, name,activity_id} = this.state;
        const params:any = {
            current: currentPage,
            pageSize,
            coupon_id: this.props.location.state.id,
        };
        if(this.props.location.state.activity_id != 0){
            params.activity_id = this.props.location.state.activity_id
        }

        if(this.props.location.state.type == 'use'){
            params.is_use = true;
        }
        if(this.props.location.state.exchange_id){
            params.exchange_id = this.props.location.state.exchange_id;
        }
        if(name !== ''){
            params.nickname = name;
        }
        if(level){
            params.level = level;
        }
        if(start_time !== ''){
            params.start_time = start_time;
            params.end_time = end_time;
        }

        if(group){
            params.group = group;
        }
        const res:any = await getRequest(apiUrl.getCouponList,params);
        if(res.code === 1){
            this.setState({
                couponList: res.data,
                userList: res.data.add_user,
            })
        }
    }

    _initGroup = async () => {
        let res:any = await getRequest(apiUrl.allGroup, {search: true})
        if(res.code == 1){
            this.setState({
                allGroup: res.data
            })
        }
    }

    _initLevel = async () => {
        let res:any = await getRequest(apiUrl.fansLevelList)
        if(res.code == 1){
            this.setState({
                levelList: res.data
            })
        }
    }

    pageChange = async (page:number) => {
        this.setState({
            currentPage: page
        }, () => {
            this.onInitList();
        })
    }

    searchCoupon = (name: string) => {
        this.setState({
            name
        }, () => {
            this.onInitList();
        })
    }

    // 类型
    levelChange = async (level:string) => {
        this.setState({
            level
        }, () => {
            this.onInitList();
        })
    }

    groupChange = (group: string) => {
        this.setState({
            group,
            currentPage: 1
        }, () => {
            this.onInitList()
        })
    }

    userChange = (e: any) => {
        this.setState({
            add_user: e
        }, () => {
            this.onInitList();
        })
    }

    timeChange = (time: any, timeString: [any, any]) => {
        this.setState({
            start_time: timeString[0],
            end_time: timeString[1]
        }, () => {
            this.onInitList();
        })
    }

    statusCoupon = async (id: number, status: number) => {
        if(status == 2){
            status = 0;
        }else if(status == 0){
            status = 2;
        }
        const res: any = await  postRequest(apiUrl.changeVoucherStatus, {id, status})
        if(res.code === 1){
            message.success('修改成功');
            this.onInitList();
        }
    }

    toAdd = () => {
        this.props.userGroupStore.setList(this.state.selectRows)
        this.props.history.push({
            pathname: '/sidebar/couponGroupAdd',
        });
    }

    render(){
        const { couponList, levelList, allGroup} = this.state;
        let rowSelection:any = {
            selectedRowKeys: this.state.ids,
            onSelect: (record: any, selected: any, selectedRows: any) => {
                let selectRows:any = this.state.selectRows;
                if(selected){
                    selectRows.push(record)
                }else{
                    selectRows.map((item: any, index: number) => {
                        if(record.id == item.id){
                            selectRows.splice(index, 1)
                        }
                    })
                }
                this.setState({
                    selectRows
                })
            },
            onSelectAll: (selected: any, selectedRows: any, changeRows: any) => {
                let selectRows:any = this.state.selectRows;
                if(selected){
                    selectRows = selectRows.concat(changeRows)
                }else{
                    selectRows.map((item: any, index: number) => {
                        changeRows.map((change: any) => {
                            if(change.id == item.id){
                                selectRows.splice(index, 1)
                            }
                        })
                    })
                }
                this.setState({
                    selectRows
                })
            },
            onChange: (selectedRowKeys:any, selectedRows: any) => {
                this.setState({
                    ids: selectedRowKeys,
                });
            },
            type: 'checkbox',
        }

        return <div className='l_content'>
            <Row>
                <Col span={24}>
                    <Button type="primary" size="large" icon="plus" onClick={this.toAdd} disabled={this.state.ids.length <= 0}>
                        添加分组
                    </Button>
                </Col>
            </Row>
            <Row type="flex" align="middle" justify="start" className="mt20 mb40" >
                <Col className="mr30">
                    <span>用户昵称：</span>
                    <Search placeholder="请输入用户昵称" onSearch={this.searchCoupon} style={{ width: 234 }}/>
                </Col>
                <Col className="mr30">
                    <span>用户等级：</span>
                    <Select defaultValue="全部" style={{ width: 120 }} onChange={this.levelChange}>
                        <Option value={0}>全部</Option>
                        {
                            levelList.map((item: any) => {
                                return <Option value={item.id} key={item.id}>{item.name}</Option>
                            })
                        }
                    </Select>
                </Col>
                <Col className="mr30">
                    <span>用户分组：</span>
                    <Select defaultValue="全部" style={{ width: 120 }} onChange={this.groupChange}>
                        <Option value={0}>全部</Option>
                        {
                            allGroup.map((item: any) => {
                                return <Option value={item.id} key={item.id}>{item.name}</Option>
                            })
                        }
                    </Select>
                </Col>
                <Col className="mr30">
                    <span>{this.props.location.state.type == 'get' ? '有效时间：' : this.props.location.state.type == 'use' ? '使用时间：' : '兑换时间：'}</span>
                    <RangePicker onChange={this.timeChange} placeholder={["开始时间","结束时间"]} format="YYYY-MM-DD"/>
                </Col>
            </Row>

            <div className='all_user'>{this.props.location.state.type == 'get' ? '已领取数' : this.props.location.state.type == 'use' ? '已使用数' : '已兑换数'}：{couponList.total}</div>

            <div>
                <Table dataSource={couponList.list} rowSelection={rowSelection} columns={this.columns} rowKey={"id"} locale={{emptyText: '暂无数据'}} pagination={{
                    showQuickJumper: true,
                    total: couponList.total,
                    pageSize: this.state.pageSize,
                    onChange: this.pageChange,
                    showSizeChanger: true,
                    showTotal: (total: number) => `共${total}条`,
                    onShowSizeChange: (current:number, size: number) => {this.setState({pageSize: size}, () => {this.onInitList();})}
                }}/>
            </div>
        </div>;
    }
}
export default CouponGroup