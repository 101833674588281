import {observer} from "mobx-react";
import React from "react";
import {Button, Input, message, Row, Col, Modal, Radio, Icon, Table, Typography,Select, Tree, Tooltip, DatePicker} from "antd";
import { postRequest, getRequest } from '../../api/http';
import { RangePickerValue } from "antd/lib/date-picker/interface";
import BraftEditor from 'braft-editor'
// 引入编辑器样式
import 'braft-editor/dist/index.css'
import { apiUrl } from '../../api/api_url';
const { Text } = Typography;
const { Option } = Select;
const { TreeNode } = Tree;
const { RangePicker } = DatePicker;

interface WelfareEdit{
}

const key = 'WelfareEdit';

@observer
class WelfareEdit extends React.Component<any> {

    columnSelect:any = [
        {
            title: '优惠券名称',
            dataIndex: 'name',
            key: 'name',
            align: "center",
        },
        {
            title: '券类型',
            dataIndex: 'price',
            key: 'id',
            align: "center",
            render: (text:any,record:any,) => (
                <div className="color">{record.info.type === 'discount' ? '折扣券' : record.info.type === 'voucher' ? '抵扣券' : '兑换券'}</div>
            ),
        },
        {
            title: '券有效期',
            dataIndex: 'created_at',
            key: 'created_at',
            align: "center",
            render: (text:any,record:any,) => {
                let info:any = record.expire_info;
                return <div className="color">{info.type === 'time' ?  info.start_time + '至' + info.end_time : '自领取日'+info.day + '天领取'}</div>
            }
        },
        {
            title: '是否需要拆优惠券',
            dataIndex: 'limit',
            key: 'limit',
            align: "center",
            render: (text:any,record:any,index:number) => {
                let info:any = record.limit;
                return <div className="color">
                            <Radio.Group
                                value={record.limit == 'zhuli'}
                                style={{ marginLeft: 20 }}
                                onChange={(e) => this.limitChange(e.target.value, index)}
                            >
                                <Radio value={true}>是</Radio>
                                <Radio value={false}>否</Radio>
                            </Radio.Group>
                            {
                               record.limit == 'zhuli'
                               ?  <div>
                                    <Text>需</Text>
                                    <Input value={record.people_num} onChange={(e) => this.numChange(e.target.value, index)} style={{width:'40px'}}/>
                                    <Text>个好友助力</Text>
                                </div>
                                : null
                            }
                    </div>
            }
        },
        {
            title: '操作',
            align: "center",
            render: (text: string,record:any,index:number)=>(
                <div className="table_action" onClick={() => this.unBindShop(index)}>移除</div>
            )
        },
    ];

    columnNum:any = [
        {
            title: '优惠券名称',
            dataIndex: 'name',
            key: 'name',
            align: "center",
        },
        {
            title: '券类型',
            dataIndex: 'type',
            key: 'type',
            align: "center",
            render: (text:any,record:any,) => (
                <div className="color">{record.info.type === 'discount' ? '折扣券' : record.info.type === 'voucher' ? '抵扣券' : '兑换券'}</div>
            ),
        },
        {
            title: '券有效期',
            dataIndex: 'created_at',
            key: 'created_at',
            align: "center",
            render: (text:any,record:any,) => {
                let info:any = record.expire_info;
                return <div className="color">{info.type === 'time' ?  info.start_time + '至' + info.end_time : '自领取日'+info.day + '天领取'}</div>
            }
        },
        {
            title: '券份数',
            dataIndex: 'num',
            key: 'num',
            align: "center",
            render: (text:any,record:any,) => {
                return <div className="back_wrap">
                        <div className="back_btn reduce_btn active" onClick={() => this.reduceCoupon(record.id)}>-</div>
                        <div className="good_num">{record.coupon_num}</div>
                        <div className="back_btn add_btn active" onClick={() => this.addCoupon(record.id)}>+</div>
                    </div>
            }
        },
        {
            title: '操作',
            align: "center",
            render: (text: string,record:any,index:number)=>(
                <div className="table_action" onClick={() => this.unBindShop(index)}>移除</div>
            )
        },
    ];

    state = {
        editorState: BraftEditor.createEditorState(null),
        loading: false,
        name: '',
        status: 1,
        visible: false,
        couponList: [],
        listNum: 0,
        current: 1,
        selectCoupon: [],
        type: '',
        selectDisabled: false,
        typeName: '',
        stock: 0,
        price: 0,
        typeList: [
            {
                type: 'new_people',
                name: '新人福利'
            },
            {
                type: 'birthday',
                name: '生日福利'
            },
            {
                type: 'normal',
                name: '常规福利'
            },
            {
                type: 'multi_coupon',
                name: '批量送优惠券'
            },
            {
                type: 'equity_card',
                name: '权益卡'
            }
        ],
        user_type: [],
        pageSize: 100,
        selectList: [],
        validText: 'time',
        day: 0,
        start_time: '',
        end_time: '',
        sort: 0,
        show: false
    }

    componentDidMount () {
        const {location} = this.props;
        if(location.state !== undefined){
            this.initEdit(location.state.id);
        }
        this.onInitList();
    }

    // 初始化优惠券列表
    onInitList = async () => {
        let param = {
            current: 1,
            pageSize: 100
        }
        const res:any = await getRequest(apiUrl.couponList, param);
        if(res.code === 1){
            this.setState({
                couponList: res.data.list,
                listNum: res.data.total,
                current: 1,
                selectList: res.data.list.slice(0, 10)
            })
        }
    }

    // 初始化编辑
    initEdit = async (id:number) => {
        let { typeList } = this.state;
        const params = {
            id: id
        };
        const res:any = await getRequest(apiUrl.welfareEditInfo,params);
        if(res.code === 1){
            this.setState({
                name: res.data.name,
                status: res.data.status,
                selectCoupon: res.data.coupon,
                type: res.data.type
            })
            if(res.data.type === 'new_people' || res.data.type === 'birthday'){
                this.setState({
                    selectDisabled: true
                })
            }
            if(res.data.stock){
                this.setState({
                    stock: res.data.stock
                })
            }

            if(res.data.sort){
                this.setState({
                    sort: res.data.sort
                })
            }

            if(res.data.show){
                this.setState({
                    show: res.data.show
                })
            }

            if(res.data.price){
                this.setState({
                    price: res.data.price
                })
            }

            if(res.data.tips){
                this.setState({
                    editorState: BraftEditor.createEditorState(res.data.tips)
                })
            }

            if(res.data.user_type){
                this.setState({
                    user_type: res.data.user_type
                })
            }

            if(res.data.expire_info && res.data.expire_info.type === 'day'){
                this.setState({
                    validText: res.data.expire_info.type,
                    day: res.data.expire_info.day
                });
            }else if(res.data.expire_info){
                this.setState({
                    validText: res.data.expire_info.type,
                    start_time: res.data.expire_info.start_time,
                    end_time: res.data.expire_info.end_time
                });
            }

            typeList.map((item: any) => {
                if(item.type === res.data.type){
                    this.setState({
                        typeName: item.name
                    })
                }
            })
        }
    }

    addCoupon = (id: any) => {
        let selectCoupon = this.state.selectCoupon;
        selectCoupon.map((item: any) => {
            if(item.id === id){
                item.coupon_num++;
            }
        })
        this.setState({
            selectCoupon
        })
    }

    reduceCoupon = (id: any) => {
        let selectCoupon = this.state.selectCoupon;
        selectCoupon.map((item: any) => {
            if(item.id === id){
                if(item.coupon_num === 1){
                    return;
                }
                item.coupon_num--;
            }
        })
        this.setState({
            selectCoupon
        })
    }

    validChange = (e:any) => {
        this.setState({
            validText: e
        })
    }

    dayChange = (e: any) => {
        this.setState({
            day: e.target.value
        })
    }

    timeChange = (time: RangePickerValue, timeString: [string, string]) => {
        this.setState({
            start_time: timeString[0],
            end_time: timeString[1]
        })
    }

    nameChange = (e:any) => {
        let name = e.target.name;
        let value = e.target.value;
        this.setState({
            [name]: value.trim()
        })
    }

    // 福利类型
    typeChange = (e:any) => {
        let { typeList } = this.state;
        let type = '';
        typeList.map((item: any) => {
            if(item.name === e){
                type = item.type
            }
        })
        this.setState({
            type
        })
    }

    // 福利库存
    stockChange = (e: any) => {
        let name = e.target.name;
        let value = e.target.value;
        this.setState({
            [name]: value
        })
    }

    statusChange = (e:any) => {
        this.setState({
            status: e
        })
    }

    onCheck = (checkedKeys: any, info: any) => {
        let keyList = checkedKeys;
        let user_type: any[] = [];
        keyList.map((item: any) => {
            if(item !== '4'){
                user_type.push(item)
            }
        })
        this.setState({
            user_type
        })
    };

    handleSubmit = async () => {
        message.loading({ content: '发布中', key });

        const {location} = this.props;
        const { name, type, status, stock, selectCoupon, user_type, price, validText, day, start_time, end_time, sort, show } = this.state;
        var tableInfo:any = {};
        let coupon:any[] = [];

        if(location.state !== undefined){
            tableInfo.id = location.state.id;
        }

        if(name === ''){
            message.warning({ content: '请输入福利名称!', key, duration: 2 });
            return;
        }

        if(type === ''){
            message.warning({ content: '请选择福利类型!', key, duration: 2 });
            return;
        }

        if(type && type === 'multi_coupon' && stock <= 0){
            message.warning({ content: '请输入福利库存!', key, duration: 2 });
            return;
        }

        if(selectCoupon.length === 0){
            message.warning({ content: '请关联优惠券!', key, duration: 2 });
            return;
        }

        if(type && (type === 'multi_coupon' || type === 'equity_card') && user_type.length === 0){
            message.warning({ content: '请选择可见用户!', key, duration: 2 });
            return;
        }

        if(type && type === 'equity_card' && price === 0){
            message.warning({ content: '请输入权益卡售价!', key, duration: 2 });
            return;
        }

        if(type && type === 'equity_card'){
            if(validText === 'day'){
                if(day === 0){
                    message.warning({ content: '请输入有效天数!', key, duration: 2 });
                    return;
                }
            }else{
                if(start_time === ''){
                    message.warning({ content: '请选择有效区间!', key, duration: 2 });
                    return;
                }
            }
        }

        let info:any = selectCoupon[0];
        if(info.limit === 'zhuli' && (info.people_num === undefined || info.people_num === 0)){
            message.warning({ content: '请输入助力人数!', key, duration: 2 });
            return;
        }

        tableInfo.name = name;
        tableInfo.status = status;
        tableInfo.type = type;
        if(type && type === 'multi_coupon'){
            tableInfo.stock = stock;
            tableInfo.user_type = user_type;
            tableInfo.sort = sort;
            tableInfo.show = show;
        }

        if(type && type === 'equity_card'){
            const htmlContent = this.state.editorState.toHTML();
            tableInfo.tips = htmlContent;
            tableInfo.price = price;
            tableInfo.user_type = user_type;
            if(validText === 'day'){
                tableInfo.expire_info = {
                    type: validText,
                    day: day
                }
            }else{
                tableInfo.expire_info = {
                    type: validText,
                    end_time,
                    start_time
                }
            }
        }

        selectCoupon.forEach((item:any) => {
            if(item.limit == undefined){
                item.limit = '';
            }
            coupon.push({
                id: item.id,
                limit: item.limit,
                coupon_num: item.coupon_num,
                people_num: item.people_num
            })
        })

        tableInfo.coupon = coupon;

        if(location.state !== undefined){
            const res:any = await postRequest(apiUrl.welfareEdit,tableInfo);
            if(res['code'] === 1){
                message.success({ content: '修改成功!', key, duration: 2 });
                this.goBack()
            }else{
                message.error({ content: '修改失败!', key, duration: 2 });
            }
        }else{
            const res:any = await postRequest(apiUrl.welfareAdd,tableInfo);
            if(res['code'] === 1){
                message.success({ content: '发布成功!', key, duration: 2 });
                this.goBack()
            }else{
                message.error({ content: '发布失败!', key, duration: 2 });
            }
        }
    };

    // 返回上一页
    goBack = () => {
        this.props.history.goBack();
    }

    changePage = async (page: number) => {
        let { couponList } = this.state;
        let list = couponList.slice((page-1)*10, (10*page));
        console.log('pageChange', list.length);
        this.setState({
            selectList: list,
            current: page
        })
    }

    // 关联
    handleBindShop = (id:number) => {
        let { type } = this.state;
        let selectCoupon:any[] = this.state.selectCoupon;
        // if(type !== 'new_people' && selectCoupon.length === 1){
        //     message.warning({ content: '只能关联一张优惠券!', key, duration: 2 });
        //     return;
        // }
        let couponList:any[] = this.state.couponList;
        couponList.map((item) => {
            if(item.id === id){
                item.selected = true;
                item.coupon_num = 1;
                selectCoupon.push(item)
            }
        })
        // couponList.map((item: any) => {
        //     if(item.selected){
        //         selectCoupon.push(item)
        //     }
        // })
        console.log(selectCoupon);
        this.setState({
            selectCoupon,
            couponList
        })
    }

    // 解除关联
    unBindShop = (index: number) => {

        let { couponList } = this.state;
        let selectCoupon: any = this.state.selectCoupon;
        couponList.map((item: any) => {
            if(item.id === selectCoupon[index].id){
                item.selected = false;
            }
        })
        selectCoupon.splice(index, 1)

        // couponList.map((coupon: any) => {
        //     selectCoupon.map((select: any) => {
        //         if(coupon.id === select.id){
        //             coupon.selected = true;
        //         }
        //     })
        // })
        this.setState({
            couponList,
            selectList: couponList.slice(0, 10),
            current: 1,
            selectCoupon
        })
    }

    limitChange = (e:any,index:any) => {
        let selectCoupon:any = this.state.selectCoupon;
        if(e){
            selectCoupon[index].limit = 'zhuli';
        }else{
            selectCoupon[index].limit = '';
        }

        this.setState({
            selectCoupon
        })
    }

    // 助力人数
    numChange = (e: any,index:number) => {
        let selectCoupon:any = this.state.selectCoupon;

        selectCoupon[index].people_num = e;

        this.setState({
            selectCoupon
        })
    }

    handleOk = () => {
        this.setState({
            visible: false
        })
    }

    // 创建 商品列表
    columns:any = [
        {
            title: '优惠券名称',
            dataIndex: 'name',
            key: 'name',
            align: "center",
        },
        {
            title: '券类型',
            dataIndex: 'price',
            key: 'id',
            align: "center",
            render: (text:any,record:any,) => (
                <div className="color">{record.info.type === 'discount' ? '折扣券' : record.info.type === 'voucher' ? '抵扣券' : '兑换券'}</div>
            ),
        },
        {
            title: '券有效期',
            dataIndex: 'created_at',
            key: 'created_at',
            align: "center",
            render: (text:any,record:any,) => {
                let info:any = record.expire_info;
                return <div className="color">{info.type === 'time' ?  '自'+info.start_time + '至' + info.end_time : '自领取日'+info.day + '天内有效'}</div>
            }
        },
        {
            title: '操作',
            align: "center",
            render: (text: string,record:any,index:number)=>{
                return <div>
                    {
                        record.selected
                        ? '已关联'
                        : <div className="table_action" onClick={() => this.handleBindShop(record.id)}>关联</div>
                    }
                </div>
            }
        },
    ];

    handleEditorChange = (editorState:any) => {
        this.setState({ editorState })
    }

    // 显示关联
    showTable = () => {
        let { couponList, selectCoupon} = this.state;
        couponList.map((coupon: any) => {
            selectCoupon.map((select: any) => {
                if(coupon.id === select.id){
                    coupon.selected = true;
                }
            })
        })
        this.setState({
            couponList,
            selectList: couponList.slice(0, 10),
            current: 1,
            visible: true
        })
    }

    render(){
        const {validText, start_time, end_time, day, editorState, name, status, visible, selectCoupon, typeList, stock, type, typeName, selectDisabled, user_type, price, selectList, current, listNum, sort, show } = this.state;

        return <div className='l_content'>
                <div className="edit_title">福利信息</div>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">福利名称</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <Input placeholder="请输入福利名称" name='name' value={name} onChange={(e) => this.nameChange(e)}/>
                    </Col>
                </Row>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="color_black">福利类型</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <Select
                            placeholder={typeName === '' ? '请选择福利类型' : typeName}
                            style={{ width: 160}}
                            onChange={(e: any) => this.typeChange(e)}
                            disabled={selectDisabled}
                        >
                            {typeList.map((item:any) => (
                                <Option key={item.type} value={item.name}>
                                    {item.name}
                                </Option>
                            ))}
                        </Select>
                    </Col>
                </Row>

                {
                    type === 'multi_coupon'
                    ? <Row className="mb30" type="flex" align="middle">
                        <Col span={2}>
                            <div>
                                <span className="star"></span>
                                <span className="color_black">福利库存</span>
                            </div>
                        </Col>
                        <Col span={6}>
                            <Input placeholder="请输入福利库存" value={stock} name='stock' onChange={(e) => this.stockChange(e)} type='number'/>
                        </Col>
                    </Row>
                    : null
                }

                {
                    type === 'multi_coupon'
                    ? <Row className="mb30" type="flex" align="middle">
                        <Col span={2}>
                            <div>
                                <span className="color_black">福利排序</span>
                            </div>
                        </Col>
                        <Col span={6}>
                            <Input placeholder="请输入福利库存" value={sort} name='sort' onChange={(e) => this.stockChange(e)} type='number'/>
                        </Col>
                    </Row>
                    : null
                }

                {
                    type === 'equity_card'
                    ?
                        <div>
                            <Row className="mb30" type="flex" align="middle">
                                <Col span={2}>
                                    <div>
                                        <span className="star"></span>
                                        <span className="color_black">有效期类型</span>
                                    </div>
                                </Col>
                                <Col span={14}>
                                    <Select style={{ width: 140 }} onChange={this.validChange} value={validText}>
                                        <Option value="time">指定有效区间</Option>
                                        <Option value="day">指定有效天数</Option>
                                    </Select>
                                </Col>
                            </Row>
                            {validText === 'time' ?
                            <Row className="mb30" type="flex" align="middle">
                                <Col span={2}>
                                    <div>
                                        <span className="star"></span>
                                        <span className="color_black">有效期</span>
                                    </div>
                                </Col>
                                <Col span={14}>
                                    <Row type="flex" align="middle">
                                    <RangePicker onChange={this.timeChange} format="YYYY-MM-DD HH:mm:ss" placeholder={start_time !== '' ? [start_time, end_time] :["开始时间","结束时间"]} />
                                    </Row>
                                </Col>
                            </Row>
                            : <Row className="mb30" type="flex" align="middle">
                                <Col span={2}>
                                    <div>
                                        <span className="star"></span>
                                        <span className="color_black">券有效期</span>
                                    </div>
                                </Col>
                                <Col span={14}>
                                    <Row type="flex" align="middle">
                                        <span>自领取</span>
                                        <Input placeholder="0" maxLength={10} addonAfter="天" style={{ width: 120 }} type="number" className="mlr10" value={day} onChange={this.dayChange} />
                                        <span>内有效</span>
                                    </Row>
                                </Col>
                            </Row>}
                        </div>
                    : null
                }

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">关联优惠券</span>
                        </div>
                    </Col>
                    <Col span={16}>
                        <div onClick={this.showTable}>
                             <Icon type="plus-circle" style={{fontSize: '20px',marginRight: '10px'}} />
                             关联优惠券
                        </div>

                        {
                            selectCoupon.length > 0
                            ? <Table
                                size="middle"
                                rowKey={"id"}
                                dataSource={selectCoupon}
                                columns={type === 'equity_card' ? this.columnNum : this.columnSelect}
                                style={{marginTop: '20px'}}
                                pagination={
                                    {
                                        hideOnSinglePage: true,
                                        total: selectCoupon.length,
                                    }
                                }
                            />
                            : null
                        }

                    </Col>
                </Row>
                {
                    type === 'equity_card'
                    ?   <Row className="mb30" type="flex" align="middle">
                            <Col span={2}>
                                <div>
                                    <span className="star"></span>
                                    <span className="color_black">售价</span>
                                </div>
                            </Col>
                            <Col span={4}>
                                <Input type='number' placeholder="输入金额" value={price} name='price' onChange={(e) => this.nameChange(e)} addonAfter='元'/>
                            </Col>
                        </Row>
                        : null
                }

                {
                    type === 'multi_coupon' || type === 'equity_card'
                    ? <Row className="mb30" type="flex" align="top">
                        <Col span={2}>
                            <div>
                                <span className="star"></span>
                                <span className="color_black">可见用户</span>
                            </div>
                        </Col>
                        <Col span={6}>
                            <Tree
                                checkable
                                showIcon
                                onCheck={this.onCheck}
                                defaultSelectedKeys={user_type}
                                checkedKeys={user_type}
                            >
                                <TreeNode title="新用户" key="1" icon={
                                    <Tooltip placement="topLeft" title={"还未注册的用户或已经注册还没有下单的用户"}>
                                    <Icon type="question-circle" />
                                    </Tooltip>
                                }>
                                </TreeNode>
                                <TreeNode title="老顾客" key="4" icon={
                                    <Tooltip placement="topLeft" title={"交易次数>=1次的交易次数"}>
                                    <Icon type="question-circle" />
                                    </Tooltip>
                                }>
                                    <TreeNode title="充值用户" key="2" />
                                    <TreeNode title="非充值用户" key="3" />
                                </TreeNode>
                            </Tree>
                        </Col>
                    </Row>
                    : null
                }

                {
                    type === 'equity_card'
                    ? <div className="wrap">
                        <Row>
                            <Col span={2}>
                                <div>
                                    <span className="star"></span>
                                    <span className="color_black">使用须知</span>
                                </div>
                            </Col>
                            <Col span={14}>
                                <div className="editor_wrap">
                                    <BraftEditor
                                        value={editorState}
                                        onChange={this.handleEditorChange}
                                        excludeControls={['media']}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    : null
                }

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">福利状态</span>
                        </div>
                    </Col>
                    <Col span={22}>
                        <Radio.Group
                            value={status}
                            onChange={(e) => this.statusChange(e.target.value)}
                        >
                            <Radio value={1}>开启</Radio>
                            <Radio value={0}>暂停</Radio>
                        </Radio.Group>
                    </Col>
                </Row>

                {
                    type === 'multi_coupon'
                    ?   <Row className="mb30" type="flex" align="middle">
                            <Col span={2}>
                                <div>
                                    <span className="star"></span>
                                    <span className="color_black">是否在首页展示</span>
                                </div>
                            </Col>
                            <Col span={22}>
                                <Radio.Group
                                    value={show}
                                    onChange={(e) => {this.setState({show: e.target.value})}}
                                >
                                    <Radio value={true}>是</Radio>
                                    <Radio value={false}>不是</Radio>
                                </Radio.Group>
                            </Col>
                        </Row>
                    :   null
                }

                <Row>
                    <Col span={12}>
                        <div className="bottom_wrap">
                            <Button type="primary" ghost onClick={this.goBack}>
                                取消
                            </Button>
                            <Button type="primary" onClick={this.handleSubmit}>
                                保存
                            </Button>
                        </div>
                    </Col>
                </Row>

            <Modal
                title={null}
                visible={visible}
                onOk={this.handleOk}
                onCancel={()=>{this.setState({visible: false})}}
                width="70vw"
            >
                <div className="model_head">
                    <div className="head_title">关联优惠券</div>
                </div>
                <Table
                    size="middle"
                    rowKey={"id"}
                    dataSource={selectList}
                    columns={this.columns}
                    pagination={
                        {
                            current,
                            total: listNum,
                            onChange: this.changePage,
                            pageSize: 10
                        }
                    }
                />
            </Modal>

        </div>;
    }

}
export default WelfareEdit