import React from 'react'
import { Button, Table, Tooltip, message, Form, Input, Modal, Icon, Popconfirm, Radio } from 'antd';
import './staffList.scss';
import { getRequest, postRequest } from "../../../api/http";
import { apiUrl } from "../../../api/api_url";
import { RadioChangeEvent } from 'antd/lib/radio';

export default class StaffList extends React.Component<any>{

    state = {
        current: 1, // 当前页面索引
        pageSize: 20, // 每页数量
        total: 0,    // 总共多少条数据
        staffList: [],
        currentStaff: {
            id: undefined,
            name: undefined,
            mobile: undefined,
            job: undefined,
            status: 1,
            is_vacation: 2
        },
        listParams: {
            name: undefined,
            mobile: undefined,
            job: undefined,
            start_time: undefined,
            end_time: undefined
        },
        isAddFormVisible: false,
        addFormData: {
            id: undefined,
            name: undefined,
            mobile: undefined,
            job: undefined,
            status: 1,
            is_vacation: 2
        },
        currentTimeTag: 0,
        loadingText: ''
    }

    async componentDidMount() {
        this.getStaffList(1);
    }

    parseDate = (type:Number) => {
        // 解析日期
        let startDate:Date = new Date(),
            year: number = startDate.getFullYear(),
            month: number = startDate.getMonth(),
            endDate: Date = new Date();

        switch (type) {
            case 2: // 上月
                startDate = new Date(month === 0 ? year - 1 : year, month === 0 ? 11 : month - 1, 1);
                endDate = new Date(year, month, 0);
                break;

            case 3: // 近3月
                startDate = new Date(startDate.getTime() - 90*86400000);
                break;

            case 4: // 近半年
                startDate = new Date(startDate.getTime() - 180*86400000);
                break;

            default: // 1/本月
                endDate = new Date(month === 12 ? year + 1 : year, month === 11 ? 0 : month + 1, 0);
        }

        let start_time = startDate.getFullYear().toString() + '-' + (startDate.getMonth() < 9 ? '0' : '')
                + (startDate.getMonth() + 1).toString() + '-' + '01',
            end_time = endDate.getFullYear().toString() + '-' + (endDate.getMonth() < 9 ? '0' : '')
                + (endDate.getMonth() + 1).toString() + '-' + (endDate.getDate() < 10 ? '0' : '')
                + endDate.getDate(); 

        return {start_time, end_time};
    }


    getStaffList = async (current: any, pageSize?: any) => {
        this.setState({loadingText: '加载中...'})
        const res: any = await getRequest(apiUrl.getStaffList, { ...this.state.listParams, current: current || this.state.current, pageSize: pageSize || this.state.pageSize })
        this.setState({loadingText: ''})
        if (res.code === 1) {
            //    return res.data
            this.setState({ staffList: res.data.list, total: res.data.total })
        } else {
            message.error("服务器请求数据失败")
            return
        }
    }

    
    addStaff = async () => {
        let postData: any = {}
        const {addFormData, currentStaff} = this.state

        // console.log(addFormData, currentStaff)

        if (addFormData.id) {
            postData.id = addFormData.id
            if (addFormData.name !== currentStaff.name) {
                postData.name = addFormData.name;
            }
            if (addFormData.mobile !== currentStaff.mobile) {
                postData.mobile = addFormData.mobile;
            }
            if (addFormData.job !== currentStaff.job) {
                postData.job = addFormData.job;
            }
            if (addFormData.status !== currentStaff.status) {
                postData.status = addFormData.status;
            }
            if (addFormData.is_vacation !== currentStaff.is_vacation) {
                postData.is_vacation = addFormData.is_vacation;
            }
        } else {
            postData = addFormData
        }

        const res: any = await postRequest(this.state.addFormData.id && apiUrl.updateStaff || apiUrl.addStaff, postData)
        if (res.code === 1) {
            this.getStaffList(1)
        }

        this.setState({ isAddFormVisible: false });
    }


    deleteBtnClick = async (row: any) => {
        const res: any = await getRequest(apiUrl.deleteStaff, { id: row.id });

        if (res.code === 1) {
            this.getStaffList(1)
        }
    }


    setSearchInput = async (e: any) => {
        let listParams: any = this.state.listParams;
        listParams[e.target.name] = e.target.value;
        this.setState({ listParams })
    }

    setAddFormInput = async (e: any) => {
        let addFormData: any = this.state.addFormData;
        addFormData[e.target.name] = e.target.value;
        this.setState({ addFormData })
    }

    pageChange = (current: number) => {
        this.setState({ current: current })
        this.getStaffList(current, this.state.pageSize)
    }

    // 改变每页数量
    onSizeChange = async (current: number, pageSize: number) => {
        this.setState({ pageSize })
        this.getStaffList(1, pageSize)
    }

    onChangeTimeTag = (e:any) => {
        let {currentTimeTag} = this.state 
        currentTimeTag = e.target.value

        let start_time:any = undefined,
         end_time:any = undefined

        if ([1,2,3,4].indexOf(currentTimeTag) !== -1) {
            let date:any = this.parseDate(currentTimeTag)
            start_time = date.start_time
            end_time = date.end_time
        }

        this.setState({currentTimeTag, listParams:{...this.state.listParams, start_time, end_time}})
    }

    render() {
        const columns = [
            {
                title: '服务员',
                key: 'name',
                dataIndex: 'name'
            },
            {
                title: '手机号码',
                key: 'mobile',
                dataIndex: 'mobile',
            },
            {
                title: '在职状态',
                key: 'status_desc',
                dataIndex: 'status_desc',
            },
            {
                title: '在岗状态',
                key: 'is_vacation_desc',
                dataIndex: 'is_vacation_desc',
            },
            {
                title: '岗位',
                key: 'job',
                dataIndex: 'job',
            },
            {
                title: '服务桌数',
                key: 'service_table_count',
                dataIndex: 'service_table_count',
            },
            {
                title: '总平均分',
                key: 'avg',
                dataIndex: 'avg'
            },
            {
                title: '服务平均分',
                key: 'avg_service',
                dataIndex: 'avg_service'
            },
            {
                title: '菜品平均分',
                key: 'avg_food',
                dataIndex: 'avg_food'
            },
            {
                title: '总评数',
                key: 'comment_total',
                dataIndex: 'comment_total',
            },
            {
                title: '好评数',
                key: 'good_count',
                dataIndex: 'good_count',
            },
            {
                title: '差评数',
                key: 'bad_count',
                dataIndex: 'bad_count',
            },
            {
                title: '1星',
                key: 'one_star',
                dataIndex: 'one_star',
            },
            {
                title: '2星',
                key: 'two_star',
                dataIndex: 'two_star',
            },
            {
                title: '3星',
                key: 'three_star',
                dataIndex: 'three_star',
            },
            {
                title: '4星',
                key: 'four_star',
                dataIndex: 'four_star',
            },
            {
                title: '5星',
                key: 'five_star',
                dataIndex: 'five_star',
            },
            {
                title: '操作',
                dataIndex: 'action',
                render: (text: any, record: any, index: number) => (
                    <div className="btn_wrap">
                        <Tooltip placement="top" title='编辑'>
                            <Icon style={{ fontSize: 21, color: '#3975FF' }} type="calendar" key={'bind-' + index} onClick={() => {
                                this.setState({
                                    isAddFormVisible: true,
                                    addFormData: {
                                        id: record.id,
                                        name: record.name,
                                        mobile: record.mobile,
                                        job: record.job,
                                        status: record.status,
                                        is_vacation: record.is_vacation
                                    },
                                    currentStaff: {
                                        id: record.id,
                                        name: record.name,
                                        mobile: record.mobile,
                                        job: record.job,
                                        status: record.status,
                                        is_vacation: record.is_vacation
                                    }
                                })
                            }} />
                        </Tooltip>
                        <Popconfirm
                            title={'此操作将不可恢复，确认要删除「' + record.name + '」吗？'}
                            onConfirm={() => { this.deleteBtnClick(record) }}
                            okText="删除"
                            okType="danger"
                            cancelText="取消"
                            placement="left"
                        >
                            <Tooltip placement="top" title='删除'>
                                <Icon style={{ fontSize: 22, marginLeft: 16, color: "red" }} key={'del-' + index} type="delete" />
                            </Tooltip>
                        </Popconfirm>
                    </div>
                ),
            }
        ];

        const layout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 18 },
        };


        return <div className="container" style={{ margin: 15 }}>
            <div>
                <Form className="form" name="horizontal_login" layout="inline" onSubmit={(e: any) => {
                    console.log(e)
                    return false;
                }}>
                    <Form.Item>
                        <Input name="name" placeholder="服务员" onChange={this.setSearchInput} allowClear={true} onKeyDown={(key: any) => {
                            if (key.keyCode === 13) {
                                this.getStaffList(1)
                            }
                        }} />
                    </Form.Item>
                    <Form.Item>
                        <Input name="mobile" type="mobile" placeholder="手机号码" onChange={this.setSearchInput} allowClear={true} onKeyDown={(key: any) => {
                            if (key.keyCode === 13) {
                                this.getStaffList(1)
                            }
                        }} />
                    </Form.Item>
                    <Form.Item>
                        <Input name="job" type="text" placeholder="岗位" onChange={this.setSearchInput} allowClear={true} onKeyDown={(key: any) => {
                            if (key.keyCode === 13) {
                                this.getStaffList(1)
                            }
                        }} />
                    </Form.Item>
                    <Form.Item>
                        <Radio.Group onChange={this.onChangeTimeTag} value={this.state.currentTimeTag}>
                            <Radio value={0}>全部</Radio>
                            <Radio value={1}>本月</Radio>
                            <Radio value={2}>上月</Radio>
                            <Radio value={3}>近3月</Radio>
                            <Radio value={4}>近半年</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item>
                        <Button icon="search" type="primary" onClick={() => { this.getStaffList(1) }}>搜索</Button>
                    </Form.Item>
                    <Form.Item>{this.state.loadingText}</Form.Item>
                    <Form.Item className="fr">
                        <Button icon="plus" type="primary" ghost onClick={() => {
                            this.setState({
                                isAddFormVisible: true,
                                addFormData: {
                                    id: undefined,
                                    name: undefined,
                                    mobile: undefined,
                                    job: undefined,
                                    status: 1,
                                    is_vacation: 2
                                }})
                        }}>添加服务员</Button>
                    </Form.Item>

                </Form>


                <Modal title={(this.state.addFormData.id && '编辑' || '添加') + '服务员'} visible={this.state.isAddFormVisible} okButtonProps={{
                    disabled: !this.state.addFormData.name || !this.state.addFormData.mobile || !this.state.addFormData.job
                }} onOk={this.addStaff} onCancel={() => {
                    this.setState({ isAddFormVisible: false })
                }} okText={(this.state.addFormData.id && '保存' || '添加') + '服务员'}>
                    <Form {...layout} name="control-hooks" onSubmit={() => { return false; }}>
                        <Form.Item label="服 务 员">
                            <Input name="name" placeholder="服务员昵称" value={this.state.addFormData.name} onChange={this.setAddFormInput} allowClear={true} />
                        </Form.Item>
                        <Form.Item label="手机号码">
                            <Input name="mobile" type="mobile" placeholder="手机号码" value={this.state.addFormData.mobile} onChange={this.setAddFormInput} allowClear={true} />
                        </Form.Item>
                        <Form.Item label="岗　　位">
                            <Input name="job" type="text" placeholder="岗位" value={this.state.addFormData.job} onChange={this.setAddFormInput} allowClear={true} />
                        </Form.Item>
                        <Form.Item label="在职状态">
                            <Radio.Group onChange={(e: RadioChangeEvent) => {
                                this.setState({ addFormData: { ...this.state.addFormData, status: e.target.value } })
                            }} value={this.state.addFormData.status}>
                                <Radio value={1}>在职</Radio>
                                <Radio value={2} disabled={!this.state.addFormData.id}>离职</Radio>
                                {this.state.addFormData.status === 2? <span style={{color:'red'}}>离职状态下，排班将被移除</span>: <></>}
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label="在岗状态">
                            <Radio.Group onChange={(e: RadioChangeEvent) => {
                                this.setState({ addFormData: { ...this.state.addFormData, is_vacation: e.target.value } })
                            }} value={this.state.addFormData.is_vacation}>
                                <Radio value={2}>正常上班</Radio>
                                <Radio value={1} disabled={!this.state.addFormData.id}>休假</Radio>
                                {this.state.addFormData.is_vacation === 1? <span style={{color:'red'}}>休假状态下，排班将被移除</span>: <></>}
                            </Radio.Group>
                        </Form.Item>
                    </Form>
                </Modal>


                <Table
                    // rowSelection={this.rowSelection}
                    dataSource={this.state.staffList}
                    columns={columns}
                    rowKey={"id"}
                    size="middle"
                    locale={{ emptyText: '暂无数据' }}
                    pagination={{
                        // current: this.state.currentPage,
                        // defaultCurrent: 1,
                        total: this.state.total,
                        pageSize: this.state.pageSize,
                        showQuickJumper: true,
                        showSizeChanger: true,
                        onShowSizeChange: this.onSizeChange,
                        onChange: this.pageChange,
                        showTotal: (total: number) => `共${total}条`
                    }}
                />
            </div>
        </div>
    }
}