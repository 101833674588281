import React, { useEffect, useState } from 'react'
import './equity_card_order.scss'
import { Row, Col, DatePicker, Table, Avatar, message } from 'antd';
import Search from 'antd/lib/input/Search';
import { getRequest, postRequest } from '../../../api/http';
import { apiUrl } from '../../../api/api_url';

const { RangePicker } = DatePicker;
interface IProps {
    location: {
        query?: {
            day: string
        }
    }
}

/**
 * 会员列表
 */
let  username = "";  //用户昵称
let times:any = [];      // 时间区间
const MemberList = (props: IProps) => {
//    let tempItem:any = {};       // 记录临时操作对象
//    let mark = "";               // 备注

   const [memberList,setMemberList] = useState([]);       // 公众号数组
   const [count,setCount] = useState(0);                  // 总数量
   const [total,setTotal] = useState(0);                  // 总收入
   const [levelList,setLevelList] = useState([]);
   const [visible,setVisible] = useState(false);
   const [isLoading,setIsLoading] = useState(false);
   const [groupList,setGroupList] = useState([]);
   const [tempItem, settempItem] = useState<any>();
   const [mark, setmark] = useState<string>('');
   const [confirmLoading, setconfirmLoading] = useState<boolean>(false);
   const [page,setpage] = useState(1);
   const [page_size,setpage_size] = useState(10);

  // 相当于 componentDidMount 和 componentDidUpdate:
    useEffect(() =>{
        // requestGetMemberList();

        // times = dateString;
        if(props.location.query && props.location.query.day){
            times = [props.location.query.day, props.location.query.day];
            requestGetMemberList();
        }else{
            requestGetMemberList();
        }
        console.log(props.location.query)
        //getMemberLeverList();
        //getGroupList();
    },[]);
   // 获取会员列表
   const requestGetMemberList = async (data = { }) => {
        setIsLoading(true);
       let param:any = {
            current: page,
            pageSize: page_size,
            ...data
       }
       Object.keys(param).map(el => {
           if(!param[el]) delete param[el]
       })
       if(times.length > 0){
           console.log(times[0] != '')
           if(times[0] != '') {
                param.start_time = times[0];
           }
           if(times[1] != '') {
                param.end_time = times[1];
            }
       }
        const res:any = await getRequest(apiUrl.equityCardList,param);
        if(res.code === 1){
            setMemberList(res.data.list);
            setCount(res.data.count);
            setTotal(res.data.total);
            setIsLoading(false);
        }
   };

   // 获取分组列表
   const getGroupList = async() =>{
       const res:any = await getRequest(apiUrl.groupList);
       console.log(res);
       if(res.code === 1){
           setGroupList(res.data.list);
       }
   }
   // 获取会员等级列表
   const getMemberLeverList =  async () =>{
       const res: any = await getRequest(apiUrl.fansLevelList);
       console.log('res',res);
       if(res.code === 1){
        setLevelList(res.data);
       }
   };
   // 保存备注
   const saveRemarks =  async () =>{
       if (mark) {
           setconfirmLoading(true);
         const res: any = await postRequest(apiUrl.addMark, {
           id: tempItem.id,
           remark: mark,
         });
         if (res.code === 1) {
           message.success("更新成功");
           setconfirmLoading(false);
           setVisible(false);
           requestGetMemberList({ page })
         }
       } else {
         message.warn("备注名不能为空");
       }

   }
    const createTable = () =>{
        const columns = [
            {
                title: '序号',
                render:(text: any,record: any,index:number)=>`${index+1}`,
            },
            {
                title: '用户昵称',
                dataIndex: 'id',
                key: 'id',
                render: (text: string,record:any,index:number) =>
                <Row className="" justify="start" align="middle">
                    <div><Avatar src={record.headimgurl} /></div>
                    <div>{record.nickname}</div>
                </Row>
            },
            {
                title: '订单编号',
                dataIndex: 'code',
                key: 'code'
            },
            {
                title: '下单时间',
                dataIndex: 'created_at',
                key: 'created_at',
            },
            {
                title: '有效时间',
                dataIndex: 'expire_time',
                key: 'expire_time'
            },
            {
                title: '实付金额',
                dataIndex: 'price',
                key: 'price'
            },
            // {
            //     title: '操作',
            //     render: (text: string, record:any, index:number) =>
            //     <a onClick={() =>{
            //         settempItem(record);
            //         setmark(record.mark);
            //         setVisible(true);
            //     }}>加分组</a>
            // }
        ];
        return <Table
            dataSource={memberList}
            columns={columns}
            rowKey={"id"}
            loading={isLoading}
            size="middle"
            pagination={{
                total: count,
                current: page,
                pageSize: page_size,
                onChange: (page1:number) =>{
                    setpage(page1)
                   requestGetMemberList({ page: page1 });
                },
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (total: number) => `共${total}条`,
                onShowSizeChange: (current:number, size: number) => {setpage_size(size); requestGetMemberList({ page, pageSize: size });}

            }}
        />;
    }

    return <div className="member_view l_content">
        <div className="select_group">
            <Row>
                <Col span={4}>
                    <Search
                        placeholder="请输入用户昵称"
                        onSearch={value => {
                            username = value;
                            requestGetMemberList({ nickname: value });

                        }}
                    />
                </Col>
                <Col span={9}>
                    <div className="select_item">
                        <div>下单时间：</div>
                        <RangePicker
                            format={`YYYY-MM-DD`}
                            showTime={{ format: 'YYYY-MM-DD' }}
                            onChange={(e:any, dateString) => {
                                times = dateString;
                                requestGetMemberList();
                            }}
                            placeholder={times[0] != '' ? times : ['开始时间', '结束时间']} />
                    </div>
                </Col>
            </Row>
        </div>
        <div className='all_user'>
            总收入金额：{total}元
        </div>
        {createTable()}
        {/* <Modal
          title="顾客备注"
          visible={visible}
          closable={false}
          cancelText="取消"
          okText="保存"
          destroyOnClose={true}
          confirmLoading={confirmLoading}
          onOk={() => saveRemarks()}
          onCancel={() => setVisible(false)}
        >
          <TextArea
            placeholder="顾客备注..."
            value={mark}
            autoSize={{ minRows: 3, maxRows: 5 }}
            onChange={(e) =>{
                setmark(e.target.value);
            }}
          />
        </Modal> */}
    </div>
}

export default MemberList;
