import React from 'react';
import { Row, Col } from 'antd';
import '../management.scss';

import winAddAdList from '../../../../../../assret/images/winAddAdList.png';
import winAddAdEdit from '../../../../../../assret/images/winAddAdEdit.png';
import winAddAdDetail from '../../../../../../assret/images/winAddAdDetail.png';
import mAdIndex from '../../../../../../assret/images/mAdIndex.png';
import mAdIndex2 from '../../../../../../assret/images/mAdIndex2.png';
import mAdIndex3 from '../../../../../../assret/images/mAdIndex3.png';
import mAdIndex4 from '../../../../../../assret/images/mAdIndex4.png';
import mAdIndex21 from '../../../../../../assret/images/mAdIndex2-1.png';
import mAdIndex22 from '../../../../../../assret/images/mAdIndex2-2.png';
import mAdIndex23 from '../../../../../../assret/images/mAdIndex2-3.png';
import mAdIndex24 from '../../../../../../assret/images/mAdIndex2-4.png';
import mAdIndex31 from '../../../../../../assret/images/mAdIndex3-1.png';
import mAdIndex32 from '../../../../../../assret/images/mAdIndex3-2.png';
import mAdIndex33 from '../../../../../../assret/images/mAdIndex3-3.png';

interface ShopManagementDetail {
    history: any,
}

class ShopManagementDetail extends React.Component<ShopManagementDetail> {

    render() {
        return  <Row className="typeDetail l_content">
                    <Col className="typeDetail-title">一、功能介绍</Col>
                    <Col className="typeDetail-col">针对点餐小程序端的banner展示，方便运营人员自行操作（随时更改）广告位，促进消费者成交。</Col>
                    <Col className="typeDetail-title">二、功能路径</Col>
                    <Col className="typeDetail-col">电脑端登录后台-广告管理-广告列表-添加广告。</Col>
                    <Col className="typeDetail-col">
                        <img src={winAddAdList} />
                    </Col>
                    <Col className="typeDetail-title">三、操作流程</Col>
                    <Col className="typeDetail-col">1、<span className="txt-bold">添加广告</span>，在“广告列表”界面下点击“添加”</Col>
                    <Col className="typeDetail-col">
                        <img src={winAddAdEdit} />
                    </Col>
                    <Col className="typeDetail-col">1、广告标题：填写广告标题</Col>
                    <Col className="typeDetail-col">2、展示时间：有固定时间和永久时间，当选择固定时间时，只在选择这个时间段展示；当选择永久，则一直显示该广告</Col>
                    <Col className="typeDetail-col">3、广告版位：有全局中部弹窗、全局右下角弹窗、首页-轮播图、首页-中部弹窗、首页-中部图片广告、 首页-右下角弹窗、点餐-中部弹窗、点餐-上部图片广告、点餐-右下角弹窗、点餐-确定下单-中部弹窗、点餐-确定下单-上部图片广告、点餐-确定下单-底部图片广告、点餐-确定下单-右下角弹窗、点餐-订单详情-中部弹窗、点餐-订单详情-上部图片广告、点餐-订单详情-底部图片广告、点餐-订单详情-右下角弹窗、我的-中部弹窗、我的-右下角弹窗、我的-上部图片广告、我的-底部图片广告</Col>
                    <Col className="typeDetail-col">a、全局中部弹窗是在用户进入点餐小程序某个页面都会弹窗显示，如图1-1</Col>
                    <Col className="typeDetail-col">b、全局右下角弹窗是在用户进入点餐小程序某个页面都会弹窗显示，如图1-2</Col>
                    <Col className="typeDetail-col">c、首页轮播图是只在首页显示，如图1-3</Col>
                    <Col className="typeDetail-col">d、首页中部弹窗是只在首页弹窗，如图1-1</Col>
                    <Col className="typeDetail-col">e、首页中部广告图是只在首页显示，如图1-4</Col>
                    <Col className="typeDetail-col">f、首页右下角弹窗只在首页显示，如1-2</Col>
                    <Col className="typeDetail-col ad-img">
                        <div>
                            <img src={mAdIndex} />
                            <span>1-1</span>
                        </div>
                        <div>
                            <img src={mAdIndex2} />
                            <span>1-2</span>
                        </div>
                    </Col>
                    <Col className="typeDetail-col ad-img">
                        <div>
                            <img src={mAdIndex3} />
                            <span>1-3</span>
                        </div>
                        <div>
                            <img src={mAdIndex4} />
                            <span>1-4</span>
                        </div>
                    </Col>
                    <Col className="typeDetail-col">g、点餐中部弹窗在用户进入点餐页面都会弹窗显示，如图1-1</Col>
                    <Col className="typeDetail-col">h、点餐上部图片广告在点餐页面显示，如图2-1</Col>
                    <Col className="typeDetail-col">i、 点餐中部弹窗在用户进入点餐页面都会弹窗显示，如图1-2</Col>
                    <Col className="typeDetail-col">j、点餐-确定下单-中部弹窗在用户进入确定下单也会弹窗显示，如图1-1</Col>
                    <Col className="typeDetail-col">k、 点餐-确定下单-上部图片广告在确定页面显示，如图2-2</Col>
                    <Col className="typeDetail-col">l、点餐-确定下单-底部图片广告在确定页面显示。如图2-3</Col>
                    <Col className="typeDetail-col">n、点餐-确定下单-右下角弹窗在用户进入确定下单页面会弹窗显示，如图1-2</Col>
                    <Col className="typeDetail-col">m、点餐-订单详情-中部弹窗在用户进入订单详情页面会弹窗显示，如图1-2</Col>
                    <Col className="typeDetail-col">o、点餐-订单详情-上部图片广告在订单详情显示，如图2-4</Col>
                    <Col className="typeDetail-col ad-img">
                        <div>
                            <img src={mAdIndex21} />
                            <span>2-1</span>
                        </div>
                        <div>
                            <img src={mAdIndex22} />
                            <span>2-2</span>
                        </div>
                    </Col>
                    <Col className="typeDetail-col ad-img">
                        <div>
                            <img src={mAdIndex23} />
                            <span>2-3</span>
                        </div>
                        <div>
                            <img src={mAdIndex24} />
                            <span>2-4</span>
                        </div>
                    </Col>
                    <Col className="typeDetail-col">p、点餐-订单详情-底部图片广告订单详情页面，如图3-1</Col>
                    <Col className="typeDetail-col">q、点餐-订单详情-右下角弹窗用户进入订单详情页面会弹窗显示，如图1-2</Col>
                    <Col className="typeDetail-col">r、 我的-中部弹窗用户进入我的页面弹窗显示，如图1-1</Col>
                    <Col className="typeDetail-col">s、我的-右下角弹窗进入我的页面会弹窗显示，如图1-2</Col>
                    <Col className="typeDetail-col">t、 我的-上部图片广告用户进入我的显示广告图片，如图3-2</Col>
                    <Col className="typeDetail-col">u、我的-底部图片广告用户进入我的显示广告图片，如图3-3</Col>
                    <Col className="typeDetail-col ad-img">
                        <div>
                            <img src={mAdIndex31} />
                            <span>3-1</span>
                        </div>
                        <div>
                            <img src={mAdIndex32} />
                            <span>3-2</span>
                        </div>
                        <div>
                            <img src={mAdIndex33} />
                            <span>3-3</span>
                        </div>
                    </Col>
                    <Col className="typeDetail-col">
                        <img src={winAddAdDetail} />
                    </Col>
                    <Col className="typeDetail-col">4、广告图片：上传广告图片，建议按提示的尺寸上传相关图片</Col>
                    <Col className="typeDetail-col">5、跳转类型：有不跳转、网页跳转、小程序页面跳转三种类型</Col>
                    <Col className="typeDetail-col">a、不跳转，只显示该广告，不任何跳转</Col>
                    <Col className="typeDetail-col">b、网页跳转，输入跳转的网址，点击跳转到相关网址</Col>
                    <Col className="typeDetail-col">c、小程序页面，选择相关的小程序页面，点击跳转到相关页面</Col>
                    <Col className="typeDetail-col">6、广告描述：填写广告描述</Col>
                    <Col className="typeDetail-col">7、优先级：填写优先级，优先级数字越大，广告内容越靠前展示</Col>
                    <Col className="typeDetail-col">8、状态：默认启用状态，点击停用则广告不生效</Col>
                </Row>
    }
}

export default ShopManagementDetail;
