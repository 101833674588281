import React, { useEffect, useState, useRef } from 'react'
import { Select } from 'antd'
import ArcProgress from './components/arcProgress'
import DoughnutChart from './components/doughnutChart'
import LineChart from './components/lineChart'
import { getRequest } from '../../api/http'
import { apiUrl } from '../../api/api_url'
import { toParseFloat } from '../../utils/common'
import moment from 'moment'

import './index.scss'
import { keepTwoNum } from '../../utils/numUtils';

const { Option } = Select;

const Dashboard = (props:any)=>{

    const [ loading, setLoading ] = useState(false)
    const [ monthLoading, setMonthLoading ] = useState(false)
    const [ type, setType ] = useState<Number>(1)
    const [ monthType, setMonthType ] = useState<Number>(1)
    const [ config, setConfig ] = useState<Number>(0)
    const [ monthConfig, setMonthConfig ] = useState<Number>(0)
    const [ dayData, setDayData ] = useState<any>({})
    const [ dayOverview, setDayOverview ] = useState<any>({}) //整理面板数据
    const [ monthData, setMonthData ] = useState<any>({})
    const [ monthOverview, setMonthOverview ] = useState<any>({}) //整理面板数据
    const configList:any = {
        1: '总实收金额' ,
        2: '就餐桌数' ,
        3: '均桌消费' ,
        4: '新顾客桌数' ,
        5: '老顾客占比' ,
        6: '开桌率' ,
        7: '消费频次' ,
        8: '复购率' ,
    }
    const unit:any = {
        '1':'元',
        '2':'桌',
        '3':'%',
        '4':'次',
    }
    const [ lineChartBottom, setLineChartBottom ] = useState<any>({
        left:'今日',
        right:'昨日'
    })
    const [ lineChartBottom2, setLineChartBottom2 ] = useState<any>({
        left:'本月',
        right:'上月'
    })


    useEffect(()=>{
        _init()
    },[])

    const _init = async() => {
        getDayData(1,1)
        getMonThData(1,1)
    }

    const getDayData = async(type:Number,config_id:Number) => {
        let params:any = {
            type:type
        }
        if(config_id) params.config_id = config_id
        setType(type)
        setConfig(config_id)
        setLoading(true)
        const res:any = await getRequest(apiUrl.getTargetDayOverview,params)
        if(res.code === 1){
            let obj:any = {}
            res.data.overview.map((item:any,index:number)=>{
                if(item.config_id<7){
                    obj[item.config_id] = toParseFloat(item)
                }
            })
            // console.log('obj',obj)
            setDayOverview(obj)
            setDayData(res.data)
        }
        setLoading(false)
    }

    const getMonThData = async(type:Number,config_id:Number) => {
        let params:any = {
            type:type
        }
        if(config_id) params.config_id = config_id
        setMonthType(type)
        setMonthConfig(config_id)
        setMonthLoading(true)
        const res:any = await getRequest(apiUrl.getTargetMonthOverview,params)
        if(res.code === 1){
            let obj:any = {}
            res.data.overview.map((item:any,index:number)=>{
                obj[item.config_id] = toParseFloat(item) //批量转浮点行
            })
            setMonthOverview(obj)
            setMonthData(res.data)
        }
        setMonthLoading(false)
    }

    const getPercentage = (value:any,target:any) => {
        let percentage:any
        if(parseFloat(target) === 0){
            percentage = 0.00
        }else{
            percentage = Math.round(value/target*100)
        }
        return percentage
    }
    const getDiscount = (value:any,target:any) => {
        let percentage:any
        if(target===0){
            percentage = 0.00
        }else{
            percentage = keepTwoNum(value/target) * 10
            if(percentage.toString().indexOf('.')!==-1){
                percentage = Math.floor(percentage*10)/10
            }
        }
        return percentage
    }

    const dashboard_statistics_items = (data:any,title:string) =>{
        return(
            <div className="total wbg">
                <div className="total_title row-between">
                    <div>{title}</div>
                    {/* <div>{data.value!==undefined?data.value+unit[data.unit]:'--'}</div> */}
                </div>
                <div className="row-between">
                    <div>
                        <div><span className="circle bg_red"/><span className="total_sub_title">目 标</span>：{data.target!==undefined?data.target:'--'}{data?unit[data.unit]:''}</div>
                        <div><span className="circle bg_yellow"/><span className="total_sub_title">已完成</span>：{data.value!==undefined?data.value:'--'}{data?unit[data.unit]:''}</div>
                    </div>
                    <div className="total_line">
                        <div className="total_line_seat_view"></div>
                        <img src={require('.././../assret/images/dashboard_line_1.jpg')} />
                        <img src={require('.././../assret/images/dashboard_line_2.jpg')} />
                    </div>
                </div>
                <div className="finish">{data.value!==undefined?getPercentage(data.value,data.target):'--'}% 总完成度</div>
            </div>
        )
    }
    //初始化数据为‘--’
    const dataInit = (dayData:any,field:string) =>{
        return dayData?dayData[field]:'--'
    }

    const changeDaySelect = (e:any) => {
        let lineChartBottomTemp = JSON.parse(JSON.stringify(lineChartBottom))
        switch(e){
            case 1:
                lineChartBottomTemp.left='今日'
                lineChartBottomTemp.right='昨日'
                break
            case 2:
                lineChartBottomTemp.left='昨天 '
                lineChartBottomTemp.right='前天'
                break
            case 3:
                lineChartBottomTemp.left='近七天'
                lineChartBottomTemp.right='上近七天'
                break
        }
        // console.log('lineChartBottomTemp',lineChartBottomTemp)
        setLineChartBottom(lineChartBottomTemp)
        getDayData(e,config)
    }
    const changeMonthSelect = (e:any) => {
        let lineChartBottomTemp = JSON.parse(JSON.stringify(lineChartBottom2))
        switch(e){
            case 1:
                lineChartBottomTemp.left='本月'
                lineChartBottomTemp.right='上月'
                break
            case 2:
                lineChartBottomTemp.left='上月 '
                lineChartBottomTemp.right='前月'
                break
            case 3:
                lineChartBottomTemp.left='近三月'
                lineChartBottomTemp.right='上近三月'
                break
            case 4:
                lineChartBottomTemp.left='近半年'
                lineChartBottomTemp.right='上近半年'
                break

        }
        setLineChartBottom2(lineChartBottomTemp)
        getMonThData(e,monthConfig)
    }

    return (
        <div className="dashboard">
            <div className="dashboard_title row-between">
                {lineChartBottom.left}实时数据
                <Select defaultValue={1} style={{width:100}} onChange={(e:any)=>{changeDaySelect(e)}}>
                    <Option key={1} value={1}>今日</Option>
                    <Option key={2} value={2}>昨天</Option>
                    <Option key={3} value={3}>近七天</Option>
                </Select>
            </div>
            <div className="dashboard_statistics row">
                <div className="dashboard_statistics_l row wbg">
                    {loading?<div style={{width:260,height:260}} />:<ArcProgress type={1} target={dayOverview[1]&&dayOverview[1].target?dayOverview[1].target:0} value={dayOverview[1]&&dayOverview[1].value?dayOverview[1].value:0}/>}
                    <div className="total">
                        <div className="total_money">{dataInit(dayOverview[1],'value')}元</div>
                        <div className="total_title">营业实收</div>
                        <div><span className="circle bg_blue"/><span className="total_sub_title">目标实收</span>：{dataInit(dayOverview[1],'target')}元</div>
                        <div><span className="circle bg_purple"/><span className="total_sub_title">已 完 成</span>：{dataInit(dayOverview[1],'value')}元</div>
                        <div><span className="circle"/><span className="total_sub_title">应 收</span>：{dataInit(dayOverview[1],'receivable')}元</div>
                        <div><span className="circle"/>实收折扣：{dayOverview[1]&&dayOverview[1].receivable!==undefined ?getDiscount(dayOverview[1].value,dayOverview[1].receivable):'--'}折</div>
                    </div>
                </div>
                <div className="dashboard_statistics_r">
                    {/* <div className="row"> */}
                        {dashboard_statistics_items(dayOverview[5]?dayOverview[5]:{},'老顾客占比')}
                        {dashboard_statistics_items(dayOverview[2]?dayOverview[2]:{},'就餐桌数')}
                        {dashboard_statistics_items(dayOverview[3]?dayOverview[3]:{},'均桌消费')}
                    {/* </div>
                    <div className="row"> */}
                        {dashboard_statistics_items(dayOverview[4]?dayOverview[4]:{},'新顾客桌数')}
                        {dashboard_statistics_items(dayOverview[6]?dayOverview[6]:{},'开桌率')}
                    {/* </div> */}
                </div>
            </div>
            <div className="dashboard_line wbg">
                <div className="dashboard_line_title row-between">
                    {configList[config+'']}走势
                    <Select defaultValue={'1'} style={{width:150}} onChange={(e:any)=>{getDayData(type,e)}}>
                        {
                            Object.keys(configList).map((key)=>{
                                return key!='8'&&key!='7'&&<Option key={key} value={key}>{configList[key]}</Option>
                            })
                        }
                    </Select>
                </div>
                <LineChart color1='#FF5A6E' color2='#FFB741' bg1='#ffccb2' bg2='#ffffdf' current={dayData.current} prev={dayData.prev} bottomLeftTitle={lineChartBottom.left} bottomRightTitle={lineChartBottom.right}/>
            </div>
            <div className="dashboard_title row-between">
                    {monthType==1?moment().format('MM')+'月':lineChartBottom2.left}营业目标
                <Select defaultValue={1} style={{width:100}} onChange={(e:any)=>{changeMonthSelect(e)}}>
                    <Option key={1} value={1}>本月</Option>
                    <Option key={2} value={2}>上月</Option>
                    <Option key={3} value={3}>近三月</Option>
                    <Option key={4} value={4}>近半年</Option>
                </Select>
            </div>
            <div className="dashboard_statistics row">
                <div className="dashboard_statistics_l month wbg row-between">
                    {monthLoading?<div style={{width:260,height:260}} />:<ArcProgress type={2} target={monthOverview[1]&&monthOverview[1].target?monthOverview[1].target:0} value={monthOverview[1]&&monthOverview[1].value?monthOverview[1].value:0}/>}
                    <div className="total">
                        <div className="total_money">{dataInit(monthOverview[1],'value')}元</div>
                        <div className="total_title">营业实收</div>
                        <div><span className="circle bg_blue"/><span className="total_sub_title">目标实收</span>：{dataInit(monthOverview[1],'target')}元</div>
                        <div><span className="circle bg_purple"/><span className="total_sub_title">已 完 成</span>：{dataInit(monthOverview[1],'value')}元</div>
                        {/* <div><span className="circle"/><span className="total_sub_title">应 收</span>：{dataInit(monthOverview[1],'receivable')}元</div>
                        <div><span className="circle"/>整体折扣率：{monthOverview[1]&&monthOverview[1].receivable?getDiscount(monthOverview[1].value,monthOverview[1].receivable):'--'}%</div> */}
                    </div>
                </div>
                <div className="dashboard_statistics_r" style={{height:452}}>
                    {
                        Object.keys(configList).map((item:any,index:number)=>{
                            return item!=1&&<div className="dashboard_statistics_doughnut wbg" key={index}>
                                <DoughnutChart target={monthOverview[item]&&monthOverview[item].target?monthOverview[item].target:0} value={monthOverview[item]&&monthOverview[item].value?monthOverview[item].value:0} title={configList[item]} unit={monthOverview[item]?unit[monthOverview[item].unit]:''}/>
                            </div>
                        })
                    }
                    {/* <div className="row">
                        <div className="dashboard_statistics_doughnut wbg">
                            <DoughnutChart target={monthOverview[2]&&monthOverview[2].target?monthOverview[2].target:0} value={monthOverview[2]&&monthOverview[2].value?monthOverview[2].value:0} title='就餐桌数'/>
                        </div>
                        <div className="dashboard_statistics_doughnut wbg">
                            <DoughnutChart target={monthOverview[3]&&monthOverview[3].target?monthOverview[3].target:0} value={monthOverview[3]&&monthOverview[3].value?monthOverview[3].value:0} title='均桌消费'/>
                        </div>
                        <div className="dashboard_statistics_doughnut wbg">
                            <DoughnutChart target={monthOverview[4]&&monthOverview[4].target?monthOverview[4].target:0} value={monthOverview[4]&&monthOverview[4].value?monthOverview[4].value:0} title='新顾客桌数'/>
                        </div>
                        <div className="dashboard_statistics_doughnut wbg">
                            <DoughnutChart target={monthOverview[1]&&monthOverview[5].target?monthOverview[5].target:0} value={monthOverview[5]&&monthOverview[5].value?monthOverview[5].value:0} title='老顾客占比'/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="dashboard_statistics_doughnut wbg">
                            <DoughnutChart target={monthOverview[6]&&monthOverview[6].target?monthOverview[6].target:0} value={monthOverview[6]&&monthOverview[6].value?monthOverview[6].value:0} title='开桌率'/>
                        </div>
                        <div className="dashboard_statistics_doughnut wbg">
                            <DoughnutChart target={monthOverview[7]&&monthOverview[7].target?monthOverview[7].target:0} value={monthOverview[7]&&monthOverview[7].value?monthOverview[7].value:0} title='消费频次'/>
                        </div>
                        <div className="dashboard_statistics_doughnut wbg">
                            <DoughnutChart target={monthOverview[8]&&monthOverview[8].target?monthOverview[8].target:0} value={monthOverview[8]&&monthOverview[8].value?monthOverview[8].value:0} title='复购率'/>
                        </div>
                    </div> */}
                </div>
            </div>
            <div className="dashboard_line wbg mb20">
                <div className="dashboard_line_title row-between">
                    {configList[monthConfig+'']}走势
                    <Select defaultValue={'1'} style={{width:150}} onChange={(e:any)=>{getMonThData(monthType,e)}}>
                        {
                            Object.keys(configList).map((key)=>{
                                return <Option key={key} value={key}>{configList[key]}</Option>
                            })
                        }
                    </Select>
                </div>
                <LineChart color1='#FF5A5A' color2='#5D41FF' bg1='#edb2cc' bg2='#e9dfff' current={monthData.current} prev={monthData.prev}  bottomLeftTitle={lineChartBottom2.left} bottomRightTitle={lineChartBottom2.right}/>
            </div>
        </div>
    )
}

export default Dashboard
