import React from 'react'
import {Button, Row, Col, Input, Table, Select, DatePicker, message, Switch, Tabs} from "antd";
import btn_add from '../../assret/images/btn_add.png'
import goodsExport from '../../assret/images/goodsExport.png'
import { getIsPermisson, messageCustom } from "../../utils/permisstionUtil";
import styles from './registration.module.scss';
import { getRequest } from '../../api/http';
import { apiUrl } from '../../api/api_url';

import CustomDatePicker from '../datePicker/index'

class Registeration extends React.Component<any,any> {

    state = {
        start_time: '',
        end_time: '',
        list: [
            {
                title: '12342',
                text: '领取人数'
            },
            {
                title: '12342',
                text: '使用人数'
            },
            {
                title: '¥12342',
                text: '消费金额'
            },
            {
                title: '10%',
                text: '转化率'
            }
        ]
    }

    componentDidMount(){
        const nowDate = new Date();
        let toDay = `${nowDate.getFullYear()}-${this.formatData(nowDate.getMonth() + 1)}-${this.formatData(nowDate.getDate())}`;
        this.setState({
            start_time: toDay,
            end_time: toDay
        }, () => {
            //this._initInfo()
        })
    }

    formatData = (num: number) => {
        return num > 9 ? num : `0${num}`;
    };

    onChange = (e: any) => {
        console.log(e)
    }

    onCallback = (list:any) => {
        console.log(list)
        this.setState({
            start_time: list[0],
            end_time: list[1],
        }, () => {
            //this._initInfo();
        })
    }

    // 导出
    exportTable = async () => {
        if(!getIsPermisson('1_3_1_1')){
            messageCustom()
            return;
        }
        const token = localStorage.getItem('token');
        const shop_id = localStorage.getItem('shop_id');
        window.open(`${process.env.REACT_APP_BASEURL}${apiUrl.revenueExport}?token=${token}&shop_id=${shop_id}&start_time=${this.state.start_time}&end_time=${this.state.end_time}`)
    }

    render() {

        const { start_time, end_time, list } = this.state;

        return (
            <Row className='padding20'>
                <Col className={styles.top}>
                    <Col className={styles.top_left}>
                        <div className={styles.top_left_title}>注册有礼</div>
                        <div className={styles.top_left_status}>
                            <Switch defaultChecked onChange={this.onChange} />
                        </div>
                    </Col>
                    <Col>
                        <div className='new_btn margin_right_20' onClick={() => {
                            if(!getIsPermisson('2_2_1_1')){
                                messageCustom();
                                return;
                                }
                                this.props.history.push('/sidebar/merchantVoucherRegisterEdit')
                        }}>
                            <img src={btn_add}></img>
                            编辑规则
                        </div>
                    </Col>
                </Col>
                <Col>
                    <div style={{display: 'flex', alignItems:'center', justifyContent: 'space-between', paddingRight:'20px'}}>
                        <div>
                            <CustomDatePicker callback={(list: any) => { this.onCallback(list) }} current={0} currentDate={[start_time, end_time]}></CustomDatePicker>
                        </div>
                        <div className='export_btn' onClick={this.exportTable}>
                            <img src={goodsExport} />导出
                        </div>
                    </div>
                </Col>
                <Col style={{display: 'flex'}}>
                    {
                        list && list.length > 0 && list.map((item: any, index: any) => {
                            return (
                                <div className={styles.main} key={ item.text + index }>
                                    <div className={styles.main_top}>{item.title}</div>
                                    <div className={styles.main_bottom}>{item.text}</div>
                                </div>
                            )
                        })
                    }
                </Col>
                <Col>
                    <div className={styles.name}>活动名称：欢迎成为拈江湖会员</div>
                </Col>
                <Col>
                    <div className={styles.name}>活动奖品：50元菜品优惠券、甜品优惠券、30元优惠券</div>
                </Col>
            </Row>
        )
    }
}

export default Registeration