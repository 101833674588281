import React, {useEffect, useState} from "react";
import {Form,Input,Select,Row,Col,Checkbox,Button,Card,Cascader,Upload,Icon,Radio,message,Modal, AutoComplete} from 'antd';
import cityData from '../../../utils/cityData';
import { apiUrl } from '../../../api/api_url';
import { postRequest, getRequest, apiAxios } from '../../../api/http';
import UploadImg from './uploadImg/index'
import './store_auth.scss'
import { getIsPermisson, messageCustom } from "../../../utils/permisstionUtil";

const { Option } = Select

const StoreAuth = (props:any) =>{

    const [ formVal, setFormVal ] = useState<any>({
        manage_type:1,
        catering_type:1,
    }) //表单值对象
    const [ radioChecked, setRadioChecked ] = useState(false) //单选
    const [ codeLoading, setCodeLoading ] = useState(false) //验证码loading
    const [ second, setSecond ] = useState(0)   //倒计时
    const [ title, setTitle ] = useState('门店认证')
    const [ errorObj, setErrorObj ] = useState<any>({}) //审核失败的错误信息
    const [ bankList, setBankList ] = useState<any>([]) //支行列表

    // const [ businessLicense, setBusinessLicense ] = useState<any>({}) //营业执照信息

    //字段验证
    const validator:any = {
        shop_name: {rule:'require',msg:'门店名称必填'},
        province: {rule:'require',msg:'所在省市必填'},
        city: {rule:'require',msg:'所在省市必填'},
        district: {rule:'require',msg:'所在省市必填'},
        address: {rule:'require',msg:'门店地址必填'},
        manage_type: {rule:'require',msg:'经营类型必填'},
        catering_type: {rule:'require',msg:'餐饮类型必填'},
        service_phone: {rule:'require',msg:'门店客服联系方式必填'},
        contact_name: {rule:'require',msg:'联系人姓名必填'},
        contact_phone: {rule:'tel',msg:'请填写正确的联系人手机号'},
        code: {rule:'require',msg:'验证码必填'},
        id_card_front: {rule:'require',msg:'请上传身份证正面照片'},
        id_card_reverse: {rule:'require',msg:'请上传身份证反面照片'},
        id_card_info: {rule:'require',msg:'没有获取到身份证反面的信息,请重新上传'},
        hand_id_card: {rule:'require',msg:'请上传手持身份证'},
        contact_id_card: {rule:'require',msg:'没有获取到对应的身份证号码,请重新上传身份证正面'},
        bank_front: {rule:'require',msg:'请上传银行卡正面照片'},
        contact_bank_card: {rule:'require',msg:'没有获取到对应的银行卡号,请重新上传银行卡照片'},
        contact_bank_name: {rule:'require',msg:'没有获取到对应的银行卡名称,请重新上传银行卡照片'},
        contact_bank_branch_name: {rule:'require',msg:'支行名称必填'},
        shop_exterior_pic: {rule:'require',msg:'请上传门店门头照片'},
        shop_inner_pic: {rule:'require',msg:'请上传店内环境照片'},
        cashier_desk_pic: {rule:'require',msg:'请上传收银台照片'},
        business_license: {rule:'require',msg:'请上传营业执照照片'},
        business_license_num: {rule:'require',msg:'营业执照号必填'},
        business_license_name: {rule:'require',msg:'营业执照名称必填'},
        // food_license: {rule:'require',msg:'食品经营许可证必填'},
    }

    useEffect(()=>{
        //初始化表单信息
        init()
    },[])

    const init = () => {
        getRequest(apiUrl.shopApplyInfo).then((res:any)=>{
            if(res.code==1){
                if(res.data.info&&res.data.info.food_license){
                    res.data.info.contact_bank_branch_name = JSON.parse(res.data.info.bank_card_info).sub_bank_name
                    setRadioChecked(true)
                }
                setFormVal(res.data.info?res.data.info:{manage_type:1,catering_type:1,})
                //审核失败时的错误信息
                if(res.data.info&&res.data.info.error_value){
                    // setErrorList(JSON.parse(res.data.info.error_value))
                    let obj:any = {}
                    JSON.parse(res.data.info.error_value).map((item:any,index:number)=>{
                        Object.keys(item).map((subItem:any)=>{
                            obj[subItem] = item[subItem]
                        })
                    })
                    setErrorObj(obj)
                }
                if(res.data.info){
                    setTitle(title+'('+(res.data.info.status==0?'待审核':res.data.info.status==5?'审核失败':'审核成功')+')')
                }
                if(res.data.info&&res.data.info.status==0){
                    Modal.info({
                        okText: '确定',
                        icon: '',
                        title: '提示',
                        content: '您的申请信息已提交成功，请耐心等待审核'
                    })
                }
            }else{
                message.error(res.message||'获取信息失败')
            }
        }).catch(err=>{
            message.error(err||'获取信息失败')
        })
    }

    //监听字段变化
    const changeFieldVal = async( e:any, field:string ) => {
        let fieldObj:any = { [field]: field==='cityArr'?e:e.trim() }
        if(field==='cityArr'){
            fieldObj = {
                province:e[0]?e[0].name:'',
                city:e[1]?e[1].name:'',
                district:e[2]?e[2].name:'',
                province_code:e[0]?e[0].code:'',
                city_code:e[1]?e[1].code:'',
                district_code:e[2]?e[2].code:'',
            }
        }else if((field==='service_phone'||field==='contact_phone')&&e){
            var rule:any = /^[0-9]*$/
            console.log(rule.test(e))
            if(!rule.test(e)) fieldObj = { [field]: formVal[field] }
        }

        setFormVal({
            ...formVal,
            ...fieldObj
        })
    }

    //选择支行
    const onSelectBranch = (e:any, option:any, field:any ) => {
        let fieldObj:any = { [field]: e }
        fieldObj.bank_card_info = JSON.stringify({
            sub_bank_code:e,
            sub_bank_name:option.props.children
        })
        setFormVal({
            ...formVal,
            ...fieldObj
        })
    }

    let timer:any
    //获取支行列表
    const getBankList = (e:any) => {
        if(!e){
            setBankList([])
            return false
        }
        timer && clearTimeout(timer)
        //500毫秒后请求
        timer = setTimeout(async()=>{
            var res:any = await getRequest(apiUrl.shopApplySearchUnionpay, { 'name': e })
            if(res&&res.code==1){
                setBankList(res.data.data)
            }
        },500)
    }

    //单选触发
    const changeRadio = ()=>{
        setRadioChecked(!radioChecked)
        if(radioChecked){
            setFormVal({
                ...formVal,
                food_license:null
            })
        }
    }

    //发送验证码
    const sendVerifyCode = async() => {
        if(!(/^1[3456789]\d{9}$/.test(formVal.contact_phone))){
            message.error('请填写正确的手机号码')
            return
        }
        if (second !== 0) {
          return
        }
        setCodeLoading(true)
        try{
            var res:any = await getRequest(apiUrl.sendMobileCode, { 'mobile': formVal.contact_phone })
            if(res.code == 1) {
                setCodeLoading(false)
                countdown(60)
            }else{
                setCodeLoading(false)
                message.error(res.message||'发送失败')
            }
        }catch(err){
            setCodeLoading(false)
            console.log(err)
        }
    }

    //倒计时
    const countdown = (max:number)=>{
        let _second = max || second
        if (_second === 0) {
            setSecond(0)
            return
        }
        setSecond(_second - 1)
        setTimeout(() => countdown(_second - 1), 1000)
    }

    //提交
    const onsubmit = async() => {
        if(!getIsPermisson('2_7_4_1')){
            messageCustom();
            return;
          }
        try {
            Object.keys(validator).map((item)=>{
                if(validator[item]&&validator[item].rule=='require'&&(!formVal[item])||formVal[item]=='无'){
                    throw validator[item].msg;
                }
                if(validator[item]&&validator[item].rule=='tel'){
                    if(!formVal[item]){
                        throw '手机号必填';
                    }else{
                        if(!(/^1[3456789]\d{9}$/.test(formVal.contact_phone))){
                            throw validator[item].msg;
                        }
                    }
                }
                //判断身份证反面是否正常
                if(validator[item]&&item=='id_card_info'&&(JSON.parse(formVal[item]).idCardStart==''||JSON.parse(formVal[item]).idCardStart=='无')){
                    throw validator[item].msg;
                }

            })
        } catch (e) {
            message.error(e||'提交失败')
            return false
        }
        var res:any = await postRequest(apiUrl.shopApply, formVal)
        if(res.code == 1){
            message.success('提交成功')
            window.location.reload()
        }else{
            message.error(res.message||'提交失败')
        }
    }

    // 行元素
    const getRow = (child:any) =><Row gutter={16} align="middle" justify="start" className="gutter-row" type="flex">{child}</Row>

    // 列元素
    const getCol = (span:number,child: any) => getRow(<Col className="gutter-box" span={span}>{child}</Col>)

    //基础信息字段
    const basicsField:any = [
        {label:'联系人姓名',field:'contact_name',placeholder:'请填写营业执照的经营者/法定代表人姓名',type:'text', disabled:false},
        {label:'联系人手机号',field:'contact_phone',placeholder:'请填写联系人手机号',type:'text', disabled:false},
        {label:'请输入验证码',field:'code',placeholder:'请输入验证码',type:'text', disabled:false},
        {label:'上传身份证正面照片',field:'id_card_front',type:'img'},
        {label:'上传身份证反面照片',field:'id_card_reverse',type:'img'},
        {label:'手持身份证照片',field:'hand_id_card',type:'img'},
        {label:'身份证号码',field:'contact_id_card',placeholder:'请填写法人证件上的证件号码',type:'text', disabled:true},
        {label:'银行卡正面照片',field:'bank_front',type:'img'},
        {label:'银行卡号',field:'contact_bank_card',placeholder:'请填写联系人银行卡号码',type:'text', disabled:true},
        {label:'银行卡名称',field:'contact_bank_name',placeholder:'请填写银行卡名称',type:'text', disabled:true},
        {label:'支行名称',field:'contact_bank_branch_name',placeholder:'请填写银行卡支行名称',type:'text', disabled:false},
    ]
    //图片列表
    const imgListField:any = [
        // {label:'上传身份证正面照片',field:'id_card_front'},
        // {label:'上传身份证反面照片',field:'id_card_reverse'},
        // {label:'银行卡正面照片',field:'bank_front'},
        {label:'门店门头照片',field:'shop_exterior_pic'},
        {label:'店内环境照片',field:'shop_inner_pic'},
        {label:'收银台照片',field:'cashier_desk_pic'},
    ]

    //级联值重置格式
    const displayRender = (labels:any, selectedOptions:any) =>
        labels.map((label:any, i:any) => {
            const option = selectedOptions[i];
            if (i === labels.length - 1) {
                return (
                <span key={option.code}>
                    {label}
                </span>
                );
            }
            return <span key={option.code}>{label}  </span>;
        });

    //获取营业执照信息（百度接口）
    const getBaiduAccessToken = async(e:any,imgUrl:string) => {
        const res:any = await postRequest(apiUrl.shopApplyBusinessLicense,{image:e});
        if(res.code==1&&res.data){
            if(JSON.parse(res.data.data).error_code){
                message.error('获取营业执照信息失败')
            }else{
                formVal.business_license_num = JSON.parse(res.data.data)['words_result']['社会信用代码']['words']
                formVal.business_license_name = JSON.parse(res.data.data)['words_result']['单位名称']['words']
                formVal.business_license = imgUrl
                formVal.business_license_info = JSON.stringify({
                    licenseAddress:JSON.parse(res.data.data)['words_result']['地址']['words'],
                    licenseStart:JSON.parse(res.data.data)['words_result']['成立日期']['words'],
                    licenseEnd:JSON.parse(res.data.data)['words_result']['有效期']['words']
                })
                setFormVal({...formVal})
            }
        }else{
            message.error(res.message||'获取营业执照信息失败')
        }
    }

    //身份证正反面识别，银行卡号识别（百度接口）
    const uploadCallBack = async(field:string,e:any,imgUrl:string) => {
        switch(field){
            case 'id_card_front':
                //身份证正面识别回调
                const res:any = await postRequest(apiUrl.shopApplyIdcard,{image:e,id_card_side:'front'});
                console.log('res:',res)
                if(res.code==1&&res.data){
                    if(JSON.parse(res.data.data).error_code){
                        message.error('获取身份证反面信息失败')
                    }else{
                        formVal.contact_id_card = JSON.parse(res.data.data)['words_result']['公民身份号码']['words']
                        formVal.id_card_front = imgUrl
                        setFormVal({...formVal})
                    }
                }else{
                    message.error(res.message||'获取身份证正面信息失败')
                }
                break
            case 'id_card_reverse':
                //身份证反面识别回调
                const result:any = await postRequest(apiUrl.shopApplyIdcard,{image:e,id_card_side:'back'});
                console.log('result:',result)
                if(result.code==1&&result.data){
                    if(JSON.parse(result.data.data).error_code){
                        message.error('获取身份证反面信息失败')
                    }else{
                        formVal.id_card_info = JSON.stringify({
                            idCardStart:JSON.parse(result.data.data)['words_result']['签发日期']['words'],
                            idCardEnd:JSON.parse(result.data.data)['words_result']['失效日期']['words'],
                        })
                        formVal.id_card_reverse = imgUrl
                        setFormVal({...formVal})
                    }
                }else{
                    message.error(res.message||'获取身份证反面信息失败')
                }
                break
            case 'bank_front':
                //银行卡正面识别回调
                const bankRes:any = await postRequest(apiUrl.shopApplyBankcard,{image:e});
                console.log('bankRes:',bankRes)
                if(bankRes.code==1&&bankRes.data){
                    if(JSON.parse(bankRes.data.data).error_code){
                        message.error('获取银行卡信息失败')
                    }else{
                        formVal.contact_bank_card = JSON.parse(bankRes.data.data)['result']['bank_card_number']
                        formVal.contact_bank_name = JSON.parse(bankRes.data.data)['result']['bank_name']
                        formVal.bank_front = imgUrl
                        setFormVal({...formVal})
                    }
                }else{
                    message.error(res.message||'获取银行卡信息失败')
                }
                break
        }
    }

    return(
        <div className="store_auth l_content">
            {getCol(24,<div className="title_text">{title}</div>)}
            {getCol(24,<div className="fz16 color333 mb15">经营信息</div>)}
            {getRow(
                <>
                    <div className="row_view mbt16">
                        <div className="sub_title">门店名称 :</div>
                        <Input className="sub_unInput" value={formVal.shop_name} placeholder="请输入门店名称" onChange={(e)=>{changeFieldVal(e.currentTarget.value,'shop_name')}}/>
                    </div>
                    {
                        errorObj['shop_name']&&<div className="error">*{errorObj['shop_name']}</div>
                    }
                </>
            )}
            {getRow(
                <>
                    <div className="row_view mbt16">
                        <div className="sub_title">所在省市:</div>
                        {
                            formVal.province?<Cascader key={2} defaultValue={[formVal.province_code, formVal.city_code, formVal.district_code]} displayRender={displayRender} options={cityData} placeholder="请选择" style={{ width: 400 }} onChange={(e,selectedOptions)=>{changeFieldVal(selectedOptions,'cityArr')}} fieldNames={{ label: 'name', value: 'code', children: 'children' }}/>:<Cascader key={1} displayRender={displayRender} options={cityData} placeholder="请选择" style={{ width: 400 }} onChange={(e,selectedOptions)=>{changeFieldVal(selectedOptions,'cityArr')}} fieldNames={{ label: 'name', value: 'code', children: 'children' }}/>
                        }
                    </div>
                    {
                        errorObj['province']&&<div className="error">*{errorObj['province']}</div>
                    }
                </>
            )}
            {getRow(
                <>
                    <div className="row_view mbt16">
                        <div className="sub_title">门店地址 :</div>
                        <Input className="sub_unInput" value={formVal.address} placeholder="填写店铺详细地址，具体区/县及街道门牌号或大厦楼层" maxLength={500} onChange={(e)=>{changeFieldVal(e.currentTarget.value,'address')}}/>
                    </div>
                    {
                        errorObj['address']&&<div className="error">*{errorObj['address']}</div>
                    }
                </>
            )}
            {getRow(
                <>
                    <div className="row_view mbt16">
                        <div className="sub_title">经营类型 :</div>
                        <Select defaultValue="1" value={formVal.manage_type?formVal.manage_type+'':'1'} style={{ width: 400 }} onChange={(e:any)=>{changeFieldVal(e,'manage_type')}}>
                            <Option value="1">个体工商户</Option>
                            <Option value="2">小微商户</Option>
                            <Option value="3">企业</Option>
                            <Option value="4">其他</Option>
                        </Select>
                    </div>
                    {
                        errorObj['manage_type']&&<div className="error">*{errorObj['manage_type']}</div>
                    }
                </>
            )}
            {getRow(
                <>
                    <div className="row_view mbt16">
                        <div className="sub_title">餐饮类型 :</div>
                        <Select defaultValue="1" value={formVal.catering_type?formVal.catering_type+'':'1'} style={{ width: 400 }} onChange={(e:any)=>{changeFieldVal(e,'catering_type')}}>
                            <Option value="1">自助餐</Option>
                            <Option value="2">川菜</Option>
                        </Select>
                    </div>
                    {
                        errorObj['catering_type']&&<div className="error">*{errorObj['catering_type']}</div>
                    }
                </>
            )}
            {getRow(
                <>
                    <div className="row_view mbt16">
                        <div className="sub_title">门店客服联系方式 :</div>
                        <Input className="sub_unInput" maxLength={11} value={formVal.service_phone} placeholder="请填写真实有效的客服电话，将在交易记录中向买家展示" onChange={(e)=>{changeFieldVal(e.currentTarget.value,'service_phone')}}/>
                    </div>
                    {
                        errorObj['service_phone']&&<div className="error">*{errorObj['service_phone']}</div>
                    }
                </>
            )}

            {getCol(24,<div className="fz16 color333 mb15 mt30">基本信息</div>)}
            {
                basicsField.map((item:any,index:number)=>{
                    return <div key={index}>
                        {getRow(
                            <>
                                {
                                    item.type=='img'?(
                                        <UploadImg
                                            onChange={(e:any)=>{changeFieldVal(e,item.field)}}
                                            imgUrl={formVal[item.field]}
                                            label={item.label}
                                            callBack={(e:any,imgUrl:string)=>{uploadCallBack(item.field,e,imgUrl)}}
                                            field={item.field}
                                        />
                                    ):(
                                        <div className="row_view mbt16">
                                            <div className="sub_title">{item.label} :</div>
                                            {
                                                item.field==='contact_phone'?(
                                                    <Input
                                                        className="sub_unInput"
                                                        // type="number"
                                                        placeholder={item.placeholder}
                                                        onChange={(e)=>{changeFieldVal(e.currentTarget.value,item.field)}}
                                                        maxLength={11}
                                                        value={formVal[item.field]}
                                                        addonAfter={
                                                            <Button loading={codeLoading} type="primary" onClick={sendVerifyCode} disabled = {second > 0 ? true:false}>获取验证码{second || null}</Button>
                                                        }
                                                    />
                                                ):(
                                                    item.field==='contact_bank_branch_name'?(
                                                            formVal.contact_bank_branch_name?(
                                                                <AutoComplete
                                                                    placeholder="请输入支行名称"
                                                                    // dataSource={bankList}
                                                                    defaultValue={formVal.contact_bank_branch_name}
                                                                    onChange={(e) => getBankList(e)}
                                                                    onSelect={(e, option) => onSelectBranch(e, option, item.field)}
                                                                    className="sub_unInput"
                                                                    key={1}
                                                                >
                                                                    {
                                                                        bankList.map((subItem:any,index:number)=>{
                                                                            return <AutoComplete.Option key={subItem.sub_bank_code} value={subItem.sub_bank_code}>{subItem.sub_bank_name}</AutoComplete.Option>
                                                                        })
                                                                    }
                                                                </AutoComplete>
                                                            ):(
                                                                <AutoComplete
                                                                    placeholder="请输入支行名称"
                                                                    // dataSource={bankList}
                                                                    onChange={(e) => getBankList(e)}
                                                                    onSelect={(e, option) => onSelectBranch(e, option, item.field)}
                                                                    className="sub_unInput"
                                                                    key={2}
                                                                >

                                                                    {
                                                                        bankList.map((subItem:any,index:number)=>{
                                                                            return <AutoComplete.Option key={subItem.sub_bank_code} value={subItem.sub_bank_code}>{subItem.sub_bank_name}</AutoComplete.Option>
                                                                        })
                                                                    }
                                                                </AutoComplete>
                                                            )

                                                    ):(
                                                        <Input
                                                            disabled={item.disabled}
                                                            className="sub_unInput"
                                                            placeholder={item.placeholder}
                                                            onChange={(e)=>{changeFieldVal(e.currentTarget.value,item.field)}}
                                                            value={formVal[item.field]}
                                                        />
                                                    )
                                                )
                                            }
                                        </div>
                                    )
                                }
                                {
                                    errorObj[item.field]&&<div className="error">*{errorObj[item.field]}</div>
                                }
                            </>
                        )}
                    </div>
                })
            }

            {getCol(24,<div className="fz16 color333 mb15 mt30">场景信息</div>)}
            {
                imgListField.map((item:any,index:number)=>{
                    return <div key={index}>
                        {getRow(
                            <>
                                <UploadImg onChange={(e:any)=>{changeFieldVal(e,item.field)}} imgUrl={formVal[item.field]} label={item.label} field={item.field}/>
                                {
                                    errorObj[item.field]&&<div className="error">*{errorObj[item.field]}</div>
                                }
                            </>
                        )}
                    </div>
                })
            }

            {getCol(24,<div className="fz16 color333 mb15">经营信息</div>)}

            {getRow(
                <>
                    <UploadImg onChange={(e:any)=>{changeFieldVal(e,'business_license')}} callBack={(e:any,imgUrl:string)=>{getBaiduAccessToken(e,imgUrl)}} imgUrl={formVal.business_license} label="营业执照" field="business_license"/>
                    {
                        errorObj['business_license']&&<div className="error">*{errorObj['business_license']}</div>
                    }
                </>
            )}
            {getRow(
                <>
                    <div className="row_view mbt16">
                        <div className="sub_title">营业执照号 :</div>
                        <Input maxLength={18} disabled={true} className="sub_unInput" placeholder="请填写营业执照上的营业执照注册号" value={formVal.business_license_num} onChange={(e)=>{changeFieldVal(e.currentTarget.value,'business_license_num')}}/>
                    </div>
                    {
                        errorObj['business_license_num']&&<div className="error">*{errorObj['business_license_num']}</div>
                    }
                </>
            )}
            {getRow(
                <>
                    <div className="row_view mbt16">
                        <div className="sub_title">营业执照名称 :</div>
                        <Input maxLength={110} disabled={true} className="sub_unInput" placeholder="请填写营业执照上的商户名称" value={formVal.business_license_name} onChange={(e)=>{changeFieldVal(e.currentTarget.value,'business_license_name')}}/>
                    </div>
                    {
                        errorObj['business_license_name']&&<div className="error">*{errorObj['business_license_name']}</div>
                    }
                </>
            )}
            {getRow(
                <div className="row_view mbt16">
                    <Radio checked={radioChecked} onClick={()=>{changeRadio()}}>食品经营许可证（可以后续补齐食品经营许可证）</Radio>
                </div>
            )}
            {radioChecked&&getRow(
                <>
                    <UploadImg onChange={(e:any)=>{changeFieldVal(e,'food_license')}} imgUrl={formVal.food_license} label="食品经营许可证" field={false}/>
                    {
                        errorObj['food_license']&&<div className="error">*{errorObj['food_license']}</div>
                    }
                </>
            )}
            {(formVal.status===undefined||formVal.status==5)&&getRow(
                <div className="footer">
                    <Button type='primary' onClick={()=>{onsubmit()}}>提交</Button>
                </div>
            )}
        </div>
    )

}

export default StoreAuth
