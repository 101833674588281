import React, { useEffect, useState } from 'react'
import { Select, Row, DatePicker, message, Spin } from 'antd'
import IndexTable from './components/indexTable'
import RadarChart from './components/radarChart'
import LineChart from './components/lineChart'
// import PieChart from './components/pieChart'
import ArcProgress from './components/arcProgress'
import { getRequest } from '../../api/http'
import { apiUrl } from '../../api/api_url'
import moment from 'moment'
import see_icon from '../../assret/images/see.png'


import './index.scss'

const { Option } = Select;
const { MonthPicker } = DatePicker;
const { RangePicker } = DatePicker;

const Dashboard = (props:any)=>{
    const unitList = ['人','元','元','人']

    const formatData = (num:any) => {
        return num > 9 ? num : `0${num}`
    }
    //获取日期开始日
    const getMonthStart = (year:number,month:number) => {
        // 获取当前日期
        return year + '-' + month + '-01'
    }

    //获取日期结束日
    const getMonthEnd = (year:number,month:number) => {
        // 获取当前日期
        return year + '-' + month + '-' + new Date(year,formatData(month),0).getDate()
    }
    const [ statistics, setStatistics] = useState([
        {title: "社群消费总人数", num: '--', items:{num:'--',title:'社群总人数'},icon:''},
        {title: "总实收金额", num: "--", items:{num:'--',title:'总订单数'},icon:''},
        {title: "总充值金额", num: '--', items:{num:'--',title:'充值次数'},icon:''},
        {title: "成功邀请人数", num: '--', items:{num:'--',title:''},icon:''} ,
    ])
    const [ rate, setRate] = useState('0.00')
    const [ income, setIncome] = useState('0.00')
    const [ statisticsLoading, setStatisticsLoading ] = useState(false)
    const [ statisticsParams, setStatisticsParams ] = useState<any>({
        start_at: getMonthStart(new Date().getFullYear(),formatData(new Date().getMonth()+1)),
        end_at: getMonthEnd(new Date().getFullYear(),formatData(new Date().getMonth()+1)),
        currentTab: 0,
    }) //统计参数
    // const [ pieData, setPieData] = useState<any>({})

    const [ lineParams, setLineParams ] = useState<any>({
        date: getMonthStart(new Date().getFullYear(),formatData(new Date().getMonth()+1)),
        // currentTab: 0,
    }) //折线图参数

    const [ lineData, setLinData ] = useState<any>({
        groups: [],
        xLine: [],
        yLine: []
    }) //折线图参数

    const [ groupList, setGroupList ] = useState<any>([])

    const [ radarData, setRadarData ] = useState<any>([])
    const [ radarSelect, setRadarSelect ] = useState<any>([])
    const [ radarLoading, setRadarLoading ] = useState(false)
    const [ max, setMax ] = useState(0)

    useEffect(()=>{
        _init()
    },[])

    const _init = () => {
        //调用刷新接口
        getRequest(apiUrl.usergroupListFresh)
        //统计
        getStatistics(statisticsParams)
        //私欲分组列表
        getGroups()
        //折线图数据
        getLineChart(lineParams)
        //雷达图
        getRadar()
    }

    const getStatistics = async(data = {}) => {
        let params:any = {
            ...data
        }
        Object.keys(params).map(el => {
            if(params[el]===undefined) delete params[el]
        })
        setStatisticsParams({...params})
        delete  params['currentTab']
        setStatisticsLoading(true)
        const res:any = await getRequest(apiUrl.siyuIndex,params)
        if(res.code === 1){
            setStatistics(res.data.list)
            setRate(res.data.rate.rate)
            setIncome(res.data.rate.income)
        }
        setStatisticsLoading(false)
        // const pRes:any = await getRequest(apiUrl.siyuGroupRate,params)
        // if(pRes.code === 1){
        //     setPieData(pRes.data)
        // }
    }

    const getGroups = async()=> {
        const res:any = await getRequest(apiUrl.siyuGetGroups)
        if(res.code === 1){
            setGroupList(res.data)
        }else{
            message.error(res.message||'获取分组失败')
        }
    }

    const getLineChart = async(data = {}) => {
        let params:any = {
                ...data
        }
        Object.keys(params).map(el => {
            if(params[el]===undefined) delete params[el]
        })
        setLineParams({...params})
        // delete  params['currentTab']
        const res:any = await getRequest(apiUrl.siyuGetlineChart,params)
        if(res.code === 1){
            setLinData(res.data)
        }else{
            message.error(res.message||'社群产生消费金额数据获取失败')
        }
    }

    const getRadar = async() => {
        setRadarLoading(true)
        const res:any = await getRequest(apiUrl.siyuGetDetail)
        if(res.code === 1){
            let max = 0
            if(res.data.length>0){
                max = Math.max(...res.data.map((item:any)=>{return item.users}))
            }
            setMax(max)
            setRadarData(res.data)
            setRadarSelect(res.data.map((item:any)=>{return item.id}))
            setRadarLoading(false)
        }else{
            setRadarLoading(false)
            message.error(res.message||'社群产生消费金额数据获取失败')
        }

    }

    const changeTab = (index:any) => {
        if(index === statisticsParams.currentTab) return false
        let params:any = {}
        if(index === 0){
            params = {
                start_at: getMonthStart(new Date().getFullYear(),formatData(new Date().getMonth()+1)),
                end_at: getMonthEnd(new Date().getFullYear(),formatData(new Date().getMonth()+1)),
                currentTab: index
            }
            // params.date = getMonthStart(new Date().getFullYear(),formatData(new Date().getMonth()+1))
        }else{
            let beforeMonthStart = new Date().getFullYear() + '-' + formatData(new Date().getMonth()) + '-01';
            let currentMonth = new Date().getFullYear() + '-' + formatData(new Date().getMonth() + 1) + '-01'
            let num = new Date(new Date(currentMonth).getTime() - 24 * 60 * 60 * 1000);
            let beforeMonthEnd = `${num.getFullYear()}-${formatData(num.getMonth() + 1)}-${formatData(num.getDate())}`;
            beforeMonthStart = `${num.getFullYear()}-${formatData(num.getMonth() + 1)}-01`
            params = {
                start_at: beforeMonthStart,
                end_at: beforeMonthEnd,
                currentTab: index
            }
            // params.date = getMonthStart(new Date().getFullYear(),formatData(new Date().getMonth()))
        }
        // getLineChart(params)
        getStatistics(params)
    }

    const changeMonthPicker = (dateString:any) => {
        if(dateString){
            getLineChart({...lineParams,date:getMonthStart(dateString.split('-')[0],dateString.split('-')[1])})
        }else{
            getLineChart({...lineParams,date:getMonthStart(new Date().getFullYear(),formatData(new Date().getMonth()+1))})
        }
    }

    const changeRangePicker = (dateString:any) => {
        if(dateString[0]){
            getStatistics({...statisticsParams,start_at:dateString[0],end_at:dateString[1],currentTab:''})
        }else{
            getStatistics({...statisticsParams,start_at:getMonthStart(new Date().getFullYear(),formatData(new Date().getMonth()+1)),end_at:getMonthEnd(new Date().getFullYear(),formatData(new Date().getMonth()+1)),currentTab:0})
        }
    }

    const handleChange = (value:any) => {
        if(value.length>0){
            let max = Math.max(...value.map((item:any)=>{return radarData.filter((subItem:any)=>subItem.id===item)[0].users}))
            setMax(max)
            setRadarSelect(value)
        }else{
            let max = Math.max(...radarData.map((item:any)=>{return item.users}))
            setMax(max)
            setRadarSelect(radarData.map((item:any)=>{return item.id}))
        }
    }

    return (
        <div className="siyu">
            <div className="siyu_title">私域社群运营</div>
            <Row className="search" type="flex" align="middle" justify="space-between">
                <Row type="flex" align="middle" >
                    <Row type="flex" align="middle" className="search_tab">
                        <div className={`search_tab_item ${statisticsParams.currentTab===0?'active':''}`} onClick={()=>{changeTab(0)}}>本月</div>
                        <div className={`search_tab_item ${statisticsParams.currentTab===1?'active':''}`} onClick={()=>{changeTab(1)}}>上月</div>
                    </Row>
                    <RangePicker
                        className="mr10"
                        // picker="month"
                        value={[moment(statisticsParams.start_at, 'YYYY-MM-DD'),moment(statisticsParams.end_at, 'YYYY-MM-DD')]}
                        onChange={(dates,dateString)=>{changeRangePicker(dateString)}}
                    />
                </Row>
                <div className='new_btn'>
                    <img src={see_icon}></img>
                    新添监控社群请联系客服
                </div>
            </Row>
            <div className="siyu_dashboard_wrap row">
                <div className="card row-between">
                    {
                        statistics.map((item:any,index:number)=>{
                            return <div className="card_item wbg" key={index}>
                                <div className="card_item_top row-between">
                                    <div>
                                        <div>{item.title}</div>
                                        <div className="card_item_total">{item.num}{unitList[index]}</div>
                                    </div>
                                    <img src={item.icon}/>
                                </div>
                                {
                                    item.items.title?(
                                        <div className="card_item_bottom">{item.items.title}:{item.items.num}</div>
                                    ):(
                                        <div className="card_item_bottom"></div>
                                    )
                                }
                            </div>
                        })
                    }
                </div>
                <div className="user_detail_dashboard wbg row-center">
                    {
                        <Spin spinning={statisticsLoading}>{!statisticsLoading&&<ArcProgress value={income?income:'0.00'} rate={parseFloat(rate)}/>}</Spin>
                    }
                </div>
            </div>
            <div className="siyu_line wbg">
                <div className="siyu_line_title row-between">
                    社群产生消费金额数据
                    <MonthPicker
                        className="mr10"
                        // picker="month"
                        value={moment(lineParams.date, 'YYYY-MM')}
                        onChange={(dates,dateString)=>{changeMonthPicker(dateString)}}
                    />
                </div>
                <LineChart color1='#FF5A6E' color2='#FFB741' bg1='#ffccb2' bg2='#ffffdf' lineData={lineData}/>
            </div>
            {
                radarSelect.length>0&&(
                    <>
                        <div className="siyu_title">社群能力对比</div>
                        <Select
                            mode="multiple"
                            style={{ width: 291 }}
                            placeholder="全部"
                            onChange={handleChange}
                            className="mb20"
                        >
                            {
                                groupList.map((item:any,index:number)=>{
                                    return <Option value={item.id} key={item.id}>{item.name}</Option>
                                })
                            }
                        </Select>
                        <div>
                            {
                                !radarLoading&&radarSelect.map((item:any,index:number)=>{
                                    return  <Row type="flex" align="middle" className="siyu_radar" key={index}>
                                        <div className="wbg radar">
                                            <RadarChart data={radarData.filter((subItem:any)=>item===subItem.id)[0]} max={max}/>
                                        </div>
                                        <div className="wbg">
                                            <IndexTable id={radarData.filter((subItem:any)=>item===subItem.id)[0].id} />
                                        </div>
                                    </Row>
                                })
                            }
                        </div>
                    </>
                )
            }
        </div>
    )
}

export default Dashboard
