import React, {useEffect} from "react";
import {Radio, Statistic} from 'antd';

import './dataStatistcs.scss'
import {Select} from "antd";
import ReactEcharts from "echarts-for-react";
const {Option} = Select;
const DataStatistics = ()=>{
    // 相当于 componentDidMount 和 componentDidUpdate:
    useEffect(() =>{

        return cleanUp();
    });

    const cleanUp = () =>{
        //这里执行组件销毁 清除一些监听等
    };

    // 创建总数据组
    const createDataGroup = (title: string) =>{
        return (
            <div className='data_group'>
                <div className='data_num'> <Statistic value={112893} /></div>
                <div className='data_title'>今日新增</div>
                <div className="group_bottom">
                    <div className='today_num'>今日净增:200</div>
                    <div className='data_count'>累计:200</div>
                </div>
            </div>
        )
    };

    // 创建 趋势折线图
    const createTrend = () =>{
       const option = {
            tooltip: {
                trigger: 'axis'
            },
            legend: {
                data: ['新增粉丝', '净增粉丝','取关新粉丝','参与粉丝','完成任务']
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            toolbox: {
                feature: {
                    saveAsImage: {}
                }
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: ['00:00', '03:00', '06:00', '09:00', '12:00', '15:00', '18:00','21:00','00:00']
            },
            yAxis: {
                type: 'value'
            },
            series: [
                {
                    name: '新增粉丝',
                    type: 'line',
                    stack: '总量',
                    data: [120, 132, 101, 134, 90, 230, 210,123,345]
                },
                {
                    name: '净增粉丝',
                    type: 'line',
                    stack: '总量',
                    data: [220, 182, 191, 234, 290, 330, 310,123,345]
                },
                {
                    name: '取关新粉丝',
                    type: 'line',
                    stack: '总量',
                    data: [220, 182, 191, 234, 290, 330, 310,123,345]
                },
                {
                    name: '参与粉丝',
                    type: 'line',
                    stack: '总量',
                    data: [220, 182, 191, 234, 290, 330, 310,123,345]
                },
            ]
        };

        return (
          <div className='trend'>
              <div className='trend_top'>
                  <div>趋势</div>
                  <Radio.Group value={1} onChange={e =>{console.log(e.target.value)}}>
                      <Radio.Button value={1}>今日</Radio.Button>
                      <Radio.Button value={2}>最近7天</Radio.Button>
                  </Radio.Group>
              </div>
              <ReactEcharts
                  option={option}
                  style={{height: '350px', width: '100%'}}
                  className='react_for_echarts' />
          </div>
      )
    };

    // 创建饼状图
    const createCake = () =>{
      const  option = {
            tooltip: {
                trigger: 'item',
                formatter: '{a} <br/>{b} : {c} ({d}%)'
            },
            series: [
                {
                    name: '统计',
                    type: 'pie',
                    radius: '55%',
                    center: ['40%', '50%'],
                    data: [
                        {value:1000, name:'新增粉丝'},
                        {value:1500, name:'净增粉丝'},
                        {value:2000, name:'参与粉丝'},
                    ],
                    itemStyle: {
                        normal:{
                            color:function(params:any) {
                                //自定义颜色
                                var colorList = [
                                    '#1890FF','#52C41A','#FFBF58',
                                ];
                                return colorList[params.dataIndex]
                            }
                        }
                    }
                }
            ]
        };
        return (
            <div className='trend'>
                <div className='trend_top'>
                    <div>活动完成情况统计</div>
                </div>
                <div className="">
                    <ReactEcharts
                        option={option}
                        style={{height: '350px', width: '100%'}}
                        className='react_for_echarts' />
                </div>
            </div>
        )
    };

    return (
        <div className='data_statistics'>
            <div className="select_view">
                <div className="select_item">
                    <div>
                        当前公众号：
                    </div>
                    <div>
                        <Select style={{ width: 122 }} defaultValue="0">
                            <Option value="0">全部</Option>
                            <Option value="1">刷吃</Option>
                        </Select>
                    </div>
                </div>
                <div className="select_item">
                    <div>
                        当前任务：
                    </div>
                    <div>
                        <Select style={{ width: 122 }} defaultValue="0">
                            <Option value="0">活动1</Option>
                            <Option value="1">活动2</Option>
                        </Select>
                    </div>
                </div>
            </div>
            <div className="data_groups">
                {createDataGroup('今日新增')}
                {createDataGroup('今日新增')}
                {createDataGroup('今日参与')}
                {createDataGroup('今日完成')}
            </div>
            <div className='echart_group'>
                {createTrend()}
                {createCake()}
            </div>
        </div>
    )
};

export default DataStatistics;