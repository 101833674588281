import React from "react";
import {Button, Row, Col, Input, Radio, Table, Modal, Select, message, Icon, Popconfirm, Carousel} from "antd";
import { postRequest, getRequest } from '../../../api/http';
import { apiUrl } from '../../../api/api_url';
import styles from './windowTable.module.scss';
const { Option } = Select;

const key = 'updatable';
let timer: any = '';
export default class businessModel extends React.Component<any> {

    state = {
        region_list: [],
        region_select_list: [],
        list_status: 0,
        all_status: true,
        printer_list_status: [], //打印机状态列表离线
        carousel_list: [], //所有打印机
        carousel_list_status: false, //是否显示所有打印机
    }

    componentDidMount() {
        this.openWhereTableRequest()
        this.onGetPrinterStatus()
        this.onPrintListLoop(); //轮询任务
    }

    

    componentWillUnmount() {
        console.log('componentWillUnmount')
        clearInterval(timer)
    }

    handleSubmit = async (e:any, typeAdd: any) => {
        message.loading({ content: '发布中', key });
        e.preventDefault();

        let goodInfo:any = {};

        const { region_list } = this.state;

        goodInfo.business_model = region_list;

        const res:any = await postRequest(apiUrl.changePassword,goodInfo);
        if(res['code'] === 1){
            message.success({ content: '修改成功!', key, duration: 2 });
            if(typeAdd == 'add') {
                window.history.go(-1)
            }
        }else{
            message.error({ content: res['message'], key, duration: 2 });
        }

    };

    changeBusinessModel = (e: any) => {
        this.setState({
            business_model: e
        })
    }

    // 返回上一页
    goBack = () => {
        window.history.go(-1)
    }

    //获取区域
    openWhereTableRequest = async () => {
        const res:any = await getRequest(`${apiUrl.regionIdList}`);
        //let region_select_list: any  = this.state.region_select_list;

        if(res.code === 1){
            let len = res.data;
            let obj = len.map((item: any, index : any) => {
                let _obj = item;
                _obj.select = false;
                return _obj
            })
            this.setState({
                region_list: obj
            }, () => {
                // let obj = len.map((item: any, index : any) => {
                //     let _obj = len;
                //     _obj.select = false;
                //     return _obj
                // })
                // console.log(region_select_list)
            })
        }

    }

    onSelectRegion = (item: any, index: any) => {
        let region_list: any = this.state.region_list;
        let region_select_list: any = this.state.region_select_list;
        if(region_list[index]['select']) {
            region_list[index]['select'] = false;
            this.setState({
                [region_list[index]['select']] : false
            })
            // console.log('asdasdaaaaa:',item['id'])
            // console.log('asdasd:',region_select_list.indexOf(item['id']))
            if(region_select_list.indexOf(item['id']) != '-1') {
                region_select_list.splice(region_select_list.indexOf(item['id']),1)
                this.setState({
                    region_select_list
                }, () => {
                    this.props.onTab(this.state.region_select_list, 0)
                })
            }
        } else {
            console.log(item['id'])
            this.setState({ 
                [region_list[index]['select']] : true
            })
            region_list[index]['select'] = true;

            let _list: any = [];
            region_list.map((item: any, index: any) => {
                if(item.select) {
                    _list.push(item['id'])
                }
            })
            this.setState({
                region_select_list: _list,
                all_status: false,
            }, () => {
                this.props.onTab(this.state.region_select_list, 0)
            })
        }
    }

    //全部
    onAllSelectRegion = () => {
        let region_list: any = this.state.region_list;
        let obj = region_list.map((item: any, index: any) => {
            let _obj = item;
            _obj.select = false;
            return _obj
        })
        this.setState({
            all_status: true,
            region_list: obj
        }, () => {
            this.props.onTab([], 0)
        })
    }

    onSelectStatus = (type: any) => {
        if(type == 1) {
            if(this.state.list_status == 1) {
                this.props.onStatusAllWindow()
                this.setState({
                    list_status: 0
                })
            } else {
                this.props.onStatusUseAction()
                this.setState({
                    list_status: 1
                })
            }
        } else {
            if(this.state.list_status == 2) {
                this.props.onStatusAllWindow()
                this.setState({
                    list_status: 0
                })
            } else {
                this.props.onStatusPayAction()
                this.setState({
                    list_status: 2
                })
            }
        }
    }

    //轮询打印机列表
    onPrintListLoop = () => {
        timer = setInterval(() => {
            this.onGetPrinterStatus()
        }, 8000)
    }

    //获取打印机状态列表
    onGetPrinterStatus = async () => {
        let res: any = await getRequest(apiUrl.getPrinterStatus);
        if(res.code == 1) {
            let all_data = res.data;
            let _list: any = [];
            all_data.map((item: any, index: any) => {
                //过滤掉在线打印机状态
                if(!item.status) { 
                    _list.push(item)
                }
            })
            console.log('all_data:',all_data)
            this.setState({
                printer_list_status: _list,
                carousel_list: res.data
            })

        } else {
            message.error(res.message)
        }
    }

    //显示所有打印机
    onCarouselList = async () => {
        await this.onGetPrinterStatus();
        this.setState({
            carousel_list_status: true,
        })
    }

    //关闭所有打印机
    onCarouselListCancel = async () => {
        this.setState({
            carousel_list_status: false
        })
    }

    render() {

        const { carousel_list_status, carousel_list, region_list, list_status, all_status, printer_list_status } = this.state;

        return (
            // <div className={styles.windowTable}>
            //     {
            //         region_list.map((item: any, index: any) => {
            //             return (
            //                 <span key={index} className={`${item.select ? styles.active : ''}`} onClick={() => {this.onSelectRegion(item, index)}}>{item.name}</span>
            //             )
            //         })
            //     }
            // </div>

                <Row style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                    <Col span={19}>
                        <div style={{display: 'flex'}}>
                            <div className={`${all_status ? 'windowManage_group_tab_item windowManage_group_tab_active' : 'windowManage_group_tab_item'}`} onClick={() => {this.onAllSelectRegion()}}>全部</div>
                            {
                                region_list.map((item: any, index: any) => {
                                    return (
                                        <div key={index} className={`${item.select ? `windowManage_group_tab_item windowManage_group_tab_active` : 'windowManage_group_tab_item'}`} onClick={() => {this.onSelectRegion(item, index)}}>{item.name}</div>
                                    )
                                })
                            }
                        </div>
                    </Col>
                    <Col span={4} style={{display: 'flex',alignItems: 'center', position: 'relative'}} onClick={() => {this.onCarouselList()}} onMouseLeave={() => {this.onCarouselListCancel()}}>
                        {
                            printer_list_status.length <= 1
                            ?   printer_list_status.length == 0
                                ?   <div className={styles.windown_main_carousel_empty}>打印机状态(全部在线)</div>
                                :   <div className={styles.windown_carousel_one}>
                                        <div>{printer_list_status[0]['name']}</div>
                                        <div>
                                            {
                                                printer_list_status[0]['status']
                                                ?   <span className={styles.windown_print_grey}>在线</span>
                                                :   <span className={styles.windown_print_red}>离线</span>
                                            }
                                        </div>       
                                    </div>
                            :   <Carousel autoplay dotPosition="right" dots={false} className={styles.windown_main_carousel}>
                                    {
                                        printer_list_status.map((item: any, index: any) => {
                                            return (
                                                <div key={item.name} className={styles.windown_carousel}>
                                                    <div>{item.name}</div>
                                                    <div>
                                                        {
                                                            item.status
                                                            ?   <span className={styles.windown_print_grey}>在线</span>
                                                            :   <span className={styles.windown_print_red}>离线</span>
                                                        }
                                                    </div>       
                                                </div>
                                            )
                                        })
                                    }
                                </Carousel>
                        }
                        {
                            carousel_list_status
                            ?   <div className={styles.windown_popup}>
                                    {
                                        carousel_list.map((item: any, index: any) => {
                                            return (
                                                <div key={item.name} className={styles.windown_carousel_popup}>
                                                    <div>{item.name}</div>
                                                    <div>
                                                        {
                                                            item.status
                                                            ?   <span className={styles.windown_print_grey}>在线</span>
                                                            :   <span className={styles.windown_print_red}>离线</span>
                                                        }
                                                    </div>       
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            :   ''
                        }
                        {/* <Select size='default' defaultValue="打印机状态" style={{width: '100%'}}>
                            {
                                printer_list_status.map((item: any, index: any) => {
                                    return (
                                        <Option key={item.name} value={item.name} style={{display: 'flex',justifyContent: 'space-between'}}>
                                            <div>{item.name}</div>
                                            <div>
                                                {
                                                    item.status
                                                    ?   <span className={styles.windown_print_grey}>在线</span>
                                                    :   <span className={styles.windown_print_red}>离线</span>
                                                }
                                            </div>       
                                        </Option>
                                    )
                                })
                            }
                        </Select> */}
                    </Col>
                    <Col span={1}>
                        
                    </Col>
                </Row>
        )
    }
}