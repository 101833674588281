import React from "react";
import "./posterSetting.scss"
import {Button, Col, Icon, Row, Switch, Tooltip, Select, Radio, Upload} from "antd";
import {ColorResult, SketchPicker} from 'react-color';
import {nickNameText} from "../../../../distribution/util/fabric_utils";
import "fabric";
import {getRequest} from "../../../../../api/http";
import {apiUrl} from "../../../../../api/api_url";
const { Option } = Select;
declare let fabric: any;
interface IProps {
    callbackParent(type:number,param:any,canvas?:any):void
    onRef(ref:any):void,
    param: any
}
const canvasConfig = {
    width: 340,
    height: 340
};
const QINIU_SERVER = "https://up-z2.qiniup.com";  // 七牛上传地址
// 画布 内容 唯一标识
const uniqueKeys = {
    nickName: 'nickName',
    avatarUrl: 'avatarUrl',
    qrCode: 'qrCode',
    backImage: 'backImage'
};
class PosterSetting extends React.Component<IProps>{
    myCanvas :any= {};
    state = {
        isSelectColor: false,
        param: {
            avatarRadius: '方形',            // 头像圆形方形
            showAvatar: true,               // 是否显示头像
            showNickname: true,             // 是否显示昵称
            nickNameTextAlign: 'left',    // 昵称对齐方式
            nickNameSize: 15,               // 昵称大小
            nickNameColor: '#000000',       // 昵称颜色
            imageUrl: ''
        },
        qiniuParam: {
            token: '',
            imageKey: ''
        }
    };

    componentDidMount(): void {

        this.props.onRef(this);
    }

    // 提交参数给 父级组件
    submitParentParams =()=>{
        let {showAvatar,showNickname,imageUrl} = this.state.param;
        const canvasData = this.myCanvas.toJSON();
        canvasData.showAvatar = showAvatar;
        canvasData.showNickname = showNickname;
        let nickNameIndex = this.getCanvasItemIndex(uniqueKeys.nickName);
        let avatarUrlIndex = this.getCanvasItemIndex(uniqueKeys.avatarUrl);
        let qrCodeIndex = this.getCanvasItemIndex(uniqueKeys.qrCode);
        if(nickNameIndex !== -1) canvasData.objects[nickNameIndex].uniqueKey = uniqueKeys.nickName;
        if(avatarUrlIndex !== -1) canvasData.objects[avatarUrlIndex].uniqueKey = uniqueKeys.avatarUrl;
        if(qrCodeIndex !== -1) canvasData.objects[qrCodeIndex].uniqueKey = uniqueKeys.qrCode;
        const canvsdom:any = document.getElementById('canvas');
        canvasData.width = canvsdom.width;
        canvasData.height = canvsdom.height;
        canvasData.imageUrl = imageUrl;
        this.props.callbackParent(4,canvasData,this.myCanvas);
    };



    init = (canvasRef:any) =>{

        let canvasDate = this.props.param;
        if(Object.keys(canvasDate).length !== 0){
            this.myCanvas = new fabric.Canvas(canvasRef, {
                backgroundColor: '#eef0f3',
                width: canvasDate.width,
                height:canvasDate.height
            });


            this.myCanvas.loadFromJSON(this.props.param, () => {
                let {param} = this.state;

                const index = this.getCanvasItemIndex(uniqueKeys.nickName);
                if(index !==-1){
                    let nickNameObj = canvasDate.objects[index];
                    param.showNickname = canvasDate.showAvatar;
                    param.nickNameColor = nickNameObj.fill;
                    param.nickNameTextAlign = nickNameObj.textAlign;
                    param.nickNameSize = nickNameObj.fontSize;
                    param.showAvatar = canvasDate.showNickname;
                    param.imageUrl = canvasDate.backgroundImage.src;
                    this.setState({param});

                }
            },(o:any,object:any) => { });
        }else{
            this.myCanvas = new fabric.Canvas(canvasRef, { backgroundColor: '#eef0f3'});
            this.initCanvas(this.myCanvas);
        }

    };

    // 上传图片前
    handleBeforeUpload = async() =>{
        const res:any = await getRequest(apiUrl.getUploadToken, {
            type: "poster"
        });
        if (res && res.data) {
            const key = `${Math.random().toString(36).slice(-8)}${new Date().valueOf()}.png`;
            let qiniuParamTemp = this.state.qiniuParam;
            qiniuParamTemp.imageKey = key;
            qiniuParamTemp.token = res.data.upload_token;
            this.setState({qiniuParam: qiniuParamTemp})
        }
    };

    // 海报改变监听
    handleUploadChange = (e:any) =>{
        if (e.file && e.file.status === "done") {
            let paramTemp = this.state.param;
            paramTemp.imageUrl = e.file.response.url;
            this.setState({param: paramTemp});
            this.drawImage(e.file.response.url);
        }
    };

    // 控制头像是否显示
    avatarOnChange = (val:boolean) =>{
        let {param} = this.state;
        const index = this.getCanvasItemIndex(uniqueKeys.avatarUrl);
        if(index !== -1){
            param.showAvatar =val;
            this.setState({param});
            this.myCanvas.item(index).set('opacity', val ? 1 : 0);
            this.myCanvas.renderAll();
        }
    };

    // 控制头像圆形或方形
    handleAvatarShapChange = (shap: string)=>{
        const index = this.getCanvasItemIndex(uniqueKeys.avatarUrl);
        if(index !== -1){
            if(shap === '方形') {
                this.myCanvas.item(index).item(0).set('opacity', 1);
                this.myCanvas.item(index).item(1).set('opacity', 0);
            }
            if(shap === '圆形') {
                this.myCanvas.item(index).item(0).set('opacity', 0);
                this.myCanvas.item(index).item(1).set('opacity', 1);
            }
            let {param} = this.state;
            param.avatarRadius = shap;
            this.setState({param});
            this.myCanvas.renderAll();
        }
    };

    // 控制昵称是否显示
    nickNameOnChange = (val:boolean) => {
        let {param} = this.state;
        const index = this.getCanvasItemIndex(uniqueKeys.nickName);
        if(index !== -1){
            this.myCanvas.item(index).set('opacity', val ? 1 : 0);
            param.showNickname =val;
            this.setState({param});
            this.myCanvas.renderAll();
        }
    };

    // 改变昵称颜色
    changeNickNameColor = (color:string) =>{
        let {param} = this.state;
        const index = this.getCanvasItemIndex(uniqueKeys.nickName);
        if(index !== -1){
            this.myCanvas.item(index).set('fill', color);
            param.nickNameColor = color;
            this.setState({param});
            this.myCanvas.renderAll();
        }
    };

    // 改变昵称字体大小
    changeNickNameSize = (size:number)=>{
        let {param} = this.state;
        const index = this.getCanvasItemIndex(uniqueKeys.nickName);
        if(index !== -1){
            this.myCanvas.item(index).set('fontSize', size);
            param.nickNameSize = size;
            this.setState({param});
            this.myCanvas.renderAll();
        }
    };

    // 昵称对齐方式
    textAlignChange = (val:string) => {
        let {param} = this.state;
        const index = this.getCanvasItemIndex(uniqueKeys.nickName);
        if(index !== -1){
            this.myCanvas.item(index).set('textAlign', val);
            param.nickNameTextAlign = val;
            this.setState({param});
            this.myCanvas.renderAll();
        }
    };

    // 初始化canvas
    initCanvas = (canvas: any) => {
        canvas.setWidth(canvasConfig.width);
        canvas.setHeight(576);
        canvas.add(nickNameText); // 默认 用户名
        const defaultAvatarUrl = 'http://ydn-siyu.cdn.shuachi.com/poster/dufault_avatar_bg.png';    // 默认头像地址
        const defaultQrCodeUrl = 'http://ydn-siyu.cdn.shuachi.com/poster/default_qr_code.png';    // 默认二维码地址
        const defaultAvatarImg = new Image();       // 默认头像 实例
        const defaultQrCode = new Image();          // 默认二维码 实例
        defaultQrCode.src = defaultQrCodeUrl;
        defaultQrCode.crossOrigin = 'anonymous';
        defaultAvatarImg.src = defaultAvatarUrl;
        defaultAvatarImg.crossOrigin = 'anonymous';
        defaultQrCode.onload = () => {
            defaultAvatarImg.onload = () => {
                const imgW = defaultAvatarImg.width;
                const avatarW = 70;
                const _scale =
                    imgW > avatarW
                        ? avatarW / imgW
                        : imgW / avatarW;
                const _defaultAvatar = new fabric.Image(defaultAvatarImg, {
                    left: 0,
                    top: 0,
                    angle: 0,
                    opacity: 1,
                    scaleX: _scale,
                    scaleY: _scale,
                    hasControls: true,
                    selectable: true,
                    crossOrigin: 'anonymous'
                });

                fabric.Image.fromURL(defaultAvatarUrl, (img:any) => {
                    fabric.Image.fromURL(defaultQrCodeUrl, (imgQrcode:any) => {
                        img.scale(_scale).set({
                            left: 0,
                            top: 0,
                            angle: 0,
                            crossOrigin: 'anonymous'
                            // clipTo: (ctx:any) => {
                            //     ctx.arc(0, 0, 130, 0, Math.PI * 2, true);
                            // }
                        });
                        imgQrcode.scale(_scale).set({
                            left: 50,
                            top: 100,
                            angle: 0,
                            crossOrigin: 'anonymous'
                        });

                        const avatarGroup = new fabric.Group([_defaultAvatar, img,], {
                            left: 40,
                            top: 40,
                            angle: 0,
                        });
                        nickNameText['uniqueKey'] = uniqueKeys.nickName;
                        imgQrcode['uniqueKey'] = uniqueKeys.qrCode;    // 添加画布二维码唯一标识
                        avatarGroup['uniqueKey'] = uniqueKeys.avatarUrl;
                        canvas.add(avatarGroup);
                        canvas.add(imgQrcode);
                    },{crossOrigin: 'anonymous'});
                },{crossOrigin: 'anonymous'});
            }
        };
        defaultQrCode.onerror = (e) => {
            console.log('二维码加载错误', e);
        };
        defaultAvatarImg.onerror = (e) => {
            console.log('图片加载错误', e)
        }

    };

    // 画海报
    drawImage = (url: string) => {
        let myCanvas = this.myCanvas;
        const index = this.getCanvasItemIndex(uniqueKeys.backImage);
        if(index !== -1){
            myCanvas.remove(myCanvas.item(index));
        }

        const img = new Image();
        img.src = url;
        img.onload = () => {
            myCanvas.setWidth(canvasConfig.width);
            const scaleImg =
                img.width > canvasConfig.width
                    ? canvasConfig.width / img.width
                    : img.width / canvasConfig.width;

            const _height = img.height * scaleImg;
            myCanvas.setHeight(_height);

            const imgInstance = new fabric.Image(img, {
                left: 0, // 图片相对画布的左侧距离
                top: 0, // 图片相对画布的顶部距离
                angle: 0, // 图片旋转角度
                opacity: 1, // 图片透明度
                scaleX: scaleImg,
                scaleY: scaleImg,
                hasControls: false,
                selectable: false
            });

            imgInstance['uniqueKey'] = uniqueKeys.backImage;
            myCanvas.setBackgroundImage(
                imgInstance,
                myCanvas.renderAll.bind(myCanvas),
                {
                    scaleX: scaleImg,
                    scaleY: scaleImg,
                    crossOrigin: 'anonymous'
                }
            );
            myCanvas.renderAll();
        };
    };

    // 获取 当前操作 内容在画布下的下标
    getCanvasItemIndex =(uniqueKey:string) =>{
        const items = this.myCanvas._objects;
        let index = -1;
        for(let i = 0;i< items.length;i++){
            let flag:boolean = items[i].hasOwnProperty('uniqueKey');
            if(flag){
                if(items[i].uniqueKey === uniqueKey){
                    index = i;
                    break;
                }
            }
        }
        return index;
    };
    render(){
        let {isSelectColor,param,qiniuParam} = this.state;
        return <div className="poster_setting_view l_content" onClick={(e)=>{e.stopPropagation();this.setState({isSelectColor:false})}}>
            <div className="poster_title">海报设置</div>
            <Row>
                <Col span={12}>
                    <div className="poster_left">
                        <div className="upload_poster">
                            <div className="margin_view title_name">海报</div>
                            <Tooltip placement="topLeft" className="margin_view" title="建议尺寸1080*1920，请勿上传超过2M的图片">
                                <Icon type="exclamation-circle" theme="filled" />
                            </Tooltip>
                            <Upload
                                className='uploadStyle'
                                showUploadList={false}
                                action={QINIU_SERVER}
                                accept="image/*"
                                beforeUpload={this.handleBeforeUpload}
                                onChange={this.handleUploadChange}
                                data={{
                                    token:qiniuParam.token,
                                    region: `http://up-z2.qiniu.com`,
                                    key: qiniuParam.imageKey,
                                    "x:type": "poster"
                                }}
                            >
                                <Button
                                    type="primary"
                                    className="margin_view upload_poster_action"
                                >
                                    上传海报
                                </Button>

                            </Upload>

                        </div>
                        <div className="upload_poster">
                            <div className="margin_view title_name">头像</div>
                            <Switch className="margin_view" defaultChecked={param.showAvatar} onChange={this.avatarOnChange} />
                            <Radio.Group
                                value={param.avatarRadius}
                                style={{ marginLeft: 20 }}
                                onChange={(e) => this.handleAvatarShapChange(e.target.value)}
                            >
                                <Radio.Button value={'圆形'} disabled>圆形</Radio.Button>
                                <Radio.Button value={'方形'}>方形</Radio.Button>
                            </Radio.Group>
                        </div>
                        <div className="upload_poster">
                            <div className="margin_view title_name">昵称</div>
                            <Switch className="margin_view" defaultChecked={param.showNickname} onChange={(val:boolean) => this.nickNameOnChange(val)}/>
                            <div className="margin_view color_div">
                                <div>颜色</div>
                                <div className="show_color_div" style={{backgroundColor: param.nickNameColor}} onClick={(e)=>{
                                    e.stopPropagation();
                                    this.setState({
                                        isSelectColor: true,
                                    });
                                }}>
                                {
                                    isSelectColor ?
                                        <div className="select_color">
                                            <SketchPicker
                                                color={param.nickNameColor}
                                                onChangeComplete={(e: ColorResult) =>
                                                    this.changeNickNameColor(e.hex)
                                                }
                                            />
                                        </div> : null
                                }
                                </div>
                            </div>
                            <div className="margin_view color_div">
                                <div style={{marginRight: "10px"}}>大小</div>
                                <Select
                                    value={param.nickNameSize}
                                    style={{ width: 68 }}
                                    onChange={(e: number) => this.changeNickNameSize(e)}
                                >
                                    {[12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22].map(size => (
                                        <Option key={size} value={size}>
                                            {size}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                            <Radio.Group
                                value={param.nickNameTextAlign}
                                style={{ marginLeft: 20 }}
                                onChange={(val) => this.textAlignChange(val.target.value)}
                            >
                                <Radio.Button value="left">左对齐</Radio.Button>
                                <Radio.Button value="center">居中</Radio.Button>
                                <Radio.Button value="right">右对齐</Radio.Button>
                            </Radio.Group>
                        </div>
                    </div>
                </Col>
                <Col span={4} />
                <Col span={8}>
                    <canvas
                        id="canvas"
                        ref={this.init}
                        style={{ touchAction: "none", userSelect: "none" }}
                    />
                </Col>
            </Row>
        </div>
    }
}
export default PosterSetting;