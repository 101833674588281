import React from 'react'
import { Row, Typography, Col, Select, Input, Table} from 'antd'
import './turnplateComment.scss';
const { Text } = Typography;
const { Option } = Select;
const { Search } = Input;



interface TurnplateCommentProps {

}

interface TurnplateCommentState {

}

class TurnplateComment extends React.Component<TurnplateCommentProps, TurnplateCommentState> {
    state = {
        pageSize: 10,
        numList:[
            {
                'num': 23455,
                'text': '参与活动人数',
                'tips': '当前活动总收款笔数'
            },
            {
                'num': 22155,
                'text': '受邀总人数',
                'tips': '当前活动总收款笔数'
            },
            {
                'num': 23490,
                'text': '发放奖品人数',
                'tips': '当前活动总收款笔数'
            }
        ],
        personList: [
            {
                'name': '辣鸡沙煲',
                'content': '公众号',
                'time': '2019-09-08',
                'applyContent': '回复',
            }
        ]
    }

    columns = [
        {
          title: '用户',
          dataIndex: 'first_pic',
          key: 'first_pic',
          className: 'list_center',
          render: (record:any) => {
            return <div className="btn_wrap">
                {/* <img src={record.url} alt="商品头图" className="list_img"/> */}
                <div></div>
            </div>
          }
        },
        {
            title: '所属活动',
            dataIndex: 'name',
            key: 'name',
            className: 'list_center',
        },
        {
            title: '评价内容',
            dataIndex: 'content',
            key: 'content',
            className: 'list_center',
        },
        {
            title: '评价时间',
            dataIndex: 'time',
            key: 'time',
            className: 'list_center',
        },
        {
            title: '回复内容',
            dataIndex: 'applyContent',
            key: 'applyContent',
            className: 'list_center',
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            className: 'list_center',
            render: (text:any,record:any,) => (
                <div className="btn_wrap">
                    <div className="list_btn" onClick={() => this.selectedList(record)}>设置精选</div>
                    <div className="list_btn" onClick={() => this.setList(record)}>置顶</div>
                    <div className="list_btn" onClick={() => this.applyList(record)}>回复</div>
                    <div className="list_btn" onClick={() => this.deleteList(record)}>删除</div>
                </div>
            ),
        },
    ];

    // 精选
    selectedList = (e:any) => {

    }

    // 置顶
    setList = (e:any) => {

    }

    // 回复
    applyList = (e:any) => {

    }

    // 删除
    deleteList = (e:any) => {

    }

    // 当前公众号选择
    handleChange = () => {

    }

    pageChange = () => {

    }


    render() {
        // const { numList } = this.state;

        return (
            <div className='l_content'>
                <Row>
                    <Col span={2}>
                        <div className="col_title">当前公众号：</div>
                    </Col>
                    <Col span={3}>
                        <Select defaultValue="lucy" style={{ width: 120 }} onChange={this.handleChange}>
                            <Option value="jack">Jack</Option>
                            <Option value="lucy">Lucy</Option>
                            <Option value="Yiminghe">yiminghe</Option>
                        </Select>
                    </Col>

                    <Col span={2}>
                        <div className="col_title">当前活动：</div>
                    </Col>
                    <Col span={3}>
                        <Select defaultValue="lucy" style={{ width: 120 }} onChange={this.handleChange}>
                            <Option value="jack">Jack</Option>
                            <Option value="lucy">Lucy</Option>
                            <Option value="Yiminghe">yiminghe</Option>
                        </Select>
                    </Col>
                    <Col span={2}>
                        <div className="col_title">
                            用户名称
                        </div>
                    </Col>
                    <Col span={3}>
                        <Search placeholder="请输入用户昵称" />
                    </Col>
                </Row>
                <div className="table_wrap">
                    <div>
                        <Table dataSource={this.state.personList} size="middle" columns={this.columns} rowKey={"id"} locale={{emptyText: '暂无数据'}} pagination={{
                            showQuickJumper:true,
                            defaultCurrent: 1,
                            total: 10,
                            pageSize: this.state.pageSize,
                            hideOnSinglePage: true,
                            onChange: this.pageChange
                        }}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default TurnplateComment;