import React from "react";
import {Button, Row, Col, Input, Radio, Table, Modal, Select, message, Icon, Popconfirm} from "antd";
import { postRequest, getRequest } from '../../api/http';
import { apiUrl } from '../../api/api_url';
import styles from './systemSettings.module.scss'
import { getIsPermisson, messageCustom } from "../../utils/permisstionUtil";
const { Option } = Select;

const key = 'updatable';
export default class SystemSettings extends React.Component<any> {

    state = {
        zero_order: false, //沽清后收银台是否可点
        business_model: 1, //营业模式
        order_confirm: 1, //用户下单
        wait_call_open: 1, //等叫功能是否开启
        is_force_authorize:2 //小程序下单是否需要强制授权手机号
    }

    componentDidMount() {
        this.onGettingChange()
    }

    onGettingChange = async () => {
        let res: any = await getRequest(apiUrl.systemConfig)
        if(res.code == 1) {
            this.setState({
                zero_order: res.data.zero_order,//true:可点，false:不可点
                business_model: res.data.business_model, // 1：先就餐后结账，2：先结账后就餐
                order_confirm: res.data.order_confirm, // 1：下单不要服务确定，2：下单要服务员确定，3：称重菜需要确定
                wait_call_open: res.data.wait_call_open, //等叫功能是否开启  1-不开启  5-开启
                is_force_authorize: res.data.is_force_authorize, //小程序下单是否需要强制授权手机号  1-强制授权  2-非强制授权
            })
        }
    }

    settingChange = async (e: any, type: any) => {
        let { business_model, order_confirm } = this.state;
        if(type === '1') {
            if(order_confirm != 1 && e.target.value == 2) {
                message.warn('当前营业模式为服务员确认下单模式，不支持先结账后就餐模式，请先切换为[下单不要服务确定]模式')
                return false;
            }
            // if(order_confirm != 1 && e.target.value == 2) {
            //     message.warn('当前店铺正在营业，修改营业模式会导致已经下单用户订单会产生问题！')
            //     return false;
            // }
        }
        if(type === '3') {
            if(business_model == 2) {
                message.warn('当前营业模式为先结账后用餐模式，不支持服务员确认下单模式，请先切换为[先用餐后结账]模式！')
                return false;
            }
        }
        let name: any = e.target.name;
        let value: any = e.target.value;
        this.setState({
            [name]: value
        })
        let res: any = await postRequest(apiUrl.systemConfig, {arr: {[name]: value}})
        if(res.code == 1) {
            message.success('操作成功！')
        } else {
            message.error(res.message)
        }
    }

    // 返回上一页
    goBack = () => {
        window.history.go(-1)
    }

    render() {

        const { order_confirm, zero_order, business_model,wait_call_open,is_force_authorize } = this.state;

        return (
            <div className='system goods_main padding15 background_fff border_radius_10' style={{margin: '20px 30px'}}>
                <div className="edit_title">系统设置</div>
                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="color_black">营业模式</span>
                        </div>
                    </Col>
                    <Col span={22}>
                        <Radio.Group
                                name='business_model'
                                value={business_model}
                                style={{ marginLeft: 20 }}
                                onChange={(e) => {
                                    if(!getIsPermisson('2_5_4_1')){
                                        messageCustom();
                                        return;
                                      }
                                    this.settingChange(e, '1')
                                }}
                            >
                                <Radio value={1}>
                                    <span>先用餐后结账</span>
                                    <div className={styles.system_tip}>顾客点菜确认下单，直接后<br/>厨出单做菜，无需提前付款</div>
                                </Radio>
                                <Radio value={2}>
                                    <span>先结账后用餐</span>
                                    <div className={styles.system_tip}>要求顾客选定菜品后需进行付款，订<br/>单信息才生效（后厨才会打印菜品）</div>
                                </Radio>
                            </Radio.Group>
                    </Col>
                </Row>
                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="color_black">沽清后收银台可点</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <Radio.Group
                                name='zero_order'
                                value={zero_order}
                                style={{ marginLeft: 20 }}
                                onChange={(e) => {
                                    if(!getIsPermisson('2_5_4_2')){
                                        messageCustom();
                                        return;
                                      }
                                    this.settingChange(e, '2')
                                }}
                            >
                                <Radio value={true}>是</Radio>
                                <Radio value={false}>否</Radio>
                            </Radio.Group>
                    </Col>
                </Row>
                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="color_black">用户下单</span>
                        </div>
                    </Col>
                    <Col span={22}>
                        <Radio.Group
                                name='order_confirm'
                                value={order_confirm}
                                style={{ marginLeft: 20 }}
                                onChange={(e) => {
                                    if(!getIsPermisson('2_5_4_3')){
                                        messageCustom();
                                        return;
                                      }
                                    this.settingChange(e, '3')
                                }}
                            >
                                <Radio value={1}>下单不要服务员确定</Radio>
                                <Radio value={2}>下单要服务员确定</Radio>
                                <Radio value={3}>称重菜品要服务员确定</Radio>
                            </Radio.Group>
                    </Col>
                </Row>
                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="color_black">等叫功能</span>
                        </div>
                    </Col>
                    <Col span={22}>
                        <Radio.Group
                                name='wait_call_open'
                                value={wait_call_open}
                                style={{ marginLeft: 20 }}
                                onChange={(e) => {
                                    if(!getIsPermisson('2_5_4_3')){
                                        messageCustom();
                                        return;
                                      }
                                    this.settingChange(e,'4')
                                }}
                            >
                                <Radio value={1}>关闭</Radio>
                                <Radio value={5}>开启</Radio>
                            </Radio.Group>
                    </Col>
                </Row>
                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="color_black">小程序下单强制授权手机号</span>
                        </div>
                    </Col>
                    <Col span={22}>
                        <Radio.Group
                            name='is_force_authorize'
                            value={is_force_authorize}
                            style={{ marginLeft: 20 }}
                            onChange={(e) => {
                                if(!getIsPermisson('2_5_4_3')){
                                    messageCustom();
                                    return;
                                }
                                this.settingChange(e,'5')
                            }}
                        >
                            <Radio value={1}>强制授权</Radio>
                            <Radio value={2}>非强制授权</Radio>
                            <div className={styles.system_tip}>若开启小程序下单需要强制授权手机号,则顾客在【下单至厨房】页面会弹出授权手机号登录的页面进行授权，如取消授权，则无法下单</div>
                        </Radio.Group>
                    </Col>
                </Row>
            </div>
        )
    }
}