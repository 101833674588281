import {observer} from "mobx-react";
import React from "react";
import {Button, Form, Input, Icon, Upload, Modal, message, Row, Col} from "antd";
import { FormComponentProps } from 'antd/es/form';
import BraftEditor from 'braft-editor'
// 引入编辑器样式
import 'braft-editor/dist/index.css'

import "../product.css";
import { postRequest, getRequest } from '../../../api/http';
import { apiUrl } from '../../../api/api_url';

const { TextArea } = Input;

interface ProductEdit{
}

interface UserFormProps extends FormComponentProps {
    name: string,
    desc: string,
    price: string,
    info: string
}

function getBase64More(file:any) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

const formItemLayout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 6 },
};

const QINIU_SERVER = 'https://up-z2.qiniup.com';
const key = 'updatable';

@observer
class ProductEdit extends React.Component<any> {

    state = {
        loading: false,
        imageUrl: '',
        imageObj: {
            key: '',
            url: ''
        },
        previewVisible: false,
        previewImage: '',
        fileLists: [],
        editorState: BraftEditor.createEditorState(null),
        updateObj: {
            key: '',
            tokens: '',
            region: '',
            'x:type': ''
        }
    }

    componentDidMount () {
        const {location} = this.props;
        console.log("跳转地址"+ JSON.stringify(this.props.location) );
        console.log((location.state !== undefined) + '===================');
        if(location.state !== undefined){
            this.initEdit(location.state.id);
        }
    }

    // 初始化编辑
    initEdit = async (id:number) => {
        const params = {
            id: id
        };
        var picArray:object[] = [];
        const res:any = await postRequest(apiUrl.productDetail,params);
        if(res.code === 1){
            this.props.form.setFieldsValue({
                name: res.data.name,
                desc: res.data.desc,
                price: res.data.price,
            });
            for(var i=0;i<res.data.pics.length;i++){
                picArray.push({
                    uid:'-'+ i,
                    status: 'done',
                    url: res.data.pics[i].url,
                    name: res.data.pics[i].key
                });
            }
            this.setState({
                imageObj: res.data.first_pic,
                imageUrl: res.data.first_pic.url,
                fileLists: picArray,
                editorState: BraftEditor.createEditorState(res.data.detail)
            })
        }
    }

    // 获取图片token
    beforeUpload = async (file:any) => {
        const res:any = await getRequest(apiUrl.getUploadToken, { type: 'food' });
        if(res && res.data) {
          const key = `food/${Math.random().toString(36).slice(-8)}${(new Date()).valueOf()}.png`
          this.setState({
            updateObj: { token: res.data.upload_token, region: `https://up-z2.qiniu.com`, key: key, 'x:type': "food" }
          })
        }
    }

    // 商品头图上传
    handleChange = (info:any) => {
        if (info.file.status === 'uploading') {
          this.setState({ loading: true });
          return;
        }
        if (info.file.status === 'done') {
            this.setState({
              imageUrl: info.file.response.url,
              imageObj: info.file.response,
              loading: false,
            })
        }
    };

    // 商品详情图上传
    handleChangeMore = (fileLists:any ) => {
        console.log(JSON.stringify(fileLists.fileList));
        this.setState({ fileLists:fileLists.fileList })
    };

    // 图片预览
    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = async (file:any) => {
        if (!file.url && !file.preview) {
          file.preview = await getBase64More(file.originFileObj);
        }

        this.setState({
          previewImage: file.url || file.preview,
          previewVisible: true,
        });
    };

    handleSubmit = async (e:any) => {
        message.loading({ content: '发布中', key });

        e.preventDefault();
        const { imageUrl, fileLists, imageObj } = this.state;

        let productInfo = this.props.form.getFieldsValue();

        const {location} = this.props;
        console.log("跳转地址"+ JSON.stringify(this.props.location) );
        console.log((location.state !== undefined) + '===================');
        if(location.state !== undefined){
            productInfo.id = location.state.id;
        }

        if(imageUrl !== null && imageUrl !== ''){
            productInfo.first_pic = imageObj.key;
        }

        if(fileLists !== null && fileLists.length > 0){
            var imgArray:string[] = [];
            for(var i=0; i<fileLists.length; i++){
                var obj:any = fileLists[i];
                console.log(JSON.stringify(obj.response));
                if(obj.response !== undefined){
                    imgArray.push(obj.response.key);
                }else{
                    imgArray.push(obj.name);
                }
            }
            productInfo.pics = imgArray;
        }

        const htmlContent = this.state.editorState.toHTML();
        productInfo.detail = htmlContent;

        console.log('111111111111'+JSON.stringify(productInfo));

        if(productInfo.name === undefined || productInfo.name === ''){
            message.warning({ content: '请输入商品名称!', key, duration: 2 });
            return;
        }

        if(productInfo.first_pic === undefined || productInfo.first_pic === ''){
            message.warning({ content: '请选择商品头图!', key, duration: 2 });
            return;
        }

        if(productInfo.pics === undefined || productInfo.pics.length === 0){
            message.warning({ content: '请选择商品详情图!', key, duration: 2 });
            return;
        }

        if(productInfo.desc === undefined || productInfo.desc === ''){
            message.warning({ content: '请输入商品描述!', key, duration: 2 });
            return;
        }

        if(productInfo.price === undefined || productInfo.price === ''){
            message.warning({ content: '请输入商品价格!', key, duration: 2 });
            return;
        }

        if(productInfo.detail === undefined || productInfo.detail === '<p></p>'){
            message.warning({ content: '请输入商品信息!', key, duration: 2 });
            return;
        }

        if(location.state !== undefined){
            const res:any = await postRequest(apiUrl.productEdit,productInfo);
            if(res['code'] === 1){
                message.success({ content: '修改成功!', key, duration: 2 });
                this.props.history.push('/sidebar/product');
            }else{
                message.error({ content: '修改失败!', key, duration: 2 });
            }
        }else{
            const res:any = await postRequest(apiUrl.productAdd,productInfo);
            if(res['code'] === 1){
                message.success({ content: '发布成功!', key, duration: 2 });
                this.props.history.push('/sidebar/product')
            }else{
                message.error({ content: '发布失败!', key, duration: 2 });
            }
        }
    };

    handleEditorChange = (editorState:any) => {
        this.setState({ editorState })
    }

    // 返回上一页
    goBack = () => {
        this.props.history.push('/sidebar/product');
    }

    render(){

        const { getFieldDecorator } = this.props.form;
        const { imageUrl, previewVisible, previewImage, fileLists, editorState } = this.state;

        return <div className='l_content'>
            <Form onSubmit={this.handleSubmit} {...formItemLayout} labelAlign="left">
                <div className="edit_title">基本信息</div>
                <Form.Item label="商品名称">
                    {
                        getFieldDecorator('name',{
                            rules: [{required: true, message:'请输入商品名称'},]
                        })(
                            <Input placeholder="请输入商品名称" maxLength={15}/>
                        )
                    }
                </Form.Item>

                <Form.Item label="商品头图" >
                    {
                        getFieldDecorator('first_pic',{
                            rules: [{required: true, message:'请输入商品描述'}]
                        })(
                            <Upload
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            action={QINIU_SERVER}
                            beforeUpload={this.beforeUpload}
                            onChange={this.handleChange}
                            data={this.state.updateObj}
                            >
                            {
                                imageUrl ?
                                <img src={imageUrl} alt="商品头图" style={{ width: '100%' }} />
                                : <div className="">
                                    <Icon type={this.state.loading ? 'loading' : 'plus'} style={{fontSize: '40px'}}/>
                                </div>
                            }
                        </Upload>
                        )
                    }

                    <div className="img_tips">（建议尺寸400x400像素）</div>
                </Form.Item>
                <Form.Item label="商品详情图">
                    {
                        getFieldDecorator('pics',{
                            rules: [{required: true, message:'请输入商品描述'}]
                        })(
                            <div className="clearfix">
                                <Upload
                                action={QINIU_SERVER}
                                listType="picture-card"
                                fileList={fileLists}
                                onPreview={this.handlePreview}
                                onChange={this.handleChangeMore}
                                beforeUpload={this.beforeUpload}
                                data={this.state.updateObj}
                                showUploadList = {{showDownloadIcon: false}}
                                >
                                {fileLists.length >= 9 ? null : <div className="">
                                    <Icon type='plus' style={{fontSize: '40px'}}/>
                                </div>}
                                </Upload>
                                <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                                    <img alt="商品详情图" style={{ width: '100%' }} src={previewImage} />
                                </Modal>
                            </div>
                        )
                    }

                    <div className="img_tips">（建议尺寸752x600像素，最多9张）</div>
                </Form.Item>
                <Form.Item label="商品描述">
                    {
                        getFieldDecorator('desc',{
                            rules: [{required: true, message:'请输入商品描述'}]
                        })(
                            <TextArea rows={4} placeholder="请输入商品描述" />
                        )
                    }
                </Form.Item>

                <div className="edit_title">商品价格</div>
                <Form.Item label="商品价格" wrapperCol={{ span: 3}}>
                    {
                        getFieldDecorator('price',{
                            rules: [{required: true, message:'请输入商品价格'}]
                        })(
                            <Input placeholder="请输入商品价格" addonAfter="元" type="number"/>
                        )
                    }
                </Form.Item>
                <div className="edit_title">商品信息</div>

                <div className="wrap">
                    <Row>
                        <Col span={2}>
                            <div>
                                <span className="star"></span>
                                <span>内容详情</span>
                            </div>
                        </Col>
                        <Col span={14}>
                            <div className="editor_wrap">
                                <BraftEditor
                                    value={editorState}
                                    onChange={this.handleEditorChange}
                                    excludeControls={['media']}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>

                <Row>
                    <Col span={18}>
                        <div className="bottom_wrap">
                            <Button type="primary" ghost onClick={this.goBack}>
                                取消
                            </Button>
                            <Button type="primary" htmlType="submit">
                                保存
                            </Button>
                        </div>
                    </Col>
                </Row>

            </Form>

        </div>;
    }

}
export default Form.create<UserFormProps>()(ProductEdit)