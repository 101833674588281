import React, { useState, useEffect } from "react";
import { Layout, Row, Icon, Avatar, Dropdown, Button, Menu, Col, message } from "antd";
import { useHistory } from "react-router-dom";

import './header_bar.scss'

import notifi from '../../../assret/images/notifi.png'
import ShopModal from "./shopModal";
import help_center from '../../../assret/images/help_center.png'
import { getRequest } from "../../../api/http";
import { apiUrl } from "../../../api/api_url";
import {getIsPermisson, messageCustom} from "../../../utils/permisstionUtil";


const { Header, Sider } = Layout;

type Props = {
  collapsed: boolean
  onCollapse(): void;
  setPhone(phone: string):void
  phone: string
};

const HeaderBar = (props: Props) => {
  const { onCollapse, collapsed, phone  } = props;
  const history:any = useHistory();

  const [selectStore, setSelectStore] = useState(0);
  const [isLoading,setIsLoading] = useState(false);
  const [noti, setnoti] = useState<any>('0');
  const [shop_name, setshop_name] = useState<any>('')
  const [userImg, setuserImg] = useState('')

  const buildType = process.env.REACT_APP_ChromeApp;
  const verSion = process.env.REACT_APP_Version;

  const loginOut = () => {
    //let _version: any = localStorage.getItem('version');
    localStorage.clear();
    //localStorage.setItem('version',_version)
    history.push("/login");
  };

  useEffect(() => {
    setInterval(() => {
      setnoti(localStorage.getItem('noti'))
      setshop_name(localStorage.getItem('shop_name'))
    }, 1000)
    let userInfo:any = localStorage.getItem('user_info');
    userInfo&&setuserImg(JSON.parse(userInfo).headimgurl)
  },[])

  const showSelect = () => {
      history.push('/store');
    //setSelectStore(1)
  }

  const hideSelect = () => {
      history.push('/store');
    // setSelectStore(0)
  }

  //选择门店
  const onGoPage = async (shop_id:any, shop_name: any, info:any) => {
    setIsLoading(true)
    const res:any = await getRequest(apiUrl.webGetPermission,{shopid: shop_id});
    if(res.code == 1){
     let permissions = res.data.permission ?? []
      let keys = res.data.key ?? []
      if(keys.length == 0){
        setIsLoading(false)
        message.warning('没有权限请联系管理员');
        return
      }
      // setbaseUrl('http://www.baidu.com')
      // console.log(',baseUrl', baseUrl);

      window.localStorage.setItem('siyu_tab_list',JSON.stringify(permissions));
      window.localStorage.setItem('uni_keys',JSON.stringify(keys));
      window.location.reload()
      // console.log('Global',Global.baseUrl);
      // Global.baseUrl = 'YDN.SHUACHI.COM'
      // console.log('Global',Global.baseUrl);

      onGoPageEnd(shop_id, shop_name,info,permissions)
    }else{
      setIsLoading(false)
      message.error('服务器异常'+ res.message);
    }
  }

  const onGoPageEnd = (shop_id:any, shop_name:any, info: any,siyu_tab_list: any) =>{
    localStorage.setItem('shop_id', shop_id);
    localStorage.setItem('shop_name', shop_name)
    localStorage.setItem('shop_request', info.request)
    localStorage.setItem('shop_info', JSON.stringify(info))
    // window.location.reload()
    let timer = setTimeout(() => {
      setSelectStore(0)
      setIsLoading(false)
      clearTimeout(timer)
      if(process.env.REACT_APP_ChromeApp === "1") {
        history.push('/sidebar/windowManage');
      }else{
        console.log('siyu_tab_list',siyu_tab_list)
          if(siyu_tab_list[0].routers && siyu_tab_list[0].routers.length > 0){
              console.log(siyu_tab_list[0].routers[0].path)
              history.push(siyu_tab_list[0].routers[0].path);
          }else{
              history.push(siyu_tab_list[0].path);
          }
      }
    },1000)

}

  //修改密码
  const modify = () => {
    history.push("/sidebar/ModifyPassword");
  }

  const menu = (
    <Menu>
      <Menu.Item>
        <Button type="link" onClick={showSelect}>
          切换门店
        </Button>
      </Menu.Item>
      <Menu.Item>
        <Button type="link" onClick={modify}>
          修改密码
        </Button>
      </Menu.Item>
      <Menu.Item>
        <Button type="link" onClick={loginOut}>
         退出登录
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <Header style={{ background: "#fff", padding: "0 20px 0 0" }}>
      <Row
        type="flex"
        justify="space-between"
        style={{ height: "100%" }}
        align="middle"
      >
        {
          process.env.REACT_APP_ChromeApp === "1" && <Row
          type='flex'
          align='middle'
          >
            <Col
            onClick={() => history.goBack()}
            style={{
            width: 80,
            cursor: "pointer",
            fontWeight: "bold",
            fontSize: 18,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: 20
          }}><Icon type="left" />返回</Col>
            <Col><div className='shop_name'>{shop_name}</div></Col></Row>
        }
        {
          buildType === "0" && <Row
            onClick={onCollapse}
            style={{
              // width: "60px",
              height: "100%",
              paddingLeft: 20,
            }}
            type="flex"
            justify="start"
            align="middle"
          >
            {!collapsed ? (
              <Icon
                type="menu-fold"
                style={{ fontSize: "24px", cursor: "pointer" }}
              />
            ) : (
              <Icon
                type="menu-unfold"
                style={{ fontSize: "24px", cursor: "pointer" }}
              />
            )}
            <div className='shop_name'>{shop_name}</div>
          </Row>
        }

        {
          history.location.pathname != '/sidebar/windowManage'
          ? buildType === "1" && <Row> <Button type="link" style={{fontSize: 18, color: '#b92622', fontWeight: "bold"}} onClick={() => history.replace("/sidebar/windowManage")}>返回实时餐桌</Button> </Row>
          : ''
        }
        {/* {
          buildType === "1" && <Row> <Button type="link" style={{fontSize: 18, color: '#b92622', fontWeight: "bold"}} onClick={() => history.replace("/sidebar/windowManage")}>返回实时餐桌</Button> </Row>
        } */}

        <Row>
          <Col className='notifi' onClick={() => {history.push('/sidebar/notification')}}>
            <img src={notifi} />
            <div className='noti_num'>{noti}</div>
          </Col>
          {/* <Avatar size={30} icon="question-circle" style={{backgroundColor: '#000000'}} /> */}
          <span className="header_fontWeight">{verSion}</span>
          <Button type="link" style={{marginRight: 20}} onClick={() => {history.push('/helpCenter/HelpTypeManagement')}}>
            <img src={help_center} style={{width: '31px',height: '31px'}}/>
              {/* 帮助中心 */}
          </Button>
          <Avatar size={30} icon="user" src={userImg} />
          <Dropdown overlay={menu}>
            <Button type="link">
              管理员:{phone} <Icon type="down" />
            </Button>
          </Dropdown>
        </Row>
      </Row>
      {
        selectStore == 0
        ? ''
        :  <ShopModal hideSelect={hideSelect} onGoPage={onGoPage} isLoading={isLoading}></ShopModal>
      }
    </Header>
  );
};

export default HeaderBar