import React from "react";
import "./rewardSetting.scss"
import {Button, Col, Icon, Input, Row, Tooltip, Modal, Table, message, Avatar} from "antd";
import {getRequest} from "../../../../../api/http";
import {apiUrl} from "../../../../../api/api_url";
import {Link} from "react-router-dom";
interface IProps {
    callbackParent(type:number,param:any):void,
    param ?: any,
}
interface paramType {
    value?:any,
    stockNum ?: any,
    bindShop?: any
}
class RewardSetting extends React.Component<IProps>{
    re = /^[1-9]+[0-9]*]*$/;            // 正整数校验
    pageSize= 10;
    state = {
        visible: false,
        shopList: [],
        listNum: 0,
        current: 1,
        param: {
            value: '',
            stockNum: '',
            bindShop: {}
        } as paramType
    };

    componentDidMount(): void {
        this.initPageParams();
    }

    // 初始化页面参数
    initPageParams =() =>{
        let that = this;
        let paramTem = that.props.param;
        if(Object.keys(paramTem).length !== 0){
            that.setState({
                param:paramTem
            })
        }
    };

    handleModel =() =>{
        let {visible} = this.state;
        if(!visible){
            this.requestShopList();
        }
        this.setState({visible: !visible});
    };

    // 目标人数监听
    handlePeopleNum =(e:any) =>{
        let value = e.target.value;
        let paramTemp: any = this.state.param;
        if(isNaN(value)){
            message.error("请输入整数!");
        }else{
            paramTemp.value = this.re.test(value) ? parseInt(value):'';
        }
        this.setState({param: paramTemp});
        this.submitParentParams();
    };

    // 绑定商品
    handleBindShop = (item:any) =>{
        let paramTemp: any = this.state.param;
        paramTemp.bindShop = item;
        this.setState({
            param:paramTemp
        });
        this.handleModel();
        this.submitParentParams();
    };

    // 库存数监听
    handleStockNum =(e:any) =>{
        let value = e.target.value;
        let paramTemp: any = this.state.param;
        if(isNaN(value)){
            message.error("请输入整数!");
        }else{
            paramTemp.stockNum = this.re.test(value) ? parseInt(value):'';
        }
        this.setState({param: paramTemp});
        this.submitParentParams();
    };
    // 提交参数给 父级组件
    submitParentParams =()=>{
        this.props.callbackParent(3,this.state.param);
    };
    // 获取 商品列表
    requestShopList = async () =>{
        let {current} = this.state;
        const res: any = await getRequest(apiUrl.productList,{page: current,pageSize: this.pageSize});
        if(res.code === 1){
            this.setState({shopList:res.data.data,listNum:res.data.total})
        }else{
            console.log(res.msg)
        }
    };

    // 分页
    changePage = (page:number) => {
        this.setState({
            current: page,
        }, () => {
            this.requestShopList();
        })
    };

    // 创建 商品列表
    createShopTable = () =>{
        let{shopList,listNum,current} = this.state;
        const dataSource = shopList;
        const columns:any = [
            {
                title: '商品名称',
                dataIndex: 'name',
                key: 'name',
                align: "center",
            },
            {
                title: '商品价格',
                dataIndex: 'price',
                key: 'id',
                align: "center",
            },
            {
                title: '创建时间',
                dataIndex: 'created_at',
                key: 'created_at',
                align: "center",
            },
            {
                title: '操作',
                align: "center",
                render: (text: string,record:any,index:number)=>(
                    <div className="table_action" onClick={this.handleBindShop.bind(this,record)}>绑定</div>
                )
            },
        ];
        return  <Table
            rowKey={"id"}
            size="middle"
            dataSource={dataSource}
            columns={columns}
            pagination={
                {
                    simple: true,
                    current: current,
                    total: listNum,
                    onChange: this.changePage,

                }
            }
        />

    };

    render(){
        let {visible,param} = this.state;
        const tipText = "参与活动粉丝将自己的专属海报分享给好友，成功拉取X名好友扫码关注公众号，即可完成任务";
        return (
            <div className="reward_setting_view l_content">
                <div className="step_title_name">活动奖励</div>
                <div className="reward_form">
                    <Row align="middle">
                        <Col>
                            <Row className="middle_view">
                                <Col span={2}>
                                    <div>目标人数：</div>
                                </Col>
                                <Col span={3} className="people_number_input">
                                    <Input type="number" value={param.value === 0 ? '':param.value}  addonAfter="人" onChange={this.handlePeopleNum}/>
                                </Col>
                                <Col span={1}>
                                    <div>
                                        <Tooltip placement="topLeft" title={tipText}>
                                            <Icon type="exclamation-circle" theme="filled" />
                                        </Tooltip>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="middle_view">
                                <Col span={2}>
                                    <div>完成奖品：</div>
                                </Col>
                                <Col span={4}>
                                    {
                                        Object.keys(param.bindShop).length === 0 ?
                                            <Button icon="plus" type="default" onClick={this.handleModel}>绑定商品</Button>
                                            :
                                            <div className="bindShopClass" onClick={this.handleModel}>
                                                <Avatar shape="square"  size={60} src={param.bindShop.first_pic.url} />
                                                <div className="bindShopName">{param.bindShop.name}</div>
                                            </div>
                                    }
                                </Col>
                            </Row>
                            <Row className="middle_view">
                                <Col span={2}>
                                    <div>奖品库存：</div>
                                </Col>
                                <Col span={3}>
                                    <Input type="number" value={param.stockNum} onChange={this.handleStockNum}  addonAfter="件" />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <Modal
                    visible={visible}
                    closable={false}
                    title={null}
                    onCancel={this.handleModel}
                    footer={null}
                    width="70vw"
                >
                    <div className="model_head">
                        <div className="head_title">选择商品</div>
                        <Link to='/sidebar/ProductEdit'>
                            <Button type="primary" icon="plus" className="add_shop_button">新增商品</Button>
                        </Link>
                    </div>
                    {this.createShopTable()}
                </Modal>
            </div>
        )
    }
}

export default RewardSetting;