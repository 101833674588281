import React, { Fragment } from 'react'
import { Modal, Layout, Row, Avatar, Typography } from 'antd'

const { Text, Title } = Typography;

interface IDetailModal {
  showDrawer: boolean,
  visitable(show: boolean): void,
  currentDrawerData: {
    user: {
      avatar: string,
      nickname: string,
    },
    pay_amount: string,
    updated_at: string,
    status_display: string,
    invited_parent_user: {
      avatar: string,
      nickname: string,
    },
    code: string,
  }
}

const DetailModal = (props: IDetailModal) => {
  
  const { currentDrawerData } = props;

  if(!currentDrawerData) return <div></div>;

  return <Modal
      title="详情"
      visible={props.showDrawer}
      onOk={() => props.visitable(false)}
      onCancel={() => props.visitable(false)}
      footer={null}
      width={`70vw`}
    >
      <Layout style={{padding: 10}}>
        <Title level={4} style={{textAlign: 'center', fontSize: 16}}>买家</Title>
        <Row type="flex" justify="space-between">
          <Row style={{flex: 1}}>
            <Avatar src={currentDrawerData.user.avatar} style={{marginRight: 10}} />
            <Text>{currentDrawerData.user.nickname}</Text>
          </Row>
          <Row style={{flex: 1}} type="flex" justify="center">
          <Text>支付金额：￥{currentDrawerData.pay_amount}</Text>
          </Row>
          <Row style={{flex: 1}} type="flex" justify="end">
            <Text>{currentDrawerData.status_display}：{currentDrawerData.updated_at}</Text>
          </Row>
        </Row>
        
        {
          (currentDrawerData && currentDrawerData.invited_parent_user) 
          && (
            <Fragment>
              <Title level={4} style={{textAlign: 'center', fontSize: 16}}>上级</Title>
              <Row type="flex" justify="space-between">
                <Row style={{flex: 1}}>
                  <Avatar icon="user" style={{marginRight: 10}} />
                  <Text>{currentDrawerData.invited_parent_user.nickname}</Text>
                </Row>
                <Row style={{flex: 1}} type="flex" justify="center">
                  <Text>上级返现：￥12.00</Text>
                </Row>
                <Row style={{flex: 1}} type="flex" justify="end">
                  <Text>未支付：2019-12-12 10：10</Text>
                </Row>
              </Row>
            </Fragment>
          )
        }
        

        <Row style={{flex: 1, marginTop: 20}} type="flex" justify="end">
        <Text>订单号：{currentDrawerData.code}</Text>
          </Row>
      </Layout>
      
    </Modal>
}

export default DetailModal