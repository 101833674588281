import { Row, Col, DatePicker, message, Select } from 'antd';
import React, { Component } from 'react';

import styles from './index.module.scss';
import moment from 'moment';
const { RangePicker } = DatePicker;

interface IProps {
    history?: any,
    dateList?: Array<{
        type: string,
        name: string
    }>,
    callback: Function,
    currentDate?: Array<string>,
    current?: number
}

interface IState {
    current: number,
    dataList: Array<{
        type: string,
        name: string
    }>
}

const nowDate = new Date();
const yesterday = new Date(nowDate.getTime() - 24 * 60 * 60 * 1000)
const beforeYesterday = new Date(nowDate.getTime() - 24 * 2 * 60 * 60 * 1000)

var now:any = new Date(); //当前日期
var nowMonth = now.getMonth(); //当前月
var nowYear = now.getYear(); //当前年
nowYear += (nowYear < 2000) ? 1900 : 0; //
var lastMonthDate:any = new Date(); //上月日期
lastMonthDate.setDate(1);
lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);

class CustomDatePicker extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            current: 0,
            dataList: [
                {
                    type: 'today',
                    name: '今日'
                },
                {
                    type: 'yesterday',
                    name: '昨日'
                },
                {
                    type: 'nearlySevenDays',
                    name: '近7天'
                },
                {
                    type: 'ownMonth',
                    name: '近30天'
                },
                {
                    type: 'currentMonth',
                    name: '本月'
                },
                {
                    type: 'beforeMonth',
                    name: '上月'
                },
                {
                    type: 'quarter',
                    name: '本季度'
                },
                {
                    type: 'lastQuarter',
                    name: '上季度'
                },
                {
                    type: 'own',
                    name: '累计'
                }
            ]
        }
    }

    componentDidMount(){
        const { currentDate, current } = this.props;
        if(current){
            this.setState({
                current
            })
        }else if(currentDate && currentDate[0] != ''){
            this.setState({
                current: -1,
            })
        }
    }

    getTimeData(type: string, inx: number) {
        const { callback } = this.props;
        this.setState({
            current: inx
        }, () => {
            switch (type) {
                case 'currentMonth': // 本月
                    let currMonthStart = `${nowDate.getFullYear()}-${this.formatData(nowDate.getMonth() + 1)}-01`;
                    let currMonthEnd = `${nowDate.getFullYear()}-${this.formatData(nowDate.getMonth() + 1)}-${this.formatData(nowDate.getDate())}`;
                    callback([currMonthStart,currMonthEnd]);
                    break;
                case 'nearlySevenDays': //近七天
                    let date = new Date();
                    date.setTime(date.getTime()-((24*60*60*1000) * 6));
                    let year: any = date.getFullYear();
                    let month:any = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : '0' + (date.getMonth() + 1);
                    let day:any = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();
                    let nearlySevenStart = year + '-' + month + '-' + day;
                    let nearlySevenEnd = `${nowDate.getFullYear()}-${this.formatData(nowDate.getMonth() + 1)}-${this.formatData(nowDate.getDate())}`;
                    callback([nearlySevenStart,nearlySevenEnd]);
                    break;
                case "beforeWeek":
                    var end = now.getTime() - 1000 * 60 * 60 * 24 * now.getDay();
                    let start = now.getTime() - 1000 * 60 * 60 * 24 * (now.getDay() + 6)
                    let startDay = new Date(start).getFullYear() + '-' + this.formatData(new Date(start).getMonth() + 1) + '-' + this.formatData(new Date(start).getDate());
                    let endDay = new Date(end).getFullYear() + '-' + this.formatData(new Date(end).getMonth() + 1) + '-' + this.formatData(new Date(end).getDate());
                    callback([startDay,endDay]);
                    break;
                case "beforeMonth": //上月
                    let beforeMonthStart = nowDate.getFullYear() + '-' + this.formatData(nowDate.getMonth()) + '-01';
                    let currentMonth = nowDate.getFullYear() + '-' + this.formatData(nowDate.getMonth() + 1) + '-01'
                    let num = new Date(new Date(currentMonth).getTime() - 24 * 60 * 60 * 1000);
                    let beforeMonthEnd = `${num.getFullYear()}-${this.formatData(num.getMonth() + 1)}-${this.formatData(num.getDate())}`;
                    if(nowDate.getMonth() == 0){
                        beforeMonthStart = (nowDate.getFullYear()-1) + '-12-01'
                    }
                    callback([beforeMonthStart,beforeMonthEnd]);
                    break;
                case "beforeYesterday":
                    let beforeYesDay = `${beforeYesterday.getFullYear()}-${this.formatData(beforeYesterday.getMonth() + 1)}-${this.formatData(beforeYesterday.getDate())}`;
                    callback([beforeYesDay,beforeYesDay]);
                    break;
                case "yesterday": //昨日
                    let yesDay = `${yesterday.getFullYear()}-${this.formatData(yesterday.getMonth() + 1)}-${this.formatData(yesterday.getDate())}`;
                    callback([yesDay,yesDay]);
                    break;
                case "today": //今日
                    let toDay = `${nowDate.getFullYear()}-${this.formatData(nowDate.getMonth() + 1)}-${this.formatData(nowDate.getDate())}`;
                    callback([toDay,toDay]);
                    break;
                case "own": //累计
                    let own = `${nowDate.getFullYear()}-${this.formatData(nowDate.getMonth() + 1)}-${this.formatData(nowDate.getDate())}`;
                    callback(['2020-01-01',own]);
                    break;
                case "quarter": //本季度
                    callback([this.getQuarterStartDate(),this.getQuarterEndDate()]);
                    break;
                case "lastQuarter": //上季度
                    callback([this.getLastQuarterStartDate(),this.getLastQuarterEndDate()]);
                    break;
                case "ownMonth": //近30天
                    callback([this.getLastMonth().last,this.getLastMonth().now]);
                    break;
            }
        })
    }

    //近一个月
	getLastMonth() {
		var now:any = new Date();
		var year = now.getFullYear();
		var month = now.getMonth() + 1;//0-11表示1-12月
		var day = now.getDate();
		var dateObj: any = {};
		dateObj.now = year + '-' + month + '-' + day;
		var nowMonthDay = new Date(year, month, 0).getDate();    //当前月的总天数
		if(month - 1 <= 0){ //如果是1月，年数往前推一年<br>　　　　
			dateObj.last = (year - 1) + '-' + 12 + '-' + day;
		}else{
			var lastMonthDay = new Date(year, (parseInt(month) - 1), 0).getDate();
			if(lastMonthDay < day){    //1个月前所在月的总天数小于现在的天日期
				if(day < nowMonthDay){        //当前天日期小于当前月总天数
					dateObj.last = year + '-' + (month - 1) + '-' + (lastMonthDay - (nowMonthDay - day));
				}else{
					dateObj.last = year + '-' + (month - 1) + '-' + lastMonthDay;
				}
			}else{
				dateObj.last = year + '-' + (month - 1) + '-' + day;
			}
		}
		return dateObj;
    }

    //获得上季度季度的开始日期
	getLastQuarterStartDate() {
		var quarterStartDate = new Date(nowYear, this.getLastQuarterStartMonth() - 2, 1);
		return this.newFormatDate(quarterStartDate);
    }

    //获得上季度的结束日期
	getLastQuarterEndDate() {
		var quarterEndMonth = this.getLastQuarterStartMonth();
		var quarterStartDate = new Date(nowYear, quarterEndMonth,this.getMonthDays(quarterEndMonth));
		return this.newFormatDate(quarterStartDate);
	}

    //获得本季度的开始月份
	getQuarterStartMonth() {
		var quarterStartMonth = 0;
		if (nowMonth < 3) {
			quarterStartMonth = 0;
		}
		if (2 < nowMonth && nowMonth < 6) {
			quarterStartMonth = 3;
		}
		if (5 < nowMonth && nowMonth < 9) {
			quarterStartMonth = 6;
		}
		if (nowMonth > 8) {
			quarterStartMonth = 9;
		}
		return quarterStartMonth;
    }

    //获得上季度的开始月份
	getLastQuarterStartMonth() {
		var quarterStartMonth = 0;
		if (nowMonth < 3) {
			quarterStartMonth = 0;
		}
		if (2 < nowMonth && nowMonth < 6) {
			quarterStartMonth = 3;
		}
		if (5 < nowMonth && nowMonth < 9) {
			quarterStartMonth = 6;
		}
		if (nowMonth > 8) {
			quarterStartMonth = 9;
		}
		return quarterStartMonth;
	}

    //获得本季度的开始日期
	getQuarterStartDate() {
		var quarterStartDate = new Date(nowYear, this.getQuarterStartMonth(), 1);
		return this.newFormatDate(quarterStartDate);
    }

    //或的本季度的结束日期
	getQuarterEndDate() {
		var quarterEndMonth = this.getQuarterStartMonth() + 2;
		var quarterStartDate = new Date(nowYear, quarterEndMonth,this.getMonthDays(quarterEndMonth));
		return this.newFormatDate(quarterStartDate);
    }

    //获得某月的天数
	getMonthDays(myMonth: any) {
		var monthStartDate: any = new Date(nowYear, myMonth, 1);
		var monthEndDate: any = new Date(nowYear, myMonth + 1, 1);
		var days = (monthEndDate - monthStartDate) / (1000 * 60 * 60 * 24);
		return days;
	}

    newFormatDate(date: any) {
		var myyear = date.getFullYear();
		var mymonth = date.getMonth() + 1;
		var myweekday = date.getDate();
		if (mymonth < 10) {
			mymonth = "0" + mymonth;
		}
		if (myweekday < 10) {
			myweekday = "0" + myweekday;
		}
		return (myyear + "-" + mymonth + "-" + myweekday);
	}

    formatData = (num: number) => {
        return num > 9 ? num : `0${num}`
    }

    dateChange = (e:any, dateString: any) => {
        const { callback , current} = this.props;
        if(current == 0 && dateString[0] == ''){
            this.setState({
                current
            })
        }else{
            this.setState({
                current: -1
            })
        }
        callback([dateString[0], dateString[1]]);
    }

    render() {
        const { currentDate } = this.props;
        const { dataList, current } = this.state;

        return (
            <Row className={styles.customDatePicker}>
                <Col className={styles.customDatePicker_select}>
                    <div className={styles.customDatePicker_select_left}>
                        {
                            dataList.map((item: any, index: any) => {
                                return (
                                    <div key={item + index} className={`${styles.customDatePicker_select_left_near} ${current == index ? styles.user_ifon_active : ''}`} onClick={() => {this.getTimeData(item.type, index)}}>{item.name}</div>
                                )
                            })
                        }
                        <div className={styles.customDatePicker_select_left_selecttime}>
                            <RangePicker
                                format={`YYYY-MM-DD`}
                                showTime={false}
                                onChange={(e, dateString) => this.dateChange(e, dateString)}
                                value={currentDate && currentDate[0] ? [moment(currentDate[0], 'YYYY-MM-DD'),moment(currentDate[1], 'YYYY-MM-DD')] : []}
                                placeholder={['开始时间', '结束时间']} />
                        </div>
                    </div>
                </Col>
            </Row>
        )
    }
}

export default CustomDatePicker