import {observer} from "mobx-react";
import React from "react";
import {Button, Input, message, Row, Col, Modal, Radio, Icon, Table, Tree, Tooltip, DatePicker, Upload, Select} from "antd";
import { postRequest, getRequest } from '../../api/http';
import { RangePickerValue } from "antd/lib/date-picker/interface";

// 引入编辑器样式
import 'braft-editor/dist/index.css'
import { apiUrl } from '../../api/api_url';
import BraftEditor from "braft-editor";
const { TreeNode } = Tree;
const { Option } = Select;
const { RangePicker } = DatePicker;

const key = 'HelpEdit';
const QINIU_SERVER = 'https://up-z2.qiniup.com';

@observer
class HelpEdit extends React.Component<any> {

    columnNum:any = [
        {
            title: '优惠券名称',
            dataIndex: 'name',
            key: 'name',
            align: "center",
        },
        {
            title: '券类型',
            dataIndex: 'type',
            key: 'type',
            align: "center",
            render: (text:any,record:any,) => (
                <div className="color">{record.type === 'discount' ? '折扣券' : record.type === 'voucher' ? '优惠券' : '兑换券'}</div>
            ),
        },
        {
            title: '券有效期',
            dataIndex: 'created_at',
            key: 'created_at',
            align: "center",
            render: (text:any,record:any,) => {
                let info:any = record.expire_info;
                return <div className="color">{info.type === 'time' ?  info.start_time + '至' + info.end_time : '自领取日'+info.day + '天领取'}</div>
            }
        },
        {
            title: '操作',
            align: "center",
            render: (text: string,record:any,index:number)=>(
                <div className="table_action" onClick={() => this.unBindShop(index)}>移除</div>
            )
        },
    ];

    pColumns = [
        {
          title: '商品名称',
          dataIndex: 'name',
          key: 'name',
          className: 'list_center',
        },
        {
            title: '所属分类',
            dataIndex: 'cat_name',
            key: 'cat_name',
            className: 'list_center',
        },
        {
            title: '商品价格',
            dataIndex: 'price',
            key: 'price',
            className: 'list_center',
        },
    ];

    state = {
        editorState: BraftEditor.createEditorState(null),
        type: '1',
        eat_users: 0,
        need_users: 0,
        count: 0,
        title: '',
        introduction: '',
        imageUrl: '',
        imageObj: {
            key: '',
            url: ''
        },
        updateObj: {
            key: '',
            tokens: '',
            region: '',
            'x:type': ''
        },
        favour: [],
        productList: [],
        productCount: 0,
        currentPage: 1,
        cat_id: 0,
        searchName: '',
        pVisible: false,
        sortList: [],
        showProductList: [],
        validText: 'time',
        day: 0,
        start_time: '',
        end_time: '',


        loading: false,
        name: '',
        status: 1,
        visible: false,
        couponList: [],
        listNum: 0,
        current: 1,
        selectCoupon: [],
        selectDisabled: false,
        user_type: [],
        pageSize: 10,
        selectList: [],
        excrete_time: 0
    }

    componentDidMount () {

        const {location} = this.props;
        if(location.state !== undefined){
            this.initEdit(location.state.id);
        }
        this.onInitList();
        this._initList()
        let imageUrl = ''
        if(this.state.type === '1'){
            imageUrl = 'https://ydn-siyu.cdn.shuachi.com/open.png';
        }else{
            imageUrl = 'https://ydn-siyu.cdn.shuachi.com/open.png';
        }
        this.setState({
            imageUrl
        })
    }

    // 初始化优惠券列表
    onInitList = async () => {
        let param = {
            current: 1,
            pageSize: 100,
            position: 3,
            type: 'voucher',
            expire_type: 'day'
        }
        const res:any = await getRequest(apiUrl.couponList, param);
        if(res.code === 1){
            this.setState({
                couponList: res.data.list,
                listNum: res.data.total,
                current: 1,
                selectList: res.data.list.slice(0, 10)
            })
        }
    }

    _initList = async () => {
        let sortList:any[] = [];

        const { currentPage } = this.state;
        const params:any = {
            current: currentPage,
            pageSize: 100,
            up_status: 4
        };

        const res:any = await getRequest(apiUrl.goodList,params);
        if(res.code === 1){
            let sortTest:string[] = [];
            res.data.map((item: any) => {
                if(sortTest.indexOf(item.cat_name) < 0){
                    sortList.push({id: item.cat_id, name: item.cat_name})
                    sortTest.push(item.cat_name)
                }
            })
            this.setState({
                productList: res.data,
                sortList
            },() => {
                this.filterList();
            })
        }
    }

    // 初始化编辑
    initEdit = async (id:number) => {
        const params = {
            id: id
        };
        const res:any = await getRequest(apiUrl.dishesHelpConfigDetail,params);
        if(res.code === 1){
            this.setState({
                type: res.data.type.toString(),
                name: res.data.name,
                status: res.data.status,
                selectCoupon: res.data.coupon,
                user_type: res.data.user_type,
                // title: res.data.title,
                // introduction: res.data.introduction,
                // imageUrl: res.data.img_url,
                need_users: res.data.need_users,
            })
            if(res.data.type === 1){
                this.setState({
                    eat_users: res.data.eat_users,
                    count: res.data.count,
                    favour: [res.data.dish]
                })

                if(res.data.expire_info && res.data.expire_info.type === 'day'){
                    this.setState({
                        validText: res.data.expire_info.type,
                        day: res.data.expire_info.day
                    });
                }else if(res.data.expire_info){
                    this.setState({
                        validText: res.data.expire_info.type,
                        start_time: res.data.expire_info.start_time,
                        end_time: res.data.expire_info.end_time
                    });
                }
            }else{
                this.setState({
                    selectCoupon: res.data.coupon,
                    editorState: BraftEditor.createEditorState(res.data.tips)
                })
                if(res.data.excrete_time){
                    this.setState({
                        excrete_time: res.data.excrete_time
                    })
                }
            }
        }
    }

    addCoupon = (id: any) => {
        let selectCoupon = this.state.selectCoupon;
        selectCoupon.map((item: any) => {
            if(item.id === id){
                item.coupon_num++;
            }
        })
        this.setState({
            selectCoupon
        })
    }

    reduceCoupon = (id: any) => {
        let selectCoupon = this.state.selectCoupon;
        selectCoupon.map((item: any) => {
            if(item.id === id){
                if(item.coupon_num === 1){
                    return;
                }
                item.coupon_num--;
            }
        })
        this.setState({
            selectCoupon
        })
    }


    inputChange = (e:any) => {
        let name = e.target.name;
        let value = e.target.value;
        this.setState({
            [name]: value.trim()
        })
    }

    onCheck = (checkedKeys: any, info: any) => {
        let keyList = checkedKeys;
        let user_type: any[] = [];
        keyList.map((item: any) => {
            if(item !== '4'){
                user_type.push(item)
            }
        })
        this.setState({
            user_type
        })
    };

    handleSubmit = async () => {
        message.loading({ content: '发布中', key });

        const {location} = this.props;
        const { editorState, name, type, status, selectCoupon, user_type, need_users, eat_users, count, excrete_time, introduction, imageUrl, favour, start_time, day, validText,end_time } = this.state;
        var tableInfo:any = {};
        let coupon:any[] = [];

        if(location.state !== undefined){
            tableInfo.id = location.state.id;
        }

        if(name === ''){
            message.warning({ content: '请输入活动名称!', key, duration: 2 });
            return;
        }

        if(user_type.length === 0){
            message.warning({ content: '请选择可见用户!', key, duration: 2 });
            return;
        }

        if(need_users === 0){
            message.warning({ content: '请输入助力人数!', key, duration: 2 });
            return;
        }
        if(type === '1'){
            if(eat_users === 0){
                message.warning({ content: '请输入用餐人数!', key, duration: 2 });
                return;
            }
            if(count === 0){
                message.warning({ content: '请输入可获得份数!', key, duration: 2 });
                return;
            }
            // if(title === ''){
            //     message.warning({ content: '请输入分享标题!', key, duration: 2 });
            //     return;
            // }
            // if(introduction === ''){
            //     message.warning({ content: '请输入分享简介!', key, duration: 2 });
            //     return;
            // }
            // if(imageUrl === ''){
            //     message.warning({ content: '请选择分享图片!', key, duration: 2 });
            //     return;
            // }

            if(validText === 'day'){
                if(day === 0){
                    message.warning({ content: '请输入有效天数!', key, duration: 2 });
                    return;
                }
            }else{
                if(start_time === ''){
                    message.warning({ content: '请选择有效区间!', key, duration: 2 });
                    return;
                }
            }
        }else{
            if(selectCoupon.length === 0){
                message.warning({ content: '请关联优惠券!', key, duration: 2 });
                return;
            }
            if(excrete_time == 0){
                message.warning({ content: '请输入拆券有效期!', key, duration: 2 });
                return;
            }
            if(!editorState){
                message.warning({ content: '请输入使用须知!', key, duration: 2 });
                return;
            }
        }

        tableInfo.type = type;
        tableInfo.name = name;
        tableInfo.status = status;
        // tableInfo.title = title;
        // tableInfo.introduction = introduction;
        // tableInfo.img_url = imageUrl;
        tableInfo.user_type = user_type;
        tableInfo.need_users = need_users;

        if(type === '1'){
            tableInfo.eat_users = eat_users;
            tableInfo.count = count;
            tableInfo.dish = favour[0];
            if(validText === 'day'){
                tableInfo.expire_info = {
                    type: validText,
                    day: day
                }
            }else{
                tableInfo.expire_info = {
                    type: validText,
                    end_time,
                    start_time
                }
            }
        }else{
            selectCoupon.forEach((item:any) => {
                if(item.limit == undefined){
                    item.limit = '';
                }
                coupon.push({
                    id: item.id,
                    limit: item.limit,
                    people_num: need_users,
                    coupon_num: 1,
                })
            })
            tableInfo.coupon = coupon;
            tableInfo.excrete_time = excrete_time;
            const htmlContent = this.state.editorState.toHTML();
            tableInfo.tips = htmlContent;
        }

        if(location.state !== undefined){
            const res:any = await postRequest(apiUrl.dishesHelpConfigEdit,tableInfo);
            if(res['code'] === 1){
                message.success({ content: '修改成功!', key, duration: 2 });
                this.goBack()
            }else{
                message.error({ content: '修改失败!', key, duration: 2 });
            }
        }else{
            const res:any = await postRequest(apiUrl.dishesHelp,tableInfo);
            if(res['code'] === 1){
                message.success({ content: '发布成功!', key, duration: 2 });
                this.goBack()
            }else{
                message.error({ content: res.message, key, duration: 2 });
            }
        }
    };

    // 返回上一页
    goBack = () => {
        this.props.history.goBack();
    }

    changePage = async (page: number) => {
        let { couponList } = this.state;
        let list = couponList.slice((page-1)*10, (10*page));
        this.setState({
            selectList: list,
            current: page
        })
    }

    // 关联
    handleBindShop = (id:number) => {
        let selectCoupon:any[] = this.state.selectCoupon;
        let couponList:any[] = this.state.couponList;
        if(selectCoupon.length >= 1){
            message.warning('只能关联一张券')
            return;
        }
        couponList.map((item) => {
            if(item.id === id){
                item.selected = true;
                item.coupon_num = 1;
                selectCoupon.push(item)
            }
        })
        this.setState({
            selectCoupon,
            couponList
        })
    }

    // 解除关联
    unBindShop = (index: number) => {

        let { couponList } = this.state;
        let selectCoupon: any = this.state.selectCoupon;
        couponList.map((item: any) => {
            if(item.id === selectCoupon[index].id){
                item.selected = false;
            }
        })
        selectCoupon.splice(index, 1)
        this.setState({
            couponList,
            selectList: couponList.slice(0, 10),
            current: 1,
            selectCoupon
        })
    }

    // 创建 商品列表
    columns:any = [
        {
            title: '优惠券名称',
            dataIndex: 'name',
            key: 'name',
            align: "center",
        },
        {
            title: '券类型',
            dataIndex: 'price',
            key: 'id',
            align: "center",
            render: (text:any,record:any,) => (
                <div className="color">{record.type === 'discount' ? '折扣券' : record.type === 'voucher' ? '优惠券' : '兑换券'}</div>
            ),
        },
        {
            title: '券有效期',
            dataIndex: 'created_at',
            key: 'created_at',
            align: "center",
            render: (text:any,record:any,) => {
                let info:any = record.expire_info;
                return <div className="color">{info.type === 'time' ?  '自'+info.start_time + '至' + info.end_time : '自领取日'+info.day + '天内有效'}</div>
            }
        },
        {
            title: '操作',
            align: "center",
            render: (text: string,record:any,index:number)=>{
                return <div>
                    {
                        record.selected
                        ? '已关联'
                        : <div className="table_action" onClick={() => this.handleBindShop(record.id)}>关联</div>
                    }
                </div>
            }
        },
    ];

    handleEditorChange = (editorState:any) => {
        this.setState({ editorState })
    }

    // 显示关联
    showTable = () => {
        let { couponList, selectCoupon} = this.state;
        couponList.map((coupon: any) => {
            selectCoupon.map((select: any) => {
                if(coupon.id === select.id){
                    coupon.selected = true;
                }
            })
        })
        this.setState({
            couponList,
            selectList: couponList.slice(0, 10),
            current: 1,
            visible: true
        })
    }

    // 获取图片token
    beforeUpload = async (file:any) => {
        const res:any = await getRequest(apiUrl.getUploadToken, { type: 'food' });
        if(res && res.data) {
          const key = `food/${Math.random().toString(36).slice(-8)}${(new Date()).valueOf()}.png`
          this.setState({
            updateObj: { token: res.data.upload_token, region: `https://up-z2.qiniu.com`, key: key, 'x:type': "food" }
          })
        }
    }

    // 商品头图上传
    handleChange = (info:any) => {
        if (info.file.status === 'uploading') {
          this.setState({ loading: true });
          return;
        }
        if (info.file.status === 'done') {
            this.setState({
              imageUrl: info.file.response.url,
              imageObj: info.file.response,
              loading: false,
            })
        }
    };

    isChinese = (s:string) =>{
        return /[\u4e00-\u9fa5]/.test(s);
    }

    ch2Unicdoe =(str:string) =>{
        if(!str){
          return;
        }
        var unicode = '';
        for (var i = 0; i <  str.length; i++) {
          var temp = str.charAt(i);
          if(this.isChinese(temp)){
            unicode += '\\u' +  temp.charCodeAt(0).toString(16);
          }
          else{
            unicode += temp;
          }
        }
          return unicode;
      }

    searchFoodName = (searchText:string,text:string) => {
        let test = this.ch2Unicdoe(searchText)
        var pattern2 = `/[${test}]+/g`;
        var reg = RegExp(eval(pattern2))
        return reg.test(text)
    }

    deleteDish = (id: number) => {
        let { favour, productList } = this.state;
        favour.map((item:any, index: number) => {
            if(item.dish_id === id){
                favour.splice(index,1)
            }
        })

        productList.map((item: any) => {
            console.log(item.dish_id);
            if(item.dish_id === id){
                item.select = false;
            }
        })

        this.setState({
            favour,
            productList
        })
    }

    filterList = () => {
        let { productList, cat_id, searchName } = this.state;
        let newList:any = [];
        let newListTow:any = [];

        productList.map((item:any) => {
            if(searchName !== ''){
                if(this.searchFoodName(searchName, item.name)){
                    newList.push(item)
                }
            }else{
                if(cat_id !== 0){
                    if(cat_id === item.cat_id){
                        newList.push(item)
                    }
                }else{
                    newList.push(item)
                }
            }
        })

        newListTow = JSON.parse(JSON.stringify(newList));

        this.setState({
            showProductList: newList,
            productCount: newListTow.length
        })
    }

    //商品名搜索
    searchAction = (e:any) => {
        this.setState({
            searchName: e.target.value.trim(),
            currentPage: 1
        }, () => {
            this.filterList()
        })
    }
    // 分类
    sortChange = (e:any) => {
        this.setState({
            cat_id: e,
            currentPage: 1
        }, () => {
            this.filterList()
        })
    }
    // 分页
    orderChange = (page: number) => {
        this.setState({
            currentPage: page
        }, () => {
            this.filterList()
        })
    }

    rowSelection: any = {
        onSelect: (record: any, selected: any, selectedRows: any) => {
            let productList:any = this.state.productList;
            productList.map((item: any) => {
                item.select = false;
                if(item.id === record.id){
                    item.select = selected;
                }
            })
            this.setState({
                productList
            })
        },
        type: 'radio',
        getCheckboxProps(record: any) {
            return {
                defaultChecked: record.select // 配置默认勾选的列
            }
        }
    };

    handleOk = (e:any) => {
        let productList:any[] = this.state.productList;
        let favour: any = [];
        productList.map((item:any) => {
            if(item.select){
                favour.push({
                    dish_id: item.id,
                    standard: 0,
                    name: item.name
                })
            }
        })
        this.setState({
            pVisible: false,
            favour
        });
      };

    showProductTable = () => {
        let { productList, favour } = this.state;
        productList.map((item: any) => {
            favour.map((item2: any) => {
                if(item.id === item2.dish_id){
                    item.select = true;
                }
            })
        })
        this.setState({
            productList,
            pVisible: true
        }, () => {
            this.filterList()
        })
    }

    reDefault = () => {
        let imageUrl = ''
        if(this.state.type === '1'){
            imageUrl = 'https://ydn-siyu.cdn.shuachi.com/open.png';
        }else{
            imageUrl = 'https://ydn-siyu.cdn.shuachi.com/open.png';
        }
        this.setState({
            imageUrl
        })
    }

    dayChange = (e: any) => {
        this.setState({
            day: e.target.value
        })
    }

    timeChange = (time: RangePickerValue, timeString: [string, string]) => {
        this.setState({
            start_time: timeString[0],
            end_time: timeString[1]
        })
    }

    render(){
        let {editorState, day, start_time, end_time, validText, eat_users, need_users,count, title, excrete_time, name, status, visible, selectCoupon, type, user_type, selectList, current, listNum, favour, imageUrl} = this.state;

        return <div className='l_content'>
                <div className="edit_title">活动基本信息</div>
                <Row className="mb30" type="flex" align="middle">
                        <Col span={2}>
                            <div>
                                <span className="star"></span>
                                <span className="color_black">助力类型</span>
                            </div>
                        </Col>
                        <Col span={14}>
                        <Radio.Group
                                name='type'
                                value={type}
                                onChange={this.inputChange}
                            >
                                <Radio value='1'>助力送菜</Radio>
                                <Radio value='2'>助力拆券</Radio>
                            </Radio.Group>
                        </Col>
                    </Row>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">活动名称</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <Input placeholder="请输入活动名称" name='name' value={name} onChange={(e) => this.inputChange(e)}/>
                    </Col>
                </Row>
                {
                    type === '1'
                    ? <div>
                        <Row className="mb30" type="flex" align="middle">
                            <Col span={2}>
                                <div>
                                    <span className="star"></span>
                                    <span className="color_black">有效时间</span>
                                </div>
                            </Col>
                            <Col span={14}>
                            <Radio.Group
                                    name='validText'
                                    value={validText}
                                    onChange={(e) => {console.log(e);this.setState({validText: e.target.value})}}
                                >
                                    <Radio value='time'>固定时间</Radio>
                                    <Radio value='day'>相对时间</Radio>
                                </Radio.Group>
                            </Col>
                        </Row>
                        {validText === 'time' ?
                        <Row className="mb30" type="flex" align="middle">
                            <Col span={2}></Col>
                            <Col span={14}>
                                <Row type="flex" align="middle">
                                    <RangePicker onChange={this.timeChange} format="YYYY-MM-DD HH:mm:ss" placeholder={start_time !== '' ? [start_time, end_time] :["开始时间","结束时间"]} />
                                </Row>
                            </Col>
                        </Row>
                        : <Row className="mb30" type="flex" align="middle">
                            <Col span={2}></Col>
                            <Col span={14}>
                                <Row type="flex" align="middle">
                                    <span>自领取</span>
                                    <Input placeholder="0" maxLength={10} addonAfter="天" style={{ width: 120 }} type="number" className="mlr10" value={day} onChange={this.dayChange} />
                                    <span>内有效</span>
                                </Row>
                            </Col>
                        </Row>}
                    </div>
                    : null
                }

                {
                    type === '1'
                    ? <Row className="mb30" type="flex" align="middle">
                        <Col span={2}>
                            <div>
                                <span className="star"></span>
                                <span className="color_black">活动礼品</span>
                            </div>
                        </Col>
                        <Col span={2}>
                            <div className='plus_wrap'>
                                <Icon type="plus" style={{fontSize: 30, color: '#d4d4d4'}} onClick={this.showProductTable}/>
                            </div>
                        </Col>
                    </Row>
                    : <Row className="mb30" type="flex" align="middle">
                        <Col span={2}>
                            <div>
                                <span className="star"></span>
                                <span className="color_black">活动礼品</span>
                            </div>
                        </Col>
                        <Col span={16}>
                            <div onClick={this.showTable}>
                                <Icon type="plus-circle" style={{fontSize: '20px',marginRight: '10px'}} />
                                关联优惠券
                            </div>

                            {
                                selectCoupon && selectCoupon.length > 0
                                ? <Table
                                    size="middle"
                                    rowKey={"id"}
                                    dataSource={selectCoupon}
                                    columns={this.columnNum}
                                    style={{marginTop: '20px'}}
                                    pagination={
                                        {
                                            hideOnSinglePage: true,
                                            total: selectCoupon.length,
                                        }
                                    }
                                />
                                : null
                            }

                        </Col>
                    </Row>
                }

                {
                    type === '1' && favour.length > 0
                    ? <Row>
                        <Col offset={2}>
                            <div className='present_good good_title'>
                                <div>商品名</div>
                                <div>操作</div>
                            </div>
                            {
                                favour.map((item: any) => {
                                    return <div key={item.dish_id} className='present_good'>
                                            <div>{item.name}</div>
                                            <Button onClick={() => this.deleteDish(item.dish_id)}>删除</Button>
                                        </div>
                                })
                            }
                        </Col>
                    </Row>
                    : null
                }

                <Row className="mb30" type="flex" align="top">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">参与对象</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <Tree
                            checkable
                            showIcon
                            onCheck={this.onCheck}
                            defaultSelectedKeys={user_type}
                            checkedKeys={user_type}
                        >
                            <TreeNode title="新用户" key="1" icon={
                                <Tooltip placement="topLeft" title={"还未注册的用户或已经注册还没有下单的用户"}>
                                <Icon type="question-circle" />
                                </Tooltip>
                            }>
                            </TreeNode>
                            <TreeNode title="老顾客" key="4" icon={
                                <Tooltip placement="topLeft" title={"交易次数>=1次的交易次数"}>
                                <Icon type="question-circle" />
                                </Tooltip>
                            }>
                                <TreeNode title="充值用户" key="2" />
                                <TreeNode title="非充值用户" key="3" />
                            </TreeNode>
                        </Tree>
                    </Col>
                </Row>

                <div className='sub_title'>使用设置</div>
                {
                    type === '1'
                    ? <Row className="mb30" type="flex" align="middle">
                        <Col span={2}>
                            <div>
                                <span className="star"></span>
                                <span className="color_black">用餐人数</span>
                            </div>
                        </Col>
                        <Col span={6}>
                            <Input placeholder="请输入用餐人数" name='eat_users' value={eat_users} onChange={(e) => this.inputChange(e)}/>
                        </Col>
                    </Row>
                    : null
                }
                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">需要助力人数</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <Input placeholder="请输入助力人数" name='need_users' value={need_users} onChange={(e) => this.inputChange(e)}/>
                    </Col>
                </Row>
                {
                    type === '1'
                    ? <Row className="mb30" type="flex" align="middle">
                            <Col span={2}>
                                <div>
                                    <span className="star"></span>
                                    <span className="color_black">可获得份数</span>
                                </div>
                            </Col>
                            <Col span={6}>
                                <Input placeholder="请输入可获得份数" name='count' value={count} onChange={(e) => this.inputChange(e)}/>
                            </Col>
                        </Row>
                    : <Row className="mb30" type="flex" align="middle">
                            <Col span={2}>
                                <div>
                                    <span className="star"></span>
                                    <span className="color_black">拆券有效期</span>
                                </div>
                            </Col>
                            <Col style={{marginRight: 10}}>分享  </Col>
                            <Col span={2}>
                                <Input name='excrete_time' value={excrete_time} onChange={(e) => this.inputChange(e)}/>
                            </Col>
                            <Col style={{marginLeft: 10}}>小时助力不成功拆券无效</Col>
                        </Row>
                }

                {
                    type == '2'
                    ?<div className="wrap">
                        <Row>
                            <Col span={2}>
                                <div>
                                    <span className="star"></span>
                                    <span className="color_black">使用须知</span>
                                </div>
                            </Col>
                            <Col span={14}>
                                <div className="editor_wrap">
                                    <BraftEditor
                                        value={editorState}
                                        onChange={this.handleEditorChange}
                                        excludeControls={['media']}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    : null
                }

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">状态</span>
                        </div>
                    </Col>
                    <Col span={22}>
                        <Radio.Group
                            name='status'
                            value={status}
                            onChange={this.inputChange}
                        >
                            <Radio value={1}>开启</Radio>
                            <Radio value={0}>暂停</Radio>
                        </Radio.Group>
                    </Col>
                </Row>

                {/* <div className='sub_title'>分享设置</div>
                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">分享标题</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <Input placeholder="请输入分享标题" name='title' value={title} onChange={(e) => this.inputChange(e)}/>
                    </Col>
                </Row>
                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">分享简介</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <Input placeholder="请输入分享简介" name='introduction' value={introduction} onChange={(e) => this.inputChange(e)}/>
                    </Col>
                </Row>

                <Row className="mb30" type="flex" align="top">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">分享图片</span>
                        </div>
                    </Col>
                    <Col span={2}>
                        <Upload
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            action={QINIU_SERVER}
                            beforeUpload={this.beforeUpload}
                            onChange={this.handleChange}
                            data={this.state.updateObj}
                            >
                            {
                                imageUrl ?
                                <img src={imageUrl} alt="自定义图片" style={{ width: '100%' }} />
                                : <div className="">
                                    <Icon type={this.state.loading ? 'loading' : 'plus'} style={{fontSize: '40px'}}/>
                                </div>
                            }
                        </Upload>
                        <div>（建议尺寸：宽800像素*高640像素等比图片，大小不超过1MB）</div>
                    </Col>
                    <Col>
                            <Button onClick={this.reDefault}>恢复默认图</Button>
                    </Col>
                </Row> */}

                <Row>
                    <Col span={12}>
                        <div className="bottom_wrap">
                            <Button type="primary" ghost onClick={this.goBack}>
                                取消
                            </Button>
                            <Button type="primary" onClick={this.handleSubmit}>
                                保存
                            </Button>
                        </div>
                    </Col>
                </Row>

            <Modal
                title={null}
                visible={visible}
                onOk={() => {this.setState({
                    visible: false
                })}}
                onCancel={()=>{this.setState({visible: false})}}
                width="70vw"
            >
                <div className="model_head">
                    <div className="head_title">关联优惠券</div>
                </div>
                <Table
                    size="middle"
                    rowKey={"id"}
                    dataSource={selectList}
                    columns={this.columns}
                    pagination={
                        {
                            current,
                            total: listNum,
                            onChange: this.changePage,
                            pageSize: 10
                        }
                    }
                />
            </Modal>

            <Modal
                    title=""
                    visible={this.state.pVisible}
                    onOk={this.handleOk}
                    onCancel={() => {this.setState({pVisible: false})}}
                    >
                    <Row type="flex" align="middle" justify="start" className="mb20" >
                        <Col className="mr30">
                            <span>商品名称：</span>
                            <Input placeholder="请输入商品名称" onChange={this.searchAction} style={{ width: 234 }}/>
                        </Col>
                    </Row>

                    <Row type="flex" align="middle" justify="start" className="mb20" >
                        <Col className="">
                            <span>商品分类：</span>
                            <Select style={{ width: 150 }} onChange={this.sortChange} placeholder="请选择商品分类">
                                {
                                    this.state.sortList.map((item:any) => {
                                        return <Option key={item.id} value={item.id} >{item.name}</Option>
                                    })
                                }
                            </Select>
                        </Col>
                    </Row>

                    <div>
                        <Table
                        dataSource={this.state.showProductList}
                        columns={this.pColumns}
                        rowKey={"id"}
                        size="middle"
                        locale={{emptyText: '暂无数据'}}
                        rowSelection={this.rowSelection}
                        pagination={{
                            current: this.state.currentPage,
                            showQuickJumper:true,
                            defaultCurrent: 1,
                            total: this.state.productCount,
                            pageSize: this.state.pageSize,
                            hideOnSinglePage: true,
                            onChange: this.orderChange
                        }}/>
                    </div>
                </Modal>

        </div>;
    }

}
export default HelpEdit