import React from "react";
import { Table, Row, Col, Button, Select, DatePicker} from "antd";
import { getRequest } from '../../api/http';
import { apiUrl } from '../../api/api_url';
import { Link } from "react-router-dom";
import { getIsPermisson, messageCustom } from "../../utils/permisstionUtil";
const { RangePicker } = DatePicker;
const { Option } = Select;

const key = 'updatable';

class SendCoupon extends React.Component<any> {
    state = {
        equityList: [],
        current: 1,
        pageSize: 10,
        total_count: 0,
        userList: [],
        start_time: '',
        end_time: '',
        add_user: 0,
        name: ''
    }

    columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            className: 'list_center',
        },
        {
            title: '关联优惠券',
            dataIndex: 'coupon',
            key: 'coupon',
            className: 'list_center',
        },
        {
            title: '有效期',
            dataIndex: 'expire_info',
            key: 'expire_info',
            className: 'list_center',
            render: (text: string, record: any) => {
                return <div>{record.type == 'gift_bag' ? '--' : record.expire_info.type == 'day' ? '自领取日'+ record.expire_info.day + '天内' : record.expire_info.start_time + '-' + record.expire_info.end_time}</div>
            }
        },
        {
            title: '发行数量',
            dataIndex: 'amount',
            key: 'amount',
            className: 'list_center',
        },
        {
            title: '已使用',
            dataIndex: 'used_count',
            key: 'used_count',
            className: 'list_center btn_green',
            render: (rowData:any, record: any) => {
                return <div className='btn_green' onClick={() => {this.addGroup(rowData, record, 'use')}}>{rowData}</div>
            }
        },
        {
            title: '发放者',
            dataIndex: 'nickname',
            key: 'nickname',
            className: 'list_center',
        },
        {
            title: '创建时间',
            dataIndex: 'created_at',
            key: 'created_at',
            className: 'list_center',
        },
    ];

    componentDidMount(){
        this.onInitList();
    }

    // 初始化列表
    onInitList = async () => {
        let { current, pageSize, add_user, start_time, end_time } = this.state;
        const params:any = {
            current,
            pageSize,
        };
        if(add_user){
            params.add_user = add_user;
        }
        if(start_time !== ''){
            params.start_time = start_time;
            params.end_time = end_time;
        }
        const res:any = await getRequest(apiUrl.sendCouponList,params);
        if(res.code === 1){
            this.setState({
                equityList: res.data.list,
                total_count: res.data.total_count,
                userList: res.data.add_user
            })
        }
    }

    pageChange = async (page:number) => {
        this.setState({
            current: page
        }, () => {
            this.onInitList()
        })
    }

    userChange = (e: any) => {
        this.setState({
            add_user: e
        }, () => {
            this.onInitList();
        })
    }

    timeChange = (time: any, timeString: [any, any]) => {
        this.setState({
            start_time: timeString[0],
            end_time: timeString[1]
        }, () => {
            this.onInitList();
        })
    }

    // 跳转添加分组页面
    addGroup = (row:any, record: any, type: string) => {
        if(row > 0){
            this.props.history.push({
                pathname: '/sidebar/couponGroup',
                state: {
                    id: record.coupon_id,
                    type
                }
            });
        }
    }

    render(){
        let { equityList, total_count, pageSize, current, userList} = this.state;
        return <div className='l_content'>
             <Row className="mb30">
                <Col span={24}>
                    <Button type="primary" size="large" icon="plus" onClick={() =>{{
                        if(!getIsPermisson('1_12_6_1')){
                            messageCustom();
                            return;
                            }
                        this.props.history.push('/sidebar/sendCouponEdit')
                    }}}>
                            直接发券
                        </Button>
                </Col>
            </Row>

            <Row type="flex" align="middle" justify="start" className="mt20 mb40" >
                <Col className="mr30">
                    <span>发放者：</span>
                    <Select placeholder="请选择发放者" allowClear style={{ width: 150 }} onChange={this.userChange}>
                        {
                            userList && userList.map((item: any) => {
                                return <Option value={item.key} key={item.key}>{item.value}</Option>
                            })
                        }
                    </Select>
                </Col>
                <Col className="mr30">
                    <span>创建时间：</span>
                    <RangePicker onChange={this.timeChange} placeholder={["开始时间","结束时间"]} format="YYYY-MM-DD"/>
                </Col>
            </Row>

            <div>
                <Table dataSource={equityList}  size="middle" columns={this.columns} rowKey={'id'} locale={{emptyText: '暂无数据'}} pagination={{
                    total: total_count,
                    hideOnSinglePage: true,
                    pageSize,
                    onChange: this.pageChange,
                    current,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    showTotal: (total: number) => `共${total}条`,
                    onShowSizeChange: (current:number, size: number) => {this.setState({pageSize: size}, () => {this.onInitList();})}
                }} />
            </div>
        </div>;
    }
}
export default SendCoupon