import React from "react";
import {Button, Input, Icon, Upload, message, Row, Col, Select, Radio, Tooltip, Checkbox, Modal, Table} from "antd";
import {postRequest, getRequest} from '../../api/http';
import GoodAddType from './components/goodsAddType'
import {apiUrl} from '../../api/api_url';
import btn_add from '../../assret/images/btn_add.png'
import i_add_icon from '../../assret/images/i_add_icon.png'
import t_delete from '../../assret/images/t_delete.png'
import goodPhoto1 from '../../assret/images/goodPhoto1.png'
import goodPhoto2 from '../../assret/images/goodPhoto2.png'
import goodPhoto3 from '../../assret/images/goodPhoto3.png'
import goodPhoto4 from '../../assret/images/goodPhoto4.png'
import goodPhoto5 from '../../assret/images/goodPhoto5.png'
import good_reduce from '../../assret/images/good_reduce.png'
import set_add_disabled from '../../assret/images/set_add.png'
import goodEditDel from '../../assret/images/goodEditDel.png'
import {Link} from "react-router-dom";
import './goods.scss';
import TextArea from "antd/lib/input/TextArea";
import AddMaterialCom from "../material/addMaterial/addMaterial";

import {onlyNumbers, onlyStrings} from '../../utils/utils';

const {Option} = Select;
const {Search} = Input;

interface specs {
    name: string
    value: [
        {
            name: string
        }
        ]
}

interface attrItem {
    price: number,
    price_vip: number,
    pic: string,
    pic_url?: string
}

function getBase64More(file: any) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

const QINIU_SERVER = 'https://up-z2.qiniup.com';
const key = 'updatable';
const baseUrl = 'https://ydn-siyu.cdn.shuachi.com/';

class GoodsEdit extends React.Component<any> {

    state = {
        loading: false,
        serial_number: 0,
        name: '',
        definition: '',
        cat_id: undefined,
        cat_name: '',
        main_pic: '',
        people_num_eat: 0,
        left_top_tips: '',
        right_top_tips: '',
        order_by: 0,
        stock_type: 'normal',  //specs   normal
        price: 0,
        price_vip: 0,
        stock: 0,
        imageUrl: '',
        imageObj: {
            key: '',
            url: ''
        },
        sortList: [],
        previewVisible: false,
        previewImage: '',
        updateObj: {
            key: '',
            tokens: '',
            region: '',
            'x:type': ''
        },
        specs_type: [],
        attribute: [],
        is_no_discount: true,
        is_must_eat: false,
        dep_id: undefined,
        onIsTwoPrintView: false,
        onTwoPrintId: undefined,
        onIsThreePrintView: false,
        onThreePrintId: undefined,
        printList: [],
        shop_flavor: true,
        shop_specs_list: [], //口味列表
        more_set: true, //展开更多设置
        more_txt: '展开更多设置',
        type_num: 1, //1：菜品，2：套餐
        dep_name: '',
        add_type_statue: false, //添加分类
        display_mode: 1, //展示方式
        goodPhotoOne: 0,
        definitions: '份',
        definitions_list: [], //单位列表
        is_definitions: false, //单位下拉列表
        product_info: '', //商品详情
        is_must_mini: true, //是否在小程序端显示
        is_sell_out: false, //沽清是否在收银台可点
        is_weigh: false, //是否是称重菜
        start_num: 1, //起售份数
        single_operate_num: 1, //起售份数：默认是1
        has_weigh: false, //售价是否有规格
        weigh_value: {
            weighName: '',
            value: [
                {
                    name: '',
                    price: '',
                    price_vip: ''
                }
            ]
        }, //售价规格数组
        is_vip_set: false, //是否设置会员价
        add_material_visible: false, // 加料展示模态框
        currentPage: 1,
        count: 0,
        pageSize: 10, // 加料展示模态框
        dataMaterialList: [], // 店铺加料列表
        ids: [],
        selIds: [], // 选择加料
        kw: "",// 加料搜索
        material_info: [], // 商品绑定加料名称
        must_eat_qty: 1, // 开台必点 自定义数量和按就餐人数
        materialIdMap: {}, // 加料id对应加料数据映射
        addVisible: false, // 是否展示添加加料
        is_order_limit: 1,//是否设置开桌限点
        order_limit_num: 0//单桌限点份数
    }

    componentDidMount() {
        const {location} = this.props;
        if (location.state !== undefined) {
            console.log('location:', location)
            this.initEdit(location.state.id);
        } else {
            this.onInitSort();
        }

        this.onChangeAttr(this.state.specs_type);
        this.printerList();
        this.getDefinitions();
    }

    onChangeAttr = (specs_type: any) => {
        let list = specs_type;
        let {price, price_vip} = this.state;
        if (specs_type.length <= 0) {
            return;
        }
        let arr: any = [];
        if (list[0].value.length <= 0) {
            return;
        }

        if (list.length === 1) {
            list[0].value.forEach((item: specs) => {
                arr.push({
                    'name': item.name,
                    'price': price,
                    'price_vip': price_vip,
                    'pic': ''
                })
            });
        } else if (list.length === 2) {
            list[0].value.forEach((item: specs) => {
                list[1].value.forEach((child: specs) => {
                    arr.push({
                        'name': item.name + "_" + child.name,
                        'price': price,
                        'price_vip': price_vip,
                        'pic': ''
                    })
                })
            });
        } else if (list.length === 3) {
            list[0].value.forEach((item: specs) => {
                list[1].value.forEach((child: specs) => {
                    list[2].value.forEach((second: specs) => {
                        arr.push({
                            'name': item.name + "_" + child.name + "_" + second.name,
                            'price': price,
                            'price_vip': price_vip,
                            'pic': ''
                        })
                    })
                })
            });
        }

        this.setState({
            attribute: arr
        })
    }

    // 打印部门列表
    printerList = async () => {
        const res: any = await getRequest(apiUrl.printDepartList);
        if (res.code === 1) {
            this.setState({
                printList: res.data,
            })
        }
    }

    // 初始化编辑
    initEdit = async (id: number) => {
        const params = {
            id: id
        };
        const res: any = await getRequest(apiUrl.goodsInfo, params);
        if (res.code === 1) {
            const resData = res.data;
            console.log(resData.price)
            console.log(resData.m)
            this.setState({
                price: resData.price,
                serial_number: resData.serial_number,
                name: resData.name,
                definition: resData.definition,
                cat_id: resData.cat_id,
                cat_name: resData.cat_name,
                order_by: resData.order_by,
                is_no_discount: resData.is_no_discount,
                imageUrl: baseUrl + resData.main_pic,
                type_num: resData.type,
                imageObj: {
                    key: resData.main_pic,
                    url: baseUrl + resData.main_pic
                },
                dep_id: resData.dep_id && resData.dep_id != 0 ? resData.dep_id : undefined,
                onTwoPrintId: resData.on_two_print_id && resData.on_two_print_id != 0 ? resData.on_two_print_id : undefined,
                onIsTwoPrintView: resData.on_two_print_id && resData.on_two_print_id != 0 ? true : false,
                onThreePrintId: resData.on_three_print_id && resData.on_three_print_id != 0 ? resData.on_three_print_id : undefined,
                onIsThreePrintView: resData.on_three_print_id && resData.on_three_print_id != 0 ? true : false,
                // dep_id: resData.dep_id || '请选择打印部门',
                dep_name: resData.dep_name,
                display_mode: resData.show_type,
                definitions: resData.definition,
                product_info: resData.info,
                is_must_mini: resData.xcx_display,
                is_sell_out: resData.zero_order,
                is_weigh: resData.is_weigh,
                start_num: resData.start_num,
                single_operate_num: resData.single_operate_num,
                has_weigh: resData.has_weigh,
                is_vip_set: resData.is_vip_set,
                must_eat_qty: resData.must_eat_qty,
                is_order_limit: resData.is_order_limit,
                order_limit_num: resData.order_limit_num,

            }, () => {
                this.onInitSort(this.props.location.state.type);
            })
            if (resData.has_weigh) {
                this.setState({
                    weigh_value: resData.weigh_value
                })
            }
            if (resData.people_num_eat !== null) {
                this.setState({
                    people_num_eat: resData.people_num_eat
                })
            }
            if (resData.left_top_tips.tips !== null) {
                this.setState({
                    left_top_tips: resData.left_top_tips.tips
                })
            }
            if (resData.right_top_tips.tips !== null) {
                this.setState({
                    right_top_tips: resData.right_top_tips.tips
                })
            }
            //设置vip价格
            if (resData.price_vip !== null && resData.price_vip !== '0.00' && resData.is_vip_set) {
                this.setState({
                    price_vip: resData.price_vip,
                })
            }
            if (Array.isArray(resData.specs_info)) {
                this.setState({
                    price: resData.price,
                    stock: resData.price_vip
                })

            } else {
                if (resData.specs_info !== null) {
                    this.setState({
                        shop_flavor: false,
                        specs_type: resData.specs_info.specs_type,
                        stock_type: 'specs',
                        attribute: resData.specs_info.attribute
                    })

                } else {
                    this.setState({
                        price: resData.price,
                        stock: resData.price_vip
                    })

                }
            }
            // 开台必点
            if (!resData.is_must_eat) {
                this.setState({
                    is_must_eat: resData.is_must_eat, //不设置
                })
            } else if (resData.is_must_eat && resData.must_eat_qty != 0) {
                this.setState({
                    is_must_eat: 2, // 按自定义人数设置
                })
            } else {
                this.setState({
                    is_must_eat: true, // 按人数设置
                })
            }

            //单桌某个菜品限点份数配置
            if (resData.is_order_limit == 1) {
                this.setState({
                    is_order_limit: 1, // 单桌不限制点菜份数
                    order_limit_num: 0, // 单桌限点份数
                })
            } else {
                this.setState({
                    is_order_limit: 2, // 单桌限制点菜份数
                    order_limit_num: resData.order_limit_num, // 单桌限制点菜份数
                })
            }

            // 加料数据
            if (Array.isArray(resData.material_info)) {
                // 1. 选择加料列表
                const selIds: any = []
                const materialIdMap: any = this.state.materialIdMap
                resData.material_info.map((item: any) => {
                    selIds.push(item.id)
                    materialIdMap[item.id] = item
                })
                this.setState({
                    selIds,
                    ids: selIds,
                    materialIdMap,
                    material_info: resData.material_info
                })
            }

        }
    }

    // 分类列表
    onInitSort = async (type = 1) => {
        const params: any = {
            pageSize: 100,
            type
        };
        const res: any = await getRequest(apiUrl.goodSort, params);
        if (res.code === 1) {
            this.setState({
                sortList: res.data
            })
        }
    }

    // 编号
    serialChange = (e: any) => {
        this.setState({
            serial_number: onlyNumbers(e.target.value)
        })
    }

    nameChange = (e: any) => {
        this.setState({
            name: e.target.value
        })
    }

    // 单位
    definitionChange = (e: any) => {
        this.setState({
            definition: onlyStrings(e.target.value)
        })
    }

    // 商品分类
    typeChange = (e: any) => {
        this.setState({cat_id: e});
    }

    // 推荐人数
    personChange = (e: any) => {
        this.setState({
            people_num_eat: e.target.value.trim()
        })
    }

    // 左上角标签
    leftChange = (e: any) => {
        this.setState({
            left_top_tips: e.target.value.trim()
        })
    }

    // 右上角标签
    rightChange = (e: any) => {
        this.setState({
            right_top_tips: e.target.value.trim()
        })
    }

    priceChange = (e: any) => {
        this.setState({
            price: e.target.value  //onlyNumbers
        })
    }

    priceVipChange = (e: any) => {
        this.setState({
            price_vip: e.target.value
        })
    }

    stockChange = (e: any) => {
        this.setState({
            stock: e.target.value.trim()
        })
    }

    // 排序
    orderChange = (e: any) => {
        this.setState({
            order_by: e.target.value.trim()
        })
    }

    // 是否开台必点
    musteatChange = (e: any) => {
        console.log("----------", e)
        this.setState({
            is_must_eat: e
        })
    }

    // 单桌限点份数
    isOrderLimitChange = (e: any) => {
        console.log("----------", e)
        this.setState({
            is_order_limit: e
        })
    }

    // 单桌限点份数
    orderLimitChange = (e: any) => {
        console.log("----------", e)
        this.setState({
            order_limit_num: e
        })
    }

    // 是否小程序端显示
    miniShowChange = (e: any) => {
        this.setState({
            is_must_mini: e
        })
    }

    // 是否在收银台可点
    miniSelOutChange = (e: any) => {
        this.setState({
            is_sell_out: e
        })
    }

    //展示方式
    goodPhotoChange = (e: any) => {
        this.setState({
            display_mode: e
        })
    }

    //商品口味
    shopFlavor = (e: any) => {
        this.setState({
            shop_flavor: e
        })
    }

    // 是否可打折
    discountChange = (e: any) => {
        this.setState({
            is_no_discount: e
        })
    }

    //通用radio方法
    onCurrencyChange = (e: any) => {
        let name: any = e.target.name;
        let value: any = e.target.value;
        this.setState({
            [name]: value,
        })
    }

    // 添加规格
    addSpecs = () => {
        let specs_type: specs[] = this.state.specs_type;
        if (specs_type.length >= 3) {
            message.warning({content: '最多添加3个口味!', key, duration: 2});
            return;
        }

        specs_type.push(
            {
                'name': '',
                'value': [
                    {
                        'name': ''
                    }
                ]
            }
        )
        this.setState({
            stock_type: 'specs',
            specs_type
        })
        this.onChangeAttr(specs_type);
    }

    addSpecsMore = (idx: number) => {
        let specs_type: any = this.state.specs_type;
        specs_type[idx].value.push({'name': ''});
        this.setState({
            specs_type
        })
        this.onChangeAttr(specs_type);
    }

    // 删除某一个规格
    deleteItem = (idx: number) => {
        let specs_type: any = this.state.specs_type;
        specs_type.splice(idx, 1);

        this.setState({
            specs_type
        })
        if (specs_type.length <= 0) {
            this.setState({
                stock_type: 'normal'
            })
        } else {
            this.onChangeAttr(specs_type);
        }
    }

    // 删除子规格
    deleteChild = (idx: number, index: number) => {
        let specs_type: any = this.state.specs_type;
        specs_type[idx].value.splice(index, 1);
        this.setState({
            specs_type
        })
        this.onChangeAttr(specs_type);
    }

    itemChange = (e: any, idx: number) => {
        let specs_type: any = this.state.specs_type;
        specs_type[idx].name = e.target.value;
        this.setState({
            specs_type
        })
        //口味列表
        //this.shopSpecsList(e.target.value);
        this.onChangeAttr(specs_type);
    }

    //口味列表
    async shopSpecsList(str: any) {
        const res: any = await getRequest(apiUrl.specsTypeList, {keyword: str});
        if (res.code === 1) {
            this.setState(() => {
                return {
                    shop_specs_list: res.data.list
                }
            })
        }
    }

    childChange = (e: any, idx: number, index: number) => {
        console.log('e:', e)
        let specs_type: any = this.state.specs_type;
        specs_type[idx].value[index].name = e.target.value;
        this.setState({
            specs_type
        })
        this.onChangeAttr(specs_type);
    }

    labelPrice = (e: any, index: number) => {
        let attribute: attrItem[] = this.state.attribute;
        attribute[index].price = e.target.value;
        this.setState({attribute});
    }

    labelPriceVip = (e: any, index: number) => {
        let attribute: attrItem[] = this.state.attribute;
        attribute[index].price_vip = e.target.value;
        this.setState({attribute});
    }

    // 获取图片token
    beforeUpload = async (file: any) => {
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
            this.setState({
                loading: false,
            }, () => {
                message.error(file.name + "图片大小超出限制，请修改后重新上传");
                return;
            })
        } else {
            const res: any = await getRequest(apiUrl.getUploadToken, {type: 'food'});
            if (res && res.data) {
                const key = `food/${Math.random().toString(36).slice(-8)}${(new Date()).valueOf()}.png`
                this.setState({
                    updateObj: {
                        token: res.data.upload_token,
                        region: `https://up-z2.qiniu.com`,
                        key: key,
                        'x:type': "food"
                    }
                })
            }
        }
    }

    // 商品头图上传
    handleChange = (info: any) => {
        const isLt5M = info.file.size / 1024 / 1024 < 5;
        if (isLt5M) {
            if (info.file.status === 'uploading') {
                this.setState({loading: true});
                return;
            }
            if (info.file.status === 'done') {
                this.setState({
                    imageUrl: info.file.response.url,
                    imageObj: info.file.response,
                    loading: false,
                })
            }
        }
    };

    labelPicChange = (info: any, index: number) => {
        if (info.file.status === 'uploading') {
            this.setState({loading: true});
            return;
        }

        if (info.file.status === 'done') {
            let attribute: attrItem[] = this.state.attribute;
            attribute[index].pic = info.file.response.key;
            attribute[index].pic_url = info.file.response.url;
            this.setState({attribute, loading: false,});
        }
    }

    // 图片预览
    handleCancel = () => this.setState({previewVisible: false});

    handlePreview = async (file: any) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64More(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
        });
    };

    // 打印机部门修改
    printerChange = (e: any) => {
        console.log('print:', e)
        // if(!!e) {
        this.setState({
            dep_id: e
        })
        // } else {
        // this.setState({
        //     dep_id: 0,
        //     dep_name: ''
        // })
        // }
    }

    //打印机清除
    printClear = (e: any) => {
        console.log('print:', e)
    }

    handleSubmit = async (e: any, typeAdd: any) => {
        message.loading({content: '发布中', key});
        e.preventDefault();

        let goodInfo: any = {};
        const {location} = this.props;
        if (location.state !== undefined) {
            goodInfo.id = location.state.id;
        }
        const {is_vip_set, has_weigh, weigh_value, single_operate_num, start_num, is_weigh, is_sell_out, is_must_mini, product_info, display_mode, shop_flavor, serial_number, name, definitions, cat_id, people_num_eat, left_top_tips, right_top_tips, order_by, stock_type, price, price_vip, imageUrl, imageObj, is_no_discount, is_must_eat, dep_id, ids, is_order_limit, order_limit_num} = this.state;
        let flag = true;
        const attribute: attrItem[] = this.state.attribute;

        if (name === undefined || name === '') {
            message.warning({content: '请输入商品名称!', key, duration: 2});
            return;
        }

        if (cat_id === 0) {
            message.warning({content: '请选择商品分类!', key, duration: 2});
            return;
        }

        if (imageUrl === null || imageUrl === '') {
            message.warning({content: '请选择商品头图!', key, duration: 2});
            return;
        }

        if (stock_type !== 'normal') {
            this.state.specs_type.forEach((item: specs) => {
                if (item.name === undefined || item.name === '' || item.name === null) {
                    flag = false;
                }
                item.value.forEach(child => {
                    if (child.name === undefined || child.name === '' || child.name === null) {
                        flag = false;
                    }
                });
            });
            if (!flag) {
                message.warning({content: '请输入商品库存或规格值或价格!', key, duration: 2});
                return;
            }
        }
        if (!has_weigh) {
            if (price == 0) {
                message.warning({content: '请输入商品价格!', key, duration: 2});
                return;
            }
        }
        goodInfo.is_vip_set = is_vip_set;//是否设置会员价
        // if(!dep_id || dep_id === 0){
        //     message.warning({ content: '请选择打印机部门!', key, duration: 2 });
        //     return;
        // }
        //是否选择口味
        if (!shop_flavor) {
            goodInfo.has_specs = true;
        }
        goodInfo.price = price;
        goodInfo.serial_number = serial_number;
        goodInfo.name = name;
        goodInfo.definition = definitions;
        goodInfo.cat_id = cat_id;
        goodInfo.is_no_discount = is_no_discount;

        goodInfo.xcx_display = is_must_mini;
        goodInfo.show_type = display_mode; //展示方式
        goodInfo.zero_order = is_sell_out; //沽清是否在收银台可点
        goodInfo.is_weigh = is_weigh; //称重菜
        goodInfo.start_num = start_num; //起售份数
        goodInfo.single_operate_num = single_operate_num; //小程序添加菜品是+还是*
        goodInfo.is_order_limit = is_order_limit;
        goodInfo.order_limit_num = order_limit_num;
        // 开台必点
        if (Number(is_must_eat) == 1) {
            goodInfo.is_must_eat = true;
            goodInfo.must_eat_qty = 0;
        } else if (Number(is_must_eat) == 0) {
            goodInfo.is_must_eat = false;
            goodInfo.must_eat_qty = 0;
        } else if (Number(is_must_eat) == 2) {
            goodInfo.is_must_eat = true;
            if (this.state.must_eat_qty <= 0) {
                message.error("自定义开台数不能小于等于0")
                return
            }
            goodInfo.must_eat_qty = this.state.must_eat_qty;
        }
        // 加料ids
        goodInfo.material_ids = ids
        if (people_num_eat !== 0) {
            goodInfo.people_num_eat = people_num_eat;
        }
        if (left_top_tips !== '') {
            goodInfo.left_top_tips = left_top_tips;
        }
        if (right_top_tips !== '') {
            goodInfo.right_top_tips = right_top_tips;
        }
        goodInfo.order_by = order_by;

        if (price_vip !== 0) {
            goodInfo.price_vip = price_vip;
        }
        if (stock_type !== 'normal') {
            //价格
            let temp = attribute;
            attribute.map((item: any, index: any) => {
                temp[index].price = price
                temp[index].price_vip = price_vip
            })
            goodInfo.specs_info = {
                specs_type: this.state.specs_type,
                attribute: temp
            }
        }

        if (imageUrl !== null && imageUrl !== '') {
            goodInfo.main_pic = imageObj.key;
        }

        //商品详请
        if (product_info != '') {
            goodInfo.info = product_info
        }

        //打印机
        if (dep_id || dep_id !== 0) {
            goodInfo.dep_id = dep_id;
        }
        //第二打印机
        if (this.state.onTwoPrintId || this.state.onTwoPrintId !== 0) {
            goodInfo.on_two_print_id = this.state.onTwoPrintId;
        }
        //第三打印机
        if (this.state.onThreePrintId || this.state.onThreePrintId !== 0) {
            goodInfo.on_three_print_id = this.state.onThreePrintId;
        }

        //售价是否有规格
        if (has_weigh) {
            let flag = false;
            if (weigh_value.weighName == '') {
                message.info({content: '请填写规格名称!', key, duration: 2});
                return;
            }
            let val_list: any = this.state.weigh_value.value;
            val_list.map((item: any, index: any) => {
                for (const key in item) {
                    if (key != 'price_vip' && item[key] == '') {
                        flag = true
                    }
                }
            })
            if (flag) {
                message.info({content: '规格分类或者售价不能为空!', key, duration: 2});
                return;
            }

            goodInfo.weigh_value = weigh_value;
            goodInfo.has_weigh = has_weigh;
        }

        //goodInfo.dep_id = dep_id;;

        console.log('goodInfo:', goodInfo)
        // return;

        if (location.state !== undefined) {
            const res: any = await postRequest(apiUrl.goodEdit, goodInfo);
            if (res['code'] === 1) {
                message.success({content: '修改成功!', key, duration: 2});
                if (typeAdd == 'add') {
                    this.props.history.goBack();
                } else {
                    window.location.reload()
                }
            } else {
                message.error({content: '修改失败!', key, duration: 2});
            }
        } else {
            const res: any = await postRequest(apiUrl.goodAdd, goodInfo);
            if (res['code'] === 1) {
                message.success({content: '发布成功!', key, duration: 2});
                if (typeAdd == 'add') {
                    this.props.history.goBack();
                } else {
                    window.location.reload()
                }
            } else {
                message.error({content: '发布失败!', key, duration: 2});
            }
        }
    };
    // 返回上一页
    goBack = () => {
        this.props.history.push('/sidebar/goodsList');
    }

    addItem = () => {
        console.log('addItem');
        const {shop_specs_list, name} = this.state;
        this.setState({
            shop_specs_list: [...shop_specs_list, name || `New item ${1}`],
            name: '',
        });
    };

    onMoreSet = () => {
        this.setState(() => {
            return {
                more_txt: !this.state.more_set ? '展开更多设置' : '收起更多设置',
                more_set: !this.state.more_set
            }
        })
    }

    //添加分类
    onAddType = () => {
        //this.props.history.push('/sidebar/GoodsAdd')
        this.setState({
            add_type_statue: !this.state.add_type_statue
        })
    }

    // 输入类型
    inputChange = (e: any) => {
        console.log(e)
        let name = e.target.name;
        let value = e.target.value.trim();
        console.log('++++++++++++++', name)
        this.setState({
            [name]: value
        })
    }

    //选中单位只能输入数字
    selectDefinitions = (e: any) => {
        let name = e.target.name;
        let value = onlyStrings(e.target.value);
        this.setState({
            [name]: value
        })
    }

    //获取单位
    getDefinitions = async () => {
        const res: any = await getRequest(apiUrl.getDefinitions);
        if (res.code === 1) {
            this.setState(() => {
                return {
                    definitions_list: res.data
                }
            })
        }
    }

    //选中单位
    changeDefinition = (e: any, val: any) => {
        console.log(val)
        e.stopPropagation();
        this.setState({
            definitions: val,
            is_definitions: false
        })
    }

    onHideDefinitions = (e: any) => {
        e.stopPropagation();
        console.log('2')
        this.setState({
            is_definitions: false
        })
    }

    onInputClick = (e: any) => {
        e.stopPropagation();
        this.setState({
            is_definitions: true
        })
    }

    //商品详情
    onInfoChange = (e: any) => {
        this.setState({
            product_info: e.target.value
        })
    }

    //起售份数
    onFromSaleNum = (e: any, type: any) => {
        let start_num: any = this.state.start_num;
        let single_operate_num: any = this.state.single_operate_num;
        let must_eat_qty: any = this.state.must_eat_qty;
        if (type == 'sale') {
            if (e === 'add') {
                start_num = Number(start_num) + 1;
                this.setState({
                    start_num,
                })
            } else {
                if (start_num <= 1) {
                    message.info('起售份数不能小于0')
                    return false;
                }
                start_num = parseFloat((Number(start_num) - 1).toFixed(2));
                this.setState({
                    start_num,
                })
            }
        } else if (type == 'material') {
            if (e === 'add') {
                must_eat_qty = Number(must_eat_qty) + 1;
                this.setState({
                    must_eat_qty,
                })
            } else {
                if (must_eat_qty <= 1) {
                    message.info('人数不能为0')
                    return false;
                }
                must_eat_qty = parseFloat((Number(must_eat_qty) - 1).toFixed(2));
                this.setState({
                    must_eat_qty,
                })
            }
        } else {
            if (e === 'add') {
                single_operate_num = Number(single_operate_num) + 1;
                this.setState({
                    single_operate_num,
                })
            } else {
                if (single_operate_num <= 1) {
                    message.info('单次加菜叠加不能小于0')
                    return false;
                }
                single_operate_num = parseFloat((Number(single_operate_num) - 1).toFixed(2));
                this.setState({
                    single_operate_num,
                })
            }
        }
    }

    //销售价格
    changeCurrency = (e: any) => {
        let name = e.target.name;
        let val = e.target.value;
        this.setState({
            [name]: val
        })
    }

    //是否设置会员价
    onIsSetPriceVip = (e: any) => {
        console.log('e.target.checked:', e.target.checked)
        this.setState({
            is_vip_set: e.target.checked
        })
    }

    //添加售价规格
    addPriceSpace = () => {
        let weigh_value: any = this.state.weigh_value;
        weigh_value.value.push(
            {
                name: '',
                price: '',
                price_vip: ''
            }
        )
        this.setState({
            weigh_value
        })
    }

    //删除所有规格

    //设置第二台打印机
    onIsTwoPrint = () => {
        this.setState({
            onIsTwoPrintView: !this.state.onIsTwoPrintView
        })
    }
    onPrinterTwoChange = (e: any) => {
        console.log('print:', e)
        this.setState({
            onTwoPrintId: e
        })
    }
    //设置第二台打印机
    onIsThreePrint = () => {
        this.setState({
            onIsThreePrintView: !this.state.onIsThreePrintView
        })
    }
    onPrinterThreeChange = (e: any) => {
        console.log('print:', e)
        this.setState({
            onThreePrintId: e
        })
    }


    //删除售价规格
    delChangePriceSpace = (idx: any) => {
        let weigh_value: any = this.state.weigh_value;
        weigh_value.value.splice(idx, 1)
        this.setState({
            weigh_value
        })
    }

    //修改售价规格
    updateSpace = (e: any, idx: any, type: any) => {
        let weigh_value: any = this.state.weigh_value;
        let val = e.target.value;

        if (type === 'weighVal') {
            weigh_value.weighName = val;
            this.setState({
                weigh_value
            })
        }

        if (type === 'name') {
            let weigh_obj = weigh_value.value[idx];
            weigh_obj['name'] = val;
            weigh_value.value[idx] = weigh_obj;
            this.setState({
                weigh_value
            })
        }

        if (type === 'price') {
            let weigh_obj = weigh_value.value[idx];
            weigh_obj['price'] = (val);  //onlyNumbers
            weigh_value.value[idx] = weigh_obj;
            this.setState({
                weigh_value
            })
        }

        if (type === 'price_vip') {
            let weigh_obj = weigh_value.value[idx];
            weigh_obj['price_vip'] = (val);  //onlyNumbers
            weigh_value.value[idx] = weigh_obj;
            this.setState({
                weigh_value
            })
        }


    }

    // 添加加料按钮
    addMaterial = async () => {
        const page = this.state.currentPage
        const pageSize = this.state.pageSize
        // 获取店铺加料数据加料
        await this.getShopMaterialList(page, pageSize)
        this.setState({
            add_material_visible: true
        })
    }

    // 获取店铺加料列表
    getShopMaterialList = async (page?: any, pageSize?: any, kw?: any) => {
        const res: any = await getRequest(apiUrl.getMaterialList, {"page": page, "pagesize": pageSize, 'kw': kw})
        if (res.code === 1) {
            const data: any = res.data
            const materialIdMap: any = this.state.materialIdMap
            // 分页数据append 到数组中

            data['list'].map((item: any) => {
                materialIdMap[item.id] = item
            })
            this.setState({
                dataMaterialList: data['list'], // 加料列表数据
                count: data['count'], // 加料总条数
                materialIdMap,
            })
            return res.data
        } else {
            message.error("服务器请求数据失败")
            return
        }
    }


    addMaterialHandleOk = () => {
        const selIds: any = this.state.selIds
        // 商品绑定加料数据
        const material_info: any = []
        const materialIdMap: any = this.state.materialIdMap
        selIds.map((item: any) => {
            material_info.push(materialIdMap[item])
        })

        this.setState({
            add_material_visible: false,
            ids: selIds, // 更新绑定商品加料id
            material_info, // 更新商品绑定加料数据

        })
    }

    addMaterialHandleCancel = () => {
        const material_info: any = this.state.material_info
        const selIds: any = []
        material_info.map((item: any) => {
            selIds.push(item.id)
        })
        this.setState({
            add_material_visible: false,
            selIds
        })
    }

    pageChangeMaterial = (page: number) => {
        console.log('页面索引')
        const pageSize = this.state.pageSize
        // 获取店铺加料列表数据
        // 更新页面数据
        this.getShopMaterialList(page, pageSize)
        this.setState({
            currentPage: page
        })
    }

    onSizeChangeMaterial = () => {
        console.log('页面数量')
        const pageSize = this.state.pageSize
        const page = 1
        // 更新页面数据
        this.getShopMaterialList(page, pageSize)
        this.setState({
            currentPage: page,
        })
    }

    // 搜索按钮
    searchBtn = async (val: any) => {
        await this.getShopMaterialList(null, null, val)
        // this.setState({
        //     kw:""
        // })
    }

    searchInput = (e: any) => {
        const kw = e.target.value
        this.setState({
            kw
        })
    }


    // 删除已经绑定加料id
    deleteBtnClick = async (index: any) => {
        const material_info: any = this.state.material_info
        // 删除数组数据
        material_info.splice(index, 1)
        // 更新ids
        const ids: any = []
        material_info.map((item: any) => {
            ids.push(item.id)
        })
        this.setState({
            material_info,
            ids,
            selIds: ids
        })
    }

    // 子组件通过调用父组件更新组件状态,子组件取消后，addVisible:false
    onUpdateaddVisible = async () => {
        await this.getShopMaterialList()
        this.setState({
            addVisible: false,
            add_material_visible: true
        })
    }

    render() {
        const {is_vip_set, has_weigh, cat_id, single_operate_num, start_num, is_weigh, is_sell_out, is_must_mini, product_info, is_definitions, definitions_list, definitions, goodPhotoOne, display_mode, add_type_statue, more_txt, more_set, shop_specs_list, shop_flavor, serial_number, name, definition, people_num_eat, cat_name, left_top_tips, right_top_tips, order_by, price, price_vip, imageUrl, sortList, stock_type, specs_type, attribute, is_no_discount, is_must_eat, printList, dep_name, dep_id, must_eat_qty, selIds, is_order_limit, order_limit_num} = this.state;
        const weigh_value: any = this.state.weigh_value;

        const columns = [
            {
                title: '加料名称',
                dataIndex: 'material_name',
            },
            {
                title: '售价（元）',
                dataIndex: 'material_price',
            }
        ];

        const materialColumns = [
            {
                title: '加料名称',
                dataIndex: 'material_name',
            },
            {
                title: '价格',
                dataIndex: 'material_price',
            },
            {
                title: '操作',
                width: 100,
                dataIndex: 'action',
                render: (text: any, record: any, index: number) => (
                    <div className="btn_wrap">
                        <Tooltip placement="bottomRight" title={'删除'}>
                            <img src={t_delete} className='table_icon' onClick={() => this.deleteBtnClick(index)}></img>
                        </Tooltip>
                    </div>
                ),
            }
        ];

        // 行选择
        const rowSelectionMaterial: any = {
            selectedRowKeys: selIds,
            onChange: (selectedRowKeys: any, selectedRows: any) => {

                this.setState({
                    selIds: selectedRowKeys,
                })
            },
        }

        return <div className='l_content' onClick={(e) => {
            this.onHideDefinitions(e)
        }}>
            <div>
                {
                    add_type_statue
                        ? <GoodAddType entry='1' onAddType={this.onAddType} onShopSort={this.onInitSort}></GoodAddType>
                        : <div>
                            <div className="edit_title">商品信息</div>
                            <Row className="mb30" type="flex" align="middle">
                                <Col span={2}>
                                    <div>
                                        <span className="star"></span>
                                        <span className="color_black">商品图片</span>
                                    </div>
                                </Col>
                                <Col span={14}>
                                    <Upload
                                        listType="picture-card"
                                        className="avatar-uploader"
                                        showUploadList={false}
                                        action={QINIU_SERVER}
                                        beforeUpload={this.beforeUpload}
                                        onChange={this.handleChange}
                                        data={this.state.updateObj}
                                    >
                                        {
                                            imageUrl ?
                                                <img src={imageUrl} alt="商品图片" style={{width: '100%'}}/>
                                                : <div className="">
                                                    <Icon type={this.state.loading ? 'loading' : 'plus'}
                                                          style={{fontSize: '40px'}}/>
                                                </div>
                                        }
                                    </Upload>
                                    <div>（若分类展示样式为横图，建议上传封面图宽高为558*308；若分类展示样式为竖图尺寸宽高为266*414；上传图片大小5M以内）</div>
                                </Col>
                            </Row>
                            <Row className="mb30" type="flex" align="middle">
                                <Col span={2}>
                                    <div>
                                        <span className="star"></span>
                                        <span className="color_black">商品编号</span>
                                    </div>
                                </Col>
                                <Col span={6}>
                                    <Input placeholder="请输入商品编号" value={serial_number}
                                           onChange={(e) => this.serialChange(e)} type='number'/>
                                </Col>
                            </Row>

                            <Row className="mb30" type="flex" align="middle">
                                <Col span={2}>
                                    <div>
                                        <span className="star"></span>
                                        <span className="color_black">商品名称</span>
                                    </div>
                                </Col>
                                <Col span={6}>
                                    <Input placeholder="请输入商品名称" value={name} onChange={(e) => this.nameChange(e)}/>
                                </Col>
                            </Row>

                            <Row className="mb30" type="flex" align="middle">
                                <Col span={2}>
                                    <div>
                                        <span className="star"></span>
                                        <span className="color_black">商品分类</span>
                                    </div>
                                </Col>
                                <Col span={22} style={{display: 'flex'}}>
                                    <Select style={{width: 120}} onChange={this.typeChange} value={cat_id}
                                            placeholder={cat_name !== '' ? cat_name : "请选择分类"}>
                                        {
                                            sortList.map((item: any) => {
                                                return <Option value={item.id} key={item.id}>{item.name}</Option>
                                            })
                                        }
                                    </Select>
                                    <div className='goodEdit_add_type' onClick={() => {
                                        this.onAddType()
                                    }}>
                                        <img src={btn_add} style={{width: '22px', height: '22px', marginRight: '6px'}}/>
                                        <span>添加新分类</span>
                                    </div>
                                </Col>
                            </Row>

                            <Row className="mb30" type="flex" align="middle">
                                <Col span={2}>
                                    <div>
                                        <span className="star"></span>
                                        <span className="color_black">商品口味</span>
                                    </div>
                                </Col>
                                <Col span={22}>
                                    <Radio.Group
                                        value={shop_flavor}
                                        onChange={(e) => this.shopFlavor(e.target.value)}
                                    >
                                        <Radio value={true}>无</Radio>
                                        <Radio value={false}>有</Radio>
                                    </Radio.Group>
                                    {/* {
                                shop_flavor
                                ? ''
                                : <div>
                                    {
                                        specs_type.map((item:specs, idx) => {
                                            return <Row className="mb30" type="flex" align="middle" key={idx} style={{marginTop: '30px'}}>
                                                <Col span={12}>
                                                    <div className="good_label">
                                                        <Icon type="close-circle" className="delete_label" onClick={() => this.deleteItem(idx)}/>
                                                        <Row type="flex" align="middle" className="mb15">
                                                            <Col className="mr10">
                                                                <span className="star"></span>
                                                                <span className="color_black">口味名称</span>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Input placeholder="例：辣度" value={item.name} maxLength={10} onChange={(e) => this.itemChange(e, idx)}/>
                                                            </Col>
                                                        </Row>
                                                        <Row type="flex" align="middle">

                                                            <Col className="mr10">
                                                                <span className="star"></span>
                                                                <span className="color_black">添加口味</span>
                                                            </Col>

                                                            {
                                                                item.value.map((itemChild, index) => {
                                                                    return  <Col style={{ width: 120 }} key={index} className="child_wrap">
                                                                                <div className="relative child_wrap">
                                                                                    <Icon type="close-circle" style={{right: '-15px'}} className="delete_label" onClick={() => this.deleteChild(idx, index)}/>
                                                                                    <Input maxLength={10} placeholder={itemChild.name} onChange={(e) => this.childChange(e, idx, index)}/>

                                                                                    <Select style={{ width: 120 }} onChange={(e) => this.childChange(e, idx, index)} placeholder={itemChild.name !== '' ? itemChild.name : "请选择口味"}>
                                                                                        {
                                                                                            shop_specs_list.map((item:any, index: any) => {
                                                                                                return <Option value={item} key={item + index}>{item}</Option>
                                                                                            })
                                                                                        }
                                                                                    </Select>

                                                                                </div>
                                                                            </Col>
                                                                })
                                                            }
                                                            <Col span={3}>
                                                                <div style={{color: '#2589ff'}} onClick={() => this.addSpecsMore(idx)}>添加口味</div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                            </Row>
                                        })
                                    }
                                </div>
                            } */}
                                    {/* {
                            stock_type !== 'normal'
                            ?
                                <div>

                                    {
                                        specs_type.map((item:specs, idx) => {
                                            return <Row className="mb30" type="flex" align="middle" key={idx}>
                                                <Col span={22}>
                                                    <div className="good_label">
                                                        <Icon type="close-circle" className="delete_label" onClick={() => this.deleteItem(idx)}/>
                                                        <Row type="flex" align="middle" className="mb15">
                                                            <Col className="mr10">
                                                                <span className="star"></span>
                                                                <span className="color_black">规格名</span>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Input placeholder="请输入规格名称" value={item.name} maxLength={10} onChange={(e) => this.itemChange(e, idx)}/>
                                                            </Col>
                                                        </Row>
                                                        <Row type="flex" align="middle">
                                                            <Col className="mr10">
                                                                <span className="star"></span>
                                                                <span className="color_black">规格值</span>
                                                            </Col>
                                                            {
                                                                item.value.map((itemChild, index) => {
                                                                    return  <Col span={3} key={index} className="child_wrap">
                                                                                <div className="relative child_wrap">
                                                                                    <Icon type="close-circle" className="delete_label" onClick={() => this.deleteChild(idx, index)}/>
                                                                                    <Input maxLength={10} value={itemChild.name} onChange={(e) => this.childChange(e, idx, index)}/>
                                                                                </div>
                                                                            </Col>
                                                                })
                                                            }
                                                            <Col offset={4}>
                                                                <div onClick={() => this.addSpecsMore(idx)}>添加规格值</div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                            </Row>
                                        })
                                    }
                                </div>

                            : <div>
                                <Row className="mb30" type="flex" align="middle">
                                    <Col span={2}>
                                        <div>
                                            <span className="star"></span>
                                            <span className="color_black">价格</span>
                                        </div>
                                    </Col>
                                    <Col span={6}>
                                        <Input placeholder="请输入价格" maxLength={10} value={price} onChange={(e) => this.priceChange(e)}/>
                                    </Col>
                                </Row>

                                <Row className="mb30" type="flex" align="middle">
                                    <Col span={2}>
                                        <div>
                                            <span className="">  </span>
                                            <span className="color_black">会员价</span>
                                        </div>
                                    </Col>
                                    <Col span={6}>
                                        <Input placeholder="请输入会员价" maxLength={10} value={price_vip} onChange={(e) => this.priceVipChange(e)}/>
                                    </Col>
                                </Row>
                            </div>
                        } */}
                                </Col>
                            </Row>
                            <Row style={{marginTop: `${specs_type.length > 0 ? '-40px' : '0'}`}}>
                                <Col offset={2}>
                                    {
                                        shop_flavor
                                            ? ''
                                            : <div>
                                                {
                                                    specs_type.map((item: specs, idx) => {
                                                        return <Row className="mb30" type="flex" align="middle" key={idx}
                                                                    style={{marginTop: '30px'}}>
                                                            <Col span={12}>
                                                                <div className="good_label">
                                                                    <Icon type="close-circle" className="delete_label"
                                                                          onClick={() => this.deleteItem(idx)}/>
                                                                    <Row type="flex" align="middle" className="mb15">
                                                                        <Col className="mr10">
                                                                            <span className="star"></span>
                                                                            <span className="color_black">口味名称</span>
                                                                        </Col>
                                                                        <Col span={12}>
                                                                            <Input placeholder="例：辣度" value={item.name}
                                                                                   maxLength={10}
                                                                                   onChange={(e) => this.itemChange(e, idx)}/>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row type="flex" align="middle">
                                                                        {/* <Col className="mr10">
                                                                <span className="star"></span>
                                                                <span className="color_black">添加口味</span>
                                                            </Col> */}
                                                                        {
                                                                            item.value.map((itemChild, index) => {
                                                                                return <Col style={{width: 120}} key={index}
                                                                                            className="child_wrap">
                                                                                    <div className="relative child_wrap">
                                                                                        <Icon type="close-circle"
                                                                                              style={{right: '-15px'}}
                                                                                              className="delete_label"
                                                                                              onClick={() => this.deleteChild(idx, index)}/>
                                                                                        <Input maxLength={10}
                                                                                               placeholder={itemChild.name}
                                                                                               onChange={(e) => this.childChange(e, idx, index)}/>
                                                                                        {/* <Select style={{ width: 120 }} onChange={(e) => this.childChange(e, idx, index)} placeholder={itemChild.name !== '' ? itemChild.name : "请选择口味"}>
                                                                                        {
                                                                                            shop_specs_list.map((item:any, index: any) => {
                                                                                                return <Option value={item} key={item + index}>{item}</Option>
                                                                                            })
                                                                                        }
                                                                                    </Select> */}
                                                                                    </div>
                                                                                </Col>
                                                                            })
                                                                        }
                                                                        <Col span={3}>
                                                                            <div style={{color: '#2589ff'}}
                                                                                 onClick={() => this.addSpecsMore(idx)}>添加口味
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    })
                                                }
                                            </div>
                                    }
                                </Col>
                            </Row>
                            {
                                shop_flavor
                                    ? ''
                                    : <Row>
                                        <Col offset={2}>
                                            <Button className="mb30" onClick={this.addSpecs}>添加口味分类</Button>
                                        </Col>
                                    </Row>
                            }

                            <Row className="mb30" type="flex" align="middle">
                                <Col span={2}>
                                    <div>
                                        <span className="star"></span>
                                        <span className="color_black">销售价格</span>
                                    </div>
                                </Col>

                                <Col span={6}>
                                    <Radio.Group
                                        name="has_weigh"
                                        value={has_weigh}
                                        onChange={(e) => this.changeCurrency(e)}
                                    >
                                        <Radio value={false}>单一售价</Radio>
                                        <Radio value={true}>多规格</Radio>
                                    </Radio.Group>
                                </Col>
                            </Row>

                            {
                                has_weigh
                                    ? <Row className="mb30">
                                        <Col span={2}>
                                            <div>
                                                <span className=""> </span>
                                                <span className="color_black"></span>
                                            </div>
                                        </Col>

                                        <Col offset={2} className="goodEdit_price_space">
                                            <div className="goodEdit_price_space_left">
                                                <div className="goodEdit_price_space_left_main">
                                                    <div className="goodEdit_price_space_left_main_name">
                                                        <span className="star"></span>
                                                        <span className="color_black">规格名称</span>
                                                    </div>
                                                    <div>
                                                        <Input className="goodEdit_price_space_left_main_inp" placeholder="例：规格"
                                                               maxLength={10} value={weigh_value.weighName}
                                                               onChange={(e) => this.updateSpace(e, 0, 'weighVal')}/>
                                                    </div>
                                                </div>
                                                <div>
                                                    <Checkbox checked={is_vip_set}
                                                              onChange={this.onIsSetPriceVip}>设置会员价</Checkbox>
                                                </div>
                                            </div>
                                            <div className="goodEdit_price_space_item">
                                                <div className="goodEdit_price_space_item_top">
                                                    <div className="goodEdit_price_space_item_top_txt">
                                                        <span className="star"></span>
                                                        <span className="color_black">规格分类</span>
                                                    </div>
                                                    <div className="goodEdit_price_space_item_top_txt">
                                                        <span className="star"></span>
                                                        <span className="color_black">售价</span>
                                                    </div>
                                                    <div className="goodEdit_price_space_item_top_txt">
                                                        <span className="color_black">会员价</span>
                                                    </div>
                                                </div>
                                                <div className="goodEdit_price_space_item_bottom">
                                                    {
                                                        weigh_value.value && weigh_value.value.length > 0 && weigh_value.value.map((item: any, index: any) => {
                                                            return (
                                                                <div key={item + index}
                                                                     className="goodEdit_price_space_item_bottom_list">
                                                                    <div className="goodEdit_price_space_item_bottom_list_inp">
                                                                        <div
                                                                            className="goodEdit_price_space_item_bottom_list_inp_count">
                                                                            <Input placeholder="请输入规格" maxLength={10}
                                                                                   value={item.name}
                                                                                   onChange={(e) => this.updateSpace(e, index, 'name')}/>
                                                                        </div>
                                                                        <div
                                                                            className="goodEdit_price_space_item_bottom_list_inp_count">
                                                                            <Input placeholder="请输入售价" maxLength={10}
                                                                                   type='number' value={item.price}
                                                                                   onChange={(e) => this.updateSpace(e, index, 'price')}/>
                                                                        </div>
                                                                        <div
                                                                            className="goodEdit_price_space_item_bottom_list_inp_count">
                                                                            <Input placeholder="请输入会员价" maxLength={10}
                                                                                   type='number' value={item.price_vip}
                                                                                   onChange={(e) => this.updateSpace(e, index, 'price_vip')}/>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <img src={goodEditDel}
                                                                             className="goodEdit_price_space_left_img"
                                                                             onClick={() => {
                                                                                 this.delChangePriceSpace(index)
                                                                             }}/>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                <div className='new_btn' onClick={() => {
                                                    this.addPriceSpace()
                                                }}>
                                                    <img src={btn_add}/>
                                                    添加规格值
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    :
                                    <Row>
                                        <Row className="mb30" type="flex" align="middle">
                                            <Col span={2}>
                                                <div>
                                                    <span className="star"></span>
                                                    <span className="color_black">售价</span>
                                                </div>
                                            </Col>

                                            <Col span={6}>
                                                <Input placeholder="请输入价格" maxLength={10} type='number' value={price}
                                                       onChange={(e) => this.priceChange(e)}/>
                                            </Col>
                                        </Row>
                                        <Row className="mb30" type="flex" align="middle">
                                            <Col span={2}>
                                                <div>
                                                    <span className="">  </span>
                                                    <span className="color_black">会员价</span>
                                                </div>
                                            </Col>
                                            <Col span={8} style={{display: 'flex', alignItems: 'center'}}>
                                                <Col span={12}><Input placeholder="请输入会员价" maxLength={10} type='number'
                                                                      value={price_vip}
                                                                      onChange={(e) => this.priceVipChange(e)}/></Col>
                                                <Col span={8} offset={2}><Checkbox checked={is_vip_set}
                                                                                   onChange={this.onIsSetPriceVip}>设置会员价</Checkbox></Col>
                                            </Col>
                                        </Row>
                                    </Row>
                            }


                            <Row className="mb30" type="flex" align="middle">
                                <Col span={2}>
                                    <div>
                                        <span className="color_black">打印机部门</span>
                                    </div>
                                </Col>
                                <Col span={20}>

                                    <Row type="flex" align="middle">
                                        <Select allowClear placeholder={'请选择打印部门'} value={dep_id} style={{width: 200}}
                                                onChange={this.printerChange}>
                                            {
                                                printList.map((item: any) => {
                                                    return <Option key={item.id}
                                                                   value={item.id}>{item.department_name}</Option>
                                                })
                                            }
                                        </Select>
                                        <Checkbox checked={this.state.onIsTwoPrintView}
                                                  onChange={this.onIsTwoPrint}>设置第二台打印机</Checkbox>
                                        {
                                            this.state.onIsTwoPrintView ?
                                                <div>
                                                    <Select allowClear placeholder={'绑定第二台打印机'}
                                                            value={this.state.onTwoPrintId} style={{width: 200}}
                                                            onChange={this.onPrinterTwoChange}>
                                                        {
                                                            printList.map((item: any) => {
                                                                return <Option key={item.id}
                                                                               value={item.id}>{item.department_name}</Option>
                                                            })
                                                        }
                                                    </Select>
                                                    <Checkbox checked={this.state.onIsThreePrintView}
                                                              onChange={this.onIsThreePrint}>设置第三台打印机</Checkbox>
                                                </div>
                                                :
                                                <div></div>
                                        }

                                        {
                                            this.state.onIsThreePrintView ?
                                                <div>
                                                    <Select allowClear placeholder={'设置第三台打印机'}
                                                            value={this.state.onThreePrintId} style={{width: 200}}
                                                            onChange={this.onPrinterThreeChange}>
                                                        {
                                                            printList.map((item: any) => {
                                                                return <Option key={item.id}
                                                                               value={item.id}>{item.department_name}</Option>
                                                            })
                                                        }
                                                    </Select>
                                                </div>
                                                :
                                                <div></div>
                                        }


                                    </Row>


                                </Col>
                            </Row>


                            <Row className="mb30" type="flex" align="middle">
                                <Col span={2}>
                                    <div>
                                        <span className="star"></span>
                                        <span className="color_black">展示方式</span>
                                    </div>
                                </Col>
                                <Col span={22}>
                                    <Radio.Group
                                        value={display_mode}
                                        style={{marginLeft: 20}}
                                        onChange={(e: any) => this.goodPhotoChange(e.target.value)}
                                    >
                                        <Radio style={{position: 'relative', marginRight: '30px'}} value={1}>横图展示
                                            <span onMouseEnter={() => {
                                                this.setState({goodPhotoOne: 1})
                                            }} onMouseLeave={() => {
                                                this.setState({goodPhotoOne: 0})
                                            }} style={{color: '#7480FE', marginLeft: '10px', cursor: 'pointer'}}>预览</span>
                                            <div style={{
                                                position: 'absolute',
                                                top: '-600px',
                                                left: '0%',
                                                backgroundColor: '#fff',
                                                zIndex: 9
                                            }}>
                                                {
                                                    goodPhotoOne == 1
                                                        ? <img src={goodPhoto1} style={{width: '290px', height: 'auto'}}/>
                                                        : ''
                                                }
                                            </div>
                                            <span style={{
                                                position: 'absolute',
                                                bottom: '-20px',
                                                left: '25px',
                                                color: '#5F5F5F',
                                                fontSize: '12px'
                                            }}>尺寸558*308</span>
                                        </Radio>
                                        <Radio style={{marginRight: '30px'}} value={2}>竖图展示
                                            <span onMouseEnter={() => {
                                                this.setState({goodPhotoOne: 2})
                                            }} onMouseLeave={() => {
                                                this.setState({goodPhotoOne: 0})
                                            }} style={{color: '#7480FE', marginLeft: '10px', cursor: 'pointer'}}>预览</span>
                                            <div style={{
                                                position: 'absolute',
                                                top: '-600px',
                                                left: '0%',
                                                backgroundColor: '#fff',
                                                zIndex: 9
                                            }}>
                                                {
                                                    goodPhotoOne == 2
                                                        ? <img src={goodPhoto2} style={{width: '290px', height: 'auto'}}/>
                                                        : ''
                                                }
                                            </div>
                                            <span style={{
                                                position: 'absolute',
                                                bottom: '-20px',
                                                left: '25px',
                                                color: '#5F5F5F',
                                                fontSize: '12px'
                                            }}>尺寸264*264</span>
                                        </Radio>
                                        <Radio style={{marginRight: '30px'}} value={3}>大图展示
                                            <span onMouseEnter={() => {
                                                this.setState({goodPhotoOne: 3})
                                            }} onMouseLeave={() => {
                                                this.setState({goodPhotoOne: 0})
                                            }} style={{color: '#7480FE', marginLeft: '10px', cursor: 'pointer'}}>预览</span>
                                            <div style={{
                                                position: 'absolute',
                                                top: '-600px',
                                                left: '0%',
                                                backgroundColor: '#fff',
                                                zIndex: 9
                                            }}>
                                                {
                                                    goodPhotoOne == 3
                                                        ? <img src={goodPhoto3} style={{width: '290px', height: 'auto'}}/>
                                                        : ''
                                                }
                                            </div>
                                            <span style={{
                                                position: 'absolute',
                                                bottom: '-20px',
                                                left: '25px',
                                                color: '#5F5F5F',
                                                fontSize: '12px'
                                            }}>尺寸558*622</span>
                                        </Radio>
                                        <Radio style={{marginRight: '30px'}} value={4}>横小图展示
                                            <span onMouseEnter={() => {
                                                this.setState({goodPhotoOne: 4})
                                            }} onMouseLeave={() => {
                                                this.setState({goodPhotoOne: 0})
                                            }} style={{color: '#7480FE', marginLeft: '10px', cursor: 'pointer'}}>预览</span>
                                            <div style={{
                                                position: 'absolute',
                                                top: '-600px',
                                                left: '0%',
                                                backgroundColor: '#fff',
                                                zIndex: 9
                                            }}>
                                                {
                                                    goodPhotoOne == 4
                                                        ? <img src={goodPhoto4} style={{width: '290px', height: 'auto'}}/>
                                                        : ''
                                                }
                                            </div>
                                            <span style={{
                                                position: 'absolute',
                                                bottom: '-20px',
                                                left: '25px',
                                                color: '#5F5F5F',
                                                fontSize: '12px'
                                            }}>尺寸188*186</span>
                                        </Radio>
                                        <Radio value={5}>竖小图展示
                                            <span onMouseEnter={() => {
                                                this.setState({goodPhotoOne: 5})
                                            }} onMouseLeave={() => {
                                                this.setState({goodPhotoOne: 0})
                                            }} style={{color: '#7480FE', marginLeft: '10px', cursor: 'pointer'}}>预览</span>
                                            <div style={{
                                                position: 'absolute',
                                                top: '-600px',
                                                left: '0%',
                                                backgroundColor: '#fff',
                                                zIndex: 9
                                            }}>
                                                {
                                                    goodPhotoOne == 5
                                                        ? <img src={goodPhoto5} style={{width: '290px', height: 'auto'}}/>
                                                        : ''
                                                }
                                            </div>
                                            <span style={{
                                                position: 'absolute',
                                                bottom: '-20px',
                                                left: '25px',
                                                color: '#5F5F5F',
                                                fontSize: '12px'
                                            }}>尺寸188*186</span>
                                        </Radio>
                                    </Radio.Group>
                                </Col>
                            </Row>

                            {/* <Row className="mb30" type="flex" align="middle">
                        <Col span={2}>
                            <div>
                                <span className="color_black">商品单位</span>
                            </div>
                        </Col>
                        <Col span={6}>
                            <Input placeholder="请输入商品单位" maxLength={10} value={definition} onChange={(e) => this.definitionChange(e)}/>
                        </Col>
                    </Row> */}
                            {/* <div className="edit_title">商品设置</div> */}
                            {
                                more_set
                                    ? ''
                                    : <Row>
                                        <Row className="mb30" type="flex" align="middle">
                                            <Col span={2}>
                                                <div>
                                                    <span className="color_black">称重菜</span>
                                                </div>
                                            </Col>
                                            <Col span={6}>
                                                <Radio.Group
                                                    name="is_weigh"
                                                    value={is_weigh}
                                                    style={{marginLeft: 20}}
                                                    onChange={(e) => this.onCurrencyChange(e)}
                                                >
                                                    <Radio value={true}>是</Radio>
                                                    <Radio value={false}>否</Radio>
                                                </Radio.Group>
                                            </Col>
                                        </Row>
                                        <Row className="mb30" type="flex" align="middle" style={{position: 'relative'}}>
                                            <Col span={2}>
                                                <div>
                                                    <span className="star"></span>
                                                    <span className="color_black">单位</span>
                                                </div>
                                            </Col>
                                            <Col span={6}>
                                                <Input onPressEnter={() => {
                                                    this.setState({is_definitions: false})
                                                }} onClick={(e) => {
                                                    this.onInputClick(e)
                                                }} placeholder="请填写单位" type="text" maxLength={10} name='definitions'
                                                       value={definitions} onChange={(e) => this.selectDefinitions(e)}/>
                                                {
                                                    is_definitions
                                                        ? <div style={{
                                                            width: '100%',
                                                            position: 'absolute',
                                                            left: '0',
                                                            top: '34px',
                                                            zIndex: 2,
                                                            backgroundColor: '#fff',
                                                            margin: 0,
                                                            border: '1px solid #d9d9d9',
                                                            borderRadius: '4px'
                                                        }}>
                                                            <ul style={{
                                                                listStyle: 'none',
                                                                margin: 0,
                                                                padding: 0,
                                                                height: '290px',
                                                                overflowY: 'scroll'
                                                            }}>
                                                                {
                                                                    definitions_list.map((item: any, index: any) => {
                                                                        return <li onClick={(e) => {
                                                                            this.changeDefinition(e, item)
                                                                        }} className="goodEdit-definttions"
                                                                                   style={{cursor: 'pointer', padding: '4px 11px'}}
                                                                                   key={index}>{item}</li>
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                        : ''
                                                }
                                            </Col>
                                        </Row>


                                        <Row className="mb30" type="flex" align="middle" style={{position: 'relative'}}>
                                            <Col span={2}>
                                                <div>
                                                    <span className="color_black">选择加料：</span>
                                                </div>
                                            </Col>
                                            <Col span={2} style={{display: 'flex', alignItems: 'center'}}>
                                                <div className="material" onClick={this.addMaterial}>
                                                    <img className={"material_img"} src={i_add_icon} alt=""/>
                                                </div>
                                            </Col>

                                        </Row>

                                        {
                                            this.state.material_info.length > 0 ?
                                                <Row className="mb30" type="flex" align="middle" style={{position: 'relative'}}>
                                                    <div className="goods_material_list">
                                                        <Table
                                                            dataSource={this.state.material_info}
                                                            columns={materialColumns}
                                                            rowKey={"id"}
                                                            size="middle"
                                                            locale={{emptyText: '暂无数据'}}
                                                            scroll={{y: 200}}
                                                            pagination={false}
                                                        />
                                                    </div>
                                                </Row> : null
                                        }


                                        <Row className="mb30" type="flex" align="middle" style={{position: 'relative'}}>
                                            <Col span={2}>
                                                <div>
                                                    <span className="color_black">起售份数</span>
                                                </div>
                                            </Col>
                                            <Col span={2} style={{display: 'flex', alignItems: 'center'}}>
                                                <img src={good_reduce} className="goodEdit_reduce_num" onClick={() => {
                                                    this.onFromSaleNum('reduce', 'sale')
                                                }}/>
                                                <Input className="goodEdit_from_sale_num" style={{width: '100px'}} type="number"
                                                       value={start_num} name="start_num"
                                                       onChange={(e) => this.inputChange(e)}/>
                                                <img src={set_add_disabled} className="goodEdit_reduce_num" onClick={() => {
                                                    this.onFromSaleNum('add', 'sale')
                                                }}/>
                                            </Col>
                                            {/* <Col span={6}>
                                    <Radio.Group
                                        name="single_operate_num"
                                        value={single_operate_num}
                                        style={{ marginLeft: 20 }}
                                        onChange={(e) => this.onCurrencyChange(e)}
                                    >
                                        <Radio value={true}>
                                            起售份数累加<Tooltip title={'例如：起售份数为2，点餐小程序选择该菜品默认数量为2，,每点击“+”号按钮数量加1'} className="tip">
                                                <Icon type="question-circle" theme="filled" />
                                            </Tooltip>
                                        </Radio>
                                        <Radio value={false}>
                                            起售份数叠加<Tooltip title={'例如：起售份数为2，点餐小程序选择该菜品默认数量为2，,每点击“+”号按钮数量乘2'} className="tip">
                                                <Icon type="question-circle" theme="filled" />
                                            </Tooltip>
                                        </Radio>
                                    </Radio.Group>
                                </Col> */}
                                        </Row>

                                        <Row className="mb30" type="flex" align="middle" style={{position: 'relative'}}>
                                            <Col span={2}>
                                                <div>
                                                    <span className="color_black">单次加菜叠加</span>
                                                </div>
                                            </Col>
                                            <Col span={2} style={{display: 'flex', alignItems: 'center'}}>
                                                <img src={good_reduce} className="goodEdit_reduce_num" onClick={() => {
                                                    this.onFromSaleNum('reduce', 'sign')
                                                }}/>
                                                <Input className="goodEdit_from_sale_num" style={{width: '100px'}}
                                                       value={single_operate_num} name="single_operate_num" type="number"
                                                       onChange={(e) => this.inputChange(e)}/>
                                                <img src={set_add_disabled} className="goodEdit_reduce_num" onClick={() => {
                                                    this.onFromSaleNum('add', 'sign')
                                                }}/>
                                            </Col>
                                            {/* <Col span={6}>
                                    <Radio.Group
                                        name="single_operate_num"
                                        value={single_operate_num}
                                        style={{ marginLeft: 20 }}
                                        onChange={(e) => this.onCurrencyChange(e)}
                                    >
                                        <Radio value={true}>
                                            起售份数累加<Tooltip title={'例如：起售份数为2，点餐小程序选择该菜品默认数量为2，,每点击“+”号按钮数量加1'} className="tip">
                                                <Icon type="question-circle" theme="filled" />
                                            </Tooltip>
                                        </Radio>
                                        <Radio value={false}>
                                            起售份数叠加<Tooltip title={'例如：起售份数为2，点餐小程序选择该菜品默认数量为2，,每点击“+”号按钮数量乘2'} className="tip">
                                                <Icon type="question-circle" theme="filled" />
                                            </Tooltip>
                                        </Radio>
                                    </Radio.Group>
                                </Col> */}
                                        </Row>
                                        <Row className="mb30" type="flex" align="middle">
                                            <Col span={2}>
                                                <div>
                                                    <span className="color_black">菜品推荐人数</span>
                                                </div>
                                            </Col>
                                            <Col span={6}>
                                                <Input placeholder="请输入推荐人数" type="number" maxLength={10} value={people_num_eat}
                                                       onChange={(e) => this.personChange(e)}/>
                                            </Col>
                                        </Row>

                                        <Row className="mb30" type="flex" align="middle">
                                            <Col span={2}>
                                                <div>
                                                    <span className="color_black">左上角标签</span>
                                                </div>
                                            </Col>
                                            <Col span={6}>
                                                <Input placeholder="请输入左上角标签" maxLength={10} value={left_top_tips}
                                                       onChange={(e) => this.leftChange(e)}/>
                                            </Col>
                                        </Row>

                                        <Row className="mb30" type="flex" align="middle">
                                            <Col span={2}>
                                                <div>
                                                    <span className="color_black">右上角标签</span>
                                                </div>
                                            </Col>
                                            <Col span={6}>
                                                <Input placeholder="请输入右上角标签" maxLength={10} value={right_top_tips}
                                                       onChange={(e) => this.rightChange(e)}/>
                                            </Col>
                                        </Row>

                                        <Row className="mb30" type="flex" align="middle">
                                            <Col span={2}>
                                                <div>
                                                    <span className="color_black">参与打折</span>
                                                </div>
                                            </Col>
                                            <Col span={6}>
                                                <Radio.Group
                                                    value={is_no_discount}
                                                    style={{marginLeft: 20}}
                                                    onChange={(e) => this.discountChange(e.target.value)}
                                                >
                                                    <Radio value={false}>是</Radio>
                                                    <Radio value={true}>否</Radio>
                                                </Radio.Group>
                                            </Col>
                                        </Row>

                                        <Row className="mb30" type="flex" align="middle">
                                            <Col span={2}>
                                                <div>
                                                    <span className="color_black">开台必点</span>
                                                </div>
                                            </Col>
                                            <Col span={10}>
                                                <Radio.Group
                                                    value={is_must_eat}
                                                    style={{marginLeft: 20}}
                                                    onChange={(e) => this.musteatChange(e.target.value)}
                                                >
                                                    <div className="must_eat">
                                                        <div>
                                                            <Radio value={false}>不设置</Radio>
                                                            <Radio value={true}>按就餐人数算</Radio>
                                                            <Radio value={2}>自定义</Radio>
                                                        </div>
                                                        {
                                                            Number(is_must_eat) > 1 ?
                                                                <div>
                                                                    <Col span={4}
                                                                         style={{display: 'flex', alignItems: 'center'}}>
                                                                        <img src={good_reduce} className="goodEdit_reduce_num"
                                                                             onClick={() => {
                                                                                 this.onFromSaleNum('reduce', 'material')
                                                                             }}/>
                                                                        <Input className="goodEdit_from_sale_num"
                                                                               style={{width: '100px'}} value={must_eat_qty}
                                                                               name="must_eat_qty" type="number"
                                                                               onChange={(e) => this.inputChange(e)}/>
                                                                        <img src={set_add_disabled}
                                                                             className="goodEdit_reduce_num" onClick={() => {
                                                                            this.onFromSaleNum('add', 'material')
                                                                        }}/>
                                                                    </Col>
                                                                </div> : null
                                                        }

                                                    </div>

                                                </Radio.Group>
                                            </Col>
                                        </Row>

                                        <Row className="mb30" type="flex" align="middle">
                                            <Col span={2}>
                                                <div>
                                                    <span className="color_black">单桌限点份数</span>
                                                </div>
                                            </Col>
                                            <Col>
                                                <Radio.Group value={is_order_limit}
                                                             onChange={(e) => this.isOrderLimitChange(e.target.value)}
                                                             name='is_order_limit'>
                                                    <Radio value={1}>无限制</Radio>
                                                    <Radio value={2}>限制</Radio>
                                                </Radio.Group>
                                            </Col>
                                            <Col>
                                                {
                                                    is_order_limit == 2
                                                        ? <div> 用户在小程序下单时,单桌最多可点
                                                            <Input onChange={(e) => this.orderLimitChange(e.target.value)}
                                                                   value={order_limit_num} type="text"
                                                                   name='order_limit_num' style={{
                                                                width: '100px',
                                                                textAlign: 'center',
                                                                marginLeft: '5px',
                                                                marginRight: '5px'
                                                            }}/> 份,服务员在收银台下单则不受此限制.
                                                        </div>
                                                        : ''
                                                }
                                            </Col>
                                        </Row>

                                        <Row className="mb30" type="flex" align="middle">
                                            <Col span={2}>
                                                <div>
                                                    <span className="color_black">小程序显示</span>
                                                </div>
                                            </Col>
                                            <Col span={6}>
                                                <Radio.Group
                                                    value={is_must_mini}
                                                    style={{marginLeft: 20}}
                                                    onChange={(e) => this.miniShowChange(e.target.value)}
                                                >
                                                    <Radio value={true}>是</Radio>
                                                    <Radio value={false}>否</Radio>
                                                </Radio.Group>
                                            </Col>
                                        </Row>

                                        {
                                            display_mode == 3 || display_mode == 1
                                                ? <Row className="mb30" type="flex" align="middle">
                                                    <Col span={2}>
                                                        <div>
                                                            <span className="color_black">商品详情</span>
                                                        </div>
                                                    </Col>
                                                    <Col span={6}>
                                                        <TextArea defaultValue={product_info} allowClear
                                                                  onChange={(e: any) => {
                                                                      this.onInfoChange(e)
                                                                  }}></TextArea>
                                                    </Col>
                                                </Row>
                                                : ''
                                        }

                                    </Row>
                            }

                            <Row>
                                <div style={{cursor: 'pointer'}} onClick={() => {
                                    this.onMoreSet()
                                }}>
                                    <span style={{color: '#2589ff', width: '100px'}}>{more_txt}</span>
                                    <Icon style={{color: '#2589ff', width: '30px'}} type="caret-down"/>
                                    <span className="line"></span>
                                </div>
                            </Row>

                            <Row>
                                <Col span={6} offset={2}>
                                    <div className="bottom_wrap">
                                        <Button type="primary" onClick={(e) => {
                                            this.handleSubmit(e, 'add')
                                        }}>
                                            保存
                                        </Button>
                                        <Button type="primary" onClick={(e) => {
                                            this.handleSubmit(e, 'save')
                                        }}>
                                            保存并继续添加
                                        </Button>
                                        <Button type="primary" ghost onClick={this.goBack}>
                                            取消
                                        </Button>
                                    </div>
                                </Col>
                            </Row>


                            {/* <Row className="mb30" type="flex" align="middle">
                        <Col span={2}>
                            <div>
                                <span className="star"></span>
                                <span className="color_black">商品排序</span>
                            </div>
                        </Col>
                        <Col span={6}>
                            <Input placeholder="请输入商品排序" maxLength={10} value={order_by} onChange={(e) => this.orderChange(e)}/>
                        </Col>
                    </Row> */}
                        </div>
                }
            </div>

            <div>
                <Modal
                    title="选择加料"
                    visible={this.state.add_material_visible}
                    onOk={this.addMaterialHandleOk}
                    onCancel={this.addMaterialHandleCancel}
                    className={"material_list_modal"}
                    footer={null}
                >
                    <div className="material_list">
                        {
                            this.state.dataMaterialList.length > 0 ? <div>
                                <div className="search_btn">
                                    <div className="search_title">
                                        菜品
                                    </div>
                                    <div className="search_input">
                                        <Search
                                            placeholder="请输入加料名称"
                                            allowClear={true}
                                            value={this.state.kw}
                                            onChange={(e) => {
                                                this.searchInput(e)
                                            }}
                                            onSearch={(value) => {
                                                this.searchBtn(value)
                                            }}
                                            style={{width: 259}}
                                        />
                                    </div>
                                </div>

                                <div className="material_center">
                                    <Table
                                        rowSelection={rowSelectionMaterial}
                                        dataSource={this.state.dataMaterialList}
                                        columns={columns}
                                        rowKey={"id"}
                                        size="middle"
                                        locale={{emptyText: '暂无数据'}}
                                        scroll={{y: 200}}
                                        pagination={{
                                            current: this.state.currentPage,
                                            defaultCurrent: 1,
                                            total: this.state.count,
                                            pageSize: this.state.pageSize,
                                            showQuickJumper: true,
                                            showSizeChanger: true,
                                            onChange: this.pageChangeMaterial,
                                            showTotal: (total: number) => `共${total}条`,
                                            onShowSizeChange: (current: number, size: number) => {
                                                this.setState({pageSize: size}, () => {
                                                    this.onSizeChangeMaterial();
                                                })
                                            }
                                        }}
                                    />
                                </div>

                                <div className="add_material_btn_modal">
                                    <div className="add_btn_cancel" onClick={this.addMaterialHandleCancel}>
                                        取 消
                                    </div>
                                    <div className="add_btn_save" onClick={this.addMaterialHandleOk}>
                                        确 定
                                    </div>
                                </div>
                            </div> : <div className="no_material_data">
                                <div className="no_material_data_content">
                                    暂无数据
                                </div>

                                <div className="no_material_data_footer">
                                    <div className="go_to_add_material" onClick={(e) => {
                                        console.log("====go_to_add_material=====")
                                        this.setState({
                                            addVisible: true,
                                            add_material_visible: false
                                        })
                                    }}>
                                        去添加加料
                                    </div>
                                </div>
                                {
                                    // this.state.addVisible ? <AddMaterialCom addVisibleChild={true} onUpdateMateriaList={this.getShopMaterialList.bind(this)} />:null
                                    this.state.addVisible ? <AddMaterialCom addVisibleChild={true}
                                                                            onUpdateMateriaList={this.getShopMaterialList.bind(this)}
                                                                            onUpdateaddVisible={this.onUpdateaddVisible.bind(this)}/> : null
                                }


                            </div>
                        }


                    </div>
                </Modal>
            </div>

        </div>;
    }

}

export default GoodsEdit