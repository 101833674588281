import * as React from "react";
import OverView from "../pages/overview/overview";
import AccountAuthView from "../pages/account/powderRising/accountAuth";
import Distribution from "../pages/distribution/distribution";
import Product from "../pages/product/product";
import ProductEdit from "../pages/product/product_edit/product_edit";
import CreateActive from "../pages/distribution/create_active/create_active";
import TaskManage from "../pages/taskTreasure/taskManage/taskManage";
import NewTask from "../pages/taskTreasure/newTask/newTask";
import DistributionManager from '../pages/distribution/distribution_manager/distribution_manager'
import DistributionList from '../pages/distribution/distribution_manager/distribution_list/distribution_list'
import JoinFans from "../pages/joinFans/joinFans";
import DataStatistics from "../pages/dataStatistics/dataStatistics";
import QRCode from "../pages/qRCode/qRCode";
import Recharge from "../pages/recharge/recharge";
import Order from '../pages/distribution/distribution_order/order_list/order_list'
import ParticipatingUser from '../pages/distribution/distribution_order/participating_user/participating_user'
import Withdraw from '../pages/distribution/distribution_order/withdraw/withdraw'
import Turnplate from '../pages/turnplate/turnplate';
import TurnplatePerson from '../pages/turnplate/turnplatePerson/turnplatePerson';
import TurnplateCreate from '../pages/turnplate/turnplateCreate/turnplateCreate';
import TurnplateComment from '../pages/turnplate/turnplateComment/turnplateComment';
import DistributionApplyWithdrawal from '../pages/distribution/distribution_order/withdraw/apply_withdrawal'

import Coupon from "../pages/coupon/coupon";
import CouponAdd from "../pages/coupon/couponAdd";
// import PeopleCoupon from "../pages/coupon/peopleCoupon";
import SendCoupon from "../pages/sendCoupon/sendCoupon";
import SendCouponEdit from "../pages/sendCoupon/sendCouponEdit";
import Goods from "../pages/goods/goods";
import GoodsSort from "../pages/goods/goodsSort";
import GoodsSortEdit from "../pages/goods/goodsSortEdit";
import GoodsEdit from "../pages/goods/goodsEdit";
import GoodsAdd from "../pages/goods/goodsAdd";
import SmallProgram from "../pages/account/smallProgram/smallProgram";
import ShopTable from "../pages/shop/shopTable/shopTable";
import ShopTableEdit from "../pages/shop/shopTable/shopTableEdit";
import ShopList from "../pages/shop/shopList";
import ShopRegion from "../pages/shop/shopRegion/shopRegion";
import OrderManagerOrderList from '../pages/order_manager/order_list/order_list';
import XcxWithdraw from '../pages/xcxWithdraw/xcxWithdraw';
import Message from '../pages/message/message'
import MessagePushList from '../pages/message/messagePushList/messagePushList'
import MessageTemplate from "../pages/message/messageTemplate/messageTemplate";
import RechargeManage from '../pages/recharge/recharge_manage/recharge_manage';
import MemberList from '../pages/vip_management/memberList/memberList';
import MemberDetail from '../pages/vip_management/memberDetail/memberDetail';
import MemberGroupIndex from '../pages/vip_management/group/index';
import MemberGroupVipList from '../pages/vip_management/group/sub/vip_group/group_members';
import MemberGroupVipDetail from '../pages/vip_management/group/sub/vip_group/components/detail_group_members';
import MemberGroupCustomAdd from '../pages/vip_management/group/sub/vip_group/add_vip_group';
import SiyuGroupDetail from '../pages/vip_management/group/sub/siyu_group/detail';
import SiyuOfficialAccount from '../pages/vip_management/group/sub/siyu_group/official_account';
import SiyuCommunity from '../pages/vip_management/group/sub/siyu_group/community';
import SiyuOfficialMemberIndex from '../pages/vip_management/group/sub/siyu_group/member/index';
import SiyuGroupAdd from '../pages/vip_management/group/sub/siyu_group/add';
import MarketingIndex from '../pages/marketing/index';
import MarketingDetail from '../pages/marketing/detail';
import MarketingSubDetail from '../pages/marketing/sub_detail';
import MarketingAdd from '../pages/marketing/add';
import SmsIndex from '../pages/message/sms/index';
import WechatSmsIndex from '../pages/message/wechatSms/index';
import WechatSmsAdd from '../pages/message/wechatSms/add';
import WechatSmsGuide from '../pages/message/wechatSms/guide';
import AdverEdit from '../pages/adverManager/adverEdit/adverEdit'
import WeappDistriutionConfig from '../pages/weapp_distriution_manage/weapp_distriution_config/weapp_distriution_config'
import WeappDistriution from '../pages/weapp_distriution_manage/weapp_distriutions/weapp_distriutions'
import WeappDistriutionUsers from '../pages/weapp_distriution_manage/weapp_distriution_users/weapp_distriution_users'
import WelfareList from '../pages/welfareManager/welfareList'
import WelfareEdit from '../pages/welfareManager/welfareEdit'
import PrinterList from '../pages/printerManager/printerList'
import PrinterEdit from '../pages/printerManager/printerEdit'
import PrinterMsgList from '../pages/printerManager/printerMsgList'

import FinancialManagement from '../pages/financial_management/financial_management'
import ShopManagement from '../pages/financial_management/shop_management/shop_management'
import RulesConfig from '../pages/vip_config/rules_config'
import VipLevelConfig from '../pages/vip_config/vip_level_config'
import EditVipLevel from '../pages/vip_config/edit_vip_level'
import AddStoreUser from '../pages/storeUser/addStoreUser/addStoreUser'
import StoreUserList from '../pages/storeUser/storeUserList/storeUserList'

import InviteHelpConfigurationList from '../pages/weapp_invite_help/configuration_list/configuration_list';
import InviteHelpUsers from '../pages/weapp_invite_help/invite_help_users/invite_help_users';
import AddInviteHelpConfiguratin from '../pages/weapp_invite_help/add_configuration/add_configuration';

import Parinter from '../pages/parinter/parinter'

import DiscountCoupon from '../pages/realTimeManager/discountCoupon/discountCoupon'
import DiscountAdd from '../pages/realTimeManager/discountCoupon/discountAdd'
import DeductionCoupon from '../pages/realTimeManager/deductionCoupon/deductionCoupon'
import DeductionAdd from '../pages/realTimeManager/deductionCoupon/deductionAdd'
import SettleAccounts from '../pages/realTimeManager/settleAccounts/settleAccounts'


import ChangeTable from '../pages/windowManage/changeTable'
import MergeTable from '../pages/windowManage/mergeTable'
import OrderingFood from '../pages/orderingFood/orderingFood'
import OrderTimes from '../pages/timely_message/order_times/order_times'
import VipGroup from '../pages/vip_management/vip_group/vip_group'
import AddVipGroup from '../pages/vip_management/vip_group/add_vip_group'
import DeatilGroupMembers from '../pages/vip_management/vip_group/components/detail_group_members'
import ManageUser from '../pages/permission/manageUser/manageUser'
import SystemRole from '../pages/permission/systemRole/systemRole';
import AddRole from '../pages/permission/systemRole/addRole/addRole';
import AddManageUser from '../pages/permission/manageUser/addManageUser/addManageUser'
import Stockout from '../pages/stockout/stockout'
import VegetableSaleTable from '../pages/vegetable_sale_table/vegetable_sale_table'

import GroupMembers from '../pages/vip_management/vip_group/group_members'
import FinanceForm from '../pages/financeForm/index'

import SetMeal from '../pages/setMeal/setMeal'
import SetMealAdd from '../pages/setMeal/setMealAdd/setMealAdd'
import BuyAndGive from '../pages/buyAndGive/index'
import BuyAndGiveEdit from '../pages/buyAndGive/buyAndGiveEdit'

import VouchersList from '../pages/vouchers_list/vouchers_list'
import AddVouchers from '../pages/vouchers_list/add_vouchers'
import CouponOther from '../pages/coupon/couponOther'
import PrinterDepart from '../pages/printerManager/printerDepart'
import PrinterDepartEdit from '../pages/printerManager/printerDepartEdit'
import CashCoupon from '../pages/coupon/cashCoupon/index'
import EquityList from '../pages/equity/equityList'
import EquityEdit from '../pages/equity/equityEdit'
import BirthEdit from '../pages/birth/birthEdit'
import BirthList from '../pages/birth/birthList'
import NewPeople from '../pages/newPeople/newPeople'
import NewPeopleEdit from '../pages/newPeople/newPeopleEdit'
import HelpEdit from '../pages/help/helpEdit'
import ChangeDishList from '../pages/changeDish/changeDishList'
import ChangeDishEdit from '../pages/changeDish/changeDishEdit'
import HelpCenter from '../pages/help/helpCenter'

import IndexHome from '../pages/index/index'
import EquityCardOrder from '../pages/order_manager/equity_card_order/equity_card_order'
import StoreCharge from '../pages/storeCharge/index'
import Notification from '../pages/notification/index';
import ChargeFree from '../pages/chargeFree/chargeFree'
import ChargeFreeEdit from '../pages/chargeFree/chargeFreeEdit'
import UserGroup from '../pages/userGroup/userGroup'
import UserGroupDetail from '../pages/userGroup/userGroupDetail'
import UserGroupAdd from '../pages/userGroup/userGroupAdd'
import ShopAdd from '../pages/shop/shopAdd'
import PaymentAdd from '../pages/coupon/paymentAdd'

import WeappDistriutionAdd from '../pages/weapp_distriution_manage/weapp_distriution_add/weapp_distriution_add'

import BindApplet from '../pages/store_accout/bind_applet/bind_applet'
import BindPublic from '../pages/store_accout/bind_pulbic/bind_public'
import StoreAuth from '../pages/store_accout/store_auth/store_auth'

import HelpAdvertManagementList from '../pages/index/components/helpCenter/advertManagement/advertManagementList/helpAdvertManagementList.tsx'
import HelpAdvertManagementListDetail from '../pages/index/components/helpCenter/advertManagement/advertManagementList/helpAdvertManagementListDetail.tsx'
import HelpAdminNum from '../pages/index/components/helpCenter/authorityManagement/adminNum/helpAdminNum.tsx'
import HelpAdminNumDetail from '../pages/index/components/helpCenter/authorityManagement/adminNum/helpAdminNumDetail.tsx'
import HelpSystemNum from '../pages/index/components/helpCenter/authorityManagement/systemNum/helpSystemNum.tsx'
import HelpSystemNumDetail from '../pages/index/components/helpCenter/authorityManagement/systemNum/helpSystemNumDetail.tsx'
import HelpMealManagement from '../pages/index/components/helpCenter/commodityAdmin/mealManagement/helpMealManagement.tsx'
import HelpMealManagementDetail from '../pages/index/components/helpCenter/commodityAdmin/mealManagement/helpMealManagementDetail.tsx'
import HelpShopManagement from '../pages/index/components/helpCenter/commodityAdmin/shopManagement/helpShopManagement.tsx'
import HelpShopManagementDetail from '../pages/index/components/helpCenter/commodityAdmin/shopManagement/helpShopManagementDetail.tsx'
import HelpTypeManagement from '../pages/index/components/helpCenter/commodityAdmin/typeManagement/helpTypeManagement.tsx'
import HelpTypeManagementDetail from '../pages/index/components/helpCenter/commodityAdmin/typeManagement/helpTypeManagementDetail.tsx'
import HelpLocationManagement from '../pages/index/components/helpCenter/deskManagement/locationManagement/helpLocationManagement.tsx'
import HelpLocationManagementDetail from '../pages/index/components/helpCenter/deskManagement/locationManagement/helpLocationManagementDetail.tsx'
import HelpTableManager from '../pages/index/components/helpCenter/deskManagement/tableManager/helpTableManager.tsx'
import HelpTableManagerDetail from '../pages/index/components/helpCenter/deskManagement/tableManager/helpTableManagerDetail.tsx'
import HelpMbaBirthday from '../pages/index/components/helpCenter/mbaManagement/mbaManagementTools/helpMbaBirthday.tsx'
import HelpMbaBusiness from '../pages/index/components/helpCenter/mbaManagement/mbaManagementTools/helpMbaBusiness.tsx'
import HelpMbaBuyFree from '../pages/index/components/helpCenter/mbaManagement/mbaManagementTools/helpMbaBuyFree.tsx'
import HelpMbaChargeFree from '../pages/index/components/helpCenter/mbaManagement/mbaManagementTools/helpMbaChargeFree.tsx'
import HelpMbaCoupon from '../pages/index/components/helpCenter/mbaManagement/mbaManagementTools/helpMbaCoupon.tsx'
import HelpMbaCourtesy from '../pages/index/components/helpCenter/mbaManagement/mbaManagementTools/helpMbaCourtesy.tsx'
import HelpMbaDiscount from '../pages/index/components/helpCenter/mbaManagement/mbaManagementTools/helpMbaDiscount.tsx'
import HelpMbaExchange from '../pages/index/components/helpCenter/mbaManagement/mbaManagementTools/helpMbaExchange.tsx'
import HelpMbaInterests from '../pages/index/components/helpCenter/mbaManagement/mbaManagementTools/helpMbaInterests.tsx'
import HelpMbaManagementTools from '../pages/index/components/helpCenter/mbaManagement/mbaManagementTools/helpMbaManagementTools.tsx'
import HelpMbaSecurities from '../pages/index/components/helpCenter/mbaManagement/mbaManagementTools/helpMbaSecurities.tsx'
import HelpMbaWelcome from '../pages/index/components/helpCenter/mbaManagement/mbaManagementTools/helpMbaWelcome.tsx'
import HelpStoreManagementList from '../pages/index/components/helpCenter/storeManagement/storeManagementList/helpStoreManagementList.tsx'
import HelpStoreManagementListDetail from '../pages/index/components/helpCenter/storeManagement/storeManagementList/helpStoreManagementListDetail.tsx'
import FinanceBalance from '../pages/financeBalance/index'
import FinanceCash from '../pages/financeCash/index'

import SmartPushTicket from '../pages/smartPushTicket/index'
import SmartPushTicketAdd from '../pages/smartPushTicket/smartPushTicketAdd/smartPushTicketAdd'

import Consumption from '../pages/consumption/consumption'
import ConsumptionAdd from '../pages/consumption/consumptionAdd'
import CouponGroup from '../pages/couponGroup/couponGroup'
import CouponGroupAdd from '../pages/couponGroup/couponGroupAdd'

import ProductIntroduction from '../pages/index/components/productIntroduction/productIntroduction'

import MatterDown from '../pages/matterDown/index'
import RechargeAddOrEdit from '../pages/recharge/recharge_manage/recharge_addOrEdit'

import ModifyPassword from '../pages/modifyPassword/modifyPassword'

import BusinessModel from '../pages/businessModel/businessModel'
import PreferentialSettings from '../pages/preferentialSettings/preferentialSettings'
import SystemSettings from '../pages/systemSettings/systemSettings'

import ScoreIndex from '../pages/score/index' //积分列表
import ScoreAdd from '../pages/score/add' //积分添加
import Dashboard from "../pages/dashboard";
import DashboardList from "../pages/dashboard/list";
import DashboardAdd from "../pages/dashboard/add";

import DeepOperation from '../pages/deepOperation/deepOperation'
import VipList from '../pages/vip_management/memberList/vipList'

import DepositIndex from '../pages/deposit/index'
import DepositAdd from '../pages/deposit/add'
import DepositPrintSetting from '../pages/deposit/printSetting'
import DepositMsgSetting from '../pages/deposit/msgSetting'

//商家券
import MerchantVoucherCoupon from '../pages/merchantVoucher/coupon' //商家券--优惠券
import MerchantVoucherCouponAdd from '../pages/merchantVoucher/couponAdd' //商家券--添加优惠券
import MerchantVoucherDishList from '../pages/merchantVoucher/changeDishList' //商家券--兑换菜
import MerchantVoucherDishEdit from '../pages/merchantVoucher/changeDishEdit' //商家券--兑换菜编辑
import MerchantVoucherRegister from '../pages/merchantVoucher/registration' //商家券--注册有礼
import MerchantVoucherRegisterEdit from '../pages/merchantVoucher/registrationEdit' //商家券--注册有礼编辑
import MerchantVoucherConsumption from '../pages/merchantVoucher/consumption' //商家券--消费有礼
import MerchantVoucherConsumptionAdd from '../pages/merchantVoucher/consumptionAdd' //商家券--消费有礼编辑

// 3.0.1 tabbar改版
import Home from '../pages/runManageMenu/Home/home'
import IncomeForm from '../pages/incomeForm/index'
import OrderDetails from "../pages/runManageMenu/orderDetails/orderDetails";
// import SiyuGroupIndex from '../pages/vip_management/group/sub/siyu_group/index'
import SiyuGroupIndex from '../pages/siyu/index'
import DeepCustomerOperate from "../pages/runManageMenu/deepCustomerOperate/deepCustomerOperate";
import AdverManager from '../pages/adverManager/adverManager';
import ShopManage from "../pages/systemManageMenu/shopManage/shopManage";
import PrintManage from "../pages/systemManageMenu/printManage/printManage";
import SystemManage from "../pages/systemManageMenu/systemManage/systemManage";
import QueueList from '../pages/queue/list'
import StoreManage from "../pages/systemManageMenu/storeManage/storeManage";
import CommentPage from "../pages/systemManageMenu/comment/comment";
import DeskManage from "../pages/systemManageMenu/deskManage/deskManage";
import VolumeCenter from '../pages/volumeCenter/volumCenter'
import MessageManage from "../pages/systemManageMenu/messageManage/messageManage";
import FissionOperation from "../pages/runManageMenu/fissionOperation/fissionOperation";
import WindowManage from '../pages/windowManage/windowManage'
import MemberListIndex from '../pages/vip_management/memberList/index';
import FinaceAndData from "../pages/runManageMenu/finaceandData/finaceAndData";
import DashboardMain from "../pages/runManageMenu/dashboard";
import DevelopPage from "../pages/developPage/developPage";
import PullNewOperation from '../pages/pullNewOperation/pullNewOperation';
import RepurchaseOperation from '../pages/repurchaseOperation/repurchaseOperation';
import FinanceDetail from '../pages/order_manager/order_list/financeDetail/financeDetail'
import IncomeDetail from '../pages/order_manager/order_list/financeDetail/incomeDetail'
import DecorationMain from '../pages/shop/shopDecoration/decorationMain'
import DecorationHomeMain from '../pages/shop/shopDecorationHome/main'

import EditTicket from '../pages/systemManageMenu/printManage/ticketStyle/editTicket/editTicket'

import OtherCapital from '../pages/newBusinessData/otherCapital'
import OtherIncome from '../pages/newBusinessData/otherIncome'
import SysData from '../pages/newBusinessData/sysData'
import MemberManage from '../pages/memberManage/index'

const Shop = () => <h3>商家</h3>;
const Example = () => <h3>账号授权</h3>;

// tab 导航路由数组
const menus = [];
const commentObj  = {

  // 'OrderTimes': OrderTimes,
  // 'OrderManagerOrderList': OrderManagerOrderList,
  // 'MemberList': MemberList,
  // 'MemberDetail': MemberDetail,
  // 'MemberGroupIndex': MemberGroupIndex,//用户分组
  // 'MemberGroupVipList': MemberGroupVipList,//自定义分组用户列表
  // 'MemberGroupVipDetail': MemberGroupVipDetail,//自定义分组用户详情
  // 'MemberGroupCustomAdd': MemberGroupCustomAdd,//新增自定义分组
  // 'SiyuGroupDetail': SiyuGroupDetail,//丝域流量分组详情
  // 'SiyuOfficialAccount': SiyuOfficialAccount, //丝域流量分组公众号详情
  // 'SiyuCommunity': SiyuCommunity, //丝域流量分组社群详情
  // 'SiyuOfficialMemberIndex': SiyuOfficialMemberIndex, //丝域流量公众号用户详情

  // 'SiyuGroupAdd': SiyuGroupAdd,//丝域流量分组用户添加
  // 'MarketingIndex': MarketingIndex,//营销管理/分组运营
  // 'MarketingDetail': MarketingDetail,//运营数据
  // 'MarketingSubDetail': MarketingSubDetail,//运营数据
  // 'MarketingAdd': MarketingAdd,//创建运营活动
  // 'SmsIndex': SmsIndex,//短信记录
  // 'WechatSmsIndex': WechatSmsIndex,//公众号消息模版
  // 'wechatSmsAdd': WechatSmsAdd,//公众号消息模版添加
  // 'WechatSmsGuide': WechatSmsGuide,//公众号消息模版添加
  // 'VipGroup': VipGroup,
  // 'VipLevelConfig': VipLevelConfig,
  // 'RulesConfig': RulesConfig,
  // 'Recharge': Recharge,
  // 'RechargeManage': RechargeManage,
  // 'Coupon': Coupon,
  // // 'PeopleCoupon': PeopleCoupon,
  // 'WelfareList': WelfareList,
  // 'InviteHelpUsers':InviteHelpUsers,
  // 'InviteHelpConfigurationList': InviteHelpConfigurationList,
  // 'DeductionCoupon': DeductionCoupon,
  // 'DiscountCoupon': DiscountCoupon,
  // 'Parinter': Parinter,
  // 'XcxWithdraw': XcxWithdraw,
  // 'WeappDistriution': WeappDistriution,
  // 'WeappDistriutionUsers': WeappDistriutionUsers,
  // 'ShopManagement': ShopManagement,
  // 'ShopList': ShopList,
  // 'StoreUserList': StoreUserList,
  // 'ShopRegion': ShopRegion,
  // 'ShopTable': ShopTable,

  // 'PrinterList': PrinterList,
  // 'PrinterMsgList': PrinterMsgList,

  // 'GoodsSort': GoodsSort,
  // 'GoodsAdd': GoodsAdd,
  // 'Goods': Goods,
  // 'Message': Message,
  // 'MessagePushList': MessagePushList,
  // 'MessageTemplate': MessageTemplate,
  // 'SmallProgram': SmallProgram,
  // 'AccountAuthView': AccountAuthView,
  // 'TaskManage': TaskManage,
  // 'JoinFans': JoinFans,
  // 'DataStatistics': DataStatistics,
  // 'Turnplate': Turnplate,
  // 'Distribution': Distribution,
  // 'DistributionList': DistributionList,
  // 'Order': Order,
  // 'ParticipatingUser': ParticipatingUser,
  // 'Shop': Shop,
  // 'QRCode':QRCode,
  // 'ManageUser': ManageUser,
  // 'SystemRole': SystemRole,
  // // 'IncomeForm': IncomeForm,
  // 'FinanceForm': FinanceForm,
  // 'VegetableSaleTable': VegetableSaleTable,
  // 'SetMeal': SetMeal,
  // 'SetMealAdd': SetMealAdd,
  // 'BuyAndGive': BuyAndGive,
  // 'BuyAndGiveEdit': BuyAndGiveEdit,
  // 'CouponOther': CouponOther,
  // 'PrinterDepart': PrinterDepart,
  // 'PrinterDepartEdit': PrinterDepartEdit,
  // 'CashCoupon': CashCoupon,
  // 'EquityList': EquityList,
  // 'EquityEdit': EquityEdit,
  // 'SendCouponEdit': SendCouponEdit,
  // 'BirthList': BirthList,
  // 'BirthEdit': BirthEdit,
  // 'NewPeople': NewPeople,
  // 'NewPeopleEdit': NewPeopleEdit,
  // // 'HelpList': HelpList,
  // 'HelpEdit': HelpEdit,
  // 'Index': IndexHome,
  // 'EquityCardOrder': EquityCardOrder,
  // 'ChangeDishEdit': ChangeDishEdit,
  // 'ChangeDishList': ChangeDishList,
  // 'HelpCenter': HelpCenter,
  // 'StoreCharge': StoreCharge,
  // 'Notification': Notification,
  // 'UserGroup': UserGroup,
  // 'UserGroupDetail': UserGroupDetail,
  // 'UserGroupAdd': UserGroupAdd,
  // 'ShopAdd': ShopAdd,
  // 'PaymentAdd': PaymentAdd,
  // 'WeappDistriutionAdd': WeappDistriutionAdd,
  // 'WeappDistriutionAdd': WeappDistriutionAdd,
  // 'BindApplet': BindApplet,
  // 'BindPublic': BindPublic,
  // 'StoreAuth' : StoreAuth,
  // 'HelpAdvertManagementList' : HelpAdvertManagementList,
  // 'HelpAdvertManagementListDetail' : HelpAdvertManagementListDetail,
  // 'HelpAdminNum' : HelpAdminNum,
  // 'HelpAdminNumDetail' : HelpAdminNumDetail,
  // 'HelpSystemNum' : HelpSystemNum,
  // 'HelpSystemNumDetail' : HelpSystemNumDetail,
  // 'HelpMealManagement' : HelpMealManagement,
  // 'HelpMealManagementDetail' : HelpMealManagementDetail,
  // 'HelpShopManagement' : HelpShopManagement,
  // 'HelpShopManagementDetail' : HelpShopManagementDetail,
  // 'HelpTypeManagement' : HelpTypeManagement,
  // 'HelpTypeManagementDetail' : HelpTypeManagementDetail,
  // 'HelpLocationManagement' : HelpLocationManagement,
  // 'HelpLocationManagementDetail' : HelpLocationManagementDetail,
  // 'HelpTableManager' : HelpTableManager,
  // 'HelpTableManagerDetail' : HelpTableManagerDetail,
  // 'HelpMbaBirthday' : HelpMbaBirthday,
  // 'HelpMbaBusiness' : HelpMbaBusiness,
  // 'HelpMbaBuyFree' : HelpMbaBuyFree,
  // 'HelpMbaChargeFree' : HelpMbaChargeFree,
  // 'HelpMbaCoupon' : HelpMbaCoupon,
  // 'HelpMbaCourtesy' : HelpMbaCourtesy,
  // 'HelpMbaDiscount' : HelpMbaDiscount,
  // 'HelpMbaExchange' : HelpMbaExchange,
  // 'HelpMbaInterests' : HelpMbaInterests,
  // 'HelpMbaManagementTools' : HelpMbaManagementTools,
  // 'HelpMbaSecurities' : HelpMbaSecurities,
  // 'HelpMbaWelcome' : HelpMbaWelcome,
  // 'HelpStoreManagementList' : HelpStoreManagementList,
  // 'HelpStoreManagementListDetail' : HelpStoreManagementListDetail,
  // 'FinanceBalance': FinanceBalance,
  // 'FinanceCash': FinanceCash,
  // 'Consumption': Consumption,
  // 'ConsumptionAdd': ConsumptionAdd,
  // 'CouponGroup': CouponGroup,
  // 'CouponGroupAdd': CouponGroupAdd,


  // 'ProductIntroduction': ProductIntroduction,
  // 'MatterDown': MatterDown,
  // 'RechargeAddOrEdit': RechargeAddOrEdit,
  // 'ModifyPassword': ModifyPassword,
  // 'BusinessModel': BusinessModel,
  // 'PreferentialSettings': PreferentialSettings,
  // 'SystemSettings': SystemSettings,
  // 'ScoreIndex': ScoreIndex, //积分列表
  // 'ScoreAdd': ScoreAdd, //添加积分活动
  // 'Dashboard': Dashboard, //经营仪表盘
  // 'DashboardList': DashboardList, //设定经营目标
  // 'DashboardAdd': DashboardAdd, //设定经营目标
  // 'DeepOperation': DeepOperation,
  // 'VipList': VipList,



  // 3.0.1tabbar改版
  'Home': Home, // 首页
  'FinaceAndData': FinaceAndData, // 财务和数据
  'IncomeForm': IncomeForm, // 财务和数据
  'OrderDetails': OrderDetails, // 订单明细
  'SiyuGroupIndex': SiyuGroupIndex, //私域流量管理
  'DeepCustomerOperate': DeepCustomerOperate, //客户深度运营

  'FissionOperation': FissionOperation, // 裂变运营
  'WindowManage': WindowManage,
  'VolumeCenter': VolumeCenter,  // 营销工具
  'DashboardMain': DashboardMain,  // 仪表盘


  'AdverManager': AdverManager, // 广告管理
  'ShopManage': ShopManage, // 商品管理
  'PrintManage': PrintManage, // 打印机管理
  'MessageManage': MessageManage, // 消息管理
  'SystemManage': SystemManage, // 系统管理
  'QueueList':QueueList, // 排队管理
  'StoreManage':StoreManage, // 门店管理
  'DeskManage':DeskManage, // 桌台管理
  'MemberListIndex': MemberListIndex,

  'DevelopPage': DevelopPage, //开发中敬请期待
  'PullNewOperation': PullNewOperation, //拉新运营
  'RepurchaseOperation': RepurchaseOperation, //复购营运

  'Comment': CommentPage, //评价管理

  'FinanceDetail': FinanceDetail,  //财务收入详情
  'IncomeDetail': IncomeDetail,   //营业收入详情

  'DecorationMain': DecorationMain, //门店装修--我的--编辑

  'MerchantVoucherCoupon': MerchantVoucherCoupon, //商家优惠券
  'MerchantVoucherCouponAdd': MerchantVoucherCouponAdd, //商家添加优惠券
  'MerchantVoucherDishList': MerchantVoucherDishList, // 商家券-兑换菜
  'MerchantVoucherDishEdit': MerchantVoucherDishEdit, //商家券--兑换菜编辑
  'MerchantVoucherRegister': MerchantVoucherRegister, //商家券--注册有礼
  'MerchantVoucherRegisterEdit': MerchantVoucherRegisterEdit, //商家券--注册有礼编辑
  'MerchantVoucherConsumption': MerchantVoucherConsumption, //商家券--消费有礼
  'MerchantVoucherConsumptionAdd': MerchantVoucherConsumptionAdd, //商家券--消费有礼
  'TaskManage': TaskManage, //任务宝
  'SmallProgram': SmallProgram, //小程序授权
  'AccountAuthView': AccountAuthView, //公众号授权
  'Product': Product, //公众号授权

  'OtherCapital': OtherCapital,
  'OtherIncome': OtherIncome,
  'MemberManage': MemberManage,  //会员管理

}

// 所有路由 数组
const allComments = [
  { path: "/", component: IndexHome },

  // 3.0.1tabbar改版
  { path: "/sidebar/home", component: Home },
  { path: "/sidebar/finaceAndData", component: FinaceAndData },
  { path: "/sidebar/incomeForm", name: "营业收入报表", component: IncomeForm },
  { path: "/sidebar/orderDetails", name: "订单明细", component: OrderDetails },
  { path: '/sidebar/siyuGroupIndex', name: '私域流量管理', component: SiyuGroupIndex },
  { path: '/sidebar/deepCustomerOperate', name: '私域流量管理', component: DeepCustomerOperate },

  { path: '/sidebar/dashboardMain', name: '经营仪表盘', component: DashboardMain },

  { path: '/sidebar/fissionOperation', name: '裂变运营', component: FissionOperation},
  { path: '/sidebar/volumeCenter', name: '营销工具', component: VolumeCenter},


  { path: "/sidebar/adverManager", component: AdverManager},
  { path: "/sidebar/shopManage", component: ShopManage},
  { path: "/sidebar/printManage", component: PrintManage},
  { path: "/sidebar/messageManage", component: MessageManage},
  { path: "/sidebar/systemManage", component: SystemManage},
  { path: "/sidebar/queueList", component: QueueList},
  { path: "/sidebar/storeManage", component: StoreManage},
  { path: "/sidebar/deskManage", component: DeskManage},
  { path: "/sidebar/memberListIndex", component: MemberListIndex},
  { path: "/sidebar/comment", component: CommentPage},


  { path: "/sidebar/developPage", component: DevelopPage},
  { path: "/sidebar/pullNewOperation", component: PullNewOperation},
  { path: "/sidebar/repurchaseOperation", component: RepurchaseOperation},


  { path: "/sidebar/accountAuthView", component: AccountAuthView }, // 公众号授权
  { path: "/sidebar/smallProgram", component: SmallProgram},          // 小程序授权
  { path: "/sidebar/taskManage", component: TaskManage }, //任务宝
  { path: "/sidebar/joinFans", component: JoinFans}, // 参与粉丝
  { path: '/sidebar/dataStatistics', component: DataStatistics}, //数据统计

  { path: "/sidebar/turnplate", component: Turnplate}, // 活动管理
  { path: "/sidebar/turnplatePerson", component: TurnplatePerson}, //参与人员
  { path: "/sidebar/turnplateCreate", component: TurnplateCreate},  // 创建活动
  { path: "/sidebar/turnplateComment", component: TurnplateComment}, //活动评价
  // END 3.0.1tabbar改版
  { path: '/sidebar/deepOperation', name: '用户深度营运', component: DeepOperation },
  { path: "/sidebar/overview", component: OverView },
  { path: "/sidebar/example", component: Example },

  { path: "/sidebar/distribution", component: Distribution },
  { path: "/sidebar/product", component: Product },
  { path: "/sidebar/ProductEdit", component: ProductEdit },
  { path: "/sidebar/create_active", name: '分销活动', component: CreateActive },
  { path: "/sidebar/newTask", component: NewTask },
  { path: "/sidebar/distribution_manager", name: '分销管理', component: DistributionManager},
  { path: "/sidebar/distribution_list", component: DistributionList},

  { path: "/sidebar/qrcode", component: QRCode},
  { path: "/sidebar/order_list", name: '订单列表', component: Order},
  { path: "/sidebar/participating_user", component: ParticipatingUser},
  { path: "/sidebar/withdraw", name: '提现', component: Withdraw},

  { path: "/sidebar/apply_withdrawal", name: '分销提现', component: DistributionApplyWithdrawal},
  { path: "/sidebar/qrcode", component: QRCode},
  { path: "/sidebar/recharge", component: Recharge},

  { path: "/sidebar/order", component: JoinFans},
  { path: "/sidebar/participate_users", component: ParticipatingUser},
  { path: "/sidebar/coupon", component: Coupon },
  // { path: "/sidebar/peopleCoupon", component: PeopleCoupon },
  { path: "/sidebar/sendCoupon", component: SendCoupon },
  { path: "/sidebar/sendCouponEdit", component: SendCouponEdit },
  { path: "/sidebar/couponAdd", component: CouponAdd },
  { path: "/sidebar/goodsList", component: Goods },
  { path: "/sidebar/goodsSort", component: GoodsSort },
  { path: "/sidebar/goodsSortEdit", component: GoodsSortEdit },
  { path: "/sidebar/goodsEdit", component: GoodsEdit },
  { path: "/sidebar/goodsAdd", component: GoodsAdd },
  { path: "/sidebar/shopTable", component: ShopTable},
  { path: "/sidebar/shopList", component: ShopList},
  { path: "/sidebar/shopRegion", component: ShopRegion},
  { path: "/sidebar/shopTableEdit", component: ShopTableEdit},

  { path: "/sidebar/order_manager_orderlist", component: OrderManagerOrderList },
  { path: "/sidebar/xcxWithdraw", component: XcxWithdraw },
  { path: '/sidebar/weapp_distriutions', name: '分销活动', component: WeappDistriution},
  { path: '/sidebar/weapp_distriution_config', name: '分销配置', component: WeappDistriutionConfig},
  { path: '/sidebar/weapp_distriutions_users', component: WeappDistriutionUsers},
  { path: "/sidebar/rechargeManage", component: RechargeManage },
  { path: "/sidebar/memberList", name: '会员列表', component: MemberList},
  { path: "/sidebar/memberDetail", name: '会员详情', component: MemberDetail},
  { path: "/sidebar/memberGroupIndex", name: '', component: MemberGroupIndex},//用户分组
  { path: "/sidebar/memberGroupVipList", name: '', component: MemberGroupVipList},//自定义分组用户列表
  { path: "/sidebar/memberGroupVipDetail", name: '', component: MemberGroupVipDetail},//自定义分组用户详情
  { path: "/sidebar/memberGroupCustomAdd", name: '', component: MemberGroupCustomAdd},//新增自定义分组
  { path: "/sidebar/siyuGroupDetail", name: '', component: SiyuGroupDetail},//丝域流量分组详情
  { path: "/sidebar/siyuOfficialAccount", name: '', component: SiyuOfficialAccount},//丝域流量分组公众号详情
  { path: "/sidebar/siyuCommunity", name: '', component: SiyuCommunity},//丝域流量分组社群详情
  { path: "/sidebar/siyuOfficialMemberIndex", name: '', component: SiyuOfficialMemberIndex},//丝域流量公众号详情

  { path: "/sidebar/siyuGroupAdd", name: '', component: SiyuGroupAdd},//丝域流量分组用户添加
  { path: "/sidebar/marketingIndex", name: '', component: MarketingIndex},//营销管理/分组运营
  { path: "/sidebar/marketingDetail", name: '', component: MarketingDetail},//运营数据
  { path: "/sidebar/marketingSubDetail", name: '', component: MarketingSubDetail},//运营数据
  { path: "/sidebar/marketingAdd", name: '', component: MarketingAdd},//创建运营活动
  { path: "/sidebar/smsIndex", name: '', component: SmsIndex},//短信记录
  { path: "/sidebar/wechatSmsIndex", name: '', component: WechatSmsIndex},//微信公众号模版消息
  { path: "/sidebar/wechatSmsAdd", name: '', component: WechatSmsAdd},//微信公众号模版消息批量添加
  { path: "/sidebar/wechatSmsGuide", name: '', component: WechatSmsGuide},//微信公众号模版操作指引


  { path: "/sidebar/message", component: Message},
  { path: "/sidebar/messagePushList", component: MessagePushList},
  { path: "/sidebar/messageTemplate", component: MessageTemplate},

  { path: "/sidebar/adverEdit", component: AdverEdit},
  // { path: "/sidebar/welfare", component: WelfareList},
  { path: "/sidebar/welfareEdit", component: WelfareEdit},
  { path: "/sidebar/printer", component: PrinterList},
  { path: "/sidebar/printerEdit", component: PrinterEdit},
  { path: "/sidebar/financial_management", name: '财务管理', component: FinancialManagement},
  { path: '/sidebar/shop_management', name: '店铺管理', component: ShopManagement},
  { path: '/sidebar/vip_cofing', name: '会员配置', component: RulesConfig},
  { path: '/sidebar/vip_level_config', name: '会员等级配置', component: VipLevelConfig},
  { path: '/sidebar/edit_vip_level', name: '编辑会员等级', component: EditVipLevel},
  { path: '/sidebar/printerMsgList', component: PrinterMsgList},
  { path: '/sidebar/addStoreUser', component: AddStoreUser},
  { path: '/sidebar/storeUserList', component: StoreUserList},
  { path: '/sidebar/configuration_list', name: '邀请配置列表', component: InviteHelpConfigurationList },
  { path: '/sidebar/invite_help_users', name: '邀请用户列表', component: InviteHelpUsers },
  { path: '/sidebar/add_invite_help_configuration', name: '添加邀请配置', component: AddInviteHelpConfiguratin },
  { path: '/sidebar/parinter', component: Parinter},
  { path: '/sidebar/discountCoupon', name: '结账打折列表', component: DiscountCoupon},
  { path: '/sidebar/discountAdd', name:'添加结账打折', component: DiscountAdd},
  { path: '/sidebar/deductionCoupon', name: '抵扣券列表', component: DeductionCoupon},
  { path: '/sidebar/deductionAdd', name: '添加抵扣券', component: DeductionAdd},
  { path: '/sidebar/windowManage', component: WindowManage},
  { path: '/sidebar/settleAccounts', name:'结账页面', component: SettleAccounts },
  { path: '/sidebar/orderingFood', component: OrderingFood},
  { path: "/sidebar/order_times", name: "实时消息", component: OrderTimes },
  { path: "/sidebar/vip_group", name: "会员分组", component: VipGroup },
  { path: "/sidebar/add_vip_group", name: "添加会员分组", component: AddVipGroup },
  { path: "/sidebar/manageUser", name: "管理者账户", component: ManageUser },
  { path: "/sidebar/systemRole", name: "管理者账户", component: SystemRole },
  { path: "/sidebar/addRole", name: "添加用户", component: AddRole },
  { path: "/sidebar/addManageUser", name: "添加用户", component: AddManageUser },
  { path: '/sidebar/changeTable', name: '换桌', component: ChangeTable },
  { path: '/sidebar/mergeTable', name: '合桌', component: MergeTable },
  { path: "/sidebar/stockout", name: "沽清", component: Stockout },
  { path: "/sidebar/vegetable_sale_table", name: "菜品销售报表", component: VegetableSaleTable },
  // { path: "/sidebar/incomeForm", name: "营业收入报表", component: IncomeForm },
  { path: "/sidebar/group_members", name: "会员分组管理", component: GroupMembers },
  { path: "/sidebar/financeForm", name: "营业收入财务报表", component: FinanceForm },
  { path: "/sidebar/setMeal", name: "套餐管理", component: SetMeal },
  { path: '/sidebar/setMealAdd', name: '套餐管理新增', component: SetMealAdd},
  { path: '/sidebar/buyAndGive', name: '买赠', component: BuyAndGive},
  { path: '/sidebar/buyAndGiveEdit', name: '买赠编辑', component: BuyAndGiveEdit},
  { path: '/sidebar/vouchers_list', name: '代金券列表', component: VouchersList},
  { path: '/sidebar/add_vouchers', name: '添加代金券', component: AddVouchers},
  // { path: '/sidebar/couponEntrance', name: '优惠券', component: CouponEntrance},
  { path: '/sidebar/couponOther', name: '其它优惠', component: CouponOther },
  { path: '/sidebar/printerDepart', name: '打印部门', component: PrinterDepart },
  { path: '/sidebar/printerDepartEdit', name: '打印部门编辑', component: PrinterDepartEdit },
  { path: '/sidebar/cashCouponList', name: '代金券订单列表', component: CashCoupon },
  { path: '/sidebar/equityList', name: '权益卡', component: EquityList},
  { path: '/sidebar/equityEdit', name: '添加权益卡', component: EquityEdit},
  { path: '/sidebar/birthEdit', name: '生日福利编辑', component: BirthEdit},
  { path: '/sidebar/birthList', name: '生日福利列表', component: BirthList},
  { path: '/sidebar/newPeople', name: '新人福利', component: NewPeople},
  { path: '/sidebar/newPeopleEdit', name: '新人福利编辑', component: NewPeopleEdit},
  // { path: '/sidebar/HelpList', name: '助力有礼列表', component: HelpList},
  { path: '/sidebar/HelpEdit', name: '助力有礼编辑', component: HelpEdit},
  { path: '/sidebar/changeDishEdit', name: '兑菜券编辑', component: ChangeDishEdit},
  { path: '/sidebar/changeDishList', name: '兑菜券列表', component: ChangeDishList},
  { path: '/sidebar/helpCenter', name: '助力有礼', component: HelpCenter },

  { path: '/sidebar/index',name: '首页', component: IndexHome},
  { path: '/sidebar/EquityCardOrder',name: '权益卡订单', component: EquityCardOrder},
  { path: '/sidebar/storeCharge', name: '商家充值金额', component: StoreCharge },

  { path: '/sidebar/notification', name: '打印机通知消息', component: Notification },
  { path: '/sidebar/chargeFree', name: '充值免单', component: ChargeFree },
  { path: '/sidebar/chargeFreeEdit', name: '商家充值金额', component: ChargeFreeEdit },
  { path: '/sidebar/userGroup', name: '用户分群', component: UserGroup },
  { path: '/sidebar/userGroupDetail', name: '用户分群详情', component: UserGroupDetail},
  { path: '/sidebar/userGroupAdd', name: '添加用户分群', component: UserGroupAdd },
  { path: '/sidebar/shopAdd', name: '添加门店', component: ShopAdd},
  { path: '/sidebar/paymentAdd', name: '添加支付方式', component: PaymentAdd},
  {path: '/sidebar/detail_group_members', name: '用户分组详情', component: DeatilGroupMembers},

  { path: '/sidebar/bindPublic', name: '公众号绑定', component: BindPublic },
  { path: '/sidebar/bindApplet', name: '小程序绑定', component:  BindApplet},
  { path: '/sidebar/storeAuth', name: '商家认证', component:  StoreAuth},
  { path: '/sidebar/WeappDistriutionAdd', name: '添加分销配置', component: WeappDistriutionAdd },
  { path: '/sidebar/HelpAdvertManagementList', name: '广告列表', component: HelpAdvertManagementList }, //广告列表
  { path: '/sidebar/HelpAdvertManagementListDetail', name: 'HelpAdvertManagementListDetail', component: HelpAdvertManagementListDetail },
  { path: '/sidebar/HelpAdminNum', name: '管理者账号', component: HelpAdminNum }, //管理者账号
  { path: '/sidebar/HelpAdminNumDetail', name: 'HelpAdminNumDetail', component: HelpAdminNumDetail },
  { path: '/sidebar/HelpSystemNum', name: '系统角色', component: HelpSystemNum }, //系统角色
  { path: '/sidebar/HelpSystemNumDetail', name: 'HelpSystemNumDetail', component: HelpSystemNumDetail },
  { path: '/sidebar/HelpMealManagement', name: '套餐管理', component: HelpMealManagement },  //套餐管理
  { path: '/sidebar/HelpMealManagementDetail', name: 'HelpMealManagementDetail', component: HelpMealManagementDetail },
  { path: '/sidebar/HelpShopManagement', name: '商品管理', component: HelpShopManagement }, //商品管理
  { path: '/sidebar/HelpShopManagementDetail', name: 'HelpShopManagementDetail', component: HelpShopManagementDetail },
  { path: '/sidebar/HelpTypeManagement', name: '分类管理', component: HelpTypeManagement }, //分类管理
  // { path: '/sidebar/HelpTypeManagement', name: '加料管理', component: HelpTypeManagement }, //加料管理
  { path: '/sidebar/HelpTypeManagementDetail', name: 'HelpTypeManagementDetail', component: HelpTypeManagementDetail },
  { path: '/sidebar/HelpLocationManagement', name: '位置管理', component: HelpLocationManagement }, // 位置管理
  { path: '/sidebar/HelpLocationManagementDetail', name: 'HelpLocationManagementDetail', component: HelpLocationManagementDetail },
  { path: '/sidebar/HelpTableManager', name: '餐桌管理', component: HelpTableManager }, // 餐桌管理
  { path: '/sidebar/HelpTableManagerDetail', name: 'HelpTableManagerDetail', component: HelpTableManagerDetail },
  { path: '/sidebar/HelpMbaBirthday', name: '生日礼物', component: HelpMbaBirthday }, //生日礼物
  { path: '/sidebar/HelpMbaBusiness', name: '商家工具', component: HelpMbaBusiness }, // 商家工具
  { path: '/sidebar/HelpMbaBuyFree', name: '添加买赠', component: HelpMbaBuyFree }, //添加买赠
  { path: '/sidebar/HelpMbaChargeFree', name: '充值免单', component: HelpMbaChargeFree }, //充值免单
  { path: '/sidebar/HelpMbaCoupon', name: '添加代金券', component: HelpMbaCoupon }, //添加代金券
  { path: '/sidebar/HelpMbaCourtesy', name: '助力有礼', component: HelpMbaCourtesy }, // 助力有礼
  { path: '/sidebar/HelpMbaDiscount', name: '添加优惠券', component: HelpMbaDiscount }, //添加优惠券
  { path: '/sidebar/HelpMbaExchange', name: '添加兑换', component: HelpMbaExchange }, //添加兑换
  { path: '/sidebar/HelpMbaInterests', name: '添加权益', component: HelpMbaInterests }, // 添加权益
  { path: '/sidebar/HelpMbaManagementTools', name: '营销工具', component: HelpMbaManagementTools }, //营销工具
  { path: '/sidebar/HelpMbaSecurities', name: '直接发券', component: HelpMbaSecurities }, //直接发券
  { path: '/sidebar/HelpMbaWelcome', name: '进店有礼', component: HelpMbaWelcome }, //进店有礼
  { path: '/sidebar/HelpStoreManagementList', name: '门店列表', component: HelpStoreManagementList }, // 门店列表
  { path: '/sidebar/HelpStoreManagementListDetail', name: 'HelpStoreManagementListDetail', component: HelpStoreManagementListDetail },
  { path: '/sidebar/FinanceBalance', name: '会员余额消费对账', component: FinanceBalance },
  { path: '/sidebar/financeCash', name: '代金券消费对账', component: FinanceCash },

  { path: '/sidebar/consumption', name: '消费有礼', component: Consumption },
  { path: '/sidebar/consumptionAdd', name: '消费有礼添加', component: ConsumptionAdd },
  { path: '/sidebar/couponGroup', name: '优惠券用户添加分组', component: CouponGroup},
  { path: '/sidebar/couponGroupAdd', name: '优惠券用户添加分组', component: CouponGroupAdd },
  { path: '/sidebar/productIntroduction', name: '产品介绍', component: ProductIntroduction },

  { path: '/sidebar/matterDown', name: '物料下载', component: MatterDown},
  { path: '/sidebar/rechargeAddOrEdit', name: '充值配置编辑', component: RechargeAddOrEdit },

  { path: '/sidebar/ModifyPassword', name: '修改密码', component: ModifyPassword },

  { path: '/sidebar/BusinessModel', name: '营业模式', component: BusinessModel },
  { path: '/sidebar/PreferentialSettings', name: '优惠限制设置', component: PreferentialSettings },
  { path: '/sidebar/SystemSettings', name: '系统设置', component: SystemSettings },

  { path: '/sidebar/scoreIndex', name: '积分商城', component: ScoreIndex },
  { path: '/sidebar/scoreAdd', name: '积分商城', component: ScoreAdd },
  

  { path: '/sidebar/smartPushTicket', name: '积分商城', component: SmartPushTicket },
  { path: '/sidebar/smartPushTicketAdd', name: '积分商城', component: SmartPushTicketAdd },

  { path: '/sidebar/vipList', name: 'VIP列表', component: VipList },

  { path: '/sidebar/dashboard', name: '经营仪表盘', component: Dashboard },
  { path: '/sidebar/dashboardList', name: '设定经营目标', component: DashboardList },
  { path: '/sidebar/dashboardAdd', name: '设定经营目标', component: DashboardAdd },

  { path: '/sidebar/depositIndex', name: '存酒/存物', component: DepositIndex },
  { path: '/sidebar/depositAdd', name: '添加寄存', component: DepositAdd },
  { path: '/sidebar/depositPrintSetting', name: '单据打印设置', component: DepositPrintSetting },
  { path: '/sidebar/depositmsgSetting', name: '信息提醒设置', component: DepositMsgSetting },
  {path: '/sidebar/financeDetail', name: '财务收入详情', component: FinanceDetail},
  {path: '/sidebar/incomeDetail', name: '营业收入优惠详情', component: IncomeDetail},
  {path: '/sidebar/editTicket', name: '编辑小票样式', component: EditTicket},

  {path: '/sidebar/decorationMain', name: '门店装修编辑', component: DecorationMain},
  {path: '/sidebar/decorationHomeMain', name: '门店装修首页', component: DecorationHomeMain},


  {path: '/sidebar/otherCapital', name: '新经营数据-其它资金', component: OtherCapital},
  {path: '/sidebar/otherIncome', name: '新经营数据-其它收入', component: OtherIncome},
  {path: '/sidebar/memberManage', name: '实时桌面-会员管理', component: MemberManage},
  {path: '/sidebar/sysData', name: '新经营数据-系统收款', component: SysData},

  {path: '/sidebar/merchantVoucherCoupon', name: '商家券优惠券', component: MerchantVoucherCoupon},
  {path: '/sidebar/merchantVoucherCouponAdd', name: '商家券添加优惠券', component: MerchantVoucherCouponAdd},
  {path: '/sidebar/merchantVoucherDishList', name: '商家券兑换菜', component: MerchantVoucherDishList},
  {path: '/sidebar/merchantVoucherDishEdit', name: '商家券兑换菜编辑', component: MerchantVoucherDishEdit},
  {path: '/sidebar/merchantVoucherRegister', name: '商家券注册有礼', component: MerchantVoucherRegister},
  {path: '/sidebar/merchantVoucherRegisterEdit', name: '商家券注册有礼编辑', component: MerchantVoucherRegisterEdit},
  {path: '/sidebar/merchantVoucherConsumption', name: '商家券消费有礼', component: MerchantVoucherConsumption},
  {path: '/sidebar/merchantVoucherConsumptionAdd', name: '商家券消费有礼编辑', component: MerchantVoucherConsumptionAdd},
];

export const routerConfig = {
  menus,
  allComments,
  commentObj
};

