import React, { useState, useEffect } from "react";
import { Row, Tabs, Spin, Table, Avatar, Button, Modal, Tooltip, message } from "antd";
import OrderTimesFilterRow from "./components/filter_nav/filter_nav";
import { getRequest, postRequest } from "../../../api/http";
import { apiUrl } from "../../../api/api_url";
import OrderDetailModal from "./order_detail_modal";
import { getIsPermisson, messageCustom } from "../../../utils/permisstionUtil";

const { TabPane } = Tabs;

interface IpageData {
  count: number;
  // current_page: string,
  list: Array<{
    code: string;
    consume_time: number;
    eat_at: string;
    fan_id: number;
    finished: number;
    headimgurl: string;
    id: number;
    is_read: number;
    level: number;
    member_level_name: string;
    name: string;
    next_consume_days: number;
    nickname: string;
    origin_fee: string;
    remark_name: string;
    active: boolean;
    invitor: string;
    pay_amount: string;
    status_display: string;
  }>;
}

const OrderTimes = () => {
  // const [currentPage, setcurrentPage] = useState(1);
  const [loadingData, setloadingData] = useState(true);
  const [visibleDetail, setvisibleDetail] = useState(false);
  const [currentCode, setcurrentCode] = useState("");
  const [sendDate, setSendDate] = useState({});
  const [currentTab, setcurrentTab] = useState<1 | 2>(1);
  const [pageData, setpageData] = useState<IpageData>({
    count: 0,
    list: [],
  });
  const [fliterData, setfliterData] = useState({
    member_level: 0,
    member_group: 0,
    order_status: 0,
  });
  const [page, setpage] = useState(1)
  const [page_size, setpage_size] = useState(10)

  useEffect(() => {
    getOrderList(1, 1);
  }, []);

  const callback = (key: any) => {
    setcurrentTab(key)
    getOrderList(1, key);
    setpage_size(10)
    setpage(1)
  };

  const searchData = (filter: any, key: 1 | 2) => {
    const _filter = JSON.parse(JSON.stringify(filter));
    Object.keys(_filter).map(el => {
      if(_filter[el] === "") delete _filter[el];
    })
    setfliterData(_filter);
    getOrderList(1, currentTab, { page: 1, ..._filter });
  };

  // 获取订单列表
  const getOrderList = async ( page: number, type: 1 | 2, parms = {}) => {
    setpage(page)
    const data = {
      page,
      type,
      page_size,
      ...parms
    };
    setloadingData(true);
    const res: any = await getRequest(apiUrl.messageOrderList, data);
    if (res && res.data) {
      console.log('currentPage------------', page)
      if (page < 2) {
        res.data.list.map((el: any) => (el.active = false));
        setpageData(res.data);
      } else {
        res.data.list.map((el: any) => (el.active = false));
        setpageData({...res.data});
        // setpageData({
        //   ...res.data,
        //   list: [...pageData.list, ...res.data.list],
        // });
      }
    }
    setloadingData(false);
  };

  // 打印结账单
  const printStatement = async (rowdata: any) => {
    if(!getIsPermisson('1_11_1_2')){
      messageCustom();
      return;
    }
    console.log('row', rowdata);
    const res:any = await postRequest(apiUrl.printBill, { code: rowdata.code, group_key: rowdata.group_key, has_invoice: false});
    if(res && res['code'] === 1) {
      message.success('打印成功');
    }
  }

  const columns = [
    {
      title: "序号",
      key: "xuhao",
      width: 50,
      render: (text: any, record: any, index: number) => index + 1,
    },
    {
      title: "用户ID",
      dataIndex: "fan_id",
      key: "fan_id",
    },
    {
      title: "桌号",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "用户",
      key: "user",
      render: (rowdata: any) => {
        if (rowdata) {
          return (
            <div>
                <div><Avatar
                  src={rowdata["headimgurl"]}
                  size={30}></Avatar>
                 </div>
                <div>
                    {
                      rowdata["remark_name"]
                        ? rowdata["remark_name"]
                        : rowdata["nickname"]
                    }
                  </div>
            </div>
            // <Row type="flex" justify="start">
            //   <Avatar
            //     src={rowdata["headimgurl"]}
            //     size={30}
            //     style={{ marginRight: 10 }}
            //   />{" "}
            //   {rowdata["remark_name"]
            //     ? rowdata["remark_name"]
            //     : rowdata["nickname"]}
            // </Row>
          );
        }
        return <Row></Row>;
      },
    },
    {
      title: "用户等级",
      dataIndex: "member_level_name",
      key: "member_level_name",
    },
    {
      title: "用户分组",
      dataIndex: "groups",
      key: "groups",
    },
    {
      title: "就餐次数",
      dataIndex: "consume_time",
      key: "consume_time",
    },
    {
      title: "距离上次(天)",
      width: 100,
      dataIndex: "next_consume_days",
      key: "next_consume_days",
    },
    {
      title: "金额",
      key: "origin_fee",
      render: (rowdata: any) => {
        if (!rowdata) return <Row></Row>;
        return <Row>￥{rowdata["origin_fee"]}</Row>;
      },
    },
    {
      title: "下单时间",
      dataIndex: "eat_at",
      key: "eat_at",
    },{
      title: "订单状态",
      dataIndex: "status_display",
      key: "status_display",
    },
    {
      title: "备注",
      key: "remark",
      width: 100,
      ellipsis: true,
      render: (rowdata: any) => {
        if(!rowdata) return <Row/>;
        return <Row>
          <Tooltip placement="topLeft" title={`${rowdata.remark}`}>
          {rowdata.remark}
          </Tooltip>
        </Row>
      }
    },
    {
      title: "操作",
      key: "action",
      render: (rowdata: any) => {
        if (!rowdata) return <Row></Row>;
        return (
          <Row>
            <Button
              type="link"
              onClick={() => {
                if(!getIsPermisson('1_11_1_1')){
                  messageCustom();
                  return;
                }
                setcurrentCode(rowdata["code"]);
                setSendDate(rowdata);
                setvisibleDetail(true);
              }}
            >
              详情
            </Button>
            {
              rowdata.status == 5
              ? <Button type="link" onClick={() => printStatement(rowdata)}>补打结账单</Button>
              : <Button type="link" style={{color: '#ccc', cursor: 'default'}}>补打结账单</Button>
            }
          </Row>
        );
      },
    },
  ];

  return (
    <Row className='l_content'>
      <Tabs defaultActiveKey="1" onChange={callback} animated={false}>
        <TabPane tab="实时订单" key="1">
          <OrderTimesFilterRow
            currentTab={currentTab}
            search={(filter: any) => searchData(filter, 1)}
          />
          <Table
            key={1}
            size="small"
            columns={columns}
            loading={loadingData}
            rowKey={'id'}
            dataSource={pageData.list}
            pagination={{
              current: page,
              total: pageData.count,
              onChange: (e) => getOrderList(e, 1, fliterData),
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total: number) => `共${total}条`,
              onShowSizeChange: (current:number, size: number) => {setpage_size(size); getOrderList(page, 1, {page_size: size})}
            }}
            style={{ marginTop: 30 }}
          />
        </TabPane>
        <TabPane tab="区别对待" key="2">
          <OrderTimesFilterRow
            currentTab={currentTab}
            search={(filter: any) => searchData(filter, 2)}
          />
          <Table
            key={2}
            size="small"
            loading={loadingData}
            columns={columns}
            rowKey={'code'}
            dataSource={pageData.list}
            style={{ marginTop: 30 }}
            pagination={{
              current: page,
              total: pageData.count,
              onChange: (e) => getOrderList(e, 2, fliterData),
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total: number) => `共${total}条`,
              onShowSizeChange: (current:number, size: number) => {setpage_size(size); getOrderList(page, 2, {page_size: size})}
            }}
          />
        </TabPane>
      </Tabs>

      <Modal
        title="详情"
        visible={visibleDetail}
        onOk={() => setvisibleDetail(false)}
        onCancel={() => setvisibleDetail(false)}
        okText="确认"
        cancelText="取消"
        width="1056px"
        destroyOnClose
        footer={null}
        centered={true}
      >
        <OrderDetailModal code={currentCode} send={sendDate} />
      </Modal>
    </Row>
  );
};

export default OrderTimes;
