import React, { useEffect, useState } from 'react'
import { Modal, message } from 'antd';
import './addAttributeDich.scss'
import { Add, Minus, keepTwoNum } from '../../utils/numUtils';
const key = 'addAttribute';
const AddAttributeDich = (props: any) => {
    const [data,setdata] = useState<any>({})

    useEffect(()=>{
        // if(props.attributeData && props.attributeData.data){
        //     console.log(props.attributeData.data);
        //     if(props.attributeData.data.selectedDish && props.attributeData.data.selectedDish[0].name_id){
        //         let isArr = props.attributeData.data.selectedDish[0].name_id.split('_')
        //         props.attributeData.data.specifications.map((item: any) => {
        //             item.values.map((item2:any) => {
        //                 item2.sel = false
        //                 isArr.map((item3: any) => {
        //                     if(item2.id == item3){
        //                         item2.sel = true
        //                     }
        //                 })
        //             })
        //         })
        //     }

        //     if(props.attributeData.data.selectedDish && props.attributeData.data.selectedDish[0].weigh_value_id){
        //         let id = props.attributeData.data.selectedDish[0].weigh_value_id;
        //         props.attributeData.data.weigh[0].values.map((item: any) => {
        //             item.sel = false;
        //             if(item.id == id){
        //                 item.sel = true;
        //             }
        //         })
        //     }

        //     if(props.attributeData.data.selectedDish && props.attributeData.data.selectedDish[0].material_id){
        //         let ids = props.attributeData.data.selectedDish[0].material_id;
        //         props.attributeData.data.material_info.map((item: any) => {
        //             item.sel = false;
        //             ids.map((id: any) => {
        //                 if(item.id == id){
        //                     item.sel = true;
        //                 }
        //             })
        //         })
        //     }
        // }
        setdata(props.attributeData)
    })

    const onOKAction = () => {
        let dichId:any = []
        if(data.data.specifications){
            data.data.specifications.map((item:any) => {
                item.values.map((itemA:any) => {
                    if(itemA.sel === true){
                        dichId.push(itemA.id)
                    }
                })
            })
        }

        let flag = false;
        let weighItem:any = {};
        if(data.data.weigh){
            data.data.weigh.map((item:any) => {
                item.values.map((itemA:any) => {
                    if(itemA.sel){
                        flag = true;
                        weighItem = itemA;
                    }
                })
            })
        }

        if(data.data.specifications && data.data.specifications.length !== dichId.length){
            //请选择完整的规格
            message.error({ content: '请选择完整的规格!', key, duration: 2 });
            return false
        }
        if(data.data.weigh && !flag){
            message.error({ content: '请选择完整的规格!', key, duration: 2 });
            return false
        }

        let nameId = dichId.join('_')
        let selectedDichAttr:any = {}
        if(data.data.attribute){
            data.data.attribute.map((item:any) => {
                if(item.name_id === nameId){
                    selectedDichAttr = item
                    if(data.data.weigh && flag){
                        selectedDichAttr.weigh_value_id = weighItem.id;
                        selectedDichAttr.weigh_name = weighItem.name;
                        selectedDichAttr.price = weighItem.price;
                        selectedDichAttr.price_vip = weighItem.price_vip;
                    }
                    if(data.data.material_info && data.data.material_info.length > 0){
                        let arr:any = [];
                        let ids:any = [];
                        data.data.material_info.map((item:any) => {
                            if(item.sel){
                                selectedDichAttr.price = keepTwoNum(Add(selectedDichAttr.price, item.material_price))
                                if(selectedDichAttr.price_vip){
                                    selectedDichAttr.price_vip = keepTwoNum(Add(selectedDichAttr.price_vip, item.material_price))
                                }

                                arr.push(item.material_name)
                                ids.push(item.id)
                            }
                        })
                        selectedDichAttr.material_name = arr;
                        selectedDichAttr.material_id = ids;
                    }
                }
            })
        }
        if(data.data.weigh && flag){
            selectedDichAttr.weigh_value_id = weighItem.id;
            selectedDichAttr.weigh_name = weighItem.name;
            selectedDichAttr.price = weighItem.price;
            selectedDichAttr.price_vip = weighItem.price_vip;
            if(data.data.material_info && data.data.material_info.length > 0){
                let arr:any = [];
                let ids:any = [];
                data.data.material_info.map((item:any) => {
                    if(item.sel){
                        selectedDichAttr.price = keepTwoNum(Add(selectedDichAttr.price, item.material_price))
                        if(selectedDichAttr.price_vip){
                            selectedDichAttr.price_vip = keepTwoNum(Add(selectedDichAttr.price_vip, item.material_price))
                        }

                        arr.push(item.material_name)
                        ids.push(item.id)
                    }
                })
                selectedDichAttr.material_name = arr;
                selectedDichAttr.material_id = ids;
            }
        }
        // if(!data.data.attribute && !data.data.weigh){
        //     selectedDichAttr.price = data.data.price;
        //     selectedDichAttr.price_vip = data.data.price_vip;
        // }
        if(!data.data.attribute && !data.data.weigh && data.data.material_info && data.data.material_info.length > 0){
            let arr:any = [];
            let ids:any = [];
            let price = 0;
            selectedDichAttr.price = data.data.price;
            selectedDichAttr.price_vip = data.data.price_vip;
            data.data.material_info.map((item:any) => {
                if(item.sel){
                    price = keepTwoNum(Add(price, item.material_price))
                    arr.push(item.material_name)
                    ids.push(item.id)
                }
            })
            // selectedDichAttr.price = keepTwoNum(Add(selectedDichAttr.price, price))
            // if(selectedDichAttr.price_vip){
            //     selectedDichAttr.price_vip = keepTwoNum(Add(selectedDichAttr.price_vip, price))
            // }

            selectedDichAttr.material_name = arr;
            selectedDichAttr.material_id = ids;
        }

        console.log('selectedDichAttr',selectedDichAttr,data.data.attribute)
        return selectedDichAttr
    }

    const clickValues = (index:number,valuesIndex:any) => {
        let newData = data
        newData.data.specifications[index].values.map((item:any,vindex:number) => {
            newData.data.specifications[index].values[vindex].sel = undefined
        })
        newData.data.specifications[index].values[valuesIndex].sel = true
        setdata(newData)
        refreshAction()
    }

    const clickWeigh = (index:number,valuesIndex:any) => {
        let newData = data
        newData.data.weigh[index].values.map((item:any,vindex:number) => {
            newData.data.weigh[index].values[vindex].sel = undefined
        })
        newData.data.weigh[index].values[valuesIndex].sel = true
        newData.data.price = newData.data.weigh[index].values[valuesIndex].price
        newData.data.price_vip = newData.data.weigh[index].values[valuesIndex].price_vip
        if(newData.data.material_info && newData.data.material_info.length > 0){
            newData.data.material_info.map((item:any) => {
                if(item.sel){
                    newData.data.price = keepTwoNum(Add(newData.data.price, item.material_price))
                }
            })
        }
        setdata(newData)
        refreshAction()
    }

    const clickMaterial = (valuesIndex:any) => {
        let newData = data
        let sel = newData.data.material_info[valuesIndex].sel;
        newData.data.material_info[valuesIndex].sel = !sel
        if(sel){
            newData.data.price = keepTwoNum(Minus(newData.data.price, newData.data.material_info[valuesIndex].material_price))
            newData.data.price_vip = keepTwoNum(Minus(newData.data.price_vip, newData.data.material_info[valuesIndex].material_price))
        }else{
            newData.data.price = keepTwoNum(Add(newData.data.price, newData.data.material_info[valuesIndex].material_price))
            newData.data.price_vip = keepTwoNum(Add(newData.data.price_vip, newData.data.material_info[valuesIndex].material_price))
        }
        setdata(newData)
        refreshAction()
    }

    useEffect(()=>{
    },[data])

    //用于刷新的方法
    const [refresh,setRefresh] = useState(false);
    const refreshAction = () => {
        if(refresh === true){
            setRefresh(false)
        }else{
            setRefresh(true)
        }
    }
    useEffect(()=>{},[refresh])

    return (
        <div>
            <Modal
                title={'菜品'}
                visible={data.visible}
                onOk={()=>{data.onOk(onOKAction())}}
                onCancel={data.onCancel}
                okText="确认"
                cancelText="取消"
                closable={false}
                destroyOnClose={false}
            >
                <div className='dish_desc'>菜品名称：{data.data ? data.data.name : ''}</div>
                {
                    data.data && data.data.is_vip_set && props.is_vip_fans
                    ? <div className='dish_desc' style={{textDecoration: data.data.is_vip_set ? 'line-through' : 'none'}}>菜品售价：￥{data.data.price ? data.data.price : ''}</div>
                    : data.data ? <div className='dish_desc'>菜品售价：￥{data.data.price ? data.data.price : ''}</div> : null
                }
               { data.data && data.data.selectedDish && data.data.is_vip_set && props.is_vip_fans && <div className='dish_desc'>菜品会员价：￥{data.data.price_vip ? data.data.price_vip : '--'}</div>}

                <div className="addAttributeDich">
                    {
                        data.data && data.data.specifications ?
                        data.data.specifications.map((item:any,index:number) => {
                            return(
                                <div className="container" key={item.id}>
                                    <div className="title">{item.name}：</div>
                                    <div className="list">
                                    {item.values.map((values:any,valuesIndex:number) => {
                                        return(
                                            <div className={values.sel ? "item sel": 'item'} key={values.id} onClick={()=>{clickValues(index,valuesIndex)}}>{values.name}</div>
                                        )
                                    })}
                                    </div>
                                </div>
                            )
                        })
                        : null
                    }
                </div>

                <div className="addAttributeDich">
                    {
                        data.data && data.data.weigh ?
                        data.data.weigh.map((item:any,index:number) => {
                            return(
                                <div className="container" key={item.id}>
                                    <div className="title">{item.name}：</div>
                                    <div className="list">
                                    {item.values.map((values:any,valuesIndex:number) => {
                                        return(
                                            <div className={values.sel ? "item sel": 'item'} key={values.id} onClick={()=>{clickWeigh(index,valuesIndex)}}>{values.name}</div>
                                        )
                                    })}
                                    </div>
                                </div>
                            )
                        })
                        : null
                    }
                </div>

                <div className="addAttributeDich">
                    {
                        data.data && data.data.material_info ?
                        // data.data.material_info.map((item:any,index:number) => {
                            // return(
                                <div className="container">
                                    <div className="title">{data.data.material_name}：</div>
                                    <div className="list">
                                    {data.data.material_info.map((values:any,valuesIndex:number) => {
                                        return(
                                            <div className={values.sel ? "item sel": 'item'} key={values.id} onClick={()=>{clickMaterial(valuesIndex)}}>{values.material_name}</div>
                                        )
                                    })}
                                    </div>
                                </div>
                        //     )
                        // })
                        : null
                    }
                </div>
            </Modal>
        </div>
    )

}

export default AddAttributeDich