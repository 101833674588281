import React from 'react';
import {Row } from 'antd';
import style from './productIntroduction.module.scss';

import product1 from '../../../../assret/images/product1.png';
import product2 from '../../../../assret/images/product2.png';
import product3 from '../../../../assret/images/product3.png';
import product4 from '../../../../assret/images/product4.png';
import close from '../../../../assret/images/productclose.png';
import { getIsPermisson, messageCustom } from '../../../../utils/permisstionUtil';


interface Meal {
    history: any,
}
class ProductIntroduction extends React.Component<Meal> {

    state = {
        qrcode_status: false
    }

    onGoPage(url: any,title: string) {
        var uni_key = '';
        switch(title){
            case '配置优惠卷/配置菜品卷':
                uni_key = '1_2_1_1';
                break;
            case '分组运营':
                uni_key = '1_2_1_3';
                break;
            case '邀请终身奖励配置':
                uni_key = '1_2_1_4';
                break;
            case '邀请双方得卷':
                uni_key = '1_2_1_5';
                break;
        }
        if(getIsPermisson(uni_key)){
            this.props.history.push(url)
        }else{
            messageCustom()
        }
    }

    //弹窗二维码
    onPopQrcode() {
        if(getIsPermisson('1_2_1_2')){
            this.setState({
                qrcode_status: !this.state.qrcode_status
            })
        }else{
            messageCustom()
        }
    }

    render() {
        
        const { qrcode_status } = this.state;
        return (
            <Row className={style.product}>
                <Row className={style.one}>
                    <img className={style.productImg} src={product1} />
                    <div className={style.oneBtn} onClick={() => {this.onGoPage('/sidebar/volumeCenter','配置优惠卷/配置菜品卷')}}></div> 
                </Row>
                <Row className={style.two}>
                    <img className={style.productImg} src={product2} />
                    <div className={style.twoBtn} onClick={() => {this.onPopQrcode()}}></div>
                </Row>
                <Row className={style.three}>
                    <img className={style.productImg} src={product3} />
                    <div className={style.threeBtn} onClick={() => {this.onGoPage('/sidebar/marketingIndex','分组运营')}}></div>
                </Row>
                <Row className={style.four}>
                    <img className={style.productImg} src={product4} />
                    <div className={style.fourBtn} onClick={() => {this.onGoPage('/sidebar/coupon','邀请双方得卷')}}></div>
                    <div className={style.fourBtnPlay} onClick={() => {this.onGoPage('/sidebar/weapp_distriutions','邀请终身奖励配置')}}></div>
                </Row>
                {
                    qrcode_status
                    ?   <Row className={style.popupQrcode}>
                            <div className={style.popupMain}>
                                <div className={style.popupTop}>
                                    <div className={style.popupTopTitle}>
                                        联系客服
                                    </div>
                                    <div className={style.popupTopClose}>
                                        <img className={style.popupTopCloseImg} src={close} onClick={() => {this.onPopQrcode()}} />
                                    </div>
                                </div>
                                <div className={style.popupBottom}>
                                    <img className={style.popupBottomImg} src='https://ydn-siyu.cdn.shuachi.com/customer-20200917173648.jpg' />
                                </div>
                            </div>
                        </Row>
                    :   ''
                }
            </Row>
        )
    }

}

export default ProductIntroduction;