import React from "react";
import homeTemplate1 from '../../../assret/images/home_demo_1.jpg'
import homeTemplate2 from '../../../assret/images/home_demo_2.jpg'
import shopDecorationStore from '../../../stores/shopDecoration.store'
import styles from './home.module.scss'
import { Row } from "antd";
import { getIsPermisson, messageCustom } from '../../../utils/permisstionUtil';

type IProps = {
    history?: any
}
type IState = {
    edit: number
}

class ShopDecorationHome extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props)
        this.state = {
            edit: -1,
        }
    }

    mouseEnter(index:number) {
        this.setState({
            edit: index
        })
    }

    mouseLeave(index:number) {
        this.setState({
            edit: index
        })
    }

    //编辑
    onEdit(index:number) {
        if(!getIsPermisson('2_7_5_3')){
            messageCustom();
            return;
        }
        // this.initData(index)
        // shopDecorationStore.updateHomeTemplate(index)
        shopDecorationStore.updateHomePreview(0)
        this.props.history.push({pathname: "/sidebar/decorationHomeMain",state:{template_id:index}})
    }
    
    onPreview = (index:number) => {
        if(!getIsPermisson('2_7_5_2')){
            messageCustom();
            return;
        }
        // this.initData(index)
        shopDecorationStore.updateHomePreview(1)
        // shopDecorationStore.updateHomeTemplate(index)
        this.props.history.push({pathname: "/sidebar/decorationHomeMain",state:{template_id:index}})
    }

    render() {

        const { edit } = this.state;

        return (
            <Row type="flex" align="middle">
                <div className={styles.home}>
                    <div className={styles.home_top}  onMouseEnter={()=>{this.mouseEnter(1)}}>
                        <img src={homeTemplate1} className={styles.home_img} />
                    </div>
                    {
                        edit === 1 && <div className={styles.home_tip} onMouseLeave={()=>{this.mouseLeave(1)}}>
                                    <div className={styles.home_tip_edit} onClick={()=>{this.onEdit(1)}}>编辑</div>
                                    <div className={styles.home_tip_show} onClick={()=>{this.onPreview(1)}}>预览</div>
                                </div>
                    }
                </div>
                <div className={styles.home}>
                    <div className={styles.home_top}  onMouseEnter={()=>{this.mouseEnter(2)}}>
                        <img src={homeTemplate2} className={styles.home_img} />
                    </div>
                    {
                        edit === 2 && <div className={styles.home_tip} onMouseLeave={()=>{this.mouseLeave(2)}}>
                                    <div className={styles.home_tip_edit} onClick={()=>{this.onEdit(2)}}>编辑</div>
                                    <div className={styles.home_tip_show} onClick={()=>{this.onPreview(2)}}>预览</div>
                                </div>
                    }
                </div>
            </Row>
        )
    }
}

export default ShopDecorationHome