import React, { useEffect, useState } from 'react'
import { message, Modal, Input, Row, Table, Collapse, Icon } from 'antd'
import { getRequest } from '../../../../api/http'
import { apiUrl } from '../../../../api/api_url'

import './index.scss'

const { Search } = Input;
const { Panel } = Collapse;

const GoodsModal = (props:any)=>{

    const { visible, selectGoods, closeModal, checkData } = props
    const [ dishesList, setDishesList ] = useState<any>([])
    const [ dataSource, setDataSource] = useState<any>([])
    const [ currentId, setCurrentId ] = useState(0)
    const [ keywords, setKeywords ] = useState('')
    const [ selectGoodsList, setSelectGoodsList ] = useState<any>([])
    const [ rowKeys, setRowKeys ] = useState<any>([])
    const [ specList, setSpecList ] = useState<any>({})

    const columns = [
        {
            title: '菜品名称',
            dataIndex: 'name',
            render: (text:any,record:any,index:number)=>
            <>
                <div>{text}</div>
                {
                    record.specs.length>0&&record.checked&&<Row className="mt20" type="flex" align="middle" justify="center">
                        {
                            record.specs.map((item:any,subIndex:number)=>{
                                return <div className={`sepc_item ${item.checked?'sepc_item_active':''}`} key={record.id+'-'+subIndex} onClick={()=>{changeSpec(index,subIndex)}}>{item.name}</div>
                            })
                        }
                    </Row>
                }
            </>         
        },
    ];

    const changeSpec = (pIndex:any,current:any) => {
        // const specListTemp = JSON.parse(JSON.stringify(specList))
        // //全部充值为false
        // specListTemp[id].value.map((item:any,index:number)=>{
        //     specListTemp[id].value[index].checked = false 
        // })
        // specListTemp[id].value[current].checked = true
        // setSpecList(specListTemp)
        let dataSourceTemp = JSON.parse(JSON.stringify(dataSource))
        dataSourceTemp[pIndex].specs = dataSource[pIndex].specs.map((item:any,index:number)=>{
            item.checked = false
            return item
        })
        dataSourceTemp[pIndex].specs[current].checked = true
        setDataSource(dataSourceTemp)
    }

    useEffect(()=>{
        // let checkKeys = checkData.map((item:any,index:number)=>{
        //     return item.id
        // })
        // setRowKeys(checkKeys)
        getAllDishes()
    },[])

    //获取所有菜品列表
    const getAllDishes = async() => {
        const res:any = await getRequest(apiUrl.getAllDishes)
        if(res.code === 1 && res.data){
            setDishesList(res.data)
            let arr:any = []
            res.data.map((item:any,index:number)=>{
                if(item.dish_list){
                    arr = arr.concat(item.dish_list)
                }
            })
            setDataSource(arr)
        }else{
            message.error('菜品信息获取失败')
        }
    }

    //关键字搜索
    const onSearch = (value:any) => {
        value = value.trim()
        setKeywords(value)
        let arr:any = []
        // let checkKeys:any = []
        let dishesListTemp = JSON.parse(JSON.stringify(dishesList))
        if(currentId){
            dishesListTemp = dishesListTemp.filter((item:any) => item.id === currentId)
        }
        dishesListTemp.map((item:any,index:number)=>{
            item.dish_list&&item.dish_list.map((subItem:any,index:number)=>{
                if(subItem.name.indexOf(value)!==-1){
                    let sIndex = selectGoodsList.findIndex((sItem:any)=>sItem.id===subItem.id)
                    if(sIndex!==-1){
                        arr.push(selectGoodsList[sIndex])
                    }else{
                        arr.push(subItem)
                    }
                    // if(checkData.findIndex((fItem:any)=>fItem.id===subItem.id)!==-1){
                    //     checkKeys.push(subItem.id)
                    // }
                }
            })
        })
        // setRowKeys(checkKeys)
        setDataSource(arr)
    }
    //点击左侧菜单
    const menuClick = (id:number) => {
        setCurrentId(id)
        let arr:any = []
        // let checkKeys:any = []
        let dishesListTemp = JSON.parse(JSON.stringify(dishesList)).filter((item:any) => item.id === id)
        dishesListTemp.map((item:any,index:number)=>{
            item.dish_list&&item.dish_list.map((subItem:any,index:number)=>{
                if(keywords){
                    if(subItem.name.indexOf(keywords)!==-1){
                        let sIndex = selectGoodsList.findIndex((sItem:any)=>sItem.id===subItem.id)
                        if(sIndex!==-1){
                            arr.push(selectGoodsList[sIndex])
                        }else{
                            arr.push(subItem)
                        }
                        // if(checkData.findIndex((fItem:any)=>fItem.id===subItem.id)!==-1){
                        //     checkKeys.push(subItem.id)
                        // }
                    }
                }else{
                    let sIndex = selectGoodsList.findIndex((sItem:any)=>sItem.id===subItem.id)
                    if(sIndex!==-1){
                        arr.push(selectGoodsList[sIndex])
                    }else{
                        arr.push(subItem)
                    }
                    // if(checkData.findIndex((fItem:any)=>fItem.id===subItem.id)!==-1){
                    //     checkKeys.push(subItem.id)
                    // }
                }
            })
        })
        // setRowKeys(checkKeys)
        setDataSource(arr)
    }
    //左侧菜单收缩变化
    const changeCollapse = () => {
        setCurrentId(0)
        let arr:any = []
        // let checkKeys:any = []
        dishesList.map((item:any,index:number)=>{
            item.dish_list&&item.dish_list.map((subItem:any,index:number)=>{
                if(keywords){
                    if(subItem.name.indexOf(keywords)!==-1){
                        let sIndex = selectGoodsList.findIndex((sItem:any)=>sItem.id===subItem.id)
                        if(sIndex!==-1){
                            arr.push(selectGoodsList[sIndex])
                        }else{
                            arr.push(subItem)
                        }
                        // if(checkData.findIndex((fItem:any)=>fItem.id===subItem.id)!==-1){
                        //     checkKeys.push(subItem.id)
                        // }
                    }
                }else{
                    if(subItem.name.indexOf(keywords)!==-1){
                        let sIndex = selectGoodsList.findIndex((sItem:any)=>sItem.id===subItem.id)
                        if(sIndex!==-1){
                            arr.push(selectGoodsList[sIndex])
                        }else{
                            arr.push(subItem)
                        }
                        // if(checkData.findIndex((fItem:any)=>fItem.id===subItem.id)!==-1){
                        //     checkKeys.push(subItem.id)
                        // }
                    }
                }
            })
        })
        // setRowKeys(checkKeys)
        setDataSource(arr)
    }
    //表格多选
    const rowSelection = {
        onChange: (selectedRowKeys:any, selectedRows:any) => {
            // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            setRowKeys(selectedRowKeys)
            if(selectedRowKeys.length>0){
                let dataSourceTemp = []
                let selectData:any = []
                dataSourceTemp = dataSource.map((item:any,index:number)=>{
                    if(selectedRowKeys.indexOf(item.id) !== -1){
                        if(!item.checked){
                            item.checked = true
                            item.specs.map((subItem:any,index:number)=>{
                                item.specs[index].checked = index === 0 ? true :false
                            })
                            if(item.checked){
                                selectData.push(item)
                            }
                            return item
                        }else{
                            selectData.push(item)
                            return item
                        }
                    }else{
                            item.checked = false
                            item.specs.map((subItem:any,index:number)=>{
                                item.specs[index].checked = false
                            })
                            return item                        
                    }
                })
                
                let arr:any = selectData
                selectGoodsList.map((item:any)=>{
                    //已选择去重
                    let index = selectData.findIndex((subItem:any)=>subItem.id===item.id)
                    if(index===-1){
                        arr.push(item)
                    }
                })

                setDataSource(dataSourceTemp)
                setSelectGoodsList(arr)
            }else{
                // setSpecList([])
                let dataSourceTemp = []
                dataSourceTemp = dataSource.map((item:any,index:number)=>{
                    item.checked = false
                    item.specs.map((subItem:any,index:number)=>{
                        item.specs[index].checked = false
                    })
                    return item
                })
                setDataSource(dataSourceTemp)
                setSelectGoodsList([])
            }
        },
        selectedRowKeys:rowKeys,
        // onSelect: (record:any, selected:any, selectedRows:any, nativeEvent:any) => {
        //     if(selected){
        //         getDetDishesSepc(selectedRows[0].id)
        //     }
        // },
        // onSelectAll: (selected:any, selectedRows:any, changeRows:any) => {
        //     if(selected&&selectedRows.length>0){
        //         let id_arr = selectedRows.map((item:any)=>{
        //             return item.id
        //         })
        //         getDetDishesSepc(id_arr.join(','))
        //     }
            
        // },
        // getCheckboxProps: (record:any) => {
        //     return {
        //         checked:true,
        //         // disabled: record.name === '卤肥肠', // Column configuration not to be checked
        //         name: record.name,
        //     }
        // }
    };
    //批量获取规格
    const getDishesSepc = async(ids:any) => {
        const res:any = await getRequest(apiUrl.getDishesSepc,{dishe_ids:ids})
        if(res.code === 1 && res.data){
            let arr:any = {}
            res.data.map((item:any)=>{
                item.value.map((subItem:any,index:number)=>{
                    item.value[index] = {}
                    item.value[index].value = subItem
                    item.value[index].checked = index === 0 ? true :false
                })
                arr[item.dishe_id] = item
            })
            setSpecList(arr)
        }else{
            message.error(res.message||'菜品规格获取失败')
        }
    }

    const handleOk = () => {
        if(selectGoodsList.length === 0){
            message.error('请至少选择一个菜品')
            return false
        }
        selectGoods(selectGoodsList)
        closeModal()
    }

    const handleCancel = () => {
        closeModal()
    }

    return (
        <Modal
          title="请选择菜品"
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          className="deposit_goods_modal"
          width={710}
        >
            <Search
                placeholder="请输入菜品名称"
                onChange={e => { onSearch(e.currentTarget.value) }}
                onSearch={value => { onSearch(value) }}
                style={{ width: 259 }}
                className="mb20"
                allowClear
            />
            <Row type="flex" align="top" justify="space-between">
                <div className="collapse_view">
                    <Collapse
                        bordered={false}
                        defaultActiveKey={['1']}
                        expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
                        onChange={()=>{changeCollapse()}}
                    >
                        <Panel header="全部分类" key="1">
                            {
                                dishesList.map((item:any,index:number)=>{
                                    return <div className={`menu_item ${currentId === item.id ? 'menu_item_active' : ''}`} key={item.id} onClick={()=>{menuClick(item.id)}}>{item.name}</div>
                                })
                            }
                        </Panel>
                    </Collapse>  
                </div>
                <div className="table_view">
                    <Table 
                        rowSelection={rowSelection} 
                        dataSource={dataSource} 
                        columns={columns} 
                        pagination={false}
                        rowKey={"id"}
                    />
                </div>
            </Row>
        </Modal>
    )
}

export default GoodsModal
