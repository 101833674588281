import React from 'react';
import { Row, Col } from 'antd';
import '../management.scss';

import addType from '../../../../../../assret/images/winAddType.png';
import mSort from '../../../../../../assret/images/mSort.png';
import showType from '../../../../../../assret/images/winShowType.png';
import mShowType from '../../../../../../assret/images/mShowType.png';
import mShowType2 from '../../../../../../assret/images/mShowType2.png';
import mShowType3 from '../../../../../../assret/images/mShowType3.png';
import mShowType4 from '../../../../../../assret/images/mShowType4.png';

interface TypeManagement {
    history: any,
}

class TypeManagement extends React.Component<TypeManagement> {

    render() {
        return  <Row className="typeDetail">
                    <Col className="typeDetail-title">一、功能介绍</Col>
                    <Col className="typeDetail-col">商品分类，当商品种类繁多时，需要按照不同标准给商品进行分类，比如：精选荤菜、精选素菜等。</Col>
                    <Col className="typeDetail-title">二、功能路径</Col>
                    <Col className="typeDetail-col">电脑端登录后台-商品管理-分类管理-添加商品分类，进行添加、删除、编辑、排序。</Col>
                    <Col className="typeDetail-col">注：该分类下有菜品，无法删除</Col>
                    <Col className="typeDetail-col">
                        <img src={addType} />
                    </Col>
                    <Col>1、分类排序展示</Col>
                    <Col className="typeDetail-col">排序：鼠标放到排序图标，可拖动进行排序，拖动的排序在点餐小程序显示</Col>
                    <Col className="typeDetail-col"><img src={mSort} /></Col>
                    <Col className="typeDetail-title">三、操作流程</Col>
                    <Col className="typeDetail-col">1. 设置分类名称、分类类型、展示样式等信息</Col>
                    <Col className="typeDetail-col">2、分类类型</Col>
                    <Col className="typeDetail-col">分类分两种类型，菜品分类只针对单个菜品，添加菜品时使用；套餐分类主要针对商家添加套餐使用</Col>
                    <Col className="typeDetail-col"><img src={showType} /></Col>
                    <Col className="typeDetail-col">3、展示样式</Col>
                    <Col className="typeDetail-col">
                        <img src={mShowType} />
                        <img src={mShowType2} />
                    </Col>
                    <Col className="typeDetail-col">
                        <img src={mShowType3} />
                        <img src={mShowType4} />
                    </Col>
                    <Col className="typeDetail-col">不同的样式，有不同的展示，建议多点点，然后组合出自己喜欢的样式哦～</Col>
                </Row>
    }
}

export default TypeManagement;