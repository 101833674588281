
// 申请提现

import React, { Fragment, useState, useEffect } from 'react'
import Title from 'antd/lib/typography/Title'
import { Radio, Row, Col, Button, Modal, message } from 'antd'
import WechatWithdrawal from './components/wechat_withdrawal';
import CardWithdrawal from './components/card_withdrawal';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { getRequest, postRequest } from '../../../../api/http';
import { apiUrl } from '../../../../api/api_url';

interface IApplyWithdrawal extends RouteComponentProps{}

interface IsubmitData {
  'type' : string,
  'amount' : number | string,
  'truename' :string
  'mobile' :string
  'idcard' :string
  // wechat 类型传递
  'wechat': string
  'nickname':string
  'openid':string
  // 银行类型传递
  'bank':string
  'account_number':string
}

export interface IApplyWithdrawalConfig {
  bank: {
    id: number
    name: string,
    user_rate: string,
    user_min_fee: string,
    user_min_amount: string,
    user_max_day: string,
    shop_rate: string,
    shop_min_fee: string,
    shop_min_amount: string,
    shop_max_day: string
    key: string
  },
  wechat: {
    id: number
    name: string
    user_rate: string
    user_min_fee: string
    user_min_amount: string
    user_max_day: string
    shop_rate: string
    shop_min_fee: string
    shop_min_amount: string
    shop_max_day: string
    key: string
  }
}

// 提现方式
const ApplyWithdrawal = (props: IApplyWithdrawal) => {

  const [applyType, setapplyType] = useState(1);
  const [showSureModal, setshowSureModal] = useState(false);
  const [submitData, setsubmitData] = useState<IsubmitData>({
    'type': '',
    'amount': 0,
    'truename': '',
    'mobile': '',
    'idcard': '',
    // wechat 类型传递
    'wechat': '',
    'nickname': '',
    'openid': '',
    // 银行类型传递
    'bank': '',
    'account_number': ''
  });

  const [withDrawalConfig, setwithDrawalConfig] = useState<IApplyWithdrawalConfig>({
    "bank": {
      "id": 0,
      "name": "",
      "user_rate": "",      // 用户提现手续费率
      "user_min_fee": "0",    // 用户单笔最小提现手续费
      "user_min_amount": "0", // 用户单笔最小提现数量
      "user_max_day": "0",// 用户日提现上限
      "shop_rate": "0",      // 商户提现手续费率
      "shop_min_fee": "0",    // 商户单笔最小提现手续费
      "shop_min_amount": "0", // 商户单笔最小提现数量
      "shop_max_day": "0",// 商户日提现上限
      "key": "bank"              //
    },
    "wechat": {
      "id": 0,
      "name": "0",
      "user_rate": "0",
      "user_min_fee": "0",
      "user_min_amount": "0",
      "user_max_day": "0",
      "shop_rate": "0",
      "shop_min_fee": "0",
      "shop_min_amount": "0",
      "shop_max_day": "0",
      "key": "wechat"
    }
  });
  
  // 可提现金额
  const [withdrawableRMB, setwithdrawableRMB] = useState<any>();
  
  useEffect(() => {
    getApplyWithDrawal();
    initData();
  });

  const initData = () => {
    if(props.location && props.location.state){
      setwithdrawableRMB(props.location.state)
    }
  }

  const RadioChange = (e: any) => setapplyType(e.target.value);

  const updateFormData = (data: any) => {
    setsubmitData(data);
  }
  
  // 获取提现配置
  const getApplyWithDrawal = async () => {
    const res:any = await getRequest(apiUrl.withdrawConfig);
    if(res && res.data) {
      setwithDrawalConfig(res.data)
    }
  }

  const handelSumite = () => {
    const regEpxWechat:any = {
      'nickname': "你还没有微信授权！",
      'wechat': "你还没有微信授权！",
      "openid": '你还没有微信授权！',
      'truename': "真实姓名必填",
      'mobile': "手机号必填",
      'idcard': "身份证号不正确",
      "amount": "提现金额不正确"
    };
    const regEpxCard:any = {
      "bank": '开户行必选',
      'truename': "真实姓名必填",
      'account_number': '银行卡号不正确',
      'mobile': "手机号不正确",
      'idcard': "身份证号不正确",
      "amount": "提现金额不正确"
    };
    let alltrue = true;
    if(applyType === 1) {
      Object.keys(regEpxWechat).map((el:string) => {
        const dataTem: any = submitData;
        if(!dataTem[el]){
          message.info(`${regEpxWechat[el]}`);
          alltrue = false;
        }
        return true;
      })
    } else {
      Object.keys(regEpxCard).map((el:string) => {
        const dataTem: any = submitData;
        if(!dataTem[el]){
          message.info(`${regEpxCard[el]}`);
          alltrue = false;
        }
        return true;
      })
    }

    if(Number(submitData.amount) < Number(withDrawalConfig?.wechat.shop_min_amount) && applyType === 1) {
      message.info(`最少提现${withDrawalConfig?.wechat.shop_min_amount}元`);
      return;
    }

    if(Number(submitData.amount) < Number(withDrawalConfig?.bank.shop_min_amount) && applyType === 2) {
      message.info(`最少提现${withDrawalConfig?.bank.shop_min_amount}元`);
      return;
    }

    if(submitData.idcard.length < 18) {
      message.warning('身份证号错误');
      return;
    }
    
    if(alltrue) {
      setshowSureModal(true);
    }
  }

  const sureApply = async() => {
    const res:any = await postRequest(apiUrl.shopWithdraw, submitData);
    if(res && res.data) {
      message.success('提现成功！');
      setTimeout(() => {
        props.history.replace('/sidebar/withdraw');
      }, 1000);
    }
  }

  // 获取到账金额
  const getApplyWithDrawalMoney = () => {
    // 微信提现
    if(applyType === 1) {
      let fee = Number(submitData.amount) * Number(withDrawalConfig?.wechat.shop_rate)
      const shop_min_fee = Number(withDrawalConfig?.wechat.shop_min_fee)
      if(fee && fee < shop_min_fee){
        fee = shop_min_fee;
      }
      return fee || 0;
    }

    //银行卡提现
    if(applyType === 2) {
      let fee = Number(submitData.amount) * Number(withDrawalConfig?.bank.shop_rate)
      const shop_min_fee = Number(withDrawalConfig?.bank.shop_min_fee)
      if(fee && fee < shop_min_fee){
        fee = shop_min_fee;
      }
      return fee || 0;
    }
    return 0;
  }

  const getApplyWithDrawalToText = () => {
    if(applyType === 1) {
      return `微信零钱 (微信昵称：${submitData.nickname})`;
    }
    if(applyType === 2 && submitData.bank) {
      return `中国银行(${submitData.bank.slice(-4)})`;
    }
    return '';
  }
  
  return <Fragment>
    <Title level={4} style={{marginTop: 20, marginBottom: 20}}>选择提现方式</Title>
    <Radio.Group onChange={RadioChange} value={applyType}>
      <Radio value={1}>微信提现</Radio>
      <Radio value={2}>银行卡提现</Radio>
    </Radio.Group>
    
    {applyType === 1 && <WechatWithdrawal updateParentData={updateFormData} withDrawalConfig={withDrawalConfig} withdrawableRMB={withdrawableRMB}/>} 
    {applyType === 2 && <CardWithdrawal updateParentData={updateFormData} withDrawalConfig={withDrawalConfig} withdrawableRMB={withdrawableRMB} />} 
    
    <Row type="flex" justify="center" style={{marginTop: 80}}>
      <Button onClick={() => window.history.back()}>取消</Button>
      <Button type="primary" style={{marginLeft: 100}} onClick={handelSumite}>提交</Button>
    </Row>

    <Modal
      title="确认提现信息"
      visible={showSureModal}
      onOk={() => sureApply()}
      onCancel={() => setshowSureModal(false)}
    >
      <Row style={{padding: '0 50px'}}>
        <Row type="flex" justify="center" style={{marginTop: 20}}><Title level={4}>提现金额</Title></Row>
        <Row type="flex" justify="center" style={{marginTop: 10}}><Title level={3} style={{color: '#FF3850'}}>{Number(submitData.amount)}元</Title></Row>
        <Row type="flex" justify="center" style={{marginTop: 10}}>
          <Col style={{flex: '1'}}>提现方式</Col>
          <Col style={{flex: '1', textAlign: 'right'}}>银行卡提现</Col>
        </Row>
        <Row type="flex" justify="center" style={{marginTop: 20}}>
          <Col style={{flex: '1'}}>到账金额</Col>
          <Col style={{flex: '1', textAlign: 'right'}}>{Number(submitData.amount) - getApplyWithDrawalMoney()}元</Col>
        </Row>
        <Row type="flex" justify="center" style={{marginTop: 20}}>
          <Col style={{flex: '1'}}>手续费</Col>
          <Col style={{flex: '1', textAlign: 'right'}}>{getApplyWithDrawalMoney()}元</Col>
        </Row>
        <Row type="flex" justify="center" style={{marginTop: 20}}>
          <Col style={{flex: '1'}}>提现到</Col>
          <Col style={{flex: '1', textAlign: 'right'}}>{getApplyWithDrawalToText()}</Col>
        </Row>
      </Row>
    </Modal>

  </Fragment>
}

export default withRouter(ApplyWithdrawal)


