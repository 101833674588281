import React from 'react';
import { Row, Col } from 'antd';
import '../management.scss';

import winAddAdminNum from '../../../../../../assret/images/winAddAdminNum.png';
import winAddAdminEdit from '../../../../../../assret/images/winAddAdminEdit.png';

interface ShopManagementDetail {
    history: any,
}

class ShopManagementDetail extends React.Component<ShopManagementDetail> {

    render() {
        return  <Row className="typeDetail">
                    <Col className="typeDetail-title">一、功能介绍</Col>
                    <Col className="typeDetail-col">管理者账号是指该账号所有人在该门店后台所扮演的角色，可在该界面下根据不同角色，所能操作和查看的界面不一样。</Col>
                    <Col className="typeDetail-title">二、功能路径</Col>
                    <Col className="typeDetail-col">电脑端登录后台-权限管理-管理者账号-添加管理者账号。</Col>
                    <Col className="typeDetail-col">
                        <img src={winAddAdminNum} />
                    </Col>
                    <Col className="typeDetail-title">三、操作流程</Col>
                    <Col className="typeDetail-col">1、<span className="txt-bold">添加管理者账号</span>，在“管理者账号”界面下点击“添加”填写商家端用户姓名、用户登陆手机号、账号状态、用户密码、选择角色</Col>
                    <Col className="typeDetail-col">注：当账号状态设置为冻结，则该用户不能登录该系统。</Col>
                    <Col className="typeDetail-col">
                        <img src={winAddAdminEdit} />
                    </Col>
                    <Col className="typeDetail-col">点击“确定”，完成管理者账号的添加。返回管理者账号列表页，在“操作”类目中可以进行编辑。</Col>
                </Row>
    }
}

export default ShopManagementDetail;