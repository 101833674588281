import {observer} from "mobx-react";
import React from "react";
import {Button, Row, Col, Input, DatePicker, Table, Modal, } from "antd";
import { postRequest } from '../../api/http';
import { apiUrl } from '../../api/api_url';
import "./product.css";
import { RangePickerValue } from "antd/lib/date-picker/interface";
import { Link } from "react-router-dom";

const { Search } = Input;
const { RangePicker } = DatePicker;

@observer
class Product extends React.Component<Product> {
    state = {
        visible: false,
        productCount: 0,
        productList: [],
        currentPage: 1,
        pageSize: 10,
        searchName: '',
        timeArray: [],
        modelTitle: '商品',
        modelContent: '删除',
        currentRecord: {
            id: 0,
            img: '',
            name: '',
            price: 0,
            time: '',
        }
    }

    columns = [
        {
          title: '商品头图',
          dataIndex: 'first_pic',
          key: 'first_pic',
          className: 'list_center',
          render: (record:any) => {
            return <div className="btn_wrap">
                <img src={record.url} alt="商品头图" className="list_img"/>
            </div>
          }
        },
        {
            title: '商品名称',
            dataIndex: 'name',
            key: 'name',
            className: 'list_center',
        },
        {
            title: '商品价格',
            dataIndex: 'price',
            key: 'price',
            className: 'list_center',
        },
        {
            title: '创建时间',
            dataIndex: 'created_at',
            key: 'created_at',
            className: 'list_center',
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            className: 'list_center',
            render: (text:any,record:any,) => (
                <div className="btn_wrap">
                    <Link className="list_btn" to={{pathname:'/sidebar/ProductEdit', state:{id: record.id}}}>编辑</Link>
                    {/* <div className="list_btn" onClick={() => this.copyList(record)}>复制</div> */}
                    <div className="list_btn" onClick={() => this.deleteList(record)}>删除</div>
                </div>
            ),
        },
    ];

    componentDidMount(){
        this.onInitList();
    }

    // 初始化列表
    onInitList = async () => {
        this.searchAction('')
    }

    searchAction  = async (name:string) => {
        const {pageSize, timeArray} = this.state;
        const params:any = {
            // current: currentPage,
            pageSize: pageSize,
        };
        if(name !== ''){
            params.name = name;
        }
        if(timeArray.length > 0){
            params.created_at_start = timeArray[0];
            params.created_at_end = timeArray[1];
        }
        const res:any = await postRequest(apiUrl.productList,params);
        if(res.code === 1){
            this.setState({
                productList: res.data.data,
                productCount: res.data.total,
                searchName: name,
            })
        }
    }

    timeChange = async (time: RangePickerValue, timeString: [string, string]) => {
        const {searchName, pageSize, currentPage} = this.state;
        const params:any = {
            page: currentPage,
            pageSize: pageSize,
        };
        if(searchName !== ''){
            params.name = searchName;
        }
        if(timeString.length > 0 && timeString[0] !== ''){
            params.created_at_start = timeString[0];
            params.created_at_end = timeString[1];
        }
        const res:any = await postRequest(apiUrl.productList,params);
        if(res.code === 1){
            this.setState({
                productList: res.data.data,
                productCount: res.data.total,
                searchName: searchName,
            })
        }
    }

    pageChange = async (page:number) => {

        const {searchName, pageSize, timeArray} = this.state;
        const params:any = {
            page: page,
            pageSize: pageSize,
        };
        if(searchName !== ''){
            params.name = searchName;
        }
        if(timeArray.length > 0){
            params.created_at_start = timeArray[0];
            params.created_at_end = timeArray[1];
        }
        const res:any = await postRequest(apiUrl.productList,params);
        if(res.code === 1){
            this.setState({
                productList: res.data.data,
                productCount: res.data.total,
                searchName: searchName,
            })
        }
    }

    // 复制
    copyList = (record:any) => {
        this.setState({
            currentRecord: record,
            modelTitle: '复制商品',
            modelContent:`确认复制商品「${record.name}」？`,
            visible: true
        })
    }

    copyListFun = async (id:any) => {
        const params:any = {
            id: id
        };
        const res:any = await postRequest(apiUrl.productCopy,params);
        if(res.code === 1){
            this.onInitList();
        }
    }

    // 删除列表
    deleteList = (record:any) => {
        this.setState({
            currentRecord: record,
            modelTitle: '删除商品',
            modelContent:`确认删除商品「${record.name}」？`,
            visible: true
        })
    }

    deleteListFun = async (id:any) => {
        const { productList, productCount } = this.state;
        const params:any = {
            id: id
        };
        const res:any = await postRequest(apiUrl.productDelete,params);
        if(res.code === 1){
            const newList = productList.filter((item:any) => item.id !== id);
            this.setState({
                productList: newList
            })
        }
    }

    // 弹窗确认
    handleOk = () => {
        const {modelTitle,currentRecord} = this.state;
        if(modelTitle === '复制商品'){
            this.copyListFun(currentRecord.id);
        }

        if(modelTitle === '删除商品'){
            this.deleteListFun(currentRecord.id);
        }

        this.setState({
          visible: false,
        });
    };

    // 关闭弹窗
    handleCancel = () => {
        this.setState({
          visible: false,
        });
    };

    render(){
        return <div className='l_content'>
            <Row>
                <Col span={24}>
                    <Link to={'/sidebar/ProductEdit'}>
                        <Button type="primary" size="large" icon="plus">
                            添加商品
                        </Button>
                    </Link>
                </Col>
            </Row>
            <Row type="flex" align="middle" justify="start" className="mt20 mb40" >
                <Col className="mr30">
                    <span>活动名称：</span>
                    <Search placeholder="请输入商品名称" onSearch={this.searchAction} style={{ width: 234 }}/>
                </Col>
                <Col>
                    <span>起止时间：</span>
                    <RangePicker onChange={this.timeChange} placeholder={["开始时间","结束时间"]} format="YYYY-MM-DD HH:mm:ss"/>
                </Col>
            </Row>

            <div>
                <Table dataSource={this.state.productList} size="middle" columns={this.columns} rowKey={"id"} locale={{emptyText: '暂无数据'}} pagination={{
                    showQuickJumper:true,
                    defaultCurrent: 1,
                    total: this.state.productCount,
                    pageSize: this.state.pageSize,
                    hideOnSinglePage: true,
                    onChange: this.pageChange
                }}/>
            </div>

            <Modal
                title={this.state.modelTitle}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                centered
                okText="确认"
                cancelText="取消"
                >
                <p>{this.state.modelContent}</p>
                </Modal>
        </div>;
    }
}
export default Product