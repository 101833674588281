import React from "react";
import { Button, Input, message, Row, Col, Radio, Icon, Modal, Table, Upload } from "antd";
import { postRequest, getRequest } from '../../../api/http';
import { apiUrl } from '../../../api/api_url';
import './index.scss'

const key = 'updatable';
const { TextArea } = Input
const QINIU_SERVER = 'https://up-z2.qiniup.com';

class ScoreAdd extends React.Component<any> {
    columnNum:any = [
        {
            title: '优惠券名称',
            dataIndex: 'name',
            key: 'name',
            align: "center",
        },
        {
            title: '券类型',
            dataIndex: 'type',
            key: 'type',
            align: "center",
            render: (text:any,record:any,) => (
                <div className="color">{record.info.type === 'discount' ? '折扣券' : record.info.type === 'voucher' ? '优惠券' : '兑换券'}</div>
            ),
        },
        {
            title: '券有效期',
            dataIndex: 'created_at',
            key: 'created_at',
            align: "center",
            render: (text:any,record:any,) => {
                let info:any = record.expire_info;
                // return <div className="color">{info.type === 'time' ?  info.start_time + '至' + info.end_time : '自领取日'+info.day + '天领取'}</div>
                return <div className="color">{info.type === 'time' ?  '自'+info.start_time + '至' + info.end_time : info.type === 'day' ? '自领取日'+info.day + '天内有效':'永久'}</div>

            }
        },
        {
            title: '券份数',
            dataIndex: 'num',
            key: 'num',
            align: "center",
            render: (text:any,record:any,) => {
                return <div className="back_wrap">
                        <div className="back_btn reduce_btn active" onClick={() => this.reduceCoupon(record.id)}>-</div>
                        <div className="good_num">{record.num}</div>
                        <div className="back_btn add_btn active" onClick={() => this.addCoupon(record.id)}>+</div>
                    </div>
            }
        },
        {
            title: '操作',
            align: "center",
            render: (text: string,record:any,index:number)=>(
                <div className="table_action" onClick={() => this.unBindShop(index)}>移除</div>
            )
        },
    ];

    // 创建 商品列表
    columns:any = [
        {
            title: '优惠券名称',
            dataIndex: 'name',
            key: 'name',
            align: "center",
        },
        {
            title: '券类型',
            dataIndex: 'price',
            key: 'id',
            align: "center",
            render: (text:any,record:any,) => (
                <div className="color">{record.info.type === 'discount' ? '折扣券' : record.info.type === 'voucher' ? '优惠券' : '兑换券'}</div>
            ),
        },
        {
            title: '券有效期',
            dataIndex: 'created_at',
            key: 'created_at',
            align: "center",
            render: (text:any,record:any,) => {
                let info:any = record.expire_info;
                return <div className="color">{info.type === 'time' ?  '自'+info.start_time + '至' + info.end_time : info.type === 'day' ? '自领取日'+info.day + '天内有效':'永久'}</div>
            }
        },
        {
            title: '操作',
            align: "center",
            render: (text: string,record:any,index:number)=>{
                return <div>
                    {
                        record.selected
                        ? '已关联'
                        : <div className="table_action" onClick={() => this.handleBindShop(record.id)}>关联</div>
                    }
                </div>
            }
        },
    ];

    typeList:any = [
        '优惠券',
        '折扣券',
        '代金券',
        '兑菜券',
    ]

    state = {
        uploading: false,
        loading: false,
        // 礼包
        selectCoupon: [],
        couponVisible: false,
        current: 1,
        selectList: [],
        listNum: 0,
        giftCouponList: [],
        fileList: [],
        uploadObj: {},
        // imgUrl: '',


        cover: '', //封面
        type: '1', //类型 1优惠券 2折扣券 3代金券 4兑菜券
        coupon: '', //优惠券id
        score: '', //所需积分
        num: '', //发行数量
        limit: '', //每人限兑换多少张
        description: '', //描述

    }

    componentDidMount() {
        this.onInitList()
    }

    // 初始化优惠券列表
    onInitList = async () => {
        const { location } = this.props
        const type = location.state&&location.state.type ? location.state.type : this.state.type
        let typeTemp = ''
        console.log(type)
        switch(type){
            case '1': typeTemp = 'voucher'
                break;
             case '2': typeTemp = 'discount'
                 break;
             case '3': typeTemp = 'cash_coupon'
                 break;
             case '4': typeTemp = 'present_goods'
                 break;
       }

        let param:any = {
            current: 1,
            pageSize: 100,
            type: typeTemp,
            status: 2,
        }
        if(typeTemp!=='cash_coupon') param.position = 3
        const res:any = await getRequest(apiUrl.couponList, param);
        if(res.code === 1){
            let obj:any = {}
            if(location.state !== undefined){
                let tempArr:any = []
                console.log('res.data.list',res.data.list)
                console.log('location.state.coupon',location.state.coupon)
                res.data.list.map((item: any) => {
                    location.state.coupon.map((select: any) => {
                        if(item.id === select.id){
                            item.selected = true
                            item.num = select.num
                            tempArr.push(item)
                        }
                    })
                })
                obj.selectCoupon = tempArr;
                obj.cover= location.state.cover; //封面
                obj.type= location.state.type; //类型 1优惠券 2折扣券 3代金券 4兑菜券
                obj.score= location.state.score; //所需积分
                obj.num= location.state.num; //发行数量
                obj.limit= location.state.limit; //每人限兑换多少张
                obj.description= location.state.description; //描述
            }
            
            obj.giftCouponList= res.data.list;
            obj.listNum= res.data.total;
            obj.current= 1;
            obj.selectList= res.data.list.slice(0, 10);
            this.setState(obj)
        }
    }

    //监听字段变化
    changeFieldVal = ( e: any, field: string ) => {
        let obj:any = {}
        if(field=='type'){
            obj.selectCoupon = []
        }
        let val = typeof e === 'string' && field != 'description' ? e.trim() : e
        obj[field]= val
        this.setState(obj)
    }
    // 校验提交数据
    validatorParams = () =>{
        const { score, num, limit, selectCoupon } = this.state
        console.log(selectCoupon)
        if(!score||parseFloat(score)<=0){
            message.error('请输入兑换所需积分,且大于0')
            return false
        }
        if(!num||parseFloat(num)<-1){
            message.error('请输入正确的发行数量')
            return false
        }
        if(!limit||parseFloat(limit)<=0){
            message.error('请输入每人限兑张数,且大于0')
            return false
        }
        console.log('parseFloat(limit)',parseFloat(limit))
        if(parseFloat(num)!=-1&&parseFloat(limit)>=parseFloat(num)){
            message.error('每人限兑换数量不能超过发行数量')
            return false
        }
        if(selectCoupon.length===0){
            message.error('请选择关联优惠券')
            return false
        }
        return true
    }

    handleSubmit = async () => {
        let flag = this.validatorParams()
        if(flag){
            try{
                this.setState({
                    loading: true
                })
                const { location } = this.props;
                const { cover, type, coupon, score, num, limit, description, selectCoupon }:any = this.state;
                //整合已选择的优惠券，考虑选择多个优惠券的情况
                let selectTemp:any = []
                selectCoupon.map((item:any,index:number)=>{
                    selectTemp.push({
                        id:item.id,
                        num: item.num,
                    })
                })
                var formVal: any = {
                    cover, //封面
                    type, //类型 1优惠券 2折扣券 3代金券 4兑菜券
                    coupon:JSON.stringify(selectTemp), //优惠券 [{"id":358,"num":5} ]
                    score, //所需积分
                    num, //发行数量
                    limit, //每人限兑换多少张
                    description, //描述                
                }
        
                if (location.state !== undefined&&!location.state.is_copy) {
                    formVal.id = location.state.id;
                }
                const res: any = await postRequest(apiUrl.scoreSave, formVal);
                this.setState({
                    loading: false
                })
                if (res['code'] === 1) {
                    message.success(formVal.id?'修改成功!':'保存成功')
                    this.goBack()
                } else {
                    message.error(formVal.id?'修改失败!':'保存失败');
                }    
            }catch(err){
                this.setState({
                    loading: false
                })
                console.log(err)
            }
        }
    };

    // 返回上一页
    goBack = () => {
        this.props.history.goBack();
    }

    // 显示关联
    showTable = () => {
        this.getList()

    }

    getList = async() => {
        const { type, selectCoupon } = this.state
        let typeTemp = ''
        switch(type){
            case '1': typeTemp = 'voucher'
                break;
            case '2': typeTemp = 'discount'
                break;
            case '3': typeTemp = 'cash_coupon'
                break;
            case '4': typeTemp = 'present_goods'
                break;
        }

        let param:any = {
            current: 1,
            pageSize: 100,
            type: typeTemp,
            status: 2,
        }
        if(typeTemp!=='cash_coupon') param.position = 3
        const res:any = await getRequest(apiUrl.couponList, param);
        if(res.code === 1){
            let tempArr:any = []
            res.data.list.map((coupon: any) => {
                selectCoupon.map((select: any) => {
                    if(coupon.id === select.id){
                        coupon.selected = true;
                    }
                })
                tempArr.push(coupon)
            })
            this.setState({
                giftCouponList: tempArr,
                selectList: tempArr.slice(0, 10),
                current: 1,
                listNum: res.data.total,
                couponVisible: true
            })
        }
    }

    addCoupon = (id: any) => {
        let selectCoupon = this.state.selectCoupon;
        selectCoupon.map((item: any) => {
            if(item.id === id){
                item.num++;
            }
        })
        this.setState({
            selectCoupon
        })
    }

    reduceCoupon = (id: any) => {
        let selectCoupon = this.state.selectCoupon;
        selectCoupon.map((item: any) => {
            if(item.id === id){
                if(item.num === 1){
                    return;
                }
                item.num--;
            }
        })
        this.setState({
            selectCoupon
        })
    }

    // 关联
    handleBindShop = (id:number) => {
        let selectCoupon:any[] = this.state.selectCoupon;
        let giftCouponList:any[] = this.state.giftCouponList;
        giftCouponList.map((item) => {
            if(item.id === id){
                item.selected = true;
                item.num = 1;
                // selectCoupon.push(item)
                selectCoupon = [item]
            }else{
                item.selected = false;
            }
        })
        console.log(selectCoupon);
        this.setState({
            selectCoupon,
            giftCouponList
        })
    }

    // 解除关联
    unBindShop = (index: number) => {

        let { giftCouponList } = this.state;
        let selectCoupon: any = this.state.selectCoupon;
        giftCouponList.map((item: any) => {
            if(item.id === selectCoupon[index].id){
                item.selected = false;
            }
        })
        selectCoupon.splice(index, 1)
        this.setState({
            giftCouponList,
            selectList: giftCouponList.slice(0, 10),
            current: 1,
            selectCoupon
        })
    }

    changePage = async (page: number) => {
        let { giftCouponList } = this.state;
        let list = giftCouponList.slice((page-1)*10, (10*page));
        this.setState({
            selectList: list,
            current: page
        })
    }
    // 获取图片token
    beforeUpload = async(file:any) => {
        const res:any = await getRequest(apiUrl.getUploadToken, { type: 'food' });
        if(res && res.data) {
            const key = `food/${Math.random().toString(36).slice(-8)}${(new Date()).valueOf()}.png`
            const obj = { token: res.data.upload_token, region: `https://up-z2.qiniu.com`, key: key, 'x:type': "food" }
            this.setState({
                uploadObj: obj
            })
        }
    }

    handleChange = async(info:any) => {
        this.setState({
            fileList: info.fileList
        })
        if(info.fileList.length<=0) this.setState({cover:''})
        if (info.file.status === 'uploading') {
            this.setState({
                uploading: true
            })
            return;
        }
        if (info.file.status === 'done') {
            let file = await this.getBase64(info.file.originFileObj);
            this.setState({
                cover:info.file.response.url,
                uploading: false
            })
        }
    };

    getBase64 = (file:any) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
      }
      
    render() {
        const { score, limit, description, selectCoupon, selectList, current, listNum, couponVisible, type, num, loading, fileList, cover, uploadObj, uploading } = this.state;
        const createUpload = () =>{
            const uploadButton = (
                <div>
                    { uploading ? <Icon type="loading" style={{color:'#ccc',fontSize:'32px'}}/> : <Icon type="plus" style={{color:'#f0f0f0',fontSize:'32px'}}/> }
                </div>        
            );
            return <>
                <Upload
                    action={QINIU_SERVER}
                    listType="picture-card"
                    fileList={fileList}
                    beforeUpload={this.beforeUpload}
                    onChange={this.handleChange}
                    data={uploadObj}
                    showUploadList={false}
                >
                {cover ? <span className="poster"><img src={cover} style={{ width: '100%' }} /></span>: uploadButton}
                </Upload>
            </>
        }
    
        return <div className="score_add">
            <div className='l_content'>
                <div className="edit_title">添加积分兑换</div>
                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star no_star"></span>
                            <span className="color_black">上传图片</span>
                        </div>
                    </Col>
                    <Col span={22}>
                        {
                            createUpload()
                        }
                    </Col>
                </Row>
                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">类型</span>
                        </div>
                    </Col>
                    <Col span={22}>
                        <Radio.Group
                            value={type}
                            onChange={(e) => this.changeFieldVal(e.target.value,'type')}
                        >
                            {
                                this.typeList.map((item:any,index:number)=>{
                                    return <Radio value={index+1+''} key={index}>{item}</Radio>
                                })
                            }
                        </Radio.Group>
                    </Col>
                </Row>
                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">兑换所需：</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <Input type="number" placeholder='请输入兑换所需积分' value={score} onChange={(e)=>{this.changeFieldVal(e.currentTarget.value,'score')}} />
                    </Col>
                    <Col span={6} className="ml10">
                        积分
                    </Col>
                </Row>
                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">发行数量：</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <Input type="number" placeholder="请输入发行数量" maxLength={10} value={num}  onChange={(e)=>{this.changeFieldVal(e.currentTarget.value,'num')}}/>
                    </Col>
                    <Col span={6} className="ml10 color999">
                        -1表示无上限
                    </Col>
                </Row>
                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">每人限兑换：</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <Input type="number" placeholder="请输入每人限兑张数" maxLength={10} value={limit} onChange={(e)=>{this.changeFieldVal(e.currentTarget.value,'limit')}}/>
                    </Col>
                    <Col span={6} className="ml10">
                        张
                    </Col>
                </Row>
                <Row className="mb30" type="flex" align="top">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">兑换礼品</span>
                        </div>
                    </Col>
                    <Col span={16}>
                        <div onClick={this.showTable} className="add_coupon">
                            <span className="add_coupon_icon">
                                <Icon type="plus" style={{fontSize: '12px',color:'#FF4646'}}/>
                            </span>
                            关联优惠券
                        </div>
                        {
                            selectCoupon.length > 0
                            ? <Table
                                size="middle"
                                rowKey={"id"}
                                dataSource={selectCoupon}
                                columns={this.columnNum}
                                style={{marginTop: '20px'}}
                                pagination={
                                    {
                                        hideOnSinglePage: true,
                                        total: selectCoupon.length,
                                    }
                                }
                            />
                            : null
                        }

                    </Col>
                </Row>
                <Row className="mb30" type="flex" align="top">
                    <Col span={2}>
                        <div>
                            <span className="star no_star"></span>
                            <span className="color_black">兑换须知：</span>
                        </div>
                    </Col>
                    <Col span={12}>
                        <TextArea rows={6} value={description} onChange={(e)=>{this.changeFieldVal(e.currentTarget.value,'description')}}/>
                    </Col>
                </Row>
            </div>
            <Row className="bottom_wrap">
                <Button className="btn_l" type="primary" onClick={this.handleSubmit} loading={loading}>
                    保存
                </Button>
                <Button className="btn_r" type="primary" ghost onClick={this.goBack}>
                    取消
                </Button>
            </Row>

            <Modal
                title={null}
                visible={couponVisible}
                onOk={() => {this.setState({couponVisible: false})}}
                onCancel={()=>{this.setState({couponVisible: false})}}
                width="70vw"
            >
                <div className="model_head">
                    <div className="head_title">关联优惠券</div>
                </div>
                <Table
                    size="middle"
                    rowKey={"id"}
                    dataSource={selectList}
                    columns={this.columns}
                    pagination={
                        {
                            current,
                            total: listNum,
                            onChange: this.changePage,
                            pageSize: 10
                        }
                    }
                />
            </Modal>
        </div>
    }

}
export default ScoreAdd