import React, { useEffect, useState } from 'react'
import { Button, Table, Input, message } from 'antd'
import { useHistory } from 'react-router-dom';
import { getRequest, postRequest } from '../../../api/http'
import { apiUrl } from '../../../api/api_url'
import './add.scss'

const WechatSmsAdd = ()=>{

    const history = useHistory()
    const [data, setData] = useState([]);
    const [formVal, setFormVal] = useState<any>({})

    useEffect(() => {
        getOfficialTemplateList()
    }, [])

    const getOfficialTemplateList = async () => {
        const res:any = await getRequest(apiUrl.getOfficialTemplate)
        if(res && res['code'] === 1&&res.data) {
            res.data.map((item:any)=>{
                formVal[item.id] = item.template_id
            })
            console.log(formVal)
            setFormVal(formVal)
            setData(res.data);
        }
    }

    const onChange = (id:number, value:string) => {
        if(!value.trim()){
            delete formVal[id]
        }else{
            formVal[id] = value.trim()
        }
        console.log(formVal)
        setFormVal(JSON.parse(JSON.stringify(formVal)))
    } 

    const onSubmit = async() => {
        // if(Object.keys(formVal).length===0){
        //     message.error('请至少配置添加一个模版id')
        // }
        const res:any = await postRequest(apiUrl.updateOfficialTemplate,{params:formVal})
        if(res&&res.code==1){
            message.success('保存成功')
            history.goBack()
        }else{
            message.error(res.message||'保存失败')
        }
    }

    const creatTable = ()=>{
            
        const columns:any = [
            {
                title: '模板名称',
                dataIndex: 'title',
            },
            {
                title: '模板id',
                dataIndex: 'template_id',
                render: (text:string, record:any, index:number) => <Input placeholder="请添加模版id" value={formVal[record.id]} onChange={(e)=>{onChange(record.id,e.currentTarget.value)}}/> 
            },
        ];

        return (
            <Table 
                dataSource={data} 
                columns={columns} 
                rowKey={'id'}    
                pagination={false}
            />
        )
    }

    console.log(formVal)
    console.log(Object.keys(formVal).length)
    return(
        <div className="wechat_sms_add l_content">
            <div className="title_text">
                消息模版
                <Button className="ml20" type="primary" onClick={()=>{history.push('/sidebar/wechatSmsGuide')}}>查看教程</Button>
            </div>
            {
                creatTable()
            }
            <Button type="primary" onClick={()=>{onSubmit()}} className="mt20">确定</Button>
        </div>
    )
}

export default WechatSmsAdd