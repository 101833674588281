import React from 'react';
import { Table, Row, Col, Button, DatePicker, Select, Pagination } from 'antd'
import Search from 'antd/lib/input/Search';
import { RangePickerValue } from "antd/lib/date-picker/interface";
import { getRequest } from '../../api/http';
import { apiUrl } from '../../api/api_url';
import { getIsPermisson, messageCustom } from '../../utils/permisstionUtil';

import CustomDatePicker from '../datePicker/index';
import InsideNav from '../../component/insideNav/insideNav';

import RechargeRecord from './components/recharge_record';
import AdjustRecord from './components/adjust_record';
import ConsumeRecord from './components/consume_record';
import WithdrawRecord from './components/withdraw_record';
import CommissionRecord from './components/commission_record';
import CrossRecord from './components/cross_record';

import goodsExport from '../../assret/images/goodsExport.png';
import reduce_icon from '../../assret/images/reduce_icon.png'

import styles from './index.module.scss';
const { RangePicker } = DatePicker
const { Option } = Select;

interface FinanceBalanceProps {
	history: any,
}

const nowDate = new Date();
const yesterday = new Date(nowDate.getTime() - 24 * 60 * 60 * 1000)
const beforeYesterday = new Date(nowDate.getTime() - 24 * 2 * 60 * 60 * 1000)

class FinanceBalance extends React.Component<FinanceBalanceProps> {
	column = [
		{
			title: "用户昵称",
			dataIndex: "user_name",
			key: "user_name",
			className: "list_center",
			render: (value: any, row: any, index: any) => {
				const obj: any = {
					children: <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
						<div><img src={row.head_img_url} style={{ width: 30, height: 30, borderRadius: '50%', marginRight: 10 }} /></div>
						<div>{row.user_name}</div>
					</div>,
					props: {},
				};
				obj.props.rowSpan = row.rowSpan;
				return obj;
			},
		},
		{
			title: "总充值金额",
			dataIndex: "charge",
			key: "charge",
			className: "list_center",
			render: (value: any, row: any, index: any) => {
				return {
					props: { rowSpan: row.rowSpan },
					children: <div>{row.charge}</div>
				}
			}
		},
		{
			title: "门店充值",
			dataIndex: "charge_name",
			key: "charge_name",
			className: "list_center",
		},
		{
			title: "充值余额",
			dataIndex: "charge_fee_item",
			key: "charge_fee_item",
			className: "list_center",
		},
		{
			title: "充值来源",
			dataIndex: "charge_type",
			key: "charge_type",
			className: "list_center",
		},
		{
			title: "门店消费",
			dataIndex: "consume_shop_name",
			key: "consume_shop_name",
			className: "list_center",
		},
		{
			title: "消费金额",
			dataIndex: "consume_fee_item",
			key: "consume_fee_item",
			className: "list_center",
		},
		{
			title: "赠送金额",
			dataIndex: "present_fee_item",
			key: "present_fee_item",
			className: "list_center",
		},
		{
			title: "总赠送金额",
			dataIndex: "present_fee",
			key: "present_fee",
			className: "list_center",
			render: (value: any, row: any, index: any) => {
				return {
					props: { rowSpan: row.rowSpan },
					children: <div>{row.present_fee}</div>
				}
			}
		},
		{
			title: "佣金",
			dataIndex: "commission_fee_item",
			key: "commission_fee_item",
			className: "list_center",

		},
		{
			title: "总佣金",
			dataIndex: "commission_fee",
			key: "commission_fee",
			className: "list_center",
			render: (value: any, row: any, index: any) => {
				return {
					props: { rowSpan: row.rowSpan },
					children: <div>{row.commission_fee}</div>
				}
			}
		},
		{
			title: "调整余额",
			dataIndex: "adjust_balance_item",
			key: "adjust_balance_item",
			className: "list_center",
		},
		{
			title: "资金变更时间",
			dataIndex: "created_at",
			key: "created_at",
			className: "list_center"
		},
		{
			title: "剩余金额",
			dataIndex: "remaining_balance_item",
			key: "remaining_balance_item",
			className: "list_center",
		},
		{
			title: "总剩余金额",
			dataIndex: "balance",
			key: "balance",
			className: "list_center",
			render: (value: any, row: any, index: any) => {
				return {
					props: { rowSpan: row.rowSpan },
					children: <div>{row.balance}</div>
				}
			}
		},
	];

	state = {
		list: [],
		current: 1,
		pageSize: 10,
		count: 0,
		start_time: "",
		end_time: "",
		shopList: [],
		shop_id: 0,
		type: 0,
		charge_shop_id: 0,
		size: 0,
		dateList: [
			{
				type: 'today',
				name: '今日'
			},
			{
				type: 'yesterday',
				name: '昨日'
			},
			{
				type: 'nearlySevenDays',
				name: '近7天'
			},
			{
				type: 'ownMonth',
				name: '近30天'
			},
			{
				type: 'currentMonth',
				name: '本月'
			},
			{
				type: 'beforeMonth',
				name: '上月'
			},
			{
				type: 'quarter',
				name: '本季度'
			},
			{
				type: 'lastQuarter',
				name: '上季度'
			},
			{
				type: 'own',
				name: '累计'
			}
		],
		real_consump_list: [
			{
				title: '充值总人数',
				price: '0',
				type: 'none'
			},
			{
				title: '充值总笔数',
				price: '0',
				type: 'none'
			},
			{
				title: '充值总额',
				price: '¥0',
				type: 'none'
			},
			{
				title: '赠送总额',
				price: '¥0',
				type: 'none'
			},
			{
				title: '剩余余额',
				price: '¥0',
				type: 'plus'
			},
		],
		navTable : [
			{
				title: '充值记录',
				isActive: true,
				KEY: "INCOMEFORM",
				uni_key: '1_3_1'
			},
			{
				title: '余额消费记录',
				isActive: false,
				KEY: "FINANCEFPRM",
				uni_key: '1_3_2'
			},
			{
				title: '邀请奖励记录',
				isActive: false,
				KEY: "RECHARGE",
				uni_key: '1_3_3'
			},
			{
				title: '提现记录',
				isActive: false,
				KEY: "VEGETABLESALE_TABLE",
				uni_key: '1_3_4'
			},
			{
				title: '调整余额记录',
				isActive: false,
				KEY: "FINANCE_BALANCE",
				uni_key: '1_3_5'
			},
			{
				title: '跨店余额消费',
				isActive: false,
				KEY: "ACROSS",
				uni_key: '1_3_6'
			}
		],
		current_key: 'INCOMEFORM',
		all_date: {}, //总数据
		tel_number: '',//手机号码
		search_user_name: '',//用户昵称
		recharge_number: '',//充值单号
		own_shop_name_id: 0,
	};

	componentDidMount() {
		this.getTimeData("currentMonth");
		this._initShop();

		// let toDay = `${nowDate.getFullYear()}-${this.formatData(
		// 	nowDate.getMonth() + 1
		// )}-${this.formatData(nowDate.getDate())}`;
		// this.setState({
		// 	start_time: toDay,
		// 	end_time: toDay
		// }, () => {
		// 	this._initQueryDate(); //初始化时间筛选数据
		// })
		

		this._initQueryListDate('charge'); //列表数据  charge 充值 ，consume消费，commission佣金，withdraw提现，adjust调整余额
	}

	navCallBack(currentKey: string){
        this.setState({
			current_key: currentKey,
			own_shop_name_id: 0
        }, () => {
			this._initQueryDate()//初始化时间筛选数据
		})
    }

	//时间获取
	onCallback = (dateList: any) => {
		//console.log('dateList:', dateList)
		this.setState({
			start_time: dateList[0],
			end_time: dateList[1]
		}, () => {
			this._initQueryDate(); //初始化时间筛选数据
		})
	}

	// 初始化时间筛选数据
	_initQueryDate = async () => {
		const own_type = this.state.current_key;
		let type = '';
		switch (own_type) {
			case 'INCOMEFORM':
				type = 'charge'
				break;
			case 'FINANCEFPRM':
				type = 'consume'
				break;
			case 'RECHARGE':
				type = 'commission'
				break;
			case 'VEGETABLESALE_TABLE':
				type = 'withdraw'
				break;
			case 'FINANCE_BALANCE':
				type = 'adjust'
				break;
			case 'ACROSS':
				type = 'cross'
				break;
			default:
				break;
		}
		let { pageSize, current, start_time, end_time, own_shop_name_id } = this.state;
		let params: any = {
			start_time,
			end_time,
			type,
			search_shop_id: own_shop_name_id
		}
		let res: any = await getRequest(apiUrl.memberSumBill, params);
		if(res.code == 1) {
			this.setState({
				real_consump_list: res.data
			})
		}
	}

	// 初始化列表数据
	_initQueryListDate = async (type: any) => {
		let { pageSize, current } = this.state;
		let params: any = {
			current,
			page_size: pageSize,
			type
		};
		let res: any = await getRequest(apiUrl.memberOfBill, params);
		if(res.code == 1) {
			
		}
	}

	// 初始化数据
	_initInfo = async () => {
		let { pageSize, current, start_time, end_time, shop_id, charge_shop_id } = this.state;
		let params: any = {
			current,
			pageSize: 10
		};
		if (start_time !== "") {
			params.start_time = start_time;
			params.end_time = end_time;
		}
		if (shop_id != 0) {
			params.order_shop_id = shop_id
		}
		if (charge_shop_id != 0) {
			params.charge_shop_id = charge_shop_id
		}
		let res: any = await getRequest(apiUrl.balanceList, params);
		if (res.code === 1) {

			let newList: any = [];

			let list: any = [];
			if (res.data && res.data.list) {
				list = res.data.list;
			}

			let size = 0;
			for (var i = 0; i < list.length; i++) {
				let item: any = list[i]
				if (list[i].date_list.length > 0) {
					for (var j = 0; j < list[i].date_list.length; j++) {
						size++;
						let obj = {
							created_at: item.date_list[j],
							remaining_balance_item: item.remaining_balance_list[j].remaining_balance,
							balance: list[i].balance,
							adjust_balance_item: list[i].adjust_balance_list[j].adjust_balance,
							commission_fee: list[i].commission_fee,
							consume_fee_item: item.consume_list[j].consume_fee,
							consume_shop_name: item.consume_list[j].name,
							commission_fee_item: item.commission_list[j].commission_fee,
							present_fee: list[i].present_fee,
							present_fee_item: item.present_list[j].present_fee,
							charge: list[i].charge,
							charge_fee_item: item.charge_list[j].charge_fee,
							charge_name: item.charge_list[j].name,
							charge_type: item.charge_list[j].charge_type,
							head_img_url: list[i].head_img_url,
							user_name: list[i].user_name,
							fans_id: list[i].fans_id,
							rowSpan: list[i].date_list.length
						}
						if (j != 0) {
							obj.rowSpan = 0;
						}
						newList.push(obj);
					}
				} else {
					size++;
					let obj = {
						created_at: '',
						remaining_balance_item: 0,
						balance: list[i].balance,
						adjust_balance_item: 0,
						commission_fee: list[i].commission_fee,
						consume_fee_item: 0,
						consume_shop_name: 0,
						commission_fee_item: 0,
						present_fee: list[i].present_fee,
						present_fee_item: 0,
						charge: list[i].charge,
						charge_fee_item: 0,
						charge_name: 0,
						charge_type: 0,
						head_img_url: list[i].head_img_url,
						user_name: list[i].user_name,
						fans_id: list[i].fans_id,
						rowSpan: 1
					}
					// if(j != 0){
					//   obj.rowSpan = 0;
					// }
					newList.push(obj);
				}

				// newList= newList.concat(newList)
			}
			console.log('newList', size, newList)

			this.setState({
				count: res.data.total,
				list: newList,
				size: size
			});
		}
	};

	_initShop = async () => {
		let res: any = await getRequest(apiUrl.cashShopList)
		if (res.code == 1) {
			this.setState({
				shopList: res.data
			})
		}
	}

	shopChange = (e: any) => {
		this.setState({
			shop_id: e,
			current: 1
		}, () => {
			this._initInfo();
		})
	}

	shopCharge = (e: any) => {
		this.setState({
			charge_shop_id: e,
			current: 1
		}, () => {
			this._initInfo();
		})
	}

	pageChange = (page: number) => {
		this.setState({
			current: page
		}, () => {
			this._initInfo();
		}
		);
	};

	timeChange = (time: RangePickerValue, timeString: [string, string]) => {
		this.setState({
			start_time: timeString[0],
			end_time: timeString[1]
		}, () => {
			this._initInfo();
		}
		);
	};

	//条件修改门店
	searchShopNmae = (id: any, callback: any) => {
		// this.setState({
		// 	own_shop_name_id:　id
		// }, () => {
		// 	this._initQueryDate()
		// 	setTimeout(() => {
		// 		callback()
		// 	}, 300)
		// })
	}

	// 导出
	exportTable = async () => {
		if (!getIsPermisson('1_3_5_1')) {
			messageCustom();
			return;
		}
		const token = localStorage.getItem("token");
		const shop_id = localStorage.getItem("shop_id");
		const own_type = this.state.current_key;
		let { own_shop_name_id } = this.state;
		let type = '';
		switch (own_type) {
			case 'INCOMEFORM':
				type = 'charge'
				break;
			case 'FINANCEFPRM':
				type = 'consume'
				break;
			case 'RECHARGE':
				type = 'commission'
				break;
			case 'VEGETABLESALE_TABLE':
				type = 'withdraw'
				break;
			case 'FINANCE_BALANCE':
				type = 'adjust'
				break;
			case 'ACROSS':
				type = 'cross'
				break;
			default:
				break;
		}
		window.open(`${process.env.REACT_APP_BASEURL}${apiUrl.memberOfBill}?export=true&search_shop_id=${own_shop_name_id}&type=${type}&token=${token}&start_time=${this.state.start_time}&end_time=${this.state.end_time}&shop_id=${shop_id}`
		);
	};

	getTimeData = (
		type:
			| "today"
			| "yesterday"
			| "beforeYesterday"
			| "beforeMonth"
			| "beforeWeek"
			| "currentMonth"
	) => {
		switch (type) {
			case "currentMonth":
				let currMonthStart = `${nowDate.getFullYear()}-${this.formatData(
					nowDate.getMonth() + 1
				)}-01`;
				let currMonthEnd = `${nowDate.getFullYear()}-${this.formatData(
					nowDate.getMonth() + 1
				)}-${this.formatData(nowDate.getDate())}`;
				this.setState(
					{
						start_time: currMonthStart,
						end_time: currMonthEnd
					},
					() => {
						//this._initDate();
					}
				);
				break;
			case "beforeWeek":
				var d = new Date();
				var end = d.getTime() - 1000 * 60 * 60 * 24 * d.getDay();
				let start = d.getTime() - 1000 * 60 * 60 * 24 * (d.getDay() + 6);
				let startDay =
					new Date(start).getFullYear() +
					"-" +
					this.formatData(new Date(start).getMonth() + 1) +
					"-" +
					this.formatData(new Date(start).getDate());
				let endDay =
					new Date(end).getFullYear() +
					"-" +
					this.formatData(new Date(end).getMonth() + 1) +
					"-" +
					this.formatData(new Date(end).getDate());

				this.setState(
					{
						start_time: startDay,
						end_time: endDay
					},
					() => {
						this._initInfo();
					}
				);
				break;
			case "beforeMonth":
				let beforeMonthStart =
					nowDate.getFullYear() +
					"-" +
					this.formatData(nowDate.getMonth()) +
					"-01";
				let currentMonth =
					nowDate.getFullYear() +
					"-" +
					this.formatData(nowDate.getMonth() + 1) +
					"-01";
				let num = new Date(
					new Date(currentMonth).getTime() - 24 * 60 * 60 * 1000
				);
				let beforeMonthEnd = `${num.getFullYear()}-${this.formatData(
					num.getMonth() + 1
				)}-${this.formatData(num.getDate())}`;
				this.setState(
					{
						start_time: beforeMonthStart,
						end_time: beforeMonthEnd
					},
					() => {
						this._initInfo();
					}
				);
				break;
			case "beforeYesterday":
				let beforeYesDay = `${beforeYesterday.getFullYear()}-${this.formatData(
					beforeYesterday.getMonth() + 1
				)}-${this.formatData(beforeYesterday.getDate())}`;
				this.setState(
					{
						start_time: beforeYesDay,
						end_time: beforeYesDay
					},
					() => {
						this._initInfo();
					}
				);
				break;
			case "yesterday":
				let yesDay = `${yesterday.getFullYear()}-${this.formatData(
					yesterday.getMonth() + 1
				)}-${this.formatData(yesterday.getDate())}`;
				this.setState(
					{
						start_time: yesDay,
						end_time: yesDay
					},
					() => {
						this._initInfo();
					}
				);
				break;

			case "today":
				let toDay = `${nowDate.getFullYear()}-${this.formatData(
					nowDate.getMonth() + 1
				)}-${this.formatData(nowDate.getDate())}`;
				this.setState(
					{
						start_time: toDay,
						end_time: toDay
					},
					() => {
						this._initInfo();
					}
				);
				break;
		}
	};

	formatData = (num: number) => {
		return num > 9 ? num : `0${num}`;
	};

	inputValuechange = (e: any) => {
		console.log('e:',e)
		let name = e.target.name;
		let val = e.target.value;
		this.setState({
			[name]: val
		})
	}

	render() {
		let {
			list,
			current,
			pageSize,
			start_time,
			end_time,
			count,
			shopList,
			size,
			dateList,
			real_consump_list,
			navTable,
			current_key,
			search_user_name,
			tel_number,
			recharge_number,
			own_shop_name_id,
		} = this.state;
		return (
			<div className={styles.balance}>
				<Row>
					{/* <Col>
						<div className={styles.balance_tip}>充值余额概况</div>
					</Col>
					<Col>
						<div className={styles.balance_consump}>
							{
								all_consump_list.map((item: any, index: any) => {
									return (
										<>
										<div key={item + index} className={styles.balance_consump_content}>
											<div className={styles.balance_consump_list} style={{height:  item.type === 'none' ? '1.9rem' : '1.33rem'}}>
												{
													item.type === 'none'
													?	<div className={styles.balance_consump_list_last}>
															<div className={styles.balance_consump_list_last_all}>
																<div className={styles.balance_consump_list_last_all_top}>
																	<div className={styles.balance_consump_list_last_all_top_title}>
																		{item.title}
																	</div>
																	<div className={styles.balance_consump_list_last_all_top_price}>
																		{item.price}
																	</div>
																</div>
																<div className={styles.balance_consump_list_last_all_bottom}>
																	<div className={styles.balance_consump_list_last_all_bottom_title}>
																		{item.all_title}
																	</div>
																	<div className={styles.balance_consump_list_last_all_bottom_price}>
																		<div className={styles.balance_consump_list_last_all_bottom_price_flag}></div>
																		<div className={styles.balance_consump_list_last_all_bottom_price_val}>{item.all_price}</div>
																	</div>
																</div>
															</div>
														</div>
													:	<div style={{height: '100%'}}>
															<div className={styles.balance_consump_list_main}>
																<div className={styles.balance_consump_list_main_title}>{item.title}</div>
																<div className={styles.balance_consump_list_main_price}>
																	<div className={styles.balance_consump_list_main_price_flag}></div>
																	<div className={styles.balance_consump_list_main_price_val}>{item.price}</div>
																</div>
															</div>
														</div>
												}
											</div>
										</div>
										{
											item.type !== 'none'
											?	<div style={{position: 'relative'}} className={item.type == 'plus' ? styles.balance_consump_list_main_plus : item.type == 'reduce' ? styles.balance_consump_list_main_reduce : styles.balance_consump_list_main_equal}></div>
											:	null
										}
										</>
									)
								})
							}
						</div>
					</Col>
					<Col>
						<div className={styles.balance_tip}>充值会员对账</div>
					</Col> */}
					<Col>
						<div style={{display: 'flex', alignItems:'center', justifyContent: 'space-between', paddingRight:'50px'}}>
							<div>
								<CustomDatePicker dateList={dateList} callback={(list: any) => { this.onCallback(list) }} currentDate={[start_time, end_time]}></CustomDatePicker>
							</div>
							<div className={styles.balance_export} onClick={this.exportTable}>
								<img src={goodsExport} className={styles.balance_export_img} />
								导出
							</div>
						</div>
					</Col>
					<Col>
						<div className={styles.balance_consump}>
							{
								real_consump_list && real_consump_list.length > 0 && real_consump_list.map((item: any, index: any) => {
									return (
										<div key={item + index} className={styles.balance_consump_list} style={{height: '1.33rem'}}>
											<div className={styles.balance_consump_list_main}>
												<div className={styles.balance_consump_list_main_price}>
													<div className={styles.balance_consump_list_main_price_val}>{item.price}</div>
												</div>
												<div className={styles.balance_consump_list_main_title}>{item.title}</div>
											</div>
										</div>
									)
								})
							}
						</div>
					</Col>
					<Col style={{position: 'relative'}}>
						<InsideNav navList={navTable} callBack={(key: string) =>this.navCallBack(key)}/>
						{
							current_key === 'INCOMEFORM'
							?	<div>
									<RechargeRecord own_shop_name_id={own_shop_name_id}　onSearchShopNmae={(id: any, callback: any) => {this.searchShopNmae(id,callback)}} start_time={start_time} end_time={end_time}></RechargeRecord>
								</div>
							:	null
						}
						{/* 余额消费 */}
						{
							current_key === 'FINANCEFPRM'
							?	<div>
									<ConsumeRecord own_shop_name_id={own_shop_name_id} onSearchShopNmae={(id: any, callback: any) => {this.searchShopNmae(id,callback)}} start_time={start_time} end_time={end_time}></ConsumeRecord>
								</div>
							:	null
						}
						{/* 邀请奖励 */}
						{
							current_key === 'RECHARGE'
							?	<div>
									<CommissionRecord own_shop_name_id={own_shop_name_id} onSearchShopNmae={(id: any, callback: any) => {this.searchShopNmae(id,callback)}} start_time={start_time} end_time={end_time}></CommissionRecord>
								</div>
							:	null
						}
						{/* 提现记录 */}
						{
							current_key === 'VEGETABLESALE_TABLE'
							?	<div>
									<WithdrawRecord own_shop_name_id={own_shop_name_id} onSearchShopNmae={(id: any, callback: any) => {this.searchShopNmae(id,callback)}} start_time={start_time} end_time={end_time}></WithdrawRecord>
								</div>
							:	null
						}
						{/* 调整余额 */}
						{
							current_key === 'FINANCE_BALANCE'
							?	<div>
									<AdjustRecord own_shop_name_id={own_shop_name_id} onSearchShopNmae={(id: any, callback: any) => {this.searchShopNmae(id,callback)}} start_time={start_time} end_time={end_time}></AdjustRecord>
								</div>
							:	null
						}
						{/* 跨店余额消费 */}
						{
							current_key === 'ACROSS'
							?	<div>
									<CrossRecord own_shop_name_id={own_shop_name_id} onSearchShopNmae={(id: any, callback: any) => {this.searchShopNmae(id,callback)}} start_time={start_time} end_time={end_time}></CrossRecord>
								</div>
							:	null
						}
					</Col>
				</Row>

				{/* <Table
					bordered
					dataSource={list}
					size="middle"
					columns={this.column}
					rowKey={Math.random().toString()}
					locale={{ emptyText: "暂无数据" }}
					pagination={{
						// current,
						// total: count,
						pageSize: size,
						hideOnSinglePage: true
						// onChange: this.pageChange,
						// showQuickJumper: true,
						// showSizeChanger: true,
						// showTotal: (total: number) => `共${total}条`,
						// onShowSizeChange: (current:number, size: number) => {this.setState({pageSize: size}, () => {this.getTimeData("currentMonth");})}

					}}
				/>
				<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
					<Pagination showTotal={(total: number) => `共${total}条`} current={current} total={count} pageSize={pageSize} onChange={this.pageChange} />
				</div> */}
			</div>
		);
	}
}

export default FinanceBalance;