import React, { useEffect, useState } from 'react'
import { Select, Row, DatePicker } from 'antd'
import { getDate } from '../../../utils/common'
import { getRequest } from '../../../api/http'
import { apiUrl } from '../../../api/api_url'
import down_icon from '../../../assret/images/down_icon.png'
import moment from 'moment'

import './index.scss'

const { Option } = Select
const { RangePicker } = DatePicker;

type Iprops = {
    callBack(data:any): void,
    exportTable: Function,
}

const FinanceOtherDatePicker = (props:Iprops)=>{

    const { callBack, exportTable } = props

    const dateList = [
        {
            type: 'today',
            name: '今日'
        },
        {
            type: 'yesterday',
            name: '昨日'
        },
        {
            type: 'beforeDay',
            name: '前日'
        },
        {
            type: 'lastWeek',
            name: '上周'
        },
        {
            type: 'lastMonth',
            name: '上月'
        },
        {
            type: 'currentMonth',
            name: '本月'
        },
    ] 

    const formatData = (num:any) => {
        return num > 9 ? num : `0${num}`
    }

    //获取日期开始日
    const getMonthStart = (year:number,month:number) => {
        // 获取当前日期
        return year + '-' + month + '-01'
    }

    //获取日期结束日
    const getMonthEnd = (year:number,month:number) => {
        // 获取当前日期
        return year + '-' + month + '-' + new Date(year,formatData(month),0).getDate()
    }

    const [ channelList, setChannelList ] = useState<any>([])
    const [ currentTab, setCurrentTab ] = useState(5)
    const [ start, setStart ] = useState(getMonthStart(new Date().getFullYear(),formatData(new Date().getMonth()+1)))
    const [ end, setEnd ] = useState(getMonthEnd(new Date().getFullYear(),formatData(new Date().getMonth()+1)))

    useEffect(()=>{
        getChannel()
    },[])

    const getChannel = async() => {
        const res:any = await getRequest(apiUrl.getGroupOperationChannel)
        if(res.code === 1){
            setChannelList(res.data)
        }
    }

    //选择时间tab
    const onChangeTabs = (index:number) => {
        if(index===currentTab) return false
        let start = ''
        let end = ''
        let year = new Date().getFullYear();
        let month = new Date().getMonth();
        switch (dateList[index].type) {
            case 'today': 
                start = getDate()
                end = start
                break 
            case 'yesterday': 
                start = getDate(new Date().getTime() - 1000 * 60 * 60 * 24 * 1)
                end = start
                break 
            case 'beforeDay': 
                start = getDate(new Date().getTime() - 1000 * 60 * 60 * 24 * 2)
                end = start
                break 
            case 'lastWeek': 
                start = moment().week(moment().week() -1).startOf('week').format('YYYY-MM-DD')
                end = moment().week(moment().week() -1).endOf('week').format('YYYY-MM-DD')
                break 
            case 'lastMonth': 
                start = moment().month(moment().month() -1).startOf('month').format('YYYY-MM-DD')
                end = moment().month(moment().month() -1).endOf('month').format('YYYY-MM-DD')
                break 
            case 'currentMonth': 
                start = getMonthStart(year,formatData(month+1))
                end = getMonthEnd(year,formatData(month+1))
                break 
        }
        setCurrentTab(index)
        setStart(start)
        setEnd(end)
        if(callBack) callBack({start_time:start,end_time:end})
    }

    //日期改变
    const dateChange = (e:any, dateString:any) => {
        if(dateString[0]){
            setStart(dateString[0])
            setEnd(dateString[1])
            setCurrentTab(-1)
            if(callBack) callBack({start_time:dateString[0],end_time:dateString[1]})
        }else{
            setStart(getDate())
            setEnd(getDate())
            setCurrentTab(0)
            if(callBack) callBack({start_time:getDate(),end_time:getDate()})
        }
    }

    //选择套餐来源
    const onChangeSelect = (e:any) => {
        if(callBack) callBack({channel:e})
    }

    return (
        <Row className='finance_other_datePicker' type="flex" align="middle" justify="space-between">
            <Row type="flex" align="middle">
                <Select defaultValue={"0"} className="select" onChange={(e:any)=>{onChangeSelect(e)}}>
                    <Option value="0">全部</Option>
                    {
                        Object.keys(channelList).map((item)=>{
                            return <Option key={item} value={item}>{[channelList[item]]}</Option>
                        })
                    }                    
                </Select>
                <Row className="tab" type="flex" align="middle">
                    {
                        dateList.map((item:any,index:number)=>{
                            return <div className={`tab_item ${currentTab === index ? 'tab_item_active' : ''}`} key={index} onClick={()=>{onChangeTabs(index)}}>{item.name}</div>
                        })
                    }
                </Row>
                <RangePicker
                    format={`YYYY-MM-DD`}
                    showTime={false}
                    onChange={(e, dateString) => dateChange(e, dateString)}
                    value={[moment(start, 'YYYY-MM-DD'),moment(end, 'YYYY-MM-DD')]}
                    placeholder={['开始时间', '结束时间']} 
                />
            </Row>
            <div className="new_btn" onClick={()=>{exportTable&&exportTable()}}>
                <img src={down_icon}></img>
                导出
            </div>
        </Row>
    )
}

export default FinanceOtherDatePicker
