import * as React from 'react';
import { useState, useEffect } from "react";
import { Modal, Button, Row, Col, Typography, Table } from 'antd';
import { apiUrl } from '../../../../api/api_url';
import { getRequest } from '../../../../api/http';

interface IProps {
  visible: boolean,
  closeModel(): void,
  updateProductId(d: object): void,
}

interface PItem {
  id: number
  name: string
  first_pic: string
  price: string
  created_at: string
}

const { Title } = Typography;
const OpenBindProductModel = (props: IProps) => {
  const [visible, setvisible] = useState(false);
  const [productList, setproductList] = useState([]);
  
  // const dataSource = [
  //   {
  //     key: '1',
  //     name: '胡彦斌',
  //     age: 32,
  //     address: '西湖区湖底公园1号',
  //   },
  //   {
  //     key: '2',
  //     name: '胡彦祖',
  //     age: 42,
  //     address: '西湖区湖底公园1号',
  //   },
  // ];

  useEffect(() => {
    setvisible(props.visible);
    _getProductList();
  }, [props.visible]);


  const _getProductList = async (page = 1) => {
    const data = {
      pageSize: 30,
      current: page
    };
    const res: any = await getRequest(apiUrl.productList, data);
    if(res && res.data) {
      setproductList(res.data);
    }
  }
  
  const tapBind = (e: PItem) => {
    props.updateProductId(e);
    props.closeModel();
  }

  const columns = [
    {
      title: '商品名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '商品价格',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: '创建时间',
      dataIndex: 'created_at',
      key: 'created_at',
    },{
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      render: () => <Button type="link">绑定</Button>
    },
  ];

  
  return <Modal
    title={null}
    width={'70vw'}
    visible={visible}
    closable={false}
    onOk={() => setvisible(false)}
    onCancel={() => props.closeModel()}
    okText="确认"
    closeIcon={null}
    footer={null}
    cancelText="取消"
  >
    <Row type="flex" justify="space-between">
      <Col span={14}>
        <Title level={4}>选择商品</Title>
      </Col>
        <Button type="primary">新增商品</Button>
    </Row>

    <Row style={{marginTop: 20}}>
      <Table dataSource={productList} 
              size="middle"
             columns={columns} 
             onRowClick={(e: PItem) => tapBind(e)} />
    </Row>
  </Modal>
}

export default OpenBindProductModel