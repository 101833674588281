import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Input, Select } from "antd";
import ApplyBottomBar from "./apply_bottom_bar";
import { getRequest } from "../../../../../api/http";
import { apiUrl } from "../../../../../api/api_url";
import { IApplyWithdrawalConfig } from "../apply_withdrawal";

const { Option } = Select;

interface ICardWithdrawal {
  updateParentData(data: any): void
  withDrawalConfig: IApplyWithdrawalConfig
  withdrawableRMB: string
}

interface IFormData {
  type: string
  amount: number
  truename: string
  mobile: string
  idcard: string
  bank: string
  account_number: string
}

interface IBankItem {
  title: string;
  value: number;
}

const CardWithdrawal = (props: ICardWithdrawal) => {
  const [formData, setformData] = useState<IFormData>({
    type: "bank",
    amount: 0,
    truename: "",
    mobile: "",
    idcard: "",
    bank: "",
    account_number: ""
  });

  const [banksList, setbanksList] = useState<Array<IBankItem>>([]);

  useEffect(() => {
    getBankList();
  }, []);

  const getBankList = async () => {
    const res: any = await getRequest(apiUrl.fetchBanksList);
    if (res && res.data) {
      setbanksList(res.data);
    }
  };

  const formOnChange = (key: string | number, value: string) => {
    const data = JSON.parse(JSON.stringify(formData));
    data[value] = key;
    setformData(data);
    props.updateParentData(data);
  };

  return (
    <Fragment>
      <Row type="flex" align="middle" style={{ marginTop: 20 }}>
        <Col style={{ width: 100, textAlign: "right" }}>开户行：</Col>
        <Col>
          <Select
            defaultValue="请选择开户行"
            style={{ width: 300 }}
            onChange={(e: string) => formOnChange(e, "bank")}
          >
            {banksList.map(el => (
              <Option key={el.title} value={el.title}>
                {el.title}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>

      <Row type="flex" align="middle" style={{ marginTop: 20 }}>
        <Col style={{ width: 100, textAlign: "right" }}>持卡人：</Col>
        <Col>
          <Input
            placeholder="请输入银行卡对应的真实姓名"
            value={formData.truename}
            onChange={e => formOnChange(e.target.value, "truename")}
            style={{ minWidth: 300 }}
          />
        </Col>
      </Row>
      
      <Row type="flex" align="middle" style={{ marginTop: 20 }}>
        <Col style={{ width: 100, textAlign: "right" }}>银行卡号：</Col>
        <Col>
          <Input
            placeholder="请输入银行卡卡号"
            value={formData.account_number}
            onChange={e => formOnChange(e.target.value, "account_number")}
            style={{ minWidth: 300 }}
          />
        </Col>
      </Row>

      <Row type="flex" align="middle" style={{ marginTop: 20 }}>
        <Col style={{ width: 100, textAlign: "right" }}>手机号码：</Col>
        <Col>
          <Input
            placeholder="请输入收款人手机号码"
            onChange={e => formOnChange(e.target.value, "mobile")}
            value={formData.mobile}
            style={{ minWidth: 300 }}
          />
        </Col>
      </Row>

      <Row type="flex" align="middle" style={{ marginTop: 20 }}>
        <Col style={{ width: 100, textAlign: "right" }}>身份证号码：</Col>
        <Col>
          <Input
            placeholder="请输入收款人银行卡号绑定的身份证号码"
            onChange={e => formOnChange(e.target.value, "idcard")}
            value={formData.idcard}
            maxLength={18}
            minLength={18}
            style={{ minWidth: 300 }}
          />
        </Col>
      </Row>

      <ApplyBottomBar amountChange={(val) => formOnChange(val, 'amount')} 
                      withDrawalConfig={props.withDrawalConfig} 
                      withdrawableRMB={props.withdrawableRMB}
                      applyType={2} />
    </Fragment>
  );
};

export default CardWithdrawal;
