import React from "react";
import { inject, observer } from "mobx-react"
import shopDecorationStore from '../../../stores/shopDecoration.store'

import DecorationHeader from './components/decorationHeader'
import DecorationHeaderPreview from './components/decorationHeaderPreview'
import DecorationLeft from './components/decorationLeft'
import DecorationRight from './components/decorationRight'
import DecorationCenter from './components/decorationCenter'
import DecorationCenterPreview from './components/decorationCenterPreview'
import DecorationEditItem from './components/decorationEditItem'


import styles from './wode.module.scss'

type IProps = {
    history: any
}

@observer
class DecorationMain extends React.Component<IProps,any> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            header_list: {
                left: [
                    {
                        type: 1,
                        txt: '返回'
                    }
                ],
                right: [
                    {
                        type: 1,
                        txt: '预览'
                    },
                    {
                        type: 1,
                        txt: '恢复默认设置'
                    },
                    {
                        type: 1,
                        txt: '保存'
                    },
                    {
                        type: 2,
                        txt: '保存并运用'
                    }
                ]
            },
            header_preview: {
                left: '预览：手机版',
                right: [
                    {
                        type: 1,
                        txt: '返回列表'
                    },
                    {
                        type: 1,
                        txt: '编辑页面'
                    },
                    {
                        type: 2,
                        txt: '使用'
                    }
                ]
            }
        }
    }

    componentWillUnmount() {
        shopDecorationStore.updateRefresh(1)
    }

    render() {
        const { header_list, header_preview } = this.state;

        const { merber_menu } = shopDecorationStore.shop_decoration_wode;

        return (
            <div className={styles.decorationMain}>
                <div className={styles.decorationMain_header}>
                    {
                        shopDecorationStore.shop_decoration_wode_preview === 0
                        ?   <DecorationHeader dataObj={header_list} history={this.props.history} ></DecorationHeader>
                        :   <DecorationHeaderPreview dataObj={header_preview} history={this.props.history} ></DecorationHeaderPreview>
                    }
                </div>
                {
                    shopDecorationStore.shop_decoration_wode_preview === 0
                    ?   <div className={styles.decorationMain_content}>
                            <div><DecorationLeft history={this.props.history} type={1}></DecorationLeft></div>
                            <div><DecorationCenter></DecorationCenter></div>
                            <div>
                                {
                                    merber_menu.current_is_edit === 0
                                    ?   <DecorationRight></DecorationRight>
                                    :   <DecorationEditItem></DecorationEditItem>
                                }
                            </div>
                        </div>
                    :   <div className={styles.decorationMain_content} style={{justifyContent:'center'}}>
                            <div><DecorationCenterPreview></DecorationCenterPreview></div>
                        </div>
                }
            </div>
        )
    }
}

export default DecorationMain