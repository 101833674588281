import React from 'react';
import InsideNav from '../../../component/insideNav/insideNav';
import { collectChildNav } from '../../../utils/permisstionUtil';
import Parinter from '../../parinter/parinter';
import StoreCharge from '../../storeCharge';
import WeappDistriutions from '../../weapp_distriution_manage/weapp_distriutions/weapp_distriutions';
import WeappDistriutionUsers from '../../weapp_distriution_manage/weapp_distriution_users/weapp_distriution_users';
import XcxWithdraw from '../../xcxWithdraw/xcxWithdraw';
import './fissionOperation.scss'

type IProps = {
    history: any
}
type IState = {
    navList: any
    current_key: string
}
const navTable = [
    {
        title: '裂变运营',
        isActive: false,
        KEY: "WEAPP_DISTRIUTIONS",
        uni_key: '1_9_1'
    },
    {
        title: '裂变可提现',
        isActive: false,
        KEY: "PARINTER",
        uni_key: '1_9_2'
    },
    {
        title: '提现记录',
        isActive: false,
        KEY: "XCX_WITHDRAW",
        uni_key: '1_9_3'
    },
    {
        title: '参与用户',
        isActive: false,
        KEY: "WEAPP_DISTRIUTIONS_USERS",
        uni_key: '1_9_4'
    },
    {
        title: '商家充值记录',
        isActive: false,
        KEY: "STORE_CHARGE",
        uni_key: '1_9_5'
    }
]
class FissionOperation extends React.Component<IProps,IState>{
    
    constructor(props:IProps) {
        super(props);
        this.state = {
            navList: [],
            current_key: "",
        }
    }

    componentDidMount(){
        this.initPageState();
    }

    initPageState = () =>{
        let navList = collectChildNav(navTable);
        let currentKey = localStorage.getItem('current_key') ?? navList[0].KEY;
        let isSetKey = false;
        navList.map((item:any) => {
            if(item.KEY == currentKey){
                item.isActive = true;
                isSetKey = true;
            }else{
                item.isActive = false;
            }
        })
        if(!isSetKey){
            currentKey = navList[0].KEY;
            navList[0].isActive = true;
        };
        this.setState({
            current_key: currentKey,
            navList: navList
        })
    }

    navCallBack(currentKey: string){
        localStorage.setItem('current_key',currentKey) 
        this.setState({
            current_key: currentKey
        })
    }

    render() {
        let { navList,current_key } = this.state;
        return <div>
            <InsideNav navList={navList} callBack={(key: string) =>this.navCallBack(key)}/>
            <div className="body">
                {
                    current_key === 'WEAPP_DISTRIUTIONS' ?  <WeappDistriutions />
                    :
                    current_key === 'PARINTER'  ?  <Parinter />
                    :
                    current_key === 'XCX_WITHDRAW' ?  <XcxWithdraw />
                    :
                    current_key === 'WEAPP_DISTRIUTIONS_USERS' ?  <WeappDistriutionUsers />
                    :
                    current_key === 'STORE_CHARGE' ?  <StoreCharge />
                    : <></>
                    
                }
            </div>
        </div>
    }

}

export default FissionOperation;