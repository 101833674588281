import { getRequest, postRequest } from "./../../api/http";
import { apiUrl } from "./../../api/api_url";

// 创建
export const createQRCodeRequest = async (param:object) =>{
    const res:any = await postRequest(`${apiUrl.createQRCode}`,param);
    return res;
};

// 修改
export const updateQRCodeRequest = async (param:object) =>{
    const res:any = await postRequest(`${apiUrl.updateQRCode}`,param);
    return res;
};

// 通过昵称查找openid
export const searchOpenid = async (param:object) =>{
    const res:any = await getRequest(`${apiUrl.searchOpenid}`,param);
    return res;
};