import React, { useEffect, useState } from 'react'
import { Table, Row, Avatar, Typography } from 'antd'
import { getRequest } from '../../../../../api/http';
import { apiUrl } from '../../../../../api/api_url';
import { IoverView } from '../participating_user';

const { Text } = Typography;

interface IParticipatingTableList {
  setoverView(data: IoverView): void
}

const ParticipatingTableList = (props: IParticipatingTableList) => {

  const [tableData, settableData] = useState<{total: number, items: Array<any>}>({total: 0, items: []});
  useEffect(() => {
    getTableData();
  },[]);

  const getTableData = async (current = 1) => {
    const parms = {
      pageSize: 10,
      current: current
    }
    const res: any = await getRequest(apiUrl.fetchShareUsers, parms);
    if(res && res.data) {
      if(current < 2) {
        settableData({ total: res.data.total, items: res.data.items });
      } else {
        settableData({ total: res.data.total, items: [...res.data.items]});
      }
      props.setoverView(res.data.overview);
    }
  }

  const columns = [
    {
      title: "用户昵称",
      dataIndex: "user",
      key: "user",
      render: (rowdata: any) => {
        return <Row>
          <Avatar src={rowdata.avatar} style={{marginRight: 10}} />
          <Text>{rowdata.nickname}</Text>
        </Row>
      }
    },
    {
      title: "邀请人数",
      dataIndex: "invited_users",
      key: "invited_users",
    },
    {
      title: "分销订单数",
      dataIndex: "order_counts",
      key: "order_counts",
    },
    {
      title: "一级订单",
      dataIndex: "level_1",
      key: "level_1",
      render: (rowdata: any) => {
        if(rowdata) {
          return <div>{rowdata.nickname}</div>
        }
        return <div>-- --</div>
      }
    },
    {
      title: "二级订单",
      dataIndex: "level_2",
      key: "level_2",
    },
    {
      title: "已到账受益",
      dataIndex: "back_amount",
      key: "back_amount",
    },
    {
      title: "未到账受益",
      dataIndex: "wait_amount",
      key: "wait_amount"
    },
    {
      title: "可提现金额",
      dataIndex: "withdraw_amount",
      key: "withdraw_amount",
    },
  ];

  return <Table columns={columns} 
  size="middle"
  dataSource={tableData.items} 
  rowKey={'id'} 
  pagination={{
    total: tableData.total, 
    pageSize: 1,
    onChange: (page: number) => getTableData(page),
  }} />
}

export default ParticipatingTableList