import React, { useEffect, useState, useRef } from 'react'
import ReactEcharts from "echarts-for-react";
import './lineChart.scss'

type Iprops = {
    color1:String,
    color2:String,
    bg1:String,
    bg2:String,
    lineData:any,
}

const SiyuLineChart = (props:Iprops)=>{
    
    const { lineData } = props

    const colorList = [ 'rgba(255,90,110,1)', 'rgba(35,207,75,1)', 'rgba(255, 183, 65, 1)', 'rgba(95, 90, 255, 1)', 'rgba(25, 227, 255, 1)', 'rgba(252, 65, 255, 1)', 'rgba(235, 229, 30, 1)', ]

    const bgList = [ 'rgba(255,90,110,0.2)', 'rgba(35,207,75,0.2)', 'rgba(255, 183, 65, 0.2)', 'rgba(95, 90, 255, 0.2)', 'rgba(25, 227, 255, 0.2)', 'rgba(252, 65, 255, 1)', 'rgba(235, 229, 30, 0.2)', ]

    const [option, setOption] = useState<any>({
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985',
                },
            },
            formatter: function(params:any) {
                var result = params[0].name+'<br>';
                params.forEach(function(item:any) {
                    result += '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' + item.color + '"></span>';
                    result +=item.seriesName.split('-')[0]+" : "+'<span style="color:#fff">'+ item.data+"</span><br>"
                        // if(parseFloat(item.data)>=0){
                        //     result +=item.seriesName+" : "+'<span style="color:#e30101">'+ item.data+"%</span><br>"
                        // }else if(parseFloat(item.data)<0){
                        //     result +=item.seriesName+" : "+'<span style="color:#049500">'+ item.data+"%</span><br>"
                        // }
        
                });
                return result;
            }
        },
        legend: {
            type:'scroll',
            data: ['社群1'],
            bottom: '15px',
            itemHeight: 15,
            borderColor :'yellow',
            icon:'circle',
            formatter: function (value:any) {
                return value.split('-')[0];
            },
        },
        color : [ 'rgba(255,90,110,1)'],
        grid: {
            left: '3%',
            right: '4%',
            bottom: '60px',
            top: '8%',
            containLabel: true,
            show:true,
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: false,
                data: [0, 1, 2, 3, 4, 5, 6],
            },
        ],
        yAxis: [
            {
                type: 'value',
            }
        ],        
        series: [
            {
                name: '社群1',
                type: 'line',
                // stack: '总量',
                areaStyle: {type: 'default',color:'rgba(255,90,110,0.2)'},//内容颜色
                symbol: 'none', // 拐点类型
                smooth: true,
                data: [0,0,0,0,0,0,0,],
            },
        ],
    });

    useEffect(()=>{
        if(lineData){
            //初始化数据
            let optionTemp:any = JSON.parse(JSON.stringify(option))
            optionTemp.xAxis[0].data = lineData.xLine
            optionTemp.series = []
            optionTemp.color = []
            lineData.groups.map((item:any,index:number)=>{
                optionTemp.series.push({
                    name: item.name+'-'+item.id,
                    type: 'line',
                    areaStyle: {type: 'default',color:bgList[index%7]},//内容颜色
                    symbol: 'none', // 拐点类型
                    smooth: true,
                    data: lineData.yLine[index].map((subItem:any)=>{return subItem.actual_fee}),
                })
                optionTemp.color.push(colorList[index%7])
            })
            optionTemp.legend.data = lineData.groups.map((item:any,index:number)=>{return item.name+'-'+item.id})
            console.log('lineData',lineData)
            console.log('optionTemp',optionTemp)
            setOption(optionTemp)
        }
    },[lineData])

    return (
        <div className="siyu_lineChart">
            <div style={{ height: '350px', width: '100%' }}>
                <ReactEcharts option={option} style={{ height: '100%', width: '100%' }} className='react_for_echarts' />
            </div> 
        </div>
    )
}

export default SiyuLineChart



