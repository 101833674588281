import React, { Fragment, useState, useEffect } from 'react'
import { Row, Input, Button, message } from 'antd'
import { postRequest } from '../../api/http';
import { apiUrl } from '../../api/api_url';
import { getIsPermisson, messageCustom } from '../../utils/permisstionUtil';

const RulesConfig = () => {

  const [isEdit, setisEdit] = useState(false);
  const [money, setmoney] = useState('');

  useEffect(() => {
    getRule()
  }, []);

  const getRule = async () => {
    const res:any = await postRequest(apiUrl.editRuleInfo);
    if(res && res.data) {
      setmoney(res.data.money);
    }
  }

  const editConfig = async () => {
    if(!getIsPermisson('1_6_3_1')){
      messageCustom();
      return;
    }
    if(isEdit) {
      console.log('保存')
      if(!Number(money)) {
        message.warn('请输入正确的金额！')
        return;
      }
      const res:any = await postRequest(apiUrl.editRule, { money });
      if(res && res.data) {
        console.log('res', res)
        message.success(res.message);
      }
    }
    setisEdit(!isEdit);
  }

  return <Fragment>
    <div className='l_content'>
    <Row type="flex" align="middle">
      每消费
      <Input suffix="元"
        style={{width: 110, margin: '0 10px'}}
        onChange={(e) => setmoney(e.target.value)}
        disabled={isEdit ? false : true}
        value={money}/> =
      1 积分
      {/* <Input suffix="积分" style={{width: 110, margin: '0 10px'}} disabled={isEdit ? false : true} /> */}
    </Row>

    <Row type="flex" justify="center" style={{marginTop: 100}}>
      <Button type="primary" onClick={editConfig}>{isEdit ? '保存' : '编辑'}</Button>
    </Row></div>
  </Fragment>
}

export default RulesConfig