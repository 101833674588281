import React from 'react';
import { Table, Row, Col, Button, DatePicker, Modal, message, Tooltip, Icon } from 'antd'
import { RangePickerValue } from "antd/lib/date-picker/interface";
import { getRequest } from '../../api/http';
import { apiUrl } from '../../api/api_url';
import moment from 'moment';
import './index.scss'
import { getIsPermisson, messageCustom } from '../../utils/permisstionUtil';
import CustomDatePicker from '../datePicker';
import goodsExport from '../../assret/images/goodsExport.png'
import equal_icon from '../../assret/images/equal_icon.png'
import reduce_icon from '../../assret/images/reduce_icon.png'
import add_icon from '../../assret/images/add_icon.png'
const { RangePicker } = DatePicker


interface FinanceFormProps {
    history: any,
}

const nowDate = new Date();
const yesterday = new Date(nowDate.getTime() - 24 * 60 * 60 * 1000)
const beforeYesterday = new Date(nowDate.getTime() - 24 * 2 * 60 * 60 * 1000)


class FinanceForm extends React.Component<FinanceFormProps> {
    column = [
        {
            title: "日期",
            dataIndex: "day",
            key: "day",
            className: "list_center"
        },
        {
            title: () => {
                return <div>实际打款<Tooltip title={`实际打款=昨天系统实收+前天未打款金额（微信实收+支付宝实收）`}>
                    <div className="finance_tip">
                        <Icon type="question-circle" theme="filled" />
                    </div>
                </Tooltip></div>
            },
            dataIndex: "settle_account_true",
            key: "settle_account_true",
            className: "list_center"
        },
        {
            title: "未打款",
            dataIndex: "settle_account_false",
            key: "settle_account_false",
            className: "list_center"
        },
        {
            title: "商家应得",
            dataIndex: "shop_total_amount",
            key: "shop_total_amount",
            className: "list_center"
        },
        {
            title: "实际收入",
            dataIndex: "actual_sum",
            key: "actual_sum",
            className: "list_center"
        },
        {
            title: "微信实收",
            children: [
                {
                    title: "代金券",
                    dataIndex: "cash",
                    key: "cash",
                    className: "list_center btn_green",
                    render: (text: any, record: any) => {
                        return (
                            <div
                                onClick={() => {
                                    this.discountDetail(1, record.day, "购买代金券明细");
                                }}
                            >
                                {record.wechat.list[0].value}
                            </div>
                        );
                    }
                },
                {
                    title: "权益卡",
                    dataIndex: "equity",
                    key: "equity",
                    className: "list_center btn_green",
                    render: (text: any, record: any) => {
                        return (
                            <div
                                onClick={() => {
                                    this.discountDetail(2, record.day, "购买权益卡明细");
                                }}
                            >
                                {record.wechat.list[1].value}
                            </div>
                        );
                    }
                },
                {
                    title: "充值",
                    dataIndex: "charge",
                    key: "charge",
                    className: "list_center btn_green",
                    render: (text: any, record: any) => {
                        return (
                            <div
                                onClick={() => {
                                    this.discountDetail(3, record.day, "用户充值明细", record);
                                }}
                            >
                                {record.wechat.list[2].value}
                            </div>
                        );
                    }
                },
                {
                    title: "消费",
                    dataIndex: "xiaofei",
                    key: "xiaofei",
                    className: "list_center",
                    render: (text: any, record: any) => {
                        return <div>{record.wechat.list[3].value}</div>;
                    }
                },
                {
                    title: "小计",
                    dataIndex: "total",
                    key: "total",
                    className: "list_center",
                    render: (text: any, record: any) => {
                        return <div>{record.wechat.total}</div>;
                    }
                }
            ]
        },
        {
            title: "支付宝实收",
            children: [
                {
                    title: "消费",
                    dataIndex: "ali",
                    key: "ali",
                    className: "list_center"
                }
            ]
        },
        {
            title: "第三方平台实收",
            children: [
            ]
        },
        {
            title: "现金实收",
            dataIndex: "money",
            key: "money",
            className: "list_center"
        },
        {
            title: "银行卡实收",
            dataIndex: "bank",
            key: "bank",
            className: "list_center"
        },
        {
            title: "操作",
            dataIndex: "action",
            key: "action",
            className: "list_center",
            render: (text: string, record: any) => {
                return <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div onClick={() => this.toDetail(record)} className='mr30 btn_green'>明细</div>
                    <div onClick={() => this.refresh(record)} className='btn_green'>刷新</div>
                </div>
            }
        },
        {
            title: "更新时间",
            dataIndex: "updated_at",
            key: "updated_at",
            className: "list_center"
        },
    ];

    state = {
        list: [],
        preview: {},
        current: 1,
        pageSize: 10,
        count: 0,
        start_time: "",
        end_time: "",
        visible: false,
        day: "",
        title: "",
        type: 0,
        cashInfo: {
            total: "0.00",
            list: []
        },
        equityInfo: {
            total: "0.00",
            list: []
        },
        chargeInfo: {
            total: "0.00",
            list: []
        },
        meiInfo: {
            total: "0.00",
            list: []
        },
        xingInfo: {
            total: "0.00",
            list: []
        },
        kouInfo: {
            total: "0.00",
            list: []
        },
        record: {},
    };

    componentDidMount() {
        this.getTimeData("currentMonth");
    }

    // 初始化数据
    _initInfo = async () => {
        let { pageSize, current, start_time, end_time } = this.state;
        let params: any = {
            current,
            pageSize
        };
        if (start_time !== "") {
            params.start_time = start_time;
            params.end_time = end_time;
        }
        let res: any = await getRequest(apiUrl.financeReport, params);
        if (res.code === 1) {
            let list: any = res.data.list;
            let wechatLen = 0;
            let thirdLen = 0;
            let aliLen = 0;
            let wxIndex = 0;
            let thirdIndex = 0;
            let aliIndex = 0;
            let column = this.column

            list && list.length > 0 && list.map((item: any, index: number) => {
                if (item.wechat.list.length > wechatLen) {
                    wechatLen = item.wechat.list.length
                    wxIndex = index;
                }
                item.wechat.list.map((wx: any) => {
                    if(wx.name == '消费') {
                        item[`微信${wx.name}`] = wx.value
                    } else if(wx.name == '服务费') {
                        item[`微信${wx.name}`] = wx.value
                    } else {
                        item[`${wx.name}`] = wx.value
                    }
                })
                if (item.third_plat.list.length > thirdLen) {
                    thirdLen = item.third_plat.list.length;
                    thirdIndex = index;
                }
                item.third_plat.list.map((third: any) => {
                    item[third.name] = third.total_fee
                })
                item.ali.list.map((ali: any) => {
                    if(ali.name == '消费') {
                        item[`阿里${ali.name}`] = ali.value
                    } else if(ali.name == '服务费') {
                        item[`阿里${ali.name}`] = ali.value
                    } else {
                        item[`${ali.name}`] = ali.value
                    }
                })
            })

            let wxArr: any = [];
            list && list.length > 0 && list[wxIndex].wechat.list.map((item: any, index: number) => {
                wxArr.push({
                    title: item.name != '服务费' ? item.name : () => {
                        return <div>服务费<Tooltip title={`服务费是微信官方收取${res.data.preview.service_charge_rate}手续费`}>
                            <div className="finance_tip">
                                <Icon type="question-circle" theme="filled" />
                            </div>
                        </Tooltip></div>
                    },
                    dataIndex: item.name,
                    key: item.name + index,
                    className: 'list_center',
                    render: (text: string, record: any, index: number) => {
                        return <div>
                                { item.name == '代金券'
                                    ?   <div className='btn_green' onClick={() => { this.discountDetail(1, record.day, "购买代金券明细"); }}>{record[item.name] ? record[item.name] : '0.00'}</div>
                                    :   null
                                }
                                {item.name == '权益卡'
                                    ? <div className='btn_green' onClick={() => { this.discountDetail(2, record.day, "购买权益卡明细"); }}> {record[item.name] ? record[item.name] : '0.00'}</div>
                                    : null}
                                {item.name == '充值'
                                    ? <div className='btn_green' onClick={() => { this.discountDetail(3, record.day, "用户充值明细", record); }}> {record[item.name] ? record[item.name] : '0.00'}</div>
                                    : null}
                                { item.name == '消费'
                                    ? <div> {record[`微信${item.name}`] ? record[`微信${item.name}`] : '0.00'}</div>
                                    : null
                                }
                                { item.name == '服务费'
                                    ? <div> {record[`微信${item.name}`] ? record[`微信${item.name}`] : '0.00'}</div>
                                    : null
                                }
                                {/* { item.name != '代金券' && item.name != '权益卡' && item.name != '充值'
                                    ? <div> {record[item.name] ? record[item.name] : '0.00'}</div>
                                    : null
                                } */}
                            </div>
                    }
                })
            })
            wxArr.push({
                title: '小计',
                dataIndex: 'wechat["total"]',
                key: 'wechat["total"]',
                className: 'list_center',
            })

            column[5].children = wxArr

            let aliArr: any = [];
            list && list.length > 0 && list[0].ali.list.map((item: any, index: number) => {
                aliArr.push({
                    title: item.name != '服务费' ? item.name : () => {
                        return <div>服务费<Tooltip title={`服务费是支付宝官方收取${res.data.preview.service_charge_rate}手续费`}>
                            <div className="finance_tip">
                                <Icon type="question-circle" theme="filled" />
                            </div>
                        </Tooltip></div>
                    },
                    dataIndex: item.name,
                    key: item.name + index,
                    className: 'list_center',
                    render: (text: string, record: any, index: number) => {
                        return <div>
                            {
                                item.name == '消费'
                                ?   record[`阿里${item.name}`] ? record[`阿里${item.name}`] : '0.00'
                                :   null
                            }
                            {
                                item.name == '服务费'
                                ?   record[`阿里${item.name}`] ? record[`阿里${item.name}`] : '0.00'
                                :   null
                            }
                        </div>
                    }
                })
            })
            aliArr.push({
                title: '小计',
                dataIndex: 'ali["total"]',
                key: 'ali["total"]',
                className: 'list_center',
            })

            column[6].children = aliArr

            let thidArr: any = [];
            list && list.length > 0 && list[thirdIndex].third_plat.list.map((item: any, index: number) => {
                thidArr.push({
                    title: item.name,
                    dataIndex: item.name,
                    key: item.name + index,
                    className: 'list_center',
                    render: (text: string, record: any, index: number) => {
                        return <div>
                            {item.name == '美团'
                                ? <div className='btn_green' onClick={() => { this.thirdDetail(4, record.day, "美团明细", item.channel, record,); }}>{record[item.name] ? record[item.name] : '0.00'}</div>
                                : null}
                            {item.name == '星选'
                                ? <div className='btn_green' onClick={() => { this.thirdDetail(5, record.day, "星选明细", item.channel, record,); }}>{record[item.name] ? record[item.name] : '0.00'}</div>
                                : null}
                            {item.name == '口碑'
                                ? <div className='btn_green' onClick={() => { this.thirdDetail(6, record.day, "口碑明细", item.channel, record,); }}>{record[item.name] ? record[item.name] : '0.00'}</div>
                                : null}
                            {
                                item.name != '美团' && item.name != '星选' && item.name != '口碑'
                                    ? <div>{record[item.name] ? record[item.name] : '0.00'}</div>
                                    : null
                            }
                        </div>

                    }
                })
            })
            thidArr.push({
                title: '小计',
                dataIndex: 'third_plat["total"]',
                key: 'third_plat["total"]',
                className: 'list_center',
            })

            column[7].children = thidArr;

            this.column = column;
            this.setState({
                count: res.data.preview && res.data.preview.count ? res.data.preview.count : 0,
                list: list,
                preview: res.data.preview ? res.data.preview : {}
            });
        }
    };
    discountDetail = async (type: number, day: string, title: string, record?: any) => {
        let params = {
            detail_type: type,
            start_time: day,
            end_time: day
        };
        let res: any = await getRequest(apiUrl.financeDetail, params);
        if (res.code == 1) {
            if (type == 1) {
                this.setState({
                    cashInfo: res.data
                });
            }
            if (type == 2) {
                this.setState({
                    equityInfo: res.data
                });
            }
            if (type == 3) {
                this.setState({
                    chargeInfo: res.data
                });
            }
            this.setState({
                type,
                title,
                day,
                record,
                visible: true
            });
        }
    };

    thirdDetail = async (type: number, day: string, title: string, channel: number, record?: any,) => {
        let res: any = await getRequest(apiUrl.channelDetail, { channel, start_time: day, end_time: day })
        if (res.code == 1) {
            if (type == 4) {
                this.setState({
                    meiInfo: res.data
                });
            }
            if (type == 5) {
                this.setState({
                    xingInfo: res.data
                });
            }
            if (type == 6) {
                this.setState({
                    kouInfo: res.data
                });
            }
            this.setState({
                type,
                title,
                day,
                record,
                visible: true
            });
        }
    }

    pageChange = (page: number) => {
        this.setState({
            current: page
        }, () => {
            this._initInfo();
        }
        );
    };

    timeChange = (time: RangePickerValue, timeString: [string, string]) => {
        this.setState({
            start_time: timeString[0],
            end_time: timeString[1]
        }, () => {
            this._initInfo();
        }
        );
    };

    // 导出
    exportTable = async () => {
        if(!getIsPermisson('1_3_2_1')){
            messageCustom();
            return;
          }
        const token = localStorage.getItem("token");
        const shop_id = localStorage.getItem("shop_id");
        window.open(`${process.env.REACT_APP_BASEURL}/v1/shop-report/export?token=${token}&start_time=${this.state.start_time}&end_time=${this.state.end_time}&shop_id=${shop_id}`
        );
    };

    // 打印
    printerTable = (record: any) => {
        Modal.confirm({
            title: "提示",
            content: `是否确定打印${record.day}的营业收入`,
            okText: "确认",
            cancelText: "取消",
            onOk: async () => {
                let res: any = await getRequest(apiUrl.shopReportPrinter, {
                    date: record.day
                });
                if (res.code === 1) {
                    message.success("打印成功");
                }
            },
            onCancel() { }
        });
    };

    //   刷新
    refresh = async (record: any) => {
        if(!getIsPermisson('1_3_2_3')){
            messageCustom();
            return;
          }
        let res: any = await getRequest(apiUrl.freshReport, { date: record.day })
        if (res.code == 1) {
            message.success('刷新成功')
            this.getTimeData("currentMonth");
        }
    }

    getTimeData = (
        type:
            | "today"
            | "yesterday"
            | "beforeYesterday"
            | "beforeMonth"
            | "beforeWeek"
            | "currentMonth"
    ) => {
        switch (type) {
            case "currentMonth":
                let currMonthStart = `${nowDate.getFullYear()}-${this.formatData(
                    nowDate.getMonth() + 1
                )}-01`;
                let currMonthEnd = `${nowDate.getFullYear()}-${this.formatData(
                    nowDate.getMonth() + 1
                )}-${this.formatData(nowDate.getDate())}`;
                this.setState(
                    {
                        start_time: currMonthStart,
                        end_time: currMonthEnd
                    },
                    () => {
                        this._initInfo();
                    }
                );
                break;
            case "beforeWeek":
                var d = new Date();
                var end = d.getTime() - 1000 * 60 * 60 * 24 * d.getDay();
                let start = d.getTime() - 1000 * 60 * 60 * 24 * (d.getDay() + 6);
                let startDay =
                    new Date(start).getFullYear() +
                    "-" +
                    this.formatData(new Date(start).getMonth() + 1) +
                    "-" +
                    this.formatData(new Date(start).getDate());
                let endDay =
                    new Date(end).getFullYear() +
                    "-" +
                    this.formatData(new Date(end).getMonth() + 1) +
                    "-" +
                    this.formatData(new Date(end).getDate());

                this.setState(
                    {
                        start_time: startDay,
                        end_time: endDay
                    },
                    () => {
                        this._initInfo();
                    }
                );
                break;
            case "beforeMonth":
                let beforeMonthStart =
                    nowDate.getFullYear() +
                    "-" +
                    this.formatData(nowDate.getMonth()) +
                    "-01";
                let currentMonth =
                    nowDate.getFullYear() +
                    "-" +
                    this.formatData(nowDate.getMonth() + 1) +
                    "-01";
                let num = new Date(
                    new Date(currentMonth).getTime() - 24 * 60 * 60 * 1000
                );
                let beforeMonthEnd = `${num.getFullYear()}-${this.formatData(
                    num.getMonth() + 1
                )}-${this.formatData(num.getDate())}`;
                this.setState(
                    {
                        start_time: beforeMonthStart,
                        end_time: beforeMonthEnd
                    },
                    () => {
                        this._initInfo();
                    }
                );
                break;
            case "beforeYesterday":
                let beforeYesDay = `${beforeYesterday.getFullYear()}-${this.formatData(
                    beforeYesterday.getMonth() + 1
                )}-${this.formatData(beforeYesterday.getDate())}`;
                this.setState(
                    {
                        start_time: beforeYesDay,
                        end_time: beforeYesDay
                    },
                    () => {
                        this._initInfo();
                    }
                );
                break;
            case "yesterday":
                let yesDay = `${yesterday.getFullYear()}-${this.formatData(
                    yesterday.getMonth() + 1
                )}-${this.formatData(yesterday.getDate())}`;
                this.setState(
                    {
                        start_time: yesDay,
                        end_time: yesDay
                    },
                    () => {
                        this._initInfo();
                    }
                );
                break;

            case "today":
                let toDay = `${nowDate.getFullYear()}-${this.formatData(
                    nowDate.getMonth() + 1
                )}-${this.formatData(nowDate.getDate())}`;
                this.setState(
                    {
                        start_time: toDay,
                        end_time: toDay
                    },
                    () => {
                        this._initInfo();
                    }
                );
                break;
        }
    };

    formatData = (num: number) => {
        return num > 9 ? num : `0${num}`;
    };

    toDetail = (record: any) => {
        if(!getIsPermisson('1_3_2_2')){
            messageCustom();
            return;
          }
        this.props.history.push({
            pathname: "/sidebar/financeDetail",
            state: {
                open_at: record.day,
                close_at: record.day
            }
        });
    };

    //   代金券
    toCash = (day: string) => {
        if(!getIsPermisson('1_3_2_4')){
            messageCustom();
            return;
          }
        this.props.history.push({
            pathname: "/sidebar/cashCouponList",
            query: {
                day
            }
        });
    };

    //   权益卡
    toEquity = (day: string) => {
        if(!getIsPermisson('1_3_2_4')){
            messageCustom();
            return;
          }
        this.props.history.push({
            pathname: "/sidebar/EquityCardOrder",
            query: {
                day
            }
        });
    };

    //   充值
    toCharge = () => {
        if(!getIsPermisson('1_3_2_4')){
            messageCustom();
            return;
          }
        let record: any = this.state.record;
        this.props.history.push({
            pathname: "/sidebar/recharge",
            query: {
                open_at: record.open_at,
                close_at: record.close_at
            }
        });
    };

    onCallback = (list:any) => {
        console.log('list', list);
        this.setState(
            {
                start_time: list[0],
                end_time: list[1]
            },
            () => {
                this._initInfo();
            }
        );
    }



    render() {
        let {
            list,
            current,
            pageSize,
            start_time,
            end_time,
            count,
        } = this.state;
        let preview: any = this.state.preview;

        return (
            <div className="income" style={{padding: '20px'}}>
                <div className='charge_title mb20'>财务收入</div>
                <div style={{display: 'flex', alignItems:'center', justifyContent: 'space-between', paddingRight:'50px'}}>
                    <div>
                        <CustomDatePicker callback={(list: any) => { this.onCallback(list) }} current={4} currentDate={[start_time, end_time]}></CustomDatePicker>
                    </div>
                    <div className='export_btn' onClick={this.exportTable}>
                        <img src={goodsExport} />导出
                    </div>
                </div>

                <div className='data_wrap mb30'>
                    <div className='data_shadow'>
                        <div className='data_title'>
                            <div className='data_title_l'>系统实收<Tooltip title={`已打款金额：${preview.settle_account_true}元和未打款${preview.settle_account_false}元`}>
                                <div className="finance_tip"><Icon type="question-circle" theme="filled" /></div></Tooltip>
                                </div>
                            <div className='data_title_r'>￥{preview.sys_actual_total}</div>
                        </div>
                        <div className="data_content">
                            <div className="data_con_item">
                                <div className='data_item_title'>
                                    <div className='data_item_title_t'>微信</div>
                                    <div className='data_item_title_b'>￥{preview.wechat}</div>
                                </div>
                                <img src={reduce_icon} className='action_icon_small_reduce' />
                                <div className='data_item_title'>
                                    <div className='data_item_title_t'>服务费<Tooltip title={`服务费是微信官方收取${preview.service_charge_rate}手续费`}>
                                    <div className="finance_tip">
                                        <Icon type="question-circle" theme="filled" />
                                    </div>
                                </Tooltip></div>
                                    <div className='data_item_title_b'>￥{preview.wechat_service_fee}</div>
                                </div>
                                <img src={equal_icon} className='action_icon_small_equal' />
                                <div className='data_item_title'>
                                    <div className='data_item_title_t'>实收</div>
                                    <div className='data_item_title_b'>￥{preview.wechat_actual}</div>
                                </div>
                            </div>
                            <div className="data_con_item">
                                <div className='data_item_title'>
                                    <div className='data_item_title_t'>支付宝</div>
                                    <div className='data_item_title_b'>￥{preview.ali}</div>
                                </div>
                                <img src={reduce_icon} className='action_icon_small_reduce' />
                                <div className='data_item_title'>
                                    <div className='data_item_title_t'>服务费<Tooltip title={`服务费是支付宝官方收取${preview.service_charge_rate}手续费`}>
                                    <div className="finance_tip">
                                        <Icon type="question-circle" theme="filled" />
                                    </div>
                                </Tooltip></div>
                                    <div className='data_item_title_b'>￥{preview.ali_service_fee}</div>
                                </div>
                                <img src={equal_icon} className='action_icon_small_equal' />
                                <div className='data_item_title'>
                                    <div className='data_item_title_t'>实收</div>
                                    <div className='data_item_title_b'>￥{preview.ali_actual}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src={add_icon} className='action_icon_add' />
                    <div className='data_shadow'>
                        <div className='data_title'>
                            <div className='data_title_l'>第三方平台<Tooltip title={'第三方平台总收入，扣除第三方手续费'}>
                                <div className="finance_tip">
                                    <Icon type="question-circle" theme="filled" />
                                </div>
                            </Tooltip></div>
                            <div className='data_title_r'>￥{preview.third_actual_total_fee}</div>
                        </div>
                        <div className="data_content">
                            {
                                preview.others && preview.others.map((item:any) => {
                                    return <div className="data_con_item">
                                        <div className='data_item_title'>
                                            <div className='data_item_title_t'>{item.name}</div>
                                        </div>
                                        <div className='data_item_title'>
                                            <div className='data_item_title_b'>￥{item.total_fee_actual}</div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                    <img src={add_icon} className='action_icon_add' />
                    <div className='data_shadow'>
                        <div className='data_title'>
                            <div className='data_title_l'>现金实收</div>
                            <div className='data_title_r'>￥{preview.money}</div>
                        </div>
                        <div className='data_title'>
                            <div className='data_title_l'>银行卡实收</div>
                            <div className='data_title_r'>￥{preview.bank}</div>
                        </div>
                    </div>
                    <img src={equal_icon} className='action_icon_equal' />
                    <div className='data_shadow'>
                        <div className='data_total'>
                            <div className="data_total_name">商家应得<Tooltip title={'商家应得=系统实收+第三方平台+现金实收+银行卡实收'}>
                                <div className="finance_tip">
                                    <Icon type="question-circle" theme="filled" />
                                </div>
                            </Tooltip></div>
                            <div className="data_total_value">￥{preview.shop_total_amount}</div>
                        </div>
                    </div>
                </div>


            <div style={{background:'#fff'}} className='finance_table_wrap'>
                <Table
                    bordered
                    dataSource={list}
                    size="middle"
                    columns={this.column}
                    rowKey={"day"}
                    locale={{ emptyText: "暂无数据" }}
                    pagination={{
                        current,
                        total: count,
                        pageSize,
                        onChange: this.pageChange,
                        showQuickJumper: true,
                        showSizeChanger: true,
                        showTotal: (total: number) => `共${total}条`,
                        onShowSizeChange: (current: number, size: number) => { this.setState({ pageSize: size }, () => { this.getTimeData("currentMonth"); }) }
                    }}
                >
                </Table></div>

                <Modal
                    visible={this.state.visible}
                    footer={null}
                    title={this.state.title}
                    onCancel={() => {
                        this.setState({ visible: false });
                    }}
                >
                    {this.state.type == 1 ? (
                        <div className="info_wrap">
                            <div className="info_title">
                                平台代金券实收：{this.state.cashInfo.total}元
              </div>
                            {this.state.cashInfo.list.map((item: any) => {
                                return (
                                    <div className="deduction_item">
                                        <div className="">
                                            {item.name} X{item.count}
                                        </div>
                                        <div>{item.total_fee}</div>
                                    </div>
                                );
                            })}
                            <div
                                className="btn_detail"
                                onClick={() => this.toCash(this.state.day)}
                            >
                                查看详情
              </div>
                        </div>
                    ) : null}

                    {this.state.type == 2 ? (
                        <div className="info_wrap">
                            <div className="info_title">
                                权益卡实收：{this.state.equityInfo.total}元
              </div>
                            {this.state.equityInfo.list.map((item: any) => {
                                return (
                                    <div className="deduction_item">
                                        <div className="">
                                            {item.name} X{item.count}
                                        </div>
                                        <div>{item.total_fee}</div>
                                    </div>
                                );
                            })}
                            <div
                                className="btn_detail"
                                onClick={() => this.toEquity(this.state.day)}
                            >
                                查看详情
              </div>
                        </div>
                    ) : null}

                    {this.state.type == 3 ? (
                        <div className="info_wrap">
                            <div className="info_title">
                                充值实收：{this.state.chargeInfo.total}元
              </div>
                            {this.state.chargeInfo.list.map((item: any) => {
                                return (
                                    <div className="deduction_item">
                                        <div className="">
                                            {item.name} X{item.count}
                                        </div>
                                        <div>{item.total_fee}</div>
                                    </div>
                                );
                            })}

                            <div className="btn_detail" onClick={() => this.toCharge()} >
                                查看详情
              </div>
                        </div>
                    ) : null}

                    {this.state.type == 4 ? (
                        <div className="info_wrap">
                            <div className="info_title">
                                美团实收：{this.state.meiInfo.total}元
              </div>
                            {this.state.meiInfo.list.map((item: any) => {
                                return (
                                    <div className="deduction_item">
                                        <div className="">
                                            {item.channel_name}
                                        </div>
                                        <div className="">
                                            {item.name} X{item.count}
                                        </div>
                                        <div>{item.total_fee}</div>
                                    </div>
                                );
                            })}
                        </div>
                    ) : null}

                    {this.state.type == 5 ? (
                        <div className="info_wrap">
                            <div className="info_title">
                                星选实收：{this.state.xingInfo.total}元
              </div>
                            {this.state.xingInfo.list.map((item: any) => {
                                return (
                                    <div className="deduction_item">
                                        <div className="">
                                            {item.channel_name}
                                        </div>
                                        <div className="">
                                            {item.name} X{item.count}
                                        </div>
                                        <div>{item.total_fee}</div>
                                    </div>
                                );
                            })}
                        </div>
                    ) : null}

                    {this.state.type == 6 ? (
                        <div className="info_wrap">
                            <div className="info_title">
                                口碑实收：{this.state.kouInfo.total}元
              </div>
                            {this.state.kouInfo.list.map((item: any) => {
                                return (
                                    <div className="deduction_item">
                                        <div className="">
                                            {item.channel_name}
                                        </div>
                                        <div className="">
                                            {item.name} X{item.count}
                                        </div>
                                        <div>{item.total_fee}</div>
                                    </div>
                                );
                            })}
                        </div>
                    ) : null}
                </Modal>
            </div>
        );
    }
}

export default FinanceForm;