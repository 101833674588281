import React, { Fragment, useEffect, useState } from 'react'
import { Table, Row, Button, Drawer, Typography, Col, Icon, Modal, Input, message } from 'antd'
import { getRequest, postRequest } from '../../../../api/http';
import { apiUrl } from '../../../../api/api_url';

const { Text } = Typography;

interface IOrderPaddingProps {
  showChargeBacks: boolean
  changeVisitity(status: boolean): void
  code: string
}

interface IorderData {
  desk: string
  amount: number
  price: string
  price_vip: string
  code: string
  status: number
  status_display: string
  coupons_pay: string
  actual_fee: string
  dishes: Array<{
    mark: string,
    dishes: Array<{
      id: string
      name: string
      count: number
      price: string
      main_pic: string
      standard: string
      price_vip: string
      dish_weigh_value_id: any,
      material_key: any
    }>,
    created_at: string
    fan: {
      headimgurl: string,
      nickname: string
    }
  }>
  list: Array<{
    id: string
    name: string
    count: number
    orgain_count: number
    standard: string
    price: string
    price_vip: string
    dish_weigh_value_id: any,
    material_key: any
  }>
  created_at: string
  paid_at: string
  fan: {
    headimgurl: string,
    nickname: string
  } | null
}

const OrderChargebackDrawer = (props: IOrderPaddingProps) => {

  const titleWidth = 80;
  const { code } = props;
  const [showCheckModal, setshowCheckModal] = useState(false);
  const [currentChargeBackIdx, setcurrentChargeBackIdx] = useState(0);
  const [currChargeBackCount, setcurrChargeBackCount] = useState(1);
  const [currSelectDishes, setcurrSelectDishes] = useState<Array<any>>([]);
  const [currChargeBackData, setcurrChargeBackData] = useState({
    id: "",
    name: "",
    count: -1,
    orgain_count: 0,
    standard: "",
    price: "0.00",
    price_vip: "0.00",
    dish_weigh_value_id: 0,
    material_key: null
  })
  const [orderData, setorderData] = useState<IorderData>({
    desk: '',
    amount: 0,
    price: '',
    price_vip: '',
    code: '',
    status: 0,
    coupons_pay: '',
    actual_fee: '',
    status_display: '',
    dishes: [],
    list: [],
    created_at: '',
    paid_at: '',
    fan: null
  });

  useEffect(() => {
    if(props.showChargeBacks) {
      getOrderDetail()
    }
  }, [code])

  const getOrderDetail = async () => {
    if(props.code) {
      const parms = {
        code: props.code
      }
      const res: any = await getRequest(apiUrl.foodOrderDetail, parms);
      if(res && res.data) {
        const data: any = res.data;
        data.list.forEach((item: any) => {
          item.orgain_count = item.count;
        });
        setorderData(data)
      }
    }
  }

  const columns = [{
    title: '商品名称',
    dataIndex: "name",
    key: "name",
  },{
    title: '规格',
    dataIndex: "standard",
    key: "standard",
  },{
    title: '已点份数',
    dataIndex: "count",
    key: "count",
  }]

  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      setcurrSelectDishes(selectedRows);
    },
    getCheckboxProps: (record:any) => ({
      disabled: record.order_dish_refund_num && record.count <= record.order_dish_refund_num, // Column configuration not to be checked
      name: record.name,
    }),
  };

  const editOrderColumns = [{
    title: '商品名称',
    dataIndex: "name",
    key: "name",
  },{
    title: '规格',
    dataIndex: "standard",
    key: "standard",
  },{
    title: '实付(元)/份数(份数)',
    // dataIndex: "count",
    key: "count",
    render: (rowdata: any, curr: any, idx: number) => {
      return <Row>
        <Text>{(curr.count * curr.price).toFixed(2)}元/{curr.count}份</Text>
      </Row>
    }
  },{
    title: '操作',
    key: "action",
    render: (rowdata: any, curr: any, idx: any) => {
      if(rowdata) {
        return <Row type="flex" justify="center">
          <Button size="small" disabled={rowdata.order_dish_refund_num && rowdata.count <= rowdata.order_dish_refund_num} type="link" onClick={() => chargeBack(idx)}>退款</Button>
        </Row>
      }
      return <Row></Row>
    }
  }]

  const chargeBack = (idx: number) => {
    setcurrentChargeBackIdx(idx);
    setcurrChargeBackData({...orderData.list[idx]});
    setshowCheckModal(true);
  }

  // 选择菜品退单
  const selectOrderChargeBack = async () => {
    const list:any = [];
    currSelectDishes.forEach((item) => {
      list.push({
        dish_id: item.id,
        count: item.count,
        standard: item.standard,
        dish_weigh_value_id: item.dish_weigh_value_id ? item.dish_weigh_value_id : 0,
        material_key: item.material_key ? item.material_key : null
      })
    })

    const parms = {
      code: props.code,
      refund: list
    }
    const res:any = await postRequest(apiUrl.foodChargeBack, parms)
    if(res && res.data) {
      console.log('res', res);
      setshowCheckModal(false);
      getOrderDetail()
    }
  }

  const orderTableFooter = () => {
    return <Row type="flex" justify="space-between">
      <Button disabled={currSelectDishes.length === 0 ? true : false} onClick={() => selectOrderChargeBack()}>退款</Button>
      <Col>
        <Row type="flex">
          <Col style={{width: 100, textAlign: "right"}}>合计：</Col>
          <Col>{orderData.price}元</Col>
        </Row>
        <Row type="flex">
          <Col style={{width: 100, textAlign: "right"}}>使用优惠：</Col>
          <Col>{orderData.coupons_pay}元</Col>
        </Row>
        <Row type="flex">
          <Col style={{width: 100, textAlign: "right"}}>实付：</Col>
          <Col>{orderData.actual_fee}元</Col>
        </Row>
      </Col>
    </Row>
  }

  const decrementChargeBackCount = () => {
    if(currChargeBackCount > 1) {
      setcurrChargeBackCount(currChargeBackCount - 1);
      setcurrChargeBackData({...orderData.list[currentChargeBackIdx]});
    }
  }

  const incrementChargeBackCount = () => {
    if(currChargeBackCount < orderData.list[currentChargeBackIdx].count) {
      setcurrChargeBackCount(currChargeBackCount + 1);
      setcurrChargeBackData({...orderData.list[currentChargeBackIdx]});
    }
  }

  // 获取退款份数总金额
  const getChargeMoney = () => {
    let money = 0;
    const price = Number(currChargeBackData.price);
    if(price){
      money = currChargeBackCount * price
    }
    return money.toString();
  }

  // 确定部分退款
  const sureCharge = async () => {
    const parms = {
      code: props.code,
      refund: [{
        dish_id: currChargeBackData.id,
        count: currChargeBackCount,
        standard: currChargeBackData.standard,
        dish_weigh_value_id: currChargeBackData.dish_weigh_value_id ? currChargeBackData.dish_weigh_value_id : 0,
        material_key: currChargeBackData.material_key ? currChargeBackData.material_key : null
      }]
    }

    const res:any = await postRequest(apiUrl.foodChargeBack, parms)
    if(res && res.data) {
      setshowCheckModal(false);
      message.success(`${res.message}`)
      getOrderDetail()
    } else {
      message.error(`${res.message}`)
    }

  }

  return  <Fragment>
    <Drawer
        title="订单信息"
        placement={`left`}
        closable={false}
        onClose={() => props.changeVisitity(false)}
        width={800}
        destroyOnClose
        visible={props.showChargeBacks}
      >
        <Row type="flex" style={{marginBottom: 10}}>
          <Col style={{width: titleWidth, textAlign: "left", marginRight: 10}}>
            <Text strong >订单号</Text>
          </Col>
          <Text >{orderData.code}</Text>
        </Row>
        <Row type="flex" style={{marginBottom: 10}}>
          <Col style={{width: titleWidth, textAlign: "left", marginRight: 10}}>
            <Text strong >餐台号</Text>
          </Col>
          <Text >{orderData.desk}</Text>
        </Row>
        <Row type="flex" style={{marginBottom: 10}}>
          <Col style={{width: titleWidth, textAlign: "left", marginRight: 10}}>
            <Text strong >订单状态</Text>
          </Col>
          <Text >{orderData.status_display}</Text>
        </Row>

        <Table rowSelection={rowSelection}
          columns={editOrderColumns}
          dataSource={orderData.list}
          pagination={false}
          rowKey={`id`}
          size="middle"
          footer={() => orderTableFooter()}
          />

        <Row type="flex" justify="center" style={{marginTop: 50}}>
          <Button onClick={() => props.changeVisitity(false)}>取消</Button>
        </Row>
      </Drawer>

      <Modal
        title="请确认退款份数和金额"
        visible={showCheckModal}
        okText={`确定退款`}
        cancelText={`取消`}
        onOk={() => sureCharge()}
        onCancel={() => setshowCheckModal(false)}
      >
        <Row type="flex" style={{marginBottom: 20}}>
          <Col>商品名称</Col>
          <Col offset={4}>{currChargeBackData.name}</Col>
        </Row>
        <Row type="flex" style={{marginBottom: 20}}>
          <Col>已支付份数</Col>
          <Col offset={3}>{currChargeBackData.count}份</Col>
        </Row>
        <Row type="flex" style={{marginBottom: 20}}>
          <Col>退款份数</Col>
          <Col offset={4}>
            <Row type="flex" align="middle">
              <Button size="small"
                disabled={currChargeBackCount === 1 ? true : false}
                onClick={() => decrementChargeBackCount()}><Icon type="minus" /></Button>
              <p style={{width: 50, textAlign: "center", margin: 0}}>{currChargeBackCount}</p>
              <Button size="small"
               disabled={currChargeBackCount === currChargeBackData.orgain_count}
               onClick={() => incrementChargeBackCount()}><Icon type="plus" /></Button>
            </Row>
          </Col>
        </Row>
        <Row type="flex" align="middle" style={{marginBottom: 20}}>
          <Col>退款金额</Col>
          <Col offset={3}>
            <Input style={{width: 100, marginLeft: 20}} disabled={true} value={getChargeMoney()} suffix="元" />
          </Col>
        </Row>
      </Modal>
</Fragment>

}

export default OrderChargebackDrawer