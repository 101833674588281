import React, { useEffect, useState } from 'react'
import './adverEdit.scss'
import { Select, Input, Button, message, Upload, Modal, Table, Row, Col, Radio, DatePicker } from 'antd';
import { getRequest, postRequest } from '../../../api/http';
import { apiUrl } from '../../../api/api_url';
import { RouteComponentProps } from 'react-router-dom';
import BraftEditor from 'braft-editor';

const { Option } = Select
const { RangePicker } = DatePicker;
interface Iprops extends RouteComponentProps{

}

/**
 * 添加广告或编辑
 */
const QINIU_SERVER = "https://up-z2.qiniup.com";  // 七牛上传地址

const AdverEdit = (props: Iprops) => {
    const columns = [
        {
          title: '商品名称',
          dataIndex: 'name',
          key: 'name',
          className: 'list_center',
        },
        {
            title: '所属分类',
            dataIndex: 'cat_name',
            key: 'cat_name',
            className: 'list_center',
        },
        {
            title: '商品价格',
            dataIndex: 'price',
            key: 'price',
            className: 'list_center',
        },
    ];
    const [cover_url, setCoverUrl] = useState('');
    const [qiniuParam,setQiniuParam] = useState({token:'',imageKey:''});
    const [position,setPosition] = useState(0); // 广告展示位置



    const [dishesVisible,setDishesVisible] = useState(false); // 菜品弹窗
    const [productList,setProductList] = useState([]);
    const [adverInfo,setAdverInfo] = useState<any>(null);
    const [currentPage,setCurrentPage] = useState(1);
    const [tempProducts,setTempProducts] = useState([]);
    const [dishObj,setDishObj] = useState<any>({});
    const [positionList, setpositionList] = useState([])
    const [redirect_types, setredirect_types] = useState([])

    const [name, setname] = useState<string>('');
    const [size, setsize] = useState<string>('');
    const [time_type, settime_type] = useState<number>(1)
    const [start_time, setstart_time] = useState<string>('')
    const [end_time, setend_time] = useState<string>('')
    const [type,setType] = useState(3);  // 广告类型
    const [redirect_url,setRedirectUrl] = useState('');// 跳转地址
    const [redirect_type, setredirect_type] = useState(0)
    const [redirect_id, setredirect_id] = useState(0)
    const [order_by,setOrderBy] = useState('0'); // 排序
    const [is_show, setis_show] = useState<number>(1) //状态
    const [editorState, seteditorState] = useState<any>(BraftEditor.createEditorState(null))
    const [priority, setpriority] = useState<number>(0) //状态


    useEffect(() => {
        let adverInfo1 = props.location.state;
        if(adverInfo1){
            // 整理数据
            collectValue(adverInfo1);
        }
        getGoods();
        getAdverConfig();
    }, []);

    // 获取商品列表
    const getGoods = async () =>{
        const params:any = {
            current: currentPage,
            pageSize: 120,
            up_status: 4
        };

        const res:any = await getRequest(apiUrl.goodList,params);
        if(res.code === 1){
            setProductList(res.data);
            setTempProducts(res.data);
        }
    }

    // 获取广告版位信息
    const getAdverConfig = async () =>{
        const res: any = await getRequest(apiUrl.advertConfig);
        if(res.code === 1){
            setpositionList(res.data.position)
            setredirect_types(res.data.redirect_type)
        }else{
            message.error(res.message)
        }
    }

    // 初始化数据
    const collectValue = async (adverInfo1:any) =>{
        let res:any = await getRequest(apiUrl.advertInfo,{id: adverInfo1.id})
        if(res.code == 1){
            adverInfo1 = res.data;

        console.log('adverInfo1',adverInfo1);
        setname(adverInfo1.name)
        settime_type(adverInfo1.time_type)
        if(time_type == 1){
            setstart_time(adverInfo1.start_time)
            setend_time(adverInfo1.end_time)
        }
        setPosition(adverInfo1.position);
        setAdverInfo(adverInfo1)
        setRedirectUrl(adverInfo1.redirect_url);
        setCoverUrl(adverInfo1.cover_url);

        setType(adverInfo1.type);
        setOrderBy(adverInfo1.order_by);
        if(adverInfo1.type === 1){
            setredirect_type(adverInfo1.redirect_type)
        }
        if(adverInfo1.type === 1 && adverInfo1.redirect_type === 3){
            setredirect_id(adverInfo1.redirect_id)
        }
        if(adverInfo1.redirect_type === 1 && adverInfo1.type === 1){
            setDishObj({
                id: adverInfo1.redirect_id,
                name: adverInfo1.dish_name
            })
        }else if(adverInfo1.redirect_type === 3){
            setredirect_id(adverInfo1.redirect_id)
        }
        if(adverInfo1.desc){
            seteditorState(BraftEditor.createEditorState(adverInfo1.desc))
        }
        setis_show(adverInfo1.is_show)

        setpriority(adverInfo1.priority);

        if(adverInfo1.position == 22221121){
            setOrderBy(adverInfo1.order_by);
        }
    }
    }

    // 筛选 商品
    const filterProducts = (e:string) =>{
        console.log(e);
        if(e){
            let temArray:any = [];
            tempProducts.map((item:any) =>{

                if(item.name.indexOf(e) != -1){
                    temArray.push(item)
                }
            })
            setProductList(temArray);
        }else{
            setProductList(tempProducts);
        }
    }

    // 保存广告信息
    const saveAderInfo = () =>{
        if(adverInfo !== null){
            //编辑广告
            checkoutRequestParam();
        }else{
            // 添加广告
            checkoutRequestParam();
        }
    }

    const editCheckoutRequestParam = () =>{
        let param:any = {};
        console.log(position);
        if(position !== adverInfo.position && position !== 0){
            param.position = position;
        }else{
            param.position = adverInfo.position;
        }
        if(type !== adverInfo.type && type !== 0){
            param.type = type;
        }else{
            param.type = adverInfo.type;
        }
        if (type === 2 && redirect_url === '') {
            message.warning('请填写页面地址');
            return
        }
        if (type === 1 && redirect_url === '') {
            message.warning('请选择对应的页面');
            return
        }
        if(type === 1 && redirect_url == '/pages/dishesMenu/dishesMenu' && Object.keys(dishObj).length == 0){
            message.warning('请绑定商品');
            return
        }
        if(cover_url !== adverInfo.cover_url){
            param.cover_url = cover_url;
        }else{
            param.cover_url = adverInfo.cover_url;
        }
        if(redirect_url){
            param.redirect_url = type === 1 ? redirect_url : '';
        }
        if(order_by !== adverInfo.order_by){
            param.order_by = order_by;
        }else{
            param.order_by = adverInfo.order_by;
        }
        param.dish_id = type === 1 ?  dishObj.id : 0;
        console.log('param',param)
        if(JSON.stringify(param) !== '{}'){
            //提交信息
            editAdver(param);
        }
    }

    const checkoutRequestParam = () => {
        let param: any = {};
        if(!name){ message.warning('请输入广告标题'); return }
        if(time_type == 1 && !start_time) {  message.warning('请选择展示时间'); return }
        if (position === 0) { message.warning('请选择广告版位'); return }
        if (cover_url === '') { message.warning('请上传背景图'); return };

        if (type === 2 && redirect_url === '') {
            message.warning('请填写页面地址');
            return
        }
        if (type === 1 && redirect_url === '') {
            message.warning('请选择对应的页面');
            return
        }

        param.name = name;
        param.time_type = time_type;
        if(time_type == 1){
            param.start_time = start_time;
            param.end_time = end_time;
        }
        param.position = position;
        param.type = type;
        param.cover_url = cover_url;
        if(type === 1 || type === 2){
            param.redirect_url = redirect_url;
        }
        if(type === 1 && redirect_type == 3 && redirect_id){
            param.redirect_id = redirect_id;
        }
        if(type === 1 && redirect_type == 1 && Object.keys(dishObj).length !== 0){
            param.redirect_id = dishObj.id;
        }
        if(type === 1 && redirect_type){
            param.redirect_type = redirect_type;
        }

        if(editorState){
            param.desc = editorState.toHTML();
        }
        if(position == 22221121){
            param.order_by = order_by;
        }

        param.priority = priority;
        param.is_show = is_show;
        if(adverInfo !== null){
            editAdver(param)
        }else{
            addAdver(param);
        }

    }

    // 编辑广告
    const editAdver = async (param:any) =>{
        const res: any = await postRequest(apiUrl.editAdver+'/'+adverInfo.id,param);
        if(res.code === 1){
            message.success("编辑广告成功");
            props.history.push('/sidebar/adverManager');
        }else{
            message.error(res.message);
        }
    }

    // 添加广告
    const addAdver = async (param:any) =>{
        const res: any = await postRequest(apiUrl.addAdver,param);
        if(res.code === 1){
            message.success("添加广告成功");
            props.history.push('/sidebar/adverManager');
        }else{
            message.error(res.message);
        }
    }

    // 上传图片前
    const handleBeforeUpload = async () => {
        const res: any = await getRequest(apiUrl.getUploadToken, {
            type: "poster"
        });
        if (res && res.data) {
            const key = `${Math.random().toString(36).slice(-8)}${new Date().valueOf()}.png`;
            let qiniuParamTemp:any = {};
            qiniuParamTemp.imageKey = key;
            qiniuParamTemp.token = res.data.upload_token;
            setQiniuParam(qiniuParamTemp);
        }
    };

    // 海报改变监听
    const handleUploadChange = (e: any) => {
        if (e.file && e.file.status === "done") {
            setCoverUrl(e.file.response.url);
        }
    };

    const inputChange = (e:any) => {
        let name = e.target.name;
        let value = e.target.value;
        if(name === 'name'){
            setname(value)
        }
        if(name === 'time_type'){
            settime_type(value)
        }
        if(name === 'type'){
            if(adverInfo && adverInfo.type !== 2){
                setRedirectUrl('')
            }
            setType(value)
        }
        if(name === 'is_show'){
            setis_show(value)
        }
        if(name === 'redirect_url'){
            setRedirectUrl(value)
        }
        if(name === 'redirect_id'){
            setredirect_id(value)
        }
    }

    // 版位
    const positionChange = (position: number) => {
        setPosition(position)
        positionList.map((item: any) => {
            if(item.id == position){
                setsize(item.size)
            }
        })
    }

    const timeChange = (time: any, timeString: [string, string]) => {
        setstart_time(timeString[0])
        setend_time(timeString[1])
    }

    const rediType = (e: any) => {
        redirect_types.map((item: any) => {
            if(item.id == e){
                setredirect_type(item.id)
                setRedirectUrl(item.url)
            }
        })
    }

    const handleEditorChange = (editorState:any) => {
        seteditorState(editorState);
    }

    return <div className="edit_page l_content">

        <Row className="mb30" type="flex" align="middle">
            <Col span={2}>
                <div>
                    <span className="star"></span>
                    <span className="color_black">广告标题</span>
                </div>
            </Col>
            <Col span={6}>
                <Input placeholder="请输入广告标题" name={'name'} value={name} onChange={(e) => inputChange(e)}/>
            </Col>
        </Row>

        <Row className="mb30" type="flex" align="middle">
            <Col span={2}>
                <div>
                    <span className="star"></span>
                    <span className="color_black">展示时间</span>
                </div>
            </Col>
            <Col>
                <Radio.Group
                    value={time_type}
                    name={'time_type'}
                    onChange={(e) => inputChange(e)}
                >
                    <Radio value={1}>固定时间</Radio>
                    <Radio value={2}>永久</Radio>
                </Radio.Group>
            </Col>
        </Row>

        {
            time_type == 1
            ? <Row className="mb30" >
                    <Col span={7} offset={2}>
                        <Row type="flex" align="middle">
                            <RangePicker onChange={timeChange} format="YYYY-MM-DD HH:mm:ss" showTime={{ format: 'HH:mm' }} placeholder={start_time !== '' ? [start_time, end_time] :["开始时间","结束时间"]} />
                        </Row>
                    </Col>
                </Row>
            : null
        }

        <Row className="mb30" type="flex" align="middle">
            <Col span={2}>
                <div>
                    <span className="star"></span>
                    <span className="color_black">广告版位</span>
                </div>
            </Col>
            <Col>
                <Select style={{width: '300px'}} placeholder='请选择广告版位' onChange={positionChange} value={position != 0 ? position : undefined}>
                    {positionList.map((item: any) => {
                        return <Option value={item.id} key={item.id}>{item.name}</Option>
                    })}
                </Select>
            </Col>
        </Row>

        <Row className="mb30" type="flex" align="top">
            <Col span={2}>
                <div>
                    <span className="star"></span>
                    <span className="color_black">广告图片</span>
                </div>
            </Col>
            <Col>
                <div className='choose_item_view'>
                    <Upload
                        className='uploadStyle'
                        showUploadList={false}
                        action={QINIU_SERVER}
                        accept="image/*"
                        beforeUpload={handleBeforeUpload}
                        onChange={handleUploadChange}
                        data={{
                            token: qiniuParam.token,
                            region: `http://up-z2.qiniu.com`,
                            key: qiniuParam.imageKey,
                            "x:type": "poster"
                        }}
                    >
                        <div className="choose_right" style={{backgroundColor: cover_url === '' ? '#999':'#FFF'}}>
                            {
                                cover_url === '' ? <div/>:
                                <img src={cover_url} className="right_cover_url" alt="广告图片" />
                            }

                        </div>
                    </Upload>
                </div>

                <div className="tip_view">(建议上传封面图宽高{size})</div>
            </Col>
        </Row>

        <Row className="mb30" type="flex" align="middle">
            <Col span={2}>
                <div>
                    <span className="star"></span>
                    <span className="color_black">跳转类型</span>
                </div>
            </Col>
            <Col>
                <Radio.Group
                    value={type}
                    name={'type'}
                    onChange={(e) => inputChange(e)}
                >
                    <Radio value={3}>不跳转</Radio>
                    <Radio value={2}>网页链接</Radio>
                    <Radio value={1}>小程序页</Radio>
                </Radio.Group>
            </Col>
        </Row>

        {
            type == 2
            ? <Row className="mb30" type="flex" align="middle">
                <Col span={2}>
                    <div>
                        <span className="star"></span>
                        <span className="color_black">跳转链接</span>
                    </div>
                </Col>
                <Col span={6}>
                    <Input placeholder="请输入跳转链接" name={'redirect_url'} value={redirect_url} onChange={(e) => inputChange(e)}/>
                </Col>
            </Row>
            : null
        }

        {
            type == 1
            ? <Row className="mb30" type="flex" align="middle">
                <Col span={2}>
                    <div>
                        <span className="star"></span>
                        <span className="color_black">跳转页面</span>
                    </div>
                </Col>
                <Col span={6}>
                    <Select style={{width: '200px'}} onSelect={(e:any) => rediType(e)} value={redirect_type ? redirect_type : undefined} placeholder='请选择跳转页面'>
                        {
                            redirect_types.map((item: any) => {
                                return <Option value={item.id} key={item.id}>{item.name}</Option>
                            })
                        }
                    </Select>
                </Col>
            </Row>
            : null
        }

        {
            redirect_type == 1
            ? <Row className="mb30" type="flex" align="middle">
                <Col span={6} offset={2}>
                    <div className="bind_shop_view">
                        <Button onClick={() => setDishesVisible(true)}>绑定商品</Button>
                        {
                            Object.keys(dishObj).length > 0 ?
                            <div style={{marginLeft: '10px'}}>{dishObj.name}</div>:<div/>
                        }
                    </div>
                </Col>
            </Row>
            : null
        }

        {
            redirect_type == 3
            ? <Row className="mb30" type="flex" align="middle">
                <Col span={2}>
                    <div>
                        <span className="star"></span>
                        <span className="color_black">权益卡ID</span>
                    </div>
                </Col>
                <Col span={6}>
                    <Input placeholder="请输入权益卡ID" name={'redirect_id'}  value={redirect_id} onChange={(e) => inputChange(e)}/>
                </Col>
            </Row>
            : null
        }

        <Row className="mb30" type="flex" align="top">
            <Col span={2}>
                <div>
                    <span className="color_black">广告描述</span>
                </div>
            </Col>
            <Col span={10}>
                <div className="editor_wrap">
                    <BraftEditor
                        value={editorState}
                        onChange={handleEditorChange}
                        excludeControls={['media']}
                    />
                </div>
            </Col>
        </Row>

        {
            position == 22221121
            ? <Row className="mb30" type="flex" align="middle">
                <Col span={2}>
                    <div>
                        <span className="star"></span>
                        <span className="color_black">排序</span>
                    </div>
                </Col>
                <Col className='mr30'>
                    <Input placeholder="输入整数" value={order_by} style={{ width: 220 }} onChange={(e) => {
                        let value = e.target.value.trim();
                        setOrderBy(value);
                    }} />
                </Col>
                <div className="tip_view">(排序数字越大，广告内容越靠前展示)</div>
            </Row>
            : null
        }



        {/* priority */}

        <Row className="mb30" type="flex" align="middle">
            <Col span={2}>
                <div>
                    <span className="star"></span>
                    <span className="color_black">优先级</span>
                </div>
            </Col>
            <Col className='mr30'>
                <Input placeholder="输入整数" value={priority} style={{ width: 220 }} onChange={(e) => {
                    let value = e.target.value.trim();
                    setpriority(Number(value));
                }} />
            </Col>
            <div className="tip_view">(优先级数字越大，广告内容越靠前展示)</div>
        </Row>

        <Row className="mb30" type="flex" align="middle">
            <Col span={2}>
                <div>
                    <span className="star"></span>
                    <span className="color_black">状态</span>
                </div>
            </Col>
            <Col>
                <Radio.Group
                    value={is_show}
                    name={'is_show'}
                    onChange={(e) => inputChange(e)}
                >
                    <Radio value={1}>启用</Radio>
                    <Radio value={0}>停用</Radio>
                </Radio.Group>
            </Col>
        </Row>

        <div className="button_group">
            <Button type="default" onClick={() =>{ props.history.push('/sidebar/adverManager')}}>取消</Button>
            <Button type="primary" onClick={() => saveAderInfo()}>保存</Button>
        </div>
        <Modal
          title="绑定菜品"
          visible={dishesVisible}
          onCancel={() =>setDishesVisible(false)}
          footer ={null}
        >
            <Row type="flex" align="middle" justify="start" className="mb20" >
                <Col className="mr30">
                    <span>商品名称：</span>
                    <Input placeholder="请输入商品名称" onChange={(e:any) =>{
                        setCurrentPage(1);
                        filterProducts(e.target.value.trim())
                    }} style={{ width: 234 }}/>
                </Col>
            </Row>
           <Table
            dataSource={productList}
            columns={columns}
            rowKey={"id"}
            size="middle"
            rowSelection = {
                {
                    type: 'radio',
                    columnTitle: "选择",
                    onSelect: (selectedRowKeys, selectedRows) => {
                        setDishObj(selectedRowKeys);
                   },
                }
            }
            locale={{emptyText: '暂无数据'}}
            pagination={{
                current: currentPage,
                total: productList.length,
                pageSize: 8,
                hideOnSinglePage: true,
                onChange: (e) =>{
                    setCurrentPage(e);
                }
            }}/>
        </Modal>
    </div>
}

export default AdverEdit;