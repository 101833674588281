import React from 'react';
import { Row, Col } from 'antd';
import '../management.scss';

import winAddSecur1 from '../../../../../../assret/images/winAddSecur1.png';
import winAddSecur2 from '../../../../../../assret/images/winAddSecur2.png';

interface HelpMbaSecurities {
    history: any,
}

class HelpMbaSecurities extends React.Component<HelpMbaSecurities> {

    render() {
        return  <Row className="typeDetail">
                    <Col className="typeDetail-title">一、功能介绍</Col>
                    <Col className="typeDetail-col">自主选定客户群，进行精准化营销。</Col>
                    <Col className="typeDetail-title">二、功能路径</Col>
                    <Col className="typeDetail-col">电脑端登录后台-营销管理-直接发券列表-直接发券。</Col>
                    <Col className="typeDetail-col">
                        <img src={winAddSecur1} />
                    </Col>
                    <Col className="typeDetail-title">三、操作流程</Col>
                    <Col className="typeDetail-col">1、<span className="txt-bold">直接发券</span>，在“直接发券列表”界面下点击“直接发券”</Col>
                    <Col className="typeDetail-col">
                        <img src={winAddSecur2} />
                    </Col>
                    <Col className="typeDetail-col">1、活动名称：填写名称</Col>
                    <Col className="typeDetail-col">2、发券类型：指定用户分组、指定用户、新老用户、客服号四种类型</Col>
                    <Col className="typeDetail-col">3、发放用户：选择某个分组，优惠只发到该分组的所有用户账户上，选择某个人，优惠只发到某个人的用户账户上，选择新老用户，优惠直接发到新用户或老用户账户上</Col>
                    <Col className="typeDetail-col">4、选择发放券：选择想发放的优惠类型的券</Col>
                    <Col className="typeDetail-col">5、发行数量：填写发行数量,发行量就是优惠的张数，例如填写1时，发放一张优惠券到某个用户或分组账号上</Col>
                </Row>
    }
}

export default HelpMbaSecurities;