import React, { Fragment, useState, useEffect } from 'react'
import { Row, Col, Button, Input, Modal, message } from 'antd'
import ApplyBottomBar from './apply_bottom_bar';
import { getRequest } from '../../../../../api/http';
import { apiUrl } from '../../../../../api/api_url';
import { IApplyWithdrawalConfig } from '../apply_withdrawal';
const QRCode = require('qrcode.react');

let timer:any;

interface IUserinfo {
  id: number,
  avatar: string,
  nickname: string,
  openid: string
}

interface IWechatWithdrawal {
  updateParentData(data:any): void
  withDrawalConfig: IApplyWithdrawalConfig
  withdrawableRMB: string
}

interface IFormData {
  'type': 'wechat',
  'amount': number,
  'truename': string,
  'mobile': string,
  'wechat': string,
  'nickname' : string,
  'openid': string,
  "idcard": string
}

const WechatWithdrawal = (props: IWechatWithdrawal) => {
  
  const [weixinBtnText, setweixinBtnText] = useState('获取信息');
  const [showAuthModal, setweinAuthModal] = useState(false);
  const [authUrl, setauthUrl] = useState('');
  const [userInfo, setuserInfo] = useState<IUserinfo>();
  const [formData, setformData] = useState<IFormData>({
    'type': 'wechat',
    'amount': 0,
    'truename': '',
    'mobile': '',
    'wechat': '',
    'nickname' : '',
    'openid': '',
    "idcard": ''
  })

  useEffect(() => {
    return () => {
      clearInterval(timer);
    };
  }, [])
  
  // 弹出微信授权二维码
  const tapWxAuth = async () => {
    const link = `${process.env.REACT_APP_H5_BASEURL}/distribution_auth`
    const res: any = await getRequest(apiUrl.getAuthLink, { target_url: link })
    if (res && res.data) {
      setauthUrl(res.data.link);
      authCheck(res.data.key);
      setweinAuthModal(true);
    } 
  }
  
  // 隐藏微信授权二维码
  const hideWxAuth = () => {
    setweinAuthModal(false);
    clearInterval(timer);
  }
  
  // 检查授权
  const authCheck = (authKey: string) => {
    timer = setInterval(async () => {
      const res: any = await getRequest(apiUrl.checkWxAuth, { 'key': authKey });
      if (res && res.data && res.data.auth) {
        message.success('授权成功');
        setweixinBtnText('重新获取');
        setuserInfo(res.data.user);
        setformData({ ...formData, nickname: res.data.user.nickname, openid: res.data.user.openid });
        props.updateParentData(formData);
        setweinAuthModal(false);
        clearInterval(timer);
      }
    }, 1500);
  }
  
  // 表单修改了
  const formOnChange = (key: string | number, value: string) => {
    const data = JSON.parse(JSON.stringify(formData));
    data[value] = key;
    setformData(data);
    props.updateParentData(data);
  }

  return <Fragment>
    <Row type="flex" align="middle" style={{marginTop: 20}}>
      <Col style={{width: 100, textAlign: 'right'}}>微信扫码：</Col>
      <Col><Button type="primary" onClick={tapWxAuth}>{weixinBtnText}</Button></Col>
    </Row>
    <Row type="flex" align="middle" style={{marginTop: 20}}>
      <Col style={{width: 100, textAlign: 'right'}}>微信昵称：</Col>
      <Col><Input placeholder="扫码后自动获取" value={userInfo?.nickname} disabled /></Col>
    </Row>
    <Row type="flex" align="middle" style={{marginTop: 20}}>
      <Col style={{width: 100, textAlign: 'right'}}>微信号：</Col>
      <Col><Input placeholder="请输入收款微信绑定的微信号" onChange={(e) => formOnChange(e.target.value, 'wechat')} value={formData.wechat} style={{minWidth: 300}} /></Col>
    </Row>
    <Row type="flex" align="middle" style={{marginTop: 20}}>
      <Col style={{width: 100, textAlign: 'right'}}>真实姓名：</Col>
      <Col><Input placeholder="请输入收款微信绑定的真实姓名" value={formData?.truename} onChange={(e) => formOnChange(e.target.value, 'truename')} style={{minWidth: 300}}/></Col>
    </Row>
    <Row type="flex" align="middle" style={{marginTop: 20}}>
      <Col style={{width: 100, textAlign: 'right'}}>手机号码：</Col>
      <Col><Input placeholder="请输入收款人手机号码" maxLength={11} value={formData?.mobile} onChange={(e) => formOnChange(e.target.value, 'mobile')} style={{minWidth: 300}}/></Col>
    </Row>
    <Row type="flex" align="middle" style={{marginTop: 20}}>
      <Col style={{width: 100, textAlign: 'right'}}>身份证号码：</Col>
      <Col><Input placeholder="请输入收款微信绑定的身份证号码" value={formData?.idcard} onChange={(e) => formOnChange(e.target.value, 'idcard')} maxLength={18} minLength={18} style={{minWidth: 300}}/></Col>
    </Row>

    <ApplyBottomBar amountChange={(e) => formOnChange(e, 'amount')} 
                    withdrawableRMB={props.withdrawableRMB}
                    withDrawalConfig={props.withDrawalConfig} 
                    applyType={1} />
    
    <Modal
      title="微信扫码"
      visible={showAuthModal}
      onCancel={hideWxAuth}
      footer={null}
    >
      <Row type="flex" justify="center">
        <QRCode value={`${authUrl}`} size={150} />
      </Row>
    </Modal>

  </Fragment>
}

export default WechatWithdrawal