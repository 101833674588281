import React from "react"
import Wode from './shopDecoration/wode'
import Home from './shopDecorationHome/home'
import InsideNav from './components/insideNav/insideNav'

type IProps = {
    history: any
}
type IState = {
    navList: any
    current_key: string
}
// const navTable = [
//     {
//         title: '我的',
//         isActive: true,
//         KEY: "WODE",
//         uni_key: '2_2_1'
//     },
//     {
//         title: '套餐发布记录',
//         isActive: false,
//         KEY: "Home",
//         uni_key: '2_2_2'
//     }
// ]
class ShopDecorationMain extends React.Component<IProps,IState> {
    constructor(props:IProps) {
        super(props);
        this.state = {
            navList: [
                {
                    title: '首页',
                    isActive: true,
                    KEY: "Home",
                    uni_key: '2_2_1'
                },
                {
                    title: '我的',
                    isActive: false,
                    KEY: "WODE",
                    uni_key: '2_2_2'
                }
            ],
            current_key: "Home",
        }
    }

    navCallBack(currentKey: string){
        console.log(currentKey)
        this.setState({
            current_key: currentKey
        })
    }

    render() {
        let { navList,current_key } = this.state;
        return <div className='padding20'>
            <div className='padding15 background_fff border_radius_10'>
                <InsideNav navList={navList} callBack={(key: string) =>this.navCallBack(key)}/>
                <div className="body" style={{marginTop: '0.4rem'}}>
                    {
                        current_key === 'Home' ?  <Home history={this.props.history}/>
                        :
                        current_key === 'WODE'  ?  <Wode history={this.props.history}/>
                        : <></>
                    }
                </div>
            </div>
        </div>
    }
}

export default ShopDecorationMain