import React, { useState, useEffect } from 'react'
import { Row, Typography, Button, Icon, Col, Input, Select, DatePicker, Table, Modal, Avatar, message } from "antd"
import EditGroutMembers from './components/edit_group_members';
import FilterRow from './components/from_item';
import { useHistory } from 'react-router-dom';
import { getRequest, postRequest } from '../../../../../api/http';
import { apiUrl } from '../../../../../api/api_url';
import { Link } from "react-router-dom";

const { Title } = Typography;


// 会员表格 title
export const memberColumns = [
	{
		title: '序号',
		render: (text: any, record: any, index: number) => `${index + 1}`,
	},
	{
		title: '用户ID',
		dataIndex: 'id',
		key: 'id',
	},
	{
		title: '用户昵称',
		// dataIndex: 'name',
		key: 'nickname',
		// render: (rowdata:any) => {
		//   if(rowdata) {
		//     return <Row type="flex" justify="start" align="middle">
		//       <Avatar src={rowdata.headimgurl} style={{marginRight: 5}} />
		//       {rowdata.nickname}
		//     </Row>
		//   }
		//   return <Row></Row>
		// }
		render: (text: string, record: any, index: number) =>
			<Row className="" justify="start" align="middle">
				<div><Avatar src={record.headimgurl} /></div>
				<div>{record.nickname}</div>
			</Row>
	},
	// {
	//   title: '所属小程序',
	//   dataIndex: 'nick_name',
	//   key: 'nick_name',
	// },
	{
		title: '手机号码',
		dataIndex: 'phone_number',
		key: 'phone_number',
	},
	// {
	//   title: '会员等级',
	//   dataIndex: 'level',
	//   key: 'level',
	// },
	// {
	//   title: '分组',
	//   dataIndex: 'group',
	//   key: 'group',
	// },
	{
		title: '性别',
		dataIndex: 'sex',
		key: 'sex',
		render: (text: string, record: any, index: number) =>
			<div>
				{record.sex == 0 ? '未知' : record.sex === 1 ? '男' : '女'}
			</div>
	},
	{
		title: '消费次数',
		dataIndex: 'comsumer_times',
		key: 'comsumer_times',
	},
	{
		title: '累计消费（元）',
		dataIndex: 'actual_fee',
		key: 'actual_fee',
	},
	{
		title: '最近消费时间',
		dataIndex: 'last_comsumer_time',
		key: 'last_comsumer_time',
	},
	{
		title: '成功邀请（人）',
		//dataIndex: 'invite_num',
		//key: 'invite_num',
		render: (rowdata: any) => {
			return <Row>
			{/* {
				(!rowdata['is_default']) && <>
				<Button type="link" onClick={() => history.push({pathname: "/sidebar/add_vip_group", state: { rowdata }})}>编辑</Button>
				<Button type="link" onClick={() => history.push({pathname: '/sidebar/group_members', state: {title: `${rowdata.name}`, id: rowdata.id}})}>管理</Button>
				<Button type="link" onClick={() => deleteGroup(rowdata)}>删除</Button>
				</>
			} */}
				{/* <Button type="link" onClick={() => history.push({pathname: "/sidebar/add_vip_group", state: { rowdata }})}>编辑</Button> */}
				<Link className="list_btn" to={{ pathname: '/sidebar/detail_group_members', state: { id: rowdata.id, invited_money: rowdata.invited_money } }}>{rowdata.invite_num}</Link>
			</Row>
		}
	},
	// {
	// 	title: '消费次数',
	// 	dataIndex: 'comsumer_times',
	// 	key: 'comsumer_times',
	// },
	// {
	//   title: '成长值',
	//   dataIndex: 'score',
	//   key: 'score',
	// },
	{
		title: '累计邀请消费（元）',
		dataIndex: 'invited_money',
		key: 'invited_money',
	},
	{
		title: '操作',
		key: 'action',
		width: 100,
		render: (rowdata: any) => {
			return <Row>
			{/* {
				(!rowdata['is_default']) && <>
				<Button type="link" onClick={() => history.push({pathname: "/sidebar/add_vip_group", state: { rowdata }})}>编辑</Button>
				<Button type="link" onClick={() => history.push({pathname: '/sidebar/group_members', state: {title: `${rowdata.name}`, id: rowdata.id}})}>管理</Button>
				<Button type="link" onClick={() => deleteGroup(rowdata)}>删除</Button>
				</>
			} */}
				{/* <Button type="link" onClick={() => history.push({pathname: "/sidebar/add_vip_group", state: { rowdata }})}>编辑</Button> */}
				<Link className="list_btn" to={{ pathname: '/sidebar/memberGroupVipDetail', state: { id: rowdata.id, invited_money: rowdata.invited_money } }}>详情</Link>
			</Row>
		}
	}
	// {
	//   title: '订单数量',
	//   dataIndex: 'order_count',
	//   key: 'order_count',
	// },
	// {
	//   title: '注册时间',
	//   dataIndex: 'create_time',
	//   key: 'create_time',
	// },
	// {
	// 	title: '最近消费时间',
	// 	dataIndex: 'last_comsumer_time',
	// 	key: 'last_comsumer_time',
	// }
];

type Imembers = {
	total: number,
	list: Array<IImembersItem>
}

type IImembersItem = {

}

const GroupMembers = () => {
	const [showEditGroup, setshowEditGroup] = useState(false);
	const history: any = useHistory();
	const [groupTitle, setgroupTitle] = useState('');
	const [groupDefault, setGroupDefault] = useState(false);
	const [members, setmembers] = useState<Imembers>({
		total: 0,
		list: []
	});
	const [currentSelector, setcurrentSelector] = useState<Array<any>>([]);
	const [currentSelectorIdArr, setcurrentSelectorIdArr] = useState<Array<Number>>([]);
	const [currPage, setcurrPage] = useState(1);
	const [pageSize, setpageSize] = useState(10);

	useEffect(() => {
		initData();
	}, [])

	// 初始化数据
	const initData = async (parms = {}) => {
		const reqdata = {
			page: 1,
			pageSize,
			id: history.location.state.id,
			...parms,
		}
		const res: any = await getRequest(apiUrl.groupMembers, reqdata);
		if (res && res['code'] === 1) {
			console.log(res.data.total)
			setmembers(res.data);
		}
	}

	const rowSelection = {
		onChange: (selectedRowKeys: any, selectedRows: any) => {
			setcurrentSelectorIdArr([...selectedRowKeys]);
			setcurrentSelector([...selectedRows]);
		}
	};

	// 从会员分组中移除
	const removeFromGroup = async () => {
		const parms = {
			type: 2,
			member_ids: currentSelectorIdArr.toString()
		};
		const res: any = await postRequest(`${apiUrl.addMemberToGroup}${history.location.state.id}`, parms);
		if (res && res['code'] === 1) {
			message.success('移除成功');
			initData({ page: 1 });
		} else {
			message.error(`${res.message}`);
		}
	}

	const orderTableFooter = () => {
		return <Row type="flex" justify="space-between">
			<Button type="primary" disabled={currentSelector.length > 0 ? false : true} onClick={removeFromGroup}>移出分组</Button>
		</Row>
	}

	// 添加组员
	const addMember = () => {
		setshowEditGroup(true);

	}

	useEffect(() => {
		if (history.location.state && history.location.state.title) {
			setgroupTitle(history.location.state.title);
			setGroupDefault(history.location.state.is_default);
		}
	}, []);

	// 条件筛选
	const searchList = (searchKey: any) => {
		const copy = JSON.parse(JSON.stringify(searchKey));
		Object.keys(copy).map((el) => {
			if (!copy[el]) delete copy[el]
		})
		initData(copy);
	}

	// 显示影藏modal
	const hideModal = (show: boolean) => {
		setshowEditGroup(show);
		initData({ page: currPage });
	}

	return <Row className='l_content'>
		<Title level={3}>{groupTitle}</Title>
		{
			groupDefault
				? ''
				: <Button type="primary" style={{ marginBottom: 20 }} onClick={addMember}><Icon type="plus" />添加组员</Button>
		}
		<FilterRow search={(key) => searchList(key)} />
		<Row style={{margin: '15px 0'}}>
			<Col style={{fontSize: '16px', fontWeight: 'bold'}}>
				{groupTitle}：{members.total}
			</Col>
		</Row>
		<Table
			style={{ marginTop: 30 }}
			rowSelection={rowSelection}
			rowKey={"id"}
			size="small"
			footer={orderTableFooter}
			dataSource={members.list}
			columns={memberColumns}
			pagination={{
				defaultCurrent: 1,
				total: members.total,
				pageSize,
				onChange: (p) => {
					setcurrPage(p);
					initData({ page: p })
				},
				showQuickJumper: true,
				showSizeChanger: true,
				showTotal: (total: number) => `共${total}条`,
				onShowSizeChange: (current: number, size: number) => { setpageSize(size); initData({ pageSize: size }) }
			}}
		/>

		<EditGroutMembers groupId={history.location.state.id} showEditGroup={showEditGroup} hideModal={(s) => hideModal(s)} />

		<Row style={{ marginTop: 50 }} type="flex" justify="center">
			<Button type="default" style={{ marginRight: 20, }} onClick={() => history.goBack()}>返回</Button>
			{/* <Button type="primary" onClick={saveOptions}>确定</Button> */}
		</Row>
	</Row>
}




export default GroupMembers

