import React from 'react'
import {Button, Row, Col, Input, DatePicker, Table, Icon, Switch, Modal} from "antd";
import { getIsPermisson, messageCustom } from "../../utils/permisstionUtil";
import btn_add from '../../assret/images/btn_add.png'
import styles from './registration.module.scss';

const { RangePicker } = DatePicker;

class RegisterationEdit extends React.Component<any, any> {

    columnNum:any = [
        {
            title: '优惠券名称',
            dataIndex: 'name',
            key: 'name',
            align: "center",
        },
        {
            title: '券类型',
            dataIndex: 'type',
            key: 'type',
            align: "center",
            render: (text:any,record:any,) => (
                <div className="color">{record.info.type === 'discount' ? '折扣券' : record.info.type === 'voucher' ? '优惠券' : '兑换券'}</div>
            ),
        },
        {
            title: '券有效期',
            dataIndex: 'created_at',
            key: 'created_at',
            align: "center",
            render: (text:any,record:any,) => {
                let info:any = record.expire_info;
                return <div className="color">{info.type === 'time' ?  info.start_time + '至' + info.end_time : '自领取日'+info.day + '天领取'}</div>
            }
        },
        {
            title: '券份数',
            dataIndex: 'num',
            key: 'num',
            align: "center",
            render: (text:any,record:any,) => {
                return <div className="back_wrap">
                        <div className="back_btn reduce_btn active" onClick={() => this.reduceCoupon(record.id)}>-</div>
                        <div className="good_num">{record.coupon_num}</div>
                        <div className="back_btn add_btn active" onClick={() => this.addCoupon(record.id)}>+</div>
                    </div>
            }
        },
        {
            title: '操作',
            align: "center",
            render: (text: string,record:any,index:number)=>(
                <div className="table_action" onClick={() => this.unBindShop(index)}>移除</div>
            )
        },
    ];

    // 创建 商品列表
    columns:any = [
        {
            title: '优惠券名称',
            dataIndex: 'name',
            key: 'name',
            align: "center",
        },
        {
            title: '券类型',
            dataIndex: 'type',
            key: 'id',
            align: "center",
            render: (text:any,record:any,) => (
                <div className="color">{record.info.type === 'discount' ? '折扣券' : record.info.type === 'voucher' ? '优惠券' : '兑换券'}</div>
            ),
        },
        {
            title: '券有效期',
            dataIndex: 'created_at',
            key: 'created_at',
            align: "center",
            render: (text:any,record:any,) => {
                let info:any = record.expire_info;
                return <div className="color">{info.type === 'time' ?  '自'+info.start_time + '至' + info.end_time : '自领取日'+info.day + '天内有效'}</div>
            }
        },
        {
            title: '操作',
            align: "center",
            render: (text: string,record:any,index:number)=>{
                return <div>
                    {
                        record.selected
                        ? '已关联'
                        : <div className="table_action" onClick={() => this.handleBindShop(record.id)}>关联</div>
                    }
                </div>
            }
        },
    ];

    state = {
        start_time: '',
        end_time: '',
        couponList: [],
        selectCoupon: [],
        visible: false,
        selectList: [],
        current: 1,
        listNum: 0,
        type: 'birthday',
        status_txt: '已开启'
    }

    onChange = (e: any) => {
        console.log(e)
        this.setState({
            status_txt: e ? '已开启' : '已关闭'
        })
    }

    onCurrencyChange = (e: any) => {
        let name: any = e.target.name;
        let value: any = e.target.value;
        this.setState({
            [name]: value,
        })
    }

    timeChange = (time: any, timeString: [any, any]) => {
        this.setState({
            start_time: timeString[0],
            end_time: timeString[1]
        }, () => {
            //this.onInitList();
        })
    }

    reduceCoupon = (id: any) => {
        let selectCoupon = this.state.selectCoupon;
        selectCoupon.map((item: any) => {
            if(item.id === id){
                if(item.coupon_num === 1){
                    return;
                }
                item.coupon_num--;
            }
        })
        this.setState({
            selectCoupon
        })
    }

    addCoupon = (id: any) => {
        let selectCoupon = this.state.selectCoupon;
        selectCoupon.map((item: any) => {
            if(item.id === id){
                item.coupon_num++;
            }
        })
        this.setState({
            selectCoupon
        })
    }

    // 关联
    handleBindShop = (id:number) => {
        let { type } = this.state;
        let selectCoupon:any[] = this.state.selectCoupon;
        let couponList:any[] = this.state.couponList;
        couponList.map((item) => {
            if(item.id === id){
                item.selected = true;
                item.coupon_num = 1;
                selectCoupon.push(item)
            }
        })
        console.log(selectCoupon);
        this.setState({
            selectCoupon,
            couponList
        })
    }

    // 解除关联
    unBindShop = (index: number) => {

        let { couponList } = this.state;
        let selectCoupon: any = this.state.selectCoupon;
        couponList.map((item: any) => {
            if(item.id === selectCoupon[index].id){
                item.selected = false;
            }
        })
        selectCoupon.splice(index, 1)
        this.setState({
            couponList,
            selectList: couponList.slice(0, 10),
            current: 1,
            selectCoupon
        })
    }

    // 显示关联
    showTable = () => {
        let { couponList, selectCoupon} = this.state;
        couponList.map((coupon: any) => {
            selectCoupon.map((select: any) => {
                if(coupon.id === select.id){
                    coupon.selected = true;
                }
            })
        })
        this.setState({
            couponList,
            selectList: couponList.slice(0, 10),
            current: 1,
            visible: true
        })
    }

    changePage = async (page: number) => {
        let { couponList } = this.state;
        let list = couponList.slice((page-1)*10, (10*page));
        this.setState({
            selectList: list,
            current: page
        })
    }

    handleOk = () => {
        this.setState({
            visible: false
        })
    }

    // 返回上一页
    goBack = () => {
        this.props.history.go(-1)
    }

    render () {

        const { selectCoupon, visible, selectList, current, listNum, status_txt } = this.state;

        return (
            <div className="padding20">
                <Row className='padding20 background_fff border_radius_10'>
                    <Col className={`${styles.top} mb30`}>
                        <Col className={styles.top_left}>
                            <div className={styles.top_left_title}>注册有礼开关</div>
                            <div className={styles.top_left_status} style={{width: '100px', display: 'flex', alignItems: 'center'}}>
                                <Switch defaultChecked onChange={this.onChange} />
                                <span style={{marginLeft: '10px'}}>{status_txt}</span>
                            </div>
                        </Col>
                        <Col>
                            <div className='new_btn margin_right_20' onClick={() => {
                                if(!getIsPermisson('2_2_1_1')){
                                    messageCustom();
                                    return;
                                    }
                                    //this.props.history.push('/sidebar/merchantVoucherCouponAdd')
                            }}>
                                <img src={btn_add}></img>
                                编辑规则
                            </div>
                        </Col>
                    </Col>
                    <Row className="mb30" type="flex" align="middle">
                        <Col span={2}>
                            <div>
                                <span className="star"></span>
                                <span className="color_black">活动名称：</span>
                            </div>
                        </Col>
                        <Col span={4}>
                            <Input placeholder="请输入商品名称" onChange={(e) => this.onCurrencyChange(e)}/>
                        </Col>
                    </Row>
                    <Row className="mb30" type="flex" align="middle">
                        <Col span={2}>
                            <div>
                                <span className="star"></span>
                                <span className="color_black">活动时间：</span>
                            </div>
                        </Col>
                        <Col span={4}>
                            <RangePicker onChange={this.timeChange} placeholder={["开始时间","结束时间"]} format="YYYY-MM-DD"/>
                        </Col>
                    </Row>
                    <Row className="mb30" type="flex" align="middle">
                        <Col span={2}>
                            <div>
                                <span className="star"></span>
                                <span className="color_black">活动礼品：</span>
                            </div>
                        </Col>
                        <Col span={16}>
                            <div onClick={this.showTable}>
                                <Icon type="plus-circle" style={{fontSize: '20px',marginRight: '10px'}} />
                                关联优惠券
                            </div>

                            {
                                selectCoupon.length > 0
                                ? <Table
                                    size="middle"
                                    rowKey={"id"}
                                    dataSource={selectCoupon}
                                    columns={this.columnNum}
                                    style={{marginTop: '20px'}}
                                    pagination={
                                        {
                                            hideOnSinglePage: true,
                                            total: selectCoupon.length,
                                        }
                                    }
                                />
                                : null
                            }

                        </Col>
                    </Row>
                    <Row className="mb30" type="flex" align="middle">
                        <Col span={6} offset={2}>
                            <div className="bottom_wrap">
                                <Button type="primary" onClick={this.goBack}>
                                    取消
                                </Button>
                                <Button type="primary" ghost>
                                    保存
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Modal
                        title={null}
                        visible={visible}
                        onOk={this.handleOk}
                        onCancel={()=>{this.setState({visible: false})}}
                        width="70vw"
                    >
                        <div className="model_head">
                            <div className="head_title">关联优惠券</div>
                        </div>
                        <Table
                            size="middle"
                            rowKey={"id"}
                            dataSource={selectList}
                            columns={this.columns}
                            pagination={
                                {
                                    current,
                                    total: listNum,
                                    onChange: this.changePage,
                                    pageSize: 10
                                }
                            }
                        />
                    </Modal>
                </Row>
            </div>
        )
    }
}

export default RegisterationEdit