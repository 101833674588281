// 提现管理

import React, { useState, Fragment, useEffect } from 'react'
import {Table } from 'antd';
import { getRequest } from '../../../../../api/http';
import { IoverView } from '../withdraw';
import { apiUrl } from '../../../../../api/api_url';

interface ITableData {
  updateOverview(data:IoverView): void
}

const WithdrawListTable = (props: ITableData) => {

  const [tableList, settableList] = useState<{items: Array<any>, total: number}>({items: [], total: 0 });
  
  useEffect(() => {
    _getData();
  })

  const _getData = async (current = 1) => {
    const parms = {
      current: current,
      pageSize: 10
    }
    const res:any = await getRequest(apiUrl.fetchWithdraw,parms);
    if(res && res.data) {
      settableList({items: res.data.items, total: res.data.total})
      props.updateOverview(res.data.overview);
    }
  }

  const columns = [
    {
      title: "申请单号",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "提现方式",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "收款账号",
      dataIndex: "account_number",
      key: "account_number",
    },
    {
      title: "提现金额",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "手续费",
      dataIndex: "fee",
      key: "fee",
    },
    {
      title: "到账金额",
      dataIndex: "amount_act",
      key: "amount_act",
    },
    {
      title: "申请状态",
      dataIndex: "status",
      key: "status"
    },{
      title: "到账时间",
      dataIndex: "succeed_at",
      key: "succeed_at"
    },
  ];
  
  // 翻页
  const pageOnChange = (page: number) => _getData(page)
  
  return (
    <Fragment>
      <Table
       size="middle"
        rowKey={"id"}
        dataSource={tableList.items}
        columns={columns}
        pagination={{ 
          pageSize: 10, 
          total: tableList.total, 
          onChange: (e: number) => pageOnChange(e) 
        }}
      />
    </Fragment>
  );
};

export default WithdrawListTable