
interface ResquestUrl {
  // [key: string]: string;
  register: string,
  login: string,
  sendMobileCode: string,
  shareActivities: string,
  createShareActivities: string,
  productCount: string,
  productList: string,
  getUploadToken: string
  productCopy: string,
  productDelete: string,
  productDetail: string,
  productEdit: string,
  accountAuthH5: string,
  productAdd: string
  authAccountList: string,
  createTask: string,
  taskList: string,
  deleteTask: string,
  getAuthLink: string,
  checkWxAuth: string,
  getVerifyUsers: string,
  deleteActicity: string,
  createVerifyUser: string,
  luckList: string,
  luckAdd: string,
  luckStart: string,
  luckPause: string,
  luckDel: string,
  luckCopy: string,
  luckCount: string,
  // deleteVerifyUser: string,
  updateVerifyUser: string,
  updateActivity: string,
  //二唯码相关
  createQRCode:string,
  searchOpenid:string,
  updateQRCode:string,
  deleteQRCode:string,
  selectQRCode:string,
  fetchShareOrder: string,
  getTaskDetailById:string,
  updateTask: string,
  fetchShareUsers: string,
  searchUsers: string,
  fetchWithdraw: string,
  fetchBanksList: string,
  withdrawConfig: string,
  shopWithdraw: string,
  luckInfo: string,
  luckEdit: string,
  getFansList: string,
  pauseTask: string,
  luckStatistics: string,
  joinPeople: string,
  awardList: string,
  couponList: string,
  couponCount: string,
  couponAdd: string,
  couponEdit: string,
  couponInfo: string,
  goodSort: string,
  goodSortCount: string,
  goodSortAdd: string,
  goodSortEdit: string,
  goodSortDelete: string,
  goodInfo: string,
  goodList: string,
  immediatelyReturnAction: string,
  goodAdd: string,
  goodCount: string,
  goodsInfo: string,
  goodEdit: string,
  goodStockOver: string,
  goodSortChange: string,
  shopList: string,
  xcxList: string,
  unbindXcx: string,
  bindXcx: string,
  goodDelete: string,
  regionList: string,
  regionAdd: string,
  regionEdit: string,
  regionDel: string,
  tableList: string,
  tableCount: string,
  tableInfo: string,
  tableAdd: string,
  tableEdit: string,
  rebuildQrcode: string,
  tableDelete: string,
  tableUse: string,
  fetchFoodOrders: string
  foodOrderDetail: string
  foodOrderUpdate: string
  foodChargeBack: string
  getRechargeList: string,
  getXcxWithdraw:string,
  getXcxWithdrawAudit:string,
  mpShareCreate: string
  shareUserList: string
  shareActiviteList: string
  shareActiviteDetail: string
  shareActiviteUpdate: string
  welfareConfigList:string,
  welfareConfigAdd:string,
  welfareConfigEdit:string,
  welfareConfigDelete:string,
  getMemberList: string,
  getMemberListSendCoupon: string,
  userSearch: string,
  messageTemplateList:string,
  messageTemplateAdd: string,
  messageTemplateEdit:string,
  messageTemplateDelete:string,
  messageTemplateSearch:string,
  messageTaskList: string,
  messageTaskAdd: string,
  messageTaskEdit:string,
  messageTaskDelete: string,
  messageTaskAudit: string,
  messagePush:string,
  messagePushLog: string,
  messagePushLogDelete:string,
  addAdver:string,
  editAdver:string,
  adverList: string,
  deleteAdver: string,
  advertStatus: string,
  advertConfig: string,
  advertInfo: string,
  welfareList: string,
  printerList: string,
  printerMsgList: string,
  printerDel: string,
  printerTest: string,
  printerTestEdit: string,
  printerInfo: string,
  printerMsgDel: string,
  printerMsgSend: string,
  printerMsgCount: string,
  welfareDelete: string,
  welfareStart: string,
  welfareCouponList: string,
  welfareEditInfo: string,
  welfareAdd: string,
  welfareEdit: string,
  welfareConfigStatus: string,
  shopBalanceList: string,
  shopBalanceBillList: string,
  editRuleInfo: string,
  editRule: string,
  fansLevelList: string,
  fansLevelAdd: string,
  fansLevelEdit: string
  peopleCoupon: string,
  printerSearch: string,
  downAll: string,
  memberGroup: string,
  couponSend: string,
  dishesList: string
  dishesHelp: string
  dishesHelpConfigList: string
  dishesHelpConfigEdit: string
  helpUsersList: string
  employeeSave:string,
  employeeList: string,
  partnerList: string, //合伙人记录列表
  partnerDelete: string, //合伙人记录列表
  partnerEdit: string, //合伙人记录列表
  partnerCreate: string, //合伙人记录列表
  getInvitedFans: string,//合伙人邀请用户
  regionTableList:string, //餐桌方块列表
  regionIdList:string, //餐桌区域列表
  shopInfo:string,//店铺详情
  getShopInfo:string,
  shopOpenClose:string, //店铺营业and打烊接口
  getDishLists:string, //桌面管理菜品列表v1/order-dish/get-dish-lists
  dishSearch:string, //桌面管理菜品搜索v1/order-dish/dish-search
  dishInfoByDishId:string, //桌面管理菜品规格v1/order-dish/dish-info-by-dish-id
  // orderDishDetail:string, //桌面管理已点菜详情v1/order-dish/order-dish-detail
  deductionChannelList: string,
  deductionList: string,
  deductionCount: string,
  deductionAdd: string,
  deductionEdit: string,
  deductionInfo: string,
  deductionDel: string,
  deductionStart: string,
  discountList: string,
  discountInfo: string,
  discountAdd: string,
  discountEdit: string,
  discountStart: string,
  discountDel: string,
  discountCount: string,
  changeVoucherStatus: string
  goodEat: string,
  goodDiscount: string,
  orderExchange: string,
  orderDiscount: string,
  orderDishDetail: string,
  openDeskWithEmpty: string,//开空桌v1/order-dish/open-desk-with-empty
  orderCommitToKitchen: string,//下单v1/order-dish/order-commit-to-kitchen
  codeCallToKitchen: string,//下单v1/order-dish/order-commit-to-kitchen
  editUsersByDineKey: string,//修改用餐人数v1/order-dish/edit-users-by-dine-key
  orderPresentDishes:string,//赠菜v1/order-dish/order-present-dishes
  printerDishes:string,//重新打印菜品
  orderPay: string
  messageOrderList: string
  messageUserInfo: string
  inviteRecording: string
  getPersionInfo: string
  getOrderDetail: string
  printBill: string
  prepayOrder: string,
  allPermission: string,
  addRole: string,
  roleList: string,
  rolePermission: string,
  roleDel: string,
  updateRole: string,
  addManageUser: string,
  manageList: string,
  deleteManageUser: string,
  updateManageUser: string,
  updateManageAccount: string,
  getManageRoles: string,
  outOfSomething:string, //多菜品估清 v1/order-dish/out-of-something
  cancelOutByDishId:string, //取消沽清 v1/order-dish/cancel-out-by-dish-id
  outOfSomethingIndex:string, //沽清列表 /v1/order-dish/out-of-something-index
  changeTable: string,
  reprintOrder:string,
  reprintOrderMake:string,
  dishesReport: string,
  dishesReportTotal: string
  shopReport: string,
  shopReportPrinter: string
  dishesReportExport: string
  dishesReportPrint: string
  dishesReportReflesh: string
  memberGroupSave: string
  memberGroupList: string
  memberList: string
  addMemberToGroup: string
  cancelPresentDishes:string,
  financeReport: string
  group_list: string
  memberGroupMembers: string
  setMealIndex: string,
  setMealAdd: string,
  setMealDelete: string,
  setMealUpdate: string,
  setMealList: string,

  addMark: string
  membersNoInGroup: string
  memberGroupDelete: string
  cancelOrder: string,
  checkToPay: string,
  employeeCancelOrder: string,
  prepayDiscountSave: string,
  prepayDiscountDel: string,
  setGroupTreat: string

  saleList: string, //买赠列表
  saleAdd: string, //买赠添加
  saleEdit: string,   //买赠编辑
  saleDetail: string,  //买赠详情
  saleStatus: string
  voucherEdit: string
  printDepartList: string,  //打印机部门列表
  printDepartDel: string,  //打印机部门删除
  printDepartEdit: string,//打印机部门编辑
  printDepartAdd: string,
  cashCouponList: string,
  getShopList: string,
  couponCopy: string,
  copyWelfare: string
  dishesHelpConfigDetail: string

  indexHomeList: string,  //首页
  fanList: string, //用户列表
  getFansDetail: string,//用户详情
  groupList: string, //用户分组
  groupMembers: string, //用户分组详情
  groupDetail: string, //用户分组-编辑-添加
  groupSave: string, //用户分组-保存
  sendCouponList: string
  discountDetail: string
  financeDetail: string
  revenueExport: string
  orderedDetail: string // 已点列表
  dishesPrinter: string, //打印菜品

  userEatingRecord: string, //获取同桌人员
  getEatingUser: string,    //获取消费记录
  deleteEatRecord: string,  //删除消费记录

  equityCardList: string, //权益卡
  rechargeList: string,
  rechargePay: string,
  withdrawList: string,
  withdrawAudit: string,
  rechargCode: string,   //充值订单号
  rechargeStatus: string
  freshReport: string
  zhuliStart: string
  printerFail: string
  printerOff: string
  reprinter: string
  delayPrinter: string

  orderActivityAdd: string
  orderActivityList: string
  orderActivityEdit: string
  orderActivityGetInfo: string
  orderActivityStatus: string

  systemVersion: string

  getCustomer: string
  setCustomer: string

  getSelfRecharge: string
  setSelfRecharge: string

  getChannel: string
  dishesGetList: string
  getSetMealAdd: string
  groupActual: string
  usergroupDetail: string
  groupFresh: string
  groupAdd: string
  usergroupList: string
  shopUserList: string,
  usergroupListFresh: string
  groupDelete: string,
  groupUpdate: string
  groupAddUser: string

  specsTypeList: string

  changeDiscount: string

  setCatSort: string
  setDishesSort: string
  getSetMealInfo: string
  invitedDetail: string

  getTableList:string //获取排队桌形列表
  changeTableStatus:string //更新排队桌形状态
  changeTableConfig:string //修改桌形配置
  getLineUpConfig:string //获取排队配置
  changeLineUpConfig:string //更新排队配置

  shopApply:string //商家认证申请
  shopApplyInfo:string //商家认证申请信息
  shopPublicInfo:string //获取公众号绑定信息
  shopMiniProgramInfo:string //获取小程序绑定信息
  shopApplyBusinessLicense:string //获取百度识别营业执照token
  shopApplyBankcard:string //银行卡识别
  shopApplyIdcard:string //身份证识别
  shopApplySearchUnionpay:string //查询银行卡支行号

  payList: string,  //支付方式列表
  deletePayList: string,  //删除支付方式列表
  updatePayList: string,  //编辑支付方式列表
  addPayList: string,  //添加支付方式列表
  channelList: string,
  payChannelList: string
  shopCreate: string,
  shopStatus: string,

  getGroupListNew: string //分组列表（自定义分组，推荐分组）
  getGroupOperationList: string //获取用户分组运营配置列表
  getGroupOperationInfo: string //获取用户分组运营配置【新】
  saveGroupOperation: string //保存用户分组运营配置【新】
  saveStopOperation: string //结束运营
  queryOtherOperationCoupon: string //分组运营获取过滤的用户
  getGroupOperationChannel: string //获取折扣券渠道【新】
  getAllGroup: string //获取所有用户分组【新】

  getRecommendGroup: string //获取推荐分组【新】
  getPrivateGroup: string //获取私域流量分组渠道【新】
  getOperationStat: string //分组运营数据详情【新】
  getOperationDetails: string //分组运营数据汇总【新】
  getGroupPolyLine: string //私域分组渠道折线图[新】
  getAllPrivate: string //获取所有私域分组【新】
  getOffiaccountMemberList: string //获取私欲流量公众号分组会员列表
  getJoinGroupQrCode: string //自定义分组邀请进群
  getSmsRechargeList: string //获取商家短信充值记录【新】
  getSmsSendList: string //获取商家短信发送记录【新】
  getSmsPackage: string //短信套餐【新】
  smsRecharge: string //充值短信【新】
  getSmsRechargeOrder: string //查询短信充值订单【新】（轮询）
  getSmsInfo: string //查询短信【新】
  getSmsEditSign: string //修改短信签名【新】
  getOfficialTemplate: string, //获取公众号消息模板
  updateOfficialTemplate: string, //更新公众号消息模板
  addDiscountChannel: string
  updateDiscountChannel: string
  cashList: string
  cashShopList: string
  stepStatus: string
  balanceList: string
  setRechargeConfig: string
  getRechargeConfig: string
  stockOverList: string
  payType: string
  channelDetail: string
  changeShopList: string,
  updateConsume: string,
  addConsume: string
  consumeList: string
  consumeInfo: string
  shareStatusUpdates: string
  getTemplate: string
  uploadPic: string
  getCouponList: string
  addCouponGroup: string
  allGroup: string
  groupCate: string
  uploadDish: string
  downloadDish: string
  uploadImg: string
  welfareConfigEditAll: string
  welfareConfigStatusAll: string

  downCode: string,
  getQrCode: string,

  bindPrintDep: string,
  getDefinitions: string,

  changePassword: string,

  getFormat: string,

  getSystemCouponConfig: string,
  updateSystemCouponConfig: string,
  sensitiveList: string,
  createForbidden: string,
  getUserProperty: string,
  setUserProperty: string,

  payChannelListMultiple: string,
  mixDiversePay: string,
  refundMixDiverse: string,
  payBillComplete: string,
  refundSetMeal: string,
  manualFeeLogList: string,
  delManualFeeLog: string,
  discountByCode: string,
  cancelMixDiverse: string,
  payTrueListWithCode: string,
  setZeroOrder: string,
  getZeroOrder: string,
  get_import_url: string,

  getBusinessModel: string,
  setBusinessModel: string,
  getPrinterStatus: string,

  scoreSave: string,
  getScoreIndex: string,
  setScoreStatus: string,
  fansSearchWithPhone: string,
  setmealStatus: string,

  cancelOrderWithWaiter: string,

  systemConfig: string,
  vipList: string,
  vipAdd: string,
  vipExport: string,
  vipImport: string,
  vipSetStatus: string,
  vipEdit: string
  webGetPermission: string


  getTargetDayOverview: string,
  getTargetMonthOverview: string,
  getTargetRows: string,
  targetSave: string,
  getTableTotal: string,
  targetDel: string,
  getTargetDetail: string,
  userRunLevel: string,
  userRunComsumeTimes: string

  getCfgComment: string,
  cfgComment: string,
  commentList:string,
  comments:string,
  deleteComment:string,
  userRunComsumeMonth: string,
  userRunEatMonth: string,
  setOrCancelVip: string,
  userRunComsumeBehavior: string,
  userRunIndex: string,

  getStaffList: string,
  getScheduleList: string,
  addStaff: string,
  updateStaff: string,
  deleteStaff: string,
  deleteSchedule: string,
  scheduleStaff: string,
  getCommentTableList: string,
  getCommentTableIndex: string,

  siyuIndex: string,
  siyuGetlineChart: string,
  siyuGetGroups: string,
  siyuGetDetail: string,
  siyuGroupRate: string,

  rePurchaseIndex: string,
  rePurchaseCountChart: string,
  rePurchaseChart: string,
  rePurchaseGroupChart: string,
  rePurchaseUserList: string,
  rePurchaseActivity: string,

  pullnewpeople: string,
  pullnew: string,
  pullnewline: string,
  addMaterial: string,
  getMaterialList:string,
  deleteMaterial:string,
  editMaterial:string,


  getAllDishes: string, //商家所有菜品列表
  pushStorageDishs: string, //寄存菜品
  storageOrder: string, //寄存订单列表
  pullStorageDishs: string, //取出寄存商品
  queryStorageSms: string,  //查询寄存提醒配置
  getDishesSepc: string, //获取菜品规格
  getNickByMibile: string, //通过手机号查询会员昵称
  setStorage: string, //修改寄存提醒相关配置
  orderFinance: string, //财务营收详情列表
  memberOfBill: string,
  memberSumBill: string,
  memberTotalBill: string,
  fansProperty: string,   //余额用户列表

  vegetableSalesData :string, // 菜品销售数据
  vegetableSalesCfg :string, // 菜品允许排名菜品数据
  vegetableSalesExport :string, // 导出菜品数据
  vegetableSalesPrint :string, // 打印菜品数据
  rechargeCount: string,  //充值总览
  rechargeNewList: string,  //充值列表
  revenueDetail: string,
  versionRead: string,
  profileIndex: string,

  discountOrdersList: string, //第三方代金券列表
  mealOrdersList: string, //第三方套餐列表
  tableDisplayInfo: string,
  tableDisplaySet: string,
  editProfile: string,
  editProfileItem: string,
  getDefaultProfile: string,
  applyProfile: string,


  pringStyleList: string // 打印样式列表
  pringStyleEdit: string // 编辑打印样式
  getPrintStyleDefalut: string // 获取打印默认样式
  getDefalutHomepage: string, //门店装修-首页-取默认数据
  getHomepage: string, //门店装修-首页-取保存数据
  saveHomepage: string, //门店装修-首页-保存数据
  applyHomepage: string, //门店装修-首页-应用数据

  newBusiness: string,
  newBusinessIncome: string
  newBusinessIncomeExport: string
  newBusinessCapital: string,   //新经营数据-其它资金
  newBusinessCapitalExport: string,   //新经营数据-其它资金-导出
  newBusinessSys: string,  //新经营数据-系统收款
  newBusinessSysExport: string,   ////新经营数据-系统收款
  cashierUserList: string,   //用户列表
  cashierUserListExport: string
  cashierVipList: string,  // vip用户
  cashierVipListExport: string
  cashierChargeList: string,  //充值用户列表
  cashierChargeListExport: string
  transToVip: string,  //充值用户转化为Vip用户
  chargeWelfareList: string,  //充值福利列表
  cashierMobileSearch: string,  //充值搜索手机号
  cashierAdjustBalance: string, //调整余额
  cashierEditInfo: string,  //变更会员信息
  cashierFrozenUser: string
  cashierUserCharge: string
  merchantCouponAdd: string
  dishesReflesh: string
  mergeOrder: string


  SmartPushTicketList: string
  SmartPushTicketAdd: string
  SmartPushTicketIndex: string
  SmartPushTicketEdit: string
  SmartPushTicketOpen: string
  SmartPushTicketUserCount: string

  DishesShowTypeSetAll: string

}
export const apiUrl:ResquestUrl = {
  DishesShowTypeSetAll: 'v1/dishes/show-type-set-all',
  regionIdList: '/v1/regionTable/regionList',
  register: '/v1/register', // 注册
  login: '/v1/login',  // 登录
  sendMobileCode: '/v1/send-mobile-code',  // 发送手机验证码
  productCount: '/v1/food/count', //商品总数
  productList: '/v1/food/list',   //商品列表
  productCopy: '/v1/food/copy',///复制商品
  productDelete: '/v1/food/del',   //删除商品
  productDetail: '/v1/food/info',   // 商品详情
  productEdit: '/v1/food/edit', //编辑商品
  productAdd: 'v1/food/add',    //添加商品
  shareActivities: '/v1/fetch-share-activities', //获取分销活动
  createShareActivities: '/v1/create-share-activity', //创建分销活动
  accountAuthH5: '/v1/component-login-page',
  getUploadToken: '/v1/upload-token', //获取分销活动
  authAccountList: 'v1/mp-account/list-by-uid', //公众号列表
  createTask: '/v1/mission/save',   // 创建任务宝任务
  taskList: '/v1/mission/list-by-uid',  // 任务列表
  deleteTask: '/v1/mission/delete',
  getAuthLink :"/v1/auth-link", // 获取授权链接
  checkWxAuth: '/v1/auth-check', // 检查核销人员微信授权
  getVerifyUsers: "/v1/fetch-shop-verify-users",  // 获取核销人员列表
  deleteActicity: '/v1/delete-share-activity', // 删除活动
  createVerifyUser: "/v1/create-shop-verify-user", // 添加核销人员
  // deleteVerifyUser: "/v1/delete-shop-verify-user", // 删除核销人员
  updateVerifyUser: "/v1/update-shop-verify-user", // 更新核销人员信息
  luckList: '/v1/luck/list',   //抽奖列表
  luckAdd: '/v1/luck/add',    //添加抽奖活动
  luckStart: '/v1/luck/start',   //抽奖活动启动
  luckPause: '/v1/luck/pause',    //抽奖活动暂停
  luckDel: '/v1/luck/del',        //抽奖活动删除
  luckCopy: '/v1/luck/copy',      //抽奖活动复制
  luckCount: '/v1/luck/count', // 抽奖活动总数
  luckInfo: '/v1/luck/info',   //抽奖活动信息
  luckEdit: '/v1/luck/edit',
  updateActivity: "/v1/update-share-activity",
  getTaskDetailById: "/v1/mission/info", // 获取任务详情byid
  fetchShareOrder:  "/v1/fetch-share-orders", // 分销订单列表
  //二唯码相关
  createQRCode: "/v1/create-qr-code", // 创建
  searchOpenid: "/v1/fans-info",
  updateQRCode: "/v1/update-qr-code", // 更新
  deleteQRCode: "/v1/delete-qr-code", // 删除
  immediatelyReturnAction: "/v1/background-withdraw-to-shop-user", //立即返现动作
  selectQRCode: "/v1/fetch-qr-code-list", // 获取列表
  updateTask: '/v1/mission/update',
  fetchShareUsers: "/v1/fetch-share-users", // 获取参与用户列表
  searchUsers: "/v1/search-users",  // 搜索用户
  fetchWithdraw: "/v1/fetch-withdraw-records", // 获取提现记录
  getFansList: "/v1/mission/fans/list",// 获取参加任务粉丝列表
  fetchBanksList: "/v1/banks", // 获取银行卡列表
  withdrawConfig: "/v1/withdraw-config", // 获取提现配置
  shopWithdraw: "/v1/shop-withdraw", // 商家提现
  pauseTask: "/v1/mission/pause", // 暂停活动或开始
  luckStatistics: "/v1/luck/statistics",   //统计基本信息
  joinPeople: '/v1/luck/join-people-list',   // 参与用户列表
  awardList: '/v1/luck/user-award-list',   //获取奖品列表
  // 优惠券
  couponList: '/v1/coupon/list',   //优惠券列表
  couponCount: '/v1/coupon/count',   //优惠券总数
  couponAdd: '/v1/coupon/add',   //添加优惠券
  couponEdit: '/v1/coupon/edit',   //编辑优惠券
  couponInfo: '/v1/coupon/info',  //优惠券详情
  peopleCoupon: '/v1/coupon/user-coupon-list',
  memberGroup: '/v1/coupon/member-group',
  couponSend: '/v1/coupon/send',
  // 菜品分类
  goodSort: '/v1/dishes-cat/list',   //菜品分类
  goodSortCount: '/v1/dishes-cat/count',   //菜品分类
  goodSortAdd: '/v1/dishes-cat/add',  //添加菜品分类
  goodSortEdit: '/v1/dishes-cat/edit',  //编辑菜品分类
  goodSortDelete: '/v1/dishes-cat/del',   //删除菜品分类
  goodInfo: '/v1/dishes-cat/info',   //菜品信息
  goodList: '/v1/dishes/list',   //菜品列表
  goodAdd: '/v1/dishes/add',  //添加菜品
  goodCount: '/v1/dishes/count',
  goodsInfo: '/v1/dishes/info',  //菜品详情
  goodEdit: '/v1/dishes/edit',  //菜品编辑
  goodStockOver: '/v1/dishes/stock-over',    //菜品售罄
  goodDelete: '/v1/dishes/del',  //删除商品
  goodEat: '/v1/dishes/must-eat', //设为默点
  goodDiscount: '/v1/dishes/no-discount',   //设为打折
  goodSortChange: '/v1/dishes/order-with-dish-id', //修改商品排序
  // 店铺
  shopList: '/v1/shop/list',   //店铺列表
  xcxList: '/v1/shop/list-xcx',  //小程序列表
  unbindXcx: '/v1/shop/unbind-xcx',   //解绑小程序
  bindXcx: '/v1/shop/bind-xcx',  //绑定小程序
  // 店铺区域
  regionList: '/v1/shop/region-list',   //区域列表
  regionAdd: '/v1/shop/region-add',    //添加区域
  regionEdit: '/v1/shop/region-edit',  // 编辑区域
  regionDel: '/v1/shop/region-del',   //删除区域
  tableList: '/v1/shop/region-table-list',   //餐桌管理
  tableCount: '/v1/shop/region-table-count',
  tableInfo: '/v1/shop/region-table-info',  //餐桌详情
  tableAdd: '/v1/shop/region-table-add',   //添加餐台
  tableEdit: '/v1/shop/region-table-edit',  //编辑餐台
  rebuildQrcode: '/v1/shop/region-table-rebuild-qrcode',   //重新生成二维码
  tableDelete: '/v1/shop/region-table-del',   //删除餐桌
  tableUse: '/v1/shop/region-table-use',   //使用餐桌
  // 点餐订单
  fetchFoodOrders: '/v1/fetch-mp-food-orders', // 点餐订单列表
  foodOrderDetail : '/v1/mp-food-order-detail', //点餐订单详情
  foodOrderUpdate: '/v1/mp-food-order-update', // 修改订单数据
  foodChargeBack: '/v1/mp-food-order-refund',  // 订单退单
  //充值记录相关
  getRechargeList: '/v1/user-charge-record-with-shop',  //获取充值记录
  //小程序资金相关
  getXcxWithdraw: '/v1/user-withdraw-record-with-shop',
  getXcxWithdrawAudit: '/v1/withdraw-review-handle',

  mpShareCreate: '/v1/mp-share-create', // 创建小程序分销活动
  shareUserList: '/v1/mp-share-user-list', // 小程序分销活动参与用户
  shareActiviteList: '/v1/mp-share-list', // 小程序分销活动列表
  shareActiviteDetail: '/v1/mp-share-detail', // 获取小程序分销详情
  shareActiviteUpdate: '/v1/mp-share-update', // 编辑小晨旭分销活动

  //充值配置相关
  welfareConfigList:'/v1/welfare-config-list',
  welfareConfigAdd:'/v1/welfare-config-add',
  welfareConfigEdit:'/v1/welfare-config-edit',
  welfareConfigEditAll: '/v1/welfare-config-edit-all',  //充值配置编辑
  welfareConfigStatusAll: '/v1/welfare-config-status-all',  //充值配置状态
  welfareConfigDelete:'/v1/welfare-config-delete',
  welfareConfigStatus:'/v1/welfare-config-status', //停用
  getMemberList: '/v1/mp-account/member/list',   //获取会员列表
  getMemberListSendCoupon: '/v1/mp-account/member/get-member-list-by-shop',   //直接发券选择用户
  //消息相关
  userSearch: '/v1/fans-search', //搜索用户
  messageTemplateList: '/v1/list-notice-template', //消息列表
  messageTemplateAdd: '/v1/create-notice-template', //消息列表
  messageTemplateEdit: '/v1/edit-notice-template', //消息列表
  messageTemplateDelete: '/v1/delete-notice-template', //消息列表
  messageTemplateSearch: 'v1/notice-template-search', //模板搜索
  messageTaskList: '/v1/list-notice-task', //消息任务列表
  messageTaskAdd: '/v1/create-notice-task', //消息任务列表
  messageTaskEdit: '/v1/edit-notice-task', //消息任务列表
  messageTaskDelete: '/v1/delete-notice-task', //消息任务列表
  messageTaskAudit: '/v1/audit-notice-task',  //消息审核
  messagePush: '/v1/push-notice',  //立即推送
  messagePushLog: '/v1/list-notice',  //消息推送记录
  messagePushLogDelete: '/v1/delete-notice', //删除推送记录

  addAdver: '/v1/adver/save',                      // 添加广告
  adverList: '/v1/adver/list-by-uid',                //广告列表
  deleteAdver: '/v1/adver/delete',                  // 删除广告
  editAdver: '/v1/adver/update',
  advertStatus: '/v1/adver/change-status',
  advertConfig: '/v1/adver/config/get', // 版位信息
  advertInfo: '/v1/adver/get-info',  //广告详情

  // 福利管理
  welfareList: '/v1/welfare/list',
  welfareDelete: '/v1/welfare/del',
  welfareStart: '/v1/welfare/start',
  welfareCouponList: '/v1/welfare/coupon-list',
  welfareEditInfo: '/v1/welfare/edit-info',
  welfareAdd: '/v1/welfare/add',
  welfareEdit: '/v1/welfare/edit',

  // 打印机管理
  printerList: '/v1/printer/list',
  printerDel: '/v1/printer/del',
  printerTest: '/v1/printer/test',
  printerTestEdit: '/v1/printer/edit',
  printerInfo: '/v1/printer/edit-info',
  printerSearch: '/v1/printer/search',
  downAll: '/v1/shop/download-all-qrcode',
  printerMsgList: '/v1/msg/list',
  printerMsgDel: '/v1/msg/del',
  printerMsgSend: '/v1/msg/resend',
  printerMsgCount: '/v1/msg/count',
  // 财务明细
  shopBalanceList: '/v1/shop-balance-list', // 获取店铺资产列表
  shopBalanceBillList: '/v1/shop-balance-bill-list', // 获取店铺资产详情

  // 会员配置
  editRuleInfo: "/v1/shop/edit-rule-info",  // 会员配置信息
  editRule: "/v1/shop/edit-rule", // 修改会员配置
  fansLevelList: "/v1/shop/fans-level-list", // 会员等级配置列表
  fansLevelAdd: "/v1/shop/fans-level-add",  // 添加等级
  fansLevelEdit: "/v1/shop/fans-level-edit",  // 等级编辑
  dishesList: '/v1/dishes', // 获取菜品列表
  dishesHelp: '/v1/zhuli/config/create', // 创建助力菜品
  dishesHelpConfigList: '/v1/zhuli/config/list', // 助力配置列表
  dishesHelpConfigEdit: '/v1/zhuli/config/update', // 编辑助力配置
  dishesHelpConfigDetail: '/v1/zhuli/config/detail', // 编辑助力详情
  helpUsersList: "/v1/zhuli/users", // 助力参与用户
  employeeSave: '/v1/employee/save',          // 添加员工账号
  employeeList: '/v1/employee/list',          // 员工列表
  memberGroupSave: "/v1/member/group/save", // 会员分组保存
  memberGroupList: "/v1/member/group/list", // 获取会员分组列表
  memberList: "/v1/member/list", // 会员列表
  addMemberToGroup: "/v1/member/save/", // 添加会员到分组
  memberGroupMembers: "/v1/member/group/info/", // 会员分组会员列表
  membersNoInGroup : "/v1/member/not-group-member/", // 查询不在当前分组的会员
  memberGroupDelete: "/v1/member/group-delete", // 删除分组

  // 合伙人相关
  partnerList: '/v1/partner-list', //合伙人记录列表
  partnerDelete: '/v1/partner-delete', //合伙人记录列表
  partnerEdit: '/v1/partner-edit', //合伙人记录列表
  partnerCreate: '/v1/partner-create', //合伙人记录列表
  getInvitedFans: 'v1/get-invited-fans', //合伙人邀请用户


  regionTableList: '/v1/regionTable/list',
  shopInfo:'/v1/shop/info',//店铺详情
  getShopInfo:'/v1/shop/infoIndex',//店铺详情(传shop_id)

  shopOpenClose:'/v1/shop/open_close', //店铺营业and打烊接口

  getDishLists:'/v1/order-dish/get-dishes-index', //桌面管理菜品列表v1/order-dish/get-dish-lists
  dishSearch:'/v1/order-dish/dish-search', //桌面管理菜品搜索v1/order-dish/dish-search
  dishInfoByDishId:'/v1/order-dish/dish-info-by-dish-id', //桌面管理菜品规格v1/order-dish/dish-info-by-dish-id
  orderDishDetail:'v1/order-dish/order-dish-detail', //桌面管理已点菜详情v1/order-dish/order-dish-detail
  openDeskWithEmpty: '/v1/order-dish/open-desk-with-empty',//开空桌v1/order-dish/open-desk-with-empty
  orderCommitToKitchen: '/v1/order-dish/order-commit-to-kitchen',//下单v1/order-dish/order-commit-to-kitchen
  codeCallToKitchen: 'v1/order-dish/code-call-to-kitchen',//下单v1/order-dish/order-commit-to-kitchen
  editUsersByDineKey: '/v1/order-dish/edit-users-by-dine-key',//修改用餐人数v1/order-dish/edit-users-by-dine-key
  orderPresentDishes:'v1/order-dish/order-present-dishes',//赠菜v1/order-dish/order-present-dishes
  printerDishes:'/v1/printer/dishes', //重新打印菜品
  dishesPrinter:'/v1/dishes/printer', //后台管理系统打印

  // 抵扣券，折扣
  deductionChannelList: '/v1/deduction/channel-list',
  deductionList: '/v1/deduction/list',
  deductionCount: '/v1/deduction/count',
  deductionAdd: '/v1/deduction/add',
  deductionEdit: '/v1/deduction/edit',
  deductionInfo: '/v1/deduction/info',
  deductionDel: '/v1/deduction/del',
  deductionStart: '/v1/deduction/start',

  discountList: '/v1/discount/list',
  discountInfo: '/v1/discount/info',
  discountAdd: '/v1/discount/add',
  discountEdit: '/v1/discount/edit',
  discountStart: '/v1/discount/start',
  discountDel: '/v1/discount/del',
  discountCount: '/v1/discount/count',
  changeVoucherStatus: "/v1/coupon/change-status",
  voucherEdit: "/v1/coupon/edit",

  // 支付
  orderExchange: '/v1/order-pay/exchange-coupon-list',
  orderDiscount: '/v1/order-pay/discount-coupon-list',
  // orderDishDetail: '/v1/order-dish/order-dish-detail',
  orderPay: '/v1/order-pay/wc-micro-pay',
  prepayOrder: '/v1/printer/prepay-order', //预结单

  // 实时消息
  messageOrderList: '/v1/message/order/list',         // 下单次数列表
  messageUserInfo: '/shop/people/info/',                // 消息用户详情
  inviteRecording: '/v1/message/invite/recording/',    // 获取邀请记录
  getPersionInfo: '/v1/people/info/', // 获取用户信息
  getOrderDetail: '/v1/order/detail', // 获取订单详情
  printBill: '/v1/printer/payed-order', // 打印账单

  // 权限相关
  allPermission: '/v1/auth/permission',  // 所有权限列表
  updateRole: '/v1/auth/role-update',       // 更新角色
  addRole: '/v1/auth/role-add',             // 添加角色
  roleList: '/v1/auth/role',              // 角色列表
  rolePermission: '/v1/auth/role-permission', // 角色已拥有权限
  roleDel: '/v1/auth/role-del',               // 删除角色
  addManageUser: '/v1/auth/user-add',         // 添加管理者用户
  manageList: '/v1/auth/user',                // 管理者列表
  deleteManageUser: '/v1/auth/user-del',      // 删除管理者
  updateManageUser: '/v1/auth/user-status',           // 更新用户状态
  updateManageAccount: '/v1/auth/user-auth',        // 更新用户全部信息
  getManageRoles: '/v1/auth/user-auth',           // 获取用户已拥有角色
  outOfSomething:'/v1/order-dish/out-of-something', //多菜品估清 v1/order-dish/out-of-something
  cancelOutByDishId:'/v1/order-dish/cancel-out-by-dish-id', //取消沽清 v1/order-dish/cancel-out-by-dish-id
  outOfSomethingIndex:'/v1/order-dish/out-of-something-index', //沽清列表 /v1/order-dish/out-of-something-index

  changeTable: '/v1/order-dish/change-table-by-table-id',   //换桌
  reprintOrder:'/v1/printer/repeat-order',
  reprintOrderMake:'v1/order-dish/again-print-make-menu',

  dishesReport: "/v1/dishesReport/list", // 菜品销售报表
  dishesReportTotal: "/v1/dishesReport/count", // 菜品销售报表数据总条数
  shopReport: '/v1/shop-report/revenue-report',   //营业收入报表
  revenueExport: '/v1/shop-report/revenue-export-report',  //报表导出
  discountDetail: '/v1/shop-report/discount-detail',
  financeReport: '/v1/shop-report/finance-report',   //营业财务报表
  financeDetail: '/v1/shop-report/finance-detail',   //财务详情
  shopReportPrinter: '/v1/shop-report/printer-with-date',  //打印当日营业收入
  dishesReportExport: "/v1/dishesReport/export", // 菜品销售报表导出
  dishesReportPrint: "/v1/dishesReport/print", // 菜品销售表打印
  dishesReportReflesh: "/v1/dishesReport/reflesh", // 刷新数据
  cancelPresentDishes: '/v1/order-dish/cancel-present-dishes', //取消赠菜
  group_list: '/v1/member/group-list',            // 会员分组列表
  addMark: '/v1/member/add-mark',                 // 备注更新
  fanList: '/v2/fans/list',     //用户列表
  getFansDetail: "/v2/fans/detail",// 用户详情
  groupList: '/v2/group/list',     //用户分组
  groupMembers: '/v2/group/members', //分组用户列表
  groupDetail: '/v2/group/detail', //用户分组--编辑&添加
  groupSave: '/v2/group/save', //用户分组--保存

  setMealIndex: '/v1/order-dish/get-setMeal-index',
  setMealAdd: '/v1/order-dish/get-setMeal-add',
  setMealDelete: '/v1/order-dish/get-setMeal-delete',
  setMealUpdate: '/v1/order-dish/get-setMeal-update',
  setMealList: '/v1/order-dish/get-setMeal-list',
  cancelOrder: '/v1/cancel-pay-food-orderr',      // 后台取消订单支付（恢复订单）
  checkToPay : '/v1/order-pay/checkout-to-pay',  //服务员操作反结账
  employeeCancelOrder : '/v1/order-pay/employee-cancel-order-with-code',  //服务员取消订单
  prepayDiscountSave: '/v1/order-pay/prepay-discount-info-save',
  prepayDiscountDel: '/v1/order-pay/prepay-discount-info-del',
  setGroupTreat: '/v1/member/set_treat', // 设置分组为区别对待

  saleList: '/v1/onsale/index', //买赠列表
  saleAdd: '/v1/onsale/store', //买赠添加
  saleEdit: '/v1/onsale/update',   //买赠编辑
  saleDetail: '/v1/onsale/detail',  //买赠详情
  saleStatus: '/v1/onsale/status',  //设置买赠状态

  printDepartList: '/v1/print_depart_index',  //打印机部门列表
  printDepartDel: '/v1/print_depart_del',  //打印机部门删除
  printDepartEdit: '/v1/print_depart_edit',//打印机部门编辑
  printDepartAdd: '/v1/print_depart_add',  //打印机部门添加

  cashCouponList: '/v1/coupon/cash-coupon-list',  //代金券购买列表
  getShopList: '/v1/coupon/get-shop-info',  //权限下店铺
  couponCopy: '/v1/coupon/copy',   //优惠券复制
  copyWelfare: '/v1/welfare/copy-welfare',  //权益卡复制

  indexHomeList: '/v1/shop/home-index',  //首页
  sendCouponList: '/v1/coupon/send-coupon-list',   //发券列表
  orderedDetail: 'v1/order-dish/ordered-detail',  //已点列表

  userEatingRecord: "shop/get-eating-record",//就餐记录
  getEatingUser: "shop/get-eating-user", //同桌人员
  deleteEatRecord: "shop/delete-eat-record", //删除就餐记录

  equityCardList: 'v1/shop-report/equity-card-list', //权益卡

  // 充值
  rechargeList: '/v2/recharge/list',  //充值记录
  rechargePay: '/v2/recharge/pay',   //充值二维码
  withdrawList: '/v2/withdraw/list',   //提现列表
  withdrawAudit: '/v2/withdraw/audit',  //提现审核
  rechargCode: '/v2/recharge/getCode',   //充值订单号
  rechargeStatus: '/v2/recharge/payStatus',   //充值状态
  freshReport: '/v1/shop-report/daily-finance-report-update',  //报表刷新
  zhuliStart: '/v1/zhuli/start', //助力活动暂停开启
  printerFail: '/v2/printer/fail',  //打印失败列表
  printerOff: '/v2/printer/off',  //打印机离线
  reprinter: '/v2/reprinter/',  //重新打印
  delayPrinter: '/v2/is_read/',  //稍后处理打印

  orderActivityAdd: '/v1/order-fee-activity-add', //添加充值免单
  orderActivityList: '/v1/order-fee-activity-list', //充免单列表
  orderActivityEdit: '/v1/order-fee-activity-edit', //充值免单编辑保存
  orderActivityGetInfo: '/v1/order-fee-activity-get-info', //充值免单获取单条记录
  orderActivityStatus: '/v1/order-fee-activity-status', //充值免单停用启用

  systemVersion: '/v1/system/version',  //版本刷新页面

  getCustomer: '/v2/get_customer', //获取客服号列表
  setCustomer: '/v2/set_customer', //设置客服号

  getSelfRecharge: '/v1/get_self_recharge',//获取自定义充值状态
  setSelfRecharge: '/v1/set_self_recharge',//设置自定义充值状态

  getChannel: '/v1/order-dish/get-channel', //获取套餐渠道
  dishesGetList: '/v1/dishes-cat/get-list', //获取套餐分类
  getSetMealAdd: '/v1/order-dish/get-setMeal-add', //添加套餐

  groupActual: '/v1/wxgroup/line', //分群曲线图
  usergroupDetail: '/v1/wxgroup/info',//分群详情
  groupFresh: '/v1/wxgroup/reflesh_group',   //
  groupAdd: '/v1/wxgroup/create',   //创建分群
  usergroupList: '/v1/wxgroup/list',
  shopUserList: 'v1/wxgroup/shop_user_list',
  usergroupListFresh: '/v1/wxgroup/reflesh_all_group',
  groupDelete: '/v1/wxgroup/del',
  groupUpdate: '/v1/wxgroup/update',
  groupAddUser: '/v1/wxgroup/add_user',

  specsTypeList:'/v2/specs_type_list', //添加商品-搜索口味

  setCatSort: '/v2/set_cat_sort', //商品分类排序
  setDishesSort: '/v2/set_dishes_sort', //商品排序

  getTableList:'/v2/line_up_table_type', //获取桌形列表
  changeTableStatus:'/v2/line_up_table_type_status', //更新排队桌形状态
  changeTableConfig:'/v2/line_up_table_type_edit', //修改桌形配置
  getLineUpConfig:'/v2/line_up_config', //获取排队配置
  changeLineUpConfig:'/v2/line_up_config_update', //更新排队配置

  changeDiscount: '/v1/order-dish/change-discount', //批量打折
  getSetMealInfo: '/v1/order-dish/get-set-meal-info', //获取商品套餐详情
  payList: '/v1/order-pay-channel/list',  //支付方式列表
  deletePayList: '/v1/order-pay-channel/delete',  //删除支付方式列表
  updatePayList: '/v1/order-pay-channel/update',  //编辑支付方式列表
  addPayList: '/v1/order-pay-channel/create',  //添加支付方式列表
  channelList: '/v1/order-pay-channel/usable-pay-channels',  //支付方式渠道列表
  payChannelList: '/v1/order-pay-channel/pay-channel-list',  //支付方式渠道（结账）
  shopCreate: '/v1/shop-create', //添加店铺
  shopStatus: '/v1/shop-change-status',  //改变状态
  invitedDetail: '/v2/invited_detail', //邀请人详情

  shopApply:'/v1/shop/apply', //商家认证申请
  shopApplyInfo:'/v1/shop/apply/info', //商家认证申请信息
  shopPublicInfo:'/v1/shop/publicInfo', //公众号绑定信息
  shopMiniProgramInfo:'/v1/shop/miniProgramInfo', //小程序绑定信息
  shopApplyBusinessLicense:'/v1/shop/apply/businessLicense', //获取百度识别营业执照token
  shopApplyBankcard:'/v1/shop/apply/bankcard', //银行卡识别
  shopApplyIdcard:'/v1/shop/apply/idcard', //身份证识别
  shopApplySearchUnionpay:'/v1/shop/apply/searchUnionpay', //查询银行卡支行号

  getGroupListNew:'/v2/group/list', //分组列表（自定义分组，推荐分组）
  getGroupOperationList:'/v2/group/operationList', //获取用户分组运营配置列表
  getGroupOperationInfo:'/v2/group/getOperation', //获取用户分组运营配置【新】
  saveGroupOperation:'/v2/group/operation', //保存用户分组运营配置【新】
  saveStopOperation:'/v2/group/stopOperation', //结束运营
  queryOtherOperationCoupon:'/v2/group/queryOtherOperationCoupon', //分组运营获取过滤的用户
  getGroupOperationChannel:'/v2/discount/getChannel', //获取折扣券渠道【新】
  getAllGroup:'/v2/group/allGroup', //获取所有用户分组【新】
  getRecommendGroup:'/v2/group/recommend', //获取推荐分组
  getPrivateGroup:'/v2/group/private', //获取私域流量分组渠道【新】
  getOperationStat:'/v2/group/operationStat', //分组运营数据详情【新】
  getOperationDetails:'/v2/group/operationDetails', //分组运营数据汇总【新】
  getGroupPolyLine:'/v2/group/polyLine', //私域分组渠道折线图[新】
  getAllPrivate:'/v2/group/allPrivate', //获取所有私域分组【新】[新】
  getOffiaccountMemberList:'/v2/group/offiaccountList', //获取丝域流量公众号会员列表
  getJoinGroupQrCode: '/v2/group/getJoinGroupQrCode',  //自定义分组邀请进群

  getSmsRechargeList: '/v2/sms/rechargeList', //获取商家短信充值记录【新】
  getSmsSendList: '/v2/sms/sendList', //获取商家短信发送记录【新】
  getSmsPackage: '/v2/sms/package', //短信套餐【新】
  smsRecharge: '/v2/sms/recharge', //充值短信【新】
  getSmsRechargeOrder: '/v2/sms/queryRecharge', //查询短信充值订单【新】（轮询）
  getSmsInfo: '/v2/sms/querySms', //查询短信【新】
  getSmsEditSign: '/v2/sms/editSign', //修改短信签名【新】
  getOfficialTemplate: '/v2/officialTemplate', //获取公众号消息模板
  updateOfficialTemplate: '/v2/updateOfficialTemplate', //更新公众号消息模板

  addDiscountChannel: '/v1/deduction/add-discount-channel',   //添加抵扣券渠道
  updateDiscountChannel: '/v1/deduction/update-discount-channel',  //删除或编辑渠道
  cashList: '/v2/coupon_list',   //代金券对账列表
  cashShopList: '/v2/shop_list',   //店铺列表
  balanceList: '/v1/balance-bill',  //余额消费

  stepStatus: '/v2/step_status', //开店指南
  setRechargeConfig: '/v2/set_recharge_config', //设置充值配置
  getRechargeConfig: '/v2/get_recharge_config', //获取充值配置

  stockOverList: '/v1/order-dish/stock-over-list',  //已沽清列表
  payType: '/v1/pay-type-with-sys',  //支付方式列表

  channelDetail: '/v1/shop-report/channel-receipt-detail',  //财务报表第三方详情
  changeShopList: '/v1/shop-list-with-user',   //切换门店店铺列表
  addConsume: '/v1/welfare/add-consume-present',  //添加消费有礼
  updateConsume: '/v1/welfare/update-consume-present',  //更新消费有礼
  consumeList: '/v1/welfare/consume-present-list',   //消费有礼列表
  consumeInfo: '/v1/welfare/get-info',   //消费有礼详情

  shareStatusUpdates: '/v1/share_status_updates', //分销列表-状态更新
  getTemplate: '/v1/template', //获取分销系统海报
  uploadPic: '/v1/uploadPic', //上传图片
  getCouponList: '/v1/coupon/get-list',  //已领取优惠券用户
  addCouponGroup: '/v2/group/add-user-to-group',   //添加用户分组
  allGroup: '/v2/group/allGroup',   //获取所有分组
  groupCate: '/v2/group/group-category',  //推荐分组类别
  uploadDish: '/v2/upload_dish', //上传商品
  downloadDish: '/v2/download_dish', //商品导出
  uploadImg: '/v2/upload_img', //图片上传

  downCode: '/v1/region-table/download-tables-qr-code',  //物料下载
  getQrCode: '/v1/order-dish/get-card-with-advance-order',  //提前点餐二维码

  bindPrintDep: '/v2/bind_print_dep', //批量绑定打印机部分
  getDefinitions: '/v2/get_definitions', //添加商品-获取单位

  changePassword: '/v1/change_password', //修改密码

  getFormat: '/v2/get_format', //打印机制作单格式

  getSystemCouponConfig: '/v1/get-system-coupon-config', //获取店铺优惠限制
  updateSystemCouponConfig: '/v1/update-system-coupon-config', //更新店铺优惠限制
  sensitiveList: '/v2/sensitive_list', //敏感操作列表
  createForbidden: '/v2/create_forbidden', //敏感操作的限制
  getUserProperty: '/v2/get_user_property', //获取用户资产详情
  setUserProperty: '/v2/set_user_property', //设置用户资产详情

  payChannelListMultiple: '/v1/order-pay-channel/pay-channel-list-multiple', //获取多种支付-第三方支付渠道
  mixDiversePay: '/v1/order-pay/mix-diverse-pay', //多种支付方式-支付
  refundMixDiverse: 'v1/order-pay/refund-mix-diverse', //多种支付方式-撤销
  payBillComplete: 'v1/order-pay/pay-bill-complete', //多种支付-完成结账
  refundSetMeal: '/v1/refund-set-meal', //退套餐
  manualFeeLogList: '/v1/get-manual-fee-log-list', //调价列表
  delManualFeeLog: '/v1/del-manual-fee-log', //取消调价
  discountByCode: '/v1/discount-by-code', //单个菜品-打折
  cancelMixDiverse: 'v1/order-pay/cancel-mix-diverse', //多种支付方式-取消结账
  payTrueListWithCode: 'v1/order-pay/pay-true-list-with-code', //多种支付方式-已支付列表
  setZeroOrder: 'v1/set_zero_order', //设置沽清后收银台可点
  getZeroOrder: 'v1/get_zero_order', //获取沽清后收银台可点

  get_import_url: 'v1/get_import_url', //商品管理-下载模版

  setBusinessModel: '/v1/set_business_model', //设置营业模式
  getBusinessModel: '/v1/get_business_model', //获取营业模式
  getPrinterStatus: '/v2/getPrinterStatus', //获取打印机状态

  scoreSave: '/v2/score/save', //保存积分兑换
  getScoreIndex: '/v2/score/index', //积分商城
  setScoreStatus: '/v2/score/status', //修改积分商品状态
  fansSearchWithPhone: '/v1/order-pay/fans-search-with-phone', //结账搜索会员信息
  setmealStatus: '/v1/update-set-mel-status',  //上下架套餐

  cancelOrderWithWaiter: 'v1/order-dish/cancel-order-with-waiter', //桌面管理-餐前支付-点餐中-取消点餐


  SmartPushTicketList: 'v1/coupon-auto-marketing/list', //智能发券列表
  SmartPushTicketAdd: 'v1/coupon-auto-marketing/add', //智能发券列表
  SmartPushTicketIndex: 'v1/coupon-auto-marketing/info', //智能发券列表
  SmartPushTicketEdit: 'v1/coupon-auto-marketing/update-data', //智能发券列表
  SmartPushTicketOpen: 'v1/coupon-auto-marketing/open', //智能发券列表
  SmartPushTicketUserCount: 'v1/coupon-auto-marketing/get-old-user-total', //智能发券列表

  systemConfig: 'v2/system/config', //系统设置
  vipList: 'v2/vip/list', //会员列表
  vipAdd: 'v2/vip/save', //添加会员
  vipExport: 'v2/vip/export', //会员导出
  vipImport: 'v2/vip/import', //会员导入
  vipSetStatus: 'v2/vip/set_status', //设置状态
  vipEdit: 'v2/vip/edit', //编辑会员

  webGetPermission: '/v1/webGetPermission', // 根据门店返回权限

  getTargetDayOverview: 'v2/target/dayOverview', //店铺经营目标看板 - 日
  getTargetMonthOverview: 'v2/target/monthOverview', //店铺经营目标看板 - 月
  getTargetRows: 'v2/target/rows', //经营目标列表
  targetSave: 'v2/target/save', //保存经营目标
  getTableTotal: '/shop/index/table/total', //获取桌台总数
  targetDel: 'v2/target/del', //删除经营目标
  getTargetDetail: 'v2/target/details', //商家经营目标详情
  userRunLevel: 'v2/user/run/level', //用户等级
  userRunComsumeTimes: 'v2/user/run/comsumeTimes', //用户消费次数

  getCfgComment: 'v1/comment/cfgcomment' ,// 获取评价配置信息
  cfgComment: 'v1/comment/cfgcomment', // 配置信息
  commentList: 'v1/comment/shop', // 获取店铺评价数据汇总信息
  comments: 'v1/comment/list', // 获取评价列表数据
  deleteComment: 'v1/comment/list', // 删除评价数据
  userRunComsumeMonth: 'v2/user/run/comsumeMonth', //用户消费月份
  userRunEatMonth: 'v2/user/run/eatMonth', //用户就餐月份

  getStaffList: 'v1/shop-waiter-manage/list', // 获取服务员列表
  getScheduleList: 'v1/shop-waiter-manage/schedule-list', // 获取服务员列表
  addStaff: 'v1/shop-waiter-manage/add', // 添加服务员
  updateStaff: 'v1/shop-waiter-manage/update-data', // 更新服务员
  deleteStaff: 'v1/shop-waiter-manage/del', // 删除服务员
  deleteSchedule: 'v1/shop-waiter-manage/schedule-del', // 删除服务员
  scheduleStaff: 'v1/shop-waiter-manage/schedule', // 服务员排班
  getCommentTableList: 'v1/shop-waiter-manage/table-list', // 评价餐桌列表
  getCommentTableIndex: 'v1/shop-waiter-manage/tables-index', // 餐桌列表目录

  setOrCancelVip: 'v1/order-dish/vip-price-set-by-code',   //结账设置或取消会员价
  userRunComsumeBehavior: 'v2/user/run/comsumeBehavior', //用户就餐行为
  userRunIndex: 'v2/user/run/index', //用户首页

  siyuIndex: 'v2/siyu/index', //统计图表
  siyuGetlineChart: 'v2/siyu/getChart', //折线图
  siyuGetGroups: 'v2/siyu/getGroups', //私域分组列表
  siyuGetDetail: 'v2/siyu/getDetail', //社群能力对比
  siyuGroupRate: 'v2/siyu/groupRate', //社群详情页饼状图
  //复购营运
  rePurchaseIndex: 'v2/re-purchase/index', //复购营运
  rePurchaseCountChart: 'v2/re-purchase/countChart', //饼图
  rePurchaseChart: 'v2/re-purchase/chart', //复购折线图
  rePurchaseGroupChart: 'v2/re-purchase/groupChart', //复购分析饼图
  rePurchaseUserList: 'v2/re-purchase/userList', //复购分析用户列表
  rePurchaseActivity: 'v2/re-purchase/activity', //复购活动

  //拉新
  pullnew: '/v1/pullnew', //拉新运营
  pullnewpeople: '/v1/pullnewpeople', //拉新用户分析列表
  pullnewline: '/v1/pullnewline', //拉新折线图，活动分析

  // 加料
  addMaterial:'/v1/material', // 店铺加料
  getMaterialList:'/v1/material', // 店铺加料列表
  deleteMaterial:'/v1/material', // 删除加料数据
  editMaterial:'/v1/material' ,// 编辑加料数据

  // 余额支付
  fansProperty: '/v1/order-pay/fans-property-with-code',

  //存酒/存物
  getAllDishes: 'v2/get_all_dishes', //商家所有菜品列表
  pushStorageDishs: 'v2/push_storage_dishs', //寄存菜品
  storageOrder: 'v2/storage_order', //寄存订单列表
  pullStorageDishs: 'v2/pull_storage_dishs', //取出寄存商品
  queryStorageSms: 'v2/sms/queryStorageSms', //查询寄存提醒配置
  getDishesSepc: 'v2/get_dishes_sepc', //查询菜品规格
  getNickByMibile: 'v2/get_nick_by_mobile', //通过手机号查询会员昵称
  setStorage: 'v2/sms/setStorage', //修改寄存提醒相关配置
  orderFinance: '/v1/shop-report/orders-by-finance',

  //新版_会员对账
  memberOfBill:'/v1/member-of-bill', //会员对账
  memberSumBill: 'v1/member-sum-bill', //会员对账-sum
  memberTotalBill: 'v1/member-total-bill', //会员对账-total
  // 菜品销售数据
  vegetableSalesData:"/v1/dishes/vegetable-sale-list",
  vegetableSalesCfg:"/v1/dishes/vegetable-sale-cfg",
  vegetableSalesExport:"/v1/dishes/vegetable-sale-export",
  vegetableSalesPrint:"/v1/dishes/vegetable-sale-print",
  rechargeCount: '/v2/recharge/count',  //充值数据
  rechargeNewList:'/v2/recharge/list',  //充值列表
  revenueDetail: '/v1/shop-report/revenue-report-detail',  //营业收入优惠详情
  versionRead: '/v1/system/notice-read',

  pringStyleList: 'v2/print_style/list', //打印样式列表
  pringStyleEdit: 'v2/print_style/edit', // 编辑打印样式
  getPrintStyleDefalut: 'v2/print_style/default', // 获取默认样式

  profileIndex: '/v1/profile-index', //门店装修-我的

  discountOrdersList: 'v2/third/discountOrders', //第三方代金券列表
  mealOrdersList: 'v2/third/mealOrders', //第三方套餐列表

  tableDisplayInfo: '/v1/region-table/table-display-config-info',  //桌面管理餐桌显示配置详情
  tableDisplaySet: '/v1/region-table/table-display-config-set',
  editProfile: '/v1/edit-profile', //门店装修-保存
  editProfileItem: '/v1/edit-profile-item', //门店装修-菜单编辑
  getDefaultProfile: '/v1/get-default-profile', //门店装修-默认数据
  applyProfile: '/v1/apply-profile', //门店装修-我的-预览使用
  getDefalutHomepage: 'v1/get-default-homepage', //门店装修-首页-取默认数据
  getHomepage: 'v1/homepage', //门店装修-首页-取保存数据
  saveHomepage: 'v1/save-homepage', //门店装修-首页-保存数据
  applyHomepage: 'v1/apply-homepage', //门店装修-首页-应用数据

  newBusiness: '/v1/shop-business-data-new/business-data-new',   //新经营数据
  newBusinessIncome: '/v1/shop-business-data-new/sys-other-data-record',  //新经营数据-其它收入
  newBusinessIncomeExport: '/v1/shop-business-data-new/sys-other-data-record-export',  //新经营数据-其它收入-导出
  newBusinessCapital: '/v1/shop-business-data-new/other-income-data-record',   //新经营数据-其它资金
  newBusinessCapitalExport: '/v1/shop-business-data-new/other-income-data-record-export',   //新经营数据-其它资金-导出
  newBusinessSys: '/v1/shop-business-data-new/sys-coll-data-record',  //新经营数据-系统收款
  newBusinessSysExport: '/v1/shop-business-data-new/sys-coll-data-record-export',   ////新经营数据-系统收款
  cashierUserList: '/v1/cashier-charge/vip-user-list',   //用户列表
  cashierUserListExport: '/v1/cashier-charge/vip-user-list-export',  //用户列表-导出
  cashierVipList: '/v1/cashier-charge/vip-single-list',   //vip用户
  cashierVipListExport: '/v1/cashier-charge/vip-single-list-export', //（VIP会员列表数据导出）
  cashierChargeList: '/v1/cashier-charge/charge-single-list',  //充值用户列表
  cashierChargeListExport:'/v1/cashier-charge/charge-single-list-export', //（充值会员列表数据导出）
  transToVip: '/v1/cashier-charge/trans-vip-by-mobile',  //充值用户转化为Vip用户
  chargeWelfareList: '/v1/cashier-charge/charge-welfare-list',  //充值福利列表
  cashierMobileSearch: '/v1/cashier-charge/mobile-search',  //充值搜索手机号
  cashierAdjustBalance: '/v1/cashier-charge/adjust-balance-by-uid', //调整余额
  cashierEditInfo: '/v1/cashier-charge/edit-vip-info',  //变更会员信息
  cashierFrozenUser: '/v1/cashier-charge/frozen-vip-by-uid',   //冻结解冻
  cashierUserCharge: '/v1/cashier-charge/vip-user-charge',  //收银台会员充值
  merchantCouponAdd: '/v1/merchant-coupon-add', //T添加商家优惠券
  dishesReflesh: '/v1/dishesReport/reflesh',   //菜品销量刷新

  mergeOrder: '/v1/order-dish/merge-desk-with-orders' // 合并订单
};
