import React from 'react'
import { Form, Icon, Input, Button, Row, message, } from 'antd';
import Search from 'antd/lib/input/Search';
import { FormComponentProps } from 'antd/lib/form';
import { getRequest, postRequest } from '../../api/http';
import { observer, inject } from 'mobx-react';
import { IAppStore } from '../../stores/app.store';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {apiUrl} from "../../api/api_url";



interface RegisterFormProps extends FormComponentProps, RouteComponentProps {
  appStore?: IAppStore
}

@inject('appStore')
@observer
class RegisterForm extends React.Component<RegisterFormProps, {}> {

  state = {
    phone: '',
    password01: '',
    password02: '',
    confirmDirty: false,
    enterButtonText: '获取短信验证码',
    second: 0
  }

  // 发送验证码
  sendCode = async () => {
    let { phone, second } = this.state;
    if(second > 0) {
      message.error(`${second}秒后获取`);
      return;
    }
    var ret:any = await getRequest(apiUrl.sendMobileCode, { 'mobile': phone })
    if(ret && ret.data) {
      second = 60;
      const timer = setInterval(() => {
        second--;
        if(second > 0) {
          this.setState({
            enterButtonText: `${second}秒后获取`,
            second: second
          })
        } else {
          clearInterval(timer);
        }
      }, 1000);
    }
  }

  hasErrors(fieldsError: { [x: string]: unknown; }) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
  }

  handleSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const res: any = await postRequest(apiUrl.register, values);
        if (res && res.data) {
          localStorage.setItem('token', `${res['data']['token']}`);
          this.props.history.push('/');
        }
      }
    });
  };

  checkPhoneNumber = (rule: any, value: any, callback: Function) => {
    const regex = /^((\+)?86|((\+)?86)?)0?1[3458]\d{9}$/;
    if (value) {
      //react使用正则表达式变量的test方法进行校验，直接使用value.match(regex)显示match未定义
      if (regex.test(value)) {
        callback();
      } else {
        if (value.length === 11 && Number(value) > 0) {
          callback();
        } else {
          callback('请输入正确的手机号码！');
        }
      }
    } else {
      //这里的callback函数会报错
    }
  }

  phoneChange = (e: any) => {
  }

  componentDidMount() {
    // To disabled submit button at the beginning.
    this.props.form.validateFields();
  }

  // 对比两个密码是否一致
  compareToFirstPassword = (rule: any, value: any, callback: any) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback('输入的两个密码不一致!');
    } else {
      callback();
    }
  };

  // 校验密码格式
  validateToNextPassword = (rule: any, value: any, callback: () => void) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  tapToLogin = () => {
    this.props.history.push('/login');
  }

  render() {
    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;
    const phoneError = isFieldTouched('mobile') && getFieldError('mobile');
    const phoneCodeError = isFieldTouched('mobile_code') && getFieldError('mobile_code');
    const passWorldCodeError = isFieldTouched('password') && getFieldError('password');

    return <Row type="flex" justify="center" align="middle" style={{ minHeight: '100vh', minWidth: '10vw' }}>
      <Form className="login-form" style={{ width: '350px' }} onSubmit={this.handleSubmit}>
        <Form.Item validateStatus={phoneError ? 'error' : ''}>
          {
            getFieldDecorator('mobile', {
              rules: [{ required: true, message: '请输入手机号' }, { validator: this.checkPhoneNumber }]
            })(<Input
              size="large"
              prefix={<Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="今后使用手机号登录"
              maxLength={11}
              onChange={(e) => this.setState({phone :e.target.value})}
            />)
          }
        </Form.Item>
        <Form.Item validateStatus={phoneCodeError ? 'error' : ''}>
          {
            getFieldDecorator('mobile_code', {
              rules: [{ required: true, message: '验证码格式错误' }]
            })(<Search
              size="large"
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="string"
              placeholder="填写短信验证码"
              enterButton={`${this.state.enterButtonText}`}
              maxLength={4}
              onSearch={() => this.sendCode()}
            />)
          }
        </Form.Item>
        <Form.Item validateStatus={passWorldCodeError ? 'error' : ''}>
          {
            getFieldDecorator('password', {
              rules: [{ required: true, message: '密码格式错误' }, { validator: this.validateToNextPassword }]
            })(<Input.Password
              size="large"
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="8-20位字符，包含字母数字"
            />)
          }
        </Form.Item>
        <Form.Item validateStatus={passWorldCodeError ? 'error' : ''}>
          {
            getFieldDecorator('password_confirmation', {
              rules: [{ required: true, message: '密码格式错误' }, { validator: this.compareToFirstPassword, }]
            })(<Input.Password
              size="large"
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="请再次输入密码"
            />)
          }

        </Form.Item>
        <Button type="primary" htmlType="submit" size="large" style={{ width: '350px' }} disabled={this.hasErrors(getFieldsError())}>确认注册2</Button>
        <Row type="flex" justify="end">
          <Button type="link" onClick={() => this.tapToLogin()}>已有账号？去登录</Button>
        </Row>
      </Form>
    </Row>

  }
}

export default withRouter(Form.create<RegisterFormProps>({})(RegisterForm));