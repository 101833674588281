import {observer} from "mobx-react";
import React from "react";
import {Button, Row, Col, Table, Select, message, Modal, Tag} from "antd";
import {getRequest, postRequest} from '../../../api/http';
import {apiUrl} from '../../../api/api_url';
import {Link} from "react-router-dom";
import {getIsPermisson, messageCustom} from "../../../utils/permisstionUtil";

const {Option} = Select;
const key = 'DeductionCoupon'

@observer
class DeductionCoupon extends React.Component<any> {
    state = {
        count: 0,
        list: [],
        currentPage: 1,
        pageSize: 10,
        cate: [],
    }

    columns = [
        {
            title: '打折名称',
            dataIndex: 'name',
            key: 'name',
            className: 'list_center',
        },
        {
            title: '渠道',
            dataIndex: 'channel_name',
            key: 'channel_name',
            className: 'list_center',
        },
        {
            title: '已使用数量',
            dataIndex: 'use_num',
            key: 'use_num',
            className: 'list_center',
        },
        {
            title: '状态',
            dataIndex: 'limit',
            key: 'limit',
            className: 'list_center',
            render: (text: any, record: any,) => (
                //<div className="color">{record.is_effect === 1 ? `已生效` : '未生效'}</div>
                <div>{
                    record.is_effect === 1 ? <Tag color="#87d068">已生效</Tag> : <Tag color="#ff0000">未生效</Tag>
                }</div>
            ),
        },
        {
            title: '添加时间',
            dataIndex: 'created_at',
            key: 'created_at',
            className: 'list_center',
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            className: 'list_center',
            render: (text: any, record: any,) => (
                <div className="btn_wrap">
                    <div className="list_btn" onClick={() => {
                        if (!getIsPermisson('1_12_13_4')) {
                            messageCustom();
                            return;
                        }
                        this.props.history.push({pathname: '/sidebar/deductionAdd', state: {id: record.id}})
                    }}>编辑
                    </div>
                    <div className="list_btn"
                         onClick={() => this.stopItem(record)}>{record.is_effect === 1 ? '暂停' : '开启'}</div>
                    <div className="list_btn" onClick={() => this.deleteItem(record.id)}>删除</div>
                </div>
            ),
        },
    ];

    componentDidMount() {
        this.onInitCount();
        this.initList({});
        this.initCate();
    }

    // 初始化列表总数
    onInitCount = async () => {
        const res: any = await getRequest(apiUrl.deductionCount,);
        if (res.code === 1) {
            this.setState({
                count: res.data.num
            })
        }
    }

    // 渠道
    initCate = async () => {
        const res: any = await getRequest(apiUrl.deductionChannelList,);
        if (res.code === 1) {
            let cate = [{
                'value': -1,
                'name': '全部'
            }]
            cate = cate.concat(res.data.list);
            this.setState({
                cate
            })
        }
    }

    pageChange = async (page: number) => {

        const {pageSize,} = this.state;
        const params: any = {
            current: page,
            pageSize: pageSize,
        };

        this.setState({
            currentPage: page
        })

        this.initList(params);
    }

    // 类型
    cateChange = async (channel: any) => {
        const {pageSize} = this.state;
        const params: any = {
            current: 1,
            pageSize: pageSize,
        };
        if (channel !== -1) {
            params.channel = channel;
        }
        this.initList(params);
    }

    initList = async (params: any) => {
        const res: any = await getRequest(apiUrl.deductionList, params);
        if (res.code === 1) {
            this.setState({
                list: res.data.list
            })
        }
    }

    deleteItem = async (id: number) => {
        if (!getIsPermisson('1_12_13_6')) {
            messageCustom();
            return;
        }
        Modal.confirm({
            title: '提示',
            content: '确认删除此券',
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                const params = {
                    id
                }
                const res: any = await postRequest(apiUrl.deductionDel, params);
                if (res.code === 1) {
                    message.success({content: '删除成功!', key, duration: 2});
                    this.initList({});
                    this.onInitCount();
                }
            },
            onCancel() {
            },
        });
    }

    stopItem = (record: any) => {
        if (!getIsPermisson('1_12_13_5')) {
            messageCustom();
            return;
        }
        Modal.confirm({
            title: '提示',
            content: `确认${record.is_effect === 1 ? '暂停' : '开启'}此券`,
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                const params = {
                    id: record.id
                }
                const res: any = await postRequest(apiUrl.deductionStart, params);
                if (res.code === 1) {
                    message.success({content: '操作成功!', key, duration: 2});
                    this.initList({});
                }
            },
            onCancel() {
            },
        });
    }

    render() {
        const {list, count, cate} = this.state;

        return <div className='l_content'>
            <Row style={{marginBottom: '20px'}} type="flex" align="middle" justify="start">
                <Col span={2}>
                    <Button type="primary" size="large" icon="plus" onClick={() => {
                        {
                            if (!getIsPermisson('1_12_13_1')) {
                                messageCustom();
                                return;
                            }
                            this.props.history.push('/sidebar/deductionAdd')
                        }
                    }}>
                        添加新抵扣券
                    </Button>
                </Col>
                <Col span={4} offset={2}>
                    <span>抵扣券渠道：</span>
                    <Select placeholder="请选择抵扣劵渠道" allowClear style={{width: 170}} onChange={this.cateChange}>
                        {
                            cate.map((item: any) => {
                                return <Option value={item.value} key={item.value}>{item.name}</Option>
                            })
                        }
                    </Select>
                </Col>
            </Row>

            <div>
                <Table dataSource={list} size="middle" columns={this.columns} rowKey={"id"} locale={{emptyText: '暂无数据'}}
                       pagination={{
                           total: count,
                           current: this.state.currentPage,
                           pageSize: this.state.pageSize,
                           hideOnSinglePage: true,
                           onChange: this.pageChange,
                           showQuickJumper: true,
                           showSizeChanger: true,
                           showTotal: (total: number) => `共${total}条`,
                           onShowSizeChange: (current: number, size: number) => {
                               this.setState({pageSize: size});
                               this.initList({page: this.state.currentPage, pageSize: size})
                           }

                       }}/>
            </div>

        </div>;
    }
}

export default DeductionCoupon