import React, { useEffect, useState } from 'react'
import { Modal, message, Row, Col, Button } from 'antd'
import { getRequest } from '../../api/http'
import { apiUrl } from '../../api/api_url'
import './windowManage.scss'
import { useHistory } from 'react-router-dom';

const ChangeTable = ()=>{
    const history:any = useHistory()
    const [windowList,setWindowList] = useState([])

    const [divisible_num_class, setDivisible_num_class] = useState('window_5'); //每一排显示的个数--对应的类名

    useEffect(()=> {
        windowListRequest(1)
    },[])

    const buildType = process.env.REACT_APP_ChromeApp;
    //餐桌list
    const windowListRequest = async (status:number) => {
        const res:any = await getRequest(`${apiUrl.regionTableList}`,{
            status:status,
            pageSize:100,
            code: history.location.state.code});
        if(res.code === 1){
            let arr = [];
            let list = res.data.list;
            let table_count = res.data.line_show_table_count;
            let len = 6;

            //根据餐桌来自动调整每一排显示个数
            if(table_count == 5) {
                len = 5;
                if(buildType === '1') {
                    setDivisible_num_class('window_5_waiter')
                } else {
                    setDivisible_num_class('window_5')
                }
            } else if(table_count == 6) {
                len = 6;
                if(buildType === '1') {
                    setDivisible_num_class('window_6_waiter')
                } else {
                    setDivisible_num_class('window_6')
                }
            } else if(table_count == 7) {
                len = 7;
                if(buildType === '1') {
                    setDivisible_num_class('window_7_waiter')
                } else {
                    setDivisible_num_class('window_7')
                }
            } else {
                len = 8;
                if(buildType === '1') {
                    setDivisible_num_class('window_8_waiter')
                } else {
                    setDivisible_num_class('window_8')
                }
            }

            if(list && list.length > 1){
                let num = list.length % len;
                for(let i=0; i< len-num;i++){
                    arr.push({'active': true})
                }
            }

            list = list.concat(arr);
            setWindowList(list)
            console.log(list)
        }
    }

    const onTableChange = (tableId:any, tableName:any,) => {
        Modal.confirm({
            title: (<div style={{fontSize:20}}>提示</div>),
            content: (<div style={{fontSize: 24}}>确定将桌号 {history.location.state.tableName} 换到 {tableName}</div>),
            okText: (<div style={{fontSize: 20}}>确认</div>),
            cancelText: (<div style={{fontSize: 20}}>取消</div>),
            onOk: async () => {
                let params = {
                    table_id_his: history.location.state.table_id,
                    table_id_new: tableId
                }
                let res: any = await getRequest(apiUrl.changeTable, params)
                if(res.code === 1){
                    message.success('换桌成功')
                    history.push('/sidebar/windowManage')
                }
            },
            onCancel() {
            },
        });
    }

    return (
        <div className="windowManage l_content">
            <Row type='flex' align='middle' className='mb30'>
                <Col span={2}>
                    <div onClick={() => {history.goBack()}}>
                        <Button type='primary' icon='left' size='large' style={{fontWeight: 'bold'}}>返回</Button>
                    </div>
                </Col>
                <Col className='table_info'>
                    <div>换桌</div>
                </Col>
            </Row>
            <div className="content">
                {
                    windowList.length > 0
                    ? windowList.map((item:any) => {
                        return (
                            !item.active
                            ? <div className={item.is_eating ? `${divisible_num_class} full` : `${divisible_num_class}`}
                            style={{position: 'relative', background: item.corlour_value}}
                            onClick={()=>{ onTableChange(item.id, item.name)}} key={item.id}>

                            <div className="table">
                                <div className="left">{item.name}</div>
                                <div className="right">{item.user_amount}/{item.people_num}人</div>
                            </div>
                            {
                                item.is_eating ?
                                <div className="price">¥{item.origin_fee}</div>
                                :
                                <div className="price"></div>
                            }
                            {
                                item.is_eating ?
                                <div className="time">
                                    <div className="open">开</div>
                                    <div className="time">{item.eat_time}</div>
                                </div>
                                :
                                <div className="time"></div>
                            }
                        </div>
                        : <div className={`${divisible_num_class} window_empty`}></div>

                        )
                    })
                    : <div style={{textAlign:'center', width: "100%", margin:'50px 0', fontSize: '20px'}}>暂无空桌可换</div>

                }
            </div>
        </div>
    )
}

export default ChangeTable
