import React, { useEffect, useState, useRef } from 'react'
import ArcProgress from 'react-arc-progress';
import styles from './arcProgress.module.scss'

type Iprops = {
    target: Number,
    value: Number,
    type:Number,
}

const ArcProgressNew = (props:Iprops)=>{

    const { target, value, loading, type }:any = props

    const box1:any = useRef(null)
    const box1Inner:any = useRef(null)
    const box2:any = useRef(null)
    const box2Inner:any = useRef(null)

    useEffect(()=>{
        var style = document.createElement('style');
        style.type = 'text/css';
        console.log('target：',target)
        console.log('value',value)
        //let deg:any = (target?parseFloat(value)>parseFloat(target)?1:value/target:1)*360
        let deg:any = (target ? target/100 : 0) * 360
        //console.log('adasdsa:',target ? 2 : 1)
        //console.log('deg:',deg)
        var keyFrames = '\
        @keyframes spin-'+type+'{\
            to{transform: rotate('+deg+'deg);}\
        }\
        @keyframes spin-reverse-'+type+'{\
            to{\
                transform:rotate(-'+deg+'deg);\
            }\
        }';
        style.innerHTML = keyFrames;
        document.getElementsByTagName('head')[0].appendChild(style);


        // setTimeout(()=>{
        //     if(box1.current) box1.current.style="transform:rotate("+deg+"deg)"
        //     if(box1Inner.current) box1Inner.current.style="transform:rotate(-"+deg+"deg)"
        //     if(box2.current) box2.current.style="transform:rotate(300deg)"
        //     if(box2Inner.current) box2Inner.current.style="transform:rotate(-300deg)"
        // },3000)
    },[target, value])

    const progress:any = {
        num: 0.8,
        text: '100',
    }

    //单位换算,千改为k，万改为W
    const changeUnit = (number:any) => {
        if(number<10000){
            return (number/1000).toFixed(2)+'k'
        }else{
            return (number/10000).toFixed(2)+'w'
        }
    }

    return (
        <div className={styles.arc_progress_wrap}>
            <div className={styles.arc_progress_box}>
                <ArcProgress
                    progress={target ? target/100 : 0}
                    text={`${value}人`}
                    key={type}
                    textStyle={{
                        size: '22px',
                        color:'#4A4A4A',
                        x:100+30,
                        y:90+30,
                    }}
                    customText={[{
                        text:'拉新活动顾客',
                        size: '24px',
                        color:'#4A4A4A',
                        x:100+30,
                        y:120+30,
                    },{
                        text:'',
                        size: '22px',
                        color:'#4A4A4A',
                        x:120+30,
                        y:90+30,
                    }]}
                    size={260}
                    // customText={["aasas","aasas"]}
                    observer={(current) => {
                        const { percentage, currentText } = current;
                        // console.log('observer:', percentage, currentText);
                    }}
                    animationEnd={({ progress, text }) => {
                        // console.log('animationEnd', progress, text);
                    }}
                    thickness={30} //设置圆环进度条的厚度
                    arcStart={-90}
                    arcEnd={270}
                    animation={3000}
                    fillColor={{gradient: ['#FF97E5','#516EFF']}}
                    //emptyColor={'transparent'}
                    className="arc_progress"
                />  
                {/* <ArcProgress
                    progress={1}
                    text={''}
                    size={260}
                    // customText={["aasas","aasas"]}
                    observer={(current) => {
                        const { percentage, currentText } = current;
                        // console.log('observer:', percentage, currentText);
                    }}
                    animationEnd={({ progress, text }) => {
                        // console.log('animationEnd', progress, text);
                    }}
                    thickness={25} //设置圆环进度条的厚度
                    arcStart={-90}
                    arcEnd={270}
                    animation={3000}
                    // fillColor={{gradient: ['#3753FD', '#56CEFE']}}
                    emptyColor={'transparent'}
                    className="arc_progress_inner"
                />     */}
                <div className={`${styles.box1} ${styles.box}`}>
                    <div className={styles.ball} ref={box1} style={{animation:'spin-'+type+' 3s 1 linear forwards'}}>
                        <div className={styles.inner} ref={box1Inner} style={{animationName:'spin-reverse-'+type}}>
                            <span className={styles.item_tip}>拉新人数</span>
                            <span className={styles.item_rate}>占总新增顾客：{target}%</span>
                        </div>
                    </div>
                </div>   
                {/* <div className="box box2">
                    <div className="ball" ref={box2}>
                        <div className="inner" ref={box2Inner}>{changeUnit(target)}</div>
                    </div>
                </div>    */}
            </div>
        </div>
    )
}

export default ArcProgressNew



