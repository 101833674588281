import {observer} from "mobx-react";
import React from "react";
import {Button, Input, message, Row, Col,} from "antd";
import { postRequest, getRequest } from '../../../api/http';
import { apiUrl } from '../../../api/api_url';

const key = 'updatable';

@observer
class DiscountAdd extends React.Component<any> {

    state = {
        loading: false,
        name: '',
        discount: 0,
    }

    componentDidMount () {
        const {location} = this.props;
        if(location.state !== undefined){
            this.initEdit(location.state.id);
        }
    }

    // 初始化编辑
    initEdit = async (id:number) => {
        const params = {
            id: id
        };
        const res:any = await getRequest(apiUrl.discountInfo,params);
        if(res.code === 1){
            this.setState({
                name: res.data.name,
                discount: res.data.discount
            })
        }
    }

    nameChange = (e:any) => {
        let name = e.target.value.trim();
        this.setState({
            name
        })
    }

    discountChange = (e:any) => {
        this.setState({
            discount: e.target.value
        })
    }

    handleSubmit = async () => {
        message.loading({ content: '发布中', key });

        const {location} = this.props;
        const { name, discount } = this.state;
        var tableInfo:any = {};

        if(location.state !== undefined){
            tableInfo.id = location.state.id;
        }

        if(name === ''){
            message.warning({ content: '请输入打折名称!', key, duration: 2 });
            return;
        }

        if(discount <= 0 || discount >= 10 ){
            message.warning({ content: '请输入10以下的折扣值!', key, duration: 2 });
            return;
        }

        const reg = /^[0-9]+([.][0-9]{1}){0,1}$/;
        const pattern = new RegExp(reg);
        if(!pattern.test(discount.toString())){
            message.warning({ content: '请输入最多一位小数的折扣值!', key, duration: 2 });
            return;
        }

        tableInfo.name = name;
        tableInfo.discount = discount;

        if(location.state !== undefined){
            const res:any = await postRequest(apiUrl.discountEdit,tableInfo);
            if(res['code'] === 1){
                message.success({ content: '修改成功!', key, duration: 2 });
                this.goBack();
                // this.props.history.push('/sidebar/discountCoupon');
            }else{
                message.error({ content: '修改失败!', key, duration: 2 });
            }
        }else{
            const res:any = await postRequest(apiUrl.discountAdd,tableInfo);
            if(res['code'] === 1){
                message.success({ content: '发布成功!', key, duration: 2 });
                // this.props.history.push('/sidebar/discountCoupon')
                this.goBack();
            }else{
                message.error({ content: '发布失败!', key, duration: 2 });
            }
        }
    };

    // 返回上一页
    goBack = () => {
        this.props.history.goBack();
    }

    render(){
        const { name, discount} = this.state;

        return <div className='l_content'>
                <div className="edit_title">添加结账打折信息</div>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">结账打折名称</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <Input placeholder="请输入打折名称" maxLength={10} value={name} onChange={(e) => this.nameChange(e)}/>
                    </Col>
                </Row>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">打多少折</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        {
                            discount
                            ?   <Input type='number' maxLength={10} placeholder='请输入10以下的折扣值' value={discount} onChange={(e) => this.discountChange(e)} />
                            :   <Input placeholder='请输入10以下的折扣值' type='number' maxLength={10} onChange={(e) => this.discountChange(e)} />
                        }
                    </Col>
                </Row>

                <Row>
                    <Col span={12}>
                        <div className="bottom_wrap">
                            <Button type="primary" ghost onClick={this.goBack}>
                                取消
                            </Button>
                            <Button type="primary" onClick={this.handleSubmit}>
                                保存
                            </Button>
                        </div>
                    </Col>
                </Row>

        </div>;
    }

}
export default DiscountAdd