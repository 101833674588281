import React, { useEffect, useState } from 'react'
import { Row, Button, Table, Col, message, Tooltip } from 'antd'
import { useHistory, useLocation } from 'react-router-dom';
import { getRequest } from '../../api/http'
import { apiUrl } from '../../api/api_url'
import './detail.scss'
import moment from 'moment';
import { floor } from 'lodash';

const MarketingDetail = ()=>{

    const history = useHistory()
    const location:any = useLocation()
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [stat, setStat] = useState<any>({});
    const [paramsObj, setParamsObj] = useState<any>({
        page: 1,
        pageSize: 10,
    })

    useEffect(()=>{
        console.log(location)
        init()
    },[])

    const init = async() => {
        const operation_id = location.state && location.state.id ? location.state.id : 0
        if(!operation_id){
            message.error('没有分组运营编号')
            history.goBack()
            return false
        }
        paramsObj.detail_id = operation_id
        getOperationStat({...paramsObj})
    }

    const getOperationStat = async (data:any = {}) => {
        let param = {
            ...data
        }
        if(param.sortType)param.sortType=param.sortType=='ascend'?'asc':'desc'
        Object.keys(param).map(el => {
            if(!param[el]) delete param[el]
        })
        setParamsObj({...param})
        const res:any = await getRequest(apiUrl.getOperationStat, param)
        if(res && res['code'] === 1) {
            setData(res.data.items.data)
            setStat(res.data.stat)
            setTotal(res.data.items.total)
        }
    }

    const handleTableChange = (pagination:any, filters:any, sorter:any) => {
        console.log(pagination)
        getOperationStat({
            ...paramsObj,
            page:pagination.current,
            pageSize:pagination.pageSize,
            sortField:sorter.field,
            sortType:sorter.order
        })
    }

    const percentage = (molecule:any,denominator:any)=>{
        let percentage:any
        if(molecule == 0){
            percentage = 0
        }else if(denominator == 0){
            percentage = 100
        }else{
            percentage = (molecule/denominator*100).toFixed(2)
        } 
        return percentage
    }
    
    const creatTable = ()=>{

        const columns:any = [
            {
                title: '用户ID',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: '用户昵称',
                dataIndex: 'nickname',
                key: 'nickname',
            },
            {
                title: '用户等级',
                dataIndex: 'level_name',
                key: 'level_name',
            },
            {
                title: '手机号码',
                dataIndex: 'mobile',
                key: 'mobile',
            },
            {
                title: '性别',
                dataIndex: 'sex',
                key: 'sex',
                render:( text:string, record:any, index:number )=> text=='1'?'男':'女'
            },
            {
                title: '用户分组',
                dataIndex: 'group_name',
                key: 'group_name',
                render:( text:any, record:any, index:number )=> 
                    <Tooltip title={text.join('、')}>
                        {
                            text[0]+'...'
                        }
                    </Tooltip>                
                    // <>
                    //     {
                    //         text.map((item:any,index:number)=>{
                    //             return <div key={index}>{item}</div>
                    //         })
                    //     }
                    // </>
            },
            {
                title: '查看时间',
                dataIndex: 'look_at',
                key: 'look_at',
            },
            {
                title: '是否使用',
                dataIndex: 'is_use',
                key: 'is_use',
                render: (text:any,record:any,index:number)=>text?'是':'否'
            },
            {
                title: '注册时间',
                dataIndex: 'create_time',
                key: 'create_time',
                sorter:true,
                render:( text:any, record:any, index:number )=> moment(text*1000).format('YYYY-MM-DD HH:mm:ss')
            },
            {
                title: '券使用时间',
                dataIndex: 'used_at',
                key: 'used_at',
            },
        ];
        
        return (
            <Table 
                dataSource={data} 
                columns={columns} 
                onChange={handleTableChange}   
                rowKey={'id'}     
                pagination={{
                    defaultCurrent: 1,
                    current:paramsObj.page,
                    pageSize: paramsObj.pageSize,
                    total: total,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    showTotal: (total: number) => `共${total}条`,
                }}   
            />
        )
    }


    return(
        <div className="marketing_detail l_content">
            <div className="title_text">运营数据</div>
            <Row className="action_info">
                <Col span={4} className="fz16">{location.state?location.state.name:''}</Col>
                <Col span={9} className="fz16">生效时间：{location.state?(location.state.start_time+'至'+location.state.end_time):''}</Col>
                <Col span={6} className="fz16">{location.state?location.state.coupon_name:''}：{location.state && location.state.expire_info ? location.state.expire_info.type == 'day' ? '自领取日'+ location.state.expire_info.day + '天内' : location.state.expire_info.start_time + '-' + location.state.expire_info.end_time:''}</Col>         
                <Col span={5} className="fz16">{location.state?location.state.pushTime:''}</Col>                    
            </Row>
            <div className="title_text sub_title">数据汇总</div>
            <Row className="statistics">
                <Row className="mb30">
                    <Col span={4}><div>分组人数</div><div className="statistics_num">{stat.group_member_num}</div></Col>
                    <Col span={4}><div>已发送人数</div><div className="statistics_num">{stat.push_member_num}</div></Col>
                    <Col span={4}><div>通知送达人数</div><div className="statistics_num">{stat.push_successd_num}</div></Col>
                    <Col span={4}><div>查看人数</div><div className="statistics_num">{stat.look_fan_num}</div></Col>
                    <Col span={4}><div>使用人数</div><div className="statistics_num">{stat.use_coupon_fan_num}</div></Col>
                    <Col span={4}><div>消费人数</div><div className="statistics_num">{stat.consumer_member_num}</div></Col>
                 </Row>
                <Row className="mb30">
                    <Col span={4}><div>消费订单数</div><div className="statistics_num">{stat.consumer_order_num}</div></Col>
                    <Col span={4}><div>消费金额</div><div className="statistics_num">{stat.consumer_money}</div></Col>
                    <Col span={4}><div>通知-触达转化率</div><div className="statistics_num">{stat.push_member_num&&percentage(stat.push_successd_num,stat.push_member_num)}%</div></Col>
                    <Col span={4}><div>通知-使用转化率</div><div className="statistics_num">{stat.push_successd_num&&percentage(stat.use_coupon_fan_num,stat.push_successd_num)}%</div></Col>
                    <Col span={4}><div>通知-消费转化率</div><div className="statistics_num">{stat.push_successd_num&&percentage(stat.consumer_member_num,stat.push_successd_num)}%</div></Col>
                </Row>  
            </Row>
            {
                creatTable()
            }
        </div>
    )
}

export default MarketingDetail