import React from 'react';
import { Row, Col } from 'antd';
import '../management.scss';

import winAddWel1 from '../../../../../../assret/images/winAddWel1.png';
import winAddWel2 from '../../../../../../assret/images/winAddWel2.png';
import winAddWel3 from '../../../../../../assret/images/winAddWel3.png';
import winAddWel4 from '../../../../../../assret/images/winAddWel4.png';

interface HelpMbaWelcome {
    history: any,
}

class HelpMbaWelcome extends React.Component<HelpMbaWelcome> {

    render() {
        return  <Row className="typeDetail">
                    <Col className="typeDetail-title">一、功能介绍</Col>
                    <Col className="typeDetail-col">门店营销送礼，拉新促活增收。</Col>
                    <Col className="typeDetail-title">二、功能路径</Col>
                    <Col className="typeDetail-col">电脑端登录后台-营销管理-进店有礼列表-添加进店有礼。</Col>
                    <Col className="typeDetail-col">
                        <img src={winAddWel1} />
                    </Col>
                    <Col className="typeDetail-title">三、操作流程</Col>
                    <Col className="typeDetail-col">1、<span className="txt-bold">添加进店有礼</span>，在“进店有礼列表”界面下点击“添加进店有礼”</Col>
                    <Col className="typeDetail-col">
                        <img src={winAddWel2} />
                    </Col>
                    <Col className="typeDetail-col">1、活动名称：填写名称</Col>
                    <Col className="typeDetail-col">2、活动时间：选择活动时间</Col>
                    <Col className="typeDetail-col">3、推广渠道：有自然流量和邀请两种方式，自然流量，是用户自己进入小程序；邀请是通过朋友分享海报，进入小程序，当选择邀请，显示领取方式，可选择仅受邀请人获得或邀请人和受邀请人同时获得；仅受邀请人获得，是只有受邀请人能获得该活动奖励；邀请人和受邀请人同时获得，是邀请人和受邀请人同时获得该活动奖励</Col>
                    <Col className="typeDetail-col">4、状态：启用和停用两种状态，默认选择启用，当选择停用，则用户不能领取到该活动奖励</Col>
                    <Col className="typeDetail-col">
                        <img src={winAddWel3} />
                    </Col>
                    <Col className="typeDetail-col">
                        <img src={winAddWel4} />
                    </Col>
                    <Col className="typeDetail-col">1、推送页面：有首页、点餐页、领券中心，当现在首页时，领取活动礼品在点餐小程序首页弹窗显示；当选择点餐页时，领取活动礼品在点餐小程序点餐页显示；当现在领取中心时，领取活动礼品在点餐小程序领取中心页显示</Col>
                    <Col className="typeDetail-col">2、使用须知：填写使用须知</Col>
                    <Col className="typeDetail-col">3、活动礼品：选择相关优惠券或菜品券，当没有优惠券或菜品券时，去创建优惠券选择礼包渠道</Col>
                </Row>
    }
}

export default HelpMbaWelcome;