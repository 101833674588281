import React from 'react';
import {Row, Col, Input, message, } from 'antd';
import './selectStore.scss'
import { getRequest } from '../../api/http';
import { apiUrl } from '../../api/api_url';

interface SelectStore {
    history: any,
}
 class SelectStore extends React.Component<SelectStore>{

    state =  {
        shop_list: [
            {
                shop_name: '深圳拈江湖',
                shop_id: '',
                is_open: true //是否开店 true-已开店 false-已打烊
            }
        ],
        user_info: {
            mobile: ''
        }, //用戶信息
    }

    componentDidMount() {
        let list:any = window.localStorage.getItem('shop_list');
        let user_info:any = window.localStorage.getItem('user_info');
        this.setState({
            shop_list: JSON.parse(list),
            user_info: JSON.parse(user_info)
        })
    }

    //退出登录
    onOutLogin() {
        localStorage.clear();
        this.props.history.push("/login");
    }

    //选择门店
    onGoPage(shop_id:any, shop_name:any, info: any,siyu_tab_list: any) {
        localStorage.setItem('shop_id', shop_id);
        localStorage.setItem('shop_name', shop_name)
        localStorage.setItem('shop_request', info.request)
        localStorage.setItem('shop_info', JSON.stringify(info))
        if(process.env.REACT_APP_ChromeApp === "1") {
            this.props.history.push('/sidebar/windowManage');
        }else{
          console.log('siyu_tab_list',siyu_tab_list)
            if(siyu_tab_list[0].routers && siyu_tab_list[0].routers.length > 0){
                console.log(siyu_tab_list[0].routers[0].path)
                this.props.history.push(siyu_tab_list[0].routers[0].path);
            }else{
                this.props.history.push(siyu_tab_list[0].path);
            }
        }
    }

    onChange = (e:any) => {
        if(!e.target.value) {
            let list:any = window.localStorage.getItem('shop_list');
            this.setState({
                shop_list: JSON.parse(list),
            })
        }
    }

    //搜索
    onSearchShop = (e:any) => {
        if(e.target.value !== '') {
            try {
                let shop_list:any = this.state.shop_list;
                let temp = [];
                for (let index = 0; index < shop_list.length; index++) {
                    if(shop_list[index]['shop_name'].indexOf(e.target.value) !== -1) {
                        temp.push(shop_list[index])
                    }
                }
                this.setState({
                    shop_list: temp
                })
            } catch (error) {

            }
        } else {
            let list:any = window.localStorage.getItem('shop_list');
            this.setState({
                shop_list: JSON.parse(list),
            })
        }

    }

    handleClick = async (item: any) => {
        const res:any = await getRequest(apiUrl.webGetPermission,{shopid: item.shop_id});
        if(res.code == 1){
         let permissions = res.data.permission ?? []
          let keys = res.data.key ?? []
          if(keys.length == 0){
            message.warning('没有权限请联系管理员');
            // localStorage.removeItem('shop_request')
            return
          }
          window.localStorage.setItem('siyu_tab_list',JSON.stringify(permissions));
          window.localStorage.setItem('uni_keys',JSON.stringify(keys));
          // todo 请求导航栏 和已拥有权限
          this.onGoPage(item.shop_id, item.shop_name,item,permissions)
        }else{
            message.error('服务器异常'+ res.message);
        }

    }

    render() {
        let { shop_list, user_info } = this.state;

        return <div className='store l_content'>
            <Row>
                <Col span={18} className='store-select'>选择门店</Col>
                <Col span={6} className='store-out'>
                    <div className='store-out-tel'>{user_info.mobile}</div>
                    <div className='store-out-exit' onClick={() => {this.onOutLogin()}}>退出</div>
                </Col>
            </Row>
            <Row className='store-all'>
                <Col span={18}>所有门店</Col>
                <Col span={6}>
                    <Input placeholder="搜索门店" onCompositionEnd={this.onSearchShop} onChange={this.onChange} />
                </Col>
            </Row>
            <div className='store-item'>
                {
                    shop_list.map((item, index) => {
                        return (
                        <div key={index} className='store-item-list' onClick={() => this.handleClick(item)}>
                            <div className='store-item-list-name'>{item.shop_name}</div>
                            <div className='store-item-list-status'>店铺状态：{item.is_open ?　'正常营业' : '已打烊'}</div>
                        </div>
                        )
                    })
                }
            </div>
        </div>
    }
}

export default SelectStore;