import React, { useEffect, useState } from "react";
import { Modal, Input, Row, Col, Switch, Select, Tooltip, Icon, message, InputNumber } from 'antd';
import { postRequest, getRequest } from '../../../api/http';
import { apiUrl } from '../../../api/api_url';

import './lineUp.scss'

const { Option } = Select;

type Eprops = {
    lineUpFlag: boolean,
    onClose(): void,
}

const LineUpModal = (props:Eprops)=>{

    const { lineUpFlag, onClose } = props
    const [ confirmLoading, setConfirmLoading] = useState(false) //过号弹窗(延桌设置)
    const [ info, setInfo] = useState<any>({}) //过号弹窗(延桌设置)

    var tables:number[] = new Array(9)
    useEffect(()=>{
        init()
    },[])

    const init = async() => {
        const res:any = await getRequest(apiUrl.getLineUpConfig)
        console.log(res)
        if( res.code == 1 ) {
            res.data.map((item:any)=>{
                info[item.key] = item.value[0]
            })
            setInfo(JSON.parse(JSON.stringify(info)))
            console.log(info)
        }
    }

    const handleOk = async()=>{
        let params:any = {}
        Object.keys(info).map((item)=>{
            if(item=='is_add_zero'){
                params[item] = [info[item]==true?1:0]
            }else{
                params[item] = [info[item]]
            }
        })
        setConfirmLoading(true)
        const res:any = await postRequest(apiUrl.changeLineUpConfig,{data:JSON.stringify(params)})
        setConfirmLoading(false)
        if( res.code == 1 ){
            message.success('设置成功')
            onClose()
        }else{
            message.error(res.message||'设置失败')
        }
    }

    //监听Switch
    const onChangeSwitch = (e:any)=>{
        info.is_add_zero = e
        setInfo(JSON.parse(JSON.stringify(info)))
    }
    //监听Input
    const onChange = (e:any)=>{
        info.start_num = e
        setInfo(JSON.parse(JSON.stringify(info)))
    }
    //监听Select
    const onChangeSelect = (e:any)=>{
        info.jump_num = e
        setInfo(JSON.parse(JSON.stringify(info)))
    }

    return (
        <Modal
            title="排队号码设置"
            visible={lineUpFlag}
            onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={onClose}
            className="linp-up-modal"
        >
            <Row type="flex" align="middle" className="mb20">
                <Col span={10}>
                    <span>排队为个数时桌号不加0</span>
                </Col>
                <Col span={14}>
                    <Switch checked={info.is_add_zero ==1?true:false}  onChange={onChangeSwitch} />
                </Col>
            </Row>  
            <Row type="flex" align="middle" className="mb20">
                <Col span={10}>
                    <span className="mr5">排队起始号码</span>
                    <Tooltip title={'每次归零重新开始排号时，排号起始号码从输入号码开始往上递增'}><Icon type="question-circle" theme="filled" /></Tooltip>
                </Col>
                <Col span={14}>
                    <InputNumber min={1} placeholder="请输入排队起始号码" value={info.start_num ?info.start_num :1} onChange={onChange} style={{width:150}} />
                </Col>
            </Row>  
            <Row type="flex" align="middle" className="mb20">
                <Col span={10}>
                    <span className="mr5">排队跳过数字</span>
                    <Tooltip title={'选择数字后排号自动跳过包含数字的所有排号'}><Icon type="question-circle" theme="filled" /></Tooltip>
                </Col>
                <Col span={14}>
                    <Select value={info.jump_num} style={{ width: 150 }} onChange={onChangeSelect}>
                        <Option value={'0'}>无</Option>
                        {
                            tables.toString().split(',').map((item,index)=>{
                                return <Option value={(index+1)+''} key={index}>{index+1}</Option>
                            })
                        }
                    </Select> 
                </Col>
            </Row>  
        </Modal>        
    )
}

export default LineUpModal