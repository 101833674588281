import React from 'react';
import {Table, Tooltip, message, Select} from 'antd'
import { getRequest, postRequest } from '../../../../api/http';
import { apiUrl } from '../../../../api/api_url';
import new_detail from '../../../../assret/images/new_detail.png'
import print_select from '../../../../assret/images/print_select.png'
import { getIsPermisson, messageCustom } from '../../../../utils/permisstionUtil';
import OrderPadding from '../components/order_padding_detail';
import goodsExport from '../../../../assret/images/goodsExport.png'

import styles from './recharge_record.module.scss';

const { Option } = Select;

class IncomeDetail extends React.Component<any> {
    column = [
        {
            title: "订单号",
            dataIndex: "code",
            key: "code",
        },
        {
            title: '订单状态',
            dataIndex: "status_display",
            key: "status_display",
        },
        {
            title: "餐台号",
            dataIndex: "desk",
            key: "desk",
        },
        {
            title: "用餐人数",
            dataIndex: "user_amount",
            key: "user_amount",
        },
        {
            title: "已点（份）",
            dataIndex: "foods_num",
            key: "foods_num",
        },
        {
            title: "下单金额",
            dataIndex: "origin_fee",
            key: "origin_fee",
        },
        {
            title: "付款用户",
            dataIndex: "fan",
            key: "fan",
            render: (text: string, record: any) => {
                return <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={record.fan.headimgurl} style={{width: '20px', height: '20px', borderRadius: '50%', marginRight: '5px'}} />
                    <div>{record.fan.nickname}</div>
                </div>
            }
        },
        {
            title: "优惠类型",
            dataIndex: "discount_type",
            key: "discount_type",
            render: (text: string, record: any) => {
                return <div>
                    {
                        record.discount_type ? record.discount_type.map((item:any) => {
                            return <div key={item}>{item}</div>
                        }): '--'
                    }
                </div>
            }
        },
        {
            title: "优惠名称",
            dataIndex: "discount_name",
            key: "discount_name",
            render: (text: string, record: any) => {
                return <div>
                    {
                        record.discount_name ? record.discount_name.map((item:any) => {
                            return <div key={item}>{item}</div>
                        }): '--'
                    }
                </div>
            }
        },
        {
            title: "优惠金额",
            dataIndex: "discount_fee",
            key: "discount_fee",
            render: (text: string, record: any) => {
                return <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {
                        record.discount_fee ? record.discount_fee: '--'
                    }
                </div>
            }
        },
        {
            title: "余额支付",
            dataIndex: "over_pay",
            key: "over_pay",
            render: (text: string, record: any) => {
                return <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {
                        record.over_pay ? record.over_pay: '--'
                    }
                </div>
            }
        },
        {
            title: "实付金额",
            dataIndex: "actual_fee",
            key: "actual_fee",
        },
        {
            title: "支付类型",
            dataIndex: "pay_type",
            key: "pay_type",
        },
        {
            title: "创建时间",
            dataIndex: "created_at",
            key: "created_at",
        },
        {
            title: "支付时间",
            dataIndex: "paid_at",
            key: "paid_at",
        },
        {
            title: "操作",
            dataIndex: "updated_at",
            key: "updated_at",
            className: "list_center",
            render: (text: string, rowdata:any) => {
                return <div style={{display: 'flex', alignItems: 'center'}}>
                    <Tooltip placement="bottomRight" title={'详情'}>
                        <img src={new_detail} className='table_icon' onClick={() => this.showOrderDetail(rowdata)}></img>
                    </Tooltip>
                    <Tooltip placement="bottomRight" title={'补打印结账单'}>
                        <img src={print_select} className='table_icon' onClick={() => this.printStatement(rowdata)}></img>
                    </Tooltip>
                </div>
            }
        },
    ];

    state = {
        list: [],
        current: 1,
        pageSize: 10,
        count: 0,
        third_discount: {},
        sys_discount: {},
        showPaddingOrderDrawer: false,
        currentDrawerData: {
            desk: '',
            dishes: [],
            amount: 0,
            price: '',
            price_vip: '',
            code: '',
            status_display: '',
        },
        pay_type: undefined,
    };

    componentDidMount() {
        this._initInfo()
    }
    payTypeChange = (e: any) => {
        this.setState({
            pay_type: e
        }, () => {
            this._initInfo({
                pay_type:  e
            })
        })
    }

    // 初始化数据
    _initInfo = async (data = {}) => {
        let start_time = this.props.location.state && this.props.location.state.open_at;
        let end_time = this.props.location.state && this.props.location.state.close_at;
        console.log(start_time,end_time)

        let { pageSize, current,pay_type } = this.state;
        let params: any = {
            ...data,
            current,
            pageSize,
            pay_type
        };
        if(pay_type){
            params.pay_type = pay_type;
        }
        if (start_time !== "") {
            params.start_time = start_time;
            params.end_time = end_time;
        }
        let res: any = await getRequest(apiUrl.revenueDetail, params);
        if (res.code === 1) {
            this.setState({
                count: res.data.order_list.count,
                list: res.data.order_list.list,
                sys_discount: res.data.sys_discount,
                third_discount: res.data.third_discount
            });
        }
    };

    pageChange = (page: number) => {
        this.setState({
            current: page
        }, () => {
            this._initInfo();
        });
    };

    // 显示订单详情
    showOrderDetail = async (rowdata: any) => {
        if(!getIsPermisson('1_4_1_2')){
        messageCustom();
        return;
        }
        this.setState({
            currentDrawerData: rowdata,
            showPaddingOrderDrawer: true
        })
    }

       // 打印结账单
    printStatement = async (rowdata: any) => {
        if(!getIsPermisson('1_4_1_3')){
        messageCustom();
        return;
        }
        console.log('row', rowdata);
        const res:any = await postRequest(apiUrl.printBill, { code: rowdata.code, group_key: rowdata.group_key, has_invoice: false});
        if(res && res['code'] === 1) {
            message.success('打印成功');
        }
    }

    // 导出
    exportTable = async () => {
        if(!getIsPermisson('1_3_1_1')){
            messageCustom()
            return;
        }
        const token = localStorage.getItem('token');
        const shop_id = localStorage.getItem('shop_id');
        let start_time = this.props.location.state && this.props.location.state.open_at;
        let end_time = this.props.location.state && this.props.location.state.close_at;
        let pay_type = this.state.pay_type;
        window.open(`${process.env.REACT_APP_BASEURL}${'/v1/shop-report/revenue-report-detail-export'}?token=${token}&shop_id=${shop_id}&start_time=${start_time}&end_time=${end_time}&pay_type=${pay_type}`)
    }

    render() {
        let {
            list,
            current,
            pageSize,
            count,
        } = this.state;
        let sys_discount:any = this.state.sys_discount;
        let third_discount:any = this.state.third_discount;

        return (
            <div className="income" style={{padding: '20px'}}>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', paddingRight: '30px'}}>
                    <div className='charge_title mb20'>{sys_discount.name}
                    <div className={styles.rechargeRecord_query_list} style={{display: 'inline-block', 'marginLeft': '100px'}}>
                        <div className={styles.rechargeRecord_query_list_title} style={{display:'inline'}}>支付方式：</div>
                        <Select placeholder="请选择支付类型" value={this.state.pay_type} style={{ width: 160 }} onChange={this.payTypeChange} allowClear>
                            <Option value={'wechat'}>微信</Option>
                            <Option value={'alipay'}>支付宝</Option>
                            <Option value={'money'}>现金</Option>
                            <Option value={'no-pay'}>免单</Option>
                            <Option value={'over_pay'}>余额支付</Option>
                            <Option value={'meituan'}>美团支付</Option>
                            <Option value={'koubei'}>口碑支付</Option>
                            <Option value={'bank'}>银行卡支付</Option>
                        </Select>
                    </div>
                    </div>
                    <div className='export_btn' onClick={this.exportTable}>
                        <img src={goodsExport} />导出
                    </div>
                </div>


                <div className='data_wrap mb30'>
                    {
                        sys_discount.list && sys_discount.list.map((item:any) => {
                            return <div className='data_shadow income_item' style={{marginRight:'20px'}} key={item.name}>
                                    <div className='income_total'>
                                        <div className='income_total_l'>{item.name}</div>
                                        <div className='income_total_r'>￥{item.value}</div>
                                    </div>
                                    <div className='income_rate' style={{margin:0}}>
                                        <div className='income_rate_l' style={{fontSize: '16px'}}>{item.name2}</div>
                                        <div className='income_rate_r'>{item.count}</div>
                                    </div>
                                </div>
                        })
                    }
                </div>

                <div className='charge_title mb20'>{third_discount.name}</div>
                <div className='data_wrap'>
                    {
                        third_discount.list && third_discount.list.map((item:any) => {
                            return <div className='data_shadow income_item mb20' style={{marginRight:'20px', width:'580px'}} key={item.name}>
                                <div className='income_total borderB'>
                                    <div className='income_total_l'>{item.name}</div>
                                    <div className='income_total_r'>￥{item.value}/{item.count}</div>
                                </div>
                                <div className='income_con' style={{paddingBottom: '20px'}}>
                                    {
                                        item.detail.map((detail:any) => {
                                            return <div className="income_info">
                                                <div className='income_info_l'>{detail.name}</div>
                                                <div className='income_info_r'>{detail.value}/{detail.count}</div>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        })
                    }
                </div>

                <div style={{background:'#fff', marginRight: '30px'}} className='finance_table_wrap'>
                <Table
                    dataSource={list}
                    size="middle"
                    columns={this.column}
                    rowKey={"day"}
                    locale={{ emptyText: "暂无数据" }}
                    pagination={{
                        current,
                        total: count,
                        pageSize,
                        onChange: this.pageChange,
                        showQuickJumper: true,
                        showSizeChanger: true,
                        showTotal: (total: number) => `共${total}条`,
                        onShowSizeChange: (current: number, size: number) => { this.setState({ pageSize: size }, () => { this._initInfo(); }) }
                    }}
                >
                </Table></div>

                              {/* 订单详情 */}
      <OrderPadding
        showPaddingOrder={this.state.showPaddingOrderDrawer}
        changeVisitity={(v) => {this.setState({showPaddingOrderDrawer:false})}}
        code={this.state.currentDrawerData.code ? this.state.currentDrawerData.code : ''} />
            </div>
        );
    }
}

export default IncomeDetail;