import React, { useEffect, useState, useRef } from 'react'
import ReactEcharts from "echarts-for-react";
import './pieChart.scss'

type Iprops = {
    data: any,
    income:any
}

const UserDetailPieChart = (props:Iprops)=>{

    const { data,income }:any = props

    const [ option, setOption ] = useState<any>({
        title: {
            zlevel: 0,
            text: [
                '{name|总人数}',
                '{value|0人}',
            ].join('\n'),
            rich: {
                name: {
                    color: '#242A31',
                    fontSize: 20,
                },
                value: {
                    fontSize: 26,
                    color: '#242A31',
                    fontWeight: 'bold',
                },
            },
            top: 'center',
            left: '24%',
            textAlign: 'center',
            textStyle: {
                rich: {
                    name: {
                        color: '#242A31',
                        fontSize: 20,
                        lineHeight: 40,
                    },
                    value: {
                        fontSize: 26,
                        color: '#242A31',
                        fontWeight: 'bold',
                    },
                },
            },
        },       
        tooltip: {
            show:false,
            trigger: 'item',
            formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        // grid:{
        //     left:'-50%'
        // },
        legend: {
            orient: 'vertical',
            right: '15%',
            top: '18%',
            icon: 'circle',
            itemGap: 30,
            // data: ["忠实客户", "消费人数", "就餐人数", "充值人数", "成功邀请人数"],
            data: ["忠实客户", "消费人数", "就餐人数", "充值人数"],
            textStyle: { //图例文字的样式
                color: '#8D8D8D',
                fontSize: 15,
            }
        },
        width: '100%',
        height: '100%',
        color: ['#7B72FF','#00149E','#FF5FFC','#22E930','#FFB25B'],
        series: [
            {
                name: '',
                type: 'pie',
                radius: ['45%', '75%'],
                left: '-25%',
                label: {
                    formatter: '{b|{b}}\n{per|{c}人}',
                    backgroundColor: '#fff',
                    borderColor: '#fff',
                    //borderWidth: 1,
                    borderRadius: 10,
                    width:50,
                    height:33,
                    shadowBlur:1,
                    //shadowOffsetX: 2,
                    // shadowOffsetY: 2,
                    shadowColor: '#ccc',
                    // padding: [0, 7],
                    verticalAlign: 'middle',
                    align:'center',
                    fontSize: 10,
                    padding:[3,-60],
                    center: ['50%','50%'],
                    fontWeight: 600,
                    //lineHeight:10,
                    rich: {
                        a: {
                            color: 'red',
                            lineHeight: 22,
                            align: 'center',
                        },
                        b: {
                            fontWeight: 600,
                            align:'center',
                            fontSize: 10,
                            lineHeight:20,
                        },
                        per: {
                            align:'center',
                            fontWeight: 600,
                             fontSize: 10,
                        }
                    },
                },
                data: [
                    {name: "忠实客户", value: "0"},
                    {name: "消费人数", value: "0"},
                    {name: "就餐人数", value: "0"},
                    {name: "充值人数", value: "0"},
                    // {name: "成功邀请人数", value: "17.02"},
                ]
            }
        ],
        // graphic:[
        //     {
        //         type: 'text',
        //         left: 'center',
        //     }
        // ],
    });
           

    useEffect(()=>{
        if(data){
            //初始化数据
            let optionTemp:any = JSON.parse(JSON.stringify(option))
            // optionTemp.series[0].name = title
            optionTemp.title.text = [
                '{name|总人数}',
                '{value|'+data.total_uses?data.total_uses:0+'人}',
            ].join('\n')
            // optionTemp.graphic[1].style.text = target?Math.round(value/target*100)+'%':0+'%'
            optionTemp.series[0].data =[
                {name: "忠实客户", value: data.chart_loyer_user},
                {name: "消费人数", value: data.chart_consumer_user},
                {name: "就餐人数", value: data.chart_eat_user},
                {name: "充值人数", value: data.chart_rechagre_counts},
                // {name: "成功邀请人数", value: data.chart_invited_user},
            ]
            setOption(optionTemp)
        }
    },[data])

    return (
        <div className="user_detail_pie_chart_wrap">
            <div className="pie_chart_title">消费行为</div>
            <div className="pie_chart">
                <ReactEcharts option={option} style={{height:241}} className='react_for_echarts' />
            </div> 
        </div>
    )
}

export default UserDetailPieChart



