import React from 'react';
import { Row, Col, Button, Input, Select, DatePicker, Table, Modal, Spin, message } from 'antd';
import { Link } from 'react-router-dom';
import { postRequest } from '../../api/http';
import { apiUrl } from '../../api/api_url';
import moment from 'moment';

const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

class UserGroup extends React.Component{
    columns = [
        {
            title: '分群名称',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: '用户',
            dataIndex: 'member_num',
            key: 'member_num',
            sorter: true,
        },
        {
            title: '忠实用户(人)',
            dataIndex: 'zhongshi_num',
            key: 'zhongshi_num',
            sorter: true,
        },
        {
            title: '消费人数',
            dataIndex: 'consumer_member_num',
            key: 'consumer_member_num',
            sorter: true,
        },
        {
            title: '实收金额',
            dataIndex: 'actual_fee',
            key: 'actual_fee',
            sorter: true,
        },
        {
            title: '订单占比',
            dataIndex: 'order_rate',
            key: 'order_rate',
            sorter: true,
        },
        {
            title: '充值(次)',
            dataIndex: 'recharge_num',
            key: 'recharge_num',
            sorter: true,
        },
        {
            title: '累计充值(元)',
            dataIndex: 'recharge_fee',
            key: 'recharge_fee',
            sorter: true,
        },
        {
            title: '成功邀请(人)',
            dataIndex: 'invite_num',
            key: 'invite_num',
            sorter: true,
        },
        {
            title: '操作时间',
            dataIndex: 'udpate_time',
            key: 'udpate_time'
        },
        {
            title: '操作者',
            dataIndex: 'udpate_people',
            key: 'udpate_people'
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            render: (text: string, record: any) => {
                return <div style={{display:'flex'}}>
                    <Link to={{pathname: "/sidebar/userGroupAdd",state: {id: record.id, name: record.name}}}>
                        <div className='btn_green mr30'>编辑</div>
                    </Link>
                    <div className='btn_green mr30' onClick={() => this.deleteList(record)}>删除</div>
                    <Link to={{pathname: "/sidebar/userGroupDetail",state: {id: record.id, name: record.name}}}>
                        <div className='btn_green'>详情</div>
                    </Link>
                </div>
            }
        },
    ]

    state = {
        loading: false,
        list: [],
        userList:[],
        shop_user_id: 0,
        sum:{
            member_num: 0, //总用户数
            actual_fee: 0, //总实收金额
            consumer_member_num: 0, //总消费人数
            recharge_fee: 0, //总充值金额
            order_rate: 0, //总订单占比
            invite_num: 0 //总成功邀请人数
        },
        name: '',
        page: 1,
        pageSize: 10,
        timeList: [],
        last_comsumer_time_start: '',
        last_comsumer_time_end: '',
        online_time: '',
        group_count: 0,
        order_field: '',
        order_by: ''
    }

    componentDidMount(){
        this._initList()
        this._initUser()

    }

    _initList = async () => {
        let { shop_user_id, name, page, pageSize, last_comsumer_time_start, last_comsumer_time_end, order_by, order_field } = this.state;
        let param:any = {
            current: page,
            pageSize
        }
        if(name){
            param.name = name;
        }
        if(shop_user_id != 0){
            param.shop_user_id = shop_user_id
        }

        if(last_comsumer_time_start){
            param.statistical_time_start = last_comsumer_time_start;
            param.statistical_time_end = last_comsumer_time_end;
        }

        if(order_field){
            param.order_field = order_field;
        }

        if(order_by){
            param.order_by = order_by;
        }
        const res:any = await postRequest(apiUrl.usergroupList, param)
        if(res.code == 1){
            this.setState({
                list: res.data.group_list,
                sum: res.data.sum,
                online_time: res.data.online_time,
                group_count: res.data.group_count,
                loading: false,
                last_comsumer_time_start: res.data.sum.statistical_time_start,
                last_comsumer_time_end: res.data.sum.statistical_time_end
            }, () => {
                this._initTime()
            })
        }
    }

    _initUser = async () => {
        const res:any = await postRequest(apiUrl.shopUserList)
        if(res.code == 1){
            this.setState({
                userList: res.data
            })
        }
    }

    formatData = (num: any) => {
        return num > 9 ? num : `0${num}`
    }

    _initTime = () => {
        let nowDate = new Date();
        let toDay = `${nowDate.getFullYear()}-${this.formatData(nowDate.getMonth() + 1)}-${this.formatData(nowDate.getDate())}`;
        // 上线至今
        let initDay = this.state.online_time;
        // 本周
        let week = nowDate.getDay()
        let weekDate = nowDate.getTime() - ((week - 1) * 1000 * 60 * 60 * 24)
        let nowWeek = `${new Date(weekDate).getFullYear()}-${this.formatData(new Date(weekDate).getMonth() + 1)}-${this.formatData(new Date(weekDate).getDate())}`;
        // 本月
        let nowMonth = `${nowDate.getFullYear()}-${this.formatData(nowDate.getMonth() + 1)}-01`;
        // 本季度
        let startDay = '';
        var currMonth = nowDate.getMonth() + 1;
        var currQuarter = Math.floor( ( currMonth % 3 == 0 ? ( currMonth / 3 ) : ( currMonth / 3 + 1 ) ) );
        if(currQuarter == 1){
            startDay = nowDate.getFullYear() + '-01-01'
        }else if(currQuarter == 2){
            startDay = nowDate.getFullYear() + '-04-01'
        }else if(currQuarter == 3){
            startDay = nowDate.getFullYear() + '-07-01'
        }else if(currQuarter == 4){
            startDay = nowDate.getFullYear() + '-10-01'
        }
        // 近三个月
        let three = nowDate.getTime() - (90 * 1000 * 60 * 60 * 24)
        let threeMonth = `${new Date(three).getFullYear()}-${this.formatData(new Date(three).getMonth() + 1)}-${this.formatData(new Date(three).getDate())}`;

        let timeList = [
            {
                title: '上线至今',
                time: [initDay, toDay]
            },
            {
                title: '本日',
                time: [toDay, toDay]
            },
            {
                title: '本周',
                time: [nowWeek, toDay]
            },
            {
                title: '本月',
                time: [nowMonth, toDay]
            },
            {
                title: '本季度',
                time: [startDay, toDay]
            },
            {
                title: '近三个月',
                time: [threeMonth, toDay]
            }
        ]

        this.setState({
            timeList
        })
    }

    inputChange = (e:any) => {
        this.setState({
            name: e,
            page: 1
        }, () => {
            this._initList()
        })
    }

    selectChange = (e: any) => {
        this.setState({
            shop_user_id: e,
            page: 1
        },() => {
            this._initList()
        })
    }

    timeselectChange = (e:any) => {
        let start = e.split(',')[0]
        let end = e.split(',')[1]
        this.setState({
            last_comsumer_time_start: start,
            last_comsumer_time_end: end,
            loading: true,
            page: 1
        }, async () => {
            let param:any = {
                statistical_time_start: start,
                statistical_time_end: end
            }
            let res: any = await postRequest(apiUrl.usergroupListFresh, param)
            if(res.code == 1){
                this._initList()
            }
        })
    }

    timeChange = (e: any, time: [string, string]) => {
        this.setState({
            last_comsumer_time_start: time[0],
            last_comsumer_time_end: time[1],
            loading: true,
            page: 1
        }, async () => {
            let param:any = {
                statistical_time_start: time[0],
                statistical_time_end: time[1]
            }
            let res: any = await postRequest(apiUrl.usergroupListFresh, param)
            if(res.code == 1){
                this._initList()
            }
        })
    }


    deleteList = (record: any) => {
        Modal.confirm({
            title: '删除',
            content: '确定删除该分群？删除后，将解散该分群的用户',
            onOk: async () => {
                let res: any = await postRequest(apiUrl.groupDelete, {group_id: record.id})
                if(res.code == 1){
                    message.success("删除成功")
                    this._initList()
                }
            }
        })
    }

    tableChange = (page: any, filters:any, sort: any) => {
        console.log(sort)
        if(sort.order){
            this.setState({
                order_field: sort.field,
                order_by: sort.order == 'ascend' ? 'ASC' : 'DESC',
                page: page.current
            }, () => {
                this._initList()
            })
        }else{
            this.setState({
                order_field: '',
                order_by: '',
                page: page.current
            }, () => {
                this._initList()
            })
        }
    }

    render(){
        let { userList,sum, list, timeList, group_count, last_comsumer_time_start, last_comsumer_time_end, loading } = this.state;
        return<div className='l_content'>
            <Row>
                <Col>
                    <Link to={'/sidebar/userGroupAdd'}>
                        <Button icon='plus' type='primary' >添加用户分群</Button>
                    </Link>
                </Col>
            </Row>

            <Row type="flex" align="middle" justify="start" className="mt20" style={{marginBottom: 20}}>
                <Col className='mr30'>
                    用户昵称：<Search placeholder='请输入分群名称' style={{ width: 234 }} onSearch={this.inputChange} />
                </Col>
                <Col className='mr30'>
                    操作者：<Select style={{width: 150}} placeholder='请选择操作者' onChange={this.selectChange}>
                        {
                            userList.map((item: any) => {
                                return <Option key={item.id}>{item.name}</Option>
                            })
                        }
                    </Select>
                </Col>
                <Col className='mr30'>
                    统计时间：<Select style={{width: 150, marginRight: 20}} onChange={this.timeselectChange} placeholder='请选择时间'>
                        {
                            timeList.map((item: any) => {
                                return <Option key={item.time}>{item.title}</Option>
                            })
                        }
                    </Select>
                    <RangePicker format="YYYY-MM-DD" placeholder={["开始时间","结束时间"]} value={last_comsumer_time_start ? [moment(last_comsumer_time_start, 'YYYY-MM-DD'),  moment(last_comsumer_time_end, 'YYYY-MM-DD')]: [null,null]} onChange={this.timeChange} />
                </Col>
            </Row>

            <Row style={{fontWeight: 'bold', fontSize: 18, marginBottom: 20}} type="flex" align="middle">
                <Col style={{marginRight: 20}}>总用户数： {sum.member_num}人</Col>
                <Col style={{marginRight: 20}}>总实收金额： {sum.actual_fee}元</Col>
                <Col style={{marginRight: 20}}>总消费人数： {sum.consumer_member_num}人</Col>
                <Col style={{marginRight: 20}}>总充值金额： {sum.recharge_fee}元</Col>
                <Col style={{marginRight: 20}}>总订单占比： {sum.order_rate}%</Col>
                <Col style={{marginRight: 20}}>总成功邀请： {sum.invite_num}人</Col>
            </Row>

            <Spin tip="正在统计..." spinning={this.state.loading}>
                <Table
                    columns={this.columns}
                    key={'name'}
                    dataSource={list}
                    onChange={(page, filters, sort) => this.tableChange(page, filters, sort)}
                    pagination={{
                        total: group_count,
                        pageSize: this.state.pageSize,
                        showQuickJumper: true,
                        showSizeChanger: true,
                        // onChange: (page: number) => {console.log(page);this.setState({page,}, () => {this._initList()})},
                        showTotal: (total: number) => `共${total}条`,
                        onShowSizeChange: (current:number, size: number) => {this.setState({pageSize: size, page: 1},() => {this._initList()})}
                    }}
                />
            </Spin>
        </div>
    }
}

export default UserGroup;