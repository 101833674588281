import React from 'react';
import { Row, Col } from 'antd';
import styles from '../management.module.scss';

import winAddSorteList from '../../../../../../assret/images/winAddSorteList.png';
import winAddSorteEdit from '../../../../../../assret/images/winAddAdEdit.png';

interface ShopManagementDetail {
    history: any,
}

class ShopManagementDetail extends React.Component<ShopManagementDetail> {

    render() {
        return  <Row className="typeDetail">
                    <Col className="typeDetail-title">一、功能介绍</Col>
                    <Col className="typeDetail-col">门店列表里可查看各个门店，可以对各个门店进行相应的配置、门店状态的启/停用。</Col>
                    <Col className="typeDetail-title">二、功能路径</Col>
                    <Col className="typeDetail-col">电脑端登录后台-门店管理-门店列表-添加门店。</Col>
                    <Col className="typeDetail-col">
                        <img src={winAddSorteList} />
                    </Col>
                    <Col className="typeDetail-title">三、操作流程</Col>
                    <Col className="typeDetail-col">1、<span className="txt-bold">添加门店</span>，在“门店列表”界面下点击“添加”设置地区、详细地址、位置标记、门店名称、联系电话、营业时间</Col>
                    <Col className="typeDetail-col">
                        <img src={winAddSorteEdit} />
                    </Col>
                    <Col className="typeDetail-col">点击“保存”，完成门店添加。返回门店列表页，在“操作”类目中可以进行操作</Col>
                </Row>
    }
}

export default ShopManagementDetail;