import React, { useState } from 'react'
import { Select, Input } from 'antd'
import FinanceOtherSetMeal from './setMeal/index'
import FinanceOtherCoupon from './coupon/index'

import './index.scss'

const FinanceOtherIndex = (props:any)=>{

    const [ currentTab, setCurrentTab ] = useState(0)

    const onChangeTabs = (index:number) => {
        setCurrentTab(index)
    }

    return (
        <div className='finance_other_index'>
            <div className="inside_nav">
                <div className="nav_list">
                        <div className={`nav_body ${currentTab === 0 ? 'nav_body_active': ''}`} onClick={() => onChangeTabs(0)}>
                            <div
                                className={` 
                                    nav_item 
                                    ${currentTab === 0 ? 'item_active': 'item'}
                                    ${currentTab - 1 == 0 ? 'left_ratius': ''}
                                    ${currentTab + 1 == 0 ? 'right_ratius': ''}
                                    `}
                                >
                                第三方套餐
                            </div>
                        </div>            
                        <div className={`nav_body ${currentTab === 1 ? 'nav_body_active': ''}`} onClick={() => onChangeTabs(1)}>
                            <div
                                className={` 
                                    nav_item 
                                    ${currentTab === 1 ? 'item_active': 'item'}
                                    ${currentTab - 1 == 1 ? 'left_ratius': ''}
                                    ${currentTab + 1 == 1 ? 'right_ratius': ''}
                                    `}
                                >
                                第三方代金券
                            </div>
                        </div>       
                    </div>
                </div>      
            <div className="body">
                {
                    currentTab === 0 ? (
                        <FinanceOtherSetMeal />
                    ) : (
                        <FinanceOtherCoupon />
                    )
                }
            </div>
        </div>
    )
}

export default FinanceOtherIndex
