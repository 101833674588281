import React, { useState, useEffect, useLayoutEffect } from "react";
import {Drawer,Button,Input,Form,Switch,Radio,AutoComplete,Icon} from 'antd'

import useForm from 'rc-form-hooks'
import {codeData} from './dataType'
import {createQRCode} from './dataType'
import {createQRCodeRequest,searchOpenid,updateQRCodeRequest} from './request'
import "./qRCode.scss"

function CreateQRCode (props:createQRCode) {
    
    const { getFieldDecorator, validateFields, errors, values,resetFields,setFieldsValue,getFieldValue } = useForm<codeData>();
    const [nicknameSource,setNicknameSource] = useState([{nickname:'',openid:''}])
    const [data,setData] = useState(props.data)
    const [type,setType] = useState(props.data && props.data.type ? props.data.type : 1)

    useEffect(()=>{
        setType(data && data.type ? data.type : 1)
        resetFields()
        // resetFields()
    },[data])

    useLayoutEffect(()=> {
        setData(props.data)
        // setType(data && data.type ? data.type : 1)
    })


    const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 4 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 20 },
        },
    };

    const handleSubmit = (e:any) => {
        e.preventDefault();
        validateFields().then(
            (e) => {
                console.log(e)
                if(props.data !== undefined && props.data.name != undefined){
                    //编辑 
                    updateQRCodeRequest(e).then((res) =>{
                        if(res.code === 1){
                            props.setVisible();
                        }
                    })
                }else{
                    //添加
                    createQRCodeRequest(e).then((res) =>{
                        if(res.code === 1){
                            props.setVisible();

                        }
                    })
                }
            }
        )
        .catch(e => console.error(e.message));
    }

    
    const nicknameSearch = (e:any) => {
        console.log('sss',e)
        if(e.length > 0){
            searchOpenid({nickname:e}).then((res) =>{
                console.log(res)
                if(res.data.length > 0){
                    setNicknameSource(res.data)
                }
            })
        }
    }

    const nicknameSelect = (e:any) => {
        setFieldsValue({openid:e})
        nicknameSource.map((a)=>{
            if(a.openid === e){
                setFieldsValue({nickname:a.nickname})
            }
        })
        // console.log(getFieldValue("nickname"))
        
    }
    const { Option } = AutoComplete;

    function nicknameView (item:any){
        return (
            // <span><img src={item.headimgurl} width={60} height={60} /></span>
            <Option key={item.nickname} className="show-all" title={item.nickname} value={item.openid}>
                <span>
                    <img src={item.headimgurl} width={60} height={60} />
                </span>
                {item.nickname}
            </Option>
        )
    }
    return (
        <div>
            <Drawer
                title={props.data !== undefined && props.data.name != undefined ? '编辑' : '添加'}
                placement="right"
                closable={false}
                onClose={()=>{
                    props.setVisible();
                    resetFields();
                    setType(1)
                }}
                visible={props.visible}
                width={900}
            >
                <Form {...formItemLayout} className="login-form" onSubmit={handleSubmit}>
                    {
                        props.data !== undefined && props.data.name != undefined ?
                        <div className="none">
                            <Form.Item label="id">
                                {getFieldDecorator('id', {
                                    initialValue: data !== undefined && data.id ? data.id : 1,
                                    rules: [{ required: true, message: '填写openid!' }],
                                })(<Input placeholder="填写openid" />)}
                            </Form.Item>
                        </div>
                        :
                        <div></div>
                    }
                    
                    
                    <Form.Item label="名称">
                        {   
                            getFieldDecorator('name', {
                                initialValue: data !== undefined ? data.name : '',
                                rules: [{ required: true, message: '填写跳转二维码名称!' }]
                            })(<Input placeholder="填写跳转二维码名称"  />)
                        }
                    </Form.Item>
                    <Form.Item label="推送二维码url">
                        {getFieldDecorator('third_url', {
                            initialValue: data !== undefined ? data.third_url : '',
                            rules: [{ required: true, message: '填写推送二维码url!' }]
                        })(<Input placeholder="填写推送二维码url" />)}
                    </Form.Item>
                    <Form.Item label="备注">
                        {getFieldDecorator('desc', {
                            initialValue: data !== undefined ? data.desc : '',
                            rules: [{ required: true, message: '添加备注!' }]
                        })(<Input placeholder="添加备注" />)}
                    </Form.Item>
                    <Form.Item label="是否启用">
                        {getFieldDecorator('is_use', {
                            initialValue: data && data.is_use ? data.is_use : 1,
                            rules: [{ required: true, message: '设置是否启用!' }]
                        })(<Radio.Group >
                            <Radio value={1}>启用</Radio>
                            <Radio value={2}>不启用</Radio>
                          </Radio.Group>)}
                    </Form.Item>

                    <Form.Item label="二维码类型">
                        {getFieldDecorator('type', {
                            initialValue: data !== undefined && data.type ? data.type : 1,
                            rules: [{ required: true, message: '选择类型!' }],
                        })(<Radio.Group onChange={(e)=>{
                            setType(e.target.value)
                            setFieldsValue({type:e.target.value})
                        }}>
                            <Radio value={1}>公众号推广</Radio>
                            <Radio value={2}>增粉计划</Radio>
                          </Radio.Group>)}
                    </Form.Item>

                    {
                        type === 2 ?
                        <div>
                            <Form.Item label="微信昵称">
                                {getFieldDecorator('nickname', {
                                    initialValue: data && data.nickname ? data.nickname : '',
                                    rules: [{ required: true, message: '填写微信昵称' }],
                                })(<AutoComplete
                                    dataSource={nicknameSource.map(nicknameView)}
                                    style={{ width: 400 }}
                                    onSelect={nicknameSelect}
                                    onSearch={nicknameSearch}
                                    placeholder="input here"
                                    optionLabelProp="title"
                                >
                                    <Input
                                        suffix={
                                        <Button
                                            className="search-btn"
                                            style={{ marginRight: -12 }}
                                            size="large"
                                            type="primary"
                                        >
                                            <Icon type="search" />
                                        </Button>
                                        }
                                    />
                                </AutoComplete>)}
                            </Form.Item>
                            <Form.Item label="商家收款人姓名">
                                {getFieldDecorator('true_name', {
                                    initialValue: data !== undefined && data.true_name ? data.true_name : '',
                                    rules: [{ required: true, message: '填写商家收款人姓名!' }],
                                })(<Input placeholder="填写商家收款人姓名" />)}
                            </Form.Item>
                            <Form.Item label="收款人手机号码">
                                {getFieldDecorator('mobile', {
                                    initialValue: data !== undefined && data.mobile ? data.mobile : '',
                                    rules: [{ required: true, message: '填写收款人手机号码!' }],
                                })(<Input placeholder="填写收款人手机号码" />)}
                            </Form.Item>
                            <Form.Item label="返现金额">
                                {getFieldDecorator('cashback_amount', {
                                    initialValue: data !== undefined && data.cashback_amount ? data.cashback_amount : '',
                                    rules: [{ required: true, message: '填写返现金额!' }],
                                })(<Input placeholder="填写返现金额" />)}
                            </Form.Item>
                            <Form.Item label="红包金额">
                                {getFieldDecorator('packet_fee', {
                                    initialValue: data !== undefined && data.packet_fee ? data.packet_fee : '',
                                    rules: [{ required: true, message: '填写红包金额，最大不超过10块!' }],
                                })(<Input placeholder="填写红包金额" />)}
                            </Form.Item>
                            <Form.Item label="每天领取次数">
                                {getFieldDecorator('get_limit', {
                                    initialValue: data !== undefined && data.get_limit ? data.get_limit : '',
                                    rules: [{ required: true, message: '填写每天领取次数!' }],
                                })(<Input placeholder="填写每天领取次数" />)}
                            </Form.Item>
                            <Form.Item label="有效时长(分钟)">
                                {getFieldDecorator('effective_time', {
                                    initialValue: data !== undefined && data.effective_time ? data.effective_time : '',
                                    rules: [{ required: true, message: '填写有效时长!' }],
                                })(<Input placeholder="填写有效时长" />)}
                            </Form.Item>
                        </div>
                        : <div></div>
                    }

                    
                    {
                        type === 2 ?
                        <div className="none">
                        <Form.Item label="商家收款微信openid">
                            {getFieldDecorator('openid', {
                                initialValue: data !== undefined && data.openid ? data.openid : 1,
                                rules: [{ required: true, message: '填写openid!' }],
                            })(<Input placeholder="填写openid" />)}
                        </Form.Item>
                        
                        
                        </div>
                        : <div></div>
                    }







                    <Button type="primary" htmlType="submit"  className="login-form-button" >
                        提交
                    </Button>
                </Form>
            </Drawer>
        </div>
    )
}

export default CreateQRCode
