import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'mobx-react';
import { stores } from './stores/index.store';
import { Button, notification, ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';

const buildType = process.env.REACT_APP_ChromeApp;

const openNotification = () => {
  const key = `open${Date.now()}`;
  const btn = (
    <>
      <Button
        type="primary"
        size="small"
        onClick={() => {updateServiceWorker(); notification.close(key)}}
      >
        更新使用(获取)
      </Button>
    </>
  );
  notification.open({
    message: '版本更新',
    duration: null,
    description:'发现新的版本是否立即更新使用？',
    btn, key, onClose: close,
  });
};

const updateServiceWorker = () => {
  const _serviceWorkerRegistration = stores.appStore.serviceWorkerStatus.serviceWorkerRegistration;
  if(_serviceWorkerRegistration) {
    const registrationWaiting = _serviceWorkerRegistration.waiting;
    if (registrationWaiting) {
      // registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
      registrationWaiting.addEventListener('statechange', (e: any) => {
        if (e.target.state === 'activated') {
          window.location.reload();
        }
      });
    }
  }
};

// 关闭更新通知
const close = () => {};

ReactDOM.render(
  <ConfigProvider locale={zhCN}>
  <Provider {...stores}>
    <App />
  </Provider>
  </ConfigProvider>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
(buildType === "1") && serviceWorker.register({
  onSuccess: () => {
    stores.appStore.setServiceWorker("success");
  },
  onUpdate: (registration) => {
    console.log('更新了', registration);
    stores.appStore.setServiceWorkerRegistration(registration);
    // setTimeout(() => openNotification(), 100);
  }
});
