import React from "react";
import { Button, Row, Col, Input, DatePicker, Table, Modal, Select, message, Icon, Popconfirm, Tooltip, Upload, Radio } from "antd";
import {upload} from 'qiniu-js'
import ShopSortLeft from './shopSort/shopSortLeft';
import ShopSortRight from './shopSort/shopSortRight';
import { postRequest, getRequest } from '../../api/http';
import { apiUrl } from '../../api/api_url';
import "./goods.scss";
import { RangePickerValue } from "antd/lib/date-picker/interface";
import btn_add from '../../assret/images/btn_add.png'
import t_edit from '../../assret/images/t_edit.png'
import t_delete from '../../assret/images/t_delete.png'
import goodsExport from '../../assret/images/goodsExport.png'
import goodsImport from '../../assret/images/goodsImport.png'
import goodsSort from '../../assret/images/goodsSort.png'
import close from '../../assret/images/close.png'
import goodPhoto1 from '../../assret/images/goodPhoto1.png'
import goodPhoto2 from '../../assret/images/goodPhoto2.png'
import goodPhoto3 from '../../assret/images/goodPhoto3.png'
import goodPhoto4 from '../../assret/images/goodPhoto4.png'
import goodPhoto5 from '../../assret/images/goodPhoto5.png'
import { Link } from "react-router-dom";
import { getIsPermisson, messageCustom } from "../../utils/permisstionUtil";

const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

const key = 'Goods';

let $child_list: any = {};
let $child_input: any = [];
let $child_list_value: any = [];
let upload_img_list: any = [];
interface GoodsProps {
    history: any,
}
class Goods extends React.Component<GoodsProps> {
    state = {
        is_up: null,
        order_by: undefined,
        cat_id: 0,
        sortList: [],
        name: '',
        visible: false,
        productCount: 0,
        productList: [],
        currentPage: 1,
        pageSize: 10,
        searchName: '',
        timeArray: [],
        modelTitle: '商品排序',
        modelContent: '删除',
        currentRecord: {
            id: 0,
            img: '',
            name: '',
            price: 0,
            time: '',
        },
        sortVisible: false,
        selectedRowKeys: [],
        shopCatId: 0,
        orderValue: undefined,
        store_export: false,
        goods_store_active: 1,
        store_export_file: false,
        store_export_img: false,
        goods_file_active: 0,
        goods_img_active: 1,
        updateObj: {
            key: '',
            tokens: '',
            region: '',
            'x:type': ''
        },
        previewTitle: [],
        previewImage: [],
        file_image_list: [],
        previewVisible: false,
        previewImageNum: -1,
        upload_dish: {
            // status: true,
            // file: ''
        },
        successCount: 0, //文件上传成功
        allcount: 0, //总上传个数
        successCountImg: 0, //图片上传成功
        allcountImg: 0, //图片总上传个数
        statusCount: false,
        statusCountImg: false,
        file_data: '', //文件流
        shop_id: '', //店铺id
        img_status_done: false, //是否上传完成
        is_open_default: 0, //0全部，1必点，2不必点
        is_discount: 0, //0全部，1不打折，2打折
        is_batch_print: false, //选择打印机弹窗
        printList: [], //打印机列表
        dep_id: 0, //选中的打印机id
        showStyleModal: false,  //批量设置展示方式
        display_mode: 1, //展示方式
        goodPhotoOne: 0,
    }

    columns = [
        {
            title: '商品编号',
            dataIndex: 'serial_number',
            key: 'serial_number',
            className: 'list_center',
            // sorter: true,
            // editable: true,
            // render: (text: string, record: any) => {
            //     return <div>
            //         <span>{record.order_by}</span>
            //         <Popconfirm
            //             icon={<Icon type="edit" />}
            //             okText='保存'
            //             cancelText='取消'
            //             title={<div>
            //                 <Input onChange={(e) => { this.onEdit(e) }} value={this.state.orderValue} type='number'></Input>
            //             </div>
            //             }
            //             onConfirm={() => this.handleSave(record.id)}>
            //             <Icon type="edit" style={{ marginLeft: 5 }} />
            //         </Popconfirm>
            //     </div>
            // }
        },
        {
            title: '商品名称',
            dataIndex: 'name',
            key: 'name',
            className: 'list_center',
        },
        {
            title: '商品分类',
            dataIndex: 'cat_name',
            key: 'cat_name',
            className: 'list_center',
        },
        {
            title: '展示方式',
            dataIndex: 'show_type',
            key: 'show_type',
            className: 'list_center',
            render: (text: string, record:any) => {
                return <div className="">
                    {
                        record.show_type == 1 
                        ?   '横图展示'
                        :   ''
                    }
                    {
                        record.show_type == 2
                        ?   '竖图展示'
                        :   ''
                    }
                    {
                        record.show_type == 3
                        ?   '大图展示'
                        :   ''
                    }
                    {
                        record.show_type == 4
                        ?   '横小图展示'
                        :   ''
                    }
                    {
                        record.show_type == 5
                        ?   '竖小图展示'
                        :   ''
                    }
                </div>
            }
        },
        {
            title: '商品价格',
            dataIndex: 'price',
            key: 'price',
            className: 'list_center',
        },
        // {
        //     title: '会员价格',
        //     dataIndex: 'price_vip',
        //     key: 'price_vip',
        //     className: 'list_center',
        //     render: (text: string, record: any) => {
        //         return <div className="">{record.price_vip !== null ? record.price_vip : "--"}</div>
        //     }
        // },
        {
            title: '商品状态',
            dataIndex: 'food_status',
            key: 'food_status',
            className: 'list_center',
            // render: (text: string, record:any) => {
            //     return <div className="">{record.food_status === 1 ? "上架中" : record.food_status === 2 ? '今日沽清' : '长期沽清' }</div>
            // }
        },
        {
            title: '开桌默点',
            dataIndex: 'is_must_eat',
            key: 'is_must_eat',
            className: 'list_center',
            render: (text: string, record:any) => {
                return <div className="">{record.is_must_eat ? "是" : '否' }</div>
            }
        },
        {
            title: '参与打折',
            dataIndex: 'is_no_discount',
            key: 'is_no_discount',
            className: 'list_center',
            render: (text: string, record:any) => {
                return <div className="">{!record.is_no_discount ? "是" : '否' }</div>
            }
        },
        {
            title: '打印部门',
            dataIndex: 'dep_name',
            key: 'dep_name',
            className: 'list_center',
        },
        // {
        //     title: '商品库存',
        //     dataIndex: 'residue',
        //     key: 'residue',
        //     className: 'list_center',
        //     // render: (text: string, record:any) => {
        //     //     return <div className="">{record.is_up ? "上架中" : "下架中"}</div>
        //     // }
        // },
        {
            title: '创建时间',
            dataIndex: 'created_at',
            key: 'created_at',
            className: 'list_center',
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            className: 'list_center',
            render: (text: any, record: any,) => (
                <div className="btn_wrap">
                    <Tooltip placement="bottomRight" title={'编辑'}>
                        <img src= {t_edit} className='table_icon' onClick={()=>{
                            if(!getIsPermisson('2_2_1_8')){
                                messageCustom();
                                return;
                            }
                            this.props.history.push('/sidebar/GoodsEdit',{id: record.id, type: record.type })
                        }}></img>
                    </Tooltip>
                    <Tooltip placement="bottomRight" title={'删除'}>
                        <img src={t_delete} className='table_icon' onClick={() => {
                            if(!getIsPermisson('2_2_1_7')){
                                messageCustom();
                                return;
                            }
                            this.deleteList(record)
                        }}></img>
                    </Tooltip>
                    {/* <Link className="list_btn" to={{ pathname: '/sidebar/GoodsEdit', state: { id: record.id, type: record.type } }}>编辑</Link> */}
                    {/* <div className="list_btn" onClick={() => this.stockBtn(record)}>{record.is_stock_over ? '设为售卖' : '设为沽清'}</div> */}
                    {/* <div className="list_btn" onClick={() => this.defaultList(record)}>{record.is_must_eat ? '不' : ''}设为开台默点</div>
                    <div className="list_btn" onClick={() => this.noDiscountList(record)}>设为{record.is_no_discount ? '打折' : '不打折'}</div> */}
                    {/* <div className="list_btn" onClick={() => this.deleteList(record)}>删除</div> */}
                </div>
            ),
        },
    ];

    handleSave = async (id: number) => {
        let orderValue = this.state.orderValue;
        if (orderValue === undefined) {
            return;
        }
        let params = {
            dish_id: id,
            order_id: orderValue
        }
        let res: any = await getRequest(apiUrl.goodSortChange, params)
        if (res.code === 1) {
            message.success('更新成功');
            this.onInitCount();
            this._initList();
        } else {
            message.error(res.message)
        }
    }

    onEdit = (e: any) => {
        this.setState({
            orderValue: e.target.value
        })
    }

    componentDidMount() {
        this.onInitCount();
        this._initList();
        this.onInitSort();
        //获取shop_id
        this.setState({
            shop_id: localStorage.getItem('shop_id')
        })
    }

    // 初始化列表总数
    onInitCount = async () => {
        const { timeArray, currentPage, is_up, cat_id, searchName, is_open_default, is_discount } = this.state;
        const params: any = {
            current: currentPage
        };
        if (searchName !== '') {
            params.name = searchName;
        }

        if (cat_id !== 0) {
            params.cat_id = cat_id;
        }

        if (is_up !== null) {
            params.up_status = is_up;
        }

        if (is_open_default !== null) {
            params.is_must_eat = is_open_default;
        }

        if (is_discount !== null) {
            params.is_no_discount = is_discount;
        }

        if (timeArray.length > 0) {
            params.created_at_start = timeArray[0];
            params.created_at_end = timeArray[1];
        }
        const res: any = await getRequest(apiUrl.goodCount, params);
        if (res.code === 1) {
            this.setState({
                productCount: res.data.num
            })
        }
    }

    // 分类列表
    onInitSort = async () => {
        const params: any = {
            pageSize: 200
        };
        const res: any = await getRequest(apiUrl.goodSort, params);
        if (res.code === 1) {
            // res.data.unshift(
            //     {
            //         id: 0,
            //         name: "全部",
            //         order_by: 0,
            //         dishes_num: 0
            //     }
            // );
            this.setState({
                sortList: res.data,
            })
        }
    }

    _initList = async () => {

        const { pageSize, timeArray, currentPage, is_up, cat_id, searchName, order_by, is_discount, is_open_default } = this.state;
        const params: any = {
            current: currentPage,
            pageSize: pageSize
        };
        if (searchName !== '') {
            params.name = searchName;
        }

        if (cat_id !== 0) {
            params.cat_id = cat_id;
        }

        if (is_up !== null) {
            params.up_status = is_up;
        }

        if (is_open_default !== null) {
            params.is_must_eat = is_open_default;
        }

        if (is_discount !== null) {
            params.is_no_discount = is_discount;
        }

        if (timeArray.length > 0) {
            params.created_at_start = timeArray[0];
            params.created_at_end = timeArray[1];
        }

        if (order_by !== undefined) {
            params.order_by = order_by === 'ascend' ? 'asc' : 'desc';
        }

        const res: any = await getRequest(apiUrl.goodList, params);
        if (res.code === 1) {
            this.onInitCount();
            this.setState({
                productList: res.data,
            })
        }
    }

    searchAction = async (name: string) => {
        this.setState({
            searchName: name,
            currentPage: 1
        }, () => {
            this._initList()
        })
    }

    // 分类选择
    sortChange = async (cat_id: any) => {
        this.setState({
            cat_id: cat_id,
            currentPage: 1
        }, () => {
            this._initList()
        })
    }

    // 状态选择
    typeChange = async (is_up: any) => {
        this.setState({
            is_up,
            currentPage: 1
        }, () => {
            this._initList()
        })
    }

    //是否开台默点
    onIsOpenDefault = (e: any) => {
        this.setState({
            is_open_default: e,
            currentPage: 1
        }, () => {
            this._initList()
        })
    }

    //是否参与打折
    onIsdiscount = (e: any) => {
        console.log('e:',e)
        this.setState({
            is_discount: e,
            currentPage: 1
        }, () => {
            this._initList()
        })
    }

    timeChange = async (time: RangePickerValue, timeString: [string, string]) => {
        this.setState({
            timeArray: timeString,
            currentPage: 1
        }, () => {
            this._initList()
        })
    }

    // 排序
    orderChange = async (pagination: any, filter: any, sorter: any) => {
        this.setState({
            order_by: sorter.order,
            currentPage: pagination.current
        }, () => {
            this._initList()
        })
    }

    // 设为售罄或售卖
    stockBtn = async (record: any) => {
        const params: any = {
            id: record.id
        };
        const res: any = await postRequest(apiUrl.goodStockOver, params);
        if (res.code === 1) {
            message.success({ content: '设置成功!', key, duration: 2 });
            this.onInitCount();
            this._initList();
        } else {
            message.error({ content: '设置失败!', key, duration: 2 });
        }
    }

    // 设为开台默认
    defaultList = async (record: any) => {
        Modal.confirm({
            title: '提示',
            content: `此项是否${record.is_must_eat ? '不' : ''}设置为开台默点`,
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                let res: any = await postRequest(apiUrl.goodEat, { id: record.id })
                if (res.code === 1) {
                    message.success({ content: '设置成功!', key, duration: 2 });
                    this.onInitCount();
                    this._initList();
                }
            },
            onCancel() {
            },
        });
    }

    // 设为不打折
    noDiscountList = async (record: any) => {
        Modal.confirm({
            title: '提示',
            content: `此项是否设置为${record.is_no_discount ? '打折' : '不打折'}`,
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                let res: any = await postRequest(apiUrl.goodDiscount, { id: record.id })
                if (res.code === 1) {
                    message.success({ content: '设置成功!', key, duration: 2 });
                    this.onInitCount();
                    this._initList();
                }
            },
            onCancel() {
            },
        });
    }

    // 删除列表
    deleteList = (record: any) => {
        Modal.confirm({
            title: '删除商品',
            content: `确认删除商品「${record.name}」？`,
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                const { productList, productCount } = this.state;
                const params: any = {
                    id: record.id
                };
                const res: any = await postRequest(apiUrl.goodDelete, params);
                if (res.code === 1) {
                    message.success({ content: '删除成功!', key, duration: 2 });
                    const newList = productList.filter((item: any) => item.id !== record.id);
                    this.setState({
                        productList: newList
                    })
                    if (productCount === 11) {
                        this.onInitCount();
                    }
                } else {
                    message.success({ content: '删除失败!', key, duration: 2 });
                }
            },
            onCancel() {
            },
        });
    }

    //展示方式
    goodPhotoChange = (e: any) => {
        this.setState({
            display_mode: e
        })
    }

    // 弹窗确认
    handleOk = () => {

    };

    //批量设置弹窗确认
    showStyleOK = async ()=> {
        if(this.state.display_mode == 0){
            return message.warn({ content: '请选择展示方式!', key, duration: 2 });
        }
        let param = {
            ids: this.state.selectedRowKeys,
            show_type: this.state.display_mode
        }

        let res: any = await postRequest(apiUrl.DishesShowTypeSetAll, param)

        if( res.code === 1){
            message.success({ content: res.message || '设置成功!', key, duration: 2 });
            this.onInitCount();
            this._initList();
            setTimeout(() => {
                this.setState({
                    showStyleModal: false,
                    selectedRowKeys: [],
                }, () => {
                    //window.location.reload();
                })
            },1000)
            
            
        }else {
            message.success({ content: '设置失败!', key, duration: 2 });
            this.setState({
                showStyleModal: false
            })
        }

    }

    //打印机弹窗确认
    printHandleOk = async () => {
        if(this.state.dep_id == 0) {
            return message.warn({ content: '请选择打印机部门!', key, duration: 2 });
        }
        let param = {
            dishes: this.state.selectedRowKeys,
            dep_id: this.state.dep_id
        }
        if(this.state.selectedRowKeys.length == 0) {
            return message.warn({ content: '请选择商品！', key, duration: 2 })
        }
        Modal.confirm({
            title: '提示',
            content: `是否绑定？`,
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                let res: any = await postRequest(apiUrl.bindPrintDep, param)
                if (res.code === 1) {
                    message.success({ content: '设置成功!', key, duration: 2 });
                    this.onInitCount();
                    this._initList();
                    setTimeout(() => {
                        this.setState({
                            is_batch_print: false,
                            selectedRowKeys: [],
                        }, () => {
                            //window.location.reload();
                        })
                    },1000)
                } else {
                    message.success({ content: '设置失败!', key, duration: 2 });
                }
            },
        });
    }

    // 关闭弹窗
    handleCancel = () => {
        this.setState(() => {
            return {
                sortVisible: false
            }
        })
    };

    handleSortOk = () => {
        console.log('child_list_value:ok:',$child_list_value)
        this.onSortTable($child_input)
        this.onSortDishesTable($child_list_value)
    }

    //商品排序
    shopSort() {
        if(!getIsPermisson('2_2_1_2')){
            messageCustom();
            return;
          }
        this.setState(() => {
            return {
                sortVisible: true
            }
        })
    }

    //批量必点
    async onBatchMust(type: any) {
        if(!getIsPermisson('2_2_1_5')){
            messageCustom();
            return;
          }
        if(this.state.selectedRowKeys.length == 0) {
            return message.warn({ content: '请选择商品！', key, duration: 2 })
        }

        if(type == 'silent') {
            Modal.confirm({
                title: '批量商品',
                content: `确认开台默点选中商品？`,
                okText: '确认',
                cancelText: '取消',
                onOk: async () => {
                    let param = {
                        id: this.state.selectedRowKeys,
                        is_must_eat: true,
                    }
                    let res: any = await postRequest(apiUrl.goodEat, param)
                    if (res.code === 1) {
                        message.success({ content: '设置成功!', key, duration: 2 });
                        this.onInitCount();
                        this._initList();
                        setTimeout(() => {
                            this.setState({
                                selectedRowKeys: [],
                            }, () => {
                                //window.location.reload();
                            })
                        },1000)
                    }
                },
                onCancel() {
                },
            });
        } else {
            Modal.confirm({
                title: '批量商品',
                content: `确认取消开台默点选中商品？`,
                okText: '确认',
                cancelText: '取消',
                onOk: async () => {
                    let param = {
                        id: this.state.selectedRowKeys,
                        is_must_eat: false,
                    }
                    let res: any = await postRequest(apiUrl.goodEat, param)
                    if (res.code === 1) {
                        message.success({ content: '设置成功!', key, duration: 2 });
                        this.onInitCount();
                        this._initList();
                        setTimeout(() => {
                            this.setState({
                                selectedRowKeys: [],
                            }, () => {
                                //window.location.reload();
                            })
                        },1000)
                    }
                },
                onCancel() {
                },
            });
        }
    }

    async onShowStyle(){
        if(!getIsPermisson('2_2_1_6')){
            messageCustom();
            return;
          }
        if(this.state.selectedRowKeys.length == 0) {
            return message.warn({ content: '请选择商品！', key, duration: 2 })
        }
        this.setState({showStyleModal: true})

        
    }

    //批量打折
    async onDiscountMust(type: any) {
        if(!getIsPermisson('2_2_1_6')){
            messageCustom();
            return;
          }
        if(this.state.selectedRowKeys.length == 0) {
            return message.warn({ content: '请选择商品！', key, duration: 2 })
        }

        if(type == 'silent') {
            Modal.confirm({
                title: '批量商品',
                content: `确认批量打折选中商品？`,
                okText: '确认',
                cancelText: '取消',
                onOk: async () => {
                    let param = {
                        id: this.state.selectedRowKeys,
                        is_no_discount: true,
                    }
                    let res: any = await postRequest(apiUrl.goodDiscount, param)
                    if (res.code === 1) {
                        message.success({ content: '设置成功!', key, duration: 2 });
                        this.onInitCount();
                        this._initList();
                        setTimeout(() => {
                            this.setState({
                                selectedRowKeys: [],
                            }, () => {
                                //window.location.reload();
                            })
                        },1000)
                    }
                },
                onCancel() {
                },
            });
        } else {
            Modal.confirm({
                title: '批量商品',
                content: `确认取消批量打折选中商品？`,
                okText: '确认',
                cancelText: '取消',
                onOk: async () => {
                    let param = {
                        id: this.state.selectedRowKeys,
                        is_no_discount: false,
                    }
                    let res: any = await postRequest(apiUrl.goodDiscount, param)
                    if (res.code === 1) {
                        message.success({ content: '设置成功!', key, duration: 2 });
                        this.onInitCount();
                        this._initList();
                        setTimeout(() => {
                            this.setState({
                                selectedRowKeys: [],
                            }, () => {
                                //window.location.reload();
                            })
                        },1000)
                    }
                },
                onCancel() {
                },
            });
        }
    }

    //批量删除
    async onDeleteMust() {
        if(!getIsPermisson('2_2_1_7')){
            messageCustom();
            return;
          }
        if(this.state.selectedRowKeys.length == 0) {
            return message.warn({ content: '请选择商品！', key, duration: 2 })
        }

        Modal.confirm({
            title: '提示',
            content: `确认删除当前所选商品？`,
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                let param = {
                    id: this.state.selectedRowKeys,
                }
                let res: any = await postRequest(apiUrl.goodDelete, param)
                if (res.code === 1) {
                    message.success({ content: '删除成功!', key, duration: 2 });
                    this.onInitCount();
                    this._initList();
                    setTimeout(() => {
                        this.setState({
                            selectedRowKeys: [],
                        }, () => {
                            //window.location.reload();
                        })
                    },1000)
                }
            },
        });
    }

    //批量绑定打印机
    async onBindPrint(type: any) {
        if(!getIsPermisson('2_2_1_4')){
            messageCustom();
            return;
          }
        if(!this.state.selectedRowKeys) {
            console.log(this.state.selectedRowKeys)
            return;
        }

        if(type == 'silent') {
            this.printerList()
            this.setState({
                is_batch_print: true,
            })
        } else {
            if(this.state.selectedRowKeys.length == 0) {
                return message.warn({ content: '请选择商品！', key, duration: 2 })
            }
            let param = {
                dishes: this.state.selectedRowKeys,
                dep_id: 0
            }
            Modal.confirm({
                title: '提示',
                content: `是否取消绑定？`,
                okText: '确认',
                cancelText: '取消',
                onOk: async () => {
                    let res: any = await postRequest(apiUrl.bindPrintDep, param)
                    if (res.code === 1) {
                        message.success({ content: '设置成功!', key, duration: 2 });
                        this.onInitCount();
                        this._initList();
                        setTimeout(() => {
                            this.setState({
                                is_batch_print: false,
                                selectedRowKeys: [],
                            }, () => {
                                //window.location.reload();
                            })
                        },1000)
                        // this.setState({
                        //     is_batch_print: false,
                        //     selectedRowKeys: [],
                        // })
                    } else {
                        message.error({ content: '设置失败!', key, duration: 2 });
                    }
                },
            });
        }

    }

    // 打印机部门修改
    printerChange = (e: any) => {
        console.log('print:',e)
        this.setState({
            dep_id: e || 0,
        })
    }

    // 打印部门列表
    printerList = async () => {
        const res:any = await getRequest(apiUrl.printDepartList);
        if(res.code === 1){
            this.setState({
                printList: res.data,
            })
        }
    }

    // rowSelection: any = {
    //     onChange: (selectedRowKeys: any, selectedRows: any) => {
    //         this.setState({
    //             selectedRowKeys
    //         })
    //         //this.onBatchMust(selectedRowKeys)
    //         console.log('selectedRowKeys:',selectedRowKeys)
    //         console.log('selectedRows:',selectedRows)
    //     },
    //     selectedRowKeys: this.state.selectedRowKeys,
    // };

    onCatIdParam(id: any) {
        this.setState(() => {
            return {
                shopCatId: id
            }
        })
    }

    //分类排序接口
    onSortTable = async (arrayList: any) => {
        let count = arrayList.length;
        let temp = arrayList.map((item: any, index: any) => {
            let obj: any = {};
            obj.id = item.id;
            obj.order_by = count - index;
            return (
                obj
            )
        })
        //console.log(temp)
        const res:any = await getRequest(apiUrl.setCatSort,{arr:JSON.stringify(temp)});
        if(res.code === 1){
            this.setState(() => {
                return {
                    sortVisible: false
                }
            })
            //this.onMenuList()
        }
    }

    //商品排序接口
    onSortDishesTable = async (arrayList: any) => {
        let count = arrayList.length;
        let temp = arrayList.map((item: any, index: any) => {
            let obj: any = {};
            obj.id = item.id;
            obj.order_by = count - index;
            return (
                obj
            )
        })
        //console.log(temp)
        const res:any = await getRequest(apiUrl.setDishesSort,{arr:JSON.stringify(temp)});
        if(res.code === 1){
            this.setState(() => {
                return {
                    sortVisible: false
                }
            })
            //this.onMenuList()
        }
    }

    onGoPage = (url: any) => {
        this.props.history.push(url);
    }

    //商品导入弹窗
    onStoreExport() {
        this.setState({
            store_export: !this.state.store_export
        })
    }

    //打开导入商品弹窗
    onStoreExportFile() {
        this.setState({
            store_export_file: !this.state.store_export_file
        })
    }

    //打开导入图片弹窗
    onStoreExportImg() {
        this.setState({
            store_export_img: !this.state.store_export_img
        })
    }

    //选择导入图片or文件
    onExpotrFileORImg(num: any) {
        this.setState({
            goods_store_active: num
        })
    }

    //导入商品选择。是否
    onExporeFileStore(num: any) {
        this.setState({
            goods_file_active: num
        })
    }

    //上传文件或者图片控件
    onUpload() {
        const { goods_store_active } = this.state;
        if(goods_store_active == 1) {
            this.onStoreExport()
            this.onStoreExportFile()
        } else {
            this.onStoreExport()
            this.onStoreExportImg()
        }
    }

    // 获取图片token
    beforeUploadImg = async (file:any) => {
        upload_img_list.push(file)
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
            this.setState({
                loading: false,
            }, () => {
                message.error(file.name + "图片大小超出限制，请修改后重新上传");
                return;
            })
        } else {
            const res:any = await getRequest(apiUrl.getUploadToken, { type: 'food' });
            if(res && res.data) {
                console.log('beforeUploadImg:',file)
                this.setState({
                    updateObj: { token: res.data.upload_token, region: `https://up-z2.qiniu.com`, key: `${this.state.shop_id}/food/${file.name}`, 'x:type': "food" },
                    img_status_done: false
                })
            }
        }
    }

    // 批量上传图片
    handleChangeImg = ( info:any ) => {
        this.setState({
            previewImage: info.fileList
        })
        if (info.file.status === 'done') {
            console.log(this.state.previewImage)
            this.setState({
                img_status_done: true
            })
        }
    }

    getBase64 = (file: any) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    //上传图片到后台
    async onUploadImg() {
        message.loading({ content: '上传中', key });
        console.log('img_status_done:',this.state.img_status_done)
        if(this.state.img_status_done) {
            let _list: any = [];
            let previewImage = this.state.previewImage;
            let obj: any = {};
            try {
                for(let i = 0; i < previewImage.length; i++) {
                    let _key: any = (previewImage[i]['name']);
                    let _split_key: any = _key.split('.')[0];
                    obj[_split_key] = previewImage[i]['response']['key'];
                    //_list.push(obj)
                }
                const res: any = await postRequest(apiUrl.uploadImg, {arr: obj, status: this.state.goods_file_active});
                if(res.code == 1) {
                    this.setState({
                        allcountImg: res.data.count,
                        successCountImg: res.data.successCount,
                        statusCountImg: true,
                    }, () => {
                        message.success({ content: '上传成功!', key, duration: 2 });
                    })
                } else {
                    message.error({ content: '上传失败，请重试', key, duration: 2 });
                }
            } catch (error) {
                this.onUpload()
            }
        }
        // for(let i = 0;i< upload_img_list.length;i++){
        //     const res:any = await getRequest(apiUrl.getUploadToken, { type: 'food' });
        //     let token = res.data.upload_token;
        //     console.log('token:',token)
        //     let imageKey = this.getQiniuKey("IMGS");
        //     console.log('imageKey:',imageKey)
        //     let observer = {
        //         complete: (res: any) => {
        //             console.log('上传成功',res)
        //             // let contentT = contentTemp
        //             // contentT[contentT.length -1].value[i] = FILE_HOST+res.key
        //             // setContent(JSON.parse(JSON.stringify(contentT)))
        //             // props.handleCallBack(JSON.parse(JSON.stringify(contentT)))
        //         },
        //         error: (error: any) =>  {
        //             message.error(error || '上传资源失败');
        //             return
        //         },
        //         next: (next: any,file: any) => {
        //             console.log('上传进度',next.total.percent)
        //             console.log('file',file)
        //             return
        //         }
        //     }
        //     this.qiniu_upload(upload_img_list[i],upload_img_list[i]['name'],token,observer,{customVars: { 'x:type': 'food'}})
        // }

    }

    //删除图片
    onDeleteUploadImg(idx:any) {
        let previewImage = this.state.previewImage;
        previewImage.splice(idx,1);
        this.setState({
            previewImage
        })
    }


    beforeUploadFile = (file: any) => {
        //console.log(file)
        this.setState({
            file_data: file
        })
        return false;
    }

    //上传文件
    customRequest = () => {
        message.loading({ content: '上传中', key });
        let formData = new FormData();
        let _this = this;
        let files: any = this.state.file_data;
        let status = this.state.goods_file_active;
        formData.append('file', files);
        formData.append('status', String(status));
        formData.append('shop_id', String(localStorage.getItem('shop_id')));

        let request: any = new XMLHttpRequest();
        request.open('POST', `${process.env.REACT_APP_BASEURL+'/'+apiUrl.uploadDish}?token=${localStorage.getItem('token')}`);
        request.send(formData);
        request.onload = function(e: any){
            if (request.status === 200) {
                // console.log(request)
                // console.log(_this.setState)
                let result = JSON.parse(request.response)
                _this.setState({
                    allcount: result.data.count,
                    successCount: result.data.successCount,
                    statusCount: true,
                }, () => {
                    _this._initList()
                })
                message.success({ content: '上传成功!', key, duration: 2 });
            }
            else{
                //alert('上传失败，请重试！');
                message.error({ content: '上传失败，请重试', key, duration: 2 });
            }
        }
        request.onerror = function(e: any){
            //alert('请求失败')
            message.error({ content: '请求失败，请重试', key, duration: 2 });
        }
        return {
            abort() {
              console.log('upload progress is aborted.');
            },
        };
    }

    //删除文件
    onRemove = (file: any) => {
        this.setState({
            statusCount: false
        })
    }

    // 获取key
    getQiniuKey = (type: any) =>{
        return `${Math.random().toString(36).slice(-8)}${new Date().valueOf()}.png`; // 生成随机key
    }

    //七牛上传图片
    /**
     *
     * @param {*} file 文件
     * @param {*} key 唯一key
     * @param {*} token 七牛token
     * @param {-} observer 上传监听 回调
     */
    qiniu_upload = (file: any, key: any, token: any, observer: any, putExtra={},) =>{
        const observable = upload(file,key,token,putExtra,observer)
        console.log('observer',observer)
        const subscription = observable.subscribe(observer)
    }

    //商品导出
    async onShopExport() {
        console.log(this.state.selectedRowKeys.length)
        if(this.state.selectedRowKeys.length <= 0) {
            message.warn({ content: '请选择导出商品!', key, duration: 2 })
            return false;
        }
        window.open(`${process.env.REACT_APP_BASEURL}${apiUrl.downloadDish}?arr=${this.state.selectedRowKeys}&token=${localStorage.getItem('token')}&shop_id=${localStorage.getItem('shop_id')}`)
    }

    //下载模版
    async onDownloadTemplate() {
        let res: any = await getRequest(apiUrl.get_import_url)
        if(res.code == 1) {
            window.open(`${res.data.url}`)
        } else {
            message.error('下载超时，稍后重试！')
        }
    }

    render() {
        const { printList, successCount, allcountImg, successCountImg, statusCountImg, allcount, statusCount, previewImageNum, previewImage, previewVisible, file_image_list, goods_img_active, goods_file_active, store_export_img, store_export_file, goods_store_active, store_export, is_up, sortList, productList, sortVisible,display_mode,goodPhotoOne } = this.state;
        const rowSelection: any = {
            onChange: (selectedRowKeys: any, selectedRows: any) => {
                this.setState({
                    selectedRowKeys
                })
            },
            selectedRowKeys: this.state.selectedRowKeys,
        };
        return (
            <div className='padding20'>
                <div className='goods_btn'>
                    <div className='new_btn mb30 margin_right_20' onClick={() => {
                        if(!getIsPermisson('2_2_1_1')){
                            messageCustom();
                            return;
                          }
                        this.onGoPage(`/sidebar/GoodsEdit`)}
                    }>
                        <img src={btn_add}></img>
                        添加商品
                    </div>
                    <div className='new_btn mb30 margin_right_20' onClick={() => {
                        if(!getIsPermisson('2_2_1_2')){
                            messageCustom();
                            return;
                          }
                        this.shopSort()}
                    }>
                        <img src={goodsSort}></img>
                        商品排序
                    </div>
                    <div className='new_btn mb30 margin_right_20' onClick={() => {
                        if(!getIsPermisson('2_2_1_3')){
                            messageCustom();
                            return;
                          }
                        this.onStoreExport()}
                    }>
                        <img src={goodsImport}></img>
                        商品导入
                    </div>
                    <div className='new_btn mb30 margin_right_20' onClick={() => {
                        if(!getIsPermisson('2_2_1_3')){
                            messageCustom();
                            return;
                          }
                        this.onShopExport()}
                    }>
                        <img src={goodsExport}></img>
                        商品导出
                    </div>
                </div>
                <div className='goods_main padding15 background_fff border_radius_10'>
                    <Row type="flex" align="middle" justify="start" className="mt20 mb30" >
                        <Col className="mr30">
                            <span>商品名称：</span>
                            <Search placeholder="请输入商品名称" onSearch={this.searchAction} style={{ width: 200 }} />
                        </Col>
                        <Col className="mr30">
                            <span>商品分类：</span>
                            <Select style={{ width: 150 }} onChange={this.sortChange} placeholder="请选择商品分类" allowClear>
                                {
                                    sortList.map((item: any) => {
                                        return <Option key={item.id} value={item.id} >{item.name}</Option>
                                    })
                                }
                            </Select>
                        </Col>
                        <Col className="mr30">
                            <span>商品状态：</span>
                            <Select style={{ width: 150 }} onChange={this.typeChange} placeholder="请选择商品状态" allowClear>
                                {/* <Option value="true">全部</Option> */}
                                <Option value="1">售卖中</Option>
                                <Option value="2">今日沽清</Option>
                                <Option value="3">长期沽清</Option>
                            </Select>
                        </Col>
                        <Col className="mr30">
                            <span>参与打折：</span>
                            <Select style={{ width: 150 }} onChange={this.onIsdiscount} placeholder="请选择参与打折" allowClear>
                                <Option value="2">是</Option>
                                <Option value="1">否</Option>
                            </Select>
                        </Col>
                        <Col className="mr30">
                            <span>开桌默点：</span>
                            <Select style={{ width: 150 }} onChange={this.onIsOpenDefault} placeholder="请选择开桌默点" allowClear>
                                <Option value="1">是</Option>
                                <Option value="2">否</Option>
                            </Select>
                        </Col>
                        <Col>
                            <span>起止时间：</span>
                            <RangePicker style={{width: 280}} onChange={this.timeChange} placeholder={["开始时间", "结束时间"]} format="YYYY-MM-DD HH:mm:ss" />
                        </Col>
                    </Row>
                    <Row className="goods_tip">
                        <Col className="goods_tip_list background_7480FD border_radius_18 margin_right_20" onClick={() => {this.onBindPrint('silent')}}>批量绑定打印部门</Col>
                        <Col className="goods_tip_list background_7480FD border_radius_18 margin_right_20" onClick={() => {this.onBindPrint('nosilent')}}>批量取消绑定打印部门</Col>
                        <Col className="goods_tip_list background_7480FD border_radius_18 margin_right_20" onClick={() => {this.onBatchMust('silent')}}>批量开台默点</Col>
                        <Col className="goods_tip_list background_7480FD border_radius_18 margin_right_20" onClick={() => {this.onBatchMust('nosilent')}}>批量取消开台默点</Col>
                        <Col className="goods_tip_list background_7480FD border_radius_18 margin_right_20" onClick={() => {this.onDiscountMust('silent')}}>批量打折</Col>
                        <Col className="goods_tip_list background_7480FD border_radius_18 margin_right_20" onClick={() => {this.onDiscountMust('nosilent')}}>批量取消打折</Col>
                        <Col className="goods_tip_list background_7480FD border_radius_18 margin_right_20" onClick={() => {this.onShowStyle()}}>批量设置展示方式</Col>
                        <Col className="goods_tip_list background_FF5166 border_radius_18 margin_right_20" onClick={() => {this.onDeleteMust()}}>批量删除</Col>
                    </Row>

                    <div>
                        <Table
                            rowSelection={rowSelection}
                            onChange={this.orderChange}
                            dataSource={productList}
                            columns={this.columns}
                            rowKey={"id"}
                            size="middle"
                            locale={{ emptyText: '暂无数据' }}
                            pagination={{
                                current: this.state.currentPage,
                                defaultCurrent: 1,
                                total: this.state.productCount,
                                pageSize: this.state.pageSize,
                                showQuickJumper: true,
                                showSizeChanger: true,
                                showTotal: (total: number) => `共${total}条`,
                                onShowSizeChange: (current: number, size: number) => { this.setState({ pageSize: size }, () => { this._initList(); }) }

                            }} />
                    </div>

                    {/* 商品导入 */}
                    {
                        store_export
                        ? <div className="goodsStore">
                            <div className="goodsStore-main">
                                <div className="goodsStore-main-title">
                                    导入商品
                                </div>
                                <div className="goodsStore-main-tip">
                                    <div className="goodsStore-main-tip-file" onClick={() => {this.onExpotrFileORImg(1)}} >
                                        <div className={goods_store_active == 2 ? 'goodsStore-main-tip-active goodsStore-main-tip-file-point' : 'goodsStore-main-tip-file-point'}></div>
                                        <div className="goodsStore-main-tip-file-store">普通商品导入</div>
                                    </div>
                                    <div className="goodsStore-main-tip-photo" onClick={() => {this.onExpotrFileORImg(2)}}>
                                        <div className={goods_store_active == 1 ? 'goodsStore-main-tip-active goodsStore-main-tip-file-point' : 'goodsStore-main-tip-file-point'}></div>
                                        <div className="goodsStore-main-tip-photo-store">图片批量导入</div>
                                    </div>
                                </div>
                                <div className="goodsStore-main-btn">
                                    <div className="goodsStore-main-btn-cancel" onClick={() => {this.onStoreExport()}}>取消</div>
                                    <div className="goodsStore-main-btn-up" onClick={() => {this.onUpload()}}>上传</div>
                                </div>
                                <div className="goodsStore-main-close" onClick={() => {this.onStoreExport()}}>
                                    <img src={close} alt=""/>
                                </div>
                            </div>
                        </div>
                        : ''
                    }

                    {/* 普通商品导入 */}
                    {
                        store_export_file
                        ? <div className="goodsStoreExport">
                            <div className="goodsStoreExport-main">
                                <div className="goodsStoreExport-main-title">
                                    普通商品导入
                                </div>
                                <div className="goodsStoreExport-main-tip">
                                   <div className="goodsStoreExport-main-tip-first">
                                        1.还没创建过导入数据文件，
                                        <span className="goodsStoreExport-main-tip-first-demo" onClick={() => {this.onDownloadTemplate()}}>下载模版</span>
                                   </div>
                                   <div className="goodsStoreExport-main-tip-two">
                                        <div className="goodsStoreExport-main-tip-two-txt">
                                            2.已创建好导入数据文件，直接导入：
                                        </div>
                                        <Upload
                                            beforeUpload={this.beforeUploadFile}
                                            onRemove={this.onRemove}
                                            // customRequest={this.customRequest}
                                        >
                                            <div className="goodsStoreExport-main-tip-two-main">
                                                <div className="goodsStoreExport-main-tip-two-main-left">
                                                    请选择导入的文件
                                                </div>
                                                <div className="goodsStoreExport-main-tip-two-main-right">
                                                    选择文件
                                                </div>
                                            </div>
                                        </Upload>
                                        {
                                            statusCount
                                            ?   <div>
                                                    <span>总上传个数：<span style={{color: '#FF5166'}}>{allcount}</span></span>，
                                                    <span>上传成功个数: <span style={{color: '#FF5166'}}>{successCount}</span></span>
                                                </div>
                                            : ''
                                        }
                                        <div className="goodsStoreExport-main-tip-two-rule">
                                            导入文件为.xls的excel文件
                                        </div>
                                   </div>
                                   <div className="goodsStoreExport-main-tip-status">
                                        <div className="goodsStoreExport-main-tip-status-left">
                                            已存在的商品是否更新 ：
                                        </div>
                                        <div className="goodsStoreExport-main-tip-status-right">
                                            <div className="goodsStoreExport-main-tip-status-right-f" onClick={() => {this.onExporeFileStore(0)}}>
                                                <div className={goods_file_active == 1 ? 'goodsStoreExport-main-tip-status-right-active goodsStoreExport-main-tip-status-right-f-point' : 'goodsStoreExport-main-tip-status-right-f-point'}></div>
                                                <div className="goodsStoreExport-main-tip-status-right-f-txt">否</div>
                                            </div>
                                            <div className="goodsStoreExport-main-tip-status-right-r" onClick={() => {this.onExporeFileStore(1)}}>
                                                <div className={goods_file_active == 0 ? 'goodsStoreExport-main-tip-status-right-active goodsStoreExport-main-tip-status-right-r-point' : 'goodsStoreExport-main-tip-status-right-r-point'}></div>
                                                <div className="goodsStoreExport-main-tip-status-right-r-txt">是</div>
                                            </div>
                                        </div>
                                   </div>
                                </div>
                                <div className="goodsStoreExport-main-btn">
                                    <div className="goodsStoreExport-main-btn-cancel" onClick={() => {this.onStoreExportFile()}}>取消</div>
                                    <div className="goodsStoreExport-main-btn-up" onClick={this.customRequest}>上传</div>
                                </div>
                                <div className="goodsStoreExport-main-close" onClick={() => {this.onStoreExportFile()}}>
                                    <img src={close} alt=""/>
                                </div>
                            </div>
                        </div>
                        : ''
                    }

                    {/* 图片批量导入 */}
                    {
                        store_export_img
                        ? <div className="goodsStoreExportImg">
                            <div className="goodsStoreExportImg-main">
                                <div className="goodsStoreExportImg-main-title">
                                    图片批量导入
                                </div>
                                <div className="goodsStoreExportImg-main-tip">
                                    <div className="goodsStoreExportImg-main-tip-imglist">
                                        {
                                            previewImage.length > 0
                                            ?   previewImage.map((item: any, index: any) => {
                                                    return (
                                                        <div onMouseEnter={() => {this.setState({previewImageNum: index})}} onMouseLeave ={() => {this.setState({previewImageNum: -1})}} key={item.uid} className="goodsStoreExportImg-main-tip-imglist-item">
                                                            {
                                                                item.status != "done"
                                                                ? '上传中'
                                                                : ''
                                                            }
                                                            {
                                                                item.response && <img src={item.response.url} style={{width: '170px', height: '170px'}} />
                                                            }
                                                            {
                                                                previewImageNum == index
                                                                ?   <div className="goodsStoreExportImg-main-tip-imglist-item-del">
                                                                        <Icon type="delete" style={{fontSize: '30px', cursor: 'pointer', color: '#fff'}} onClick={() => {this.onDeleteUploadImg(index)}} />
                                                                    </div>
                                                                : ''
                                                            }
                                                        </div>
                                                    )
                                                })
                                            : ''
                                        }
                                    </div>
                                    {
                                            statusCountImg
                                            ?   <div>
                                                    <span>总上传个数：<span style={{color: '#FF5166'}}>{allcountImg}</span></span>，
                                                    <span>上传成功个数: <span style={{color: '#FF5166'}}>{successCountImg}</span></span>
                                                </div>
                                            : ''
                                        }
                                    <div className="goodsStoreExportImg-main-tip-first">
                                            1.批量上传图片商品编号作为文件名,图片格式支持 .jpg/png,图片文件 大小不超过5M,最佳上传尺寸为558*308
                                    </div>
                                    <div style={{display: 'flex'}}>
                                        <div className="goodsStoreExportImg-main-tip-two">
                                                <Upload
                                                    action='https://up-z2.qiniup.com'
                                                    multiple={true}
                                                    beforeUpload={this.beforeUploadImg}
                                                    onChange={this.handleChangeImg}
                                                    data={this.state.updateObj}
                                                    showUploadList={false}
                                                >
                                                    <div className="goodsStoreExportImg-main-tip-two-main">

                                                            <div className="goodsStoreExportImg-main-tip-two-main-left">
                                                                请选择导入的文件
                                                            </div>
                                                            <div className="goodsStoreExportImg-main-tip-two-main-right">
                                                                选择文件
                                                            </div>
                                                    </div>
                                                </Upload>
                                        </div>
                                        <div className="goodsStoreExportImg-main-tip-status">
                                                <div className="goodsStoreExportImg-main-tip-status-left">
                                                    已存在的商品是否更新 ：
                                                </div>
                                                <div className="goodsStoreExportImg-main-tip-status-right">
                                                    <div className="goodsStoreExportImg-main-tip-status-right-f" onClick={() => {this.onExporeFileStore(0)}}>
                                                        <div className={goods_file_active == 1 ? 'goodsStoreExportImg-main-tip-status-right-active goodsStoreExportImg-main-tip-status-right-f-point' : 'goodsStoreExportImg-main-tip-status-right-f-point'}></div>
                                                        <div className="goodsStoreExportImg-main-tip-status-right-f-txt">否</div>
                                                    </div>
                                                    <div className="goodsStoreExportImg-main-tip-status-right-r" onClick={() => {this.onExporeFileStore(1)}}>
                                                        <div className={goods_file_active == 0 ? 'goodsStoreExportImg-main-tip-status-right-active goodsStoreExportImg-main-tip-status-right-r-point' : 'goodsStoreExportImg-main-tip-status-right-r-point'}></div>
                                                        <div className="goodsStoreExportImg-main-tip-status-right-r-txt">是</div>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="goodsStoreExportImg-main-btn">
                                    <div className="goodsStoreExportImg-main-btn-cancel" onClick={() => {this.onStoreExportImg()}}>取消</div>
                                    <div className="goodsStoreExportImg-main-btn-up" onClick={() => {this.onUploadImg()}}>上传</div>
                                </div>
                                <div className="goodsStoreExportImg-main-close" onClick={() => {this.onStoreExportImg()}}>
                                    <img src={close} alt=""/>
                                </div>
                            </div>
                        </div>
                        : ''
                    }

                    <Modal
                        title={this.state.modelTitle}
                        visible={this.state.visible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        centered
                        okText="确认"
                        cancelText="取消"
                    >
                        <p>{this.state.modelContent}</p>
                    </Modal>

                    <Modal
                        title={this.state.modelTitle}
                        visible={this.state.sortVisible}
                        onOk={this.handleSortOk}
                        onCancel={this.handleCancel}
                        centered
                        okText="确认"
                        cancelText="取消"
                        width='472px'
                        >
                            <div style={{display: 'flex',justifyContent: 'center'}}>
                                <ShopSortLeft inputEvent={(input: any) => $child_input = input} addEvent={(item: any) => $child_list.addRecord(item)}></ShopSortLeft>
                                <ShopSortRight listEventValue={(input: any) => $child_list_value = input} listEvent={(list: any) => {$child_list = list}}></ShopSortRight>
                            </div>
                    </Modal>

                    {/* 批量绑定打印机 */}
                    <Modal
                        title={'批量绑定打印机'}
                        visible={this.state.is_batch_print}
                        onOk={this.printHandleOk}
                        onCancel={() => {this.setState({is_batch_print: false})}}
                        centered
                        okText="确认"
                        cancelText="取消"
                    >
                        <div>
                            <Select allowClear={true} placeholder={'请选择打印机部门'} style={{ width: 200 }} onChange={this.printerChange} >
                                {
                                    printList.map((item: any) => {
                                        return <Option key={item.id} value={item.id}>{item.department_name}</Option>
                                    })
                                }
                            </Select>
                        </div>
                    </Modal>
                    {/* 批量设置展示方式 */}
                    <Modal
                        title={'批量设置展示方式'}
                        visible={this.state.showStyleModal}
                        onOk={this.showStyleOK}
                        onCancel={() => {this.setState({showStyleModal: false})}}
                        centered
                        okText="确认"
                        cancelText="取消"
                    >
                        <div>
                        <Radio.Group
                                        value={display_mode}
                                        style={{marginLeft: 20}}
                                        onChange={(e: any) => this.goodPhotoChange(e.target.value)}
                                    >
                                        <Radio style={{position: 'relative', marginRight: '30px'}} value={1}>横图展示
                                            <span onMouseEnter={() => {
                                                this.setState({goodPhotoOne: 1})
                                            }} onMouseLeave={() => {
                                                this.setState({goodPhotoOne: 0})
                                            }} style={{color: '#7480FE', marginLeft: '10px', cursor: 'pointer'}}>预览</span>
                                            <div style={{
                                                position: 'absolute',
                                                top: '-600px',
                                                left: '0%',
                                                backgroundColor: '#fff',
                                                zIndex: 9
                                            }}>
                                                {
                                                    goodPhotoOne == 1
                                                        ? <img src={goodPhoto1} style={{width: '290px', height: 'auto'}}/>
                                                        : ''
                                                }
                                            </div>
                                            {/* <span style={{
                                                position: 'absolute',
                                                bottom: '-20px',
                                                left: '25px',
                                                color: '#5F5F5F',
                                                fontSize: '12px'
                                            }}>尺寸558*308</span> */}
                                        </Radio>
                                        <Radio style={{marginRight: '30px'}} value={2}>竖图展示
                                            <span onMouseEnter={() => {
                                                this.setState({goodPhotoOne: 2})
                                            }} onMouseLeave={() => {
                                                this.setState({goodPhotoOne: 0})
                                            }} style={{color: '#7480FE', marginLeft: '10px', cursor: 'pointer'}}>预览</span>
                                            <div style={{
                                                position: 'absolute',
                                                top: '-600px',
                                                left: '0%',
                                                backgroundColor: '#fff',
                                                zIndex: 9
                                            }}>
                                                {
                                                    goodPhotoOne == 2
                                                        ? <img src={goodPhoto2} style={{width: '290px', height: 'auto'}}/>
                                                        : ''
                                                }
                                            </div>
                                            {/* <span style={{
                                                position: 'absolute',
                                                bottom: '-20px',
                                                left: '25px',
                                                color: '#5F5F5F',
                                                fontSize: '12px'
                                            }}>尺寸264*264</span> */}
                                        </Radio>
                                        <Radio style={{marginRight: '30px'}} value={3}>大图展示
                                            <span onMouseEnter={() => {
                                                this.setState({goodPhotoOne: 3})
                                            }} onMouseLeave={() => {
                                                this.setState({goodPhotoOne: 0})
                                            }} style={{color: '#7480FE', marginLeft: '10px', cursor: 'pointer'}}>预览</span>
                                            <div style={{
                                                position: 'absolute',
                                                top: '-600px',
                                                left: '0%',
                                                backgroundColor: '#fff',
                                                zIndex: 9
                                            }}>
                                                {
                                                    goodPhotoOne == 3
                                                        ? <img src={goodPhoto3} style={{width: '290px', height: 'auto'}}/>
                                                        : ''
                                                }
                                            </div>
                                            {/* <span style={{
                                                position: 'absolute',
                                                bottom: '-20px',
                                                left: '25px',
                                                color: '#5F5F5F',
                                                fontSize: '12px'
                                            }}>尺寸558*622</span> */}
                                        </Radio>
                                        <Radio style={{marginRight: '30px'}} value={4}>横小图展示
                                            <span onMouseEnter={() => {
                                                this.setState({goodPhotoOne: 4})
                                            }} onMouseLeave={() => {
                                                this.setState({goodPhotoOne: 0})
                                            }} style={{color: '#7480FE', marginLeft: '10px', cursor: 'pointer'}}>预览</span>
                                            <div style={{
                                                position: 'absolute',
                                                top: '-600px',
                                                left: '0%',
                                                backgroundColor: '#fff',
                                                zIndex: 9
                                            }}>
                                                {
                                                    goodPhotoOne == 4
                                                        ? <img src={goodPhoto4} style={{width: '290px', height: 'auto'}}/>
                                                        : ''
                                                }
                                            </div>
                                            {/* <span style={{
                                                position: 'absolute',
                                                bottom: '-20px',
                                                left: '25px',
                                                color: '#5F5F5F',
                                                fontSize: '12px'
                                            }}>尺寸188*186</span> */}
                                        </Radio>
                                        <Radio value={5}>竖小图展示
                                            <span onMouseEnter={() => {
                                                this.setState({goodPhotoOne: 5})
                                            }} onMouseLeave={() => {
                                                this.setState({goodPhotoOne: 0})
                                            }} style={{color: '#7480FE', marginLeft: '10px', cursor: 'pointer'}}>预览</span>
                                            <div style={{
                                                position: 'absolute',
                                                top: '-600px',
                                                left: '0%',
                                                backgroundColor: '#fff',
                                                zIndex: 9
                                            }}>
                                                {
                                                    goodPhotoOne == 5
                                                        ? <img src={goodPhoto5} style={{width: '290px', height: 'auto'}}/>
                                                        : ''
                                                }
                                            </div>
                                            {/* <span style={{
                                                position: 'absolute',
                                                bottom: '-20px',
                                                left: '25px',
                                                color: '#5F5F5F',
                                                fontSize: '12px'
                                            }}>尺寸188*186</span> */}
                                        </Radio>
                                    </Radio.Group>
                        </div>
                    </Modal>




                </div>
            </div>
        )
    }
}
export default Goods