import React, { useState, useEffect } from 'react'
import { Table, Button, Dropdown, Icon, Menu, Modal, Row, Col, message } from 'antd';
import DeleteActiveBtn from './delete_active';
import { IFormData } from '../create_active/create_active';
import { postRequest } from '../../../api/http';
import { apiUrl } from '../../../api/api_url';
import { Link } from 'react-router-dom';
const clipboard = require("clipboard-polyfill");
var QRCode = require('qrcode.react');

interface IProductList  {
  tableList: ItableItem
  statusList: Array<{text: string, value: number}>
}

interface ItableItem {
  total: number,
  items: Array<IFormData>
}

const TableList = (props: IProductList) => {
  const [showModal, setshowModal] = useState(false);
  const [promoteUrl, setpromoteUrl] = useState('');
  const [tabList, settabList] = useState<ItableItem>({total: 0, items: []});

  useEffect(() => {
    settabList(props.tableList);
  }, [props.tableList])

  const getActivityStatus = (statusNum: number) => {
    const el = props.statusList.find(el => el.value === statusNum);
    return el?.text;
  }

  const columns = [
    {
      title: '活动标题',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '商品名称',
      dataIndex: 'food',
      key: 'food',
      render: (rowdata:any) => {
        return (<div>
          {rowdata['name']}
        </div>)
      },
    },
    {
      title: '收益类型',
      dataIndex: 'profit_type',
      key: 'profit_type',
    },
    {
      title: '价格',
      dataIndex: 'address',
      key: 'price',
      render: (rowdata:any,) => {
        if(rowdata && rowdata.profit_type === 1) {
          return <div>
            {rowdata.profit_value_1}
          </div>
        }
        if(rowdata && rowdata.profit_type === 2) {
          return <div>{Number(rowdata.profit_value_1) * Number(rowdata.buy_price)}</div>
        }
        return <div></div>
      },
    },
    {
      title: '一级返现',
      dataIndex: 'profit_value_1',
      key: 'profit_value_1',
    },
    {
      title: '二级返现',
      dataIndex: 'profit_value_2',
      key: 'profit_value_2',
    },
    {
      title: '活动状态',
      dataIndex: 'status',
      key: 'status',
      render: (rowdata:any) => {
        return <div>{getActivityStatus(rowdata)}</div>
      },
    },
    {
      title: '活动开始时间',
      dataIndex: 'start_at',
      key: 'start_at',
    },
    {
      title: '浏览量',
      dataIndex: 'views',
      key: 'views',
    },
    {
      title: '销售',
      dataIndex: 'address',
      key: 'sale',
      render: () => (
        <div>销量</div>
      ),
    },
    {
      title: '操作',
      key: 'action',
      render: (rowdata:any, record: any, idx: number) => {
        return (<div>
          <Button type="link" onClick={() => tapPromote(rowdata)}>推广</Button>
          <Link to={{pathname: '/sidebar/create_active', state: rowdata}}>
            <Button type="link" onClick={() => editActicity(rowdata)}>编辑</Button>
          </Link>
          <Button type="link">复制</Button>
          <Dropdown overlay={
            <Menu>
              <Menu.Item key="1">
                <DeleteActiveBtn rowdata={rowdata} deleteIdx={idx} deleteFun={(i, data) => deleteTableRow(i, data)}/>
              </Menu.Item>
            </Menu>
          }>
            <Button type="link" className="ant-dropdown-link">
              更多 <Icon type="down" />
            </Button>
          </Dropdown>
        </div>);
      }
      
    },
  ];

  // 点击推广
  const tapPromote = (rowdata:any) => {
    setshowModal(true);
    setpromoteUrl(`${process.env.REACT_APP_BASEURL}/v1/jump/h5-go?jump_url=${encodeURI(`distribution_detail?activitityid=${rowdata['id']}`)}`);
  }

  const copyPromoteUrl = () => {
    clipboard.writeText(promoteUrl).then((e: any) =>{
      message.success('链接已复制')
    })
  }
  
  // 删除活动
  const deleteTableRow = async (idx: number, rowdata: any) => {
    const ret:any = await postRequest(apiUrl.deleteActicity, { ids: [rowdata.id] });
    if(ret && ret.data) {
      const _tabList = tabList;
      _tabList?.items?.splice(idx, 1);
      settabList({total: _tabList?.total -1, items: [..._tabList?.items]});
    }
  }

  // 编辑活动
  const editActicity = (data: any) => {
    // this.pro
  }

  return <React.Fragment>
    <Table dataSource={tabList.items}  size="middle" rowKey={'id'} columns={columns} pagination={{pageSize: 30, total: tabList.total}} />
    <Modal title="推广" visible={showModal} footer={null} onCancel={() => setshowModal(false)} width={700}>
      <Row>
        <Col >
          <Row type="flex" justify="center" onClick={() => copyPromoteUrl()}>
            <Button >{promoteUrl}</Button> <Button style={{marginLeft: 20}} type="primary">复制</Button>
          </Row>
        </Col>
        <Col >
          <Row type="flex" justify="center" style={{margin: '30px 0'}}>
             <QRCode value={promoteUrl} size={150} />
          </Row>
          <Row type="flex" justify="center">
            <p className="fz32">复制链接，分享给更多的人</p>
          </Row>
        </Col>
      </Row>
    </Modal>
  </React.Fragment>;
}

export default TableList