import React, { useEffect, useState } from 'react'
import { Button, Table, DatePicker, Tooltip } from 'antd'
import { useHistory, useLocation } from 'react-router-dom';
import { postRequest } from '../../../../../../../api/http'
import { apiUrl } from '../../../../../../../api/api_url'
import t_member from '../../../../../../../assret/images/t_member.png'
import t_manage from '../../../../../../../assret/images/t_manage.png'
import t_manage_g from '../../../../../../../assret/images/t_manage_g.png'
import t_data from '../../../../../../../assret/images/t_data.png'
import t_data_g from '../../../../../../../assret/images/t_data_g.png'
import './recommend.scss'

const { RangePicker } = DatePicker;

const SiyuGroupOfficialAccountDeftail = ()=>{
    
    const history = useHistory()
    const location:any = useLocation()
    const [data, setData] = useState([]);
    const [sysData, setSysData] = useState<any>([]);
    const [ rowSpans, setRowSpans ] = useState<any>([])
    const [paramsObj, setParamsObj] = useState<any>({
        current: 1,
        pageSize: 10,
    });

    useEffect(() => {
        if(location.state&&location.state.channel_id) paramsObj.channel_id = location.state.channel_id
        getGroupList({...paramsObj})
    }, [])

    const getGroupList = async (data:any = {}) => {
        let param = {
            ...data
        }
        if(param.order_by)param.order_by=param.order_by=='ascend'?'asc':'desc'
        Object.keys(param).map(el => {
            if(!param[el]) delete param[el]
        })
        setParamsObj({...param})
        param.type = 1
        const res:any = await postRequest(apiUrl.usergroupList, param);
        if(res && res['code'] === 1) {
            setSysData(res.data.group_list[0].items)
            res.data.group_list.splice(0,1)
            setData(dataInit(res.data.group_list))
            // setData(res.data.group_list);
        }
    }

    //数据源格式化成多条数据
    const dataInit = (data:any)=>{
        let dataArr:any = []
        let i = 0
        data.map((item:any,index:number)=>{
            if(item.items&&item.items.length>1){
                item.items.map((itemG:any,indexG:number)=>{
                    dataArr[i] = itemG
                    dataArr[i].label = item.label
                    dataArr[i].label_id = item.label_id
                    i++
                })
            }else{
                dataArr[i] = item.items[0]
                dataArr[i].label = item.label
                dataArr[i].label_id = item.label_id
                i++
            }
        })   
        setRowSpans(getRowSpans(dataArr, 'label_id'))
        return dataArr     
    }

    //获取合并行
    const getRowSpans = (arr:any, key:string) => {
        let sameValueLength = 0;
        const rowSpans = [];
        for(let i = arr.length - 1; i >= 0; i--){
            if(i === 0) {
                rowSpans[i] = sameValueLength + 1;
                continue;
            }
            if(arr[i][key] === arr[i-1][key]) {
                rowSpans[i] = 0;
                sameValueLength++;
            } else {
                rowSpans[i] = sameValueLength + 1;
                sameValueLength = 0;
            }
        }
        return rowSpans;
    };

    //表格值初始化
    const renderContent = ( value:any, _:any, index:number)=>{
        const obj:any = {
            children: value,
            props: {},
        };
        obj.props.rowSpan = rowSpans[index];
        return obj;
    }

    const creatTable = ()=>{
        const columns:any = [
            {
                title: '分组类型',
                dataIndex: 'label',
                render: renderContent     
            },
            {
                title: '分组名称',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '定义',
                dataIndex: 'remark',
                key: 'remark',
            },
            {
                title: '客户数量',
                dataIndex: 'member_num',
                key: 'member_num',
            },
            {
                title: '上月客户数量',
                dataIndex: 'last_month_member_num',
                key: 'last_month_member_num',
            },
            {
                title: '创建时间',
                dataIndex: 'created_at',
                key: 'created_at',
            },
            {
                title: '更新时间',
                dataIndex: 'udpate_time',
                key: 'udpate_time',
            },
            {
                title: '操作',
                dataIndex: 'option',
                key: 'option',
                render: ( text:string, record:any, index:number )=>
                    <>
                        <Tooltip placement="bottomRight" title={'查看人员'}>
                            <img src={t_member} className='table_icon' onClick={()=>{history.push('/sidebar/siyuOfficialMemberIndex',{group_id:record.id,title:record.name})}}></img>
                        </Tooltip>

                        <Tooltip placement="bottomRight" title={'去运营'}>
                            <img src={!record.operation_id ? t_manage : t_manage_g} className='table_icon' onClick={() => {if(!record.operation_id) history.push('/sidebar/marketingAdd',{group_id:record.id,name:record.name,is_siyu:true,is_disabled:true})}}></img>
                        </Tooltip>
                        <Tooltip placement="bottomRight" title={'查看数据'}>
                            <img src={!record.operation_id ? t_data_g : t_data} className='table_icon' onClick={() => record.operation_id&&history.push({pathname: '/sidebar/marketingDetail', state: { id: record.operation_id, name: record.name}})}></img>
                        </Tooltip>                    

                        {/* <Button type="link" onClick={()=>{history.push('/sidebar/siyuOfficialMemberIndex',{group_id:record.id,title:record.name})}}>查看人员</Button>
                        <Button type="link" onClick={() => history.push('/sidebar/marketingAdd',{group_id:record.id,name:record.name})} disabled={record.operation_id?true:false}>去运营</Button>
                        <Button type="link" className={!record.operation_id?'color999':''} onClick={() => record.operation_id&&history.push({pathname: '/sidebar/marketingDetail', state: { id: record.operation_id}})}>查看数据</Button> */}
                    </>
            },
        ];
        
        return <Table 
                    dataSource={data} 
                    columns={columns} 
                    rowKey={'id'}     
                    pagination={false}                
                />
    }

    const creatTableSys = ()=>{
        const columns:any = [
            {
                title: '分组名称',
                dataIndex: 'name',
                key: 'name',
                render: renderContent     
            },
            {
                title: '定义',
                dataIndex: 'remark',
                key: 'remark',
            },
            {
                title: '客户数量',
                dataIndex: 'member_num',
                key: 'member_num',
            },
            {
                title: '上月客户数量',
                dataIndex: 'last_month_member_num',
                key: 'last_month_member_num',
            },
            {
                title: '创建时间',
                dataIndex: 'created_at',
                key: 'created_at',
            },
            {
                title: '更新时间',
                dataIndex: 'udpate_time',
                key: 'udpate_time',
            },
            {
                title: '操作',
                dataIndex: 'option',
                key: 'option',
                render: ( text:string, record:any, index:number )=>
                    <Tooltip placement="bottomRight" title={'查看人员'}>
                        <img src={t_member} className='table_icon' onClick={()=>{history.push('/sidebar/siyuOfficialMemberIndex',{group_id:record.id,title:record.name})}}></img>
                    </Tooltip>
                    // <Button type="link" onClick={()=>{history.push('/sidebar/siyuOfficialMemberIndex',{group_id:record.id,title:record.name})}}>查看人员</Button>
            },
        ];
        
        return <Table 
                    dataSource={sysData} 
                    columns={columns} 
                    rowKey={'id'}     
                    pagination={false}                
                />
    }

    return(
        <div className="officail_account_recommend">
            {
                creatTableSys()
            }
            <div className="sub_title">非系统客户定义：近180天，未登录过系统用户</div>
            {
                creatTable()
            }
        </div>
    )
}

export default SiyuGroupOfficialAccountDeftail