import React from 'react';
import styles from './addMaterial.module.scss'
import { Input,Table,Tooltip,Modal, Button, message } from 'antd';
import {getRequest, postRequest, requestWithJson,getRequestWithoutShopId} from "../../../api/http";
import {apiUrl} from "../../../api/api_url";

import btn_add from '../../assret/images/btn_add.png'
import t_delete from '../../assret/images/t_delete.png'
import t_edit from '../../assret/images/t_edit.png'

const { Search } = Input;

// type IProps =  {
//     onUpdateMateriaList:any,
//     addVisible:any,
//     materialName:any,
//     materialPrice:any
// }

class AddMaterial extends React.Component<any> {
    state = {
        addVisibleChild:false, // 是否展示添加加料
        materialName:"", // 加料名称
        materialPrice:"", // 加料价格
    }
    componentDidMount(){
        const addVisibleChild = this.props.addVisibleChild
        this.setState({
            addVisibleChild
        })
    }
    // 添加加料模态框ok btn
    addHandleOk = async()=>{
        if(this.state.materialName == ""){
            message.error('名称不能为空');
        }
        // TODO 
        // 添加加料请求
        this.postShopMaterial();
        // 更新加料列表

        this.props.onUpdateaddVisible()
        // 更新当前页面数据
        this.props.onUpdateMateriaList()
        this.setState({
            addVisibleChild: false,
          });

    }

    // 添加加料
    postShopMaterial = async ()=>{
        // 加料名称
        const materialName = this.state.materialName
        const materialPrice = this.state.materialPrice
        const res:any = await postRequest(apiUrl.addMaterial,{"material_name":materialName,"material_price":materialPrice})
        if(res.code === 1){
            this.setState({
                materialName:"",
                materialPrice:"",
            })
           return res.data
        }else{
            message.error(res.message)
            return 
        }
    }

    // 添加加料模态框取消 btn
    AddhandleCancel = ()=>{
        // this.props.addVisible = false
        // 更改父组件addVisible的状态
        this.props.onUpdateaddVisible()
        // 更新当前页面数据
        this.props.onUpdateMateriaList()
        this.setState({
            addVisibleChild: false,
          });
    }

    // 加料名称改变
    materialChangeName= (e:any)=>{
        const materialName = e.target.value
        this.setState({
            materialName
          });
    }

    // 加料价格改变
    materialChangePrice= (e:any)=>{
        const materialPrice = e.target.value
        this.setState({
            materialPrice
          });
    }

    // 添加并继续
    AddhandleContinue = async()=>{
        if(this.state.materialName == ""){
            message.error('名称不能为空');
        }
        // 添加加料请求
        await this.postShopMaterial();
        // this.setState({
        //     addVisibleChild:true
        // })
    }

    render(){

        return  <div>
        <Modal
            title="添加加料"
            visible={this.state.addVisibleChild}
            onOk={this.addHandleOk}
            onCancel={this.AddhandleCancel}
            className={"material_list_modal"}
            footer={null}
            >
                <div className={styles.material_input}>
                    <div className={styles.material_input_title} >
                        加料名称：
                    </div>
                    <Input placeholder="请输入配料名称"  value={this.state.materialName} onChange={(e)=>{this.materialChangeName(e)}}/>
                </div>
                <div className={styles.material_input}>
                <div className={styles.material_input_title} >
                    售价：
                    </div>
                    <Input placeholder="请输入配料售价" value={this.state.materialPrice} type="number" onChange={(e)=>{this.materialChangePrice(e)}}/>
                </div>
                <div className={styles.input_btn}>
                    <div className={styles.input_btn_save} onClick={this.addHandleOk}>
                        <Button type="primary">保存</Button>
                    </div>
                    <div className={styles.input_btn_save_con} onClick={this.AddhandleContinue} >
                        <Button type="primary">保存并继续</Button>
                    </div>
                    <div className={styles.input_btn_cancel} onClick={this.AddhandleCancel}>
                        <Button >取消</Button>
                    </div>
                </div>
        </Modal>
    </div>
        
    }
}

export default AddMaterial