import React from "react"
import styles from './decorationHeader.module.scss'
import { inject, observer } from "mobx-react"
import shopDecorationStore from '../../../../stores/shopDecoration.store'
import { getRequest, postRequest } from '../../../../api/http'
import { apiUrl } from '../../../../api/api_url'
import { message, Table, Icon, Tooltip, Upload } from 'antd'

interface IProps {
    dataObj: {
        left: Array<{
            type: number,
            txt: string
        }>,
        right: Array<{
            type: number,
            txt: string
        }>
    },
    history?: any
}

interface IState {

}

@observer
class DecorationHeader extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            
        }
    }

    //点击事件
    onClickSubmit = async (type: any) => {
        const { merber_info, merber_interests, merber_assets, merber_menu, id } = shopDecorationStore.shop_decoration_wode;
        switch (type) {
            case '保存':
                let params = {
                    id,
                    background_image: merber_info.background,
                    profile_photo_position: merber_info.photo_position,
                    menu_type: merber_menu.module_style,
                    vip_rights: merber_interests,
                    user_property: merber_assets,
                    num: merber_menu.module_column,
                    default: merber_info.default,
                    apply: 0,
                    list: merber_menu.module_list,
                }
                let res: any = await postRequest(apiUrl.editProfile, params)
                if(res.code === 1) {
                    message.success('操作成功！')
                } else {
                    message.error(res.message)
                }
                // if(merber_menu.current_is_edit === 0)  {
                //     let params = {
                //         id,
                //         background_image: merber_info.background,
                //         profile_photo_position: merber_info.photo_position,
                //         menu_type: merber_menu.module_style,
                //         vip_rights: merber_interests,
                //         user_property: merber_assets,
                //         num: merber_menu.module_column,
                //         default: merber_info.default,
                //         apply: 0,
                //         list: merber_menu.module_list,
                //     }
                //     let res: any = await postRequest(apiUrl.editProfile, params)
                //     if(res.code === 1) {
                //         message.success('操作成功！')
                //     } else {
                //         message.error(res.message)
                //     }
                // } else {
                //     let params = {
                //         profile_settings_id: id,
                //         apply: 0,
                //         ...merber_menu.module_list[merber_menu.current_index]
                //     }
                //     let res: any = await postRequest(apiUrl.editProfileItem, params)
                //     if(res.code === 1) {
                //         message.loading('操作成功！')
                //     } else {
                //         message.error(res.message)
                //     }
                // }
                break;
            case '保存并运用':
                let params_apply = {
                    id,
                    background_image: merber_info.background,
                    profile_photo_position: merber_info.photo_position,
                    menu_type: merber_menu.module_style,
                    vip_rights: merber_interests,
                    user_property: merber_assets,
                    num: merber_menu.module_column,
                    default: merber_info.default,
                    apply: 1,
                    list: merber_menu.module_list,
                }
                let res_apply: any = await postRequest(apiUrl.editProfile, params_apply)
                if(res_apply.code === 1) {
                    message.success('操作成功！')
                } else {
                    message.error(res_apply.message)
                }
                // if(merber_menu.current_is_edit === 0)  {
                //     let params = {
                //         id,
                //         background_image: merber_info.background,
                //         profile_photo_position: merber_info.photo_position,
                //         menu_type: merber_menu.module_style,
                //         vip_rights: merber_interests,
                //         user_property: merber_assets,
                //         num: merber_menu.module_column,
                //         default: merber_info.default,
                //         apply: 1,
                //         list: merber_menu.module_list,
                //     }
                //     let res: any = await postRequest(apiUrl.editProfile, params)
                //     if(res.code === 1) {
                //         message.success('操作成功！')
                //     } else {
                //         message.error(res.message)
                //     }
                // } else {
                //     let params = {
                //         profile_settings_id: id,
                //         apply: 1,
                //         ...merber_menu.module_list[merber_menu.current_index]
                //     }
                //     let res: any = await postRequest(apiUrl.editProfileItem, params)
                //     if(res.code === 1) {
                //         message.success('操作成功！')
                //     } else {
                //         message.error(res.message)
                //     }
                // }
            
                break;
            case '恢复默认设置':
                this._initDateDefault()
                break;
            case '返回':
                this.props.history.goBack();
                break;
            case '预览':
                shopDecorationStore.updatePreview(1)
                break;
        
            default:
                break;
        }
    }
    //获取默认数据
    _initDateDefault = async () => {
        let res: any = await getRequest(apiUrl.getDefaultProfile)
        if(res.code === 1) {
            let dataObj = res.data;
            let temp = res.data.list;
            let new_temp = temp.map((item: any, idx:any) => {
                item.index = idx
                return (
                    item
                )
            })
            let shop_decoration_wode = {    //装修门店-我的
                id: dataObj.id,
                merber_info: {
                    status: 1,
                    background: dataObj.background_image,
                    photo_position: dataObj.profile_photo_position,
                    default: dataObj.default, //1:默认 0:自定义
                },
                merber_interests: dataObj.vip_rights, //会员权益，1:显示，0:不显示;
                merber_assets: dataObj.user_property, //用户资产，1:显示，0:不显示;
                merber_interests_open: 1, //0：展开  1：收起
                merber_assets_open: 1, //0：展开  1：收起
                merber_menu: {
                    status: 1,
                    module_style: dataObj.menu_type,
                    module_column: dataObj.num,
                    current_index: 0, //表示module_list的当前操作的索引
                    current_is_edit: 0, //表示当前菜单是否有编辑 0:否 1:是
                    module_list: dataObj.list,
                },
                href_path: dataObj.path
            }
            shopDecorationStore.updateRefresh(0)
            shopDecorationStore.updateShopDecorationWode(shop_decoration_wode)
            this.onSortTable(dataObj.list)
            message.success('操作成功！')
        } else {
            message.error(res.message)
        }
    }

    //排序接口
    onSortTable = async (arrayList: any) => {
        let count = arrayList.length;
        let temp = arrayList.map((item: any, index: any) => {
            let obj: any = item;
            obj.order = count - index;
            obj.is_edit = 0;
            return (
                obj
            )
        })
        shopDecorationStore.updateObjPropsDefault('merber_menu','module_list',temp)
        
    }

    render() {
        const { dataObj } = this.props;

        return (
            <div className={styles.header}>
                <div className={styles.header_left}>
                    {
                        dataObj.left && dataObj.left.length > 0 && dataObj.left.map((item: any, index: number) => {
                            return (
                                item.type == 1
                                ?   <div key={item.txt + index} className={styles.header_left_list} onClick={() => {this.onClickSubmit(item.txt)}}>
                                        {
                                            item.txt
                                        }
                                    </div>
                                :   <div key={item.txt + index} className={styles.header_left_item} onClick={() => {this.onClickSubmit(item.txt)}}>
                                        {
                                            item.txt
                                        }
                                    </div>
                            )
                        })
                    }
                </div>
                <div className={styles.header_right}>
                    {
                        dataObj.right && dataObj.right.length > 0 && dataObj.right.map((item: any, index: number) => {
                            return (
                                item.type == 1
                                ?   <div key={item.txt + index} className={styles.header_right_list} onClick={() => {this.onClickSubmit(item.txt)}}>
                                        {
                                            item.txt
                                        }
                                    </div>
                                :   <div key={item.txt + index} className={styles.header_right_item} onClick={() => {this.onClickSubmit(item.txt)}}>
                                        {
                                            item.txt
                                        }
                                    </div>
                            )
                        })
                    }
                </div>
                
            </div>
        )
    }
}

export default DecorationHeader