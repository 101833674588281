import React, { Fragment, useState, useEffect } from 'react'
import { Typography, Table, Form, Select, Button, Row } from 'antd';
import { getRequest } from '../../../api/http';
import { apiUrl } from '../../../api/api_url';

const { Title } = Typography;
const { Option } = Select;

interface IusersList {
  total: number
  items: Array<{
    "id": number,                      // 订单id
    "desk": string,                   // 桌号
    "code": string,    // 订单号码
    "user_amount": number,               // 就餐用户数
    "status": number,                    // 订单状态
    "status_display": string,      // 订单状态显示
    "created_at": string,  // 创建时间
    "zhuli_dish": {                 // 助力菜品
      "id": number,
      "name": string,
      "main_pic": string,
      "printer_key": string,
      "price": string
    },
    "zhuli_count": number,               // 助力菜份数
    "need_users": number,                // 助力所需用户数
    "zhuli_status": number,             // 助力状态 // 1进行中、5成功、10下单完成
    zhuli_users: Array<{
      "id": number,
      "headimgurl": string
      "nickname": string,
      "phone_number": string,
      "group": string,
      "level": string,
      "count": number,
      "group_id": number,
      "recharge_count": number
    }>
  }>
}

interface ItableList {
  id: number | string
  name: string
  dine_key: string
  qrcode: string
  region_name: string
  order_num: number
  is_eating: boolean
}

let filterData = {
  table_id: -1,
  status: -1,
  zhuli_status: -1
}

type IProps = {
  tabIdx: number
}

const InviteHelpUsers = ({ tabIdx }: IProps) => {

  const [usersList, setusersList] = useState({
    total_count: 0,
    list: []
  });
  const [tableList, settableList] = useState<Array<ItableList>>([]);
  const [page, setpage] = useState(1);
  const [pageSize, setpageSize] = useState(10)
  const [loadingData, setloadingData] = useState(false);

  useEffect(() => {
    setpage(1);
    initList(page, pageSize);
    getTableList();
  }, [tabIdx]);

  const initList = async (curr = page, size = pageSize, table_id = -1, status = -1, zhuli_status = -1) => {
    setloadingData(true);
    let parms: any = {
      current: curr,
      pageSize: size
    }
    if(table_id != -1) parms['table_id'] = table_id;
    if(status != -1) parms['status'] = status;
    if(zhuli_status != -1) parms['zhuli_status'] = zhuli_status;
    const res:any = await getRequest(apiUrl.helpUsersList, parms);
    if(res && res.data) {
      setusersList(res.data);
    }
    setloadingData(false);
  }

  const getTableList = async () => {
    const res: any = await getRequest(apiUrl.tableList, { current: 1,
      pageSize: 500 });
    if(res && res.data) {
      settableList(res.data);
    }
  }

  const columns = [{
    title: '餐桌号',
    dataIndex: 'table',
    key: 'table',
  },{
    title: '用餐人数',
    dataIndex: 'user_amount',
    key: 'user_amount',
  },
  {
    title: '助力类型',
    dataIndex: 'type',
    key: 'type',
  },{
    title: '需助力人数(人)',
    dataIndex: 'need_users',
    key: 'need_users',
  },{
    title: '已获得助力人数(人)',
    dataIndex: 'zhuli_users',
    key: 'zhuli_users',
  },{
    title: '可获得奖励',
    dataIndex: 'dish_name',
    key: 'dish_name',
  },{
    title: '可获得份数',
    dataIndex: 'zhuli_count',
    key: 'zhuli_count',
  },{
    title: '完成状态',
    dataIndex: 'status_text',
    key: 'status_text',
  },
]
  const status = (status: number) => {
    switch(status){
      case 1:
        return '待付款'
        break;
      case 2:
          return '已取消'
          break;
      case 3:
          return '支付中'
          break;
      case 5:
          return '已付款'
          break;
      case 10:
          return '全部退款'
          break;
      case 15:
          return '部分退款'
          break;
    }
  }

  const fliterOrderStatus = (num: number) => {
    filterData = {...filterData, status: num }
    setpage(1);
    initList(1, pageSize, filterData.table_id, filterData.status, filterData.zhuli_status);
  }

  return <Fragment>
    <Row type="flex">
      <Button type="primary">导出</Button>
      <Form layout="inline" style={{marginBottom: 30, marginLeft: 30}}>
        <Form.Item label="完成状态">
          <Select
            placeholder="请选择状态"
            showSearch={false}
            style={{ width: 120 }}
            onChange={fliterOrderStatus}
            allowClear
          >
            <Option value={1}>进行中</Option>
            <Option value={2}>已完成</Option>
            <Option value={3}>未完成</Option>
          </Select>
        </Form.Item>
      </Form>
    </Row>
    <Table
      columns={columns}
      dataSource={usersList.list}
      rowKey={`id`}
      loading={loadingData}
      size="middle"
      pagination={{
        pageSize,
        total:usersList.total_count,
        current: page,
        onChange: (page) => {
          setpage(page);
          initList(page, pageSize, filterData.table_id, filterData.status, filterData.zhuli_status);
        },
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total: number) => `共${total}条`,
        onShowSizeChange: (current:number, size: number) => {setpageSize(size); setpage(1); initList(1, size, -1, -1, -1)}

      }}
    />
  </Fragment>
}

export default InviteHelpUsers