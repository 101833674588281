import {
    Row,
    Typography,
    Form,
    Input,
    Radio,
    Button,
    message,
    Spin,
    InputNumber,
    Col,
    Modal,
    Table,
    Checkbox,
    DatePicker
} from "antd"
import React, {useState, useEffect} from "react"
import {postRequest, getRequest} from "../../api/http";
import {apiUrl} from "../../api/api_url";
import {useHistory} from "react-router-dom";
import useForm from 'rc-form-hooks';
import BraftEditor from 'braft-editor'
// 引入编辑器样式
import 'braft-editor/dist/index.css'
import moment from "moment";

const {Title, Text} = Typography;
const {RangePicker} = DatePicker;

type FormData = {
    name: string,
    info: {
        "type": "cash_coupon",
        favour: string,
        limit: null | {
            type: "full",
            money: number
        }
    },
    can_trans: boolean,
    is_vip: boolean,
    price: number,
    amount: number,
    limit_value: number,
    user_type: "",
    status: 2 | 0, // 启用 | 停用
    tips: string,
    superposition: 0 | 1,  // 可叠加 | 不可叠加
    overlay: number
    expire_info: {},
    shop_type: number,
    allDate: boolean
}

const AddVouchers = () => {

    const [formdata, setFormData] = useState<FormData>({
        name: "",
        info: {
            type: "cash_coupon",
            favour: "",
            limit: null
        },
        can_trans: false,
        is_vip: true,
        price: 0.0,
        amount: 0,
        limit_value: 50,
        user_type: "",
        status: 2,
        tips: "",
        overlay: 0,
        superposition: 1,
        expire_info: {},
        shop_type: 1,
        allDate: true
    });

    const [editorState, seteditorState] = useState(BraftEditor.createEditorState(null))

    let columns = [
        {
            title: '门店名称',
            dataIndex: 'name',
            key: 'name',
            className: 'list_center',
        },
        {
            title: '门店地址',
            dataIndex: 'address',
            key: 'address',
            className: 'list_center',
        }
    ];

    const [time_type, settime_type] = useState<number>(1)
    const [start_time, setstart_time] = useState<string>('')
    const [end_time, setend_time] = useState<string>('')
    const [limit, setlimit] = useState(1);
    const history = useHistory();
    const state: any = history.location.state;
    const [loadingData, setloadingData] = useState(false);
    const [currentPage, setcurrentPage] = useState(1)
    const [visible, setvisible] = useState(false)
    const [pageSize, setpageSize] = useState(10)
    const [searchName, setsearchName] = useState('')
    const [productList, setproductList] = useState([])
    const [newproductList, setnewproductList] = useState([])
    const [productCount, setproductCount] = useState(0)
    const [shop_ids, setshop_ids] = useState([])
    const [favour, setfavour] = useState([])
    const [edit, setedit] = useState(false)
    const [allDate, setallDate] = useState(true)
    const [weeks, setweeks] = useState([{
        name: '周一',
        value: 1
    },
        {
            name: '周二',
            value: 2
        },
        {
            name: '周三',
            value: 3
        },
        {
            name: '周四',
            value: 4
        },
        {
            name: '周五',
            value: 5
        },
        {
            name: '周六',
            value: 6
        },
        {
            name: '周日',
            value: 0
        }])
    const [week, setweek] = useState([])
    let [dateList, setdateList] = useState([])
    const [festival, setfestival] = useState([
        {
            name: '法定节假日（元旦、春节、清明节、劳动节、端午节、中秋节、国庆节）',
            date: [{start: '2021-01-01', end: '2021-01-03', fix: false},
                {start: '2021-02-11', end: '2021-02-17', fix: false},
                {start: '2021-04-03', end: '2021-04-05', fix: false},
                {start: '2021-05-01', end: '2021-05-05', fix: false},
                {start: '2021-06-12', end: '2021-06-14', fix: false},
                {start: '2021-09-19', end: '2021-09-21', fix: false},
                {start: '2021-10-01', end: '2021-10-07', fix: false}
            ],
        },
        {
            name: '元旦',
            date: [{start: '2021-01-01', end: '2021-01-03', fix: false}]
        },
        {
            name: '春节',
            date: [{start: '2021-02-11', end: '2021-02-17', fix: false}]
        },
        {
            name: '元宵节',
            date: [{start: '2021-02-26', end: '2021-02-26', fix: true}]
        },
        {
            name: '情人节',
            date: [{start: '2021-02-14', end: '2021-02-14', fix: true}]
        },
        {
            name: '清明节',
            date: [{start: '2021-04-03', end: '2021-04-05', fix: false}]
        },
        {
            name: '劳动节',
            date: [{start: '2021-05-01', end: '2021-05-05', fix: false}]
        },
        {
            name: '母亲节',
            date: [{start: '2021-05-09', end: '2021-05-09', fix: true}]
        },
        {
            name: '儿童节',
            date: [{start: '2021-06-01', end: '2021-06-01', fix: true}]
        },
        {
            name: '父亲节',
            date: [{start: '2021-06-20', end: '2021-06-20', fix: true}]
        },
        {
            name: '端午节',
            date: [{start: '2021-06-12', end: '2021-06-14', fix: false}]
        },
        {
            name: '七夕节',
            date: [{start: '2021-08-14', end: '2021-08-14', fix: true}]
        },
        {
            name: '中秋节',
            date: [{start: '2021-09-19', end: '2021-09-21', fix: false}]
        },
        {
            name: '国庆节',
            date: [{start: '2021-10-01', end: '2021-10-07', fix: false}]
        },
        {
            name: '万圣节',
            date: [{start: '2021-10-31', end: '2021-10-31', fix: true}]
        },
        {
            name: '感恩节',
            date: [{start: '2021-11-25', end: '2021-11-25', fix: true}]
        },
        {
            name: '圣诞节',
            date: [{start: '2021-12-25', end: '2021-12-25', fix: true}]
        },
    ])

    const form = useForm();

    useEffect(() => {
        getVoucherInfo();
        _initList()
    }, []);

    const getVoucherInfo = async () => {
        if (state && state.type === "edit") {
            setloadingData(true);
            const res: any = await getRequest(apiUrl.couponInfo, {id: state.id});
            if (res && res.code === 1) {
                //
                const _data = res.data;
                setFieldsValue({
                    name: _data.name,
                    favour: _data.info.favour,
                    price: _data.price,
                    amount: _data.amount,
                    limit_value: _data.limit_value,
                    status: _data.status,
                    superposition: _data.superposition,
                    overlay: _data.overlay,
                    tips: BraftEditor.createEditorState(_data.tips),
                    shop_type: _data.shop_type,
                    allDate: _data.allDate
                })
                setedit(true)
                setFormData({...res.data, tips: BraftEditor.createEditorState(_data.tips)});
                if (_data.info.limit && _data.info.limit && _data.info.limit.money) {
                    setlimit(2);
                    setFieldsValue({limit_money: _data.info.limit.money});
                }
                settime_type(time_type)
                if (time_type === 1) {
                    setstart_time(_data.expire_info.start_time)
                    setend_time(_data.expire_info.end_time)
                }

                if (_data.shop_type == 2 && _data.shop_id) {
                    let ids: any = [];
                    _data.shop_id.map((item: any) => {
                        ids.push(item.id)
                    })
                    setshop_ids(ids)
                    setfavour(_data.shop_id)
                }

                if (_data.disabled_use_day && (_data.disabled_use_day.date || _data.disabled_use_day.week)) {
                    setdateList(res.data.disabled_use_day.date)
                    setweek(res.data.disabled_use_day.week)
                    refreshAction()
                }
            }
            setloadingData(false);
        }
    }

    // 提交
    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        validateFields().then(async (e) => {
            let parms: any = {
                ...formdata,
                name: e.name,
                price: e.price,
                amount: e.amount,
                status: e.status,
                superposition: e.superposition,
                limit_value: e.limit_value,
                overlay: e.overlay,
                tips: editorState.toHTML(),
                info: {
                    ...formdata.info,
                    favour: e.favour,
                },
                shop_ids,
                shop_type: e.shop_type
            }
            if (limit === 2) {
                parms.info.limit = {
                    type: 'full',
                    money: e.limit_money
                }
            }
            if (time_type === 1) {
                parms.expire_info = {
                    type: 'time',
                    start_time: start_time,
                    end_time: end_time
                }
            } else {
                parms.expire_info = {
                    type: "permanent",
                    value: ''
                }
            }

            if (!parms.allDate) {
                let newDate: any[] = [];
                dateList.map((date: any) => {
                    if (date.start) {
                        newDate.push({start: date.start, end: date.end ? date.end : date.start})
                    }
                })
                parms.disabled_use_day = {
                    week,
                    date: newDate
                }
            }

            if (!parms.name.trim()) {
                message.error('代金券名称不能为空!');
                return;
            }
            const res: any = await postRequest(apiUrl.couponAdd, parms);
            if (res && res.code) {
                message.success('添加成功');
                history.goBack();
            } else {
                message.error(res.message);
            }

        })
            .catch(e => {
                console.log('e 表单出错', e)
            })
    }

    const onEditSumit = async () => {
        validateFields().then(async (e) => {
            console.log(`e`, e);
            let parms: any = {
                ...formdata,
                name: e.name,
                price: e.price,
                amount: e.amount,
                status: e.status,
                superposition: e.superposition,
                limit_value: e.limit_value,
                tips: editorState.toHTML(),
                overlay: e.overlay,
                info: {
                    ...formdata.info,
                    favour: e.favour,
                },
                shop_ids,
                shop_type: e.shop_type
            }
            if (limit === 2) {
                parms.info.limit = {
                    type: 'full',
                    money: e.limit_money
                }
            }

            if (time_type === 1) {
                parms.expire_info = {
                    type: 'time',
                    start_time: start_time,
                    end_time: end_time
                }
            } else {
                parms.expire_info = {
                    type: "permanent",
                    value: ''
                }
            }

            // 防止从有限制改到无限制没有清除限制
            if (limit === 1) {
                parms.info.limit = null;
            }

            if (!parms.allDate) {
                let newDate: any[] = [];
                dateList.map((date: any) => {
                    if (date.start) {
                        newDate.push({start: date.start, end: date.end ? date.end : date.start})
                    }
                })
                parms.disabled_use_day = {
                    week,
                    date: newDate
                }
            }

            if (!parms.name.trim()) {
                message.error('代金券名称不能为空!');
                return;
            }
            const res: any = await postRequest(apiUrl.voucherEdit, parms);
            if (res && res.code === 1) {
                message.success('编辑成功');
                history.goBack();
            } else {
                message.error(res.message);
            }
        })

    }

    const {getFieldDecorator, validateFields, setFieldsValue, errors, values} = useForm<{
        name: string,
        favour: number,
        price: number,
        amount: number,
        limit_value: number,
        limit_money: number,
        status: number,
        superposition: number,
        tips: string,
        overlay: number,
        shop_type: number,
        allDate: boolean
    }>();

    const handleEditorChange = (e: any) => {
        console.log('e :>> ', e.toHTML());
        seteditorState(e);
        console.log('editorState :>> ', editorState.toHTML());
    }


    // 选择门店
    const handleOk = () => {
        let favour: any = [];
        productList.map((item: any) => {
            if (item.select) {
                favour.push(item)
            }
        })
        setvisible(false)
        setfavour(favour)
    }

    const _initList = async () => {
        const res: any = await getRequest(apiUrl.getShopList);
        if (res.code === 1) {
            setproductList(res.data)
            filterList(res.data)
        }
    }

    const isChinese = (s: string) => {
        return /[\u4e00-\u9fa5]/.test(s);
    }

    const ch2Unicdoe = (str: string) => {
        if (!str) {
            return;
        }
        var unicode = '';
        for (var i = 0; i < str.length; i++) {
            var temp = str.charAt(i);
            if (isChinese(temp)) {
                unicode += '\\u' + temp.charCodeAt(0).toString(16);
            }
            else {
                unicode += temp;
            }
        }
        return unicode;
    }

    const searchFoodName = (searchText: string, text: string) => {
        let test = ch2Unicdoe(searchText)
        var pattern2 = `/[${test}]+/g`;
        var reg = RegExp(eval(pattern2))
        return reg.test(text)
    }

    const filterList = (list?: any) => {
        // let { productList, searchName } = this.state;
        let newList: any = [];
        let newListTow: any = [];

        let currentList = productList;
        if (list) {
            currentList = list
        }

        currentList.map((item: any) => {
            if (searchName !== '') {
                if (searchFoodName(searchName, item.name)) {
                    newList.push(item)
                }
            } else {
                newList.push(item)
            }
        })

        newListTow = JSON.parse(JSON.stringify(newList));
        setnewproductList(newList)
        setproductCount(newListTow.length)
    }

    const timeChange = (time: any, timeString: [string, string]) => {
        setstart_time(timeString[0])
        setend_time(timeString[1])
    }

    //商品名搜索
    const searchAction = (e: any) => {
        setcurrentPage(1)
        setsearchName(e.target.value.trim())
        filterList()
    }
    // 分页
    const orderChange = (page: number) => {
        setcurrentPage(page)
        filterList()
    }

    let rowSelection: any = {
        selectedRowKeys: shop_ids,
        onSelect: (record: any, selected: any, selectedRows: any) => {
            // let productList:any = productList;
            productList.map((item: any) => {
                if (item.id === record.id) {
                    console.log(item.id)
                    item.select = selected;
                }
            })
            setproductList(productList)
        },
        onSelectAll: (selected: any, selectedRows: any, changeRows: any) => {
            // let productList:any[] = this.state.productList;
            productList.map((item: any) => {
                changeRows.map((item2: any) => {
                    if (item.id === item2.id) {
                        item.select = selected;
                    }
                })
            })

            setproductList(productList)
        },
        onChange: (selectedRowKeys: any, selectedRows: any) => {
            setshop_ids(selectedRowKeys)
        },
        type: 'checkbox',
    };

    const deleteDish = (id: number) => {
        favour.map((item: any, index: number) => {
            if (item.id === id) {
                favour.splice(index, 1)
            }
        })

        productList.map((item: any) => {
            if (item.id === id) {
                item.select = false;
            }
        })

        shop_ids.map((item: any, index: any) => {
            if (item === id) {
                shop_ids.splice(index, 1)
            }
        })

        setfavour(favour)
        setproductList(productList)
        setshop_ids(shop_ids)
        refreshAction()

    }

    //用于刷新的方法
    const [refresh, setRefresh] = useState(false);
    const refreshAction = () => {
        if (refresh === true) {
            setRefresh(false)
        } else {
            setRefresh(true)
        }
    }
    useEffect(() => {

    }, [refresh])

    // 星期选择
    const weekChange = (checkedValues: any) => {
        setweek(checkedValues)
    }

    const addNull = () => {
        let newList: any = dateList;
        newList.push({start: null, end: null, fix: true});
        setdateList(newList)
        refreshAction()
    }

    const addDate = (item?: any) => {
        let newList: any = dateList;
        if (newList.length == 0) {
            newList = dateList.concat(item.date);
        } else {
            if (item.date.length > 1) {
                newList.map((item1: any) => {
                    let count = 0;
                    item.date.map((item2: any) => {
                        if (item1.start != item2.start && item1.end != item2.end) {
                            count++;
                            if (count == newList.length) {
                                console.log(count, newList.length, item1.start, item2.start);
                                newList.push(item2)
                            }
                        }
                    })
                })
            } else {
                let newcount = 0;
                newList.map((item1: any) => {
                    item.date.map((item2: any) => {
                        if (item1.start != item2.start && item1.end != item2.end) {
                            newcount++;
                            if (newcount == newList.length) {
                                console.log(newcount, newList.length, item1.start, item2.start);
                                newList.push(item2)
                            }
                        }
                    })
                })
            }
        }
        setdateList(newList)
        refreshAction()
    }

    const onCalendarChange = (dates: any, dateStrings: any, index: any, text: string) => {
        let newList: any = dateList;
        console.log(dateList, dates, dateStrings)
        if (text === 'start') {
            newList[index].start = dateStrings
        } else {
            newList[index].end = dateStrings
        }
        setdateList(newList)
        refreshAction()
    }

    const deleteDate = (idx: number) => {
        let newList: any = dateList;
        newList.splice(idx, 1)
        setdateList(newList)
        refreshAction()
    }


    return <Row className='l_content'>
        <Spin tip="Loading..." spinning={loadingData}>
            <Title level={4}> 代金券基本信息 </Title>

            <Form labelCol={{span: 4}} wrapperCol={{span: 12}} onSubmit={onSubmit}>
                <Form.Item label="代金券名称">
                    {
                        getFieldDecorator('name', {rules: [{required: true, message: "请输入代金券名称!",}]})
                        (<Input
                            placeholder="输入代金券名称"
                            value={formdata.name}
                            style={{width: 250}}
                            allowClear/>)
                    }
                </Form.Item>

                <Row>
                    <Form.Item label="有效时间"
                               required>
                        <Radio.Group
                            value={time_type}
                            name={'time_type'}
                            onChange={(e) => settime_type(e.target.value)}
                        >
                            <Radio value={1}>固定时间</Radio>
                            <Radio value={2}>永久</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Row>

                {
                    time_type == 1
                        ? <Row className="mb30">
                            <Col span={7} offset={3}>
                                <Row type="flex" align="middle">
                                    <RangePicker onChange={timeChange} format="YYYY-MM-DD HH:mm:ss"
                                                 showTime={{format: 'HH:mm'}}
                                                 placeholder={start_time !== '' ? [start_time, end_time] : ["开始时间", "结束时间"]}/>
                                </Row>
                            </Col>
                        </Row>
                        : null
                }

                <Row>
                    <Form.Item label="使用消费条件"
                               required>
                        <Radio.Group
                            onChange={(e) => setlimit(e.target.value)}
                            disabled={edit}
                            value={limit}>
                            <Radio value={1}>无门槛</Radio>
                            <Radio value={2}>有门槛</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Row>

                {
                    limit === 2 && <Form.Item label="消费限制" required>
                        <Row style={{display: "inline"}}>
                            满{
                            getFieldDecorator("limit_money", {
                                rules: [{required: limit === 2 ? true : false, message: "请输入最低消费限制"}]
                            })
                            (<InputNumber placeholder="最低消费" min={0.01} style={{width: 160}}/>)
                        } 元
                        </Row>
                    </Form.Item>
                }

                <Form.Item label="券面价值" required>
                    {
                        getFieldDecorator("favour", {rules: [{required: true, message: "请输入券面价值"}]})
                        (<InputNumber placeholder="输入券面价值" disabled={edit} min={0.01} style={{width: 250}}/>)
                    } 元
                </Form.Item>

                <Form.Item label="售价" required>
                    {
                        getFieldDecorator("price", {rules: [{required: true, message: '请输入售价'}]})
                        (<InputNumber placeholder="输入售价" disabled={edit} min={0.01} style={{width: 250}}/>)
                    } 元
                </Form.Item>

                <Form.Item label="发行数量" required>
                    {
                        getFieldDecorator("amount", {rules: [{required: true, message: "请输入发行量"}]})
                        (<InputNumber placeholder="输入发行数量" min={1} step={1}
                                      disabled={state && state.type === "edit" ? true : false} style={{width: 250}}/>)
                    }
                    {state && state.type === "edit" ? null : <Text style={{marginLeft: 10}}> *创建成功后不能修改，请谨慎填写</Text>}
                </Form.Item>

                <Form.Item label="限制每人领取" required>
                    {
                        getFieldDecorator("limit_value", {
                            rules: [{required: true, message: "请输入限制每人领取数量"}],
                            initialValue: 50
                        })
                        (<InputNumber placeholder="" min={1} step={1} value={formdata.limit_value}
                                      style={{width: 250}}/>)
                    } 张
                    <Text style={{marginLeft: 10}}> *每个用户的领券上限，默认50张</Text>
                </Form.Item>

                <Form.Item label="状态" style={{display: "flex"}} required>
                    {
                        getFieldDecorator("status", {rules: [{required: true}], initialValue: 2})
                        (<Radio.Group
                            value={formdata.status}>
                            <Radio value={2} defaultChecked>启用</Radio>
                            <Radio value={0}>停用</Radio>
                        </Radio.Group>)
                    }
                </Form.Item>

                <Title level={4}> 使用设置 </Title>

                <Form.Item label="允许叠加使用" style={{display: "flex"}}>
                    {
                        getFieldDecorator("superposition", {rules: [{required: true}], initialValue: 1})
                        (<Radio.Group
                            onChange={(e) => setFormData({...formdata, superposition: e.target.value})}
                            value={formdata.superposition}>
                            <Radio value={1}>否</Radio>
                            <Radio value={0} defaultChecked>是</Radio>
                        </Radio.Group>)
                    }
                </Form.Item>

                <Form.Item label="可用门店" style={{display: "flex"}}>
                    {
                        getFieldDecorator('shop_type', {rules: [{required: true}], initialValue: 1})
                        (
                            <Row type="flex" align="middle">
                                <Col>
                                    <Radio.Group
                                        name='shop_type'
                                        value={formdata.shop_type}
                                        onChange={(e) => setFormData({...formdata, shop_type: e.target.value})}
                                    >
                                        <Radio value={1}>全部门店</Radio>
                                        <Radio value={2}>部分门店</Radio>
                                    </Radio.Group>
                                </Col>
                                {
                                    formdata.shop_type === 2
                                        ? <Col>
                                            <Button icon='plus' onClick={() => {
                                                setvisible(true)
                                            }}>选择门店</Button>
                                        </Col>
                                        : null
                                }
                            </Row>
                        )
                    }
                </Form.Item>
                {
                    formdata.shop_type === 2 && favour.length > 0
                        ? <Row>
                            <Col span={12} offset={2}>
                                <Table
                                    dataSource={favour}
                                    columns={[
                                        {
                                            title: '门店名称',
                                            dataIndex: 'name',
                                            key: 'name',
                                            className: 'list_center',
                                        },
                                        {
                                            title: '门店地址',
                                            dataIndex: 'address',
                                            key: 'address',
                                            className: 'list_center',
                                        },
                                        {
                                            title: '操作',
                                            dataIndex: 'action',
                                            key: 'action',
                                            className: 'list_center',
                                            render: (rowdata: any, record: any, idx: number) => {
                                                return <div className='list_btn'
                                                            onClick={() => deleteDish(record.id)}>删除</div>
                                            }
                                        }
                                    ]}
                                    rowKey={"id"}
                                    size="middle"
                                    locale={{emptyText: '暂无数据'}}
                                    pagination={{
                                        showQuickJumper: true,
                                        defaultCurrent: 1,
                                        pageSize: 20,
                                    }}/>
                            </Col>
                        </Row>
                        : null
                }

                <Form.Item label="不可用日期" style={{display: "flex"}}>
                    {
                        getFieldDecorator('allDate', {rules: [{required: false}], initialValue: true})
                        (
                            <Row className="mb30" type="flex" align="middle">
                                <Col span={14}>
                                    <Row type="flex" align="middle">
                                        <Col>
                                            <Radio.Group
                                                name='allDate'
                                                value={formdata.allDate}
                                                onChange={(e) => setFormData({...formdata, allDate: e.target.value})}
                                            >
                                                <Radio value={true}>所有日期都可用</Radio>
                                                <Radio value={false}>部分日期不可用</Radio>
                                            </Radio.Group>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        )
                    }
                </Form.Item>

                {
                    !formdata.allDate
                        ? <Row>
                            <Col offset={3}>
                                <Row>
                                    <Checkbox.Group style={{width: '100%'}} onChange={weekChange} value={week}>
                                        <Row>
                                            {weeks && weeks.map((item: any) => {
                                                return <Col span={1} key={item.name}>
                                                    <Checkbox value={item.value}>{item.name}</Checkbox>
                                                </Col>
                                            })}
                                        </Row>
                                    </Checkbox.Group>
                                </Row>
                                <div className='sub_title'>指定不可用时间（点击某个节日后，相应日期会自动被添加到下方日期栏中。）</div>
                                <Row>
                                    <Col span={10}>
                                        <Row type='flex'>
                                            {
                                                festival.map((item: any, index: number) => {
                                                    return <Col key={item.name + index} span={index > 0 ? 4 : 20}
                                                                className='festival_item'
                                                                onClick={() => addDate(item)}>{item.name}</Col>
                                                })
                                            }
                                        </Row>
                                    </Col>
                                </Row>


                                <div className='sub_title'>手动添加</div>
                                {

                                    dateList && dateList.map((day: any, idx: number) => {
                                        return <Row type='flex' align='middle' style={{margin: '15px 0'}}
                                                    key={JSON.stringify(day)}>
                                            <Col>
                                                <DatePicker
                                                    onChange={(date, dateString) => onCalendarChange(date, dateString, idx, 'start')}
                                                    placeholder='选择日期'
                                                    disabled={!day.fix}
                                                    defaultValue={day.start ? moment(day.start, 'YYYY-MM-DD') : null}
                                                />
                                            </Col>
                                            <Col className='mlr10'>至</Col>
                                            <Col>
                                                <DatePicker
                                                    onChange={(date, dateString) => onCalendarChange(date, dateString, idx, 'end')}
                                                    placeholder='选择日期'
                                                    disabled={!day.fix}
                                                    defaultValue={day.end ? moment(day.end, 'YYYY-MM-DD') : null}
                                                />
                                            </Col>
                                            <Col className='mlr10' onClick={() => deleteDate(idx)}>删除</Col>
                                        </Row>
                                    })
                                }
                            </Col>
                            <Col offset={3} span={2}>
                                <div className='add_btn' onClick={addNull}>新增</div>
                            </Col>
                        </Row>
                        : null
                }


                {
                    formdata.superposition === 0 && <Form.Item label="最多叠加数量">
                        <Row>
                            最多叠加 {
                            getFieldDecorator("overlay", {
                                rules: [{
                                    required: formdata.superposition === 0 ? true : false,
                                    message: "数量必须填写"
                                }], initialValue: ''
                            })
                            (<InputNumber placeholder="" min={1} step={1} style={{width: 100}}/>)
                        } 张
                        </Row>
                    </Form.Item>
                }

                <Form.Item label="使用须知">
                    <Row style={{border: '1px solid rgba(0, 0, 0, 0.2)'}}>
                        {
                            getFieldDecorator("tips", {rules: [{required: false}]})
                            (<BraftEditor
                                value={editorState}
                                onChange={handleEditorChange}
                                excludeControls={['media']}
                                // onSave={this.submitContent}
                            />)
                        }


                    </Row>
                </Form.Item>

                <Row type="flex" justify="center" style={{marginBottom: 100, marginTop: 50}}>
                    <Button onClick={() => history.goBack()}>取消</Button>
                    {
                        // onEditSumit
                        state && state.type === "edit"
                            ? <Button type="primary" style={{marginLeft: 50}} onClick={onEditSumit}>确定</Button>
                            : <Button type="primary" htmlType="submit" style={{marginLeft: 50}}>确定</Button>
                    }
                </Row>
            </Form>
        </Spin>

        <Modal
            title="指定门店"
            visible={visible}
            okText='确定'
            cancelText='取消'
            onOk={handleOk}
            onCancel={() => {
                setvisible(false)
            }}
        >
            <Row type="flex" align="middle" justify="start" className="mb20">
                <Col className="mr30">
                    <Input placeholder="请输入关键字" onChange={searchAction} style={{width: 234}}/>
                </Col>
            </Row>

            <div>
                <Table
                    dataSource={newproductList}
                    columns={columns}
                    rowKey={"id"}
                    size="middle"
                    locale={{emptyText: '暂无数据'}}
                    rowSelection={rowSelection}
                    pagination={{
                        current: currentPage,
                        showQuickJumper: true,
                        defaultCurrent: 1,
                        total: productCount,
                        pageSize: pageSize,
                        hideOnSinglePage: true,
                        onChange: orderChange
                    }}/>
            </div>
        </Modal>
    </Row>
}

export default AddVouchers