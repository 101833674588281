import React from 'react';
import { Row, Col } from 'antd';
import '../management.scss';

import winAddTable from '../../../../../../assret/images/winAddTable.png';
import winAddTableDetail from '../../../../../../assret/images/winAddTableDetail.png';

interface ShopManagementDetail {
    history: any,
}

class ShopManagementDetail extends React.Component<ShopManagementDetail> {

    render() {
        return  <Row className="typeDetail">
                    <Col className="typeDetail-title">一、功能介绍</Col>
                    <Col className="typeDetail-col">可以对桌台进行编号，扫描桌位二维码可进入点菜界面。</Col>
                    <Col className="typeDetail-title">二、功能路径</Col>
                    <Col className="typeDetail-col">电脑端登录后台-桌台管理-餐桌管理-添加餐桌。</Col>
                    <Col className="typeDetail-col">
                        <img src={winAddTable} />
                    </Col>
                    <Col className="typeDetail-title">三、操作流程</Col>
                    <Col className="typeDetail-col">1、<span className="txt-bold">添加餐桌</span>，根据系统提示设置餐桌号、可就餐人数、所属区域</Col>
                    <Col className="typeDetail-col">注：添加桌台完成，系统自动生成餐桌二维码</Col>
                    <Col className="typeDetail-col">
                        <img src={winAddTableDetail} />
                    </Col>
                </Row>
    }
}

export default ShopManagementDetail;