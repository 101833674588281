import React from "react";
import {Button, Input, message, Row, Col, Radio, DatePicker, Modal, Select, Tree, Icon, Tooltip, Table, Checkbox } from "antd";
import { RangePickerValue } from "antd/lib/date-picker/interface";
import BraftEditor from 'braft-editor'
// 引入编辑器样式
import 'braft-editor/dist/index.css'

import { getRequest, postRequest } from '../../api/http';
import { apiUrl } from '../../api/api_url';
import moment from 'moment';
const { RangePicker } = DatePicker;
const { Option } = Select;
const { TreeNode } = Tree;

const key = 'ChangeDishEdit';

class ChangeDishEdit extends React.Component<any> {
    // 商品表格头
    columns = [
        {
          title: '商品名称',
          dataIndex: 'name',
          key: 'name',
          className: 'list_center',
        },
        {
            title: '所属分类',
            dataIndex: 'cat_name',
            key: 'cat_name',
            className: 'list_center',
        },
        {
            title: '商品价格',
            dataIndex: 'price',
            key: 'price',
            className: 'list_center',
        },
    ];
    state = {
        loading: false,
        editorState: BraftEditor.createEditorState(null),
        type: 'present_goods',
        name: '',
        validText: 'time',
        day: 0,
        start_time: '',
        end_time: '',
        amount: 0,
        position: 1,
        sort: 0,
        status: 2,
        can_trans: false,
        user_type: [],
        shop_type: 1,
        receive_type: 1,
        receive_limit: 0,
        shop_ids: [],
        weeks:[{
            name: '周一',
            value: 1
        },
        {
            name: '周二',
            value: 2
        },
        {
            name: '周三',
            value: 3
        },
        {
            name: '周四',
            value: 4
        },
        {
            name: '周五',
            value: 5
        },
        {
            name: '周六',
            value: 6
        },
        {
            name: '周日',
            value: 7
        }],
        week: [],
        festival: [
            {
                name: '法定节假日（元旦、春节、清明节、劳动节、端午节、中秋节、国庆节）',
                date: [{start: '2021-01-01', end:'2021-01-03', fix: false},
                {start: '2021-02-11', end:'2021-02-17', fix: false},
                {start: '2021-04-03', end:'2021-04-05', fix: false},
                {start: '2021-05-01', end:'2021-05-05', fix: false},
                {start: '2021-06-12', end:'2021-06-14', fix: false},
                {start: '2021-09-19', end: '2021-09-21', fix: false},
                {start: '2021-10-01', end: '2021-10-07', fix: false}
             ],
            },
            {
                name: '元旦',
                date: [{start: '2021-01-01', end:'2021-01-03', fix: false}]
            },
            {
                name: '春节',
                date: [{start: '2021-02-11', end:'2021-02-17', fix: false}]
            },
            {
                name: '元宵节',
                date: [{start: '2021-02-26', end:'2021-02-26', fix: true}]
            },
            {
                name: '情人节',
                date: [{start: '2021-02-14', end:'2021-02-14', fix: true}]
            },
            {
                name: '清明节',
                date: [{start: '2021-04-03', end:'2021-04-05', fix: false}]
            },
            {
                name: '劳动节',
                date: [{start: '2021-05-01', end:'2021-05-05', fix: false}]
            },
            {
                name: '母亲节',
                date: [{start: '2021-05-09', end:'2021-05-09', fix: true}]
            },
            {
                name: '儿童节',
                date: [{start: '2021-06-01', end:'2021-06-01', fix: true}]
            },
            {
                name: '父亲节',
                date: [{start: '2021-06-20', end:'2021-06-20', fix: true}]
            },
            {
                name: '端午节',
                date: [{start: '2021-06-12', end:'2021-06-14', fix: false}]
            },
            {
                name: '七夕节',
                date: [{start: '2021-08-14', end:'2021-08-14', fix: true}]
            },
            {
                name: '中秋节',
                date: [{start: '2021-09-19', end: '2021-09-21', fix: false}]
            },
            {
                name: '国庆节',
                date: [{start: '2021-10-01', end: '2021-10-07', fix: false}]
            },
            {
                name: '万圣节',
                date: [{start: '2021-10-31', end:'2021-10-31', fix: true}]
            },
            {
                name: '感恩节',
                date: [{start: '2021-11-25', end:'2021-11-25', fix: true}]
            },
            {
                name: '圣诞节',
                date: [{start: '2021-12-25', end:'2021-12-25', fix: true}]
            },
        ],
        dateList:[],
        allDate: true,

        visible: false,
        sortList: [],
        currentPage: 1,
        pageSize: 10,
        productList: [],
        productCount: 0,
        cat_id: 0,
        searchName: '',
        showProductList: [],
        favour: [],
        keyList: [],
        limit_part: 0,
        can_use_total_num: 0,
        goodsList: [],
        showGoodsList: [],
        goodsCount: 0,
        goodsName: '',
        goodsVisible: false,
        goodsFavour: [],
        present_type: 1,
        edit: false,
        notice_day: 0,
        can_use_time: 0,
        valid_day: 0
    }

    componentDidMount () {
        const {location} = this.props;
        if(location.state !== undefined){
            this.initEdit(location.state.id);
            if(location.state.edit){
                this.setState({
                    edit: true
                })
            }
        }
        this._initList();
        this._initGoodsList()
    }

    // 初始化编辑
    initEdit = async (id:number) => {
        const params = {
            id: id
        };
        const res:any = await postRequest(apiUrl.couponInfo,params);
        if(res.code === 1){
            this.setState({
                name: res.data.name,
                type: res.data.info.type,
                editorState: BraftEditor.createEditorState(res.data.tips),
                goodsFavour: res.data.info.favour,
                amount: res.data.amount,
                status: res.data.status,
                limit_part: res.data.limit_part,
                can_use_total_num: res.data.can_use_total_num,
                position: res.data.position,
                sort: res.data.sort,
                user_type: res.data.user_type,
                notice_day: res.data.notice_day,
                can_use_time: res.data.can_use_time,
            });
            if(res.data.info.limit !== null){
                this.setState({
                    limit: true,
                    money: res.data.info.limit.money,
                });
            }

            if(res.data.can_trans){
                this.setState({
                    can_trans: res.data.can_trans
                })
            }
            if(res.data.receive_type){
                this.setState({
                    receive_type: res.data.receive_type.type,
                    receive_limit: res.data.receive_type.type === 1 ? 0 : res.data.receive_type.limit
                })
            }

            if(res.data.can_use_time){
                this.setState({
                    valid_day: res.data.expire_info.day
                })
            }else{
                if(res.data.expire_info.type === 'day'){
                    this.setState({
                        validText: res.data.expire_info.type,
                        day: res.data.expire_info.day
                    });
                }else{
                    this.setState({
                        validText: res.data.expire_info.type,
                        start_time: res.data.expire_info.start_time,
                        end_time: res.data.expire_info.end_time
                    });
                }
            }

            if(res.data.disabled_use_day && (res.data.disabled_use_day.date || res.data.disabled_use_day.week)){
                this.setState({
                    allDate: false,
                    dateList: res.data.disabled_use_day.date,
                    week: res.data.disabled_use_day.week
                })
            }

            if(res.data.shop_type){
                this.setState({
                    shop_type: res.data.shop_type
                })
            }

            if(res.data.shop_type && res.data.shop_type == 2){
                let arr: any = [];
                res.data.shop_id.map((item: any) => {
                    arr.push(item.id)
                })
                this.setState({
                    shop_type: 2,
                    favour: res.data.shop_id,
                    shop_ids: arr
                })
            }

            if(res.data.present_type){
                this.setState({
                    present_type: res.data.present_type
                })
            }

            if(this.props.location.state.type && res.data.status != 0 && res.data.status != 2){
                this.setState({
                    status: 2
                })
            }
        }
    }

    _initGoodsList = async () => {
        let sortList:any[] = [];

        const { currentPage } = this.state;
        const params:any = {
            current: currentPage,
            pageSize: 500,
            up_status: 4
        };

        const res:any = await getRequest(apiUrl.goodList,params);
        if(res.code === 1){
            let sortTest:string[] = [];
            res.data.map((item: any) => {
                if(sortTest.indexOf(item.cat_name) < 0){
                    sortList.push({id: item.cat_id, name: item.cat_name})
                    sortTest.push(item.cat_name)
                }
            })
            this.setState({
                goodsList: res.data,
                sortList
            },() => {
                this.filterGoodsList();
            })
        }
    }

    filterGoodsList = () => {
        let { goodsList, cat_id, searchName } = this.state;
        let newList:any = [];
        let newListTow:any = [];
        goodsList.map((item:any) => {
            if(searchName !== ''){
                if(this.searchFoodName(searchName, item.name)){
                    newList.push(item)
                }
            }else{
                if(cat_id !== 0){
                    if(cat_id === item.cat_id){
                        newList.push(item)
                    }
                }else{
                    newList.push(item)
                }
            }
        })

        newListTow = JSON.parse(JSON.stringify(newList));

        this.setState({
            showGoodsList: newList,
            goodsCount: newListTow.length
        })
    }

    // 输入类型
    inputChange = (e:any) => {
        let name = e.target.name;
        let value = e.target.value;
        this.setState({
            [name]: value
        })
    }

    // 优惠券券类型  单选类型
    radioChange = (e:any) => {
        let goodsList = this.state.goodsList;
        let name = e.target.name;
        let value = e.target.value;
        if(name == 'present_type'){
            goodsList.map((item:any) => {
                item.select = false;
            })
            this.setState({
                goodsFavour:[],
                goodsList
            })
        }
        this.setState({
            [name]: value
        })
    }

    // 有效时间区间
    timeChange = (time: RangePickerValue, timeString: [string, string]) => {
        this.setState({
            start_time: timeString[0],
            end_time: timeString[1]
        })
    }

    // 可见用户
    onCheck = (checkedKeys: any, info: any) => {
        let keyList = checkedKeys;
        let user_type: any[] = [];
        keyList.map((item: any) => {
            if(item !== '4'){
                user_type.push(item)
            }
        })
        this.setState({
            user_type
        })
    };

    // 星期选择
    weekChange = (checkedValues: any) => {
        this.setState({
            week: checkedValues
        })
    }

    handleSubmit = async (e:any) => {
        message.loading({ content: '发布中', key });

        e.preventDefault();

        const {location} = this.props;
        const {
            amount,
            position,
            sort,
            status,
            shop_ids,
            user_type,
            receive_type,
            receive_limit,
            week,
            dateList,
            goodsFavour,
            shop_type,
            notice_day,
            can_use_time,
            valid_day,
            present_type,
            name, type, limit_part, can_use_total_num, can_trans, validText, day, start_time, end_time, allDate } = this.state;

        var productInfo:any = {};

        if(location.state !== undefined && !location.state.type){
            productInfo.id = location.state.id;
        }

        if(name === ''){
            message.warning({ content: '请输入商品名称!', key, duration: 2 });
            return;
        }

        if(!can_use_time || can_use_time == 0){
            if(validText === 'day'){
                if(day === 0){
                    message.warning({ content: '请输入有效天数!', key, duration: 2 });
                    return;
                }
            }else{
                if(start_time === ''){
                    message.warning({ content: '请选择有效区间!', key, duration: 2 });
                    return;
                }
            }
        }else{
            if(!valid_day || valid_day == 0){
                message.warning({ content: '请输入券有效期!', key, duration: 2 });
                return;
            }
        }

        if(position == 3 || position == 5) {
            productInfo.amount = 0;
            productInfo.sort = sort;
        } else {
            if(amount === 0){
                message.warning({ content: '请输入发行数量!', key, duration: 2 });
                return;
            }
            productInfo.amount = amount;
        }

        if(limit_part === 0){
            message.warning({ content: '请输入兑换份数!', key, duration: 2 });
            return;
        }

        if(can_use_total_num === 0){
            message.warning({ content: '请输入桌台使用份数!', key, duration: 2 });
            return;
        }

        if(goodsFavour.length === 0){
            message.warning({ content: '请至少关联一个商品!', key, duration: 2 });
            return;
        }

        if(!allDate && (!can_use_time || can_use_time == 0)){
            let newDate:any[] = [];
            dateList.map((date: any) => {
                if(date.start){
                    newDate.push({start: date.start, end: date.end ? date.end : date.start})
                }
            })
            productInfo.disabled_use_day = {
                week,
                date: newDate
            }
        }

        can_use_time && (productInfo.can_use_time = can_use_time)

        productInfo.name = name;
        productInfo.type = type;
        productInfo.can_trans = can_trans;
        productInfo.limit_part = limit_part;
        productInfo.can_use_total_num = can_use_total_num
        productInfo.position = position;
        productInfo.present_type = present_type;

        productInfo.status = status;
        productInfo.shop_ids = shop_ids;
        productInfo.user_type = user_type;
        productInfo.shop_type = shop_type;
        productInfo.info = {
            'type': type,
            'favour': goodsFavour,
            "limit": null
        }
        if(receive_type === 1){
            productInfo.receive_type = {
                type: 1,
                limit: null
            }
        }else if(receive_type === 2){
            productInfo.receive_type = {
                type: 2,
                limit: receive_limit
            }
        }else{
            productInfo.receive_type = {
                type: 3,
                limit: null
            }
        }

        if(!can_use_time || can_use_time == 0){
            if(validText === 'day'){
                productInfo.expire_info = {
                    type: validText,
                    day: day
                }
            }else{
                productInfo.expire_info = {
                    type: validText,
                    end_time,
                    start_time
                }
            }
        }else{
            productInfo.expire_info = {
                type: 'day',
                day: valid_day
            }
        }

        const htmlContent = this.state.editorState.toHTML();
        productInfo.tips = htmlContent;

        if(location.state !== undefined && !location.state.type){
            const res:any = await postRequest(apiUrl.couponEdit,productInfo);
            if(res['code'] === 1){
                message.success({ content: '修改成功!', key, duration: 2 });
                this.goBack()
            }else{
                message.error({ content: '修改失败!', key, duration: 2 });
            }
        }else{
            const res:any = await postRequest(apiUrl.couponAdd,productInfo);
            if(res['code'] === 1){
                message.success({ content: '发布成功!', key, duration: 2 });
                this.goBack()
            }else{
                message.error({ content: '发布失败!', key, duration: 2 });
            }
        }
    };

    handleEditorChange = (editorState:any) => {
        this.setState({ editorState })
    }

    // 返回上一页
    goBack = () => {
        this.props.history.goBack();
    }

    _initList = async () => {
        const res:any = await getRequest(apiUrl.getShopList);
        if(res.code === 1){
            this.setState({
                productList: res.data,
            },() => {
                this.filterList();
            })
        }
    }

    isChinese = (s:string) =>{
        return /[\u4e00-\u9fa5]/.test(s);
    }

    ch2Unicdoe =(str:string) =>{
        if(!str){
          return;
        }
        var unicode = '';
        for (var i = 0; i <  str.length; i++) {
          var temp = str.charAt(i);
          if(this.isChinese(temp)){
            unicode += '\\u' +  temp.charCodeAt(0).toString(16);
          }
          else{
            unicode += temp;
          }
        }
          return unicode;
      }

    searchFoodName = (searchText:string,text:string) => {
        let test = this.ch2Unicdoe(searchText)
        var pattern2 = `/[${test}]+/g`;
        var reg = RegExp(eval(pattern2))
        return reg.test(text)
    }

    filterList = () => {
        let { productList, searchName } = this.state;
        let newList:any = [];
        let newListTow:any = [];

        productList.map((item:any) => {
            if(searchName !== ''){
                if(this.searchFoodName(searchName, item.name)){
                    newList.push(item)
                }
            }else{
                newList.push(item)
            }
        })

        newListTow = JSON.parse(JSON.stringify(newList));

        this.setState({
            showProductList: newList,
            productCount: newListTow.length
        })
    }

    //店铺名搜索
    searchAction = (e:any) => {
        this.setState({
            searchName: e.target.value.trim(),
            currentPage: 1
        }, () => {
            this.filterList()
        })
    }
    // 分页
    orderChange = (page: number) => {
        this.setState({
            currentPage: page
        }, () => {
            this.filterList()
        })
    }

    //商品名搜索
    goodsAction = (e:any) => {
        this.setState({
            searchName: e.target.value.trim(),
            currentPage: 1
        }, () => {
            this.filterGoodsList()
        })
    }
    // 商品分类
    goodsChange = (id: number) => {
        this.setState({
            cat_id: id
        }, () => {
            this.filterGoodsList()
        })
    }

    // 商品分页
    goodPageChange = (page: number) => {
        this.setState({
            currentPage: page
        }, () => {
            this.filterGoodsList()
        })
    }

    handleOk = (e:any) => {
        let productList:any[] = this.state.productList;
        let favour: any = [];
        productList.map((item:any) => {
            if(item.select){
                favour.push(item)
            }
        })
        this.setState({
          visible: false,
          favour
        });
      };

    handleCancel = (e:any) => {
        this.setState({
          visible: false,
        });
    };

    showTable = () => {
        let { productList, favour } = this.state;
        productList.map((item: any) => {
            favour.map((item2: any) => {
                if(item.id === item2.id){
                    item.select = true;
                }
            })
        })
        this.setState({
            productList,
            visible: true
        }, () => {
            this.filterList()
        })
    }

    deleteDish = (id: number) => {
        let { favour, productList } = this.state;
        favour.map((item:any, index: number) => {
            if(item.id === id){
                favour.splice(index,1)
            }
        })

        productList.map((item: any) => {
            if(item.id === id){
                item.select = false;
            }
        })

        this.setState({
            favour,
            productList
        })
    }

    onCalendarChange = (dates: any, dateStrings: any, index: any, text: string) => {
        console.log('dateStrings', dateStrings)
        let dateList:any[]= this.state.dateList;
        if(text === 'start'){
            dateList[index].start = dateStrings
        }else{
            dateList[index].end = dateStrings
        }
        this.setState({
            dateList
        })
    }

    addDate = (item?: any) => {
        let dateList: any = this.state.dateList;
        if(dateList.length == 0){
            dateList = dateList.concat(item.date);
        }else{
            if(item.date.length > 1){
                dateList.map((item1: any) => {
                    let count = 0;
                    item.date.map((item2: any) => {
                        if(item1.start != item2.start && item1.end != item2.end){
                            count++;
                            if(count == dateList.length){
                                console.log(count, dateList.length, item1.start, item2.start);
                                dateList.push(item2)
                            }
                        }
                    })
                })
            }else{
                let newcount = 0;
                dateList.map((item1: any) => {
                    item.date.map((item2: any) => {
                        if(item1.start != item2.start && item1.end != item2.end){
                            newcount++;
                            if(newcount == dateList.length){
                                console.log(newcount, dateList.length, item1.start, item2.start);
                                dateList.push(item2)
                            }
                        }
                    })
                })
            }
        }
        this.setState({
            dateList
        })
    }

    addNull = () => {
        let dateList: any = this.state.dateList;
        dateList.push({start: null,end: null,fix: true});
        this.setState({
            dateList
        })
    }

    deleteDate = (idx: number) => {
        let dateList: any = this.state.dateList;
        dateList.splice(idx, 1)
        this.setState({
            dateList
        })
    }

    // 分类
    sortChange = (e:any) => {
        this.setState({
            cat_id: e,
            currentPage: 1
        }, () => {
            this.filterList()
        })
    }

    rowSelection: any = {
        onSelect: (record: any, selected: any, selectedRows: any) => {
            let goodsList:any = this.state.goodsList;
            goodsList.map((item: any) => {
                if(item.id === record.id){
                    item.select = selected;
                }
            })
            this.setState({
                goodsList
            })
        },
        onSelectAll: (selected: any, selectedRows: any, changeRows: any) => {
            let goodsList:any[] = this.state.goodsList;

            goodsList.map((item: any) => {
                changeRows.map((item2: any) => {
                    if(item.id === item2.id){
                        item.select = selected;
                    }
                })
            })

            this.setState({
                goodsList
            })
        },
        type: 'checkbox',
        getCheckboxProps(record: any) {
            return {
                defaultChecked: record.select // 配置默认勾选的列
            }
        }
    };

    handleGoodsOk = (e:any) => {
        let goodsList:any[] = this.state.goodsList;

        let favour: any = [];
        goodsList.map((item:any) => {
            if(item.select){
                favour.push({
                    dish_id: item.id,
                    standard: 0,
                    name: item.name,
                    price: item.price,
                })
            }
        })
        if(this.state.present_type == 1 && (this.state.goodsFavour.length == 1 || favour.length > 1)){
            message.info('只能选择一个菜品')
            return;
        }
        this.setState({
            goodsVisible: false,
            goodsFavour: favour
        });
    };

    deleteGoodsDish = (id: number) => {
        if(this.state.edit){
            return;
        }
        let { goodsFavour, goodsList } = this.state;
        goodsFavour.map((item:any, index: number) => {
            if(item.dish_id === id){
                goodsFavour.splice(index,1)
            }
        })

        goodsList.map((item: any) => {
            if(item.dish_id === id){
                item.select = false;
            }
        })

        this.setState({
            goodsFavour,
            goodsList
        })
    }

    render(){
        const {
            edit,
            name,
            user_type,
            amount,
            position,
            sort,
            status,
            receive_type,
            receive_limit,
            shop_ids,
            weeks,
            festival,
            dateList,
            allDate,
            week,
            sortList,
            goodsFavour,
            present_type,
            editorState,
            can_use_time,
            notice_day,
            valid_day,
            limit_part, can_use_total_num, can_trans, validText, day, start_time, end_time,  favour,
         } = this.state;

        let columns = [
            {
                title: '门店名称',
                dataIndex: 'name',
                key: 'name',
                className: 'list_center',
            },
            {
                title: '门店地址',
                dataIndex: 'address',
                key: 'address',
                className: 'list_center',
            }
        ];

        let rowSelection: any = {
            selectedRowKeys: shop_ids,
            onSelect: (record: any, selected: any, selectedRows: any) => {
                let productList:any = this.state.productList;
                productList.map((item: any) => {
                    if(item.id === record.id){
                        item.select = selected;
                    }
                })
                this.setState({
                    productList
                })
            },
            onSelectAll: (selected: any, selectedRows: any, changeRows: any) => {
                let productList:any[] = this.state.productList;
                productList.map((item: any) => {
                    changeRows.map((item2: any) => {
                        if(item.id === item2.id){
                            item.select = selected;
                        }
                    })
                })

                this.setState({
                    productList
                })
            },
            onChange: (selectedRowKeys:any, selectedRows: any) => {
                this.setState({
                    shop_ids: selectedRowKeys
                })
            },
            type: 'checkbox',
        };

        return <div className='l_content'>
                <div className="edit_title">优惠基本信息</div>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">优惠名称</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <Input placeholder="请输入优惠名称" maxLength={10} name='name' value={name} onChange={(e) => this.inputChange(e)}/>
                    </Col>
                </Row>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">推广渠道</span>
                        </div>
                    </Col>
                    <Col span={14}>
                        <Row type="flex" align="middle">
                            <Col>
                                <Radio.Group
                                    name='position'
                                    value={position}
                                    onChange={(e) => this.radioChange(e)}
                                    disabled={edit}
                                >
                                    <Radio value={1}>领券中心</Radio>
                                    {/* <Radio value={2}>领券中心</Radio> */}
                                    <Radio value={3}>礼包渠道</Radio>
                                    <Radio value={5}>内部渠道</Radio>
                                </Radio.Group>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="color_black">用券时间</span>
                        </div>
                    </Col>
                    <Col span={14}>
                        <Row type="flex" align="middle">
                            <span>领取</span>
                            <Input addonAfter="天" style={{ width: 120 }} name='can_use_time' type="number" className="mlr10" value={can_use_time} onChange={this.inputChange} />
                            <span>后使用</span>
                            <span className="mlr10" >券有效期</span>
                            <Input addonAfter="天" style={{ width: 120 }} name='valid_day' type="number" value={valid_day} onChange={this.inputChange} />
                        </Row>
                    </Col>
                </Row>

                {
                    !can_use_time || can_use_time == 0
                    ? <Row className="mb30" type="flex" align="middle">
                        <Col span={2}>
                            <div>
                                <span className="star"></span>
                                <span className="color_black">有效时间</span>
                            </div>
                        </Col>
                        <Col span={14}>
                        <Radio.Group
                                name='validText'
                                value={validText}
                                onChange={(e) => this.radioChange(e)}
                            >
                                <Radio value='time'>固定时间</Radio>
                                <Radio value='day'>相对时间</Radio>
                            </Radio.Group>
                        </Col>
                    </Row>
                    : null
                }

                {
                    !can_use_time || can_use_time == 0 ?
                    validText === 'time' ?
                    <Row className="mb30" type="flex" align="middle">
                        <Col span={2}></Col>
                        <Col span={14}>
                            <Row type="flex" align="middle">
                                <RangePicker onChange={this.timeChange} format="YYYY-MM-DD HH:mm:ss" placeholder={start_time !== '' ? [start_time, end_time] :["开始时间","结束时间"]} />
                            </Row>
                        </Col>
                    </Row>
                    : <Row className="mb30" type="flex" align="middle">
                        <Col span={2}></Col>
                        <Col span={14}>
                            <Row type="flex" align="middle">
                                <span>领取</span>
                                <Input placeholder="0" maxLength={10} addonAfter="天" style={{ width: 120 }} name='day' type="number" className="mlr10" value={day} onChange={this.inputChange} />
                                <span>内使用</span>
                            </Row>
                        </Col>
                    </Row>
                    : null
                }

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">类型</span>
                        </div>
                    </Col>
                    <Col span={14}>
                    <Radio.Group
                            name='present_type'
                            value={present_type}
                            onChange={(e) => this.radioChange(e)}
                            disabled={edit}
                        >
                            <Radio value={1}>单菜品</Radio>
                            <Radio value={2}>多菜品</Radio>
                        </Radio.Group>
                    </Col>
                </Row>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">选择商品</span>
                        </div>
                    </Col>
                    <Col span={2}>
                        <div className='plus_wrap'>
                            <Icon type="plus" style={{fontSize: 30, color: '#d4d4d4'}} onClick={() => {if(edit){return;}this.setState({goodsVisible: true})}}/>
                        </div>
                    </Col>
                </Row>
                {
                    present_type == 2
                    ? <Row className="mb30" type="flex" align="middle">
                        <Col span={2} offset={2}>
                            <div>
                                <span className="star"></span>
                                <span className="color_black">可兑换数量</span>
                            </div>
                        </Col>
                        <Col span={2}>
                            <Input value={limit_part} name='limit_part' disabled={edit} onChange={(e) => this.inputChange(e)} addonAfter='份' type='number' />
                        </Col>
                        <Col span={1} offset={1}>
                            <Tooltip placement="topLeft" title={"如兑换菜品5选3，则可兑换数量设为3"}>
                                <Icon type="question-circle" />
                            </Tooltip>
                        </Col>
                    </Row>
                    :null
                }
                {
                    goodsFavour && goodsFavour.length > 0
                    ? <Row>
                        <Col offset={2}>
                            <div className='present_good good_title'>
                                <div>商品名</div>
                                <div>金额（元）</div>
                                {present_type == 1 ? <div>数量</div> : null}
                                <div>操作</div>
                            </div>
                            {
                                goodsFavour.map((item: any, index:number) => {
                                    return <div key={item.dish_id} className='present_good' style={{}}>
                                            <div>{item.name}</div>
                                            <div>{item.price}</div>
                                            {
                                                present_type == 1
                                                ?  <div>
                                                        <Input value={limit_part} disabled={edit} name={'limit_part'} style={{width: '100px'}} onChange={(e) => this.inputChange(e)} />
                                                    </div>
                                                : null
                                            }

                                            <div>
                                                <Button onClick={() => this.deleteGoodsDish(item.dish_id)}>删除</Button>
                                            </div>
                                        </div>
                                })
                            }
                        </Col>
                    </Row>
                    : null
                }

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">桌台使用</span>
                        </div>
                    </Col>
                    <Col span={4}>
                        <Input value={can_use_total_num} name='can_use_total_num' disabled={edit} onChange={(e) => this.inputChange(e)} addonAfter='张' type='number'/>
                    </Col>
                    <div className='ml20'>-1表示无上限使用</div>
                </Row>

                {
                    position == 3 || position == 5
                    ? ''
                    : <Row className="mb30" type="flex" align="middle">
                        <Col span={2}>
                            <div>
                                <span className="star"></span>
                                <span className="color_black">发行数量</span>
                            </div>
                        </Col>
                        <Col span={2}>
                            <Input name='amount' value={amount} onChange={(e) => this.inputChange(e)} addonAfter='张'/>
                        </Col>
                        <Col>
                            <div className='tips mlr10'>创建成功后不能修改，请谨慎操作</div>
                        </Col>
                    </Row>
                }

                {
                    position != 3 && position != 5
                    ? <Row className="mb30" type="flex" align="middle">
                        <Col span={2}>
                            <div>
                                <span className="color_black">排序</span>
                            </div>
                        </Col>
                        <Col span={2}>
                            <Input name='sort' value={sort} onChange={(e) => this.inputChange(e)} />
                        </Col>
                        <Col>
                            <div className='tips mlr10'>数字越大，排序越靠前</div>
                        </Col>
                    </Row>
                    : null
                }

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">状态</span>
                        </div>
                    </Col>
                    <Col span={14}>
                        <Row type="flex" align="middle">
                            <Col>
                                <Radio.Group
                                    name='status'
                                    value={status}
                                    onChange={(e) => this.radioChange(e)}
                                >
                                    <Radio value={2}>启用</Radio>
                                    <Radio value={0}>停用</Radio>
                                </Radio.Group>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <div className="edit_title">使用设置</div>

                {/* <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="color_black">可用门店</span>
                        </div>
                    </Col>
                    <Col span={14}>
                        <Row type="flex" align="middle">
                            <Col>
                                <Radio.Group
                                    name='shop_type'
                                    value={shop_type}
                                    onChange={(e) => this.radioChange(e)}
                                >
                                    <Radio value={1}>全部门店</Radio>
                                    <Radio value={2}>部分门店</Radio>
                                </Radio.Group>
                            </Col>
                            {
                                shop_type === 2
                                ? <Col>
                                        <Button icon='plus' onClick={() => {this.setState({visible: true})}}>选择门店</Button>
                                    </Col>
                                : null
                            }
                        </Row>
                    </Col>
                </Row>
                {
                    shop_type === 2 && favour.length > 0
                    ? <Row>
                        <Col span={12} offset={2}>
                            <Table
                                dataSource={this.state.favour}
                                columns={[
                                    {
                                        title: '门店名称',
                                        dataIndex: 'name',
                                        key: 'name',
                                        className: 'list_center',
                                    },
                                    {
                                        title: '门店地址',
                                        dataIndex: 'address',
                                        key: 'address',
                                        className: 'list_center',
                                    },
                                    {
                                        title: '操作',
                                        dataIndex: 'action',
                                        key: 'action',
                                        className: 'list_center',
                                        render: (rowdata:any, record: any, idx: number) => {
                                            return <div className='list_btn' onClick={() => this.deleteDish(record.id)}>删除</div>
                                        }
                                    }
                                ]}
                                rowKey={"id"}
                                size="middle"
                                locale={{emptyText: '暂无数据'}}
                                pagination={{
                                    showQuickJumper:true,
                                    defaultCurrent: 1,
                                    pageSize: 20,
                                }}/>
                        </Col>
                    </Row>
                    : null
                } */}

                {
                     !can_use_time || can_use_time == 0
                     ? <Row className="mb30" type="flex" align="middle">
                            <Col span={2}>
                                <div>
                                    <span className="color_black">不可用日期</span>
                                </div>
                            </Col>
                            <Col span={14}>
                                <Row type="flex" align="middle">
                                    <Col>
                                        <Radio.Group
                                            name='allDate'
                                            value={allDate}
                                            onChange={(e) => this.radioChange(e)}
                                        >
                                            <Radio value={true}>所有日期都可用</Radio>
                                            <Radio value={false}>部分日期不可用</Radio>
                                        </Radio.Group>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                     : null
                }

                {
                    !allDate
                    ? <Row>
                        <Col offset={2}>
                            <Row>
                                <Checkbox.Group style={{ width: '100%' }} onChange={this.weekChange} value={week}>
                                    <Row>
                                        {weeks && weeks.map((item: any) => {
                                            return <Col span={1}>
                                                <Checkbox value={item.value}>{item.name}</Checkbox>
                                            </Col>
                                        })}
                                    </Row>
                                </Checkbox.Group>
                            </Row>
                            <div className='sub_title'>指定不可用时间（点击某个节日后，相应日期会自动被添加到下方日期栏中。）</div>
                            <Row>
                                <Col span={10}>
                                    <Row type='flex'>
                                        {
                                            festival.map((item: any, index:number) => {
                                                return <Col span={index > 0 ? 4 : 20} className='festival_item' onClick={() => this.addDate(item)}>{item.name}</Col>
                                            })
                                        }
                                    </Row>
                                </Col>
                            </Row>

                            <div className='sub_title'>手动添加</div>
                            {

                                dateList && dateList.map((day: any, idx: number) => {
                                    return <Row type='flex' align='middle' style={{margin:'15px 0'}} key={JSON.stringify(day)}>
                                            <Col>
                                            <DatePicker
                                               onChange={(date, dateString) => this.onCalendarChange(date, dateString, idx, 'start')}
                                                placeholder='选择日期'
                                                disabled={!day.fix}
                                                defaultValue={day.start ? moment(day.start, 'YYYY-MM-DD') : null}
                                                />
                                        </Col>
                                        <Col className='mlr10'>至</Col>
                                        <Col>
                                            <DatePicker
                                                onChange={(date, dateString) => this.onCalendarChange(date, dateString, idx, 'end')}
                                                placeholder='选择日期'
                                                disabled={!day.fix}
                                                defaultValue={day.end ? moment(day.end, 'YYYY-MM-DD') : null}
                                                />
                                        </Col>
                                        <Col className='mlr10' onClick={() => this.deleteDate(idx)}>删除</Col>
                                    </Row>
                                })
                            }

                            <div className='add_btn' onClick={this.addNull}>新增</div>
                        </Col>
                    </Row>
                    : null
                }

                {
                    position == 5
                    ? ''
                    : <Row className="mb30" type="flex" align="middle">
                        <Col span={2}>
                            <div>
                                <span className="color_black">消费继续领券</span>
                            </div>
                        </Col>
                        <Col span={14}>
                            <Row type="flex" align="middle">
                                <Col>
                                    <Radio.Group
                                        name='receive_type'
                                        value={receive_type}
                                        onChange={(e) => this.radioChange(e)}
                                    >
                                        <Radio value={1}>每次消费</Radio>
                                        <Radio value={2}>消费满</Radio>
                                        {
                                            receive_type === 2
                                            ?   <Col>
                                                    <div className="limit_tip">
                                                        <Input placeholder="0" name='receive_limit' addonAfter="元" style={{ width: 120 }} value={receive_limit} type="number" onChange={(e) => this.inputChange(e)} />
                                                        <span>可继续领取</span>
                                                    </div>
                                                </Col>
                                            : null
                                        }
                                        <Radio value={3}>一次性领取</Radio>
                                    </Radio.Group>
                                </Col>

                            </Row>
                        </Col>
                    </Row>
                }

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="color_black">是否支持赠送</span>
                        </div>
                    </Col>
                    <Col span={14}>
                        <Row type="flex" align="middle">
                            <Col>
                                <Radio.Group
                                    name='can_trans'
                                    value={can_trans}
                                    onChange={(e) => this.radioChange(e)}
                                >
                                    <Radio value={false}>不支持赠送</Radio>
                                    <Radio value={true}>支持赠送</Radio>
                                </Radio.Group>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {
                    position == 3 || position == 5
                    ? ''
                    : <Row className="mb30" type="flex" align="top">
                        <Col span={2}>
                            <div>
                                <span className="star"></span>
                                <span className="color_black">可见用户</span>
                            </div>
                        </Col>
                        <Col span={6}>
                            <Tree
                                checkable
                                showIcon
                                onCheck={this.onCheck}
                                defaultSelectedKeys={user_type}
                                checkedKeys={user_type}
                            >
                                <TreeNode title="新用户" key="1" icon={
                                    <Tooltip placement="topLeft" title={"还未注册的用户或已经注册还没有下单的用户"}>
                                    <Icon type="question-circle" />
                                    </Tooltip>
                                }>
                                </TreeNode>
                                <TreeNode title="老顾客" key="4" icon={
                                    <Tooltip placement="topLeft" title={"交易次数>=1次的交易次数"}>
                                    <Icon type="question-circle" />
                                    </Tooltip>
                                }>
                                    <TreeNode title="充值用户" key="2" />
                                    <TreeNode title="非充值用户" key="3" />
                                </TreeNode>
                            </Tree>
                        </Col>
                    </Row>
                }

                <div className="wrap">
                    <Row>
                        <Col span={2}>
                            <div>
                                <span className="star"></span>
                                <span className="color_black">使用须知</span>
                            </div>
                        </Col>
                        <Col span={14}>
                            <div className="editor_wrap">
                                <BraftEditor
                                    value={editorState}
                                    onChange={this.handleEditorChange}
                                    excludeControls={['media']}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>

                <Row>
                    <Col span={18}>
                        <div className="bottom_wrap">
                            <Button type="primary" ghost onClick={this.goBack}>
                                取消
                            </Button>
                            <Button type="primary" onClick={(e) => this.handleSubmit(e)}>
                                保存
                            </Button>
                        </div>
                    </Col>
                </Row>


                <Modal
                    title="指定门店"
                    visible={this.state.visible}
                    okText='确定'
                    cancelText='取消'
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    >
                    <Row type="flex" align="middle" justify="start" className="mb20" >
                        <Col className="mr30">
                            <Input placeholder="请输入关键字" onChange={this.searchAction} style={{ width: 234 }}/>
                        </Col>
                    </Row>

                    <div>
                        <Table
                        dataSource={this.state.showProductList}
                        columns={columns}
                        rowKey={"id"}
                        size="middle"
                        locale={{emptyText: '暂无数据'}}
                        rowSelection={rowSelection}
                        pagination={{
                            current: this.state.currentPage,
                            showQuickJumper:true,
                            defaultCurrent: 1,
                            total: this.state.productCount,
                            pageSize: this.state.pageSize,
                            hideOnSinglePage: true,
                            onChange: this.orderChange
                        }}/>
                    </div>
                </Modal>

                <Modal
                    title=""
                    visible={this.state.goodsVisible}
                    onOk={this.handleGoodsOk}
                    onCancel={() => {this.setState({
                        goodsVisible: false
                    })}}
                    >
                    <Row type="flex" align="middle" justify="start" className="mb20" >
                        <Col className="mr30">
                            <span>商品名称：</span>
                            <Input placeholder="请输入商品名称" onChange={this.goodsAction} style={{ width: 234 }}/>
                        </Col>
                    </Row>

                    <Row type="flex" align="middle" justify="start" className="mb20" >
                        <Col className="">
                            <span>商品分类：</span>
                            <Select style={{ width: 150 }} onChange={this.goodsChange} placeholder="请选择商品分类">
                                {
                                    sortList.map((item:any) => {
                                        return <Option key={item.id} value={item.id} >{item.name}</Option>
                                    })
                                }
                            </Select>
                        </Col>
                    </Row>

                    <div>
                        <Table
                        dataSource={this.state.showGoodsList}
                        columns={this.columns}
                        rowKey={"id"}
                        size="middle"
                        locale={{emptyText: '暂无数据'}}
                        rowSelection={this.rowSelection}
                        pagination={{
                            current: this.state.currentPage,
                            showQuickJumper:true,
                            defaultCurrent: 1,
                            total: this.state.goodsCount,
                            pageSize: this.state.pageSize,
                            hideOnSinglePage: true,
                            onChange: this.goodPageChange
                        }}/>
                    </div>
                </Modal>

        </div>;
    }

}
export default ChangeDishEdit