import React from "react";
import { Table, Modal, message, Row, Col, Button, Select, DatePicker, Input} from "antd";
import { postRequest, getRequest } from '../../api/http';
import { apiUrl } from '../../api/api_url';
import { Link } from "react-router-dom";
import { getIsPermisson, messageCustom } from "../../utils/permisstionUtil";

const { Search } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;

const key = 'updatable';

class chargeFree extends React.Component<any> {
    state = {
        equityList: [],
        current: 1,
        pageSize: 10,
        total_count: 0,
        userList: [],
        start_time: '',
        end_time: '',
        add_user: 0,
        status: null,
        name: ''
    }

    columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            className: 'list_center',
        },
        {
            title: '优惠名称',
            dataIndex: 'name',
            key: 'name',
            className: 'list_center',
        },
        // {
        //     title: '活动奖品',
        //     dataIndex: 'coupon',
        //     key: 'coupon',
        //     className: 'list_center',
        //     render: (text: string, record: any) => {
        //         return <div>
        //             {
        //                 record.coupon.map((item: any) => {
        //                     return <div key={item.id}>{item.name} ， </div>
        //                 })
        //             }
        //         </div>
        //     }
        // },
        {
            title: '有效期',
            dataIndex: 'expire_info',
            key: 'expire_info',
            className: 'list_center',
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            className: 'list_center',
            render: (text:any,record:any,) => (
                <div>{record.status === 1 ? '暂停中' : '启用中'}</div>
            ),
        },
        {
            title: '最多参与',
            dataIndex: 'participate_time',
            key: 'participate_time',
            className: 'list_center',
            render: (text:any,record:any,) => (
                <div>{record.participate_time === -1 ? '无限' : `${record.participate_time}次`}</div>
            ),
        },
        {
            title: '活动用户',
            dataIndex: 'status',
            key: 'status',
            className: 'list_center',
            render: (text:any,record:any,) => (
                <div>{record.user_type_txt}</div>
            ),
        },
        {
            title: '创建时间',
            dataIndex: 'created_at',
            key: 'created_at',
            className: 'list_center',
        },
        {
            title: '创建者',
            dataIndex: 'add_user',
            key: 'add_user',
            className: 'list_center',
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            className: 'list_center',
            render: (text:any,record:any,) => (
                <div className="btn_wrap">
                    <div className="list_btn" onClick={() =>{
                        if(!getIsPermisson('1_12_9_2')){
                            messageCustom();
                            return;
                        }
                        this.props.history.push({pathname:'/sidebar/chargeFreeEdit', state:{id: record.id}})
                    }}>编辑</div>
                    {
                        record.status === 2
                        ? <div className="list_btn" onClick={() => this.pauseList(record)}>停用</div>
                        : <div className="list_btn" onClick={() => this.startList(record)}>启用</div>
                    }
                </div>
            ),
        },
    ];

    componentDidMount(){
        this.onInitList();
    }

    // 初始化列表
    onInitList = async () => {
        let { current, pageSize, add_user, name, status, start_time, end_time } = this.state;
        const params:any = {
            current,
            pageSize,
        };
        if(name !== ''){
            params.name = name;
        }
        if(add_user){
            params.add_user = add_user;
        }
        if(status){
            params.status = status;
        }
        if(start_time !== ''){
            params.start_time = start_time;
            params.end_time = end_time;
        }
        const res:any = await getRequest(apiUrl.orderActivityList,params);
        if(res.code === 1){
            this.setState({
                equityList: res.data.list,
                total_count: res.data.total_count,
                userList: res.data.add_user
            })
        }
    }

    pageChange = async (page:number) => {
        this.setState({
            current: page
        }, () => {
            this.onInitList()
        })
    }

    // 暂停福利
    pauseList = (record:any) => {
        if(!getIsPermisson('1_12_9_3')){
            messageCustom();
            return;
        }
        Modal.confirm({
            title: '停用',
            content: `设为停用后，用户将无法参与该优惠，是否确认停用？`,
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                const params = {
                    id: record.id,
                    status: record.status == 1 ? 2 : 1
                }
                const res:any = await postRequest(apiUrl.orderActivityStatus ,params);
                if(res.code === 1){
                    message.success({ content: '停用成功!', key, duration: 2 });
                }
                this.onInitList();
            },
            onCancel() {
            },
        });
    }

    // 开启福利
    startList = (record:any) => {
        if(!getIsPermisson('1_12_9_3')){
            messageCustom();
            return;
        }
        Modal.confirm({
            title: '启用',
            content: `设为开启后，用户可参与该优惠，是否确认启用？`,
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                const params = {
                    id: record.id,
                    status: record.status == 1 ? 2 : 1
                }
                const res:any = await postRequest(apiUrl.orderActivityStatus,params);
                if(res.code === 1){
                    message.success({ content: '启用成功!', key, duration: 2 });
                }
                this.onInitList();
            },
            onCancel() {
            },
        });
    }

    searchCoupon = (name: string) => {
        this.setState({
            name
        }, () => {
            this.onInitList();
        })
    }

    statusChange = (status: string) => {
        this.setState({
            status
        }, () => {
            this.onInitList()
        })
    }

    userChange = (e: any) => {
        this.setState({
            add_user: e
        }, () => {
            this.onInitList();
        })
    }

    timeChange = (time: any, timeString: [any, any]) => {
        this.setState({
            start_time: timeString[0],
            end_time: timeString[1]
        }, () => {
            this.onInitList();
        })
    }

    copyCoupon = async (id: number) => {
        const res: any = await postRequest(apiUrl.copyWelfare, {id})
        if(res.code === 1){
            message.success('已复制');
            this.props.history.push({
                pathname: '/sidebar/newPeopleEdit',
                state: {
                  id: id
                }
            });
        }
    }

    statusCoupon = async (id: number, status: number) => {
        const res: any = await  postRequest(apiUrl.changeVoucherStatus, {id, status})
        if(res.code === 1){
            message.success('修改成功');
            this.onInitList();
        }
    }

    //
    requestGetMemberList = async (data: any, type: any) => {
        if(type == 0) {
            //setSearchName({nickname: data.nickname})
        } else {
            //setSearchState({is_effect: data.is_effect})
        }
        //getList(currenPage,data);
    };

    render(){
        let { equityList, total_count, pageSize, current, userList} = this.state;
        return <div className='l_content'>
            <Row type="flex" align="middle" justify="start" className="mt20 mb40" >
                <Col span={2} className="mr30">
                    <Button type="primary" size="large" icon="plus" onClick={() =>{{
                            if(!getIsPermisson('1_12_9_1')){
                                messageCustom();
                                return;
                            }
                            this.props.history.push('/sidebar/ChargeFreeEdit')
                        }}}>
                            添加免单
                        </Button>
                </Col>
                <Col className="mr30">
                    <span>优惠名称：</span>
                    <Search placeholder="请输入券名称" onSearch={this.searchCoupon} style={{ width: 234 }}/>
                </Col>
                <Col className="mr30">
                    <span>状态：</span>
                    <Select placeholder="请选择状态" allowClear style={{ width: 120 }} onChange={this.statusChange}>
                        <Option value="2">已启用</Option>
                        <Option value="1">已停用</Option>
                        <Option value="3">已过期</Option>
                    </Select>
                </Col>
                {/* <Col className="mr30">
                    <span>创建者：</span>
                    <Select defaultValue="全部" style={{ width: 120 }} onChange={this.userChange}>
                        {
                            userList.map((item: any) => {
                                return <Option value={item.key} key={item.key}>{item.value}</Option>
                            })
                        }
                    </Select>
                </Col> */}
                <Col className="mr30">
                    <span>创建时间：</span>
                    <RangePicker onChange={this.timeChange} placeholder={["开始时间","结束时间"]} format="YYYY-MM-DD"/>
                </Col>
            </Row>

            <div>
                <Table dataSource={equityList}  size="middle" columns={this.columns} rowKey={'id'} locale={{emptyText: '暂无数据'}} pagination={{
                    total: total_count,
                    pageSize,
                    onChange: this.pageChange,
                    current,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    showTotal: (total: number) => `共${total}条`,
                    onShowSizeChange: (current:number, size: number) => {this.setState({pageSize: size}, () => {this.onInitList();})}
                }} />
            </div>
        </div>;
    }
}
export default chargeFree