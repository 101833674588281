import React from 'react';
import { Row, Col } from 'antd';
import '../management.scss';

import winAddCount1 from '../../../../../../assret/images/winAddCount1.png';
import winAddCount2 from '../../../../../../assret/images/winAddCount2.png';

interface HelpMbaCoupon {
    history: any,
}

class HelpMbaCoupon extends React.Component<HelpMbaCoupon> {

    render() {
        return  <Row className="typeDetail">
                    <Col className="typeDetail-title">一、功能介绍</Col>
                    <Col className="typeDetail-col">所有需要投放的代金券，必须要在代金券管理列表中添加代金券，主要帮助商家拉新促活促复购。</Col>
                    <Col className="typeDetail-title">二、功能路径</Col>
                    <Col className="typeDetail-col">电脑端登录后台-营销管理-代金券列表-添加代金券。</Col>
                    <Col className="typeDetail-col">
                        <img src={winAddCount1} />
                    </Col>
                    <Col className="typeDetail-title">三、操作流程</Col>
                    <Col className="typeDetail-col">1、<span className="txt-bold">添加代金券</span>，在“代金券列表”界面下点击“添加”</Col>
                    <Col className="typeDetail-col">
                        <img src={winAddCount2} />
                    </Col>
                    <Col className="typeDetail-col">1、代金券名称：填写名称</Col>
                    <Col className="typeDetail-col">2、有效期：目前只有永久</Col>
                    <Col className="typeDetail-col">3、抵扣条件：最低消费满多少元（如不选择，则默认“无门槛”）</Col>
                    <Col className="typeDetail-col">4、券面价值：填写券面价值</Col>
                    <Col className="typeDetail-col">5、售价：填写售价</Col>
                    <Col className="typeDetail-col">6、发行数量：填写发行数量</Col>
                    <Col className="typeDetail-col">7、限制每人领取：填写限制每人领取，填写1，用户只能购买1张</Col>
                    <Col className="typeDetail-col">8、允许叠加使用：默认选择不能叠加使用，可选择叠加使用，选择叠加时候，填写可叠加多少张</Col>
                    <Col className="typeDetail-col">9、使用须知：填写使用须知，在用户购买代金券后，用户点击使用规则显示</Col>
                </Row>
    }
}

export default HelpMbaCoupon;