import React, { useEffect, useState, useLayoutEffect } from "react";
import { getRequest, postRequest } from "./../../api/http";
import { apiUrl } from "./../../api/api_url";
import { useHistory } from 'react-router-dom';
import './stockout.scss'
import { Icon, Table, Pagination, message, Button, Checkbox, Menu, Modal } from "antd";
import Search from "antd/lib/input/Search";
import stock_over from '../../assret/images/stock_over_1.png'
import { getIsPermisson, messageCustom } from "../../utils/permisstionUtil";

const Stockout = ()=>{
    const history:any = useHistory();
    //返回桌面
    const orderingFoodReturn = ()=>{
        history.goBack()
    }

    const [current, setCurrent] = useState('');
    const [toBeList, settoBeList] = useState([]);
    const [stockList, setStockList] = useState([])

    useEffect(()=> {
        foodListRequest()
        stockListRequest()
        controPermisson()
    },[])

    const controPermisson = () =>{
        if(getIsPermisson('1_11_4_1')){
            setCurrent('to_be_sold')
            return;
          }
          if(getIsPermisson('1_11_4_2')){
            setCurrent('sold_out')
            return;
          }
    }


    const [originFoodAll,setoriginFoodAll] = useState<any>([])
    //请求列表
    const [foodAll,setfoodAll] = useState<any>([])

    const foodListRequest = async ()=> {
        let param = {}
        const res:any = await getRequest(`${apiUrl.outOfSomethingIndex}`,param);
        if(res.code === 1){
            setfoodAll(res.data)
            currentPageListRefresh(res.data)
            setoriginFoodAll(JSON.parse(JSON.stringify(res.data)))
            refreshAction()
        }
    }

    // 已沽清列表
    const stockListRequest = async () => {
        let res:any = await getRequest(apiUrl.stockOverList)
        if(res.code){
            setStockList(res.data)
            tableDataSourceAction(res.data)
        }
    }

    //用于刷新的方法
    const [refresh,setRefresh] = useState(false);
    const refreshAction = () => {
        if(refresh === true){
            setRefresh(false)
        }else{
            setRefresh(true)
        }
    }
    useEffect(()=>{
        tableDataSourceAction(stockList)
    },[refresh])

    const tableDataSourceAction = (data:any) => {
        let list:any = []
        data.map((itemB:any) => {
            if(filterData.A){
                if((itemB.is_up === true && itemB.is_stock_over === true)){
                    list.push(itemB)
                }
            }
            if(filterData.B){
                if((itemB.is_up === false && itemB.is_stock_over === true)){
                    list.push(itemB)
                }
            }
            if(itemB.time){
                list.push(itemB)
            }
        })
        settableDataSource(list)
    }

    const [tableDataSource,settableDataSource] = useState([])

    const tableDataMinusAction = (id:number) => {
        let list = foodAll
        list.map((itemA:any,indexA:number) => {
            itemA.dish_list.map((itemB:any,indexB:number) => {
                if(itemB.id === id){
                    list[indexA].dish_list[indexB].residue = Math.max(0,list[indexA].dish_list[indexB].residue-1)
                    if(!list[indexA].dish_list[indexB].hasOwnProperty('time')){
                        if(list[indexA].dish_list[indexB].is_up){
                            list[indexA].dish_list[indexB].time = 1
                        }else{
                            list[indexA].dish_list[indexB].time = 2
                        }
                    }

                }
            })
        })
        setfoodAll(list)
        refreshAction()
    }

    const tableDataAddAction = (id:number) => {
        let list = foodAll
        list.map((itemA:any,indexA:number) => {
            itemA.dish_list.map((itemB:any,indexB:number) => {
                if(itemB.id === id){
                    list[indexA].dish_list[indexB].residue++
                    if(!list[indexA].dish_list[indexB].hasOwnProperty('time')){
                        if(list[indexA].dish_list[indexB].is_up){
                            list[indexA].dish_list[indexB].time = 1
                        }else{
                            list[indexA].dish_list[indexB].time = 2
                        }
                    }
                }
            })
        })
        setfoodAll(list)
        refreshAction()
    }


    const columns = [
        {
          title: '菜品',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: '数量',
          dataIndex: 'residue',
          key: 'residue',
          render: (rowdata: any, record: any, idx: number) => {
            return (
                current == 'to_be_sold' ?
                <div className="residue">
                    <div className="back_btn reduce_btn active" onClick={()=> {
                        tableDataMinusAction(record.id)
                    }}>-</div>
                    <div style={{paddingLeft:5,paddingRight:5}}>{rowdata}</div>
                    <div className="back_btn reduce_btn active" onClick={()=> {
                        tableDataAddAction(record.id)
                    }}>+</div>
                </div>
                :
                current == 'sold_out' ? <div style={{paddingLeft:5,paddingRight:5}}>{rowdata}</div>
                :<div></div>
            )
        },

        },
        {
          title: '状态',
          key: 'status',
          render: (rowdata: any, record: any, idx: number) => {
              if(rowdata.hasOwnProperty('time')){
                  return (
                    rowdata.time === 1 ? '今日估清' : rowdata.time === 2 ? '长期估清' : ''
                  )
              }else{
                return (
                    rowdata.is_up === true ? '今日估清' : rowdata.is_up === false ? '长期估清' : ''
                )
              }
          },
        },
        {
            title: '操作',
            key: 'edit',
            render: (rowdata: any, record: any, idx: number) => {
                return (
                    <Icon type="delete" style={{fontSize:24,color:'#ff0000'}} onClick={()=> {
                        tableDataDeleteAction(record.id)
                    }} />
                )
            },
          },
    ];

    const [searchInputText,setsearchInputText] = useState('')
    const searchAction = (text:string)=>{
        setcurrentPage(1)
        setsearchInputText(text)
    }

    useEffect(() => {
        currentPageListRefresh(foodAll)
    },[searchInputText])

    const [selectTypeId,setselectTypeId] = useState(0)
    const selectTypeIdAction = (id:number) => {
        setcurrentPage(1)
        setselectTypeId(id)
    }

    const [currentPageTotal,setcurrentPageTotal] = useState(0)
    const [currentPage,setcurrentPage] = useState(1)
    const [currentPageList,setcurrentPageList] = useState<any>([])
    const searchFoodName = (searchText:string,text:string) => {
            let test = ch2Unicdoe(searchText)
            var pattern2 = `/[${test}]+/g`;
            var reg = RegExp(eval(pattern2))
            return reg.test(text)
    }
    const isChinese = (s:string) =>{
        return /[\u4e00-\u9fa5]/.test(s);
    }
    const ch2Unicdoe =(str:string) =>{
        if(!str){
            return;
        }
        var unicode = '';
        for (var i = 0; i <  str.length; i++) {
            var temp = str.charAt(i);
            if(isChinese(temp)){
            unicode += '\\u' +  temp.charCodeAt(0).toString(16);
            }
            else{
            unicode += temp;
            }
        }
            return unicode;
    }
    const currentPageListRefresh = (data:any)=> {
        let total = 0
        let list:any = []
        let setList = []
        let pageSize = 12

        if(data.length < 0) {
        return
        }
        data.map((itemA:any) => {
        if(itemA.dish_list){
            itemA.dish_list.map((itemB:any) => {
            if(searchInputText === ''){
                if(selectTypeId === 0){
                list.push(itemB)
                }
                if(itemA.cat_id === selectTypeId){
                list.push(itemB)
                }

            }else{
                if(searchFoodName(searchInputText,itemB.name)){
                list.push(itemB)
                }
            }
            })
        }
        })


        if(currentPage === 1){
            setList = list.slice(0,pageSize)
        }else{
            setList = list.slice(((currentPage-1)*pageSize),(currentPage*pageSize))
        }

        setcurrentPageList(setList)
        setcurrentPageTotal(list.length)
    }

    const pageAction = (e:number) => {
        setcurrentPage(e)
    }
    useEffect(()=> {
        currentPageListRefresh(foodAll)
    },[currentPage,selectTypeId])


    //筛选长期和今日沽清 1：今日  2： 长期
    const [filterData,setfilterData] = useState({A:true,B:true})
    const filterDataAction = (num:number)=> {
        if(num === 1){
            setfilterData({A:!filterData.A,B:filterData.B})
        }
        if(num === 2){
            setfilterData({A:filterData.A,B:!filterData.B})
        }
        refreshAction()
    }

    //添加估清
    const addFoodAction = (type:number,id:any) => {
        let list = foodAll
        let arr:any = toBeList;
        foodAll.map((itemA:any,indexA:number) => {
            itemA.dish_list.map((itemB:any,indexB:number) => {
                if(itemB.id === id){
                    if(type === 1){
                        list[indexA].dish_list[indexB].time = 1
                    }
                    if(type === 2){
                        list[indexA].dish_list[indexB].time = 2
                    }
                    let result = arr.findIndex((item:any) =>{
                        return item.id == id
                    });
                    if(result < 0){
                        arr.unshift(itemB)
                    }
                }
            })
        })
        settoBeList(arr)
        refreshAction()
    }

    // 删除沽清
    const tableDataDeleteAction = async (id:number) => {
        if(current == 'to_be_sold'){
            toBeList.map((item:any, index: number) => {
                if(item.id == id){
                    toBeList.splice(index, 1)
                }
            })
            settoBeList(toBeList)
        }else{
            if(!getIsPermisson('1_11_4_2_1')){
                messageCustom();
                return;
              }
            Modal.confirm({
                title: '提示',
                content: '是否解除沽清',
                onOk: async () => {
                    let param = {
                        dish_id: id,
                    }
                    const res:any = await postRequest(`${apiUrl.cancelOutByDishId}`,param);
                    if(res.code === 1){
                        message.info('解除成功');
                        foodListRequest()
                        stockListRequest()
                    }
                },
            })
        }

        refreshAction()
    }

    // 保存沽清
    const saveStockoutAction = async ()=> {
        if(!getIsPermisson('1_11_4_1_3')){
            messageCustom();
            return;
          }
        let list:any = []
        foodAll.map((itemA:any) => {
            itemA.dish_list.map((itemB:any) => {
                if(itemB.hasOwnProperty('time')){
                    list.push({dish_id:itemB.id,is_up:itemB.time === 1 ? true : false,residue:itemB.residue})
                }
            })
        })
        let param = {
            list: list,
        }
        const res:any = await postRequest(`${apiUrl.outOfSomething}`,param);
        if(res.code === 1){
            message.info('沽清成功');
            foodListRequest()
            stockListRequest()
            settoBeList([])
        }
    }

    return(
        <div className="stockout l_content">
            <div className="left">
                <div className="top">
                    <div onClick={orderingFoodReturn} className='mr90'><Button type='primary' icon='left' size='large' style={{fontWeight: 'bold'}}>返回</Button></div>
                </div>

                <Menu onClick={(e) => {
                    if(e.key == 'to_be_sold'){
                        if(!getIsPermisson('1_11_4_1')){
                            messageCustom();
                            return;
                          }
                    }

                    if(e.key == 'sold_out'){
                        if(!getIsPermisson('1_11_4_2')){
                            messageCustom();
                            return;
                          }
                    }
                    setCurrent(e.key)
                }}
                selectedKeys={[current]}
                mode="horizontal"
                >
                    <Menu.Item key="to_be_sold">待沽清</Menu.Item>
                    <Menu.Item key="sold_out">已沽清</Menu.Item>
                </Menu>
                {
                    current == 'to_be_sold'
                    ? <div>
                        <div className="table">
                            <Table className="tableView" dataSource={toBeList} columns={columns} />
                        </div>
                        <div className="bottom">
                            <div className="bottom_left"></div>
                            <div className="bottom_right" onClick={()=>{saveStockoutAction()}}><div className="text">保存</div></div>
                        </div>
                    </div>
                    : 
                    current == 'sold_out' ?
                    <div>
                        <div className="select">
                            <div className="today" >
                                <Checkbox onChange={(e) => {filterDataAction(1)}} checked={filterData.A}>今日沽清</Checkbox>
                            </div>
                            <div className="long" >
                                <Checkbox onChange={(e) => {filterDataAction(2)}} checked={filterData.B}>长期沽清</Checkbox>
                            </div>
                        </div>
                        <div className="table">
                            <Table className="tableView" dataSource={tableDataSource} columns={columns} />
                        </div>
                    </div>
                    : <div/>
                }

            </div>
            <div className="content">
                <div className="search">
                    <div className="text">菜名</div>
                    <Search
                        placeholder="输入菜品名"
                        onSearch={(value:any) => {searchAction(value)}}
                        style={{ width: 200 }}
                        allowClear={true}
                    />
                </div>
                <div className="list">
                    {
                        currentPageList.map((item:any) => {
                            return (
                                    <div className={!item.is_up || (item.is_up && item.is_stock_over && item.residue == 0)? 'item sel' : 'item'} key={item.id} onClick={()=> {
                                        //如果已沽清，点击无效果
                                        if(!item.is_up || (item.is_up && item.is_stock_over && item.residue == 0)){
                                            message.warning('已沽清')
                                            return;
                                        }
                                        let list = foodAll
                                        list.map((itemA:any,indexA:number) => {
                                            itemA.dish_list.map((itemB:any,indexB:number) => {
                                                list[indexA].dish_list[indexB].action = false
                                                if(itemB.id === item.id){
                                                    if(itemB.hasOwnProperty('action')){
                                                        list[indexA].dish_list[indexB].action = !list[indexA].dish_list[indexB].action
                                                    }else{
                                                        list[indexA].dish_list[indexB].action = true
                                                    }
                                                }
                                            })
                                        })
                                        setfoodAll(list)
                                        refreshAction()
                                    }}>
                                    {
                                        item.action
                                        ? <div className="action">
                                            <div className="button" onClick={(e)=> {
                                                addFoodAction(1,item.id)
                                                e.stopPropagation()
                                            }}>今日估清</div>
                                            <div className="button"onClick={(e)=> {
                                                addFoodAction(2,item.id)
                                                e.stopPropagation()
                                            }}>长期估清</div>
                                        </div>
                                        : null
                                    }

                                    <img src={stock_over} className='stock_logo'/>

                                    <div className='one'>
                                        <div className=''>{item.name}</div>
                                    </div>
                                    <div className='price'>{item.has_specs === false ? `￥${item.price}` : ''}</div>
                                    {
                                        item.residue > 0
                                        ?
                                        <div className="num">剩{item.residue}</div>
                                        :
                                        <div></div>
                                    }
                                </div>
                            )
                        })
                    }
                    {
                        currentPageTotal > 11 ?
                        <div className="pageView">
                            <Pagination defaultCurrent={1} total={currentPageTotal} onChange={(a)=>{pageAction(a)}} pageSize={12} />
                        </div>
                        :
                        <div className='none'></div>
                    }

                    </div>
            </div>
            <div className="right">
                <div className="selectType">
                    <div className="prev">
                        <Icon type="up"  style={{fontSize: 20, color: '#ffffff'}} />
                    </div>
                    <div className="content">
                        <div className={selectTypeId === 0 ? 'item sel' : 'item'} onClick={()=>{selectTypeIdAction(0)}}>全部</div>
                        {
                            foodAll.map((item:any,index:number) => {
                            return(
                                <div className={selectTypeId === item.cat_id ? 'item sel' : 'item'} onClick={()=>{selectTypeIdAction(item.cat_id)}} key={item.cat_id}>
                                    <div className="text">{item.cat_name}</div>
                                </div>
                            )
                            })
                        }
                    </div>
                    <div className="next">
                        <Icon type="down" style={{fontSize: 20, color: '#ffffff'}} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Stockout