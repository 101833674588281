import React from "react";
import Nothing from "./components/nothing";
import { Button, Row, DatePicker, Select, Form, Input, Col } from "antd";
import TableList from "./components/table_list";
import { Link } from "react-router-dom";
import { getRequest } from "../../api/http";
import { apiUrl } from "../../api/api_url";
import { IFormData } from "./create_active/create_active";
import { FormComponentProps } from "antd/lib/form";

const { Option } = Select;
const { RangePicker } = DatePicker;

interface IAnyKey {
  [key: string]: any;
}

interface IState {
  data: {
    items: Array<IFormData>,
    total: number
  },
  page: number,
  filter: {
    status: number,
    name: string,
    date: string,
    create_start_at: string,
    create_end_at: string
  },
  isFilter: boolean
}

interface IDistributionProps extends FormComponentProps {}

class Distribution extends React.Component<IDistributionProps, IState> {
  state = {
    data: {
      items: [],
      total: 0
    },
    status: [
      {
        value: 0,
        text: "全部"
      },
      {
        value: 5,
        text: "预热中"
      },
      {
        value: 10,
        text: "进行中"
      },
      {
        value: 15,
        text: "暂停"
      },
      {
        value: 20,
        text: "结束"
      }
    ],
    page: 1,
    filter: {
      status: 0,
      name: '',
      date: '',
      create_start_at: '',
      create_end_at: ''
    },
    isFilter: false,
  };

  initData = async (current = 1, status = 0,) => {
    const data: IAnyKey = {
      pageSize: 30,
      current: current
    };

    const res: any = await getRequest(apiUrl.shareActivities, data);
    if (res && res.data) {
      if(this.state.page < 2) {
        this.setState({
          data: res.data,
        });
      } else {
        this.setState({
          data: {
            total: res.data.total,
            items: [...this.state.data.items, ...res.data.items]
          }
        })
      }
    }
  };

  activeStatusChange = (e: any) => {
    this.initData(this.state.page);
  };

  onFormChange = async (e: any, type: string) => {
    let { filter } = this.state;
    if(type !== 'date') {
      filter = {...filter, [type]: e}
    } else {
      filter['create_start_at'] = e[0].format('YYYY-MM-DD HH:mm:ss')
      filter['create_end_at'] = e[1].format('YYYY-MM-DD HH:mm:ss')
    }
    this.setState({ filter});
    const data = JSON.parse(JSON.stringify(filter));
    Object.keys(data).map(function(item) {
      if(!data[item]) delete data[item];
      return true;
    })
    const res:any = await getRequest(apiUrl.shareActivities, data);
    this.setState({
      data: {
        ...res.data,
      },
      isFilter: true
    })
  };

  componentDidMount() {
    this.initData();
  }

  render() {
    const { data, status, isFilter } = this.state;
    const { getFieldDecorator, getFieldError, isFieldTouched } = this.props.form;
    const statusErr = isFieldTouched("status") && getFieldError("status");

    return (
      <div className='l_content'>
        {data.items.length === 0 && !isFilter ? (
          <Nothing />
        ) : (
          <div>
            <Form >
              <Link to="/sidebar/create_active">
                <Button type="primary" icon="plus">
                  新建活动
                </Button>
              </Link>
              <Row type="flex" align="middle" style={{ marginTop: 20 }}>

                <Form.Item validateStatus={statusErr ? "error" : ""}>
                  <Row type="flex" align="middle">
                    <Col>活动状态 ：</Col>
                    <Col>
                    {getFieldDecorator("status", {
                      initialValue: status[0].text,
                      rules: [{ required: false }]
                    })(
                      <Select onChange={(e: any) => this.onFormChange(e, 'status')}>
                        {status.map(item => {
                          return (
                            <Option key={item.text} value={item.value}>
                              {item.text}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                    </Col>
                  </Row>
                </Form.Item>
                <div style={{ width: 20 }} />

                <Form.Item>
                  <Row type="flex" align="middle" >
                    <Col>活动名称 ：</Col>
                    <Col>{getFieldDecorator("name", {
                        rules: [{ required: false }]
                      })(
                        <Input
                          placeholder="input search text"
                          style={{ width: "300px" }}
                          onChange={e => this.onFormChange(e.target.value, 'name')}
                        />
                      )}
                    </Col>
                  </Row>
                </Form.Item>

                <div style={{ width: 20 }} />

                <Form.Item>
                  <Row type="flex" align="middle" >
                    <Col>创建时间 ：</Col>
                    <Col>{
                      getFieldDecorator("time", {
                      rules: [{ required: false }]
                      })(
                      <RangePicker
                        showTime={true}
                        showToday={true}
                        onChange={e => this.onFormChange(e, 'date')}
                      />)}
                    </Col>
                  </Row>
                </Form.Item>

              </Row>
            </Form>
            <div style={{ height: 40 }} />
            <TableList tableList={data} statusList={status} />
          </div>
        )}
        {/* <AddActive open={openAddActive} closeFun={() => setopenAddActive(false)}  /> */}
      </div>
    );
  }
}

export default Form.create({})(Distribution);

// Distribution