import React from "react";
import {Button, Row, Col, Input, Radio, Table, Modal, Select, message, Icon, Popconfirm} from "antd";
import { postRequest, getRequest } from '../../api/http';
import { apiUrl } from '../../api/api_url';

import styles from './preferentialSettings.module.scss';
import { getIsPermisson, messageCustom } from "../../utils/permisstionUtil";

const { Option } = Select;

const key = 'updatable';
export default class preferentialSettings extends React.Component<any> {

    state = {
        balance_use_limit: 0,
        charge_config: 0,
        charge_free_order_config: 0,
        coupon_charge_free_order_config: 0,
        cash_coupon_config: 1,
        waiter_coupon_config: 0,
        third_set_meal_config: 0,
        third_discount_config: 0,
        present_coupon_config: 0,
        id: 0,
    }

    componentDidMount() {
        this.onInitDate()
    }

    async onInitDate() {
        const res:any = await getRequest(apiUrl.getSystemCouponConfig);
        if(res['code'] === 1){
            this.setState({
                balance_use_limit: res.data.balance_use_limit,
                charge_config: res.data.charge_config,
                charge_free_order_config: res.data.charge_free_order_config,
                coupon_charge_free_order_config: res.data.coupon_charge_free_order_config,
                cash_coupon_config: res.data.cash_coupon_config,
                waiter_coupon_config: res.data.waiter_coupon_config,
                third_set_meal_config: res.data.third_set_meal_config,
                third_discount_config: res.data.third_discount_config,
                present_coupon_config: res.data.present_coupon_config,
                id: res.data.id
            })
        }
    }

    handleSubmit = async (val: any) => {

        const res:any = await postRequest(apiUrl.updateSystemCouponConfig,val);
        if(res['code'] === 1){
            message.success({ content: '修改成功!', key, duration: 2 });
            
        }else{
            message.error({ content: res['message'], key, duration: 2 });
        }

    };

    changeBusinessModel = (e: any) => {
        let name = e.target.name;
        let value = e.target.value;
        this.setState({
            [name]: value
        }, () => {
            let _obj = {
                id: this.state.id,
                [name]: value
            }
            this.handleSubmit(_obj)
        })
    }

    // 返回上一页
    goBack = () => {
        window.history.go(-1)
    }

    render() {

        const { present_coupon_config, balance_use_limit, charge_config, charge_free_order_config, coupon_charge_free_order_config, cash_coupon_config, waiter_coupon_config, third_set_meal_config, third_discount_config } = this.state;

        return (
            <div>
                <div className={styles.business_content}>
                    <div className="edit_title">充值设置</div>
                    <Row className="mb30" type="flex" align="middle">
                        <Col span={22}>
                            <Radio.Group
                                name="charge_config"
                                value={charge_config}
                                onChange={(e) => {
                                    if(!getIsPermisson('2_5_3_1')){
                                        messageCustom();
                                        return;
                                      }
                                    this.changeBusinessModel(e)
                                }}
                            >
                                <Radio value={1}>账户余额能和优惠券同时使用</Radio>
                                <Radio value={2}>账户余额不能和优惠券同时使用</Radio>
                            </Radio.Group>
                        </Col>
                    </Row>
                </div>
                <div className={styles.business_content}>
                    <div className="edit_title">账户余额使用设置</div>
                    <Row className="mb30" type="flex" align="middle">
                        <Col span={22}>
                            <Radio.Group
                                name="balance_use_limit"
                                value={balance_use_limit}
                                onChange={(e) => {
                                    if(!getIsPermisson('2_5_3_1')){
                                        messageCustom();
                                        return;
                                    }
                                    this.changeBusinessModel(e)
                                }}
                            >
                                <Radio value={1}>享受会员价可以使用账户余额支付</Radio>
                                <Radio value={2}>享受会员价不可以使用账户余额支付</Radio>
                            </Radio.Group>
                        </Col>
                    </Row>
                </div>
                {/* <div className={styles.business_content}>
                    <div className="edit_title">充值免单设置</div>
                    <Row className="mb30" type="flex" align="middle">
                        <Col span={22}>
                            <Radio.Group
                                name="charge_free_order_config"
                                value={charge_free_order_config}
                                onChange={(e) => this.changeBusinessModel(e)}
                            >
                                <Radio value={1}>充值免单金额能和优惠券同时使用</Radio>
                                <Radio value={2}>充值免单金额不能和优惠券同时使用</Radio>
                            </Radio.Group>
                        </Col>
                    </Row>
                </div> */}
                <div className={styles.business_content}>
                    <div className="edit_title">优惠券设置</div>
                    <Row className="mb30" type="flex" align="middle">
                        <Col span={22}>
                            <Radio.Group
                                name="coupon_charge_free_order_config"
                                value={coupon_charge_free_order_config}
                                onChange={(e) => {
                                    if(!getIsPermisson('2_5_3_2')){
                                        messageCustom();
                                        return;
                                      }
                                    this.changeBusinessModel(e)
                                }}
                            >
                                <Radio value={1}>使用优惠券能享受充值免单</Radio>
                                <Radio value={2}>使用优惠券不能享受充值免单</Radio>
                            </Radio.Group>
                        </Col>
                    </Row>
                </div>
                <div className={styles.business_content}>
                    <div className="edit_title">代金券设置</div>
                    <Row className="mb30" type="flex" align="middle">
                        <Col span={22}>
                            <Radio.Group
                                name="cash_coupon_config"
                                value={cash_coupon_config}
                                onChange={(e) => {
                                    if(!getIsPermisson('2_5_3_3')){
                                        messageCustom();
                                        return;
                                      }
                                    this.changeBusinessModel(e)
                                }}
                            >
                                <Radio value={1}>使用代金券能使用优惠券</Radio>
                                <Radio value={2}>使用代金券不能使用优惠券</Radio>
                            </Radio.Group>
                        </Col>
                    </Row>
                </div>
                <div className={styles.business_content}>
                    <div className="edit_title">服务员优惠设置</div>
                    <Row className="mb30" type="flex" align="middle">
                        <Col span={22}>
                            <Radio.Group
                                name="waiter_coupon_config"
                                value={waiter_coupon_config}
                                onChange={(e) =>{
                                    if(!getIsPermisson('2_5_3_4')){
                                        messageCustom();
                                        return;
                                      } 
                                    this.changeBusinessModel(e)
                                }}
                            >
                                <Radio value={1}>使用服务员优惠能使用优惠券</Radio>
                                <Radio value={2}>使用服务员优惠不能使用优惠券</Radio>
                            </Radio.Group>
                        </Col>
                    </Row>
                </div>
                <div className={styles.business_content}>
                    <div className="edit_title">第三方套餐设置</div>
                    <Row className="mb30" type="flex" align="middle">
                        <Col span={22}>
                            <Radio.Group
                                name="third_set_meal_config"
                                value={third_set_meal_config}
                                onChange={(e) => {
                                    if(!getIsPermisson('2_5_3_5')){
                                        messageCustom();
                                        return;
                                      } 
                                    this.changeBusinessModel(e)
                                }}
                            >
                                <Radio value={1}>使用第三方套餐能使用优惠券</Radio>
                                <Radio value={2}>使用第三方套餐不能使用优惠券</Radio>
                            </Radio.Group>
                        </Col>
                    </Row>
                </div>
                <div className={styles.business_content}>
                    <div className="edit_title">第三方抵扣券设置</div>
                    <Row className="mb30" type="flex" align="middle">
                        <Col span={22}>
                            <Radio.Group
                                name="third_discount_config"
                                value={third_discount_config}
                                onChange={(e) => {
                                    if(!getIsPermisson('2_5_3_6')){
                                        messageCustom();
                                        return;
                                      } 
                                    this.changeBusinessModel(e)
                                }}
                            >
                                <Radio value={1}>使用第三方抵扣券能使用优惠券</Radio>
                                <Radio value={2}>使用第三方抵扣券不能使用优惠券</Radio>
                            </Radio.Group>
                        </Col>
                    </Row>
                </div>
                <div className={styles.business_content}>
                    <div className="edit_title">兑换券设置</div>
                    <Row className="mb30" type="flex" align="middle">
                        <Col span={22}>
                            <Radio.Group
                                name="present_coupon_config"
                                value={present_coupon_config}
                                onChange={(e) => {
                                    if(!getIsPermisson('2_5_3_7')){
                                        messageCustom();
                                        return;
                                      } 
                                    this.changeBusinessModel(e)
                                }}
                            >
                                <Radio value={1}>使用兑菜券能使用优惠券</Radio>
                                <Radio value={2}>使用兑菜券不能优惠券</Radio>
                            </Radio.Group>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}