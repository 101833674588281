import { Row, Col, DatePicker, message, Avatar, Tooltip, Button, Table } from 'antd';
import React, { Component } from 'react';
import ReactEcharts from "echarts-for-react";
import { apiUrl } from '../../../../api/api_url';
import { getRequest, postRequest } from '../../../../api/http';
import { getIsPermisson, messageCustom } from '../../../../utils/permisstionUtil';

import styles from './userLevel.module.scss';

interface UserLevelInfo {
    history?: any,
}

class UserLevel extends Component<UserLevelInfo> {

    state = {
        option_new: {
            //height: 500,
            color: ['#FF4141'],
            tooltip: {
                trigger: 'axis',
                axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                    type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                }
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                top: '60px',
                containLabel: true
            },
            xAxis: [
                {
                    type: 'category',
                    data: ['普通用户', '黄金用户', '黄金用户', '钻石用户'],
                    axisTick: true,
                    axisLabel: {        
                        show: true,
                        textStyle: {
                            color: '#4A4A4A',
                            fontSize: '20'
                        }
                    },
                    axisLine:{
                        lineStyle:{
                            color:'#aaabb1',
                              width:1,   //这里是坐标轴的宽度,可以去掉
                        }
                    }
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    axisLabel: {        
                        show: true,
                        textStyle: {
                            color: '#A3A3A3',
                            fontSize:'16'
                        }
                    },
                    axisLine:{
                        lineStyle:{
                            color:'#aaabb1',
                              width:1,   //这里是坐标轴的宽度,可以去掉
                        }
                    }
                }
            ],
            series: [
                {
                    name: '普通用户',
                    type: 'bar',
                    barWidth: '60px',
                    data: []
                }
            ]
        },
        memberList: [],
        total: 0,
        paramsObj: {
            page: 1,
            pageSize: 5,
        },
        isLoading: false,
        pageSizeOptions: [
            '5','10','20','40'
        ],
        count: 0,
        haviorData: [],
        data_id_list: []
    }

    componentDidMount() {
        this._initDate()
        this.requestGetMemberList()
    }

    _initDate = async () => {
        let res: any = await getRequest(apiUrl.userRunLevel);
        if (res.code == 1) {
            let option_new: any = {
                color: ['#FF4141'],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                        type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                    },
                    // formatter(params: any) {
                    //     console.log('params:',params)
                    //     // let name = params.seriesName;
                    //     // let idx = params.componentIndex;
                    //     // let text = `<span style="color:#000000;">${name}:${params.data[idx+1]}%</span>`
                    //     // return text;
                    // },
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    top: '60px',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        data: res.data.xLine,
                        axisTick: true
                    }
                ],
                yAxis: [
                    {
                        type: 'value'
                    }
                ],
                series: [
                    {
                        name: '人数',
                        type: 'bar',
                        barWidth: '60px',
                        data: res.data.yLine,
                    }
                ]
            }
            this.setState({
                option_new,
                haviorData: res.data.xLine,
                data_id_list: res.data.xId
            })

        } else {
            message.error(res.message)
        }
    }

    requestGetMemberList = async (data = {}) => {
        let param: any = {
            ...data
        }
        if (param.sortType) param.sortType = param.sortType == 'ascend' ? 'asc' : 'desc'
        Object.keys(param).map(el => {
            if (!param[el]) delete param[el]
        })
        if(param.pageSize) {
            this.setState({
                paramsObj: param,
                isLoading: true
            })
        }
        let group_arr = []
        if (param.group) {
            group_arr.push(param.group)
            delete param.group
        }
        if (param.recommend_group) {
            group_arr.push(param.recommend_group)
            delete param.recommend_group
        }
        if (group_arr.length > 0) param.group = group_arr.join(',')
        const res: any = await getRequest(apiUrl.fanList, param);
        if (res.code === 1) {
            this.setState({
                memberList: res.data.list,
                count: res.data.total,
                isLoading: false
            })
        }
    }

    createTable = () => {
        const columns: any = [
            {
                title: '用户昵称',
                dataIndex: 'nickname',
                key: 'nickname',
                className: 'list_center',
                render: (text: string, record: any, index: number) =>
                    <Row className="" justify="start" align="middle">
                        <div><Avatar src={record.headimgurl} /></div>
                        <div>{record.nickname}</div>
                    </Row>
            },
            {
                title: '用户等级',
                dataIndex: 'name',
                key: 'name',
                className: 'list_center',
            },
            {
                title: '用户类型',
                dataIndex: 'vip_id',
                key: 'vip_id',
                className: 'list_center',
                render: (text: string, record: any, index: number) =>
                    <div>
                        <span>{record.vip_id ? 'VIP会员' : '普通会员'}</span>
                    </div>
            },
            {
                title: '手机号码',
                dataIndex: 'phone_number',
                className: 'list_center',
                key: 'phone_number'
            },
            {
                title: '操作',
                dataIndex: 'option',
                key: 'option',
                className: 'list_center',
                render: (text: string, record: any, index: number) =>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            type="link"
                            onClick={() => {
                                if((getIsPermisson('1_6_00') && getIsPermisson('1_6_1_3')) || (getIsPermisson('1_6_1') && getIsPermisson('1_6_00_2'))){
                                    this.props.history.push({
                                        pathname: '/sidebar/memberDetail',
                                        state: { id: record.id }
                                    })
                                }else{
                                    messageCustom();
                                }
                               
                            }}
                        >详情</Button>
                    </div>
            },
        ];

        return <Table
            dataSource={this.state.memberList}
            columns={columns}
            rowKey={"id"}
            loading={this.state.isLoading}
            size="middle"
            onChange={this.handleTableChange}
            pagination={{
                total: this.state.count,
                current: this.state.paramsObj.page,
                pageSize: this.state.paramsObj.pageSize,
                showQuickJumper: true,
                showSizeChanger: true,
                pageSizeOptions: this.state.pageSizeOptions,
                showTotal: (total: number) => `共${total}条`,
            }}
        />;
    }

    handleTableChange = (pagination:any, filters:any, sorter:any) => {
        let paramsObj: any = this.state.paramsObj;
        this.requestGetMemberList({
            ...paramsObj,
            page:pagination.current,
            pageSize:pagination.pageSize,
            sortField:sorter.field,
            sortType:sorter.order
        })
    };

    onByModelClick = (event: any) => {
        // console.log('onByModelClick: e=', event);
        let ip = event.name; //获取当前点击的列名称
        let data_id_list = this.state.data_id_list; //所有id
        let haviorData: any = this.state.haviorData;  //所有的列名
        let res_index = haviorData.indexOf(ip); //根据名称去找对应的idx
        let level_id = data_id_list[res_index]; //根据获取的idx去找id
        
        let paramsObj = this.state.paramsObj;
        this.setState({
            paramsObj: Object.assign(paramsObj,{level: level_id})
        }, () => {
            this.requestGetMemberList({
                level: level_id
            })
        })
    }

    onClickByModelEvents = {
        'click': this.onByModelClick
    }

    render() {

        let { option_new } = this.state;

        return (
            <Row className={styles.userlevel}>
                <Col className={styles.userlevel_echatrs}>
                    <div className={styles.userlevel_echatrs_tip}>用户等级</div>
                    <div>
                        <ReactEcharts onEvents={this.onClickByModelEvents} option={option_new} style={{height: '4.5rem'}} className={styles.userlevel_echatrs_react_for_echarts} />
                    </div>
                </Col>
                <Col className={styles.userlevel_table}>
                    {this.createTable()}
                </Col>
            </Row>
        )
    }

}

export default UserLevel