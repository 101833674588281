import React, { useState, Fragment } from 'react'
import { Row, Avatar, Typography, Button, Table} from 'antd';
import DetailModal from './detail_modal';

const { Text } = Typography;

interface ITableData {
  tableData: {
    items: Array<any>,
    total: number
  },
  pageChange(page: number): void
}

const OrderTable = (table: ITableData) => {
  const [showDrawer, setshowDrawer] = useState(false);
  const [currentDrawerData, setcurrentDrawerData] = useState<any>();
  const columns = [
    {
      title: "用户昵称",
      dataIndex: "user",
      key: "user",
      render: (rowdata: any) => {
        return <Row>
          <Avatar src={rowdata.avatar} style={{marginRight: 10}} />
          <Text>{rowdata.nickname}</Text>
        </Row>
      }
    },
    {
      title: "支付金额",
      dataIndex: "pay_amount",
      key: "pay_amount",
    },
    {
      title: "支付状态",
      dataIndex: "status_display",
      key: "status_display",
    },
    {
      title: "直接上级",
      dataIndex: "invited_user",
      key: "invited_user",
      render: (rowdata: any) => {
        if(rowdata) {
          return <div>{rowdata.nickname}</div>
        }
        return <div>-- --</div>
      }
    },
    {
      title: "一级返现",
      dataIndex: "commission_1",
      key: "commission_1",
    },
    {
      title: "间接上级",
      dataIndex: "invited_parent_user",
      key: "invited_parent_user",
      render: (rowdata: any) => {
        if(rowdata) {
          return <div>{rowdata.nickname}</div>
        }
        return <div>-- --</div>
      }
    },
    {
      title: "二级返现",
      dataIndex: "commission_2",
      key: "commission_2"
    },
    {
      title: "活动名称",
      dataIndex: "share_activity.name",
      key: "share_activity.name",
    },
    {
      title: "操作",
      key: "action",
      render: (rowdata: any) => {
        return <Button type="link" onClick={() => showOrderDetail(rowdata)}>查看详情</Button>
      }
    }
  ];
  
  // 显示订单详情
  const showOrderDetail = (rowdata: any) => {
    setcurrentDrawerData(rowdata);
    setshowDrawer(true);
  }
  
  // 翻页
  const pageOnChange = (page: number) => table.pageChange(page)
  
  return (
    <Fragment>
      <Table
        rowKey={"id"}
        size="middle"
        dataSource={table.tableData.items}
        columns={columns}
        pagination={{ 
          pageSize: 10, 
          total: table.tableData.total, 
          onChange: (e: number) => pageOnChange(e) 
        }}
      />
      
      <DetailModal showDrawer={showDrawer} currentDrawerData={currentDrawerData} visitable={setshowDrawer} />

      {/* <DrawerModal visitable={setshowDrawer} showDrawer={showDrawer} rowData={currentDrawerData} /> */}
    </Fragment>
  );
};

export default OrderTable