import React from 'react';
import InsideNav from '../../../component/insideNav/insideNav';
import { collectChildNav } from '../../../utils/permisstionUtil';
import Message from '../../message/message';
import MessagePushList from '../../message/messagePushList/messagePushList';
import MessageTemplate from '../../message/messageTemplate/messageTemplate';
import SmsIndex from '../../message/sms';
import WechatSmsIndex from '../../message/wechatSms';
import './messageManage.scss'

type IProps = {
    history: any
}
type IState = {
    navList: any
    current_key: string
}
const navTable = [
    {
        title: '消息列表',
        isActive: false,
        KEY: "MESSAGE",
        uni_key: '2_4_1'
    },
    {
        title: '推送记录列表',
        isActive: false,
        KEY: "MESSAGE_PUSH",
        uni_key: '2_4_2'
    },
    {
        title: '消息模板',
        isActive: false,
        KEY: "MESSAGE_TEMPLATE",
        uni_key: '2_4_3'
    },
    {
        title: '短信群发',
        isActive: false,
        KEY: "SMS_INDEX",
        uni_key: '2_4_4'
    },
    {
        title: '公众号消息模板',
        isActive: false,
        KEY: "WECHAT_SMS_INDEX",
        uni_key: '2_4_5'
    }
]
class MessageManage extends React.Component<IProps,IState>{
    
    constructor(props:IProps) {
        super(props);
        this.state = {
            navList: [],
            current_key: "",
        }
    }

    componentDidMount(){
        this.initPageState();
    }

    initPageState = () =>{
        let navList = collectChildNav(navTable);
        let currentKey = localStorage.getItem('current_key') ?? navList[0].KEY;
        let isSetKey = false;
        navList.map((item:any) => {
            if(item.KEY == currentKey){
                item.isActive = true;
                isSetKey = true;
            }else{
                item.isActive = false;
            }
        })
        if(!isSetKey){
            currentKey = navList[0].KEY;
            navList[0].isActive = true;
        };
        this.setState({
            current_key: currentKey,
            navList: navList
        })
    }

    navCallBack(currentKey: string){
        localStorage.setItem('current_key',currentKey) 
        this.setState({
            current_key: currentKey
        })
    }

    render() {
        let { navList,current_key } = this.state;
        return <div>
            <InsideNav navList={navList} callBack={(key: string) =>this.navCallBack(key)}/>
            <div className="body">
                {
                    current_key === 'MESSAGE'  ? <Message />
                    :
                    current_key === 'MESSAGE_PUSH' ?  <MessagePushList />
                    :
                    current_key === 'MESSAGE_TEMPLATE' ?  <MessageTemplate />
                    :
                    current_key === 'SMS_INDEX' ?  <SmsIndex />
                    :
                    current_key === 'WECHAT_SMS_INDEX' ?  <WechatSmsIndex />
                    : <></>
                    
                }
            </div>
        </div>
    }

}

export default MessageManage;