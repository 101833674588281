import React, { useEffect, useState } from 'react'
import { message } from 'antd'
import { useHistory,  } from 'react-router-dom'
import { getRequest, postRequest } from '../../api/http'
import { apiUrl } from '../../api/api_url'
import Main from './components/main'
import Used from './components/used'
import Expire from './components/expire'

import './index.scss'


const DepositIndex = (props:any)=>{

    const history = useHistory()
    const [ currentTab, setCurrentTab ] = useState(0)
    const tabList = [ '寄存中', '已过期', '已使用' ]

    useEffect(()=>{

    },[])

    const handleTab = (index:number) => {
        setCurrentTab(index)
    }

    return (
        <div className="deposit_index">
            <div className="deposit_index_tab">
                <div className="tab_list">
                    {
                        tabList.map((item:any,index:number) =>{
                            return <div key={index} className={`tab_body ${currentTab === index ? 'tab_body_active': ''}`} onClick={() => handleTab(index)}>
                                <div
                                    className={` 
                                        tab_item 
                                        ${currentTab === index ? 'item_active': 'item'}
                                        ${currentTab - 1 == index ? 'left_ratius': ''}
                                        ${currentTab + 1 == index ? 'right_ratius': ''}
                                        `}
                                    >
                                    {item}
                                </div>
                            </div>
                        })
                    }
                </div>
                {
                    currentTab === 0 ? (
                        <Main currentTab={currentTab}/>
                    ) : currentTab === 1 ? (
                        <Expire currentTab={currentTab}/>
                    ) : (
                        <Used currentTab={currentTab}/>
                    )
                }
            </div>
        </div>
    )
}

export default DepositIndex
