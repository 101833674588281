import React from 'react';
import styles from './index.module.scss'
import horizon_1 from '../../assret/images/horizon_1.png'
import horizon_2 from '../../assret/images/horizon_2.png'
import horizon_3 from '../../assret/images/horizon_3.png'
import horizon_4 from '../../assret/images/horizon_4.png'
import horizon_5 from '../../assret/images/horizon_5.png'

import vertical_1 from '../../assret/images/vertical_1.png'
import vertical_2 from '../../assret/images/vertical_2.png'
import vertical_3 from '../../assret/images/vertical_3.png'
import vertical_4 from '../../assret/images/vertical_4.png'
import vertical_5 from '../../assret/images/vertical_5.png'
import down_icon from '../../assret/images/down_icon.png'

import dish_1 from '../../assret/images/dish_1.png'
import dish_2 from '../../assret/images/dish_2.png'
import { postRequest, getRequest } from '../../api/http';
import { apiUrl } from '../../api/api_url';

class MatterDown extends React.Component<any> {
    state = {
        horizonArr: [horizon_1,horizon_2,horizon_3,horizon_4,horizon_5],
        verticalArr: [vertical_1,vertical_2,vertical_3,vertical_4,vertical_5],
        logo: '',
        qrImg: ''
    }

    componentDidMount(){
        this.getLogo()
        this.getCode()
    }

    getLogo = () => {
        let shop_info:any = localStorage.getItem('shop_info');
        this.setState({
            logo: JSON.parse(shop_info).img
        })
    }

    getCode = async () => {
        let res:any = await getRequest(apiUrl.getQrCode, {media_name: 'njh_gzh_logo'})
        if(res.code == 1){
            this.setState({
                qrImg: res.data.img_src
            })
        }
    }

    downloadCode = async (no: number) => {

        const token = localStorage.getItem("token");
        const shop_id = localStorage.getItem("shop_id");
        window.open(`${process.env.REACT_APP_BASEURL}${apiUrl.downCode}?token=${token}&no=${no}&logo_url=${this.state.logo}&shop_id=${shop_id}&code_url=${this.state.qrImg}`)

    }

    render(){
        return <div className={`${styles.matter_wrap}`}>
            <div className={`mb30 ${styles.matter_con}`}>
                <div className={styles.title}>小程序点餐物料</div>
                <div style={{display:'flex',justifyContent:'space-between', flexWrap:'wrap'}}>
                    {
                        this.state.horizonArr.map((item: any, index: number) => {
                            return <div className={styles.item}  key={item}>
                                        <div>
                                            <div className={styles.card_wrap} style={{height: 'auto'}}>
                                                <div className={styles.card_horizontal}>
                                                    <img src={`${item}`} style={{width: '100%', height:'100%'}} />
                                                </div>
                                            </div>
                                            <div className={styles.btn} onClick={() => this.downloadCode(index+1)}>
                                                <img src={down_icon} />
                                            </div>
                                            <div className={styles.card_title}>小程序点餐-桌台贴纸</div>
                                        </div>
                                    </div>
                        })
                    }
                </div>

                <div style={{display:'flex',justifyContent:'space-between', flexWrap:'wrap'}}>
                    {
                        this.state.verticalArr.map((item: any, index: number) => {
                            return <div className={styles.item_vertical} key={item}>
                                        <div>
                                        <div className={styles.card_wrap}><div className={styles.card_vertical}>
                                            <img src={item} /> </div></div>
                                            <div className={styles.btn} onClick={() => this.downloadCode(index+6)}>
                                                <img src={down_icon} />
                                            </div>
                                            <div className={styles.card_title}>小程序点餐-桌台贴纸</div>
                                        </div>
                                    </div>
                        })
                    }

                </div>
            </div>

            <div className={`${styles.matter_con}`}>
                <div className={styles.title}>提前点餐物料</div>
                <div style={{display:'flex',justifyContent:'start'}}>
                    <div className={styles.item} style={{marginRight:'70px'}}>
                        <div>
                            <div style={{textAlign:'center', fontSize: '16px', marginBottom: '20px'}}>提前点餐.模板（横版1）</div>
                            <div className={styles.card_wrap}>
                                <div className={styles.card_horizontal}>
                                    <img src={dish_1} />
                                </div>
                            </div>
                            <div className={styles.btn} onClick={() => this.downloadCode(11)}>
                                <img src={down_icon} />
                            </div>
                            <div className={styles.card_title}>小程序点餐-桌台贴纸</div>
                        </div>
                    </div>
                    <div className={styles.item_vertical}>
                        <div>
                            <div style={{textAlign:'center', fontSize: '16px', marginBottom: '20px'}}>提前点餐.模板（竖版1）</div>
                            <div className={styles.card_wrap}>
                                <div className={styles.card_vertical}>
                                    <img src={dish_2} />
                                </div>
                            </div>
                            <div className={styles.btn} onClick={() => this.downloadCode(12)}>
                                <img src={down_icon} />
                            </div>
                            <div className={styles.card_title}>小程序点餐-桌台贴纸</div>
                        </div>
                    </div>
                </div>
            {/* </div> */}
            </div>
        </div>
    }
}

export default MatterDown