import React, {useEffect, useState} from "react";
import { Upload, Modal, message, Icon, Spin } from 'antd';
import { getRequest } from '../../../../../api/http';
import { apiUrl } from '../../../../../api/api_url';
import { Observer } from 'mobx-react';
import shopDecorationStore from '../../../../../stores/shopDecoration.store'
// import {toJS} from 'mobx'
import './index.scss'

const UploadImg = (props:any) => {
    const { field, isSingle, imgList, module } = props

    const [ updateObj, setUploadObj ] = useState<any>({})
    const [ loading, setLoading ] = useState(false)
    const [ previewImage, setPreviewImage ] = useState('')
    const [ previewVisible, setpreviewVisible ] = useState(false)
    const [ uploadCurrent, setUploadCurrent ] = useState(-1)


    useEffect(()=>{

    },[])

    const QINIU_SERVER = 'https://up-z2.qiniup.com';
    // 获取图片token
    const beforeUpload = async (file:any) => {
        try{
            setLoading(true)
            const res:any = await getRequest(apiUrl.getUploadToken, { type: 'food' });
            const key = `food/${Math.random().toString(36).slice(-8)}${(new Date()).valueOf()}.png`
            const obj = { token: res.data.upload_token, region: `https://up-z2.qiniu.com`, key: key, 'x:type': "food" }
            setUploadObj(obj)
        }catch(err){
            message.error(err||'获取七牛token失败')
        }
    }
    const handleChange = async(info:any) => {
        console.log('----uploadCurrent----',uploadCurrent)
        if (info.file.status === 'uploading') {
            setLoading(true)
            return;
        }
        if (info.file.status === 'done') {
            let file:any = await getBase64(info.file.originFileObj);
            let dataSource = JSON.parse(JSON.stringify(shopDecorationStore.shop_decoration_home))
            let data = dataSource[shopDecorationStore.shop_decoration_home_other.current]
            let dataOther = shopDecorationStore.shop_decoration_home_other
            if(module === 'muti'){
                if(uploadCurrent!==-1){
                    data[field][dataOther.editMutiCurrent].imgUrl = info.file.response.url
                    shopDecorationStore.updateShopDecorationHomeObjProps(dataOther.current,field,data[field])
                }else{
                    data[field].push({
                        imgUrl:info.file.response.url,
                        desc:'名称', //描述（名称）
                        subDesc:'价格', //二级描述（价格）
                    })
                    shopDecorationStore.updateShopDecorationHomeObjProps(dataOther.current,field,data[field])
                }
            }else if(module === 'moFang'){
                data.list[dataOther.editMofangCurrent].imgUrl = info.file.response.url
                shopDecorationStore.updateShopDecorationHomeObjProps(dataOther.current,field,data.list)
            }else{
                shopDecorationStore.updateShopDecorationHomeObjProps(dataOther.current,field,info.file.response.url)
            }
            setLoading(false)
        }
    };
    const handlePreview = async (file:any) => {
        if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
        }
        setpreviewVisible(true)
        setPreviewImage(file.url || file.preview)
    };

    const getBase64 = (file:any) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }    

    const handleCancel = () => setpreviewVisible(false);

    const handleEnter = (index:number) => {
        // setUploadCurrent(index)
        shopDecorationStore.updateShopDecorationHomeOtherObjProps('hoverEditMutiCurrent',index)
    }

    const handleOut = () => {
        shopDecorationStore.updateShopDecorationHomeOtherObjProps('hoverEditMutiCurrent',-1)
    }

    const closeImg = (e:any,index:number) => {
        e.stopPropagation()
        let dataSource = JSON.parse(JSON.stringify(shopDecorationStore.shop_decoration_home))
        let data = dataSource[shopDecorationStore.shop_decoration_home_other.current]
        let dataOther = shopDecorationStore.shop_decoration_home_other
        if(module === 'muti'){
            if(data[field].length>1){
                data[field].splice(index,1)
                shopDecorationStore.updateShopDecorationHomeObjProps(dataOther.current,field,data[field])
            }else{
                dataSource.splice(dataOther.current,1)
                shopDecorationStore.updateShopDecorationHome(dataSource)
                shopDecorationStore.updateShopDecorationHomeOther({
                    module:'' , //当前选中模版
                    showRight:false , //是否显示右侧编辑
                    current: -1, //当前板块
                    hoverCurrent: -1, //鼠标悬停模块
                    editMofangCurrent: -1 , //当前编辑的魔方导航
                    hoverEditMofangCurrent: -1 , //当前悬停的编辑的魔方导航
                    editMutiCurrent: 0 , //当前编辑的多图文导航
                    hoverMutiCurrent: -1, //当前悬停编辑的多图文导航
                })
            }
        }else if(module === 'moFang'){
            data.list[dataOther.editMofangCurrent].imgUrl = ''
            shopDecorationStore.updateShopDecorationHomeObjProps(dataOther.current,field,data.list)
        }else{
            shopDecorationStore.updateShopDecorationHomeObjProps(dataOther.current,field,'')
        }
    }

    const clickImg = (e:any,index:number) => {
        e.stopPropagation()
        shopDecorationStore.updateShopDecorationHomeOtherObjProps('hoverEditMutiCurrent',index)
        shopDecorationStore.updateShopDecorationHomeOtherObjProps('editMutiCurrent',index)
    }

    const edit = (index:number) => {
        setUploadCurrent(index)
        shopDecorationStore.updateShopDecorationHomeOtherObjProps('hoverEditMutiCurrent',-1)
        shopDecorationStore.updateShopDecorationHomeOtherObjProps('editMutiCurrent',index)
    }
    const add = () => {
        setUploadCurrent(-1)
    }

    return(<Observer render={() =>
        <Spin spinning={loading}>
            <div className="decoration_upload">
                {
                    imgList.map((item:any,index:number)=>{      
                        return <div className="mr10 mb10" key={index}>
                            <Upload
                            listType="picture-card"
                            className="avatar_uploader"
                            showUploadList={false}
                            action={QINIU_SERVER}
                            beforeUpload={beforeUpload}
                            onChange={handleChange}
                            data={updateObj}
                            onPreview={handlePreview}
                            // multiple={uploadCurrent===-1} //多图上传还存在问题
                            >    
        
                                    <div className="img_wrap" onMouseEnter={()=>handleEnter(index)} onMouseLeave={()=>handleOut()}>
                                        <div className={`img_item ${(shopDecorationStore.shop_decoration_home_other.editMutiCurrent === index || shopDecorationStore.shop_decoration_home_other.hoverEditMutiCurrent === index) ? 'img_item_dash' : ''}`} onClick={(e)=>{clickImg(e,index)}}>
                                            <img src={item} />
                                        </div>
                                        {
                                            shopDecorationStore.shop_decoration_home_other.editMutiCurrent === index && <img src={require('../../../../../assret/images/decoration_check.png')} alt="" className="decoration_check" onClick={(e)=>{e.stopPropagation()}}/>
                                        }
                                        {
                                            shopDecorationStore.shop_decoration_home_other.hoverEditMutiCurrent === index && <img src={require('../../../../../assret/images/decoration_edit.png')} alt="" className="decoration_edit" onClick={()=>{edit(index)}}/>
                                        }
                                        {
                                            (shopDecorationStore.shop_decoration_home_other.editMutiCurrent === index || shopDecorationStore.shop_decoration_home_other.hoverEditMutiCurrent === index) && !isSingle && <div className="img_close" onClick={(e)=>{closeImg(e,index)}}>x</div>
                                        }
                                    </div>
        
                            {/* {
                                isSingle?(
                                    imgList.length === 0&&(
                                        <div className="upload_img_disabled">
                                            <Icon type={loading ? 'loading' : 'plus'} style={{fontSize: '40px'}}/>
                                        </div>
                                    )
                                ):(
                                    <div className="upload_img_disabled" onClick={()=>{add()}} onMouseEnter={()=>setUploadCurrent(-1)}>
                                        <Icon type={loading ? 'loading' : 'plus'} style={{fontSize: '40px'}}/>
                                    </div>
                                )
                            }                 */}
                            </Upload>
                        </div>      
                    })
                }
                {
                    isSingle ? (
                        imgList.length === 0&&(
                            <div className="mr10 mb10">
                                <Upload
                                    listType="picture-card"
                                    className="avatar_uploader"
                                    showUploadList={false}
                                    action={QINIU_SERVER}
                                    beforeUpload={beforeUpload}
                                    onChange={handleChange}
                                    data={updateObj}
                                    onPreview={handlePreview}
                                    // multiple={true} //多图上传还存在问题
                                    >    
                                    <div className="upload_img_disabled" onClick={()=>{add()}} onMouseEnter={()=>setUploadCurrent(-1)}>
                                        <Icon type={loading ? 'loading' : 'plus'} style={{fontSize: '40px'}}/>
                                    </div>              
                                </Upload>  
                            </div>                           
                        )
                    ) : (
                        <div className="mr10 mb10">
                            <Upload
                                listType="picture-card"
                                className="avatar_uploader"
                                showUploadList={false}
                                action={QINIU_SERVER}
                                beforeUpload={beforeUpload}
                                onChange={handleChange}
                                data={updateObj}
                                onPreview={handlePreview}
                                multiple={true} 
                                >    
                                <div className="upload_img_disabled" onClick={()=>{add()}} onMouseEnter={()=>setUploadCurrent(-1)}>
                                    <Icon type={loading ? 'loading' : 'plus'} style={{fontSize: '40px'}}/>
                                </div>              
                            </Upload>   
                        </div>
                    )
                }              
            </div>                 
            <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
        </Spin>
    }/>)
}

export default UploadImg