import React from "react";
import { Form, Input, Icon, Button, Row, Col, Modal, message } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { getRequest, postRequest } from "../../../../../api/http";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { apiUrl } from "../../../../../api/api_url";
var QRCode = require('qrcode.react');

interface IProps extends FormComponentProps,RouteComponentProps { }
interface IState {
  showModal: boolean,
  qrValue: string,
  timer: any,
  userInfo: {
    nickname: string,
    wechat: string,
    truename: string,
    mobile: string,
  },
  isEdit: boolean
}

class AddDistibutionPersion extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);

    this.state = {
      showModal: false,
      qrValue: '',
      timer: 0,
      userInfo: {
        nickname: '',
        wechat: '',
        truename: '',
        mobile: '',
      },
      isEdit: false,
    }
  }

  componentDidMount() {
    if(this.props.location.state) {
      let obj: any = this.props.location.state;
      this.setState({
        userInfo: {...this.state.userInfo, ...obj},
        isEdit: true,
      })
    }
  }

  changeModal = async (show: boolean) => {
    if (show) {
      // /v1/auth-link
      const link = `${process.env.REACT_APP_H5_BASEURL}/distribution_auth`
      const res: any = await getRequest(apiUrl.getAuthLink, { target_url: link })
      if (res && res.data) {
        this.setState({
          qrValue: res.data.link,
          showModal: show,
        });
        this.authCheck(res.data.key);
      }
    } else {
      clearInterval(this.state.timer);
      this.setState({
        showModal: show
      })
    }
  }

  authCheck = (authKey: string) => {
    const timer = setInterval(async () => {
      const res: any = await getRequest(apiUrl.checkWxAuth, { 'key': authKey });
      if (res && res.data && res.data.auth) {
        message.success('授权成功');
        this.setState({
          showModal: false,
          userInfo: res.data.user
        })
        clearInterval(timer);
      }
    }, 1500);
    this.setState({ timer })
  }

  handleSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const data = {...this.state.userInfo, ...values};
        const { isEdit } = this.state;
        if(isEdit) {
          delete data['openid']
          delete data['avatar']
          delete data['shop_user_id']
          delete data['verify_count']
          delete data['created_at']
          delete data['updated_at']
          const res:any = await postRequest(apiUrl.updateVerifyUser, {
            id: data['id'],
            update: data
          });
          if(res && res.data) {
            message.success('修改成功！');
            this.props.history.push('/sidebar/distribution_list');
            this.props.form.resetFields();
          }
        } else {
          const res: any = await postRequest(apiUrl.createVerifyUser, data);
          if (res && res.data) {
            clearInterval(this.state.timer);
            this.props.form.resetFields();
            message.success('添加成功！');
            this.props.history.push('/sidebar/distribution_list');
          }
        }
      } else {
        if(err.values && !err.values.nickname) {
          message.error('您还没有微信授权');
        }
      }
    })
  }

  render() {
    const { getFieldDecorator, getFieldError, isFieldTouched, } = this.props.form;
    const { showModal, qrValue, userInfo, isEdit } = this.state;
    const mobile = isFieldTouched('mobile') && getFieldError('mobile');
    const nickname = isFieldTouched('nickname') && getFieldError('nickname');
    const truename = isFieldTouched('truename') && getFieldError('truename');
    const wechat = isFieldTouched('wechat') && getFieldError('wechat');
    return (
      <div className='l_content'>
        <Form onSubmit={(e) => this.handleSubmit(e)}>
          {
            !isEdit && <Form.Item>
              <Row type="flex">
                <Col>核销员微信信息：</Col>
                <Col>
                  <Button type="primary" onClick={() => this.changeModal(true)}>点击微信授权</Button>
                </Col>
              </Row>
            </Form.Item>
          }
          <Form.Item validateStatus={nickname ? 'error' : ''}>
            <Row type="flex">
              <Col>核销员微信昵称：</Col>
              <Col>
                {getFieldDecorator("nickname", {
                  rules: [
                    { required: true, message: "您还没有微信授权" }
                  ],
                  initialValue: userInfo.nickname ? userInfo.nickname : ''
                })(
                  <Input prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
                    placeholder="微信昵称"
                  />
                )}
              </Col>
            </Row>
          </Form.Item>
          <Form.Item validateStatus={wechat ? 'error' : ''}>
            <Row type="flex">
              <Col>核销员微信号：</Col>
              <Col>
                {getFieldDecorator("wechat", {
                  rules: [{ required: true, message: "微信号必填" }],
                  initialValue: userInfo.wechat ? userInfo.wechat : ''
                })(
                  <Input prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
                    placeholder="微信号"
                  />
                )}
              </Col>
            </Row>
          </Form.Item>
          <Form.Item validateStatus={ truename ? 'error' : '' }>
            <Row type="flex">
              <Col>核销员真实姓名：</Col>
              <Col>
                {getFieldDecorator("truename", {
                  rules: [{ required: true, message: "请输入真实姓名" }],
                  initialValue: userInfo.truename ? userInfo.truename : ''
                })(
                  <Input prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
                    placeholder="姓名"
                  />
                )}
              </Col>
            </Row>
          </Form.Item>
          <Form.Item validateStatus={ mobile ? 'error' : '' }>
            <Row type="flex">
              <Col>核销员手机号码：</Col>
              <Col>
                {getFieldDecorator("mobile", {
                  rules: [{ required: true, message: "请输入正确的手机号", max: 11, whitespace: true }],
                  initialValue: userInfo.mobile ? userInfo.mobile : ''
                })(
                  <Input
                    prefix={
                      <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder="手机号"
                  />
                )}
              </Col>
            </Row>
          </Form.Item>
          <Row type="flex" justify="center" align="middle" style={{marginTop: 50}}>
            <Link to="/sidebar/distribution_list">
              <Button>取消</Button>
            </Link>
              <Button type="primary" htmlType="submit" style={{marginLeft: 30}}>{isEdit ? '确认修改' : '确定添加'}</Button>
          </Row>
        </Form>
        <Modal title={'添加微信'}
          visible={showModal}
          footer={null}
          width="400px"
          onCancel={() => this.changeModal(false)}>
          <Row type="flex" justify="center">
            <QRCode value={qrValue} size={200} />
          </Row>
        </Modal>
      </div>
    );
  }
}

export default Form.create({})(withRouter(AddDistibutionPersion));
