// 提现管理

import React, { Fragment, useState } from 'react'
import { Row, Button } from 'antd'
import WithdrawListTable from './components/withdraw_list'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import DataSummaryItem from '../order_list/components/data_summary_item'


export interface IoverView {
  pay_amount: number,
  verify_amount: number,
  cny_over: string,
  cny_lock: string,
  withdraw_amount: number
}

interface IWithdrawProps extends RouteComponentProps {}

const Withdraw = (props: IWithdrawProps) => {
  
  const [overView, setoverView] = useState<IoverView>({
    pay_amount: 0,
    verify_amount: 0,
    cny_over: "0.00",
    cny_lock: "0.00",
    withdraw_amount: 0
  })

  // const toApplyWithdrawal = () => props.history.push('/sidebar/apply_withdrawal', overView)
  const toApplyWithdrawal = () => props.history.push('/sidebar/apply_withdrawal', overView.cny_over);

  return <Fragment>
    <Row>
      <DataSummaryItem
        tips=""
        mainTitle={`${overView.verify_amount}元`}
        subTitle="实际总额"
      />
      <DataSummaryItem
        tips=""
        mainTitle={`${overView.pay_amount}元`}
        subTitle="待计算金额"
      />
      <DataSummaryItem
        tips=""
        mainTitle={`${overView.cny_over}元`}
        subTitle="可提现金额"
      />
      <DataSummaryItem
        tips=""
        mainTitle={`${overView.withdraw_amount}元`}
        subTitle="已提现金额"
      />
    </Row>

    <Button type="primary" onClick={toApplyWithdrawal} style={{marginLeft: 20, marginBottom: 20}}>申请提现</Button>

    <WithdrawListTable updateOverview={(data) => setoverView(data)} />
  </Fragment>
}

export default withRouter(Withdraw)