import React from "react";
import { Table, Modal, message, Row, Col, Select, Input, Tooltip, Radio, Avatar} from "antd";
import { postRequest, getRequest } from '../../api/http';
import { apiUrl } from '../../api/api_url';
import { getIsPermisson, messageCustom } from "../../utils/permisstionUtil";
import goodsExport from '../../assret/images/goodsExport.png'
import index3 from '../../assret/images/index3.png'
import adjustment from '../../assret/images/adjustment.png'
import t_info from '../../assret/images/t_info.png'
import new_price from '../../assret/images/new_price.png'
import trans_vip from '../../assret/images/trans_vip.png'
import './index.scss'

const { Search } = Input;
const { Option } = Select;

class UserList extends React.Component<any> {
    state = {
        current: 1,
        pageSize: 10,
        total: 0,
        userList: [],
        level: 0,
        type: 0,
        key_words: '',
        changeRechargeFee:  0,
        changePresentFee: 0,
        changeCommissionFee: 0,
        changeReason: '',
        adjustment: 'add',
        userOverPop: false,
        userInfo: {uid:''},
        member_level: [],
        member_type: [
            {
                id: 1,
                name: '普通会员'
            },
            {
                id: 2,
                name: 'VIP会员'
            },
            {
                id: 3,
                name: '充值会员'
            }
        ],
        chargeShow: false,
        welfareList:[],

        // 充值
        phone_number: '',
        nickname: '',
        charge_amount: 0,
        present_amount: 0,
        fans_id: 0,
        config_id: 0,
        pay_type: '',
        payVisible: false,
        payStatus: 0,
        valueTemp: ''
    }

    columns = [
        {
            title: '用户昵称',
            dataIndex: 'id',
            key: 'id',
            render: (text: string, record: any, index: number) =>
                    <Row type='flex' justify="start" align="middle">
                        <div style={{marginRight: '5px'}}><Avatar src={record.headimgurl} /></div>
                        <div>{record.nickname}</div>
                    </Row>
        },
        {
            title: '用户等级',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '手机号码',
            dataIndex: 'phone_number',
            key: 'phone_number',
        },
        {
            title: '会员类型',
            dataIndex: 'vip_type_desc',
            key: 'vip_type_desc',
        },
        {
            title: '累计消费',
            dataIndex: 'consume_total',
            key: 'consume_total',
        },
        {
            title: '积分',
            dataIndex: 'score',
            key: 'score',
        },
        {
            title: '账户余额',
            dataIndex: 'balance',
            key: 'balance',
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            width: 200,
            render: (text:any,record:any,) => (
                <div className="btn_wrap">

                    <Tooltip placement="bottomRight" title={'充值'}>
                        <img src={adjustment} className='table_icon' onClick={() => {
                            this.onInitWelfare()
                            this.setState({
                                fans_id: record.fans_id,
                                phone_number: record.phone_number,
                                nickname: record.nickname,
                                chargeShow: true,
                                config_id: 0,
                                charge_amount: 0,
                                present_amount: 0,
                            })
                        }} ></img>
                    </Tooltip>
                    <Tooltip placement="bottomRight" title={'调额'}>
                        <img src={new_price} className='table_icon' onClick={() => {
                            if(!getIsPermisson('1_6_1_2')){
                                messageCustom();
                                return;
                            }
                            this.onChangePricePop(record)
                        }} ></img>
                    </Tooltip>

                    <Tooltip placement="bottomRight" title={'详情'}>
                        <img src={t_info} className='table_icon' onClick={() => {
                            if(!getIsPermisson('1_6_1_3')){
                                messageCustom();
                                return;
                            }
                            this.props.history.push({pathname: "/sidebar/memberDetail", state: {id: record.fans_id }})
                        }} ></img>
                    </Tooltip>

                    <Tooltip placement="bottomRight" title={'转为VIP会员'}>
                        <img src={trans_vip} className='table_icon' onClick={() => this.transToVip(record)} ></img>
                    </Tooltip>

                </div>
            ),
        },
    ];

    componentDidMount(){
        console.log(this.props)
        if(this.props.phone){
            this.setState({
                key_words: this.props.phone
            }, () => {
                console.log(this.state.key_words);
                this.onInitList();
            })
        }else{
            this.onInitList();
        }


        var code = "";
        var lastTime: any, nextTime;
        var lastCode: any, nextCode;

        window.addEventListener('keypress', (e) => {
            if (this.state.payVisible) {
                nextCode = e.which;
                nextTime = new Date().getTime();
                if (lastCode != null && lastTime != null && nextTime - lastTime <= 30) {
                    code += String.fromCharCode(lastCode);
                } else if (lastCode != null && lastTime != null && nextTime - lastTime > 100) {
                    code = "";
                }
                lastCode = nextCode;
                lastTime = nextTime;
                if (e.which == 13) {
                    code = code.replace(/[^0-9]/ig,"");
                    this.setState({
                        valueTemp: code,
                        payStatus: 1
                    }, () => {
                        this.setState({
                            pay_type: 'one'
                        }, () => {
                            this.payOrder();
                        })
                    })
                    code = "";
                }
            }
        })
    }

    // 初始化列表
    onInitList = async () => {
        let { current, pageSize, level, key_words, type, } = this.state;
        const params:any = {
            current,
            pageSize,
        };
        if(key_words !== ''){
            params.key_words = key_words;
        }
        if(level){
            params.level = level;
        }
        if(type){
            params.vip_type = type;
        }
        const res:any = await getRequest(apiUrl.cashierUserList,params);
        if(res.code === 1){
            this.setState({
                total: res.data.total,
                userList: res.data.list,
                member_level: res.data.member_level
            })
        }
    }

    // 充值福利列表
    onInitWelfare = async () => {
        const res:any = await getRequest(apiUrl.chargeWelfareList);
        if(res.code === 1){
            this.setState({
                welfareList: res.data
            })
        }
    }

    pageChange = async (page:number) => {
        this.setState({
            current: page
        }, () => {
            this.onInitList()
        })
    }

    searchCoupon = (key_words: string) => {
        console.log('key_words', key_words);
        this.setState({
            key_words
        }, () => {
            this.onInitList();
        })
    }

    statusChange = (type: string) => {
        this.setState({
            type
        }, () => {
            this.onInitList()
        })
    }

    levelChange = (level:any) => {
        this.setState({
            level
        }, () => {
            this.onInitList();
        })
    }

    exportBtn = () => {
        const token = localStorage.getItem("token");
        const shop_id = localStorage.getItem("shop_id");
        let { level, key_words, type, } = this.state;
        let url = `${process.env.REACT_APP_BASEURL}${apiUrl.cashierUserListExport}?token=${token}&shop_id=${shop_id}`;
        if(level && level != 0) url += `&level=${level}`;
        if(type && type != 0) url += `&vip_type=${type}`;
        if(key_words != '') url += `&key_words=${key_words}`;
        window.open(url);
    }

    transToVip = (record:any) => {
        Modal.confirm({
            title: '提示',
            content: `是否将用户${record.nickname}转为VIP会员`,
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                let res:any = await postRequest(apiUrl.transToVip, {nickname: record.nickname, phone_number: record.phone_number})
                if(res.code === 1){
                    message.success(res.message);
                    this.onInitList();
                }
            },
        });
    }

    //调整余额弹窗
    onChangePricePop = async (e: any) => {
        if(!getIsPermisson('1_6_1_2')){
            messageCustom();
            return;
        }
        this.setState({
            userOverPop: true
        })
        console.log(e)
        const res: any = await getRequest(apiUrl.getUserProperty, { fan_id: e.fans_id })
        let obj = Object.assign(e,res.data);
        this.setState({
            userInfo: obj
        })
    }

    // 就餐人数修改
    numChange = (e:any) => {
        let name: any = e.target.name;
        let val: any = e.target.value;
        this.setState({
            [name]: val
        })
    }

    //调整余额提交
    closeStoreActionOK = async (e:any) => {
        let { changeRechargeFee, changeReason, changePresentFee, changeCommissionFee, } = this.state;
        let userInfo:any = this.state.userInfo;
        let param: any = { fan_id: userInfo.fans_id, status: this.state.adjustment }

        if(Number(changeRechargeFee) < 0) {
            message.warn('输入的金额必须大于0！')
            return false;
        }
        if(changeReason == '') {
            message.warn('请输入调整原因！')
            return false;
        }
        if(Number(changePresentFee) < 0) {
            message.warn('输入的金额必须大于0！')
            return false;
        }
        if(Number(changeCommissionFee) < 0) {
            message.warn('输入的金额必须大于0！')
            return false;
        }

        if(changeReason.length < 5) {
            message.warn('调整原因至少输入五个字符')
            return false;
        }

        param.recharge_fee = changeRechargeFee;
        param.present_fee = changePresentFee;
        param.commission_fee = changeCommissionFee;
        param.reason = changeReason;

        const res: any = await postRequest(apiUrl.setUserProperty, param)
        if(res.code == 1) {
            this.setState({
                userOverPop: false,
                changeRechargeFee:  0,
                changePresentFee: 0,
                changeCommissionFee: 0,
                changeReason: '',
            })
            message.success("操作成功")
            this.onInitList()
        } else {
            message.error(res.message)
        }
    }

    // 会员充值
    chooseWelfare = (item: any) => {
        this.setState({
            config_id: item.id,
            charge_amount: item.charge_fee,
            present_amount: item.present_fee
        })
    }

    inputChange = (e:any) => {
        let name: any = e.target.name;
        let val: any = e.target.value;
        this.setState({
            [name]: val,
            config_id: 0
        })
    }

    payOrder = async () => {
        let { phone_number, nickname, charge_amount, present_amount, fans_id, pay_type, config_id, valueTemp} = this.state;
        if(charge_amount == 0){
            message.warn('请输入充值金额')
            return;
        }
        let params:any = {
            vip_type: 1,
            phone_number,
            nickname,
            charge_amount,
            present_amount
        }
        if(fans_id !== 0){
            params.fans_id = fans_id;
        }
        if(config_id !== 0){
            params.config_id = config_id;
        }
        if(pay_type !== ''){
            params.pay_type = pay_type;
        }
        if (pay_type === 'one') {
            params.auth_code = valueTemp;
        }
        let res:any = await postRequest(apiUrl.cashierUserCharge, params)
        .then((res:any) => {
            if(res.code == 1){
                message.success(res.message)
                this.onInitList()
                if (res.sub_code && res.sub_code === 500) {
                    if (pay_type === 'one') {
                        message.error(res.message);
                        this.setState({
                            payStatus: 0,
                            valueTemp: '',
                            payVisible: false,
                        })
                    }
                }else{
                    this.setState({
                        config_id: 0,
                        chargeShow: false,
                        valueTemp: '',
                        payVisible: false,
                        payStatus: 0
                    })
                }
            }
        }).catch((e) => {
            message.success('支付超时，请重新扫码');
            this.setState({
                payStatus: 0,
                valueTemp: '',
                payVisible: false,
            })
        })
    }


    // 人民币支付
    moneyPay = () => {
        Modal.confirm({
            title: '人民币支付',
            content: `确定已使用现金支付？`,
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                this.setState({
                    pay_type: 'money'
                }, () => {
                    this.payOrder();
                })
            },
            onCancel() {
            },
        });
    }

    // 银行卡支付
    bankPay = () => {
        Modal.confirm({
            title: '银行卡支付',
            content: `确定已使用银行卡支付？`,
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                this.setState({
                    pay_type: 'bank'
                }, () => {
                    this.payOrder();
                })
            },
            onCancel() {
            },
        });
    }

    keyChange = (e:any) => {
        if(e && e.target.value){
            this.setState({
                key_words: e.target.value
            })
        }
    }

    render(){
        let { pageSize, current, userList, total, member_type, member_level, chargeShow, welfareList, phone_number, nickname, charge_amount, present_amount} = this.state;
        let {payVisible, payStatus, valueTemp} = this.state;
        let userInfo:any = this.state.userInfo;
        return <div style={{padding: '20px'}}>
            <div className="user_title mb20" style={{fontSize: '20px', color: '#333'}}>用户列表显示系统所有用户(仅显示小程序授权过手机号用户)</div>

            <Row className="mb30" type='flex' align='middle'>
                <Col className="mr30">
                    <div className='export_btn' onClick={() => this.exportBtn()}>
                        <img src={goodsExport} />导出用户
                    </div>
                </Col>
                <Col className="mr30">
                    <Search placeholder="请输入名称/手机号" onSearch={this.searchCoupon} value={this.state.key_words} allowClear onChange={(e) => this.keyChange(e)} style={{ width: 234 }}/>
                </Col>
                <Col className="mr30">
                    <span>用户等级：</span>
                    <Select placeholder="请选择等级" allowClear style={{ width: 120 }} onChange={this.levelChange}>
                        {
                            member_level.map((item:any) => {
                                return <Option value={item.id}>{item.name}</Option>
                            })
                        }
                    </Select>
                </Col>
                <Col className="mr30">
                    <span>会员类型：</span>
                    <Select placeholder="请选择类型" allowClear style={{ width: 120 }} onChange={this.statusChange}>
                        {
                            member_type.map((item:any) => {
                                return <Option value={item.id}>{item.name}</Option>
                            })
                        }
                    </Select>
                </Col>
            </Row>

            <div className="user_total">
                <div className="user_total_value">{total}</div>
                <div className="user_total_title">总用户数</div>
                <img src={index3} />
            </div>

            <div className='finance_table_wrap'>
                <Table dataSource={userList}  size="middle" columns={this.columns} rowKey={'fans_id'} locale={{emptyText: '暂无数据'}} pagination={{
                    total: total,
                    pageSize,
                    onChange: this.pageChange,
                    current,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    showTotal: (total: number) => `共${total}条`,
                    onShowSizeChange: (current:number, size: number) => {this.setState({pageSize: size}, () => {this.onInitList();})}

                }} />
            </div>

            <Modal
                title='会员充值'
                visible={chargeShow}
                footer={null}
                onCancel={() => {this.setState({chargeShow: false,config_id: 0})}}>
                    <Row className="mb30" type='flex' align='middle'>
                        <Col span={4}>
                            <div>
                                <span className="star"></span>
                                <span className="color_black">手机号</span>
                            </div>
                        </Col>
                        <Col span={20}>
                            <Input name='phone_number' disabled value={phone_number} onChange={(e) => this.inputChange(e)}/>
                        </Col>
                    </Row>

                    <Row className="mb30" type='flex' align='middle'>
                        <Col span={4}>
                            <div>
                                <span className="color_black">用户昵称</span>
                            </div>
                        </Col>
                        <Col span={20}>
                            <Input name='nickname' value={nickname} onChange={(e) => this.inputChange(e)}/>
                        </Col>
                    </Row>

                    <Row className="mb30" type='flex' align='middle'>
                        <Col span={4}>
                            <div>
                                <span className="star"></span>
                                <span className="color_black">充值金额</span>
                            </div>
                        </Col>
                        <Col span={20}>
                            <Input name='charge_amount' value={charge_amount} onChange={(e) => this.inputChange(e)}/>
                        </Col>
                    </Row>

                    <Row className="mb30" type='flex' align='middle'>
                        <Col span={4}>
                            <div>
                                <span className="color_black">赠送金额</span>
                            </div>
                        </Col>
                        <Col span={20}>
                            <Input name='present_amount' value={present_amount} onChange={(e) => this.inputChange(e)}/>
                        </Col>
                    </Row>
                    {
                        welfareList && welfareList.length > 0
                        ? <div className='user_charge_wrap'>
                            {
                                welfareList.map((item:any) => {
                                    return <div className='user_charge_item' key={item.id} onClick={() => this.chooseWelfare(item)}>{item.charge_desc}</div>
                                })
                            }
                        </div>
                        : null
                    }
                    <div className='pay_con mb30'>
                        <div className='' onClick={() => {this.setState({payVisible: true, chargeShow: false})}}>微信/支付宝条码支付</div>
                        <div className='' onClick={() => this.bankPay()}>银行卡支付</div>
                        <div onClick={() => this.moneyPay()}>人民币支付</div>
                    </div>
                    <div style={{color: 'red', fontSize: '14px'}}>收银台会员储值暂不支持退款，请仔细确认后再操作，谢谢！</div>
                </Modal>

                {/* 支付状态 */}
            <Modal
                visible={payVisible}
                title='扫码支付'
                closable={false}
                footer={null}
                width="70vw"
            >
                <div style={{ textAlign: 'center' }}>
                    <div className='pay_number'>支付： {charge_amount}</div>
                    {
                        payVisible
                            ? <Input style={{ width: '200px', margin: '30px auto' }}
                                autoFocus={true}
                                value={valueTemp}
                                readOnly
                                placeholder='等待用户扫码'
                                onBlur={() => { console.log('失去焦点'); }}
                                onFocus={() => { console.log('获取焦点'); }} />
                            : null
                    }

                    {
                        payStatus === 1
                            ? <div className='pay_status success_status'>付款中...</div>
                            : payStatus === 2
                                ? <div className='pay_status success_status'>付款成功</div>
                                : payStatus === 3
                                    ? <div className='pay_status fail_status'>付款失败，请重新扫码</div>
                                    : null
                    }
                </div>

                {
                    payStatus === 0
                        ? <div className='cancel_wrap mt50'>
                            <div className="cancel_btn" onClick={() => { this.setState({ payVisible: false }) }}>取消</div>
                        </div>
                        : null
                }

            </Modal>

                <Modal
                title="调整余额"
                visible={this.state.userOverPop}
                onOk={this.closeStoreActionOK}
                onCancel={() => {this.setState({userOverPop: false})}}
                okText="确认"
                cancelText="取消"
                closable={false}
            >
                <div className="member_list_main">
                    <div className="member_list_main_top mb10">
                        <div className="member_list_main_top_img">
                            <img style={{width: '75px', height: '75px'}} src={userInfo.headimgurl} />
                        </div>
                        <div className="member_list_main_top_right">
                            <div className="member_list_main_top_right_name">{userInfo.nickname}</div>
                            <div className="member_list_main_top_right_phone">手机号：{userInfo.phone_number}</div>
                            <div className="member_list_main_top_right_price">可用余额：{userInfo.balance} （实充：{userInfo.recharge_fee}元，赠送{userInfo.present_fee}元，奖励{userInfo.commission_fee}元）</div>
                        </div>
                    </div>
                    <Row style={{display: 'flex', alignItems: 'center'}} className="mb10">
                        <Col span={4}>
                            <div>
                                <span className="color_black">选择方式</span>
                            </div>
                        </Col>
                        <Col span={20}>
                        <Radio.Group
                                value={this.state.adjustment}
                                name='adjustment'
                                onChange={(e) => this.numChange(e)}
                            >
                                <Radio value={'add'}>增加金额</Radio>
                                <Radio value={'reduce'}>减少金额</Radio>
                            </Radio.Group>
                        </Col>
                    </Row>
                    <Row style={{display: 'flex', alignItems: 'center'}} className="mb10">
                        <Col span={4}>
                            <div style={{marginLeft: '11px'}}>
                                <span className="color_black">调整实充</span>
                            </div>
                        </Col>
                        <Col span={20}>
                            <div>
                                <Input style={{width: '90%', marginRight: '5%'}} value={this.state.changeRechargeFee} name="changeRechargeFee" placeholder="" maxLength={10} onChange={(e) => this.numChange(e)} type='number'/>元
                            </div>
                        </Col>
                    </Row>
                    <Row style={{display: 'flex', alignItems: 'center'}} className="mb10">
                        <Col span={4}>
                            <div style={{marginLeft: '11px'}}>
                                <span className="color_black">调整赠送</span>
                            </div>
                        </Col>
                        <Col span={20}>
                            <div>
                                <Input style={{width: '90%', marginRight: '5%'}} value={this.state.changePresentFee} name="changePresentFee" placeholder="" maxLength={10} onChange={(e) => this.numChange(e)} type='number'/>元
                            </div>
                        </Col>
                    </Row>
                    <Row style={{display: 'flex', alignItems: 'center'}} className="mb10">
                        <Col span={4}>
                            <div style={{marginLeft: '11px'}}>
                                <span className="color_black">调整奖励</span>
                            </div>
                        </Col>
                        <Col span={20}>
                            <div>
                                <Input style={{width: '90%', marginRight: '5%'}} value={this.state.changeCommissionFee} name="changeCommissionFee" placeholder="" maxLength={10} onChange={(e) => this.numChange(e)} type='number'/>元
                            </div>
                        </Col>
                    </Row>
                    <Row style={{display: 'flex', alignItems: 'center'}} className="mb10">
                        <Col span={4}>
                            <div>
                                <span className="star"></span>
                                <span className="color_black">调整原因</span>
                            </div>
                        </Col>
                        <Col span={20}>
                            <div>
                                <Input style={{width: '90%', marginRight: '5%'}} name="changeReason" value={this.state.changeReason} placeholder="至少包含5个中文字符，如：收银台储值" maxLength={10} onChange={(e) => this.numChange(e)}/>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </div>;
    }
}
export default UserList