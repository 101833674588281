import React from 'react';

import './manageUser.scss';
import { Row, Col, Button, Table, Divider, Tag, Select, message } from 'antd';
import { Link } from 'react-router-dom';
import Search from 'antd/lib/input/Search';
import { getRequest, postRequest } from '../../../api/http';
import { apiUrl } from '../../../api/api_url';
import { getIsPermisson, messageCustom } from '../../../utils/permisstionUtil';

type IProps = {
    history: any
}

type IState = {
    userName: string,
    userPhone: string,
    role_id: string,
    userStatus: any,
    roles: any,
    manageUserList: any,
    count: number,
    isLoading: boolean
}

const {Option} = Select;

class ManageUser extends React.Component<IProps,IState> {

    constructor(props:IProps) {
        super(props);
        this.state = {
            userName: '',
            userPhone: '',
            role_id: '-1',
            userStatus: 1,
            roles: [],
            manageUserList: [],
            count: 0,
            isLoading: false
        }
    }
    currnet = 1;

    componentDidMount(){
        this.getManageUser();
        this.getRoles();
    }

    // 获取所有角色
    getRoles = async() =>{
        const res:any = await getRequest(apiUrl.roleList,{current: 1,
            pageSize: 10000
        });
        if(res.code == 1){
            let roles = res.data.list;
            this.setState({roles})
        }else{
            message.error('服务器异常'+ res.message);
        }
    }

    // 获取管理员列表
    getManageUser = async () =>{
        this.setState({isLoading: true});
        let {userName,userPhone,role_id,userStatus} = this.state;
        let params:any = {
            current: this.currnet,
            pageSize: 10,
            status: userStatus
        }

        if(userName)params.nickname = userName;
        if(userPhone)params.mobile = userPhone;
        if(role_id != '-1') params.role_id = role_id;

        const res:any = await getRequest(apiUrl.manageList,params);
        if(res.code == 1){
            this.setState({
                manageUserList: res.data.list,
                count: res.data.count,
                isLoading: false
            })
        }else{
            message.error("服务器异常"+ res.message)
        }
    }



    // 创建table
    creatTable = ()=>{
        let {manageUserList,count,isLoading} = this.state;
        const columns = [
            {
              title: '商家端用户姓名',
              dataIndex: 'nickname',
              key: 'nickname',
            },
            {
              title: '手机',
              dataIndex: 'mobile',
              key: 'mobile',
            },
            {
                title: '角色',
                dataIndex: 'display_name',
                key: 'display_name',
                render: (text:any, record:any) => (
                    <div className="permission">
                        {
                            record.display_name.map((item:any,index:number) =>{
                                return (
                                    <div className="permission_item" key={index+item}>
                                        <Tag color="#2db7f5">{item}</Tag>
                                    </div>
                                );
                            })
                        }
                    </div>
                  ),
            },
            {
                title: '状态',
                dataIndex: 'user_status',
                key: 'user_status',
                render: (text:any, record:any) => (
                    <div>{record.status === 1 ? "正常":"冻结"}</div>
                  ),
            },
            {
                title: '创建时间',
                dataIndex: 'created_at',
                key: 'created_at',
            },
            {
              title: '操作',
              key: 'action',
              render: (text:any, record:any) => (
                record.is_super_admin ? <span>不可操作</span>
                :
                <span>
                  <a onClick={() =>{
                      if(!getIsPermisson('2_5_1_2')){
                        messageCustom();
                        return;
                      }
                      this.props.history.push({pathname: '/sidebar/addManageUser',state: {user: record}})
                  }}>
                    编辑
                  </a>
                  <Divider type="vertical" />
                  <a onClick={() => this.updateUserStatus(record)}>设为{record.status == 1 ? '冻结':'正常'}</a>
                  <Divider type="vertical" />
                  <a onClick={() =>this.deleteManageUser(record.user_id)}>删除</a>
                </span>
              ),
            },
          ];
       return <Table
            columns={columns}
            dataSource={manageUserList}
            rowKey= "user_id"
            loading={isLoading}
            expandedRowRender={record => (
                <div className="permission">
                    {
                        record.display_name.map((item:any,index:any) =>{
                            return (
                                <div className="permission_item" key={item+index}>
                                    <Tag color="#2db7f5" >{item}</Tag>
                                </div>
                            );
                        })
                    }
                </div>
            )}

            pagination={{
              pageSize: 10,
              total: count,
              onChange: (e: number) => {
                  this.currnet = e;
                  this.getManageUser();
              }
            }}
       />;
    }

    // 删除管理者
    deleteManageUser = async (userId: number) =>{
        if(!getIsPermisson('2_5_1_4')){
            messageCustom();
            return;
          }
        const res:any = await getRequest(apiUrl.deleteManageUser,{user_id: userId});
        if(res.code == 1){
            message.success("删除成功");
            this.getManageUser();
        }else{
            message.error("删除失败"+ res.message);
        }
    }

    // 设为冻结或正常
    updateUserStatus = async (userInfo: any) => {
        if(!getIsPermisson('2_5_1_3')){
            messageCustom();
            return;
          }
        const res: any = await getRequest(apiUrl.updateManageUser,{
            user_id: userInfo.user_id,
            status: userInfo.status == 1 ? 2:1
        });
        if(res.code ==1){
            let strInfo = userInfo.status == 1 ? "设置账户冻结成功":"设置账户正常成功";
            message.success(strInfo);
            this.getManageUser();
        }else{
            message.error("更新失败"+ res.message);
        }
    }

    render() {
        let {role_id,userStatus,roles} = this.state;
        return (
            <div className="user_page l_content">
            <Row>
                <Col span={2}>
                    <Button onClick={() => {
                        if(!getIsPermisson('2_5_1_1')){
                            messageCustom();
                            return;
                          }
                        this.props.history.push('/sidebar/addManageUser')
                    }} icon="plus" type="primary">添加</Button>
                </Col>
                <Col span={5}>
                    <div className="serach_view">
                        <div className="serach_text">用户姓名 ：</div>
                        <Search
                            placeholder="输入姓名..."
                            onSearch={value => {
                                this.currnet = 1;
                                this.setState({userName: value},() =>{
                                    this.getManageUser();
                                });
                            }}
                            />
                    </div>
                </Col>
                <Col span={5}>
                    <div className="serach_view">
                        <div className="serach_text">用户电话 ：</div>
                        <Search
                            placeholder="输入电话..."
                            onSearch={value => {
                                this.currnet = 1;
                                this.setState({userPhone: value},() =>{
                                    this.getManageUser();
                                });
                            }}
                            />
                    </div>
                </Col>
                <Col span={5}>
                    <div className="serach_view">
                        <div className="serach_text">角色 ：</div>
                        <Select allowClear placeholder="请选择角色" style={{ width: 200 }} onChange={(value:any) =>{
                            this.setState({role_id: value},() =>{
                                this.currnet = 1;
                                this.getManageUser();
                            });
                        }}>
                            {/* <Option value='-1'>请选择角色</Option> */}
                            {
                                roles.map((item:any) =>{
                                    return (
                                    <Option value={item.role_id} key={item.role_id}>{item.display_name}</Option>
                                    )
                                })
                            }
                        </Select>
                    </div>
                </Col>
                <Col span={4}>
                    <div className="serach_view">
                        <div className="serach_text">用户状态 ：</div>
                        <Select allowClear placeholder="请选择用户状态" style={{ width: 200 }} onChange={(value:any) =>{
                            this.setState({userStatus: value},() =>{
                                this.currnet = 1;
                                this.getManageUser();
                            });
                        }}>
                            <Option value="1">正常</Option>
                            <Option value="2">冻结</Option>
                        </Select>
                    </div>
                </Col>
            </Row>
            <div className="table_view">
                {this.creatTable()}
            </div>
        </div>
        )
    }
}

export default ManageUser;