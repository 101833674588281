import React from 'react';
import InsideNav from '../../../component/insideNav/insideNav';
import { collectChildNav } from '../../../utils/permisstionUtil';
import CommentCfg from '../../comment/commentCfg';
import CommentList from '../../comment/commentList/commentList';
import StaffList from '../../comment/staffManage/staffList';
import ScheduleList from '../../comment/schedule/scheduleList';
// import PrinterDepart from '../../printerManager/printerDepart';
// import PrinterList from '../../printerManager/printerList';
// import PrinterMsgList from '../../printerManager/printerMsgList';
import './comment.scss'

type IProps = {
    history: any
}
type IState = {
    navList: any
    current_key: string
}
const navTable = [
    {
        title: '评价数据',
        isActive: false,
        KEY: "COMMENTDATA",
        uni_key: '2_9_2'
    },
    {
        title: '评价配置',
        isActive: false,
        KEY: "COMMENTCFG",
        uni_key: '2_9_1'
    },
    {
        title: '排班管理',
        isActive: false,
        KEY: "COMMENT_SCHEDULE_MANAGE",
        uni_key: '2_9_1'
    },
    {
        title: '服务员管理',
        isActive: false,
        KEY: "COMMENT_STAFF_MANAGE",
        uni_key: '2_9_1'
    }
]
class Comment extends React.Component<IProps,IState>{
    
    constructor(props:IProps) {
        super(props);
        this.state = {
            navList: [],
            current_key: "",
        }
    }

    componentDidMount(){
        this.initPageState();
    }

    initPageState = () =>{
        let navList = collectChildNav(navTable);
        let currentKey = localStorage.getItem('current_key') ?? navList[0].KEY;
        let isSetKey = false;
        navList.map((item:any) => {
            if(item.KEY == currentKey){
                item.isActive = true;
                isSetKey = true;
            }else{
                item.isActive = false;
            }
        })
        if(!isSetKey){
            currentKey = navList[0].KEY;
            navList[0].isActive = true;
        };
        this.setState({
            current_key: currentKey,
            navList: navList
        })
    }

    navCallBack(currentKey: string){
        localStorage.setItem('current_key',currentKey) 
        this.setState({
            current_key: currentKey
        })
    }

    render() {
        let { navList,current_key } = this.state;
        console.log(navList)
        return <div>
            <InsideNav navList={navList} callBack={(key: string) =>this.navCallBack(key)}/>
            <div className="body">
                {
                    current_key === 'COMMENTCFG'  ?  <CommentCfg/>
                    :
                    current_key === 'COMMENTDATA' ?  <CommentList/>
                    :
                    current_key === 'COMMENT_STAFF_MANAGE'? <StaffList/>
                    :
                    current_key === 'COMMENT_SCHEDULE_MANAGE'? <ScheduleList/>
                    : <></>
                    
                }
            </div>
        </div>
    }

}

export default Comment;