import React from "react";
import { Row, Col, Input, DatePicker, Table, } from "antd";
import { getRequest } from '../../../api/http';
import { apiUrl } from '../../../api/api_url';
import { RangePickerValue } from "antd/lib/date-picker/interface";
import moment from "moment";

const { Search } = Input;
const { RangePicker } = DatePicker;

class CashCoupon extends React.Component<any> {
    state = {
        name: '',
        productCount: 0,
        productList: [],
        currentPage: 1,
        pageSize: 10,
        searchName: '',
        timeArray: [],
        totalPrice: 0,
        start_time: '',
        end_time: ''
    }

    columns = [
        {
            title: '序号',
            dataIndex: 'order_by',
            key: 'order_by',
            className: 'list_center',
            render: (text: string, record:any, index: number) => {
                return <div className="">
                    {index+1}
                </div>
            }
       },
        {
          title: '付款用户',
          dataIndex: 'nickname',
          key: 'nickname',
          className: 'list_center',
          render: (text: string, record:any) => {
                return <div className="">
                    <img src={record.head_img_url} style={{width: '30px',height: '30px', borderRadius:'50%', display: 'inline-block'}}></img>
                    <div className='' style={{display: 'inline-block', marginLeft: '10px'}}>{record.nickname}</div>
                </div>
            }
        },
        {
            title: '订单编号',
            dataIndex: 'out_trade_no',
            key: 'out_trade_no',
            className: 'list_center',
        },
        {
            title: '下单时间',
            dataIndex: 'created_at',
            key: 'created_at',
            className: 'list_center',
        },
        {
            title: '购买张数（份）',
            dataIndex: 'num',
            key: 'num',
            className: 'list_center',
        },
        {
            title: '使用数量',
            dataIndex: 'used_count',
            key: 'used_count',
            className: 'list_center',
        },
        {
            title: '剩余',
            dataIndex: 'unused',
            key: 'unused',
            className: 'list_center',
        },
        {
            title: '有效时间',
            dataIndex: 'expire_time',
            key: 'expire_time',
            className: 'list_center',
        },
        {
            title: '支付金额',
            dataIndex: 'price',
            key: 'price',
            className: 'list_center',
        },
    ];

    componentDidMount(){
        console.log('传参', this.props)
        if(this.props.location.query && this.props.location.query.day){
            this.setState({
                start_time: this.props.location.query.day,
                end_time: this.props.location.query.day,
                timeArray: [this.props.location.query.day, this.props.location.query.day],
                currentPage: 1
            },() => {
                this._initList()
            })
        }else{
            this._initList();
        }
    }

    _initList = async () => {

        const {pageSize, timeArray, currentPage, searchName, } = this.state;
        const params:any = {
            current: currentPage,
            pageSize: pageSize
        };
        if(searchName !== ''){
            params.nickname = searchName;
        }

        if(timeArray.length > 0){
            params.start_time = timeArray[0];
            params.end_time = timeArray[1];
        }

        const res:any = await getRequest(apiUrl.cashCouponList,params);
        if(res.code === 1){
            this.setState({
                productList: res.data.list,
                productCount:  res.data.total,
                totalPrice: res.data.total_price
            })
        }
    }

    searchAction  = async (name:string) => {
        this.setState({
            searchName: name,
            currentPage: 1
        },() => {
            this._initList()
        })
    }

    timeChange = async (time: RangePickerValue, timeString: [string, string]) => {
        this.setState({
            timeArray: timeString,
            currentPage: 1,
            start_time: timeString[0],
            end_time: timeString[1]
        },() => {
            this._initList()
        })
    }

     // 分页
     orderChange = async (pagination:any, filter: any, sorter: any) => {
        this.setState({
            currentPage: pagination.current
        },() => {
            this._initList()
        })
    }

    render(){
        const { productList, totalPrice, start_time, end_time  } = this.state;
        return <div className='l_content'>
            <Row type="flex" align="middle" justify="start" className="mt20 mb40" >
                <Col className="mr30">
                    <span>用户名：</span>
                    <Search placeholder="请输入商品名称" onSearch={this.searchAction} style={{ width: 234 }}/>
                </Col>
                <Col>
                    <span>下单时间：</span>
                    <RangePicker onChange={this.timeChange} placeholder={start_time !== '' ? [start_time, end_time] : ["开始时间","结束时间"]} format="YYYY-MM-DD"/>
                </Col>
            </Row>

            <div className='mb40' style={{fontWeight: 'bold', fontSize: '16px'}}>总收入金额：{totalPrice}元</div>

            <div>
                <Table
                onChange= {this.orderChange}
                dataSource={productList}
                columns={this.columns}
                rowKey={"id"}
                size="middle"
                locale={{emptyText: '暂无数据'}}
                pagination={{
                    current: this.state.currentPage,
                    defaultCurrent: 1,
                    total: this.state.productCount,
                    pageSize: this.state.pageSize,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    showTotal: (total: number) => `共${total}条`,
                    onShowSizeChange: (current:number, size: number) => {this.setState({pageSize: size}, () => {this._initList();})}

                }}/>
            </div>
        </div>;
    }
}
export default CashCoupon