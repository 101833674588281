import {observer} from "mobx-react";
import React from "react";
import {Button, Row, Col, Table, message, Modal, Tag} from "antd";
import {getRequest, postRequest} from '../../../api/http';
import {apiUrl} from '../../../api/api_url';
import {Link} from "react-router-dom";
import {getIsPermisson, messageCustom} from "../../../utils/permisstionUtil";

const key = 'DiscountCoupon';

interface DiscountCoupon {
    history: any,
    location: any
}

@observer
class DiscountCoupon extends React.Component<any> {
    state = {
        couponCount: 0,
        couponList: [],
        currentPage: 1,
        pageSize: 10,
        page: 1
    }

    columns = [
        {
            title: '打折名称',
            dataIndex: 'name',
            key: 'name',
            className: 'list_center',
        },
        {
            title: '已使用数量',
            dataIndex: 'use_num',
            key: 'use_num',
            className: 'list_center',
        },
        {
            title: '状态',
            dataIndex: 'limit',
            key: 'limit',
            className: 'list_center',
            render: (text: any, record: any,) => (
                //<div className="color">{record.is_effect === 1 ? `已生效` : '未生效'}</div>
                <div>{
                    record.is_effect === 1 ? <Tag color="#87d068">已生效</Tag> : <Tag color="#ff0000">未生效</Tag>
                }</div>
            ),
        },
        {
            title: '添加时间',
            dataIndex: 'created_at',
            key: 'created_at',
            className: 'list_center',
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            className: 'list_center',
            render: (text: any, record: any,) => (
                <div className="btn_wrap">
                    <div className="list_btn" onClick={() => {
                        if (!getIsPermisson('1_12_13_4')) {
                            messageCustom();
                            return;
                        }
                        this.props.history.push({pathname: '/sidebar/discountAdd', state: {id: record.id}})
                    }}>编辑
                    </div>
                    <div className="list_btn"
                         onClick={() => this.stopItem(record)}>{record.is_effect === 1 ? '暂停' : '开启'}</div>
                    <div className="list_btn" onClick={() => this.deleteItem(record.id)}>删除</div>
                </div>
            ),
        },
    ];

    componentDidMount() {
        this.onInitCount();
        this.onInitList({});
    }

    // 初始化列表总数
    onInitCount = async () => {
        const res: any = await getRequest(apiUrl.discountCount,);
        if (res.code === 1) {
            this.setState({
                couponCount: res.data.num
            })
        }
    }

    // 初始化列表
    onInitList = async (params: any) => {
        const res: any = await getRequest(apiUrl.discountList, params);
        if (res.code === 1) {
            this.setState({
                couponList: res.data.list
            })
        }
    }

    pageChange = async (page: number) => {
        const {pageSize} = this.state;
        this.setState({
            page
        })
        const params: any = {
            current: page,
            pageSize: pageSize,
        };
        this.onInitList(params)
    }

    deleteItem = async (id: number) => {
        if (!getIsPermisson('1_12_13_6')) {
            messageCustom();
            return;
        }
        Modal.confirm({
            title: '提示',
            content: '确认删除此券',
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                const params = {
                    id
                }
                const res: any = await postRequest(apiUrl.discountDel, params);
                if (res.code === 1) {
                    message.success({content: '删除成功!', key, duration: 2});
                    this.onInitList({});
                    this.onInitCount();
                }
            },
            onCancel() {
            },
        });
    }

    stopItem = (record: any) => {
        if (!getIsPermisson('1_12_13_5')) {
            messageCustom();
            return;
        }
        Modal.confirm({
            title: '提示',
            content: `确认${record.is_effect === 1 ? '暂停' : '开启'}此券`,
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                const params = {
                    id: record.id
                }
                const res: any = await postRequest(apiUrl.discountStart, params);
                if (res.code === 1) {
                    message.success({content: '操作成功!', key, duration: 2});
                    this.onInitList({});
                }
            },
            onCancel() {
            },
        });
    }

    backPage = () => {
        this.props.history.goBack();
    }

    render() {
        const {couponList, couponCount} = this.state;
        console.log('this.props', this.props.history);
        return <div className='l_content'>
            <Row style={{marginBottom: '20px'}}>
                <Col span={24} style={{display: 'flex'}}>
                    {
                        this.props.backShow || (this.props.history && this.props.history.location && this.props.history.location.state && this.props.history.location.state.showBack)
                            ? <div onClick={this.backPage} style={{marginRight: 20}}>
                                <Button type='primary' icon='left' size='large' style={{fontWeight: 'bold'}}>返回</Button>
                            </div>
                            : null
                    }
                    <Button type="primary" size="large" icon="plus" onClick={() => {
                        {
                            if (!getIsPermisson('1_12_13_2')) {
                                messageCustom();
                                return;
                            }
                            this.props.history.push('/sidebar/discountAdd')
                        }
                    }}>
                        添加新打折
                    </Button>
                </Col>
            </Row>

            <div>
                <Table dataSource={couponList} size="middle" columns={this.columns} rowKey={"id"}
                       locale={{emptyText: '暂无数据'}} pagination={{
                    current: this.state.page,
                    total: couponCount,
                    pageSize: this.state.pageSize,
                    onChange: this.pageChange,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    showTotal: (total: number) => `共${total}条`,
                    onShowSizeChange: (current: number, size: number) => {
                        this.setState({pageSize: size});
                        this.onInitList({page: this.state.page, pageSize: size})
                    }
                }}/>
            </div>

        </div>;
    }
}

export default DiscountCoupon