import React, { useEffect, useState } from 'react'

import { Row, Table, Button, Col, Popconfirm, message, Tooltip, Switch } from 'antd';
import { getRequest, postRequest } from '../../../api/http';
import { apiUrl } from '../../../api/api_url';
import { useHistory } from 'react-router-dom';
import btn_add from '../../../assret/images/btn_add.png'
import t_edit from '../../../assret/images/t_edit.png'
import { getIsPermisson, messageCustom } from '../../../utils/permisstionUtil';


interface IListItem {
  id: number
  created_at: string
  updated_at: string
  rules: string
  first_order_percent: string
  order_percent: string
  status: number
  poster: number
  mp_id: string
  shop_user_id: string
  xcx: string
  amount: number
}

interface IList {
  total: number
  items: Array<IListItem>
}

const WeappDistriutions = () => {

  const [list, setlist] = useState<IList>({ total: 0, items: [] });
  const history:any = useHistory();

  useEffect(() => {
    initList();
  }, []);

  const initList = async () => {
    let parms = {
      pageSize: 10,
      current: 1
    }
    const res: any = await getRequest(apiUrl.shareActiviteList, parms);
    if(res && res.data) {
      setlist(res.data);
    }
  }

  //打开就刷新一遍


  const columns = [
    {
      title: '关联小程序',
      dataIndex: 'xcx',
      key: 'xcx',
    },
    {
      title: '佣金比例',
      key: 'age',
      render: (rowdata: any) => {
        return <Row>
          好友下单奖励比例首页{Number(rowdata.first_order_percent) * 100}%,之后每单{Number(rowdata.order_percent) * 100}%
        </Row>
      }
    },
    {
      title: '参与用户数',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: '邀请特权',
      dataIndex: 'invited_pority',
      key: 'invited_pority',
    },
    {
      title: '获奖方式',
      dataIndex: 'reward_way',
      key: 'reward_way',
    },
    {
      title: '获取奖品',
      dataIndex: 'coupons',
      key: 'coupons',
    },
    {
      title: '状态',
      key: 'status',
      render: (rowdata: any) => {
        return (
          <div>
            {
              rowdata.status == 1
              ? '启用'
              : '停用'
            }
          </div>
        )
      }
    },
    {
      title: '操作时间',
      dataIndex: 'updated_at',
      key: 'updated_at',
    },
    {
      title: '操作者',
      dataIndex: 'nickname',
      key: 'nickname',
    },
    {
      title: '操作',
      key: 'action',
      render: (rowdata: any) => {
        return (
          <div style={{display: 'flex', alignItems:'center', justifyContent: 'center'}}>
              <Tooltip placement="bottomRight" title={'编辑'}>
                <img src={t_edit} className='table_icon' onClick={() => eidtConfig(rowdata)}></img>
              </Tooltip>
              <Popconfirm
                  placement="bottomRight"
                  title={rowdata.status == 1 ? '是否停用？' : '是否启用？'}
                  onConfirm={()=>{onOpenStatus(rowdata)}}
                  okText="Yes"
                  cancelText="No"
              >
                  <span style={{cursor: 'pointer',color: '#3975FF'}}>
                  <Switch checked={rowdata.status == 1 ? true : false} size='small' style={{marginRight: '5px'}} />
                    {
                          rowdata.status == 1
                          ? '启用'
                          : '停用'
                      }
                  </span>
              </Popconfirm>
          </div>
        )
      }
    },
  ];

  const eidtConfig = (rowdata: any) => {
    if(!getIsPermisson('1_9_1_2')){
      messageCustom();
      return;
    }
    history.push({
      pathname: '/sidebar/WeappDistriutionAdd',
      state: {
        id: rowdata.id,
        welfare_id: rowdata.welfare_id
      }
    });
  }

  const addConfig = () => {
    if(!getIsPermisson('1_9_1_1')){
      messageCustom();
      return;
    }
    history.push({
      pathname: '/sidebar/WeappDistriutionAdd',
    });
  }

  // 启用，停用
  const onOpenStatus = async (rowdata: any) => {
    if(!getIsPermisson('1_9_1_3')){
      messageCustom();
      return;
    }
    // if(rowdata.status == 2) {
    //   let temp: any = list.items;
    //   let flah = false;
    //   temp.map((item: any, idnex: any) => {
    //     if(item['status'] == 1) {
    //       flah = true
    //     }
    //   })
    //   if(flah) {
    //     message.error('请先暂停其它活动！')
    //     return;
    //   }
    // }
    const res:any = await getRequest(apiUrl.shareStatusUpdates, {
      id: rowdata.id,
      status: rowdata.status == 1 ? 2 : 1
    });
    if(res && res.data) {
       console.log('res', res);
       message.success('操作成功');
       initList()
    }
  }

  return <Row className='weapp_distriutions' style={{padding: '20px'}}>
    <Row className='mb30'>
      <Col>
        <div className='new_btn' onClick={addConfig}>
          <img src={btn_add}></img>
          新建分组
        </div>
      </Col>
    </Row>
    <Table dataSource={list.items} size="middle" pagination={{total: list.total}} rowKey={'id'} columns={columns}  />
  </Row>
}

export default WeappDistriutions