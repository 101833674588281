import React from "react";
import {Button, Row, Col, Input, Radio, Table, Modal, Select, message, Icon, Popconfirm} from "antd";
import { postRequest, getRequest } from '../../api/http';
import { apiUrl } from '../../api/api_url';
const { Option } = Select;

const key = 'updatable';
export default class businessModel extends React.Component<any> {

    state = {
        is_sell_out: false,
    }

    componentDidMount() {
        
    }

    handleSubmit = async (e:any, typeAdd: any) => {
        message.loading({ content: '发布中', key });
        e.preventDefault();

        let goodInfo:any = {};

        const { is_sell_out } = this.state;

        goodInfo.business_model = is_sell_out;

        const res:any = await postRequest(apiUrl.changePassword,goodInfo);
        if(res['code'] === 1){
            message.success({ content: '修改成功!', key, duration: 2 });
            if(typeAdd == 'add') {
                window.history.go(-1)
            }
        }else{
            message.error({ content: res['message'], key, duration: 2 });
        }

    };

    settingChange = (e: any) => {
        let name: any = e.target.name;
        let value: any = e.target.value;
        this.setState({
            [name]: value
        })
    }

    // 返回上一页
    goBack = () => {
        window.history.go(-1)
    }

    render() {

        const { is_sell_out } = this.state;

        return (
            <div className='l_content'>
                <div className="edit_title">系统设置</div>
                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="color_black">营业模式</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <Radio.Group
                                name='is_sell_out'
                                value={is_sell_out}
                                style={{ marginLeft: 20 }}
                                onChange={(e) => this.settingChange(e)}
                            >
                                <Radio value={true}>先用餐后结账</Radio>
                                <Radio value={false}>先结账后用餐</Radio>
                            </Radio.Group>
                    </Col> 
                </Row>
            </div>
        )
    }
}