import React from 'react';

import './addRole.scss';
import { Input, Tree, message, Button, List } from 'antd';
import { getRequest, postRequest } from '../../../../api/http';
import { apiUrl } from '../../../../api/api_url';
import { collectPermisson } from '../../../../utils/permisstionUtil';
import TreeCom from './treeCom/treeCom';


type ParamType = {
    title: string
    allPermissions: any
    permissions: any
  }
type IProps = {
    location: any,
    history: any
}
type IState = {
    roleName: string,
    roleDesc: string,
    isEdit: boolean,
    allPermissions: any
    permissions: any,
    rootPermisson: any
}

const { TreeNode } = Tree;
class AddRole extends React.Component<IProps, IState>{

    constructor(props: IProps) {
        super(props);
        this.state = {
            roleName: '',       // 角色名称
            roleDesc: '',        // 角色描述
            isEdit: false,
            allPermissions: {
                pc_web: [] // pc_web 全部权限
            },
            permissions: {
                pc_web: [] // pc_web 拥有权限
            },
            rootPermisson: {
              root_pc_web: [] // web根节点
            }
        };
    }
    role_id = 0;
    componentDidMount() {
        this.init();
    }

    // 初始化页面数据等
    init = () => {
        if (this.props.location.state) {
            let role = this.props.location.state.role;
            // 更新
            this.role_id = role.role_id;
            this.setState({
                roleName: role.display_name,
                roleDesc: role.description,
                isEdit: true,
            });
            this.requestHavePermisson();
        }
        this.requestAllPermisson();
    }

    // 获取全部权限
    requestAllPermisson = async () => {
        let {allPermissions} = this.state;
       
        const res: any = await getRequest(apiUrl.allPermission);
        if (res.code === 1) {
            this.setState({
              allPermissions: {...allPermissions,pc_web: res.data ?? []}
            })
        } else {
            message.error('服务器异常' + res.message);
        }
    }

    // 获取已拥有权限
    requestHavePermisson = async () => {
        let {permissions} = this.state;
        const res: any = await getRequest(apiUrl.rolePermission, { role_id: this.role_id });
        if (res.code === 1) {
            this.setState({
                permissions: {...permissions,pc_web: res.data ?? []}
            })
        } else {
            message.error('服务器异常' + res.message);
        }
    }

    // 添加角色
    requestAddRole = async () => {
        let { roleDesc, roleName,isEdit,permissions,rootPermisson } = this.state;
        if (!roleName) {
            message.warn('角色名称不能为空');
            return;
        }
        if (!roleDesc) {
            message.warn('角色描述不能为空');
            return;
        }
        if (permissions.pc_web.length == 0) {
            message.warn('请给角色分配权限');
            return;
        }
        let url = isEdit ? apiUrl.updateRole : apiUrl.addRole;
        let param: any = {
            display_name: roleName,
            description: roleDesc,
            permission_arr: [...permissions.pc_web].concat(rootPermisson.root_pc_web)
        };
        if (isEdit) {
            param.role_id = this.role_id;
        }
        const res: any = await postRequest(url, param);
        if (res.code === 1) {
            if (isEdit) {
                message.success('已编辑成功');
                this.props.history.goBack();
            } else {
                message.success('已成功添加')
                this.props.history.goBack();
            }

        } else {
            message.error(res.message);
        }
    }

    // 监听 角色名称 input
    handleRoleName = (e: any) => {
        this.setState({ roleName: e })
    }

    // 监听 角色描述 input
    handleRoleDesc = (e: any) => {
        this.setState({ roleDesc: e })
    }

    // 子组件回调  商家后台 --> PCWEB 点菜机 --> ORDER_MACHINE
    handleChildCallBack = (type: string,data: any) =>{
      let {permissions} = this.state;
        switch(type){
            case 'PCWEB':
                console.log('选择的角色'+data);
                this.setState({
                  permissions: {...permissions,pc_web: data.pc_web ?? []},
                  rootPermisson: {...permissions,root_pc_web: data.root_pc_web ?? []},
              })
                break;
        }
    }
   
    render() {
        let { roleName, roleDesc, isEdit ,permissions,allPermissions} = this.state;
        let params: ParamType ={
            title: "后台权限",
            allPermissions: allPermissions.pc_web,
            permissions:permissions.pc_web
        }
        return (
            <div className="add_role_page " >
                <div className="head_body">
                    <div className="head_title">{isEdit ? '编辑角色' : '添加角色'}</div>
                    <div className="base_info">
                        <div className="input_view">
                            <div className="view_text">
                                角色名称：
                            </div>
                            <div className="input_class">
                                <Input placeholder="输入角色名称" value={roleName} onChange={(e) => this.handleRoleName(e.target.value)} />
                            </div>
                        </div>
                        <div className="input_view">
                            <div className="view_text">
                                角色描述：
                            </div>
                            <div className="input_class">
                                <Input placeholder="输入角色描述" value={roleDesc} onChange={(e) => this.handleRoleDesc(e.target.value)} />
                            </div>
                        </div>
                    </div>
                </div>
                <TreeCom
                    params={params}
                    callBack={this.handleChildCallBack}
                  />
               
                {/* <TreeCom title="点菜机权限" /> */}
                <div className="button_group">
                    <Button className="canel_action" onClick={() => { this.props.history.goBack() }}>取消</Button>
                    <Button type="primary" onClick={() => this.requestAddRole()}>确认</Button>
                </div>
            </div>
        )
    }

}

export default AddRole;