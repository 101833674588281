import React, { useEffect, useState } from 'react'
import './adverManager.scss'
import { Button, Table, Modal, message, Row, Col, Select, DatePicker, Input } from 'antd';
import { getRequest, postRequest } from '../../api/http';
import { apiUrl } from '../../api/api_url';
import { getIsPermisson, messageCustom } from '../../utils/permisstionUtil';
import { useHistory } from 'react-router-dom';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Search } = Input;

interface IProps {

}

/**
 * 广告列表
 */
const AdverManager = (props: IProps) => {
    const history:any = useHistory();
    const [actionModal,setActionModal] = useState(false);
    const [adverList,setAdverList] = useState<any>([]);
    const [count,setCount] = useState(0);
    const [modalTip,setModalTip] = useState({title: '',desc: ''});
    const [actionType,setActionType] = useState(0);// 操作类型
    const [positionList, setpositionList] = useState([])

    // 操作下标
    const [actionIndex,setActionIndex] = useState(0);
    const [page, setpage] = useState(1);
    const [page_size, setpage_size] = useState(10)

// 相当于 componentDidMount 和 componentDidUpdate:
    useEffect(() =>{
        getAdverList();
        getAdverConfig();
    },[]);

    // 获取广告列表
    const getAdverList = async (param={}) =>{
        const res: any = await getRequest(apiUrl.adverList,param);
        if(res.code === 1){
            setAdverList(res.data.list);
            setCount(res.data.count)
        }else{
            message.error(res.message)
        }
    }

     // 获取广告版位信息
     const getAdverConfig = async () =>{
        const res: any = await getRequest(apiUrl.advertConfig);
        if(res.code === 1){
            setpositionList(res.data.position)
        }else{
            message.error(res.message)
        }
    }

    // 控制 弹窗显示内容
    const controModal = (type:number,index:number) =>{
        var uni_key = '2_1_3';
        if(type === 3){
            uni_key = '2_1_4';
        }
        if(!getIsPermisson(uni_key)){
            messageCustom();
            return;
          }
        switch(type){
            case 1:
                //设置隐藏
                setModalTip({
                    title: '设为停用',
                    desc:'设为停用后,小程序将不展示该广告！是否确认停用该广告'
                })
                break;
            case 0:
                //设置显示
                setModalTip({
                    title: '设为启用',
                    desc:'设为启用后,小程序将会展示该广告！是否确认启用该广告'
                })
                break;
            case 3:
                // 删除
                setModalTip({
                    title: '删除广告',
                    desc:'是否确认删除该广告'
                })
                break;
        }
        setActionType(type);
        setActionIndex(index);
        setActionModal(true);
    }

    const setShowOrHide = async () =>{
        let adverListTemp:any = adverList;
       let param:any = {};
       if(actionType === 1){
           param.is_show = 0;
       }else{
            param.is_show = 1;
       }
       const res: any = await postRequest(apiUrl.advertStatus, {id: adverListTemp[actionIndex].id});
       if(res.code === 1){
        adverListTemp[actionIndex].is_show = param.is_show;
        setAdverList([...adverListTemp]);
           if(param.is_show === 0){
                message.success("设置停用成功");
           }else{
                message.success("设置启用成功");
           }
       }else{
           message.error(res.message);
       }
    }

    // 删除广告
    const deleteAdver = async () =>{
        if(!getIsPermisson('2_1_4')){
            messageCustom();
            return;
          }
        let adverListTemp:any = adverList;
        const res:any = await getRequest(apiUrl.deleteAdver+'/'+adverListTemp[actionIndex].id);
        if(res.code === 1){
            adverListTemp.splice(actionIndex,1);
            setAdverList([...adverListTemp]);
            message.success("删除成功");
        }else{
            message.error(res.message);
        }
    }

    // 搜索广告标题
    const searchAdvert = (name: string) => {
        getAdverList({name, page: 1, page_size})
    }

    const positionChange = (position: number) => {
        if(position !== 0){
            getAdverList({position, page: 1, page_size})
        }else{
            getAdverList({page: 1, page_size})
        }
    }

    const statusChange = (status: number) => {
        getAdverList({is_show: status, page: 1, page_size})
    }

    const timeChange = async (time: any, timeStr:[string, string]) => {
        getAdverList({start_time: timeStr[0],end_time: timeStr[1], page: 1, page_size})
    }


        const columns = [
            {
                title: '序号',
                dataIndex: 'index',
                key: 'index',
                render: (text: string, record: any, index:number) => {
                    return <div>{index+1 < 10 ? '0' + (index+1) : index+1}</div>
                }
            },
            {
                title: '广告标题',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '展示开始时间',
                dataIndex: 'start_time',
                key: 'start_time',
            },
            {
                title: '结束展示时间',
                dataIndex: 'end_time',
                key: 'end_time',
                render: (text:string,record:any,index:number) =>
                    <div>
                        {record.time_type == 1 ? record.end_time : '永久'}
                    </div>
            },
            {
                title: '广告版位',
                dataIndex: 'position_name',
                key: 'position_name',
            },
            {
                title: '广告图片',
                dataIndex: 'cover_url',
                key: 'cover_url',
                render: (text:string,record:any,index:number) =>
                <div className='btn_green'>
                    {/* 查看 */}
                    <img alt="广告图" className="adver_img" src={record.cover_url} />
                </div>
            },
            {
                title: '跳转类型',
                dataIndex: 'type_txt',
                key: 'type_txt',
                render: (text:string,record:any,index:number) =>
                <div>
                    {record.type_txt ? record.type_txt : '--'}
                </div>
            },
            {
                title: '跳转链接',
                dataIndex: 'redirect_url',
                key: 'redirect_url',
                render: (text:string,record:any,index:number) =>
                <div>
                    {record.redirect_url ? record.redirect_url : '--'}
                </div>
            },
            {
                title: '广告描述',
                dataIndex: 'desc',
                key: 'desc',
                render: (text:string,record:any,index:number) =>
                <div dangerouslySetInnerHTML={{__html:record.desc}} >

                </div>
            },
            {
                title: '优先级',
                dataIndex: 'priority',
                key: 'priority'
            },
            {
                title: '状态',
                dataIndex: 'adverStatus',
                key: 'adverStatus',
                render: (text:string,record:any,index:number) =>
                <div>
                    {record.is_show === 1 ? '启用中':'停用中'}
                </div>

            },
            {
                title: '创建时间',
                dataIndex: 'create_time',
                key: 'create_time'
            },
            {
                title: '最后修改时间',
                dataIndex: 'update_time',
                key: 'update_time'
            },
            {
                title: '操作',
                key: 'action',
                render: (rowdata:any, record: any, idx: number) => {
                    return <div className="action_group">
                            <div onClick={() =>{
                                if(!getIsPermisson('2_1_2')){
                                    messageCustom();
                                    return;
                                }
                                history.push({pathname: "/sidebar/adverEdit",state: record})
                            }}>编辑</div>
                        
                            <div onClick={() => controModal(record.is_show,idx)}>{record.is_show === 1 ? '停用':'启用'}</div>
                            <div onClick={() => controModal(3,idx)}>删除</div>
                    </div>
                }
            }
        ];


    return <div className="adverManager l_content">
        <Row className='mb40' type='flex' >
            <Col className='mr30'>
                <Button type='primary' onClick={() =>{
                    if(!getIsPermisson('2_1_1')){
                        messageCustom();
                        return;
                    }
                    history.push("/sidebar/adverEdit")
                }}>添加</Button>
            </Col>

            <Col className='mr30'>
                广告标题：<Search placeholder="请输入广告标题" onSearch={searchAdvert} style={{ width: 234 }}/>
            </Col>

            <Col className='mr30'>
                广告版位：<Select style={{width: '150px'}} allowClear placeholder='请选择广告版位' onChange={positionChange}>
                    {positionList.map((item: any) => {
                        return <Option value={item.id} key={item.id}>{item.name}</Option>
                    })}
                </Select>
            </Col>

            <Col className='mr30'>
                广告状态：<Select style={{width: '150px'}} allowClear placeholder='请选择广告状态' onChange={statusChange}>
                    <Option value='1'>启用</Option>
                    <Option value='0'>停用</Option>
                </Select>
            </Col>

            <Col className='mr30'>
                创建时间：<RangePicker onChange={timeChange} placeholder={["开始时间","结束时间"]} format="YYYY-MM-DD"/>
            </Col>
        </Row>

        <Table
            dataSource={adverList}
            columns={columns}
            rowKey={"cover_url"}
            size="middle"
            pagination={{
                defaultCurrent:1,
                total: count,
                pageSize: page_size,
                showQuickJumper: true,
                onChange: (page1:number) =>{
                    setpage(page1);
                    getAdverList({page: page1, page_size});
                },
                showTotal: (total: number) => `共${total}条`,
                showSizeChanger: true,
                onShowSizeChange: (current:number, size: number) => {setpage_size(size); getAdverList({page_size: size})},
            }}
    />

        <Modal
          title=""
          visible={actionModal}
          closable={false}
          closeIcon={null}
          footer={
            [
                // 定义右下角 按钮的地方 可根据需要使用 一个或者 2个按钮
                <Button key="back" type="primary" onClick={() =>{
                    if(actionType === 3){
                        deleteAdver();
                    }else{
                        setShowOrHide();
                    }
                    setActionModal(false);
                  }}>确认</Button>,
                <Button key="submit" onClick={() =>{
                    setActionModal(false);
                  }}>
                      取消
                </Button>,          ]
          }
        >
         <div className="modal_title" style={{marginTop: '20px'}}>{modalTip.title}</div>
         <div className="modal_desc" style={{marginBottom: '20px'}}>
          {modalTip.desc}
         </div>
        </Modal>
    </div>

}

export default AdverManager;