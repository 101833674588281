import React from 'react'

import './developPage.scss'

const DevelopPage = () => {

    return <div className="developPage">
        开发中，敬请期待
    </div>
}

export default DevelopPage;