import React from "react";
import { Menu, Breadcrumb, Row, Button, Col, Table, Modal, message,Tag } from "antd";
import DeductionCoupon from "../realTimeManager/deductionCoupon/deductionCoupon";
import DiscountCoupon from "../realTimeManager/discountCoupon/discountCoupon";
import { Link } from "react-router-dom";
import { getRequest, postRequest } from "../../api/http";
import { apiUrl } from "../../api/api_url";
import { getIsPermisson, messageCustom } from "../../utils/permisstionUtil";

class CouponOther extends React.Component<any> {
    columns = [
        {
            title: '支付名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '渠道',
            dataIndex: 'channel_name',
            key: 'channel_name',
            // render: (text: string, record: any, index: number) => {
            //     return <div>{record.channel_type == 'meituan' ? '美团': '口碑'}</div>
            // }
        },
        {
            title: '活动类型',
            dataIndex: 'discount_type',
            key: 'discount_type',
            render: (text: string, record: any, index: number) => {
                return <div>{record.discount_type == 1 ? '全单打折': record.discount_type == 3 ? '满减' : '扣除不参与打折金额后打折'}</div>
            }
        },
        {
            title: '打多少折',
            dataIndex: 'discount',
            key: 'discount',
            render: (text: string, record: any, index: number) => {
                return <div>{record.discount_type == 1 ? record.discount == 0 ? '不打折': (record.discount / 10) + '折' : '--'}</div>
            }
        },
        {
            title: '满减',
            dataIndex: 'discount_detail',
            key: 'discount_detail',
            render: (text: string, record: any, index: number) => {
                return <div>{text ? text : '--'}</div>
            }
        },
        {
            title: '已使用数量',
            dataIndex: 'use_num',
            key: 'use_num',
        },
        {
            title: '状态',
            dataIndex: 'is_effect',
            key: 'is_effect',
            render: (text: string, record: any, index: number) => {
                return <div>{
                    record.is_effect === 1 ? <Tag color="#87d068">启用</Tag> : <Tag color="#ff0000">停用</Tag>
                }</div>

            }
        },
        {
            title: '操作时间',
            dataIndex: 'updated_at',
            key: 'updated_at',
        },
        {
            title: '操作人',
            dataIndex: 'shop_user_name',
            key: 'shop_user_name',
        },
        {
            title: '操作',
            key: 'action',
            render: (rowdata:any, record: any, idx: number) => {
                return <div className="action_group">
                    <div onClick={() => {
                        if(!getIsPermisson('1_12_13_4')){
                            messageCustom();
                            return;
                        }
                        this.props.history.push({pathname: "/sidebar/paymentAdd",state: record})
                    }}>
                        <div>编辑</div>
                    </div>
                    <div onClick={() => this.statueChange(record)}>{record.is_effect === 1 ? '停用':'启用'}</div>
                    <div onClick={() => this.deleteList(record)}>删除</div>
                </div>
            }
        }
    ]
    state = {
        current: 'deductionCoupon',
        count: 0,
        page_size: 10,
        list: []
    }

    handleClick = (e: any) => {
        this.setState({
          current: e.key,
        }, () => {
            if(e.key == 'payment'){
                this._initList()
            }
        });
    };

    _initList  = async () => {
        const res: any = await getRequest(apiUrl.payList)
        if(res.code == 1){
            this.setState({
                list: res.data
            })
        }
    }

    statueChange = (record: any) => {
        if(!getIsPermisson('1_12_13_5')){
            messageCustom();
            return;
        }
        Modal.confirm({
            title: '提示',
            content: `是否${record.is_effect == 1 ? '停用' : '启用'}此支付方式？`,
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                const params = {
                    id: record.id,
                    is_effect: record.is_effect == 1 ? 2 : 1
                }
                const res:any = await postRequest(apiUrl.updatePayList ,params);
                if(res.code === 1){
                    message.success({ content: '操作成功!', duration: 2 });
                }
                this._initList();
            },
            onCancel() {
            },
        });
    }

    deleteList = (record: any) => {
        if(!getIsPermisson('1_12_13_6')){
            messageCustom();
            return;
        }
        Modal.confirm({
            title: '提示',
            content: `是否删除此支付方式？`,
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                const params = {
                    id: record.id
                }
                const res:any = await getRequest(apiUrl.deletePayList ,params);
                if(res.code === 1){
                    message.success({ content: '删除成功!', duration: 2 });
                }
                this._initList();
            },
            onCancel() {
            },
        });
    }

    render(){
        let { current, count, page_size, list } = this.state;
        return <div className='l_content'>
            <Breadcrumb separator='>' className='mb20'>
                <Breadcrumb.Item>商家工具</Breadcrumb.Item>
                <Breadcrumb.Item>其它优惠</Breadcrumb.Item>
            </Breadcrumb>
            <Menu onClick={this.handleClick} selectedKeys={[this.state.current]} mode="horizontal">
                <Menu.Item key="deductionCoupon">
                抵扣券列表
                </Menu.Item>
                <Menu.Item key="discountCoupon">
                结账打折列表
                </Menu.Item>
                <Menu.Item key="payment">
                支付方式
                </Menu.Item>
            </Menu>
            <div style={{marginTop: 20}}>
                {
                    current === 'deductionCoupon'
                    ? <DeductionCoupon history={this.props.history}/>
                    : null
                }
                {
                    current === 'discountCoupon'
                    ? <DiscountCoupon backShow={false} history={this.props.history}/>
                    : null
                }
                {
                    current === 'payment'
                    ? <div>
                        <Row style={{marginBottom: '20px'}} >
                            <Col span={24} style={{display: 'flex'}}>
                                    <Button type="primary" size="large" icon="plus" onClick={() =>{{
                                        if(!getIsPermisson('1_12_13_3')){
                                            messageCustom();
                                            return;
                                        }
                                        this.props.history.push('/sidebar/paymentAdd')
                                    }}}>
                                        添加支付方式
                                    </Button>
                            </Col>
                        </Row>

                        <Table
                            dataSource={list}
                            columns={this.columns}
                            rowKey={"id"}
                            size="middle"
                            // pagination={{
                            //     total: count,
                            //     pageSize: page_size,
                            //     showQuickJumper: true,
                            //     onChange: (page1:number) =>{
                            //         // setpage(page1);
                            //         // getAdverList({page: page1, page_size});
                            //     },
                            //     showTotal: (total: number) => `共${total}条`,
                            //     showSizeChanger: true,
                            //     // onShowSizeChange: (current:number, size: number) => {setpage_size(size); getAdverList({page_size: size})},
                            // }}
                            />
                    </div>
                    : null
                }
            </div>
        </div>;
    }
}
export default CouponOther