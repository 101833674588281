
/** 
 * 整理最终已选权限
 * choosePermissions: 已选权限id
 * permissions: 全部权限id
 */

import { message } from "antd";

 let choosePermissions:any = [];
 let permissions:any = [];
 let tempChildPermissions:any = []; //子集页面权限
const collectPermisson = (choosePerms:any,perms:any) => {
    choosePermissions = choosePerms;
    permissions = perms;
    tempChildPermissions= [];
    // 已选择的 权限
    let childPerms = filterNochildPerm(permissions);
    return childPerms;

}

/**
 * 过出最子集页面权限
 */

const filterNochildPerm = (allPermisson: any)=>{
    allPermisson.map((item:any) =>{
        if(item.routers){
            filterNochildPerm(item.routers);
            return;
        }else{
            let index = choosePermissions.findIndex((item1:any) => item.id == item1)
            if(index!= -1){
                tempChildPermissions.push(item.id);
            }
        }
    })
    return tempChildPermissions;
}


// 判断该功能是否有权限
const getIsPermisson = (key: string) =>{
    var permissons:any =  window.localStorage.getItem("uni_keys");
    permissons = permissons ? JSON.parse(permissons) : []
    let isHave = false;
    for(var item in permissons){
        if(permissons[item] == key){
            isHave = true;
            break;
        }
    }
    return isHave
}

// 整理子集导航
const collectChildNav =(navList: any) => {
    let resultNav: any = [];
    navList = navList ?? [];
    navList.map((item: any) => {
        if(getIsPermisson(item.uni_key)){
            item.isActive = false;
            resultNav.push(item)
        }
    })

    console.log('navList',navList)
    return resultNav;
}


const messageCustom = () =>{
    message.warning("权限不足请联系管理员");
}

export { collectPermisson,getIsPermisson ,collectChildNav,messageCustom}