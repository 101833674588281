import React, { useEffect, useState, useRef } from 'react'
import ReactEcharts from "echarts-for-react";
import { useHistory } from 'react-router-dom';
import { Row } from 'antd'
import see_icon from '../../../assret/images/see.png'
import './radarChart.scss'

type Iprops = {
    data:any,
    max:number
}

const SiyuRadarChart = (props:Iprops)=>{

    const { data, max } = props

    const history = useHistory()

    const list = ['忠实用户','就餐人数','消费人数','成功邀请人数','充值次数']

    const [ option, setOption ] = useState<any>({
        tooltip: {
            formatter: function(params:any) {
                var result = params.data.name+'<br>';
                params.data.value.map((item:any,index:number)=>{
                    result += list[index]+" : "+'<span style="color:#fff">'+ item+"</span><br>"
                })
                return result;
            }
        },
        legend: {
            show:false,
        },
        radar: {
            splitNumber:4,
            shape: 'circle',
            name: {
                textStyle: {
                    color: '#4A4A4A',
                    fontSize:14,
                    borderRadius: 3,
                    padding: [-10, 5]
                },
                formatter: function (value:any) {
                    return value.split('-')[0] + '\n{a|'+value.split('-')[1]+'人}';
                },
                rich: {
                    a: {
                        color:'rgba(74, 74, 74, 1)',
                        fontSize:12,
                        fontWeight:'bold',
                        lineHeight: 22,
                    },
                },
            },
            indicator: [
                { 
                    name: '忠实用户-0', 
                    max: 100,
                    axisLabel: {
                        show: true,
                        color: '#838D9E',
                        showMaxLabel: true, //不显示最大值，即外圈不显示数字30
                        showMinLabel: true, //显示最小数字，即中心点显示0
                    },                
                },
                { name: '就餐人数-0', max: 100},
                { name: '消费人数-0', max: 100},
                { name: '成功邀请人数-0', max: 100},
                { name: '充值次数-0', max: 100},
            ],
            axisLine: {
                lineStyle: {
                    color: '#E1E1E1'
                }
            },
            splitLine: {
                lineStyle: {
                    width:0.5,
                    color: '#fff',
                }
            },   
            splitArea: {
                areaStyle: {
                    color: ['#fff','#fff','#fff','#fff',],
                    shadowColor: 'rgba(174, 154, 255, 0.3)',
                    shadowBlur: 10
                },
            },    
        },
        series: [{
            name:'社群',
            type: 'radar',
            itemStyle: {
                color: '#FF5166'
            },       
            areaStyle: {
                opacity: 0.1
            },        
            data: [
                {
                    value: [0, 0, 0, 0, 0],
                },
            ]
        }]
    });

    useEffect(()=>{
        var max = Math.max(...Object.keys(data.chart).map((item:any)=>{return data.chart[item]}))
        max = Math.ceil(Math.ceil(max/10)*10/4)*4?Math.ceil(Math.ceil(max/10)*10/4)*4:100
        //初始化数据
        let optionTemp:any = JSON.parse(JSON.stringify(option))
        optionTemp.radar.indicator[0] = {
            max:max,
            name:'忠实客户-'+data.chart.chart_loyer_user,
            axisLabel: {
                show: true,
                color: '#838D9E',
                showMaxLabel: true, //不显示最大值，即外圈不显示数字30
                showMinLabel: true, //显示最小数字，即中心点显示0

            },                
        }
        optionTemp.radar.indicator[1] = {
            max:max,
            name:'就餐人数-'+data.chart.chart_eat_user
        }
        optionTemp.radar.indicator[2] = {
            max:max,
            name:'消费人数-'+data.chart.chart_consumer_user
        }
        optionTemp.radar.indicator[3] = {
            max:max,
            name:'成功邀请人数-'+data.chart.chart_invited_user
        }
        optionTemp.radar.indicator[4] = {
            max:max,
            name:'充值次数-'+data.chart.chart_rechagre_counts
        }
        optionTemp.series[0].data[0].value[0] = data.chart.chart_loyer_user
        optionTemp.series[0].data[0].value[1] = data.chart.chart_eat_user
        optionTemp.series[0].data[0].value[2] = data.chart.chart_consumer_user
        optionTemp.series[0].data[0].value[3] = data.chart.chart_invited_user
        optionTemp.series[0].data[0].value[4] = data.chart.chart_rechagre_counts
        optionTemp.series[0].data[0].name=data.name
        setOption(optionTemp)
    },[data])

    const goDetail = (id:number,name:string) => {
        history.push('/sidebar/userGroupDetail',{id:id,name:name})
    }

    return (
        <div className="siyu_radar_chart">
            <Row type="flex" align="middle" justify="space-between">
                <div className="radar_chart_title">{data.name}</div>
                <div className='new_btn' onClick={()=>{goDetail(data.id,data.name)}}>
                    <img src={see_icon}></img>
                    查看详情
                </div>            
            </Row>  
            <div className="radar_chart_sub_title"><span>社群总人数</span> : {data.users}</div>   
            <div className="radar_chart_sub_title"><span>实收金额</span> : {data.actual_fee}</div>   
            <div className="radar_chart_sub_title"><span>充值金额</span> : {data.recharge}</div>          
            <ReactEcharts option={option} style={{ height: '350px', width: '100%' }} className='react_for_echarts' />
        </div>
    )
}

export default SiyuRadarChart

