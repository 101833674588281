import React, { useState, Fragment, useEffect } from 'react'
import { Row, Avatar, Typography, Button, Table, message,Modal} from 'antd';
import { getRequest, postRequest } from '../../../../api/http';
import { apiUrl } from '../../../../api/api_url';
import OrderPadding from './order_padding_detail';
import OrderEditDrawer from './order_edit_drawer';
import OrderChargebackDrawer from './order_chargeback_drawer';
import OrderDetailModal from '../../../timely_message/order_times/order_detail_modal';
import { getIsPermisson, messageCustom } from '../../../../utils/permisstionUtil';

const { Text } = Typography;
interface ITableData {
  items: Array<any>,
  total: number
}
const { confirm } = Modal;
interface IcurrentDrawerData {
  desk: string,
  amount: number,
  price: string,
  price_vip: string,
  code: string,
  status_display: string,
  dishes: Array<{
    mark: string,
    dishes: Array<any>,
    created_at: string
  }>
}

interface IOrderManagerTableProps {
  condition: {
    [key: string] : any
  }
}

const OrderManagerTable = (props: IOrderManagerTableProps) => {
  const [showEditOrderDrawer, setshowEditOrderDrawer] = useState(false);
  const [showPaddingOrderDrawer, setshowPaddingOrderDrawer] = useState(false);
  const [showChargeBack, setshowChargeBack] = useState(false);
  const [page, setpage] = useState(1);
  const [currentDrawerData, setcurrentDrawerData] = useState<IcurrentDrawerData>({
    desk: '',
    dishes: [],
    amount: 0,
    price: '',
    price_vip: '',
    code: '',
    status_display: '',
  });

  const [pageSize, setpageSize] = useState(10)
  const [tableData, settableData] = useState<ITableData>({
    items: [],
    total: 0
  });
  const [visibleDetail, setvisibleDetail] = useState(false)
  const [currentCode, setcurrentCode] = useState('')
  const [sendDate, setsendDate] = useState({})

  useEffect(() => {
    props.condition && initOrders(1, props.condition);
  }, [props.condition])

  // 初始化订单列表
  const initOrders = async(curpage = 1, condition = {}) => {
    let parms = {
      pageSize: pageSize,
      current: curpage
    }
    parms = {...parms, ...condition}
    const ret:any = await getRequest(apiUrl.fetchFoodOrders, parms);
    if(ret && ret.data) {
      settableData(ret.data);
    }
  }

  // 恢复订单
  const recoveryOrder = (item:any) =>{
    if(!getIsPermisson('1_4_1_7')){
      messageCustom();
      return;
    }
    confirm({
      title: '确定要恢复订单: '+item.code+' 吗?',
      onOk: async () =>{
        const res:any = await getRequest(apiUrl.cancelOrder,{code: item.code});
        if(res.code === 1){
          message.success("该订单已恢复");
        }else{
          message.error(res.message)
        }
      },
      onCancel: () =>{}
    })
  }

  const columns = [
    {
      title: "订单号",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "订单状态",
      dataIndex: "status_display",
      key: "status_display",
    },
    {
      title: "餐台号",
      dataIndex: "desk",
      key: "desk",
    },
    {
      title: "用餐人数",
      dataIndex: "user_amount",
      key: "user_amount",
    },
    {
      title: "已点(份)",
      dataIndex: "amount",
      key: "amount",
      sorter: true
    },
    {
      title: "下单金额(元)",
      dataIndex: "price",
      key: "price",
      sorter: true
    },
    {
      title: "付款用户",
      key: "fan",
      render: (userinfo: any , record: any) => {
        if(userinfo && userinfo.fan) {
          return <Row type="flex" justify="start" align="middle" onClick={ () => {
            if(!getIsPermisson('1_4_1_5')){
                messageCustom();
                return;
              }
              setcurrentCode(record.code)
              setsendDate(record)
              setvisibleDetail(true)
          }}>
            <Avatar src={userinfo.fan.headimgurl} style={{marginRight: 10}} />
            <Text className='btn_green'>{userinfo.fan.nickname}</Text>
          </Row>
        }
        return <Row>--</Row>
      }
    },
    {
      title: "实付金额(元)",
      dataIndex: "actual_fee",
      key: "actual_fee",
      sorter: true
    },
    {
      title: "支付类型",
      dataIndex: "pay_type",
      key: "pay_type",
    },
    {
      title: "创建时间",
      dataIndex: "created_at",
      key: "created_at",
      sorter: true
    },
    {
      title: "支付时间",
      dataIndex: "paid_at",
      key: "paid_at",
      sorter: true
    }
    ,{
      title: "操作",
      key: "action",
      render: (rowdata: any) => {
        return <Row type="flex">
          {
            (rowdata && rowdata.is_recover_order) && <Button type="link" size="small" onClick={() => recoveryOrder(rowdata)}>恢复订单</Button>
          }

          {
            (rowdata && rowdata.status === 1) &&
            <Button type="link" size="small"
              onClick={() => tapEditOrderBtn(rowdata)}>修改订单</Button>
          }
          {
            (rowdata && rowdata.is_back_pay) &&
            <Button type="link" size="small"
              onClick={() => tapChargeBackBtn(rowdata)}>退款</Button>
          }
          <Button type="link" size="small" onClick={() => showOrderDetail(rowdata)}>详情</Button>
          <Button type="link" size="small" onClick={() => printStatement(rowdata)}>补打印结账单</Button>
          {
            (rowdata && rowdata.is_checkout_pay) && <Button type="link" size="small" onClick={() => cancelClose(rowdata)}>反结账</Button>
          }
            {
                (rowdata && rowdata.is_cancel_order) && <Button type="link" size="small" onClick={() => employeeCancelOrder(rowdata)}>取消订单</Button>
            }
        </Row>
      }
    }
  ];

  // 反结账
  const cancelClose = async (rowdata: any) => {
    if(!getIsPermisson('1_4_1_4')){
      messageCustom();
      return;
    }
    confirm({
      title: '确定要对此订单进行反结账操作',
      okText: "确定",
      cancelText: "取消",
      onOk: async () =>{
        let params:any = {
          mp_food_orders_code: rowdata.code
        }
        if(rowdata.group_key){
          params.group_key = rowdata.group_key;
        }
        const res:any = await postRequest(apiUrl.checkToPay, params);
        if(res && res['code'] === 1) {
          message.success('操作成功');
          initOrders(page)
        }
      },
      onCancel: () =>{}
    })

  }

    // 服务员取消订单
    const employeeCancelOrder = async (rowdata: any) => {
        if(!getIsPermisson('1_4_1_8')){
            messageCustom();
            return;
        }
        confirm({
            title: '确定取消该订单？订单取消后，将无法恢复，请谨慎操作',
            okText: "确定",
            cancelText: "取消",
            onOk: async () =>{
                let params:any = {
                    mp_food_orders_code: rowdata.code
                }
                if(rowdata.group_key){
                    params.group_key = rowdata.group_key;
                }
                const res:any = await postRequest(apiUrl.employeeCancelOrder, params);
                if(res && res['code'] === 1) {
                    message.success('订单取消成功');
                    initOrders(page)
                }
            },
            onCancel: () =>{}
        })

    }

  // 打印结账单
  const printStatement = async (rowdata: any) => {
    if(!getIsPermisson('1_4_1_3')){
      messageCustom();
      return;
    }
    console.log('row', rowdata);
    const res:any = await postRequest(apiUrl.printBill, { code: rowdata.code, group_key: rowdata.group_key, has_invoice: false});
    if(res && res['code'] === 1) {
      message.success('打印成功');
    }
  }

  // 修改订单
  const tapEditOrderBtn = (rowdata: any) => {
    if(!getIsPermisson('1_4_1_6')){
      messageCustom();
      return;
    }
    setcurrentDrawerData(rowdata);
    setshowEditOrderDrawer(true);
  }

  // 点击退款
  const tapChargeBackBtn = (rowdata: any) => {
    if(!getIsPermisson('1_4_1_1')){
      messageCustom();
      return;
    }
    setcurrentDrawerData(rowdata);
    setshowChargeBack(true);
  }

  // 显示订单详情
  const showOrderDetail = async (rowdata: any) => {
    if(!getIsPermisson('1_4_1_2')){
      messageCustom();
      return;
    }
    setcurrentDrawerData(rowdata);
    setshowPaddingOrderDrawer(true);
  }

  // 排序，翻页
  const tableChange = (pagination: any, filter: any, sorter: any) => {
    console.log({...props.condition});
    setpage(pagination.current);
    let obj:any = {
      order: sorter.columnKey,
      current: pagination.current,
      ...props.condition
    }
    if(sorter.order){
      obj.sort = sorter.order == 'ascend' ? 'asc' : 'desc'
    }else{
      obj.sort = ''
    }
    initOrders(page, obj)
  }

  return (
    <Fragment>
      <Table
        rowKey={"code"}
        dataSource={tableData.items}
        columns={columns}
        size="middle"
        onChange={tableChange}
        pagination={{
          current: page,
          pageSize: pageSize,
          total: tableData.total,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total: number) => `共${total}条`,
          onShowSizeChange: (current:number, size: number) => {setpageSize(size); initOrders(page, {pageSize: size}) }

        }}
      />

      {/* 修改订单 */}
      <OrderEditDrawer
        showEditOrder={showEditOrderDrawer}
        changeVisitity={setshowEditOrderDrawer}
        code={currentDrawerData.code ? currentDrawerData.code : ''} />

      {/* 订单退款 */}
      <OrderChargebackDrawer
        showChargeBacks={showChargeBack}
        code={currentDrawerData.code ? currentDrawerData.code : ''}
        changeVisitity={setshowChargeBack}
      />

      {/* 订单详情 */}
      <OrderPadding
        showPaddingOrder={showPaddingOrderDrawer}
        changeVisitity={setshowPaddingOrderDrawer}
        code={currentDrawerData.code ? currentDrawerData.code : ''} />

      <Modal
        title="详情"
        visible={visibleDetail}
        onOk={() => setvisibleDetail(false)}
        onCancel={() => setvisibleDetail(false)}
        okText="确认"
        cancelText="取消"
        width="1056px"
        destroyOnClose
        footer={null}
        centered={true}
      >
        <OrderDetailModal code={currentCode} send={sendDate} />
      </Modal>

    </Fragment>
  );
};

export default OrderManagerTable