import React from 'react'
import { Row, Button, Steps, Col, message, Spin} from 'antd'
import { getRequest, postRequest } from "../../../api/http";
import {apiUrl} from "../../../api/api_url";
import CreateActivity from './components/createActivity';
import CreateAccount from './components/createAccount';
import CreateSend from './components/createSend';
import CreateReward from './components/createReward';
import CreatePoster from './components/createPoster';
const {Step} = Steps;

interface TurnplateCreateProps {
    history: any,
    location: any
}

interface TurnplateCreateState {

}

class TurnplateCreate extends React.Component<TurnplateCreateProps, TurnplateCreateState> {
    propsParam:any;
    id = -1;

    state = {
        currentIndex: 3,
        loading: true
    }

    canvas:any;
    taskParam: any = {};
    accountParam: any = [];
    sendParam: any = {};
    rewardParam: any = [];
    posterParam: any = {};
    posterChild: any;

    componentDidMount(): void {
        this.propsParam = this.props.location.state;
        if(this.props.location.state !== undefined){
            this.id = this.props.location.state.id;
            this.initComponent(this.props.location.state.id);
        }else{
            this.setState({
                loading: false
            })
        }
    }

    initComponent = async (id: number) => {
        const res:any = await getRequest(apiUrl.luckInfo,{id:id});
        if(res.code === 1){
            const luck_info = res.data.luck_info;
            const luck_award = res.data.luck_award_list;
            // 活动设置
            this.taskParam = {
                name: luck_info.name,
                activityTime: [luck_info.start_time, luck_info.end_time],
                detail: luck_info.detail,
                join_people_num_show: luck_info.join_people_num_show,
                join_people_num_limit: luck_info.join_people_num_limit,
                look_num_fictitious: luck_info.look_num_fictitious,
                looking_num_fictitious: luck_info.looking_num_fictitious,
            }

            if(luck_info.join_people_num_show){
                this.taskParam.join_people_num_fictitious = luck_info.join_people_num_fictitious;
            }
            if(luck_info.join_people_num_limit){
                this.taskParam.join_people_num_max = luck_info.join_people_num_max;
            }

            // 公众号
            this.accountParam = [luck_info.mp_info];

            // 派奖设置
            this.sendParam = {
                people_luck_num_free: luck_info.people_luck_num_free,
                people_luck_num_all_max_limit: luck_info.people_luck_num_all_max_limit,
                people_luck_num_day_max_limit: luck_info.people_luck_num_day_max_limit,
                subscribe_reward: luck_info.subscribe_reward
            }
            if(luck_info.people_luck_num_all_max_limit){
                this.sendParam.people_luck_num_all_max = luck_info.people_luck_num_all_max;
            }
            if(luck_info.people_luck_num_day_max_limit){
                this.sendParam.people_luck_num_day_max = luck_info.people_luck_num_day_max;
            }

            // 奖励设置
            this.rewardParam = luck_award;

            // 海报设置
            this.posterParam = JSON.parse(luck_info.poster);
        }

        this.setState({
            loading: false
        })
    }

    next = () => {
        let currentIndexTem = this.state.currentIndex;
        switch (currentIndexTem) {
            case 0: // 任务设置
                this.checkTaskParams();
                break;
            case 1: // 公众号设置
                this.checkAccountParams();
                break;
            case 2: // 派奖设置
                this.checkSendParams();
                break;
            case 3: // 奖励设置
                this.checkRewardParams();
                break;
            case 4: // 海报设置
                this.posterChild.submitParentParams();
                this.checkPosterParams();
                break;
        }

    };

    onRef = (ref:any) => this.posterChild = ref;

    prev =() => {
        const currentIndex = this.state.currentIndex - 1;
        this.setState({ currentIndex });
    };

    handleParams = (type:number,param:any,canvas = '') => {
        console.log(type,param,'111');
        if(canvas){
            this.canvas = canvas;
        }
        switch (type) {
            case 1: // 任务设置
                this.taskParam = param;
                break;
            case 2: // 公众号设置
                this.accountParam = param;
                break;
            case 3:
                this.sendParam = param;
                break;
            case 4: // 奖励设置
                this.rewardParam = param;
                break;
            case 5: // 海报设置
                this.posterParam = param;
                break;
        }
    }

    checkTaskParams = () => {
        let {name,activityTime,detail, join_people_num_show, join_people_num_limit} = this.taskParam;

        if(name == null || name.length === 0){
            message.error("任务名称不能为空!");
            return false;
        }

        if(activityTime == null){
            message.error("请选活动时间周期!");
            return false;
        }

        if(detail == null || detail === ''){
            message.error("请填写任务描述!");
            return false;
        }

        if(join_people_num_show == null || join_people_num_show === ''){
            message.error("请选择参与显示!");
            return false;
        }

        if(join_people_num_limit == null || join_people_num_limit === ''){
            message.error("请选择参与人员限制!");
            return false;
        }
        let currentIndexTem = this.state.currentIndex;
        this.setState({ currentIndex:currentIndexTem + 1 });
        return true;
    }

    checkAccountParams = () => {
        if(this.accountParam.length <= 0){
            message.error("请选择一个公众号!");
            return false;
        }
        let currentIndexTem = this.state.currentIndex;
        this.setState({ currentIndex:currentIndexTem + 1 });
        return true;
    }

    checkSendParams = () => {
        const { people_luck_num_free,
                people_luck_num_all_max,
                people_luck_num_all_max_limit,
                people_luck_num_day_max,
                people_luck_num_day_max_limit,
                subscribe_reward} = this.sendParam;
        if(people_luck_num_free === null || people_luck_num_free === ''){
            message.error("起始抽奖数不能为空!");
            return false;
        }
        if(people_luck_num_all_max_limit === null){
            message.error('请选择是否限制用户最大抽奖次数');
            return false;
        }else if(people_luck_num_all_max_limit && (people_luck_num_all_max === null || people_luck_num_all_max === '')){
            message.error('请输入限制用户最大抽奖次数');
            return false;
        }

        if(people_luck_num_day_max_limit === null){
            message.error('请选择是否限制用户每日最大抽奖次数');
            return false;
        }else if(people_luck_num_day_max_limit && (people_luck_num_day_max === null || people_luck_num_day_max === '')){
            message.error('请输入限制用户每日最大抽奖次数');
            return false;
        }

        if(subscribe_reward === null || subscribe_reward === ""){
            message.error('拉新可增加人数不能为空');
            return false;
        }
        let currentIndexTem = this.state.currentIndex;
        this.setState({ currentIndex:currentIndexTem + 1 });
        return true;
    }

    checkRewardParams = () => {
        let list:any = this.rewardParam;

        console.log(list);
        if(list.length <= 0){
            message.error('请输入奖项名称');
            return false;
        }

        list.map((item:any) => {
            if(item.name === null || item.name === ''){
                message.error('请输入奖项名称');
                return false;
            }
        });
        list.map((item:any) => {
            item.award_type = 'food';
            item.award_id = item.award_info.id;
        });
        let currentIndexTem = this.state.currentIndex;
        this.setState({ currentIndex:currentIndexTem + 1 });
        return true;
    }

    checkPosterParams = () => {
        let posterParam = this.posterParam;
        if(Object.keys(posterParam).length === 0){
            message.error("请上传海报哦！");
            return false
        }
        if(posterParam.imageUrl === null && posterParam.imageUrl === ''){
            message.error("请上传海报哦！");
            return false
        }
        // 开始创建活动
        this.requestCreateTask();
        return true;
    }

    // 开始创建任务
    requestCreateTask = async () =>{
        let {taskParam, accountParam, sendParam, rewardParam, posterParam} = this;
        this.canvas.loadFromJSON(this.posterParam, () => {
            // console.log('23posterParam23posterParam23posterParam23posterParam23posterParam');
        },(o:any,object:any) => { });
        let params:any = {
            name: taskParam.name,
            start_time: taskParam.activityTime[0],
            end_time: taskParam.activityTime[1],
            detail: taskParam.detail,
            join_people_num_show: taskParam.join_people_num_show,
            join_people_num_limit: taskParam.join_people_num_limit,
            mp_id: accountParam[0].id,
            people_luck_num_free: sendParam.people_luck_num_free,
            people_luck_num_all_max_limit: sendParam.people_luck_num_all_max_limit,
            people_luck_num_day_max_limit: sendParam.people_luck_num_day_max_limit,
            subscribe_reward: sendParam.subscribe_reward,
            award_list: rewardParam,
            poster: JSON.stringify(posterParam)
        };

        if(taskParam.join_people_num_show){
            params.join_people_num_fictitious = taskParam.join_people_num_fictitious;
        }

        if(taskParam.join_people_num_limit){
            params.join_people_num_max = taskParam.join_people_num_max;
        }

        if(sendParam.people_luck_num_all_max_limit){
            params.people_luck_num_all_max = sendParam.people_luck_num_all_max;
        }

        if(sendParam.people_luck_num_day_max_limit){
            params.people_luck_num_day_max = sendParam.people_luck_num_day_max;
        }

        if(taskParam.look_num_fictitious !== null && taskParam.look_num_fictitious !== ''){
            params.look_num_fictitious = taskParam.look_num_fictitious;
        }

        if(taskParam.looking_num_fictitious !== null && taskParam.looking_num_fictitious !== ''){
            params.looking_num_fictitious = taskParam.looking_num_fictitious;
        }

        if(this.id === -1){
            const res: any = await postRequest(apiUrl.luckAdd,params);
            if(res.code === 1){
                message.success("添加活动成功");
                this.props.history.push('/sidebar/turnplate');
            }else{
                message.error(res.message);
            }
        }else{
            params.id = this.id;
            const res: any = await postRequest(apiUrl.luckEdit,params);
            if(res.code === 1){
                message.success("编辑活动成功");
                this.props.history.push('/sidebar/turnplate');
            }else{
                message.error(res.message);
            }
        }
    };


    render() {
        const { currentIndex, loading } = this.state;
        const steps= [
            {
                title: '活动设置',
                content: <CreateActivity callbackParent={this.handleParams.bind(this)} param={this.taskParam}/>,
            },
            {
                title: '参与公众号设置',
                content: <CreateAccount callbackParent={this.handleParams.bind(this)} param={this.accountParam}/>,
            },
            {
                title: '派奖设置',
                content: <CreateSend callbackParent={this.handleParams.bind(this)} param={this.sendParam}/>,
            },
            {
                title: '奖励设置',
                content: <CreateReward callbackParent={this.handleParams.bind(this)} param={this.rewardParam}/>,
            },
            {
                title: '海报设置',
                content: <CreatePoster onRef={this.onRef} callbackParent={this.handleParams.bind(this)} param={this.posterParam} />,
            },
        ];

        return (
            loading ? <div className='loading_view'><Spin /></div>:
            <div className='l_content'>
                <Steps current={currentIndex}>
                    {steps.map(item => (
                        <Step key={item.title} title={item.title} />
                    ))}
                </Steps>
                <div className="steps-content">{steps[currentIndex].content}</div>
                <div className='action_steps'>
                <Row>
                    <Col span={10}/>
                    <Col span={2}>
                        {
                            currentIndex > 0 && (
                                <Button size="default" onClick={this.prev}>
                                    上一步
                                </Button>
                            )
                        }
                    </Col>
                    <Col span={2}>
                        {
                            currentIndex < steps.length -1 && (
                                <Button type="primary" onClick={this.next} size="default">
                                    下一步
                                </Button>
                            )
                        }
                        {
                            currentIndex  === 4 && (
                                <Button type="primary" onClick={this.next} size="default">
                                    保存
                                </Button>
                            )
                        }
                    </Col>
                </Row>
            </div>
            </div>
        );
    }
}

export default TurnplateCreate;