import React, { useEffect, useState } from 'react'
import { Modal, Input } from 'antd'
import './numChangeDialog.scss'
import { keepTwoNum } from '../../utils/numUtils';

const NumChangeDialog = (props: any)=>{
  const [data,setdata] = useState<any>({})
  const [currentNum, setcurrentNum] = useState<any>('1')

  useEffect(()=>{
    setdata(props.data)
  })

  const [numList, setnumList] = useState<any>([7,8,9,4,5,6,1,2,3,0,'.','c'])

  //用于刷新的方法
  const [refresh,setRefresh] = useState(false);

  const refreshAction = () => {
    if(refresh === true){
      setRefresh(false)
    }else{
      setRefresh(true)
    }
  }
  useEffect(()=>{},[refresh])

  const numChange = (item: any) => {
      let newItem = '';
      if(item === 'c'){
          newItem = '';
      }else if(item === ''){
          newItem = currentNum;
      }else if(item === 0){
          if(currentNum === ''){
              newItem = ''
          }else{
              newItem = currentNum + item;
          }
      }else if(item === '.'){
        if (currentNum.indexOf('.') > 0) {
            newItem = currentNum
        }else{
            newItem = currentNum + item;
        }
      }else{
          newItem = currentNum + item;
      }
      setcurrentNum(newItem)
  }

  return (
    <Modal
        title={data.title ? data.title : ""}
        visible={data.visible ? data.visible : false}
        footer={false}
        onCancel={()=> {data.onCancel()}}
        destroyOnClose={true}
      >
        <div className="num_change_dialog">
            <div className='digit_wrap'>
                <div className='digit_con'>
                    <div className='digit_top'>
                        <Input type='number' value={currentNum} autoFocus className="input" onChange={(e:any) => {
                            if(!e.target.value) {
                                e.target.value = '';
                            }
                            let str = e.target.value.toString()
                            if (str.indexOf('.') > 0) {
                                let str1 = str.split('.');
                                if (str1[1].length > 2) {
                                    let str2 = str1[1].substring(0,2);
                                    str = str1[0] + '.' + str2;
                                }
                            }
                            setcurrentNum(str)
                        }} />
                    </div>
                    <div className='digit_bottom'>
                        {
                            numList.map((item: any) => {
                                return <div key={item} onClick={() => numChange(item)}>{item}</div>
                            })
                        }
                    </div>
                </div>
            </div>
            <div className='digit_btn' onClick={() => {
                data.onOk(keepTwoNum(currentNum));
                setcurrentNum('1')
            }}>确定</div>
        </div>
    </Modal>
  )
}

export default NumChangeDialog