import React, { useEffect, useState } from "react";
import './index.scss'
import { Row, Col, Pagination, message } from "antd";
import { Button } from "antd";
import { getRequest } from "../../api/http";
import { apiUrl } from "../../api/api_url";

const Notification = () => {
    const [page, setpage] = useState(1)
    const [pageSize, setpageSize] = useState(10)
    const [info, setinfo] = useState({total: 0,list: []})

    useEffect(() => {
        _initList()
    },[])

    const _initList = async () => {
        const res: any = await getRequest(apiUrl.printerFail,{page, pageSize, type: 2})
        if(res.code == 1){
            setinfo(res.data)
        }
    }

    const pageChange = async (p: number) => {
        console.log(p)
        const res: any = await getRequest(apiUrl.printerFail,{page: p, pageSize, type: 2})
        if(res.code == 1){
            setpage(p)
            setinfo(res.data)
        }
    }

    const rePrinter = async (id: number) => {
        const res: any = await getRequest(apiUrl.reprinter+id)
        if(res.code == 1){
            message.success('重新打印成功')
            _initList()
        }else{
            message.error(res.message)
        }
    }

    return (
        <div className='l_content'>
            <div className='noti_title'>全部</div>

            <div className='noti_wrap'>
                {
                    info.list.map((item: any) => {
                        return <Row className='noti_item' type='flex' align='middle' justify='space-between' key={item.id}>
                            <Col>
                                <div className='info'>{item.table_name}桌 {item.printer_name} 打印失败</div>
                                <div className='time'>{item.created_at}</div>
                            </Col>
                            <Col>
                                <Button type='primary' onClick={() => rePrinter(item.id)}>重新打印</Button>
                                {/* <Button disabled>已打印</Button> */}
                                {/* 未连接，已连接 */}
                            </Col>
                        </Row>
                    })
                }

                <div className='pagi_wrap'>
                    <Pagination current={page} total={info.total} onChange={pageChange} pageSize={pageSize} />
                </div>
            </div>
        </div>
    )
}

export default Notification;