import React, { useState, useEffect } from 'react'
import { Row, Input, Button, message, Select, Radio, Checkbox } from 'antd'
import { useHistory } from 'react-router-dom';
import { postRequest, getRequest } from '../../../../../api/http';
import { apiUrl } from '../../../../../api/api_url';
import './add_vip_group.scss'
import { isArray } from 'lodash';

const { Option } = Select;

const AddVipGroup = () => {

  const history = useHistory();
  // const platformList = ['平台', '美团', '星选', '口碑', '抖音', '在线接龙']
  const [ channelList, setChannelList ] = useState<any>({}) 
  const [groupName, setgroupName] = useState('');
  const [searchData, setSearchData] = useState<any>({
    condition: '1',
    config: [
      {status: '', day: "", low_limit: "", high_limit: "", channel:'', state: false, type:''},
      {status: '', day: "", low_limit: "", high_limit: "", channel:'', state: false, type:''},
      {status: '', day: "", low_limit: "", high_limit: "", channel:'', state: false, type:''},
      {status: '', day: "", low_limit: "", high_limit: "", channel:'', state: false, type:''},
      {status: '', day: "", low_limit: "", high_limit: "", channel:'', state: false, type:''},
      {status: '', day: "", low_limit: "", high_limit: "", channel:'', state: false, type:''},
      {status: '', day: "", low_limit: "", high_limit: "", channel:'', state: false, type:''},
    ]
  })
  const [groupDetailList, setGroupDetailList] = useState<any>( {
    condition: '1',
    config: [
      {status: '', day: "", low_limit: "", high_limit: "", channel:'', state: false, type:''},
      {status: '', day: "", low_limit: "", high_limit: "", channel:'', state: false, type:''},
      {status: '', day: "", low_limit: "", high_limit: "", channel:'', state: false, type:''},
      {status: '', day: "", low_limit: "", high_limit: "", channel:'', state: false, type:''},
      {status: '', day: "", low_limit: "", high_limit: "", channel:'', state: false, type:''},
      {status: '', day: "", low_limit: "", high_limit: "", channel:'', state: false, type:''},
      {status: '', day: "", low_limit: "", high_limit: "", channel:'', state: false, type:''},
    ]
  });
  const [tagList, setTagList] = useState([{ type: false, txt: '消费次数' }, { type: false, txt: '人均消费' }, { type: false, txt: '累计消费金额' }, { type: false, txt: '账户余额' }, { type: false, txt: '就餐次数' }, { type: false, txt: '上次消费距今天数' }, { type: false, txt: '第三方优惠' }]);

  const goBack = () => history.goBack()

  const [selectState, setSelectState] = useState(1)

  const [rowData, setrowData] = useState<any>();

  useEffect(() => {
    const state: any = history.location.state
    getChannelList()
    if (state && state.rowdata) {
      setrowData(state.rowdata)
      setgroupName(state.rowdata.name)
      ongetGroupDetail(state.rowdata.id)
    }
  }, [])

  //获取折扣券渠道列表
  const getChannelList = async()=>{
      const res:any = await getRequest(apiUrl.getGroupOperationChannel)
      if(res && res['code'] === 1) {
          setChannelList(res.data)
      }
  }

  //获取分组详情
  const ongetGroupDetail = async (id: any) => {
    let params = {
      id: id
    }
    const res: any = await getRequest(apiUrl.groupDetail, params);
    if (res.code === 1) {
      setSelectState(res.data.condition)
      if(JSON.stringify(res.data.config) == '[]') {
        let temp:any = {
          condition: '1',
          config: [
            {status: '', day: "", low_limit: "", high_limit: "", channel:'', state: false, type:''},
            {status: '', day: "", low_limit: "", high_limit: "", channel:'', state: false, type:''},
            {status: '', day: "", low_limit: "", high_limit: "", channel:'', state: false, type:''},
            {status: '', day: "", low_limit: "", high_limit: "", channel:'', state: false, type:''},
            {status: '', day: "", low_limit: "", high_limit: "", channel:'', state: false, type:''},
            {status: '', day: "", low_limit: "", high_limit: "", channel:'', state: false, type:''},
            {status: '', day: "", low_limit: "", high_limit: "", channel:'', state: false, type:''},
          ]
        }
        setGroupDetailList(temp);  
      } else {
        //处理后台返回的数据，全部统一转化
        let tag_list = tagList;
        let temp:any = [];
        res.data.config.map((item:any, index:any) => {
          //兼容旧数据的数组形式，存在数组的一律取第一个
          if(isArray(item)){
            temp[index] = item.length>0?item[0]:{status: '', day: "", low_limit: "", high_limit: "", channel:'', state: false, type:''}
          }else{
            temp[index] = item
          }
          if(index==5){
            if(temp[index].day) {
              tag_list[index]['type'] = true;
            }            
          }else if(index==6) {
            if(temp[index].status!=='') {
              tag_list[index]['type'] = true;
            }
          }else{
            if(temp[index].low_limit) {
              tag_list[index]['type'] = true;
            }
          }
        })
        setGroupDetailList({condition:res.data.condition,config: temp});
        setSearchData({condition: res.data.condition,config: temp});
        setTagList([...tag_list]);
      }
    }
  }
  //保存分组
  const saveMemberGroup = async () => {
    let flag = false;
    if (!groupName) {
      message.error('请填写分组名称');
      return;
    }
    let param: any = {
      name: groupName,
      ...searchData
    }
    try {
      param.id = rowData.id
    } catch (error) {
      
    };
    param.config.forEach(async (element: any, index:any) => {
      if(tagList[index]['type']) {
        if(isObjEmpty(element,index)) {
          flag = true;
        }
      }
    });
    //判断是否选择了分组条件is_select_group
    if(tagList.filter((item:any)=>item.type).length==0)param.config = []
    if(!flag) {
      param.config = JSON.stringify(param.config);
      const res: any = await postRequest(apiUrl.groupSave, param);
      if(res && res['code'] === 1) {
        message.success('保存成功');
        goBack();
      }
    }
  }

  //判断对象是否有空值
  const isObjEmpty = (obj: any,idx: any) => {
    let flag = false
    let platform_flag = false
    delete obj.state  
    switch(true){
      case idx==0||idx==1||idx==2||idx==4:
        Object.keys(obj).forEach((element,i) => {
            if(element!='status'&&element!='channel'&&element!='type'){
              if(!obj[element]) {
                flag = true
                return
              }
            }
        }) 
        if(flag){
            message.error('请填写完整的'+tagList[idx].txt+'！')
            return true     
        }else{
          if(Number(obj.low_limit) > Number(obj.high_limit)) {
            message.error('开始'+tagList[idx].txt+'不能大于结束'+tagList[idx].txt+'！')
            return true 
          }
        }
        break
      case idx==3:  
        Object.keys(obj).forEach((element,i) => {
          if(element!='status'&&element!='channel'&&element!='day'&&element!='type'){
            if(!obj[element]) {
              flag = true
              return
            }
          }
        }) 
        if(flag){
            message.error('请填写完整的'+tagList[idx].txt+'！')
            return true     
        }else{
          if(Number(obj.low_limit) > Number(obj.high_limit)) {
            message.error('开始'+tagList[idx].txt+'不能大于结束'+tagList[idx].txt+'！')
            return true
          }
        }            
        break
      case idx==5:
        Object.keys(obj).forEach((element,i) => {
          if(element=='day'){
            if(!obj[element]) {
              flag = true
              return 
            }
          }
        }) 
        if(flag){
            message.error('请填写完整的'+tagList[idx].txt+'！')
            return true;     
        }   
        break
      case idx==6:
        console.log('obj',obj)
        if(obj.status){
          Object.keys(obj).forEach((element,i) => {
            if(element!='status'){
              if(element=='channel'){
                if(!obj[element]){
                  platform_flag=true
                  return  
                }
              }else if(element!='day'&&element!='high_limit'&&element!='day'&&element!='low_limit'){
                if(!obj[element]) {
                  flag = true;
                  return
                }
              }
            }
          }) 
        }
        if(flag){
            message.error('请填写完整的'+tagList[idx].txt+'！')
            return true     
        }else{
          if(Number(obj.low_limit) > Number(obj.high_limit)) {
            message.error('开始'+tagList[idx].txt+'不能大于结束'+tagList[idx].txt+'！')
            return true
          }
          if(platform_flag){
            message.error('用券情况请至少选择一个平台')
            return true
          }
        }          
        break
    }
  }

  // 选择的条件
  const inputValuechange = async (key: string, type: string, val: any, valkey: string, idx:any) => {
      if(valkey=='channel'){
        searchData[key][type][valkey] = val.join(',');
      }else{
        searchData[key][type][valkey] = val;
      }
      setSearchData({...searchData});
      setGroupDetailList({...searchData})
  }

  //选择满足所有还是任意条件
  const onCondition = (e: any) => {
    searchData['condition'] = e;
    setSelectState(e)
  }

  //选择分组条件
  const onTag = (index: any) => {
    // if(tagList[index].type) {
      let list:any = groupDetailList;
      list['config'][index] = {status: tagList[index].type?'':(index==6?1:''), day: "", low_limit: "", high_limit: "", channel:'', state: false, type:tagList[index].type?'':(index==6?1:'')}
      setSearchData({condition: list.condition,config: list['config']});
    // }
    tagList[index].type = !tagList[index].type;
    setTagList([...tagList])
  }

  //删除条件
  const groupDetailListDel = (idx:any)=>{
    let list:any = groupDetailList;
    list['config'][idx] = {status: '', day: "", low_limit: "", high_limit: "", channel:'', state: false, type:''}
    setSearchData({condition: list.condition,config: list['config']});
    tagList[idx].type = !tagList[idx].type;
    setTagList([...tagList])
  }

  return <Row className="add_vip_group l_content">
    <div className='group-name'>
      <div className='group-name-title'>分组名称：</div>
      <Input className='group-name-inp' placeholder="请输入活动名称" value={groupName} onChange={(e) => setgroupName(e.target.value)} style={{ width: '300px' }} />
    </div>
    <div className='group-condition'>
      <div className='group-condition-title'>分组条件：</div>
      <div className='group-condition-left'>
        <div className='group-condition-left-tip'>请选择需要标签条件</div>
        <div className='group-condition-left-tag'>
          <div className='group-condition-left-tag-left'>交易数据：</div>
          <div className='group-condition-left-tag-right'>
            {
              tagList.map((item, index) => {
                return (
                  <div key={index} className='group-condition-left-tag-right-list' onClick={() => { onTag(index) }}>
                    <div className={item.type ? 'group-condition-left-tag-right-list-item active' : 'group-condition-left-tag-right-list-item'}>{item.txt}</div>
                  </div>
                )
              })
            }
          </div>
        </div>
        <div className='group-condition-left-bottom'>
          <div className='group-condition-left-bottom-left'>满足以下：</div>
          <div className='group-condition-left-bottom-center'>
            <Select value={String(selectState)} className='group-condition-left-bottom-center-select' onChange={onCondition}>
              <Option value='1'>所有</Option>
              <Option value='0'>任意</Option>
            </Select>
          </div>
          <div className='group-condition-left-bottom-right'>条件的用户</div>
        </div>
      </div>
    </div>
    <div className='group-additional'>
      <div>
            {
              (groupDetailList['config'][0]&&groupDetailList['config'][0]['state']) || tagList[0]['type']&&(
                <div style={{display: 'flex'}}>
                    <div className='group-additional-consumption'>
                      <div className='group-additional-consumption-num'>消费次数：</div>
                      <div className='group-additional-consumption-daynum'>
                        <Input type='text' placeholder={searchData['config'][0]['day']} onChange={value => inputValuechange('config', '0', value.target.value, 'day',-1)} />
                      </div>
                      <div className='group-additional-consumption-daytxt'>天内累计消费</div>
                      <div className='group-additional-consumption-start'><Input type='text' placeholder={searchData['config'][0]['low_limit']} onChange={value => inputValuechange('config', '0', value.target.value, 'low_limit',-1)} /></div>
                      <div className='group-additional-consumption-reach'>至</div>
                      <div className='group-additional-consumption-end'><Input type='text' placeholder={searchData['config'][0]['high_limit']} onChange={value => inputValuechange('config', '0', value.target.value, 'high_limit',-1)} /></div>
                      <div className='group-additional-consumption-company'>次</div>
                      <div className='group-additional-consumption-unlimited'>注：-1表示无限制</div>
                      <div className="new_del" onClick={() => groupDetailListDel(0)}>
                        删除
                      </div>
                    </div>
                </div>
              )
            }
            {
              (groupDetailList['config'][1]&&groupDetailList['config'][1]['state']) || tagList[1]['type']&&(
                <div style={{display: 'flex'}}>
                    <div className='group-additional-consumption'>
                      <div className='group-additional-consumption-num'>人均消费：</div>
                      <div className='group-additional-consumption-daynum'>
                        <Input type='text' placeholder={searchData['config'][1]['day']} onChange={value => inputValuechange('config', '1', value.target.value, 'day',-1)} />
                      </div>
                      <div className='group-additional-consumption-daytxt'>天内累计消费</div>
                      <div className='group-additional-consumption-start'><Input type='text' placeholder={searchData['config'][1]['low_limit']} onChange={value => inputValuechange('config', '1', value.target.value, 'low_limit',-1)} /></div>
                      <div className='group-additional-consumption-reach'>至</div>
                      <div className='group-additional-consumption-end'><Input type='text' placeholder={searchData['config'][1]['high_limit']} onChange={value => inputValuechange('config', '1', value.target.value, 'high_limit',-1)} /></div>
                      <div className='group-additional-consumption-company'>元</div>
                      <div className='group-additional-consumption-unlimited'>注：-1表示无限制</div>
                      <div className="new_del" onClick={() => groupDetailListDel(1)}>
                        删除
                      </div>
                    </div>
                </div>
              )
            }
            {
              (groupDetailList['config'][2]&&groupDetailList['config'][2]['state']) || tagList[2]['type']&&(
                <div style={{display: 'flex'}}>
                    <div>
                      <div className='group-additional-cumulative'>
                      <div className='group-additional-cumulative-num'>累计消费金额：</div>
                      <div className='group-additional-cumulative-daynum'>
                        <Input type='text' placeholder={searchData['config'][2]['day']} onChange={value => inputValuechange('config', '2', value.target.value, 'day', -1)} />
                      </div>
                      <div className='group-additional-cumulative-daytxt'>天内累计消费</div>
                      <div className='group-additional-cumulative-start'><Input type='text' placeholder={searchData['config'][2]['low_limit']} onChange={value => inputValuechange('config', '2', value.target.value, 'low_limit', -1)} /></div>
                      <div className='group-additional-cumulative-reach'>至</div>
                      <div className='group-additional-cumulative-end'><Input type='text' placeholder={searchData['config'][2]['high_limit']} onChange={value => inputValuechange('config', '2', value.target.value, 'high_limit', -1)} /></div>
                      <div className='group-additional-cumulative-company'>元</div>
                      <div className='group-additional-cumulative-unlimited'>注：-1表示无限制</div>
                      <div className="new_del" onClick={() => groupDetailListDel(2)}>
                        删除
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
            {
              (groupDetailList['config'][3]&&groupDetailList['config'][3]['state']) || tagList[3]['type']
                ? <div className='group-additional-balance'>
                  <div className='group-additional-balance-account'>账户余额：</div>
                  <div className='group-additional-balance-start'><Input type='text' placeholder={searchData['config'][3]['low_limit']} onChange={value => inputValuechange('config', '3', value.target.value, 'low_limit', -1)} /></div>
                  <div className='group-additional-balance-reach'>至</div>
                  <div className='group-additional-balance-end'><Input type='text' placeholder={searchData['config'][3]['high_limit']} onChange={value => inputValuechange('config', '3', value.target.value, 'high_limit', -1)} /></div>
                  <div className='group-additional-balance-company'>元</div>
                  <div className='group-additional-balance-unlimited'>注：-1表示无限制</div>
                  <div className="new_del" onClick={() => groupDetailListDel(3)}>
                    删除
                  </div>                  
                </div>
                : ''
            }
            {
              (groupDetailList['config'][4]&&groupDetailList['config'][4]['state']) || tagList[4]['type']&&(
                <div style={{display: 'flex'}}>
                    <div className='group-additional-consumption'>
                      <div className='group-additional-consumption-num'>就餐次数：</div>
                      <div className='group-additional-consumption-daynum'>
                        <Input type='text' placeholder={searchData['config'][4]['day']} onChange={value => inputValuechange('config', '4', value.target.value, 'day',-1)} />
                      </div>
                      <div className='group-additional-consumption-daytxt'>天内累计就餐</div>
                      <div className='group-additional-consumption-start'><Input type='text' placeholder={searchData['config'][4]['low_limit']} onChange={value => inputValuechange('config', '4', value.target.value, 'low_limit',-1)} /></div>
                      <div className='group-additional-consumption-reach'>至</div>
                      <div className='group-additional-consumption-end'><Input type='text' placeholder={searchData['config'][4]['high_limit']} onChange={value => inputValuechange('config', '4', value.target.value, 'high_limit',-1)} /></div>
                      <div className='group-additional-consumption-company'>次</div>
                      <div className='group-additional-consumption-unlimited'>注：-1表示无限制</div>
                      <div className="new_del" onClick={() => groupDetailListDel(4)}>
                        删除
                      </div>
                    </div>
                </div>                
              )
            }
            {
              (groupDetailList['config'][5]&&groupDetailList['config'][5]['state']) || tagList[5]['type']
                ? <div className='group-additional-balance'>
                  <div className='group-additional-balance-account'>上次消费距今天数：</div>
                  <div className='group-additional-balance-end'><Input type='text' placeholder={searchData['config'][5]['day']} onChange={value => inputValuechange('config', '5', value.target.value, 'day', -1)} /></div>
                  <div className='group-additional-balance-company'>天</div>
                  <div className='group-additional-balance-unlimited'>注：-1表示无限制</div>
                  <div className="new_del" onClick={() => groupDetailListDel(5)}>
                    删除
                  </div>                  
                </div>
                : ''
            }
            {
              (groupDetailList['config'][6]&&groupDetailList['config'][6]['state']) || tagList[6]['type']
                ? <div className='group-additional-coupon'>
                    <div className='group-additional-coupon-left'>第三方优惠：</div>
                    <div className='group-additional-coupon-right'>
                      <div className="mb20">
                        <Select value={searchData['config'][6]['type']} style={{width:200}} onChange={(e:any)=>{{inputValuechange('config', '6', e, 'type', -1)}}}>
                          <Option value={1}>套餐</Option>
                          <Option value={2}>代金券</Option>
                        </Select>
                      </div>
                      <Radio.Group value={searchData['config'][6]['status']} className="mb20" onChange={(e)=>{inputValuechange('config', '6', e.target.value, 'status', -1)}}>
                          <Radio value={1}>已使用券</Radio>
                          <Radio value={0}>未使用券</Radio>
                      </Radio.Group>
                      {
                        searchData['config'][6]['status']==1&&(
                          <>
                            {/* <div className="group-additional-coupon-right-input mb20">
                              <div className="group-additional-coupon-right-input-item"><Input placeholder={searchData['config'][6]['day']} onChange={value => inputValuechange('config', '6', value.target.value, 'day', -1)} /></div>天内使用优惠券抵扣
                              <div className="group-additional-coupon-right-input-item"><Input placeholder={searchData['config'][6]['low_limit']} onChange={value => inputValuechange('config', '6', value.target.value, 'low_limit', -1)} /></div>至
                              <div className="group-additional-coupon-right-input-item"><Input placeholder={searchData['config'][6]['high_limit']} onChange={value => inputValuechange('config', '6', value.target.value, 'high_limit', -1)} /></div>元
                              <span className="group-additional-coupon-right-input-unlimited">注：-1表示无限制<span className="new_del" onClick={() => groupDetailListDel(6)}>删除</span></span>
                            </div> */}
                            <div>
                              <Checkbox.Group onChange={(value)=>{{inputValuechange('config', '6', value, 'channel', -1)}}} value={searchData['config'][6]['channel'].split(',')}>
                                {
                                  Object.keys(channelList).map((item)=>{
                                    return <Checkbox key={'platformList_'+item} value={item}>{[channelList[item]]}</Checkbox>
                                  })
                                }
                              </Checkbox.Group>
                            </div>                          
                          </>
                        )
                      }
                    </div>
                </div>
                : ''
            }
        </div>
    </div>
    <Row style={{ marginTop: 300 }} type="flex" justify="center">
      <Button style={{ marginRight: 100 }} onClick={goBack}>取消</Button>
      <Button type="primary" onClick={saveMemberGroup}>保存</Button>
    </Row>
  </Row>
}

export default AddVipGroup