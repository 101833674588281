import React, { useEffect, useState } from 'react'
import { Modal, Radio, message } from 'antd'
import { getRequest, postRequest } from '../../../../api/http'
import { apiUrl } from '../../../../api/api_url'

type Iprops = {
    visible:boolean,
    onClose():void,
    onOpen(info:any):void,
}

const RechargeModal = (props:Iprops)=>{

    const { visible, onClose, onOpen } = props
    const [ smsPackage, setSmsPackage ] = useState<any>([])
    const [ checkedId, setCheckedId ] = useState(0)
    const [ channel, setChannel ] = useState(1)
    // const channelList = [ '微信', '支付宝', '余额支付' ]
    const channelList = [ '微信', '支付宝' ]

    useEffect(()=>{
        getSmsPackage()
    },[])

    const getSmsPackage = async() => {
        const res:any = await getRequest(apiUrl.getSmsPackage);
        if(res&&res.code==1){
            setSmsPackage(res.data)
        }
    }

    const handleOk = async() => {
        if(!checkedId){
            message.error('请选择短信套餐')
            return false
        }

        if(!channel){
            message.error('请选择支付方式')
            return false
        }
        
        let params = {
            id:checkedId,
            channel:channel
        }

        const res:any = await postRequest(apiUrl.smsRecharge,params);
        if(res&&res.code==1){
            //打开支付二维码
            let data:any = res.data
            let packageInfo = smsPackage.filter((item:any)=>item.id==checkedId)
            console.log(packageInfo)
            if(packageInfo.length==0){
                message.error('找不到该套餐，请重新选择套餐')
                return false
            }
            data.amount = packageInfo?packageInfo[0].amount:0
            data.channel = channel
            onOpen(data)
        }else{
            message.error(res.message||'提交失败')
        }

    }

    return(
        <Modal
            title='短信充值'
            visible={visible}
            onOk={handleOk}
            onCancel={onClose}
            width={455}
            className="recharge_modal"
            closable={false}
        >
            <div className="input_list">
                {
                    smsPackage.map((item:any,index:number)=>{
                        return(
                            <div key={item.id} className={item.id==checkedId?"input_item input_item_active":"input_item"} onClick={()=>{setCheckedId(item.id)}}>
                                <span>{item.num}条短信</span>
                                <span className="color_red">{item.amount}元</span>
                            </div>     
                        )
                   
                    })
                }
                <Radio.Group value={channel} onChange={(e)=>{setChannel(e.target.value)}}>
                    {
                        channelList.map((item,index)=>{
                            return <Radio key={index+1} value={index+1}>{item}</Radio>
                        })
                    }
                </Radio.Group>
                {/* <div className="input_item">
                    <span>30000条短信</span>
                    <span className="color_red">500元</span>
                </div>
                <div className="input_item">
                    <span>20000条短信</span>
                    <span className="color_red">200元</span>
                </div>
                <div className="input_item">
                    <span>10000条短信</span>
                    <span className="color_red">100元</span>
                </div> */}
            </div>
        </Modal> 
    )
}

export default RechargeModal