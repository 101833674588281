import React, {useEffect, useState} from "react";
import { Row, Col, Button, Divider } from "antd";
import {apiUrl} from "../../../../api/api_url";

const wx_pubilc = 'https://mp.weixin.qq.com/'
const PublicUnAuth = () =>{

    // 捕获按钮点击事件 1 跳转公众号首页 2 绑定公众号 跳转公众号授权 3.
    const handerAction = (type:number) =>{
        switch(type){
            case 1:
                window.open(wx_pubilc)
                break;
            case 2:
                window.location.href = process.env.REACT_APP_BASEURL+apiUrl.accountAuthH5 + `?token=${localStorage.getItem('token')}&auth_type=1&type=2`
                break;
            case 3:
                break;
        }
    }

    // 行元素
    const getRow = (child:any) =><Row gutter={16} align="middle" justify="start" className="gutter-row" type="flex">{child}</Row>

    // 列元素
    const getCol = (span:number,child: any) => getRow(<Col className="gutter-box" span={span}>{child}</Col>)

    return <div className="bind_applet l_content">
        {getCol(12,<>
            <div className="gutter-box">
                绑定微信公众号
            </div>
            <Button type="primary" className="action_button" onClick= {() => handerAction(1)}>还没有公众号？</Button>
            <Button type="primary">查看教程</Button>
        </>)}
        <Divider />
        {getCol(24,<div className="sub-text-1"> 绑定微信公众号，把门店和微信打通</div>)}
        {getCol(24,<div className="sub-text-2"> 绑定后既可以再这里管理你的公众号，可以在公众号自动回复/图文素材/自定义菜单等功能，中结合商品进行推广。</div>)}
        {getCol(24,<div className="sub-text-3"> 温馨提示：为了保证所有的功能能正常使用，收起是请把所有权限同意授权给刷吃。</div>)}
        {getCol(24,<div className="sub-text-4"> 注意：需要公众平台绑定的管理员个人微信号进行扫码确认授权。</div>)}
        {getCol(24,<Button className="sub-button-1" type="primary" onClick= {() => handerAction(2)}>立即绑定</Button>)}
    </div>
}

export default PublicUnAuth