import { observable, action } from "mobx";

export interface IAppStore {
  phone: string, // 登录账号
  setPhone(phone: string):void // 设置登录账号
  serviceWorkerStatus: {
    // serviceWorkerInitialized: boolean
    // serviceWorkerUpdated: boolean
    serviceWorkerRegistration: any
  }
}


export class AppStore implements IAppStore {
  @observable
  phone = '';
  @observable
  serviceWorkerStatus = {
    // serviceWorkerInitialized: false,
    // serviceWorkerUpdated: false,
    serviceWorkerRegistration: null
  }
  @observable
  test = "我是默认内容";
  @observable
  groupTab = "custom";
  @observable
  communityTab = "community";
  @observable
  scoreTab = 0;

  @action // 设置手机号
  setPhone = (phone: string) => this.phone = phone;

  @action
  setServiceWorkerRegistration = (val: any) => {
    this.serviceWorkerStatus = {...this.serviceWorkerStatus, serviceWorkerRegistration: val};
  }

  @action
  setTest = (txt: any) => {
    this.test = txt;
  }

  //设置分组tab
  @action
  setGroupTab = (txt: string) => {
    this.groupTab = txt;
  }
  //设置社群tab
  @action
  setCommunityTab = (txt: string) => {
    this.communityTab = txt;
  }
  //设置积分列表tab
  @action
  setScoreTab = (txt: number) => {
    this.scoreTab = txt;
  }
}