import React, {useEffect, useState} from "react";
import AppletUnAuth from './applet_unAuth/index'
import down_icon from '../../../assret/images/down_icon.png'

import './bind_applet.scss'
import { Row, Col,Avatar,Modal, Button, message } from "antd";
import { getRequest } from '../../../api/http';
import { apiUrl } from '../../../api/api_url';
import { useLocation } from "react-router-dom";

const BindApplet = () =>{
    const location = useLocation();
    const [status,setStatus] = useState(1)
    const [visible,setVisible] = useState(false)
    const [tipVisible,setTipVisible] = useState(false)
    const [actionType,setActionType] = useState(1)
    const [info,setInfo] = useState<any>({})
    const [tips,setTips] = useState<any>({alert:'',tips:'',head_img:''})
    const [modalObj,setModalObj] = useState({
        title: '',
        tip: ''
    })

    useEffect(()=>{
        controlModelTip()
        getRequest(apiUrl.shopMiniProgramInfo).then((res:any)=>{
            if(res.code==1){
                setInfo(res.data.info?res.data.info:{})
            }else{
                message.error(res.message||'获取信息失败')
            }
        }).catch(err=>{
            message.error(err||'获取信息失败')
        })
    },[])

    // 行元素
    const getRow = (child:any) =><Row gutter={16} align="middle" justify="start" className="gutter-row" type="flex">{child}</Row>

    // 列元素
    const getCol = (span:number,child: any) => getRow(<Col className="gutter-box" span={span}>{child}</Col>)

    // 弹出重新授权弹窗或 解绑授权 1 重新授权 2解绑
    const showModal = (type: number) =>{
        let obj = {title: '',tip:''}
        setActionType(type)
       if(type == 1){
           obj.title = '重新授权'
           obj.tip = '请使用商家绑定的公众号进行授权，否则将可能导致重要数据丢失或者自动回复、模板消息等功能异常。为保证你在公众号自动回复、模板消息等功能的正常使用，授权时请确保把所有权限授权给刷吃。'
       }else{
            obj.title = '解绑授权'
            obj.tip = '解除绑定公众号，会造成当前商家的重要信息丢失，解绑后，不能使用自动回复、微信模板消息、自定义菜单功能，请谨慎操作。'
       }
       console.log(obj)
       setModalObj(obj)
       setVisible(true)
    }

    // 确认执行操作
    const handleModalOk = () =>{
        if(actionType==1){
            window.location.href = process.env.REACT_APP_BASEURL+apiUrl.accountAuthH5 + `?token=${localStorage.getItem('token')}&auth_type=2&type=2`
        }else{

        }
        setVisible(false)
    }

    const downloadIamge = (imgsrc:any, name:any) => {//下载图片地址和图片名
        return false
        let image = new Image();
        // 解决跨域 Canvas 污染问题
        image.setAttribute("crossOrigin", "anonymous");
        image.onload = function() {
            let canvas = document.createElement("canvas");
            canvas.width = image.width;
            canvas.height = image.height;
            let context:any = canvas.getContext("2d");
            context.drawImage(image, 0, 0, image.width, image.height);
            let url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
            let a = document.createElement("a"); // 生成一个a元素
            let event = new MouseEvent("click"); // 创建一个单击事件
            a.download = name || "photo"; // 设置图片名称
            a.href = url; // 将生成的URL设置为a.href属性
            a.dispatchEvent(event); // 触发a的单击事件
        };
        image.src = imgsrc;
    }
    const handleModel =() =>{
        setTipVisible(!tipVisible)
    };

    /**
     * 公众号授权回调 判断
     */
    const controlModelTip =()=>{
        const params = new URLSearchParams(location.search);
        console.log('params:',params.get("alert"))
        if(params.get("alert") != null){
            tips.alert = unicodeToUtf8(params.get("alert") as string);
            tips.tips = unicodeToUtf8(params.get("tips") as string);
            tips.head_img = params.get("head_img");
            setTips(tips)
            handleModel();
        }
    };
    /**
     * unicode 转中文
     */
    const unicodeToUtf8 =(str:string) =>{
        str = unescape(str.replace(/\\/g, "%"))
        str = str.substring(1,str.length-1);
        return str;
    };

    return !info.id ? <AppletUnAuth /> :
    <div className="bind_applet l_content">
        {getCol(24,<div className="title_text">微信小程序</div>)}
        {getCol(24,<div className="fz16 color333 mb15">基础信息</div>)}
        {getRow(
            <div className="row_view mbt16">
                <div className="sub_title">小程序名称 :</div>
                <div className="sub_unInput">{info.nick_name}</div>
            </div>
        )}
        {getRow(
            <div className="row_view mbt16">
                <div className="sub_title">小程序头像 :</div>
                <Avatar src={info.head_img} size={64} icon="user" />
            </div>
        )}
        {getRow(
            <div className="row_view mbt16">
                <div className="sub_title">小程序码 :</div>
                <Avatar src={info.qrcode_url} shape="square" size={64} icon="user" />
                <a href={info.qrcode_url} target="target">
                    <img src={down_icon} className="down_icon" onClick={()=>{downloadIamge(info.qrcode_url,'小程序码')}}/>
                </a>
            </div>
        )}
        {getRow(
            <div className="row_view mbt16">
                <div className="sub_title">小程序授权状态 :</div>
                <div className="sub_unInput">已授权</div>
                <div className="action_group">
                    {/* <Button type="primary" className="action_text" onClick={() => showModal(1)}>重新授权</Button> */}
                    {/* <Button type="danger" className="action_text"  onClick={() => showModal(2)}>解绑授权</Button> */}
                </div>
            </div>
        )}
        {getRow(
            <div className="row_view mbt16">
                <div className="sub_title">主体信息 :</div>
                <div className="sub_unInput">{info.principal_name}</div>
            </div>
        )}

        {getCol(24,<div className="fz16 color333 mb15  mt30">账号信息</div>)}
        {getRow(
            <div className="row_view mbt16">
                <div className="sub_title">小程序AppID :</div>
                <div className="sub_unInput">{info.appid}</div>
            </div>
        )}
        {getRow(
            <div className="row_view mbt10">
                <div className="sub_title">原始ID :</div>
                <div className="sub_unInput">{info.user_name}</div>
            </div>
        )}

        <Modal
          title={modalObj.title}
          visible={visible}
          onOk={()=> handleModalOk()}
          onCancel={()=> setVisible(false)}
          okText="确认"
          cancelText="取消"
        >
            <div className="tip_style">{modalObj.tip}</div>
        </Modal>
        <Modal
            visible={tipVisible}
            footer={null}
            title={null}
            onCancel={handleModel}
        >
            <div className="model_view">
                <div className='model_title'>绑定失败</div>
                <Avatar size={90} src={tips.head_img} />
                <div className='model_result_tips'>{tips.alert}</div>
                <div className='model_tips'>{tips.tips}</div>
            </div>

        </Modal>

    </div>


}

export default BindApplet