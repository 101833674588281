import {
	Row,
	Typography,
	Card,
	Input,
	Button,
	Descriptions,
	Table,
	message,
	Spin,
} from "antd";
import React, { useEffect, useState } from "react";
import { getRequest, postRequest } from "../../../api/http";
import { apiUrl } from "../../../api/api_url";
import Consumption from './components/consumption/consumption';
import DiningRecord from './components/dining_records/dining_records';
import './order_detail_modal.scss'

type IProps = {
	code: string;
	send: any;
};

type IorderData = {
	actual_fee: string;
	ali_pay: string;
	amount: number;
	code: string;
	coupons_pay: string;
	created_at: string;
	desk: string;
	discount_desc: string;
	discounts_fee_total: string;
	dishes: Array<Idishes>;
	eating_time: string;
	fan: null | Iuser;
	users: Array<Iuser>;
	price: string
	zhuli_dish: {
		id: number;
		main_pic: string;
		name: string;
		price: string;
		printer_key: string;
		standard: string;
	};
	user_amount: number;
	status_display: string;
	present_foods: Array<Idishes>;
	redeem_dishes: Array<Idishes>;
	third_coupon_list: any;
	pay_style: any;
	platform_coupon_list: any;
	zhuli_status: number;
};

type Iuser = {
	count: number;
	group: string;
	group_id: number;
	groupid: number;
	groups: string;
	headimgurl: string;
	id: number;
	level: string;
	nickname: string;
	phone_number: string;
	recharge_count: number;
	remark: number;
	sex: number;
	index: number;
	days: number;
	name: string;
};

type Idishes = {
	created_at: string;
	dishes: Array<{
		count: number;
		id: string;
		is_must_eat: boolean;
		is_no_discount: boolean;
		main_pic: string;
		name: string;
		pack_num: number;
		price: string;
		price_vip: string;
		standard: string;
	}>;
	employee: [];
	mark: string;
	table_name: string;
	user_type: string;
};

const { Text } = Typography;
const { TextArea } = Input;


const OrderDetailModal = ({ code, send }: IProps) => {
	// 订单数据
	const [orderData, setorderData] = useState<IorderData>({
		actual_fee: "0.00",
		ali_pay: "0.00",
		amount: 0,
		code: "",
		coupons_pay: "0.00",
		created_at: "",
		desk: "",
		discount_desc: "",
		discounts_fee_total: "0.00",
		dishes: [],
		eating_time: "",
		fan: null,
		users: [],
		price: '0.00',
		zhuli_dish: {
			id: 0,
			main_pic: "",
			name: "",
			price: "",
			printer_key: "",
			standard: "",
		},
		user_amount: 0,
		status_display: "",
		present_foods: [],
		redeem_dishes: [],
		third_coupon_list: [],
		platform_coupon_list: [],
		pay_style: '',
		zhuli_status: 0,
	});

	const [remark, setremark] = useState({});
	const [loadingData, setloadingData] = useState(true);
	const [singuser, setSingUser] = useState<any>([]);
	const [swicthUser, setSwicthUser] = useState(0);
	const [swicthList, setSwicthList] = useState(0);
	const [userInfo, setUserInfo] = useState<any>({});
	const [getUserInfo, setGetUserInfo] = useState<any>(1);
	const [curren, setCurren] = useState(1);

	useEffect(() => {
		getOrderDetail(code);
	}, []);

	// 获取订单详情
	const getOrderDetail = async (code: string) => {
		setloadingData(true)
		const data = { code };
		const res: any = await getRequest(apiUrl.getOrderDetail, data);
		if (res && res["code"] === 1) {
			setorderData(res.data);
			const _remark: any = {};
			res.data.users.map((el: any, idx: number) => (_remark[idx] = el.remark));
			setremark(_remark);
			setSingUser([res.data.users[0]])
		}
		setloadingData(false);
	};

	// table
	const columns = [
		{
			title: "商品名称",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "规格",
			dataIndex: "standard",
			key: "standard",
		},
		{
			title: "份数",
			dataIndex: "count",
			key: "count",
		},
		{
			title: "价格(元)",
			dataIndex: "price",
			key: "price",
		},
	];

	// 修改备注
	const changeMark = (e: string, idx: number) => setremark({ ...remark, [idx]: e });

	// 保存备注
	const saveMark = async (item: any, idx: number) => {
		const _remark: any = remark;
		const res: any = await postRequest(apiUrl.addMark, {
			id: item.id,
			remark: _remark[idx],
		});
		if (res && res["code"] === 1) {
			message.success(res.message);
			getOrderDetail(code);
		}
	};

	// 取消暂存备注
	const cancelMark = async (idx: number) => setremark({ ...remark, [idx]: orderData.users[idx].remark });

	//切换用户
	const switchUsers = (index:any) => {
		setSwicthUser(index)
		setSingUser([orderData.users[index]])
		setUserInfo({...orderData.users[index]})
		setCurren(1)
	}

	//消费就餐切换
	const onSwicthList = (index: any) => {
		setSwicthList(index)
		setCurren(1)
	}

	const getChilderMsg = (msg:any) => {
		setUserInfo(msg)
	}

	//子组件切换订单信息
	const swicthSubOrder = (code: any) => {
		getOrderDetail(code)
	}


	// 用户信息
	const UserCard = (item: Iuser, idx: number) => {
		const _remark: any = remark;
		return (
			<Row className="usercard" style={{ marginTop: 20 }} key={item.id}>
				{
					orderData.users.length > 1
					?	<div className="usercard-top">
							<div className="usercard-top-item">
								{
									orderData.users.map((item:any, index:any) => {
										return (
											<div key={index} className={swicthUser === index ? 'usercard-top-item-active' : ''} onClick={() => { switchUsers(index) }}>
												{
													item.nickname
												}
											</div>
										)
									})
								}
							</div>
						</div>
					: ''
				}
				<div className="usercard-info">
					<p>用户ID：{item.id}</p>
					<p>用户名：{item.nickname}</p>
					<p>手机号：{item.phone_number}</p>
					<p>性别：{item.sex == 1 ? '男' : '女'}</p>
					<p>用户分组：{item.groups}</p>
					<p>下单次数：{item.count}</p>
					<p>距离上次：{item.days}</p>
					<Row type="flex">
						<p>备注：</p>
						<TextArea
							autoSize
							style={{ minHeight: 50 }}
							value={item.remark}
							onChange={(e) => changeMark(e.target.value, idx)}
						/>
						<Row
							type="flex"
							justify="end"
							style={{
								border: `1px solid #e8e8e8`,
								padding: 10,
								width: "100%",
							}}
						>
							<Button size="small" type="link" onClick={() => cancelMark(idx)}>取消</Button>
							<Button
								size="small"
								type="primary"
								style={{ marginLeft: 10 }}
								onClick={() => saveMark(item, idx)}
							>
								保存
							</Button>
						</Row>
					</Row>
					<div className="invitation">
						邀请好友：累计邀请<span className="special">{userInfo.invitor_num}人</span>，共获得奖励<span className="special">{userInfo.count_reward}元</span>
					</div>
					<div className="balance">
						账户余额：储值余额<span className="special">{userInfo.amount}元</span>
					</div>
				</div>
				<div>
					<div className="usercard-record">
						<div className={swicthList == 0 ? 'usercard-record-consumption curren' : 'usercard-record-consumption'} onClick={() => { onSwicthList(0) }}>消费记录</div>
						<div className={swicthList == 1 ? 'usercard-record-consumption curren' : 'usercard-record-consumption'} onClick={() => { onSwicthList(1) }}>就餐记录</div>
					</div>
					<div>
						{
							swicthList === 0
							? <Consumption send = {getUserInfo} onRef={onRef} onMsg = { getChilderMsg } swicthSubOrder = { swicthSubOrder } />
							: <DiningRecord send = {singuser} />
						}
					</div>			
				</div>
			</Row>
		);
	};

	// 订单基础信息
	const OrderInfo = (orderData: IorderData) => {
		return (
			<Card style={{ marginTop: 20 }}>
				<div className="orderinfo">
					<div className="orderinfo-left">
						<div className="orderinfo-left-item">
							<div className="orderinfo-left-item-txt">桌台号：</div>
							<div className="orderinfo-left-item-content">{orderData.desk}</div>
						</div>
						<div className="orderinfo-left-item">
							<div className="orderinfo-left-item-txt">用餐人数：</div>
							<div className="orderinfo-left-item-content">{orderData.user_amount}</div>
						</div>
						<div className="orderinfo-left-item">
							<div className="orderinfo-left-item-txt">订单号：</div>
							<div>{orderData.code}</div>
						</div>
						<div className="orderinfo-left-item">
							<div className="orderinfo-left-item-txt">用餐状态：</div>
							<div>{orderData.status_display}</div>
						</div>
						<div className="orderinfo-left-item">
							<div className="orderinfo-left-item-txt">支付方式：</div>
							<div>{orderData.pay_style}</div>
						</div>
					</div>
					<div className="orderinfo-right">
						<div className="orderinfo-right-item">
							<div className="orderinfo-right-item-txt">用餐时长：</div>
							<div className="orderinfo-right-item-content">{orderData.eating_time}</div>
						</div>
						<div className="orderinfo-right-item">
							<div className="orderinfo-right-item-txt">下单时间：</div>
							<div className="orderinfo-right-item-content">{orderData.created_at}</div>
						</div>
						<div className="orderinfo-right-item">
							<div className="orderinfo-right-item-txt">实付：</div>
							<div className="orderinfo-right-item-content">{orderData.actual_fee}</div>
						</div>
						<div className="orderinfo-right-item">
							<div className="orderinfo-right-item-txt">订单金额：</div>
							<div className="orderinfo-right-item-content">{orderData.price}</div>
						</div>
						<div className="orderinfo-right-item">
							<div className="orderinfo-right-item-txt">优惠：</div>
							<div className="orderinfo-right-item-content">
								{
									//JSON.stringify(orderData.third_coupon_list[0])
									orderData.third_coupon_list.map((item:any, index:any) => {
										return (
											<div key={index}>
												{item.name}： - ¥{item.price}
											</div>
										)
									})
								}
								{
									//JSON.stringify(orderData.third_coupon_list[0])
									orderData.platform_coupon_list.map((item:any, index:any) => {
										return (
											<div key={index}>
												{item.name}： - ¥{item.price}
											</div>
										)
									})
								}
							</div>
						</div>
					</div>
				</div>
				{/* <Descriptions column={2}>
          <Descriptions.Item label="桌台号">{orderData.desk}</Descriptions.Item>
          <Descriptions.Item label="用餐时间">
            <div>{orderData.eating_time}</div>
          </Descriptions.Item>
          <Descriptions.Item label="已下单">
            ￥{orderData.price}
          </Descriptions.Item>
          <Descriptions.Item label="用餐人数">
            {orderData.user_amount}
          </Descriptions.Item>
          <Descriptions.Item label="已用餐">
            {orderData.eating_time}
          </Descriptions.Item>
          <Descriptions.Item label="订单号">{orderData.code}</Descriptions.Item>
          <Descriptions.Item label="用餐状态">
            {orderData.status_display}
          </Descriptions.Item>
          <Descriptions.Item label="下单时间">
            {orderData.created_at}
          </Descriptions.Item>
        </Descriptions> */}
			</Card>
		);
	};

	//滚动加载更多
	const loadMore = (e:any) => {
		// console.log(e.target.scrollHeight)
		// console.log(e.target.scrollTop)
		// console.log(e.target.clientHeight)
		if(e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 1) { //滚动到底部
			if(e.target.clientHeight > 100 && swicthList == 0) {
				let num = curren;
				++num;
				setCurren(num)
				onRef()
				setGetUserInfo(num)
			}
		}
	}

	const onRef = async () => {
		setloadingData(true)
		const id = singuser[0]['id'];
		let param = {
			page: curren,
			page_size: 5,
		}
        const res:any = await getRequest(apiUrl.messageUserInfo+`${id}`,param);
		setGetUserInfo(1)
		setloadingData(false)
		return res
	}

	return (
		<Spin spinning={loadingData}>
			<Row type="flex" style={{ width: "100%", height: "100%" }}>
				<Row className='userLeft' onScroll={(event) => {loadMore(event)}}>
					<Text strong>用户信息</Text>
					{singuser.map((el: any, idx: any) => UserCard(el, idx))}
				</Row>
				<Row className='userRight'>
					<Text strong>订单信息</Text>
					{OrderInfo(orderData)}

					{orderData && orderData.dishes.map((el: any, idx: number) => {
						return (
							<Row key={el.created_at} style={{ display: el.dishes.length > 0 ? "block" : "none" }}>
								<Row style={{ marginTop: 20, fontWeight: "bold" }}>{idx === 0 ? "" : `追单(${el.created_at})`}</Row>
								<Table
									pagination={false}
									rowKey={() => el.created_at + Math.random() * 100}
									style={{ marginTop: 20 }}
									size="small"
									columns={columns}
									dataSource={el.dishes}
								/>
							</Row>
						);
					})}

					{orderData && orderData.present_foods && orderData.present_foods.map((el: any, idx: number) => {
						return <Row key={el.created_at} style={{ display: el.foods.length > 0 ? "block" : "none" }}>
							<Row style={{ marginTop: 20, fontWeight: "bold" }}>赠菜</Row>
							<Table
								pagination={false}
								rowKey={() => el.created_at + Math.random() * 10}
								style={{ marginTop: 20 }}
								size="small"
								columns={columns}
								dataSource={el.foods}
							/>
						</Row>
					})
					}

					{
						orderData && orderData.redeem_dishes && orderData.redeem_dishes.map((el: any, idx: number) => {
							return <Row key={el.created_at}>
								<Row style={{ marginTop: 20, fontWeight: "bold" }} >兑菜</Row>
								<Table pagination={false} rowKey={el.created_at} size="small" columns={columns} dataSource={[el]} />
							</Row>
						})
					}

					{
						(orderData.zhuli_status == 5 || orderData.zhuli_status == 10) && <Row>
							<Row style={{ marginTop: 20, fontWeight: "bold" }} >助力</Row>
							<Table pagination={false} rowKey={'id'} size="small" columns={columns} dataSource={[orderData.zhuli_dish]} />
						</Row>
					}
				</Row>
			</Row>
		</Spin>
	);
};

export default OrderDetailModal;
