import React, { useEffect, useState } from 'react'
import { Row, Button, Table, Col, message } from 'antd'
import { useHistory, useLocation } from 'react-router-dom';
import { getRequest } from '../../api/http'
import { apiUrl } from '../../api/api_url'
import './detail.scss'
import moment from 'moment';
import { floor } from 'lodash';

const MarketingDetail = ()=>{

    const history = useHistory()
    const location:any = useLocation()
    const weekList = ['周一','周二','周三','周四','周五','周六','周日']
    const dayList = ()=>{
        let data = []
        for(var i=1; i<=31; i++){
            data.push(i)
        }
        return data
    }
    const [data, setData] = useState([]);
    const [pushTime, setPushTime] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [total, setTotal] = useState(0);
    const [stat, setStat] = useState<any>({});
    const [paramsObj, setParamsObj] = useState<any>({
        page: 1,
        pageSize: 10,
    })

    useEffect(()=>{
        console.log(location)
        init()
    },[])

    const init = async() => {
        const operation_id = location.state && location.state.id ? location.state.id : 0
        if(!operation_id){
            message.error('没有分组运营编号')
            history.goBack()
            return false
        }
        paramsObj.operation_id = operation_id
        getOperationStat({...paramsObj})
    }

    const getOperationStat = async (data:any = {}) => {
        let param = {
            ...data
        }
        if(param.sortType)param.sortType=param.sortType=='ascend'?'asc':'desc'
        Object.keys(param).map(el => {
            if(!param[el]) delete param[el]
        })
        setParamsObj({...param})
        const res:any = await getRequest(apiUrl.getOperationDetails, param)
        if(res && res['code'] === 1) {
            setData(res.data.items.data)
            setStat(res.data.stat)
            setTotal(res.data.items.total)
            setStartTime(res.data.activity_start_time)
            setEndTime(res.data.activity_end_time)
            //获取推送时间
            let str = ''
            if(res.data.notice_type==1){
                //持续推送
                if(res.data.cycle_type==1){
                    str = '推送时间:持续推送,每'+weekList[res.data.cycle_day]
                }else{
                    str = '推送时间:持续推送,每月'
                    res.data.cycle_day.split(',').map((item:any,index:number)=>{
                        str += dayList()[item-1]+'日,'
                    })
                    str = str.substr(0,str.length-1)
                }
            }else{
                //指定时间
                str = '指定时间:'+res.data.activity_start_time
            }
            setPushTime(str)
        }
    }

    const handleTableChange = (pagination:any, filters:any, sorter:any) => {
        console.log(pagination)
        getOperationStat({
            ...paramsObj,
            page:pagination.current,
            pageSize:pagination.pageSize,
            sortField:sorter.field,
            sortType:sorter.order
        })
    }

    const percentage = (molecule:any,denominator:any)=>{
        let percentage:any
        if(molecule == 0){
            percentage = 0
        }else if(denominator == 0){
            percentage = 100
        }else{
            percentage = (molecule/denominator*100).toFixed(2)
        } 
        return percentage
    }

    const creatTable = ()=>{

        const columns:any = [
            {
                title: '优惠券名称',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '券有效期',
                dataIndex: 'expire_info',
                key: 'expire_info',
                render:( text:any, record:any, index:number )=> <>{text.type == 'day' ? '自领取日'+ text.day + '天内' : text.start_time + '-' + text.end_time}</>
            },
            {
                title: '发送时间',
                dataIndex: 'created_at',
                key: 'created_at',
            },
            {
                title: '发送人数',
                dataIndex: 'push_fan_num',
                key: 'push_fan_num',
            },
            {
                title: '通知送达人数',
                dataIndex: 'successd_fan_num',
                key: 'successd_fan_num',
            },
            {
                title: '查看人数',
                dataIndex: 'look_fan_num',
                key: 'look_fan_num',
            },
            {
                title: '使用人数',
                dataIndex: 'use_coupon_fan_num',
                key: 'use_coupon_fan_num',
            },
            {
                title: '消费人数',
                dataIndex: 'coupon_consume_num',
                key: 'coupon_consume_num',
            },
            {
                title: '过期数量',
                dataIndex: 'expire_coupon_num',
                key: 'expire_coupon_num',
                sorter:true,
            },
            {
                title: '消费转化率',
                dataIndex: 'radio',
                key: 'radio',
                sorter:true,
                render: (text:any,record:any,index:number)=>percentage(record.coupon_consume_num,record.successd_fan_num)+'%'
            },
            {
                title: '使用转化率',
                dataIndex: 'use_coupon_fan_num',
                key: 'use_coupon_fan_num',
                sorter:true,
                render: (text:any,record:any,index:number)=>percentage(record.use_coupon_fan_num,record.successd_fan_num)+'%'
            },
            {
                title: '操作',
                dataIndex: 'options',
                key: 'options',
                render: ( text:string, record:any, index:number )=>
                    <Button 
                        type="link" 
                        onClick={()=>{
                            history.push('/sidebar/marketingSubDetail',{id:record.id,coupon_name:record.name,pushTime:pushTime,name:location.state.name,start_time:startTime,end_time:endTime,expire_info:record.expire_info})
                        }}>详情</Button>
            },
        ];

        return (
            <Table
                dataSource={data}
                columns={columns}
                onChange={handleTableChange}
                rowKey={'id'}
                pagination={{
                    defaultCurrent: 1,
                    current:paramsObj.page,
                    pageSize: paramsObj.pageSize,
                    total: total,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    showTotal: (total: number) => `共${total}条`,
                }}
            />
        )
    }

    return(
        <div className="marketing_detail l_content">
            <div className="title_text">运营数据</div>
            <Row className="action_info">
                <Col span={4} className="fz16">{location.state?location.state.name:''}</Col>
                <Col span={12} className="fz16">生效时间：{startTime?(startTime+'至'+endTime):''}</Col>
                <Col span={8} className="fz16">{pushTime}</Col>
            </Row>
            <div className="title_text sub_title">数据汇总</div>
            <Row className="statistics">
                <Row className="mb30">
                    <Col span={4}><div>分组人数</div><div className="statistics_num">{stat.group_member_num}</div></Col>
                    <Col span={4}><div>已发送人数</div><div className="statistics_num">{stat.push_member_num}</div></Col>
                    <Col span={4}><div>通知送达人数</div><div className="statistics_num">{stat.push_successd_num}</div></Col>
                    <Col span={4}><div>查看人数</div><div className="statistics_num">{stat.look_fan_num}</div></Col>
                    <Col span={4}><div>使用人数</div><div className="statistics_num">{stat.use_coupon_fan_num}</div></Col>
                    <Col span={4}><div>消费人数</div><div className="statistics_num">{stat.consumer_member_num}</div></Col>
                </Row>
                <Row className="mb30">
                    <Col span={4}><div>消费订单数</div><div className="statistics_num">{stat.consumer_order_num}</div></Col>
                    <Col span={4}><div>消费金额</div><div className="statistics_num">{stat.consumer_money}</div></Col>
                    <Col span={4}><div>通知-触达转化率</div><div className="statistics_num">{stat.push_member_num&&percentage(stat.push_successd_num,stat.push_member_num)}%</div></Col>
                    <Col span={4}><div>通知-使用转化率</div><div className="statistics_num">{stat.push_successd_num&&percentage(stat.use_coupon_fan_num,stat.push_successd_num)}%</div></Col>
                    <Col span={4}><div>通知-消费转化率</div><div className="statistics_num">{stat.push_successd_num&&percentage(stat.consumer_member_num,stat.push_successd_num)}%</div></Col>
                </Row>
            </Row>
            {
                creatTable()
            }
        </div>
    )
}

export default MarketingDetail