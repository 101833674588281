import React from "react";
import {Button, Row, Col, Input, Table, message, } from "antd";
import { IUserGroupStore } from '../../stores/userGroup.store';
import { inject, observer } from "mobx-react"
import { apiUrl } from "../../api/api_url";
import { postRequest } from "../../api/http";

@inject('userGroupStore')
@observer

class CouponGroupAdd extends React.Component<any> {
    state = {
        currentPage: 1,
        pageSize: 10,

        ids: [],
        selectRows: [],
        list: [],
        total: 0,
        showList: [],
        name: ''
    }

    columns = [
        {
            title: '序号',
            dataIndex: 'index',
            key: 'index',
            className: 'list_center',
            render: ( text: string, record: any, index: number) => {
                return <div>{index+1}</div>
            }
        },
        {
            title: '用户ID',
            dataIndex: 'fan_id',
            key: 'fan_id',
            className: 'list_center',
        },
        {
            title: '用户昵称',
            dataIndex: 'nickname',
            key: 'nickname',
            className: 'list_center',
        },
        {
            title: '用户等级',
            dataIndex: 'level',
            key: 'level',
            className: 'list_center',
        },
        {
            title: '手机号',
            dataIndex: 'phone_number',
            key: 'phone_number',
            className: 'list_center',
        },
        {
            title: '性别',
            dataIndex: 'sex',
            key: 'sex',
            className: 'list_center',
        },
        {
            title: '用户分组',
            dataIndex: 'groups',
            key: 'groups',
            className: 'list_center',
            render: (rowData: any, record: any) => {
                return <div>
                    {rowData.map((item:any) => {
                        return <div>{item}</div>
                    })}
                </div>
            }
        },
        {
            title: '消费次数',
            dataIndex: 'receive_amount',
            key: 'receive_amount',
            className: 'list_center',
        },
        {
            title: '累计消费',
            dataIndex: 'stock',
            key: 'stock',
            className: 'list_center',
        },
        {
            title: '券有效天数',
            dataIndex: 'used_count',
            key: 'used_count',
            className: 'list_center',
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            className: 'list_center',
            render: (text: string, record: any, index: number) => {
                return <div className='btn_green' onClick={() => this.removeList(record.id)}>移除</div>
            }
        },
    ];

    componentDidMount(){
        this.onInitList();
    }

    onInitList = () => {
        let list = this.props.userGroupStore.list;
        this.setState({
            list: list,
            total: list.length,
            showList: list.slice(0, 2)
        })
    }

    pageChange = async (page:number) => {
        let { list } = this.state;
        this.setState({
            currentPage: page,
            showList: list.slice((page-1)*10, 10*page)
        })
    }

    removeList = (id: number) => {
        let {list} = this.state;
        list.map((item:any, index: number) => {
            if(item.id == id){
                list.splice(index,1)
            }
        })

        this.setState({
            list,
            showList: list.slice(0, 10)
        })
    }

    removeMore = () => {
        let { ids } = this.state;
        let {list} = this.state;
        list.map((item:any, index: number) => {
            ids.map((id: any) => {
                if(item.id == id){
                    list.splice(index,1)
                }
            })
        })

        this.setState({
            list,
            showList: list.slice(0, 10)
        })
    }

    nameChange = (e: any) => {
        this.setState({
            name: e.target.value
        })
    }

    saveGroup = async () => {
        let { list, name } = this.state;
        if(!name){
            message.warning('请输入分组名称')
            return;
        }

        let params:any = {}
        let fans:any = [];
        params.name = name;
        list.map((item: any) => {
            if(fans.indexOf(item.id) <= -1){
                fans.push(item.id)
            }
        })
        params.fan_ids = fans;

        let res:any = await postRequest(apiUrl.addCouponGroup, params)
        if(res.code == 1){
            message.success('添加成功');
            this.props.history.goBack();
        }
    }


    render(){
        const { showList, total } = this.state;
        let rowSelection:any = {
            selectedRowKeys: this.state.ids,
            onChange: (selectedRowKeys:any, selectedRows: any) => {
                this.setState({
                    ids: selectedRowKeys,
                });
            },
            type: 'checkbox',
        }

        return <div className='l_content'>

            <Row type="flex" align="middle" justify="start" className="mt20 mb40" >
                <Col className="mr30">
                    <span className='star'></span>
                    <span>分组名称：</span>
                    <Input placeholder="请输入分组名称" onChange={(e) => this.nameChange(e)} style={{ width: 234 }}/>
                </Col>
            </Row>

            <div>
                <Table dataSource={showList} rowSelection={rowSelection} columns={this.columns} rowKey={"id"} locale={{emptyText: '暂无数据'}} pagination={{
                    showQuickJumper: true,
                    total: total,
                    pageSize: this.state.pageSize,
                    onChange: this.pageChange,
                    showSizeChanger: true,
                    showTotal: (total: number) => `共${total}条`,
                    onShowSizeChange: (current:number, size: number) => {this.setState({pageSize: size}, () => {this.onInitList();})}
                }}/>
            </div>

            <Row>
                <Col span={24}>
                    <Button type="primary" disabled={this.state.ids.length <= 0} onClick={this.removeMore}>
                        移除
                    </Button>
                </Col>
            </Row>

            <Row type='flex' align="middle" justify='center' className='mt30'>
                <Col span={6}>
                    <Button size="large" onClick={() => { this.props.history.goBack();}}>
                        取消
                    </Button>
                </Col>
                <Col span={6}>
                    <Button type="primary" size="large" onClick={this.saveGroup}>
                        保存
                    </Button>
                </Col>
            </Row>
        </div>;
    }
}
export default CouponGroupAdd