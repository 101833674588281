import React from "react";
import wode from '../../../assret/images/wode_demo.png'
import shopDecorationStore from '../../../stores/shopDecoration.store'
import { inject, observer } from "mobx-react"
import { getIsPermisson, messageCustom } from '../../../utils/permisstionUtil';

import styles from './wode.module.scss'

type IProps = {
    history?: any
}
type IState = {
    edit: boolean
}

@observer
class ShopDecoration extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props)
        this.state = {
            edit: false,
        }
    }

    mouseEnter() {
        this.setState({
            edit: true
        })
    }

    mouseLeave() {
        this.setState({
            edit: false
        })
    }

    //编辑
    onEdit() {
        if(!getIsPermisson('2_7_5_3')){
            messageCustom();
            return;
        }
        this.props.history.push({pathname: "/sidebar/decorationMain"})
    }

    //预览
    onPreview() {
        if(!getIsPermisson('2_7_5_2')){
            messageCustom();
            return;
        }
        shopDecorationStore.updatePreview(1)
        this.props.history.push({pathname: "/sidebar/decorationMain"})
    }
    
    render() {

        const { edit } = this.state;

        return (
            <div className={styles.wode}>
                <div className={styles.wode_top}  onMouseEnter={this.mouseEnter.bind(this)}>
                    <img src={wode} className={styles.wode_img} />
                </div>
                {
                    edit && <div className={styles.wode_tip} onMouseLeave={this.mouseLeave.bind(this)}>
                                <div className={styles.wode_tip_edit} onClick={this.onEdit.bind(this)}>编辑</div>
                                <div className={styles.wode_tip_show} onClick={() => {this.onPreview()}}>预览</div>
                            </div>
                }
            </div>
        )
    }
}

export default ShopDecoration