import React, { useEffect, useState } from 'react'
import { Table, Tooltip } from 'antd'
import { getRequest } from '../../../api/http'
import { apiUrl } from '../../../api/api_url'
import './indexTable.scss'

type Iprops = {
    id:any
}

const SiyuIndexTable = (props:Iprops)=>{

    const { id } = props

    const [ params, setParams ] = useState<any>({
        id:props.id,
        current: 1,
        pageSize: 4,
    }) //门店列表参数

    const [ loading, setLoading] = useState(false)
    const [ total, setTotal] = useState(0)
    const [ dataSource, setDataSource] = useState<any>([])

    useEffect(()=>{
        getList(params)
    },[id])

	//获取列表
    const getList = async(data = {}) => {
        let params:any = {
            ...data
        }
        Object.keys(params).map(el => {
                if(!params[el]) delete params[el]
        })
        if(params.sortType)params.order_by=params.sortType==='ascend'?'asc':'desc'
        if(params.sortField)params.order_field = params.sortField;

        setParams({...params})
        setLoading(true)
        const res: any = await getRequest(apiUrl.usergroupDetail, params)
        setLoading(false)
        if(res.code === 1){
            setDataSource(res.data.member_list);
            setTotal(res.data.member_count)
        }
    }    

    const columns = [
        {
            title: '用户昵称',
            dataIndex: 'nickname',
            render: (text:any)=> <Tooltip title={text} className="table_text">{text}</Tooltip>
        },
        {
            title: '消费次数',
            dataIndex: 'consumer_num',
            sorter: true,
        },
        {
            title: '累计消费(元)',
            dataIndex: 'consumer_money',
            sorter: true,
        },
        {
            title: '充值(次)',
            dataIndex: 'recharge_num',
            sorter: true,
        },
        {
            title: '累计充值(元)',
            dataIndex: 'recharge_money',
            sorter: true,
        },
        {
            title: '成功邀请(人)',
            dataIndex: 'invite_num',
            sorter: true,
        },
        {
            title: '距离上次消费(天)',
            dataIndex: 'last_comsumer_day',
        },
    ]

    const handleTableChange = (pagination:any, filters:any, sorter:any) => {
        getList({
            ...params,
            current:pagination.current,
            pageSize:pagination.pageSize,
            sortField:sorter.field,
            sortType:sorter.order
        })
    }
    
    return (
        <div className="siyu_index_table">
          <Table 
              dataSource={dataSource} 
              columns={columns} 
              onChange={handleTableChange}   
              rowKey={'id'}  
              loading={loading}
              pagination={{
                  defaultCurrent: 1,
                  current:params.current,
                  pageSize: params.pageSize,
                  total: total,
                  showQuickJumper: true,
                  showSizeChanger: true,
                  showTotal: (total) => `共${total}条`,
              }}   
          />            
        </div>
    )
}

export default SiyuIndexTable

