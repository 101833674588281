import React, { useEffect, useState } from 'react'
import { Row, Table, Tooltip, Pagination } from 'antd'
import { useHistory } from 'react-router-dom'
import { getRequest } from '../../../api/http'
import { apiUrl } from '../../../api/api_url'
import { getIsPermisson, messageCustom } from '../../../utils/permisstionUtil'
import DatePicker from '../datePicker/index'
import new_detail from '../../../assret/images/new_detail.png'
import OrderPadding from '../../order_manager/order_list/components/order_padding_detail';


import './index.scss'
interface IcurrentDrawerData {
    desk: string,
    amount: number,
    price: string,
    price_vip: string,
    code: string,
    status_display: string,
    dishes: Array<{
        mark: string,
        dishes: Array<any>,
        created_at: string
    }>
}

const FinanceOtherSetMeal = (props:any)=>{

    const { currentTab } = props

    const formatData = (num:any) => {
        return num > 9 ? num : `0${num}`
    }

    //获取日期开始日
    const getMonthStart = (year:number,month:number) => {
        // 获取当前日期
        return year + '-' + month + '-01'
    }

    //获取日期结束日
    const getMonthEnd = (year:number,month:number) => {
        // 获取当前日期
        return year + '-' + month + '-' + new Date(year,formatData(month),0).getDate()
    }

    const history = useHistory()
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [paramsObj, setParamsObj] = useState<any>({
        page: 1,
        page_size: 10,
        start_time:getMonthStart(new Date().getFullYear(),formatData(new Date().getMonth()+1)),
        end_time:getMonthEnd(new Date().getFullYear(),formatData(new Date().getMonth()+1)),
        channel:'',
    });
    const [ rowSpans, setRowSpans ] = useState<any>([])
    const [ finishOrderNum, setFinishOrderNum ] = useState(0)
    const [ mealsActualFee, setMealsActualFee ] = useState(0)
    // const [ downloadLoading, setDownloadLoading] = useState(false)
    const [currentDrawerData, setcurrentDrawerData] = useState<IcurrentDrawerData>({
        desk: '',
        dishes: [],
        amount: 0,
        price: '',
        price_vip: '',
        code: '',
        status_display: '',
    });
    const [showPaddingOrderDrawer, setshowPaddingOrderDrawer] = useState(false);

    useEffect(()=>{
        getList({...paramsObj})
    },[ currentTab ])

    const getList = async(data:any = {}) => {
        let param = {
            ...data
        }
        if(param.sortType)param.sortType=param.sortType=='ascend'?'asc':'desc'
        setParamsObj({...param})
        if(!param.channel){
            delete param.channel
        }
        const res:any = await getRequest(apiUrl.mealOrdersList, {...param});
        if(res && res['code'] === 1) {
            setTotal(res.data.items.total)
            setData(res.data.items.data)
            setMealsActualFee(res.data.meals_actual_fee)
            setFinishOrderNum(res.data.finish_order_num)
            setData(dataInit(res.data.items.data))
            console.log('res.data.items.data',dataInit(res.data.items.data))
        }
    }

    //数据源格式化成多条数据
    const dataInit = (data:any)=>{
        let dataArr:any = []
        let i = 0
        data.map((item:any,index:number)=>{
            if(item.meals&&item.meals.length>1){
                item.meals.map((itemM:any,indexG:number)=>{
                    dataArr[i] = itemM
                    dataArr[i].paid_at = item.paid_at
                    dataArr[i].code = item.code
                    dataArr[i].user_amount = item.user_amount
                    // dataArr[i].channel_name = item.channel_name
                    dataArr[i].label_id = item.code+'-'+itemM.id+'-'+indexG//唯一id
                    i++
                })
            }else{
                dataArr[i] = item.meals[0]
                dataArr[i].paid_at = item.paid_at
                dataArr[i].code = item.code
                dataArr[i].user_amount = item.user_amount
                // dataArr[i].channel_name = item.channel_name
                dataArr[i].label_id = item.code+'-'+item.meals[0].id+'-'+index
                i++
            }
        })
        setRowSpans(getRowSpans(dataArr, 'code'))
        return dataArr
    }
    
    //获取合并行
    const getRowSpans = (arr:any, key:string) => {
        let sameValueLength = 0;
        const rowSpans = [];
        for(let i = arr.length - 1; i >= 0; i--){
            if(i === 0) {
                rowSpans[i] = sameValueLength + 1;
                continue;
            }
            if(arr[i][key] === arr[i-1][key]) {
                rowSpans[i] = 0;
                sameValueLength++;
            } else {
                rowSpans[i] = sameValueLength + 1;
                sameValueLength = 0;
            }
        }
        return rowSpans;
    };

    //表格值初始化
    const renderContent = ( value:any, _:any, index:number)=>{
        const obj:any = {
            children: value,
            props: {},
        };
        obj.props.rowSpan = rowSpans[index];
        return obj;
    }

    const renderOption = ( value:any, record:any, index:number)=>{
        const obj:any = {
            children:<Tooltip placement="bottomRight" title={'详情'}>
                <img src={new_detail} className='table_icon' onClick={() => showOrderDetail(record)}></img>
            </Tooltip>,
            props: {},
        };
        obj.props.rowSpan = rowSpans[index];
        return obj;
    }

    // 显示订单详情
    const showOrderDetail = async (rowdata: any) => {
        if(!getIsPermisson('1_3_7_1')){
            messageCustom();
            return;
        }
        setcurrentDrawerData(rowdata);
        setshowPaddingOrderDrawer(true);
    }


    const creatTable = ()=>{
        const columns:any = [
            {
                title: '订单号',
                dataIndex: 'code',
                render: renderContent     
            },
            {
                title: '就餐人数',
                dataIndex: 'user_amount',
                render: renderContent     
            },
            {
                title: '套餐名称',
                dataIndex: 'name',
            },
            {
                title: '套餐来源',
                dataIndex: 'channel_name',
            },
            {
                title: '套餐价格',
                dataIndex: 'price',
            },
            {
                title: '已核销',
                dataIndex: 'count',
            },
            {
                title: '套餐实收',
                dataIndex: 'price',
                render: (text:any,record:any) => text*record.count
            },
            {
                title: '核销时间',
                dataIndex: 'paid_at',
                render: renderContent  
            },
            {
                title: '操作',
                dataIndex: 'option',
                render: renderOption  
                // render: ( text:string, record:any)=><>
                //     <Tooltip placement="bottomRight" title={'详情'}>
                //         <img src={new_detail} className='table_icon' onClick={() => showOrderDetail(record)}></img>
                //     </Tooltip>
                // </>
            },
        ];


        return (
            <Table
                dataSource={data}
                columns={columns}
                onChange={handleTableChange}
                rowKey={'label_id'}
                pagination={false}
                // pagination={{
                //     defaultCurrent: 1,
                //     current:paramsObj.page,
                //     pageSize: paramsObj.pageSize,
                //     total: total,
                //     showQuickJumper: true,
                //     showSizeChanger: false,
                //     showTotal: (total: number) => `共${total}条`,
                // }}
            />
        );
    }

    const handleTableChange = (pagination:any, filters:any, sorter:any) => {
        getList({
            ...paramsObj,
            sortField:sorter.field,
            sortType:sorter.order
        })
    }

    // 导出
    const exportTable = async () => {
        if(!getIsPermisson('1_3_7_2')){
            messageCustom();
            return;
        }
        const token = localStorage.getItem("token");
        const shop_id = localStorage.getItem("shop_id");
        console.log(`${process.env.REACT_APP_BASEURL}/v2/export/mealOrders?token=${token}&start_time=${paramsObj.start_time}&end_time=${paramsObj.end_time}${paramsObj.channel&&'&channel='+paramsObj.channel}&shop_id=${shop_id}`)
        window.open(`${process.env.REACT_APP_BASEURL}/v2/export/mealOrders?token=${token}&start_time=${paramsObj.start_time}&end_time=${paramsObj.end_time}${paramsObj.channel&&'&channel='+paramsObj.channel}&shop_id=${shop_id}`);
    };



    return (
        <div className="finance_other_setMeal">
            <DatePicker callBack = {(data:any)=>{getList({...paramsObj,...data})}} exportTable={()=>{exportTable()}}/>
            <Row type="flex" align="middle" className="card">
                <Row type="flex" align="middle" justify="space-between" className="card_item">
                    <div>已核销</div>
                    <div>{finishOrderNum}</div>
                </Row>
                <Row type="flex" align="middle" justify="space-between" className="card_item">
                    <div>套餐实收</div>
                    <div>{mealsActualFee}</div>
                </Row>
            </Row>
            {
                creatTable()
            }
            {
                total>0&&<Row type="flex" align="middle" justify="end" className="page_wrap">
                    <Pagination showQuickJumper current={paramsObj.page} total={total} onChange={(pageNumber)=>{getList({...paramsObj,page:pageNumber})}} showTotal={(total: number) => `共${total}条`}/>
                </Row>
            }
            {/* 订单详情 */}
            <OrderPadding
                showPaddingOrder={showPaddingOrderDrawer}
                changeVisitity={setshowPaddingOrderDrawer}
                code={currentDrawerData.code ? currentDrawerData.code : ''} 
            />
        </div>
    )
}

export default FinanceOtherSetMeal
