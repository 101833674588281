import React from "react";
import {Button, Row, Col, Input, Radio, Table, Modal, Select, message, Icon, Popconfirm} from "antd";
import { postRequest, getRequest } from '../../api/http';
import { apiUrl } from '../../api/api_url';
const { Option } = Select;

const key = 'updatable';
export default class GoodsAdd extends React.Component<any> {

    state = {
        serial_number: '',
        is_must_eat: 1,
        type: false,
        is_no_discount: false,
    }

    componentDidMount() {
        this.onEntrance()
    }

    //判断入口is_must_eat
    onEntrance = () => {
        let { location } = this.props;
        if(location.state != undefined) {
            this.setState(() => {
                return {
                    is_must_eat: 2
                }
            })
        }
    }

    // 分类名称
    serialChange = (e:any) => {
        this.setState({
            serial_number: e.target.value
        })
    }

    // 是否开台必点
    musteatChange = (e:any) => {
        this.setState({
            is_must_eat: e
        })
    }

    // 是否可打折
    discountChange = (e:any) => {
        this.setState({
            is_no_discount: e
        })
    }

    handleSubmit = async (e:any, typeAdd: any) => {
        message.loading({ content: '发布中', key });
        e.preventDefault();

        let goodInfo:any = {};

        const { is_must_eat, serial_number, type, is_no_discount } = this.state;

        if(serial_number === undefined || serial_number === ''){
            message.warning({ content: '请输入分类名称!', key, duration: 2 });
            return;
        }

        // if(is_no_discount) {
        //     goodInfo.show_type = 'vertical';

        // } else {
        //     goodInfo.show_type = 'horizontal';
        // }

        //goodInfo.type = is_must_eat
        goodInfo.order_by = 0;
        goodInfo.name = serial_number;

        const res:any = await postRequest(apiUrl.goodSortAdd,goodInfo);
        if(res['code'] === 1){
            message.success({ content: '发布成功!', key, duration: 2 });
            if(typeAdd == 'add') {
                window.history.go(-1)
            } else {
                this.setState({
                    serial_number: ''
                })
            }
        }else{
            message.error({ content: '发布失败!', key, duration: 2 });
        }

    };

    // 返回上一页
    goBack = () => {
        window.history.go(-1)
    }

    render() {

        const { serial_number, is_must_eat, is_no_discount } = this.state;

        return (
            <div className='l_content'>
                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">分类名称</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <Input placeholder="请输入分类名称" value={serial_number} onChange={(e) => this.serialChange(e)} type='text'/>
                    </Col>
                </Row>
                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">分类类型</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <Radio.Group
                                value={is_must_eat}
                                onChange={(e) => this.musteatChange(e.target.value)}
                            >
                                <Radio value={1}>菜品分类</Radio>
                                <Radio value={2}>套餐分类</Radio>
                            </Radio.Group>
                    </Col>
                </Row>
                {/* <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">展示样式</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <Radio.Group
                                value={is_no_discount}
                                onChange={(e) => this.discountChange(e.target.value)}
                            >
                                <Radio value={false}>横图类型</Radio>
                                <Radio value={true}>竖图类型</Radio>
                            </Radio.Group>
                    </Col>
                </Row> */}

                <Row>
                    <Col span={8}>
                        <div className="bottom_wrap">
                            <Button type="primary" onClick={(e) => {this.handleSubmit(e,'add')}}>
                                保存
                            </Button>
                            <Button type="primary" onClick={(e) => {this.handleSubmit(e,'continue')}}>
                                保存继续添加
                            </Button>
                            <Button type="primary" ghost onClick={this.goBack}>
                                取消
                            </Button>
                        </div>
                    </Col>
                </Row>

            </div>
        )
    }
}