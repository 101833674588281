import React, { useState, useEffect } from "react";
import { Layout, Row, Icon, Avatar, Dropdown, Button, Menu, Col, Spin } from "antd";
import { useHistory } from "react-router-dom";


import './header_bar.scss'

import clsoe from '../../../assret/images/close.png'
import notifi from '../../../assret/images/notifi.png'
import { apiUrl } from "../../../api/api_url";
import { postRequest } from "../../../api/http";

type Props = {
    hideSelect: any,
    onGoPage: any,
    isLoading: boolean
};

const ShopModal = (props: Props) => {
  const { hideSelect, onGoPage } = props;

  const [shop_list, setshop_list] = useState([])

  useEffect(() => {
    getShopList()
  },[])

  const getShopList = async () => {
    let res:any = await postRequest(apiUrl.changeShopList)
    if(res.code == 1){
      setshop_list(res.data)
    }
  }

  return (
    <div className='selectStore'>
        
        <div className='selectStore-main'>
            <div className='selectStore-inner'>
            <div className='selectStore-content'>
                <div className='selectStore-top'>
                <div className='selectStore-top-left'>
                    选择门店
                </div>
                <div className='selectStore-top-bottom' onClick={() => { hideSelect() }}>
                    <img src={clsoe} alt="" className='selectStore-top-bottom-img'/>
                </div>
                </div>
                <Spin spinning={props.isLoading} tip="努力加载中">
                <div className='selectStore-bottom' style={{paddingBottom: shop_list.length > 4 ? '10px' : '55px'}} >
                {

                    shop_list.map((item: any, index: any) => {
                    return (
                        <div className='selectStore-bottom-list' key={index} onClick={() => {onGoPage(item.shop_id, item.shop_name, item)}}>
                        <div className='selectStore-bottom-list-logo'>
                            <img src={item.img} alt="" className='selectStore-bottom-list-logo-img'/>
                        </div>
                        <div className='selectStore-bottom-list-name'>
                            {item.shop_name}
                        </div>
                            <div className='selectStore-bottom-list-status'>店铺状态：{item.is_open ? '正常营业' : '已打烊'}</div>
                          </div>
                        )
                      })
                    }
                  </div>
                  </Spin>
                </div>
              </div>
            </div>
      
          </div>
  );
};

export default ShopModal