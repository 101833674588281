import React from "react";
import "./newTask.scss"
import {Button, Col, message, Row, Spin, Steps} from "antd";
import TaskSetting from "./components/taskSetting/taskSetting";
import AccountSetting from "./components/accountSetting/accountSetting";
import RewardSetting from "./components/rewardSetting/rewardSetting";
import PosterSetting from "./components/posterSetting/posterSetting";
import moment from "moment"
import {getRequest, postRequest} from "../../../api/http";
import {apiUrl} from "../../../api/api_url";
import { RouteComponentProps} from "react-router-dom";
const {Step} = Steps;
interface IProps extends RouteComponentProps{

}

interface taskDetailType {
    id: number,
    name: string,
    prized_id: number,
    prize_stock:number,
    help_num: 1,
    introduction:string,
    poster: string,
    start_time: number,
    end_time: number,
    verify_start_time: number,
    verify_end_time: number,
    status: number,
    mp_ids: string,
    mp_account_list: [],
    prize_info: []
}

class NewTask extends React.Component<IProps>{

    propsParam:any;

    state = {
        currentIndex: 0,
        loading: true
    };
    canvas:any;
    posterChild: any;
    taskParam: any = {};            // 活动参数
    accountParam: any = [];         // 参与公众号设置参数
    rewardParam: any = {};          // 活动奖励设置参数
    posterParam: any = {};          // 海报设置参数

    componentDidMount(): void {
        this.propsParam = this.props.location.state;
        this.initComponent();
    }

    // 初始化组件数据
    initComponent = ()=>{
        if(this.propsParam){
            this.requestTaskDetail(this.propsParam.id);
        }else {
            this.setState({
                loading: false
            })
        }
    };

    // 获取任务详情
    requestTaskDetail = async (id: number) =>{
        const res:any = await getRequest(`${apiUrl.getTaskDetailById}/${id}`);
        if(res.code === 1){
            this.initAllComponentParam(res.data);
        }
    };

    // 初始化各组件数据
    initAllComponentParam = (taskDetail:taskDetailType) =>{
        let formatStr = 'YYYY-MM-DD HH:mm:ss';
        // 拼接 第一步 任务设置 参数
        let activityTime = [moment(taskDetail.start_time*1000).format(formatStr),moment(taskDetail.end_time*1000).format(formatStr)];
        let taskWriteOffTime = [moment(taskDetail.verify_start_time*1000).format(formatStr),moment(taskDetail.verify_end_time*1000).format(formatStr)];
        this.taskParam ={
            activityTime: activityTime,
            taskWriteOffTime: taskWriteOffTime,
            name: taskDetail.name,
            introduction: taskDetail.introduction
        };
        this.accountParam = taskDetail.mp_account_list;
        this.rewardParam = {
            value: taskDetail.help_num,
            stockNum: taskDetail.prize_stock,
            bindShop: taskDetail.prize_info
        };
        this.posterParam = JSON.parse(taskDetail.poster);
        this.setState({
            loading: false
        })

    };

    next =() => {
        let currentIndexTem = this.state.currentIndex;
        switch (currentIndexTem) {
            case 0: // 任务设置
                this.checkTaskParams();
                break;
            case 1: // 公众号设置
                this.checkAccountParams();
                break;
            case 2: // 奖励设置
                this.checkRewardParams();
                break;
            case 3: // 海报设置
                this.posterChild.submitParentParams();
                this.checkPosterParams();
                break;
        }

    };
    onRef = (ref:any) => this.posterChild = ref;
    prev =() => {
        const currentIndex = this.state.currentIndex - 1;
        this.setState({ currentIndex });
    };

    // 接收 子集组件 传来的参数 1 任务设置 2 公众号设置 3 奖励设置 4 海报设置
    handleParams =(type:number,param:any,canvas = '') =>{
        if(canvas){
            this.canvas = canvas;
        }
        switch (type) {
            case 1: // 任务设置
                this.taskParam = param;
                break;
            case 2: // 公众号设置
                this.accountParam = param;
                break;
            case 3: // 奖励设置
                this.rewardParam = param;
                break;
            case 4: // 海报设置
                this.posterParam = param;
                break;
        }
    };

    // 检查任务设置参数
    checkTaskParams =() =>{
        let {name,activityTime,taskWriteOffTime,introduction} = this.taskParam;
        if(name == null || name.length === 0){
            message.error("任务名称不能为空!");
            return false;
        }
        if(activityTime == null){
            message.error("请选活动时间周期!");
            return false;
        }
        if(taskWriteOffTime == null){
            message.error("请选人活动核销周期!");
            return false;
        }

        if(introduction == null || introduction === ''){
            message.error("请填写任务描述!");
            return false;
        }
        let currentIndexTem = this.state.currentIndex;
        this.setState({ currentIndex:currentIndexTem + 1 });
        return true;
    };

    // 检查参与公众号设置参数
    checkAccountParams =() =>{
        if(this.accountParam.length === 0){
            message.error("请添加要参与公众号!");
            return false;
        }
        let currentIndexTem = this.state.currentIndex;
        this.setState({ currentIndex:currentIndexTem + 1 });
        return true;
    };

    // 检查奖励设置参数
    checkRewardParams =() =>{
        let {value,stockNum,bindShop} = this.rewardParam;
        if(value == null || value.length === 0){
            message.error("请填写目标人数哦!");
            return false;
        }
        if(stockNum == null || stockNum.length === 0){
            message.error("请填写奖品库存哦!");
            return false;
        }
        if(bindShop == null || Object.keys(bindShop).length === 0){
            message.error("请绑定奖励商品!");
            return false;
        }
        let currentIndexTem = this.state.currentIndex;
        this.setState({ currentIndex:currentIndexTem + 1 });
        return true;
    };
    // 检查海报设置参数
    checkPosterParams =() =>{
        let posterParam = this.posterParam;
        if(Object.keys(posterParam).length === 0){
            message.error("请上传海报哦！");
            return false
        }
        if(posterParam.imageUrl === null && posterParam.imageUrl === ''){
            message.error("请上传海报哦！");
            return false
        }
        // 开始创建任务
        this.requestCreateTask();
        return true;
    };

    // 开始创建任务
    requestCreateTask = async () =>{
        let {taskParam,accountParam,rewardParam,posterParam} = this;
        let params = {
            name: taskParam.name,
            introduction: taskParam.introduction,
            start_time: moment(taskParam.activityTime[0]).valueOf()/1000,
            end_time: moment(taskParam.activityTime[1]).valueOf()/1000,
            verify_start_time: moment(taskParam.taskWriteOffTime[0]).valueOf()/1000,
            verify_end_time: moment(taskParam.taskWriteOffTime[1]).valueOf()/1000,
            mp_ids: accountParam[0].id,
            help_num: rewardParam.value,
            prize_stock: rewardParam.stockNum,
            prize_id: rewardParam.bindShop.id,
            poster: JSON.stringify(posterParam)
        };
        if(this.propsParam){
            // 编辑
            const res: any = await postRequest(apiUrl.updateTask+'/'+this.propsParam.id,params);
            if(res.code === 1){
                message.success("编辑任务成功");
                this.props.history.push('/sidebar/taskManage');
            }else{
                message.error(res.message);
            }
        }else{
            // 新建
            const res: any = await postRequest(apiUrl.createTask,params);
            if(res.code === 1){
                message.success("添加任务成功");
                this.props.history.push('/sidebar/taskManage');
            }else{
                message.error(res.message);
            }
        }

    };

    render(){
        const { currentIndex,loading } = this.state;
        const steps= [
            {
                title: '任务设置',
                content: <TaskSetting callbackParent={this.handleParams.bind(this)} param={this.taskParam}/>,
            },
            {
                title: '参与公众号设置',
                content: <AccountSetting callbackParent={this.handleParams.bind(this)} param={this.accountParam}/>,
            },
            {
                title: '奖励设置',
                content: <RewardSetting callbackParent={this.handleParams.bind(this)} param={this.rewardParam}/>,
            },
            {
                title: '海报设置',
                content: <PosterSetting onRef={this.onRef} callbackParent={this.handleParams.bind(this)} param={this.posterParam} />,
            },
        ];
        return loading ? <div className='loading_view'><Spin /></div>:
        <div className='l_content'>
            <Steps current={currentIndex}>
                {steps.map(item => (
                    <Step key={item.title} title={item.title} />
                ))}
            </Steps>
            <div className="steps-content">{steps[currentIndex].content}</div>
            <div className='action_steps'>
                <Row>
                    <Col span={10}/>
                    <Col span={2}>
                        {
                            currentIndex > 0 && (
                                <Button size="default" onClick={this.prev}>
                                    上一步
                                </Button>
                            )
                        }
                        {
                            currentIndex === 0 ?
                                <Button size="default" onClick={()=>{
                                    this.props.history.goBack();
                                }}>
                                    取消
                                </Button> : null
                        }
                    </Col>
                    <Col span={2}>
                        {
                            currentIndex < steps.length -1 && (
                                <Button type="primary" onClick={this.next} size="default">
                                    下一步
                                </Button>
                            )
                        }
                        {
                            currentIndex  === 3 && (
                                <Button type="primary" onClick={this.next} size="default">
                                    保存
                                </Button>
                            )
                        }
                    </Col>
                </Row>
            </div>
        </div>
    }
}
export default NewTask;