import React from 'react';
import { Row, Col, Button, Input, Radio, Upload, Icon, message } from 'antd';
import { getRequest, postRequest } from '../../api/http';
import { apiUrl } from '../../api/api_url';

const QINIU_SERVER = "https://up-z2.qiniup.com";

class UserGroupAdd extends React.Component<any>{

    state = {
        loading: false,
        dataObj: {},
        name: '',
        type: 1,
        activeList: [{
            name: '',
            region: '',
            imgUrl: '',
            key: ''
        }],
        fileContent: ''
    }

    componentDidMount(){
        const {location} = this.props;
        if(location.state){
            this.setState({
                name: location.state.name
            })
        }
    }

    inputChange = (e: any) => {
        let name = e.target.name;
        let value = e.target.value;
        this.setState({
            [name]: value
        })
    }

    nameChange = (e: any, index: number) => {
        let activeList = this.state.activeList;
        activeList[index].name = e.target.value;
        this.setState({
            activeList
        })
    }

    regionChange = (e: any, index: number) => {
        let activeList = this.state.activeList;
        activeList[index].region = e.target.value;
        this.setState({
            activeList
        })
    }

    // 增加
    addList = () => {
        let activeList = this.state.activeList;
        activeList.push({
            name: '',
            region: '',
            imgUrl: '',
            key: ''
        })
        this.setState({
            activeList
        })
    }

    deleteList = (index: number) => {
        let activeList = this.state.activeList;
        activeList.splice(index, 1)
        this.setState({
            activeList
        })
    }

     // 上传图片前
     handleBeforeUpload = async () => {
        const res: any = await getRequest(apiUrl.getUploadToken, {
            type: "poster"
        });
        if (res && res.data) {
            const key = `${Math.random().toString(36).slice(-8)}${new Date().valueOf()}.png`;
            let updateObj = { token: res.data.upload_token, region: `https://up-z2.qiniu.com`, key: key, 'x:type': "poster" }
            this.setState({
                dataObj: updateObj
            })
        }
    };

    // 海报改变监听
    handleUploadChange = (info: any, index: number) => {
        let activeList = this.state.activeList;

        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
          }
          if (info.file.status === 'done') {
              activeList[index].key = info.file.response.key;
              activeList[index].imgUrl = info.file.response.url;
              this.setState({ activeList,loading: false, });
          }
    };

    saveGroup = async () => {
        const {location} = this.props;
        let { name, fileContent } = this.state;
        if(!location.state){
            if(name == ''){
                message.warning('请输入分群名称')
                return;
            }
        }
        let param:any = {
            group_name: name,
            member_file: fileContent
        }

        if(!location.state){
            const res: any = await postRequest(apiUrl.groupAdd, param)
            if(res.code == 1){
                message.success('保存成功');
                this.props.history.goBack();
            }
        }else{
            param.id = this.props.location.state.id
            const res: any = await postRequest(apiUrl.groupUpdate, param)
            if(res.code == 1){
                message.success('更新成功');
                this.props.history.goBack();
            }
        }
    }

    oninputChange = (e: any) => {
        const file = e.target.files[0];
        const fr = new FileReader();
        fr.readAsDataURL(file);
        let fileContent:any = null;
        fr.onload = () => {
          fileContent = fr.result;
        };

        setTimeout(() => {
            this.setState({
                fileContent
            })
          console.log('fileContent', fileContent);
        }, 100);
    }
    render(){
        let { name, type, activeList } = this.state;
        return<div className='l_content'>
            <Row className="mb30" type="flex" align="middle">
                <Col span={2}>
                    <div>
                        <span className="star"></span>
                        <span className="color_black">分群名称</span>
                    </div>
                </Col>
                <Col span={6}>
                    <Input placeholder="请输入分群名称" value={name} name='name' onChange={(e) => this.inputChange(e)}/>
                </Col>
            </Row>

            {/* <Row className="mb30" type="flex" align="middle">
                <Col span={2}>
                    <div>
                        <span className="star"></span>
                        <span className="color_black">添加类型</span>
                    </div>
                </Col>
                <Col span={6}>
                    <Radio.Group
                        value={type}
                        name='type'
                        onChange={(e) => this.inputChange(e)}
                    >
                        <Radio value={1}>批量导入</Radio>
                        <Radio value={2}>手动上传</Radio>
                    </Radio.Group>
                </Col>
            </Row> */}

            {
                type == 1
                ?   <Row className="mb30" type="flex" align="top">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">添加类型</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <input type='file' onChange={(e) => this.oninputChange(e)} ref='file'></input>
                        {/* <Input type='file'></Input> */}
                        {/* <Upload
                            accept=".tsx,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            name='file'
                            action={QINIU_SERVER}
                            data={this.state.dataObj}
                            beforeUpload={this.handleBeforeUpload}
                            onChange = {(info:any) => {
                                console.log('1111',info)
                                if (info.file.status !== 'uploading') {
                                  console.log(info.file, info.fileList);
                                }
                                if (info.file.status === 'done') {
                                //   message.success(`${info.file.name} file uploaded successfully`);
                                } else if (info.file.status === 'error') {
                                //   message.error(`${info.file.name} file upload failed.`);
                                }
                              }}>
                            <Button>
                                <Icon type="upload" /> 上传文件
                            </Button>
                        </Upload> */}
                    </Col>
                </Row>
                : <div>
                    <Button type='primary' onClick={this.addList}>增加</Button>
                        {
                            activeList.map((item: any, index: number) => {
                                return <Row type='flex' align="middle" justify="start" style={{marginTop: 20}} key={index}>
                                            <Col className='mr30' style={{display:'flex', alignItems:'center'}}>
                                                <span style={{width: 130}} className='star'> 微信昵称：</span>
                                                <Input placeholder='请输入微信昵称' value={item.name} onChange={(e) => this.nameChange(e, index)} />
                                            </Col>
                                            <Col className='mr30' style={{display:'flex', alignItems:'center'}}>
                                                <span style={{width: 130}} className='star'>微信地区：</span>
                                                <Input placeholder='请输入微信地区' value={item.region} onChange={(e) => this.regionChange(e, index)} />
                                            </Col>
                                            <Col className='mr30' style={{display:'flex', alignItems:'center'}}>
                                                <span style={{width: 180}} className='star mr30'>上传微信头像</span>
                                                <Upload
                                                        listType="picture-card"
                                                        className="avatar-uploader"
                                                        showUploadList={false}
                                                        action={QINIU_SERVER}
                                                        accept="image/*"
                                                        beforeUpload={this.handleBeforeUpload}
                                                        onChange={(e) => this.handleUploadChange(e, index)}
                                                        data={this.state.dataObj}
                                                    >
                                                            {item.imgUrl
                                                            ? <img src={item.imgUrl} alt="avatar" style={{ width: 104, height: 104 }} />
                                                            : <div>
                                                                <Icon type={this.state.loading ? 'loading' : 'plus'} />
                                                                <div className="ant-upload-text">Upload</div>
                                                            </div>}

                                                    </Upload>
                                            </Col>
                                            <Col>
                                                <Button onClick={() => this.deleteList(index)}>删除</Button>
                                            </Col>
                                        </Row>
                                    })
                                }
                </div>
            }

            <Row>
                <Col span={18}>
                    <div className="bottom_wrap">
                        <Button type="primary" ghost onClick={() => {this.props.history.goBack();}}>
                            取消
                        </Button>
                        <Button type="primary" onClick={() => this.saveGroup()}>
                            保存
                        </Button>
                    </div>
                </Col>
            </Row>
        </div>
    }
}

export default UserGroupAdd;