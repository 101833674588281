import React from 'react'
import { Button, Modal } from 'antd'

interface IProps {
  rowdata: any,
  deleteIdx: number,
  deleteFun(idx:number, data:any):void
}

const DeleteActiveBtn = (props: IProps) => {
  
  function deleteActive() {
    Modal.confirm({
      title: '删除活动',
      content: `确认删除活动「${props.rowdata.name}」？`,
      okText: '确认',
      cancelText: '取消',
      onOk: () => props.deleteFun(props.deleteIdx, props.rowdata),
    });
  }
  return <Button type="link" onClick={() => deleteActive()}>删除</Button>
}

export default DeleteActiveBtn