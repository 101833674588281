import React from 'react';
import { Table, Row, Col, Button, DatePicker, Modal, message } from 'antd'
import { RangePickerValue } from "antd/lib/date-picker/interface";
import { getRequest } from '../../../../../api/http'
import { apiUrl } from '../../../../../api/api_url'
import './consumption.scss'

interface IProps {
    send: any;
    onMsg: any;
    swicthSubOrder: any
    onRef: any
}
interface IStates{}

class Consumption extends React.Component<IProps,IStates>{

    state = {
        consumption_list: [],
        user_info: {

        },
        count_amount: '',  //总消费次数
        sum_amount: '', //总消费金额
        temp_list: [],
        // current: 1,
        // pageSize: 10,
        // count: 0,
        // start_time: '',
        // end_time: '',
        //id: 7862
    }

    componentDidMount(){
        this._initDate()
    }

    componentWillReceiveProps(nextPros:any) {
        if(nextPros.send != this.props.send) {
            this._initDate()
        }
    }

    //获取用户信息
    async _initDate() {
        const temp_list:any  = this.state.temp_list;
        let res:any = await this.props.onRef();
        this.sendParat(res.data.user)
        // this.setState(() => {
        //     return {
        //         consumption_list: temp_list.concat(res.data.list),
        //         user_info: res.data.user,
        //         count_amount: res.data.count_amount,
        //         sum_amount: res.data.sum_amount,
        //     }
        // })
        //第一次不处理
        if(temp_list.length == 0) {
            this.setState({
                consumption_list: temp_list.concat(res.data.list),
                temp_list: temp_list.concat(res.data.list),
                user_info: res.data.user,
                count_amount: res.data.count_amount,
                sum_amount: res.data.sum_amount,
            }, () => {
                //console.log(this.state.consumption_list)
                //console.log(this.state.temp_list)
            })
        } else {
            //去掉数组对象id重复的元素
            let data_list = res.data.list;
            data_list = data_list.filter((item:any) => {
                let table_lsit = temp_list.map((v:any) => v.id)
                return !table_lsit.includes(item.id)
            })
            //console.log(data_list)
            this.setState({
                consumption_list: temp_list.concat(data_list),
                temp_list: temp_list.concat(data_list),
                user_info: res.data.user,
                //count_amount: res.data.count_amount,
                //sum_amount: res.data.sum_amount,
            }, () => {
                //console.log(this.state.consumption_list)
                //console.log(this.state.temp_list)
            })
        }

    }

    //传值给父组件
    sendParat(data: any) {
        this.props.onMsg(data)
    }

    //切换订单信息
    onSwicthOrderInfo(code: any) {
        this.props.swicthSubOrder(code)
    }


    render() {
        const { consumption_list, count_amount, sum_amount } = this.state;

        return <div className='income l_content'>
            <div className='income-total'>
                <div>
                    总消费次数：{count_amount}
                </div>
                <div>
                    累计消费：{sum_amount}元
                </div>
            </div>
            {
                consumption_list.map((item:any, index:any) => {
                    return (
                        <div className='income-main' key={index}>
                            <div className='income-main-left'>
                                <div className='income-main-left-item'>
                                    <div>
                                        本次第{item.pay_time}次 <span className='income-main-left-icon'>|</span> 距上次{item.last_pay_time}天
                                    </div>
                                    <div className='income-main-left-item-right'>
                                        下单时间：{item.created_at}
                                    </div>
                                </div>
                                <div className='income-main-left-item'>
                                    <div>
                                        订单号: {item.code}
                                    </div>
                                    <div className='income-main-left-item-right'>
                                        实付：{item.actual_fee}
                                    </div>
                                </div>
                                <div className='income-main-left-item'>
                                    <div>
                                        桌号: {item.name} 用餐人数：{item.user_amount}
                                    </div>
                                    <div className='income-main-left-item-right'>
                                        订单金额：{item.origin_fee}
                                    </div>
                                </div>
                                <div className='income-main-left-item'>
                                    <div>
                                        用餐时长：{item.spend_time}
                                        <div className='income-main-left-item-detail' onClick={() => {this.onSwicthOrderInfo(item.code)}}>
                                            查看详情 》
                                        </div>
                                    </div>
                                    <div className='income-main-left-discount'>
                                        <div className="income-main-left-discount-coupon">使用优惠:</div>
                                        {
                                            JSON.stringify(item.third_coupon_list) !== '[]'
                                            ? item.third_coupon_list.map((sub_item:any, sub_index:any) => {
                                                return (
                                                    <div className='income-main-left-item-right'>
                                                        {
                                                            sub_item.name
                                                        }:-¥{sub_item.price}
                                                    </div>
                                                )
                                            })
                                            : ''
                                        }
                                        {
                                            JSON.stringify(item.platform_coupon_list) !== '[]'
                                            ? item.platform_coupon_list.map((sub_item:any, sub_index:any) => {
                                                return (
                                                    <div className='income-main-left-item-right'>
                                                        {
                                                            sub_item.name
                                                        }:-¥{sub_item.price}
                                                    </div>
                                                )
                                            })
                                            : ''
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    }
}

export default Consumption;