import React from 'react';
import InsideNav from '../../../component/insideNav/insideNav';
import { collectChildNav } from '../../../utils/permisstionUtil';
import MatterDown from '../../matterDown';
import ShopRegion from '../../shop/shopRegion/shopRegion';
import ShopTable from '../../shop/shopTable/shopTable';
import './deskManage.scss'
import DesktopDisplay from '../../shop/desktopDisplay/desktopDisplay';

type IProps = {
    history: any
}
type IState = {
    navList: any
    current_key: string
}
const navTable = [
    {
        title: '餐桌管理',
        isActive: false,
        KEY: "SHOP_TABLE",
        uni_key: '2_8_1'
    },
    {
        title: '位置管理',
        isActive: false,
        KEY: "SHOP_REGION",
        uni_key: '2_8_2'
    },
    {
        title: '物料下载',
        isActive: false,
        KEY: "MATTER_DOWN",
        uni_key: '2_8_3'
    },
    {
        title: '餐桌显示',
        isActive: false,
        KEY: "DESKTOP_DISPLAY",
        uni_key: '2_8_4'
    }
]
class DeskManage extends React.Component<IProps,IState>{

    constructor(props:IProps) {
        super(props);
        this.state = {
            navList: [],
            current_key: "",
        }
    }

    componentDidMount(){
        this.initPageState();
    }

    initPageState = () =>{
        let navList = collectChildNav(navTable);
        let currentKey = localStorage.getItem('current_key') ?? navList[0].KEY;
        let isSetKey = false;
        navList.map((item:any) => {
            if(item.KEY == currentKey){
                item.isActive = true;
                isSetKey = true;
            }else{
                item.isActive = false;
            }
        })
        if(!isSetKey){
            currentKey = navList[0].KEY;
            navList[0].isActive = true;
        };
        this.setState({
            current_key: currentKey,
            navList: navList
        })
    }

    navCallBack(currentKey: string){
        localStorage.setItem('current_key',currentKey) 
        this.setState({
            current_key: currentKey
        })
    }

    render() {
        let { navList,current_key } = this.state;
        return <div>
            <InsideNav navList={navList} callBack={(key: string) =>this.navCallBack(key)}/>
            <div className="body">
                {
                    current_key === 'SHOP_TABLE' ?  <ShopTable history={this.props.history} />
                    :
                    current_key === 'SHOP_REGION' ?  <ShopRegion />
                    :
                    current_key === 'MATTER_DOWN' ?  <MatterDown />
                    :
                    current_key === 'DESKTOP_DISPLAY' ? <DesktopDisplay />
                    : <></>
                }
            </div>
        </div>
    }

}

export default DeskManage;