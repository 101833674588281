import React, { useState } from "react"
import { Row } from 'antd'
import shopDecorationStore from '../../../../stores/shopDecoration.store'
import decoration_menu_mofang_icon from '../../../../assret/images/decoration_menu_mofang.png'
import decoration_menu_richtext_icon from '../../../../assret/images/decoration_menu_richtext.png'
import decoration_menu_card_icon from '../../../../assret/images/decoration_menu_card.png'
import decoration_menu_store_icon from '../../../../assret/images/decoration_menu_store.png'
import decoration_menu_invitation_icon from '../../../../assret/images/decoration_menu_invitation.png'
import decoration_menu_muti_icon from '../../../../assret/images/decoration_menu_muti.png'
import decoration_menu_mofang_select_icon from '../../../../assret/images/decoration_menu_mofang_select.png'
import decoration_menu_richtext_select_icon from '../../../../assret/images/decoration_menu_richtext_select.png'
import decoration_menu_card_select_icon from '../../../../assret/images/decoration_menu_card_select.png'
import decoration_menu_store_select_icon from '../../../../assret/images/decoration_menu_store_select.png'
import decoration_menu_invitation_select_icon from '../../../../assret/images/decoration_menu_invitation_select.png'
import decoration_menu_muti_select_icon from '../../../../assret/images/decoration_menu_muti_select.png'
import styles from './decorationHomeLeft.module.scss'

interface IProps {
    scrollToBottom():void
}

interface IState {

}
const DecorationHomeLeft = (props:IProps) => {

    const { scrollToBottom } = props

    const [ current, setCurrent ] = useState(-1)
    const menuList = [
        {
            module:'richText',
            name:'富文本',
            img:decoration_menu_richtext_icon,
            imgActive:decoration_menu_richtext_select_icon,
            moduleData:{
                module: 'richText',
                moduleName: '富文本',
                title: '富文本', //模块名称
                showTitle: false, //是否显示模块
                subTitle: '', //副标题
                showSubTitle: false, //是否显示副标题
                richText:'',
            }
        },
        {
            module:'muti',
            name:'多图文',
            img:decoration_menu_muti_icon,
            imgActive:decoration_menu_muti_select_icon,
            moduleData:{
                module: 'muti',
                moduleName: '多图文',
                title: '多图文', //模块名称
                showTitle: false, //是否显示模块
                subTitle: '', //副标题
                showSubTitle: false, //是否显示副标题
                type:2, //模块样式 1为轮播图，2为横向滚动，3为竖排
                imgList: [
                    {
                        imgUrl:'https://ydn-siyu.cdn.shuachi.com/j0m9p7t41597657516406.png',
                        desc:'测试名称', //描述（名称）
                        subDesc:'测试价格', //二级描述（价格）
                    },
                    {
                        imgUrl:'https://ydn-siyu.cdn.shuachi.com/e3elc6k51606892048799.png',
                        desc:'测试名称1', //描述（名称）
                        subDesc:'测试价格2', //二级描述（价格）
                    },
                    {
                        imgUrl:'https://ydn-siyu.cdn.shuachi.com/l1qghcr31605230734932.png',
                        desc:'测试名称2', //描述（名称）
                        subDesc:'测试价格3', //二级描述（价格）
                    },
                    {
                        imgUrl:'https://ydn-siyu.cdn.shuachi.com/roejlc9r1595497362213.png',
                        desc:'测试名称2', //描述（名称）
                        subDesc:'测试价格4', //二级描述（价格）
                    }
                ],
                desc:'', //描述（名称）
                subDesc:'', //二级描述（价格）
            }
        },
        {
            module:'moFang',
            name:'魔方导航',
            img:decoration_menu_mofang_icon,
            imgActive:decoration_menu_mofang_select_icon,
            moduleData:{
                module: 'moFang',
                moduleName: '魔方导航',
                title: '魔方导航', //模块名称
                showTitle: false, //是否显示模块
                subTitle: '', //副标题
                showSubTitle: false, //是否显示副标题
                type:2, //模块样式  1为横排，2为异形排布
                column: 3, //列数
                list: [
                    {
                        name:'余额',
                        show:1,
                        type:'',
                        styleType: 0, //标题样式 0默认，1自定义
                        style: {
                            fontSize: 1, //字体 ，1为16号，2为14号， 3为14号
                            color: 'rgba(0,0,0)', //字体颜色
                            fontWeight: false, //字体是否加粗
                        },
                        imgType: 0, //图标附图 0默认，1自定义
                        imgUrl:'https://ydn-siyu.cdn.shuachi.com/card_charge.png' , //图标附图url,
                        defaultImgUrl:'https://ydn-siyu.cdn.shuachi.com/card_charge.png' , //图标附图url,
                        linkUrl: '',
                        linkName: '',
                    },
                    {
                        name:'优惠卡券',
                        show:1,
                        type:'',
                        styleType: 0, //标题样式 0默认，1自定义
                        style: {
                            fontSize: 1, //字体 ，1为16号，2为14号， 3为14号
                            color: 'rgba(0,0,0)', //字体颜色
                            fontWeight: false, //字体是否加粗
                        },
                        imgType: 0, //图标附图 0默认，1自定义
                        imgUrl:'https://ydn-siyu.cdn.shuachi.com/card_coupon.png' , //图标附图url,
                        defaultImgUrl:'https://ydn-siyu.cdn.shuachi.com/card_coupon.png' , //图标附图url,
                        linkUrl: '',
                        linkName: '',
                    },
                    {
                        name:'兑换好礼',
                        show:1,
                        type:'',
                        styleType: 0, //标题样式 0默认，1自定义
                        style: {
                            fontSize: 1, //字体 ，1为16号，2为14号， 3为14号
                            color: 'rgba(0,0,0)', //字体颜色
                            fontWeight: false, //字体是否加粗
                        },
                        imgType: 0, //图标附图 0默认，1自定义
                        imgUrl:'https://ydn-siyu.cdn.shuachi.com/card_score.png' , //图标附图url,
                        defaultImgUrl:'https://ydn-siyu.cdn.shuachi.com/card_score.png' , //图标附图url,
                        linkId: '',
                        linkUrl: '',
                        linkName: '',
                    },
                ]
            }            
        },
        {
            module:'invitation',
            name:'邀请有礼',
            img:decoration_menu_invitation_icon,
            imgActive:decoration_menu_invitation_select_icon,
            moduleData:{
                module: 'invitation',
                moduleName: '邀请有礼',
                title:'邀请有礼',
                imgUrl:'https://ydn-siyu.cdn.shuachi.com/lprer4491607074173495.png',
                defaultImgUrl:'https://ydn-siyu.cdn.shuachi.com/lprer4491607074173495.png',
                type: 0,
            }
        },
        {
            module:'equityCard',
            name:'权益卡',
            img:decoration_menu_card_icon,
            imgActive:decoration_menu_card_select_icon,
            moduleData:{
                module: 'equityCard',
                moduleName: '权益卡',
                title:'权益卡',
                imgUrl:'https://ydn-siyu.cdn.shuachi.com/equityCard.jpg',
                defaultImgUrl:'https://ydn-siyu.cdn.shuachi.com/equityCard.jpg',
                type: 0,
            }
        },
        {
            module:'address',
            name:'商家信息',
            img:decoration_menu_store_icon,
            imgActive:decoration_menu_store_select_icon,
            moduleData:{
                module: 'address',
                moduleName: '商家信息',
                title:'商家信息'
            }
        },
    ]

    const mouseEnter = (index:number) => {
        setCurrent(index)
    }

    const mouseLeave = () => {
        setCurrent(-1)
    }

    const menuClick = (item:any) => {
        let length = shopDecorationStore.shop_decoration_home.length
        let dataTemp = JSON.parse(JSON.stringify(shopDecorationStore.shop_decoration_home))
        dataTemp.push(item.moduleData)
        shopDecorationStore.updateShopDecorationHome(dataTemp)
        shopDecorationStore.updateShopDecorationHomeOther({
            module:item.module , //当前选中模版
            showRight:true , //是否显示右侧编辑
            current: length, //当前板块
            hoverCurrent: -1,
            editMofangCurrent: -1 , //当前编辑的魔方导航
            hoverEditMofangCurrent: -1 , //当前悬停的编辑的魔方导航
            editMutiCurrent: 0 , //当前编辑的多图文导航
            hoverMutiCurrent: -1, //当前悬停编辑的多图文导航
        })
        setTimeout(()=>{
            scrollToBottom&&scrollToBottom()
        },200)
    }

    return (
        <div className={styles.left}>
            <div className={styles.left_content} onClick={(e)=>{e.stopPropagation()}}>
                <div className={styles.left_title}>常用模块</div>
                <Row type="flex" align="middle" justify="space-between" className={styles.left_card}>
                    {
                        menuList.map((item:any,index:number)=>{
                            return <Row type="flex" align="middle" justify="center" key={index} className={`${styles.left_card_item} ${current === index ? styles.left_card_item_active : ''}`} onMouseEnter={()=>{mouseEnter(index)}} onMouseLeave={()=>{mouseLeave()}} onClick={()=>{menuClick(item)}}>
                                <img src={current === index ? item.img : item.imgActive} />
                                <div>{item.name}</div>
                            </Row>
                        })
                    }
                </Row>
            </div>
        </div>
    )
}

export default DecorationHomeLeft