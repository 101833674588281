import React from "react"
import { getRequest } from '../../../../api/http'
import { apiUrl } from '../../../../api/api_url'
import { message, Table, Icon, Tooltip, Upload, Popover } from 'antd'
import { inject, observer } from "mobx-react"
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import styles from './decorationRight.module.scss'
import set_down from '../../../../assret/images/decoration_wode_bottom.png'
import set_right from '../../../../assret/images/decoration_wode_right.png'
import decoration_wode_delete from '../../../../assret/images/decoration_wode_delete.png'
import decoration_wode_open from '../../../../assret/images/decoration_wode_open.png'
import decoration_wode_close from '../../../../assret/images/decoration_wode_close.png'

import shopDecorationStore from '../../../../stores/shopDecoration.store'

const DragHandle = SortableHandle(() => (
    <div className="">
        <div style={{height: '10px'}}><Icon type="caret-up" style={{height: '10px', color: '#000', fontSize: '20px'}} /></div>
        <div><Icon type="caret-down" style={{height: '10px', color: '#000', fontSize: '20px'}} /></div>
    </div>
));

const key_tip = 'updatable';
const QINIU_SERVER = 'https://up-z2.qiniup.com';
const key = 'updatable';
const baseUrl = 'https://ydn-siyu.cdn.shuachi.com/';

const SortableItem = SortableElement((props: any) => <tr {...props} />);
const SortableContainerTab = SortableContainer((props: any) => <tbody {...props} />);

@observer
class DecorationRight extends React.Component<any, any> {

    state = {
        sortList: [],
        merber_interests_status: 1,
        merber_assets_status: 1,
        loading: false,
        imageUrl: '',
        updateObj: {
            key: '',
            tokens: '',
            region: '',
            'x:type': ''
        },
    }

    columns = [
        {
            title: '项名称',
            dataIndex: 'name',
            key: 'name',
            className: 'list_center drag-visible',
        },
        {
            title: '显示',
            dataIndex: 'show',
            key: 'show',
            // className: 'list_center',
            render: ( text: string, record: any, index: number) => {
                return <div onClick={() => {this.onIsShow(record,index)}}>
                        {
                            record.show === '1'
                            ?   <img style={{cursor: 'pointer', width: '22px'}} src={decoration_wode_open} />
                            :   <img style={{cursor: 'pointer', width: '22px'}} src={decoration_wode_close} />
                        }
                    </div>
            }
        },
        {
            title: () => {
                return (
                    <div>
                        <span>排序</span>
                        <Tooltip title={'拖动这一列的图标进行排序'} className="tip">
                            <Icon type="question-circle" theme="filled" />
                        </Tooltip>
                    </div>
                )
            },
            className: 'list_center',
            render: () => <DragHandle />,
        },
        {
            title: '操作',
            dataIndex: 'status',
            key: 'status',
            render: ( text: string, record: any, index: number) => {
                return <div onClick={() => {this.onDeleteItem(record, index)}}><img style={{cursor: 'pointer', width: '22px'}} src={decoration_wode_delete} /></div>
            }
        },
    ]

    componentDidMount() {
        shopDecorationStore.refresh === 1 && this._initDate()
    }

    componentWillUnmount() {
        shopDecorationStore.updateRefresh(1)
    }

    //显示和隐藏对应项
    onIsShow = (record: any, index: any) => {
        let temp_list = shopDecorationStore.shop_decoration_wode.merber_menu.module_list;
        temp_list[index]['show'] = record.show === '1' ? '0' : '1';
        this.setState({
            sortList: temp_list
        }, () => {
            shopDecorationStore.updateObjProps('merber_menu','module_list',temp_list)
        })
    }

    //删除对应的liet
    onDeleteItem = (dataObj: any, index: any) => {
        let temp_list = shopDecorationStore.shop_decoration_wode.merber_menu.module_list;
        temp_list.splice(index,1)
        this.setState({
            sortList: temp_list
        }, () => {
            shopDecorationStore.updateObjProps('merber_menu','module_list',temp_list)
        })
    }

    _initDate = async () => {
        let res: any = await getRequest(apiUrl.profileIndex)
        if(res.code === 1) {
            let dataObj = res.data;
            let temp = res.data.list;
            let new_temp = temp.map((item: any, idx:any) => {
                item.index = idx
                return (
                    item
                )
            })
            let shop_decoration_wode = {    //装修门店-我的
                id: dataObj.id,
                merber_info: {
                    status: 1,
                    background: dataObj.background_image,
                    photo_position: dataObj.profile_photo_position,
                    default: dataObj.default, //1:默认 0:自定义
                },
                merber_interests: dataObj.vip_rights, //会员权益，1:显示，0:不显示;
                merber_assets: dataObj.user_property, //用户资产，1:显示，0:不显示;
                merber_interests_open: 1, //0：展开  1：收起
                merber_assets_open: 1, //0：展开  1：收起
                merber_menu: {
                    status: 1,
                    module_style: dataObj.menu_type,
                    module_column: dataObj.num,
                    current_index: 0, //表示module_list的当前操作的索引
                    current_is_edit: 0, //表示当前菜单是否有编辑 0:否 1:是
                    module_list: dataObj.list,
                },
                href_path: dataObj.path
            }
            shopDecorationStore.updateShopDecorationWode(shop_decoration_wode)
            this.onSortTable(dataObj.list,  0)
            this.setState({
                sortList: shopDecorationStore.shop_decoration_wode.merber_menu.module_list
            })
        } else {
            message.error(res.message)
        }
    }

    //关闭||展开
    onSwicth = (e: any,type: any) => {
        e.stopPropagation();
        switch (type) {
            case 'merber_info':
                let merber_info_param = {
                    ...shopDecorationStore.shop_decoration_wode.merber_info
                }
                merber_info_param.status = shopDecorationStore.shop_decoration_wode.merber_info.status === 0 ? 1 : 0
                shopDecorationStore.updateDecorationVal(type,merber_info_param)
                break;
            case 'merber_interests':
                let merber_interests_param = shopDecorationStore.shop_decoration_wode.merber_interests === 0 ? 1 : 0
                shopDecorationStore.updateDecorationVal(type,merber_interests_param)
                break;
            case 'merber_assets':
                let merber_assets_param = shopDecorationStore.shop_decoration_wode.merber_assets === 0 ? 1 : 0
                shopDecorationStore.updateDecorationVal(type,merber_assets_param)
                break;
            case 'merber_menu':
                let merber_menu_param: any = {
                    ...shopDecorationStore.shop_decoration_wode.merber_menu,
                }
                merber_menu_param.status = shopDecorationStore.shop_decoration_wode.merber_menu.status === 0 ? 1 : 0
                shopDecorationStore.updateDecorationVal(type,merber_menu_param)
                break;
            case 'merber_interests_open':
                let merber_interests_open = shopDecorationStore.shop_decoration_wode.merber_interests_open === 0 ? 1 : 0
                shopDecorationStore.updateDecorationVal(type,merber_interests_open)
                break;
            case 'merber_assets_open':
                let merber_assets_open = shopDecorationStore.shop_decoration_wode.merber_assets_open === 0 ? 1 : 0
                shopDecorationStore.updateDecorationVal(type,merber_assets_open)
                break;
            default:
                break;
        }
    }

    //修改对象指定属性
    updateObjProps = (type: any, props: any, val: any) => {
        shopDecorationStore.updateObjProps(type,props,val)
    }

    onSortEnd = ({ oldIndex, newIndex }: any) => {
        const dataSource: any = this.state.sortList;
        if (oldIndex !== newIndex) {
            const newData = arrayMove([].concat(dataSource), oldIndex, newIndex).filter(el => !!el);
            this.onSortTable(newData, 0)
            this.setState({ sortList: newData });
        }
    };

    DraggableBodyRow = ({ className, style, ...restProps }: any) => {
        const dataSource: any  = this.state.sortList;
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = dataSource.findIndex((x: any) => x.index === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };

    //排序接口
    onSortTable = async (arrayList: any, type: any) => {
        let count = arrayList.length;
        let temp = arrayList.map((item: any, index: any) => {
            let obj: any = item;
            obj.order = count - index;
            obj.is_edit = 0;
            return (
                obj
            )
        })
        if(type === 0) {
            shopDecorationStore.updateObjProps('merber_menu','module_list',temp)
        } else {
            shopDecorationStore.updateObjPropsDefault('merber_menu','module_list',temp)
        }
        
    }

    // 获取图片token
    beforeUpload = async (file:any) => {
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
            this.setState({
                loading: false,
            }, () => {
                message.error(file.name + "图片大小超出限制，请修改后重新上传");
                return;
            })
        } else {
            const res:any = await getRequest(apiUrl.getUploadToken, { type: 'food' });
            if(res && res.data) {
            const key = `food/${Math.random().toString(36).slice(-8)}${(new Date()).valueOf()}.png`
                this.setState({
                    updateObj: { token: res.data.upload_token, region: `https://up-z2.qiniu.com`, key: key, 'x:type': "food" }
                })
            }
        }
    }

    // 商品头图上传
    handleChange = (info:any) => {
        const isLt5M = info.file.size / 1024 / 1024 < 5;
        if(isLt5M) {
            if (info.file.status === 'uploading') {
                this.setState({ loading: true });
                return;
            }
            if (info.file.status === 'done') {
                this.setState({
                    imageUrl: info.file.response.url,
                    imageObj: info.file.response,
                    loading: false,
                }, () => {
                    shopDecorationStore.updateObjProps('merber_info','background',this.state.imageUrl)
                })
            }
        }
    }

    //上传导航
    onAddNavigation = () => {
        let temp_list: any = shopDecorationStore.shop_decoration_wode.merber_menu.module_list;
        temp_list.push(
            {
                background: "#ffffff",
                font_color: "#cccccc",
                font_size: 12,
                icon: "",
                name: "新导航",
                order: 0,
                path: '',
                shop_id: 1,
                show: "1",
                status: 0,
                index: temp_list.length,
                weigh: 0,
                font_default: 1,
                icon_default: 1,
            }
        )
        this.setState({
            sortList: temp_list
        }, () => {
            shopDecorationStore.updateObjProps('merber_menu','module_list',temp_list)
        })
    }

    render() {

        const { merber_info, merber_interests, merber_assets, merber_menu, merber_interests_open, merber_assets_open } = shopDecorationStore.shop_decoration_wode;

        const { sortList, merber_interests_status, merber_assets_status, imageUrl, loading, } = this.state;

        const DraggableContainer = (props: any) => (
            
            <SortableContainerTab
                useDragHandle
                helperClass="row-dragging"
                onSortEnd={this.onSortEnd}
                {...props}
            />
        );

        return (
            <div className={styles.right}>
                <div className={styles.right_main}>
                    <div className={styles.right_main_tip}>
                        我的
                    </div>
                    <div className={styles.right_main_list}>
                        <div className={styles.right_main_list_title} onClick={(e: any) => {this.onSwicth(e,'merber_info')}}>
                            <div className={`${styles.right_main_list_title_name}`}>
                                会员信息
                                {
                                    merber_info.status === 0
                                    ?   <img src={set_down} className={`${styles.right_main_list_title_name_img}`} />
                                    :   <img src={set_right} className={`${styles.right_main_list_title_name_img}`} />
                                }
                            </div>
                            {/* <div className={styles.right_radio}>

                            </div> */}
                        </div>
                        {
                            merber_info.status === 0 && (
                                <div className={styles.right_main_list_option}>
                                    <div className={styles.right_main_list_option_item}>
                                        <div className={styles.right_main_list_option_item_name}>
                                            背景图：
                                        </div>
                                        <div className={`${styles.right_main_list_option_item_info} ${styles.right_cursor}`} onClick={() => {this.updateObjProps('merber_info','default',1)}}>
                                            <div className={`${merber_info.default === 1 ? styles.right_radio : styles.right_radioHide} ${styles.right_cursor}`}></div>
                                            <div className={styles.right_radio_txt}>默认</div>
                                        </div>
                                        <div className={`${styles.right_main_list_option_item_info} ${styles.right_cursor}`} onClick={() => {this.updateObjProps('merber_info','default',0)}}>
                                            <div className={`${merber_info.default === 0 ? styles.right_radio : styles.right_radioHide} ${styles.right_cursor}`}></div>
                                            <div className={styles.right_radio_txt}>自定义</div>
                                        </div>
                                    </div>
                                    {
                                        merber_info.default === 0 && (
                                            <div className={styles.right_main_list_option_item}>
                                                {/* <div className={`${styles.right_main_list_option_item_update}`} onClick={() => {this.updateObjProps('merber_info','photo_position',1)}}>
                                                    <div className={`${styles.right_main_list_option_item_update_border} ${styles.right_cursor}`}></div>
                                                    <div className={styles.right_main_list_option_item_update_tip}>最佳尺寸:100x200</div>
                                                </div> */}
                                                <Upload
                                                    listType="picture-card"
                                                    className="avatar-uploader"
                                                    showUploadList={false}
                                                    action={QINIU_SERVER}
                                                    beforeUpload={this.beforeUpload}
                                                    onChange={this.handleChange}
                                                    data={this.state.updateObj}
                                                    >
                                                    {
                                                        imageUrl ?
                                                        <img src={imageUrl} alt="商品图片" style={{ width: '100%' }} />
                                                        : <div className="">
                                                            <Icon type={this.state.loading ? 'loading' : 'plus'} style={{fontSize: '40px'}}/>
                                                        </div>
                                                    }
                                                </Upload>
                                            </div>
                                        )
                                    }
                                    <div className={styles.right_main_list_option_item}>
                                        <div className={styles.right_main_list_option_item_name}>
                                            头像位置：
                                        </div>
                                        <div className={`${styles.right_main_list_option_item_info} ${styles.right_cursor}`} onClick={() => {this.updateObjProps('merber_info','photo_position',1)}}>
                                            <div className={`${merber_info.photo_position === 1 ? styles.right_radio : styles.right_radioHide}`}></div>
                                            <div className={styles.right_radio_txt}>居左</div>
                                        </div>
                                        <div className={`${styles.right_main_list_option_item_info} ${styles.right_cursor}`} onClick={() => {this.updateObjProps('merber_info','photo_position',2)}}>
                                            <div className={`${merber_info.photo_position === 2 ? styles.right_radio : styles.right_radioHide} ${styles.right_cursor}`}></div>
                                            <div className={styles.right_radio_txt}>居中</div>
                                        </div>
                                        <div className={`${styles.right_main_list_option_item_info} ${styles.right_cursor}`} onClick={() => {this.updateObjProps('merber_info','photo_position',3)}}>
                                            <div className={`${merber_info.photo_position === 3 ? styles.right_radio : styles.right_radioHide} ${styles.right_cursor}`}></div>
                                            <div className={styles.right_radio_txt}>居右</div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <div className={styles.right_main_list}>
                        <div className={styles.right_main_list_title} onClick={(e: any) => {this.onSwicth(e,'merber_interests_open')}}>
                            <div className={`${styles.right_main_list_title_name}`}>
                                会员权益
                                {
                                    merber_interests_open === 0
                                    ?   <img src={set_down} className={`${styles.right_main_list_title_name_img}`} />
                                    :   <img src={set_right} className={`${styles.right_main_list_title_name_img}`} />
                                }
                            </div>
                            <div className={`${merber_interests === 1 ? styles.right_radio : styles.right_radioHide}`} onClick={(e: any) => {this.onSwicth(e,'merber_interests')}}>

                            </div>
                        </div>
                            {
                                merber_interests_open === 0 && (
                                    <div className={styles.right_main_list_option}>
                                        <div className={styles.right_main_list_option_fixed}>
                                            固定样式暂不支持自定义
                                        </div>
                                    </div>
                                )
                            }
                    </div>
                    <div className={styles.right_main_list}>
                        <div className={styles.right_main_list_title} onClick={(e: any) => {this.onSwicth(e,'merber_assets_open')}}>
                            <div className={`${styles.right_main_list_title_name}`}>
                                会员资产
                                {
                                    merber_assets_open === 0
                                    ?   <img src={set_down} className={`${styles.right_main_list_title_name_img}`} />
                                    :   <img src={set_right} className={`${styles.right_main_list_title_name_img}`} />
                                }
                            </div>
                            <div className={`${merber_assets === 1 ? styles.right_radio : styles.right_radioHide}`} onClick={(e: any) => {this.onSwicth(e,'merber_assets')}}>

                            </div>
                        </div>
                        {
                            merber_assets_open === 0 && (
                                <div className={styles.right_main_list_option}>
                                    <div className={styles.right_main_list_option_fixed}>
                                        固定样式暂不支持自定义
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <div className={styles.right_main_list}>
                        <div className={styles.right_main_list_title} onClick={(e: any) => {this.onSwicth(e,'merber_menu')}}>
                            <div className={`${styles.right_main_list_title_name}`}>
                                菜单
                                {
                                    merber_menu.status === 0
                                    ?   <img src={set_down} className={`${styles.right_main_list_title_name_img}`} />
                                    :   <img src={set_right} className={`${styles.right_main_list_title_name_img}`} />
                                }
                            </div>
                            {/* <div className={styles.right_radio}>

                            </div> */}
                        </div>
                        {
                            merber_menu.status === 0 && (
                                <div className={styles.right_main_list_option}>
                                    <div className={styles.right_main_list_option_item}>
                                        <div className={styles.right_main_list_option_item_name}>
                                            模块样式：
                                        </div>
                                        <div className={`${styles.right_main_list_option_item_info} ${styles.right_cursor}`} onClick={() => {this.updateObjProps('merber_menu','module_style',1)}}>
                                            <div className={`${merber_menu.module_style === 1 ? styles.right_radio : styles.right_radioHide} ${styles.right_cursor}`}></div>
                                            <div className={styles.right_radio_txt}>宫格式</div>
                                        </div>
                                        <div className={`${styles.right_main_list_option_item_info} ${styles.right_cursor}`} onClick={() => {this.updateObjProps('merber_menu','module_style',2)}}>
                                            <div className={`${merber_menu.module_style === 2 ? styles.right_radio : styles.right_radioHide} ${styles.right_cursor}`}></div>
                                            <div className={styles.right_radio_txt}>列表式</div>
                                        </div>
                                    </div>
                                    {
                                        merber_menu.module_style === 1
                                        ?   <div className={styles.right_main_list_option_item}>
                                                <div className={styles.right_main_list_option_item_name}>
                                                    列数：
                                                </div>
                                                <div className={`${styles.right_main_list_option_item_info} ${styles.right_cursor}`} onClick={() => {this.updateObjProps('merber_menu','module_column',3)}}>
                                                    <div className={`${merber_menu.module_column === 3 ? styles.right_radio : styles.right_radioHide} ${styles.right_cursor}`}></div>
                                                    <div className={styles.right_radio_txt}>三列</div>
                                                </div>
                                                <div className={`${styles.right_main_list_option_item_info} ${styles.right_cursor}`} onClick={() => {this.updateObjProps('merber_menu','module_column',4)}}>
                                                    <div className={`${merber_menu.module_column === 4 ? styles.right_radio : styles.right_radioHide} ${styles.right_cursor}`}></div>
                                                    <div className={styles.right_radio_txt}>四列</div>
                                                </div>
                                                <div className={`${styles.right_main_list_option_item_info} ${styles.right_cursor}`} onClick={() => {this.updateObjProps('merber_menu','module_column',5)}}>
                                                    <div className={`${merber_menu.module_column === 5 ? styles.right_radio : styles.right_radioHide} ${styles.right_cursor}`}></div>
                                                    <div className={styles.right_radio_txt}>五列</div>
                                                </div>
                                            </div>
                                        :   null
                                    }
                                    <div className={styles.right_main_list_option_item}>
                                        <div style={{width: '100%'}}>
                                            <div className={styles.right_main_list_option_item_name}>
                                                编辑菜单：
                                            </div>
                                            <Table
                                                pagination={false}
                                                className={styles.right_main_list_option_item_table}
                                                dataSource={merber_menu.module_list} 
                                                columns={this.columns} 
                                                rowKey={"index"} 
                                                locale={{emptyText: '暂无数据'}}
                                                components={{
                                                    body: {
                                                        wrapper: DraggableContainer,
                                                        row: this.DraggableBodyRow,
                                                    },
                                                }}
                                            />
                                            <div className={styles.right_main_list_option_item_add}>
                                                <div className={styles.right_main_list_option_item_add_btn} onClick={() => {this.onAddNavigation()}}>
                                                    添加导航
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default DecorationRight