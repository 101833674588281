import React from 'react';
import styles from './material.module.scss'
import { Input,Table,Tooltip,Modal, Button, message } from 'antd';
import {getRequest, postRequest, requestWithJson,getRequestWithoutShopId} from "../../api/http";
import {apiUrl} from "../../api/api_url";

import btn_add from '../../assret/images/btn_add.png'
import t_delete from '../../assret/images/t_delete.png'
import t_edit from '../../assret/images/t_edit.png'

const { Search } = Input;

class MaterialManage extends React.Component<any> {
    state = {
        dataList:[], // 加料数据
        currentPage:1, // 当前页面索引
        pageSize:10, // 每页数量
        count:0, // 加料数据总数量
        addVisible:false, // 是否展示添加加料
        materialName:"", // 加料名称
        materialPrice:"", // 加料价格
        selIds:[], // 选择ids
        deleteVisible:false, // 删除模态框是否可见
        deleteSingleVisible:false, // 单个删除模态框是否可见
        editVisible:false, // 编辑模态框是否可见
        id:0, // 当前编辑id
        kw:"" // 搜索关键词

    }

    async componentDidMount(){
        // 加载页面数据
        this.getPageData()
    }

    // 添加加料
    addMaterial = ()=>{
        this.setState({
            addVisible:true
        })
    }

    // 批量删除加料
    deleteMaterial = async()=>{
        const ids = this.state.selIds
        // 删除数据
        if(ids.length == 0){
            message.error("请选择要删除数据")
            return 
        }
        // 展示删除提示按钮
        this.setState({
            deleteVisible: true,
          });
    }

    // 删除加料模态框ok btn
    deleteHandleOk = async()=>{
        const ids = this.state.selIds
        const page = this.state.currentPage
        const pageSize = this.state.pageSize
        // 删除数据
        await this.deleteShopMaterialReq(ids)
        // 更新页面数据
        await this.getPageData(page,pageSize)
        this.setState({
            deleteVisible: false,
          });
    }

    // 删除加料模态框取消 btn
    deleteHandleCancel = ()=>{
        this.setState({
            deleteVisible: false,
          });
    }

    // 单个删除加料模态框ok btn
    deleteSinlgeHandleOk = async()=>{
        const id = this.state.id
        const ids = [id]
        const page = this.state.currentPage
        const pageSize = this.state.pageSize
        // 删除数据
        await this.deleteShopMaterialReq(ids)

        // 更新页面数据
        await this.getPageData(page,pageSize)
        this.setState({
            deleteSingleVisible:false,
        })
    }

    // 删除加料模态框取消 btn
    deleteSinlgeHandleCancel = ()=>{
        this.setState({
            deleteSingleVisible:false,
          });
    }

    // 删除指定id数据
    deleteBtnClick = async(index:any)=>{
        // 当前页面
        const data = this.state.dataList
        const id = data[index]['id']
        this.setState({
            deleteSingleVisible:true,
            id
        })
    }


    // 编辑指定id数据
    editBtnClick = async(index:any)=>{
        const data = this.state.dataList
        const id = data[index]['id']
        const materialName = data[index]['material_name']
        const materialPrice = data[index]['material_price']
        this.setState({
            editVisible:true,
            id,
            materialName,
            materialPrice
        })
    }
    
    // 编辑加料模态框ok btn
    editHandleOk = async()=>{
        if(this.state.materialName == ""){
            message.error('名称不能为空');
        }
        // TODO 
        // 添加加料请求
        this.putShopMaterial();
        const page = this.state.currentPage
        const pageSize = this.state.pageSize
        // 更新页面数据
        this.getPageData(page,pageSize)
        this.setState({
            materialName:"",
            editVisible: false, // 
            materialPrice:"",
        })
    }

    // 编辑加料模态框取消 btn
    editHandleCancel = ()=>{
        this.setState({
            editVisible: false,
          });
    }

    
    // 添加加料模态框ok btn
    addHandleOk = async()=>{
        if(this.state.materialName == ""){
            message.error('名称不能为空');
        }
        // TODO 
        // 添加加料请求
        await this.postShopMaterial();
        const page = this.state.currentPage
        const pageSize = this.state.pageSize
        // 更新页面数据
        await this.getPageData(page,pageSize)
        
        this.setState({
            addVisible: false
        })
    }

    // 添加加料模态框ok btn
    AddhandleCancel = ()=>{
        this.setState({
            addVisible: false,
          });
    }

    // 添加并继续
    AddhandleContinue = async()=>{
        if(this.state.materialName == ""){
            message.error('名称不能为空');
        }
        // TODO 
        // 添加加料请求
        await this.postShopMaterial();
        this.setState({
            addVisible: true
        })

        const page = this.state.currentPage
        const pageSize = this.state.pageSize
        // 获取店铺加料列表数据
        // 更新页面数据
        this.getPageData(page,pageSize)
    }

    // 加料名称改变
    materialChangeName= (e:any)=>{
        const materialName = e.target.value
        this.setState({
            materialName
          });
    }

    // 加料价格改变
    materialChangePrice= (e:any)=>{
        const materialPrice = e.target.value
        this.setState({
            materialPrice
          });
    }

    // 搜索按钮
    searchBtn = async(val:any)=>{
        await this.getPageData(null,null,val)
        // this.setState({
        //     kw:""
        // })
    }

    searchInput = (e:any)=>{
        const kw = e.target.value
        this.setState({
            kw
        })
    }

    // 行选择
    rowSelection: any = {
        onChange: (selectedRowKeys: any, selectedRows: any) => {
            const selIds = this.state.selIds         
            this.setState({
                selIds:selectedRowKeys
            })
        }
    }

    selOnChange = (selectedRowKeys: any, selectedRows: any)=>{
        const selIds = this.state.selIds
        this.setState({
            
        })
    }

    // 改变列表页面索引
    pageChange = async(page:number)=>{
        const pageSize = this.state.pageSize
        // 获取店铺加料列表数据
        // 更新页面数据
        this.getPageData(page,pageSize)
        this.setState({
            currentPage:page
        })
    }

    // 改变每页数量
    onSizeChange = async()=>{
        const pageSize = this.state.pageSize
        const page = 1
        // 更新页面数据
        this.getPageData(page,pageSize)
        this.setState({
            currentPage:page,
        })
    }

    

    

    // 获取店铺加料列表
    getShopMaterialList = async(page?:any,pageSize?:any,kw?:any)=>{
        const res:any = await getRequest(apiUrl.getMaterialList,{"page":page,"pagesize":pageSize,'kw':kw})
        if(res.code === 1){
            const data:any = res.data
            this.setState({
                dataList:data['list'], // 加料列表数据
                count:data['count'], // 加料总条数
            })
           return res.data
        }else{
            message.error("服务器请求数据失败")
            return 
        }
    }

    // 删除加料
    deleteShopMaterialReq = async (ids:any)=>{
        const res:any = await requestWithJson("delete", apiUrl.deleteMaterial,null,{ids:ids})
        if(res.code === 1){
           return res.data
        }else{
            message.error("删除失败")
            return 
        }
    }
    // 添加加料
    postShopMaterial = async ()=>{
        // 加料名称
        const materialName = this.state.materialName
        const materialPrice = this.state.materialPrice
        const res:any = await postRequest(apiUrl.addMaterial,{"material_name":materialName,"material_price":materialPrice})
        if(res.code === 1){
            this.setState({
                // addVisible: false, //
                materialName:"",
                materialPrice:"",
            })
           return res.data
        }else{
            message.error(res.message)
            return 
        }
    }

    // 编辑加料数据
    putShopMaterial = async ()=>{
        const id = this.state.id
        const materialName = this.state.materialName
        const materialPrice = this.state.materialPrice
        const res:any = await requestWithJson("put", apiUrl.editMaterial,null,{id:id,material_name:materialName,material_price:materialPrice})
        if(res.code === 1){
           return res.data
        }else{
            message.error("删除失败")
            return 
        }
    }

    // 更新页面数据
    getPageData = async(page?:any,pageSize?:any,kw?:any)=>{
        const materialList = await this.getShopMaterialList(page,pageSize,kw)
        this.setState({
            dataList:materialList['list'], // 加料列表数据
            count:materialList['count'], // 加料总条数
        })
    }
    render(){
        const columns = [
            {
              title: '加料名称',
              dataIndex: 'material_name',
              width: 300,
            },
            {
              title: '价格',
              dataIndex: 'material_price',
            },
            {
                title: '操作',
                width: 100,
                dataIndex: 'action',
                render: (text: any, record: any,index: number) => (
                    <div className="btn_wrap">
                        <Tooltip placement="bottomRight" title={'编辑'}>
                            <img src={t_edit} className='table_icon' onClick={()=>this.editBtnClick(index)} ></img>
                        </Tooltip>
                        <Tooltip placement="bottomRight" title={'删除'}>
                            <img src={t_delete} className='table_icon' onClick={()=>this.deleteBtnClick(index)} ></img>
                        </Tooltip>
                    </div>
                ),
            }
          ];

        return <div>
            <div className={styles.material_header}>
                <div className={styles.material_header_left}>
                    <div className={styles.add_btn_material} onClick={this.addMaterial}>
                        <img src={btn_add} alt=""/>
                        添加加料</div>
                    <div className={styles.delete_btn_material} onClick={this.deleteMaterial} >
                        <img src={t_delete} alt=""/>
                        批量删除</div>
                </div>

                <div className={styles.material_header_right}>
                    <div className={styles.search_btn}>
                        <Search
                            placeholder="请输入名称"
                            value= {this.state.kw}
                            allowClear = {true}
                            onChange = {(e)=>{this.searchInput(e)}}
                            onSearch={(value)=>{this.searchBtn(value)}}
                            style={{ width: 259 }}
                        />
                    </div>
                </div>
            </div>
        
            <div className={styles.material_center}>
                <Table
                    rowSelection={this.rowSelection}
                    dataSource={this.state.dataList}
                    columns={columns}
                    rowKey={"id"}
                    size="middle"
                    locale={{ emptyText: '暂无数据' }}
                    pagination={{
                        current: this.state.currentPage,
                        defaultCurrent: 1,
                        total: this.state.count,
                        pageSize: this.state.pageSize,
                        showQuickJumper: true,
                        showSizeChanger: true,
                        onChange :this.pageChange,
                        showTotal: (total: number) => `共${total}条`,
                        onShowSizeChange: (current:number, size: number) => {this.setState({pageSize: size}, () => {this.onSizeChange();})}
                    }} 
                />              
            </div>
            
            <div>
                <Modal
                    title="添加加料"
                    visible={this.state.addVisible}
                    onOk={this.addHandleOk}
                    onCancel={this.AddhandleCancel}
                    className={"material_list_modal"}
                    footer={null}
                    >
                        <div className={styles.material_input}>
                            <div className={styles.material_input_title} >
                                加料名称：
                            </div>
                            <Input placeholder="请输入配料名称"  value={this.state.materialName} onChange={(e)=>{this.materialChangeName(e)}}/>
                        </div>
                        <div className={styles.material_input}>
                        <div className={styles.material_input_title} >
                            售价：
                            </div>
                            <Input placeholder="请输入配料售价" value={this.state.materialPrice}  type="number" onChange={(e)=>{this.materialChangePrice(e)}}/>
                        </div>
                        <div className={styles.input_btn}>
                            <div className={styles.input_btn_save} onClick={this.addHandleOk}>
                                <Button type="primary">保存</Button>
                            </div>
                            <div className={styles.input_btn_save_con} onClick={this.AddhandleContinue} >
                                <Button type="primary">保存并继续</Button>
                            </div>
                            <div className={styles.input_btn_cancel} onClick={this.AddhandleCancel}>
                                <Button >取消</Button>
                            </div>
                        </div>
                </Modal>
            </div>

            <div>
                <Modal
                    title="批量删除加料"
                    visible={this.state.deleteVisible}
                    onOk={this.deleteHandleOk}
                    onCancel={this.deleteHandleCancel}
                    className={"material_list_modal"}
                    footer={null}
                    >
                        <p>确认删除选中的加料？</p>
                    <div className={styles.delete_btn_modal}>
                            <div className={styles.delete_btn_cancel} onClick={this.deleteHandleCancel}>
                                <Button >取消</Button>
                            </div>
                            <div className={styles.delete_btn_save} onClick={this.deleteHandleOk}>
                                <Button type="primary">确定</Button>
                            </div>
                    </div>
                </Modal>
            </div>

            <div>
                <Modal
                    title="删除加料"
                    visible={this.state.deleteSingleVisible}
                    onOk={this.deleteSinlgeHandleOk}
                    onCancel={this.deleteSinlgeHandleCancel}
                    className={"material_list_modal"}
                    footer={null}
                    >
                        <p>确认删除该加料？</p>
                    <div className={styles.delete_btn_modal}>
                            <div className={styles.delete_btn_cancel} onClick={this.deleteSinlgeHandleCancel}>
                                <Button >取消</Button>
                            </div>
                            <div className={styles.delete_btn_save} onClick={this.deleteSinlgeHandleOk}>
                                <Button type="primary">确定</Button>
                            </div>
                    </div>
                </Modal>
            </div>
        
            <div>
                <Modal
                    title="编辑加料"
                    visible={this.state.editVisible}
                    onOk={this.editHandleOk}
                    onCancel={this.editHandleCancel}
                    className={"material_list_modal"}
                    footer={null}
                    >
                        <div className={styles.material_input}>
                            <div className={styles.material_input_title} >
                                加料名称：
                            </div>
                            <Input placeholder="请输入配料名称"  value={this.state.materialName} onChange={(e)=>{this.materialChangeName(e)}}/>
                        </div>
                        <div className={styles.material_input}>
                        <div className={styles.material_input_title} >
                            售价：
                            </div>
                            <Input placeholder="请输入配料售价" value={this.state.materialPrice}  type="number" onChange={(e)=>{this.materialChangePrice(e)}}/>
                        </div>
                        <div className={styles.edit_btn_modal}>
                            <div className={styles.edit_btn_save} onClick={this.editHandleOk}>
                                <Button type="primary">保存</Button>
                            </div>
                            <div className={styles.edit_btn_cancel} onClick={this.editHandleCancel}>
                                <Button >取消</Button>
                            </div>
                        </div>
                </Modal>
            </div>
        
        </div>

    }
}

export default MaterialManage