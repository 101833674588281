import { Row, Col, DatePicker, message, Select } from 'antd';
import React, { Component } from 'react';
import LineChart from './components/lineChart';
import LineChartActive from './components/lineChartActive';
import InsideNav from '../../component/insideNav/insideNav';
import ComsumeBehavior from './components/comsumeBehavior';
import ArcProgress from './components/arcProgress';

import styles from './pullNewOperation.module.scss';

import system from '../../assret/images/system.png';
import { apiUrl } from '../../api/api_url';
import { getRequest } from '../../api/http';

const { RangePicker } = DatePicker;
const { Option } = Select;

const nowDate = new Date();
const yesterday = new Date(nowDate.getTime() - 24 * 60 * 60 * 1000)
const beforeYesterday = new Date(nowDate.getTime() - 24 * 2 * 60 * 60 * 1000)

var now:any = new Date(); //当前日期
var nowDayOfWeek = now.getDay(); //今天本周的第几天
var nowDay = now.getDate(); //当前日
var nowMonth = now.getMonth(); //当前月
var nowYear = now.getYear(); //当前年
nowYear += (nowYear < 2000) ? 1900 : 0; //
var lastMonthDate:any = new Date(); //上月日期
lastMonthDate.setDate(1);
lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);
var lastYear = lastMonthDate.getYear();
var lastMonth = lastMonthDate.getMonth();

interface IProps {
	history?: any
}

  interface IState {
	top_icon_list: any,
	recommend_icon_list: any,

}
class PullNewOperation extends Component<IProps, any> {

	constructor(props: IProps) {
		super(props);
		this.state = {
			top_icon_list: [
				{
					title: '总新增人数',
					rate: 0,
                    icon: 'https://ydn-siyu.cdn.shuachi.com/deep_user_total.png'
				},
				{
					title: '拉新转化率',
					rate: 0,
                    icon: 'https://ydn-siyu.cdn.shuachi.com/repurchase.png'
				},
			],
			recommend_icon_list: [
				{
					id: 1,
					name: '用户等级',
					active: true,
				},
				{
					id: 2,
					name: '消费次数',
					active: false,
				},
				{
					id: 3,
					name: '消费月份',
					active: false,
				},
				{
					id: 4,
					name: '消费行为',
					active: false,
				},
				{
					id: 5,
					name: '就餐月份',
					active: false,
				}
			],
			recommend_icon_idx: 1,
			totalUser: 0,//总用户
			newUser: 0,//新用户
			vipUser: 0,//vip用户
			newVip: 0,//新vip
			singlePrice: 0,//平均桌单价
			priceTrend: 0,//价格趋势
			rechargeAmount: "0.00",//充值金额
			presentAmount: "0.00",//充值赠送金额
			start_at: '',
			end_at: '',
            currentTitleIdx: 1,
            navList: [
                {
                    title: '拉新活动分析',
                    isActive: true,
                    KEY: "USER_DATA",
                    uni_key: '1_6_00'
                },
                {
                    title: '拉新用户分析',
                    isActive: false,
                    KEY: "USER_LIST",
                    uni_key: '1_6_1'
                },
            ],
            operation_line_color: ['#FF5369','#6F74FF','#FF6BEE','#00E641','#FFD55C','#71d9fc','#ff8b5e'],//折线图颜色
            current_tabble: 1,
            operation_line_color_content: [], //复购活动分析
            operation_line_color_content_active: [], // 复购用户分析
            xLine: [],//复购活动分析
            xLine_active: [],// 复购用户分析
            legend: [],
            legend_active: [],
            month_info: {
                user: '',
            },
            group_pie: [], //饼图数据
            from_type: [], //拉新活动配置
            nearly_year: [], //最近一年的，年月
            own_all_data: {},
            group_loading: false,
            progress_loading: false,
		}
	}

	componentDidMount() {
        this.getTimeData('today')
        this.onNearlyAYear(); //折线图月份
        this.onRePurchaseChart({
            st: nowYear + '-' + this.formatData(nowMonth+1) + '-' + this.formatData(nowDay),
            et: nowYear + '-' + this.formatData(nowMonth+1) + '-' + this.formatData(nowDay)
        }); //折线图数据
    }

	//
	_initDate = async (type: any) => {
		let params = {
            st: this.formatDate(Number(type)),
            et: this.formatDate(0)
		}
        if(type === 1) {
            params.st = this.formatDate(Number(type))
		}
		if(type == 2) {
			params.st= this.state.start_at;
            params.et = this.state.end_at;
        }
        this.setState({
            group_loading: false,
            progress_loading: false
        })
		let res:any = await getRequest(apiUrl.pullnew, params);
		if(res.code == 1) {
            let { totalUser, newUser, vipUser, newVip, singlePrice, priceTrend, rechargeAmount, presentAmount } = res.data;
            let operation_line_color: any = this.state.operation_line_color;
            let current_tabble: any = this.state.current_tabble;
            let top_icon_list: any = [];
            let obj_one: any = {
                title: '总新增人数',
                icon: 'https://ydn-siyu.cdn.shuachi.com/deep_user_total.png',
                rate: res.data.new_count
            }
            let obj_two: any = {
                title: '拉新转化率',
                icon: 'https://ydn-siyu.cdn.shuachi.com/repurchase.png',
                rate: res.data.pull_new_rate
            }
            top_icon_list = [obj_one,obj_two]

            this.setState({
                top_icon_list,
                group_pie: [res.data.group_pie,res.data.activity],
                from_type: res.data.from_type,
                own_all_data: {'pull_rate': Number(res.data.pull_rate) >= 100 ? 100 : Number(res.data.pull_rate), 'pull_new_count': Number(res.data.pull_new_count)},
                month_info: {
                    user: Number(res.data.new_count)
                },
                progress_loading: true
            }, () => {
                this.setState({
                    group_loading: true
                })
            })
		} else {
			message.error(res.message)
		}
    }
    
    //拉新活动折线图
    onRePurchaseChart = async (dateObj: any) => {
        let params = {
            ...dateObj
        }
        let res:any = await getRequest(apiUrl.pullnewline, params);
		if(res.code == 1) {
            let operation_line_color: any = this.state.operation_line_color;

            //处理拉新活动折线图
            let _data_list = res.data;
            let yData: any = []; //拉新活动折线图
            let legend: any = []; //拉新活动折线图
            _data_list.line_data.map((item: any, index: any) => {
                let obj: any = {};
                obj.name = item.name;
                obj.type = 'line';
                obj.data = item.data;
                obj.areaStyle = {type: 'default',color:operation_line_color[index]};//内容颜色
                obj.symbol = 'none'; // 拐点类型
                obj.smooth = true;
                obj.color = operation_line_color[index];
                legend.push(item.name);
                yData.push(obj)
            })

            this.setState({
                operation_line_color_content: yData,
                operation_line_color_content_active: [
                    {
                        name : '本月拉新人数',
                        type : 'line',
                        data : res.data.month,
                        areaStyle : {type: 'default',color:operation_line_color[0]},//内容颜色
                        symbol : 'none', // 拐点类型
                        smooth : true,
                        color : operation_line_color[0]
                    },
                    {
                        name : '上月拉新人数',
                        type : 'line',
                        data : res.data.last_month,
                        areaStyle : {type: 'default',color:operation_line_color[1]},//内容颜色
                        symbol : 'none', // 拐点类型
                        smooth : true,
                        color : operation_line_color[1]
                    }
                ],
                xLine: res.data.xAxis,
                xLine_active: res.data.xAxis,
                legend: legend,
                legend_active: ['本月拉新人数','上月拉新人数'],
            })
		} else {
			message.error(res.message)
		}
    }

	//时间处理函数
    formatDate = (type: number) => {
        let date = new Date();
        let year:any = '';
        let month:any = '';
        let day:any = '';
        if(type === 1) {
            date.setTime(date.getTime()-24*60*60*1000);
            year = date.getFullYear();
            month = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : '0' + (date.getMonth() + 1);
            day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();

        } else if(type === 7) {
            date.setTime(date.getTime()-((24*60*60*1000) * 6));
            year = date.getFullYear();
            month = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : '0' + (date.getMonth() + 1);
            day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();

        } else {
            year = date.getFullYear();
            month = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : '0' + (date.getMonth() + 1);
            day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();
        }

        return year + '-' + month + '-' + day
    }

    //选择时间
    onChangeTime = async (start_time: any, end_time: any) => {
        this.setState({
            start_at: start_time,
            end_at: end_time
        }, () => {
            this._initDate(2)
        })
		// console.log(start_time, end_time)
		// let params = {
		// 	st: start_time,
        //     et: end_time
		// }
		// if(!start_time) {
		// 	params.st = this.formatDate(0)
        //     params.et = this.formatDate(0)
		// }
		// let res:any = await getRequest(apiUrl.pullnew, params);
		// if(res.code == 1) {
        //     let { totalUser, newUser, vipUser, newVip, singlePrice, priceTrend, rechargeAmount, presentAmount } = res.data;
        //     let operation_line_color: any = this.state.operation_line_color;
        //     let current_tabble: any = this.state.current_tabble;
        //     let top_icon_list: any = [];
        //     let obj_one: any = {
        //         title: '总新增人数',
        //         icon: 'https://ydn-siyu.cdn.shuachi.com/deep_user_total.png',
        //         rate: res.data.new_count
        //     }
        //     let obj_two: any = {
        //         title: '拉新转化率',
        //         icon: 'https://ydn-siyu.cdn.shuachi.com/deep_average_price.png',
        //         rate: res.data.pull_new_count
        //     }
        //     top_icon_list = [obj_one,obj_two]

        //     //处理拉新活动折线图
        //     let _data_list = res.data;
        //     let yData: any = []; //拉新活动折线图
        //     let legend: any = []; //拉新活动折线图
        //     // _data_list.line_data.map((item: any, index: any) => {
        //     //     let obj: any = {};
        //     //     obj.name = item.name;
        //     //     obj.type = 'line';
        //     //     obj.data = item.data;
        //     //     obj.areaStyle = {type: 'default',color:operation_line_color[index]};//内容颜色
        //     //     obj.symbol = 'none'; // 拐点类型
        //     //     obj.smooth = true;
        //     //     obj.color = operation_line_color[index];
        //     //     legend.push(item.name);
        //     //     yData.push(obj)
        //     // })

        //     this.setState({
        //         operation_line_color_content: yData,
        //         operation_line_color_content_active: [
        //             {
        //                 name : '本月拉新人数',
        //                 type : 'line',
        //                 data : res.data.month,
        //                 areaStyle : {type: 'default',color:operation_line_color[0]},//内容颜色
        //                 symbol : 'none', // 拐点类型
        //                 smooth : true,
        //                 color : operation_line_color[0]
        //             },
        //             {
        //                 name : '上月拉新人数',
        //                 type : 'line',
        //                 data : res.data.last_month,
        //                 areaStyle : {type: 'default',color:operation_line_color[1]},//内容颜色
        //                 symbol : 'none', // 拐点类型
        //                 smooth : true,
        //                 color : operation_line_color[1]
        //             }
        //         ],
        //         xLine: res.data.xAxis,
        //         xLine_active: res.data.xAxis,
        //         legend: legend,
        //         legend_active: ['本月拉新人数','上月拉新人数'],
        //         top_icon_list,
        //         group_pie: [res.data.group_pie,res.data.activity]
        //     })
		// } else {
		// 	message.error(res.message)
		// }
	}
	
	//推荐分组切换
	onGroupRecommend = (item: any, index: any) =>{
		let recommend_icon_list: any = this.state.recommend_icon_list;
		recommend_icon_list.map((sub_item: any, sub_index: any) => {
			if(item.id == sub_item.id) {
				recommend_icon_list[index]['active'] = true;
			} else {
				recommend_icon_list[sub_index]['active'] = false;
			}
		})
		this.setState({
			recommend_icon_idx: item.id,
			recommend_icon_list
		})
		
	}

	handleChange = (value: any) => {
        this._initDate(Number(value))
	}
	
	getTimeData = (type: "today" | "yesterday" | 'beforeYesterday' | 'beforeMonth' | 'beforeWeek' | 'currentMonth' | 'own' | 'quarter' | 'lastQuarter' | 'ownMonth') => {
        switch (type) {
            case 'currentMonth':
                let currMonthStart = `${nowDate.getFullYear()}-${this.formatData(nowDate.getMonth() + 1)}-01`;
                let currMonthEnd = `${nowDate.getFullYear()}-${this.formatData(nowDate.getMonth() + 1)}-${this.formatData(nowDate.getDate())}`;
                this.setState({
                    start_at: currMonthStart,
                    end_at: currMonthEnd,
                }, () => {
                    this._initDate(2);
                })
                break;
            case "beforeWeek":
                var d = new Date();
                var end = d.getTime() - 1000 * 60 * 60 * 24 * d.getDay();
                let start = d.getTime() - 1000 * 60 * 60 * 24 * (d.getDay() + 6)
                let startDay = new Date(start).getFullYear() + '-' + this.formatData(new Date(start).getMonth() + 1) + '-' + this.formatData(new Date(start).getDate());
                let endDay = new Date(end).getFullYear() + '-' + this.formatData(new Date(end).getMonth() + 1) + '-' + this.formatData(new Date(end).getDate());

                this.setState({
                    start_at: startDay,
                    end_at: endDay
                }, () => {
                    this._initDate(2);
                })
                break;
            case "beforeMonth":
                let beforeMonthStart = nowDate.getFullYear() + '-' + this.formatData(nowDate.getMonth()) + '-01';
                let currentMonth = nowDate.getFullYear() + '-' + this.formatData(nowDate.getMonth() + 1) + '-01'
                let num = new Date(new Date(currentMonth).getTime() - 24 * 60 * 60 * 1000);
                let beforeMonthEnd = `${num.getFullYear()}-${this.formatData(num.getMonth() + 1)}-${this.formatData(num.getDate())}`;
                if(nowDate.getMonth() == 0){
                    beforeMonthStart = (nowDate.getFullYear()-1) + '-12-01'
                }
                this.setState({
                    start_at: beforeMonthStart,
                    end_at: beforeMonthEnd
                }, () => {
                    this._initDate(2);
                })
                break;
            case "beforeYesterday":
                let beforeYesDay = `${beforeYesterday.getFullYear()}-${this.formatData(beforeYesterday.getMonth() + 1)}-${this.formatData(beforeYesterday.getDate())}`;
                this.setState({
                    start_at: beforeYesDay,
                    end_at: beforeYesDay
                }, () => {
                    this._initDate(2);
                })
                break;
            case "yesterday":
                let yesDay = `${yesterday.getFullYear()}-${this.formatData(yesterday.getMonth() + 1)}-${this.formatData(yesterday.getDate())}`;
                this.setState({
                    start_at: yesDay,
                    end_at: yesDay
                }, () => {
                    this._initDate(2);
                })
                break;

            case "today":
                let toDay = `${nowDate.getFullYear()}-${this.formatData(nowDate.getMonth() + 1)}-${this.formatData(nowDate.getDate())}`;
                this.setState({
                    start_at: toDay,
                    end_at: toDay,
                }, () => {
                    this._initDate(2);
				})
				break;
			case "own":
				let own = `${nowDate.getFullYear()}-${this.formatData(nowDate.getMonth() + 1)}-${this.formatData(nowDate.getDate())}`;
				this.setState({
					start_at: '1970-01-01',
                    end_at: own,
                    // start_at: '2020-09-01',
                    // end_at: '2020-09-01',
				}, () => {
                    this._initDate(2);
				})
				break;
			case "quarter":
				this.setState({
					start_at: this.getQuarterStartDate(),
					end_at: this.getQuarterEndDate(),
				}, () => {
                    this._initDate(2);
				})
				break;
			case "lastQuarter":
				this.setState({
					start_at: this.getLastQuarterStartDate(),
					end_at: this.getLastQuarterEndDate(),
				}, () => {
                    this._initDate(2);
				})
				break;
			case "ownMonth":
				this.setState({
					start_at: this.getLastMonth().last,
					end_at: this.getLastMonth().now,
				}, () => {
                    this._initDate(2);
				})
				break;
        }
	}
	
	//近一个月
	getLastMonth() {
		var now:any = new Date();
		var year = now.getFullYear();
		var month = now.getMonth() + 1;//0-11表示1-12月
		var day = now.getDate();
		var dateObj: any = {};
		dateObj.now = year + '-' + month + '-' + day; 
		var nowMonthDay = new Date(year, month, 0).getDate();    //当前月的总天数
		if(month - 1 <= 0){ //如果是1月，年数往前推一年<br>　　　　 
			dateObj.last = (year - 1) + '-' + 12 + '-' + day;
		}else{
			var lastMonthDay = new Date(year, (parseInt(month) - 1), 0).getDate();  
			if(lastMonthDay < day){    //1个月前所在月的总天数小于现在的天日期
				if(day < nowMonthDay){        //当前天日期小于当前月总天数
					dateObj.last = year + '-' + (month - 1) + '-' + (lastMonthDay - (nowMonthDay - day));
				}else{
					dateObj.last = year + '-' + (month - 1) + '-' + lastMonthDay;
				}
			}else{
				dateObj.last = year + '-' + (month - 1) + '-' + day;
			}
		}
		return dateObj;
    }
    
    //获取最近一年的，年月
    onNearlyAYear() {
        let nearly_year: any = [];
        let now: any = new Date();
        let year: any = now.getFullYear();
        let month = now.getMonth() + 1;//0-11表示1-12月
        for(let i = 0; i <= 11; i++) {
            if(month == 12) {
                nearly_year.push(`${year}年${this.formatData(12 - i)}月`)
            } else {
                if(month - i > 0) {
                    nearly_year.push(`${year}年${this.formatData(month - i)}月`)
                } else {
                    let upYear: any = year - 1;
                    nearly_year.push(`${upYear}年${this.formatData(i - month == 0 ? 12 : i - month)}月`)
                }
                
            }
        }
        this.setState({
            nearly_year
        })
    }

	//获得本季度的开始月份
	getQuarterStartMonth() {
		var quarterStartMonth = 0;
		if (nowMonth < 3) {
			quarterStartMonth = 0;
		}
		if (2 < nowMonth && nowMonth < 6) {
			quarterStartMonth = 3;
		}
		if (5 < nowMonth && nowMonth < 9) {
			quarterStartMonth = 6;
		}
		if (nowMonth > 8) {
			quarterStartMonth = 9;
		}
		return quarterStartMonth;
	}

	//获得上季度的开始月份
	getLastQuarterStartMonth() {
		var quarterStartMonth = 0;
		if (nowMonth < 3) {
			quarterStartMonth = 0;
		}
		if (2 < nowMonth && nowMonth < 6) {
			quarterStartMonth = 3;
		}
		if (5 < nowMonth && nowMonth < 9) {
			quarterStartMonth = 6;
		}
		if (nowMonth > 8) {
			quarterStartMonth = 9;
		}
		return quarterStartMonth;
	}

	//获得上季度季度的开始日期
	getLastQuarterStartDate() {
		var quarterStartDate = new Date(nowYear, this.getLastQuarterStartMonth() - 2, 1);
		return this.newFormatDate(quarterStartDate);
	}

	//获得上季度的结束日期
	getLastQuarterEndDate() {
		var quarterEndMonth = this.getLastQuarterStartMonth();
		var quarterStartDate = new Date(nowYear, quarterEndMonth,this.getMonthDays(quarterEndMonth));
		return this.newFormatDate(quarterStartDate);
	}

	//获得本季度的开始日期
	getQuarterStartDate() {
		var quarterStartDate = new Date(nowYear, this.getQuarterStartMonth(), 1);
		return this.newFormatDate(quarterStartDate);
	}

	//或的本季度的结束日期
	getQuarterEndDate() {
		var quarterEndMonth = this.getQuarterStartMonth() + 2;
		var quarterStartDate = new Date(nowYear, quarterEndMonth,this.getMonthDays(quarterEndMonth));
		return this.newFormatDate(quarterStartDate);
	} 

	//获得某月的天数
	getMonthDays(myMonth: any) {
		var monthStartDate: any = new Date(nowYear, myMonth, 1);
		var monthEndDate: any = new Date(nowYear, myMonth + 1, 1);
		var days = (monthEndDate - monthStartDate) / (1000 * 60 * 60 * 24);
		return days;
	}

	newFormatDate(date: any) {
		var myyear = date.getFullYear();
		var mymonth = date.getMonth() + 1;
		var myweekday = date.getDate();
		if (mymonth < 10) {
			mymonth = "0" + mymonth;
		}
		if (myweekday < 10) {
			myweekday = "0" + myweekday;
		}
		return (myyear + "-" + mymonth + "-" + myweekday);
	}

    formatData = (num: number) => {
      return num > 9 ? num : `0${num}`
    }

    navCallBack(currentKey: string){
        console.log(currentKey)
        if(currentKey == 'USER_LIST') {
            this.setState({
                current_tabble: 2
            })
        } else {
            this.setState({
                current_tabble: 1
            })
        }
    }

    handleChangeLineDate = (value: any) => {
        let date_obj: any = (value.replace(/([^\u0000-\u00FF])/g, '-')) + '01';
        date_obj = {
            st: date_obj,
            et: date_obj
        }
        this.onRePurchaseChart(date_obj);
    }


    render() {

		let { progress_loading, group_loading, own_all_data, nearly_year, from_type, group_pie, month_info, current_tabble, legend, legend_active, operation_line_color, operation_line_color_active, xLine, xLine_active, navList, operation_line_color_content, operation_line_color_content_active, currentTitleIdx, singlePrice, rechargeAmount, recommend_icon_idx, top_icon_list, recommend_icon_list } = this.state;

        return (
            <Row className={styles.pullNewOperation}>
                <Col className={styles.pullNewOperation_userdate}>拉新运营</Col>
                <Col className={styles.pullNewOperation_select}>
                    <div className={styles.pullNewOperation_select_left}>
                        <div className={`${styles.pullNewOperation_select_left_near} ${currentTitleIdx == 1 ? styles.user_ifon_active : ''}`} onClick={() => {this.handleChange('0');this.setState({currentTitleIdx: 1})}}>今日</div>
                        <div className={`${styles.pullNewOperation_select_left_near} ${currentTitleIdx == 7 ? styles.user_ifon_active : ''}`} onClick={() => {this.getTimeData('yesterday');this.setState({currentTitleIdx: 7})}}>昨日</div>
                        <div className={`${styles.pullNewOperation_select_left_near} ${currentTitleIdx == 2 ? styles.user_ifon_active : ''}`} onClick={() => {this.handleChange('7');this.setState({currentTitleIdx: 2})}}>近七天</div>
						<div className={`${styles.pullNewOperation_select_left_near} ${currentTitleIdx == 3 ? styles.user_ifon_active : ''}`} onClick={() => {this.getTimeData('ownMonth');this.setState({currentTitleIdx: 3})}}>近30天</div>
						<div className={`${styles.pullNewOperation_select_left_near} ${currentTitleIdx == 4 ? styles.user_ifon_active : ''}`} onClick={() => {this.getTimeData("currentMonth");this.setState({currentTitleIdx: 4})}}>本月</div>
						<div className={`${styles.pullNewOperation_select_left_near} ${currentTitleIdx == 5 ? styles.user_ifon_active : ''}`} onClick={() => {this.getTimeData("beforeMonth");this.setState({currentTitleIdx: 5})}}>上月</div>
						<div className={`${styles.pullNewOperation_select_left_near} ${currentTitleIdx == 6 ? styles.user_ifon_active : ''}`} onClick={() => {this.getTimeData('quarter');this.setState({currentTitleIdx: 6})}}>本季度</div>
                        <div className={styles.pullNewOperation_select_left_selecttime}>
                            <RangePicker
                                format={`YYYY-MM-DD`}
                                showTime={false}
                                onChange={(e:any, dateString) => {
                                    this.onChangeTime(dateString[0], dateString[1]);
                                }}
                                placeholder={['开始时间', '结束时间']} />
                        </div>
                    </div>
                </Col>
                <Col className={styles.pullNewOperation_panel}>
					<div className={styles.pullNewOperation_panel_topLeft}>
                        {
                            top_icon_list.map((item: any, index: any) => {
                                return (
                                    <div key={item + index} className={styles.pullNewOperation_panel_item}>
                                        <div className={styles.pullNewOperation_panel_item_top}>
                                            <div className={styles.pullNewOperation_panel_item_top_left}>
                                                <div className={styles.pullNewOperation_panel_item_top_left_user}>{item.title}</div>
                                                <div className={styles.pullNewOperation_panel_item_top_left_num}>{item.rate}</div>
                                            </div>
                                            <div className={styles.pullNewOperation_panel_item_top_right}><img src={item.icon} className={styles.pullNewOperation_panel_item_top_right_img} /></div>
                                        </div>
                                        <div className={styles.pullNewOperation_panel_item_bottom}>
                                            <span className={styles.pullNewOperation_panel_item_bottom_txt}></span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {/*  拉新顾客 */}
                    <div className={styles.pullNewOperation_panel_topcontent}>
                        <div className={styles.pullNewOperation_panel_topcontent_item}>
                            <div className={styles.pullNewOperation_panel_topright_item_title}>
                                拉新人数
                            </div>
                            <div className={styles.pullNewOperation_panel_topright_item_progress}>
                                { progress_loading && <ArcProgress type={1} target={own_all_data.pull_rate} value={own_all_data.pull_new_count}></ArcProgress>}
                            </div>
                        </div>
                    </div>
                    {/*  拉新活动配置 */}
                    <div className={styles.pullNewOperation_panel_topright}>
                        <div className={styles.pullNewOperation_panel_topright_item}>
                            <div className={styles.pullNewOperation_panel_topright_item_title}>
                                拉新活动配置
                            </div>
                            <div className={styles.pullNewOperation_panel_topright_item_main}>
                                {
                                    from_type.map((item: any, index: any) => {
                                        return (
                                            <div className={styles.pullNewOperation_panel_topright_item_main_list} key={item.from_type + index}>
                                                <div className={styles.pullNewOperation_panel_topright_item_main_list_left}>
                                                    <div className={styles.pullNewOperation_panel_topright_item_main_list_left_title}>{item.activity}</div>
                                                    <div className={styles.pullNewOperation_panel_topright_item_main_list_left_desc}>{item.desc}</div>
                                                </div>
                                                <div className={styles.pullNewOperation_panel_topright_item_main_list_right} onClick={() => {this.props.history.push(`${item.path}`)}}>
                                                    <img src={system} className={styles.pullNewOperation_panel_topright_item_main_list_right_img}></img>
                                                    <div className={styles.pullNewOperation_panel_topright_item_main_list_right_system}>进入设置</div>
                                                </div>
                                            </div>            
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </Col>
                {/* 折线图 */}
                <Col className={styles.pullNewOperation_lineChart}>
                    <div className={styles.pullNewOperation_lineChart_tabbar}>
                        <InsideNav navList={navList} callBack={(key: string) =>this.navCallBack(key)}/>
                    </div>
                    <div className={styles.pullNewOperation_lineChart_activeTitle}>
                        <div>
                            {
                                current_tabble == 1
                                ?   '拉新活动效果'
                                :   '拉新人数趋势'
                            }
                        </div>
                        {
                            nearly_year.length > 0
                            ?   <Select defaultValue={nearly_year[0]} style={{width:130}} onChange={(e: any) => {this.handleChangeLineDate(e)}}>
                                    {
                                        nearly_year.map((item: any, index: any) => {
                                            return (
                                            <Option value={item} key={item + index}>{item}</Option>            
                                            )
                                        })
                                    }
                                </Select>
                            : null
                        }
                    </div>
                    {
                        current_tabble == 1
                        ?   <LineChart legendList={legend} xLine={xLine} color3={operation_line_color} data={operation_line_color_content}></LineChart>
                        :   <LineChartActive legendList={legend_active} xLine={xLine_active} color3={operation_line_color} data={operation_line_color_content_active}></LineChartActive>
                    }
                </Col>
				<Col className={styles.pullNewOperation_customGroup}>
                    {
                        this.state.start_at && this.state.group_pie.length > 0 && group_loading
                        ?   <ComsumeBehavior 
                                own_date={
                                    {
                                        st: this.state.start_at,
                                        et: this.state.end_at
                                    }
                                } user={month_info.user} dataObj={group_pie}  current={current_tabble} type_title={current_tabble == 1 ? '活动效果占比' : '新用户占比'} history={this.props.history}/>
                        :   null
                    }
				</Col>
            </Row>
        )
    }

}

export default PullNewOperation