import React, { Fragment } from "react";
import { Router, Route, Switch} from "react-router-dom";
import { createBrowserHistory } from "history";
import './stores/index.store'
import "./App.css";
import Login from './pages/login/login'
import storeAuth from './pages/h5/store_auth/store_auth'
import Register from './pages/login/register'
import SelectStore from './pages/selectStore/selectStore'
import HelpCenter from './pages/index/components/helpCenter/helpCenter'
import { Main } from "./pages/main/main";
import ErrorPage from './pages/error/index.jsx'

const history = createBrowserHistory();

const App = () => {
  return <Fragment>
    <Router history={history}>
      {/* <Switch> */}
        <Route path="/storeAuth" exact component={storeAuth}/>
        <Route path="/login" exact component={Login}/>
        <Route path="/register" exact component={Register}/>
        <Route path="/store" exact component={SelectStore}/>
        <Route path="/helpCenter" exact component={HelpCenter}/>
        <Route path="/" exact component={Main}/>
        <Route path="/shouye" exact component={Main}/>
        <Route path="/sidebar/:name" exact component={Main} />
        <Route path="/helpCenter/:name" exact component={HelpCenter} />
        {/* <Route component={ErrorPage} /> */}
      {/* </Switch> */}
    </Router>
  </Fragment>
};

export default App