import React, { Fragment, useState, useEffect } from 'react'
import './order_list.scss'
import { Form, Row, Col, Select, DatePicker, } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import OrderManagerTable from './components/order_manager_table';
import Search from 'antd/lib/input/Search';
import { getRequest } from '../../../api/http';
import { apiUrl } from '../../../api/api_url';
import moment from 'moment';

const { Option } = Select;
const { RangePicker } = DatePicker;

interface IOrderManagerOrderList extends FormComponentProps {
  location: any
}

interface IsearchData {
  [key: string]: any
}

const searchData: IsearchData = {
  code: '',
  table_id: '',
  paid_start: 0,
  paid_end: 0,
  status: -1,
  nickname: '',
  pay_type: '',
  created_start: 0,
  created_end: 0
}

const OrderManagerOrderList = (props:IOrderManagerOrderList) => {

  const { getFieldDecorator, setFieldsValue, getFieldsValue} = props.form;
  const [condition, setcondition] = useState<any>((props.location && props.location.query) ? {paid_start: props.location.query.open_at, paid_end: props.location.query.close_at} : '');
  const [payTypeList, setpayTypeList] = useState([])

  useEffect(() => {
      payList()
  }, [])

  const inputValuechange = (key: string, val: any) => {
    if(key !== 'paytime' && key !== 'createtime') {
      searchData[key] = val;
    }

    if(key === 'paytime'){
      searchData['paid_start'] = val[0] ? val[0].format('YYYY-MM-DD HH:mm:ss') : '';
      searchData['paid_end'] = val[1] ? val[1].format('YYYY-MM-DD HH:mm:ss') : '';
    }else{
      let paytime = getFieldsValue(['createtime'])
      if(paytime.paytime){
        searchData['paid_start'] = paytime.paytime[0] ? paytime.paytime[0].format('YYYY-MM-DD HH:mm:ss') : '';
        searchData['paid_end'] = paytime.paytime[1] ? paytime.paytime[1].format('YYYY-MM-DD HH:mm:ss') : '';
      }
    }

    if(key === 'createtime'){
      searchData['created_start'] = val[0] ? val[0].format('YYYY-MM-DD HH:mm:ss') : '';
      searchData['created_end'] = val[1] ? val[1].format('YYYY-MM-DD HH:mm:ss') : '';
    }else{
      let createtime = getFieldsValue(['createtime'])
      if(createtime.createtime){
        searchData['created_start'] = createtime.createtime[0] ? createtime.createtime[0].format('YYYY-MM-DD HH:mm:ss') : '';
        searchData['created_end'] = createtime.createtime[1] ? createtime.createtime[1].format('YYYY-MM-DD HH:mm:ss') : '';
      }
    }

    const _search = JSON.parse(JSON.stringify(searchData));
    Object.keys(_search).forEach((item) => {
      if(!_search[item] || _search[item] === -1) {
        delete _search[item]
      }
    })
    setcondition({..._search});
  }

  // 支付方式列表
  const payList = async () => {
    let res:any = await getRequest(apiUrl.payType)
    if(res.code == 1){
      setpayTypeList(res.data.pay_type)
      if(props.location && props.location.query && props.location.query.open_at){
        setcondition({created_start: props.location.query.open_at, created_end: props.location.query.close_at});
        setFieldsValue({
          "createtime": [moment(props.location.query.open_at, 'YYYY-MM-DD HH:mm:ss'), moment(props.location.query.close_at, 'YYYY-MM-DD HH:mm:ss')]
        })
      }else{
        setcondition({created_start: res.data.time.open_at, created_end: res.data.time.close_at});
        setFieldsValue({
          "createtime": [moment(res.data.time.open_at, 'YYYY-MM-DD HH:mm:ss'), moment(res.data.time.close_at, 'YYYY-MM-DD HH:mm:ss')]
        })
      }
    }
  }

  return <Fragment>
    <div className='l_content'>
    <Form >
        <Row type="flex">
        <Form.Item style={{ minWidth: 350 }}>
            <Row type="flex">
              <Col span={6}>用户昵称 ：</Col>
              <Col span={16}>
                {getFieldDecorator("nickname", {
                  initialValue: "",
                  rules: [{ required: false }]
                })(
                  <Search
                    placeholder="请输入用户昵称"
                    allowClear
                    onSearch={value => inputValuechange('nickname', value)}
                    style={{ width: 200 }}
                  />
                  )}
              </Col>
            </Row>
          </Form.Item>
          <Form.Item style={{ minWidth: 350 }}>
            <Row type="flex">
              <Col span={6}>订单号 ：</Col>
              <Col span={16}>
                {getFieldDecorator("code", {
                  initialValue: "",
                  rules: [{ required: false }]
                })(
                  <Search
                    placeholder="输入订单号"
                    allowClear
                    onSearch={value => inputValuechange('code', value)}
                    style={{ width: 200 }}
                  />
                  )}
              </Col>
            </Row>
          </Form.Item>
          <Form.Item style={{ minWidth: 350 }}>
            <Row type="flex">
              <Col span={6}>支付方式 ：</Col>
              <Col span={16}>
                {getFieldDecorator("pay_type", {
                  // initialValue: "请选择支付方式",
                  // rules: [{ required: true }],
                })(
                  <Select
                    allowClear
                    onChange={(e: any) => inputValuechange('pay_type', e)}
                    placeholder='请选择支付方式'
                  >
                    {/* <Option value={''}>全部方式</Option> */}
                    {
                      payTypeList.map((type:any) => {
                        return <Option key={type.type} value={type.type}>{type.name}</Option>
                      })
                    }
                  </Select>
                )}
              </Col>
            </Row>
          </Form.Item>
            <Form.Item style={{minWidth: 350}}>
                <Row type="flex">
                    <Col span={6}>订单状态 ：</Col>
                    <Col span={16}>
                        <Select
                            allowClear
                            onChange={(e: any) => inputValuechange('status', e)}
                            placeholder='请选择订单状态'
                        >
                            <Option value={1}>待支付</Option>
                            <Option value={5}>已支付</Option>
                            <Option value={10}>全部退款</Option>
                            <Option value={15}>部分退款</Option>
                            <Option value={2}>已取消</Option>
                        </Select>
                    </Col>
                </Row>
            </Form.Item>

          <Form.Item style={{ minWidth: 480 }}>
            <Row type="flex">
              <Col span={6}>创建时间 ：</Col>
              <Col span={16}>
                {getFieldDecorator("createtime", {
                  rules: [{ required: false }],
                  initialValue: ''
                })(
                  <RangePicker
                    allowClear
                    format={`YYYY-MM-DD HH:mm:ss`}
                    showTime={{ format: 'HH:mm' }}
                    onChange={(e) => inputValuechange('createtime', e)}
                    />
                )}
              </Col>
            </Row>
          </Form.Item>

          <Form.Item style={{ minWidth: 400 }}>
            <Row type="flex">
              <Col span={6}>支付时间 ：</Col>
              <Col span={16}>
                {getFieldDecorator("paytime", {
                  rules: [{ required: false }]
                })(
                  <RangePicker
                    format={`YYYY-MM-DD HH:mm:ss`}
                    showTime={{ format: 'HH:mm' }}
                    onChange={(e) => inputValuechange('paytime', e)}
                    />
                )}
              </Col>
            </Row>
          </Form.Item>
        </Row>
      </Form>

      {/* <Button type="primary" style={{marginBottom: 20}}>导出</Button> */}

      <OrderManagerTable condition={condition} />
      </div>
  </Fragment>
}

export default Form.create({})(OrderManagerOrderList);