import React, { useEffect, useState } from 'react'
import { Table, Button, message, Modal } from 'antd'
import { Link } from 'react-router-dom'
import { getRequest, postRequest } from '../../../../api/http';
import { apiUrl } from '../../../../api/api_url';

interface ITableData {
  items: Array<ITableDataItem>,
  total: number
}

interface ITableDataItem {
  status: number
}

const DistributionList = () => {
  const [useList, setuseList] = useState<ITableData>({total: 0, items: []});
  const [visibleModal, setvisibleModal] = useState(false);
  const [currentRowData, setcurrentRowData] = useState<any>();
  const [currentRowDataIdx, setcurrentRowDataIdx] = useState(0);

  useEffect(() => {
    getList();
  },[]);

  const getList = async (current = 1) => {
    const res:any = await getRequest(apiUrl.getVerifyUsers, { pageSize: 30, current: current });
    if(res && res.data) {
      if(current < 2) {
        // const moni = {
        //   items: [{
        //     id: 2,
        //     nickname: "小恶魔",
        //     openid: "oyy8j5vHE6b08w-I5ZRJfCqq0zrM",
        //     avatar: "http://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTKdZMxYyIiapSQTibMDs9Scktr2RQJgWEic6ht7COxz3JSiczjsUdRFLpW0dRDswXepBb9y7yibaZ6SiccQ/132",
        //     wechat: "111",
        //     truename: "小恶魔",
        //     mobile: "18576709668",
        //     created_at: "2019-12-30 09:59:48",
        //     updated_at: "2019-12-30 09:59:48",
        //     deleted_at: null,
        //     shop_user_id: 0
        //   }],
        //   total: 1
        // };
        // setuseList(moni);
        setuseList(res.data);
      } else {
        setuseList({
          total: res.data.total,
          items: [...useList.items, ...res.data.items]
        })
      }
    }
  }

  const columns = [
    {
      title: '核销员姓名',
      dataIndex: 'truename',
      key: 'truename',
    },
    {
      title: '核销员微信',
      dataIndex: 'wechat',
      key: 'wechat',
    },
    {
      title: '核销员微信昵称',
      dataIndex: 'nickname',
      key: 'nickname',
    },
    {
      title: '核销员电话',
      dataIndex: 'mobile',
      key: 'mobile',
    },
    {
      title: '核销员订单数量',
      dataIndex: 'verify_count',
      key: 'verify_count',
    },
    {
      title: '添加时间',
      dataIndex: 'created_at',
      key: 'created_at',
    },
    {
      title: '操作',
      key: 'action',
      render: (rowdata: any, record: any, idx: number) => {
        return (<div>
          <Link to={{pathname: '/sidebar/distribution_manager', state: rowdata}}>
            <Button type="link">编辑</Button>
          </Link>
          {
            rowdata.status === 1 && <Button type="link" onClick={() => deleteVerifyUser(rowdata, idx)}>解除身份</Button>
          }
          {
            rowdata.status === 2 && <Button type="link" onClick={() => openSureModal(rowdata, idx)}>恢复身份</Button>
          }
        </div>);
      }
    },
  ];

  // 解除核销身份
  const deleteVerifyUser = async (rowdata:any, idx: number) => {
    const data = {
      id: rowdata['id'],
      update: {
        status: 2
      }
    }
    const res:any = await postRequest(apiUrl.updateVerifyUser, data)
    if(res && res.data) {
      const _useList = useList;
      _useList.items[idx].status = 2;
      setuseList({..._useList});
      message.success('解除成功');
    }
  }

  // 恢复核销身份
  const restoreVerifyUser = async(rowdata: any, idx: number) => {
    const data = {
      id: rowdata['id'],
      update: {
        status: 1
      }
    };
    const res:any = await postRequest(apiUrl.updateVerifyUser, data)
    if(res && res.data) {
      const _useList = useList;
      _useList.items[idx].status = 1;
      setuseList(_useList);
      message.success('身份已恢复');
      setvisibleModal(false);
    }
  }

  const openSureModal = (rowdata: any, idx: number) => {
    setcurrentRowData(rowdata);
    setcurrentRowDataIdx(idx);
    setvisibleModal(true);
  }

  return <div className='l_content'>
    <Link to="/sidebar/distribution_manager">
      <Button type="primary" style={{marginBottom: 50}}>添加核销人员</Button>
    </Link>

    <Table columns={columns} size="middle" dataSource={useList.items} rowKey={'id'} pagination={{total: useList.total, pageSize: 30}} />

    <Modal
      title="恢复核销员身份"
      visible={visibleModal}
      onOk={() => restoreVerifyUser(currentRowData, currentRowDataIdx)}
      onCancel={() => setvisibleModal(false)}
    >
      <p>{`是否确认恢复「${currentRowData && currentRowData['truename']}」核销员身份？恢复后即可继续对订单进行核销`}</p>
    </Modal>
  </div>
}

export default DistributionList