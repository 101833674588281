import React, { useState, useEffect } from 'react'
import { Row, Col, Input, Radio } from 'antd';
import { IFormData } from '../create_active';


interface IProps {
  update(d: IFormData): void,
  formData: IFormData
}

const CreateStep02 = (props: IProps) => {

  const [distributionLevel, setdistributionLevel] = useState(1);
  
  // 修改分销层级
  const handleSizeChange = (e:number) => {
    setdistributionLevel(e);
    props.update({...props.formData, hierarchy: e});
  }

  const profitChange = (num:string) => {
    props.update({...props.formData, profit_value_1: Number(num)})
  }

  useEffect(() => {
  }, [distributionLevel])

  return (<div>
    <Row type="flex" align="middle" style={{marginTop: 30}}>
      <Col span={2} style={{textAlign: 'right'}}>分销层级：</Col>
      <Radio.Group value={distributionLevel} onChange={(e) => handleSizeChange(e.target.value)}>
        <Radio.Button value={1}>1</Radio.Button>
        <Radio.Button disabled value={2}>2</Radio.Button>
      </Radio.Group>
    </Row>

    <Row type="flex" align="middle" style={{marginTop: 30}}>
      <Col span={2} style={{textAlign: 'right'}}>分销类型：</Col>
      <Radio.Group value={1}>
        <Radio value={1}>固定收益</Radio>
      </Radio.Group>
    </Row>

    <Row type="flex" align="middle" style={{marginTop: 30}}>
      <Col span={2} style={{textAlign: 'right'}}>一级分销：</Col>
      <Col span={2} style={{textAlign: 'right'}}>
        <Input prefix="￥" 
               defaultValue={props.formData.profit_value_1 < 0 ? '' :props.formData.profit_value_1 } 
               suffix="元" 
               onChange={(e) => profitChange(e.target.value)}/>
      </Col>
    </Row>

    <Row type="flex" align="middle" style={{marginTop: 30}}>
      <Col span={2} style={{textAlign: 'right'}}>分销绑定：</Col>
      <Radio.Group value={1}>
        <Radio value={1}>支付绑定</Radio>
        <Radio value={2} disabled>邀请绑定</Radio>
      </Radio.Group>
    </Row>
  </div>);
}

export default CreateStep02