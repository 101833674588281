import React, { useEffect, useState } from 'react'

import { getRequest, postRequest } from '../../api/http'
import { apiUrl } from '../../api/api_url'

import { message, Col, Row, Button, Table, Tag, Popconfirm, Modal, Radio, Avatar, Select } from 'antd'
import Search from 'antd/lib/input/Search';
import ParinterAdd from './parinter_add'

import './parinter.scss'
import { getIsPermisson, messageCustom } from '../../utils/permisstionUtil';

const key = 'updatable';

const { Option } = Select;
const Parinter = () => {

    const [tableSource, setTableSource] = useState([])

    const [auditVisible, setAuditVisible] = useState(false)
    const [auditStatus, setAuditStatus] = useState(5)

    const [currenPage, setcurrenPage] = useState(1)
    const [pageSize, setpageSize] = useState(10)


    const [tableTotal, settableTotal] = useState(10)



    const [itemEditData, setItemEditData] = useState<any>({})

    const [taskFormVisible, setTaskFormVisible] = useState(false)

    const [searchName, setSearchName] = useState<any>()
    const [searchState, setSearchState] = useState<any>()

    const [visibleDetail, setvisibleDetail] = useState(false);
    const [invitedvisibleDetail, setInvitedvisibleDetail] = useState(false);

    const [invitation, setInvitation] = useState<any>(
        {
            list: [],
            total_num: 0
        }
    );
    const [conumpation, setConumpation] = useState<any>(
        {
            list: [],
            total_money: 0
        }
    );

    useEffect(() => {
        getList(currenPage)
    }, [])


    const pageChange = (e: any) => {
        setcurrenPage(e)
        getList(e)

    }

    const getList = async (currenPageParam?: number, data:any = {}) => {
        if (currenPageParam === undefined) {
            currenPageParam = 1
        }
        let params = {
            pageSize: pageSize,
            current: currenPageParam,
            ...data
        }
        const res: any = await getRequest(`${apiUrl.partnerList}`, params);
        if (res.code === 1) {

            // if(res.data.length > )
            settableTotal(res.data.total)
            setTableSource(res.data.list)
        } else {
            message.error(res.message)
        }
    }

    //添加
    const addAction = () => {
        if(!getIsPermisson('1_9_2_1')){
            messageCustom();
            return;
          }
        setItemEditData({})
        setTaskFormVisible(true)
    }

    //启用-停用
    const editAction = (record: any) => {
        if(!getIsPermisson('1_9_2_2')){
            messageCustom();
            return;
          }
        Modal.confirm({
            title: `${record.is_effect == 1 ? '停用' : '启用'}`,
            content: `${record.is_effect == 1 ? `是否确定 ${record.nickname}合伙人停用?停用后 该合伙人不能提现` : `是否确定 ${record.nickname}合伙人启用?启用后 该合伙人可提现`} `,
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                const params = {
                    id: record.id,
                    is_effect: record.is_effect == 1 ? 2 : 1
                }
                const res: any = await postRequest(apiUrl.partnerEdit, params);
                if (res.code === 1) {
                    message.success({ content: `${record.is_effect == 1 ? '停用成功' : '启用成功'}`, key, duration: 2 });
                }
                getList();
            },
            onCancel() {
            },
        });
    }

    // 删除
    // const deleteAction = (record: any) => {
    //     console.log(record)
    //     Modal.confirm({
    //         title: `${record.is_effect == 1 ? '启用' : '停用'}`,
    //         content: `${record.is_effect == 1 ? `是否确定 ${record.nickname}合伙人停用?停用后 该合伙人不能提现` : `是否确定 ${record.nickname}合伙人停用?停用后 该合伙人不能提现`} `,
    //         okText: '确认',
    //         cancelText: '取消',
    //         onOk: async () => {
    //             const params = {
    //                 id: record.id,
    //             }
    //             const res: any = await getRequest(apiUrl.partnerDelete, params);
    //             if (res.code === 1) {
    //                 message.success({ content: `${record.is_effect == 1 ? '停用成功' : '启用成功'}`, key, duration: 2 });
    //             }
    //             getList();
    //         },
    //         onCancel() {
    //         },
    //     });
    // }


    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            className: 'list_center',
        },
        {
            title: '用户昵称',
            dataIndex: 'nickname',
            key: 'nickname',
            render: (text: string, record: any, index: number) =>
                <Row className="" justify="start" align="middle">
                    <div><Avatar src={record.head_img_url} /></div>
                    <div>{record.nickname}</div>
                </Row>
        },
        {
            title: '邀请人数（人）',
            dataIndex: 'invited_num',
            className: 'list_center',
            render: (text: any,rowdata: any) => {
                return (
                  <Row>
                    <Button
                      type="link"
                      onClick={() => {
                        invitationPorPle(1,rowdata)
                      }}
                    >
                      {rowdata.invited_num}
                    </Button>
                  </Row>
                );
              },
        },
        {
            title: '邀请人累计消费（元）',
            dataIndex: 'consume_money',
            className: 'list_center',
            render: (text: any,rowdata: any) => {
                return (
                  <Row>
                    <Button
                      type="link"
                      onClick={() => {
                        invitationPorPle(2,rowdata)
                      }}
                    >
                      {rowdata.consume_money}
                    </Button>
                  </Row>
                );
              },
        },
        {
            title: '总获得奖励（元）',
            dataIndex: 'total_reward',
        },
        {
            title: '提现金额（元）',
            dataIndex: 'withdraw',
        },
        {
            title: '剩余金额（元） ',
            dataIndex: 'balance_reward',
        },
        {
            title: '状态',
            dataIndex: 'is_effect',
            key: 'is_effect',
            render: (rowdata: any, record: any, idx: number) => {
                return (
                    <div>{
                        record.is_effect === 1 ? '启用' :
                        record.is_effect === 2 ? '停用' : <p></p>
                    }</div>
                )
            }
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            className: 'list_center',
            render: (text: any, record: any,) => (
                <div className="btn_wrap">
                    {/* <div className="list_btn" onClick={() => deleteAction(record)}>删除</div> */}
                    {
                        record.is_effect === 1
                        ? <div className="list_btn" onClick={() => editAction(record)}>停用</div>
                        : <div className="list_btn" onClick={() => editAction(record)}>启用</div>
                    }
                </div>
            ),
        },
    ];

    const closeAndRefresh = (data: boolean, isRefresh: boolean | undefined) => {
        setTaskFormVisible(data)
        if (isRefresh) {
            getList();
        }
    }

    //
    const requestGetMemberList = async (data: any, type: any) => {
        if(type == 0) {
            setSearchName({nickname: data.nickname})
        } else {
            setSearchState({is_effect: data.is_effect})
        }
        getList(currenPage,data);
    };

    //获取邀请人数和累计消费
    const getInvitaconumpation = async (type: any, data: any) => {
        //console.log(data)
        const params = {
            id: data.fans_id,
        }
        const res: any = await getRequest(apiUrl.getInvitedFans, params);
        if (res.code === 1) {
            if(!isArray(res.data)) {
                if(type == 1) {
                    console.log(res.data)
                    setInvitation({...res.data})

                } else {
                    setConumpation({...res.data})
                }
            }
        }
    }

    const isArray = (o:any) =>{

        return Object.prototype.toString.call(o)== '[object Array]';

    }

    //邀请人数
    const invitationPorPle = (type: any, data: any) => {
        if(type == 1) {
            setvisibleDetail(true);
        } else {
            setInvitedvisibleDetail(true)
        }
        getInvitaconumpation(type, data)
    }

    return (
        <div className='current_page l_content'>
            <ParinterAdd visible={taskFormVisible} setVisible={(data: boolean, isRefresh?: boolean) => { closeAndRefresh(data, isRefresh) }} data={itemEditData}></ParinterAdd>

            <Row className="parinter">
                <Col span={4}>
                    <Search
                        placeholder="请输入用户昵称"
                        onSearch={value => {
                            requestGetMemberList({ nickname: value, ...searchState }, 0);
                        }}
                    />
                </Col>
                <Col span={4} offset={1}>
                    <div className="select_item">
                        <div>状态：</div>
                        <Select allowClear placeholder="请选择状态" style={{ width: 122 }} onChange={(e: any) => {
                            // setgrade(e);
                            requestGetMemberList({...searchName, is_effect: e }, 1);
                        }}>
                            <Option value='1'>启用</Option>
                            <Option value='2'>停用</Option>

                        </Select>
                    </div>
                </Col>
                <Col span={4}>
                    <Button type="primary" size="large" icon="plus" onClick={addAction}>添加合伙人</Button>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Table dataSource={tableSource} size="middle" rowKey={'id'} columns={columns} pagination={{
                        defaultCurrent: 1,
                        total: tableTotal,
                        pageSize: pageSize,
                        onChange: pageChange,
                        showQuickJumper: true,
                        showSizeChanger: true,
                        showTotal: (total: number) => `共${total}条`,
                        onShowSizeChange: (current:number, size: number) => {setpageSize(size); setcurrenPage(1); getList(1, {current: 1, pageSize: size})}

                    }} />
                </Col>
            </Row>
            <Modal
                title="邀请人数"
                visible={visibleDetail}
                onOk={() => setvisibleDetail(false)}
                onCancel={() => setvisibleDetail(false)}
                okText="确认"
                cancelText="取消"
                width="300px"
                destroyOnClose
                footer={null}
                centered={true}
                style={{padding: '0'}}
            >
                <div className="invitation">
                    <div className="invitation-num">邀请人数：{invitation.total_num}人</div>
                    <div className="invitation-list">
                        {
                            invitation.list.map((item:any, index:any) => {
                                return (
                                    <div className="invitation-list-item" key={index}>
                                        <div><Avatar src={item.head_img_url} className="invitation-list-item-img" /></div>
                                        <div className="invitation-list-item-txt">{item.nickname}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </Modal>
            <Modal
                title="邀请人累计消费"
                visible={invitedvisibleDetail}
                onOk={() => setInvitedvisibleDetail(false)}
                onCancel={() => setInvitedvisibleDetail(false)}
                okText="确认"
                cancelText="取消"
                width="300px"
                destroyOnClose
                footer={null}
                centered={true}
                style={{padding: '0'}}
            >
                <div className="conumpation">
                    <div className="conumpation-num">邀请人累计消费：{conumpation.total_money}元</div>
                    <div className="conumpation-list">
                        {
                            conumpation.list.map((item:any, index:any) => {
                                return (
                                    item.consume_money == ''
                                    ? ''
                                    : <div className="conumpation-list-item" key={index}>
                                        <div className='conumpation-list-item-left'>
                                            <div><Avatar src={item.head_img_url} className="conumpation-list-item-img" /></div>
                                            <div className="conumpation-list-item-txt">{item.nickname}</div>
                                        </div>
                                        <div className="conumpation-list-item-right">
                                            总消费：{item.consume_money}元
                                        </div>
                                    </div>

                                )
                            })
                        }
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default Parinter
