import {observer} from "mobx-react";
import React from "react";
import {Button, Input, message, Row, Col, Select, Typography, Divider, Modal, Icon} from "antd";
import { postRequest, getRequest } from '../../../api/http';
import { apiUrl } from '../../../api/api_url';
const { Option } = Select;
const { Text } = Typography;

const key = 'DeductionAdd';

@observer
class DeductionAdd extends React.Component<any> {

    state = {
        loading: false,
        name: '',
        cate: [],
        origin_price: 0,
        real_price: 0,
        channelObj: {
            'key': -1,
            'label': '请选择券类型'
        },
        channelName: '',
        addModal: false,
        addInput: false,
        editObj: {}
    }

    componentDidMount () {
        const {location} = this.props;
        if(location.state !== undefined){
            this.initEdit(location.state.id);
        }
        this.initCate();
    }

    // 初始化编辑
    initEdit = async (id:number) => {
        const params = {
            id: id
        };
        const res:any = await getRequest(apiUrl.deductionInfo,params);
        if(res.code === 1){
            this.setState({
                name: res.data.name,
                origin_price: res.data.origin_price,
                real_price: res.data.real_price,
                channelObj: {
                    'key': res.data.channel,
                    'label': res.data.channel_name
                }
            })
        }
    }

    // 渠道列表
    initCate = async () => {
        const res:any = await getRequest(apiUrl.deductionChannelList,);
        if(res.code === 1){
            this.setState({
                cate: res.data.list
            })
        }
    }

    nameChange = (e:any) => {
        let name = e.target.value.trim();
        this.setState({
            name
        })
    }

    // 渠道选择
    cateChange = (channel: any) => {
        this.setState({
            channel: channel.key,
            channelObj: channel,
            open: false
        })
    }

    originChange = (e:any) => {
        this.setState({
            origin_price: e.target.value
        })
    }

    realChange = (e:any) => {
        this.setState({
            real_price: e.target.value
        })
    }

    handleSubmit = async () => {
        message.loading({ content: '发布中', key });

        const {location} = this.props;
        const { name, channelObj, origin_price, real_price } = this.state;
        var tableInfo:any = {};

        if(location.state !== undefined){
            tableInfo.id = location.state.id;
        }

        if(name === ''){
            message.warning({ content: '请输入福利名称!', key, duration: 2 });
            return;
        }

        if(channelObj.key === -1){
            message.warning({ content: '请选择抵扣券渠道!', key, duration: 2 });
            return;
        }

        if(real_price < 0){
            message.warning({ content: '请输入实际金额!', key, duration: 2 });
            return;
        }

        if(origin_price <= 0){
            message.warning({ content: '请输入抵扣金额!', key, duration: 2 });
            return;
        }

        tableInfo.name = name;
        tableInfo.channel = channelObj.key;
        tableInfo.origin_price = origin_price;
        tableInfo.real_price = real_price;

        if(location.state !== undefined){
            const res:any = await postRequest(apiUrl.deductionEdit,tableInfo);
            if(res['code'] === 1){
                message.success({ content: '修改成功!', key, duration: 2 });
                this.goBack();
            }else{
                message.error({ content: '修改失败!', key, duration: 2 });
            }
        }else{
            const res:any = await postRequest(apiUrl.deductionAdd,tableInfo);
            if(res['code'] === 1){
                message.success({ content: '发布成功!', key, duration: 2 });
                this.goBack();
            }else{
                message.error({ content: '发布失败!', key, duration: 2 });
            }
        }
    };

    // 返回上一页
    goBack = () => {
        this.props.history.goBack();
    }

    addItem = async () => {
        let channelName = this.state.channelName;
        let cate:any = this.state.cate;
        let editObj:any = this.state.editObj;

        if(!channelName){
            message.warn('请先输入渠道名称')
            return;
        }
        if(editObj && editObj.value){
            const res: any = await postRequest(apiUrl.updateDiscountChannel, {name: this.state.channelName, id: editObj.value})
            if(res.code == 1){
                message.success('更新成功')
                cate.map((item1:any) => {
                    if(item1.value == editObj.value){
                        item1.name = channelName
                    }
                })
                this.setState({
                    cate,
                    channelName: '',
                    addInput: false
                })
            }
        }else{
            const res: any = await postRequest(apiUrl.addDiscountChannel, {name: this.state.channelName})
            if(res.code == 1){
                message.success('添加成功')
                cate.push({
                    value: res.data.id,
                    name: channelName
                })
                this.setState({
                    cate,
                    channelName: '',
                    addInput: false
                })
            }
        }
    }

    // 删除渠道
    deleteChannel = async (item:any, index: number) => {
        let cate:any = this.state.cate;
        let res: any = await postRequest(apiUrl.updateDiscountChannel,{status: 0, id: item.value})
        if(res.code){
            message.success('删除成功')
            cate.splice(index, 1)
            this.setState({
                cate
            })
        }
    }

    editChannel = (item:any) => {
        this.setState({
            addInput: true,
            channelName: item.name,
            editObj: item
        })
    }

    render(){
        const { name, cate, origin_price, real_price, channelObj, channelName, addModal, addInput} = this.state;

        return <div className='l_content'>
                <div className="edit_title">编辑抵扣券信息</div>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">抵扣券名称</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <Input placeholder="请输入抵扣券名称" maxLength={10} value={name}

                        onChange={(e) => this.nameChange(e)}/>
                    </Col>
                </Row>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">抵扣券渠道</span>
                        </div>
                    </Col>
                    <Col>
                        <Select value={channelObj} labelInValue={true} onSelect={this.cateChange}
                        >
                            {
                                cate.map((item:any) => {
                                    return  <Option value={item.value} key={item.value}>{item.name}</Option>
                                })
                            }
                        </Select>
                    </Col>
                    <Col>
                        <Button onClick={() => {this.setState({addModal: true})}} style={{marginLeft: '20px'}}>添加新渠道</Button>
                    </Col>
                </Row>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">购买金额</span>
                        </div>
                    </Col>
                    <Col span={2}>
                        <Input value={real_price} onChange={(e) => this.realChange(e)} type='number'/>
                    </Col>
                    <Col style={{marginLeft: '20px'}}>
                        <Text>抵扣金额：</Text>
                    </Col>
                    <Col span={2}>
                        <Input value={origin_price} onChange={(e) => this.originChange(e)} type='number'/>
                    </Col>
                </Row>

                <Row>
                    <Col span={12}>
                        <div className="bottom_wrap">
                            <Button type="primary" ghost onClick={this.goBack}>
                                取消
                            </Button>
                            <Button type="primary" onClick={this.handleSubmit}>
                                保存
                            </Button>
                        </div>
                    </Col>
                </Row>

                <Modal
                    title='管理渠道'
                    visible={addModal}
                    footer={null}
                    centered
                    onCancel={() => {this.setState({addModal: false, addInput: false, channelName: ''})}}
                >
                    {cate.map((item:any, index: any) => {
                        return <Row type='flex' justify='space-between' style={{marginBottom: '20px'}} key={item.value}>
                            <Col>{item.name}</Col>
                            <Col>
                                <Icon type="edit" style={{padding: '0 10px'}} onClick={() => this.editChannel(item)} />
                                <Icon type="delete" style={{padding: '0 10px'}} onClick={() => this.deleteChannel(item, index)} />
                            </Col>
                        </Row>
                    })}

                    {
                        addInput
                        ? <div className='' style={{display:'flex',margin: '0 auto 30px', alignItems:'center', width:'80%', justifyContent:'center'}}>
                            <Input value={channelName} onChange={(e) => {this.setState({channelName: e.target.value});}} />
                            <Button onClick={this.addItem} style={{marginLeft: '20px'}}>添加</Button>
                        </div>
                        : null
                    }

                    <div style={{marginLeft: 'auto',marginRight: 'auto', width: '100%', textAlign: 'center'}}>
                        <Button style={{margin: '0 auto'}} onClick={() => {this.setState({addInput: true})}}>添加渠道</Button>
                    </div>

                </Modal>
        </div>;
    }

}
export default DeductionAdd