import {observer} from "mobx-react";
import React from "react";
import {Button, Input, message, Row, Col, Select, Radio, DatePicker, Modal, Table, Icon } from "antd";
import { RangePickerValue } from "antd/lib/date-picker/interface";

import { getRequest, postRequest } from '../../api/http';
import { apiUrl } from '../../api/api_url';

const { Option } = Select;
const { RangePicker } = DatePicker;

const key = 'updatable';

@observer
class BuyAndGiveEdit extends React.Component<any> {
    columns = [
        {
          title: '商品名称',
          dataIndex: 'name',
          key: 'name',
          className: 'list_center',
        },
        {
            title: '所属分类',
            dataIndex: 'cat_name',
            key: 'cat_name',
            className: 'list_center',
        },
        {
            title: '商品价格',
            dataIndex: 'price',
            key: 'price',
            className: 'list_center',
        },
    ];

    state = {
        loading: false,
        name: '',
        time_type: 1,
        status: true,
        start_time: '',
        end_time: '',
        condition_dish_id: 0,
        condition_num: 1,
        present_dish_id: 0,
        present_num: 1,
        condition: [],
        present: [],
        visible: false,
        sortList: [],
        currentPage: 1,
        pageSize: 10,
        productList: [],
        productCount: 0,
        cat_id: 0,
        searchName: '',
        showProductList: [],
        keysList: []
    }

    componentDidMount () {
        const {location} = this.props;
        if(location.state !== undefined){
            this.initEdit(location.state.id);
        }
        this._initList();
    }

    // 初始化编辑
    initEdit = async (id:number) => {
        const res:any = await getRequest(apiUrl.saleDetail + `/${id}`);
        if(res.code === 1){
            this.setState({
                name: res.data.name,
                status: res.data.status,
                time_type: res.data.time_type,
                condition_num: res.data.condition_num,
                present_num: res.data.present_num,
                condition_dish_id: res.data.condition_dish_id,
                present_dish_id: res.data.present_dish_id,
            });

            let conditionInfo:any = res.data.condition_dish_info;
            conditionInfo.id = res.data.condition_dish_id;

            let presentInfo:any = res.data.present_dish_info;
            presentInfo.id = res.data.present_dish_id;

            this.setState({
                condition: [conditionInfo],
                present: [presentInfo]
            })

            if(res.data.time_type === 1){
                this.setState({
                    start_time: res.data.start_time,
                    end_time: res.data.end_time
                });
            }
        }
    }

    nameChange = (e:any) => {
        this.setState({
            name: e.target.value.trim()
        })
    }

    // 有效时间
    typeChange = (e:string) => {
        this.setState({
            time_type: e
        })
    }

    timeChange = (time: RangePickerValue, timeString: [string, string]) => {
        this.setState({
            start_time: timeString[0],
            end_time: timeString[1]
        })
    }

    // 状态修改
    statusChange = (e: any) => {
        this.setState({
            status: e
        })
    }

    handleSubmit = async (e:any) => {
        message.loading({ content: '发布中', key });

        e.preventDefault();

        const {location} = this.props;
        const { name, time_type, start_time, end_time, condition, condition_num, present, present_num, status } = this.state;

        var productInfo:any = {};

        if(name === ''){
            message.warning({ content: '请输入优惠名称!', key, duration: 2 });
            return;
        }

        productInfo.name = name;
        productInfo.time_type = time_type;
        if(time_type === 1){
            if(start_time === '' || end_time === ''){
                message.warning({ content: '请选择有效时间!', key, duration: 2 });
                return;
            }
            productInfo.start_time = (Date.parse(start_time).toString()).substr(0, 10);;
            productInfo.end_time = (Date.parse(end_time).toString()).substr(0, 10);;
        }

        if(condition.length <= 0){
            message.warning({ content: '请选择购买菜品名称!', key, duration: 2 });
            return;
        }
        productInfo.condition_dish_id = condition[0]['id'];
        productInfo.condition_num = condition_num;

        if(present.length <= 0){
            message.warning({ content: '请选择赠送菜品名称!', key, duration: 2 });
            return;
        }

        productInfo.present_dish_id = present[0]['id'];
        productInfo.present_num = present_num;

        productInfo.status = status;

        if(location.state !== undefined){
            const res:any = await postRequest(apiUrl.saleEdit + `/${location.state.id}`,productInfo);
            if(res['code'] === 1){
                message.success({ content: '修改成功!', key, duration: 2 });
                this.props.history.push('/sidebar/buyAndGive');
            }else{
                message.error({ content: '修改失败!', key, duration: 2 });
            }
        }else{
            const res:any = await postRequest(apiUrl.saleAdd,productInfo);
            if(res['code'] === 1){
                message.success({ content: '发布成功!', key, duration: 2 });
                this.props.history.push('/sidebar/buyAndGive')
            }else{
                message.error({ content: '发布失败!', key, duration: 2 });
            }
        }
    };

    handleEditorChange = (editorState:any) => {
        this.setState({ editorState })
    }

    // 返回上一页
    goBack = () => {
        this.props.history.goBack();
    }

    _initList = async () => {
        let sortList:any[] = [];

        const { currentPage } = this.state;
        const params:any = {
            current: currentPage,
            pageSize: 100,
            up_status: 4
        };

        const res:any = await getRequest(apiUrl.goodList,params);
        if(res.code === 1){
            let sortTest:string[] = [];
            res.data.map((item: any) => {
                if(sortTest.indexOf(item.cat_name) < 0){
                    sortList.push({id: item.cat_id, name: item.cat_name})
                    sortTest.push(item.cat_name)
                }
            })
            this.setState({
                productList: res.data,
                sortList
            },() => {
                this.filterList();
            })
        }
    }

    isChinese = (s:string) =>{
        return /[\u4e00-\u9fa5]/.test(s);
    }

    ch2Unicdoe =(str:string) =>{
        if(!str){
          return;
        }
        var unicode = '';
        for (var i = 0; i <  str.length; i++) {
          var temp = str.charAt(i);
          if(this.isChinese(temp)){
            unicode += '\\u' +  temp.charCodeAt(0).toString(16);
          }
          else{
            unicode += temp;
          }
        }
          return unicode;
    }

    searchFoodName = (searchText:string,text:string) => {
        let test = this.ch2Unicdoe(searchText)
        var pattern2 = `/[${test}]+/g`;
        var reg = RegExp(eval(pattern2))
        return reg.test(text)
    }

    filterList = () => {
        let { productList, cat_id, searchName } = this.state;
        let newList:any = [];
        let newListTow:any = [];

        productList.map((item:any) => {
            if(searchName !== ''){
                if(this.searchFoodName(searchName, item.name)){
                    newList.push(item)
                }
            }else{
                if(cat_id !== 0){
                    if(cat_id === item.cat_id){
                        newList.push(item)
                    }
                }else{
                    newList.push(item)
                }
            }
        })

        newListTow = JSON.parse(JSON.stringify(newList));

        this.setState({
            showProductList: newList,
            productCount: newListTow.length
        })
    }

    //商品名搜索
    searchAction = (e:any) => {
        this.setState({
            searchName: e.target.value.trim(),
            currentPage: 1
        }, () => {
            this.filterList()
        })
    }
    // 分类
    sortChange = (e:any) => {
        this.setState({
            cat_id: e,
            currentPage: 1
        }, () => {
            this.filterList()
        })
    }
    // 分页
    orderChange = (page: number) => {
        this.setState({
            currentPage: page
        }, () => {
            this.filterList()
        })
    }

    currentType = 'condition';
    keysList = [];

    handleOk = (e:any) => {
        let productList:any[] = this.state.productList;
        let condition: any = [];
        let present: any = [];

        // return;
        productList.map((item:any) => {
            if(item.select){
                if(this.currentType === 'condition'){
                    condition.push({
                        id: item.id,
                        standard_id: 0,
                        name: item.name
                    })
                    this.setState({
                        condition
                    })
                }else{
                    present.push({
                        id: item.id,
                        standard_id: 0,
                        name: item.name
                    })
                    this.setState({
                        present
                    })
                }
            }
        })
        this.setState({
          visible: false
        });
    };

    handleCancel = (e:any) => {
        this.setState({
          visible: false,
        });
    };

    // 选择商品弹窗
    showTable = (type: string) => {
        let { productList, condition, present } = this.state;
        let oldType = this.currentType;
        if(oldType !== type){
            productList.map((item: any) => {
                item.select = false;
            })
        }
        this.setState({
            keysList:[]
        })
        this.currentType = type;
        if(this.currentType === 'condition'){
            productList.map((item: any) => {
                condition.map((item2: any) => {
                    if(item.id === item2.id){
                        item.select = true;
                    }
                })
            })
        }else{
            productList.map((item: any) => {
                present.map((item2: any) => {
                    if(item.id === item2.id){
                        item.select = true;
                    }
                })
            })
        }
        this.setState({
            productList,
            visible: true
        }, () => {
            this.filterList()
        })
    }

    deleteDish = (id: number, type: string) => {
        this.currentType = type;
        let { condition, productList, present } = this.state;
        if(type === 'condition'){
            condition.map((item:any, index: number) => {
                if(item.id === id){
                    condition.splice(index,1)
                }
            })
        }else{
            present.map((item:any, index: number) => {
                if(item.id === id){
                    present.splice(index,1)
                }
            })
        }


        productList.map((item: any) => {
            if(item.id === id){
                item.select = false;
            }
        })

        this.setState({
            condition,
            present,
            productList
        }, () => {
            this.filterList()
        })
    }

    addNum = (type: string) => {
        let { condition_num, present_num } = this.state;
        if(type === 'condition'){
            condition_num++;
            this.setState({
                condition_num
            })
        }else{
            present_num++;
            this.setState({
                present_num
            })
        }
    }

    reduceNum = (type: string) => {
        let { condition_num, present_num } = this.state;
        if(type === 'condition'){
            if(condition_num <= 1){
                return
            }
            condition_num--;
            this.setState({
                condition_num
            })
        }else{
            if(present_num <= 1){
                return
            }
            present_num--;
            this.setState({
                present_num
            })
        }
    }

    render(){

        const rowSelection:any = {
            onSelect: (record: any, selected: any, selectedRows: any) => {
                let productList:any = this.state.productList;
                productList.map((item: any) => {
                    if(item.id === record.id){
                        item.select = selected;
                    }else{
                        item.select = !selected;
                    }
                })
                this.setState({
                    productList
                })
            },
            onSelectAll: (selected: any, selectedRows: any, changeRows: any) => {
                // let productList:any[] = this.state.productList;

                // productList.map((item: any) => {
                //     changeRows.map((item2: any) => {
                //         if(item.id === item2.id){
                //             if(this.currentType === 'condition'){
                //                 item.condition = selected;
                //             }else{
                //                 item.present = selected;
                //             }
                //         }
                //     })
                // })

                // this.setState({
                //     productList
                // })
            },
            type: 'radio',
            onChange: (selectedRowKeys:any, selectedRows: any) => {
                console.log(selectedRowKeys, selectedRows);
                this.setState({
                    keysList: selectedRowKeys
                })
            },
            selectedRowKeys: this.state.keysList,
        };

        const {
            name,
            time_type,
            start_time,
            end_time,
            status,
            condition,
            present,
            condition_num,
            present_num,
            sortList, } = this.state;

        return <div className='l_content'>
                <div className="edit_title">优惠基本信息</div>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">优惠名称</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <Input placeholder="请输入优惠名称" maxLength={10} value={name} onChange={(e) => this.nameChange(e)}/>
                    </Col>
                </Row>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">有效时间</span>
                        </div>
                    </Col>
                    <Col>
                        <Radio.Group
                            value={time_type}
                            onChange={(e) => this.typeChange(e.target.value)}
                        >
                            <Radio value={1}>固定时间</Radio>
                            <Radio value={2}>永久</Radio>
                        </Radio.Group>
                    </Col>
                    <Col span={7}>
                        <Row type="flex" align="middle">
                        <RangePicker onChange={this.timeChange} format="YYYY-MM-DD HH:mm:ss" showTime={{ format: 'HH:mm' }} placeholder={start_time !== '' ? [start_time, end_time] :["开始时间","结束时间"]} />
                        </Row>
                    </Col>
                </Row>

                <Row className="mb30" type="flex" align="top">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">每购买以下菜品</span>
                        </div>
                    </Col>
                    <Col span={2}>
                        <div className='' style={{display: 'flex', alignItems: 'middle', justifyContent: 'start', marginBottom: 10}}>
                            <Icon type="minus-circle" theme="filled" style={{color: '#064CFF', fontSize: 25}}  onClick={() => this.reduceNum('condition')} />
                            <span className='' style={{margin: '0 10px'}}>{condition_num}份</span>
                            <Icon type="plus-circle" theme="filled" style={{color: '#064CFF', fontSize: 25}} onClick={() => this.addNum('condition')} />
                        </div>
                        {/* 购买以下菜品 */}
                        {
                            condition.length > 0
                            ?   <Row>
                                    <Col offset={2}>
                                        <div className='present_good good_title'>
                                            <div>商品名</div>
                                            <div>操作</div>
                                        </div>
                                        {
                                            condition.map((item: any) => {
                                                return <div key={item.id} className='present_good'>
                                                        <div>{item.name}</div>
                                                        <Button onClick={() => this.deleteDish(item.id, 'condition')}>删除</Button>
                                                    </div>
                                            })
                                        }
                                    </Col>
                                </Row>
                            :   <div className='plus_wrap'>
                                    <Icon type="plus" style={{fontSize: 30, color: '#d4d4d4'}} onClick={() => this.showTable('condition')}/>
                                </div>
                        }
                    </Col>
                </Row>

                <Row className="mb30" type="flex" align="top">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">可赠送以下菜品</span>
                        </div>
                    </Col>
                    <Col span={2}>
                        <div className='' style={{display: 'flex', alignItems: 'middle', justifyContent: 'start', marginBottom: 10}}>
                            <Icon type="minus-circle" theme="filled" style={{color: '#064CFF', fontSize: 25}}  onClick={() => this.reduceNum('present')} />
                            <span className='' style={{margin: '0 10px'}}>{present_num}份</span>
                            <Icon type="plus-circle" theme="filled" style={{color: '#064CFF', fontSize: 25}}  onClick={() => this.addNum('present')} />
                        </div>
                         {/* 赠送以下菜品 */}
                         {
                            present.length > 0
                            ?   <Row>
                                    <Col offset={2}>
                                        <div className='present_good good_title'>
                                            <div>商品名</div>
                                            <div>操作</div>
                                        </div>
                                        {
                                            present.map((item: any) => {
                                                return <div key={item.id} className='present_good'>
                                                        <div>{item.name}</div>
                                                        <Button onClick={() => this.deleteDish(item.id, 'present')}>删除</Button>
                                                    </div>
                                            })
                                        }
                                    </Col>
                                </Row>
                            :   <div className='plus_wrap'>
                                    <Icon type="plus" style={{fontSize: 30, color: '#d4d4d4'}} onClick={() => this.showTable('present')}/>
                                </div>
                        }
                    </Col>
                </Row>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">状  态</span>
                        </div>
                    </Col>
                    <Col span={4}>
                        <Radio.Group
                            value={status}
                            style={{ marginLeft: 20 }}
                            onChange={(e) => this.statusChange(e.target.value)}
                        >
                            <Radio value={true}>启用</Radio>
                            <Radio value={false}>停用</Radio>
                        </Radio.Group>
                    </Col>
                </Row>
                <Row>
                    <Col span={18}>
                        <div className="bottom_wrap">
                            <Button type="primary" ghost onClick={this.goBack}>
                                取消
                            </Button>
                            <Button type="primary" onClick={(e) => this.handleSubmit(e)}>
                                保存
                            </Button>
                        </div>
                    </Col>
                </Row>

                <Modal
                    title=""
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    >
                    <Row type="flex" align="middle" justify="start" className="mb20" >
                        <Col className="mr30">
                            <span>商品名称：</span>
                            <Input placeholder="请输入商品名称" onChange={this.searchAction} style={{ width: 234 }}/>
                        </Col>
                    </Row>

                    <Row type="flex" align="middle" justify="start" className="mb20" >
                        <Col className="">
                            <span>商品分类：</span>
                            <Select style={{ width: 150 }} onChange={this.sortChange} placeholder="请选择商品分类">
                                {
                                    sortList.map((item:any) => {
                                        return <Option key={item.id} value={item.id} >{item.name}</Option>
                                    })
                                }
                            </Select>
                        </Col>
                    </Row>

                    <div>
                        <Table
                        dataSource={this.state.showProductList}
                        columns={this.columns}
                        rowKey="id"
                        size="middle"
                        locale={{emptyText: '暂无数据'}}
                        rowSelection={rowSelection}
                        pagination={{
                            current: this.state.currentPage,
                            showQuickJumper:true,
                            defaultCurrent: 1,
                            total: this.state.productCount,
                            pageSize: this.state.pageSize,
                            hideOnSinglePage: true,
                            onChange: this.orderChange
                        }}/>
                    </div>
                </Modal>

        </div>;
    }

}
export default BuyAndGiveEdit