import React, { useEffect, useRef } from 'react'
import 'fabric';
declare let fabric: any;

const  OverView = () => {
  const canvasRef = useRef(null);
  

  useEffect(() => {
    let myCanvas = new fabric.Canvas(canvasRef.current, { backgroundColor: 'blue' });
    myCanvas.setWidth(500);
    myCanvas.setHeight(500);

    myCanvas.add(new fabric.Circle({ radius: 30, fill: 'red', top: 100, left: 100 }));
    
    console.log('这里执行了饿1', canvasRef)
    console.log('这里执行了饿', myCanvas)
    // return () => {
    //   cleanup
    // };
  }, [])

  return <div>
    概况预览概况预览概况预览概况预览概况预览概况预览概况预览概况预览概况预览概况预览概况预览概况预览概况预览概况预览概况预览概况预览概况预览概况预览概况预览概况预览概况预览概况预览概况预览概况预览概况预览概况预览概况预览概况预览概况预览概况预览概况预览概况预览概况预览概况预览概况预览概况预览概况预览概况预览概况预览概况预览概况预览概况预览概况预览概况预览概况预览概况预览概况预览概况预览概况预览概况预览概况预览
    <canvas id="canvas" ref={canvasRef} style={{touchAction: 'none', userSelect: 'none', border: '1px solid red' }}></canvas>
  </div>
}

export default OverView