import React, { useEffect, useState, useRef } from 'react'
import { Select, Row, Tooltip, Table, Button,Modal, message } from 'antd'
import { useHistory,  } from 'react-router-dom'
import btn_add from '../../../assret/images/btn_add.png'
import { getRequest, postRequest } from '../../../api/http'
import { apiUrl } from '../../../api/api_url'
import t_edit from '../../../assret/images/t_edit.png'
import t_delete from '../../../assret/images/t_delete.png'

import './index.scss'
import { getIsPermisson, messageCustom } from '../../../utils/permisstionUtil'


const DashboardList = (props:any)=>{

    const history = useHistory()
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [ rowSpans, setRowSpans ] = useState<any>([])
    const targetList:any = {
        1:'实收金额', 
        2:'就餐桌数', 
        3:'均桌消费', 
        4:'新顾客桌数', 
        5:'老顾客占比', 
        6:'开桌率', 
        7:'消费频次', 
        8:'复购率',
    }
    const unit:any = {
        1:'元', 
        2:'桌', 
        3:'%',
        4:'次',
    }
    // const [cateList, setcateList] = useState<any>([])
    const [paramsObj, setParamsObj] = useState<any>({
        page: 1,
        page_size: 16,
    });


    useEffect(()=>{
        getList({...paramsObj})
    },[])

    const getList = async(data:any = {}) => {
        let param = {
            ...data
        }
        if(param.sortType)param.sortType=param.sortType=='ascend'?'asc':'desc'
        setParamsObj({...param})
        const res:any = await getRequest(apiUrl.getTargetRows, {...param,page_size:param.page_size/8});
        if(res && res['code'] === 1) {
            setTotal(res.data.total*8)
            setData(dataInit(res.data.data))
        }
    }

    //数据源格式化成多条数据
    const dataInit = (data:any)=>{
        let dataArr:any = []
        let i = 0
        data.map((item:any,index:number)=>{
            if(item.items&&item.items.length>1){
                item.items.map((itemG:any,indexG:number)=>{
                    dataArr[i] = itemG
                    dataArr[i].name = item.name
                    dataArr[i].label_id = item.id+'-'+itemG.config_id //唯一id
                    dataArr[i].id = item.id
                    dataArr[i].operate_nick = item.operate_nick
                    dataArr[i].updated_at = item.updated_at
                    i++
                })
            }else{
                dataArr[i] = item.items[0]
                dataArr[i].name = item.name
                dataArr[i].id = item.id
                dataArr[i].label_id = item.id+'-'+item.items[0].config_id
                dataArr[i].operate_nick = item.operate_nick
                dataArr[i].updated_at = item.updated_at
                i++
            }
        })
        setRowSpans(getRowSpans(dataArr, 'id'))
        return dataArr
    }
    
    //获取合并行
    const getRowSpans = (arr:any, key:string) => {
        let sameValueLength = 0;
        const rowSpans = [];
        for(let i = arr.length - 1; i >= 0; i--){
            if(i === 0) {
                rowSpans[i] = sameValueLength + 1;
                continue;
            }
            if(arr[i][key] === arr[i-1][key]) {
                rowSpans[i] = 0;
                sameValueLength++;
            } else {
                rowSpans[i] = sameValueLength + 1;
                sameValueLength = 0;
            }
        }
        return rowSpans;
    };

    //表格值初始化
    const renderContent = ( value:any, _:any, index:number)=>{
        const obj:any = {
            children: value,
            props: {},
        };
        obj.props.rowSpan = rowSpans[index];
        return obj;
    }

    const del = (id:number) =>{
        Modal.confirm({
            title: '是否确定删除目标',
            okText: '确认',
            cancelText: '取消',
            onOk: async() => {
                const res:any = await postRequest(apiUrl.targetDel,{id:id})
                if(res.code === 1){
                    message.success('删除成功')
                    getList({...paramsObj})
                }else{
                    message.error(res.message||'删除失败')
                }
            },
            onCancel() {
            },
        });
    }

    const renderOption = ( value:any, record:any, index:number)=>{
        const obj:any = {
            children:<Row type="flex" align='middle' justify='center'>
                        <Tooltip placement="bottomRight" title={'编辑'}>
                            <img src={t_edit} className='table_icon' onClick={()=>{
                                if(!getIsPermisson('1_1_2_2')){
                                    messageCustom();
                                    return;
                                }
                                history.push('/sidebar/dashboardAdd',{id:record.id})
                            }}></img>
                        </Tooltip>
                        <Tooltip placement="bottomRight" title={'删除'}>
                            <img src={t_delete} className='table_icon' onClick={()=>{
                                if(!getIsPermisson('1_1_2_3')){
                                    messageCustom();
                                    return;
                                }
                                del(record.id)
                            }}></img>
                        </Tooltip>
                    </Row>,
            props: {},
        };
        obj.props.rowSpan = rowSpans[index];
        return obj;
    }

    const creatTable = ()=>{
        const columns:any = [
            {
                title: '目标名称',
                dataIndex: 'name',
                key: 'name',
                render: renderContent     
            },
            {
                title: '经营目标',
                dataIndex: 'config_id',
                key: 'config_id',
                render: (text:any,record:any) => targetList[text]
            },
            {
                title: '目标',
                dataIndex: 'target',
                key: 'target',
                render: (text:any,record:any) => text+unit[record.unit]
            },
            {
                title: '已完成',
                dataIndex: 'finish_target',
                key: 'finish_target',
                render: (text:any,record:any) => text+unit[record.unit]
            },
            {
                title: '完成进度',
                dataIndex: 'progress',
                key: 'progress',
                render: (text:any,record:any) => ((record.finish_target/record.target)*100).toFixed(2)+'%'
            },
            {
                title: '操作时间',
                dataIndex: 'updated_at',
                key: 'updated_at',
                render: renderContent     
            },
            {
                title: '操作人',
                dataIndex: 'operate_nick',
                key: 'operate_nick',
                render: renderContent     
            },
            {
                title: '操作',
                dataIndex: 'option',
                key: 'option',
                render: renderOption  
                // render: ( text:string, record:any)=>
            },
        ];


        return (
            <Table
                dataSource={data}
                columns={columns}
                onChange={handleTableChange}
                rowKey={'label_id'}
                pagination={{
                    defaultCurrent: 1,
                    current:paramsObj.page,
                    pageSize: paramsObj.page_size,
                    total: total,
                    showQuickJumper: true,
                    showSizeChanger: false,
                    showTotal: (total: number) => `共${total}条`,
                }}
            />
        );
    }


    const handleTableChange = (pagination:any, filters:any, sorter:any) => {
        getList({
            ...paramsObj,
            page:pagination.current,
            page_size:pagination.pageSize,
            sortField:sorter.field,
            sortType:sorter.order
        })
    }

    const goPage = () => {
        history.push('/sidebar/dashboardAdd')
    }

    return (
        <div className="dashboard_list">
            <div className='new_btn' onClick={()=>{
                if(!getIsPermisson('1_1_2_1')){
                    messageCustom();
                    return;
                }
                goPage()
            }}>
                <img src={btn_add}></img>
                    添加目标
            </div>
            <div className="dashboard_list_table">
                {
                    creatTable()
                }
            </div>
        </div>
    )
}

export default DashboardList
