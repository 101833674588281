import React from "react"
import { Input, Radio, Button, message, Tooltip, Row, Col, Table, Modal, Checkbox, Icon } from "antd";
import BraftEditor from 'braft-editor'
import { postRequest, getRequest } from "./../../../api/http";
import { apiUrl } from "./../../../api/api_url";
import styles from './recharge_manage.module.scss'

// 引入编辑器样式
import 'braft-editor/dist/index.css'

class RechargeAddOrEdit extends React.Component<any> {

       // //例如规则
    onToastRul = () => {
        return (
            <div>
                <div>不可使用，其余部分均可以使用。</div>
                <div>
                    例如说明:
                    <div>1、当赠送金额设置为立即到账时，假设某用户进行充值时已有余额50元，首次充值100元，充值赠送20元，设置的不可使用的比例为30%那么此用户可使用余额50+100*（1-30%）+20=140元可用</div>
                    <div>2、当赠送金额设置为次日到账时，假设某用户进行充值时已有余额50元，首次充值100元，充值赠送20元，设置的不可使用的比例为30%那么此用户可使用余额50+100*（1-30%）=120元可用</div>
                </div>
            </div>
        )
    }

    state = {
        storeVisible: false,
        storeList: [],
        storeSelect: [],
        shop_ids: [],
        shop_type: 1,
        storeName: '',
        showStoreList: [],
        storePage: 1,
        storeCount: 0,
        storeSize: 0,

        ruleList:[{
            full: 0,
            money: 0,
            full_check: false,
            money_check: false,
            coupon:[]
        }],
        visible: false,
        couponList: [],
        edit: false,
        listNum: 0,
        current: 1,
        selectList: [],

        arrive_at: 1,
        freeze: 1,
        freeze_percentage: 0,
        editorState: BraftEditor.createEditorState(null),
        is_effect: 1,
        recharge_config: '',
        member_type: 0,
        charge_value_least: 0
    }

    componentDidMount(){
        console.log(this.props.location.state);
        if(this.props.location.state && this.props.location.state.rechargeList.length > 0){
            let item:any = this.props.location.state.rechargeList[0]
            this.setState({
                arrive_at: item.arrive_at,
                shop_type: item.shop_type
            })
            // if(item.desc){
            //     this.setState({
            //         editorState: BraftEditor.createEditorState(item.desc)
            //     })
            // }
            if(item.freeze_percentage && item.freeze_percentage != 0){
                this.setState({
                    freeze_percentage: item.freeze_percentage,
                    freeze: 2
                })
            }else{
                this.setState({
                    freeze: 1
                })
            }

            if(item.shop_type == 2){
                let ids:any = [];
                item.shops.map((shop:any) => {
                    ids.push(shop.id)
                })
                this.setState({
                    shop_type: 2,
                    storeSelect: item.shops,
                    shop_ids: ids
                })
            }else{
                this.setState({
                    shop_type: item.shop_type,
                })
            }
            let arr:any = [];
            this.props.location.state && this.props.location.state.rechargeList.map((item: any, idx: number) => {
                let obj:any = {id: item.id, full: item.charge_fee,is_effect: item.is_effect }
                if(item.present_fee && item.present_fee != 0){
                    obj.money = item.present_fee
                    obj.money_check = true;
                }
                if(item.coupon && item.coupon.length > 0){
                    obj.coupon = item.coupon
                    obj.full_check = true;
                    item.coupon.map((coupon:any) => {
                        coupon.index = idx
                    })
                }else{
                    obj.coupon = []
                }
                arr.push(obj)
            })
            this.setState({
                ruleList: arr
            })
        }
        if(this.props.location.state.recharge_config){
            this.setState({
                editorState: BraftEditor.createEditorState(this.props.location.state.recharge_config)
            })
        }
        if(this.props.location.state.member_type){
            this.setState({
                member_type: this.props.location.state.member_type
            })
        }
        if(this.props.location.state.charge_value_least){
            this.setState({
                charge_value_least: this.props.location.state.charge_value_least
            })
        }
        this._initStoreList()
        this.onInitList();
    }

    _initStoreList = async () => {
        const res:any = await getRequest(apiUrl.getShopList);
        if(res.code === 1){
            this.setState({
                storeList: res.data,
            },() => {
                this.filterList();
            })
        }
    }

    // 初始化优惠券列表
    onInitList = async () => {
        let param = {
            current: 1,
            pageSize: 100,
            position: 3
        }
        const res:any = await getRequest(apiUrl.couponList, param);
        if(res.code === 1){
            this.setState({
                couponList: res.data.list,
                listNum: res.data.total,
                current: 1,
                selectList: res.data.list.slice(0, 10)
            })
        }
    }

    inputChange = (e:any) => {
        let name = e.target.name;
        let value = e.target.value;
        if(name === 'freeze_percentage'){
            if(value > 100){
                return;
            }
        }
        this.setState({
            [name]: value
        })
    }

    handleEditorChange = (editorState:any) => {
        this.setState({ editorState })
    }

    // 创建 商品列表
    couponColumns:any = [
        {
            title: '优惠券名称',
            dataIndex: 'name',
            key: 'name',
            align: "center",
        },
        {
            title: '券类型',
            dataIndex: 'type',
            key: 'id',
            align: "center",
            render: (text:any,record:any,) => (
                <div className="color">{record.info.type === 'discount' ? '折扣券' : record.info.type === 'voucher' ? '优惠券' : '兑换券'}</div>
            ),
        },
        {
            title: '券有效期',
            dataIndex: 'created_at',
            key: 'created_at',
            align: "center",
            render: (text:any,record:any,) => {
                let info:any = record.expire_info;
                return <div className="color">{info.type === 'time' ?  '自'+info.start_time + '至' + info.end_time : '自领取日'+info.day + '天内有效'}</div>
            }
        },
        {
            title: '操作',
            align: "center",
            render: (text: string,record:any,index:number)=>{
                return <div>
                    {
                        record.selected
                        ? '已关联'
                        : <div className="table_action" onClick={() => this.handleBindShop(record.id)}>关联</div>
                    }
                </div>
            }
        },
    ];

    columnNum:any = [
        {
            title: '优惠券名称',
            dataIndex: 'name',
            key: 'name',
            align: "center",
        },
        {
            title: '券类型',
            dataIndex: 'type',
            key: 'type',
            align: "center",
            render: (text:any,record:any,) => (
                <div className="color">{record.type === 'discount' ? '折扣券' : record.type === 'voucher' ? '优惠券' : '兑换券'}</div>
            ),
        },
        {
            title: '券有效期',
            dataIndex: 'created_at',
            key: 'created_at',
            align: "center",
            render: (text:any,record:any,) => {
                let info:any = record.expire_info;
                return <div className="color">{info.type === 'time' ?  info.start_time + '至' + info.end_time : '自领取日'+info.day + '天领取'}</div>
            }
        },
        {
            title: '操作',
            dataIndex: 'index',
            key: 'index',
            render: (rowData: any,record:any,idx:number)=>{
                return <div className="table_action" onClick={() => this.unBindShop(rowData, idx)}>移除</div>
            }
        },
    ];

    changePage = async (page: number) => {
        let { couponList } = this.state;
        let list = couponList.slice((page-1)*10, (10*page));
        this.setState({
            selectList: list,
            current: page
        })
    }

    // 关联
    handleBindShop = (id:number) => {
        let couponList:any[] = this.state.couponList;
        let ruleList:any = this.state.ruleList;

        couponList.map((item) => {
            if(item.id === id){
                item.selected = true;
                item.coupon_num = 1;
                item.index = this.currentIndex;
                ruleList[this.currentIndex].coupon.push(item)
            }
        })

        this.setState({
            couponList,
            ruleList
        })
    }

    // 解除关联
    unBindShop = (rowData: any, idx: number) => {
        let { couponList,edit } = this.state;
        let ruleList: any = this.state.ruleList;
        if(edit){
            return;
        }
        couponList.map((item: any) => {
            if(item.id === ruleList[rowData].coupon[idx].id){
                item.selected = false;
            }
        })
        ruleList[rowData].coupon.splice(idx, 1)
        if(ruleList[rowData].coupon.length == 0){
            ruleList[rowData].full_check = false
        }
        this.setState({
            couponList,
            selectList: couponList.slice(0, 10),
            current: 1,
            ruleList
        })
    }

    fullChange = (text: string, index: number, e:any) => {
        let ruleList:any = this.state.ruleList;
        ruleList[index][text] = e.target.value
        this.setState({
            ruleList
        })
    }

    // 删除规则
    deleteRule = (index: number) => {
        let ruleList:any = this.state.ruleList;
        ruleList.splice(index, 1)
        this.setState({
            ruleList
        })
    }

    // 添加规则
    addRule = () => {
        let ruleList:any = this.state.ruleList;
        if(ruleList.length >= 10){
            message.warning('最多可设置10个档位')
            return;
        }
        ruleList.push({
            full: 0,
            money: 0,
            full_check: false,
            money_check: false,
            coupon:[]
        })
        this.setState({
            ruleList
        })
    }

    checkChange = (text: string, index: number, e:any) => {
        let ruleList:any = this.state.ruleList;
        ruleList[index][text] = e.target.checked;
        this.setState({
            ruleList
        })
    }

    currentIndex = -1;
    // 显示关联
    showTable = (index: number) => {
        let { couponList, edit} = this.state;
        let ruleList: any = this.state.ruleList;
        this.currentIndex = index;
        if(edit){
            return;
        }
        couponList.map((coupon: any) => {
            coupon.selected = false;
            ruleList[index].coupon && ruleList[index].coupon.map((select: any) => {
                if(coupon.id === select.id){
                    coupon.selected = true;
                }
            })
        })
        this.setState({
            couponList,
        },() => {
            this.setState({
                selectList: couponList.slice(0, 10),
                current: 1,
                ruleList,
                visible: true
            })
        })
    }

    deleteDish = (id: number) => {
        let { storeSelect, storeList, shop_ids } = this.state;
        storeSelect.map((item:any, index: number) => {
            if(item.id === id){
                storeSelect.splice(index,1)
            }
        })

        storeList.map((item: any) => {
            if(item.id === id){
                item.select = false;
            }
        })

        shop_ids.map((item: any, index:any) => {
            if(item === id){
                shop_ids.splice(index, 1)
            }
        })

        this.setState({
            storeSelect,
            storeList,
            shop_ids
        })
    }

    // 选择门店
    handleOk = (e:any) => {
        let productList:any[] = this.state.storeList;
        let storeSelect: any = [];
        productList.map((item:any) => {
            if(item.select){
                storeSelect.push(item)
            }
        })
        this.setState({
            storeVisible: false,
            storeSelect
        });
    };

    //门店搜索
    searchAction = (e:any) => {
        this.setState({
            storeName: e.target.value.trim(),
            currentPage: 1
        }, () => {
            this.filterList()
        })
    }
    // 门店分页
    orderChange = (page: number) => {
        this.setState({
            currentPage: page
        }, () => {
            this.filterList()
        })
    }

    searchFoodName = (searchText:string,text:string) => {
        let test = this.ch2Unicdoe(searchText)
        var pattern2 = `/[${test}]+/g`;
        var reg = RegExp(eval(pattern2))
        return reg.test(text)
    }

    isChinese = (s:string) =>{
        return /[\u4e00-\u9fa5]/.test(s);
    }

    ch2Unicdoe =(str:string) =>{
        if(!str){
          return;
        }
        var unicode = '';
        for (var i = 0; i <  str.length; i++) {
          var temp = str.charAt(i);
          if(this.isChinese(temp)){
            unicode += '\\u' +  temp.charCodeAt(0).toString(16);
          }
          else{
            unicode += temp;
          }
        }
        return unicode;
    }

    filterList = () => {
        let { storeList, storeName } = this.state;
        let newList:any = [];
        let newListTow:any = [];

        storeList.map((item:any) => {
            if(storeName !== ''){
                if(this.searchFoodName(storeName, item.name)){
                    newList.push(item)
                }
            }else{
                newList.push(item)
            }
        })

        newListTow = JSON.parse(JSON.stringify(newList));

        this.setState({
            showStoreList: newList,
            storeCount: newListTow.length
        })
    }

    handleSubmit = async () => {
        let { ruleList, shop_type, shop_ids, arrive_at, freeze, freeze_percentage, editorState, member_type, charge_value_least  } = this.state;
        let params:any = {
            arrive_at,
            shop_type,
            member_type
        }

        let flag = true;
        let msg = '请正确添加赠送规则!'
        if(ruleList){
            let fullList:any = [];
            ruleList.map((item: any) => {
                if(!item.full){
                    flag = false;
                    msg = '请输入充值金额'
                }
                if(item.money_check && !item.money){
                    flag = false;
                    msg = '请输入赠送金额'
                }
                if(item.full_check && item.coupon.length <= 0){
                    flag = false;
                    msg = '请选择赠送优惠券'
                }
                if(fullList.indexOf(item.full) < 0){
                    fullList.push(item.full)
                }else{
                    flag = false
                    msg = '每个档位充值金额不可一致'
                }
            })
        }
        if(!flag){
            message.warning({ content: msg, duration: 2 });
            return;
        }

        if(shop_type != 1 && shop_ids.length <= 0){
            message.warning('请选择可用门店')
            return;
        }

        if(freeze != 1 && !freeze_percentage){
            message.warning('请输入冻结百分比')
            return;
        }

        if((member_type == 1 || member_type == 2) && !charge_value_least){
            message.warning('请输入最少充值金额')
            return;
        }

        if(member_type == 1 || member_type == 2){
            params.charge_value_least = charge_value_least;
        }

        let list:any = []
        ruleList.map((item: any) => {
            let obj:any = {};
            obj.charge_fee = item.full;
            if(item.money){
                obj.present_fee = item.money
            }else{
                obj.present_fee = 0
            }
            if(item.coupon.length > 0){
                let arr:any = [];
                item.coupon.map((c: any) => {
                    arr.push(c.id)
                })
                obj.coupon = arr;
            }else{
                obj.coupon = []
            }
            if(item.id){
                obj.id = item.id;
            }
            if(item.is_effect){
                obj.is_effect = item.is_effect;
            }
            list.push(obj)
        })
        params.data = list

        if(shop_type == 2){
            params.shop_ids = shop_ids;
        }

        if(freeze == 2){
            params.freeze_percentage = freeze_percentage;
        }

        if(editorState){
            const htmlContent = this.state.editorState.toHTML();
            params.desc = htmlContent
        }
        let res: any = await postRequest(apiUrl.welfareConfigEditAll, params)
        if(res.code == 1){
            message.success(res.message);
            this.goBack()
        }else{
            message.error(res.message)
        }
    }

    // 返回上一页
    goBack = () => {
        this.props.history.goBack();
    }

    columns = [
        {
            title: '门店名称',
            dataIndex: 'name',
            key: 'name',
            className: 'list_center',
        },
        {
            title: '门店地址',
            dataIndex: 'address',
            key: 'address',
            className: 'list_center',
        }
    ];

    render(){
        let {  arrive_at, freeze, freeze_percentage, editorState, charge_value_least, member_type  } = this.state;

        let{ storeList, shop_ids, storeSelect, shop_type, } = this.state;

        let { ruleList, visible, selectList, listNum, current } = this.state;

        let rowSelection: any = {
            selectedRowKeys: shop_ids,
            onSelect: (record: any, selected: any, selectedRows: any) => {
                // let storeList:any = storeList;
                storeList.map((item: any) => {
                    if(item.id == record.id){
                        item.select = selected;
                    }
                })
                this.setState({
                    storeList
                })
            },
            onSelectAll: (selected: any, selectedRows: any, changeRows: any) => {
                // let productList:any[] = this.state.productList;
                storeList.map((item: any) => {
                    changeRows.map((item2: any) => {
                        if(item.id == item2.id){
                            item.select = selected;
                        }
                    })
                })

                this.setState({
                    storeList
                })
            },
            onChange: (selectedRowKeys:any, selectedRows: any) => {
                this.setState({
                    shop_ids: selectedRowKeys
                })
            },
            type: 'checkbox',
        };

        return (
            <div className='l_content'>
                <div className="edit_title">充值基本信息</div>
                <div>
                {
                    ruleList.map((item: any, index: number) => {
                        return <Row className='mb30' key={index} style={{overflow: 'hidden'}}>
                            <Col span={2}>第{index+1}档</Col>
                                <Col span={8}>
                                    <div style={{border: '1px solid rgba(0,0,0,0.15)'}} className={styles.rule_wrap}>
                                        <Row className='mb30' type='flex' justify='space-between' align='middle'>
                                            <Col>满 <Input style={{width: 120}} onChange={(e) => {this.fullChange('full', index, e)}} value={item.full} /> 元</Col>
                                            {ruleList && ruleList.length > 1 ? <Col><Icon type='delete' style={{fontSize: 20}} onClick={() => this.deleteRule(index)}></Icon></Col> : null}
                                        </Row>
                                        <Row type='flex' justify='start' align='middle' className={styles.mt20}>
                                            <Col className={styles.mr20}>
                                                <Checkbox className={styles.mr20} checked={item.money_check} onChange={(e) => {this.checkChange('money_check', index, e)}} />
                                                送 <Input style={{width: 120}} value={item.money} onChange={(e) => {this.fullChange('money', index, e)}} disabled={!item.money_check} /> 元余额</Col>
                                            <Col>
                                            <Checkbox className={styles.mr20} checked={item.full_check} onChange={(e) => {this.checkChange('full_check', index, e)}} />
                                            送券 <Button type='primary' disabled={!item.full_check} onClick={() => this.showTable(index)}>选择券</Button></Col>
                                        </Row>
                                        {
                                        item.coupon && item.coupon.length > 0
                                        ? <Table
                                            size="middle"
                                            rowKey={'id'}
                                            dataSource={item.coupon}
                                            columns={this.columnNum}
                                            style={{marginTop: '20px'}}
                                            pagination={false}
                                        />
                                        : null
                                    }
                                    </div>
                                </Col>
                            </Row>
                    })
                }
                </div>

                <div className='mb30' style={{display: 'flex', alignItems: 'center'}}>
                    <Button className={styles.mr20} type='primary' onClick={this.addRule}>添加档位</Button>
                    <div>注：最多可设置10个档位</div>
                </div>

                <div className="edit_title">使用设置</div>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="color_black">可用门店</span>
                        </div>
                    </Col>
                    <Col span={14}>
                        <Row type="flex" align="middle">
                            <Col>
                                <Radio.Group
                                    name='shop_type'
                                    value={shop_type}
                                    onChange={(e) => this.inputChange(e)}
                                >
                                    <Radio value={1}>全部门店</Radio>
                                    <Radio value={2}>部分门店</Radio>
                                </Radio.Group>
                            </Col>
                            {
                                shop_type === 2
                                ? <Col>
                                        <Button icon='plus' onClick={() => {this.setState({storeVisible: true})}}>选择门店</Button>
                                    </Col>
                                : null
                            }
                        </Row>
                    </Col>
                </Row>

                {
                    shop_type === 2 && storeSelect.length > 0
                    ? <Row>
                        <Col span={12} offset={2}>
                            <Table
                                dataSource={storeSelect}
                                columns={[
                                    {
                                        title: '门店名称',
                                        dataIndex: 'name',
                                        key: 'name',
                                        className: 'list_center',
                                    },
                                    {
                                        title: '门店地址',
                                        dataIndex: 'address',
                                        key: 'address',
                                        className: 'list_center',
                                    },
                                    {
                                        title: '操作',
                                        dataIndex: 'action',
                                        key: 'action',
                                        className: 'list_center',
                                        render: (rowdata:any, record: any, idx: number) => {
                                            return <div className='list_btn' onClick={() => this.deleteDish(record.id)}>删除</div>
                                        }
                                    }
                                ]}
                                rowKey={"id"}
                                size="middle"
                                locale={{emptyText: '暂无数据'}}
                                pagination={{
                                    showQuickJumper:true,
                                    defaultCurrent: 1,
                                    pageSize: 20,
                                }}/>
                        </Col>
                    </Row>
                    : null
                }

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">赠送金额到账时间</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <Radio.Group value={arrive_at} onChange={this.inputChange} name='arrive_at'>
                            <Radio value={1}>立即到账</Radio>
                            <Radio value={2}>次日到账</Radio>
                        </Radio.Group>
                    </Col>
                </Row>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">充值成为VIP会员</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <Radio.Group value={member_type} onChange={this.inputChange} name='member_type'>
                            <Radio value={0}>暂不配置</Radio>
                            <Radio value={1}>单次充值</Radio>
                            <Radio value={2}>累计充值</Radio>
                        </Radio.Group>
                    </Col>
                </Row>

                {
                    member_type == 1 || member_type == 2
                    ? <Row className="mb30" type="flex" align="middle">
                        <Col span={5} offset={2} style={{display: 'flex', alignItems: 'center'}}>
                            {member_type == 2 ? '累计' : ''}最少充值 <Input addonAfter='元' style={{margin: '0 10px', width: '120px'}} value={charge_value_least} name='charge_value_least' onChange={this.inputChange} /> 成为VIP会员
                        </Col>
                        <div style={{color: '#A8A8A8', fontSize: '12px'}}>成为VIP会员可享受商品会员价</div>
                    </Row>
                    : null
                }

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">余额使用规则：</span>
                        </div>
                    </Col>
                    <Col>
                        <Radio.Group value={freeze} onChange={this.inputChange} name='freeze'>
                            <Radio value={1}>无限制</Radio>
                            <Radio value={2}>限制</Radio>
                        </Radio.Group>
                    </Col>
                    <Col>
                        {
                            freeze == 2
                            ?   <div>”首次充值的当天” 不可使用金额的
                                    <Input onChange={this.inputChange} value={freeze_percentage} type="text" name='freeze_percentage' style={{width: '100px',textAlign: 'center',marginLeft: '5px',marginRight: '5px'}}/> %不可使用，其余部分均可以使用。
                                    <Tooltip title={this.onToastRul}>
                                        <span style={{color: '#02A7F0'}}>例如</span>
                                    </Tooltip>
                                </div>
                            : ''
                        }
                    </Col>
                </Row>

                <div className="wrap">
                    <Row>
                        <Col span={2}>
                            <div>
                                <span className="color_black">充值规则</span>
                            </div>
                        </Col>
                        <Col span={14}>
                            <div className="editor_wrap">
                                <BraftEditor
                                    value={editorState}
                                    onChange={this.handleEditorChange}
                                    excludeControls={['media']}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>

                <Row>
                    <Col span={12}>
                        <div className="bottom_wrap">
                            <Button type="primary" ghost onClick={this.goBack}>
                                取消
                            </Button>
                            <Button type="primary" onClick={this.handleSubmit}>
                                保存
                            </Button>
                        </div>
                    </Col>
                </Row>

                <Modal
                    title="指定门店"
                    visible={this.state.storeVisible}
                    okText='确定'
                    cancelText='取消'
                    onOk={this.handleOk}
                    onCancel={() => {this.setState({  storeVisible: false, });}}
                    >
                    <Row type="flex" align="middle" justify="start" className="mb20" >
                        <Col className="mr30">
                            <Input placeholder="请输入关键字" onChange={this.searchAction} style={{ width: 234 }}/>
                        </Col>
                    </Row>

                    <div>
                        <Table
                        dataSource={this.state.showStoreList}
                        columns={this.columns}
                        rowKey={"id"}
                        size="middle"
                        locale={{emptyText: '暂无数据'}}
                        rowSelection={rowSelection}
                        pagination={{
                            current: this.state.storePage,
                            showQuickJumper:true,
                            defaultCurrent: 1,
                            total: this.state.storeCount,
                            pageSize: this.state.storeSize,
                            hideOnSinglePage: true,
                            onChange: this.orderChange
                        }}/>
                    </div>
                </Modal>

                <Modal
                title={null}
                visible={visible}
                onOk={() => {this.setState({ visible: false })}}
                onCancel={()=>{this.setState({visible: false})}}
                width="70vw"
            >
                <div className="model_head">
                    <div className="head_title">关联优惠券</div>
                </div>
                <Table
                    size="middle"
                    rowKey={"id"}
                    dataSource={selectList}
                    columns={this.couponColumns}
                    pagination={
                        {
                            current,
                            total: listNum,
                            onChange: this.changePage,
                            pageSize: 10
                        }
                    }
                />
            </Modal>
            </div>
        )
    }

}

export default RechargeAddOrEdit