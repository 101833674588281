import React from "react";
import { Menu, Breadcrumb } from "antd";
import HelpList from './helpList'
import InviteHelpUsers from  '../weapp_invite_help/invite_help_users/invite_help_users'

class HelpCenter extends React.Component<any> {
    state = {
        current: 'help',
    }

    handleClick = (e: any) => {
        this.setState({
          current: e.key,
        });
    };

    render(){
        let { current } = this.state;
        return <div className='l_content'>
             <Breadcrumb separator='>' className='mb20'>
                <Breadcrumb.Item>吸引粉丝</Breadcrumb.Item>
                <Breadcrumb.Item>助力有礼</Breadcrumb.Item>
            </Breadcrumb>
            <Menu onClick={this.handleClick} selectedKeys={[this.state.current]} mode="horizontal">
                <Menu.Item key="help">
                助力配置
                </Menu.Item>
                <Menu.Item key="user">
                参与用户
                </Menu.Item>
            </Menu>

            <div style={{marginTop: 20}}>
                {
                    current === 'help'
                    ? <HelpList history={this.props.history}/>
                    : null
                }
                {
                    current === 'user'
                    ? <InviteHelpUsers tabIdx={1} />
                    : null
                }
            </div>
        </div>;
    }
}
export default HelpCenter