import React from 'react';
import { Link } from "react-router-dom";

import { Layout, Menu, Breadcrumb, Row, Button } from 'antd';
import { withRouter, RouteComponentProps, Switch, Route } from "react-router-dom";
import HelpAdvertManagementList from './advertManagement/advertManagementList/helpAdvertManagementList'
import HelpAdvertManagementListDetail from './advertManagement/advertManagementList/helpAdvertManagementListDetail'
import HelpAdminNum from './authorityManagement/adminNum/helpAdminNum'
import HelpAdminNumDetail from './authorityManagement/adminNum/helpAdminNumDetail'
import HelpSystemNum from './authorityManagement/systemNum/helpSystemNum'
import HelpSystemNumDetail from './authorityManagement/systemNum/helpSystemNumDetail'
import HelpMealManagement from './commodityAdmin/mealManagement/helpMealManagement'
import HelpMealManagementDetail from './commodityAdmin/mealManagement/helpMealManagementDetail'
import HelpShopManagement from './commodityAdmin/shopManagement/helpShopManagement'
import HelpShopManagementDetail from './commodityAdmin/shopManagement/helpShopManagementDetail'
import HelpTypeManagement from './commodityAdmin/typeManagement/helpTypeManagement'
import HelpTypeManagementDetail from './commodityAdmin/typeManagement/helpTypeManagementDetail'
import HelpLocationManagement from './deskManagement/locationManagement/helpLocationManagement'
import HelpLocationManagementDetail from './deskManagement/locationManagement/helpLocationManagementDetail'
import HelpTableManager from './deskManagement/tableManager/helpTableManager'
import HelpTableManagerDetail from './deskManagement/tableManager/helpTableManagerDetail'
import HelpMbaBirthday from './mbaManagement/mbaManagementTools/helpMbaBirthday'
import HelpMbaBusiness from './mbaManagement/mbaManagementTools/helpMbaBusiness'
import HelpMbaBuyFree from './mbaManagement/mbaManagementTools/helpMbaBuyFree'
import HelpMbaChargeFree from './mbaManagement/mbaManagementTools/helpMbaChargeFree'
import HelpMbaCoupon from './mbaManagement/mbaManagementTools/helpMbaCoupon'
import HelpMbaCourtesy from './mbaManagement/mbaManagementTools/helpMbaCourtesy'
import HelpMbaDiscount from './mbaManagement/mbaManagementTools/helpMbaDiscount'
import HelpMbaExchange from './mbaManagement/mbaManagementTools/helpMbaExchange'
import HelpMbaInterests from './mbaManagement/mbaManagementTools/helpMbaInterests'
import HelpMbaManagementTools from './mbaManagement/mbaManagementTools/helpMbaManagementTools'
import HelpMbaSecurities from './mbaManagement/mbaManagementTools/helpMbaSecurities'
import HelpMbaWelcome from './mbaManagement/mbaManagementTools/helpMbaWelcome'
import HelpStoreManagementList from './storeManagement/storeManagementList/helpStoreManagementList'
import HelpStoreManagementListDetail from './storeManagement/storeManagementList/helpStoreManagementListDetail'

import './helpCenter.scss';

const { SubMenu } = Menu;
const { Content, Sider } = Layout;

type SelectStore = RouteComponentProps & {
    history: any,
}


export class HelpCenter extends React.Component<SelectStore,{}>{

    state = {
        siderList: [
            {
                id: 1,
                title: '商品管理',
                childs: [
                    {
                        id: 11,
                        title: '分类管理',
                        path: '/helpCenter/HelpTypeManagement',
                    },
                    {
                        id: 12,
                        title: '商品管理',
                        path: '/helpCenter/HelpShopManagement',
                    },
                    {
                        id: 13,
                        title: '套餐管理',
                        path: '/helpCenter/HelpMealManagement',
                    },
                ]
            },
            {
                id: 2,
                title: '桌台管理',
                childs: [
                    {
                        id: 21,
                        title: '位置管理',
                        path: '/helpCenter/HelpLocationManagement',
                    },
                    {
                        id: 22,
                        title: '餐桌管理',
                        path: '/helpCenter/HelpTableManager',
                    },
                ]
            },
            {
                id: 3,
                title: '权限管理',
                childs: [
                    {
                        id: 31,
                        title: '管理者账号',
                        path: '/helpCenter/HelpAdminNum',
                    },
                    {
                        id: 32,
                        title: '系统角色',
                        path: '/helpCenter/HelpSystemNum',
                    },
                ]
            },
            {
                id: 4,
                title: '门店管理',
                childs: [
                    {
                        id: 41,
                        title: '门店列表',
                        path: '/helpCenter/HelpStoreManagementList',
                    },
                ]
            },
            {
                id: 5,
                title: '营销管理',
                childs: [
                    {
                        id: 51,
                        title: '营销工具',
                        path: '/helpCenter/HelpMbaManagementTools',
                    },
                ]
            },
            {
                id: 6,
                title: '广告管理',
                childs: [
                    {
                        id: 61,
                        title: '广告列表',
                        path: '/helpCenter/HelpAdvertManagementList',
                    },
                ]
            },
        ],
        allComments: [
            { path: '/helpCenter/HelpAdvertManagementList', name: '广告列表', component: HelpAdvertManagementList }, //广告列表
            { path: '/helpCenter/HelpAdvertManagementListDetail', name: 'HelpAdvertManagementListDetail', component: HelpAdvertManagementListDetail },
            { path: '/helpCenter/HelpAdminNum', name: '管理者账号', component: HelpAdminNum }, //管理者账号
            { path: '/helpCenter/HelpAdminNumDetail', name: 'HelpAdminNumDetail', component: HelpAdminNumDetail },
            { path: '/helpCenter/HelpSystemNum', name: '系统角色', component: HelpSystemNum }, //系统角色
            { path: '/helpCenter/HelpSystemNumDetail', name: 'HelpSystemNumDetail', component: HelpSystemNumDetail },
            { path: '/helpCenter/HelpMealManagement', name: '套餐管理', component: HelpMealManagement },  //套餐管理
            { path: '/helpCenter/HelpMealManagementDetail', name: 'HelpMealManagementDetail', component: HelpMealManagementDetail },
            { path: '/helpCenter/HelpShopManagement', name: '商品管理', component: HelpShopManagement }, //商品管理
            { path: '/helpCenter/HelpShopManagementDetail', name: 'HelpShopManagementDetail', component: HelpShopManagementDetail },
            { path: '/helpCenter/HelpTypeManagement', name: '分类管理', component: HelpTypeManagement }, //分类管理
            { path: '/helpCenter/HelpTypeManagementDetail', name: 'HelpTypeManagementDetail', component: HelpTypeManagementDetail },
            { path: '/helpCenter/HelpLocationManagement', name: '位置管理', component: HelpLocationManagement }, // 位置管理
            { path: '/helpCenter/HelpLocationManagementDetail', name: 'HelpLocationManagementDetail', component: HelpLocationManagementDetail },
            { path: '/helpCenter/HelpTableManager', name: '餐桌管理', component: HelpTableManager }, // 餐桌管理
            { path: '/helpCenter/HelpTableManagerDetail', name: 'HelpTableManagerDetail', component: HelpTableManagerDetail },
            { path: '/helpCenter/HelpMbaBirthday', name: '生日礼物', component: HelpMbaBirthday }, //生日礼物
            { path: '/helpCenter/HelpMbaBusiness', name: '商家工具', component: HelpMbaBusiness }, // 商家工具
            { path: '/helpCenter/HelpMbaBuyFree', name: '添加买赠', component: HelpMbaBuyFree }, //添加买赠
            { path: '/helpCenter/HelpMbaChargeFree', name: '充值免单', component: HelpMbaChargeFree }, //充值免单
            { path: '/helpCenter/HelpMbaCoupon', name: '添加代金券', component: HelpMbaCoupon }, //添加代金券
            { path: '/helpCenter/HelpMbaCourtesy', name: '助力有礼', component: HelpMbaCourtesy }, // 助力有礼
            { path: '/helpCenter/HelpMbaDiscount', name: '添加优惠券', component: HelpMbaDiscount }, //添加优惠券
            { path: '/helpCenter/HelpMbaExchange', name: '添加兑换', component: HelpMbaExchange }, //添加兑换
            { path: '/helpCenter/HelpMbaInterests', name: '添加权益', component: HelpMbaInterests }, // 添加权益
            { path: '/helpCenter/HelpMbaManagementTools', name: '营销工具', component: HelpMbaManagementTools }, //营销工具
            { path: '/helpCenter/HelpMbaSecurities', name: '直接发券', component: HelpMbaSecurities }, //直接发券
            { path: '/helpCenter/HelpMbaWelcome', name: '进店有礼', component: HelpMbaWelcome }, //进店有礼
            { path: '/helpCenter/HelpStoreManagementList', name: '门店列表', component: HelpStoreManagementList }, // 门店列表
            { path: '/helpCenter/HelpStoreManagementListDetail', name: 'HelpStoreManagementListDetail', component: HelpStoreManagementListDetail },
        ]
    }
    

    componentDidMount() {
        
    }

    render() {

        const { siderList, allComments } = this.state;

        return (
            <div>
                <div className="helpTop">
                    <div className="helpText">
                        <Link style={{color: '#3975FF', cursor: 'pointer'}} to={'/sidebar/index'}> &lt; 返回</Link>   |   <span>帮助中心</span>
                    </div>
                </div>
                <Layout className="helpCenter">
                    <Layout>
                        <Sider width={200} className="site-layout-background" style={{
                            
                            backgroundColor: "#fff",
                            }}>
                            <Menu
                                mode="inline"
                                defaultSelectedKeys={['1']}
                                defaultOpenKeys={['sub1']}
                                style={{backgroundColor: "#fff",}}
                                className="site-layout-menu"
                            >
                                {
                                    siderList.map((item:any, index: any) => {
                                        return (
                                            item.childs && item.childs.length > 0
                                            ? <SubMenu key={item.id} title={item.title} style={{backgroundColor: "#fff",}}>
                                                {
                                                    item.childs.map((subItem: any, subIndex:any) => {
                                                        return (
                                                            <Menu.Item key={subItem.id}>
                                                                <Link to={subItem.path}>{subItem.title}</Link>
                                                            </Menu.Item>
                                                        )
                                                    })
                                                }
                                            </SubMenu>
                                            : <Menu.Item key={item.id}><Link to={item.path}>{item.title}</Link></Menu.Item>
                                        )
                                    })
                                }
                            </Menu>
                        </Sider>
                        <Layout style={{ padding: '0 24px 24px', backgroundColor: '#fff' }}>
                            {/* <Breadcrumb style={{ margin: '16px 0' }}>
                                <Breadcrumb.Item>
                                    <Link style={{color: '#3975FF', cursor: 'pointer'}} to={'/sidebar/index'}>返回</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>帮助中心</Breadcrumb.Item>
                            </Breadcrumb> */}
                            <Layout.Content style={{ margin: "16px" }}>
                                <Row className="l_content">
                                <Switch>
                                    {
                                        allComments.map((item, index) =>
                                            {
                                                return  <Route key={index} path={item.path} exact component={item.component} />
                                            }
                                        )
                                    }
                                </Switch>
                                </Row>
                            </Layout.Content>
                            {/* <Content
                                className="site-layout-background"
                                style={{
                                    padding: 24,
                                    margin: 0,
                                    minHeight: 280,
                                }}
                            >
                                Content
                            </Content> */}
                        </Layout>
                    </Layout>
                </Layout>
            </div>
        )
    }
}

export default withRouter(HelpCenter);
//export default SelectStore;