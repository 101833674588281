import {observer} from "mobx-react";
import React from "react";
import {Button, Input, message, Row, Col, Modal, Radio, Icon, Table, Tree, Tooltip, Select} from "antd";
import { postRequest, getRequest } from '../../api/http';
import { RangePickerValue } from "antd/lib/date-picker/interface";
import BraftEditor from 'braft-editor'
// 引入编辑器样式
import 'braft-editor/dist/index.css'
import { apiUrl } from '../../api/api_url';
const { TreeNode } = Tree;
const { Option } = Select;

const key = 'BirthEdit';

@observer
class BirthEdit extends React.Component<any> {

    columnNum:any = [
        {
            title: '优惠券名称',
            dataIndex: 'name',
            key: 'name',
            align: "center",
        },
        {
            title: '券类型',
            dataIndex: 'type',
            key: 'type',
            align: "center",
            render: (text:any,record:any,) => (
                <div className="color">{record.info.type === 'discount' ? '折扣券' : record.info.type === 'voucher' ? '优惠券' : '兑换券'}</div>
            ),
        },
        {
            title: '券有效期',
            dataIndex: 'created_at',
            key: 'created_at',
            align: "center",
            render: (text:any,record:any,) => {
                let info:any = record.expire_info;
                return <div className="color">{info.type === 'time' ?  info.start_time + '至' + info.end_time : '自领取日'+info.day + '天领取'}</div>
            }
        },
        {
            title: '券份数',
            dataIndex: 'num',
            key: 'num',
            align: "center",
            render: (text:any,record:any,) => {
                return <div className="back_wrap">
                        <div className="back_btn reduce_btn active" onClick={() => this.reduceCoupon(record.id)}>-</div>
                        <div className="good_num">{record.coupon_num}</div>
                        <div className="back_btn add_btn active" onClick={() => this.addCoupon(record.id)}>+</div>
                    </div>
            }
        },
        {
            title: '操作',
            align: "center",
            render: (text: string,record:any,index:number)=>(
                <div className="table_action" onClick={() => this.unBindShop(index)}>移除</div>
            )
        },
    ];

    state = {
        editorState: BraftEditor.createEditorState(null),
        loading: false,
        name: '',
        status: 1,
        visible: false,
        couponList: [],
        listNum: 0,
        current: 1,
        selectCoupon: [],
        type: 'birthday',
        selectDisabled: false,
        typeName: '',
        user_type: [],
        pageSize: 100,
        selectList: [],
        advance_day: 0,
        dayList: [
            {
                name: '前1天',
                value: 1
            },
            {
                name: '前2天',
                value: 2
            },
            {
                name: '前3天',
                value: 3
            },
            {
                name: '前4天',
                value: 4
            },
            {
                name: '前5天',
                value: 5
            },
            {
                name: '前6天',
                value: 6
            },
            {
                name: '前7天',
                value: 7
            },
            {
                name: '前8天',
                value: 8
            },
            {
                name: '前9天',
                value: 9
            },
            {
                name: '前10天',
                value: 10
            }
        ]
    }

    componentDidMount () {
        const {location} = this.props;
        if(location.state !== undefined){
            this.initEdit(location.state.id);
        }
        this.onInitList();
    }

    // 初始化优惠券列表
    onInitList = async () => {
        let param = {
            current: 1,
            pageSize: 100,
            position: 3
        }
        const res:any = await getRequest(apiUrl.couponList, param);
        if(res.code === 1){
            this.setState({
                couponList: res.data.list,
                listNum: res.data.total,
                current: 1,
                selectList: res.data.list.slice(0, 10)
            })
        }
    }

    // 初始化编辑
    initEdit = async (id:number) => {
        const params = {
            id: id
        };
        const res:any = await getRequest(apiUrl.welfareEditInfo,params);
        if(res.code === 1){
            this.setState({
                name: res.data.name,
                status: res.data.status,
                selectCoupon: res.data.coupon,
                user_type: res.data.user_type,
                advance_day: res.data.advance_day,
                editorState: BraftEditor.createEditorState(res.data.tips)
            })
        }
    }

    addCoupon = (id: any) => {
        let selectCoupon = this.state.selectCoupon;
        selectCoupon.map((item: any) => {
            if(item.id === id){
                item.coupon_num++;
            }
        })
        this.setState({
            selectCoupon
        })
    }

    reduceCoupon = (id: any) => {
        let selectCoupon = this.state.selectCoupon;
        selectCoupon.map((item: any) => {
            if(item.id === id){
                if(item.coupon_num === 1){
                    return;
                }
                item.coupon_num--;
            }
        })
        this.setState({
            selectCoupon
        })
    }

    timeChange = (time: number) => {
        this.setState({
            advance_day: time
        })
    }

    nameChange = (e:any) => {
        let name = e.target.name;
        let value = e.target.value;
        this.setState({
            [name]: value.trim()
        })
    }

    statusChange = (e:any) => {
        this.setState({
            status: e
        })
    }

    onCheck = (checkedKeys: any, info: any) => {
        let keyList = checkedKeys;
        let user_type: any[] = [];
        keyList.map((item: any) => {
            if(item !== '4'){
                user_type.push(item)
            }
        })
        this.setState({
            user_type
        })
    };

    handleSubmit = async () => {
        message.loading({ content: '发布中', key });

        const {location} = this.props;
        const { name, type, status, selectCoupon, user_type, advance_day, } = this.state;
        var tableInfo:any = {};
        let coupon:any[] = [];

        if(location.state !== undefined){
            tableInfo.id = location.state.id;
        }

        if(name === ''){
            message.warning({ content: '请输入活动名称!', key, duration: 2 });
            return;
        }

        if(selectCoupon.length === 0){
            message.warning({ content: '请关联优惠券!', key, duration: 2 });
            return;
        }

        if(user_type.length === 0){
            message.warning({ content: '请选择可见用户!', key, duration: 2 });
            return;
        }
        if(advance_day === 0){
            message.warning({ content: '请选择发送时间!', key, duration: 2 });
            return;
        }

        tableInfo.name = name;
        tableInfo.status = status;
        tableInfo.type = type;

        const htmlContent = this.state.editorState.toHTML();
        tableInfo.tips = htmlContent;
        tableInfo.user_type = user_type;
        tableInfo.advance_day = advance_day;


        selectCoupon.forEach((item:any) => {
            if(item.limit == undefined){
                item.limit = '';
            }
            coupon.push({
                id: item.id,
                limit: item.limit,
                coupon_num: item.coupon_num,
                people_num: item.people_num
            })
        })

        tableInfo.coupon = coupon;

        if(location.state !== undefined){
            const res:any = await postRequest(apiUrl.welfareEdit,tableInfo);
            if(res['code'] === 1){
                message.success({ content: '修改成功!', key, duration: 2 });
                this.goBack()
            }else{
                message.error({ content: '修改失败!', key, duration: 2 });
            }
        }else{
            const res:any = await postRequest(apiUrl.welfareAdd,tableInfo);
            if(res['code'] === 1){
                message.success({ content: '发布成功!', key, duration: 2 });
                this.goBack()
            }else{
                message.error({ content: '发布失败!', key, duration: 2 });
            }
        }
    };

    // 返回上一页
    goBack = () => {
        this.props.history.goBack();
    }

    changePage = async (page: number) => {
        let { couponList } = this.state;
        let list = couponList.slice((page-1)*10, (10*page));
        this.setState({
            selectList: list,
            current: page
        })
    }

    // 关联
    handleBindShop = (id:number) => {
        let { type } = this.state;
        let selectCoupon:any[] = this.state.selectCoupon;
        let couponList:any[] = this.state.couponList;
        couponList.map((item) => {
            if(item.id === id){
                item.selected = true;
                item.coupon_num = 1;
                selectCoupon.push(item)
            }
        })
        console.log(selectCoupon);
        this.setState({
            selectCoupon,
            couponList
        })
    }

    // 解除关联
    unBindShop = (index: number) => {

        let { couponList } = this.state;
        let selectCoupon: any = this.state.selectCoupon;
        couponList.map((item: any) => {
            if(item.id === selectCoupon[index].id){
                item.selected = false;
            }
        })
        selectCoupon.splice(index, 1)
        this.setState({
            couponList,
            selectList: couponList.slice(0, 10),
            current: 1,
            selectCoupon
        })
    }

    limitChange = (e:any,index:any) => {
        let selectCoupon:any = this.state.selectCoupon;
        if(e){
            selectCoupon[index].limit = 'zhuli';
        }else{
            selectCoupon[index].limit = '';
        }

        this.setState({
            selectCoupon
        })
    }

    // 助力人数
    numChange = (e: any,index:number) => {
        let selectCoupon:any = this.state.selectCoupon;

        selectCoupon[index].people_num = e;

        this.setState({
            selectCoupon
        })
    }

    handleOk = () => {
        this.setState({
            visible: false
        })
    }

    // 创建 商品列表
    columns:any = [
        {
            title: '优惠券名称',
            dataIndex: 'name',
            key: 'name',
            align: "center",
        },
        {
            title: '券类型',
            dataIndex: 'type',
            key: 'id',
            align: "center",
            render: (text:any,record:any,) => (
                <div className="color">{record.info.type === 'discount' ? '折扣券' : record.info.type === 'voucher' ? '优惠券' : '兑换券'}</div>
            ),
        },
        {
            title: '券有效期',
            dataIndex: 'created_at',
            key: 'created_at',
            align: "center",
            render: (text:any,record:any,) => {
                let info:any = record.expire_info;
                return <div className="color">{info.type === 'time' ?  '自'+info.start_time + '至' + info.end_time : '自领取日'+info.day + '天内有效'}</div>
            }
        },
        {
            title: '操作',
            align: "center",
            render: (text: string,record:any,index:number)=>{
                return <div>
                    {
                        record.selected
                        ? '已关联'
                        : <div className="table_action" onClick={() => this.handleBindShop(record.id)}>关联</div>
                    }
                </div>
            }
        },
    ];

    handleEditorChange = (editorState:any) => {
        this.setState({ editorState })
    }

    // 显示关联
    showTable = () => {
        let { couponList, selectCoupon} = this.state;
        couponList.map((coupon: any) => {
            selectCoupon.map((select: any) => {
                if(coupon.id === select.id){
                    coupon.selected = true;
                }
            })
        })
        this.setState({
            couponList,
            selectList: couponList.slice(0, 10),
            current: 1,
            visible: true
        })
    }

    render(){
        const {dayList, editorState, name, status, visible, selectCoupon, user_type, selectList, current, listNum, advance_day } = this.state;

        return <div className='l_content'>
                <div className="edit_title">活动基本信息</div>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">活动名称</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <Input placeholder="请输入活动名称" name='name' value={name} onChange={(e) => this.nameChange(e)}/>
                    </Col>
                </Row>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">活动礼品</span>
                        </div>
                    </Col>
                    <Col span={16}>
                        <div onClick={this.showTable}>
                             <Icon type="plus-circle" style={{fontSize: '20px',marginRight: '10px'}} />
                             关联优惠券
                        </div>

                        {
                            selectCoupon.length > 0
                            ? <Table
                                size="middle"
                                rowKey={"id"}
                                dataSource={selectCoupon}
                                columns={this.columnNum}
                                style={{marginTop: '20px'}}
                                pagination={
                                    {
                                        hideOnSinglePage: true,
                                        total: selectCoupon.length,
                                    }
                                }
                            />
                            : null
                        }

                    </Col>
                </Row>

                <Row className="mb30" type="flex" align="top">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">参与对象</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <Tree
                            checkable
                            showIcon
                            onCheck={this.onCheck}
                            defaultSelectedKeys={user_type}
                            checkedKeys={user_type}
                        >
                            <TreeNode title="新用户" key="1" icon={
                                <Tooltip placement="topLeft" title={"还未注册的用户或已经注册还没有下单的用户"}>
                                <Icon type="question-circle" />
                                </Tooltip>
                            }>
                            </TreeNode>
                            <TreeNode title="老顾客" key="4" icon={
                                <Tooltip placement="topLeft" title={"交易次数>=1次的交易次数"}>
                                <Icon type="question-circle" />
                                </Tooltip>
                            }>
                                <TreeNode title="充值用户" key="2" />
                                <TreeNode title="非充值用户" key="3" />
                            </TreeNode>
                        </Tree>
                    </Col>
                </Row>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">发放时间</span>
                        </div>
                    </Col>
                    <Col className='mlr10'>生日</Col>
                    <Col className='mlr10' span={4}>
                            <Select onChange={this.timeChange} style={{width: '150px'}} placeholder='请选择时间' value={advance_day}>
                                {
                                    dayList.map((item: any) => {
                                        return <Option value={item.value} key={item.value}>{item.name}</Option>
                                    })
                                }
                            </Select>
                    </Col>
                </Row>

                <div className="wrap">
                    <Row>
                        <Col span={2}>
                            <div>
                                <span className="star"></span>
                                <span className="color_black">使用须知</span>
                            </div>
                        </Col>
                        <Col span={14}>
                            <div className="editor_wrap">
                                <BraftEditor
                                    value={editorState}
                                    onChange={this.handleEditorChange}
                                    excludeControls={['media']}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">状态</span>
                        </div>
                    </Col>
                    <Col span={22}>
                        <Radio.Group
                            name='status'
                            value={status}
                            onChange={(e) => this.statusChange(e.target.value)}
                        >
                            <Radio value={1}>开启</Radio>
                            <Radio value={0}>暂停</Radio>
                        </Radio.Group>
                    </Col>
                </Row>

                <Row>
                    <Col span={12}>
                        <div className="bottom_wrap">
                            <Button type="primary" ghost onClick={this.goBack}>
                                取消
                            </Button>
                            <Button type="primary" onClick={this.handleSubmit}>
                                保存
                            </Button>
                        </div>
                    </Col>
                </Row>

            <Modal
                title={null}
                visible={visible}
                onOk={this.handleOk}
                onCancel={()=>{this.setState({visible: false})}}
                width="70vw"
            >
                <div className="model_head">
                    <div className="head_title">关联优惠券</div>
                </div>
                <Table
                    size="middle"
                    rowKey={"id"}
                    dataSource={selectList}
                    columns={this.columns}
                    pagination={
                        {
                            current,
                            total: listNum,
                            onChange: this.changePage,
                            pageSize: 10
                        }
                    }
                />
            </Modal>

        </div>;
    }

}
export default BirthEdit