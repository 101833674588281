import React from 'react'
import { Form, Icon, Input, Button, Typography, Row, Col, Spin, message } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IAppStore } from '../../stores/app.store';
import { inject, observer } from 'mobx-react';
import { postRequest } from '../../api/http';
import {apiUrl} from "../../api/api_url";
import { routerConfig } from '../../config/router';

interface ILogin extends FormComponentProps, RouteComponentProps {
  appStore?: IAppStore
}

const { Text } = Typography;
type IState = {
  isLoading: boolean
}

@inject('appStore')
@observer
class LoginForm extends React.Component<ILogin,IState> {

  constructor(props:ILogin){
    super(props);
    this.state = {
      isLoading: false
    }
  }

  // 提交登录
  handleSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    this.setState({isLoading: true})
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const res: any = await postRequest(apiUrl.login, values);
        if (res.code == 1) {
          // /sidebar/windowManage
          this.setState({isLoading: false})
          if(process.env.REACT_APP_ChromeApp === "1") {
            window.localStorage.setItem('shop_list',JSON.stringify(res.data.shop_list));
            window.localStorage.setItem('user_info',JSON.stringify(res.data.user_info));
            this.props.history.push('/store');
          } else {
            // window.localStorage.setItem('siyu_tab_list',JSON.stringify(this.collectMenus(res.data.permissions))); // todo 隐藏初始化导航
            window.localStorage.setItem('shop_list',JSON.stringify(res.data.shop_list));
            window.localStorage.setItem('user_info',JSON.stringify(res.data.user_info));
            this.props.history.push('/store');
          }
          localStorage.setItem('phone', values['mobile']);
          localStorage.setItem('token', `${res['data']['token']}`);
          //新版本  店铺为多个
          //localStorage.setItem('shop_id', res.data.shop_info.shop_id);
          this.props.appStore?.setPhone(values['mobile']);
        } else {
          this.setState({isLoading: false});
          message.warn(`${res.message}`)
        }
      }
    })
  }

  componentDidMount() {
    this.props.form.validateFields();
  }

  // 整理tabbar
  collectMenus = (permissons:any) =>{
    permissons.map((item: any) =>{
      if(item.routers){
        this.collectMenus(item.routers);
      }else{
        let objKey = item.comment;
        let commentObj:any = routerConfig.commentObj;
        item.comment = commentObj[objKey];
        item.path = '/sidebar'+item.path;
      }
    })
    return permissons;
  }

  hasErrors(fieldsError: { [x: string]: unknown; }) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
  }

  // 注册
  goRegister = () => {
    this.props.history.push('/register')
  }
  render() {
    const { isLoading } = this.state;
    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;
    const phoneError = isFieldTouched('mobile') && getFieldError('mobile');
    const passWorldCodeError = isFieldTouched('password') && getFieldError('password');

    return <div>
        <Row type="flex" justify="center" align="middle" style={{ minHeight: '100vh', minWidth: '10vw' }}>
          <Form className="login-form" style={{ width: '350px' }} onSubmit={this.handleSubmit}>
            <Form.Item validateStatus={phoneError ? 'error' : ''} help={phoneError || ''}>
              {
                getFieldDecorator('mobile', {
                  rules: [{ required: true, message: '请输入手机号' }],
                  initialValue: ""
                })(<Input
                  size="large"
                  prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="请输入手机号"
                />)
              }
            </Form.Item>
            <Form.Item validateStatus={passWorldCodeError ? 'error' : ''} help={passWorldCodeError || ''}>
              {
                getFieldDecorator('password', {
                  rules: [{ required: true, message: "请输入密码" }],
                  initialValue: ""
                })(<Input
                  size="large"
                  prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  type="password"
                  placeholder="请输入密码"
                />)
              }
            </Form.Item>
            <Button type="primary" htmlType="submit" size="large" style={{ width: '350px' }} loading={isLoading} disabled={this.hasErrors(getFieldsError())}>登 录</Button>
            {/* <Row align="bottom" onClick={() => this.goRegister()}>
              <Col span={8}></Col>
              <Col span={8} offset={20}><Text style={{ color: '#1890ff' }}>免费注册</Text></Col>
            </Row> */}
          </Form>
        </Row>
      </div>
  }
}

export default withRouter(Form.create<ILogin>({})(LoginForm))