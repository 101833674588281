import React, { useEffect, useState } from "react";
import { getRequest } from "./../../api/http";
import { apiUrl } from "./../../api/api_url";
import {Row,Col,Table, Input} from 'antd'
import './recharge.scss'
import moment from 'moment'
import 'moment/locale/zh-cn'
import CustomDatePicker from "../datePicker";
import goodsExport from '../../assret/images/goodsExport.png'
moment.locale('zh-cn')


const { Search } = Input;

interface IsearchData {
    [key: string]: any,
}

const searchData: IsearchData = {
    nickname: '',
    out_trade_no: '',
    phone_number: 0,
    start_time: 0,
    end_time: 0,
}

interface IProps {
    location: {
        query?: {
            day: string,
            close_at: string,
            open_at: string
        }
    }
}

const Recharge = (props: IProps)=>{

    const [rechargeList,setRechargeList] = useState([])
    const [totalCount,settotalCount] = useState(0)
    const [current,setcurrent] = useState(1)
    const [pageSize,setpageSize] = useState(10)
    const [start_at,setstart_at] = useState('')
    const [end_at,setend_at] = useState('')
    const [currentDate,setcurrentDate] = useState(0)
    const [charge_info, setcharge_info] = useState([
        {
            'title':'充值总会员数',
            'count': 0,
            'new': 0,
            'add_title': '新增人数：'
        },
        {
            'title':'充值订单总数',
            'count': 0,
            'new': 0,
            'add_title': '新增订单数：'
        },
        {
            'title':'实收充值总金额',
            'count': 0,
            'new': 0,
            'add_title': '新增：￥'
        },
        {
            'title':'赠送总金额',
            'count': 0,
            'new': 0,
            'add_title': '新增：￥'
        }
    ])

    useEffect(()=>{
        const nowDate = new Date();
        let toDay = `${nowDate.getFullYear()}-${formatData(nowDate.getMonth() + 1)}-${formatData(nowDate.getDate())}`;
        setstart_at(toDay)
        setend_at(toDay)
        if(props.location.query && props.location.query.open_at){
            initData()
            initCount({start_at: toDay, end_at: toDay})
        }else{
            initData({page: 1, pageSize})
            initCount({start_at: toDay, end_at: toDay})
        }
    },[])

    const formatData = (num: number) => {
        return num > 9 ? num : `0${num}`
    }

    const initData = async (param?: {})=>{
        let params:any = {}
        params = {...param, }
        getRechargeList(param).then((res) => {
            let list = res.data.list ? res.data.list : []
            let total = res.data.list ? res.data.total : 0
            setRechargeList((data) => data = list)
            settotalCount((data) => data = total)
            setcurrent(params && params.page ? params.page : 1)
        })
    }

    const initCount = async (param?: {}) => {
        let params:any = {}
        params = {...param, }
        let res:any = await getRequest(apiUrl.rechargeCount, params)
        if(res.code == 1){
            res.data && res.data.map((item: any, index:number) => {
                charge_info.map((item2:any) => {
                    if(item.title == item2.title){
                        // item2.num = item.count;
                        // item2.add_num = item.new;
                    }
                })
                // charge_info[index].title = item.title;
                // charge_info[index].num = item.count;
                // charge_info[index].add_num = item.new;
            })

            setcharge_info(res.data)
        }
    }

    const getRechargeList = async (params: any)=>{
        const res:any = await getRequest(`${apiUrl.rechargeNewList}`, {...params});
        return res;
    }

    const orderChange = async (page: number) => {
        let params = {
            page: page,
            pageSize
        }
        getRechargeList(params).then((res) => {
            setRechargeList((data) => data = res.data.list)
            settotalCount((data) => data = res.data.total)
            setcurrent(page)
        })
    }

    const inputValuechange = (key: string, val: any) => {
        if(key !== 'paytime') {
            searchData[key] = val;
        } else {
            try {
                searchData['start_time'] = val[0].format('YYYY-MM-DD HH:mm:ss');
                searchData['end_time'] = val[1].format('YYYY-MM-DD HH:mm:ss');
            } catch (error) {
                searchData['start_time'] = 0;
                searchData['end_time'] = 0;
            }
        }
        const _search = JSON.parse(JSON.stringify(searchData));
        Object.keys(_search).forEach((item) => {
            if(!_search[item] || _search[item] === -1) {
                delete _search[item]
            }
        })
        _search.page = 1;
        getRechargeList(_search).then((res) => {
            setRechargeList((data) => data = res.data.list)
            settotalCount((data) => data = res.data.total)
            setcurrent(1)
        })
    }

    const columns = [
        {
            title: '用户昵称',
            dataIndex: 'nickname',
            key: 'nickname',
            width: 100,
        },
        {
            title: '手机号',
            dataIndex: 'phone_number',
            key: 'phone_number',
            width: 80,
        },
        {
            title: '累计充值金额',
            dataIndex: 'recharge_fee',
            key: 'recharge_fee',
            width: 80,
        },
        {
            title: '累计余额消费',
            dataIndex: 'comsume_fee',
            key: 'comsume_fee',
            width: 80,
        },
        {
            title: '累计赠送金额',
            dataIndex: 'present_fee',
            key: 'present_fee',
            width: 80,
        },
        {
            title: '邀请奖励总金额',
            dataIndex: 'invite_fee',
            key: 'invite_fee',
            width: 80,
        },
        {
            title: '提现总金额',
            dataIndex: 'withdraw_fee',
            key: 'withdraw_fee',
            width: 80,
        },
        {
            title: '调整余额',
            dataIndex: 'adjust_fee',
            key: 'adjust_fee',
            width: 80,
            render: (text:string, record: any) => {
                return <div>
                    {record.adjust_fee && record.adjust_fee.length > 0 ? record.adjust_fee.map((item:any) => {
                        return <div>{item}</div>
                    }) : '--'}
                </div>
            }
        },
        {
            title: '账户余额',
            dataIndex: 'balance',
            key: 'balance',
            width: 80,
        },
        {
            title: '充值次数',
            dataIndex: 'recharge_num',
            key: 'recharge_num',
            width: 80,
        },
        {
            title: '余额消费次数',
            dataIndex: 'comsume_num',
            key: 'comsume_num',
            width: 80,
        },
        {
            title: '成功提现次数',
            dataIndex: 'withdraw_num',
            key: 'withdraw_num',
            width: 80,
        },
        {
            title: '资金变动时间',
            dataIndex: 'updated_at',
            key: 'updated_at',
            width: 80,
        },
    ]
    const onCallback = (list:any) => {
        setstart_at(list[0])
        setend_at(list[1])
        initCount({start_at: list[0], end_at: list[1]})
    }

    const exportBtn = () => {
        const token = localStorage.getItem('token');
        const shop_id = localStorage.getItem('shop_id');
        window.open(`${process.env.REACT_APP_BASEURL}${'/v2/recharge/export'}?token=${token}&shop_id=${shop_id}`)
    }

    return (
        <div style={{padding:'20px'}}>
            <div className='charge_title'>充值数据</div>
            <div style={{display: 'flex', alignItems:'center', justifyContent: 'space-between', paddingRight:'50px'}}>
                <div>
                    <CustomDatePicker callback={(list: any) => { onCallback(list) }} current={0} currentDate={[start_at, end_at]}></CustomDatePicker>
                </div>
                <div className='export_btn' onClick={exportBtn}>
                    <img src={goodsExport}></img>
                    导出</div>
            </div>
            <div className='index-left-title' style={{marginTop: 0}}>
                <div style={{display:'flex'}}>
                    {
                        charge_info.map((item:any, index:any) => {
                            return <div className='charge_item' key={item.title}>
                            <div className='charge_item_title'>{item.title}</div>
                            <div className='charge_item_num'>{item.count}</div>
                            <div className='charge_item_add'>{index == 0 ? '新增人数': index == 1 ? '新增订单数' : '新增￥'}{item.new}</div>
                        </div>
                        })
                    }
                </div>
            </div>
            <div className='charge_title mb20'>充值会员列表</div>
            <div className='charge_table_wrap'>
                <div className='filter_wrap'>
                    <Row>
                        <Col span={5}>
                            <div className='search_nickname'>
                                <div className='search_nickname-name'>用户昵称：</div>
                                <div className='search_nickname-inp'>
                                    <Search
                                        allowClear
                                        placeholder="请输入用户昵称"
                                        onSearch={value => inputValuechange('nickname', value)}
                                        style={{ width: 190, borderRadius: 4 }}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col span={4}>
                            <div className='search_nickname'>
                                <div className='search_nickname-name'>手机号：</div>
                                <div className='search_nickname-inp'>
                                    <Search
                                        allowClear
                                        placeholder="请输入手机号"
                                        onSearch={value => inputValuechange('phone_number', value)}
                                        style={{ width: 190, borderRadius: 4 }}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <Table dataSource={rechargeList} size="middle" rowKey={'id'} columns={columns}
                pagination={{
                    current,
                    total: totalCount,
                    pageSize,
                    onChange: orderChange,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    showTotal: (total: number) => `共${total}条`,
                    onShowSizeChange: (current1:number, size: number) => {setpageSize(size);setcurrent(1); initData({pageSize: size,page: 1})}
                }} />
            </div>
        </div>
    )
}

export default Recharge