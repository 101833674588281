import React from 'react';
import { Table, Avatar, Typography, } from 'antd'
import { getRequest } from '../../api/http';
import { apiUrl } from '../../api/api_url';
import './newbusinessData.scss'
import { getIsPermisson, messageCustom } from '../../utils/permisstionUtil';
import goodsExport from '../../assret/images/goodsExport.png'
import OrderPadding from '../order_manager/order_list/components/order_padding_detail';

const { Text } = Typography;

interface OtherIncomeProps {
    history: any,
}

class SysData extends React.Component<OtherIncomeProps> {
    column = [
        {
            title: "订单号",
            dataIndex: "code",
            key: "code",
        },
        {
            title: "状态",
            dataIndex: "status_desc",
            key: "status_desc",
        },
        {
            title: "餐桌号",
            dataIndex: "table_name",
            key: "table_name",
        },
        {
            title: "付款用户",
            dataIndex: "user",
            key: "user",
            render: (text:any, rowdata: any) => {
                return <div>
                    <Avatar src={rowdata.headimgurl} style={{marginRight: 10}} />
                    <Text>{rowdata.nickname}</Text>
                </div>
            }
        },
        {
            title: "微信支付",
            dataIndex: "wechat_pay",
            key: "wechat_pay",
        },
        {
            title: "支付宝支付",
            dataIndex: "ali_pay",
            key: "ali_pay",
        },
        {
            title: "支付类型",
            dataIndex: "pay_type_desc",
            key: "pay_type_desc",
        },
        {
            title: "创建时间",
            dataIndex: "created_at",
            key: "created_at",
        },
        {
            title: "支付时间",
            dataIndex: "paid_at",
            key: "paid_at",
        },
        {
            title: "操作",
            dataIndex: "action",
            key: "action",
            className: "list_center",
            render: (text: string, record: any) => {
                return <div>
                    <div onClick={() => this.showOrderDetail(record)} className='btn_green'>详情</div>
                </div>
            }
        },
    ];

    state = {
        list: [],
        current: 1,
        pageSize: 10,
        count: 0,
        start_time: "",
        end_time: "",

        dataList: [
            {
                title: '微信支付',
                value: 0,
            },
            {
                title: '支付宝支付',
                value: 0,
            },
        ],
        showPaddingOrderDrawer: false,
        currentDrawerData: {
            desk: '',
            dishes: [],
            amount: 0,
            price: '',
            price_vip: '',
            code: '',
            status_display: '',
        },
    };

    componentDidMount() {
        this.setState({
            start_time: this.props.history.location.state.open_at,
            end_time: this.props.history.location.state.close_at
        }, () => {
            this._initInfo()
        })
    }

    // 初始化数据
    _initInfo = async () => {
        let { pageSize, current, start_time, end_time, dataList } = this.state;
        let params: any = {
            current,
            pageSize
        };
        if (start_time !== "") {
            params.start_time = start_time;
            params.end_time = end_time;
        }
        let res: any = await getRequest(apiUrl.newBusinessSys, params);
        if (res.code === 1) {
            let list: any = res.data.list;
            dataList[0].value = res.data.wechat_total
            dataList[1].value = res.data.ali_total
            this.setState({
                count: res.data.count ? res.data.count : 0,
                list: list,
            });
        }
    };

    pageChange = (page: number) => {
        this.setState({
            current: page
        }, () => {
            this._initInfo();
        });
    };

    // 导出
    exportTable = async () => {
        if(!getIsPermisson('1_3_2_1')){
            messageCustom();
            return;
        }
        const token = localStorage.getItem("token");
        const shop_id = localStorage.getItem("shop_id");
        window.open(`${process.env.REACT_APP_BASEURL}${apiUrl.newBusinessSysExport}?token=${token}&start_time=${this.state.start_time}&end_time=${this.state.end_time}&shop_id=${shop_id}`);
    };

    // 显示订单详情
    showOrderDetail = async (rowdata: any) => {
        if(!getIsPermisson('1_4_1_2')){
        messageCustom();
        return;
        }
        this.setState({
            currentDrawerData: rowdata,
            showPaddingOrderDrawer: true
        })
    }

    render() {
        let {
            list,
            current,
            pageSize,
            start_time,
            end_time,
            count,
            dataList
        } = this.state;

        return (
            <div className="income" style={{padding: '20px'}}>
                <div className='charge_title mb20'>系统收款</div>
                <div style={{display: 'flex', alignItems:'center', justifyContent: 'space-between', marginBottom:'20px'}}>
                    <div style={{fontSize: '18px', color: '#000'}}>
                        时间：{start_time}至{end_time}
                    </div>
                    <div className='export_btn' onClick={this.exportTable}>
                        <img src={goodsExport} />导出
                    </div>
                </div>

                <div className='business_wrap'>
                    {
                        dataList.map((item:any) => {
                            return <div className='business_item' key={item.title} style={{justifyContent:'center', alignItems:'center'}}>
                                <div className='business_top' style={{flex:'none'}}>
                                    <div className='business_title'>{item.title}</div>
                                </div>
                                <div className='business_value'><span>￥</span>{item.value}</div>
                            </div>
                        })
                    }
                </div>


            <div style={{background:'#fff'}} className='finance_table_wrap'>
                <Table
                    bordered
                    dataSource={list}
                    size="middle"
                    columns={this.column}
                    rowKey={"day"}
                    locale={{ emptyText: "暂无数据" }}
                    pagination={{
                        current,
                        total: count,
                        pageSize,
                        onChange: this.pageChange,
                        showQuickJumper: true,
                        showSizeChanger: true,
                        showTotal: (total: number) => `共${total}条`,
                        onShowSizeChange: (current: number, size: number) => { this.setState({ pageSize: size }, () => { this._initInfo() }) }
                    }}
                >
                </Table></div>

        {/* 订单详情 */}
      <OrderPadding
        showPaddingOrder={this.state.showPaddingOrderDrawer}
        changeVisitity={(v) => {this.setState({showPaddingOrderDrawer:false})}}
        code={this.state.currentDrawerData.code ? this.state.currentDrawerData.code : ''} />
            </div>
        );
    }
}

export default SysData;