import React from 'react';
import "./accountSetting.scss"
import {Avatar, Button, Modal, Table, Tag, Radio, message} from "antd";
import {getRequest} from "../../../../../api/http";
import {apiUrl} from "../../../../../api/api_url";
interface IProps {
    callbackParent(type:number,param:any):void,
    param ?: any,
}
class AccountSetting extends React.Component<IProps>{
    state = {
        chooseIndex: -1,// 选择下标
        visible: false,
        listNum: 0,
        accounts: [],
        chooseAccounts: []
    };

    componentDidMount(): void {
        this.initPageParams();
    }
    // 初始化页面参数
    initPageParams =() =>{
        let that = this;
        let paramTem = this.props.param;
        if(paramTem.length !== 0){
            that.setState({
                chooseAccounts: paramTem
            })
        }
    };
    // 移除 公众号
    handleRemove =(item:any) =>{
        this.setState({chooseAccounts: [],chooseIndex:-1});
        this.props.callbackParent(2,[]);
    };

    // modal 控制
    handleModel =() =>{
        let {visible} = this.state;
        if(!visible){
            this.requestAuthAccount();
        }else{
            this.setState({visible: false});
        }
    };

    // 确认选择
    handleOk = (e:any) =>{
       if(this.state.chooseIndex === -1){
           this.handleModel();
           return;
       }
        let {accounts} = this.state;
        let temChooseAccounts:any[] = [];
        temChooseAccounts.push(accounts[this.state.chooseIndex]);
        this.setState({visible: false,chooseAccounts:temChooseAccounts});
        this.props.callbackParent(2,temChooseAccounts);
    };

    // 获取 认证公众号列表
    requestAuthAccount = async () =>{
        const res: any = await getRequest(apiUrl.authAccountList,{page: 1,page_size: 20,can_use:1});
        if(res.code === 1){
            const list = res.data.list;
            if(list.length > 0){
                this.setState({
                    accounts: list,
                    listNum: res.data.count,
                    visible: true
                });
            }else{
                message.warning("您授权的公众号任务正在进行中",2)
            }

        }else{
            console.log(res.message)
        }
    };
    // 创建 已选择的公众号列表
    createShopChoose =() =>{
        let {chooseAccounts} = this.state;
        const dataSource = chooseAccounts;
        const columns:any = [
            {
                title: '公众号',
                dataIndex: 'nick_name',
                key: 'nick_name',
                align: "center",
                render: (text: string,record:any,index:number)=>(
                    <div className="table_account">
                        <Avatar size={32} src={record.head_img} />
                        <div className="account_name" onClick={this.handleRemove.bind(this,text)}>{text}</div>
                    </div>
                )
            },
            {
                title: '公众号类型',
                align: "center",
                render: (record:any) => (
                    <div>{record.verify_type_info.id === 2 ? '服务号':'订阅号'}</div>
                )
            },
            {
                title: '操作',
                align: "center",
                render: (record:any)=>(
                    <div className="table_action" onClick={this.handleRemove.bind(this,record)}>移除</div>
                )
            },
        ];
        return <Table dataSource={dataSource} size="middle" columns={columns} rowKey="id" />;
    };

    // 单选 监听
    handleChooseAccount =(e:any)=>{
        this.setState({chooseIndex: e.target.value})
    };

    render(){
        let {visible,accounts,chooseIndex} = this.state;

        return<div className="account_set_view l_content">
            <div className="add_account_button">
                <Button type="primary" icon="plus" onClick={this.handleModel}>添加公众号</Button>
            </div>
            <div>{this.createShopChoose()}</div>
            <Modal
                visible={visible}
                title={null}
                onCancel={this.handleModel}
                onOk={this.handleOk}
            >
                <div className="model_head">
                    <div className="head_title">选择公众号</div>
                </div>
                <div className="account_table">
                    <Radio.Group value={chooseIndex} onChange={this.handleChooseAccount} style={{width: '100%'}}>
                        {
                            accounts.map((item:any,index) =>
                                <div className='table_item' key={index}>
                                    <div className='item_right'>
                                        <Avatar size={46} src={item.head_img} />
                                        <div className="account_nickName">{item.nick_name}</div>
                                    </div>
                                    <div className='item_left'>
                                        <Tag color="#FFB916">{item.verify_type_info.id === 2 ? '服':'订'}</Tag>
                                        <Tag color="purple">已认证</Tag>
                                        <Radio value={index} />
                                    </div>
                                </div>
                            )
                        }
                    </Radio.Group>
                </div>
            </Modal>
        </div>;
    }
}

export default AccountSetting