const Add = (arg1:any, arg2:any) => {
    var r1, r2, m;
    try { r1 = arg1.toString().split(".")[1].length } catch (e) { r1 = 0 }
    try { r2 = arg2.toString().split(".")[1].length } catch (e) { r2 = 0 }
    m = Math.pow(10, Math.max(r1, r2))
    return (arg1 * m + arg2 * m) / m
}
  //修改之后的减法
const Minus = (arg1:any, arg2:any) => {
    var r1, r2, m, n;
    try { r1 = arg1.toString().split(".")[1].length } catch (e) { r1 = 0 }
    try { r2 = arg2.toString().split(".")[1].length } catch (e) { r2 = 0 }
    m = Math.pow(10, Math.max(r1, r2));
    // console.log('m',m);
    //动态控制精度长度
    n = (r1 >= r2) ? r1 : r2;
    // console.log('n',n);
    // console.log(arg1 * m, arg2 * m, (arg1 * m - arg2 * m), ((arg1 * m - arg2 * m) / m).toFixed(n));
    return ((arg1 * m - arg2 * m) / m).toFixed(n);
}
  //修改之后的乘法
  const Multiply = (arg1:any, arg2:any) => {
    var m = 0, s1 = arg1.toString(), s2 = arg2.toString();
    try { m += s1.split(".")[1].length } catch (e) { }
    try { m += s2.split(".")[1].length } catch (e) { }
    return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m)
}
  //修改之后的除法
// function Division(arg1:any, arg2:any) {
//     var t1 = 0, t2 = 0, r1, r2;
//     try { t1 = arg1.toString().split(".")[1].length } catch (e) { }
//     try { t2 = arg2.toString().split(".")[1].length } catch (e) { }
//     with (Math:any) {
//       r1 = Number(arg1.toString().replace(".", ""))
//       r2 = Number(arg2.toString().replace(".", ""))
//       return (r1 / r2) * pow(10, t2 - t1);
//     }
// }

const keepTwoNum = (str: any) => {
    let numStr = str.toString()
    let index = numStr.indexOf('.')

    if(index < 0){
      numStr += '.00'
    }else{
      let arr = numStr.split('.');
      if(arr[1].length === 1){
        numStr += '0'
      }
    }
    index = numStr.indexOf('.')
    // console.log(numStr);
    let result = numStr.slice(0, index + 3)
    // console.log(result);
    return result;
}

export {Add, Minus, Multiply, keepTwoNum}