import React, { Fragment, useEffect, useState } from 'react'
import { Table, Row, Button, Drawer, Typography, Col, Icon, Modal } from 'antd'
import { getRequest, postRequest } from '../../../../api/http';
import { apiUrl } from '../../../../api/api_url';

const { confirm } = Modal;
const { Text } = Typography;

interface IOrderPaddingProps {
  showEditOrder: boolean
  changeVisitity(status: boolean): void
  code: string
}

interface IorderData {
  desk: string
  amount: number
  price: string
  price_vip: string
  code: string
  status: number
  status_display: string
  dishes: Array<{
    mark: string,
    dishes: Array<{
      id: string
      name: string
      count: number
      price: string
      main_pic: string
      standard: string
      price_vip: string
      dish_weigh_value_id: any,
      material_key: any
    }>,
    created_at: string
    fan: {
      headimgurl: string,
      nickname: string
    }
  }>
  list: Array<{
    id: string
    name: string
    count: number
    orgain_count: number
    standard: string
    price: string
    price_vip: string
    dish_weigh_value_id: any
    material_key: any
  }>
  created_at: string
  paid_at: string
  fan: {
    headimgurl: string,
    nickname: string
  } | null
}

const OrderEditDrawer = (props: IOrderPaddingProps) => {

  const titleWidth = 80;
  const { code } = props;
  const [orderData, setorderData] = useState<IorderData>({
    desk: '',
    amount: 0,
    price: '',
    price_vip: '',
    code: '',
    status: 0,
    status_display: '',
    dishes: [],
    list: [],
    created_at: '',
    paid_at: '',
    fan: null,
  });

  useEffect(() => {
    if(props.showEditOrder) {
      getOrderDetai()
    }
  }, [code])

  const getOrderDetai = async () => {
    if(props.code) {
      const parms = {
        code: props.code
      }
      const res: any = await getRequest(apiUrl.foodOrderDetail, parms);
      if(res && res.data) {
        const data: any = res.data;
        data.list.forEach((item: any) => {
          item.orgain_count = item.count;
        });
        setorderData(data)
      }
    }
  }

  const columns = [{
    title: '商品名称',
    dataIndex: "name",
    key: "name",
  },{
    title: '规格',
    dataIndex: "standard",
    key: "standard",
  },{
    title: '已点份数',
    dataIndex: "count",
    key: "count",
  }]

  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
    },
    getCheckboxProps: (record:any) => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
  };

  const editOrderColumns = [{
    title: '商品名称',
    dataIndex: "name",
    key: "name",
  },{
    title: '规格',
    dataIndex: "standard",
    key: "standard",
  },{
    title: '已下单份数',
    dataIndex: "count",
    key: "count",
  },{
    title: '操作',
    key: "action",
    render: (rowdata: any, curr: any, idx: any) => {
      if(rowdata) {
        return <Row type="flex" justify="center">
          <Button size="small" disabled={rowdata.count === 0} onClick={() => deincreaseDishesCount(idx)}><Icon type="minus" /></Button>
          <Col style={{width: 50, display: 'inline-bloack', fontWeight: "bold"}}>{rowdata.count}</Col>
          <Button size="small" onClick={() => addDishesCount(idx)}><Icon type="plus" /></Button>
        </Row>
      }
      return <Row></Row>
    }
  }]

  const addDishesCount = (idx: number) => {
    const _data = orderData;
    _data.list[idx].count += 1;
    setorderData({..._data});
  }

  const deincreaseDishesCount = (idx: number) => {
    const _data = orderData;
    if(_data.list[idx].count) {
      _data.list[idx].count -= 1;
    }
    setorderData({..._data});
  }

  const sureEditOrder = () => {
    confirm({
      title: '提示',
      content: "'确认修改'后，用户订单中信息将会同步更新，请确定是否修改？",
      okText: '确定修改',
      cancelText: '取消',
      onOk () {
        const foods: any = [];
        orderData.list.forEach((item) => {
          if(item.count != item.orgain_count) {
            foods.push({
              dish_id: item.id,
              change: item.count - item.orgain_count,
              standard: item.standard,
              dish_weigh_value_id: item.dish_weigh_value_id ? item.dish_weigh_value_id : 0,
              material_key: item.material_key ? item.material_key : null
            })
          }
        })
        const parms = {
          code: props.code,
          update: {
            foods: foods
          }
        }
        postRequest(apiUrl.foodOrderUpdate, parms)
        .then((res) => {
          console.log('res', res)
        })
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  return  <Fragment>
    <Drawer
        title="修改订单"
        placement={`left`}
        closable={false}
        onClose={() => props.changeVisitity(false)}
        width={800}
        visible={props.showEditOrder}
      >
        <Row type="flex" style={{marginBottom: 10}}>
          <Col style={{width: titleWidth, textAlign: "left", marginRight: 10}}>
            <Text strong >订单号</Text>
          </Col>
          <Text >{orderData.code}</Text>
        </Row>
        <Row type="flex" style={{marginBottom: 10}}>
          <Col style={{width: titleWidth, textAlign: "left", marginRight: 10}}>
            <Text strong >餐台号</Text>
          </Col>
          <Text >{orderData.desk}</Text>
        </Row>
        <Row type="flex" style={{marginBottom: 10}}>
          <Col style={{width: titleWidth, textAlign: "left", marginRight: 10}}>
            <Text strong >订单状态</Text>
          </Col>
          <Text >{orderData.status_display}</Text>
        </Row>

        <Table rowSelection={rowSelection}
          columns={editOrderColumns}
          dataSource={orderData.list}
          pagination={false}
          rowKey={`id`}
          size="middle"
          // footer={() => orderTableFooter()}
          />

        <Row type="flex" justify="center" style={{marginTop: 50}}>
          <Button onClick={() => props.changeVisitity(false)}>取消</Button>
          <Button type="primary"
            style={{marginLeft: 40}}
            onClick={() => sureEditOrder()}>确定修改</Button>
        </Row>
      </Drawer>
</Fragment>

}

export default OrderEditDrawer