import React from 'react';
import InsideNav from '../../../component/insideNav/insideNav';
import MemberList from '../memberList/memberList';
import VipList from '../memberList/vipList';

type IProps = {
    history?: any
}
type IState = {
    navList: any
    current_key: string,
    currentTab: string,
    vip_search: any
}

let location_this = {onInit: () => {}};
let multi_this = () => {};

class Index extends React.Component<IProps,IState>{
    
    constructor(props:IProps) {
        super(props);
        this.state = {
            navList: [
                {
                    title: '用户列表',
                    isActive: true,
                    KEY: "USER_LIST"
                },
                {
                    title: 'VIP会员',
                    isActive: false,
                    KEY: "VIP_LIST"
                }
            ],
            current_key: "USER_LIST",
            currentTab: 'custom',
            vip_search: '',
        }
    }

    componentDidMount(){
        console.log('componentDidMount')
        this.setState({
            vip_search: '',
        })   
    }

    navCallBack(currentKey: string){
        console.log(currentKey)
        this.setState({
            current_key: currentKey
        })
    }

    vipDmoCallBack(currentKey: string){
        console.log(currentKey)
        this.setState({
            current_key: currentKey,
            currentTab: currentKey == 'VIP_LIST' ? 'recommend' : 'custom',
            navList: [
                {
                    title: '用户列表',
                    isActive: false,
                    KEY: "USER_LIST"
                },
                {
                    title: 'VIP会员',
                    isActive: true,
                    KEY: "VIP_LIST"
                }
            ],
        })
    }

    //tab切换
    onChangeTabs = (e:any)=>{
        this.setState({
            currentTab: e,
            current_key: e == 'custom' ? 'USER_LIST' : 'VIP_LIST'
        })
    }

    //调整到viplist页面，需要带参数
    onVipListPage = (val: any, currentKey: string) => {
        console.log('val:',val)
        this.setState({
            vip_search: val
        }, () => {
            this.vipDmoCallBack(currentKey)
        })
    }

    onRef = () => {
        //console.log('multi_this:',multi_this)
        //multi_this()
        this.setState({
            vip_search: ''
        })
    }

    onRefMulti = (ref: any) => {
        //console.log('onRefMulti:',ref)
        //multi_this = ref;
        ref({search: this.state.vip_search})
    }

    render() {
        let { navList, current_key, currentTab, vip_search } = this.state;
        return <div className='group_index'>
            <div className='group_tab_wrap mb30'>
            <div className={`group_tab_item ${currentTab == 'custom' ? 'group_tab_active' : ''}`} onClick={() => {this.onChangeTabs('custom')}}>用户列表</div>
            <div className={`group_tab_item ${currentTab == 'recommend' ? 'group_tab_active' : ''}`} onClick={() => {this.onChangeTabs('recommend')}}>VIP会员</div>
        </div>
            <div className="body">
                {
                    current_key === 'USER_LIST' 
                    ?  <MemberList onRef={() => {this.onRef()}} onVipListPage={(val: any, key: string) => {this.onVipListPage(val, key)}} callBack={(key: string) =>this.vipDmoCallBack(key)} />
                    :   <VipList onRefMulti={(e: any) => {this.onRefMulti(e)}}  search={vip_search} />
                }
            </div>
        </div>
    }

}

export default Index;