import React from "react";
import {Button, Row, Col, Input, Radio, Table, Modal, Select, message, Icon, Popconfirm} from "antd";
import { postRequest, getRequest } from '../../api/http';
import { apiUrl } from '../../api/api_url';
const { Option } = Select;

const key = 'updatable';
export default class GoodsAdd extends React.Component<any> {

    state = {
        enter_pass: '',
        new_pass: '',
        old_pass: '',
    }

    componentDidMount() {
        
    }

    handleSubmit = async (e:any, typeAdd: any) => {
        message.loading({ content: '发布中', key });
        e.preventDefault();

        let goodInfo:any = {};

        const { new_pass, old_pass, enter_pass } = this.state;

        if(old_pass == '') {
            return message.warning({ content: '请输入旧密码!', key, duration: 2 });
        }
        if(this.vCodeChange(old_pass)) {
            return message.warning({ content: '旧密码不能输入中文!', key, duration: 2 });
        }

        if(new_pass == '') {
            return message.warning({ content: '请输入新密码!', key, duration: 2 });
        }
        if(this.vCodeChange(new_pass)) {
            return message.warning({ content: '新密码不能输入中文!', key, duration: 2 });
        }

        if(enter_pass == '') {
            return message.warning({ content: '请输入确认密码!', key, duration: 2 });
        }
        if(this.vCodeChange(enter_pass)) {
            return message.warning({ content: '确认密码不能输入中文!', key, duration: 2 });
        }
        if(enter_pass != new_pass) {
            return message.warning({ content: '确认密码有误!', key, duration: 2 });
        }
        goodInfo.old = old_pass;
        goodInfo.new = new_pass;

        const res:any = await postRequest(apiUrl.changePassword,goodInfo);
        if(res['code'] === 1){
            message.success({ content: '修改成功!', key, duration: 2 });
            this.loginOut()
        }else{
            message.error({ content: res['message'], key, duration: 2 });
        }

    };

    loginOut = () => {
        let _version: any = localStorage.getItem('version');
        localStorage.clear();
        localStorage.setItem('version',_version)
        this.props.history.push("/login");
    };

    //数字验证
    vCodeChange = (e: any) => {
        const reg = /\p{Unified_Ideograph}/ug;	// 以任意数字开头和结尾，且中间出现零个或多个数字
        return reg.test(e)
    }

    // 分类名称
    serialChange = (e:any, val: any) => {
        this.setState({
            [val]: e.target.value
        })
    }

    // 返回上一页
    goBack = () => {
        window.history.go(-1)
    }

    render() {

        const { old_pass, new_pass, enter_pass } = this.state;

        return (
            <div className='l_content'>
                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">旧密码</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <Input.Password placeholder="请输入旧密码"  value={old_pass} onChange={(e) => this.serialChange(e, 'old_pass')} />
                    </Col>
                </Row>
                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">新密码</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <Input.Password placeholder="请输入新密码" value={new_pass} onChange={(e) => this.serialChange(e, 'new_pass')}/>
                    </Col>
                </Row>
                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">确认密码</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <Input.Password placeholder="请再次输入密码" value={enter_pass} onChange={(e) => this.serialChange(e, 'enter_pass')}/>
                    </Col>
                </Row>

                <Row>
                    <Col span={8}>
                        <div className="bottom_wrap">
                            <Button type="primary" onClick={(e) => {this.handleSubmit(e,'add')}}>
                                确定
                            </Button>
                            {/* <Button type="primary" onClick={(e) => {this.handleSubmit(e,'continue')}}>
                                保存继续添加
                            </Button> */}
                            <Button type="primary" ghost onClick={this.goBack}>
                                取消
                            </Button>
                        </div>
                    </Col>
                </Row>

            </div>
        )
    }
}