import React from "react";
import { Button, Input, Icon, Upload, message, Row, Col, Select, Radio, } from "antd";
import { postRequest, getRequest } from '../../../api/http';
import GoodAddType from '../../goods/components/goodsAddType';
import { apiUrl } from '../../../api/api_url';
import btn_add from '../../../assret/images/btn_add.png';
import './setMealAdd.scss';
import goodPhoto1 from '../../../assret/images/goodPhoto1.png'
import goodPhoto2 from '../../../assret/images/goodPhoto2.png'
import goodPhoto3 from '../../../assret/images/goodPhoto3.png'
import goodPhoto4 from '../../../assret/images/goodPhoto4.png'
import goodPhoto5 from '../../../assret/images/goodPhoto5.png'
import dish from '../../../assret/images/dish.png'
import set_up from '../../../assret/images/set_up.png'
import set_down from '../../../assret/images/set_down.png'
import set_add from '../../../assret/images/set_add.png'
import set_reduce from '../../../assret/images/set_reduce.png'
import TextArea from "antd/lib/input/TextArea";

import { onlyNumbers, onlyStrings } from '../../../utils/utils';
const { Option } = Select;

interface specs {
    name: string
    value: [
        {
            name: string
        }
    ]
}
interface attrItem {
    price: number,
    price_vip: number,
    pic: string,
    pic_url?: string
}

function getBase64More(file: any) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

const QINIU_SERVER = 'https://up-z2.qiniup.com';
const key = 'updatable';
const baseUrl = 'https://ydn-siyu.cdn.shuachi.com/';

class GoodsEdit extends React.Component<any> {
    state = {
        loading: false,
        serial_number: '',
        name: '',
        cat_id: 0,
        cat_name: '',
        main_pic: '',
        people_num_eat: '',
        left_top_tips: '',
        right_top_tips: '',
        stock_type: 'normal',  //specs   normal
        price: '',
        price_vip: '',
        stock: 0,
        imageUrl: '',
        imageObj: {
            key: '',
            url: ''
        },
        sortList: [],
        catList: [],
        sortListChannel: [],
        previewVisible: false,
        previewImage: '',
        updateObj: {
            key: '',
            tokens: '',
            region: '',
            'x:type': ''
        },
        specs_type: [],
        attribute: [],
        is_no_discount: false,
        is_must_eat: false,
        dep_id: 0,
        new_cat_id: 0, //添加菜品-商品分类id，为了区分，新增一个全新的变量表示。
        set_content: [], //套餐分组
        is_edit: false, //是否是编辑
        own_model: 0,
        channel: 0,
        more_set: true, //展开更多设置
        more_txt: '展开更多设置',
        dishes_select: '', // 套餐渠道
        dishes_type: '', // 套餐分类
        dep_name: '',
        add_type_statue: false, //添加分类
        nongId: [], //选中菜品的id
        display_mode: 1, //展示方式
        goodPhotoOne: 0,

        currentCate: 0,
        dishList: [],
        cateList: [],
        dish_tab: 'added',
        selectList: [],
        totalDish: 0,
        totalPrice: 0,
        is_print_name: false,
        show_mini_program: 1,
        tips: ''
    }

    componentDidMount() {
        this.onInitSort();
        this.onInitSortCannel();
        this.getGoodsList();
    }

    getGoodsList = async () =>{
        const { location } = this.props;
        let { currentCate } = this.state;
        const res: any = await getRequest(`${apiUrl.getDishLists}`, {});
        if (res.code === 1) {
            res.data && res.data.map((item: any) => {
                item.dish_list.map((item2: any) => {
                    if(item2.weigh){
                        let attr:any = item2.attribute ? item2.attribute[0] : {};
                        item2.attribute = item2.attribute ? item2.attribute : []
                        item2.weigh[0].values[0].sel = true;
                        attr.price = item2.weigh[0].values[0].price
                        attr.weigh_name = item2.weigh[0].values[0].name
                        attr.price_vip = item2.weigh[0].values[0].price_vip
                        attr.dish_weigh_value_id = item2.weigh[0].values[0].id
                        item2.attribute[0] = attr;
                    }
                })
            })
            this.setState({
                dishList: res.data[currentCate].dish_list,
                cateList: res.data
            }, () => {
                if (location.state !== undefined) {
                    this.initEdit(location.state.id);
                }
            })
        }
    }

    changeCate = (index: number) => {
        let cateList:any = this.state.cateList;
        this.setState({
            currentCate: index,
            dishList: cateList[index].dish_list
        })
    }

    reduceDish = (index: number, id: number) => {
        let { currentCate } = this.state;
        let cateList:any = this.state.cateList;
        let dishList: any = this.state.dishList;
        let selectList: any = this.state.selectList;
        let cat_id = cateList[currentCate].cat_id;
        console.log('cat_id',cat_id)
        
        if(!dishList[index].count || dishList[index].count == 0){
            return;
        }
        dishList[index].count--;
        cateList[currentCate].dish_list = dishList
        this.setState({
            dishList,
            cateList
        })
        // console.log('dishList',dishList)
        // console.log('selectList',selectList)
        if(dishList[index].count == 0){
            selectList.map((item: any, idx1: number) => {
                item.dish_list.map((item2: any, idx: number) => {
                    if(item2.id == id){
                        item.dish_list.splice(idx, 1)
                    }
                })
                if(item.dish_list.length == 0){
                    selectList.splice(idx1, 1)
                }
            })
        }
        // console.log('selectList',selectList)



        selectList.map((item:any) => {
            // if(item.id == dishList[index].cat_id){

            // }
            if(item.cat_id == cat_id){
                let arr = [{
                    name: '全部可选',
                    value: item.dish_list.length
                }];
                item.dish_list.map((item2: any, index: number) => {
                    if(item2.id == dishList[index].id){
                        item2.count = dishList[index].count;
                    }
                    if(index + 1 < item.dish_list.length){
                        let obj = {
                            'name': `${item.dish_list.length}选${index+1}可用`,
                            'value': index + 1
                        }
                        arr.push(obj)
                    }
                })
                item.option = arr;
                item.cat_num = item.dish_list.length;
            }
        })
        this.setState({
            selectList
        }, () => {this.handleGoods()})
    }

    addDish = (index: number, id: number) => {
        let { currentCate } = this.state;
        let cateList:any = this.state.cateList;
        let dishList: any = this.state.dishList;
        let selectList: any = this.state.selectList;
        let cat_id = cateList[currentCate].cat_id;

        if(dishList[index].count){
            dishList[index].count++;
            dishList[index].attribute = cateList[currentCate].dish_list[index].attribute;
        }else{
            dishList[index].count = 1
        }
        cateList[currentCate].dish_list = dishList
        this.setState({
            dishList,
            cateList
        })
        if(selectList.length > 0){
            console.log(selectList)
            console.log('cateList',cateList)
            let tabNum = 0;
            selectList.map((item: any) => {
                if(item.id != cateList[currentCate].cat_id){
                    console.log(11111)
                    tabNum++;
                    if(selectList.length == tabNum){
                        selectList.push({
                            cat_id: cateList[currentCate].cat_id,
                            id: cateList[currentCate].cat_id,
                            name: cateList[currentCate].cat_name,
                            dish_list: [dishList[index]]
                        })
                    }
                }else{
                    console.log(222222)
                    let num = 0;
                    item.name = cateList[currentCate].cat_name
                    item.dish_list.map((item2: any) => {
                        if(item2.id != id){
                            console.log(3333);
                            num++
                            if(item.dish_list.length == num){
                                item.dish_list.push(dishList[index])
                            }
                        }else{
                            item2.count = dishList[index].count
                            item2.attribute = dishList[index].attribute;
                        }
                    })
                }
            })
        }else{
            selectList.push({
                cat_id: cateList[currentCate].cat_id,
                id: cateList[currentCate].cat_id,
                name: cateList[currentCate].cat_name,
                dish_list: [dishList[index]]
            })
        }

        selectList.map((item:any) => {
            if(item.cat_id == cat_id){
                let arr = [{
                    name: '全部可选',
                    value: item.dish_list.length
                }];
                item.dish_list.map((item2: any, index: number) => {
                    if(index + 1 < item.dish_list.length){
                        let obj = {
                            'name': `${item.dish_list.length}选${index+1}可用`,
                            'value': index + 1
                        }
                        arr.push(obj)
                    }
                })
                item.option = arr;
                item.cat_num = item.dish_list.length;
                // item.cat_id = 22;
            }
        })
        console.log('selectList AA',selectList)
        this.setState({
            selectList
        }, () => {this.handleGoods()})
    }

    addReduce = (index1: number, index2:number, id: number) => {
        let selectList:any = this.state.selectList;
        let cateList:any = this.state.cateList;
        let cat_id = selectList[index1].cat_id;

        selectList[index1].dish_list[index2].count--;
        let count = selectList[index1].dish_list[index2].count;
        if(selectList[index1].dish_list[index2].count == 0){
            selectList[index1].dish_list.splice(index2, 1)
            if(selectList[index1].dish_list.length == 0){
                selectList.splice(index1,1)
            }
        }

        cateList.map((item: any) => {
            item.dish_list.map((item2: any) => {
                if(item2.id == id){
                    item2.count = count;
                }
            })
        })

        selectList.map((item:any, index: any) => {
            if(item.cat_id == cat_id){
                let arr = [{
                    name: '全部可选',
                    value: item.dish_list.length
                }];
                item.dish_list.map((item2: any, index: number) => {
                    if(index + 1 < item.dish_list.length){
                        let obj = {
                            'name': `${item.dish_list.length}选${index+1}可用`,
                            'value': index + 1
                        }
                        arr.push(obj)
                    }
                })
                item.option = arr;
                item.cat_num = item.dish_list.length;
            }
        })

        this.setState({
            selectList,
            cateList
        }, () => {this.handleGoods()})
    }

    addAdd = (index1: number, index2:number, id: number) => {
        let selectList:any = this.state.selectList;
        let cateList:any = this.state.cateList;
        selectList[index1].dish_list[index2].count++;

        cateList.map((item: any) => {
            item.dish_list.map((item2: any) => {
                if(item2.id == id){
                    item2.count = selectList[index1].dish_list[index2].count;
                }
            })
        })

        this.setState({
            selectList,
            cateList
        }, () => {this.handleGoods()})
    }

    moveItem = (dir:string,  index1: number, index2:number) => {
        let selectList:any = this.state.selectList;
        if(dir === 'up'){
            if(index2 >= 1){
                let obj = {};
                obj = selectList[index1].dish_list[index2-1];
                selectList[index1].dish_list[index2-1] = selectList[index1].dish_list[index2];
                selectList[index1].dish_list[index2] = obj;
            }
        }else if(dir === 'down'){
            if(index2 <= selectList[index1].dish_list.length - 2){
                let obj = {};
                obj = selectList[index1].dish_list[index2+1];
                selectList[index1].dish_list[index2+1] = selectList[index1].dish_list[index2];
                selectList[index1].dish_list[index2] = obj;
            }
        }
        this.setState({
            selectList
        })
    }

    moveTab = (dir: string, index: number) => {
        let selectList:any = this.state.selectList;
        if(dir === 'up'){
            if(index >= 1){
                let obj = {};
                obj = selectList[index-1];
                selectList[index-1] = selectList[index];
                selectList[index] = obj;
            }
        }else if(dir === 'down'){
            if(index <= selectList.length - 2){
                let obj = {};
                obj = selectList[index+1];
                selectList[index+1] = selectList[index];
                selectList[index] = obj;
            }
        }
        this.setState({
            selectList
        })
    }

    handleGoods = () => {
        let cateList:any = this.state.selectList;
        let total = 0;
        let price = 0;
        console.log('cateList:',cateList)
        cateList.map((item: any) => {
            let list:any = [];
            let dish_list:any = item.dish_list;
            item.priceList = [];
            dish_list.map((item2:any) => {
                if(item2.count && item2.count > 0){
                    list.push(item2)
                    total++;

                    if(item2.has_specs){
                        // price += (parseFloat(item2.attribute[0].price) * item2.count)
                        if(item2.attribute){
                            item2.price_num = (parseFloat(item2.attribute[0].price) * item2.count)
                        }else{
                            let p:any = 0;
                            item.weigh[0].values.map((weigh:any) => {
                                if(weigh.sel){
                                    p = weigh.price
                                }
                            })
                            item2.price_num = (parseFloat(p) * item2.count)
                        }
                    }else{
                        // item2.price && (price += (parseFloat(item2.price) * item2.count))
                        item2.price_num = item2.price ? ((parseFloat(item2.price) * item2.count)) : 0
                    }
                    item.priceList.push(item2.price_num)
                }
            })
        })

        cateList.map((item: any) => {
            item.newList = item.priceList.sort((a:any,b:any) => {return b - a});
            console.log('item.newList', item.newList, price);
            item.priceList.map((item2: any, index: number) => {
                if(index < item.cat_num){
                    price += item2;
                    console.log(price);
                }
            })
        })

        this.setState({
            totalDish: total,
            totalPrice: price
        })
    }

    selectChange = (e: any, index: number) => {
        let selectList: any = this.state.selectList;
        selectList[index].cat_num = e;

        this.setState({
            selectList
        }, () => {
            this.handleGoods()
        })
    }

    onChangeAttr = (specs_type: any) => {
        let list = specs_type;
        if (specs_type.length <= 0) {
            return;
        }
        let arr: any = [];
        if (list[0].value.length <= 0) {
            return;
        }

        if (list.length === 1) {
            list[0].value.forEach((item: specs) => {
                arr.push({
                    'name': item.name,
                    'price': '',
                    'price_vip': '',
                    'pic': ''
                })
            });
        } else if (list.length === 2) {
            list[0].value.forEach((item: specs) => {
                list[1].value.forEach((child: specs) => {
                    arr.push({
                        'name': item.name + "_" + child.name,
                        'price': '',
                        'price_vip': '',
                        'pic': ''
                    })
                })
            });
        } else if (list.length === 3) {
            list[0].value.forEach((item: specs) => {
                list[1].value.forEach((child: specs) => {
                    list[2].value.forEach((second: specs) => {
                        arr.push({
                            'name': item.name + "_" + child.name + "_" + second.name,
                            'price': '',
                            'price_vip': '',
                            'pic': ''
                        })
                    })
                })
            });
        }

        this.setState({
            attribute: arr
        })
    }

    // 初始化编辑
    initEdit = async (id: number) => {
        const params = {
            id: id
        };
        const res: any = await getRequest(apiUrl.getSetMealInfo, params);
        if (res.code === 1) {
            const resData = res.data;
            
            this.setState({
                price: resData.price,
                serial_number: resData.set_meal_no,
                name: resData.name,
                cat_name: resData.cat_name,
                is_no_discount: resData.discount,
                is_print_name: resData.is_print_name,
                is_must_eat: resData.is_must_eat,
                imageUrl: baseUrl + resData.main_pic,
                imageObj: {
                    key: resData.main_pic,
                    url: baseUrl + resData.main_pic
                },
                dep_id: resData.cat_id,
                cat_id: resData.cat_id,
                dep_name: resData.dep_name,
                channel: resData.channel,
                display_mode: resData.show_type,
                show_mini_program: resData.show_mini_program
            }, () => {
                this.onInitSort();
                this.onInitSortCannel();
            })
            if (resData.recommend_num != '') {
                this.setState({
                    people_num_eat: resData.recommend_num
                })
            }
            if (JSON.stringify(resData.left_top_tips) != '{"tips":""}') {
                this.setState({
                    left_top_tips: resData.left_top_tips
                })
            }
            if (JSON.stringify(resData.right_top_tips) != '{"tips":""}') {
                this.setState({
                    right_top_tips: resData.right_top_tips
                })
            }
            if(resData.tips){
                this.setState({
                    tips: resData.tips
                })
            }
            //套餐菜品
            if(!resData.dishes.dishes) {
                let temp_list = JSON.parse(resData.dishes);
                let cateList:any = this.state.cateList;
                temp_list.map((item:any) => {
                    item.cat_name = item.name;
                    item.cat_num = item.num;
                    item.cat_id = item.id;
                    item.dish_list = item.dishes;
                })
                // console.log('temp_list',temp_list)

                temp_list.map((item:any) => {
                    let arr = [{
                        name: '全部可选',
                        value: item.dish_list.length
                    }];
                    item.dish_list.map((item2: any, index: number) => {
                        if(index + 1 < item.dish_list.length){
                            let obj = {
                                'name': `${item.dish_list.length}选${index+1}可用`,
                                'value': index + 1
                            }
                            arr.push(obj)
                        }
                    })
                    item.option = arr;
                })
                console.log('cateList AA',cateList)

                temp_list.map((item: any) => {
                    cateList.map((cate: any) => {
                        if(item.name == cate.cat_name){
                            item.dish_list.map((item2: any) => {
                                item2.count = item2.num;
                                item2.id = item2.dish_id;
                                cate.dish_list.map((item3: any) => {
                                    if(item2.dish_id == item3.id){
                                        item3.count = item2.count;
                                    }
                                })
                            })
                        }
                        if(item.cat_id == cate.cat_id){
                            item.dish_list.map((item2: any) => {
                                item2.count = item2.num;
                                item2.id = item2.dish_id;
                                cate.dish_list.map((item3: any) => {
                                    if(item2.dish_id == item3.id){
                                        item3.count = item2.count;
                                    }
                                })
                            })
                        }
                    })
                })
                console.log('initEdit  selectList',temp_list)
                this.setState({
                    selectList: temp_list,
                    cateList
                }, () => {this.handleGoods()})
            }
            if (resData.dishes !== null) {
                let temp_dishes = JSON.parse(resData.dishes);

                this.setState({
                    set_content: temp_dishes,
                    is_edit: true
                })
            } else {
                this.setState({
                    price: resData.price,
                    stock: resData.price_vip
                })
                if (resData.price_vip !== null && resData.price_vip !== '0.00') {
                    this.setState({
                        price_vip: resData.price_vip,
                    })
                }
            }
        }
    }

    // 分类列表
    onInitSort = async () => {
        const params: any = {
            pageSize: 100,
            type: 2
        };
        const res: any = await getRequest(apiUrl.dishesGetList, params);
        if (res.code === 1) {
            if (this.props.location.state !== undefined) {
                let list = res.data;
                list.map((item: any, index: any) => {
                    if(this.state.dep_id == item['id']) {
                        this.setState(() => {
                            return {
                                dishes_type: item['name']
                            }
                        })
                    }
                })
            }
            this.setState({
                catList: res.data
            })
        }
    }

    // 套餐渠道分类列表
    onInitSortCannel = async () => {
        const params: any = {
            pageSize: 100
        };
        const res: any = await getRequest(apiUrl.getChannel, params);
        if (res.code === 1) {
            if (this.props.location.state !== undefined) {
                let list = res.data;
                list.map((item: any, index: any) => {
                    if(this.state.channel == item['key']) {
                        this.setState({
                            dishes_select: item['v']
                        })
                    }
                })
            }
            this.setState({
                sortListChannel: res.data
            })
        }
    }

    //套餐渠道
    channel = (e: any) => {
        this.setState({
            channel: e
        })
    }

    // 商品分类
    typeChange = (e: any) => {
        this.setState({ cat_id: e, dep_id: e });
    }

    //
    nameChange = (e: any) => {
        let name = e.target.name;
        let value = e.target.value.trim()
        this.setState({
            [name]: value
        })
    }

    //套餐价格
    setPriceAdd = (e: any) => {
        let name = e.target.name;
        let value = (e.target.value)  //onlyNumbers
        this.setState({
            [name]: value
        })
    }

    // 是否可打折
    inputChange = (e: any) => {
        let name = e.target.name;
        let value = e.target.value;
        this.setState({
            [name]: value
        })
    }
    // 获取图片token
    beforeUpload = async (file: any) => {
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
            this.setState({
                loading: false,
            }, () => {
                message.error(file.name + "图片大小超出限制，请修改后重新上传");
                return;
            })
        } else {
            const res: any = await getRequest(apiUrl.getUploadToken, { type: 'food' });
            if (res && res.data) {
                const key = `food/${Math.random().toString(36).slice(-8)}${(new Date()).valueOf()}.png`
                this.setState({
                    updateObj: { token: res.data.upload_token, region: `https://up-z2.qiniu.com`, key: key, 'x:type': "food" }
                })
            }
        }
    }

    // 商品头图上传
    handleChange = (info: any) => {
        const isLt5M = info.file.size / 1024 / 1024 < 5;
        if(isLt5M) {
            if (info.file.status === 'uploading') {
                this.setState({ loading: true });
                return;
            }
            if (info.file.status === 'done') {
                this.setState({
                    imageUrl: info.file.response.url,
                    imageObj: info.file.response,
                    loading: false,
                })
            }
        }
    };

    labelPicChange = (info: any, index: number) => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }

        if (info.file.status === 'done') {
            let attribute: attrItem[] = this.state.attribute;
            attribute[index].pic = info.file.response.key;
            attribute[index].pic_url = info.file.response.url;
            this.setState({ attribute, loading: false, });
        }
    }

    // 图片预览
    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = async (file: any) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64More(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
        });
    };

    //展示方式
    goodPhotoChange = (e: any) => {
        this.setState({
            display_mode: e
        })
    }

    //提交保存
    handleSubmit = async (e: any, typeAdd: any) => {
        e.preventDefault();

        let goodInfo: any = {};
        const { location } = this.props;
        if (location.state !== undefined) {
            goodInfo.id = location.state.id;
        }
        const { display_mode, is_print_name, serial_number, name, cat_id, people_num_eat, left_top_tips, right_top_tips, stock_type, price, price_vip, imageUrl, imageObj, is_no_discount, show_mini_program, tips } = this.state;
        let channel: any = this.state.channel;
        let sortListChannel: any = this.state.sortListChannel;
        let selectList:any = this.state.selectList;
        let flag = true;
        const attribute: attrItem[] = this.state.attribute;

        if (channel === undefined || channel === 0) {
            message.warning({ content: '请选择套餐渠道!', key, duration: 2 });
            return;
        }

        if(channel === 7) {
            if (imageUrl === null || imageUrl === '') {
                message.warning({ content: '请选择商品图片!', key, duration: 2 });
                return;
            }
        }

        if (name === undefined || name === '') {
            message.warning({ content: '请输入套餐名称!', key, duration: 2 });
            return;
        }

        sortListChannel.map((item: any, index: any) => {
            if(item['key'] == channel) {
                if(item['val'] == '小程序') {
                    if (cat_id === 0) {
                        message.warning({ content: '请选择套餐分类!', key, duration: 2 });
                        return;
                    }
                }
            }
        })

        if (stock_type === 'normal') {
            if (price == '') {
                message.warning({ content: '请输入商品价格!', key, duration: 2 });
                return;
            }
        } else {
            this.state.specs_type.forEach((item: specs) => {
                if (item.name === undefined || item.name === '' || item.name === null) {
                    flag = false;
                }
                item.value.forEach(child => {
                    if (child.name === undefined || child.name === '' || child.name === null) {
                        flag = false;
                    }
                });
            });
            attribute.forEach(attr => {
                if (attr.price == 0 || attr.price == undefined || attr.price == null) {
                    flag = false;
                }
            });
            if (!flag) {
                message.warning({ content: '请输入商品库存或规格值或价格!', key, duration: 2 });
                return;
            }
        }
        goodInfo.set_meal_no = serial_number;
        goodInfo.name = name;
        goodInfo.discount = is_no_discount;
        goodInfo.is_print_name = is_print_name;
        goodInfo.channel = channel;
        goodInfo.show_type = display_mode; //展示方式
        if (people_num_eat != '') {
            goodInfo.recommend_num = people_num_eat;
        }
        if (left_top_tips !== '') {
            goodInfo.left_top_tips = left_top_tips;
        }
        if (right_top_tips !== '') {
            goodInfo.right_top_tips = right_top_tips;
        }
        if (stock_type === 'normal') {
            goodInfo.price = price;
            if (price_vip != '') {
                goodInfo.vip_price = price_vip;
            }
        } else {
            goodInfo.specs_info = {
                specs_type: this.state.specs_type,
                attribute: attribute
            }
        }

        if (imageUrl !== null && imageUrl !== '') {
            goodInfo.main_pic = imageObj.key;
        }

        if(channel === 7) {
            goodInfo.show_mini_program = show_mini_program;
        }

        //套餐为空判断
        if(selectList.length == 0) {
            message.warning({ content: '套餐菜品不能为空!', key, duration: 2 });
            return;
        }

        if(tips){
            goodInfo.tips = tips;
        }

        //拼接分组数据
        //种类
        console.log('save selectList',selectList)
        let dishes:any = [];
        selectList.map((item:any) => {
            let arr:any = [];
            item.dish_list.map((dish: any) => {
                arr.push({
                    dish_id: dish.id,
                    num: dish.count,
                    name: dish.name,
                    price: dish.has_specs ? dish.attribute[0].price :  dish.price,
                    has_specs: dish.has_specs,
                    default: dish.default,
                    default_name: dish.default_name,
                    attribute: dish.attribute,
                    must: false,
                    dish_weigh_value_id: dish.attribute && dish.attribute[0].dish_weigh_value_id ? dish.attribute[0].dish_weigh_value_id : 0
                })
            })
            dishes.push({
                name: item.name,
                num: item.cat_num,
                id: item.cat_id,
                dishes: arr
            })
        })
        goodInfo.dishes = dishes;
        // console.log(dishes)
        //console.log('goodInfo:',goodInfo)
        // return
        message.loading({ content: '发布中', key });
        if (location.state !== undefined) {
            goodInfo.cat_id = cat_id;
            const res: any = await postRequest(apiUrl.setMealUpdate, goodInfo);
            if (res['code'] === 1) {
                message.success({ content: '修改成功!', key, duration: 2 });
                if(typeAdd == 'add') {
                    this.props.history.goBack();
                } else {
                    window.location.reload()
                }
            } else {
                message.error({ content: '修改失败!', key, duration: 2 });
            }
        } else {
            goodInfo.cat_id = cat_id;
            const res: any = await postRequest(apiUrl.getSetMealAdd, goodInfo);
            if (res['code'] === 1) {
                message.success({ content: '发布成功!', key, duration: 2 });
                if(typeAdd == 'add') {
                    this.props.history.goBack();
                } else {
                    window.location.reload()
                }
            } else {
                message.error({ content: '发布失败!', key, duration: 2 });
            }
        }
    };

    //添加分组
    onAddGroup = () => {
        let set_content: any = this.state.set_content;
        set_content.push({num: '', name: ''})
        this.setState(() => {
            return {
                set_content
            }
        })
    }

    // 返回上一页
    goBack = () => {
        this.props.history.goBack();
    }

    onMoreSet = () => {
        this.setState(() => {
            return {
                more_txt: !this.state.more_set ? '展开更多设置' : '收起更多设置',
                more_set: !this.state.more_set
            }
        })
    }

    //添加新分类
    onAddNewType = () => {
        this.setState({
            add_type_statue: !this.state.add_type_statue
        })
    }

    onShowText = () => {
        return <div>
            <div>不选择就餐人数，点套餐要追加茶位费</div>
            <div>选择就餐人数，点套餐不追加茶位费</div>
        </div>
    }

    clickWeigh = (index:any, index1: any) => {
        let cateList: any = this.state.cateList;
        let { currentCate } = this.state;
        cateList[currentCate].dish_list[index].weigh[0].values.map((item: any)=>{
            item.sel = false
        })
        cateList[currentCate].dish_list[index].weigh[0].values[index1].sel = true;
        cateList[currentCate].dish_list[index].attribute[0].price = cateList[currentCate].dish_list[index].weigh[0].values[index1].price
        cateList[currentCate].dish_list[index].attribute[0].weigh_name = cateList[currentCate].dish_list[index].weigh[0].values[index1].name
        cateList[currentCate].dish_list[index].attribute[0].price_vip = cateList[currentCate].dish_list[index].weigh[0].values[index1].price_vip
        cateList[currentCate].dish_list[index].attribute[0].dish_weigh_value_id = cateList[currentCate].dish_list[index].weigh[0].values[index1].id
        this.setState({
            cateList
        })
    }

    onTipsChange = (e: any) => {
        this.setState({
            tips: e.target.value
        })
    }

    getPrice = (item:any) => {
        let price:any = 0;
        //if(item.has_specs){
            // if(item.attribute){
            //     price = item.attribute[0].price
            // }else if(item.weigh){
            //     item.weigh[0].values.map((weigh:any) => {
            //         if(weigh.sel){
            //             price = weigh.price
            //         }
            //     })
            // }
        if(item.weigh){
            item.weigh[0].values.map((weigh:any) => {
                if(weigh.sel){
                    price = weigh.price
                }
            })
        }else{
            price = item.price;
        }
        return price;
    }

    render() {
        const { goodPhotoOne, display_mode, add_type_statue, dishes_type, dishes_select, more_set, more_txt, sortListChannel, channel, serial_number, name, catList, people_num_eat, left_top_tips, right_top_tips, price, imageUrl, is_no_discount,} = this.state;
        const { currentCate, cateList, dishList, dish_tab, selectList, totalDish, totalPrice, is_print_name, show_mini_program, tips } = this.state;
        return(
            <div className='l_content'>
                {
                    add_type_statue
                    ? <GoodAddType entry='2' onAddType={this.onAddNewType} onShopSort={this.onInitSort}></GoodAddType>
                    : <div>
                        <div className="edit_title">添加套餐</div>
                        <Row className="mb30" type="flex" align="middle">
                            <Col span={2}>
                                <div>
                                    <span className="star"></span>
                                    <span className="color_black">套餐渠道</span>
                                </div>
                            </Col>
                            <Col span={6}>
                                <Select style={{ width: 200 }} onChange={this.channel} placeholder={dishes_select !== '' ? dishes_select : "请选择套餐渠道"}>
                                    {
                                        sortListChannel.map((item: any) => {
                                            return <Option value={item.key} key={item.key}>{item.v}</Option>
                                        })
                                    }
                                </Select>
                            </Col>
                        </Row>
                        {
                            channel == 7
                            ? <Row className="mb30" type="flex" align="middle">
                                <Col span={2}>
                                    <div>
                                        <span className="color_black">小程序显示</span>
                                    </div>
                                </Col>
                                <Col span={6}>
                                    <Radio.Group
                                        value={show_mini_program}
                                        name='show_mini_program'
                                        onChange={(e) => this.inputChange(e)}
                                    >
                                        <Radio value={2}>是</Radio>
                                        <Radio value={1}>否</Radio>
                                    </Radio.Group>
                                </Col>
                            </Row>
                            : null
                        }
                        {
                            channel == 7
                            ?   <Row className="mb30" type="flex" align="middle">
                                    <Col span={2}>
                                        <div>
                                            <span className="star"></span>
                                            <span className="color_black">商品图片</span>
                                        </div>
                                    </Col>
                                    <Col span={14}>
                                        <Upload
                                            listType="picture-card"
                                            className="avatar-uploader"
                                            showUploadList={false}
                                            action={QINIU_SERVER}
                                            beforeUpload={this.beforeUpload}
                                            onChange={this.handleChange}
                                            data={this.state.updateObj}
                                        >
                                            {
                                                imageUrl ?
                                                    <img src={imageUrl} alt="商品头图" style={{ width: '100%' }} />
                                                    : <div className="">
                                                        <Icon type={this.state.loading ? 'loading' : 'plus'} style={{ fontSize: '40px' }} />
                                                    </div>
                                            }
                                        </Upload>
                                        <div>（若分类展示样式为横图，建议上传封面图宽高为558*308；若分类展示样式为竖图尺寸宽高为266*414；上传图片大小5M以内）</div>
                                    </Col>
                                </Row>
                            :   ''
                        }
                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="color_black">套餐编号</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <Input placeholder="请输入套餐编号" value={serial_number} name='serial_number' onChange={(e) => this.nameChange(e)} type='number' />
                    </Col>
                </Row>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">套餐名称</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <Input placeholder="请输入套餐名称" type="text" value={name} name='name' onChange={(e) => this.nameChange(e)} />
                    </Col>
                </Row>

                {
                    channel == 7
                    ?   <Row className="mb30" type="flex" align="middle">
                            <Col span={2}>
                                <div>
                                    <span className="star"></span>
                                    <span className="color_black">套餐分类</span>
                                </div>
                            </Col>
                            <Col span={22} style={{display: 'flex'}}>
                                <Select style={{ width: 120 }} onChange={this.typeChange} placeholder={dishes_type !== '' ? dishes_type : "请选择分类"}>
                                    {
                                        catList.map((item: any) => {
                                            return <Option value={item.id} key={item.id}>{item.name}</Option>
                                        })
                                    }
                                </Select>
                                    <div className='goodEdit_add_type' onClick={() => {this.onAddNewType()}}>
                                        <img src={btn_add} style={{width: '22px', height: '22px', marginRight: '6px'}} />
                                        <span>添加新分类</span>
                                    </div>
                            </Col>
                        </Row>
                    :   ''
                }

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">套餐价格</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <Input placeholder="请输入套餐价格" maxLength={10} value={price} onChange={(e) => this.setPriceAdd(e)} type='number' name='price' />
                    </Col>
                </Row>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">套餐菜品</span>
                        </div>
                    </Col>
                    <Col>
                        <div className='dish_tab'>
                            <div className={`dish_tab_item ${dish_tab === 'added' ? 'active' : ''}`} onClick={() => {this.setState({dish_tab: 'added'})}}>已添加菜品</div>
                            <div className={`dish_tab_item ${dish_tab === 'dish' ? 'active' : ''}`} onClick={() => {this.setState({dish_tab: 'dish'})}}>菜单</div>
                        </div>

                        {dish_tab === 'added'
                        ?   selectList && selectList.length > 0 ? <div className='dish_con' style={{position:'relative', paddingBottom: '54px'}}><div className='added_con'>
                            {
                                selectList.map((item: any, index1: number) => {
                                    return <div className='set_select_item' key={item.cat_id}>
                                            <div className='select_top'>
                                                <div style={{display: 'flex', alignItems:'center'}}>
                                                    <div>{item.name}</div>
                                                    <Select style={{width: '150px', marginLeft: '20px'}} value={item.cat_num} onChange={(e:any) => this.selectChange(e, index1)}>
                                                        {
                                                            item.option && item.option.map((op: any) => {
                                                                return <Option value={op.value} key={op.value}>{op.name}</Option>
                                                            })
                                                        }
                                                    </Select>
                                                </div>
                                                <div className='dish_move'>
                                                    <div className='set_up_btn' onClick={() => this.moveTab('up', index1)}>
                                                        <img src={set_up} />
                                                    </div>
                                                    <div className='set_down_btn' onClick={() => this.moveTab('down',index1)}>
                                                        <img src={set_down} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='select_dish'>
                                                {
                                                    item.dish_list.map((item2:any, index2: number) => {
                                                        return <div className='select_dish_item' key={item2.id}>
                                                            <div className='item_name'>
                                                                {item2.name}
                                                                {item2.attribute && item2.attribute[0].weigh_name ? '('+item2.attribute[0].weigh_name+')' : ''}
                                                            </div>
                                                            <div className='item_price'>￥{this.getPrice(item2)}/份</div>
                                                            <div className='dish_action'>
                                                                <div className='dish_reduce' onClick={() => this.addReduce(index1, index2, item2.id)}>
                                                                    <img src={set_reduce} />
                                                                </div>
                                                                <div className='dish_count'>{item2.count}</div>
                                                                <div className='dish_add' onClick={() => this.addAdd(index1, index2, item2.id)}>
                                                                    <img src={set_add} />
                                                                </div>
                                                            </div>
                                                            <div className='dish_move'>
                                                                <div className='set_up_btn' onClick={() => this.moveItem('up', index1, index2)}>
                                                                    <img src={set_up} />
                                                                </div>
                                                                <div className='set_down_btn' onClick={() => this.moveItem('down', index1, index2)}>
                                                                    <img src={set_down} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    })
                                }
                                </div>
                                <div className='dish_con_bottom' style={{left: 0, width: '100%'}}>已选择{totalDish}个菜品，价值{totalPrice.toFixed(2)}</div>
                            </div>
                            :  <div className='no_dish'>
                                <div className='no_dish_tip'>请在菜单中选择菜品，即可快速添加套餐</div>
                                <div className='no_dish_btn' onClick={() => {this.setState({dish_tab: 'dish'})}}>
                                    <img src={dish} />
                                    菜单中选择
                                </div>
                            </div>
                        : <div className='dish_con'>
                            <div className='dish_cate'>
                                {
                                    cateList.map((item:any, index: number) => {
                                        return <div className={`dish_cate_tab ${currentCate == index ? 'dish_active' : ''}`}
                                                    key={item.cat_id}
                                                    onClick={() => this.changeCate(index)}>{item.cat_name}</div>
                                    })
                                }
                            </div>
                            <div className='dish_con_right'>
                                    {
                                        dishList.map((item: any, index: number) => {
                                            return <div className='dish_item' key={item.id}>
                                                    <div className='dish_left'>
                                                        <div className='dish_img'>
                                                            <img src={item.main_pic} />
                                                        </div>
                                                        <div>
                                                            <div className='dish_name'>{item.name}</div>
                                                            {item.weigh && item.weigh.map((item:any) => {
                                                                return(
                                                                    <div className="container" key={item.id}>
                                                                        <div className="list" style={{display: 'flex', alignItems: 'center'}}>
                                                                        {item.values.map((values:any,weighIndex:number) => {
                                                                            return(
                                                                                <div className={values.sel ? "weight_item_sel": 'weight_item'} key={values.id} onClick={()=>{this.clickWeigh(index,weighIndex)}}>{values.name}</div>
                                                                            )
                                                                        })}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                    <div className='dish_center'>￥{this.getPrice(item)}/份</div>
                                                    <div className='dish_right'>
                                                        <div className='dish_reduce' onClick={() => this.reduceDish(index, item.id)}>
                                                            <img src={set_reduce} />
                                                        </div>
                                                        <div className='dish_count'>{item.count ? item.count : 0}</div>
                                                        <div className='dish_add' onClick={() => this.addDish(index, item.id)}>
                                                            <img src={set_add} />
                                                        </div>
                                                    </div>
                                                </div>
                                        })
                                    }
                                <div className='dish_con_bottom'>
                                    已选择{totalDish}个菜品，价值{totalPrice.toFixed(2)}
                                    <div className='check_btn' onClick={() => {this.setState({dish_tab: 'added'})}}>查看已添加</div>
                                </div>
                            </div>
                        </div>
                        }
                    </Col>
                </Row>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">展示方式</span>
                        </div>
                    </Col>
                    <Col span={22}>
                        <Radio.Group
                            value={display_mode}
                            style={{ marginLeft: 20 }}
                            onChange={(e: any) => this.goodPhotoChange(e.target.value)}
                        >
                            <Radio style={{marginRight: '30px'}} value={1}>横图展示
                                <span onMouseEnter={() => {this.setState({goodPhotoOne: 1})}} onMouseLeave={() => {this.setState({goodPhotoOne: 0})}} style={{color: '#7480FE', marginLeft: '10px', cursor: 'pointer'}}>预览</span>
                                <div style={{position: 'absolute', top: '-600px',left: '0%',backgroundColor: '#fff',zIndex: 9}}>
                                    {
                                        goodPhotoOne == 1
                                        ?   <img src={goodPhoto1} style={{width: '290px', height: 'auto'}} />
                                        :   ''
                                    }
                                </div>
                                <span style={{position: 'absolute',bottom: '-20px',left: '25px',color: '#5F5F5F',fontSize: '12px'}}>尺寸558*308</span>
                            </Radio>
                            <Radio style={{marginRight: '30px'}} value={2}>竖图展示
                                <span onMouseEnter={() => {this.setState({goodPhotoOne: 2})}} onMouseLeave={() => {this.setState({goodPhotoOne: 0})}} style={{color: '#7480FE', marginLeft: '10px', cursor: 'pointer'}}>预览</span>
                                <div style={{position: 'absolute', top: '-600px',left: '0%',backgroundColor: '#fff',zIndex: 9}}>
                                    {
                                        goodPhotoOne == 2
                                        ?   <img src={goodPhoto2} style={{width: '290px', height: 'auto'}} />
                                        :   ''
                                    }
                                </div>
                                <span style={{position: 'absolute',bottom: '-20px',left: '25px',color: '#5F5F5F',fontSize: '12px'}}>尺寸264*264</span>
                            </Radio>
                            <Radio style={{marginRight: '30px'}} value={3}>大图展示
                                <span onMouseEnter={() => {this.setState({goodPhotoOne: 3})}} onMouseLeave={() => {this.setState({goodPhotoOne: 0})}} style={{color: '#7480FE', marginLeft: '10px', cursor: 'pointer'}}>预览</span>
                                <div style={{position: 'absolute', top: '-600px',left: '0%',backgroundColor: '#fff',zIndex: 9}}>
                                    {
                                        goodPhotoOne == 3
                                        ?   <img src={goodPhoto3} style={{width: '290px', height: 'auto'}} />
                                        :   ''
                                    }
                                </div>
                                <span style={{position: 'absolute',bottom: '-20px',left: '25px',color: '#5F5F5F',fontSize: '12px'}}>尺寸558*622</span>
                            </Radio>
                            <Radio style={{marginRight: '30px'}} value={4}>横小图展示
                                <span onMouseEnter={() => {this.setState({goodPhotoOne: 4})}} onMouseLeave={() => {this.setState({goodPhotoOne: 0})}} style={{color: '#7480FE', marginLeft: '10px', cursor: 'pointer'}}>预览</span>
                                <div style={{position: 'absolute', top: '-600px',left: '0%',backgroundColor: '#fff',zIndex: 9}}>
                                    {
                                        goodPhotoOne == 4
                                        ?   <img src={goodPhoto4} style={{width: '290px', height: 'auto'}} />
                                        :   ''
                                    }
                                </div>
                                <span style={{position: 'absolute',bottom: '-20px',left: '25px',color: '#5F5F5F',fontSize: '12px'}}>尺寸188*186</span>
                            </Radio>
                            <Radio value={5}>竖小图展示
                                <span onMouseEnter={() => {this.setState({goodPhotoOne: 5})}} onMouseLeave={() => {this.setState({goodPhotoOne: 0})}} style={{color: '#7480FE', marginLeft: '10px', cursor: 'pointer'}}>预览</span>
                                <div style={{position: 'absolute', top: '-600px',left: '0%',backgroundColor: '#fff',zIndex: 9}}>
                                    {
                                        goodPhotoOne == 5
                                        ?   <img src={goodPhoto5} style={{width: '290px', height: 'auto'}} />
                                        :   ''
                                    }
                                </div>
                                <span style={{position: 'absolute',bottom: '-20px',left: '25px',color: '#5F5F5F',fontSize: '12px'}}>尺寸188*186</span>
                            </Radio>
                        </Radio.Group>
                    </Col>
                </Row>

                {
                    more_set
                    ? ''
                    : <Row>
                        <Row className="mb30" type="flex" align="middle">
                            <Col span={2}>
                                <div>
                                    <span className="">  </span>
                                    <span className="color_black">菜品推荐人数</span>
                                </div>
                            </Col>
                            <Col span={6}>
                                <Input placeholder="请输入菜品推荐人数" type="number" maxLength={10} value={people_num_eat} name='people_num_eat' onChange={(e) => this.nameChange(e)}/>
                            </Col>
                        </Row>

                        <Row className="mb30" type="flex" align="middle">
                            <Col span={2}>
                                <div>
                                    <span className="color_black">左上角标签</span>
                                </div>
                            </Col>
                            <Col span={6}>
                                <Input placeholder="请输入左上角标签" maxLength={10} value={left_top_tips} name='left_top_tips' onChange={(e) => this.nameChange(e)} />
                            </Col>
                        </Row>

                        <Row className="mb30" type="flex" align="middle">
                            <Col span={2}>
                                <div>
                                    <span className="color_black">右上角标签</span>
                                </div>
                            </Col>
                            <Col span={6}>
                                <Input placeholder="请输入右上角标签" maxLength={10} value={right_top_tips} name='right_top_tips' onChange={(e) => this.nameChange(e)} />
                            </Col>
                        </Row>

                        <Row className="mb30" type="flex" align="middle">
                            <Col span={2}>
                                <div>
                                    <span className="color_black">参与折扣</span>
                                </div>
                            </Col>
                            <Col span={6}>
                                <Radio.Group
                                    value={is_no_discount}
                                    name='is_no_discount'
                                    onChange={(e) => this.inputChange(e)}
                                >
                                    <Radio value={true}>是</Radio>
                                    <Radio value={false}>否</Radio>
                                </Radio.Group>
                            </Col>
                        </Row>

                        <Row className="mb30" type="flex" align="middle">
                            <Col span={2}>
                                <div>
                                    <span className="color_black">打印套餐名称</span>
                                </div>
                            </Col>
                            <Col span={6}>
                                <Radio.Group
                                    value={is_print_name}
                                    name='is_print_name'
                                    onChange={(e) => this.inputChange(e)}
                                >
                                    <Radio value={true}>是</Radio>
                                    <Radio value={false}>否</Radio>
                                </Radio.Group>
                            </Col>
                        </Row>

                        {
                            display_mode == 3 || display_mode == 1
                            ?   <Row className="mb30" type="flex" align="middle">
                                    <Col span={2}>
                                        <div>
                                            <span className="color_black">套餐详情</span>
                                        </div>
                                    </Col>
                                    <Col span={6}>
                                        <TextArea value={tips} allowClear onChange={(e: any) => {this.onTipsChange(e)}}></TextArea>
                                    </Col>
                                </Row>
                            : ''
                        }
                    </Row>
                }

                    <Row>
                        <div style={{cursor: 'pointer'}} onClick={() => {this.onMoreSet()}}>
                            <span style={{color: '#2589ff', width: '100px'}}>{more_txt}</span>
                            <Icon style={{color: '#2589ff', width: '30px'}} type="caret-down" />
                            <span className="line"></span>
                        </div>
                    </Row>

                <Row>
                    <Col span={6} offset={2}>
                        <div className="bottom_wrap">
                            <Button type="primary" onClick={(e) => {this.handleSubmit(e,'add')}}>
                                保存
                            </Button>
                                    {
                                        this.props.location.state !== undefined
                                        ? <Button type="primary" onClick={(e) => {this.handleSubmit(e,'save')}}>
                                            保存并继续编辑
                                        </Button>
                                        : <Button type="primary" onClick={(e) => {this.handleSubmit(e,'save')}}>
                                            保存并继续添加
                                        </Button>
                                    }
                            <Button type="primary" ghost onClick={this.goBack}>
                                取消
                            </Button>
                        </div>
                    </Col>
                </Row>
                </div>
                }
            </div>
        )
    }

}
export default GoodsEdit