import React, { useEffect, useState, useLayoutEffect } from "react";
import { getRequest, postRequest } from "./../../api/http";
import { apiUrl } from "./../../api/api_url";
import {Row,Col,Button,Table,Tag,Popconfirm,Drawer,Form,Input,Modal,message} from 'antd'


import CreateQRCode from './createQRCode'

import {codeData} from './dataType'

const QRCode = ()=>{

    const[tableSource,setTableSource] = useState([])
    const[drawerCreateVisible,setDrawerCreateVisible] = useState(false)

    const[editData,setEditData] = useState({})

    // 获取任务详情
    const selectQRCodeRequest = async () =>{
        const res:any = await getRequest(`${apiUrl.selectQRCode}`);
        return res;
    };

    // 删除
    const deleteQRCodeRequest = async (id:Number) =>{
        const res:any = await postRequest(`${apiUrl.deleteQRCode}`,{id});
        return res;
    };

    const columns = [
        {
          title: '编号',
          dataIndex: 'id',
          key: 'id',
          width: 80,
        },
        {
            title: '标题',
            dataIndex: 'name',
            key: 'name',
            width: 200,
        },

        {
          title: '推送二维码',
          dataIndex: 'third_url',
          key: 'third_url',
          width: 100,
          render: (rowdata:any,record:any,idx:number)=>{
              return (
                  <p><img src={rowdata} width={100} height={100} /></p>
              )
          }
        },
        {
            title: '关注二维码',
            dataIndex: 'siyu_url',
            key: 'siyu_url',
            width: 100,
            render: (rowdata:any,record:any,idx:number)=>{
                return (
                    <p><img src={rowdata} width={100} height={100} /></p>
                )
            }
          },
        {
          title: '是否启用',
          dataIndex: 'is_use',
          key: 'is_use',
          render: (rowdata:any,record: any, idx: number)=>{
              return(
                  <div>
                    {
                        rowdata === 1 ? <Tag color="#87d068">已启用</Tag> : <Tag color="#f50">未启用</Tag>
                    }
                </div>
              )
          }
        },
        {
            title: '关注数量',
            dataIndex: 'attention_yes_nums',
            key: 'attention_yes_nums',
            width: 200,
        },

        {
            title: '取关数量',
            dataIndex: 'attention_no_nums',
            key: 'attention_no_nums',
            width: 200,
        },

        {
            title: '昨日待返现总额',
            dataIndex: 'earnings_last_day',
            key: 'earnings_last_day',
            width: 200,
        },
        // {
        //     title: '昨日总红包数',
        //     dataIndex: 'packet_num_lastday',
        //     key: 'packet_num_lastday',
        //     width: 200,
        // },
        {
            title: '今日待返现总额',
            dataIndex: 'cash_back_amount_all',
            key: 'cash_back_amount_all',
            width: 200,
        },
        // {
        //     title: '今日总红包数',
        //     dataIndex: 'packet_num_today',
        //     key: 'packet_num_today',
        //     width: 200,
        // },


        {
            title: '创建时间',
            dataIndex: 'created_at',
            key: 'created_at',
            width: 200,
        },
        // {
        //     title: '修改时间',
        //     dataIndex: 'updated_at',
        //     key: 'updated_at',
        //     width: 200,
        // },
        {
            title: '备注',
            dataIndex: 'desc',
            key: 'desc',
            width: 200,
        },
        {
            title: '操作',
            key: 'action',
            render:(rowdata:any,record:any,idx:number) => {
                return (
                    <div>
                        <Button type="primary" onClick={() => {editAction(rowdata)}}>编辑</Button>
                        <Button type="danger" onClick={() => {immediatelyReturnAction(rowdata)}}>立即返现</Button>
                        <Popconfirm
                            placement="bottomRight"
                            title={'是否删除？'}
                            onConfirm={()=>{deleteQRCode(rowdata.id)}}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button>删除</Button>
                        </Popconfirm>
                    </div>
                )
            }

        },
    ];

    function confirm(rowdata:codeData) {
        Modal.confirm({
          title: '确认返现？？',
          content: `返现金额为${rowdata.earnings_last_day}`,
          okText: '确认',
          cancelText: '取消',
          onOk:()=>{
            immediatelyReturnRequest(rowdata);
          }
        });

      }

    const immediatelyReturnAction = (rowdata:codeData) => {
        confirm(rowdata)
        // immediatelyReturnRequest().then((res) => {
        //     console.log(res)
        // })

    }

    const immediatelyReturnRequest = async (rowdata:codeData)=> {
        const res:any = await getRequest(`${apiUrl.immediatelyReturnAction}`,{id:rowdata.id,unique_key:rowdata.unique_key});
        if(res.code === 1){
            message.info('立即返现成功！');
        }else{
            message.error(res.message)
        }
        // return res;
    }

    const editAction = (codeData:codeData) =>{
        setEditData(codeData)

    }

    const deleteQRCode = (qrcodeId:Number) => {
        console.log(qrcodeId);
        deleteQRCodeRequest(qrcodeId).then((res)=>{
            if(res.code === 1){
                getList()
            }
        });
    }

    const addQRCode = () => {
        setEditData({})
    }

    const getList = async() => {
        selectQRCodeRequest().then((res) => {
            console.log(res);
            setTableSource((data) => data = res.data)
        });
    }

    useEffect(()=>{
        console.log('只执行一次')
        getList()
        setDrawerCreateVisible(false)
    },[])

    useEffect(()=>{
        console.log('useEffect更新')
    },[])

    useLayoutEffect(()=> {
        console.log('useLayoutEffect editData 更新')
        setDrawerCreateVisible(true)
    },[editData])

    const cloneRe = () => {
        setDrawerCreateVisible(false)
        getList()
    }



    return(
        console.log('useEffect更新editData',editData !== undefined ? editData : 'aaa'),
        <div className="qrcode l_content">
            <CreateQRCode visible={drawerCreateVisible} setVisible={()=> {cloneRe()}} data={editData} ></CreateQRCode>
            <Row>
                <Col span={24}>
                    <Button type="primary" size="large" icon="plus" onClick={()=> {addQRCode()}}>添加二维码</Button>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Table dataSource={tableSource} size="middle" rowKey={'id'} columns={columns} />
                </Col>
            </Row>
        </div>
    )
}

export default QRCode