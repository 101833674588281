import React, { useState, useEffect } from 'react'

import './storeUserList.scss'
import { Row, Col, Button, Select, Table } from 'antd';
import Search from 'antd/lib/input/Search';
import { getRequest } from '../../../api/http';
import { apiUrl } from '../../../api/api_url';
import { Link } from 'react-router-dom';

type Iprops = {

}

const {Option} = Select;



const StoreUserList = (props:Iprops) =>{
    const [userList,setUserList] = useState<any>([]);
    const [count,setCount] = useState(0);
    const [page,setpage] = useState(1);
    const [page_size,setpage_size] = useState(10);
    // const page_size = 8;
    // let page = 1;
    let userName = '';
    let userPhone = '';
    let userStatus = '0';
     // 相当于 componentDidMount 和 componentDidUpdate:
    useEffect(() =>{
        getStoreUserList();
    },[]);

    // 获取商家端用户列表
    const getStoreUserList =  async (param = {}) =>{
        let params = {
            page: page,
            page_size: page_size,
            nickname: userName,
            phone_number: userPhone,
            status: userStatus,
            ...param
        }
        const response:any = await getRequest(apiUrl.employeeList,params);
        if(response.code === 1){
            setUserList(response.data.list);
            setCount(response.data.count);
        }
    }


    const columns = [
        {
          title: '商家端用户姓名',
          dataIndex: 'nickname',
          key: 'nickname',
        },
        {
          title: '电话',
          dataIndex: 'phone_number',
          key: 'phone_number',
        },
        {
          title: '状态',
          dataIndex: 'status',
          key: 'status',
          render: (text:string,record:any,index:number) =>
          <div>
              {record.status === 1 ? '正常':'冻结'}
          </div>
        },
        {
            title: '创建时间',
            dataIndex: 'create_time',
            key: 'create_time',
        },
        {
            title: '操作',
            dataIndex: 'address',
            key: 'address',
            render: (text:string,record:any,index:number) =>
            <div className="action_style">
                <div className="style_item">编辑</div>
                <div className="style_item">{record.status === 1 ? '设为冻结':'设为正常'}</div>
            </div>
        },
      ];


    return (
        <div className="store_user_list l_content">
            <Row align="middle" justify="center">
                <Col span={2}>
                    <Link to='/sidebar/addStoreUser'>
                        <Button type="primary" icon='user'>添加</Button>
                    </Link>
                </Col>
                <Col span={2}>
                    用户姓名
                </Col>
                <Col span={3}>
                    <Search
                        placeholder="输入内容"
                        onSearch={value => {
                            userName = value;
                            getStoreUserList();
                        }}
                    />
                </Col>
                <Col span={2}  offset={1}>
                    用户电话
                </Col>
                <Col span={3}>
                    <Search
                        placeholder="输入内容"
                        onSearch={value => {
                            userPhone = value;
                            getStoreUserList();
                        }}
                    />
                </Col>
                <Col span={2}  offset={1}>
                    用户状态
                </Col>
                <Col span={3}>
                    <Select
                        showSearch
                        defaultValue="0"
                        onChange={(e:any) => {
                            userStatus = e;
                            getStoreUserList();
                        }}
                        style={{ width: 200 }}
                    >
                        <Option value="0">全部</Option>
                        <Option value="1">正常</Option>
                        <Option value="2">冻结</Option>
                    </Select>
                </Col>
            </Row>
            <Table
                dataSource={userList}
                columns={columns}
                rowKey={"id"}
                size="middle"
                pagination={ {
                    current: page,
                    total: count,
                    pageSize: page_size,
                    onChange: (page1:number) =>{
                        setpage(page1)
                        getStoreUserList({page: page1});
                    },
                    showQuickJumper: true,
                    showSizeChanger: true,
                    showTotal: (total: number) => `共${total}条`,
                    onShowSizeChange: (current:number, size: number) => {setpage_size(size); getStoreUserList({page_size: size})}
                }}
            />
        </div>
    );
}

export default StoreUserList;