// 参与用户

import React, { Fragment, useState } from 'react'
import { Row, Button, Form, Col, Select, Input, AutoComplete } from 'antd'
import DataSummaryItem from '../order_list/components/data_summary_item'
import ParticipatingTableList from './components/participating_table_list';
import { searchUserList } from '../../../../utils/utils';

const { Option } = Select;

export interface IoverView {
    "partake_users": number,  // 参与分销人数
    "invited_users": number,  // 受邀总人数
    "back_users": number,     // 分销出单人数
    "order_counts": number   // 分销订单总数
}

interface IUserItem {
  "id": number,
  "nickname": string,
  "avatar": string
}

const ParticipatingUser = () => {
  
  const [overView, setoverView] = useState({
      "partake_users": 0,  // 参与分销人数
      "invited_users": 0,  // 受邀总人数
      "back_users": 0,     // 分销出单人数
      "order_counts": 0   // 分销订单总数
  });
  let [searchCondition, setsearchCondition] = useState({
    status: 0,
    username: ''
  });

  const [searchUsersTipsList, setsearchUsersTipsList] = useState<Array<string>>([]);

  const status = [{
    value: 0,
    text: "全部"
  },{
    value: 5,
    text: "预热中"
  },{
    value: 10,
    text: "进行中"
  },{
    value: 15,
    text: "暂停"
  },{
    value: 20,
    text: "结束"
  }];

  const onFormChange = (e: any, type: string) => {
    searchCondition = {...searchCondition, [type]: e}
    setsearchCondition(searchCondition);
  };

  const getUserList = async (name: string) => {
    if(!name) return;
    const data = await searchUserList(name);
    if(data.length > 0) {
      setsearchUsersTipsList(data);
    }
  }

  return <Fragment>
    <Row>
      <DataSummaryItem
        tips="至少带来一个邀请人数的用户数"
        mainTitle={`${overView.partake_users}人`}
        subTitle="参与分销人数"
      />
      <DataSummaryItem
        tips=""
        mainTitle={`${overView.invited_users}人`}
        subTitle="受邀总人数"
      />
      <DataSummaryItem
        tips=""
        mainTitle={`${overView.back_users}人`}
        subTitle="分销出单人数"
      />
      <DataSummaryItem
        tips="当前活动扣除返现之后的实收收益"
        mainTitle={`${overView.order_counts}笔`}
        subTitle="分销订单总数"
      />
    </Row>
    
    <Button type="primary" style={{ marginBottom: 30 }}>
      导出
    </Button>
      <Row type="flex">
        <Form.Item style={{ minWidth: 250 }}>
          <Row type="flex">
            <Col span={8}>活动状态 ：</Col>
            <Col span={12}>
              <Select style={{minWidth: 100}} defaultValue={status[0].text} onChange={(e: any) => onFormChange(e, "status")}>
                {status.map(item => {
                  return (
                    <Option key={item.text} value={item.value}>
                      {item.text}
                    </Option>
                  );
                })}
              </Select>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item style={{ minWidth: 250 }}>
          <Row type="flex">
            <Col span={8}>用户昵称 ：</Col>
            <Col span={12}>
              <AutoComplete 
                style={{minWidth: 200}} 
                placeholder="请输入用户昵称" 
                dataSource={searchUsersTipsList}
                onChange={(e) => getUserList(e.toString())} 
                onSelect={(e) => onFormChange(e, 'username')}
              />
            </Col>
          </Row>
        </Form.Item>
      </Row>
    <Row>
      <Row>
        <Col></Col>
      </Row>
    </Row>
    
    <ParticipatingTableList setoverView={setoverView} />
  </Fragment>
}

export default ParticipatingUser