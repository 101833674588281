import React, { useState, useEffect } from "react";
import { Layout, Menu, Icon, Avatar } from "antd"
import { Link, useHistory } from "react-router-dom";

import advertising from '../../../assret/images/advertising.png';
import automation from '../../../assret/images/automation.png';
import commodity from '../../../assret/images/commodity.png';
import distribution from '../../../assret/images/distribution.png';
import finance from '../../../assret/images/finance.png';
import fission from '../../../assret/images/fission.png';
import home from '../../../assret/images/home.png';
import lineup from '../../../assret/images/lineup.png';
import management from '../../../assret/images/management.png';
import marketing from '../../../assret/images/marketing.png';
import news from '../../../assret/images/news.png';
import order from '../../../assret/images/order.png';
import print from '../../../assret/images/print.png';
import real from '../../../assret/images/real.png';
import siyuuser from '../../../assret/images/siyuuser.png';
import system from '../../../assret/images/system.png';
import table from '../../../assret/images/table.png';
import usermanagement from '../../../assret/images/usermanagement.png';
import store from '../../../assret/images/store.png';
import usergroup from '../../../assret/images/usergroup.png'
import matter from '../../../assret/images/matter.png'
import business_dashboard from '../../../assret/images/business_dashboard.png'
import repurchase_operation from '../../../assret/images/repurchase_operation.png'
import management_menu from '../../../assret/images/management_menu.png'
import system_management_menu from '../../../assret/images/system_management_menu.png'

import advertising_select from '../../../assret/images/advertising_select.png';
import automation_select from '../../../assret/images/automation_select.png';
import commodity_select from '../../../assret/images/commodity_select.png';
import distribution_select from '../../../assret/images/distribution_select.png';
import finance_select from '../../../assret/images/finance_select.png';
import fission_select from '../../../assret/images/fission_select.png';
import home_select from '../../../assret/images/home_select.png';
import lineup_select from '../../../assret/images/lineup_select.png';
import management_select from '../../../assret/images/management_select.png';
import marketing_select from '../../../assret/images/marketing_select.png';
import news_select from '../../../assret/images/news_select.png';
import order_select from '../../../assret/images/order_select.png';
import print_select from '../../../assret/images/print_select.png';
import real_select from '../../../assret/images/real_select.png';
import siyuuser_select from '../../../assret/images/siyuuser_select.png';
import system_select from '../../../assret/images/system_select.png';
import table_select from '../../../assret/images/table_select.png';
import usermanagement_select from '../../../assret/images/usermanagement_select.png';
import store_select from '../../../assret/images/store_select.png';
import usergroup_select from '../../../assret/images/usergroup_select.png'
import matter_select from '../../../assret/images/matter_select.png'
import business_dashboard_select from '../../../assret/images/business_dashboard_select.png'
import repurchase_operation_select from '../../../assret/images/repurchase_operation_select.png'
import management_menu_select from '../../../assret/images/management_menu_select.png'
import system_management_menu_select from '../../../assret/images/system_management_menu_select.png'
import { getRequest } from "../../../api/http";

const { Sider } = Layout;
const { SubMenu } = Menu;

type Props = {
  collapsed: boolean
  onCollapse(): void
}

const SiderBar = (props: Props) => {

  const history = useHistory()

  const { collapsed, onCollapse } = props;

  const [selecList, setSelecList] = useState<any>(['95']);

  const [firstSelecList, setFirstSelecList] = useState<any>([]);

  const [shopImg, setshopImg] = useState('')

  const [srcList, setSrcList] = useState([
    'advertising', 'automation', 'commodity', 'distribution', 'finance', 'fission', 'home', 'lineup',
    'management', 'marketing', 'news', 'order', 'print', 'real', 'siyuuser', 'system', 'table', 'usermanagement',
    'store', 'usergroup', 'matter', 'business_dashboard', 'repurchase_operation', 'management_menu', 'system_management_menu'
  ])

  const [srcListSelect, setSrcListSelect] = useState([
    'advertising_select', 'automation_select', 'commodity_select', 'distribution_select', 'finance_select', 'fission_select', 'home_select', 'lineup_select',
    'management_select', 'marketing_select', 'news_select', 'order_select', 'print_select', 'real_select', 'siyuuser_select', 'system_select', 'table_select', 'usermanagement_select',
    'store_select', 'usergroup_select', 'matter_select', 'business_dashboard_select', 'repurchase_operation_select', 'management_menu_select',
    'system_management_menu_select'
  ])

  const [srcListPath, setSrcListPath] = useState([
    advertising, automation, commodity, distribution, finance, fission, home, lineup,
    management, marketing, news, order, print, real, siyuuser, system, table, usermanagement,
    store, usergroup, matter, business_dashboard, repurchase_operation, management_menu, system_management_menu
  ])

  const [srcListPathSelect, setSrcListPathSelect] = useState([
    advertising_select, automation_select, commodity_select, distribution_select, finance_select, fission_select, home_select, lineup_select,
    management_select, marketing_select, news_select, order_select, print_select, real_select, siyuuser_select, system_select, table_select, usermanagement_select,
    store_select, usergroup_select, matter_select, business_dashboard_select, repurchase_operation_select, management_menu_select, system_management_menu_select
  ])

  //const [currentUrl, setCurrentUrl] = useState('/sidebar/dashboardMain');
  const [currentUrl, setCurrentUrl] = useState('');

  useEffect(() => {
    let shop_info:any = localStorage.getItem('shop_info');
    shop_info && setshopImg(JSON.parse(shop_info).img)
    //let _url = history.location.pathname;
    //console.log('getMenus:',getMenus())
    getSelectPath()
  },[])

  //先从缓存获取是有值，有则用缓存的值
  const getSelectPath = () => {
    let select_path:any = window.localStorage.getItem('select_path');
    console.log('select_path:',select_path)
    if(select_path) {
      console.log('select_pathttt:',select_path)
      setCurrentUrl(select_path)
    } else {
      console.log('select_pathtfff:',select_path)
      setCurrentUrl('/sidebar/dashboardMain')
    }
  }

  const tabTop = () => {
    return <div className='menu_head' onClick={() => history.push('/')}>
      <Avatar size={32} icon='user' src={shopImg}/>
      <div className='head_store_name'>商家后台</div>
    </div>
  };
  const getMenus = () =>{
   
    let menusTemp = window.localStorage.getItem('siyu_tab_list');
    if(menusTemp){
      let tmepMenus = JSON.parse(menusTemp);
      console.log('tmepMenus',tmepMenus)
      // let tempObj = {
      //   comment: '',
      //   f_id: 0,
      //   icon: "management_menu_select",
      //   id: 2000,
      //   name: '营销',
      //   path: '',
      //   routers: [
      //     {
      //       comment: "TaskManage",
      //       f_id: 2000,
      //       icon: "business_dashboard_select",
      //       id: 20001,
      //       name: "任务宝",
      //       path: "/sidebar/taskManage",
      //       routers: []
      //     },
      //     // {
      //     //   comment: "SmallProgram",
      //     //   f_id: 2000,
      //     //   icon: "business_dashboard_select",
      //     //   id: 20002,
      //     //   name: "小程序授权",
      //     //   path: "/sidebar/smallProgram",
      //     //   routers: []
      //     // },
      //     {
      //       comment: "AccountAuthView",
      //       f_id: 2000,
      //       icon: "business_dashboard_select",
      //       id: 20003,
      //       name: "公众号授权",
      //       path: "/sidebar/accountAuthView",
      //       routers: []
      //     },
      //     {
      //       comment: "Product",
      //       f_id: 2000,
      //       icon: "business_dashboard_select",
      //       id: 20003,
      //       name: "奖励商品",
      //       path: "/sidebar/product",
      //       routers: []
      //     },
      //     // {
      //     //   comment: "Turnplate",
      //     //   f_id: 2000,
      //     //   icon: "business_dashboard_select",
      //     //   id: 20004,
      //     //   name: "大转盘",
      //     //   path: "/sidebar/turnplate",
      //     //   routers: []
      //     // }
      //   ]
      // }

      // tmepMenus.push(tempObj);
      return tmepMenus;
      // return JSON.parse(menusTemp) ? JSON.parse(menusTemp) : [];
    }else{
      let timer = setTimeout(() =>{
        menusTemp = window.localStorage.getItem('siyu_tab_list');
        if(menusTemp){
          clearTimeout(timer);
          return JSON.parse(menusTemp);
        }

      },500)
    }
  }

  //const rootSubmenuKeys = ['2', '3', '6','24','70', '80', '88', '6', '9', '16', '18', '21', '26', '28', '30', '33', '37', '39', '43', '61', '81'];
  const rootSubmenuKeys = ['95','112'];
  const [openKeys, setopenKeys] = useState<any>(['95'])

  const onOpenChange = (e: any) => {
    console.log('onOpenChange:',e)
    // setSelecList(e)
    const latestOpenKey = e.find((key:any) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setopenKeys(e);
      setSelecList(e)
      console.log('openKeys', e)
      console.log('latestOpenKey:',latestOpenKey)
    } else {
      let key = latestOpenKey ? [latestOpenKey] : []
      setopenKeys(key);
      setSelecList(key)
      console.log('key', key)
      console.log('latestOpenKey:',latestOpenKey)
    }
  }

  const onSubmenuSelect = (e: any) => {
    setCurrentUrl(e.key)
    localStorage.setItem('select_path', e.key);
  }

  return <Sider
    collapsed={collapsed}
    onCollapse={onCollapse}
    theme="light"
    className=""
    style={{
      width: '153px',
      height: "100vh",
      backgroundColor: collapsed ? "rgb(0, 21, 41)" : "linear-gradient(#e66465, #9198e5)",
    }}
  >
    {!collapsed && tabTop()}
    {/* defaultOpenKeys={[String(getMenus()[0]['id'])]} defaultSelectedKeys={[currentUrl]} mode="inline" onOpenChange={onOpenChange} */}
    <Menu className="sider_bar_new" theme="dark" mode="inline" 
      defaultOpenKeys={getMenus()?[String(getMenus()[0]['id'])]:[]} 
      selectedKeys={[currentUrl]} 
      onOpenChange={onOpenChange}
      openKeys={openKeys}
      onClick={(e) => {e.keyPath.length > 1 && setFirstSelecList([])}}
      onSelect={(e: any) => {onSubmenuSelect(e)}}
    >
        {getMenus() && getMenus().map((item:any, index:number) =>
          item.routers && item.routers.length > 0 ? (
            <SubMenu
              key={item.id}
              className={`${selecList.indexOf(String(item.id)) != -1 ? 'select_background' : 'no_select_background'}`}
              title={
                <span>
                  {
                    selecList.indexOf(String(item.id)) == -1
                    ? <Icon
                        type={item.icon}
                        component={() => (
                          <img
                            style={{width: '20px', height: '20px', marginBottom: 8}}
                            //src={srcList.indexOf(item.icon) != -1 ? srcListPath[srcList.indexOf(item.icon)] : success}
                            src={srcListPathSelect[srcListSelect.indexOf(item.icon)]}
                            alt="icon"
                          />
                        )}
                      />
                    : <Icon
                        type={item.icon}
                        component={() => (
                          <img
                            style={{width: '20px', height: '20px', marginBottom: 8}}
                            //src={srcList.indexOf(item.icon) != -1 ? srcListPath[srcList.indexOf(item.icon)] : success}
                            src={srcListPath[srcListSelect.indexOf(item.icon)]}
                            alt="icon"
                          />
                        )}
                      />
                  }
                  <span className={`${selecList.indexOf(String(item.id)) != -1 ? 'select_span' : 'no_select_span'}`}>{item.name}</span>
                </span>
              }
            >
              {item.routers.map((item1: any, index1: number) =>
                item1.routers && item1.routers.length > 0 ? (
                  <SubMenu
                    key={item1.id}
                    title={
                      <span>
                        <Icon type={item1.icon} />
                        <span>{item1.name}</span>
                      </span>
                    }
                  >
                    {item1.routers.map((item2: any, index2: number) =>
                      item2.routers && item2.routers.length > 0 ? (
                        <SubMenu
                          key={item2.id}
                          title={
                            <span>
                              <Icon type={item2.icon} />
                              <span>{item2.name}</span>
                            </span>
                          }
                        >
                          {item2.routers.map((item3: any, index3: number) => (
                            <Menu.Item key={item2.id}>
                              <Link to={item3.path}>{item3.name}</Link>
                            </Menu.Item>
                          ))}
                        </SubMenu>
                      ) : (
                        <Menu.Item key={item2.id} className={`${selecList.indexOf(String(item.id)) != -1 ? 'select_background_main' : ''}`}>
                          <Link to={item2.path}>{item2.name}</Link>
                        </Menu.Item>
                      )
                    )}
                  </SubMenu>
                ) : (
                  <Menu.Item key={item1.path}
                  >
                    <Link to={item1.path}>
                      {
                        <Icon
                          type={item1.icon}
                          component={() => (
                            <img
                              style={{width: '20px', height: '20px', marginBottom: 8}}
                              src={srcListPathSelect[srcListSelect.indexOf(item1.icon)]}
                              alt={srcListPathSelect[srcListSelect.indexOf(item1.icon)]}
                            />
                          )}
                        />
                      }
                          {item1.name}
                    </Link>
                  </Menu.Item>
                )
              )}
            </SubMenu>
          ) : (
            <Menu.Item key={item.id} onClick={(e) => {setopenKeys([]);setSelecList([]);
              setFirstSelecList(e.key)}} className={`${firstSelecList.indexOf(String(item.id)) != -1 ? 'first_select_background' : ''} level1`}>
              <Link to={item.path}>
                <Icon
                  type={item.icon}
                  component={() => (
                    <img
                      style={{width: '20px', height: '20px', marginBottom: 5}}
                      src={firstSelecList.indexOf(item.id) == -1 ? srcListPath[srcList.indexOf(item.icon)] : srcListPathSelect[srcList.indexOf(item.icon)]}
                      alt="icon"
                    />
                  )}
                />
                <span>{item.name}</span>
              </Link>
            </Menu.Item>
          )
        )}
      </Menu>
  </Sider>
}

export default SiderBar