import {observer} from "mobx-react";
import React from "react";
import { Table, Modal, Radio, Avatar, message,} from "antd";
import { postRequest, getRequest } from '../../api/http';
import { apiUrl } from '../../api/api_url';
import { getIsPermisson, messageCustom } from "../../utils/permisstionUtil";

const key = 'updatable';

@observer
class ShopList extends React.Component<any> {
    shopId = -1;
    state = {
        visible: false,
        chooseIndex: -1,
        productList: [],
        mpList: [],
        current: 1,
        page_size: 10,
        total: 0
    }

    columns = [
        {
            title: '序号',
            dataIndex: 'index',
            key: 'index',
            className: 'list_center',
            render: (text: string, record: any, index: number) => {
                return <div>{index+1}</div>
            }
        },
        {
            title: '门店名称',
            dataIndex: 'name',
            key: 'name',
            className: 'list_center',
        },
        {
            title: '地址',
            dataIndex: 'address',
            key: 'address',
            className: 'list_center',
        },
        {
            title: '门店状态',
            dataIndex: 'status',
            key: 'status',
            className: 'list_center',
            render: (text: string, record: any,) => {
                return <div>{record.status == 1 ? '启用中' : '停用中'}</div>
            }
        },
        {
            title: '操作时间',
            dataIndex: 'operation_time',
            key: 'operation_time',
            className: 'list_center',
        },
        {
            title: '操作人',
            dataIndex: 'nickname',
            key: 'nickname',
            className: 'list_center',
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            className: 'list_center',
            render: (text:any,record:any,) => (
                <div className="btn_wrap">
                    <div className="list_btn" onClick={() => this.statusChange(record)}>{record.status == 1 ? '停用': '启用'}</div>
                </div>
            ),
        },
    ];

    componentDidMount(){
        this.onInitList();
        this.onInitMpList();
    }

    // 初始化列表
    onInitList = async (name?:string) => {
        let {current, page_size} = this.state;
        const params:any = {
            current,
            page_size
        };
        if(name){
            params.name = name;
        }
        const res:any = await getRequest(apiUrl.shopList,params);
        if(res.code === 1){
            this.setState({
                productList: res.data.list,
                total: res.data.total_count
            })
        }
    }

    onInitMpList = async () => {
        const res:any = await getRequest(apiUrl.authAccountList,{mp_type: 2});
        if(res.code === 1){
            this.setState({
                mpList: res.data.list,
            })
        }
    }

    searchShop = (e:any) => {
        this.setState({
            current: 1
        }, () => {
            this.onInitList(e)
        })
    }

    isbindWechat = (record: any) => {
        if(record.mp_name !== null){
            this.unbindWechat(record);
        }else{
            this.setState({
                visible: true
            })
            this.shopId = record.id;
        }
    }

    // 解绑小程序
    unbindWechat = async (record: any) => {
        const res:any = await postRequest(apiUrl.unbindXcx,{id: record.id});
        if(res.code === 1){
            message.success({ content: '解绑成功!', key, duration: 2 });
            this.onInitList();
        }else{
            message.error({ content: '解绑失败!', key, duration: 2 });
        }
    }

    handleCancel = () => {
        this.setState({visible: false})
    }

    handleChooseMp = (e:any) => {
        this.setState({chooseIndex: e.target.value})
    }

    handleOk = async () => {
        let mpList = this.state.mpList;
        const res:any = await postRequest(apiUrl.bindXcx,{id: this.shopId, xcx_id: mpList[this.state.chooseIndex]['id']});
        if(res.code === 1){
            this.setState({
                visible: false
            })
            this.onInitList();
            message.success({ content: '绑定成功!', key, duration: 2 });
        }else{
            message.error({ content: '绑定失败!', key, duration: 2 });
        }
    }

    // 状态改变
    statusChange = async (record: any) => {
        if(!getIsPermisson('2_7_1_1')){
            messageCustom();
            return;
          }
        const res:any = await postRequest(apiUrl.shopStatus, {status: record.status == 1 ? 0 : 1, shop_id: record.id})
        if(res.code == 1){
            message.success('修改成功')
            this.onInitList()
        }
    }


    render(){
        let {visible, mpList, chooseIndex, total, page_size} = this.state;
        return <div className='l_content'>
            {/* <Row className='mb40' type='flex' >
                <Col className='mr30'>门店名称：<Search placeholder='请输入门店名称' style={{ width: 234 }} onSearch={this.searchShop} /> </Col>
                <Col className='mr30'>
                    <Link to='/sidebar/shopAdd'>
                        <Button type='primary'>添加门店</Button>
                    </Link>
                </Col>
            </Row> */}
            <div>
                <Table dataSource={this.state.productList} size="middle" columns={this.columns} rowKey={"id"} locale={{emptyText: '暂无数据'}}
                pagination={{
                    total: total,
                    pageSize: page_size,
                    showQuickJumper: true,
                    onChange: (page1:number) =>{
                        this.setState({
                            current: page1
                        }, () => {
                            this.onInitList()
                        })
                    },
                    showTotal: (total: number) => `共${total}条`,
                    showSizeChanger: true,
                    onShowSizeChange: (current:number, size: number) => {this.setState({
                        page_size: size
                    }, () => {
                        this.onInitList()
                    })},
                }} />
            </div>

            <Modal
                visible={visible}
                title={null}
                onCancel={this.handleCancel}
                onOk={this.handleOk}
            >
                <div className="model_head">
                    <div className="head_title">选择小程序</div>
                </div>
                <div className="account_table">
                    {
                        mpList.length > 0?
                        <Radio.Group value={chooseIndex} onChange={this.handleChooseMp} style={{width: '100%'}}>
                            {
                                mpList.map((item:any,index) =>
                                    <div className='table_item' key={index}>
                                        <div className='item_right'>
                                            <Avatar size={46} src={item.head_img} />
                                            <div className="account_nickName">{item.nick_name}</div>
                                        </div>
                                        <div className='item_left'>
                                            <Radio value={index} />
                                        </div>
                                    </div>
                                )
                            }
                        </Radio.Group>
                        : null
                    }

                </div>
            </Modal>

        </div>;
    }
}
export default ShopList