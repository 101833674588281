import React, { useState, useEffect } from 'react'
import { Row, Button, Form, Col, Select, DatePicker, AutoComplete } from 'antd';
import DataSummaryItem from './components/data_summary_item';
import { FormComponentProps } from 'antd/lib/form';
import OrderTable from './components/order_table';
import { getRequest } from '../../../../api/http';
import { apiUrl } from '../../../../api/api_url';
import { searchUserList } from '../../../../utils/utils';

const { Option } = Select;
const { RangePicker } = DatePicker;

interface IOrderList extends FormComponentProps {}

const OrderList = (props: IOrderList) => {

  const { getFieldDecorator} = props.form;

  const [tableList, settableList] = useState<{
    items: Array<any>;
    total: number;
  }>({ items: [], total: 0 });
  
  const [overView, setoverView] = useState({
    count: 0,
    amount: 0,
    back: 0,
    profit: 0
  });

  let [searchCondition, setsearchCondition] = useState({
    status: 0,
    username: '',
    parent_name: '',
    indirect_parent_name: '',
    paystatus: 0,
    pay_start_at: '',
    pay_end_at: '',
  });

  const [searchUsersTipsList, setsearchUsersTipsList] = useState<Array<string>>([]);

  const status = [{
    value: 0,
    text: "全部"
  },{
    value: 5,
    text: "预热中"
  },{
    value: 10,
    text: "进行中"
  },{
    value: 15,
    text: "暂停"
  },{
    value: 20,
    text: "结束"
  }];
  

  useEffect(() => {
    getData();
  }, []);
  
  // 获取默认数据
  const getData = async (current = 1) => {
    const res: any = await getRequest(apiUrl.fetchShareOrder, {
      current: current,
      pageSize: 10
    });
    if (res && res.data) {
      if (current < 2) {
        settableList({ items: res.data.items, total: res.data.total });
      } else {
        settableList({
          items: [...res.data.items],
          total: res.data.total
        });
      }
      setoverView({ ...res.data.overview });
    }
  };


  const onFormChange = (e: any, type: string) => {
    if(type === 'paytime') {
      searchCondition['pay_start_at'] = e[0].format('YYYY-MM-DD HH:mm:ss')
      searchCondition['pay_end_at'] = e[1].format('YYYY-MM-DD HH:mm:ss')
    } else {
      searchCondition = {...searchCondition, [type]: e}
    }
    setsearchCondition(searchCondition);
  };

  const getUserList = async (name: string) => {
    if(!name) return;
    const data = await searchUserList(name);
    if(data.length > 0) {
      setsearchUsersTipsList(data);
    }
  }

  return (
    <div>
      <Row>
        <DataSummaryItem
          tips="当前活动总收款笔数"
          mainTitle={`${overView.count}笔`}
          subTitle="收款笔数"
        />
        <DataSummaryItem
          tips="当前活动总收款金额"
          mainTitle={`${overView.amount}元`}
          subTitle="收款总额"
        />
        <DataSummaryItem
          tips="当前活动一二级返现总金额"
          mainTitle={`${overView.back}元`}
          subTitle="返现金额"
        />
        <DataSummaryItem
          tips="当前活动扣除返现之后的实收收益"
          mainTitle={`${overView.profit}元`}
          subTitle="实际收益"
        />
      </Row>

      <Button type="primary" style={{ marginBottom: 30 }}>
        导出
      </Button>
      
      <Form>
        <Row type="flex">
          <Form.Item style={{ minWidth: 250 }}>
            <Row type="flex">
              <Col span={8}>活动状态 ：</Col>
              <Col span={12}>
                {getFieldDecorator("status", {
                  initialValue: status[0].text,
                  rules: [{ required: false }]
                })(
                  <Select onChange={(e: any) => onFormChange(e, "status")}>
                    {status.map(item => {
                      return (
                        <Option key={item.text} value={item.value}>
                          {item.text}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Col>
            </Row>
          </Form.Item>
          <Form.Item style={{ minWidth: 350 }}>
            <Row type="flex">
              <Col span={6}>用户昵称 ：</Col>
              <Col span={16}>
                {getFieldDecorator("username", {
                  initialValue: "",
                  rules: [{ required: false }]
                })(<AutoComplete 
                    placeholder="请输入用户昵称" 
                    dataSource={searchUsersTipsList}
                    onChange={(e) => getUserList(e.toString())}
                    onSelect={(e) => onFormChange(e, 'username')} 
                  />)}
              </Col>
            </Row>
          </Form.Item>
          <Form.Item style={{ minWidth: 350 }}>
            <Row type="flex">
              <Col span={6}>直接上级 ：</Col>
              <Col span={16}>
                {getFieldDecorator("parent_name", {
                  initialValue: "",
                  rules: [{ required: false }]
                })(<AutoComplete 
                    placeholder="请输入直接上级昵称" 
                    dataSource={searchUsersTipsList}
                    onChange={(e) => getUserList(e.toString())}
                    onSelect={(e) => onFormChange(e, 'parent_name')} 
                  />)}
              </Col>
            </Row>
          </Form.Item>
          <Form.Item style={{ minWidth: 350 }}>
            <Row type="flex">
              <Col span={6}>间距上级 ：</Col>
              <Col span={16}>
                {getFieldDecorator("indirect_parent_name", {
                  initialValue: "",
                  rules: [{ required: false }]
                })(<AutoComplete 
                    placeholder="请输入间距上级昵称" 
                    dataSource={searchUsersTipsList}
                    onChange={(e) => getUserList(e.toString())}
                    onSelect={(e) => onFormChange(e, 'indirect_parent_name')} 
                  />)}
              </Col>
            </Row>
          </Form.Item>
          <Form.Item style={{ minWidth: 350 }}>
            <Row type="flex">
              <Col span={6}>支付状态 ：</Col>
              <Col span={16}>
                {getFieldDecorator("paystatus", {
                  initialValue: "全部",
                  rules: [{ required: false }]
                })(
                  <Select onChange={(e: any) => onFormChange(e, "paystatus")}>
                    <Option key={0} value={`全部`}>全部</Option>
                    <Option key={1} value={`待支付`}>待支付</Option>
                    <Option key={2} value={`已支付`}>已支付</Option>
                  </Select>
                )}
              </Col>
            </Row>
          </Form.Item>
          <Form.Item style={{ minWidth: 350 }}>
            <Row type="flex">
              <Col span={6}>支付状态 ：</Col>
              <Col span={16}>
                {getFieldDecorator("paytime", {
                  rules: [{ required: false }]
                })(
                  <RangePicker 
                    format={`YYYY-MM-DD HH:mm:ss`} 
                    showTime={{ format: 'HH:mm' }}
                    onChange={(e) => onFormChange(e, 'paytime')}
                    placeholder={['开始时间', '结束时间']} />
                )}
              </Col>
            </Row>
          </Form.Item>
        </Row>
      </Form>

      <OrderTable tableData={{total: tableList.total, items: tableList.items}} pageChange={(page) => getData(page)} />
    </div>
  );
}

export default Form.create({})(OrderList);