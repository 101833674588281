import React, {useEffect, useState} from "react";
import {Form,Input,Select,Row,Col,Checkbox,Button,Card,Cascader,Upload,Icon,Radio,message,Modal, AutoComplete , notification} from 'antd';
import cityData from '../../../utils/cityData';
import { apiUrl } from '../../../api/api_url';
import { postRequest, getRequest, apiAxios } from '../../../api/http';
import UploadImg from './uploadImg/index'
import copy from 'copy-to-clipboard';
import './store_auth.scss'

const { Option } = Select

const StoreAuth = (props:any) =>{

    const [ formVal, setFormVal ] = useState<any>({
        account_type:1,
        manage_type:1,
        base_info:{}
        // catering_type:1,
    }) //表单值对象
    const [ codeLoading, setCodeLoading ] = useState(false) //验证码loading
    const [ second, setSecond ] = useState(0)   //倒计时
    const [ title, setTitle ] = useState('门店认证')
    const [ errorObj, setErrorObj ] = useState<any>({}) //审核失败的错误信息
    const [ bankList, setBankList ] = useState<any>([]) //支行列表

    // const [ businessLicense, setBusinessLicense ] = useState<any>({}) //营业执照信息

    //基础信息字段验证
    const baseValidator:any = {
        is_wifi:{rule:'require',msg:'请选择门店wifi是否覆盖'},      //门店wifi是否覆盖所有区域
        is_system:{rule:'require',msg:'目前使用哪家系统必填'},     //是否使用其它点餐系统
        is_print:{rule:'require',msg:'请选择是否使用打印机'},      //是否使用打印机
        cashier_desk_computer:{rule:'require',msg:'请选择收银台有无电脑'}, //收银台有无电脑
        is_tencent_isux: {rule:'require',msg:'请选择是否使用公众号'},   //是否使用公众号
        is_wechat:{rule:'require',msg:'请选择是否使用小程序'},    //是否使用小程序
    }

    //字段验证
    const validator:any = {
        shop_exterior_pic: {rule:'require',msg:'请上传门店门头照片'},
        shop_inner_pic: {rule:'require',msg:'请上传店内环境照片'},
        cashier_desk_pic: {rule:'require',msg:'请上传收银台照片'},
        shop_name: {rule:'require',msg:'门店名称必填'},
        province: {rule:'require',msg:'所在省市必填'},
        city: {rule:'require',msg:'所在省市必填'},
        district: {rule:'require',msg:'所在省市必填'},
        address: {rule:'require',msg:'门店地址必填'},
        manage_type: {rule:'require',msg:'经营类型必填'},
        catering_type: {rule:'require',msg:'餐饮类型必填'},
        service_phone: {rule:'tel',msg:'请填写正确的门店客服联系方式必填'},
        business_license: {rule:'require',msg:'请上传营业执照照片'},
        business_license_num: {rule:'require',msg:'营业执照号必填'},
        business_license_name: {rule:'require',msg:'营业执照名称必填'},
        contact_name: {rule:'require',msg:'法人姓名必填'},
        contact_phone: {rule:'tel',msg:'请填写正确的法人手机号'},
        // code: {rule:'require',msg:'验证码必填'},
        id_card_front: {rule:'require',msg:'请上传法人身份证正面照片'},
        id_card_reverse: {rule:'require',msg:'请上传法人身份证反面照片'},
        // id_card_info: {rule:'require',msg:'没有获取到法人身份证反面的信息,请重新上传'},
        contact_id_card: {rule:'require',msg:'法人身份证号码必填'},
        open_acount_name_bak: {rule:'require',msg:'结算人姓名必填',account_type:0},
        hand_id_card: {rule:'require',msg:'请上传手持身份证',account_type:0},
        authorization_paper_bak: {rule:'require',msg:'请上传结算授权书',account_type:0},
        bank_front: {rule:'require',msg:'请上传法人银行卡正面照片'},
        contact_bank_card: {rule:'require',msg:'没有获取到对应的银行卡号,请重新上传银行卡照片'},
        contact_bank_name: {rule:'require',msg:'没有获取到对应的银行卡名称,请重新上传银行卡照片'},
        contact_bank_branch_name: {rule:'require',msg:'支行名称必填'},
        id_card_front_bak: {rule:'require',msg:'请上传结算人身份证正面照片',account_type:0},
        id_card_reverse_bak: {rule:'require',msg:'请上传结算人身份证反面照片',account_type:0},
        id_card_number_bak: {rule:'require',msg:'结算人身份证号码必填',account_type:0},
        // food_license: {rule:'require',msg:'食品经营许可证必填'},
    }

    useEffect(()=>{
        const store_auth_shop_id:any = localStorage.getItem('store_auth_shop_id')
        console.log(store_auth_shop_id)
        //初始化表单信息
        init(store_auth_shop_id)
    },[])

    const init = (shop_id:any) => {
        return false
        console.log('shop_id:',shop_id)
        if(shop_id){
            getRequest(apiUrl.shopApplyInfo,{shop_id:shop_id}).then((res:any)=>{
                if(res.code==1){
                    if(res.data.info&&res.data.info.food_license){
                        res.data.info.contact_bank_branch_name = JSON.parse(res.data.info.bank_card_info).sub_bank_name
                    }
                    //基础信息初始化
                    if(res.data.info&&res.data.info.base_info){
                        res.data.info.base_info = JSON.parse(res.data.info.base_info)?JSON.parse(res.data.info.base_info):{}
                    }
                    setFormVal(res.data.info?res.data.info:{manage_type:1,catering_type:1,})
                    //审核失败时的错误信息
                    if(res.data.info&&res.data.info.error_value){
                        // setErrorList(JSON.parse(res.data.info.error_value))
                        let obj:any = {}
                        JSON.parse(res.data.info.error_value).map((item:any,index:number)=>{
                            Object.keys(item).map((subItem:any)=>{
                                obj[subItem] = item[subItem]
                            })
                        })
                        setErrorObj(obj)
                    }
                    if(res.data.info){
                        setTitle(title+'('+(res.data.info.status==0?'待审核':res.data.info.status==5?'审核失败':'审核成功')+')')
                    }
                    if(res.data.info&&res.data.info.status==0){
                        Modal.info({
                            okText: '确定',
                            icon: '',
                            title: '提示',
                            content: '您的申请信息已提交成功，请耐心等待审核'
                        })
                    }
                }else{
                    message.error(res.message||'获取信息失败')
                }
            }).catch(err=>{
                message.error(err||'获取信息失败')
            })
        }else{
            let data:any =  localStorage.getItem('store_auth_formVal')
            console.log('data:',data)
            if(data){
                setFormVal({
                    ...JSON.parse(data)
                })
            }
        }

    }

    //监听字段变化
    const changeFieldVal = async( e:any, field:string ) => {
        console.log(field)
        console.log(typeof(e))
        let fieldObj:any = { [field]: field==='cityArr'||field==='account_type'?e:e.trim() }
        if(field==='cityArr'){
            fieldObj = {
                province:e[0]?e[0].name:'',
                city:e[1]?e[1].name:'',
                district:e[2]?e[2].name:'',
                province_code:e[0]?e[0].code:'',
                city_code:e[1]?e[1].code:'',
                district_code:e[2]?e[2].code:'',
            }
        }else if((field==='service_phone'||field==='contact_phone')&&e){
            var rule:any = /^[0-9]*$/
            console.log(rule.test(e))
            if(!rule.test(e)) fieldObj = { [field]: formVal[field] }
        }

        localStorage.setItem('store_auth_formVal',JSON.stringify({
            ...formVal,
            ...fieldObj
        }))

        setFormVal({
            ...formVal,
            ...fieldObj
        })
    }

    //选择支行
    const onSelectBranch = (e:any, option:any, field:any ) => {
        let fieldObj:any = { [field]: e }
        fieldObj.bank_card_info = JSON.stringify({
            sub_bank_code:e,
            sub_bank_name:option.props.children
        })
        localStorage.setItem('store_auth_formVal',JSON.stringify({
            ...formVal,
            ...fieldObj
        }))
        setFormVal({
            ...formVal,
            ...fieldObj
        })
    }

    let timer:any
    //获取支行列表
    const getBankList = (e:any) => {
        if(!e){
            setBankList([])
            return false
        }
        timer && clearTimeout(timer)
        //500毫秒后请求
        timer = setTimeout(async()=>{
            var res:any = await getRequest(apiUrl.shopApplySearchUnionpay, { 'name': e })
            if(res&&res.code==1){
                setBankList(res.data.data)
            }
        },500)
    }

    //发送验证码
    const sendVerifyCode = async() => {
        if(!(/^1[3456789]\d{9}$/.test(formVal.contact_phone))){
            message.error('请填写正确的手机号码')
            return
        }
        if (second !== 0) {
          return
        }
        setCodeLoading(true)
        try{
            var res:any = await getRequest(apiUrl.sendMobileCode, { 'mobile': formVal.contact_phone })
            if(res.code == 1) {
                setCodeLoading(false)
                countdown(60)
            }else{
                setCodeLoading(false)
                message.error(res.message||'发送失败')
            }
        }catch(err){
            setCodeLoading(false)
            console.log(err)
            // message.error('err:'+err)
        }
    }

    //倒计时
    const countdown = (max:number)=>{
        let _second = max || second
        if (_second === 0) {
            setSecond(0)
            return
        }
        setSecond(_second - 1)
        setTimeout(() => countdown(_second - 1), 1000)
    }

    //提交
    const onsubmit = async() => {
        try {
            Object.keys(baseValidator).map((item)=>{
                if(baseValidator[item]&&baseValidator[item].rule=='require'&&(formVal.base_info[item]===undefined||formVal.base_info[item]===null)){
                    throw baseValidator[item].msg;
                }
                if(item=='is_print'&&formVal.base_info['is_print']&&!formVal.base_info['print_version']){
                    throw '请输入打印机型号'
                }
            })

            Object.keys(validator).map((item)=>{
                if(validator[item]&&validator[item].rule=='require'&&(!formVal[item])||formVal[item]=='无'){
                    if(validator[item].account_type===0){
                        if(formVal.account_type===0){
                            throw validator[item].msg;
                        }
                    }else{
                        throw validator[item].msg;
                    }
                }
                if(validator[item]&&validator[item].rule=='tel'){
                    console.log(item)
                    console.log(formVal[item])
                    if(!formVal[item]){
                            throw item=='service_phone'?'门店客服联系方式必填':'手机号必填';
                    }else{
                        if(!(/^1[3456789]\d{9}$/.test(formVal[item]))){
                            throw validator[item].msg;
                        }
                    }
                }
                //判断身份证反面是否正常
                // if(validator[item]&&item=='id_card_info'&&(JSON.parse(formVal[item]).idCardStart==''||JSON.parse(formVal[item]).idCardStart=='无')){
                //     throw validator[item].msg;
                // }

            })
        } catch (e) {
            message.error(e||'提交失败')
            return false
        }
        formVal.base_info = JSON.stringify(formVal.base_info)
        try{
            var res:any = await postRequest(apiUrl.shopApply, formVal)
            if(res.code == 1){
                Modal.success({
                    content: '提交成功,请等待审核',
                })
    
                localStorage.setItem('store_auth_shop_id', res.data.shop_id)
                // message.success('提交成功')
                // window.location.reload()
            }else{
                formVal.base_info = JSON.parse(formVal.base_info)
                message.error(res.message||'提交失败')
            }
        }catch(err){
            formVal.base_info = JSON.parse(formVal.base_info)
        }

    }

    // 行元素
    const getRow = (child:any) =><Row gutter={16} align="middle" justify="start" className="gutter-row" type="flex">{child}</Row>

    // 列元素
    const getCol = (span:number,child: any) => getRow(<Col className="gutter-box" span={span}>{child}</Col>)

    //基础信息字段
    const basicsField:any = [
        // {label:'结算方式',field:'account_type',type:'radio'},

        {label:'法人姓名',field:'contact_name',placeholder:'请填写营业执照的经营者/法定代表人姓名',type:'text', disabled:false, account_type:2},
        {label:'法人手机号(与银行卡绑定手机号)',field:'contact_phone',placeholder:'请填写与银行卡绑定手机号',type:'text', disabled:false, account_type:2},
        {label:'上传法人身份证正面照片',field:'id_card_front',type:'img', account_type:2},
        {label:'上传法人身份证反面照片',field:'id_card_reverse',type:'img', account_type:2},
        {label:'法人身份证号码',field:'contact_id_card',placeholder:'身份证号码自动识别',type:'text', disabled:true, account_type:2},

        //非法人
        {label:'结算人姓名',field:'open_acount_name_bak',placeholder:'请填写结算人姓名',type:'text', disabled:false, account_type:0},
        {label:'法人手持身份证照片',field:'hand_id_card',type:'img', account_type:0},
        {label:'结算授权书',field:'authorization_paper_bak',type:'img', account_type:0},
        {label:'结算银行卡正面照片',field:'bank_front',type:'img', account_type:0}, //原字段
        {label:'结算银行卡号(用于门店营业结算)',field:'contact_bank_card',placeholder:'结算银行卡号号码自动识别',type:'text', disabled:true, account_type:0}, //原字段
        {label:'结算银行卡名称',field:'contact_bank_name',placeholder:'结算银行卡名称自动识别',type:'text', disabled:true, account_type:0}, //原字段
        {label:'结算支行名称',field:'contact_bank_branch_name',placeholder:'请填写结算银行卡支行名称',type:'text', disabled:false, account_type:0}, //原字段
        {label:'上传结算人身份证正面照片',field:'id_card_front_bak',type:'img', account_type:0},
        {label:'上传结算人身份证反面照片',field:'id_card_reverse_bak',type:'img', account_type:0},
        {label:'结算人身份证号码',field:'id_card_number_bak',placeholder:'结算人身份证号码自动识别',type:'text', disabled:true, account_type:0},

        //法人
        {label:'法人银行卡正面照片',field:'bank_front',type:'img',account_type:1},
        {label:'法人银行卡号(用于门店营业结算)',field:'contact_bank_card',placeholder:'银行卡号码自动识别',type:'text', disabled:true, account_type:1},
        {label:'银行卡名称',field:'contact_bank_name',placeholder:'银行卡名称自动识别',type:'text', disabled:true, account_type:1},
        {label:'支行名称',field:'contact_bank_branch_name',placeholder:'请填写银行卡支行名称',type:'text', disabled:false, account_type:1},
    ]



    //图片列表
    const imgListField:any = [
        // {label:'上传身份证正面照片',field:'id_card_front'},
        // {label:'上传身份证反面照片',field:'id_card_reverse'},
        // {label:'银行卡正面照片',field:'bank_front'},
        {label:'门店门头照片',field:'shop_exterior_pic'},
        {label:'店内环境照片',field:'shop_inner_pic'},
        {label:'收银台照片',field:'cashier_desk_pic'},
    ]

    //级联值重置格式
    const displayRender = (labels:any, selectedOptions:any) =>
        labels.map((label:any, i:any) => {
            const option = selectedOptions[i];
            if (i === labels.length - 1) {
                return (
                <span key={option.code}>
                    {label}
                </span>
                );
            }
            return <span key={option.code}>{label}  </span>;
        });

    //获取营业执照信息（百度接口）
    const getBaiduAccessToken = async(e:any,imgUrl:string) => {
        const res:any = await postRequest(apiUrl.shopApplyBusinessLicense,{image:e});
        if(res.code==1&&res.data){
            if(JSON.parse(res.data.data).error_code){
                message.error('获取营业执照信息失败')
            }else{
                formVal.business_license_num = JSON.parse(res.data.data)['words_result']['社会信用代码']['words']
                formVal.business_license_name = JSON.parse(res.data.data)['words_result']['单位名称']['words']
                formVal.business_license = imgUrl
                formVal.business_license_info = JSON.stringify({
                    licenseAddress:JSON.parse(res.data.data)['words_result']['地址']['words'],
                    licenseStart:JSON.parse(res.data.data)['words_result']['成立日期']['words'].replace('年','-').replace('月','-').replace('日',''),
                    licenseEnd:JSON.parse(res.data.data)['words_result']['有效期']['words'].replace('年','-').replace('月','-').replace('日','')
                })
                localStorage.setItem('store_auth_formVal',JSON.stringify({
                    ...formVal,
                }))
                setFormVal({...formVal})
            }
        }else{
            message.error(res.message||'获取营业执照信息失败')
        }
    }

    //身份证正反面识别，银行卡号识别（百度接口）
    const uploadCallBack = async(field:string,e:any,imgUrl:string) => {
        switch(field){
            case 'id_card_front':
                //身份证正面识别回调
                const res:any = await postRequest(apiUrl.shopApplyIdcard,{image:e,id_card_side:'front'});
                console.log('res:',res)
                if(res.code==1&&res.data){
                    if(JSON.parse(res.data.data).error_code){
                        message.error('获取身份证反面信息失败')
                    }else{
                        formVal.contact_id_card = JSON.parse(res.data.data)['words_result']['公民身份号码']['words']
                        formVal.id_card_front = imgUrl
                        localStorage.setItem('store_auth_formVal',JSON.stringify({
                            ...formVal,
                        }))
                        setFormVal({...formVal})
                    }
                    formVal.contact_id_card = JSON.parse(res.data.data)['words_result']['公民身份号码']['words']
                    formVal.id_card_front = imgUrl
                    localStorage.setItem('store_auth_formVal',JSON.stringify({
                        ...formVal,
                    }))
                    setFormVal({...formVal})
                }else{
                    message.error(res.message||'获取身份证正面信息失败')
                }
                break
            case 'id_card_reverse':
                //身份证反面识别回调
                const result:any = await postRequest(apiUrl.shopApplyIdcard,{image:e,id_card_side:'back'});
                console.log('result:',result)
                if(result.code==1&&result.data){
                    if(JSON.parse(result.data.data).error_code){
                        formVal.id_card_info = JSON.stringify({
                            idCardStart:1,
                            idCardEnd:1,
                        })
                        formVal.id_card_reverse = imgUrl
                        localStorage.setItem('store_auth_formVal',JSON.stringify({
                            ...formVal,
                        }))
                        setFormVal({...formVal})
                        // message.error('获取身份证反面信息失败')
                    }else{
                        const idCardStart_str = JSON.parse(result.data.data)['words_result']['签发日期']['words']
                        const idCardEnd_str = JSON.parse(result.data.data)['words_result']['失效日期']['words']
                        formVal.id_card_info = JSON.stringify({
                            idCardStart:idCardStart_str.substr(0,4)+'-'+idCardStart_str.substr(4,2)+'-'+idCardStart_str.substr(6,2),
                            idCardEnd:idCardEnd_str.substr(0,4)+'-'+idCardEnd_str.substr(4,2)+'-'+idCardEnd_str.substr(6,2),
                        })
                        formVal.id_card_reverse = imgUrl
                        localStorage.setItem('store_auth_formVal',JSON.stringify({
                            ...formVal,
                        }))
                        setFormVal({...formVal})
                    }
                }else{
                    message.error(res.message||'获取身份证反面信息失败')
                }
                break
            case 'id_card_front_bak':
                //身份证正面识别回调
                const resBak:any = await postRequest(apiUrl.shopApplyIdcard,{image:e,id_card_side:'front'});
                console.log('res:',resBak)
                if(resBak.code==1&&resBak.data){
                    if(JSON.parse(resBak.data.data).error_code){
                        message.error('获取身份证反面信息失败')
                    }else{
                        formVal.id_card_number_bak = JSON.parse(resBak.data.data)['words_result']['公民身份号码']['words']
                        formVal.id_card_front_bak = imgUrl
                        localStorage.setItem('store_auth_formVal',JSON.stringify({
                            ...formVal,
                        }))
                        setFormVal({...formVal})
                    }
                    formVal.id_card_number_bak = JSON.parse(resBak.data.data)['words_result']['公民身份号码']['words']
                    formVal.id_card_front_bak = imgUrl
                    localStorage.setItem('store_auth_formVal',JSON.stringify({
                        ...formVal,
                    }))
                    setFormVal({...formVal})
                }else{
                    message.error(resBak.message||'获取身份证正面信息失败')
                }
                break
            case 'bank_front':
                //银行卡正面识别回调
                const bankRes:any = await postRequest(apiUrl.shopApplyBankcard,{image:e});
                console.log('bankRes:',bankRes)
                if(bankRes.code==1&&bankRes.data){
                    if(JSON.parse(bankRes.data.data).error_code){
                        message.error('获取银行卡信息失败')
                    }else{
                        formVal.contact_bank_card = JSON.parse(bankRes.data.data)['result']['bank_card_number'].replace(/\s*/g,"")
                        formVal.contact_bank_name = JSON.parse(bankRes.data.data)['result']['bank_name']
                        formVal.bank_front = imgUrl
                        localStorage.setItem('store_auth_formVal',JSON.stringify({
                            ...formVal,
                        }))
                        setFormVal({...formVal})
                    }
                }else{
                    message.error(res.message||'获取银行卡信息失败')
                }
                break
        }
    }

    //基础信息字段修改
    const changeBaseInfo = ( e:any, field:string )=>{
        console.log(e)
        let base_info =  {
            ...formVal.base_info,
            [field]:e,
        }
        localStorage.setItem('store_auth_formVal',JSON.stringify({
            ...formVal,
            base_info:base_info
        }))
        setFormVal({
            ...formVal,
            base_info:base_info
        })
    }

    return(
        <div className="h5_store_auth">
            {getCol(24,<div className="title_text">{title}</div>)}
            {getCol(24,<div className="fz16 color333 mb15">门店基础信息</div>)}
            {getRow(
                <div className="row_view mbt16">
                    <div className="sub_title"><span className="color_red mr5">*</span>门店wifi有没有良好并覆盖所有位置 :</div>
                    <Radio.Group value={formVal.base_info.is_wifi} onChange={(e)=>{changeBaseInfo(e.target.value,'is_wifi')}}>
                        <Radio value={true}>有</Radio>
                        <Radio value={false}>没有</Radio>
                    </Radio.Group>
                </div>
            )}
            {getRow(
                <div className="row_view mbt16">
                    <div className="sub_title"><span className="color_red mr5">*</span>目前使用哪家点餐收银系统 :</div>
                    <Input className="sub_unInput" value={formVal.base_info.is_system?formVal.base_info.is_system:''} placeholder="如没使用，请直接填写无" onChange={(e)=>{changeBaseInfo(e.currentTarget.value,'is_system')}}/>
                </div>
            )}
            {getRow(
                <div className="row_view mbt16">
                    <div className="sub_title"><span className="color_red mr5">*</span>有没有使用打印机 :</div>
                    <Radio.Group value={formVal.base_info.is_print} onChange={(e)=>{changeBaseInfo(e.target.value,'is_print')}}>
                        <Radio value={true}>有</Radio>
                        {
                            formVal.base_info.is_print&&<Input className='mr10' style={{width:'150px'}} value={formVal.base_info.print_version?formVal.base_info.print_version:''} placeholder="请输入打印机型号" onChange={(e)=>{changeBaseInfo(e.currentTarget.value,'print_version')}}/>
                        }
                        <Radio value={false}>没有</Radio>
                    </Radio.Group>
                </div>
            )}
            {getRow(
                <div className="row_view mbt16">
                    <div className="sub_title"><span className="color_red mr5">*</span>收银台有没有电脑 :</div>
                    <Radio.Group value={formVal.base_info.cashier_desk_computer} onChange={(e)=>{changeBaseInfo(e.target.value,'cashier_desk_computer')}}>
                        <Radio value={true}>有</Radio>
                        <Radio value={false}>没有</Radio>
                    </Radio.Group>
                </div>
            )}
            {getRow(
                <div className="row_view mbt16">
                    <div className="sub_title"><span className="color_red mr5">*</span>门店有没有公众号 :</div>
                    <Radio.Group value={formVal.base_info.is_tencent_isux} onChange={(e)=>{changeBaseInfo(e.target.value,'is_tencent_isux')}}>
                        <Radio value={true}>有</Radio>
                        <Radio value={false}>没有</Radio>
                    </Radio.Group>
                </div>
            )}
            {getRow(
                <div className="row_view mbt16">
                    <div className="sub_title"><span className="color_red mr5">*</span>门店有没有点餐小程序 :</div>
                    <Radio.Group value={formVal.base_info.is_wechat} onChange={(e)=>{changeBaseInfo(e.target.value,'is_wechat')}}>
                        <Radio value={true}>有</Radio>
                        <Radio value={false}>没有</Radio>
                    </Radio.Group>
                </div>
            )}
            {
                imgListField.map((item:any,index:number)=>{
                    return <div key={index}>
                        {getRow(
                            <>
                                <UploadImg onChange={(e:any)=>{changeFieldVal(e,item.field)}} imgUrl={formVal[item.field]} label={item.label}/>
                                {
                                    errorObj[item.field]&&<div className="error">*{errorObj[item.field]}</div>
                                }
                            </>
                        )}
                    </div>
                })
            }

            {getCol(24,<div className="fz16 color333 mb15">门店资质信息</div>)}
            {getRow(
                <>
                    <div className="row_view mbt16">
                        <div className="sub_title"><span className="color_red mr5">*</span>门店名称 :</div>
                        <Input className="sub_unInput" value={formVal.shop_name} placeholder="请输入门店名称" onChange={(e)=>{changeFieldVal(e.currentTarget.value,'shop_name')}}/>
                    </div>
                    {
                        errorObj['shop_name']&&<div className="error">*{errorObj['shop_name']}</div>
                    }
                </>
            )}
            {getRow(
                <>
                    <div className="row_view mbt16">
                        <div className="sub_title"><span className="color_red mr5">*</span>所在省市:</div>
                        {
                            formVal.province?<Cascader key={2} defaultValue={[formVal.province_code, formVal.city_code, formVal.district_code]} displayRender={displayRender} options={cityData} placeholder="请选择" style={{ width: '100%' }} onChange={(e,selectedOptions)=>{changeFieldVal(selectedOptions,'cityArr')}} fieldNames={{ label: 'name', value: 'code', children: 'children' }}/>:<Cascader key={1} displayRender={displayRender} options={cityData} placeholder="请选择" style={{ width: '100%' }} onChange={(e,selectedOptions)=>{changeFieldVal(selectedOptions,'cityArr')}} fieldNames={{ label: 'name', value: 'code', children: 'children' }}/>
                        }
                    </div>
                    {
                        errorObj['province']&&<div className="error">*{errorObj['province']}</div>
                    }
                </>
            )}
            {getRow(
                <>
                    <div className="row_view mbt16">
                        <div className="sub_title"><span className="color_red mr5">*</span>门店地址 :</div>
                        <Input className="sub_unInput" value={formVal.address} placeholder="填写店铺详细地址，具体区/县及街道门牌号或大厦楼层" maxLength={500} onChange={(e)=>{changeFieldVal(e.currentTarget.value,'address')}}/>
                    </div>
                    {
                        errorObj['address']&&<div className="error">*{errorObj['address']}</div>
                    }
                </>
            )}
            {getRow(
                <>
                    <div className="row_view mbt16">
                        <div className="sub_title"><span className="color_red mr5">*</span>经营类型 :</div>
                        <Select defaultValue="1" value={formVal.manage_type?formVal.manage_type+'':'1'} style={{ width: '100%' }} onChange={(e:any)=>{changeFieldVal(e,'manage_type')}}>
                            <Option value="1">小微商户</Option>
                            <Option value="2">个体工商户</Option>
                            <Option value="3">企业</Option>
                            <Option value="4">其他</Option>
                        </Select>
                    </div>
                    {
                        errorObj['manage_type']&&<div className="error">*{errorObj['manage_type']}</div>
                    }
                </>
            )}
            {getRow(
                <>
                    <div className="row_view mbt16">
                        <div className="sub_title"><span className="color_red mr5">*</span>餐饮类型 :</div>
                        <Input className="sub_unInput" value={formVal.catering_type} placeholder="请输入餐饮类型" onChange={(e)=>{changeFieldVal(e.currentTarget.value,'catering_type')}}/>
                        {/* <Select defaultValue="1" value={formVal.catering_type?formVal.catering_type+'':'1'} style={{ width: '100%' }} onChange={(e:any)=>{changeFieldVal(e,'catering_type')}}>
                            <Option value="1">自助餐</Option>
                            <Option value="2">川菜</Option>
                        </Select>                     */}
                    </div>
                    {
                        errorObj['catering_type']&&<div className="error">*{errorObj['catering_type']}</div>
                    }
                </>
            )}
            {getRow(
                <>
                    <div className="row_view mbt16">
                        <div className="sub_title"><span className="color_red mr5">*</span>门店客服联系方式 :</div>
                        <Input className="sub_unInput" maxLength={11} value={formVal.service_phone} placeholder="请填写真实有效的客服电话，将在交易记录中向买家展示" onChange={(e)=>{changeFieldVal(e.currentTarget.value,'service_phone')}}/>
                    </div>
                    {
                        errorObj['service_phone']&&<div className="error">*{errorObj['service_phone']}</div>
                    }
                </>
            )}
            {getRow(
                <>
                    <UploadImg onChange={(e:any)=>{changeFieldVal(e,'business_license')}} callBack={(e:any,imgUrl:string)=>{getBaiduAccessToken(e,imgUrl)}} imgUrl={formVal.business_license} label="营业执照"/>
                    {
                        errorObj['business_license']&&<div className="error">*{errorObj['business_license']}</div>
                    }
                </>
            )}
            {getRow(
                <>
                    <div className="row_view mbt16">
                        <div className="sub_title"><span className="color_red mr5">*</span>营业执照号 :</div>
                        <Input maxLength={18} disabled={false} className="sub_unInput" placeholder="营业执照号自动识别" value={formVal.business_license_num} onChange={(e)=>{changeFieldVal(e.currentTarget.value,'business_license_num')}}/>
                    </div>
                    {
                        errorObj['business_license_num']&&<div className="error">*{errorObj['business_license_num']}</div>
                    }
                </>
            )}
            {getRow(
                <>
                    <div className="row_view mbt16">
                        <div className="sub_title"><span className="color_red mr5">*</span>营业执照名称 :</div>
                        <Input maxLength={110} disabled={false} className="sub_unInput" placeholder="营业执照名称自动识别" value={formVal.business_license_name} onChange={(e)=>{changeFieldVal(e.currentTarget.value,'business_license_name')}}/>
                    </div>
                    {
                        errorObj['business_license_name']&&<div className="error">*{errorObj['business_license_name']}</div>
                    }
                </>
            )}
            {getRow(
                <>
                    <UploadImg onChange={(e:any)=>{changeFieldVal(e,'food_license')}} imgUrl={formVal.food_license} label="食品经营许可证(如暂无可后续补齐)" notRequire={true}/>
                    {
                        errorObj['food_license']&&<div className="error">*{errorObj['food_license']}</div>
                    }
                </>
            )}
            {getCol(24,<div className="fz16 color333 mb15 mt30">门店法人/财务信息</div>)}
            {getRow(
                <div className="row_view mbt16">
                    <div className="sub_title"><span className="color_red mr5">*</span>结算方式 :</div>
                    <Radio.Group value={formVal.account_type==1?1:0} onChange={(e)=>{changeFieldVal(parseInt(e.target.value),'account_type')}}>
                        <Radio value={1}>法人</Radio>
                        <Radio value={0}>非法人</Radio>
                    </Radio.Group>
                </div>
            )}
            {
                basicsField.map((item:any,index:number)=>{
                    return <div key={index}>
                        {getRow(
                            (item.account_type==2||item.account_type==formVal.account_type)&&(
                                <>
                                    {
                                        item.type=='img'?(
                                            <div>
                                                <UploadImg
                                                    onChange={(e:any)=>{changeFieldVal(e,item.field)}}
                                                    imgUrl={formVal[item.field]}
                                                    label={item.label}
                                                    callBack={(e:any,imgUrl:string)=>{uploadCallBack(item.field,e,imgUrl)}}
                                                    notRequire={item.field=='hand_id_card'?true:false}
                                                />
                                                {
                                                    item.field=='authorization_paper_bak'&&(
                                                        <a style={{paddingLeft:25}} target="非法人结算授权书" href="https://ydn-siyu.cdn.shuachi.com/%E9%9D%9E%E6%B3%95%E4%BA%BA%E7%BB%93%E7%AE%97%E6%8E%88%E6%9D%83%E4%B9%A6.pdf">下载结算授权书(下载后打印填写，拍照上传)</a>
                                                    )
                                                }
                                            </div>
                                        ):item.type=='radio'?(
                                            <Radio.Group>
                                                <Radio value={0}>法人</Radio>
                                                <Radio value={1}>非法人</Radio>
                                            </Radio.Group>
                                        ):(
                                            <div className="row_view mbt16">
                                                <div className="sub_title">
                                                    <span className="color_red mr5">*</span>
                                                    {item.label} :
                                                </div>
                                                {
                                                    item.field==='contact_phone'?(
                                                        <Input
                                                            className="sub_unInput"
                                                            // type="number"
                                                            placeholder={item.placeholder}
                                                            onChange={(e)=>{changeFieldVal(e.currentTarget.value,item.field)}}
                                                            maxLength={11}
                                                            value={formVal[item.field]}
                                                            // addonAfter={
                                                            //     <Button loading={codeLoading} type="primary" onClick={sendVerifyCode} disabled = {second > 0 ? true:false}>获取验证码{second || null}</Button>
                                                            // }
                                                        />
                                                    ):(
                                                        item.field==='contact_bank_branch_name'?(
                                                                formVal.contact_bank_branch_name?(
                                                                    <AutoComplete
                                                                        placeholder="请输入支行名称"
                                                                        // dataSource={bankList}
                                                                        defaultValue={formVal.bank_card_info?JSON.parse(formVal.bank_card_info)['sub_bank_name']:''}
                                                                        onChange={(e) => getBankList(e)}
                                                                        onSelect={(e, option) => onSelectBranch(e, option, item.field)}
                                                                        className="sub_unInput"
                                                                        key={1}
                                                                    >
                                                                        {
                                                                            bankList.map((subItem:any,index:number)=>{
                                                                                return <AutoComplete.Option key={subItem.sub_bank_code} value={subItem.sub_bank_code}>{subItem.sub_bank_name}</AutoComplete.Option>
                                                                            })
                                                                        }
                                                                    </AutoComplete>
                                                                ):(
                                                                    <AutoComplete
                                                                        placeholder="请输入支行名称"
                                                                        // dataSource={bankList}
                                                                        onChange={(e) => getBankList(e)}
                                                                        onSelect={(e, option) => onSelectBranch(e, option, item.field)}
                                                                        className="sub_unInput"
                                                                        key={2}
                                                                    >

                                                                        {
                                                                            bankList.map((subItem:any,index:number)=>{
                                                                                return <AutoComplete.Option key={subItem.sub_bank_code} value={subItem.sub_bank_code}>{subItem.sub_bank_name}</AutoComplete.Option>
                                                                            })
                                                                        }
                                                                    </AutoComplete>
                                                                )

                                                        ):(
                                                            <Input
                                                                disabled={false}
                                                                className="sub_unInput"
                                                                placeholder={item.placeholder}
                                                                onChange={(e)=>{changeFieldVal(e.currentTarget.value,item.field)}}
                                                                value={formVal[item.field]}
                                                            />
                                                        )
                                                    )
                                                }
                                            </div>
                                        )
                                    }
                                    {
                                        errorObj[item.field]&&<div className="error">*{errorObj[item.field]}</div>
                                    }
                                </>

                            )
                        )}
                    </div>
                })
            }

            {/* {getCol(24,<div className="fz16 color333 mb15 mt30">场景信息</div>)}      */}

            {/* {getCol(24,<div className="fz16 color333 mb15">经营信息</div>)}    */}

            {(formVal.status===undefined||formVal.status==5)&&getRow(
                <div className="footer">
                    <Button type='primary' onClick={()=>{onsubmit()}} disabled={localStorage.getItem('store_auth_shop_id')?true:false}>提交</Button>
                </div>
            )}
            <Modal
                title="提示"
                visible={true}
                footer={null}
                closable={false}
            >
                <p>
                    <a href="https://apply.ydn.cn/user/home">https://apply.ydn.cn/user/home</a>
                    <Button 
                        className="ml20" 
                        onClick={()=>{
                            copy('https://apply.ydn.cn/user/home');
                            message.success("链接已复制");
                        }}
                    >复制</Button>
                </p>
                <p>请使用微信浏览器打开以上网址进行资料认证</p>
            </Modal>            
        </div>
    )

}

export default StoreAuth
