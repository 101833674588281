import React, { useEffect, useState } from "react";
import { Row, Input, Select, DatePicker } from "antd";
import { getRequest } from "../../../../api/http";
import { apiUrl } from "../../../../api/api_url";

const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

type IfliterData = {
  [key: string]: string
}

let fliterData: IfliterData = {
  nickname: '',
  start_time: '',
  end_time: '',
  sex: ''
}

type Iprops = {
  search(key: IfliterData):void
}

type ImemberLevelListItem = {
  created_at: string
  id: number
  name: string
  score: number
}

const FilterRow = (props: Iprops) => {

  const [memberLevelList, setmemberLevelList] = useState<Array<ImemberLevelListItem>>([])
  
  useEffect(() => {
    getMemberLevelList();
  }, [])

  // 过滤日期
  const dateChange = (date:any, dateString:any) => {
    fliterData = {
      ...fliterData,
      start_time: dateString[0],
      end_time: dateString[1]
    }
    props.search(fliterData);
  };

  // 搜索
  const searchKey = async (val: string) => {
    fliterData = {
      ...fliterData,
      nickname: val
    }
    props.search(fliterData);
  }

  // 获取会员等级列表
  const getMemberLevelList = async() => {
    const res:any = await getRequest(apiUrl.fansLevelList);
    if(res && res['code'] === 1) {
      setmemberLevelList(res.data)
    }
  }
  
  // 筛选会员等级
  const onLevelChange = (value: any) => {
    fliterData = {
      ...fliterData,
      sex: value
    }
    props.search(fliterData);
  }

  return (
    <Row type="flex">
      {/* 用户昵称：
      <Search
        placeholder="请输入用户昵称"
        onSearch={searchKey}
        style={{ width: 200, marginRight: 20 }}
      /> */}
      <Row style={{ marginRight: 20 }}>
        用户昵称：
        <Search
          placeholder="请输入用户昵称"
          onSearch={searchKey}
          style={{ width: 200, marginRight: 20 }}
        />
        性别：
        <Select
          showSearch
          style={{ width: 200 }}
          placeholder="性别"
          optionFilterProp="children"
          onChange={onLevelChange}
          filterOption={(input, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=0 }
        >
          <Option value={0}>全部</Option>
          <Option value={1}>男</Option>
          <Option value={2}>女</Option>
          {/* {
            memberLevelList.map((el) => {
              return <Option key={el.id} value={el.id}>{el.name}</Option>
            })
          } */}
        </Select>
      </Row>
      <Row>
        最近消费时间：
        <RangePicker onChange={dateChange} placeholder={['开始时间', '结束时间']} />
      </Row>
    </Row>
  );
};

export default FilterRow;
