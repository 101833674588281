import React, { useEffect, useState, useRef } from 'react'
import ReactEcharts from "echarts-for-react";
import './doughnutChart.scss'

type Iprops = {
    target: Number,
    value: Number,
    title: String,
    unit: String,
}

const DoughnutChart = (props:Iprops)=>{

    const { target, value, title, unit }:any = props

    const [ option, setOption ] = useState<any>({
        title:{
            text:'开桌率',
            textAlign:'center',
            left:'50%',
            top:1,
            padding:'0 2 0 0',
            textStyle:{
                fontSize:16,
                color:'#242A31'
            }
        },        
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        graphic: [{ //图形中间文字
            type: "text",
            left: "center",
            top: "38%",
            style: {
                text: '总完成度',
                textAlign: "center",
                fill: "#000",
                fontSize: 14
            }
        },{ //图形中间文字
            type: "text",
            left: "center",
            top: "46%",
            style: {
                text: '75%',
                textAlign: "center",
                fill: "#000",
                fontSize: 16,
                fontWeight: 'bold',
            }
        },],        
        // legend: {
        //     orient: 'vertical',
        //     bottom: 5,
        //     data: ['总目标', '已完成'],
        //     icon: 'circle',
        // },
        color : [ '#FF3939', '#7B72FF'],
        series: [
            {
                name: '访问来源',
                type: 'pie',
                radius: ['45%', '60%'],
                center: ["50%", "45%"],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: 'center'
                },
                labelLine: {
                    show: false
                },
                data: [
                    {value: 50, name: '已完成'},
                    {value: 100, name: '总目标'},
                ],
                silent:true, //禁用点击事件
            }
        ]
    });
           

    useEffect(()=>{
        // if(target&&value){
            //初始化数据
            let optionTemp:any = JSON.parse(JSON.stringify(option))
            optionTemp.series[0].name = title
            optionTemp.title.text = title
            optionTemp.graphic[1].style.text = target?Math.round(value/target*100)+'%':0+'%'
            optionTemp.series[0].data = [
                {value: value, name: '已完成'},
                {value: parseFloat(value)>parseFloat(target)?0:target-value, name: '总目标'},
            ]
            setOption(optionTemp)
        // }
    },[target])

    return (
        <div className="doughnut_chart">
            <div style={{ height: '215px', width: '100%' }}>
                <ReactEcharts option={option} style={{ height: '100%', width: '100%' }} className='react_for_echarts' />
                <div className="circle_wrap">
                    <div className="row-start">
                        <div><span className="circle bg_blue_new"/>总目标:{target}{unit}</div>
                        <div><span className="circle bg_red"/>已完成:{value}{unit}</div>
                    </div>
                </div>
            </div> 
        </div>
    )
}

export default DoughnutChart



//进度条
// option = {
//     title: {
//     	text: '75%',
//     	textStyle: {
//     		color: '#48FFFF',
//     		fontSize: 12
//     	},
//     	left: 'center',
//     	top: 'center'
//     },
//     angleAxis: {
//     	max: 100, // 满分
//     	clockwise: false, // 逆时针
//         // 隐藏刻度线
//     	axisLine: {
//     		show: false
//     	},
//     	axisTick: {
//     		show: false
//     	},
//     	axisLabel: {
//     		show: false
//     	},
//     	splitLine: {
//     		show: false
//     	}
//     },
//     radiusAxis: {
//     	type: 'category',
//         // 隐藏刻度线
//     	axisLine: {
//     		show: false
//         },
//         axisTick: {
//     		show: false
//         },
//         axisLabel: {
//     		show: false
//         },
//         splitLine: {
//     		show: false
//         }
//     },
//     polar: {
//     	center: ['50%', '50%'],
//     	radius: '140%' //图形大小
//     },
//     series: [{
//     	type: 'bar',
//         data: [{
//     		name: '作文得分',
//     		value: 20,
//     		itemStyle: {
//     			normal: {
//     				color: { // 完成的圆环的颜色
//                         colorStops: [{
//                             offset: 0,
//                             color: '#48FFFF' // 0% 处的颜色
//                         }, {
//                             offset: 0.5,
//                             color: '#48FFFF' // 100% 处的颜色
//                         }]
//                     }
//     			}
//     		},
//         }],
//     	coordinateSystem: 'polar',
//     	roundCap: true,
//     	barWidth: 25,
//     	barGap: '-100%', // 两环重叠
//     	radius: ['49%', '53%'],
//     	z: 2,
//     },{ // 灰色环
//         type: 'bar',
//         data: [{
//     		value: 100,
//     		itemStyle: {
//                 colorStops: [{
//                     offset: 0.2,
//                     color: '#3B4068' // 0% 处的颜色
//                 }, {
//                     offset: 0.8,
//                     color: '#3B4068' // 100% 处的颜色
//                 }]    		    
//     		}
//         }],
//         coordinateSystem: 'polar',
//         roundCap: true,
//         barWidth: 25,
//         barGap: '-100%', // 两环重叠
//         radius: ['49%', '53%'],
//         z: 1,
//       },]
// }


