import { observable} from "mobx";

export interface IAccountAuthStore {
    count: number
}

export class AccountAuth implements IAccountAuthStore {
    @observable
    count = 0;

}
