import React, { useEffect, useState } from 'react'
import { Modal, message } from 'antd'
import { useHistory } from 'react-router-dom';
import './editOrderNumber.scss'
import { postRequest } from '../../api/http'
import { apiUrl } from '../../api/api_url'
const OrderReloadPrint = (props: any)=>{
  const history:any = useHistory();
  const [data,setdata] = useState<any>({})
  useEffect(()=>{
      setdata(props.data)
      // console.log(props.data)
  })


  const presentAction = async ()=>{
  //   {
  //     dish_id:int // 菜id
  //     change:int  // 变化 +1， -1， -2
  //     standard:string  // 规格
  // }

    let params = {
        code:data.code,
        id:data.id,
        attribute_id:data.standard_id,
        dish_weigh_value_id: data.dish_weigh_value_id ? data.dish_weigh_value_id : 0,
        material_key: data.material_key ? data.material_key : null,
        num:data.editCount ? data.editCount : 1,
    }
    const res:any = await postRequest(`${apiUrl.printerDishes}`,params);
    if(res.code === 1){
      message.info('重新打印成功');
      return true
    }else{
      return false
    }
  }

  const editMinus = ()=>{
    let newData = data
    if(newData.hasOwnProperty('editCount')){
        newData.editCount = Math.max(1,newData.editCount-1)
    }else{
        newData.editCount = 1
    }

    setdata(newData)
    refreshAction()
  }

  const editAdd = ()=>{
    let newData = data
    if(newData.hasOwnProperty('editCount')){
      data.editCount = Math.min(data.editCount+1,data.count)
    }else{
      data.editCount = 2
    }
    // data.editCount = data.editCount ? data.editCount+1 : data.count+1
    setdata(data)
    refreshAction()
  }


  //用于刷新的方法
  const [refresh,setRefresh] = useState(false);
  const refreshAction = () => {
    if(refresh === true){
      setRefresh(false)
    }else{
      setRefresh(true)
    }
  }
  useEffect(()=>{

  },[refresh])

  return (
    <Modal
        title={data.title ? data.title : ""}
        visible={data.visible ? data.visible : false}
        onOk={data.onOk ? ()=>{data.onOk(presentAction())} : ()=>{}}
        onCancel={data.onCancel ? data.onCancel : ()=>{}}
      >
        <div className="editOrderNumber">
          <div onClick={editMinus} className="back_btn reduce_btn active">-</div>
          <div className="content">{data.hasOwnProperty('editCount') ? data.editCount : 1}</div>
          <div onClick={editAdd} className="back_btn add_btn active">+</div>
        </div>
    </Modal>
  )
}

export default OrderReloadPrint