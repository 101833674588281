import React from 'react'
import { Tag, Select, Button, Table, Tooltip, message, Form, Modal, Icon, Popconfirm } from 'antd';
import './scheduleList.scss';
import { getRequest, postRequest } from "../../../api/http";
import { apiUrl } from "../../../api/api_url";

const { Option } = Select;
export default class ScheduleList extends React.Component<any>{

    state = {
        current: 1, // 当前页面索引
        pageSize: 20, // 每页数量
        total: 0,    // 总共多少条数据
        scheduleList: [],
        staffList: [],
        isModalVisible: false,
        tableList: [], // 排班表单餐桌列表
        tableIndex: [], // 餐桌目录，用于搜索筛选
        currentScheduleId: undefined,
        currentStaff: {
            id: undefined,
            name: undefined,
            mobile: undefined,
            job: undefined
        },
        defaultTableList: [],
        timeRange: [],
        listParams: {
            waiter_id: undefined,
            table_id: undefined
        },
    }

    async componentDidMount() {
        let { staffList } = this.state
        let res: any = await getRequest(apiUrl.getStaffList, { schedule: true, pageSize: 1000 })
        if (res.code === 1) {
            staffList = res.data.list
        }

        res = await getRequest(apiUrl.getCommentTableIndex, { pageSize: 1000 })
        let tableIndex: any = []
        if (res.code === 1) {
            res.data.forEach((item: any) => {
                item.tables.forEach((child: any) => {
                    tableIndex.push(<Option key={child.id}>{item.region_name + '-' + child.name}</Option>);
                });
            });
        }

        this.setState({ staffList, tableIndex })
        this.getScheduleList(1);
    }

    getScheduleList = async (current: any, pageSize?: any) => {
        const res: any = await getRequest(apiUrl.getScheduleList, { ...this.state.listParams, current: current || this.state.current, pageSize: pageSize || this.state.pageSize })
        if (res.code === 1) {
            //    return res.data
            this.setState({ scheduleList: res.data.list, total: res.data.total })
        } else {
            message.error("服务器请求数据失败")
            return
        }
    }

    getTableList = async (staffId: any, timeRange: any) => {
        if (timeRange[0] === "" || timeRange[1] === "") return;

        const res: any = await getRequest(apiUrl.getCommentTableList, { waiter_id: staffId || this.state.currentStaff.id, duty_start_time: timeRange[0], duty_end_time: timeRange[1] })

        if (res.code === 1) {
            let tableList: any = [];

            res.data.forEach((item: any) => {
                item.tables.forEach((child: any) => {
                    // console.log(child.id, typeof child.id)
                    tableList.push(<Option key={child.id}>{item.region_name + '-' + child.name}</Option>);
                });
            });

            this.setState({ tableList })
        } else {
            message.error("服务器请求数据失败")
            return
        }
    }

    scheduleStaff = async () => {
        const res: any = await postRequest(apiUrl.scheduleStaff, { id: this.state.currentScheduleId, waiter_id: this.state.currentStaff.id, duty_start_time: this.state.timeRange[0], duty_end_time: this.state.timeRange[1], table_ids: this.state.defaultTableList })
        if (res.code === 1) {
            this.getScheduleList(1)
        }

        this.resetFormData()
    }


    deleteBtnClick = async (row: any) => {
        const res: any = await getRequest(apiUrl.deleteSchedule, { id: row.id });

        if (res.code === 1) {
            this.getScheduleList(1)
        }
    }


    pageChange = (current: number) => {
        this.setState({ current: current })
        this.getScheduleList(current, this.state.pageSize)
    }

    // 改变每页数量
    onSizeChange = async (current: number, pageSize: number) => {
        this.setState({ pageSize })
        this.getScheduleList(1, pageSize)
    }

    selectTime = (name: string, value: any) => {
        let timeRange: any = this.state.timeRange

        if (name === 'firstTime') {
            timeRange[0] = value
        } else if (name === 'lastTime') {
            timeRange[1] = value
        }

        if (timeRange.length === 2 && timeRange[1] <= timeRange[0]) return;

        if (this.state.currentStaff.id && timeRange.length === 2) {
            this.getTableList(this.state.currentStaff.id, timeRange)
        }

        this.setState({ timeRange })
    }

    resetFormData = async () => {
        this.setState({
            currentScheduleId: undefined,
            isModalVisible: false,
            defaultTableList: [],
            currentStaff: {
                id: undefined,
                name: undefined,
                mobile: undefined,
                job: undefined
            },
            timeRange: []
        });
    }

    render() {

        const columns = [
            {
                title: '服务员',
                // key: 'name',
                dataIndex: 'name'
            },
            {
                title: '手机号码',
                // key: 'mobile',
                dataIndex: 'mobile',
            },
            {
                title: '值班时间',
                // key: 'duty_start_time',
                // dataIndex: 'duty_start_time',
                render: (text: any, record: any, index: number) => {
                    if (record.duty_start_time && record.duty_end_time && record.duty_start_time !== '点' && record.duty_end_time !== '点') {
                        return record.duty_start_time.substr(0, 16) + ' ~ ' + record.duty_end_time.substr(0, 16)
                    }

                    return '-'
                }
            },
            {
                title: '排班的餐桌',
                // key: 'tables',
                dataIndex: 'tables',
                render: (text: any, record: any, index: number) => {
                    if (record.tables) {
                        return <div>{record.tables.map((item: any) => {
                            return <Tag key={item.id}>{item.name}</Tag>
                        })}
                        </div>;
                    }

                    return '-'
                }
            },
            {
                title: '操作',
                dataIndex: 'action',
                render: (text: any, record: any, index: number) => (
                    <div className="btn_wrap">
                        <Tooltip placement="top" title='编辑'>
                            <Icon style={{ fontSize: 21, color: '#3975FF' }} type="calendar" key={'bind-' + index} onClick={() => {
                                let defaultTableList: any = [];
                                if (record.tables) {
                                    record.tables.forEach((item: any) => {
                                        defaultTableList.push(item.id.toString());
                                    })
                                }
                                let timeRange: any = []
                                if (record.duty_start_time && record.duty_start_time !== '点' && record.duty_end_time && record.duty_end_time !== '点') {
                                    timeRange = [parseInt(record.duty_start_time.replace('点', '')), parseInt(record.duty_end_time.replace('点', ''))]

                                    this.getTableList(record.waiter_id, timeRange);
                                }

                                let currentStaff: any = {
                                    id: record.waiter_id,
                                    name: record.name,
                                    mobile: record.mobile,
                                    job: undefined
                                }

                                this.setState({ currentScheduleId: record.id, currentStaff, isModalVisible: true, defaultTableList, timeRange })

                            }} />
                        </Tooltip>
                        <Popconfirm
                            title={'此操作将不可恢复，确认要删除吗？'}
                            onConfirm={() => { this.deleteBtnClick(record) }}
                            okText="删除"
                            okType="danger"
                            cancelText="取消"
                            placement="left"
                        >
                            <Tooltip placement="top" title='删除'>
                                <Icon style={{ fontSize: 22, marginLeft: 16, color: "red" }} key={'del-' + index} type="delete" />
                            </Tooltip>
                        </Popconfirm>
                    </div>
                ),
            }
        ];

        const layout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 18 },
        };

        const hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]


        return <div className="container" style={{ margin: 15 }}>
            <div>
                <Form className="form" key='searchForm' name="horizontal_login" layout="inline" onSubmit={(e: any) => {
                    console.log(e)
                    return false;
                }}>
                    <Form.Item style={{fontSize: '120%'}}>筛选：</Form.Item>
                    <Form.Item>
                        <Select style={{ width: 200 }} placeholder="服务员" allowClear={true} value={this.state.listParams.waiter_id} onChange={(v: any) => {
                            const { listParams } = this.state
                            listParams.waiter_id = v
                            this.state.listParams.waiter_id = v
                            this.setState({ listParams })

                            this.getScheduleList(1)
                        }}>
                            {this.state.staffList.map((item: any) => {
                                return <Option key={'search-staff-' + item.id} value={item.id}>{item.name}</Option>
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Select style={{ width: 200 }} placeholder="餐桌" allowClear={true} value={this.state.listParams.table_id} onChange={(v: any) => {
                            const { listParams } = this.state
                            listParams.table_id = v
                            this.state.listParams.table_id = v
                            this.setState({ listParams })

                            this.getScheduleList(1)
                        }}>
                            {this.state.tableIndex}
                        </Select>
                    </Form.Item>
                    <Form.Item className="fr">
                        <Button icon="plus" type="primary" ghost onClick={() => {
                            this.resetFormData()
                            this.setState({isModalVisible: true})
                        }}>添加排班</Button>
                    </Form.Item>

                </Form>


                <Modal title={(this.state.currentScheduleId? '编辑':'添加') + '排班'} visible={this.state.isModalVisible} okButtonProps={{
                    disabled: !this.state.currentStaff.id || this.state.timeRange.length < 2
                }} onOk={this.scheduleStaff} onCancel={this.resetFormData} okText="保存排班">
                    <Form {...layout} name="control-hooks" onSubmit={() => { return false; }}>
                        <Form.Item label="服 务 员" required={true}>
                            <Select style={{ width: '100%' }} placeholder="请选择" value={this.state.currentStaff.id} onChange={(v: any) => {
                                this.state.staffList.forEach((item: any) => {
                                    if (item.id === v) {
                                        this.setState({ currentStaff: item })
                                    }
                                })
                            }}>
                                {this.state.staffList.map((item: any) => {
                                    return <Option key={'form-staff-' + item.id} value={item.id}>{item.name}</Option>
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item label="值班时间" required={true}>
                            <div style={{
                                display: 'flex', flexWrap: 'wrap',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}>
                                <Select placeholder="开始时间" style={{ width: 160 }} value={this.state.timeRange[0]} onChange={(v: any) => { this.selectTime('firstTime', v) }}>
                                    {hours.map((h: number) => {
                                        return <Option key={'firstTime-' + h} value={h}>{h}点</Option>
                                    })}
                                </Select>
                            -
                            <Select placeholder="结束时间" style={{ width: 160 }} value={this.state.timeRange[1]} onChange={(v: any) => { this.selectTime('lastTime', v) }}>
                                    {hours.map((h: number) => {
                                        return <Option key={'lastTime-' + h} value={h}>{h}点</Option>
                                    })}
                                </Select>
                            </div>
                        </Form.Item>
                        <Form.Item label="餐　　桌" required={true}>
                            <Select mode="multiple"
                                disabled={!this.state.currentStaff.id || this.state.timeRange.length < 2}
                                style={{ width: '100%' }}
                                placeholder="请选择"
                                value={this.state.defaultTableList}
                                onChange={(e: any) => {
                                    console.log(this.state.defaultTableList)
                                    this.setState({ defaultTableList: e })
                                }}
                            >
                                {this.state.tableList}
                            </Select>
                            <div style={{ lineHeight: '150%', marginTop: 15 }}>请先选择<span style={{ textDecoration: 'underline' }}>服务员</span>和<span style={{ textDecoration: 'underline' }}>值班时间</span>，然后再选择<span style={{ textDecoration: 'underline' }}>餐桌</span>，结束时间必须<span style={{ textDecoration: 'underline' }}>大于</span>开始时间，如有跨天的需求，可分开两次设置。</div>
                        </Form.Item>
                    </Form>
                </Modal>

                <Table
                    // rowSelection={this.rowSelection}
                    dataSource={this.state.scheduleList}
                    columns={columns}
                    rowKey={"id"}
                    size="middle"
                    locale={{ emptyText: '暂无数据' }}
                    pagination={{
                        // current: this.state.currentPage,
                        // defaultCurrent: 1,
                        total: this.state.total,
                        pageSize: this.state.pageSize,
                        showQuickJumper: true,
                        showSizeChanger: true,
                        onShowSizeChange: this.onSizeChange,
                        onChange: this.pageChange,
                        showTotal: (total: number) => `共${total}条`
                    }}
                />
            </div>
        </div>
    }
}