import React from 'react'
import { Row, Button, Typography} from 'antd'
import { Link } from 'react-router-dom';

const { Text } = Typography;

const Nothing = () => {

  return <Row type="flex" justify="center" align="middle" style={{height: '80vh', width: '100%', flexDirection: 'column'}}>
      <Text>你还没创建过活动</Text>
      <Link to="/sidebar/create_active">
        <Button type="primary" style={{marginTop: 10}}>新建活动</Button>
      </Link>
  </Row>
};

export default Nothing