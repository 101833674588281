import React from 'react'
import { Row, Tooltip, Icon, Typography, Col, Card } from 'antd';

interface IDataSummaryItem {
  tips: string;
  mainTitle: string;
  subTitle: string;
}

const { Title, Text } = Typography;

const DataSummaryItem = (props: IDataSummaryItem) => {
  return (
    <Col span={4} style={{ padding: "30px" }}>
      <Card
        style={{
          boxShadow: "8px 8px 76px 0px rgba(0,0,0,0.05)",
          border: "none"
        }}
      >
        <Row type="flex" justify="end">
          <Tooltip title={props.tips}>
            <Icon type="exclamation-circle" />
          </Tooltip>
        </Row>
        <Title level={3} style={{ textAlign: "center", marginTop: "20px" }}>
          {props.mainTitle}
        </Title>
        <Row type="flex" justify="center" style={{ marginBottom: "10px" }}>
          <Text style={{ textAlign: "center", fontWeight: "bold" }}>
            {props.subTitle}
          </Text>
        </Row>
      </Card>
    </Col>
  );
}

export default DataSummaryItem