import React, {useEffect, useState} from "react";
import { RouteComponentProps } from "react-router-dom";
import i_add_icon from "../../../assret/images/i_add_icon.png";

import './smallProgram.scss'
import { apiUrl } from "../../../api/api_url";
import { Modal, Avatar, message } from "antd";
import { getRequest } from "../../../api/http";
interface Iprops  extends RouteComponentProps{

};
type modelType = {
    alert: string,
    tips: string,
    head_img: string
}


type typeInfo ={
    id: number,
}

type accountInfo ={
    id: number,
    nick_name:string,
    head_img: string,
    service_type_info: typeInfo,
    create_time: number
}

const SmallProgram = (props:Iprops) =>{

    const [tips,setTips] = useState<modelType>({
        alert: '',
        tips: '',
        head_img: ''
    });   // 小程序 授权错误提示
    const [accounts,setAccounts] = useState([]);

    const [visible,setVisible] = useState(false);

    let page = 1; let page_size = 30

     // 相当于 componentDidMount 和 componentDidUpdate:
     useEffect(() =>{
       // 获取小程序列表
       requestAuthAccount();
    },[]);

    /**
     * 公众号授权回调 判断
     */
    const controlModelTip =()=>{
        const params = new URLSearchParams(props.location.search);
        if(params.get("alert") != null){
            let tipTem:any =tips;
            tipTem.alert = unicodeToUtf8(params.get("alert") as string);
            tipTem.tips = unicodeToUtf8(params.get("tips") as string);
            tipTem.head_img = params.get("head_img");
            setVisible(true);
        }
    };

    /**
     * unicode 转中文
     */
    const unicodeToUtf8 =(str:string) =>{
        str = unescape(str.replace(/\\/g, "%"))
        str = str.substring(1,str.length-1);
        return str;
    };

    const requestAuthH5 = () =>{
        window.location.href = process.env.REACT_APP_BASEURL+apiUrl.accountAuthH5 + `?token=${localStorage.getItem('token')}&auth_type=2`
    };

     // 获取 认证公众号列表
     const requestAuthAccount = async () =>{
        const res: any = await getRequest(apiUrl.authAccountList,{page: page,page_size: page_size,mp_type: 2});
        if(res.code === 1){
            setAccounts(res.data.list);
        }else{
           message.error(res.message)
        }
    };

      // 认证状态
     const handleAuthStatus = (id:number) =>{
        return id === -1 ? '未认证':'认证'
    };

    return (
        <div className="small_program_view l_content">
            <div className="add_account_button" onClick={requestAuthH5}>
                <div><img src={i_add_icon} className="i_add_icon" alt="" /></div>
                <div><div className="add_button_text">授权小程序</div></div>
            </div>
            {
                accounts.map((item:accountInfo,index) =>
                    <div className='add_account_button' key={index}>
                        <Avatar size={90} src={item.head_img} />
                        <div className='add_button_text'>{item.nick_name}</div>
                        <div className='account_type'>{handleAuthStatus(item.service_type_info.id)}</div>
                    </div>
                )
            }
            <Modal
                visible={visible}
                footer={null}
                title={null}
                onCancel={()=>{setVisible(false)}}
            >
                <div className="model_view">
                    <div className='model_title'>绑定失败</div>
                    <Avatar size={90} src={tips.head_img} />
                    <div className='model_result_tips'>{tips.alert}</div>
                    <div className='model_tips'>{tips.tips}</div>
                </div>

            </Modal>
        </div>
    )
};

export default SmallProgram;