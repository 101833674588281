import React from 'react';
import InsideNav from '../../component/insideNav/insideNav';
import { collectChildNav, getIsPermisson } from '../../utils/permisstionUtil';
import UserList from './userList';
import VipUser from './vipUser';
import ChargeUser from './chargeUser';

type IProps = {
    history: any
}
type IState = {
    navList: any
    current_key: string,
    phone: string
}

const navTable = [
    {
        title: '用户列表',
        isActive: false,
        KEY: "USERLIST",
        uni_key: '1_11_8_1'
    },
    {
        title: 'VIP会员',
        isActive: false,
        KEY: "VIPUSER",
        uni_key: '1_11_8_2'
    },
    {
        title: '充值会员',
        isActive: false,
        KEY: "CHARGEUSER",
        uni_key: '1_11_8_3'
    },
]

class MemberManage extends React.Component<IProps,IState>{

    constructor(props:IProps) {
        super(props);
        this.state = {
            navList: [],
            current_key: "",
            phone: ''
        }
    }

    componentDidMount(){
        this.initPageState();
    }

    initPageState = () =>{
        let navList = collectChildNav(navTable);
        let currentKey = localStorage.getItem('current_key') ?? navList[0].KEY;
        let isSetKey = false;
        navList.map((item:any) => {
            if(item.KEY == currentKey){
                item.isActive = true;
                isSetKey = true;
            }else{
                item.isActive = false;
            }
        })
        if(!isSetKey){
            currentKey = navList[0].KEY;
            navList[0].isActive = true;
        };
        this.setState({
            current_key: currentKey,
            navList: navList
        })
    }

    navCallBack(currentKey: string){
        localStorage.setItem('current_key',currentKey)
        this.setState({
            current_key: currentKey,
            phone: ''
        })
    }

    navChange(key:any, phone:any){
        let _navList = JSON.parse(JSON.stringify(this.state.navList));
        _navList.map((item:any) => item.isActive = false);
        let key1 = '';
        if(key == 1){
            _navList[0].isActive = true;
            key1 = 'USERLIST';
        }else if(key == 2){
            _navList[1].isActive = true;
            key1 = 'VIPUSER';
        }else if(key == 3){
            _navList[2].isActive = true;
            key1 = 'CHARGEUSER';
        }

        this.setState({
            navList: _navList,
            current_key: key1,
            phone
        })
    }

    render() {
        let { navList,current_key } = this.state;
        return <div>
            <InsideNav navList={navList} callBack={(key: string) =>this.navCallBack(key)}/>
            {/* 会员管理 */}
            <div className="body">
                {
                    current_key === 'USERLIST' ?  <UserList history={this.props.history}  phone={this.state.phone}/>
                    :
                    current_key === 'VIPUSER' ?  <VipUser history={this.props.history} callBack={(key: string, phone:string) =>this.navChange(key,phone)} phone={this.state.phone} />
                    :
                    current_key === 'CHARGEUSER' ?  <ChargeUser location={{}} callBack={(key: string, phone:string) =>this.navChange(key,phone)} phone={this.state.phone} />
                    : <div />
                }
            </div>
        </div>
    }

}

export default MemberManage;