import React, { useEffect, useState } from 'react'
import { message, Row, Switch, Input, Button, Select, TimePicker } from 'antd'
import { useHistory } from 'react-router-dom'
import { getRequest, postRequest } from '../../../api/http'
import { apiUrl } from '../../../api/api_url'
import EditMsgModal from '../components/editMsgModal'
import moment from 'moment'

import './index.scss'

const { Option } = Select
const DepositMsgSetting = (props:any)=>{

    const history = useHistory()
    const dayList = ['0','1','2','3','4','5','6','7']
    const [ visible ,setVisible ] = useState(false)
    const [ smsInfo, setSmsInfo ] = useState({
        sign:'',
        number:0,
    })

    const [ info, setInfo ] = useState<any>({
        number:0, //剩余短信条数
        sign:'', //短信签名
        status:0, //短信签名审核状态 1审核中 2审核成功 3审核失败 4普通通道审核成功申请升级专用通道中
        sign_remark:'', //短信签名审核备注
        push_sms_content:'', //存酒短信提醒
        pull_sms_content:'', //取酒短信提醒
        expire_sms_content:'', //过期预警提醒
        protocol:'', // "<html><b>短信协议</b></html>",
    })
    //短信提醒配置
    const [ sms_setting, setSmsSetting ] = useState<any>({
        expire_day: 0, //过期预警天数 null表示未配置
        pull_switch: false, //取酒提醒开关
        push_switch: false, //存酒提醒开关
        expire_switch: false, //过期提醒开关
        expire_interval: -1, //过期提醒间隔天数
        expire_time: "00:00", //过期提醒时间

    })
    //公众号提醒配置
    const [ official_setting, setOfficialSetting ] = useState<any>({
        expire_day: 0, //过期预警天数 null表示未配置
        pull_switch: false, //取酒提醒开关
        push_switch: false, //存酒提醒开关
        expire_switch: false, //过期提醒开关
        expire_interval: -1, //过期提醒间隔天数
        expire_time: "00:00", //过期提醒时间
    })

    const [ loading, setLoading ] = useState(false)

    useEffect(()=>{
        getinfo()
    },[])

    const getinfo = async() => {
        const res:any = await getRequest(apiUrl.queryStorageSms)
        if(res.code === 1){
            setInfo(res.data) 
            res.data.storage_remind_switch.sms.expire_day = res.data.storage_remind_switch.sms.expire_day||0
            res.data.storage_remind_switch.sms.expire_interval = res.data.storage_remind_switch.sms.expire_interval||-1
            res.data.storage_remind_switch.sms.expire_time = res.data.storage_remind_switch.sms.expire_time||"00:00"
            res.data.storage_remind_switch.official.expire_day = res.data.storage_remind_switch.official.expire_day||0
            res.data.storage_remind_switch.official.expire_interval = res.data.storage_remind_switch.official.expire_interval||-1
            res.data.storage_remind_switch.official.expire_time = res.data.storage_remind_switch.official.expire_time||"00:00"
            setSmsSetting(res.data.storage_remind_switch.sms)
            setOfficialSetting(res.data.storage_remind_switch.official)
        }else{
            message.error(res.message||'获取信息提醒配置失败')
        }
    }
    
    const onsubmit = async() =>{
        if(sms_setting.expire_switch){
            if(sms_setting.expire_interval === -1){
                message.error('过期服务号模板消息提醒设置请选择每隔多少天推送一次')
                return false
            }
        }
        if(official_setting.expire_switch){
            if(official_setting.expire_interval === -1){
                message.error('过期短信提醒设置请选择每隔多少天推送一次')
                return false
            }
        }
        const params = {
            value:JSON.stringify({
                official:official_setting,
                sms:sms_setting,
                print_config:info.storage_remind_switch.print_config
            })
        }
        setLoading(true)
        const res:any = await postRequest(apiUrl.setStorage,params)
        setLoading(false)
        if(res.code === 1){
            message.success('保存成功')
            history.goBack()
        }else{
            message.error(res.message||'保存失败')
        }
    }

    const goBack = () =>{
        history.goBack()
    }

    const editSign = (sign:any) => {
        setInfo({
            ...info,
            sign:sign
        })
    }

    const openModal = (sign:string,number:number) => {
        setVisible(true)
        setSmsInfo({
            sign,
            number
        })
    }

    const closeModal = () => {
        setVisible(false)
    }

    const changeFieldVal = (e:any, field:string, type:string) => {
        console.log('changeFieldVal----')
        console.log(e)
        console.log(field)
        let fieldObj:any = {}
        if(field==='expire_day') e = e ? parseInt(e) : 0
        fieldObj = { [field]: e }
        if(type==='sms_setting'){
            setSmsSetting({
                ...sms_setting,
                ...fieldObj
            })
        }else{
            setOfficialSetting({
                ...official_setting,
                ...fieldObj
            })
        }
    }


    const format = 'HH:mm'

    const serviceItem = (title:any,isExpire:boolean=false,checked:any,field:any) => {
        return(
            <Row className="card_item" type="flex" align="top">
                <div className="card_item_left">{title}：</div>
                <div className="card_item_right">
                    <Row type="flex" align="top">
                        <div className="card_item_label">服务号提醒</div>
                        <Switch checked={checked} onChange={(e)=>{changeFieldVal(e,field,'official_setting')}} />
                        确保公众号添加此模板，并把模板ID复制到平台
                    </Row> 
                    {
                        isExpire&&checked&&(
                            <>
                                <Row className="expire_warn_day" type="flex" align="middle">
                                    <div className="card_item_label"><span>*</span>过期预警天数</div>
                                    <Input value={official_setting.expire_day} placeholder="" type="number" onChange={(e)=>{changeFieldVal(e.currentTarget.value,'expire_day','official_setting')}} />
                                </Row>  
                                {/* <Row className="expire_warn_day" type="flex" align="middle">
                                    <div className="card_item_label"><span>*</span>短信提醒发送频率</div>
                                    <Select value={0} className="card_item_select">
                                        <Option value={0}>每天</Option>
                                        <Option value={1}>自定义</Option>
                                    </Select>
                                </Row>   */}
                                <Row className="expire_warn_day" type="flex" align="middle">
                                    <div className="card_item_label"><span>*</span>每隔多少天</div>
                                    <Select value={official_setting.expire_interval} className="card_item_select" onChange={(e:any)=>{changeFieldVal(e,'expire_interval','official_setting')}}>
                                        <Option value={-1}>请选择</Option>
                                        {
                                            // dayList.map((item,index)=>{
                                            //     return <Option value={index} key={index}>{item}</Option>
                                            // })
                                            new Array(official_setting.expire_day+1).join(',').split(',').map((item:any,index:number)=>{
                                                return <Option value={index} key={index}>{index}</Option>
                                            })
                                        }
                                    </Select>
                                    推送一次
                                </Row>  
                                <Row className="expire_warn_day" type="flex" align="middle">
                                    <div className="card_item_label"><span>*</span>通知时刻</div>
                                    <TimePicker className="card_item_timePicker" defaultValue={moment(official_setting.expire_time?official_setting.expire_time:'00:00', format)} minuteStep={15} format={format} onChange={(e,timeString)=>{changeFieldVal(timeString,'expire_time','official_setting')}}/>
                                </Row> 
                            </> 
                        )
                    }
                </div>
            </Row>
        )
    }

    const smsItem = (title:any,smsTotal:number,template:string,checked:any,field:string) => {
        return(
            <Row className="card_item" type="flex" align="top">
                <div className="card_item_left">{title}：</div>
                <div className="card_item_right">
                    <Row type="flex" align="top">
                        <div className="card_item_label">短信提醒</div>
                        <Switch checked={checked} onChange={(e)=>{changeFieldVal(e,field,'sms_setting')}} />
                        短信提醒为收费项目，其确保短信账户中余额充足
                    </Row>
                    {/* <div className="card_item_total">
                        剩余 <span>{smsTotal}</span> 条短信
                    </div> */}
                    <Row type="flex" align="top" className="mt25">
                        <div className="card_item_label mt15">短信内容</div>
                        <div className="card_item_template">{template}</div>
                    </Row>
                    {/* <Row type="flex" align="top" className="card_item_sign">
                        <div className="card_item_label">短信签名</div>
                        <div>
                            <div className="card_item_edit">{sign} <span onClick={()=>{openModal(1,sign,editNum)}}>修改</span></div>
                            <div className="card_item_edit_num">每个自然月修改{editNum}次</div>
                        </div>
                    </Row> */}
                </div>
            </Row>  
        )
    }

    return (
        <div className="deposit_msg_setting">
            <div className="title_text">信息提醒设置</div>
            <div className="card">
                <div className="sub_title">服务号提醒设置</div>
                {serviceItem('存酒服务号模板消息提醒设置',false,official_setting.pull_switch,'pull_switch')}
                {serviceItem('取酒服务号模板消息提醒设置',false,official_setting.push_switch,'push_switch')}
                {serviceItem('过期服务号模板消息提醒设置',true,official_setting.expire_switch,'expire_switch')}
            </div>
            <div className="card">
                <Row type="flex" align="top" className="sub_title">
                    <div className="sub_title_left">短信提醒设置</div>
                    <div className="card_item_total">
                        剩余 <span>{info.number}</span> 条短信
                    </div>
                    <Row type="flex" align="top" className="card_item_sign">
                        <div className="card_item_label">短信签名</div>
                        <div>
                            <div className="card_item_edit">{info.sign} <span onClick={()=>{openModal(info.sign,2)}}>修改</span></div>
                            <div className="card_item_edit_num">每个自然月修改2次</div>
                        </div>
                    </Row>
                </Row>
                
                {smsItem('存酒短信提醒设置',info.number,info.push_sms_content,sms_setting.pull_switch,'pull_switch')}
                {smsItem('取酒短信提醒设置',info.number,info.pull_sms_content,sms_setting.push_switch,'push_switch')}
                {/* {smsItem('过期短信提醒设置',info.number,info.expire_sms_content,info.sign,sms_setting.expire_switch,'expire_switch',2, 2)} */}
                <Row className="card_item" type="flex" align="top">
                    <div className="card_item_left">过期短信提醒设置：</div>
                    <div className="card_item_right">
                        <Row type="flex" align="top">
                            <div className="card_item_label">服务号提醒</div>
                            <Switch checked={sms_setting.expire_switch} onChange={(e)=>{changeFieldVal(e,'expire_switch','sms_setting')}}/>
                            确保公众号添加此模板，并把模板ID复制到平台
                        </Row>
                        {
                            sms_setting.expire_switch&&(
                                <>
                                    <Row className="expire_warn_day" type="flex" align="middle">
                                        <div className="card_item_label"><span>*</span>过期预警天数</div>
                                        <Input value={sms_setting.expire_day} placeholder="" type="number" onChange={(e)=>{changeFieldVal(e.currentTarget.value,'expire_day','sms_setting')}}/>
                                    </Row>   
                                    {/* <Row className="expire_warn_day" type="flex" align="middle">
                                        <div className="card_item_label"><span>*</span>短信提醒发送频率</div>
                                        <Select value={0} className="card_item_select">
                                            <Option value={0}>每天</Option>
                                            <Option value={1}>自定义</Option>
                                        </Select>
                                    </Row>   */}
                                    <Row className="expire_warn_day" type="flex" align="middle">
                                        <div className="card_item_label"><span>*</span>每隔多少天</div>
                                        <Select value={sms_setting.expire_interval} className="card_item_select" onChange={(e:any)=>{changeFieldVal(e,'expire_interval','sms_setting')}}>
                                            <Option value={-1}>请选择</Option>
                                            {
                                                // dayList.map((item,index)=>{
                                                //     return <Option value={index} key={index}>{item}</Option>
                                                // })
                                                new Array(sms_setting.expire_day+1).join(',').split(',').map((item:any,index:number)=>{
                                                    return <Option value={index} key={index}>{index}</Option>
                                                })
                                            }
                                        </Select>
                                        推送一次
                                    </Row>  
                                    <Row className="expire_warn_day" type="flex" align="middle">
                                        <div className="card_item_label"><span>*</span>通知时刻</div>
                                        <TimePicker className="card_item_timePicker" defaultValue={moment(sms_setting.expire_time?sms_setting.expire_time:'00:00', format)} minuteStep={15} format={format}  onChange={(e,timeString)=>{changeFieldVal(timeString,'expire_time','sms_setting')}}/>
                                    </Row>                                 
                                </>
                            )
                        }
                        <Row type="flex" align="top" className="mt25">
                            <div className="card_item_label mt15">短信内容</div>
                            <div className="card_item_template">{info.expire_sms_content}</div>
                        </Row>                        
                        {/* <div className="card_item_tip">注：请勿修改大括号中的内容,配置修改后次日生效。</div> */}
                    </div>
                </Row>
            </div>
            <Row type="flex" align="middle" justify="center" className="footer">
                <Button type="primary" className="mr20" onClick={()=>{onsubmit()}} loading={loading}>保 存</Button>
                <Button onClick={()=>{goBack()}}>取 消</Button>
            </Row>
            <EditMsgModal visible={visible} editSign={(sign:any)=>{editSign(sign)}} closeModal={()=>{closeModal()}} smsInfo={smsInfo}/>
        </div>
    )
}

export default DepositMsgSetting
