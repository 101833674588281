import React from "react"
import { inject, observer } from "mobx-react"
import styles from './decorationCenter.module.scss'
import shopDecorationStore from '../../../../stores/shopDecoration.store'

import decoration_wode_date from '../../../../assret/images/decoration_wode_date.png'
import decoration_wode_signal from '../../../../assret/images/decoration_wode_signal.png'
import decoration_wode_icon from '../../../../assret/images/decoration_wode_icon.png'
import decoration_wode_asster from '../../../../assret/images/decoration_wode_asster.png'
import decoration_wode_price from '../../../../assret/images/decoration_wode_price.png'
import decoration_wode_footer from '../../../../assret/images/decoration_wode_footer.png'
import decoration_wode_edit from '../../../../assret/images/decoration_wode_edit.png'
import arrows from '../../../../assret/images/arrows.png'

@observer
class DecorationCenter extends React.Component {

    state = {
        first: 0,
        two: 0,
        three: 0,
        four: 0,
    }

    mouseEnter = (idx: any) => {
        let temp_list = shopDecorationStore.shop_decoration_wode.merber_menu.module_list;
        temp_list[idx]['is_edit'] = 1;
        shopDecorationStore.updateObjProps('merber_menu','module_list',temp_list)
    }

    mouseLeave = (idx: any) => {
        let temp_list = shopDecorationStore.shop_decoration_wode.merber_menu.module_list;
        temp_list[idx]['is_edit'] = 0;
        shopDecorationStore.updateObjProps('merber_menu','module_list',temp_list)
    }

    //编辑
    onUpdateModuleList = (e: any, idx: any) => {
        e.stopPropagation()
        shopDecorationStore.updateObjProps('merber_menu','current_index',idx)
        shopDecorationStore.updateObjProps('merber_menu','current_is_edit',1)
        this.editDefaultName()
    }

    //编辑时，搜索出对应的链接名称
    editDefaultName = () => {
        shopDecorationStore.updateObjProps('merber_menu','current_name','')
        let list = shopDecorationStore.shop_decoration_wode.href_path;
        let module_list: any = shopDecorationStore.shop_decoration_wode.merber_menu.module_list;
        let idx = shopDecorationStore.shop_decoration_wode.merber_menu.current_index;
        list && list.length > 0 && list.map((item: any, index: any) => {
            if(item.path === module_list[idx]['path']) {
                shopDecorationStore.updateObjProps('merber_menu','current_name',item.name)
            }
        })
    }

    //选中边框
    borderOnMouse = (type: any, val: any) => {
        this.setState({
            [type]: val
        })
    }

    //选中点击
    onSelectClick = (type: any) => {
        shopDecorationStore.updateObjProps('merber_menu','current_is_edit',0)
    }

    render () {
        const { merber_info, merber_interests, merber_assets, merber_menu } = shopDecorationStore.shop_decoration_wode;
        const { first, two, three, four } = this.state;
        
        return (
            <div className={styles.center}>
                <div className={`${styles.center_top} ${first === 1 ? styles.center_border : ''}`} style={{backgroundImage: `url(${merber_info.background})`, height: `${merber_info.photo_position === 2 ? '220px' : 'auto'}`, backgroundSize: 'cover'}} onMouseEnter={() => {this.borderOnMouse('first',1)}} onMouseLeave={() => {this.borderOnMouse('first',0)}} onClick={() => {this.onSelectClick('merber_info')}}>
                    <div className={styles.center_top_status}>
                        <img src={decoration_wode_date} className={styles.center_top_status_left} />
                        <img src={decoration_wode_signal} className={styles.center_top_status_right} />
                    </div>
                    <div className={styles.center_top_title}>
                        <div className={styles.center_top_title_name}>我的</div>
                        <div>
                            <img src={decoration_wode_icon} className={styles.center_top_title_img} />
                        </div>
                    </div>
                    <div className={styles.center_top_modu}>
                        {
                            merber_info.photo_position === 1 && (
                                <div className={styles.center_top_modu_one}>
                                    <div>
                                        <img src='https://ydn-siyu.cdn.shuachi.com/no_login.png' className={styles.center_top_modu_one_img} />
                                    </div>
                                    <div className={styles.center_top_modu_one_info}>
                                        <div className={styles.center_top_modu_one_info_name}>Barrett Moore</div>
                                        <div className={styles.center_top_modu_one_info_level}>普通会员</div>
                                    </div>
                                </div>
                            )
                        }
                        {
                            merber_info.photo_position === 3 && (
                                <div className={styles.center_top_modu_two}>
                                    <div className={styles.center_top_modu_two_info}>
                                        <div className={styles.center_top_modu_two_info_name}>Barrett Moore</div>
                                        <div className={styles.center_top_modu_two_info_level}>普通会员</div>
                                    </div>
                                    <div>
                                        <img src='https://ydn-siyu.cdn.shuachi.com/no_login.png' className={styles.center_top_modu_one_img} />
                                    </div>
                                </div>
                            )
                        }
                        {
                            merber_info.photo_position === 2 && (
                                <div className={styles.center_top_modu_three}>
                                    <div>
                                        <img src='https://ydn-siyu.cdn.shuachi.com/no_login.png' className={styles.center_top_modu_one_img} />
                                    </div>
                                    <div className={styles.center_top_modu_three_info}>
                                        <div className={styles.center_top_modu_three_info_name}>Barrett Moore</div>
                                        <div className={styles.center_top_modu_three_info_level}>普通会员</div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className={styles.center_main}>
                    {
                        merber_interests === 1 && (
                            <div className={`${styles.center_main_asster} ${two === 1 ? styles.center_border : ''}`} onMouseEnter={() => {this.borderOnMouse('two',1)}} onMouseLeave={() => {this.borderOnMouse('two',0)}} onClick={() => {this.onSelectClick('merber_interests_open')}}>
                                <img src={decoration_wode_asster} className={styles.center_main_asster_img} />
                            </div>
                        )
                    }
                    {
                        merber_assets === 1 && (
                            <div className={`${styles.center_main_price} ${three === 1 ? styles.center_border : ''}`} style={{marginTop: `${merber_interests === 0 ? '20px' : '0'}`}} onMouseEnter={() => {this.borderOnMouse('three',1)}} onMouseLeave={() => {this.borderOnMouse('three',0)}} onClick={() => {this.onSelectClick('merber_assets_open')}}>
                                <img src={decoration_wode_price} className={styles.center_main_price_img} />
                            </div>
                        )
                    }
                    <div className={`${styles.center_main_item} ${four === 1 ? styles.center_border : ''}`} style={{marginTop: `${merber_interests === 0 && merber_interests === 0 ? '20px' : '0'}`}} onMouseEnter={() => {this.borderOnMouse('four',1)}} onMouseLeave={() => {this.borderOnMouse('four',0)}} onClick={() => {this.onSelectClick('merber_menu')}}>
                        {
                            merber_menu.module_style === 1 && (
                                <div className={styles.center_main_item_gongge}>
                                    {
                                        merber_menu.module_column === 3 && (
                                            <div className={styles.center_main_item_gongge_one}>
                                                {
                                                    merber_menu.module_list && merber_menu.module_list.length > 0 && merber_menu.module_list.map((item: any, index: any) => {
                                                        return (
                                                            item.show === '1' && (
                                                                <div key={item.name + index + 'a'} className={styles.center_main_item_gongge_one_item} onMouseEnter={() => {this.mouseEnter(index)}} onMouseLeave={() => {this.mouseLeave(index)}}>
                                                                    <div>
                                                                        <img src={item.icon} className={styles.center_main_item_gongge_one_item_img} />
                                                                        {
                                                                            item['is_edit'] === 1 && (
                                                                                <img src={decoration_wode_edit} className={styles.center_main_item_gongge_one_item_imgedit} onClick={(e: any) => {this.onUpdateModuleList(e,index)}} />
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <div style={{color: item.font_color, fontSize: item.font_size, fontWeight: item.weigh === 1 ? 'bold' : 'normal'}} className={styles.center_main_item_gongge_one_item_txt}>
                                                                        {item.name}
                                                                    </div>
                                                                </div>
                                                            )
                                                        )
                                                    })
                                                }
                                            </div>
                                        )
                                    }
                                    {
                                        merber_menu.module_column === 4 && (
                                            <div className={styles.center_main_item_gongge_two}>
                                                {
                                                    merber_menu.module_list && merber_menu.module_list.length > 0 && merber_menu.module_list.map((item: any, index: any) => {
                                                        return (
                                                            item.show === '1' && (
                                                                <div key={item.name + index + 'b'} className={styles.center_main_item_gongge_two_item} onMouseEnter={() => {this.mouseEnter(index)}} onMouseLeave={() => {this.mouseLeave(index)}}>
                                                                    <div>
                                                                        <img src={item.icon} className={styles.center_main_item_gongge_two_item_img} />
                                                                        {
                                                                            item['is_edit'] === 1 && (
                                                                                <img src={decoration_wode_edit} className={styles.center_main_item_gongge_two_item_imgedit} onClick={(e: any) => {this.onUpdateModuleList(e,index)}} />
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <div style={{color: item.font_color, fontSize: item.font_size, fontWeight: item.weigh === 1 ? 'bold' : 'normal'}} className={styles.center_main_item_gongge_two_item_txt}>
                                                                        {item.name}
                                                                    </div>
                                                                </div>
                                                            )
                                                        )
                                                    })
                                                }
                                            </div>
                                        )
                                    }
                                    {
                                        merber_menu.module_column === 5 && (
                                            <div className={styles.center_main_item_gongge_three}>
                                                {
                                                    merber_menu.module_list && merber_menu.module_list.length > 0 && merber_menu.module_list.map((item: any, index: any) => {
                                                        return (
                                                            item.show === '1' && (
                                                                <div key={item.name + index + 'c'} className={styles.center_main_item_gongge_three_item} onMouseEnter={() => {this.mouseEnter(index)}} onMouseLeave={() => {this.mouseLeave(index)}}>
                                                                    <div>
                                                                        <img src={item.icon} className={styles.center_main_item_gongge_three_item_img} />
                                                                        {
                                                                            item['is_edit'] === 1 && (
                                                                                <img src={decoration_wode_edit} className={styles.center_main_item_gongge_three_item_imgedit} onClick={(e: any) => {this.onUpdateModuleList(e,index)}} />
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <div style={{color: item.font_color, fontSize: item.font_size, fontWeight: item.weigh === 1 ? 'bold' : 'normal'}} className={styles.center_main_item_gongge_three_item_txt}>
                                                                        {item.name}
                                                                    </div>
                                                                </div>
                                                            )
                                                        )
                                                    })
                                                }
                                            </div>
                                        )
                                    }
                                </div>
                            )
                        }
                        {
                            merber_menu.module_style === 2 && (
                                <div className={styles.center_main_item_list}>
                                    {
                                        merber_menu.module_list &&　merber_menu.module_list.length > 0 && merber_menu.module_list.map((item: any, index: any) => {
                                            return (
                                                item.show === '1' && (
                                                    <div className={styles.center_main_item_list_info} key={item.name + index + 'd'} onMouseEnter={() => {this.mouseEnter(index)}} onMouseLeave={() => {this.mouseLeave(index)}}>
                                                        <div className={styles.center_main_item_list_info_txt}>
                                                            <div style={{color: item.font_color, fontSize: item.font_size, fontWeight: item.weigh === 1 ? 'bold' : 'normal'}}>{item.name}</div>
                                                            <div className={styles.center_main_item_list_info_txt_tip}>
                                                                <img src={item.icon} className={styles.center_main_item_list_info_txt_tip_img} />
                                                                {
                                                                    item['is_edit'] === 1 && (
                                                                        <img src={decoration_wode_edit} className={styles.center_main_item_list_info_txt_imgedit} onClick={(e: any) => {this.onUpdateModuleList(e,index)}} />
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <img src={arrows} className={styles.center_main_item_list_info_img} />
                                                        </div>
                                                    </div>
                                                )
                                            )
                                        })
                                    }
                                </div>
                            )
                        }
                    </div>
                </div>
                <div style={{textAlign: 'center'}}>v1.0.0</div>
                <div className={styles.center_footer}>
                    <img src={decoration_wode_footer} className={styles.center_footer_img} />
                </div>
            </div>
        )
    }
}

export default DecorationCenter