import { AppStore } from './app.store';
import {AccountAuth} from "./accountAuth.store";
import { UserGroupStore } from './userGroup.store';

interface Stores {
  [key: string]: any;
}

export const stores: Stores = {
  appStore: new AppStore(),
  accountAuthStore: new AccountAuth(),
  userGroupStore: new UserGroupStore()
}