import React, { useEffect, useState } from 'react'
import { Icon, Row, Col, message, Modal, Button } from 'antd'
import { getRequest } from '../../api/http'
import { apiUrl } from '../../api/api_url'
import './windowManage.scss'
import { useHistory } from 'react-router-dom';

const MergeTable = ()=>{
    const history:any = useHistory()
    const [windowList,setWindowList] = useState([])

    useEffect(()=> {
        windowListRequest(2)
    },[])

    const buildType = process.env.REACT_APP_ChromeApp;
    const [divisible_num_class, setDivisible_num_class] = useState('window_5'); //每一排显示的个数--对应的类名

    //餐桌list
    const windowListRequest = async (status:number) => {
        let table_id = history.location.state.table_id;
        const res:any = await getRequest(`${apiUrl.regionTableList}`,{
            status,
            pageSize:100,
            table_id
        });
        if(res.code === 1){
            let list = res.data.list;
            let table_count = res.data.line_show_table_count;
            list.map((item: any) => {
                item.active = false;
            })
            let arr = [];
            let len = 6;

            //根据餐桌来自动调整每一排显示个数
            if(table_count == 5) {
                len = 5;
                if(buildType === '1') {
                    setDivisible_num_class('window_5_waiter')
                } else {
                    setDivisible_num_class('window_5')
                }
            } else if(table_count == 6) {
                len = 6;
                if(buildType === '1') {
                    setDivisible_num_class('window_6_waiter')
                } else {
                    setDivisible_num_class('window_6')
                }
            } else if(table_count == 7) {
                len = 7;
                if(buildType === '1') {
                    setDivisible_num_class('window_7_waiter')
                } else {
                    setDivisible_num_class('window_7')
                }
            } else {
                len = 8;
                if(buildType === '1') {
                    setDivisible_num_class('window_8_waiter')
                } else {
                    setDivisible_num_class('window_8')
                }
            }

            if(list && list.length > 1){
                let num = list.length % len;
                for(let i=0; i< len-num;i++){
                    arr.push({'show': true})
                }
            }
            list = list.concat(arr);
            setWindowList(list)
        }
    }

    const onTableChange = (index:number) => {
        let list:any = windowList;
        if(list[index].code){
            if(list[index]['active'] === true){
                list[index]['active'] = false;
            }else{
                list[index]['active'] = true;
            }

            setWindowList(list)
            refreshAction()
        }else{
            message.error('此桌未下单，暂不能合并');
        }
    }

    //用于刷新的方法
    const [refresh,setRefresh] = useState(false);
    const refreshAction = () => {
        if(refresh === true){
            setRefresh(false)
        }else{
            setRefresh(true)
        }
    }

    const mergeBtn = () => {
        let codes = [history.location.state.code];
        let desks = [history.location.state.tableName];
        windowList.map((item: any) => {
            if(item.active){
                codes.push(item.code);
                desks.push(item.name);
            }
        })
        if(codes.length <= 1){
            message.error('请至少选择一个桌合并');
            return;
        }
        let name = desks;
        name.splice(0,1);

        console.log('location.state', history.location.state);

        // 判断是否为合并订单
        if (typeof history.location.state.type === 'string' && history.location.state.type === 'order') {
            Modal.confirm({
                title: '提示',
                content: `确认合并？订单合并后不可撤销，请谨慎操作！`,
                okText: '确认',
                cancelText: '取消',
                onOk: async () => {
                    const res:any = await getRequest(`${apiUrl.mergeOrder}`,{
                        code: history.location.state.code,
                        codes: codes.join(','),
                    });
                    if(res.code === 1){
                        history.push({
                            // 跳到实时桌面
                            pathname: '/sidebar/windowManage'
                        })
                    }
                },
                onCancel() {
                },
            });
        } else {
            // 合桌
            Modal.confirm({
                title: '提示',
                content: `确定将桌号${history.location.state.tableName}与${name.join(', ')}合并结账`,
                okText: '确认',
                cancelText: '取消',
                onOk: async () => {
                    history.push({
                        pathname: '/sidebar/settleAccounts',
                        state: {
                            codes: codes.join(','),
                            code: history.location.state.code,
                            desks,
                            tableKey: history.location.state.tableKey,
                            tableName: history.location.state.tableName,
                            table_id: history.location.state.table_id,
                            tableId: history.location.state.tableKey,
                            is_eating: history.location.state.is_eating,
                            userAmount: history.location.state.userAmount,
                            dineKey: history.location.state.tableKey
                        }
                    })
                },
                onCancel() {
                },
            });
        }
    }

    return (
        <div className="windowManage l_content">
            <Row type='flex' align='middle' className='mb30'>
                <Col span={2}>
                    <div onClick={() => {history.goBack()}}>
                        <Button type='primary' icon='left' size='large' style={{fontWeight: 'bold'}}>返回</Button>
                    </div>
                </Col>
                <Col className='table_info'>
                    <div>合并到</div>
                </Col>
            </Row>
            <div className="content">
                {
                    windowList.length > 0
                    ? windowList.map((item:any, index: number) => {
                        return (
                            !item.show
                            ? <div className={item.is_eating ? `${divisible_num_class} full` : `${divisible_num_class}`}
                            style={{position: 'relative', background: item.corlour_value}}
                            onClick={()=>{onTableChange(index)}}
                            key={item.id}
                            >
                            {
                                item.active
                                ? <div className='table_active'>
                                    <Icon type="check-circle" theme="filled" style={{fontSize: 50, color: 'white'}}/>
                                </div>
                                : null
                            }

                            <div className="table">
                                <div className="left">{item.name}</div>
                                <div className="right">{item.user_amount}/{item.people_num}人</div>
                            </div>
                            {
                                item.is_eating ?
                                <div className="price">¥{item.origin_fee}</div>
                                :
                                <div className="price"></div>
                            }
                            {
                                item.is_eating ?
                                <div className="time">
                                    <div className="open">开</div>
                                    <div className="time">{item.eat_time}</div>
                                </div>
                                :
                                <div className="time"></div>
                            }
                        </div>
                        : <div className={`${divisible_num_class} window_empty`}></div>
                        )
                    })
                    : <div style={{textAlign:'center', width: "100%", margin:'50px 0', fontSize: '20px'}}>暂无可合并</div>

                }
            </div>
            {
                windowList.length > 0
                ? <div className='merge_btn' onClick={() => mergeBtn()}>确定</div>
                : null
            }

        </div>
    )
}

export default MergeTable
