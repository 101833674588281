import React, { useEffect, useState } from 'react'
import './memberList.scss'
import { Row, Col, Button, Select, DatePicker, Table, Avatar, Upload, Modal, Radio, Input, message, Tooltip, Popconfirm, Switch } from 'antd';
import Search from 'antd/lib/input/Search';
import { getRequest, postRequest } from '../../../api/http';
import { apiUrl } from '../../../api/api_url';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';

import btn_add from '../../../assret/images/btn_add.png';
import goodsExport from '../../../assret/images/goodsExport.png'
import goodsImport from '../../../assret/images/goodsImport.png'
import index3 from '../../../assret/images/index3.png'
import close from '../../../assret/images/close.png'
import t_edit from '../../../assret/images/t_edit.png'
import win_price from '../../../assret/images/win_price.png'
import usergroup_select from '../../../assret/images/new_detail.png'
import new_price from '../../../assret/images/new_price.png'
import new_dongjie from '../../../assret/images/new_dongjie.png'
import new_jiedong from '../../../assret/images/new_jiedong.png'
import new_zhuxiao from '../../../assret/images/new_zhuxiao.png'


import './memberList.scss'
import { getIsPermisson, messageCustom } from '../../../utils/permisstionUtil';

const { Option } = Select;
const { RangePicker } = DatePicker;
interface IProps {
    search?: any
    onRefMulti?: Function
}

const QINIU_SERVER = 'https://up-z2.qiniup.com';
const key = 'updatable';
const baseUrl = 'https://ydn-siyu.cdn.shuachi.com/';

/**
 * 会员列表
 */
// let times:any = [];      // 时间区间
let tel = '';
const MemberList = (props: IProps) => {
    const history = useHistory()
    const location:any = useLocation()

    const [memberList,setMemberList] = useState([]);       // 公众号数组
    const [count,setCount] = useState(0);                  // 总数量
    const [levelList,setLevelList] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
    const [groupList,setGroupList] = useState([]);
    const [recommendGroupList,setRecommendGroupList] = useState([]);
    const [siyuGroupList,setSiyuGroupList] = useState([]);
    const [userOver, setUserOver] = useState(false); //是否有调整余额权限
    const [userOverPop, setUserOverPop] = useState(false); //是否有调整余额权限
    const [adjustment, setAdjustment] = useState('add'); //调整金额
    const [fullFilling, setFullFilling] = useState(''); //调整金额
    const [userInfo, setUserInfo] = useState<any>({}); //用户信息对象
    const [changeRechargeFee, setChangeRechargeFee] = useState(''); //调整实充
    const [changePresentFee, setChangePresentFee] = useState(''); //调整赠送
    const [changeCommissionFee, setChangeCommissionFee] = useState(''); //调整奖励
    const [changeReason, setChangeReason] = useState(''); //调整原因
    const [paramsObj, setParamsObj] = useState<any>({
        page: 1,
        pageSize: 10,
    });
    const [addVip, setAddVip] = useState(false); //新增会员
    const [addVipEdit, setAddVipEdit] = useState(false); //编辑会员
    const [sex, setSex] = useState(0)
    const [mobile, setMobile] = useState('')
    const [vipNum, setVipNum] = useState('')
    const [birthday, setBirthday] = useState('')

    const [store_export_file, setStoreExportFile] = useState(false);
    const [model_url, setModelUrl] = useState('');
    const [ownId, setOwnId] = useState(0) //当前编辑的会员列表id
    const [phone_num, setPhoneNum] = useState('')
    

  // 相当于 componentDidMount 和 componentDidUpdate:
    useEffect(() =>{
        if(location.state&&location.state.recommend_group_id) paramsObj.recommend_group = location.state.recommend_group_id
        if(location.state&&location.state.siyu_group) paramsObj.siyu_group = location.state.siyu_group
        if(location.state&&location.state.search) paramsObj.search = location.state.search
        props.onRefMulti && props.onRefMulti(requestGetMemberList)
        requestGetMemberList({...paramsObj});
        getMemberLeverList();
        getGroupList();
        getRecommendGroupList();
        getSiyuGroupList();

        return () => {
            tel = ''
            setPhoneNum(tel)
        }

    },[]);
   // 获取会员列表
   const requestGetMemberList = async (data = { }) => {
       console.log(data)
        setIsLoading(true);
       let param:any = {
           ...data,
       }
       let search_data:any = data;
       console.log('tel:',tel)
       if(!!search_data.search || tel != '') {
            param.search = !!search_data.search ? search_data.search : tel;
            if(tel == '') {
                tel = search_data.search
                setPhoneNum(tel)
            }
       }
       if(search_data.search == '') {
            setPhoneNum('')
            tel = '';
            param.search = ''
       }
       if(param.sortType) param.sortType = param.sortType=='ascend'?'asc':'desc'
       Object.keys(param).map(el => {
           if(!param[el]) delete param[el]
       })
       setParamsObj({...param})
       let group_arr = []
       if(param.group){
            group_arr.push(param.group)
            delete param.group
       }
       if(param.recommend_group){
            group_arr.push(param.recommend_group)
            delete param.recommend_group
       }
    //    if(param.siyu_group) {
    //         group_arr.push(param.siyu_group)
    //         delete param.siyu_group
    //    }
       if(group_arr.length>0) param.group = group_arr.join(',')
        // setMemberList([])
        const res:any = await getRequest(apiUrl.vipList,param);
        if(res.code === 1){
            setMemberList(res.data.list);
            setCount(res.data.total);
            setIsLoading(false);
            setUserOver(res.data.user_over)
            setModelUrl(res.data.model_url)
        }
   };

   // 获取自定义分组列表
   const getGroupList = async() =>{
       const res:any = await getRequest(apiUrl.groupList,{groupType:1});
       if(res.code === 1){
           setGroupList(res.data.list);
       }
   }
   // 获取推荐分组列表
   const getRecommendGroupList = async() =>{
       const res:any = await getRequest(apiUrl.groupList,{groupType:2});
       if(res.code === 1){
            setRecommendGroupList(res.data.list);
       }
   }
   // 获取私域流量分组列表
   const getSiyuGroupList = async() =>{
       const res:any = await getRequest(apiUrl.getAllPrivate);
       if(res.code === 1){
            setSiyuGroupList(res.data);
       }
   }
   // 获取会员等级列表
   const getMemberLeverList =  async () =>{
       const res: any = await getRequest(apiUrl.fansLevelList);
       if(res.code === 1){
        setLevelList(res.data);
       }
   };

   //导出
    const exportTable = async () => {
        if(!getIsPermisson('1_6_1_5')){
            messageCustom();
            return;
        }
        const token = localStorage.getItem("token");
        const shop_id = localStorage.getItem("shop_id");
        let url = `${process.env.REACT_APP_BASEURL}/v2/vip/export?token=${token}&shop_id=${shop_id}`
        Object.keys(paramsObj).map((key:any)=>{
            if(key!='page'&&key!='pageSize'&&key!='group'&&key!='recommend_group'){
                url+='&'+key+'='+paramsObj[key]
            }
        })

        let group_arr = []
        if(paramsObj.group){
            group_arr.push(paramsObj.group)
        }
        if(location.state&&location.state.recommend_group_id){
            group_arr.push(location.state.recommend_group_id)
        }
        //  if(location.state&&location.state.siyu_group) {
        //       group_arr.push(location.state.siyu_group)
        //  }
        if(group_arr.length>0) url+='&group='+group_arr.join(',')
        //  return false
        window.open(url);
    };


    const handleTableChange = (pagination:any, filters:any, sorter:any) => {
        requestGetMemberList({
            ...paramsObj,
            page:pagination.current,
            pageSize:pagination.pageSize,
            sortField:sorter.field,
            sortType:sorter.order
        })
    };

    const createTable = () =>{
        const columns:any = [
            {
                title: '序号',
                render:(text: any,record: any,index:number)=>`${index+1}`,
                fixed: 'left',
                width: 50,
                className: 'list_center',
            },
            {
                title: '会员卡号',
                dataIndex: 'vip_num',
                key: 'vip_num',
                className: 'list_center',
            },
            {
                title: '用户昵称',
                dataIndex: 'nickname',
                key: 'nickname',
                className: 'list_center',
                render: (text: string,record:any,index:number) =>
                <Row className="" justify="start" align="middle">
                    <div><Avatar src={record.headimgurl} /></div>
                    <div>{record.nickname}</div>
                </Row>
            },
            {
                title: '用户等级',
                dataIndex: 'level',
                key: 'level',
                className: 'list_center',
            },
            {
                title: '手机号码',
                dataIndex: 'mobile',
                className: 'list_center',
                key: 'mobile'
            },
            {
                title: '性别',
                dataIndex: 'sex',
                className: 'list_center',
                render: (text: string,record:any,index:number) =>
                <div>
                   {record.sex == 0 ? '未知' : record.sex ===1 ? '男': '女'}
                </div>
            },
            {
                title: '生日',
                dataIndex: 'birthday',
                className: 'list_center',
                key: 'birthday'
            },
            {
                title: '账户余额（元）',
                dataIndex: 'balance',
                key: 'balance',
                className: 'list_center',
                sorter:true
            },
            {
                title: '积分',
                dataIndex: 'score',
                key: 'score',
                className: 'list_center',
                sorter:true
            },
            {
                title: '状态',
                dataIndex: 'status',
                className: 'list_center',
                render: (text: string,record:any,index:number) =>
                <div>
                   {record.status == 0 ? '注销' : record.status ===1 ? '正常': '冻结'}
                </div>
            },
            {
                title: '注册时间',
                dataIndex: 'created_at',
                key: 'created_at',
                className: 'list_center',
                sorter:true
            },
            // {
            //     title: '用户分组',
            //     dataIndex: 'group_name',
            //     key: 'group_name',
            //     render: (text: any,record:any,index:number) =>{
            //         return text ?
            //         <Tooltip title={text.join('、')}>
            //             {
            //                 text[0]+'...'
            //             }
            //         </Tooltip>
            //         : '--'}

            // },
            // {
            //     title: '就餐次数',
            //     dataIndex: 'eat_times',
            //     key: 'eat_times',
            //     sorter:true
            // },
            // {
            //     title: '消费次数',
            //     dataIndex: 'comsumer_times',
            //     key: 'comsumer_times',
            //     sorter:true
            // },
            // {
            //     title: '累计消费（元）',
            //     dataIndex: 'actual_fee',
            //     key: 'actual_fee',
            //     sorter:true
            // },
            // {
            //     title: '充值次数',
            //     dataIndex: 'recharge_num',
            //     key: 'recharge_num',
            //     sorter:true
            // },
            // {
            //     title: '成功邀请人',
            //     dataIndex: 'invite_num',
            //     key: 'invite_num',
            //     sorter:true
            // },
            // {
            //     title: '邀请奖励金额',
            //     dataIndex: 'invite_money',
            //     key: 'invite_money',
            //     sorter:true
            // },
            // {
            //     title: '注册时间',
            //     dataIndex: 'create_time',
            //     key: 'create_time',
            //     sorter:true
            // },
            // {
            //     title: '上次消费时间',
            //     dataIndex: 'last_comsumer_time',
            //     key: 'last_comsumer_time',
            //     sorter:true
            // },
            {
                title: '操作',
                dataIndex: 'option',
                key: 'option',
                className: 'list_center',
                width: 150,
                render: (text: string, record:any, index:number) =>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Tooltip placement="bottomRight" title={'编辑'}>
                            <img src={t_edit} className='table_icon' style={{width: '31px', height: '31px'}} onClick={() => { onEditVipUser(record) }}></img>
                        </Tooltip>
                        {
                           userOver
                           ?    record.mobile && record.nickname
                                ?   <Tooltip placement="bottomRight" title={'调整余额'}>
                                        <img src={new_price} className='table_icon' onClick={() => { onChangePricePop(record) }}></img>
                                    </Tooltip>
                                : ''
                           : ''
                        }
                        {
                            record.mobile && record.nickname
                            ?   <Tooltip placement="bottomRight" title={'详情'}>
                                    <img src={usergroup_select} className='table_icon' style={{width: '31px', height: '31px'}} onClick={() => {
                                        if(!getIsPermisson('1_6_1_3')){
                                            messageCustom();
                                            return;
                                        }
                                        history.push({pathname: "/sidebar/memberDetail", state: {id: record.uid }})
                                    }}></img>
                                </Tooltip>
                            :   ''
                        }
                        <Popconfirm
                            placement="bottomRight"
                            title={record.status == 1 ? '是否冻结？' : '是否解冻？'}
                            onConfirm={()=>{onOpenStatus(record)}}
                            okText="Yes"
                            cancelText="No"
                        >
                            <span style={{cursor: 'pointer',color: '#3975FF', display: 'flex', alignItems: 'center'}}>
                                {
                                    record.status == 2
                                    ? <img className="table_icon" src={new_dongjie} />
                                    : <img className="table_icon" src={new_jiedong} />
                                }
                                {/* <Switch checked={record.status == 1 ? true : false} size='small' style={{marginRight: '5px'}} />
                                {
                                    record.status == 1
                                    ? '启用'
                                    : '停用'
                                } */}
                            </span>
                        </Popconfirm>
                    </div>
            },
        ];

        return <Table
            rowSelection={rowSelection}
            dataSource={memberList}
            columns={columns}
            rowKey={"id"}
            loading={isLoading}
            size="middle"
            onChange={handleTableChange}
            // scroll={{ x: 2000 }}
            pagination={{
                total: count,
                current:paramsObj.page,
                pageSize: paramsObj.pageSize,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (total: number) => `共${total}条`,
            }}
        />;
    }

    const onOpenStatus = async (rowdata: any) => {
        if(!getIsPermisson('1_6_1_6')){
            messageCustom();
            return;
        }
        const res:any = await postRequest(apiUrl.vipSetStatus, {
          ids: [rowdata.id],
          status: rowdata.status == 1 ? 2 : 1
        });
        if(res && res.data) {
           console.log('res', res);
           message.success('操作成功');
           requestGetMemberList()
        }
    }

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const rowSelection: any = {
        onChange: (selectedRowKeys: any, selectedRows: any) => {
            setSelectedRowKeys(selectedRowKeys)
            //this.onBatchMust(selectedRowKeys)
            console.log(selectedRowKeys)
        }
    };

    //调整余额提交
    const closeStoreActionOK = async (e:any) => {
        let param: any = { fan_id: userInfo.uid, status: adjustment }

        if(changeRechargeFee == '' && changePresentFee == '' && changeCommissionFee == '') {
            message.warn('请填写正确的数据！')
            return false;
        }

        if(changeRechargeFee != '' && Number(changeRechargeFee) <= 0) {
            message.warn('输入的金额必须大于0！')
            return false;
        }
        if(changeReason == '') {
            message.warn('请输入调整原因！')
            return false;
        }
        if(changePresentFee != '' && Number(changePresentFee) <= 0) {
            message.warn('输入的金额必须大于0！')
            return false;
        } else {
            param.present_fee = changePresentFee;
        }
        if(changeCommissionFee != '' && Number(changeCommissionFee) <= 0) {
            message.warn('输入的金额必须大于0！')
            return false;
        } else {
            param.commission_fee = changeCommissionFee;
        }

        param.recharge_fee = changeRechargeFee;
        param.reason = changeReason;

        const res: any = await postRequest(apiUrl.setUserProperty, param)
        if(res.code == 1) {
            setUserOverPop(false)
            setChangeRechargeFee('')
            setChangePresentFee('')
            setChangeCommissionFee('')
            setChangeReason('')
            message.success("操作成功")
        } else {
            message.error(res.message)
        }
    }

    const changeAdjustment = (e: any) => {
        setAdjustment(e)
    }

    //调整余额弹窗
    const onChangePricePop = async (e: any) => {
        if(!getIsPermisson('1_6_1_2')){
            messageCustom();
            return;
        }
        setUserOverPop(true)
        console.log(e)
        const res: any = await getRequest(apiUrl.getUserProperty, { fan_id: e.uid })
        let obj = Object.assign(e,res.data);
        setUserInfo({...obj});
    }


    // 就餐人数修改
    const numChange = (e:any) => {
        let name: any = e.target.name;
        let val: any = e.target.value;
        if(name == 'recharge_fee') {
            setChangeRechargeFee(val)
        }
        if(name == 'present_fee') {
            setChangePresentFee(val)
        }
        if(name == 'commission_fee') {
            setChangeCommissionFee(val)
        }
        if(name == 'reason') {
            setChangeReason(val)
        }
    }

    const addPriceSpace = () => {

    }

    //新增会员
    const addVipUser = () => {
        if(!getIsPermisson('1_6_1_4')){
            messageCustom();
            return;
        }
        setSex(0)
        setSex(-1)
        setVipNum('')
        setBirthday('')
        setMobile('')
        setAddVip(true)
    }

    //新增会员--确定
    const closeVipActionOK = async () => {
        if(vipNum == '') {
            return message.warn('请输入会员卡号')
        }
        let params = {
            mobile: mobile,
            vip_num: vipNum,
            sex: sex == -1 ? 0 : sex,
            birthday: birthday
        }
        let res: any = await postRequest(apiUrl.vipAdd, params)
        if(res.code == 1) {
            setAddVip(false)
            requestGetMemberList()
            message.success('操作成功！')
        } else {
            message.error(res.message)
        }
    }

    //编辑会员--确定
    const closeVipEditActionOK = async () => {
        let params: any = {
            id: ownId,
            mobile: mobile,
            vip_num: vipNum,
            sex: sex,
            birthday: birthday
        }
        let res: any = await postRequest(apiUrl.vipEdit,params)
        if(res.code == 1) {
            setAddVipEdit(false)
            requestGetMemberList()
            message.success('操作成功！')
        } else {
            message.error({ content: res.message, key, duration: 2 });
        }
    }

    const changeCurrency = (e: any, type: any) => {
        let val = e.target.value;
        if(type == 'mobile') {
            setMobile(val);
        }
        if(type == 'vip_num') {
            setVipNum(val);
        }
    }

    const handleChange = (e: any) => {
        setSex(e)
    }

    //生日选择
    const onChangeDatePicker = (date: any, dateString: any) => {
        //console.log(dateString);
        setBirthday(dateString)
    }

    //下载模版
    const onDownloadTemplate = async () => {
        window.open(`${model_url}`)
    }

    const beforeUploadFile = (file: any) => {
        setFileData(file)
        return false;
    }

    //删除文件
    const onRemove = (file: any) => {
        // this.setState({
        //     statusCount: false
        // })
    }

    //打开导入商品弹窗
    const onStoreExportFile = () => {
        if(!getIsPermisson('1_6_1_5')){
            messageCustom();
            return;
        }
       setStoreExportFile(!store_export_file)
    }

    //上传文件
    const [file_data, setFileData] = useState('') //文件流goods_file_active: 0,
    const [statusCount, setStatusCount] = useState(false); //文件流
    const [allcount, setAllcount] = useState(0) // 总上传个数
    const [successCount, setSuccessCount] = useState(0) //  成功上传个数
    const customRequest = () => {
        message.loading({ content: '上传中', key });
        let formData = new FormData();
        //let _this = this;
        let files: any = file_data;
        formData.append('file', files);
        formData.append('shop_id', String(localStorage.getItem('shop_id')));

        let request: any = new XMLHttpRequest();
        request.open('POST', `${process.env.REACT_APP_BASEURL+'/'+apiUrl.vipImport}?token=${localStorage.getItem('token')}`);
        request.send(formData);
        request.onload = function(e: any){
            if (request.status === 200) {
                // console.log(request)
                // console.log(_this.setState)
                let result = JSON.parse(request.response)
                setStatusCount(true)
                setAllcount(result.data.count)
                setSuccessCount(result.data.successCount)
                requestGetMemberList();
                message.success({ content: '上传成功!', key, duration: 2 });
            }
            else{
                //alert('上传失败，请重试！');
                message.error({ content: '上传失败，请重试', key, duration: 2 });
            }
        }
        request.onerror = function(e: any){
            //alert('请求失败')
            message.error({ content: '请求失败，请重试', key, duration: 2 });
        }
        return {
            abort() {
              console.log('upload progress is aborted.');
            },
        };
    }

    //冻结会员
    const onFrozen = async (type: any) => {
       
        //selectedRowKeys
        //0：注销，1：正常，2：冻结
        let params: any = {
            status: '',
            ids: selectedRowKeys
        }
        if(type == 'frozen') {
            params.status = 2
        }
        if(type == 'untie') {
            params.status = 1
        }
        if(type == 'cancellation') {
            if(!getIsPermisson('1_6_1_7')){
                messageCustom();
                return;
            }
            params.status = 0
        }else{
            if(!getIsPermisson('1_6_1_6')){
                messageCustom();
                return;
            }
        }
        if(!selectedRowKeys) {
            return;
        }
        if(selectedRowKeys.length == 0) {
            return message.warn({ content: '请选择会员！', key, duration: 2 })
        }
        let content = type == 'frozen' ? '是否冻结会员？' : type == 'untie' ? '是否解冻会员？' : '是否注销会员？'
        Modal.confirm({
            title: '提示',
            content: content,
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                let res: any = await postRequest(apiUrl.vipSetStatus, params)
                if (res.code === 1) {
                    message.success({ content: '设置成功!', key, duration: 2 });
                    requestGetMemberList()
                } else {
                    message.error({ content: '设置失败!', key, duration: 2 });
                }
            },
        });
    }

    //编辑会员
    const onEditVipUser = async (item: any) => {
        if(!getIsPermisson('1_6_1_8')){
            messageCustom();
            return;
        }
        console.log(item.birthday)
        setSex(item.sex)
        setVipNum(item.vip_num)
        setBirthday(item.birthday)
        setMobile(item.mobile)
        setOwnId(item.id)
        setAddVipEdit(true)
    }

    return <div>
            <div className="vipList_topbtn">
                <div className='new_btn' onClick={() => { addVipUser() }}>
                    <img src={btn_add} />
                    新增会员
                </div>
                <div className='new_btn' onClick={() => { onStoreExportFile() }}>
                    <img src={goodsImport} />
                    导入会员
                </div>
                <div className='new_btn' onClick={()=>{exportTable()}}>
                    <img src={goodsExport} />
                    导出会员
                </div>
                <div className='new_btn' onClick={() => { onFrozen('frozen') }}>
                    <img src={new_dongjie} />
                    冻 结
                </div>
                <div className='new_btn' onClick={() => { onFrozen('untie') }}>
                    <img src={new_jiedong} />
                    解 冻
                </div>
                <div className='new_btn' onClick={()=>{ onFrozen('cancellation')}}>
                    <img src={new_zhuxiao} />
                    注 销
                </div>
            </div>
            <div className="member_view l_content">
            <div className="select_group">
                <Row className="mb20">
                    <Col span={4}>
                        <Search
                            value={phone_num == '' ? '' : phone_num}
                            allowClear
                            placeholder="请输入用户昵称/手机号/会员卡号"
                            onChange={e => {
                                let tel = e.target.value;
                                setPhoneNum(tel)
                            }}
                            onSearch={value => {
                                requestGetMemberList({ ...paramsObj, page:1, search: value });
                            }}
                        />
                    </Col>
                    <Col span={4} >
                        <div className="select_item">
                            <div>用户等级：</div>
                            <Select allowClear style={{ width: 150 }} placeholder="请选择用户等级" onChange={(e: any) => {
                                requestGetMemberList({ ...paramsObj, page:1, level: e });
                            }}>
                                {
                                    levelList.map((item:any,index) =>{
                                        return <Option value={item.id} key={item.id+index}>{item.name}</Option>
                                    })
                                }
                            </Select>
                        </div>
                    </Col>
                    {/* <Col span={5} >
                        <div className="select_item">
                            <div>自定义分组：</div>
                            <Select allowClear style={{ width: 150 }} placeholder="请选择分组" onChange={(e: any) => {
                                requestGetMemberList({ ...paramsObj, page:1, group: e });
                            }}>
                                {
                                    groupList.map((item:any,index) =>{
                                        return <Option value={item.id} key={item.id+index}>{item.name}</Option>
                                    })
                                }
                            </Select>
                        </div>
                    </Col>
                    <Col span={5} >
                        <div className="select_item">
                            <div>推荐分组：</div>
                            <Select allowClear style={{ width: 150 }} placeholder="请选择分组" onChange={(e: any) => {
                                requestGetMemberList({ ...paramsObj, page:1, recommend_group: e });
                            }}>
                                {
                                    recommendGroupList.map((item:any,index) =>{
                                        return <Option value={item.id} key={item.id+index}>{item.name}</Option>
                                    })
                                }
                            </Select>
                        </div>
                    </Col> */}
                    {/* <Col span={5} >
                        <div className="select_item">
                            <div>私域流量分组：</div>
                            <Select style={{ width: 150 }} defaultValue={location.state&&location.state.siyu_group?location.state.siyu_group:0} onChange={(e: any) => {
                                requestGetMemberList({ ...paramsObj, page:1, siyu_group: e });
                            }}>
                                <Option value={0}>全部</Option>
                                {
                                    siyuGroupList.map((item:any,index) =>{
                                        return (
                                            <Option value={item.group_id} key={item.group_id}>
                                                <Tooltip title={item.channel_name+'-'+item.group_name}>
                                                    {item.channel_name}-{item.group_name}
                                                </Tooltip>
                                            </Option>
                                        )
                                    })
                                }
                            </Select>
                        </div>
                    </Col> */}
                </Row>
                <Row>
                    {/* <Col span={7}>
                        <div className="select_item" style={{justifyContent:'flex-start'}}>
                            <div>注册时间：</div>
                            <RangePicker
                                format={`YYYY-MM-DD`}
                                showTime={false}
                                onChange={(e:any, dateString) => {
                                    requestGetMemberList({...paramsObj, page:1, start_time:dateString[0], end_time:dateString[1]});
                                }}
                                placeholder={['开始时间', '结束时间']} />
                        </div>
                    </Col>
                    <Col span={7}>
                        <div className="select_item">
                            <div>上次消费时间：</div>
                            <RangePicker
                                format={`YYYY-MM-DD`}
                                showTime={false}
                                onChange={(e:any, dateString) => {
                                    requestGetMemberList({...paramsObj, page:1, last_start_time:dateString[0], last_end_time:dateString[1]});
                                }}
                                placeholder={['开始时间', '结束时间']} />
                        </div>
                    </Col> */}
                    {/* <Col span={4} >
                        <div className="select_item">
                            <div>会员等级：</div>
                            <Select allowClear style={{ width: 150 }} placeholder="请选择会员等级" onChange={(e: any) => {
                                requestGetMemberList({ ...paramsObj, page:1, level: e });
                            }}>
                                {
                                    levelList.map((item:any,index) =>{
                                        return <Option value={item.id} key={item.id+index}>{item.name}</Option>
                                    })
                                }
                            </Select>
                        </div>
                    </Col> */}
                    {/* <Col span={2}>
                        <Button icon="file-markdown" type='primary' onClick={()=>{requestGetMemberList({...paramsObj})}}>搜索</Button>
                    </Col>    
                    <Col span={2}>
                        <Button icon="file-markdown" type='primary' onClick={()=>{exportTable()}}>导出</Button>
                    </Col> */}
                </Row>
            </div>
            <Row className='all_user'>
                {/* 总用户数：{count} */}
                <Col className='vipList-left-title-item-list'>
                    <div className='vipList-left-title-main'>
                        <div className='vipList-left-title-main-top'>{count}</div>
                        <div className='vipList-left-title-main-bottom'>总用户数</div>
                        <div className='vipList-left-title-main-icon3'>
                            <img className='vipList-left-title-main-icon3-img' src={index3} />
                        </div>
                    </div>
                </Col>
            </Row>
            {createTable()}
            <Modal
                title="调整余额"
                visible={userOverPop}
                onOk={closeStoreActionOK}
                onCancel={() => {setUserOverPop(false)}}
                okText="确认"
                cancelText="取消"
                closable={false}
            >
                <div className="member_list_main">
                    <div className="member_list_main_top mb10">
                        <div className="member_list_main_top_img">
                            <img style={{width: '75px', height: '75px'}} src={userInfo.headimgurl} />
                        </div>
                        <div className="member_list_main_top_right">
                            <div className="member_list_main_top_right_name">{userInfo.nickname}</div>
                            <div className="member_list_main_top_right_phone">手机号：{userInfo.phone_number}</div>
                            <div className="member_list_main_top_right_price">可用余额：{userInfo.balance} （实充：{userInfo.recharge_fee}元，赠送{userInfo.present_fee}元，奖励{userInfo.commission_fee}元）</div>
                        </div>
                    </div>
                    <Row style={{display: 'flex', alignItems: 'center'}} className="mb10">
                        <Col span={4}>
                            <div>
                                <span className="color_black">选择方式</span>
                            </div>
                        </Col>
                        <Col span={20}>
                        <Radio.Group
                                value={adjustment}
                                onChange={(e) => changeAdjustment(e.target.value)}
                            >
                                <Radio value={'add'}>增加金额</Radio>
                                <Radio value={'reduce'}>减少金额</Radio>
                            </Radio.Group>
                        </Col>
                    </Row>
                    <Row style={{display: 'flex', alignItems: 'center'}} className="mb10">
                        <Col span={4}>
                            <div style={{marginLeft: '11px'}}>
                                <span className="color_black">调整实充</span>
                            </div>
                        </Col>
                        <Col span={20}>
                            <div>
                                <Input style={{width: '90%', marginRight: '5%'}} value={changeRechargeFee} name="recharge_fee" placeholder="" maxLength={10} onChange={(e) => numChange(e)} type='number'/>元
                            </div>
                        </Col>
                    </Row>
                    <Row style={{display: 'flex', alignItems: 'center'}} className="mb10">
                        <Col span={4}>
                            <div style={{marginLeft: '11px'}}>
                                <span className="color_black">调整赠送</span>
                            </div>
                        </Col>
                        <Col span={20}>
                            <div>
                                <Input style={{width: '90%', marginRight: '5%'}} value={changePresentFee} name="present_fee" placeholder="" maxLength={10} onChange={(e) => numChange(e)} type='number'/>元
                            </div>
                        </Col>
                    </Row>
                    <Row style={{display: 'flex', alignItems: 'center'}} className="mb10">
                        <Col span={4}>
                            <div style={{marginLeft: '11px'}}>
                                <span className="color_black">调整奖励</span>
                            </div>
                        </Col>
                        <Col span={20}>
                            <div>
                                <Input style={{width: '90%', marginRight: '5%'}} value={changeCommissionFee} name="commission_fee" placeholder="" maxLength={10} onChange={(e) => numChange(e)} type='number'/>元
                            </div>
                        </Col>
                    </Row>
                    <Row style={{display: 'flex', alignItems: 'center'}} className="mb10">
                        <Col span={4}>
                            <div>
                                <span className="star"></span>
                                <span className="color_black">调整原因</span>
                            </div>
                        </Col>
                        <Col span={20}>
                            <div>
                                <Input style={{width: '90%', marginRight: '5%'}} name="reason" value={changeReason} placeholder="" maxLength={10} onChange={(e) => numChange(e)}/>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </div>
        <Modal
            title="新增会员"
            visible={addVip}
            onOk={closeVipActionOK}
            onCancel={() => {setAddVip(false)}}
            okText="确认"
            cancelText="取消"
            closable={false}
            destroyOnClose={true}
        >
            <Row style={{display: 'flex', alignItems: 'center'}} className="mb10">
                <Col span={4}>
                    <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                        <span className=""> </span>
                        <span className="color_black">手机号</span>
                    </div>
                </Col>
                <Col span={18} offset={2}>
                    <div>
                        <Input type='number' style={{width: '90%', marginRight: '5%'}} name="mobile" value={mobile} placeholder="请输入手机号" maxLength={11} onChange={(e) => changeCurrency(e, 'mobile')}/>
                    </div>
                </Col>
            </Row>
            <Row style={{display: 'flex', alignItems: 'center'}} className="mb10">
                <Col span={4}>
                    <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                        <span className="star"> </span>
                        <span className="color_black">会员卡号</span>
                    </div>
                </Col>
                <Col span={18} offset={2}>
                    <div>
                        <Input type='number' style={{width: '90%', marginRight: '5%'}} name="vipNum" value={vipNum} placeholder="请输入会员卡号" onChange={(e) => changeCurrency(e, 'vip_num')}/>
                    </div>
                </Col>
            </Row>
            <Row style={{display: 'flex', alignItems: 'center'}} className="mb10">
                <Col span={4}>
                    <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                        <span className=""> </span>
                        <span className="color_black">性别</span>
                    </div>
                </Col>
                <Col span={18} offset={2}>
                    <Select size={'default'} defaultValue='请选择性别' onChange={handleChange} style={{width: '90%'}}>
                        <Option key='1'>男</Option>
                        <Option key='2'>女</Option>
                        <Option key='0'>未知</Option>
                    </Select>
                </Col>
            </Row>
            <Row style={{display: 'flex', alignItems: 'center'}} className="mb10">
                <Col span={4}>
                    <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                        <span className=""> </span>
                        <span className="color_black">生日</span>
                    </div>
                </Col>
                <Col span={18} offset={2}>
                    <DatePicker format={`YYYY-MM-DD`} onChange={(e: any, dataString: any) => {onChangeDatePicker(e, dataString)}} style={{width: '90%'}} />
                </Col>
            </Row>
        </Modal>
        <Modal
            title="编辑会员"
            visible={addVipEdit}
            onOk={closeVipEditActionOK}
            onCancel={() => {setAddVipEdit(false)}}
            okText="确认"
            cancelText="取消"
            closable={false}
        >
            <Row style={{display: 'flex', alignItems: 'center'}} className="mb10">
                <Col span={4}>
                    <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                        <span className=""> </span>
                        <span className="color_black">手机号</span>
                    </div>
                </Col>
                <Col span={18} offset={2}>
                    <div>
                        <Input style={{width: '90%', marginRight: '5%'}} name="mobile" value={mobile} placeholder="请输入手机号" maxLength={11} onChange={(e) => changeCurrency(e, 'mobile')}/>
                    </div>
                </Col>
            </Row>
            <Row style={{display: 'flex', alignItems: 'center'}} className="mb10">
                <Col span={4}>
                    <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                        <span className="star"> </span>
                        <span className="color_black">会员卡号</span>
                    </div>
                </Col>
                <Col span={18} offset={2}>
                    <div>
                        <Input style={{width: '90%', marginRight: '5%'}} name="vipNum" value={vipNum} placeholder="请输入会员卡号" onChange={(e) => changeCurrency(e, 'vip_num')}/>
                    </div>
                </Col>
            </Row>
            <Row style={{display: 'flex', alignItems: 'center'}} className="mb10">
                <Col span={4}>
                    <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                        <span className=""> </span>
                        <span className="color_black">性别</span>
                    </div>
                </Col>
                <Col span={18} offset={2}>
                    <Select size={'default'} defaultValue="请选择性别" value={String(sex)} onChange={handleChange} style={{width: '90%'}}>
                        <Option key='1'>男</Option>
                        <Option key='2'>女</Option>
                        <Option key='0'>未知</Option>
                    </Select>
                </Col>
            </Row>
            <Row style={{display: 'flex', alignItems: 'center'}} className="mb10">
                <Col span={4}>
                    <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                        <span className=""> </span>
                        <span className="color_black">生日</span>
                    </div>
                </Col>
                <Col span={18} offset={2}>
                    <DatePicker defaultValue={moment(birthday,'YYYY/MM/DD')} format={`YYYY-MM-DD`} onChange={(e: any, dataString: any) => {onChangeDatePicker(e, dataString)}} style={{width: '90%'}} />
                </Col>
            </Row>
        </Modal>
        {/* 普通商品导入 */}
        {
            store_export_file
            ? <div className="vipList">
                <div className="vipList-main">
                    <div className="vipList-main-title">
                        导入会员
                    </div>
                    <div className="vipList-main-tip">
                        <div className="vipList-main-tip-two">
                            <Upload
                                beforeUpload={beforeUploadFile}
                                onRemove={onRemove}
                                // customRequest={this.customRequest}
                            >
                                <div className="vipList-main-tip-two-main">
                                    <div className="vipList-main-tip-two-main-left">
                                        请选择导入的文件
                                    </div>
                                    <div className="vipList-main-tip-two-main-right">
                                        选择文件
                                    </div>
                                </div>
                            </Upload>
                            {
                                statusCount
                                ?   <div>
                                        <span>总上传个数：<span style={{color: '#FF5166'}}>{allcount}</span></span>，
                                        <span>上传成功个数: <span style={{color: '#FF5166'}}>{successCount}</span></span>
                                    </div>
                                : ''
                            }
                            <div className="vipList-main-tip-first">
                                <span className="vipList-main-tip-first-demo" onClick={() => {onDownloadTemplate()}}>下载模版</span>
                                <span className="vipList-main-tip-first-txt">（请按照模板中的要求整理会员信息）</span>
                            </div>
                            {/* <div className="vipList-main-tip-two-rule">
                                导入文件为.xls的excel文件
                            </div> */}
                        </div>
                    </div>
                    <div className="vipList-main-btn">
                        <div className="vipList-main-btn-cancel" onClick={() => {onStoreExportFile()}}>取消</div>
                        <div className="vipList-main-btn-up" onClick={customRequest}>上传</div>
                    </div>
                    <div className="vipList-main-close" onClick={() => {onStoreExportFile()}}>
                        <img src={close} alt=""/>
                    </div>
                </div>
            </div>
            : ''
        }
    </div>
}

export default MemberList;
