import React, { useEffect, useState } from 'react'
import { Row, Button, Input, Table, message, Modal, Tag, Tabs, DatePicker, Tooltip } from 'antd'
import { useHistory, useLocation } from 'react-router-dom';
import { getRequest, postRequest } from '../../../../../api/http'
import { apiUrl } from '../../../../../api/api_url'
import t_edit from '../../../../../assret/images/t_edit.png'
import t_delete from '../../../../../assret/images/t_delete.png'
import t_info from '../../../../../assret/images/t_info.png'
import './detail.scss'

const { RangePicker } = DatePicker;
const { Search } = Input;

const SiyuGroupDetail = ()=>{

    // const channelList = ['社群用户', '公众号', '个人微信', '小程序会员' ]
    // const [ channelList, setChangeList ] = useState<any>([])
    const [ title, setTitle ] = useState('')
    const history = useHistory()
    const location:any = useLocation()
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [paramsObj, setParamsObj] = useState<any>({
        current: 1,
        pageSize: 10,
    });

    useEffect(() => {
        if(location.state&&location.state.channel_id) paramsObj.channel_id = location.state.channel_id
        _init()
        getGroupList({...paramsObj})
    }, [])

    const _init = async() => {
        const res:any = await getRequest(apiUrl.getPrivateGroup)
        if(res&&res.code==1&&res.data){
            const arr:any = location.state&&location.state.channel_id?res.data.filter((item:any)=>item.channel_id==location.state.channel_id):[]
            if(arr.length>0) setTitle(arr[0].name)
        }
    }
    const getGroupList = async (data:any = {}) => {
        let param = {
            ...data
        }
        if(param.order_by)param.order_by=param.order_by=='ascend'?'asc':'desc'
        Object.keys(param).map(el => {
            if(!param[el]) delete param[el]
        })
        setParamsObj({...param})
        const res:any = await postRequest(apiUrl.usergroupList, param);
        if(res && res['code'] === 1) {
            setData(res.data.group_list);
            setTotal(res.data.group_count)
        }
    }

    const handleTableChange = (pagination:any, filters:any, sorter:any) => {
        getGroupList({
            ...paramsObj,
            current:pagination.current,
            pageSize:pagination.pageSize,
            order_field:sorter.field,
            order_by:sorter.order
        })
    }

    const searchGroup = (value:string) => {
        getGroupList({
            ...paramsObj,
            name:value,
            current:1,
        })
    }

    const deleteList = (record: any) => {
        Modal.confirm({
            title: '删除',
            content: '确定删除该分群？删除后，将解散该分群的用户',
            onOk: async () => {
                let res: any = await postRequest(apiUrl.groupDelete, {group_id: record.id})
                if(res.code == 1){
                    message.success("删除成功")
                    getGroupList({...paramsObj})
                }
            }
        })
    }

    const creatTable = ()=>{
        const columns:any = [
            {
                title: '分组名称',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '分组人数',
                dataIndex: 'member_num',
                key: 'member_num',
                sorter:true,
                render:( text:string, record:any, index:number )=> <Button type="link"  onClick={()=>{history.push('/sidebar/userGroupDetail',{id:record.id})}}>{text}</Button>
            },
            {
                title: '忠实用户',
                dataIndex: 'zhongshi_num',
                key: 'zhongshi_num',
                sorter:true,
                render:( text:string, record:any, index:number )=> <Button type="link"  onClick={()=>{history.push('/sidebar/userGroupDetail',{id:record.id,type:1})}}>{text}</Button>
            },
            {
                title: '消费人数',
                dataIndex: 'consumer_member_num',
                key: 'consumer_member_num',
                sorter:true,
                render:( text:string, record:any, index:number )=> <Button type="link"  onClick={()=>{history.push('/sidebar/userGroupDetail',{id:record.id,type:2})}}>{text}</Button>
            },
            {
                title: '就餐人数',
                dataIndex: 'eat_times',
                key: 'eat_times',
                sorter:true,
                render:( text:string, record:any, index:number )=> <Button type="link"  onClick={()=>{history.push('/sidebar/userGroupDetail',{id:record.id,type:3})}}>{text}</Button>
            },
            {
                title: '重复人数',
                dataIndex: 'repeat_member_num',
                key: 'repeat_member_num',
                sorter:true,
                render:( text:string, record:any, index:number )=> <Button type="link" onClick={()=>{history.push('/sidebar/userGroupDetail',{id:record.id,type:4})}}>{text}</Button>
            },
            {
                title: '消费转化率',
                dataIndex: 'rate',
                key: 'rate',
                sorter:true,
                render:( text:string, record:any, index:number )=> record.member_num==0?'0.00%':(record.consumer_member_num/record.member_num*100).toFixed(2)+'%'
            },
            {
                title: '实收金额',
                dataIndex: 'actual_fee',
                key: 'actual_fee',
                sorter:true,
            },
            {
                title: '充值次数',
                dataIndex: 'recharge_num',
                key: 'recharge_num',
                sorter:true,
            },
            {
                title: '充值金额',
                dataIndex: 'recharge_fee',
                key: 'recharge_fee',
                sorter:true,
            },
            {
                title: '成功邀请人数',
                dataIndex: 'invite_num',
                key: 'invite_num',
                sorter:true,
                render:( text:string, record:any, index:number )=> <Button type="link" onClick={()=>{history.push('/sidebar/userGroupDetail',{id:record.id,type:5})}}>{text}</Button>
            },
            {
                title: '操作时间',
                dataIndex: 'udpate_time',
                key: 'udpate_time',
            },
            {
                title: '操作',
                dataIndex: 'option',
                key: 'option',
                width: 200,
                render: ( text:string, record:any, index:number )=>
                    <>
                        <Tooltip placement="bottomRight" title={'编辑'}>
                        <img src={t_edit} className='table_icon' onClick={()=>{history.push('/sidebar/siyuGroupAdd',{id: record.id, name: record.name, channel_id: location.state&&location.state.channel_id})}}></img>
                        </Tooltip>
                        <Tooltip placement="bottomRight" title={'删除'}>
                            <img src={t_delete} className='table_icon' onClick={() => deleteList(record)}></img>
                        </Tooltip>
                        <Tooltip placement="bottomRight" title={'详情'}>
                            <img src={t_info} className='table_icon' onClick={()=>{history.push('/sidebar/userGroupDetail',{id:record.id})}}></img>
                        </Tooltip>
                    </>
            },
        ];

        return <Table
                    dataSource={data}
                    columns={columns}
                    onChange={handleTableChange}
                    rowKey={'id'}
                    pagination={{
                        defaultCurrent: 1,
                        current:paramsObj.current,
                        pageSize: paramsObj.pageSize,
                        total: total,
                        // showQuickJumper: true,
                        showSizeChanger: true,
                        showTotal: (total: number) => `共${total}条`,
                    }}
                />
    }

    //时间修改
    // const rangePickerChange = (times:any)=>{
    //     console.log(times)
    // }

    return(
        <div className="siyu_group_detail l_content">
            {/* <div className="title_text">{channelList[location.state&&location.state.channel_id?(location.state.channel_id-1):0]}</div> */}
            <div className="title_text">{title}</div>
            <Button type="primary" onClick={()=>{history.push('/sidebar/siyuGroupAdd',{channel_id: location.state&&location.state.channel_id})}}>添加</Button>
            <Row type="flex" align="middle" className="mb30 mt30">
                <span className="mr10">分组名称</span>
                <Search placeholder="请输入分组名称" onSearch={(e:any)=>{searchGroup(e)}} style={{ width: 200 }} className="mr10"/>
                {/* <Input placeholder="请输入分组名称" style={{width:200}} className="mr10" onChange={(e:any)=>{searchGroup(e.currentTarget.value)}}/> */}
                <span className="mr10">统计时间</span>
                <RangePicker
                    className="mr10"
                    format={`YYYY-MM-DD`}
                    showTime={{ format: 'YYYY-MM-DD' }}
                    onChange={(e:any, dateString) => {
                        getGroupList({...paramsObj,current:1,statistical_time_start:dateString[0],statistical_time_end:dateString[0]});
                    }}
                    placeholder={['开始时间', '结束时间']} />                 
                {/* <Button type="primary">搜索</Button> */}
            </Row>
            {
                creatTable()
            }
        </div>
    )
}

export default SiyuGroupDetail