
import React from "react";
import {Button, Input, message, Row, Col, Modal, Radio, Icon, Table, DatePicker, Tree, Tooltip, Checkbox} from "antd";
import { postRequest, getRequest } from '../../api/http';
import { RangePickerValue } from "antd/lib/date-picker/interface";
import { apiUrl } from '../../api/api_url';
import styles from './consumption.module.scss'

const { RangePicker } = DatePicker;
const { TreeNode } = Tree;

const key = 'ConsumptionAdd';

class ConsumptionAdd extends React.Component<any> {

    columnNum:any = [
        {
            title: '优惠券名称',
            dataIndex: 'name',
            key: 'name',
            align: "center",
        },
        {
            title: '券类型',
            dataIndex: 'type',
            key: 'type',
            align: "center",
            render: (text:any,record:any,) => (
                <div className="color">{record.type === 'discount' ? '折扣券' : record.type === 'voucher' ? '优惠券' : '兑换券'}</div>
            ),
        },
        {
            title: '券有效期',
            dataIndex: 'created_at',
            key: 'created_at',
            align: "center",
            render: (text:any,record:any,) => {
                let info:any = record.expire_info;
                return <div className="color">{info.type === 'time' ?  info.start_time + '至' + info.end_time : '自领取日'+info.day + '天领取'}</div>
            }
        },
        {
            title: '券份数',
            dataIndex: 'num',
            key: 'num',
            align: "center",
            render: (text:any,record:any,) => {
                return <div className="back_wrap">
                        <div className="good_num">{record.coupon_num}</div>
                    </div>
            }
        },
        {
            title: '操作',
            dataIndex: 'index',
            key: 'index',
            render: (rowData: any,record:any,idx:number)=>{
                return <div className="table_action" onClick={() => this.unBindShop(rowData, idx)}>移除</div>
            }
        },
    ];

    state = {
        loading: false,
        name: '',
        status: 1,
        visible: false,
        couponList: [],
        listNum: 0,
        current: 1,
        user_type: [],
        pageSize: 100,
        selectList: [],
        start_time: '',
        end_time: '',
        edit: false,

        notice_time: 0,
        consume_type: 1,
        daily_radio: 1,
        daily: 0,
        total_radio: 1,
        total: 0,
        present_type: 1,
        ruleList:[{
            full: 0,
            money: 0,
            full_check: false,
            money_check: false,
            coupon:[]
        }]
    }

    componentDidMount () {
        const {location} = this.props;
        if(location.state !== undefined){
            this.initEdit(location.state.id);
        }
        this.onInitList();
    }

    // 初始化优惠券列表
    onInitList = async () => {
        let param = {
            current: 1,
            pageSize: 100,
            position: 3
        }
        const res:any = await getRequest(apiUrl.couponList, param);
        if(res.code === 1){
            this.setState({
                couponList: res.data.list,
                listNum: res.data.total,
                current: 1,
                selectList: res.data.list.slice(0, 10)
            })
        }
    }

    // 初始化编辑
    initEdit = async (id:number) => {
        const params = {
            id: id
        };
        const res:any = await getRequest(apiUrl.consumeInfo,params);
        if(res.code === 1){
            this.setState({
                edit: true,
                name: res.data.name,
                status: res.data.status,
                consume_type: res.data.consume_type,
                user_type: res.data.user_type,
                start_time: res.data.start_time,
                end_time: res.data.end_time,
                notice_time: res.data.notice_time,
                present_type: res.data.present_type,
                // ruleList: res.data.info
            })
            if(res.data.daily && res.data.daily != -1){
                this.setState({
                    daily: res.data.daily,
                    daily_radio: 2
                })
            }else{
                this.setState({
                    daily_radio: 1
                })
            }
            if(res.data.total && res.data.total != -1){
                this.setState({
                    total: res.data.total,
                    total_radio: 2
                })
            }else{
                this.setState({
                    total_radio: 1
                })
            }
            if(res.data.info && res.data.info.length > 0){
                res.data.info.map((info: any) => {
                    if(info.money){
                        info.money_check = true
                    }
                    if(info.coupon && info.coupon.length > 0){
                        info.full_check = true
                    }
                })
                this.setState({
                    ruleList: res.data.info
                })
            }
        }
    }

    nameChange = (e:any) => {
        let name = e.target.name;
        let value = e.target.value;
        this.setState({
            [name]: value
        })
        if(name == 'present_type' && value == 2){
            this.setState({
                ruleList: this.state.ruleList.slice(0,1)
            })
        }
    }

    onCheck = (checkedKeys: any, info: any) => {
        let keyList = checkedKeys;
        let user_type: any[] = [];
        keyList.map((item: any) => {
            if(item !== '4'){
                user_type.push(item)
            }
        })
        this.setState({
            user_type
        })
    };

    handleSubmit = async () => {
        message.loading({ content: '发布中', key });

        const {location} = this.props;
        const { name, status, start_time, end_time, notice_time, consume_type, user_type, daily, daily_radio, total_radio, total, present_type, ruleList} = this.state;
        var tableInfo:any = {};

        if(name === ''){
            message.warning({ content: '请输入活动名称!', key, duration: 2 });
            return;
        }

        if(start_time === ''){
            message.warning({ content: '请选择活动时间!', key, duration: 2 });
            return;
        }
        if(user_type.length <= 0 && consume_type == 1){
            message.warning({ content: '请选择参与对象!', key, duration: 2 });
            return;
        }

        if(daily_radio == 2 && !daily && consume_type != 2){
            message.warning({ content: '请输入每人每日参与次数!', key, duration: 2 });
            return;
        }

        if(total_radio == 2 && !total && consume_type != 2){
            message.warning({ content: '请输入每人参与总次数!', key, duration: 2 });
            return;
        }

        let flag = true;
        if(ruleList){
            let fullList:any = [];
            ruleList.map((item: any) => {
                if(!item.full){
                    flag = false;
                }
                if(!item.money && item.coupon.length <= 0){
                    flag = false;
                }
                if(fullList.indexOf(item.full) < 0){
                    fullList.push(item.full)
                }else{
                    flag = false
                }
            })
        }
        if(!flag){
            message.warning({ content: '请正确添加赠送规则!', key, duration: 2 });
            return;
        }

        if(location.state !== undefined){
            tableInfo.id = location.state.id;
        }
        tableInfo.name = name;
        tableInfo.start_time = start_time;
        tableInfo.end_time = end_time;
        tableInfo.consume_type = consume_type;
        if(consume_type == 1){
            tableInfo.user_type = user_type;
        }else{
            tableInfo.user_type = ['1', '2', '3']
        }
        tableInfo.present_type = present_type;
        tableInfo.status = status;
        tableInfo.info = []
        ruleList.map((item: any) => {
            let obj:any = {};
            obj.full = item.full;
            if(item.money){
                obj.money = item.money
            }else{
                obj.money = 0
            }
            if(item.coupon.length > 0){
                let arr:any = [];
                item.coupon.map((c: any) => {
                    arr.push(c.id)
                })
                obj.coupon = arr;
            }else{
                obj.coupon = []
            }
            tableInfo.info.push(obj)
        })

        notice_time && (tableInfo.notice_time = notice_time);
        if(daily_radio == 1){
            tableInfo.daily = -1;
        }else{
            tableInfo.daily = daily;
        }
        if(total_radio == 1){
            tableInfo.total = -1;
        }else{
            tableInfo.total = total;
        }

        if(location.state !== undefined){
            const res:any = await postRequest(apiUrl.updateConsume,tableInfo);
            if(res['code'] === 1){
                message.success({ content: '修改成功!', key, duration: 2 });
                this.goBack()
            }else{
                message.error({ content: '修改失败!', key, duration: 2 });
            }
        }else{
            const res:any = await postRequest(apiUrl.addConsume,tableInfo);
            if(res['code'] === 1){
                message.success({ content: '发布成功!', key, duration: 2 });
                this.goBack()
            }else{
                message.error({ content: '发布失败!', key, duration: 2 });
            }
        }
    };

    // 返回上一页
    goBack = () => {
        this.props.history.goBack();
    }

    changePage = async (page: number) => {
        let { couponList } = this.state;
        let list = couponList.slice((page-1)*10, (10*page));
        this.setState({
            selectList: list,
            current: page
        })
    }

    // 关联
    handleBindShop = (id:number) => {
        let couponList:any[] = this.state.couponList;
        let ruleList:any = this.state.ruleList;

        couponList.map((item) => {
            if(item.id === id){
                item.selected = true;
                item.coupon_num = 1;
                item.index = this.currentIndex;
                ruleList[this.currentIndex].coupon.push(item)
            }
        })

        this.setState({
            couponList,
            ruleList
        })
    }

    // 解除关联
    unBindShop = (rowData: any, idx: number) => {
        let { couponList,edit } = this.state;
        let ruleList: any = this.state.ruleList;
        if(edit){
            return;
        }
        couponList.map((item: any) => {
            if(item.id === ruleList[rowData].coupon[idx].id){
                item.selected = false;
            }
        })
        ruleList[rowData].coupon.splice(idx, 1)
        this.setState({
            couponList,
            selectList: couponList.slice(0, 10),
            current: 1,
            ruleList
        })
    }

    handleOk = () => {
        this.setState({
            visible: false
        })
    }

    // 创建 商品列表
    columns:any = [
        {
            title: '优惠券名称',
            dataIndex: 'name',
            key: 'name',
            align: "center",
        },
        {
            title: '券类型',
            dataIndex: 'type',
            key: 'id',
            align: "center",
            render: (text:any,record:any,) => (
                <div className="color">{record.info.type === 'discount' ? '折扣券' : record.info.type === 'voucher' ? '优惠券' : '兑换券'}</div>
            ),
        },
        {
            title: '券有效期',
            dataIndex: 'created_at',
            key: 'created_at',
            align: "center",
            render: (text:any,record:any,) => {
                let info:any = record.expire_info;
                return <div className="color">{info.type === 'time' ?  '自'+info.start_time + '至' + info.end_time : '自领取日'+info.day + '天内有效'}</div>
            }
        },
        {
            title: '操作',
            align: "center",
            render: (text: string,record:any,index:number)=>{
                return <div>
                    {
                        record.selected
                        ? '已关联'
                        : <div className="table_action" onClick={() => this.handleBindShop(record.id)}>关联</div>
                    }
                </div>
            }
        },
    ];

    currentIndex = -1;

    // 显示关联
    showTable = (index: number) => {
        let { couponList, edit} = this.state;
        let ruleList: any = this.state.ruleList;
        this.currentIndex = index;
        if(edit){
            return;
        }
        couponList.map((coupon: any) => {
            coupon.selected = false;
            ruleList[index].coupon && ruleList[index].coupon.map((select: any) => {
                if(coupon.id === select.id){
                    coupon.selected = true;
                }
            })
        })
        this.setState({
            couponList,
        },() => {
            this.setState({
                selectList: couponList.slice(0, 10),
                current: 1,
                ruleList,
                visible: true
            })
        })
    }

    timeChange = (time: RangePickerValue, timeString: [string, string]) => {
        this.setState({
            start_time: timeString[0],
            end_time: timeString[1]
        })
    }

    fullChange = (text: string, index: number, e:any) => {
        let ruleList:any = this.state.ruleList;
        ruleList[index][text] = e.target.value
        this.setState({
            ruleList
        })
    }

    // 删除规则
    deleteRule = (index: number) => {
        let ruleList:any = this.state.ruleList;
        ruleList.splice(index, 1)
        this.setState({
            ruleList
        })
    }

    // 添加规则
    addRule = () => {
        let ruleList:any = this.state.ruleList;
        ruleList.push({
            full: 0,
            money: 0,
            full_check: false,
            money_check: false,
            coupon:[]
        })
        this.setState({
            ruleList
        })
    }

    checkChange = (text: string, index: number, e:any) => {
        let ruleList:any = this.state.ruleList;
        ruleList[index][text] = e.target.checked;
        this.setState({
            ruleList
        })
    }

    render(){
        const { name, status, visible, selectList, current, listNum, start_time, end_time, } = this.state;
        const { notice_time, consume_type, user_type, daily, total, present_type, ruleList, daily_radio, total_radio  } = this.state;
        return <div className='l_content'>
                <div className="edit_title">活动基本信息</div>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">活动名称</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <Input placeholder="请输入活动名称" name='name' value={name} onChange={(e) => this.nameChange(e)}/>
                    </Col>
                </Row>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">活动时间</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <RangePicker onChange={this.timeChange} format="YYYY-MM-DD" placeholder={start_time !== '' ? [start_time, end_time] :["开始时间","结束时间"]} />
                    </Col>
                </Row>

                <div className="edit_title">参与规则</div>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">参与消费</span>
                        </div>
                    </Col>
                    <Col span={22}>
                        <Radio.Group
                            name='consume_type'
                            value={consume_type}
                            onChange={(e) => this.nameChange(e)}
                            disabled={this.state.edit}
                        >
                            <Radio value={1}>每次消费</Radio>
                            <Radio value={2}>仅首次消费</Radio>
                            <Radio value={3}>第二次及之后消费</Radio>
                        </Radio.Group>
                    </Col>
                </Row>

                {
                    consume_type != 2 && consume_type != 3
                    ? <Row className="mb30" type="flex" align="top">
                     <Col span={2}>
                         <div>
                             <span className="star"></span>
                             <span className="color_black">参与对象</span>
                         </div>
                     </Col>
                     <Col span={6}>
                         <Tree
                             checkable
                             showIcon
                             onCheck={this.onCheck}
                             // defaultSelectedKeys={user_type}
                             checkedKeys={user_type}
                             disabled={this.state.edit}
                         >
                             <TreeNode title="新用户" key="1" icon={
                                 <Tooltip placement="topLeft" title={"还未注册的用户或已经注册还没有下单的用户"}>
                                 <Icon type="question-circle" />
                                 </Tooltip>
                             }>
                             </TreeNode>
                             <TreeNode title="老顾客" key="4" icon={
                                 <Tooltip placement="topLeft" title={"交易次数>=1次的交易次数"}>
                                 <Icon type="question-circle" />
                                 </Tooltip>
                             }>
                                 <TreeNode title="充值用户" key="2" />
                                 <TreeNode title="非充值用户" key="3" />
                             </TreeNode>
                         </Tree>
                     </Col>
                 </Row>
                 : null
                }

                {
                    consume_type != 2
                    ? <Row className="mb30" type="flex" align="middle">
                            <Col span={2}>
                                <div>
                                    <span className="star"></span>
                                    <span className="color_black">每人每日参与次数</span>
                                </div>
                            </Col>
                            <Col span={22}>
                                <Row type='flex' align='middle'>
                                    <Col>
                                        <Radio.Group
                                        name='daily_radio'
                                        value={daily_radio}
                                        onChange={(e) => this.nameChange(e)}
                                        disabled={this.state.edit}
                                    >
                                        <Radio value={1}>无上限</Radio>
                                        <Radio value={2}>限</Radio>
                                    </Radio.Group>
                                    </Col>
                                    {   <Col>
                                            <div className="limit_tip">
                                                <Input style={{ width: 120 }} disabled={this.state.edit} name='daily' type="number" value={daily} onChange={(e) => this.nameChange(e)} />
                                                <span> 次</span>
                                            </div>
                                        </Col>
                                    }
                                </Row>
                            </Col>
                        </Row>
                    : null
                }

                {
                    consume_type != 2
                    ? <Row className="mb30" type="flex" align="middle">
                            <Col span={2}>
                                <div>
                                    <span className="star"></span>
                                    <span className="color_black">每人参与总次数</span>
                                </div>
                            </Col>
                            <Col span={22}>
                                <Row type='flex' align='middle'>
                                    <Col>
                                        <Radio.Group
                                        name='total_radio'
                                        value={total_radio}
                                        onChange={(e) => this.nameChange(e)}
                                        disabled={this.state.edit}
                                    >
                                        <Radio value={1}>无上限</Radio>
                                        <Radio value={2}>限</Radio>
                                    </Radio.Group>
                                    </Col>
                                    {   <Col>
                                            <div className="limit_tip">
                                                <Input style={{ width: 120 }} disabled={this.state.edit} name='total' value={total} type="number" onChange={(e) => this.nameChange(e)}  />
                                                <span> 次</span>
                                            </div>
                                        </Col>
                                    }
                                </Row>
                            </Col>
                        </Row>
                    : null
                }


                <div className="edit_title">赠送规则</div>

                <Row type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">赠送规则</span>
                        </div>
                    </Col>
                    <Col span={22}>
                        <Row type='flex' align='middle'>
                            <Col>
                                <Radio.Group
                                name='present_type'
                                value={present_type}
                                onChange={(e) => this.nameChange(e)}
                            >
                                <Radio value={1}>单次满赠送</Radio>
                                <Radio value={2}>每满赠送</Radio>
                            </Radio.Group>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {
                    ruleList.map((item: any, index: number) => {
                        return <Row className='mb30' key={index}>
                                <Col offset={2} span={8}>
                                    <div style={{border: '1px solid rgba(0,0,0,0.15)'}} className={styles.rule_wrap}>
                                        <Row type='flex' justify='space-between' align='middle'>
                                            <Col>满 <Input style={{width: 120}} onChange={(e) => {this.fullChange('full', index, e)}} value={item.full} /> 元</Col>
                                            {ruleList && ruleList.length > 1 ? <Col><Icon type='delete' style={{fontSize: 20}} onClick={() => this.deleteRule(index)}></Icon></Col> : null}
                                        </Row>
                                        <Row type='flex' justify='start' align='middle' className={styles.mt20}>
                                            <Col className={styles.mr20}>
                                                <Checkbox checked={item.money_check} onChange={(e) => {this.checkChange('money_check', index, e)}} />
                                                送 <Input style={{width: 120}} value={item.money} onChange={(e) => {this.fullChange('money', index, e)}} disabled={!item.money_check} /> 元余额</Col>
                                            <Col>
                                            <Checkbox checked={item.full_check} onChange={(e) => {this.checkChange('full_check', index, e)}} />
                                            送券 <Button type='primary' disabled={!item.full_check} onClick={() => this.showTable(index)}>选择券</Button></Col>
                                        </Row>
                                        {
                                        item.coupon.length > 0
                                        ? <Table
                                            size="middle"
                                            rowKey={'id'}
                                            dataSource={item.coupon}
                                            columns={this.columnNum}
                                            style={{marginTop: '20px'}}
                                            pagination={false}
                                        />
                                        : null
                                    }
                                    </div>
                                </Col>
                            </Row>
                    })
                }

                {
                    present_type == 1
                    ? <Row className="mb30">
                        <Col offset={2}><Button type='primary' onClick={this.addRule}>添加规则</Button></Col>
                    </Row>
                    : null
                }

                {/* <Row type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="color_black">赠送提醒</span>
                        </div>
                    </Col>
                    <Col span={4}>
                        消费后 <Input style={{ width: 120 }} name='notice_time' value={notice_time} onChange={(e) => this.nameChange(e)} /> 分钟提醒
                    </Col>
                    <Col className={styles.tips}>不填写则支付完成立即推送</Col>
                </Row>
                <Row className="mb30 mt10">
                    <Col offset={2} className={styles.tips} >赠送提醒需要用户关注公众号，未关注将无法推送信息</Col>
                </Row> */}

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">状态</span>
                        </div>
                    </Col>
                    <Col span={22}>
                        <Radio.Group
                            name='status'
                            value={status}
                            onChange={(e) => this.nameChange(e)}
                        >
                            <Radio value={1}>开启</Radio>
                            <Radio value={0}>暂停</Radio>
                        </Radio.Group>
                    </Col>
                </Row>

                <Row>
                    <Col span={12}>
                        <div className="bottom_wrap">
                            <Button type="primary" ghost onClick={this.goBack}>
                                取消
                            </Button>
                            <Button type="primary" onClick={this.handleSubmit}>
                                保存
                            </Button>
                        </div>
                    </Col>
                </Row>

            <Modal
                title={null}
                visible={visible}
                onOk={this.handleOk}
                onCancel={()=>{this.setState({visible: false})}}
                width="70vw"
            >
                <div className="model_head">
                    <div className="head_title">关联优惠券</div>
                </div>
                <Table
                    size="middle"
                    rowKey={"id"}
                    dataSource={selectList}
                    columns={this.columns}
                    pagination={
                        {
                            current,
                            total: listNum,
                            onChange: this.changePage,
                            pageSize: 10
                        }
                    }
                />
            </Modal>

        </div>;
    }

}
export default ConsumptionAdd