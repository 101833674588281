import React, { useEffect, useState } from 'react'
import { Button, Tabs } from 'antd'
import { useHistory, useLocation } from 'react-router-dom';
import CommunityDetail from './components/community/community'
import CommunityRecommend from './components/community/recommend'
import { stores } from '../../../../../stores/index.store'

import './community.scss'

const { TabPane } = Tabs;

const Community = ()=>{

    const history = useHistory()
    const location:any = useLocation()

    const [ currentTab, setCurrentTab ] = useState('community')

    useEffect(()=>{
        setCurrentTab(stores.appStore.communityTab)
    },[])

    //tab切换
    const onChangeTabs = (e:any)=>{
        setCurrentTab(e)
        stores.appStore.setCommunityTab(e)
    }
    

    return(
        <div className="community l_content">
            {/* <div className="title_text">{channelList[location.state&&location.state.channel_id?(location.state.channel_id-1):0]}</div> */}
            <div className="title_text">社群用户</div>
            <Button className="mb20" type="primary" onClick={()=>{history.push('/sidebar/siyuGroupAdd',{channel_id: location.state&&location.state.channel_id})}}>添加社群</Button>
            <Tabs defaultActiveKey={stores.appStore.communityTab} onChange={onChangeTabs}>
                <TabPane tab="社群分组" key="community">
                    <CommunityDetail currentTab={currentTab}/>
                </TabPane>
                <TabPane tab="推荐分组" key="recommend">
                    <CommunityRecommend currentTab={currentTab}/>
                </TabPane>
            </Tabs>                   
        </div>
    )
}

export default Community