import React, { useEffect, useState } from 'react'
import {templateFromType} from './../components/dataType'
import { getRequest } from './../../../api/http'
import { apiUrl } from './../../../api/api_url'
import { message, Col, Row, Button, Table, Tag, Popconfirm } from 'antd'
import TemplateForm from './templateFrom'
import { getIsPermisson, messageCustom } from '../../../utils/permisstionUtil'
const MessageTemplate = ()=>{

    const[tableSource,setTableSource] = useState([])

    const[itemEditData,setItemEditData] = useState<templateFromType>({})

    const [templateFormVisible,setTemplateFormVisible] = useState(false)

    useEffect(()=>{
        getList()
    },[])

    const getList = async()=>{
        const res:any = await getRequest(`${apiUrl.messageTemplateList}`,{pageSize:10,current:1});
        if(res.code === 1){
            setTableSource(res.data)
        }else{
            message.error(res.message)
        }
    }

    const editAction = (editAction:templateFromType) => {
        if(!getIsPermisson('2_4_3_3')){
            messageCustom();
            return;
          }
    }

    const deleteAction = async (id:any) => {
        if(!getIsPermisson('2_4_3_3')){
            messageCustom();
            return;
          }
        const res:any = await getRequest(`${apiUrl.messageTemplateDelete}`,{id:id});
        if(res.code === 1){
            getList();
            message.info('删除成功');
        }else{
            message.error(res.message)
        }
    }

    const columns = [
        {
          title: '编号',
          dataIndex: 'id',
          key: 'id',
          width: 80,
        },
        {
            title: '消息内容',
            dataIndex: 'content',
            key: 'content',
            width: 80,
        },
        {
            title: '消息标题',
            dataIndex: 'title',
            key: 'title',
            width: 80,
        },
        {
            title: '跳转url',
            dataIndex: 'jump_url',
            key: 'jump_url',
            width: 80,
        },
        {
            title: '通知类型',
            dataIndex: 'type',
            key: 'type',
            width: 80,
            render:(rowdata:any,record: any, idx: number)=>{
                return (
                    <div>{
                        rowdata === 1 ? <Tag color="#87d068">系统通知</Tag> :
                        rowdata === 2 ? <Tag color="#ff0000">优惠通知</Tag> : <p></p>
                    }</div>
                )
            }
        },
        {
            title: '是否生效',
            dataIndex: 'is_effect',
            key: 'is_effect',
            width: 80,
            render:(rowdata:any,record: any, idx: number)=>{
                return (
                    <div>{
                        rowdata === 1 ? <Tag color="#87d068">已生效</Tag> :
                        rowdata === 2 ? <Tag color="#ff0000">未生效</Tag> : <p></p>
                    }</div>
                )
            }
        },
        {
            title: '操作',
            key: 'action',
            width: 80,
            render:(rowdata:any,record:any,idx:number) => {
                return (
                    <div>
                        <Button type="primary" onClick={() => {editAction(rowdata)}}>编辑</Button>
                        <Popconfirm
                            placement="bottomRight"
                            title={'是否删除？'}
                            onConfirm={()=>{deleteAction(rowdata.id)}}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button>删除</Button>
                        </Popconfirm>
                    </div>
                )
            }

        },
    ]

    const templateAdd = ()=>{
        if(!getIsPermisson('2_4_3_1')){
            messageCustom();
            return;
          }
        setTemplateFormVisible(true)
    }

    const closeAndRefresh=(data:boolean) => {
        setTemplateFormVisible(data)
        getList();
    }


    return (
        <div className='l_content'>
            <TemplateForm visible={templateFormVisible} setVisible={(data:boolean)=> {closeAndRefresh(data)}} data={itemEditData} ></TemplateForm>
            <Row>
                <Col span={24}>
                    <Button type="primary" size="large" icon="plus" onClick={templateAdd}>创建模板</Button>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Table dataSource={tableSource} size="middle" rowKey={'id'} columns={columns} />
                </Col>
            </Row>
        </div>
    )
}

export default MessageTemplate
