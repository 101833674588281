import React from 'react';
import InsideNav from '../../../component/insideNav/insideNav';
import { collectChildNav, getIsPermisson } from '../../../utils/permisstionUtil';
import DeepOperation from '../../deepOperation/deepOperation';
import MarketingIndex from '../../marketing';
import SmartPushTicket from '../../smartPushTicket';
import RechargeManage from '../../recharge/recharge_manage/recharge_manage';
import RulesConfig from '../../vip_config/rules_config';
import VipLevelConfig from '../../vip_config/vip_level_config';
import GroupIndex from '../../vip_management/group';
import MemberList from '../../vip_management/memberList/index';
import './deepCustomerOperate.scss'

type IProps = {
    history: any
}
type IState = {
    navList: any
    current_key: string
}

const navTable = [
    {
        title: '用户数据',
        isActive: false,
        KEY: "USER_DATA",
        uni_key: '1_6_00'
    },
    {
        title: '会员管理',
        isActive: false,
        KEY: "USER_LIST",
        uni_key: '1_6_1'
    },
    {
        title: '基础会员分级',
        isActive: false,
        KEY: "VIP_LEVEL_CONFIG",
        uni_key: '1_6_2'
    },
    {
        title: '积分权益',
        isActive: false,
        KEY: "VIP_CONFIG",
        uni_key: '1_6_3'
    },
    {
        title: '充值配置',
        isActive: false,
        KEY: "RECHARGE_MANAGE",
        uni_key: '1_6_4'
    },
    {
        title: '分组营销',
        isActive: false,
        KEY: "MEMBER_GROUP_INDEX",
        uni_key: '1_6_5'
    },
    {
        title: '自动化营销',
        isActive: false,
        KEY: "MARKETING_INDEX",
        uni_key: '1_6_6'
    },
    {
        title: '智能发券',
        isActive: false,
        KEY: "SMARTPUSHTICKET_INDEX",
        uni_key: '1_6_6'
    }
    
];

class DeepCustomerOperate extends React.Component<IProps,IState>{
    
    constructor(props:IProps) {
        super(props);
        this.state = {
            navList: [],
            current_key: "",
        }
    }

    componentDidMount(){
        this.initPageState();
    }

    initPageState = () =>{
        let navList = collectChildNav(navTable);
        let currentKey = localStorage.getItem('current_key') ?? navList[0].KEY;
        let isSetKey = false;
        navList.map((item:any) => {
            if(item.KEY == currentKey){
                item.isActive = true;
                isSetKey = true;
            }else{
                item.isActive = false;
            }
        })
        if(!isSetKey){
            currentKey = navList[0].KEY;
            navList[0].isActive = true;
        };
        this.setState({
            current_key: currentKey,
            navList: navList
        })
    }

    navCallBack(currentKey: string){
        localStorage.setItem('current_key',currentKey) 
        this.setState({
            current_key: currentKey
        })
    }

    render() {
        let { navList,current_key } = this.state;
        return <div>
            <InsideNav navList={navList} callBack={(key: string) =>this.navCallBack(key)}/>
            <div className="body">
                {
                    current_key === 'USER_DATA' ?   <DeepOperation history={this.props.history} />
                    :
                    current_key === 'USER_LIST' ?   <MemberList />
                    :
                    current_key === 'VIP_LEVEL_CONFIG' ?  <VipLevelConfig />
                    : 
                    current_key === 'VIP_CONFIG' ?  <RulesConfig />
                    : 
                    current_key === 'RECHARGE_MANAGE' ?  <RechargeManage />
                    : 
                    current_key === 'MEMBER_GROUP_INDEX' ?  <GroupIndex />
                    :
                    current_key === 'MARKETING_INDEX' ?  <MarketingIndex />
                    :
                    current_key === 'SMARTPUSHTICKET_INDEX' ?  <SmartPushTicket />
                    :<></>
                }
            </div>
        </div>
    }

}

export default DeepCustomerOperate;