import React from 'react';
import { Row, Col } from 'antd';
import '../management.scss';

import winAddShop from '../../../../../../assret/images/winAddShop.png';
import winAddShopImg from '../../../../../../assret/images/winAddShopImg.png';
import winAddShopEdit from '../../../../../../assret/images/winAddShopEdit.png';

interface ShopManagementDetail {
    history: any,
}

class ShopManagementDetail extends React.Component<ShopManagementDetail> {

    render() {
        return  <Row className="typeDetail">
                    <Col className="typeDetail-title">一、功能介绍</Col>
                    <Col className="typeDetail-col">通过添加商品发布，您可以将商品上架到点餐小程序。</Col>
                    <Col className="typeDetail-title">二、功能路径</Col>
                    <Col className="typeDetail-col">电脑端登录后台-商品管理-商品管理-添加商品。</Col>
                    <Col className="typeDetail-col">
                        <img src={winAddShop} />
                    </Col>
                    <Col className="typeDetail-title">三、操作流程</Col>
                    <Col className="typeDetail-col">1、<span className="txt-bold">选择商品图片</span>，根据系统提示设置商品编号、商品名称、商品分类、商品口味、售价、会员价、打印部门，“ <span style={{color: '#f00'}}>*</span> ” 为必填项。商品图建议尺寸：若分类展示样式为横图，建议上传封面图宽高为470*206；若分类展示样式为竖图，建议上传封面图宽高为230*270</Col>
                    <Col className="typeDetail-col"><img src={winAddShopImg} /></Col>
                    <Col className="typeDetail-col">2、<span className="txt-bold">添加分类</span>，当没有分类时，点击添加分类，创建新的分类</Col>
                    <Col className="typeDetail-col">3、<span className="txt-bold">打印部门</span>，选择相关打印部门</Col>
                    <Col className="typeDetail-col">4、<span className="txt-bold">商品口味</span>，默认为无，当有口味选择有</Col>
                    <Col className="typeDetail-col">5、<span className="txt-bold">展开更多设置、是否参与折扣、是否开台必点</span></Col>
                    <Col className="typeDetail-col"><span className="txt-bold">是否参与折扣：</span>用户买单该菜品可以使用优惠券优惠</Col>
                    <Col className="typeDetail-col"><span className="txt-bold">是否开桌必点：</span>用户下单默认下单该菜品</Col>
                    <Col className="typeDetail-col">
                        <img src={winAddShopEdit} />
                    </Col>
                </Row>
    }
}

export default ShopManagementDetail;