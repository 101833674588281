import {inject, observer} from "mobx-react";
import React from "react";
import {IAccountAuthStore} from "../../../stores/accountAuth.store";
import i_add_icon from "../../../assret/images/i_add_icon.png";
import "./accountAuth.scss";
import {Avatar, Modal} from "antd";
// import Modal from "antd/es/modal";
import {getRequest} from "../../../api/http";
import {apiUrl} from "../../../api/api_url";
import { RouteComponentProps } from "react-router-dom";

interface IAccountAuth extends RouteComponentProps{
    accountAuthStore?: IAccountAuthStore,
}

interface typeInfo{
    id: number,
}

interface accountInfo{
    id: number,
    nick_name:string,
    head_img: string,
    service_type_info: typeInfo,
    verify_type_info: typeInfo,
    create_time: number
}

@inject('accountAuthStore')
@observer
class AccountAuthView extends React.Component<IAccountAuth> {
    page = 1;
    page_size = 20;
    state = {
        visible: false,
        tips: {
            alert: '',
            tips: '',
            head_img: '',
        },
        accounts: []
    };

    componentDidMount(): void {
        this.controlModelTip();
        this.requestAuthAccount();
    }

    /**
     * 公众号授权回调 判断
     */
    controlModelTip =()=>{
        const params = new URLSearchParams(this.props.location.search);
        let tips:any = this.state.tips;
        if(params.get("alert") != null){
            tips.alert = this.unicodeToUtf8(params.get("alert") as string);
            tips.tips = this.unicodeToUtf8(params.get("tips") as string);
            tips.head_img = params.get("head_img");
            this.handleModel();
        }
    };

    /**
     * unicode 转中文
     */
    unicodeToUtf8 =(str:string) =>{
        str = unescape(str.replace(/\\/g, "%"))
        str = str.substring(1,str.length-1);
        return str;
    };
    // 获取授权 h5地址
    requestAuthH5 = () => {
        window.location.href = process.env.REACT_APP_BASEURL+apiUrl.accountAuthH5 + `?token=${localStorage.getItem('token')}`
    };

    // 获取 认证公众号列表
    requestAuthAccount = async () =>{
        const res: any = await getRequest(apiUrl.authAccountList,{page: this.page,page_size: this.page_size});
        if(res.code === 1){
            this.setState({
                accounts: res.data.list
            });
        }else{
            console.log(res.message)
        }
    };

    handleModel =() =>{
        let temVisible = this.state.visible;
        this.setState({visible: !temVisible})
    };

    // 认证状态
    handleAuthStatus = (id:number) =>{
        return id === -1 ? '未认证':'认证'
    };

    // 账号类别
    handleAccountType = (id:number) =>{
        let accountType = '';
        switch (id) {
            case 0:
                accountType = '订阅号';
                break;
            case 1:
                accountType = '订阅号';
                break;
            case 2:
                accountType = '服务号';
                break;
            default:
                accountType = '未知';
                break;
        }
        return accountType;
    };

    render(){
        let {visible,tips,accounts} = this.state;

        return <div className="account_view l_content">
            <div className="add_account_button" onClick={this.requestAuthH5}>
                <div><img src={i_add_icon} className="i_add_icon" alt="" /></div>
                <div><div className="add_button_text">添加公众号</div></div>
            </div>
            {
                accounts.map((item:accountInfo,index) =>
                    <div className='add_account_button' key={index}>
                        <Avatar size={90} src={item.head_img} />
                        <div className='add_button_text'>{item.nick_name}</div>
                        <div className='account_type'>{this.handleAuthStatus(item.service_type_info.id)}</div>
                        <div className='account_isAuth'>{this.handleAccountType(item.verify_type_info.id)}</div>
                    </div>
                )
            }
            <Modal
                visible={visible}
                footer={null}
                title={null}
                onCancel={this.handleModel}
            >
                <div className="model_view">
                    <div className='model_title'>添加失败</div>
                    <Avatar size={90} src={tips.head_img} />
                    <div className='model_result_tips'>{tips.alert}</div>
                    <div className='model_tips'>{tips.tips}</div>
                </div>

            </Modal>
        </div>;
    }
}
export default AccountAuthView
