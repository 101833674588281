import React from "react";
import {Button, Row, Col, Input, Radio, Table, Modal, Select, message, Icon, Popconfirm} from "antd";
import { postRequest, getRequest } from '../../../api/http';
import { apiUrl } from '../../../api/api_url';
import styles from './windowTable.module.scss';
const { Option } = Select;

const key = 'updatable';
export default class businessModel extends React.Component<any> {

    state = {
        region_list: [],
        region_select_list: [],
        list_status: 3,
    }

    componentDidMount() {
        this.openWhereTableRequest()
    }

    handleSubmit = async (e:any, typeAdd: any) => {
        message.loading({ content: '发布中', key });
        e.preventDefault();

        let goodInfo:any = {};

        const { region_list } = this.state;

        goodInfo.business_model = region_list;

        const res:any = await postRequest(apiUrl.changePassword,goodInfo);
        if(res['code'] === 1){
            message.success({ content: '修改成功!', key, duration: 2 });
            if(typeAdd == 'add') {
                window.history.go(-1)
            }
        }else{
            message.error({ content: res['message'], key, duration: 2 });
        }

    };

    changeBusinessModel = (e: any) => {
        this.setState({
            business_model: e
        })
    }

    // 返回上一页
    goBack = () => {
        window.history.go(-1)
    }

    //获取区域
    openWhereTableRequest = async () => {
        const res:any = await getRequest(`${apiUrl.regionIdList}`);
        //let region_select_list: any  = this.state.region_select_list;

        if(res.code === 1){
            let len = res.data;
            let obj = len.map((item: any, index : any) => {
                let _obj = item;
                _obj.select = false;
                return _obj
            })
            this.setState({
                region_list: obj
            }, () => {
                // let obj = len.map((item: any, index : any) => {
                //     let _obj = len;
                //     _obj.select = false;
                //     return _obj
                // })
                // console.log(region_select_list)
            })
        }

    }

    onSelectRegion = (item: any, index: any) => {
        let region_list: any = this.state.region_list;
        let region_select_list: any = this.state.region_select_list;
        if(region_list[index]['select']) {
            region_list[index]['select'] = false;
            this.setState({
                [region_list[index]['select']] : false
            })
            console.log('asdasdaaaaa:',item['id'])
            console.log('asdasd:',region_select_list.indexOf(item['id']))
            if(region_select_list.indexOf(item['id']) != '-1') {
                region_select_list.splice(region_select_list.indexOf(item['id']),1)
                this.setState({
                    region_select_list
                }, () => {
                    this.props.onTab(this.state.region_select_list, 0)
                })
            }
        } else {
            console.log(item['id'])
            this.setState({ 
                [region_list[index]['select']] : true
            })
            region_list[index]['select'] = true;

            let _list: any = [];
            region_list.map((item: any, index: any) => {
                if(item.select) {
                    _list.push(item['id'])
                }
            })
            this.setState({
                region_select_list: _list
            }, () => {
                this.props.onTab(this.state.region_select_list, 0)
            })
        }
    }

    onSelectStatus = (type: any) => {
        if(type == 1) {
            if(this.state.list_status == 1) {
                this.props.onStatusAllWindow()
                this.setState({
                    list_status: 0
                })
            } else {
                this.props.onStatusUseAction()
                this.setState({
                    list_status: 1
                })
            }
        } else if(type == 2) {
            if(this.state.list_status == 2) {
                this.props.onStatusAllWindow()
                this.setState({
                    list_status: 0
                })
            } else {
                this.props.onStatusPayAction()
                this.setState({
                    list_status: 2
                })
            }
        } else {
            if(this.state.list_status == 3) {
                this.props.onStatusAllWindow()
                this.setState({
                    list_status: 0
                })
            } else {
                this.props.onStatusAllWindow()
                this.setState({
                    list_status: 3
                })
            }
        }
    }

    render() {

        const { region_list, list_status } = this.state;

        return (
            <div className={styles.windowTable}>
                {/* {
                    region_list.map((item: any, index: any) => {
                        return (
                            <span key={index} className={`${item.select ? styles.active : ''}`} onClick={() => {this.onSelectRegion(item, index)}}>{item.name}</span>
                        )
                    })
                } */}
                <span className={`${list_status == 3 ? styles.active : ''}`} onClick={() => {this.onSelectStatus(3)}}>全部状态</span>
                <span className={`${list_status == 1 ? styles.active : ''}`} onClick={() => {this.onSelectStatus(1)}}>点菜状态</span>
                <span className={`${list_status == 2 ? styles.active : ''}`} onClick={() => {this.onSelectStatus(2)}}>收银状态</span>
            </div>
        )
    }
}