import React from 'react';
import { Table, Row, Col, Button, DatePicker, Modal, message, Tooltip, Icon, Select } from 'antd'
import { RangePickerValue } from "antd/lib/date-picker/interface";
import { getRequest } from '../../api/http';
import { apiUrl } from '../../api/api_url';
import { getIsPermisson, messageCustom } from '../../utils/permisstionUtil';
// import './index.scss'
const { RangePicker } = DatePicker
const { Option } = Select;

interface FinanceCashProps {
    history: any,
}

const nowDate = new Date();
const yesterday = new Date(nowDate.getTime() - 24 * 60 * 60 * 1000)
const beforeYesterday = new Date(nowDate.getTime() - 24 * 2 * 60 * 60 * 1000)

class FinanceCash extends React.Component<FinanceCashProps> {
  column = [
    {
      title: "用户昵称",
      dataIndex: "nickname",
      key: "nickname",
      className: "list_center",
      render: (text:string, record: any, index: number) => {
        return <div style={{display: 'flex', alignItems:'center', justifyContent:'center'}}>
            <div><img src={record.headimgurl} style={{width: 30,height: 30, borderRadius: '50%', marginRight: 10}} /></div>
            <div>{record.nickname}</div>
        </div>
    }
    },
    {
      title: "门店购买",
      dataIndex: "buy_shop",
      key: "buy_shop",
      className: "list_center",
      render: (text: string, record: any) => {
        return <div>
            {record.buy_shop.map((charge: any) => {
                return <div>{charge}</div>
            })}
        </div>
    }
    },
    {
      title: "门店消费",
      dataIndex: "consumer_shop",
      key: "consumer_shop",
      className: "list_center"
    },
    {
      title: "购买数量",
      dataIndex: "pay_num",
      key: "pay_num",
      className: "list_center"
    },
    {
      title: "购买金额",
      dataIndex: "pay_price",
      key: "pay_price",
      className: "list_center"
    },
    {
        title: "使用数量",
        dataIndex: "use_num",
        key: "use_num",
        className: "list_center"
    },
    {
        title: "使用代金券金额",
        dataIndex: "coupons_pay",
        key: "coupons_pay",
        className: "list_center"
    },
    {
        title: "消费时间",
        dataIndex: "created_at",
        key: "created_at",
        className: "list_center"
    },
  ];

  state = {
    list: [],
    current: 1,
    pageSize: 10,
    count: 0,
    start_time: "",
    end_time: "",
    shopList: [],
    shop_id: 0
  };

  componentDidMount() {
    this.getTimeData("currentMonth");
    this._initShop()
  }

  // 初始化数据
  _initInfo = async () => {
    let { pageSize, current, start_time, end_time, shop_id } = this.state;
    let params: any = {
      page: current,
      pageSize
    };
    if (start_time !== "") {
      params.start_time = start_time;
      params.end_time = end_time;
    }
    if(shop_id != 0){
        params.shopid = shop_id;
    }
    let res: any = await getRequest(apiUrl.cashList, params);
    if (res.code === 1) {

      let list:any = res.data.list;

      this.setState({
        count: res.data.total,
        list: list,
      });
    }
  };

  _initShop = async () => {
      let res:any = await getRequest(apiUrl.cashShopList)
      if(res.code == 1){
          this.setState({
              shopList: res.data
          })
      }
  }

  shopChange = (e:any) => {
    this.setState({
        shop_id: e
    }, () => {
        this._initInfo();
    })
  }

  pageChange = (page: number) => {
    this.setState({
        current: page
      },() => {
        this._initInfo();
      }
    );
  };

  timeChange = (time: RangePickerValue, timeString: [string, string]) => {
    this.setState({
        start_time: timeString[0],
        end_time: timeString[1]
      },() => {
        this._initInfo();
      }
    );
  };

  // 导出
  exportTable = async () => {
    if(!getIsPermisson('1_3_6_1')){
      messageCustom();
      return;
    }
    const token = localStorage.getItem("token");
    const shop_id = localStorage.getItem("shop_id");
    window.open(`${process.env.REACT_APP_BASEURL}/v2/coupon_export?token=${token}&start_time=${this.state.start_time}&end_time=${this.state.end_time}&shop_id=${shop_id}`
    );
  };

  getTimeData = (
    type:
      | "today"
      | "yesterday"
      | "beforeYesterday"
      | "beforeMonth"
      | "beforeWeek"
      | "currentMonth"
  ) => {
    switch (type) {
      case "currentMonth":
        let currMonthStart = `${nowDate.getFullYear()}-${this.formatData(
          nowDate.getMonth() + 1
        )}-01`;
        let currMonthEnd = `${nowDate.getFullYear()}-${this.formatData(
          nowDate.getMonth() + 1
        )}-${this.formatData(nowDate.getDate())}`;
        this.setState(
          {
            start_time: currMonthStart,
            end_time: currMonthEnd
          },
          () => {
            this._initInfo();
          }
        );
        break;
      case "beforeWeek":
        var d = new Date();
        var end = d.getTime() - 1000 * 60 * 60 * 24 * d.getDay();
        let start = d.getTime() - 1000 * 60 * 60 * 24 * (d.getDay() + 6);
        let startDay =
          new Date(start).getFullYear() +
          "-" +
          this.formatData(new Date(start).getMonth() + 1) +
          "-" +
          this.formatData(new Date(start).getDate());
        let endDay =
          new Date(end).getFullYear() +
          "-" +
          this.formatData(new Date(end).getMonth() + 1) +
          "-" +
          this.formatData(new Date(end).getDate());

        this.setState(
          {
            start_time: startDay,
            end_time: endDay
          },
          () => {
            this._initInfo();
          }
        );
        break;
      case "beforeMonth":
        let beforeMonthStart =
          nowDate.getFullYear() +
          "-" +
          this.formatData(nowDate.getMonth()) +
          "-01";
        let currentMonth =
          nowDate.getFullYear() +
          "-" +
          this.formatData(nowDate.getMonth() + 1) +
          "-01";
        let num = new Date(
          new Date(currentMonth).getTime() - 24 * 60 * 60 * 1000
        );
        let beforeMonthEnd = `${num.getFullYear()}-${this.formatData(
          num.getMonth() + 1
        )}-${this.formatData(num.getDate())}`;
        beforeMonthStart = `${num.getFullYear()}-${this.formatData(num.getMonth() + 1)}-01`
        this.setState(
          {
            start_time: beforeMonthStart,
            end_time: beforeMonthEnd
          },
          () => {
            this._initInfo();
          }
        );
        break;
      case "beforeYesterday":
        let beforeYesDay = `${beforeYesterday.getFullYear()}-${this.formatData(
          beforeYesterday.getMonth() + 1
        )}-${this.formatData(beforeYesterday.getDate())}`;
        this.setState(
          {
            start_time: beforeYesDay,
            end_time: beforeYesDay
          },
          () => {
            this._initInfo();
          }
        );
        break;
      case "yesterday":
        let yesDay = `${yesterday.getFullYear()}-${this.formatData(
          yesterday.getMonth() + 1
        )}-${this.formatData(yesterday.getDate())}`;
        this.setState(
          {
            start_time: yesDay,
            end_time: yesDay
          },
          () => {
            this._initInfo();
          }
        );
        break;

      case "today":
        let toDay = `${nowDate.getFullYear()}-${this.formatData(
          nowDate.getMonth() + 1
        )}-${this.formatData(nowDate.getDate())}`;
        this.setState(
          {
            start_time: toDay,
            end_time: toDay
          },
          () => {
            this._initInfo();
          }
        );
        break;
    }
  };

  formatData = (num: number) => {
    return num > 9 ? num : `0${num}`;
  };

  render() {
    let {
      list,
      current,
      pageSize,
      start_time,
      end_time,
      count,
      shopList
    } = this.state;
    return (
      <div className="income l_content">
        <div>
          <Row type="flex" align="middle" className='mb30'>
            <Col span={2} className="mr20">
              <div onClick={() => { this.props.history.goBack(); }} >
                <Button type="primary" icon="left" size="large" style={{ fontWeight: "bold" }} > 返回 </Button>
              </div>
            </Col>
            <Col className="mr20">
              <Button type="primary" size="large" icon="download" onClick={this.exportTable} > 导出 </Button>
            </Col>

            <Col className="mr20">
                门店消费： <Select style={{width: 150}} placeholder='请选择门店' allowClear onChange={this.shopChange}>
                            {
                                shopList.map((shop:any) => {
                                    return <Option key={shop.id}>{shop.name}</Option>
                                })
                            }
                </Select>
            </Col>
            <Button type="ghost" style={{ marginRight: 20 }} onClick={() => this.getTimeData("today")} > 今日 </Button>
            <Button type="ghost" style={{ marginRight: 20 }} onClick={() => this.getTimeData("yesterday")} > 昨日 </Button>
            <Button type="ghost" style={{ marginRight: 20 }} onClick={() => this.getTimeData("beforeYesterday")} > 前日 </Button>
            <Button type="ghost" style={{ marginRight: 20 }} onClick={() => this.getTimeData("beforeWeek")} > 上周 </Button>
            <Button type="ghost" style={{ marginRight: 20 }} onClick={() => this.getTimeData("beforeMonth")} > 上月 </Button>
            <Button type="ghost" style={{ marginRight: 20 }} onClick={() => this.getTimeData("currentMonth")} > 本月 </Button>

            <Col>
              <RangePicker onChange={this.timeChange} placeholder={ start_time !== "" ? [start_time, end_time] : ["开始时间", "结束时间"] } />
            </Col>
          </Row>
        </div>
        <Table
          bordered
          dataSource={list}
          size="middle"
          columns={this.column}
          rowKey={"day"}
          locale={{ emptyText: "暂无数据" }}
          pagination={{
            current,
            total: count,
            pageSize,
            onChange: this.pageChange,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (total: number) => `共${total}条`,
            onShowSizeChange: (current:number, size: number) => {this.setState({pageSize: size}, () => {this.getTimeData("currentMonth");})}

          }}
        >

        </Table>

              </div>
    );
  }
}

export default FinanceCash;