import { Button, message, Spin } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { apiUrl } from "../../../../api/api_url";
import { getRequest } from "../../../../api/http";

import './ticketStyle.scss'

const TiketStyle = (props) =>{

    const history = useHistory();

    const [currentKey,setCurrentKey] = useState('');
    const [styleList,setStyleList] = useState([]);
    const [loading,setLoading] = useState(false);

    useEffect(() =>{
        getPrintStyleList()
    },[])
    
    const getPrintStyleList = async () =>{
        setLoading(true)
        let res = await getRequest(apiUrl.pringStyleList);
        if(res.code  == 1){
            setStyleList(res.data ?? []);
        }else{
            message.error(message ?? '请求失败')
        }
        setLoading(false)
    }

    const handleHover =(key) => setCurrentKey(key);
    const handleLeave =() => setCurrentKey('');

    const createWindow = (item,child)=> {
        return <div
                className="col_style"
                onMouseEnter={() => handleHover(item.type)}
                onMouseLeave={() => handleLeave()}
            >
            <div className="window_title">{item.name}</div>
            <div className="window_style">
                {child}
                {
                    currentKey == item.type ? <div className="mask_style" key={item.type}>
                        <Button type="primary" size="large" onClick={() => history.push('/sidebar/editTicket',{routerParams: item})}>编 辑</Button>
                    </div> : <></>
                    }
                
            </div>
        </div>
    }

    return <div className="tiket_style">
            <Spin spinning={loading}>
            <div className="tiket_body">
                {
                    styleList.map(item => {
                        return createWindow(item,
                                <img src={`https://ydn-siyu.cdn.shuachi.com/p_style_${item.type}.jpg`} className="defalut_img" /> 
                            )
                    })
                }
            </div>
            </Spin>
        </div>
 

}
export default TiketStyle;