import React from 'react';
import { Row, Col } from 'antd';
import '../management.scss';

interface Meal {
    history: any,
}

class Meal extends React.Component<Meal> {

    state = {
        list: [
            {
                id: 0,
                name: '位置管理',
                title: '如何添加位置？',
                time: '2020-08-24 15:59:26',
                detail: '/helpCenter/helpLocationManagementDetail',
            }
        ]
    }

    onGoPage(path: string) {
        this.props.history.push(path)
    }

    render() {
        const { list } = this.state;
        return  <Row>
                    {
                        list.map((item: any, index: any) => {
                            return (
                                <Row key={index + item} className="border" onClick={() => { this.onGoPage(item.detail) }}>
                                    <Col className="name">{item.name}</Col>
                                    <Col className="title">{item.title}</Col>
                                    <Col>{item.time}</Col>
                                </Row>
                            )
                        })
                    }
                </Row>
    }
}

export default Meal;