import React, { useEffect, useState } from 'react'
import { Row, Table, Tooltip, } from 'antd'
import { useHistory,  } from 'react-router-dom'
import { getRequest } from '../../../api/http'
import { apiUrl } from '../../../api/api_url'
import { getIsPermisson, messageCustom } from '../../../utils/permisstionUtil'
import new_detail from '../../../assret/images/new_detail.png'
import DatePicker from '../datePicker/index'
import OrderPadding from '../../order_manager/order_list/components/order_padding_detail';

import './index.scss'
interface IcurrentDrawerData {
    desk: string,
    amount: number,
    price: string,
    price_vip: string,
    code: string,
    status_display: string,
    dishes: Array<{
        mark: string,
        dishes: Array<any>,
        created_at: string
    }>
}

const FinanceOtherCoupon = (props:any)=>{

    const { currentTab } = props
    
    const formatData = (num:any) => {
        return num > 9 ? num : `0${num}`
    }

    //获取日期开始日
    const getMonthStart = (year:number,month:number) => {
        // 获取当前日期
        return year + '-' + month + '-01'
    }

    //获取日期结束日
    const getMonthEnd = (year:number,month:number) => {
        // 获取当前日期
        return year + '-' + month + '-' + new Date(year,formatData(month),0).getDate()
    }

    const history = useHistory()
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [paramsObj, setParamsObj] = useState<any>({
        page: 1,
        page_size: 10,
        start_time:getMonthStart(new Date().getFullYear(),formatData(new Date().getMonth()+1)),
        end_time:getMonthEnd(new Date().getFullYear(),formatData(new Date().getMonth()+1)),
        channel:'',
    });
    const [ finishOrderNum, setFinishOrderNum ] = useState(0)
    const [ discountRealPrice, setDiscountRealPrice ] = useState(0)

    const [currentDrawerData, setcurrentDrawerData] = useState<IcurrentDrawerData>({
        desk: '',
        dishes: [],
        amount: 0,
        price: '',
        price_vip: '',
        code: '',
        status_display: '',
    });
    const [showPaddingOrderDrawer, setshowPaddingOrderDrawer] = useState(false);


    useEffect(()=>{
        getList({...paramsObj})
    },[ currentTab ])

    const getList = async(data:any = {}) => {
        let param = {
            ...data
        }
        if(param.sortType)param.sortType=param.sortType=='ascend'?'asc':'desc'
        setParamsObj({...param})
        if(!param.channel){
            delete param.channel
        }
        const res:any = await getRequest(apiUrl.discountOrdersList, {...param});
        if(res && res['code'] === 1) {
            setTotal(res.data.items.total)
            setData(res.data.items.data)
            setFinishOrderNum(res.data.finish_order_num)
            setDiscountRealPrice(res.data.discount_real_price)
        }
    }

    // 显示订单详情
    const showOrderDetail = async (rowdata: any) => {
        if(!getIsPermisson('1_3_7_1')){
            messageCustom();
            return;
        }
        setcurrentDrawerData(rowdata);
        setshowPaddingOrderDrawer(true);
    }

    const creatTable = ()=>{
        const columns:any = [
            {
                title: '订单号',
                dataIndex: 'code',
            },
            {
                title: '就餐人数',
                dataIndex: 'user_amount',
            },
            {
                title: '代金券名称',
                dataIndex: 'coupon_name',
            },
            {
                title: '代金券来源',
                dataIndex: 'channel_name',
            },
            {
                title: '代金券价格',
                dataIndex: 'discount_real_price',
            },
            {
                title: '已核销',
                dataIndex: 'discount_coupon_num',
            },
            {
                title: '代金券实收',
                dataIndex: 'discount_real_price',
                render: (text:any,record:any) => text*record.discount_coupon_num
            },
            {
                title: '核销时间',
                dataIndex: 'paid_at',
            },
            {
                title: '操作',
                dataIndex: 'option',
                render: ( text:string, record:any)=><>
                    <Tooltip placement="bottomRight" title={'详情'}>
                        <img src={new_detail} className='table_icon' onClick={() => showOrderDetail(record)}></img>
                    </Tooltip>
                </>
            },
        ];


        return (
            <Table
                dataSource={data}
                columns={columns}
                onChange={handleTableChange}
                rowKey={'id'}
                pagination={{
                    defaultCurrent: 1,
                    current:paramsObj.page,
                    pageSize: paramsObj.page_size,
                    total: total,
                    showQuickJumper: true,
                    showSizeChanger: false,
                    showTotal: (total: number) => `共${total}条`,
                }}
            />
        );
    }

    const handleTableChange = (pagination:any, filters:any, sorter:any) => {
        getList({
            ...paramsObj,
            page:pagination.current,
            page_size:pagination.pageSize,
            sortField:sorter.field,
            sortType:sorter.order
        })
    }

    // 导出
    const exportTable = async () => {
        if(!getIsPermisson('1_3_7_2')){
            messageCustom();
            return;
        }
        const token = localStorage.getItem("token");
        const shop_id = localStorage.getItem("shop_id");
        window.open(`${process.env.REACT_APP_BASEURL}/v2/export/discountOrders?token=${token}&start_time=${paramsObj.start_time}&end_time=${paramsObj.end_time}${paramsObj.channel&&'&channel='+paramsObj.channel}&shop_id=${shop_id}`);
    };

    return (
        <div className="finance_other_coupon">
            <DatePicker callBack = {(data:any)=>{getList({...paramsObj,...data})}} exportTable={()=>{exportTable()}}/>
            <Row type="flex" align="middle" className="card">
                <Row type="flex" align="middle" justify="space-between" className="card_item">
                    <div>已核销</div>
                    <div>{finishOrderNum}</div>
                </Row>
                <Row type="flex" align="middle" justify="space-between" className="card_item">
                    <div>代金券实收</div>
                    <div>{discountRealPrice}</div>
                </Row>
            </Row>
            {
                creatTable()
            }
            {/* 订单详情 */}
            <OrderPadding
                showPaddingOrder={showPaddingOrderDrawer}
                changeVisitity={setshowPaddingOrderDrawer}
                code={currentDrawerData.code ? currentDrawerData.code : ''} 
            />            
        </div>
    )
}

export default FinanceOtherCoupon
