import React from 'react'
import { Radio, Select,Progress,Rate,Button,DatePicker,Table,Tooltip,message, Tag} from 'antd';

import style from "./commentlist.module.scss"
// import deleteBtn from "../../../assret/images/t_delete.png";
import {getRequest, postRequest, requestWithJson,getRequestWithoutShopId} from "../../../api/http";
import {apiUrl} from "../../../api/api_url";

const { Option } = Select;
const { RangePicker } = DatePicker;
export default class CommentList extends React.Component<any>{
    state = {
        star:0, // 按星级过滤 0:全部，1：1~3星中差评 2：4~5星好评
        dataList:[], // 用户评价数据列表
        currentPage:1, // 当前页面索引
        pageSize:10, // 每页数量
        count:0,    // 总共多少条数据
        startTime:"", // 查询的开始时间
        endTime:"", // 查询的结束时间
        defaultShop:"", // 当前选择的店铺id
        shops:{}, // 店铺列表
        shopInfo:[],
        ids:[], // 删除ids
        shop_id:null,
        shopData : { // 店铺汇总数据
            "goodCount": 0,
            "baddCount": 0,
            "shop_id": 0,
            "shop_name": "深圳拈江湖",
            "avg": 0.00,
            "avg_service": 0.00,
            "avg_food": 0.00,
            "detail": [
                {type: "one", value: 0, rate: "0%"},
                {type: "two", value: 0, rate: "0%"},
                {type: "three", value: 0, rate: "0%"},
                {type: "four", value: 0, rate: "0%"},
                {type: "five", value: 0, rate: "0%"},
            ],
            waiters: []
        },
        shopList:[],
        staffList: [],
        staffId: '0'
    }

    rowSelection: any = {
        onChange: (selectedRowKeys: any, selectedRows: any) => {
            console.log(selectedRowKeys)
            this.setState({
                ids:selectedRowKeys
            })
        }
    };
    async componentDidMount()
    {
        // 获取所有店铺信息
        let shopList:any = await this.getUseShops()
        let shopsObj:any = this.state.shops
        for(let index in shopList){
            let shop_id = shopList[index].shop_id + ""
            shopsObj[shop_id] = shopList[index].shop_name
        }
        const today = new Date()
        let year = today.getFullYear(),
            month = today.getMonth() + 1,
            st = year.toString() + '-' + (month < 10? '0': '') + month + '-' + '01',
            end_date = new Date(month === 12? year + 1: year, month === 12? 0: month, 0),
            et = year.toString() + '-' + (month < 10? '0': '') + month + '-' + (end_date.getDate() < 10? '0':'') + end_date.getDate(); 

        this.setState({
            // dataList:comments.list,
            // count:comments.count,
            startTime:st,
            endTime:et,
        })

        // 获取店铺汇总数据
        let shop_id = localStorage.getItem('shop_id')
        let shopData:any = await this.getShopInfo(shop_id, st, et)
        console.log(shopData.detail[0].value);
        
        // 获取店铺评价数据
        await this.getComments(shop_id, st, et);
        // const staffList:any = await this.getStaffList(shop_id)
        
        this.setState({
            // dataList:comments.list,
            // count:comments.count,
            shops:shopsObj, // key:value
            defaultShop:shop_id + "", // 默认shopid
            shopData, // 店铺汇总数据
            shopList  // 店铺原始数据list
            // staffList
        })
    }


    // getStaffList = async(shop_id:any) => {
    //     const res:any = await getRequest(apiUrl.getStaffList, {shop_id: shop_id, pageSize: 1000})
    //     let staffList:any = []
    //     if(res.code === 1){
    //     //    return res.data
    //         res.data.list.forEach((item:any)=>{
    //             staffList.push(<Option key={item.id}>{item.name}</Option>)
    //         });
    //     }

    //     return staffList
    // }

    // 获取用户下面所有店铺
    getUseShops = async()=>{
        const res:any = await postRequest(apiUrl.changeShopList)
        // const res:any = await postRequest("http://api.com//v1/shop-list-with-user")
        if(res.code === 1){
           return res.data
        }else{
            message.error("服务器请求数据失败")
            return 
        }
    }

    // 获取店铺汇总数据
    getShopInfo = async(id:any,st?:any, et?:any)=>{
        let strId:any = id + ""
        // const res:any = await getRequest("http://api.com/v1/comment/shop?id=" + strId)
        const res:any = await getRequestWithoutShopId(apiUrl.commentList,{"shop_id":id, st:st, et: et})
        if(res.code === 1){
            res.data.avg = parseFloat(res.data.avg);
           return res.data
        }else{
            console.log(res.message)
            return null
        }
    }

    // 获取店铺评价数据
    getComments = async(shopId?:any,st?:any, et?:any, star?:any,page?:any,pagesize?:any)=>{
        let shop_id = shopId
        // const res:any = await getRequest("http://api.com/v1/comment/list",{st:st, et:et,star:star,page:page,pagesize:pageSize})
        const res:any = await getRequestWithoutShopId(apiUrl.comments,{shop_id,st, et,star,page,pagesize,waiter_id:this.state.staffId})
        if(res.code === 1){
           let comments:any =  res.data
           for (let i = 0; i < comments.list.length; i++) {
               let comment = comments.list[i];
               comments.list[i].created_at_pay = comment.created_at_pay.substr(0,16); 
               comments.list[i].created_at_comment = comment.created_at_comment.substr(0,16); 
           }
           this.setState({
            dataList:comments.list,
            count:comments.count,
        })
            return 
        }else{
            console.log(res.message)
            return null
        }
    }

    // 切换店铺
    switchShopHandler = async(val:any)=>{
        // 获取店铺数据
        // 获取店铺汇总数据
        let shopData:any = await this.getShopInfo(val, this.state.startTime, this.state.endTime)
        // let shopId:any = {"shop_id":val}
        // 获取店铺评价数据
        await this.getComments(val, this.state.startTime, this.state.endTime)
        // const staffList:any = await this.getStaffList(val)

        let defaultShop:any = this.state.defaultShop
        defaultShop = val+""
        this.state.staffId = '0'
        this.setState({
            defaultShop,
            // dataList:comments.list,
            // count:comments.count,
            shopData,
            // staffList,
            staffId: '0'
        })
    }

    // 选择本月或者上月
    btnTimeClick = async(val:Number, target:any)=>{
        const shop_id:any = this.state.defaultShop
        const today = new Date()

        let buttonList = document.getElementsByClassName("ant-btn ant-btn-primary ant-btn-round ant-btn-lg");
        console.log(buttonList);
        for (let i = 0; i < buttonList.length; i++) {
            buttonList[i].classList.remove("ant-btn-primary");
        }

        let dom = target.currentTarget;
        dom.classList.remove("ant-btn-default");
        dom.classList.add("ant-btn-primary");

        let {st, et} = this.parseDate(val);

        const star: any = this.state.star
        const page: any = this.state.currentPage
        const pageSize: any = this.state.pageSize

        this.setState({
            // dataList:comments.list,
            // count:comments.count,
            startTime: st,
            endTime: et,
        })

        // 获取店铺评价数据
        await this.getComments(shop_id, st, et, star, page, pageSize)
        let shopData: any = await this.getShopInfo(shop_id, st, et)
        this.state.shopData = shopData
        this.setState({ shopData });

        this.staffOnChange(this.state.staffId)
    }

    // getDay = (day:any)=>{
    //     var today = new Date();
    //     var targetday_milliseconds=today.getTime() + 1000*60*60*24*day;
    //     today.setTime(targetday_milliseconds); //注意，这行是关键代码
    //     var tYear = today.getFullYear();
    //     var tMonth = today.getMonth();
    //     var tDate = today.getDate();
    //     tMonth = this.doHandleMonth(tMonth + 1);
    //     tDate = this.doHandleMonth(tDate);
    //     return tYear+"-"+tMonth+"-"+tDate;
    // }
    // doHandleMonth = (month:any)=>{
    //     var m = month;
    //     if(month.toString().length == 1){
    //      m = "0" + month;
    //     }
    //     return m;
    // }

    // 选择时间区间
    timeChange = async (time: any, timeStr:[string, string]) => {
        let buttonList = document.getElementsByClassName("ant-btn ant-btn-primary ant-btn-round ant-btn-lg");
        for (let i = 0; i < buttonList.length; i++) {
            buttonList[i].classList.remove("ant-btn-primary");
        }

        const shop_id:any = this.state.defaultShop
        const et:any = timeStr[1]
        const st:any = timeStr[0]
        const star:any = this.state.star
        const page:any = this.state.currentPage
        const pageSize:any = this.state.pageSize
        // 获取店铺评价数据
        await this.getComments(shop_id,st,et,star,page,pageSize)
        let shopData:any = await this.getShopInfo(shop_id,st,et)

        this.setState({
            // dataList:comments.list,
            // count:comments.count,
            startTime:st,
            endTime:et,
            shopData
        })
    }

    // 星级过滤
    starOnChange = async(e:any)=>{
        const star:any =  e.target.value
        // console.log(star)
        this.setState({star})
        // 请求数据
        const shop_id:any = this.state.defaultShop
        const st :any = this.state.startTime
        const et :any = this.state.endTime
        // const page:any = this.state.currentPage
        // const pageSize:any = this.state.pageSize
        await this.getComments(shop_id,st,et,star)
            this.setState({
                // dataList:comments.list,
                // count:comments.count,
                star
            })

    }

     // 服务员过滤
     staffOnChange = async(staffId:any)=>{
        this.state.staffId = staffId
        let staff:any = {}
        for(let i = 0; i < this.state.shopData.waiters.length; i++) {
            let item:any = this.state.shopData.waiters[i];
            if (item.waiter_id.toString() === staffId) {
                staff = item;
            }
        }

        this.setState({staffId, shopData: {
            ...this.state.shopData,
            avg: staff.avg || 0,
            avg_service: staff.avg_service || 0,
            avg_food: staff.avg_food || 0,
            goodCount: staff.good_count || 0,
            baddCount: staff.bad_count || 0,
            detail: staff.detail && staff.detail.length === 5 && staff.detail || [
            {type: "one", value: 0, rate: "0%"},
            {type: "two", value: 0, rate: "0%"},
            {type: "three", value: 0, rate: "0%"},
            {type: "four", value: 0, rate: "0%"},
            {type: "five", value: 0, rate: "0%"},
        ]}})
        // 请求数据
        const shop_id:any = this.state.defaultShop
        const st :any = this.state.startTime
        const et :any = this.state.endTime
        // const page:any = this.state.currentPage
        // const pageSize:any = this.state.pageSize
        await this.getComments(shop_id,st,et,this.state.star)
    }

    // 删除指定id数据
    // deleteBtnClick = async(index:any)=>{
    //     let dataList :any = this.state.dataList
    //     let ids:any = []
    //     ids.push(dataList[index].id)
    //     // 删除评价数据
    //     await this.deleteComment(ids)
    //     // 重新加载页面数据
    //     const shop_id:any = this.state.defaultShop
    //     const st :any = this.state.startTime
    //     const et :any = this.state.endTime
    //     const page:any = this.state.currentPage 
    //     const star:any = this.state.star 
    //     const pageSize:any = this.state.pageSize
    //     await this.getComments(shop_id,st,et,star,page,pageSize)
    // }

    // 删除数据
    // deleteComment = async(ids:any)=>{
    //     // const res:any = await postRequest(apiUrl.deleteComment)
    //     const shop_id:any = this.state.defaultShop
    //     const res:any = await requestWithJson("delete",apiUrl.deleteComment,{"shop_id":shop_id},{"ids":ids,})
    //     if(res.code === 1){
    //        return message.success("删除成功")
    //     }else{
    //         message.error("服务器请求数据失败")
    //         return 
    //     }
    // }
    // 改变页面索引
    pageChange = async(page:number)=>{
        const st :any = this.state.startTime
        const et :any = this.state.endTime
        const star :any = this.state.star
        const pageSize :any = this.state.pageSize
        const shopId :any = this.state.defaultShop
        this.setState({ currentPage:page})
        console.log("================",page)
        await this.getComments(shopId, st,et,star,page,pageSize)
    }

    // 改变每页数量
    onSizeChange = async()=>{
        const st :any = this.state.startTime
        const et :any = this.state.endTime
        const star :any = this.state.star
        const pageSize :any = this.state.pageSize
        const shopId :any = this.state.defaultShop
        let comments:any = await this.getComments(shopId,st,et,star,1,pageSize)
    }

    parseDate = (type:Number) => {
        // 解析日期
        let startDate:Date = new Date(),
            year: number = startDate.getFullYear(),
            month: number = startDate.getMonth(),
            endDate: Date = new Date();

        switch (type) {
            case 2: // 上月
                startDate = new Date(month === 0 ? year - 1 : year, month === 0 ? 11 : month - 1, 1);
                endDate = new Date(year, month, 0);
                break;

            case 3: // 近3月
                startDate = new Date(startDate.getTime() - 90*86400000);
                break;

            case 4: // 近半年
                startDate = new Date(startDate.getTime() - 180*86400000);
                break;

            default: // 1/本月
                endDate = new Date(month === 12 ? year + 1 : year, month === 11 ? 0 : month + 1, 0);
        }

        let st = startDate.getFullYear().toString() + '-' + (startDate.getMonth() < 9 ? '0' : '')
                + (startDate.getMonth() + 1).toString() + '-' + '01',
            et = endDate.getFullYear().toString() + '-' + (endDate.getMonth() < 9 ? '0' : '')
                + (endDate.getMonth() + 1).toString() + '-' + (endDate.getDate() < 10 ? '0' : '')
                + endDate.getDate(); 

        return {st, et};
    }

    // 批量删除
    // deleteComments = async()=>{
    //     const ids:any = this.state.ids
    //     const dataList:any = this.state.dataList
    //     const st :any = this.state.startTime
    //     const et :any = this.state.endTime
    //     const page:any = this.state.currentPage
    //     const pageSize:any = this.state.pageSize
    //     const star:any = this.state.star
    //     const shopId :any = this.state.defaultShop
    //     // 删除
    //     await this.deleteComment(ids)
    //     // 重新加载数据
    //     let comments:any = await this.getComments(shopId,st,et,star,page,pageSize)
    //         this.setState({
    //             // dataList:comments.list,
    //             // count:comments.count,
    //             star:star,
    //             ids:[]
    //         })
    // }
    render(){

        const columns = [
            {
                title: '订单号',
                dataIndex: 'order_code',
            },
            {
                title: '桌号/服务员',
                dataIndex: 'table_name',
                render: (text:any, record:any, index:number) => {
                    return <>{record.table_name}{(record.waiter_name && record.waiter_name !== '未知'? <div><Tag color="blue">{record.waiter_name}</Tag></div>: <></>)}</>
                }
            },
            {
                title: '用户',
                dataIndex: 'nick_name',
                render: (text:any, record:any, index:number) => {
                    return <>{record.nick_name}{(record.consume_time_desc? <div><Tag color="purple">{record.consume_time_desc}</Tag></div>:<></>)}</>
                }
            },
            {
                title: '用户等级',
                dataIndex: 'user_level',
            },
            {
                title: '用户类型',
                dataIndex: 'user_type',
            },
            {
                title: '消费时间',
                dataIndex: 'created_at_pay',
            },
            {
                title: '评价时间',
                dataIndex: 'created_at_comment',
            },
            {
                title: '总评分',
                dataIndex: 'total_score',
            },
            {
                title: '服务评分',
                dataIndex: 'service_score',
            },
            {
                title: '菜品评分',
                dataIndex: 'food_score',
            },
            {
                title: '满意菜品',
                dataIndex: 'good_foods',
                render:(text: any, data: any,index: number)=>(
                    <div>
                        {
                            data.good_foods.map((food:any)=>{
                                return <div key= {food.goods_id}>{food.goods_name}</div>
                            })
                        }
                    </div>
                )
            },
            {
                title: '不满意菜品',
                dataIndex: 'bad_foods',
                render:(text: any, data: any,index: number)=>(
                    <div>
                        {
                            data.bad_foods.map((food:any)=>{
                                return <div key= {food.goods_id}>{food.goods_name}</div>
                            })
                        }
                    </div>
                )
            },
            {
                title: '评价内容',
                dataIndex: 'content',
                width: 500,
            },
            // {
            //     title: '操作',
            //     dataIndex: 'action',
            //     render: (text: any, record: any,index: number) => (
            //         <div className="btn_wrap">
            //             <Tooltip placement="bottomRight" title={'删除'}>
            //                 <img src={deleteBtn} className='table_icon' onClick={()=>this.deleteBtnClick(index)} ></img>
            //             </Tooltip>
            //         </div>
            //     ),
            // }
          ];
        const shops:any = this.state.shops;
        const defaultShop = this.state.defaultShop
        let rateNum :number = Number(this.state.shopData.avg)
        return  <div className={style.comment_list}>
                    {/* <div className={style.group_index}>
                        <div className={style.group_tab_wrap}>
                            <div className={`${style.group_tab_item} ${style.group_tab_active}`} >评价数据</div>
                        </div>
                    </div> */}
                    <div className={style.l_content}>
                        <div className={style.comment_title}>评价数据</div>
                        <div className={style.comment_sel_time}>
                            <div className={style.comment_time_title}>时间</div>
                            <div className={style.comment_time_btn1}>
                                <Button type="primary" size="large" shape="round"  onClick={(e)=>{this.btnTimeClick(1, e) }}>本月</Button>
                            </div>
                            <div className={style.comment_time_btn2}>
                                <Button type="default" size="large" shape="round"  onClick={(e)=>{this.btnTimeClick(2, e) }} >上月</Button>
                            </div>
                            <div className={style.comment_time_btn2}>
                                <Button type="default" size="large" shape="round"  onClick={(e)=>{this.btnTimeClick(3, e) }} >近3月</Button>
                            </div>
                            <div className={style.comment_time_btn2}>
                                <Button type="default" size="large" shape="round"  onClick={(e)=>{this.btnTimeClick(4, e) }} >近半年</Button>
                            </div>
                            <div className={style.comment_time_range}>
                            <RangePicker onChange={this.timeChange} placeholder={["开始时间","结束时间"]} format="YYYY-MM-DD"/>
                            </div>
                        </div>
                        <div className={style.comment_data}>
                            <div className={style.comment_data_left}>
                                <div className={style.comment_shop}>
                                    <Select defaultValue={shops[defaultShop]} value={shops[defaultShop]} style={{ width: 150 }} onChange={(val:any)=>this.switchShopHandler(val)}>
                                        {
                                            this.state.shopList.map((item:any,index:any)=>{
                                                return <Option key={index + item.shop_id} value={item.shop_id}>{item.shop_name}</Option>
                                            })
                                        }
                                    </Select>
                                    <div className={style.comment_star_title} style={{marginLeft:60,display:'inline-block'}}>服务员</div>
                                    <Select className={style.comment_group}
                                        style={{ width: 150, marginLeft: 10, display: 'inline-block' }}
                                        value={this.state.staffId}
                                        placeholder='全部'
                                        onChange={(e: any) => {
                                            this.staffOnChange(e)
                                        }}
                                    >
                                        {this.state.shopData.waiters.map((item:any) => {
                                            return <Option key={'staff-' + item.waiter_id} value={item.waiter_id.toString()}>{item.name}</Option>
                                        })}
                                    </Select>
                                </div>
                                <div className={style.comment_star}>
                                    <div className={style.comment_circle}>
                                        <Progress type="circle" 
                                        strokeColor={{
                                            '0%': '#FF4545',
                                            '100%': '#FF4545',
                                        }}
                                        strokeWidth = {12}
                                        percent={this.state.shopData.avg / 5.0 *100} format={percent => `${parseFloat(this.state.shopData.avg.toFixed(2))}`} width={80} />
                                    </div>
                                    <div className={style.comment_rate}>
                                        <Rate disabled value={rateNum}/>
                                    </div>
                                </div>
                                <div style={{marginTop: 20, marginBottom: 10}}>服务评分：{this.state.shopData.avg_service.toFixed(2)}</div>
                                <div>菜品评分：{this.state.shopData.avg_food.toFixed(2)}</div>
                            </div>
                            <div className={style.comment_data_right}>
                                <div className={style.title}>
                                    <div className={style.title_good}>
                                        好评
                                    </div>
                                    <div className={style.title_bad}>
                                        中差评
                                    </div>
                                </div>
                                <div>
                                    <Progress showInfo={false}
                                        strokeWidth = {23.7}
                                        strokeColor={{
                                            '0%': '#FF3A3A',
                                            '100%': '#FFB07E',
                                        }}
                                        percent={this.state.shopData.goodCount / (this.state.shopData.goodCount + this.state.shopData.baddCount) *100}
                                    />
                                </div>
                                <div className={style.count}>
                                    <div className={style.star_good}>{this.state.shopData.goodCount}</div>
                                    <div className={style.star_bad}>{this.state.shopData.baddCount}</div>
                                </div>
                            </div>
                        </div>
                        
                        <div className={style.comment_sel_star}>
                            <div className={style.comment_star_title}>
                                星级
                            </div>
                            <div className={style.comment_star_filter}>
                                <Radio.Group className={style.comment_group} onChange={(e:any)=>this.starOnChange(e)} value={this.state.star}>
                                    <div className={style.comment_star_none}> 
                                        <Radio  value={0}>全部</Radio>
                                    </div>
                                    <div className={style.comment_star} >
                                        <Radio value={1}>1星评价({this.state.shopData.detail[0].value})</Radio>
                                    </div>
                                    <div className={style.comment_star} >
                                        <Radio value={2}>2星评价({this.state.shopData.detail[1].value})</Radio>
                                    </div>
                                    <div className={style.comment_star} >
                                        <Radio value={3}>3星评价({this.state.shopData.detail[2].value})</Radio>
                                    </div>
                                    <div className={style.comment_star} >
                                        <Radio value={4}>4星评价({this.state.shopData.detail[3].value})</Radio>
                                    </div>
                                    <div className={style.comment_star}>
                                        <Radio value={5}>5星评价({this.state.shopData.detail[4].value})</Radio>
                                    </div>
                                </Radio.Group>
                            </div>
                        </div>
                        {/* <div className={style.comment_mul_delete} onClick={this.deleteComments}> */}
                            {/* <img src={deleteBtn} /> */}
                            {/* <span>批量删除</span> */}
                        {/* </div> */}
                        <div className={style.data_table}>
                            <Table
                                // rowSelection={this.rowSelection}
                                dataSource={this.state.dataList}
                                columns={columns}
                                rowKey={"id"}
                                size="middle"
                                locale={{ emptyText: '暂无数据' }}
                                pagination={{
                                    current: this.state.currentPage,
                                    defaultCurrent: 1,
                                    total: this.state.count,
                                    pageSize: this.state.pageSize,
                                    showQuickJumper: true,
                                    showSizeChanger: true,
                                    onChange :this.pageChange,
                                    showTotal: (total: number) => `共${total}条`,
                                    onShowSizeChange: (current:number, size: number) => {this.setState({pageSize: size}, () => {this.onSizeChange();})}
                                }} 
                            />
                        </div>
                    </div>
                </div>
    }
}




