import React, { useEffect, useState } from 'react'
import { Modal, Input, Row, Col, Badge } from 'antd'
import { useHistory } from 'react-router-dom';
import './selectHumanNumber.scss'

const SelectHumanNumber = (props: any)=>{
  const history:any = useHistory();
  const [data,setdata] = useState<any>({})
  useEffect(()=>{
      setdata(props.data)
      setsetMealListData(props.list)
  })
  const [selectNum,setselectNum] = useState(0)
  const selectNumAction = (num:number) =>{
    setselectNum(num)
  }

  const [setMealListData,setsetMealListData] = useState<any>([])

  const windowNumberFor = ()=>{
    let list = []
    for (let i = 1; i < 11; i++) {
      list.push(i)
    }
    return list
  }

  const onOkAction = ()=>{
    if(selectNum === 0){
      return false
    }
    return selectNum
  }

  const inputOnChange = (inputData:any) =>{
    setselectNum(inputData)
  }
  const [windowNumber,setwindowNumber] = useState(windowNumberFor())

  //添加套餐到点菜
  const addSetMealAction = async (id:number,index:number) => {
    setMealListData[index].action = true
    setMealListData[index].count = setMealListData[index].count ? setMealListData[index].count+1 : 1
    setMealListData[index].currentCount = setMealListData[index].currentCount ? setMealListData[index].currentCount+1 : 1
    refreshAction()
  }

  const [refresh,setRefresh] = useState(false);
  const refreshAction = () => {
    setRefresh((re)=> !re)
  }

  return (
    <Modal
        title={data.title ? data.title : "选择用餐人数"}
        visible={data.visible}
        onOk={data.onOk ? () => { data.onOk( onOkAction(), setMealListData)} : ()=>{}}
        onCancel={data.onCancel ? data.onCancel : ()=>{}}
        width="80%"
        okText='确定'
        cancelText='取消'
      >
        <div className="selectHumanNumber">
          <div className="name">
            <span>{data.tableName}桌</span>
            用餐人数
          </div>
          <Row type='flex' align='middle' justify='start'>
            <Col>
              <div className="list">
                {
                  windowNumber.map((item:any) =>{
                    return(
                      <div className={selectNum === item ? "item_num sel" : "item_num"} onClick={()=>{selectNumAction(item)}} key={item}>{item}</div>
                    )
                  })
                }
              </div>
            </Col>
            <Col span={5}>
              <div className="other">
                <div>其它人数：</div>
                <Input placeholder="请输入用餐人数" className="input" onChange={(e: any) => {inputOnChange(e.target.value) }} />
              </div>
            </Col>
          </Row>

        {
          !data.hideSet
          ? <div>
              <div className="name">选择套餐</div>
                <div className="list">
                    {
                      setMealListData.map((item:any,index:number) => {
                        return (
                          <div className='item' key={`setMeal_${index}`} onClick={()=> {
                            data.onSelectMeal(item.id,index, setMealListData[index])
                          }}>
                            <div className="box">
                              <Badge count={item.count ? item.count : 0}></Badge>
                            </div>
                            <div className='one'>
                                <div className=''>{item.name}</div>
                              </div>
                              <div className='price'>{item.price}</div>
                          </div>

                        )
                      })
                    }
                  </div>
            </div>
          : null
        }



        </div>
    </Modal>
  )
}

export default SelectHumanNumber