import {observer} from "mobx-react";
import React from "react";
import { Table, Modal, message, Row, Col, Button} from "antd";
import { postRequest, getRequest } from '../../api/http';
import { apiUrl } from '../../api/api_url';
import { Link } from "react-router-dom";
import { getIsPermisson, messageCustom } from "../../utils/permisstionUtil";

const key = 'updatable';

@observer
class PrinterDepart extends React.Component<any> {
    state = {
        productList: [],
    }

    columns = [
        {
            title: '打印机部门名称',
            dataIndex: 'department_name',
            key: 'department_name',
            className: 'list_center',
        },
        {
            title: '打印机名称',
            dataIndex: 'print_name',
            key: 'print_name',
            className: 'list_center',
        },
        {
            title: '最后修改人',
            dataIndex: 'nickname',
            key: 'nickname',
            className: 'list_center',
        },
        {
            title: '最后修改时间',
            dataIndex: 'updated_at',
            key: 'updated_at',
            className: 'list_center',
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            className: 'list_center',
            render: (text:any,record:any,) => (
                <div className="btn_wrap">
                    <div className="list_btn" onClick={() =>{
                        if(!getIsPermisson('2_3_3_2')){
                            messageCustom();
                            return;
                          }
                          this.props.history.push({pathname:'/sidebar/printerDepartEdit', state:{record: record}})
                    }}>配置</div>
                    <div className="list_btn" onClick={() => this.deletePrinter(record)}>删除</div>
                </div>
            ),
        },
    ];

    componentDidMount(){
        this.onInitList();
    }

    // 初始化列表
    onInitList = async () => {
        const params:any = {
        };
        const res:any = await getRequest(apiUrl.printDepartList,params);
        if(res.code === 1){
            this.setState({
                productList: res.data,
            })
        }
    }

    // 删除打印机
    deletePrinter = (record: any) => {
        if(!getIsPermisson('2_3_3_3')){
            messageCustom();
            return;
          }
        Modal.confirm({
            title: '删除该打印机部门',
            content: `确认删除打印机部门${record.department_name}？`,
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                const res:any = await getRequest(apiUrl.printDepartDel + '/' + record.id);
                if(res.code === 1){
                    message.success({ content: '删除成功!', key, duration: 2 });
                }
                this.onInitList();
            },
            onCancel() {
            },
        });
    }

    render(){
        return <div className='l_content'>
            <Row className='mb40'>
                <Col span={24}>
                        <Button type="primary" size="large" onClick={() =>{
                             if(!getIsPermisson('2_3_3_1')){
                                messageCustom();
                                return;
                              }
                              this.props.history.push('/sidebar/printerDepartEdit')
                        }}>
                            增加打印部门
                        </Button>
                </Col>
            </Row>

            <div>
                <Table dataSource={this.state.productList}  size="middle" columns={this.columns} rowKey={"id"} locale={{emptyText: '暂无数据'}} />
            </div>

        </div>;
    }
}
export default PrinterDepart