import {observer} from "mobx-react";
import React from "react"; 
import {Button, Row, Col, Input, Table, Select, DatePicker, message, Pagination, Tabs} from "antd";
import { getRequest, postRequest } from '../../api/http';
import { apiUrl } from '../../api/api_url';
import "./coupon.scss";
import { Link } from "react-router-dom";
import { getIsPermisson, messageCustom } from "../../utils/permisstionUtil";
const { Option } = Select;
const { TabPane } = Tabs;

const { Search } = Input;
const { RangePicker } = DatePicker;

@observer
class Coupon extends React.Component<any> {
    state = {
        visible: false,
        couponList: {
          total: 0,
          list: []
        },
        currentPage: 1,
        pageSize: 10,
        type: '',
        name: '',
        userList: [],
        start_time: '',
        end_time: '',
        add_user: 0,
        status: '2'
    }

    columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            className: 'list_center',
        },
        {
            title: '优惠名称',
            dataIndex: 'name',
            key: 'name',
            className: 'list_center',
        },
        {
            title: '优惠券类型',
            dataIndex: 'info',
            key: 'info',
            className: 'list_center',
            render: (text:any,record:any,) => (
                <div className="color">{record.type === 'discount' ? '折扣券' : record.type === 'voucher' ? '优惠券' : ''}</div>
            ),
        },
        {
            title: '有效期',
            dataIndex: 'time',
            key: 'time',
            className: 'list_center',
            render: (text:any,record:any,) => (
                <div className="color">{record.expire_info.type === 'day' ? `距领取日${record.expire_info.day}天内` : `${record.expire_info.start_time} - ${record.expire_info.end_time}`}</div>
            ),
        },
        {
            title: '推广渠道',
            dataIndex: 'position',
            key: 'position',
            className: 'list_center',
        },
        {
            title: '发行数量',
            dataIndex: 'amount',
            key: 'amount',
            className: 'list_center',
        },
        {
            title: '每人限领',
            dataIndex: 'limit_value',
            key: 'limit_value',
            className: 'list_center',
        },
        {
            title: '已领取',
            dataIndex: 'receive_amount',
            key: 'receive_amount',
            className: 'list_center',
            render: (rowData:any, record: any) => {
                return <div className='btn_green' onClick={() => {this.addGroup(rowData, record, 'get')}}>{rowData}</div>
            }
        },
        {
            title: '未领取',
            dataIndex: 'stock',
            key: 'stock',
            className: 'list_center',
        },
        {
            title: '已使用',
            dataIndex: 'used_count',
            key: 'used_count',
            className: 'list_center',
            render: (rowData:any, record: any) => {
                return <div className='btn_green' onClick={() => {this.addGroup(rowData, record, 'use')}}>{rowData}</div>
            }
        },
        {
            title: '创建时间',
            dataIndex: 'created_at',
            key: 'created_at',
            className: 'list_center',
        },
        {
            title: '状态',
            dataIndex: 'status_text',
            key: 'status_text',
            className: 'list_center',
        },
        {
            title: '创建者',
            dataIndex: 'nickname',
            key: 'nickname',
            className: 'list_center',
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            className: 'list_center',
            render: (text:any,record:any,) => (
                <div className="btn_wrap">
                    <div className='list_btn' onClick={() => this.copyCoupon(record.id)}>复制活动</div>
                    <div className="list_btn" onClick={() =>{
                         if(!getIsPermisson('1_12_1_3')){
                            messageCustom();
                            return;
                          }
                          this.props.history.push({pathname:'/sidebar/couponAdd', state:{id: record.id, edit: true}})
                    }}>编辑</div>
                    <div className='list_btn' onClick={() => this.statusCoupon(record.id, record.status)}>{record.status === 2 ? '停用' : record.status === 0 ? '启用' : ''}</div>
                </div>
            ),
        },
    ];

    componentDidMount(){
        this.onInitList();
    }

    onInitList  = async () => {
        const {currentPage, pageSize, type, start_time, end_time, add_user, status, name} = this.state;
        const params:any = {
            current: currentPage,
            pageSize,
        };
        if(name !== ''){
            params.name = name;
        }
        if(type !== '' && type !== '全部'){
            params.type = type;
        }
        if(start_time !== ''){
            params.start_time = start_time;
            params.end_time = end_time;
        }

        if(add_user){
            params.add_user = add_user;
        }

        if(status){
            params.status = status;
        }
        const res:any = await getRequest(apiUrl.couponList,params);
        if(res.code === 1){
            this.setState({
                couponList: res.data,
                searchName: name,
                userList: res.data.add_user,
            })
        }
    }

    pageChange = async (page:number) => {
        this.setState({
            currentPage: page
        }, () => {
            this.onInitList();
        })
    }

    searchCoupon = (name: string) => {
        this.setState({
            name
        }, () => {
            this.onInitList();
        })
    }

    // 类型
    typeChange = async (type:string) => {
        this.setState({
            type
        }, () => {
            this.onInitList();
        })
    }

    statusChange = (status: string) => {
        this.setState({
            status,
            currentPage: 1
        }, () => {
            this.onInitList()
        })
    }

    userChange = (e: any) => {
        this.setState({
            add_user: e
        }, () => {
            this.onInitList();
        })
    }

    timeChange = (time: any, timeString: [any, any]) => {
        this.setState({
            start_time: timeString[0],
            end_time: timeString[1]
        }, () => {
            this.onInitList();
        })
    }

    copyCoupon = async (id: number) => {
        if(!getIsPermisson('1_12_1_2')){
            messageCustom();
            return;
          }
        this.props.history.push({
            pathname: '/sidebar/couponAdd',
            state: {
                id: id,
                type: 'copy'
            }
        });
    }

    statusCoupon = async (id: number, status: number) => {
        if(!getIsPermisson('1_12_1_4')){
            messageCustom();
            return;
          }
        if(status == 2){
            status = 0;
        }else if(status == 0){
            status = 2;
        }
        const res: any = await  postRequest(apiUrl.changeVoucherStatus, {id, status})
        if(res.code === 1){
            message.success('修改成功');
            this.onInitList();
        }
    }

    // 跳转添加分组页面
    addGroup = (row:any, record: any, type: string) => {
        
        if(row > 0){
            this.props.history.push({
                pathname: '/sidebar/couponGroup',
                state: {
                    id: record.id,
                    type
                }
            });
        }
    }

    render(){
        const { couponList, userList, status } = this.state;
        return <div className='l_content'>
            <Row>
                <Col span={24}>
                    <Button type="primary" size="large" icon="plus" onClick={() =>{
                         if(!getIsPermisson('1_12_1_1')){
                            messageCustom();
                            return;
                          }
                          this.props.history.push('/sidebar/couponAdd')
                    }}>
                            添加新券
                        </Button>
                </Col>
            </Row>
            <Row type="flex" align="middle" justify="start" className="mt20 mb40" >
                <Col className="mr30">
                    <span>优惠名称：</span>
                    <Search placeholder="请输入优惠券名称" onSearch={this.searchCoupon} style={{ width: 234 }}/>
                </Col>
                <Col className="mr30">
                    <span>优惠券类型：</span>
                    <Select allowClear placeholder="请选择优惠券类型" style={{ width: 170 }} onChange={this.typeChange}>
                        <Option value="discount">折扣券</Option>
                        <Option value="voucher">优惠券</Option>
                    </Select>
                </Col>
                {/* <Col className="mr30">
                    <span>状态：</span>
                    <Select defaultValue="全部" style={{ width: 120 }} onChange={this.statusChange}>
                        <Option value="">全部</Option>
                        <Option value="2">已启用</Option>
                        <Option value="0">已停用</Option>
                        <Option value="3">已领完</Option>
                    </Select>
                </Col> */}
                <Col className="mr30">
                    <span>创建者：</span>
                    <Select placeholder="请选择创建者" allowClear style={{ width: 150 }} onChange={this.userChange}>
                        {
                            userList.map((item: any) => {
                                return <Option value={item.key} key={item.key}>{item.value}</Option>
                            })
                        }
                    </Select>
                </Col>
                <Col className="mr30">
                    <span>创建时间：</span>
                    <RangePicker onChange={this.timeChange} placeholder={["开始时间","结束时间"]} format="YYYY-MM-DD"/>
                </Col>
            </Row>

            <Tabs defaultActiveKey={status} onChange={this.statusChange}>
                <TabPane tab="进行中" key="2"></TabPane>
                <TabPane tab="已领完" key="3"></TabPane>
                <TabPane tab="已停用" key="0"></TabPane>
                <TabPane tab="已过期" key="4"></TabPane>
            </Tabs>

            <div>
                <Table dataSource={couponList.list} size="middle" columns={this.columns} rowKey={"id"} locale={{emptyText: '暂无数据'}} pagination={{
                    showQuickJumper: true,
                    defaultCurrent: 1,
                    total: couponList.total,
                    pageSize: this.state.pageSize,
                    onChange: this.pageChange,
                    showSizeChanger: true,
                    showTotal: (total: number) => `共${total}条`,
                    onShowSizeChange: (current:number, size: number) => {this.setState({pageSize: size}, () => {this.onInitList();})}
                }}/>
            </div>
        </div>;
    }
}
export default Coupon