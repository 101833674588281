import React from "react";
import { Table, Modal, message, Row, Col, Button, Select, DatePicker, Input} from "antd";
import { postRequest, getRequest } from '../../api/http';
import { apiUrl } from '../../api/api_url';
import { Link } from "react-router-dom";
import { getIsPermisson, messageCustom } from "../../utils/permisstionUtil";

const { Search } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;

const key = 'updatable';

class HelpList extends React.Component<any> {
    state = {
        equityList: [],
        current: 1,
        pageSize: 10,
        total_count: 0,
        userList: [],
        start_time: '',
        end_time: '',
        add_user: 0,
        status: null,
        name: ''
    }

    columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            className: 'list_center',
        },
        {
            title: '活动名称',
            dataIndex: 'name',
            key: 'name',
            className: 'list_center',
        },
        {
            title: '活动奖品',
            dataIndex: 'award',
            key: 'award',
            className: 'list_center',
            render: (text:any,record:any,) => (
                <div>{record.type === 1 ? record.award.name : record.award}</div>
            ),
        },
        {
            title: '助力类型',
            dataIndex: 'type',
            key: 'type',
            className: 'list_center',
            render: (text:any,record:any,) => (
                <div>{record.type === 1 ? '助力送菜' : '助力拆券'}</div>
            ),
        },
        {
            title: '已领取',
            dataIndex: 'receive_amount',
            key: 'receive_amount',
            className: 'list_center',
        },
        {
            title: '已使用',
            dataIndex: 'used_amount',
            key: 'used_amount',
            className: 'list_center',
        },
        {
            title: '创建时间',
            dataIndex: 'created_at',
            key: 'created_at',
            className: 'list_center',
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            className: 'list_center',
            render: (text:any,record:any,) => (
                <div>{record.status === 1 ? '开启中' : '暂停中'}</div>
            ),
        },
        {
            title: '创建者',
            dataIndex: 'add_user',
            key: 'add_user',
            className: 'list_center',
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            className: 'list_center',
            render: (text:any,record:any,) => (
                <div className="btn_wrap">
                    <div className="list_btn" onClick={() =>{
                        if(!getIsPermisson('1_12_12_2')){
                            messageCustom();
                            return;
                        }
                        this.props.history.push({pathname:'/sidebar/helpEdit', state:{id: record.id}})
                    }}>编辑</div>
                    {
                        record.status === 1
                        ? <div className="list_btn" onClick={() => this.pauseList(record)}>停用</div>
                        : <div className="list_btn" onClick={() => this.startList(record)}>启用</div>
                    }
                </div>
            ),
        },
    ];

    componentDidMount(){
        this.onInitList();
    }

    // 初始化列表
    onInitList = async () => {
        let { current, pageSize, add_user, name, status, start_time, end_time } = this.state;
        const params:any = {
            current,
            pageSize,
        };
        if(name !== ''){
            params.name = name;
        }
        if(add_user){
            params.add_user = add_user;
        }
        if(status){
            params.status = status;
        }
        if(start_time !== ''){
            params.start_time = start_time;
            params.end_time = end_time;
        }
        const res:any = await getRequest(apiUrl.dishesHelpConfigList,params);
        if(res.code === 1){
            this.setState({
                equityList: res.data.list,
                total_count: res.data.total_count,
                userList: res.data.add_user
            })
        }
    }

    pageChange = async (page:number) => {
        this.setState({
            current: page
        }, () => {
            this.onInitList()
        })
    }

    // 暂停福利
    pauseList = (record:any) => {
        if(!getIsPermisson('1_12_12_3')){
            messageCustom();
            return;
        }
        Modal.confirm({
            title: '暂停该福利',
            content: `设为暂停后，用户将无法领取该福利优惠券，是否确认暂停该福利？`,
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                const params = {
                    id: record.id
                }
                const res:any = await getRequest(apiUrl.zhuliStart ,params);
                if(res.code === 1){
                    message.success({ content: '暂停成功!', key, duration: 2 });
                }
                this.onInitList();
            },
            onCancel() {
            },
        });
    }

    // 开启福利
    startList = (record:any) => {
        if(!getIsPermisson('1_12_12_3')){
            messageCustom();
            return;
        }
        Modal.confirm({
            title: '开启该活动',
            content: `设为开启后，用户可领取该福利优惠券，是否确认开启该福利？`,
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                const params = {
                    id: record.id
                }
                const res:any = await getRequest(apiUrl.zhuliStart,params);
                if(res.code === 1){
                    message.success({ content: '开启成功!', key, duration: 2 });
                }
                this.onInitList();
            },
            onCancel() {
            },
        });
    }

    searchCoupon = (name: string) => {
        this.setState({
            name
        }, () => {
            this.onInitList();
        })
    }

    statusChange = (status: string) => {
        this.setState({
            status
        }, () => {
            this.onInitList()
        })
    }

    userChange = (e: any) => {
        this.setState({
            add_user: e
        }, () => {
            this.onInitList();
        })
    }

    timeChange = (time: any, timeString: [any, any]) => {
        this.setState({
            start_time: timeString[0],
            end_time: timeString[1]
        }, () => {
            this.onInitList();
        })
    }

    statusCoupon = async (id: number, status: number) => {
        const res: any = await  postRequest(apiUrl.changeVoucherStatus, {id, status})
        if(res.code === 1){
            message.success('修改成功');
            this.onInitList();
        }
    }

    render(){
        let { equityList, total_count, pageSize, current, userList} = this.state;
        return <div className='l_content'>
             <Row className="mb30">
                <Col span={24}>
                    <Button type="primary" size="large" icon="plus" onClick={() =>{{
                            if(!getIsPermisson('1_12_12_1')){
                                messageCustom();
                                return;
                            }
                            this.props.history.push('/sidebar/helpEdit')
                        }}}>
                            添加助力有礼
                        </Button>
                </Col>
            </Row>

            <Row type="flex" align="middle" justify="start" className="mt20 mb40" >
                <Col className="mr30">
                    <span>优惠名称：</span>
                    <Search placeholder="请输入优惠券名称" onSearch={this.searchCoupon} style={{ width: 234 }}/>
                </Col>
                <Col className="mr30">
                    <span>状态：</span>
                    <Select placeholder="请选择状态" allowClear style={{ width: 120 }} onChange={this.statusChange}>
                        <Option value="1">已启用</Option>
                        <Option value="0">已停用</Option>
                        <Option value="2">已领完</Option>
                    </Select>
                </Col>
                <Col className="mr30">
                    <span>创建者：</span>
                    <Select placeholder="请选择创建者" allowClear style={{ width: 150 }} onChange={this.userChange}>
                        {
                            userList && userList.map((item: any) => {
                                return <Option value={item.key} key={item.key}>{item.value}</Option>
                            })
                        }
                    </Select>
                </Col>
                <Col className="mr30">
                    <span>创建时间：</span>
                    <RangePicker onChange={this.timeChange} placeholder={["开始时间","结束时间"]} format="YYYY-MM-DD"/>
                </Col>
            </Row>

            <div>
                <Table dataSource={equityList}  size="middle" columns={this.columns} rowKey={'id'} locale={{emptyText: '暂无数据'}} pagination={{
                    total: total_count,
                    pageSize,
                    onChange: this.pageChange,
                    current,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    showTotal: (total: number) => `共${total}条`,
                    onShowSizeChange: (current:number, size: number) => {this.setState({pageSize: size}, () => {this.onInitList();})}

                }} />
            </div>
        </div>;
    }
}
export default HelpList