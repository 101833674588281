import {observer} from "mobx-react";
import React from "react";
import {Button, Input, message, Row, Col, Select} from "antd";
import { postRequest } from '../../api/http';
import { apiUrl } from '../../api/api_url';

const { Option } = Select;

interface GoodsSortEdit{
}

const key = 'updatable';

@observer
class GoodsSortEdit extends React.Component<any> {

    state = {
        loading: false,
        name: '',
        order_by: 0,
        show_type: 'horizontal'
    }

    componentDidMount () {
        const {location} = this.props;
        if(location.state !== undefined){
            this.initEdit(location.state.id);
        }
    }

    // 初始化编辑
    initEdit = async (id:number) => {
        const params = {
            id: id
        };
        const res:any = await postRequest(apiUrl.goodInfo,params);
        if(res.code === 1){
            this.setState({
                name: res.data.name,
                order_by: res.data.order_by,
                show_type: res.data.show_type
            })
        }
    }

    nameChange = (e:any) => {
        let { name } = this.state;
        name = e.target.value.trim();
        this.setState({
            name
        })
    }

    sortChange = (e:any) => {
        let { order_by } = this.state;
        order_by = e.target.value.trim();
        this.setState({
            order_by
        })
    }

    // 券类型
    typeChange = (e:string) => {
        this.setState({
            show_type: e
        })
    }


    handleSubmit = async (e:any) => {
        message.loading({ content: '发布中', key });
        e.preventDefault();

        const {location} = this.props;
        const { name, show_type, order_by } = this.state;

        var sortInfo:any = {};

        if(location.state !== undefined){
            sortInfo.id = location.state.id;
        }

        if(name === ''){
            message.warning({ content: '请输入分类名称!', key, duration: 2 });
            return;
        }

        // if(order_by === 0){
        //     message.warning({ content: '请输入分类排序!', key, duration: 2 });
        //     return;
        // }

        sortInfo.name = name;
        sortInfo.order_by = order_by;
        // sortInfo.show_type = show_type;

        if(location.state !== undefined){
            const res:any = await postRequest(apiUrl.goodSortEdit,sortInfo);
            if(res['code'] === 1){
                message.success({ content: '修改成功!', key, duration: 2 });
                this.props.history.push('/sidebar/goodsSort');
            }else{
                message.error({ content: '修改失败!', key, duration: 2 });
            }
        }else{
            const res:any = await postRequest(apiUrl.goodSortAdd,sortInfo);
            if(res['code'] === 1){
                message.success({ content: '发布成功!', key, duration: 2 });
                this.props.history.push('/sidebar/goodsSort')
            }else{
                message.error({ content: '发布失败!', key, duration: 2 });
            }
        }
    };

    // 返回上一页
    goBack = () => {
        this.props.history.push('/sidebar/goodsSort');
    }

    render(){
        const { name, show_type, order_by, } = this.state;

        return <div className='l_content'>
                <div className="edit_title">基本信息</div>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">分类名称</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <Input placeholder="请输入分类名称" maxLength={10} value={name} onChange={(e) => this.nameChange(e)}/>
                    </Col>
                </Row>

                {/* <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">分类排序</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <Input placeholder="请输入分类排序" maxLength={10} value={order_by} onChange={(e) => this.sortChange(e)}/>
                    </Col>
                </Row> */}

                {/* <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">展示样式</span>
                        </div>
                    </Col>
                    <Col span={14}>
                        <Select style={{ width: 120 }} value={show_type} onChange={this.typeChange} placeholder="选择券类型">
                            <Option value="horizontal">横图展示</Option>
                            <Option value="vertical">竖图展示</Option>
                        </Select>
                    </Col>
                </Row> */}

                <Row>
                    <Col span={12}>
                        <div className="bottom_wrap">
                            <Button type="primary" ghost onClick={this.goBack}>
                                取消
                            </Button>
                            <Button type="primary" onClick={this.handleSubmit}>
                                保存
                            </Button>
                        </div>
                    </Col>
                </Row>

        </div>;
    }

}
export default GoodsSortEdit