import React from 'react';
import InsideNav from '../../../component/insideNav/insideNav';
import ManageUser from '../../permission/manageUser/manageUser';
import SystemRole from '../../permission/systemRole/systemRole';
import SystemSettings from '../../systemSettings/systemSettings';
import PreferentialSettings from '../../preferentialSettings/preferentialSettings'


import './systemManage.scss'
import { collectChildNav } from '../../../utils/permisstionUtil';

type IProps = {
    history: any
}
type IState = {
    navList: any
    current_key: string
}

const navTable = [
    {
        title: '账号管理',
        isActive: true,
        KEY: "MANAGE_USER",
        uni_key: '2_5_1'
    },
    {
        title: '角色管理',
        isActive: false,
        KEY: "SYSTEM_ROLE",
        uni_key: '2_5_2'
    },
    {
        title: '优惠限制设置',
        isActive: false,
        KEY: "PREFERENTIAL_SETTINGS",
        uni_key: '2_5_3'
    },
    {
        title: '系统设置',
        isActive: false,
        KEY: "SYSTEM_SETTINGS",
        uni_key: '2_5_4'
    }
]

class SystemManage extends React.Component<IProps,IState>{
    
    constructor(props:IProps) {
        super(props);
        this.state = {
            navList: [],
            current_key: "",
        }
    }

    componentDidMount(){
        this.initPageState();
    }

    initPageState = () =>{
        let navList = collectChildNav(navTable);
        let currentKey = localStorage.getItem('current_key') ?? navList[0].KEY;
        let isSetKey = false;
        navList.map((item:any) => {
            if(item.KEY == currentKey){
                item.isActive = true;
                isSetKey = true;
            }else{
                item.isActive = false;
            }
        })
        if(!isSetKey){
            currentKey = navList[0].KEY;
            navList[0].isActive = true;
        };
        this.setState({
            current_key: currentKey,
            navList: navList
        })
    }

    navCallBack(currentKey: string){
        localStorage.setItem('current_key',currentKey) 
        this.setState({
            current_key: currentKey
        })
    }

    render() {
        let { navList,current_key } = this.state;
        return <div>
            <InsideNav navList={navList} callBack={(key: string) =>this.navCallBack(key)}/>
            <div className="body">
                {
                    current_key === 'MANAGE_USER' ?  <ManageUser history={this.props.history} />
                    :
                    current_key === 'SYSTEM_ROLE' ?  <SystemRole history={this.props.history}/>
                    :
                    current_key === 'PREFERENTIAL_SETTINGS' ?  <PreferentialSettings />
                    :
                    current_key === 'SYSTEM_SETTINGS' ?  <SystemSettings />
                    : <></>
                    
                }
            </div>
        </div>
    }

}

export default SystemManage;