import * as React from "react";
import {
  Layout,
  message,
  Row,
  Breadcrumb,
  notification,
} from "antd";
import { inject, observer } from "mobx-react";
import { withRouter, RouteComponentProps, Switch, Route } from "react-router-dom";
import { IAppStore } from "../../stores/app.store";
import SiderBar from "./components/sider_bar";
import HeaderBar from './components/header_bar'
// import CreateAllRouter from "./components/create_router";
import { routerConfig } from "../../config/router";
import './main.scss'
import { getRequest } from "../../api/http";
import { apiUrl } from "../../api/api_url";

type AppProps = RouteComponentProps & {
  appStore?: IAppStore;
};

@inject("appStore")
@observer
export class Main extends React.Component<AppProps, {}> {
  state = {
    collapsed: false,
  };

  onCollapse = () => {
    const { collapsed } = this.state;
    this.setState({ collapsed: !collapsed });
  };

  loginOut = () => {
    localStorage.clear();
    this.props.history.push("/login");
  };

  //选择门店
  onGoPage = async (shop_id:any, shop_name: any, info:any) => {
    const res:any = await getRequest(apiUrl.webGetPermission,{shopid: shop_id});
    if(res.code == 1){
    let permissions = res.data.permission ?? []
      let keys = res.data.key ?? []
      if(keys.length == 0){
        message.warning('没有权限请联系管理员');
        return
      }
      window.localStorage.setItem('siyu_tab_list',JSON.stringify(permissions));
      window.localStorage.setItem('uni_keys',JSON.stringify(keys));
      window.location.reload()
      this.onGoPageEnd(shop_id, shop_name,info,permissions)
    }else{
      message.error('服务器异常'+ res.message);
    }
  }

  onGoPageEnd = (shop_id:any, shop_name:any, info: any,siyu_tab_list: any) =>{
    localStorage.setItem('shop_id', shop_id);
    localStorage.setItem('shop_name', shop_name)
    localStorage.setItem('shop_request', info.request)
    localStorage.setItem('shop_info', JSON.stringify(info))
    // window.location.reload()
    let timer = setTimeout(() => {
      clearTimeout(timer)
      if(process.env.REACT_APP_ChromeApp === "1") {
        this.props.history.push('/sidebar/windowManage');
      }else{
        console.log('siyu_tab_list',siyu_tab_list)
          if(siyu_tab_list[0].routers && siyu_tab_list[0].routers.length > 0){
              this.props.history.push(siyu_tab_list[0].routers[0].path);
          }else{
            this.props.history.push(siyu_tab_list[0].path);
          }
      }
    },1000)
  }

  async componentDidMount() {
    //判断url是否有phone和store_id
    let param = new URLSearchParams(this.props.location.search)
    let phone = param.get('phone')
    let shop_id = param.get('shop_id')
    if(phone&&shop_id){
      let shopInfo:any = localStorage.getItem('shop_info')||JSON.stringify({})
      let userInfo:any = localStorage.getItem('user_info')||JSON.stringify({})
      shopInfo = JSON.parse(shopInfo)
      userInfo = JSON.parse(userInfo)
      if(shopInfo.shop_id != shop_id){
        if(userInfo.mobile === phone){
          //存在，切换页面
          const res:any = await getRequest(apiUrl.getShopInfo,{shop_id:shop_id})
          if(res.code === 1){
            let info = {
              address: res.data.address,
              city: res.data.city,
              district: res.data.district,
              img: res.data.img,
              is_open: res.data.is_open,
              phone: res.data.phone,
              province: res.data.province,
              request: res.data.id == 1 ? 'online' : 'release',
              shop_id: res.data.id,
              shop_name: res.data.name,
            }
            this.onGoPage(shop_id,info.shop_name,info)
          }else{
            message.error(res.message||'获取店铺信息失败')
          }
        }else{
          this.loginOut()
        }
      }
      return
    }

    setTimeout(() => {
      if (!window.localStorage.getItem("token")) {
        message.error(`登录错误！`);
        this.props.history.push("/login");
      }
    }, 500);

    setInterval(() => {
      this.getVersion()
    },10000)

    window.addEventListener('resize', this.resizeListener);
	  this.resizeListener();

  }

  componentWillUnmount(){
    window.removeEventListener('resize', this.resizeListener);
  }

  resizeListener(){
    // 定义设计图的尺寸 1920
    let designSize = 1920;
    // 获取 html 元素
    let html = document.documentElement;
    // 定义窗口的宽度
    let clientW = html.clientWidth;
    // html 的fontsize 大小
    let htmlRem = clientW * 100 / designSize;
    html.style.fontSize = htmlRem + 'px';
  }

  getVersion = async () => {
    let version = localStorage.getItem('version')
    const res:any = await getRequest(apiUrl.systemVersion)
    if(res.code == 1){
      localStorage.setItem('notice_title', res.data.title)
      localStorage.setItem('notice_list', JSON.stringify(res.data.list))
      localStorage.setItem('notice_id', res.data.notice_id)

      if(version != res.data.version){
        console.log('更新版本');
        notification.info({
          'message': '版本更新提醒',
          'description': '发现新版本，系统已自动更新至最新版本'
        })

        setTimeout(() => {
          window.location.reload()
          localStorage.setItem('version', res.data.version)
        }, 4500);
      }
    }
  }

  setPhone = (phone: string) => {
    this.props.appStore?.setPhone(phone);
  }

  // 获取账号信息
  getAccountNum = (): string => {
    const phone = this.props.appStore?.phone || localStorage.getItem("phone");
    if (phone) {
      this.props.appStore?.setPhone(phone);
      return phone;
    }
    return "";
  };

  render() {
    const { collapsed } = this.state;
    const allComments = routerConfig.allComments;
    const buildType = process.env.REACT_APP_ChromeApp;
    // console.log('REACT_APP_ChromeApp', process.env.REACT_APP_ChromeApp)
    let componentList = ['GroupIndex', 'SiyuGroupIndex']
    return (
      <Layout className="win">

        { buildType === "0" && <SiderBar collapsed={collapsed} onCollapse={this.onCollapse} /> }

        <Layout style={{background: '#f0f0f0'}}>
          <HeaderBar
            collapsed={collapsed}
            onCollapse={this.onCollapse}
            setPhone={this.setPhone}
            phone={this.getAccountNum()}
          />

          <Layout.Content>
            <Row className="">
              <Switch>
                {
                  allComments.map((item, index) =>
                   {
                     return  <Route key={index} path={item.path} exact component={item.component} />
                   }
                  )
                }
              </Switch>
            </Row>
          </Layout.Content>
        </Layout>
      </Layout>
    );
  }
}

export default withRouter(Main);
