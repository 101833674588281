import { Row, Col, DatePicker, message, Avatar, Tooltip, Button, Table, Switch } from 'antd';
import React, { Component } from 'react';
import ReactEcharts from "echarts-for-react";
import { apiUrl } from '../../../../api/api_url';
import { getRequest, postRequest } from '../../../../api/http';
import { getIsPermisson, messageCustom } from '../../../../utils/permisstionUtil';

import t_member from '../../../../assret/images/t_member.png'
import t_manage from '../../../../assret/images/t_manage.png'
import t_manage_g from '../../../../assret/images/t_manage_g.png'

import styles from './runComsumeMonth.module.scss';


interface RunComsumeMonthInfo {
    history?: any,
}

class RunComsumeMonth extends Component<RunComsumeMonthInfo> {

    state = {
        option : {
            legend: {},
            tooltip: {
                fontSize: 18,
            },
            dataset: {
                source: [
                    ['product', '老顾客', '新顾客']
                ]
            },
            xAxis: {
                type: 'category',
                axisLabel: {        
                    show: true,
                    textStyle: {
                        color: '#9B9B9B',
                        fontSize:'16'
                    }
                },
                axisLine:{
                    lineStyle:{
                        color:'#aaabb1',
                          width:1,   //这里是坐标轴的宽度,可以去掉
                    }
                }
            },
            yAxis: {
                axisLabel: {        
                    show: true,
                    textStyle: {
                        color: '#9B9B9B',
                        fontSize:'16'
                    }
                },
                axisLine:{
                    lineStyle:{
                        color:'#aaabb1',
                          width:1,   //这里是坐标轴的宽度,可以去掉
                    }
                }
            },
            // Declare several bar series, each will be mapped
            // to a column of dataset.source by default.
            series: [
                {type: 'bar'},
                {type: 'bar'},
            ]
        },
        memberList: [],
        total: 0,
        paramsObj: {
            page: 1,
            pageSize: 6,
        },
        isLoading: false,
        pageSizeOptions: [
            '6','10','20','40'
        ],
        count: 0,
        rowSpans: [],
        data: [],
        cateList: []
    }

    componentDidMount() {
        this._initDate()
        this.getRecommendGroupList()
    }

    _initDate = async () => {
        let res: any = await getRequest(apiUrl.userRunComsumeMonth);
        if(res.code == 1) {
            let option: any = {
                legend: {},
                color: ['#FFC76A','#314DFC'],
                tooltip:{
                    formatter(params: any) {
                        console.log('params:',params)
                        let name = params.seriesName;
                        let idx = params.componentIndex;
                        let text = `<span style="color:#000000;">${name}:${params.data[idx+1]}%</span>`
                        return text;
                    },
                    backgroundColor: '#eaecfc'
                },
                dataset: {
                    source: res.data
                },
                textStyle: {
                    fontSize: 18,
                    color: '#9B9B9B'
                },
                xAxis: {
                    type: 'category',
                    axisLabel: {        
                        show: true,
                        textStyle: {
                            color: '#9B9B9B',
                            fontSize:'16'
                        }
                    }
                },
                yAxis: {
                    axisLabel: {        
                        show: true,
                        textStyle: {
                            color: '#9B9B9B',
                            fontSize:'16'
                        }
                    }
                },
                // Declare several bar series, each will be mapped
                // to a column of dataset.source by default.
                series: [
                    {type: 'bar'},
                    {type: 'bar'},
                ]
            }
            this.setState({
                option
            })

        } else {
            message.error(res.message)
        }
    }

    creatTable = ()=>{
        const columns:any = [
            {
                title: '分组名称',
                dataIndex: 'name',
                key: 'name',
                className: 'list_center',
            },
            {
                title: '定义',
                dataIndex: 'remark',
                key: 'remark',
                className: 'list_center',
            },
            {
                title: '客户数量',
                dataIndex: 'member_num',
                key: 'member_num',
                className: 'list_center',
            },
            // {
            //     title: '上月客户数量',
            //     dataIndex: 'last_month_member_num',
            //     key: 'last_month_member_num',
            //     sorter:true,
            // },
            {
                title: '区别对待',
                dataIndex: 'is_difference_treat',
                key: 'is_difference_treat',
                className: 'list_center',
                render: (text:string, rowdata: any) => {
                    return rowdata ? <Row>
                        {rowdata.is_difference_treat ? "是" : "否"}
                    </Row>
                    : <Row></Row>
                }
            },
            {
                title: '操作',
                dataIndex: 'option',
                key: 'option',
                className: 'list_center',
                render: ( text:string, record:any)=>
                    <Row type="flex" align='middle' justify='center' >
                        <Tooltip placement="bottomRight" title={'查看人员'}>
                            <img src={t_member} className='table_icon' onClick={()=>{
                                if(!getIsPermisson('1_6_5_2') && !getIsPermisson('1_6_00_4')){
                                    messageCustom();
                                    return;
                                  }
                                    this.props.history.push('/sidebar/memberList',{recommend_group_id:record.id})}
                                }></img>
                        </Tooltip>

                        <Tooltip placement="bottomRight" title={'去运营'}>
                            <img src={!record.operation_id ? t_manage : t_manage_g} className='table_icon' onClick={() => {
                                if(!getIsPermisson('1_6_5_3') && !getIsPermisson('1_6_00_5')){
                                    messageCustom();
                                    return;
                                  }
                                 if(!record.operation_id){
                                    this.props.history.push('/sidebar/marketingAdd',{group_id:record.id,name:record.name,is_disabled:true})
                                 }
                            }}></img>
                        </Tooltip>
                        {/* <Tooltip placement="bottomRight" title={'查看数据'}>
                            <img src={!record.operation_id ? t_data_g : t_data} className='table_icon' onClick={() => record.operation_id&&history.push({pathname: '/sidebar/marketingDetail', state: { id: record.operation_id, name: record.name }})}></img>
                        </Tooltip> */}

                        <span className="action_button" onClick={() => setGroupTreat(record)}>
                            <Switch checked={record.is_difference_treat ? true : false} size='small' style={{marginRight: '5px'}} />
                            {record.is_difference_treat ? "取消区别对待" : "区别对待"}
                        </span>

                    </Row>
            },
        ];

        const setGroupTreat = async (rowdata: any) => {
            if(!getIsPermisson('1_6_5_6') && !getIsPermisson('1_6_00_8')){
                messageCustom();
                return;
              }
            const res:any = await getRequest(apiUrl.setGroupTreat, { id: rowdata.id,  is_difference_treat: !rowdata.is_difference_treat });
            if(res && res.code === 1) {
                message.success('设置成功');
                let paramsObj: any = this.state.paramsObj;
                this.getRecommendGroupList({...paramsObj});
            } else {
                message.error(res.msg);
            }
        }

        return (
            <Table
                dataSource={this.state.data}
                columns={columns}
                onChange={this.handleTableChange}
                rowKey={'id'}
                pagination={{
                    defaultCurrent: 1,
                    current: this.state.paramsObj.page,
                    pageSize: this.state.paramsObj.pageSize,
                    total: this.state.total,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    pageSizeOptions: this.state.pageSizeOptions,
                    showTotal: (total: number) => `共${total}条`,
                }}
            />
        );
    }

    getRecommendGroupList = async (data:any = {}) => {
        let param = {
            ...data,
            label_id: '2.10'
        }
        if(param.sortType)param.sortType=param.sortType=='ascend'?'asc':'desc'
        if(param.pageSize) {
            this.setState({
                paramsObj: param,
                isLoading: true
            })
        }
        const res:any = await getRequest(apiUrl.getRecommendGroup, param);
        if(res && res['code'] === 1) {
            this.setState({
                memberList: res.data.list,
                total: res.data.total,
                isLoading: false,
                data: this.dataInit(res.data.list)
            })
            // if(!data.label_id){
            //     this.getGroupCate(res.data.total)
            // }
        }
    }

    // getGroupCate = async (total: number) => {
    //     let res:any = await getRequest(apiUrl.groupCate)
    //     if(res && res.code == 1){
    //         let list = [{label_id: 0, label_name: '全部分组', active: true, count: total}]
    //         list = list.concat(res.data)
    //         this.setState({
    //             cateList: list
    //         })
    //     }
    // }

    dataInit = (data:any)=>{
        let dataArr:any = []
        let i = 0
        data.map((item:any,index:number)=>{
            if(item.items&&item.items.length>1){
                item.items.map((itemG:any,indexG:number)=>{
                    dataArr[i] = itemG
                    dataArr[i].label = item.label
                    dataArr[i].label_id = item.label_id
                    i++
                })
            }else{
                dataArr[i] = item.items[0]
                dataArr[i].label = item.label
                dataArr[i].label_id = item.label_id
                i++
            }
        })
        this.setState({
            rowSpans: this.getRowSpans(dataArr, 'label_id')
        })
        return dataArr
    }

    getRowSpans = (arr:any, key:string) => {
        let sameValueLength = 0;
        const rowSpans = [];
        for(let i = arr.length - 1; i >= 0; i--){
            if(i === 0) {
                rowSpans[i] = sameValueLength + 1;
                continue;
            }
            if(arr[i][key] === arr[i-1][key]) {
                rowSpans[i] = 0;
                sameValueLength++;
            } else {
                rowSpans[i] = sameValueLength + 1;
                sameValueLength = 0;
            }
        }
        return rowSpans;
    };

    handleTableChange = (pagination:any, filters:any, sorter:any) => {
        let paramsObj: any = this.state.paramsObj;
        this.getRecommendGroupList({
            ...paramsObj,
            page:pagination.current,
            pageSize:pagination.pageSize,
            sortField:sorter.field,
            sortType:sorter.order
        })
    };

    render() {

        let { option } = this.state;

        return (
            <Row className={styles.runComsumeMonth}>
                <Col className={styles.runComsumeMonth_echatrs}>
                    <div className={styles.runComsumeMonth_echatrs_tip}>消费月份</div>
                    <div>
                        <ReactEcharts option={ option } style={{height: '4.5rem'}} className={styles.runComsumeMonth_echatrs_react_for_echarts} />
                    </div>
                </Col>
                <Col className={styles.runComsumeMonth_table}>
                    {this.creatTable()}
                </Col>
            </Row>
        )
    }

}

export default RunComsumeMonth