import { getRequest, postRequest } from "../../../api/http";
import { apiUrl } from "../../../api/api_url";

// 示例
// export const getXcxWithdraw = async (param:object) =>{
//     const res:any = await getRequest(`${apiUrl.getXcxWithdraw}`,param);
//     return res;
// };

export const messageTemplateAdd = async (param:object) =>{
    const res:any = await postRequest(`${apiUrl.messageTemplateAdd}`,param);
    return res;
};


export const messageTemplateSearch = async (param:object) => {
    const res:any = await getRequest(`${apiUrl.messageTemplateSearch}`,param);
    return res;
}

export const messageUserSearch = async (param:object) => {
    const res:any = await getRequest(`${apiUrl.userSearch}`,param);
    return res;
}

export const messageTaskAdd = async (param:object) => {
    const res:any = await postRequest(`${apiUrl.messageTaskAdd}`,param);
    return res;
}

export const messageTaskEdit = async (param:object) => {
    const res:any = await postRequest(`${apiUrl.messageTaskEdit}`,param);
    return res;
}

export const messageTaskAudit = async (param:object) => {
    const res:any = await getRequest(`${apiUrl.messageTaskAudit}`,param);
    return res;
}

export const messagePush = async (param:object) => {
    const res:any = await getRequest(`${apiUrl.messagePush}`,param);
    return res;
}

export const messageTaskDelete = async (param:object) => {
    const res:any = await getRequest(`${apiUrl.messageTaskDelete}`,param);
    return res;
}