import React, { useEffect, useState } from 'react'
import styles from './vegetable_sale_table.module.scss'
import { Input, Table, Tooltip, Modal, Button, message, DatePicker, Cascader, Select } from 'antd';
import { getRequest, postRequest, requestWithJson, getRequestWithoutShopId } from "../../api/http";
import { apiUrl } from "../../api/api_url";
import imgPrint from "../../assret/images/print_select.png"
import arrowsImg from "../../assret/images/arrows.png"
import configImg from "../../assret/images/config.png"
import refleshImg from "../../assret/images/reflesh.png"
import exportImg from "../../assret/images/goodsExport.png"
import { getIsPermisson, messageCustom } from '../../utils/permisstionUtil';
import CustomDatePicker from '../datePicker/index';
import ReactEcharts from "echarts-for-react";

const { Search } = Input;
const { Option } = Select;


class VegetableSaleTable extends React.Component<any> {

	state = {
		goodsTypes: [], // 商品分类数据
		goodsDataTable: [], // 商品信息列表
		currentPage: 1,  // 当前页面
		pageSize: 10, // 每页数量
		sn: "", // 搜索菜品编号
		st: "", //开始时间
		et: "", // 结束时间
		goods_id: "", // 查询商品id
		cat_id: "", // 查询分类id
		confVisible: false, // 配置按钮
		dataCatList: [], // 分类列表
		dataGoodsList: [], // 商品列表
		dataGoodsListTemp: [], // 商品列表
		count: 0, // 商品列表总条数
		ids: [], // 所有被选择商品id
		index: 0, // 分类数组索引值
		currentCatSelIds: [], // 当前分类选择ids
		hot_top: {}, // 热销排行榜
		refund_top: {}, // 退菜排行榜
		top_count: "", // top榜数量
		kw: "", // 搜索菜品名称


	}
	async componentDidMount() {
		// 获取当天日期
		const today = this.getTodayDate()
		this.setState({
			st: today,
			et: today
		}, async () => {
			// 获取菜品销售数据
			await this.initDataTable()
		})


		// 获取商品分类数据
		await this.getGoodsTypes()
		// 获取允许排名商品数据
		await this.getDataCatList()

		let dataGoodsList: any = []
		const dataCatList = this.state.dataCatList
		// 默认展示菜品数据
		if (dataCatList.length > 0) {
			dataGoodsList = dataCatList[0]['goods']
		}

		// 从商品列表中筛选出允许可以排名
		const ids = this.goodsList2SelIds(dataCatList)
		this.setState({
			ids,
			dataGoodsList
		})

		// const st :any = this.getTodayDate()
		// const et :any = this.getTodayDate()
		// this.setState({
		// 	dataCatList,
		// 	dataGoodsList,
		// 	ids,
		// 	st,
		// 	et
		// }, ()=>{this.initData()})
		// console.log(new Date(), "-------new Date()------")
	}

	// 热销菜品排行榜
	getHotSalesTop = (top: any) => {
		let rotate = 40
		const top_count = this.state.top_count
		if (top_count === "5" || top['xAxis'].length < 6) {
			rotate = 0
		}


		const hot_top: any = {
			color: ['#FF4141'],
			tooltip: {
				trigger: 'axis',
				axisPointer: {            // 坐标轴指示器，坐标轴触发有效
					type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
				},
			},
			grid: {
				left: '3%',
				right: '4%',
				bottom: '3%',
				top: '60px',
				containLabel: true
			},
			xAxis: [
				{
					type: 'category',
					data: top['xAxis'],
					// axisTick: true
					axisTick: {
						alignWithLabel: true,
						interval: 0,
						rotate: -30,
						margin: -15
					},
					axisLabel: {
						interval: 0,
						rotate: rotate
					}
				}
			],

			yAxis: [
				{
					type: 'value'
				}
			],
			series: [
				{
					name: '菜品数量',
					type: 'bar',
					barWidth: '60px',
					data: top['series'],
				}
			]
		}
		this.setState({
			hot_top
		})
	}

	// 退菜排行榜
	getRefundTop = (refundTop: any) => {

		let refund_top: any = {
			color: ['#FF4141'],
			tooltip: {
				trigger: 'axis',
				axisPointer: {            // 坐标轴指示器，坐标轴触发有效
					type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
				},
			},
			grid: {
				left: '3%',
				right: '4%',
				bottom: '3%',
				top: '60px',
				containLabel: true
			},
			xAxis: [
				{
					type: 'category',
					data: refundTop['xAxis'],
					// axisTick: true
					axisTick: {
						alignWithLabel: true,
						interval: 0,
						rotate: -30,
						margin: -15
					},
					// axisLabel: {
					// 	interval: 0,
					// 	rotate: 40
					//   }
				}
			],

			yAxis: [
				{
					type: 'value',
					barWidth: 30
				}
			],
			series: [
				{
					name: '菜品数量',
					type: 'bar',
					barWidth: '60px',
					data: refundTop['series'],
				}
			]
		}
		this.setState({
			refund_top
		})
	}
	// 从商品列表中筛选出允许可以排名
	goodsList2SelIds = (dataCatList: any) => {
		const ids: any = []
		dataCatList.map((item: any, index: any) => {
			let temp: any = []
			item["goods"].map((goods: any) => {
				if (goods["allow"]) {
					temp.push(goods["goods_id"])
				}
			})
			ids[index] = temp
		})
		return ids
	}

	// 从被选择的ids中跟新商品列表数据,商品配置提交时使用
	selIds2GoodsList = () => {
		const ids = this.state.ids
		// 从被选择列表中映射出 id:true
		const idsObj: any = {}
		ids.map((item: any) => {
			item.map((id: any) => {
				idsObj[id] = true
			})
		})

		// 修改商品分类是否过滤此商品
		let dataCatList = this.state.dataCatList
		dataCatList.map((item: any, index: any) => {
			const idItems: any = item.goods
			idItems.map((goods: any) => {
				if (idsObj.hasOwnProperty(goods["goods_id"])) {
					goods.allow = true
				} else {
					goods.allow = false
				}
			})
		})
	}


	getDataCatList = async () => {
		const res: any = await getRequest(apiUrl.vegetableSalesCfg);
		if (res && res['code'] === 1) {
			this.setState({
				dataCatList: res.data
			})
		}
	}
	// 获取菜品分类
	getGoodsTypes = async () => {
		const res: any = await getRequest(apiUrl.goodSort, { current: 1, pageSize: 500 });
		if (res && res['code'] === 1) {
			res.data.map((el: any) => {
				el['label'] = el.name;
				el['value'] = el.id;
				el['isLeaf'] = false;
			});
			this.setState({
				goodsTypes: res.data
			})
		}
	}

	// 获取菜品销售数据
	getGoodsSalesData = async (chart=true) => {   //筛选分类不包括上面图表数据
		const st: any = this.state.st
		const et: any = this.state.et
		const page: any = this.state.currentPage
		const pagesize: any = this.state.pageSize
		const sn: any = this.state.sn
		const goods_id: any = this.state.goods_id
		const cat_id: any = this.state.cat_id
		const top: any = this.state.top_count
		const res: any = await getRequest(apiUrl.vegetableSalesData, { st, et, page, pagesize, cat_id, goods_id, sn, top });
		if (res && res['code'] === 1) {
			const goodsDataTable = res.data.list
			if(chart){
				// 热销排行榜
				const hotSalesTop = res.data.hot_top
				this.getHotSalesTop(hotSalesTop)

				// 退菜排行榜
				const refundTop = res.data.refund_top
				this.getRefundTop(refundTop)
			}

			const count = res.data.count
			this.setState({
				goodsDataTable,
				count,
			})
		}
	}

	// 初始化数据
	initDataTable = async () => {
		await this.getGoodsSalesData()
	}

	// 通过商品分类id获取分类下商品数据
	loadDataGoodsTypes = async (selectedOptions: any) => {
		const targetOption = selectedOptions[selectedOptions.length - 1];
		targetOption.loading = true;
		const res: any = await getRequest(apiUrl.goodList, { current: 1, pageSize: 500, cat_id: selectedOptions[0]['id'] });
		if (res && res['code']) {
			targetOption.loading = false;
			res.data.map((el: any) => {
				el['label'] = el.name;
				el['value'] = el.id;
			})
			targetOption.children = res.data;
			const goodsTypes: any = this.state.goodsTypes
			this.setState({
				goodsTypes: [...goodsTypes]
			})
		}
	}

	// 周期内菜品销售（名称）
	goodsTypesChange = (e: any) => {
		// 初始化表格数据
		this.setState({
			pageSize: 10,
			page: 1,
			goods_id: e[1],
			sn: "",
			cat_id: ""
		}, () => {
			this.initDataTable()
		})
	}


	// 改变列表页面索引
	pageChange = async (page: number) => {
		this.setState({
			currentPage: page
		}, () => {
			// 更新页面数据
			this.initDataTable()
		})
	}

	// 改变每页数量
	onSizeChange = async () => {
		const page = 1
		this.setState({
			currentPage: page,
		}, () => {
			// 更新页面数据
			this.initDataTable()
		})
	}

	// 同步搜索内容
	searchInput = (e: any) => {
		const sn = e.target.value
		this.setState({
			sn
		})
	}

	// 搜索按钮
	searchBtn = async (val: any) => {
		const sn = val
		this.setState({
			sn,
			goods_id: "",
			cat_id: ""
		}, () => {
			this.initDataTable()
		})

	}


	// 导出excel数据
	downloadExcle = async () => {
		if (!getIsPermisson('1_3_4_2')) {
			messageCustom();
			return;
		}
		// 查询开始时间
		const st: any = this.state.st
		// 查询结束时间
		const et: any = this.state.et
		// 查询分类id
		const cat_id: any = this.state.cat_id
		// 查询商品id
		const goods_id: any = this.state.goods_id
		// 查询商品sn
		const sn: any = this.state.sn

		if (st === "" || et === "") {
			message.error("请选择时间")
			return
		}
		window.open(`${process.env.REACT_APP_BASEURL}${apiUrl.vegetableSalesExport}?st=${st}&et=${et}&cat_id=${cat_id}&goods_id=${goods_id}&sn=${sn}&token=${localStorage.getItem('token')}&shop_id=${localStorage.getItem('shop_id')}`);
	}

	// 打印数据
	printerData = async () => {

		if (!getIsPermisson('1_3_4_1')) {
			messageCustom();
			return;
		}
		// 查询分类id
		const cat_id: any = this.state.cat_id
		// 查询商品id
		const goods_id: any = this.state.goods_id
		// 查询商品sn
		const sn: any = this.state.sn
		// 查询开始时间
		const st: any = this.state.st
		// 查询结束时间
		const et: any = this.state.et
		if (st === "" || et === "") {
			message.error("请选择时间")
			return
		}
		const res: any = await getRequest(apiUrl.vegetableSalesPrint, { st, et, cat_id, goods_id, sn });
		if (res && res['code'] === 1) {
			message.success('打印成功!');
		} else {
			message.error(`${res.message ? res.message : '打印出错'}`);
		}
	}

	// 刷新数据
	reFreshData = async () => {
		if (!getIsPermisson('1_3_4_3')) {
			messageCustom();
			return;
		}
		// this.initDataTable()
		let res:any = await getRequest(apiUrl.dishesReflesh, {data: this.state.st})
		if(res.code == 1){
			this.getGoodsSalesData()
			message.success(res.message)
		}else{
			message.error(res.message)
		}
	}


	// 展示菜品配置
	showVegetable = () => {
		this.setState({
			confVisible: true
		})
	}

	// 保存配置数据
	confHandleOk = async () => {
		// 选择允许菜品数据，更新到goodsList
		this.selIds2GoodsList()

		// 网络请求，保存数据
		const res: any = await postRequest(apiUrl.vegetableSalesCfg, { "data": this.state.dataCatList });
		if (res && res['code'] === 1) {
			message.success('保存成功');
		} else {
			message.error('保存失败');
		}
		// 保存配置之后，重新加载数据
		await this.initDataTable()
		this.setState({
			confVisible: false,
			kw: "",
		})
	}

	confHandleCancel = async () => {
		this.setState({
			confVisible: false,
			kw: "",
		})
	}

	// 保存店铺菜品配置
	saveShopVegetableCfg = async () => {

	}

	// 获取分类下所有商品数据
	getChildenClick = (index: any) => {
		let dataGoodsList: any = []
		const dataCatList: any = this.state.dataCatList
		dataGoodsList = dataCatList[index]["goods"]
		this.setState({
			dataGoodsList,
			index,
		})
	}

	// 时间选择器组件,更新当前时间
	onCallbackDataPicker = (dataList: any) => {
		const st = dataList[0]
		const et = dataList[1]
		this.setState({
			st,
			et
		}, () => { this.initDataTable() })
	}

	// 当天日期
	getTodayDate = () => {
		let day2 = new Date();
		day2.setTime(day2.getTime());
		const today = day2.getFullYear() + "-" + (day2.getMonth() + 1) + "-" + day2.getDate();
		const st = today
		const et = today
		return today
	}

	// 切换分类，查询改分类下数据
	switchCatHandler = (val: any) => {
		this.setState({
			cat_id: val,
			page: 1,
			pagesize: 10,
			sn: "",
			goods_id: "", // 把goods id 置为空
		}, () => {
			this.initDataTable()
		})
	}

	// 切换Top
	switchTopHandler = (val: any) => {
		this.setState({
			top_count: val,
			page: 1,
			pagesize: 10,
			sn: "",
			goods_id: "", // 把goods id 置为空
		}, () => {
			this.initDataTable()
		})
	}

	// 搜素菜品名称
	searchDishName = (e: any) => {
		const kw = e.target.value
		this.setState({
			kw
		});
	}

	// 展示搜索过滤之后的数据
	searchDishBtn = (val: any) => {
		// 从所有的数据中过滤出菜品名称相同的数据
		let dataCatList = this.state.dataCatList
		dataCatList.map((cat: any, index: any) => {
			cat['goods'].map((goods: any) => {
				if (goods.goods_name === val) {
					const dataGoodsListTemp = []
					dataGoodsListTemp.push(goods)
					this.setState({
						dataGoodsListTemp,
						index,
					})
					return
				}
			})
		})


	}

	clickRow = (record: any, rowkey: any) => {
		// table 行点击获取数据
		this.getChildenClick(rowkey)
	}

	onIsOpenDefault = (e: any) => {
        this.props.onSearchShopNmae(e, () => {
            // this.requestGetMemberList({
            //     search_shop_id:  e
            // })
        })
    }

	render() {
		const { st, et } = this.state
		// 商品分类
		const columns: any = [
			{
				title: '分类名称',
				dataIndex: 'name',
				key: 'name',
				width: 100,
				// fixed: 'left',
			},
			{
				title: '菜品编号',
				dataIndex: 'dish_num',
				key: 'dish_num',
				width: 100,
			},
			{
				title: '菜品名称',
				dataIndex: 'dish_name',
				key: 'dish_name',
				width: 100,
			},
			{
				title: '单位',
				dataIndex: 'dish_definition',
				key: 'dish_definition',
				width: 100,
			},
			{
				title: '点菜',
				dataIndex: 'order_num',
				key: 'order_num',
				width: 50,
			},
			{
				title: '计量',
				dataIndex: 'measure',
				key: 'measure',
				width: 50,
			},
			{
				title: '称重菜重量',
				dataIndex: 'measure_num',
				key: 'measure_num',
				width: 60,
				// render: () => <Row>0</Row>
			},
			{
				title: '退菜',
				dataIndex: 'refund_num',
				key: 'refund_num',
				width: 50,
			},
			{
				title: '赠送',
				dataIndex: 'present_num',
				key: 'present_num',
				width: 50,
			},
			{
				title: '收费',
				dataIndex: 'pay_num',
				key: 'pay_num',
				width: 80,
			},
			{
				title: '销售金额',
				dataIndex: 'sales_amout',
				key: 'sales_amout',
				width: 80,
			},
			{
				title: '菜品优惠（元）',
				dataIndex: 'discount_fee',
				key: 'discount_fee',
				width: 80,
			},
			{
				title: '菜品实收',
				dataIndex: 'actul_fee',
				key: 'actul_fee',
				width: 80,
			},

		];

		const columnsCat: any = [
			{
				title: '全部分类',
				dataIndex: 'cat_name',
				key: 'cat_id',
			},
			{
				// title: '操作',
				dataIndex: 'action',
				render: (text: any, record: any, index: number) => (
					// <div className={styles.btn_wrap_vegetable} onClick={()=>this.getChildenClick(index)}>
					<div className={styles.btn_wrap_vegetable}>
						<Tooltip placement="bottomRight" title={''}>
							<img src={arrowsImg} className={styles.table_icon_vegetable}  ></img>
						</Tooltip>
					</div>
				),
			}
		]

		const columnsGoods: any = [
			{
				title: '全选',
				dataIndex: 'goods_name',
				key: 'goods_id',
			}
		]

		const index: any = this.state.index
		const ids: any = this.state.ids
		// 商品配置
		const rowSelection: any = {
			selectedRowKeys: ids[index],
			onChange: (selectedRowKeys: any, selectedRows: any) => {
				let ids: any = this.state.ids
				const index: any = this.state.index
				ids[index] = selectedRowKeys
				this.setState({
					ids,
				})
			}
		};

		// 时间选择器组件
		const dateList = [
			{
				type: 'today',
				name: '今日'
			},
			{
				type: 'yesterday',
				name: '昨日'
			},
			{
				type: 'nearlySevenDays',
				name: '近7天'
			},
			{
				type: 'ownMonth',
				name: '近30天'
			},
			{
				type: 'currentMonth',
				name: '本月'
			},
			{
				type: 'beforeMonth',
				name: '上月'
			},
			{
				type: 'quarter',
				name: '本季度'
			},
			{
				type: 'lastQuarter',
				name: '上季度'
			},
			{
				type: 'own',
				name: '累计'
			}
		]


		return <div className={styles.vegetable_sale}>
			<div className={styles.vegetable_title}>菜品销量</div>
			<div className={styles.date_picker}>
				<CustomDatePicker dateList={dateList} callback={(list: any) => { this.onCallbackDataPicker(list) }} currentDate={[st, et]}></CustomDatePicker>
				{/* <div className={styles.date_picker_filter}>
					<Select defaultValue="综合排名" style={{ width: 120 }} onChange={(val: any) => this.switchCatHandler(val)} >
						<Option key={0} value={""}>综合排名</Option>
						{
							this.state.dataCatList.map((item: any, index: any) => {
								return <Option key={item.cat_id} value={item.cat_id}>{item.cat_name}</Option>
							})
						}
					</Select>
				</div> */}
			</div>
			<div className={styles.vegetable_bar}>
				<div className={styles.vegetable_bar_left}>
					<div className={styles.vegetable_top_head}>
						<div className={styles.vegetable_top_title}>菜品排名</div>
						<div style={{display: 'flex',alignItems: 'center'}}>
							<div className={styles.date_picker_filter}>
								<Select defaultValue="综合排名" style={{ width: 120 }} onChange={(val: any) => this.switchCatHandler(val)} >
									<Option key={0} value={""}>综合排名</Option>
									{
										this.state.dataCatList.map((item: any, index: any) => {
											return <Option key={item.cat_id} value={item.cat_id}>{item.cat_name}</Option>
										})
									}
								</Select>
							</div>
							<div className={`${styles.vegetable_item} ${styles.vegetable_item_conf}`} onClick={this.showVegetable} >
								<img src={configImg} className={styles.vegetable_img} />
									菜品销售统计配置
							</div>
						</div>
					</div>
					<div className={styles.vegetable_top_desc}>最受欢迎菜品排名</div>
					<div className={styles.vegetable_top_content}>
						{/* <ReactEcharts onEvents={this.onClickByModelEvents} option={option_new} style={{height: '4rem'}} className={styles.userlevel_echatrs_react_for_echarts} /> */}
						<ReactEcharts option={this.state.hot_top} style={{ height: '4rem' }} className={styles.userlevel_echatrs_react_for_echarts} />
					</div>

				</div>
				<div className={styles.vegetable_bar_right}>
					<div className={styles.vegetable_top_head}>
						<div className={styles.vegetable_top_title}>退菜排名</div>
					</div>
					<div className={styles.vegetable_top_desc}>被退回的菜品排名</div>
					<div className={styles.vegetable_top_content}>
						<ReactEcharts option={this.state.refund_top} style={{ height: '4rem' }} className={styles.userlevel_echatrs_react_for_echarts} />
					</div>
				</div>
			</div>

			<div className={styles.vegetable_list}>
				<div style={{display: 'flex', justifyContent: 'space-between'}}>
					<div className={styles.vegetable_list_header}>
						<div className={styles.vegetable_sn}>
							<div className={styles.vegetable_title}>
								菜品名称:
									</div>
							<div className={styles.search_btn}>
								<Search
									placeholder="菜品名称"
									value={this.state.sn}
									allowClear={true}
									onChange={(e) => { this.searchInput(e) }}
									onSearch={(value) => { this.searchBtn(value) }}
									style={{ width: 249 }}
								/>
							</div>
						</div>
						<div className={styles.vegetable_sn} >
							<div className={styles.vegetable_title}>
								菜品分类:
									</div>
							<div className={styles.search_btn}>
								<Select placeholder="请选择菜品分类" style={{ width: 150 }} onChange={(e) => {
									this.setState({
										cat_id: e
									}, () => {
										this.getGoodsSalesData(false)
									})
								}} allowClear>
									{
										this.state.goodsTypes.map((item: any, index: any) => {
											return (
												<Option key={item.id} value={item.id}>{item.name}</Option>
											)
										})
									}
								</Select>
								{/* <Cascader
									placeholder="选择菜品分类"
									options={this.state.goodsTypes}
									loadData={this.loadDataGoodsTypes}
									allowClear
									onChange={this.goodsTypesChange}
									style={{ width: 200 }}
								/> */}
							</div>

						</div>
					</div>
					<div className={styles.vegetable_list_header}>
						<div className={`${styles.vegetable_item} ${styles.vegetable_item_import}`} onClick={() => this.downloadExcle()}>
							<img src={exportImg} className={styles.vegetable_img} />
									导出</div>
						<div className={`${styles.vegetable_item} ${styles.vegetable_item_import}`} onClick={this.printerData}>
							<img src={imgPrint} className={styles.vegetable_img} />
									打印</div>
						<div className={`${styles.vegetable_item} ${styles.vegetable_item_import}`} onClick={this.reFreshData}>
							<img src={refleshImg} className={styles.vegetable_img} />
									刷新</div>
					</div>
				</div>
				<div className={styles.vegetable_list_content}>
					<Table
						dataSource={this.state.goodsDataTable}
						columns={columns}
						size="middle"
						rowKey={"id"}
						locale={{ emptyText: '暂无数据' }}
						pagination={{
							current: this.state.currentPage,
							defaultCurrent: 1,
							total: this.state.count,
							pageSize: this.state.pageSize,
							showQuickJumper: true,
							showSizeChanger: true,
							onChange: this.pageChange,
							showTotal: (total: number) => `共${total}条`,
							onShowSizeChange: (current: number, size: number) => { this.setState({ pageSize: size }, () => { this.onSizeChange(); }) }
						}}
					/>
				</div>
			</div>

			<Modal
				title="菜品销售统计配置"
				visible={this.state.confVisible}
				onOk={this.confHandleOk}
				onCancel={this.confHandleCancel}
				className={styles.cat_list_modal}
				footer={null}
				width={627}
			>
				<div className={styles.search_input} >
					<Search
						placeholder="请输入菜品名称"
						value={this.state.kw}
						allowClear={true}
						onChange={(e) => { this.searchDishName(e) }}
						onSearch={(value) => { this.searchDishBtn(value) }}
						style={{ width: 259 }}
					/>
				</div>
				<div className={styles.conf_content}>
					<div className={styles.conf_content_left}>
						<Table
							columns={columnsCat}
							onRow={(record, rowkey) => {
								return {
									onClick: this.clickRow.bind(this, record, rowkey)    //点击行 record 指的本行的数据内容，rowkey指的是本行的索引
								}

							}}
							rowKey={"cat_id"}
							size="middle"
							scroll={{ y: 360 }}
							dataSource={this.state.dataCatList}
							pagination={false}
						/>
					</div>
					<div className={styles.conf_content_right}>
						<Table
							rowSelection={rowSelection}
							dataSource={this.state.kw === "" ? this.state.dataGoodsList : this.state.dataGoodsListTemp}
							columns={columnsGoods}
							rowKey={"goods_id"}
							size="middle"
							scroll={{ y: 360 }}
							locale={{ emptyText: '暂无数据' }}
							pagination={false}
						/>
					</div>
				</div>

				<div className={styles.conf_btn_modal}>
					<div className={styles.conf_btn_cancel} onClick={this.confHandleCancel}>
						<Button >取消</Button>
					</div>
					<div className={styles.conf_btn_save} onClick={this.confHandleOk}>
						<Button type="primary">确定</Button>
					</div>
				</div>
			</Modal>
		</div>

	}
}

export default VegetableSaleTable