import React from 'react';
import { Table, Modal, message, Tooltip, Icon } from 'antd'
import { getRequest } from '../../api/http';
import { apiUrl } from '../../api/api_url';
import './index.scss'
import { getIsPermisson, messageCustom } from '../../utils/permisstionUtil';
import CustomDatePicker from '../datePicker';
import goodsExport from '../../assret/images/goodsExport.png'
import charge_list from '../../assret/images/charge_list.png'
import deep_add from '../../assret/images/deep_add.png'
import deep_del from '../../assret/images/deep_del.png'
import equal_icon from '../../assret/images/equal_icon.png'
import reduce_icon from '../../assret/images/reduce_icon.png'
import new_detail from '../../assret/images/new_detail.png'
import print_select from '../../assret/images/print_select.png'
import new_detail_white from '../../assret/images/new_detail_white.png'
import refresh from '../../assret/images/refresh.png'

class IncomeForm extends React.Component<any>{
    column = [
        {
            title: '日期',
            dataIndex: 'day',
            key: 'day',
            className: 'list_center',
        },
        {
            title: '人数',
            dataIndex: 'count_users',
            key: 'count_users',
            className: 'list_center',
        },
        {
            title: '销售单数',
            dataIndex: 'count_paid',
            key: 'count_paid',
            className: 'list_center',
        },
        {
            title: '人均',
            dataIndex: 'user_aver',
            key: 'user_aver',
            className: 'list_center',
        },
        {
            title: '单均',
            dataIndex: 'order_aver',
            key: 'order_aver',
            className: 'list_center',
        },
        {
            title: '应收账款',
            dataIndex: 'in_amount',
            key: 'in_amount',
            className: 'list_center',
        },
        {
            title: '未结单数/未结金额',
            dataIndex: 'count_prepaid',//special_fee_total
            key: 'count_prepaid',
            className: 'list_center',
            render: (text: string, record: any) => {
                return <div>{record.count_prepaid} / {record.prepaid_fee_total}</div>
            }
        },
        {
            title: '优惠总金额',
            dataIndex: 'dis_amount',
            key: 'dis_amount',
            className: 'list_center ',
            render: (text: string, record: any) => {
                return <div className='btn_green' onClick={() => {this.discountDetail(1, record.day, '优惠总金额')}}>{record.dis_amount}</div>
            }
        },
        {
            title: '退款金额',
            dataIndex: 'refund_value',//special_fee_total
            key: 'refund_value',
            className: 'list_center',
            render: (text: string, record: any) => {
                return <div className='btn_green' onClick={() => {this.discountDetail(2, record.day, '退款明细')}}>{record.refund_value}</div>
            }
        },
        {
            title: '退菜数/退菜金额',
            dataIndex: 'refund_dish_num',//special_fee_total
            key: 'refund_dish_num',
            className: 'list_center',
            render: (text: string, record: any) => {
                return <div className='btn_green' onClick={() => {this.discountDetail(3, record.day, '退款明细')}}>{record.order_dish_refund_num} / {record.order_dish_refund_value}</div>
            }
        },
        {
            title: '实收账款',
            dataIndex: 'act_amount',//special_fee_total
            key: 'act_amount',
            className: 'list_center',
        },
        {
            title: '人民币',
            dataIndex: 'money_fee',//special_fee_total
            key: 'money_fee',
            className: 'list_center',
        },
        {
            title: '支付宝条形码支付',
            dataIndex: 'ali_micro_fee',//special_fee_total
            key: 'ali_micro_fee',
            className: 'list_center',
        },
        {
            title: '微信条码支付',
            dataIndex: 'wechat_micro_fee',//special_fee_total
            key: 'wechat_micro_fee',
            className: 'list_center',
        },
        {
            title: '抵扣券实收',
            dataIndex: 'exchange_coupon_fee',//special_fee_total
            key: 'exchange_coupon_fee',
            className: 'list_center ',
            render: (text: string, record: any) => {
                return <div className='btn_green' onClick={() => {this.discountDetail(4, record.day, '抵扣券使用明细')}}>{record.exchange_coupon_fee}</div>
            }
        },
        {
            title: '微信在线支付',
            dataIndex: 'wechat_online_fee',//special_fee_total
            key: 'wechat_online_fee',
            className: 'list_center',
        },
        {
            title: '余额支付',
            dataIndex: 'over_amount',  //present_value
            key: 'over_amount',  //present_value
            className: 'list_center',
        },
        {
            title: '套餐实收',
            dataIndex: 'meal_fee_total',  //present_value
            key: 'meal_fee_total',  //present_value
            className: 'list_center',
            render: (text: string, record: any) => {
                return <div className='btn_green' onClick={() => {this.discountDetail(5, record.day, '套餐使用明细')}}>{record.meal_fee_total}</div>
            }
        },
        {
            title: '充值实收',
            dataIndex: 'recharge_act',  //present_value
            key: 'recharge_act',  //present_value
            className: 'list_center',
        },
        {
            title: '美团支付',
            dataIndex: 'meituan_pay_total',  //present_value
            key: 'meituan_pay_total',  //present_value
            className: 'list_center',
        },
        {
            title: '口碑支付',
            dataIndex: 'koubei_pay_total',  //present_value
            key: 'koubei_pay_total',  //present_value
            className: 'list_center',
        },
        {
            title: '权益卡实收',
            dataIndex: 'equity_card_fee',  //present_value
            key: 'equity_card_fee',  //present_value
            className: 'list_center',
        },
        {
            title: '银行卡支付',
            dataIndex: 'bank_pay_total',  //present_value
            key: 'bank_pay_total',  //present_value
            className: 'list_center',
        },
        {
            title: "更新时间",
            dataIndex: "updated_at",
            key: "updated_at",
            className: "list_center"
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            className: 'list_center',
            fixed: 'right' as 'right',
            width: 180,
            render: (text: string, record: any) => {
                return <div style={{display: 'flex'}}>
                    <Tooltip placement="bottomRight" title={'明细'}>
                        <img src={new_detail} className='table_icon' onClick={() => this.toDetail(record)}></img>
                    </Tooltip>
                    <Tooltip placement="bottomRight" title={'打印'}>
                        <img src={print_select} className='table_icon' onClick={() => this.printerTable(record)}></img>
                    </Tooltip>
                    <Tooltip placement="bottomRight" title={'刷新'}>
                        <img src={refresh} className='table_icon' onClick={() => this.refresh(record)}></img>
                    </Tooltip>
                </div>
            }
        },
    ];

    state = {
        list: [],
        current: 1,
        pageSize: 10,
        count: 0,
        start_time: '',
        end_time: '',
        visible: false,
        title: '',
        type: 0,
        disInfo: {
            "dis_value_total": "0.00",//优惠总金额
            "discount_value": "0.00",//优惠券
            "present_value": "0.00",//赠送
            "single_value": "0.00",//整单打折
            "no_pay_value": "0.00",//免单
            'meal_dis_value': '0.00'
        },
        refundInfo: {
            total: '0.00',
            list: []
        },
        dishInfo: {
            count: 0,
            total: '0.00',
            list: []
        },
        deductionInfo: {
            total: '0.00',
            list: []
        },
        setInfo: {
            total: '0.00',
            list: []
        },
        preview: {
            "order_dish_refund_detail": {//退款退菜明细项
                "dish_pay_refund": "0.00",//退款金额
                "dish_order_refund": "287.90"//退菜金额
            },
        }
    }

    componentDidMount(){
        const nowDate = new Date();
        let currMonthStart = `${nowDate.getFullYear()}-${this.formatData(nowDate.getMonth() + 1)}-01`;
        let currMonthEnd = `${nowDate.getFullYear()}-${this.formatData(nowDate.getMonth() + 1)}-${this.formatData(nowDate.getDate())}`;
        this.setState({
            start_time: currMonthStart,
            end_time: currMonthEnd
        }, () => {
            this._initInfo()
        })
    }

    formatData = (num: number) => {
        return num > 9 ? num : `0${num}`;
    };

    // 初始化数据
    _initInfo = async () => {
        let { pageSize, current, start_time, end_time } = this.state;
        let params:any = {
            current,
            pageSize
        }
        if(start_time !== ''){
            params.start_time = start_time;
            params.end_time = end_time;
        }
        let res:any = await getRequest(apiUrl.shopReport, params);
        if(res.code === 1){
            this.setState({
                count: res.data.preview.count,
                list: res.data.list,
                preview: res.data.preview
            })
        }
    }

    toDetail = (record: any) => {
        if(!getIsPermisson('1_3_1_2')){
            messageCustom()
            return;
        }
        this.props.history.push({
          pathname: "/sidebar/financeDetail",
          state: {
            open_at: record.day,
            close_at: record.day,
            from: 'income'
          }
        });
      };

    pageChange = (page: number) => {
        this.setState({
            current: page
        }, ()=>{
            this._initInfo();
        })
    }
    // 导出
    exportTable = async () => {
        if(!getIsPermisson('1_3_1_1')){
            messageCustom()
            return;
        }
        const token = localStorage.getItem('token');
        const shop_id = localStorage.getItem('shop_id');
        window.open(`${process.env.REACT_APP_BASEURL}${apiUrl.revenueExport}?token=${token}&shop_id=${shop_id}&start_time=${this.state.start_time}&end_time=${this.state.end_time}`)
    }

    refresh = async (record: any) => {
        if(!getIsPermisson('1_3_1_4')){
            messageCustom()
            return;
        }
        let res: any = await getRequest(apiUrl.freshReport,{date: record.day})
        if(res.code == 1){
            message.success('刷新成功')
            this._initInfo()
        }
    }

    // 打印
    printerTable = (record: any) => {
        if(!getIsPermisson('1_3_1_3')){
            messageCustom()
            return;
        }
        Modal.confirm({
            title: '提示',
            content: `是否确定打印${record.day}的营业收入`,
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
               let res: any = await getRequest(apiUrl.shopReportPrinter,{date: record.day})
                if(res.code === 1){
                    message.success('打印成功')
                }
            },
            onCancel() {
            },
        });
    }

    discountDetail = async (type: number, day: string, title: string) => {
        let params = {
            detail_type: type,
            start_time: day,
            end_time: day
        }
        let res:any = await getRequest(apiUrl.discountDetail, params)
        if(res.code == 1){
            if(type == 1){
                this.setState({
                    disInfo: res.data
                })
            }
            if(type == 2){
                this.setState({
                    refundInfo: res.data
                })
            }
            if(type == 3){
                this.setState({
                    dishInfo: res.data
                })
            }
            if(type == 4){
                this.setState({
                    deductionInfo: res.data
                })
            }
            if(type == 5){
                this.setState({
                    setInfo: res.data
                })
            }
            this.setState({
                type,
                title,
                visible: true,
            })
        }
    }

    onCallback = (list:any) => {
        console.log(list)
        this.setState({
            start_time: list[0],
            end_time: list[1],
        }, () => {
            this._initInfo();
        })
    }

    // 详情明细
    toCouponDetail = () => {
        let {start_time, end_time} = this.state;
        let preview:any = this.state.preview;
        if(!start_time){
            message.warn('请先选择一个日期间隔');
            return;
        }
        if(preview.discount_total && preview.discount_total == 0){
            message.warn('暂没有使用优惠');
            return;
        }

        this.props.history.push({
            pathname: "/sidebar/incomeDetail",
            state: {
                open_at: start_time,
                close_at: end_time
            }
        });
    }

    render(){
        let { list, current, pageSize, start_time, end_time, count, disInfo } = this.state;
        let preview: any = this.state.preview;
        return <div className='income' style={{padding: '20px'}}>
            <div className='charge_title mb20'>营业收入</div>
            <div style={{display: 'flex', alignItems:'center', justifyContent: 'space-between', paddingRight:'50px'}}>
                <div>
                    <CustomDatePicker callback={(list: any) => { this.onCallback(list) }} current={4} currentDate={[start_time, end_time]}></CustomDatePicker>
                </div>
                <div className='export_btn' onClick={this.exportTable}>
                    <img src={goodsExport} />导出
                </div>
            </div>
            <div className='data_wrap mb20 data_shadow income_order'>
                    <div className='income_order_t'>
                        <div className='income_order_t_title'>订单数据</div>
                        <img src={charge_list} className='income_order_t_img' />
                    </div>
                    <div className="income_order_value">
                        <div className='income_order_value_l'>就餐人数</div>
                        <div className='income_order_value_r'>{preview.user_count}人</div>
                    </div>
                    <div className="income_order_value">
                        <div className='income_order_value_l'>订单数量</div>
                        <div className='income_order_value_r'>{preview.pay_count}单</div>
                    </div>
            </div>

            <div className='mb20 data_wrap'>
                <div className='data_shadow income_item'>
                    <div className='income_total'>
                        <div className='income_total_l'>应收账款</div>
                        <div className='income_total_r'>￥{preview.origin_total}</div>
                    </div>
                    <div className="income_info">
                        <div className='income_info_l'>日均销售额</div>
                        <div className='income_info_r'>￥{preview.origin_day_aver}</div>
                    </div>
                    <div className='income_rate'>
                        <div className='income_rate_l'>
                            <Tooltip title={`用户消费后应该付的原价金额，未扣除优惠和退款`}>
                                <Icon type="question-circle" theme="filled" />
                            </Tooltip>
                        </div>
                        <div className='income_rate_r'>
                            环比<img src={preview.origin_is_up ? deep_add : deep_del} />{preview.origin_chain_ratio}
                        </div>
                    </div>
                </div>
                <img src={reduce_icon} className='income_icon_reduce' />
                <div className='data_shadow income_item'>
                    <div className='income_total borderB'>
                        <div className='income_total_l'>优惠金额</div>
                        <div className='income_total_r'>￥{preview.discount_total}</div>
                    </div>
                    <div className='income_con' style={{paddingBottom: '20px'}}>
                        {
                            preview.discount_detail && preview.discount_detail.map((item:any) => {
                                return <div className="income_info" key={item.name}>
                                    <div className='income_info_l'>{item.name}</div>
                                    <div className='income_info_r'>￥{item.value}</div>
                                </div>
                            })
                        }

                        <div style={{width:'100%', display: 'flex', justifyContent:'flex-end'}}>
                            <div className='detail_btn' onClick={() => this.toCouponDetail()}>
                                <img src={new_detail_white} />
                                详情明细
                            </div>
                        </div>
                    </div>
                </div>
                {/* <img src={reduce_icon} className='income_icon_reduce' />
                <div className='data_shadow income_item'>
                    <div className='income_total borderB'>
                        <div className='income_total_l'>退款退菜</div>
                        <div className='income_total_r'>￥{preview.order_dish_refund_total}</div>
                    </div>
                    <div className='income_con' style={{paddingBottom: '20px'}}>
                        <div className="income_info">
                            <div className='income_info_l'>退款金额</div>
                            <div className='income_info_r'>￥{preview.order_dish_refund_detail.dish_pay_refund}</div>
                        </div>
                        <div className="income_info">
                            <div className='income_info_l'>退菜金额</div>
                            <div className='income_info_r'>￥{preview.order_dish_refund_detail.dish_order_refund}</div>
                        </div>
                    </div>
                </div> */}
                <img src={equal_icon} className='income_icon_equal' />
                <div className='data_shadow income_item'>
                    <div className='income_total'>
                        <div className='income_total_l'>实收账款</div>
                        <div className='income_total_r'>￥{preview.actual_total}</div>
                    </div>
                    <div className="income_info">
                        <div className='income_info_l'>日均实收金额</div>
                        <div className='income_info_r'>￥{preview.actual_day_aver}</div>
                    </div>
                    <div className='income_rate'>
                        <div className='income_rate_l'>
                            <Tooltip title={`实收账款=应收账款-优惠金额-退款退菜`}>
                                <Icon type="question-circle" theme="filled" />
                            </Tooltip>
                        </div>
                        <div className='income_rate_r'>
                            环比<img src={preview.actual_is_up ? deep_add : deep_del} />{preview.actual_chain_ratio}
                        </div>
                    </div>
                </div>
            </div>

            <div className='finance_table_wrap'>
                <Table
                    dataSource={list}
                    size="middle"
                    columns={this.column}
                    rowKey={"id"}
                    scroll={{x: 2000}}
                    locale={{emptyText: '暂无数据'}}
                    pagination={{
                        current,
                        total: count,
                        pageSize,
                        hideOnSinglePage: true,
                        onChange: this.pageChange,
                        showQuickJumper: true,
                        showSizeChanger: true,
                        showTotal: (total: number) => `共${total}条`,
                        onShowSizeChange: (current:number, size: number) => {this.setState({pageSize: size}, () => {this._initInfo();})}

                    }}
                    /></div>

            <Modal
                visible={this.state.visible}
                footer={null}
                title={this.state.title}
                onCancel={() => {this.setState({visible: false})}}
                >
                    {this.state.type == 1
                        ? <div className='info_wrap'>
                            <div className='info_title'>优惠总金额： {disInfo.dis_value_total}元</div>
                            <div className='dis_item'>
                                <div className='dis_item_l'>优惠券：</div>
                                <div className='dis_item_r'>{disInfo.discount_value}元</div>
                            </div>
                            <div className='dis_item'>
                                <div className='dis_item_l'>整单打折：</div>
                                <div className='dis_item_r'>{disInfo.single_value}元</div>
                            </div>
                            <div className='dis_item'>
                                <div className='dis_item_l'>免单：</div>
                                <div className='dis_item_r'>{disInfo.no_pay_value}元</div>
                            </div>
                            <div className='dis_item'>
                                <div className='dis_item_l'>赠菜：</div>
                                <div className='dis_item_r'>{disInfo.present_value}元</div>
                            </div>
                            <div className='dis_item'>
                                <div className='dis_item_l'>套餐优惠：</div>
                                <div className='dis_item_r'>{disInfo.meal_dis_value}元</div>
                            </div>
                        </div>
                        : null
                    }

                    {
                        this.state.type == 2
                        ? <div className='info_wrap'>
                            <div className='info_title'>总退款金额：{this.state.refundInfo.total}</div>
                            {
                                this.state.refundInfo.list.map((item: any) => {
                                    return <div className='refund_item' key={item}>
                                                <div className='refund_order'>订单号：{item.code} </div>
                                                <div className='refund_bottom'>
                                                    <div className='user'>
                                                        <img src={item.headimgurl}></img>
                                                        <div>{item.nickname}</div>
                                                    </div>
                                                    <div className='refund_money'>{item.refund_name}：{item.refund_value}元</div>
                                                </div>
                                            </div>
                                })
                            }
                        </div>
                        : null
                    }
                    {
                        this.state.type == 3
                        ?   <div className='info_wrap'>
                                <div className='info_title'>退菜数 / 退菜金额：{this.state.dishInfo.count} / {this.state.dishInfo.total}</div>
                                {
                                    this.state.dishInfo.list.map((item: any) => {
                                        return <div className='refund_item' key={item}>
                                                    <div className='refund_order'>订单号：{item.code} </div>
                                                    <div className='refund_bottom'>
                                                        <div className='user'>
                                                            <img src={item.headimgurl}></img>
                                                            <div>{item.nickname}</div>
                                                        </div>
                                                    </div>
                                                    <div className='info_title'>退菜</div>
                                                    {
                                                        item.list.map((dish: any) => {
                                                            return <div className='dish_item' key={dish}>
                                                            <div className='dish_l'>{dish.name} X{dish.count}</div>
                                                            <div className='dish_r'>{dish.price}元</div>
                                                        </div>
                                                        })
                                                    }
                                                </div>
                                    })
                                }
                            </div>
                        : null
                    }
                    {
                        this.state.type == 4
                        ? <div className='info_wrap'>
                            <div className='info_title'>抵扣券实收：{this.state.deductionInfo.total}元</div>
                            {this.state.deductionInfo.list.map((item: any) => {
                                return <div className='deduction_item' key={item}>
                                    <div className=''>{item.channel_name} {item.ex_name} X{item.count}</div>
                                    <div>{item.total_fee}</div>
                                </div>
                            })}
                        </div>
                        : null
                    }
                    {
                        this.state.type == 5
                        ? <div className='info_wrap'>
                            <div className='info_title'>套餐实收：{this.state.setInfo.total}元</div>
                            {this.state.setInfo.list.map((item: any) => {
                                return <div className='deduction_item' key={item}>
                                    <div className=''>{item.name} X{item.count}</div>
                                    <div>{item.total_fee}</div>
                                </div>
                            })}
                        </div>
                        : null
                    }
                </Modal>
        </div>
    }
}

export default IncomeForm;