import {observer} from "mobx-react";
import React from "react";
import {Button, Input, message, Row, Col, Select,} from "antd";
import { postRequest, getRequest } from '../../api/http';
import { apiUrl } from '../../api/api_url';

const { Option } = Select;

const key = 'updatable';

@observer
class PrinterDepartEdit extends React.Component<any> {

    state = {
        loading: false,
        name: '',
        format: 0,
        print_id: 0,
        print_name: '',
        productList: [],
        print_format_list: [], //制作单格式
    }

    componentDidMount () {
        const {location} = this.props;
        if(location.state !== undefined){
            let record = location.state.record;
            this.setState({
                name: record.department_name,
                format: record.format,
                print_id: record.print_id,
                print_name: record.print_name
            })
        }
        this.onInitList()
        this.getFormat()
    }

    // 初始化列表
    onInitList = async () => {
        const res:any = await getRequest(apiUrl.printerList);
        if(res.code === 1){
            this.setState({
                productList: res.data.list,
            })
        }
    }

    nameChange = (e:any) => {
        this.setState({
            name: e.target.value.trim()
        })
    }

    // 打印机类型
    typeChange = (e:string) => {
        this.setState({
            format: e
        })
    }

    // 打印机选择
    printerChange = (e:any) => {
        this.setState({
            print_id: e
        })
    }

    //打印机制作单格式
    getFormat = async () => {
        const res:any = await getRequest(apiUrl.getFormat);
        if(res.code === 1){
            this.setState({
                print_format_list: res.data
            })
        }
    }

    handleSubmit = async (e:any) => {
        message.loading({ content: '发布中', key });
        e.preventDefault();

        const {location} = this.props;
        const { name, format, print_id } = this.state;

        var tableInfo:any = {};

        if(location.state !== undefined){
            tableInfo.id = location.state.record.id;
        }

        if(name === ''){
            message.warning({ content: '请输入打印部门名称!', key, duration: 2 });
            return;
        }

        if(print_id === 0){
            message.warning({ content: '请选择打印机!', key, duration: 2 });
            return;
        }

        tableInfo.name = name;
        tableInfo.format = format;
        tableInfo.print_id = print_id;

        if(location.state !== undefined){
            const res:any = await postRequest(apiUrl.printDepartEdit,tableInfo);
            if(res['code'] === 1){
                message.success({ content: '配置成功!', key, duration: 2 });
                this.props.history.push('/sidebar/printerDepart');
            }else{
                message.error({ content: '配置失败!', key, duration: 2 });
            }
        }else{
            const res:any = await postRequest(apiUrl.printDepartAdd,tableInfo);
            if(res['code'] === 1){
                message.success({ content: '配置成功!', key, duration: 2 });
                this.props.history.push('/sidebar/printerDepart');
            }else{
                message.error({ content: '配置失败!', key, duration: 2 });
            }
        }
    };

    // 返回上一页
    goBack = () => {
        this.props.history.goBack();
    }

    render(){
        const { print_format_list, name, format, productList, print_name } = this.state;

        return <div className='l_content'>
                <div className="edit_title">配置打印部门信息</div>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">打印部门名称</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <Input placeholder="请输入打印部门名称" value={name} onChange={(e) => this.nameChange(e)}/>
                    </Col>
                </Row>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">打印机选择</span>
                        </div>
                    </Col>
                    <Col span={14}>
                        <Select style={{ width: 150 }} onChange={this.printerChange} placeholder={print_name !== '' ? print_name : '请选择打印机'}>
                            {
                                productList.map((item: any) => {
                                    return <Option value={item.id} key={item.id}>{item.name}</Option>
                                })
                            }
                        </Select>
                    </Col>
                </Row>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="color_black">制作单格式</span>
                        </div>
                    </Col>
                    <Col span={14}>
                        <Select style={{ width: 150 }} onChange={this.typeChange} placeholder={format == 0 ? '总单' : '一菜一切'}>
                            {
                                print_format_list.map((item: any, index: any) => {
                                    return (
                                        <Option value={index}>{item}</Option>
                                    )
                                })
                            }
                        </Select>
                    </Col>
                </Row>

                <Row>
                    <Col span={12}>
                        <div className="bottom_wrap">
                            <Button type="primary" ghost onClick={this.goBack}>
                                取消
                            </Button>
                            <Button type="primary" onClick={this.handleSubmit}>
                                保存
                            </Button>
                        </div>
                    </Col>
                </Row>

        </div>;
    }

}
export default PrinterDepartEdit