import React from 'react';
import { Row, Col } from 'antd';
import '../management.scss';

import winAddExchange1 from '../../../../../../assret/images/winAddExchange1.png';
import winAddExchange2 from '../../../../../../assret/images/winAddExchange2.png';
import winAddExchange3 from '../../../../../../assret/images/winAddExchange3.png';
import winAddExchange4 from '../../../../../../assret/images/winAddExchange4.png';

interface HelpMbaExchange {
    history: any,
}

class HelpMbaExchange extends React.Component<HelpMbaExchange> {

    render() {
        return  <Row className="typeDetail">
                    <Col className="typeDetail-title">一、功能介绍</Col>
                    <Col className="typeDetail-col">所有需要投放的兑菜券，必须要在兑换菜品管理列表中添加兑换券  ，主要刺激消费者积极性，添加兑换菜品后，消费者领取后，消费者点到该菜品时，自动使用该兑菜券，当用户下单到厨房后领取该菜品券则不能使用该菜品券，要用户加菜点到该菜品才可使用。</Col>
                    <Col className="typeDetail-title">二、功能路径</Col>
                    <Col className="typeDetail-col">电脑端登录后台-营销管理-兑换菜品列表-添加菜品券。</Col>
                    <Col className="typeDetail-col">
                        <img src={winAddExchange1} />
                    </Col>
                    <Col className="typeDetail-title">三、操作流程</Col>
                    <Col className="typeDetail-col">1、<span className="txt-bold">添加菜品券</span>，在“菜品券列表”界面下点击“添加”</Col>
                    <Col className="typeDetail-col">
                        <img src={winAddExchange2} />
                    </Col>
                    <Col className="typeDetail-col">
                        <img src={winAddExchange3} />
                    </Col>
                    <Col className="typeDetail-col">1、优惠名称：填写名称</Col>
                    <Col className="typeDetail-col">2、推广渠道有：首页、领取中心、礼包渠道、内部发行，选择首页只在点餐小程序显示，选择领取中心，只在点餐小程序领取中心领取，选择礼包渠道，只有在创建权益卡或进店有礼可使用，选择内部发行，只能在直接发券起效果</Col>
                    <Col className="typeDetail-col">3、有效期：可以填写固定日期或领取后使用日期</Col>
                    <Col className="typeDetail-col">4、类型：有两种类型，单菜品和多菜品，默认选择单菜品，选择单菜品时，只能添加一个菜品为兑换菜品，当选择多菜品时，可选择多个菜品</Col>
                    <Col className="typeDetail-col">5、兑换菜品数量默认为1，可修改兑换菜品数量，例如：江湖芝士莓莓只只想给用户兑换一份时，填写数量“1”</Col>
                    <Col className="typeDetail-col">6、选择多菜品时，可添加多个菜品，例如：添加了5个菜品想用户兑换其中3份，则可兑换数量填写“3”，如果想用户5份菜品全部可兑换，则可兑换数量填写“5”,如下图</Col>
                    <Col className="typeDetail-col">
                        <img src={winAddExchange4} />
                    </Col>
                    <Col className="typeDetail-col">7、桌台使用：填写桌台使用，例如想一张桌台只能使用1张，则填写1，填写-1，则每张桌台不限制使用数量</Col>
                    <Col className="typeDetail-col">8、发行数量：填写发行数量</Col>
                    <Col className="typeDetail-col">9、每人领取：可以显示消费者领取优惠券数量</Col>
                    <Col className="typeDetail-col">10、排序：数字越大，排序越靠前</Col>
                    <Col className="typeDetail-col">11、状态：启用和停用两种状态，默认选择启用，当选择停用，则用户不可领取该优惠券</Col>
                    <Col className="typeDetail-col">12、适用门店：如没有选择适用门店，默认选择全部门店，选择部分门店时，只能在所选择的门店使用该优惠券</Col>
                    <Col className="typeDetail-col">13、不可使用时段：优惠券可以设置所有日期都可用，部分日期不可用，当选择所有时间都可以使用时，任意时间都可以使用该优惠券,当选择部分日期不可用时，所选择的那一部分时间不可使用该优惠券</Col>
                    <Col className="typeDetail-col">14、消费继续领券：每次消费，选择每次消费时，用户没次买完单可继续领取，选择消费满多少钱，用户满足条件，买完单可继续领取、选择一次领取，用户领取一次后，不可继续领取</Col>
                    <Col className="typeDetail-col">15、是否支持赠送：默认为不支持赠，优惠券只能自己消费使用，当选择支持赠送，用户领取优惠券，自己不使用时，可赠送给朋友到店消费使用</Col>
                    <Col className="typeDetail-col">16、可见用户：当选择新用户，则还未注册的用户或已经注册还没有下单的用户，可在相关渠道看到该优惠券，当选择老顾客，则交易次数>=1次的交易次数用户，可在相关渠道看到该优惠券，当全部选择时，则所有用户，可在相关渠道看到该优惠券</Col>
                    <Col className="typeDetail-col">17、使用须知：填写使用须知，在用户领取兑菜券后，用户点击使用规则显示</Col>
                </Row>
    }
}

export default HelpMbaExchange;