import React, { useEffect, useState, useRef } from 'react'
import { Select, Row, Col, Input, Radio, Button, Checkbox, message } from 'antd'
import { useHistory, useLocation } from 'react-router-dom';
import { postRequest, getRequest } from '../../../api/http'
import { apiUrl } from '../../../api/api_url'
import { getMonthNum, getMonthWeekNum } from '../../../utils/common'
import moment from 'moment'
import './index.scss'

const { Option } = Select;

const DashboardAdd = (props:any)=>{
    const history = useHistory()
    const location:any = useLocation()
    const [ workDayTotal, setWorkDayTotal ] = useState(0)
    const [ unWorkDayTotal, setUnWorkDayTotal ] = useState(0)
    const [ formVal,setFormVal ] = useState<{date:string,config:any,type:number,count_type:number,work_days:any,id:Number,avg_table_num:any,work_table_num:any,unwork_table_num:any}>({
        date: '', //日期 （ 例如：2020-10 ）
        config: {
            '1':'',
            '2':'',
            '3':'',
            '4':'',
            '5':'',
            '6':'',
            '7':'',
            '8':'',
        }, //配置 1总实收金额 2总就餐桌数 3总均桌消费 4总新顾客桌数 5总老顾客占比 6总开桌率 7消费频次 8复购率 （ 例如：{"1":100,"2":10,"3":5,"4":6,"5":4,"6":50,"7":8,"8":1} 键为配置ID，值为你想设置的值）
        type: 1, //目标分配方式： 1平均分配 2工作日分配
        count_type: 1, //生效方式： 1当月1日 2立即生效
        work_days: [], //店铺工作日，值范围1-7 ( 例如： [1,2,3,4,5] ）
        id: 0, //经营目标ID，仅修改时传   
        // work_day_ratio: '', //工作日分配比例，值范围0-100
        // remark: [], //工作日分配比例，值范围0-100
        avg_table_num:'',
        work_table_num:'',
        unwork_table_num:''     
    })

    const inputList:any = [
        {name:'总实收金额',field:'config',rule:'require',right_text:'元',type:'1',disabled:false},
        {name:'总就餐桌台',field:'config',rule:'',right_text:'桌',type:'2',disabled:true,func:()=>getAllTable()},
        {name:'总均桌消费',field:'config',rule:'',right_text:'元',type:'3',disabled:true,func:()=>getAvgMoney()},
        {name:'总老顾客占比',field:'config',rule:'require',right_text:'%',type:'5',disabled:false},
        {name:'总新顾客桌数',field:'config',rule:'',right_text:'桌',type:'4',disabled:true,func:()=>getNewTable()},
        {name:'总开桌率',field:'config',rule:'',right_text:'%',type:'6',disabled:true,func:()=>getOpenTableRatio()},
        {name:'消费频次',field:'config',rule:'require',right_text:'次',type:'7',disabled:false},
        {name:'复购率',field:'config',rule:'require',right_text:'%',type:'8',disabled:false},
    ]
    const [ monthList, setMonthList] = useState<any>([])
    const [ tabelTotal, setTabelTotal] = useState(0)
    const dayList = [ '周一', '周二', '周三', '周四', '周五', '周六', '周日',]

    useEffect(()=>{
        //设置工作日总数，默认每月1号开始算
        let formValTemp = JSON.parse(JSON.stringify(formVal))

        //获取当前月份之后的12个月
        let arr:any = []
        for(var i=1;i<=12;i++){
            if(i>=new Date().getMonth()+1){
                arr.push(new Date().getFullYear()+'-'+add0(i))
            }
        }    
        if(arr.length<12){
            let length = arr.length
            for(var i=1;i<=12-length;i++){
                arr.push(new Date().getFullYear()+1+'-'+add0(i))
            }  
        }
        formValTemp.date = arr[0]
        setWorkDayTotal(getMonthNum(arr[0].split('-')[0],arr[0].split('-')[1],1))
        setFormVal({
            ...formValTemp
        })
        setMonthList(arr)
        getTableTotal()
        getDetail()
    },[])

    const getTableTotal = async() =>{
        const res:any = await getRequest(apiUrl.getTableTotal)
        if(res.code === 1){
            setTabelTotal(res.data.total)
        }
    }

    const getDetail = async() => {
        if(location.state&&location.state.id){
            const res:any = await postRequest(apiUrl.getTargetDetail,{id:location.state.id})
            if(res.code === 1){
                let configTemp:any = {}
                res.data&&res.data.items.map((item:any,index:number)=>{
                    configTemp[item.config_id] = item.target
                })
                // configTemp[2] = getAllTable()
                // configTemp[3] = getAvgMoney()
                // configTemp[4] = getNewTable()
                // configTemp[5] = getTableTotal()
                let monthNum = 0  //月份天数
                let workDayTotalTemp = 0
                let tempTiems = getNowTimes(res.data.date,res.data.count_type)//获取目标时间年月日
                monthNum = getMonthNum(tempTiems.year,tempTiems.month,tempTiems.start)
                if(res.data.type===1){
                    setWorkDayTotal(monthNum)
                    setUnWorkDayTotal(0)

                }else{
                    res.data.work_days.map((item:any,index:any)=>{
                        workDayTotalTemp += getMonthWeekNum(tempTiems.year,tempTiems.month,tempTiems.start,item)
                    })
                    setWorkDayTotal(workDayTotalTemp)
                    setUnWorkDayTotal(monthNum-workDayTotalTemp)
                }

                setFormVal({
                    date: res.data.date, //日期 （ 例如：2020-10 ）
                    config: configTemp, //配置 1总实收金额 2总就餐桌数 3总均桌消费 4总新顾客桌数 5总老顾客占比 6总开桌率 7消费频次 8复购率 （ 例如：{"1":100,"2":10,"3":5,"4":6,"5":4,"6":50,"7":8,"8":1} 键为配置ID，值为你想设置的值）
                    type: res.data.type, //目标分配方式： 1平均分配 2工作日分配
                    count_type: res.data.count_type, //生效方式： 1当月1日 2立即生效
                    work_days: res.data.work_days?res.data.work_days:[], //店铺工作日，值范围1-7 ( 例如： [1,2,3,4,5] ）
                    avg_table_num:res.data.avg_table_num,
                    work_table_num:res.data.work_table_num,
                    unwork_table_num:res.data.unwork_table_num, 
                    // work_day_ratio: res.data.work_day_ratio, //工作日分配比例，值范围0-100
                    // remark:JSON.parse(res.data.remark),
                    id:location.state.id,
                })
            }else{
                message.error(res.message||'获取目标详情信息失败')
            }
        }
    }
    
    //获取总就餐桌数
    const getAllTable:any = () => {
        if(formVal.type===1){
            //目标平均分配
            if(formVal.avg_table_num){
                return formVal.avg_table_num*workDayTotal
            }else{
                return 0
            }
        }else{
            //工作日比例
            if(formVal.work_table_num||formVal.unwork_table_num){
                return formVal.work_table_num*workDayTotal+formVal.unwork_table_num*unWorkDayTotal
            }else{
                return 0
            }
        }
    }    

    //获取均桌消费
    const getAvgMoney = () => {
        if(getAllTable()&&formVal.config[1]){
            return getAllTable()?(formVal.config[1]/getAllTable()).toFixed(2):0
        }else{
            return 0
        }
    }    

    //获取新顾客桌数
    const getNewTable = () => {
        if(getAllTable()&&formVal.config[5]){
            return Math.round(getAllTable()-formVal.config[5]*getAllTable()/100)
        }else{
            return 0
        }
    }    

    //获取开桌率
    const getOpenTableRatio = () => {
        if(getAllTable()){
            return (getAllTable()/tabelTotal/(workDayTotal+unWorkDayTotal)*100).toFixed(2)
        }else{
            return 0
        }
    }    
    

    const add0 = (i:number) => {
        let num =  i<10 ? '0'+i : i
        return num
    }

    //监听字段变化
    const changeFieldVal = ( e: any, field: string, type:string='' ) => {
        let obj:any = JSON.parse(JSON.stringify(formVal))
        let val = typeof e === 'string' && field != 'description' ? e.trim() : e

        switch(field){
            case 'config':
                obj[field][type] = val
                // if(type==='1'||type==='2'){
                //     if(obj[field]['1']&&obj[field]['2']){
                //         obj[field]['3'] = (obj[field]['1']/obj[field]['2']).toFixed(2)
                //     }else{
                //         obj[field]['3'] = ''
                //     }
                // }
                break;
            case 'type':
                if(val==1){
                    setWorkDayTotal(workDayTotal+unWorkDayTotal)
                    setUnWorkDayTotal(0)
                }else{
                    setUnWorkDayTotal(workDayTotal+unWorkDayTotal)
                    setWorkDayTotal(0)
                    obj['work_days'] = []
                }
                obj[field] = val
                // if(type==='1'||type==='2'){
                //     if(obj[field]['1']&&obj[field]['2']){
                //         obj[field]['3'] = (obj[field]['1']/obj[field]['2']).toFixed(2)
                //     }else{
                //         obj[field]['3'] = ''
                //     }
                // }
                break;
            // case 'avg_table_num':
            //     console.log('workDayTotal',workDayTotal)
            //     //每日桌台计算总就餐桌台
            //     if(val){
            //         obj['config']['2'] = val*workDayTotal
            //     }else{
            //         obj['config']['2'] = 0
            //     }
            //     //如果存在总实收金额，联动计算总均桌消费
            //     if(obj['config']['1']){
            //         obj['config']['3'] = obj['config']['2']?(obj[field]['1']/obj[field]['2']).toFixed(2):0
            //     }
            //     obj[field]= val
            //     break;
            // case 'work_table_num':
            // case 'unwork_table_num':
                
            //     break;
            default :
                obj[field]= val
        }
        console.log('obj',obj)
        setFormVal(obj)
    }

    const onSubmit = async(type:Number) => {
        var flag = validatorParams()
        if(flag){
            let formValTemp = JSON.parse(JSON.stringify(formVal))
            formValTemp.config['2'] = getAllTable()
            formValTemp.config['3'] = getAvgMoney()
            formValTemp.config['4'] = getNewTable()
            formValTemp.config['6'] = getOpenTableRatio()
            formValTemp.config = JSON.stringify(formValTemp.config)
            formValTemp.work_days = JSON.stringify(formValTemp.work_days)
            if(!formValTemp.id) delete formValTemp.id
            const res:any = await postRequest(apiUrl.targetSave, {...formValTemp});
            if(res.code==1){
                message.success(formValTemp.id?'修改成功':'保存成功')
                if(type==2){
                    setFormVal({
                        date: monthList[0], //日期 （ 例如：2020-10 ）
                        config: {
                            '1':'',
                            '2':'',
                            '3':'',
                            '4':'',
                            '5':'',
                            '6':'',
                            '7':'',
                            '8':'',
                        }, //配置 1总实收金额 2总就餐桌数 3总均桌消费 4总新顾客桌数 5总老顾客占比 6总开桌率 7消费频次 8复购率 （ 例如：{"1":100,"2":10,"3":5,"4":6,"5":4,"6":50,"7":8,"8":1} 键为配置ID，值为你想设置的值）
                        type: 1, //目标分配方式： 1平均分配 2工作日分配
                        count_type: 1, //生效方式： 1当月1日 2立即生效
                        work_days: [], //店铺工作日，值范围1-7 ( 例如： [1,2,3,4,5] ）
                        avg_table_num:'',
                        work_table_num:'',
                        unwork_table_num:'',
                        // work_day_ratio: '', //工作日分配比例，值范围0-100
                        // remark: '', //工作日分配比例，值范围0-100
                        id:0,
                        // id:location.state&&location.state.id?location.state.id:0                    
                    })
                }else{
                    history.goBack()
                }
            }else{
                message.success(res.message||(formValTemp.id?'修改失败':'保存失败'))
            }
        }
    }

    // 校验提交数据
    const validatorParams = () =>{
        // if(formVal.date === '0'){
        //     message.error('请选择目标时间')
        //     return false
        // }
        if(!formVal.config['1']){
            message.error('请输入总实收金额')
            return false
        }
        if(formVal.type === 2){
            if(formVal.work_days.length===0){
                message.error('请选择设置工作日')
                return false
            }
            if(!formVal.work_table_num){
                message.error('请选择设置工作日桌台')
                return false
            }
            if(!formVal.unwork_table_num){
                message.error('请选择设置非工作日桌台')
                return false
            }
        }else{
            if(!formVal.avg_table_num){
                message.error('请选择设置每日桌台')
                return false
            }
        }
        let flag = true
        inputList.map((item:any,index:number)=>{
            if(!formVal.config[item.type]&&item.rule==='require'){
                message.error('请输入'+item.name)
                flag = false
                return false
            }
            if(item.type==='5'&&formVal.config[item.type]>100){
                message.error('总老顾客占比不能超过100%')
            }
        })
        if(!flag){
            return false
        }
        // console.log(formVal.config[4])
        // if(formVal.config[4]>formVal.config[1]){
        //     message.error('总新顾客桌数不能大于总就餐桌台')
        //     return false
        // }
        if(!formVal.type){
            message.error('请选择目标分配')
            return false
        }
        // if(!formVal.count_type){
        //     message.error('请选择计算目标时间')
        //     return false
        // }
        return true
    }
    const inputItem = (item:any,index:number) => {
        return <Row className="mb30" type="flex" align="middle" key={index}>
            <Col xl={3} lg={4}>
                <div>
                    <span className="star"></span>
                    <span className="color_black">{item.name}：</span>
                </div>
            </Col>
            <Col xl={6} lg={8}>
                <Input type="number" value={item.func?item.func():formVal.config[item.type]}  placeholder={'请输入'+item.name} onChange={(e)=>{changeFieldVal(e.currentTarget.value,item.field,item.type)}} disabled={item.disabled}/>
            </Col>
            <Col xl={6} lg={6}className="ml10">
                {item.right_text}
            </Col>
        </Row>
    }

    //选择目标时间
    const changeTarget = (e:any,field:string) => {
        console.log('field',field === 'date')
        let workDayTotalTemp = 0
        let monthNum = 0  //月份天数
        if(field === 'date'){
            let tempTiems = getNowTimes(e,formVal.count_type) //获取目标时间年月日
            monthNum = getMonthNum(tempTiems.year,tempTiems.month,tempTiems.start)
            if(formVal.type===1){
                setWorkDayTotal(monthNum)
            }else{
                formVal.work_days.map((item:any,index:any)=>{
                    workDayTotalTemp += getMonthWeekNum(tempTiems.year,tempTiems.month,tempTiems.start,item)
                })
                setWorkDayTotal(workDayTotalTemp)
            }
        }else if(field === 'count_type'){
            let tempTiems = getNowTimes(formVal.date,e)//获取目标时间年月日
            monthNum = getMonthNum(tempTiems.year,tempTiems.month,tempTiems.start)
            if(formVal.type===1){
                setWorkDayTotal(monthNum)
            }else{
                formVal.work_days.map((item:any,index:any)=>{
                    workDayTotalTemp += getMonthWeekNum(tempTiems.year,tempTiems.month,tempTiems.start,item)
                })
                setWorkDayTotal(workDayTotalTemp)
            }
            // console.log('formVal.type',formVal.type)
            // console.log('monthNum',monthNum)
            // console.log('workDayTotalTemp',workDayTotalTemp)
        }else{
            console.log('88888',field)
            let tempTiems = getNowTimes(formVal.date,formVal.count_type)//获取目标时间年月日
            monthNum = getMonthNum(tempTiems.year,tempTiems.month,tempTiems.start)
            if(formVal.type===1){
                setWorkDayTotal(monthNum)
            }else{
                e.map((item:any,index:any)=>{
                    workDayTotalTemp += getMonthWeekNum(tempTiems.year,tempTiems.month,tempTiems.start,item)
                })
                setWorkDayTotal(workDayTotalTemp)
            }
        }
        if(formVal.type===1){
            setUnWorkDayTotal(0)
        }else{
            setUnWorkDayTotal(monthNum-workDayTotalTemp)
        }
        changeFieldVal(e,field)
    }

    //获取当前计算目标时间的年月日
    const getNowTimes = (times:any,count_type:any) => {
        //判断计算目标时间方式count_type为1则每月1号，反之创建时间起
        if(count_type === 1){
            return {
                year: times.split('-')[0],
                month: times.split('-')[1],
                start: 1,
            }
        }else{
            const year = new Date().getFullYear()
            const month = add0(new Date().getMonth()+1)
            const date = new Date().getDate()
            if(year+'-'+month === times){
                return {
                    year: times.split('-')[0],
                    month: times.split('-')[1],
                    start: date,
                }
            }else{
                return {
                    year: times.split('-')[0],
                    month: times.split('-')[1],
                    start: 1,
                }
            }
        }
    }

    return (
        <div className="dashboard_add">
            <div className="l_content">
                <div className="title_text">{formVal.id?'修改目标':'添加目标'}</div>
                <Row className="mb30" type="flex" align="middle">
                    <Col xl={3} lg={4}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">目标时间：</span>
                        </div>
                    </Col>
                    <Col xl={6} lg={8}>
                        <Select value={formVal.date} style={{width:'100%'}} placeholder="请选择目标时间" onChange={(e:any)=>{changeTarget(e,'date')}} disabled={formVal.id?true:false}>
                            {/* <Option value={'0'}>请选择目标时间</Option> */}
                            {
                                monthList.map((item:any,index:number)=>{
                                    return <Option key={index} value={item}>{item}</Option>
                                })
                            }
                        </Select>
                    </Col>
                </Row>   
                {
                    inputItem(inputList[0],0)
                }    
                <Row className="mb30" type="flex"  align="middle">
                    <Col xl={3} lg={4}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">计算目标时间</span>
                        </div>
                    </Col>
                    <Col xl={6} lg={8}>
                        <Radio.Group value={formVal.count_type} onChange={(e)=>{changeTarget(e.target.value,'count_type')}} disabled={formVal.id?true:false}>
                            <Radio value={1}>每月1号</Radio>
                            <Radio value={2}>创建时间</Radio>
                        </Radio.Group>                
                    </Col>
                </Row>                    
                <Row className="mb30" type="flex" align="middle">
                    <Col xl={3} lg={4}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">桌台数</span>
                        </div>
                    </Col>
                    <Col xl={6} lg={8}>
                        <Radio.Group value={formVal.type} onChange={(e)=>{changeFieldVal(e.target.value,'type')}}>
                            <Radio value={1}>每日桌台</Radio>
                            <Radio value={2}>工作日和非工作日桌台</Radio>
                        </Radio.Group>
                    </Col>
                </Row> 
                {
                    formVal.type !== 1 ? (
                        <>
                            <Row className="mb30" type="flex" align="middle">
                                <Col xl={3} lg={4}>
                                    <div>
                                        <span className="star"></span>
                                        <span className="color_black">设置工作日</span>
                                    </div>
                                </Col>
                                <Col xl={21} lg={20}>
                                    <Checkbox.Group value={formVal.work_days} style={{width:'100%'}} onChange={(e)=>{changeTarget(e,'work_days')}}>
                                        {
                                            dayList.map((item,index)=>{
                                                return <Checkbox key={index} value={index+1}>{item}</Checkbox>
                                            })
                                        }
                                    </Checkbox.Group>
                                </Col>
                            </Row> 
                            <Row className="mb30" type="flex" align="middle">
                                <Col xl={3} lg={4}>
                                    <div>
                                        <span className="star"></span>
                                        <span className="color_black">工作日桌台</span>
                                    </div>
                                </Col>
                                <Col xl={3} lg={5}>
                                    <Input type="number" value={formVal.work_table_num}  placeholder='工作日桌台' onChange={(e)=>{changeFieldVal(e.currentTarget.value,'work_table_num')}} />
                                </Col>&nbsp;桌
                                <Col xl={1} lg={1} style={{textAlign:'center'}}></Col>
                                <Col xl={2} lg={4}>
                                    <div>
                                        <span className="star"></span>
                                        <span className="color_black">非工作日桌台</span>
                                    </div>
                                </Col>
                                <Col xl={3} lg={5}>
                                    <Input type="number" value={formVal.unwork_table_num}  placeholder='非工作日桌台' onChange={(e)=>{changeFieldVal(e.currentTarget.value,'unwork_table_num')}} />
                                </Col>&nbsp;桌
                            </Row> 
                        </>
                    ):(
                    <Row className="mb30" type="flex" align="middle">
                        <Col xl={3} lg={4}>
                            <div>
                                <span className="star"></span>
                                <span className="color_black">每日桌台</span>
                            </div>
                        </Col>
                        <Col xl={3} lg={5}>
                            <Input type="number" value={formVal.avg_table_num}  placeholder='每日桌台' onChange={(e)=>{changeFieldVal(e.currentTarget.value,'avg_table_num')}} />
                        </Col>&nbsp;桌
                    </Row>                         
                    )
                } 
                {
                    inputList.map((item:any,index:number)=>{
                        return index!==0&&inputItem(item,index)
                    })
                }   
            </div>
            <Row type="flex" justify="center" align="middle" className="footer">
                <Button type="primary" size="large" onClick={()=>{onSubmit(1)}}>保 存</Button>
                {
                    !formVal.id&&<Button type="primary" size="large" onClick={()=>{onSubmit(2)}}>保存并继续添加</Button>
                }
                <Button size="large" onClick={()=>{history.goBack()}}>取 消</Button>
            </Row>                                          
        </div>
    )
}

export default DashboardAdd
