import {observer} from "mobx-react";
import React from "react";
import {Button, Input, message, Row, Col, Select, Icon } from "antd";
import { postRequest, getRequest } from '../../api/http';
import { apiUrl } from '../../api/api_url';
import './printerEdit.scss';

const { Option } = Select;

interface PrinterEdit{
}

interface dishType{
    dishes_id: number,
    dishes_name: string,
    cat_name: string,
    printer_key: string,
    status: number //0 未选中，1 已选中，2，不可选择
}

interface catType{
    cat_status: number, //0 未选中，1 已选中，
    cat_name: string,
    dishes: dishType[]
}

const key = 'updatable';

@observer
class PrinterEdit extends React.Component<any> {

    state = {
        loading: false,
        key: '',
        name: '',
        width: '',
        is_audio: '',
        type: 'all',
        remark: '',
        cat_list: [],
        dishes: [],
        curIdx: 0,
        dishes_id: [],
        showDishes: false
    }

    componentDidMount () {
        const {location} = this.props;
        if(location.state !== undefined){
            this.initEdit(location.state.id);
        }
    }

    // 初始化编辑
    initEdit = async (id:number) => {
        let curIdx = this.state.curIdx;
        const params = {
            id: id
        };
        const res:any = await getRequest(apiUrl.printerInfo,params);
        if(res.code === 1){
            console.log(res.data);
            let list:catType[] = res.data.cat_list;
            this.setState({
                key: res.data.key,
                name: res.data.name,
                type: res.data.type,
                remark: res.data.remark,
                cat_list: res.data.cat_list,
                width:res.data.width,
                is_audio:res.data.is_audio,

            })

            if(list.length > 0){
                this.setState({
                    dishes: list[curIdx].dishes
                })
            }

            this.getDishNum();
        }
    }

    nameChange = (e:any) => {
        let { name } = this.state;
        name = e.target.value.trim();
        this.setState({
            name
        })
    }

    // 打印机类型
    typeChange = (e:string) => {
        this.setState({
            type: e
        })
    }

    // 备注修改
    remarkChange = (e:any) => {
        this.setState({
            remark: e.target.value.trim()
        })
    }

    selectCat = (index: number) => {
        let cat_list:catType[] = this.state.cat_list;
        if(cat_list[index].cat_status === 2){
            message.warning({ content: '已被绑定，不可选择!', key, duration: 2 });
        }else{
            let dishes = cat_list[index].dishes;
            if(cat_list[index].cat_status === 1){
                dishes.map((item:any, idx: number) => {
                    if(dishes[idx].status !== 2){
                        dishes[idx].status = 0;
                    }
                })
                cat_list[index].cat_status = 0;
            }else{
                dishes.map((item:any, idx:number) => {
                    if(dishes[idx].status !== 2){
                        dishes[idx].status = 1;
                    }
                })
                cat_list[index].cat_status = 1;
            }

            this.setState({
                dishes,
                curIdx: index,
                cat_list
            })
        }
    }

    cateChange = (index: number) => {
        let cat_list:catType[] = this.state.cat_list;
        if(cat_list[index].cat_status === 2){
            message.warning({ content: '已被绑定，不可选择!', key, duration: 2 });
        }else{
            let dishes = cat_list[index].dishes;

            this.setState({
                dishes,
                curIdx: index,
                cat_list
            })
        }
    }

    selectSub = (index: number) => {
        let dishes:dishType[] = this.state.dishes;
        let cat_list:catType[] = this.state.cat_list;
        let curIdx = this.state.curIdx;
        let flag = 0;

        if(dishes[index].status === 2){
            message.warning({ content: '已被绑定，不可选择!', key, duration: 2 });
            return;
        }

        if(dishes[index].status === 1){
            dishes[index].status = 0;
        }else{
            dishes[index].status = 1;
        }

        dishes.forEach(item => {
            if(item.status === 1){
                flag++;
            }
        })

        if(flag === 0){
            cat_list[curIdx].cat_status = 0;
        }else{
            cat_list[curIdx].cat_status = 1;
        }

        this.setState({
            dishes,
            cat_list
        })
    }

    getDishNum = () => {
        let arr:number[] = [];
        let cat_list:catType[] = this.state.cat_list;

        cat_list.forEach(item => {
            if(item.cat_status === 1){
                item.dishes.forEach(itemSub => {
                    if(itemSub.status === 1){
                        arr.push(itemSub.dishes_id);
                    }
                });
            }
        })

        this.setState({
            dishes_id: arr,
            showDishes: false
        })
    }

    handleSubmit = async (e:any) => {
        message.loading({ content: '发布中', key });
        e.preventDefault();

        const {location} = this.props;
        const { name, type, remark, dishes_id, width, is_audio } = this.state;

        var tableInfo:any = {};

        if(location.state !== undefined){
            tableInfo.id = location.state.id;
        }

        if(name === ''){
            message.warning({ content: '请输入打印机名称!', key, duration: 2 });
            return;
        }

        tableInfo.name = name;
        tableInfo.type = type;
        tableInfo.width = width;
        tableInfo.is_audio = is_audio;

        if(remark !== ''){
            tableInfo.remark = remark;
        }
        // if(dishes_id.length <= 0){
        //     message.warning({ content: '请选择关联菜品!', key, duration: 2 });
        //     return;
        // }

        tableInfo.dishes_id = dishes_id;

        const res:any = await postRequest(apiUrl.printerTestEdit,tableInfo);
        if(res['code'] === 1){
            message.success({ content: '配置成功!', key, duration: 2 });
            this.props.history.push('/sidebar/printer');
        }else{
            message.error({ content: '配置失败!', key, duration: 2 });
        }
    };

    // 返回上一页
    goBack = () => {
        this.props.history.goBack();
    }

    render(){
        const { key, name, type, remark, cat_list, dishes, curIdx, showDishes, dishes_id, width, is_audio} = this.state;

        return <div className='l_content'>
                <div className="edit_title">配置打印机信息</div>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="color_black">打印机ID</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <div>{key}</div>
                    </Col>
                </Row>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">打印机名称</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <Input placeholder="请输入打印机名称" maxLength={10} value={name} onChange={(e) => this.nameChange(e)}/>
                    </Col>
                </Row>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">打印机类型</span>
                            {/* <Tooltip placement="right" title={'111111'}>
                                <Icon type="exclamation-circle" theme="filled" />
                            </Tooltip> */}
                        </div>
                    </Col>
                    <Col span={14}>
                        <Select style={{ width: 120 }} onChange={this.typeChange} placeholder={type === 'all' ? '总单打印' : '菜品打印'}>
                            <Option value='all'>总单打印</Option>
                            <Option value='kitchen'>菜品打印</Option>
                        </Select>
                    </Col>
                </Row>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="color_black">备注</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <Input placeholder="请输入该打印机备注" maxLength={10} value={remark} onChange={(e) => this.remarkChange(e)}/>
                    </Col>
                </Row>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="color_black">打印机宽度</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <Select style={{ width: 200 }} value={width+""} onChange={(e:any)=>{
                            this.setState({
                                width:e
                            })
                        }} placeholder='打印机打印宽度'>
                            <Option value='80'>80</Option>
                            <Option value='58'>58</Option>
                        </Select>
                    </Col>
                </Row>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="color_black">打印机声音</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <Select style={{ width: 200 }} value={is_audio+''} onChange={(e:any)=>{
                            this.setState({
                                is_audio:e
                            })
                        }} placeholder='设置打印机声音'>
                            <Option value='0'>否</Option>
                            <Option value='1'>是</Option>
                        </Select>
                    </Col>
                </Row>

                {/* {
                    type !== 'all'
                    ?  */}
                    <Row className="mb30" type="flex" align="top">
                        <Col span={2}>
                            <div>
                                <span className="star"></span>
                                <span className="color_black">选择关联菜品</span>
                            </div>
                        </Col>
                        <Col span={6}>
                            <div className='select_wrap'>
                                <div className='select_btn' onClick={() => {this.setState({showDishes: true})}}>
                                    <div>选择菜品</div>
                                    <div>已选择{dishes_id.length}种菜品</div>
                                </div>
                                {
                                    showDishes
                                    ? <div>
                                        <div className='select_list'>
                                            <Row className='select_child'>
                                                {
                                                cat_list.map((item:any, index: number) => {
                                                    return <Col className={`select_child_li ${curIdx === index ? 'active' : ''} ${item.cat_status === 2 ? 'color_g' : ''}`}
                                                            onClick={() => this.cateChange(index)}
                                                            key={item.cat_name}>
                                                            <div>{item.cat_name}</div>
                                                            <div className={`cat_select ${item.cat_status === 1 ? 'select_active' : ''}`} onClick={() => this.selectCat(index)}>

                                                            </div>
                                                    </Col>
                                                })
                                                }
                                            </Row>
                                            <Row className='select_child color_w'>
                                                {
                                                    dishes.map((item: any, idx: number) => {
                                                        return <Col className={`select_child_li ${item.status === 2 ? 'color_g' : ''}`}
                                                                    onClick={() => this.selectSub(idx)}
                                                                    key={item.dishes_id}>
                                                            <div  className={item.status === 1 ? 'color_green' : ''}>{item.dishes_name}</div>
                                                            {item.status === 1 ? <Icon type="check" /> : null}
                                                        </Col>
                                                    })
                                                }
                                            </Row>

                                        </div>
                                        <div className='select_btn_wrap'>
                                            <div onClick={() => {this.setState({showDishes: false})}}>取消</div>
                                            <div className='select_btn_sure' onClick={this.getDishNum}>确定</div>
                                        </div>
                                    </div>
                                    : null
                                }
                            </div>
                        </Col>
                    </Row>
                    {/* : null
                }                 */}

                <Row>
                    <Col span={12}>
                        <div className="bottom_wrap">
                            <Button type="primary" ghost onClick={this.goBack}>
                                取消
                            </Button>
                            <Button type="primary" onClick={this.handleSubmit}>
                                保存
                            </Button>
                        </div>
                    </Col>
                </Row>

        </div>;
    }

}
export default PrinterEdit