import React, { useEffect, useState } from 'react'
import { Row, Button, Input, Table, message, Modal, Tag, Tabs, DatePicker, Select, Tooltip } from 'antd'
import { useHistory } from 'react-router-dom';
import { getRequest } from '../../api/http'
import { apiUrl } from '../../api/api_url'
import './index.scss'
import btn_create from '../../assret/images/btn_create.png'
import t_end from '../../assret/images/t_end.png'
import t_edit from '../../assret/images/t_edit.png'
import t_data from '../../assret/images/t_data.png'
import t_copy from '../../assret/images/t_copy.png'
import { getIsPermisson, messageCustom } from '../../utils/permisstionUtil';

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Search } = Input

const MarketingIndex = ()=>{

    const history = useHistory()
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [paramsObj, setParamsObj] = useState<any>({
        page: 1,
        pageSize: 10,
    })
    const statusList = [ '已结束', '未开始', '进行中' ]

    useEffect(() => {
        getMarketingList({...paramsObj})
    }, [])

    const getMarketingList = async (data:any = {}) => {
        let param = {
            ...data
        }
        if(param.sortType)param.sortType=param.sortType=='ascend'?'asc':'desc'
        Object.keys(param).map(el => {
            if(!param[el]) delete param[el]
        })
        setParamsObj({...param})
        const res:any = await getRequest(apiUrl.getGroupOperationList, param)
        if(res && res['code'] === 1) {
            setData(res.data.data);
            setTotal(res.data.total)
        }
    }

    const handleTableChange = (pagination:any, filters:any, sorter:any) => {
        getMarketingList({
            ...paramsObj,
            page:pagination.current,
            pageSize:pagination.pageSize,
            sortField:sorter.field,
            sortType:sorter.order
        })
    }

    const endActivity = async(id:number) => {
        const res:any = await getRequest(apiUrl.saveStopOperation,{id:id})
        if(res.code == 1){
            message.success('结束运营活动成功')
            getMarketingList({...paramsObj})
        }else{
            message.error(res.message||'结束运营活动失败')
        }
    }

    const creatTable = ()=>{

        const columns:any = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                fixed:'left',
                width:100,
            },
            {
                title: '活动名称',
                dataIndex: 'name',
                key: 'name',
                fixed:'left',
                width:150,
            },
            {
                title: '活动时间',
                dataIndex: 'activity_time',
                key: 'activity_time',
                sorter: true,
                width: 200,
                render:( text:string, record:any, index:number )=> <>{record.activity_start_time}至{record.activity_end_time}</>
            },
            {
                title: '优惠券',
                dataIndex: 'coupon_num',
                key: 'coupon_num',
            },
            {
                title: '分组人数',
                dataIndex: 'group_member_num',
                key: 'group_member_num',
                sorter:true,
            },
            {
                title: '已发送',
                dataIndex: 'sent_fan_num',
                key: 'sent_fan_num',
                sorter:true,
            },
            {
                title: '已送达',
                dataIndex: 'push_successd_fan_num',
                key: 'push_successd_fan_num',
                sorter:true,
            },
            {
                title: '已查看',
                dataIndex: 'look_coupon_fan_num',
                key: 'look_coupon_fan_num',
                sorter:true,
            },
            {
                title: '已使用',
                dataIndex: 'use_coupon_fan_num',
                key: 'use_coupon_fan_num',
                sorter:true,
            },
            {
                title: '发放者',
                dataIndex: 'nickname',
                key: 'nickname',
            },
            {
                title: '创建时间',
                dataIndex: 'created_at',
                key: 'created_at',
                sorter:true,
                width:150
            },
            {
                title: '活动人数是否去重',
                dataIndex: 'is_filter',
                key: 'is_filter',
                width:200,
                render:( text:string, record:any, index:number )=>text?'是':'否'
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                render: ( text:number, record:any, index:number)=> statusList[text-1]
            },
            {
                title: '操作',
                dataIndex: 'option',
                key: 'option',
                fixed:'right',
                width:200,
                render: ( text:string, record:any, index:number )=>
                    <div>

                        <Tooltip placement="bottomRight" title={record.status == 1?  '复制活动' : '编辑'}>
                            <img src={record.status==1 ? t_copy : t_edit} className='table_icon' onClick={()=>{
                                 if(!getIsPermisson('1_6_6_2')){
                                    messageCustom();
                                    return;
                                  }
                                history.push('/sidebar/marketingAdd',{id:record.id,status:record.status,is_disabled:record.status == 1?false:true})}
                            
                            }></img>
                        </Tooltip>

                        <Tooltip placement="bottomRight" title={'数据'}>
                            <img src={t_data} className='table_icon' onClick={()=>{
                                if(!getIsPermisson('1_6_6_3')){
                                    messageCustom();
                                    return;
                                  }
                                history.push('/sidebar/marketingDetail',{id:record.id,name:record.name})}
                                }></img>
                        </Tooltip>

                        {
                            record.status != 1
                            ? <Tooltip placement="bottomRight" title={'结束活动'}>
                                    <img src={t_end} className='table_icon' onClick={()=>{endActivity(record.id)}}></img>
                                </Tooltip>
                            : null
                        }
                    </div>
            },
        ];

        return (
            <Table
                dataSource={data}
                columns={columns}
                onChange={handleTableChange}
                rowKey={'id'}
                scroll={{ x: 1900 }}
                pagination={{
                    defaultCurrent: 1,
                    current:paramsObj.page,
                    pageSize: paramsObj.pageSize,
                    total: total,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    showTotal: (total: number) => `共${total}条`,
                }}
            />
        )
    }

    return(
        <div className="marketing_index">
            <div className='new_btn mb30' onClick={()=>{
                if(!getIsPermisson('1_6_6_1')){
                    messageCustom();
                    return;
                  }
                history.push('/sidebar/marketingAdd')}
                }>
                <img src={btn_create}></img>
                创建活动
            </div>

            <div style={{background: '#fff', padding: '20px'}}>
                <Row type="flex" align="middle" className="mb30">
                    <span className="mr10">活动名称</span>
                    <Search
                        placeholder="请输入活动名称"
                        onSearch={value => {
                            getMarketingList({...paramsObj,page:1,name:value})
                        }}
                        style={{width:200}}
                    />
                    <span className="ml10 mr10">状态</span>
                    <Select allowClear placeholder="请选择状态" className="mr10" style={{width:120}} onChange={(e:any)=>{getMarketingList({...paramsObj,page:1,status:e})}}>
                        {
                            statusList.map((item,index)=>{
                                return <Option value={index+1} key={index}>{item}</Option>
                            })
                        }
                    </Select>
                    <span className="mr10">创建时间</span>
                    <RangePicker
                        className="mr10"
                        format={`YYYY-MM-DD`}
                        showTime={{ format: 'YYYY-MM-DD' }}
                        onChange={(e:any, dateString) => {
                            getMarketingList({...paramsObj,page:1,start_time:dateString[0],end_time:dateString[1]})
                        }}
                        placeholder={['开始时间', '结束时间']} />
                </Row>
                {
                    creatTable()
                }
            </div>
        </div>
    )
}

export default MarketingIndex