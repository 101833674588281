import React, { useEffect, useState } from 'react'
import { Modal, message } from 'antd'
import { getRequest } from '../../../../api/http'
import { apiUrl } from '../../../../api/api_url'
import QRCode  from 'qrcode.react';

type Iprops = {
    visible:boolean,
    onClose():void,
    onOpen():void,
    codeInfo:any,
}

const RechargeCodeModal = (props:Iprops)=>{

    const { visible, onClose, onOpen, codeInfo } = props
    const channelList = [ '微信', '支付宝' ]
    
    let inter:any = ''
    useEffect(()=>{
        _init()
        return ()=>{
            componentWillUnmount()
        }
    },[visible])

    const _init = () => {
        inter = setInterval(() => {
            getStatus()
        }, 1000)
    }
    
    //销毁组件清除定时器
    const componentWillUnmount = ()=>{
        if (inter) {
            clearInterval(inter)
        }
    };

    const getStatus = async () => {
        let res:any = await getRequest(apiUrl.getSmsRechargeOrder, {order_sn:codeInfo.order_sn })
        .then((res: any) => {
            if(res.code == 1){
                if(res.data.status == 2){
                    onOpen()
                    // onClose()
                    message.success('支付成功')
                    inter && clearInterval(inter)
                }else if(res.data.status == 3 || res.data.status == 0){
                    message.error('支付失败, 请选择套餐重新扫码')
                    inter && clearInterval(inter)
                }
            }
        })
        .catch((err) => {
            message.success('支付出错，请选择套餐重新扫码');
        })
    }

    return(
        <Modal
            title='短信充值'
            visible={visible}
            footer={null}
            width={455}
            className="recharge_code_modal"
            onCancel={onClose}
        >
            <div style={{height: '182px', display:'flex', alignItems: 'center',justifyContent: 'center'}}>
                {codeInfo.code_url ? <QRCode
                                value={codeInfo.code_url}  //value参数为生成二维码的链接
                                size={182} //二维码的宽高尺寸
                                fgColor="#000000"  //二维码的颜色
                            /> : '此处生成充值二维码'}
            </div>            
            <div className="mt30 mb20">金额 ：{codeInfo.amount}元</div>
            <div>请使用{channelList[codeInfo.channel-1]}扫码支付</div>
        </Modal> 
    )
}

export default RechargeCodeModal