import React from 'react';
import { Row, Col } from 'antd';
import '../management.scss';

import winAddInter1 from '../../../../../../assret/images/winAddInter1.png';
import winAddInter2 from '../../../../../../assret/images/winAddInter2.png';
import winAddInter3 from '../../../../../../assret/images/winAddInter3.png';

interface HelpMbaInterests {
    history: any,
}

class HelpMbaInterests extends React.Component<HelpMbaInterests> {

    render() {
        return  <Row className="typeDetail">
                    <Col className="typeDetail-title">一、功能介绍</Col>
                    <Col className="typeDetail-col">所有需要投放的权益卡，必须要在权益卡管理列表中添加权益卡，主要为了满足商家快速回笼资金，拉新获客等多元化经营需要，用户购买后可以使用权益券里面所有优惠，使用的前提是满足优惠里面的条件。</Col>
                    <Col className="typeDetail-title">二、功能路径</Col>
                    <Col className="typeDetail-col">电脑端登录后台-营销管理-权益卡列表-添加权益卡。</Col>
                    <Col className="typeDetail-col">
                        <img src={winAddInter1} />
                    </Col>
                    <Col className="typeDetail-title">三、操作流程</Col>
                    <Col className="typeDetail-col">1、<span className="txt-bold">添加权益卡</span>，在“权益卡列表”界面下点击“添加”</Col>
                    <Col className="typeDetail-col">
                        <img src={winAddInter2} />
                    </Col>
                    <Col className="typeDetail-col">
                        <img src={winAddInter3} />
                    </Col>
                    <Col className="typeDetail-col">1、活动名称：填写名称</Col>
                    <Col className="typeDetail-col">2、有效期：可以填写固定日期或领取后使用日期</Col>
                    <Col className="typeDetail-col">3、活动礼品：选择相关优惠券或菜品券，当没有优惠券或菜品券时，去创建优惠券选择礼包渠道</Col>
                    <Col className="typeDetail-col">4、售价：填写售价，售价就是用户购券权益卡的价格</Col>
                    <Col className="typeDetail-col">5、发行数量：填写发行数量,发行量就是共购买权益卡的总数，例如填写1时，有一个用户购买之后，权益卡已售完，自动下架</Col>
                    <Col className="typeDetail-col">6、参与对象：当选择新用户，则还未注册的用户或已经注册还没有下单的用户，可在相关渠道看到该权益卡，当选择老顾客，则交易次数>=1次的交易次数用户，可在相关渠道看到该权益卡，当全部选择时，则所有用户，可在相关渠道看到该权益卡</Col>
                    <Col className="typeDetail-col">7、使用须知：填写使用须知，在用户购买权益卡后，用户点击使用规则显示</Col>
                    <Col className="typeDetail-col">8、状态：启用和停用两种状态，默认选择启用，当选择停用，则用户不可领取该优惠券</Col>
                    <Col className="typeDetail-col">9、保存：当状态为开启时，点击保存，发布成功，显示在点餐小程首页</Col>
                </Row>
    }
}

export default HelpMbaInterests;