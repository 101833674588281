import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'antd'
import { useHistory } from 'react-router-dom'

type Iprops = {
    visible:boolean,
    onClose():void,
}

const RechargeSuccessModal = (props:Iprops)=>{
    const { visible, onClose } = props
    const history = useHistory()

    useEffect(()=>{
        setTimeout(()=>{
            onClose()
        },3000)
    },[visible])

    return(
        <Modal
            title='短信充值'
            visible={visible}
            // onOk={handleOk}
            // onCancel={onClose}
            footer={null}
            width={455}
            className="recharge_success_modal"
            closable={false}
        >
            <img src={require('../../../../assret/images/success.png')} />
            <div className="mb20">支付成功</div>
            <div className="tip">您的短信充值成功，请前往短信设置！</div>
            <div className="tip">3S后，自动跳转页面</div>
        </Modal> 
    )
}

export default RechargeSuccessModal