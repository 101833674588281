import React from 'react';
import { Row, Col, Button, Input, Select, DatePicker, Table, Modal, Upload, Icon, Radio, Spin, message, Avatar } from 'antd';
import { postRequest, getRequest } from '../../api/http';
import { apiUrl } from '../../api/api_url';
import PieChart from './components/pieChart'
import LineChart from './components/lineChart'
import './userGroupDetail.scss'
import moment from 'moment';

const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { MonthPicker } = DatePicker;

const QINIU_SERVER = "https://up-z2.qiniup.com";  // 七牛上传地址

class UserGroupDetail extends React.Component<any>{
    columns = [
        {
            title: '用户ID',
            dataIndex: 'fans_id',
            key: 'fans_id'
        },
        {
            title: '用户昵称',
            dataIndex: 'nickname',
            key: 'nickname',
            render: (text: string,record:any,index:number) =>
            <Row className="" justify="start" align="middle">
                <div><Avatar src={record.headimgurl} /></div>
                <div>{record.nickname}</div>
            </Row>
        },
        {
            title: '手机号',
            dataIndex: 'phone_number',
            key: 'phone_number'
        },
        {
            title: '性别',
            dataIndex: 'sex',
            key: 'sex',
            render: (text: string, record: any, index: number) => {
                return <div>{record.sex==1? '男': record.sex ==2?'女':'未知'}</div>
            }
        },
        {
            title: '消费次数',
            dataIndex: 'consumer_num',
            key: 'consumer_num',
            sorter: true,
        },
        {
            title: '累计消费(元)',
            dataIndex: 'consumer_money',
            key: 'consumer_money',
            sorter: true,
        },
        {
            title: '充值(次)',
            dataIndex: 'recharge_num',
            key: 'recharge_num',
            sorter: true,
        },
        {
            title: '累计充值(元)',
            dataIndex: 'recharge_money',
            key: 'recharge_money',
            sorter: true,
        },
        {
            title: '剩余金额(元)',
            dataIndex: 'remain_money',
            key: 'remain_money',
            sorter: true,
        },
        {
            title: '成功邀请(人)',
            dataIndex: 'invite_num',
            key: 'invite_num',
            sorter: true,
        },
        {
            title: '邀请奖励(元)',
            dataIndex: 'invite_money',
            key: 'invite_money',
            sorter: true,
        },
        {
            title: '最近消费时间',
            dataIndex: 'last_comsumer_time',
            key: 'last_comsumer_time'
        },
        {
            title: '距离上次消费(天)',
            dataIndex: 'last_comsumer_day',
            key: 'last_comsumer_day'
        },
    ]

    unitList = ['人','元','元','人']

    formatData = (num:any) => {
        return num > 9 ? num : `0${num}`
    }
    //获取日期开始日
    getMonthStart = (year:number,month:number) => {
        // 获取当前日期
        return year + '-' + month + '-01'
    }

    //获取日期结束日
    getMonthEnd = (year:number,month:number) => {
        // 获取当前日期
        return year + '-' + month + '-' + new Date(year,this.formatData(month),0).getDate()
    }

    state:any = {
        loading: false,
        nickname: '',
        sex: -1,
        online_time: '',
        page: 1,
        pageSize: 10,
        member_list: [],
        member_count: 0,
        group_name: '',
        order_field: '',
        order_by: '',
        dayData:{},
        lineChartBottom:{
            left:'今日',
            right:'昨日'
        },
        start_at: this.getMonthStart(new Date().getFullYear(),this.formatData(new Date().getMonth()+1)),
        end_at: this.getMonthEnd(new Date().getFullYear(),this.formatData(new Date().getMonth()+1)),
        group_id: 0,
        currentTab:0,
        groupList:[],
        statistics:[
            {title: "社群消费总人数", num: '--', items:{num:'--',title:'社群总人数'},icon:''},
            {title: "总实收金额", num: "--", items:{num:'--',title:'总订单数'},icon:''},
            {title: "总充值金额", num: '--', items:{num:'--',title:'充值次数'},icon:''},
            {title: "成功邀请人数", num: '--', items:{num:'--',title:''},icon:''} ,
        ],
        income:0,//总金额
        pieData:{},
        lineTab:0,
        lineData:{
            groups: [],
            xLine: [],
            yLine: []
        },
        lineLoading:false,
        date: this.getMonthStart(new Date().getFullYear(),this.formatData(new Date().getMonth()+1)),
    }

    componentDidMount(){
        const {location} = this.props;
        if(location.state){
            this.setState({
                group_name: location.state.name,
                group_id: location.state.id
            },()=>{
                this.getGroups()
                this.getStatistics()
                this.getLineChart()
                this._initData()
            })
        }
    }

    _initData = async () => {
        let { nickname, sex, page, pageSize, order_field, order_by, group_id } = this.state;
        let param:any = {
            current: page,
            pageSize,
            id: group_id
        };
        if(nickname){
            param.nickname = nickname;
        }
        if(sex != -1){
            param.sex = sex;
        }
        if(order_field){
            param.order_field = order_field;
        }

        if(order_by){
            param.order_by = order_by;
        }
        if(this.props.location.state&&this.props.location.state.type){
            param.type = this.props.location.state.type;
        }
        const res: any = await postRequest(apiUrl.usergroupDetail, param)
        if(res.code === 1){
            this.setState({
                loading: false,
                member_list: res.data.member_list,
                member_count: res.data.member_count,
                group_info: res.data.group_info,
                last_comsumer_time_start: res.data.statistical_time_start,
                last_comsumer_time_end: res.data.statistical_time_end,
                online_time: res.data.online_time
            })
        }
    }

    getGroups = async()=> {
        const res:any = await getRequest(apiUrl.siyuGetGroups)
        if(res.code === 1){
            this.setState({
                groupList: res.data
            })
        }else{
            message.error(res.message||'获取分组失败')
        }
    }

    getStatistics = async() => {
        const { start_at, end_at, group_id } = this.state
        let params:any = {
            start_at,
            end_at,
            group_id
        }
        const res:any = await getRequest(apiUrl.siyuIndex,params)
        if(res.code === 1){
            this.setState({
                statistics:res.data.list,
                income: res.data.rate.income
            })
        }
        const pRes:any = await getRequest(apiUrl.siyuGroupRate,params)
        if(pRes.code === 1){
            this.setState({
                pieData:pRes.data
            })
        }
    }

    getLineChart = async() => {

        const { date, group_id } = this.state

        let params:any = {
            date,
            group_id
        }
        this.setState({
            lineLoading:true
        })
        const res:any = await getRequest(apiUrl.siyuGetlineChart,params)
        if(res.code === 1){
            this.setState({
                lineData:res.data,
                lineLoading:false
            })
        }else{
            this.setState({
                lineLoading:false
            })
            message.error(res.message||'折线图数据获取失败')
        }
    }

    nameChange = (e:any) => {
        this.setState({
            nickname: e
        }, () => {
            this._initData()
        })
    }

    sexChange = (e: any) => {
        this.setState({
            sex: e
        }, () => {
            this._initData()
        })
    }

    tableChange = (page: any, filters:any, sort: any) => {
        if(sort.order){
            this.setState({
                order_field: sort.field,
                order_by: sort.order == 'ascend' ? 'ASC' : 'DESC',
                page: page.current
            }, () => {
                this._initData()
            })
        }else{
            this.setState({
                order_field: '',
                order_by: '',
                page: page.current
            }, () => {
                this._initData()
            })
        }
    }

    changeMonthPicker = (dateString:any) => {
        if(dateString){
            this.setState({
                date:this.getMonthStart(dateString.split('-')[0],dateString.split('-')[1]),
            },()=>this.getLineChart())
        }else{
            this.setState({
                date:this.getMonthStart(new Date().getFullYear(),this.formatData(new Date().getMonth()+1)),
            },()=>this.getLineChart())
        }
    }

    changeRangePicker = (dateString:any) => {
        if(dateString[0]){
            this.setState({
                start_at: dateString[0],
                end_at: dateString[1],
            },()=>this.getStatistics())
        }else{
            this.setState({
                start_at: this.getMonthStart(new Date().getFullYear(),this.formatData(new Date().getMonth()+1)),
                end_at: this.getMonthEnd(new Date().getFullYear(),this.formatData(new Date().getMonth()+1)),
                currentTab:0,
            },()=>this.getStatistics())
        }
    }

    changeTab = (index:any) => {
        if(index === this.state.currentTab) return false
        let start:any;
        let end:any;

        let beforeMonthStart = new Date().getFullYear() + '-' + this.formatData(new Date().getMonth()) + '-01';
        let currentMonth = new Date().getFullYear() + '-' + this.formatData(new Date().getMonth() + 1) + '-01'
        let num = new Date(new Date(currentMonth).getTime() - 24 * 60 * 60 * 1000);
        let beforeMonthEnd = `${num.getFullYear()}-${this.formatData(num.getMonth() + 1)}-${this.formatData(num.getDate())}`;
        beforeMonthStart = `${num.getFullYear()}-${this.formatData(num.getMonth() + 1)}-01`

        if(index === 0){
            start = this.getMonthStart(new Date().getFullYear(),this.formatData(new Date().getMonth()+1))
            end = this.getMonthEnd(new Date().getFullYear(),this.formatData(new Date().getMonth()+1))
        }else{
            start = beforeMonthStart
            end = beforeMonthEnd
        }

        this.setState({
            currentTab:index,
            start_at: start,
            end_at: end,
        },()=>{
            this.getStatistics()
        })
    }

    //选择社群
    onChangeSelect = (e:any,option:any) => {
        this.setState({
            group_id:e,
            group_name: option.props.children,
        },()=>{
            // this.getGroups()
            this.getStatistics()
            this.getLineChart()
            this._initData()
        })
    }
    changeLineTab = (index:number) => {
        if(index === this.state.lineTab) return false
        this.setState({
            lineTab:index,
        })
    }

    render(){
        const { group_name, member_list, member_count, pageSize, page, dayData, lineChartBottom, currentTab, groupList, group_id, start_at, end_at, statistics,lineTab, date, lineData, lineLoading, pieData, income } = this.state;

        return<div className='user_detail'>
            <div className="siyu_title">{group_name}社群详情</div>
            <Row className="search" type="flex" align="middle">
                <Row type="flex" align="middle" className="search_tab">
                    <div className={`search_tab_item ${currentTab===0?'active':''}`} onClick={()=>{this.changeTab(0)}}>本月</div>
                    <div className={`search_tab_item ${currentTab===1?'active':''}`} onClick={()=>{this.changeTab(1)}}>上月</div>
                </Row>
                <Select style={{width:290}} value={group_id} className="mr20" onChange={(e:any,option:any)=>{this.onChangeSelect(e,option)}}>
                    {
                        groupList.map((item:any,index:number)=>{
                            return <Option value={item.id} key={index}>{item.name}</Option>
                        })
                    }
                </Select>
                <RangePicker
                    style={{width:290}}
                    onChange={(dates,dateString)=>{this.changeRangePicker(dateString)}}
                    value={[moment(start_at, 'YYYY-MM-DD'),moment(end_at, 'YYYY-MM-DD')]}
                />
            </Row>
            <div className="user_detail_dashboard_wrap row">
                <div className="card row-between">
                    {
                        statistics.map((item:any,index:number)=>{
                            return <div className="card_item wbg" key={index}>
                                <div className="card_item_top row-between">
                                    <div>
                                        <div>{item.title}</div>
                                        <div className="card_item_total">{item.num}{this.unitList[index]}</div>
                                    </div>
                                    <img src={item.icon}/>
                                </div>
                                {
                                    item.items.title?(
                                        <div className="card_item_bottom">{item.items.title}:{item.items.num}</div>
                                    ):(
                                        <div className="card_item_bottom"></div>
                                    )
                                }
                            </div>
                        })
                    }
                </div>
                <div className="user_detail_dashboard wbg">
                    <PieChart data={pieData} income={income}/>
                </div>
            </div>
            <div className="user_detail_line_chart wbg">
                <Row type="flex" align="middle" className="search_tab" justify="space-between">
                    <Row type="flex" align="middle" >
                        <div className={`search_tab_item ${lineTab===0?'active':''}`} onClick={()=>{this.changeLineTab(0)}}>月实收金额</div>
                        <div className={`search_tab_item ${lineTab===1?'active':''}`} onClick={()=>{this.changeLineTab(1)}}>月订单</div>
                    </Row>

                    <MonthPicker
                        className="mr10"
                        // picker="month"
                        value={moment(date, 'YYYY-MM')}
                        onChange={(dates,dateString)=>{this.changeMonthPicker(dateString)}}
                    />
                </Row>
                <LineChart lineData={lineData} lineTab={lineTab}/>
            </div>
            <div className="user_list wbg">
                <Row type="flex" align="middle" justify="start" className="search">
                    <Col className='mr30'>
                        用户昵称：<Search placeholder='请输入用户昵称' style={{ width: 234 }} onSearch={this.nameChange} />
                    </Col>
                    <Col className='mr30'>
                        性别：<Select style={{width: 150}} placeholder='全部' onChange={this.sexChange}>
                            <Option key='-1'>全部</Option>
                            <Option key='1'>男</Option>
                            <Option key='2'>女</Option>
                            <Option key='0'>未知</Option>
                        </Select>
                    </Col>
                </Row>
                {
                    <Spin tip="正在统计..." spinning={this.state.loading}>
                        <Table
                            dataSource={member_list}
                            columns={this.columns}
                            rowKey={"id"}
                            onChange={(page, filters, sort) => this.tableChange(page, filters, sort)}
                            pagination={{
                                total: member_count,
                                current: page,
                                pageSize,
                                showQuickJumper: true,
                                showSizeChanger: true,
                                showTotal: (total: number) => `共${total}条`,
                                onShowSizeChange: (current:number, size: number) => {this.setState({pageSize: size, page: 1}, () => {this._initData()})}
                            }}
                        />
                    </Spin>
                }
            </div>
        </div>
    }
}

export default UserGroupDetail;