import React from "react";
import Sortablejs from 'sortablejs';
import {Button, Row, Col, Input, DatePicker, Table, Modal, Select, message, Icon, Popconfirm} from "antd";
import { postRequest, getRequest } from '../../../api/http';
import { apiUrl } from '../../../api/api_url';
import arrayMove from 'array-move';
import './shopSort.scss';

class ShopSortLeft extends React.Component<any,any> {

    constructor(props: any){
        super(props);
        this.state = {
            sortList: [],
            copy_sortList: [],
            index: 0,
        }
    }


    componentDidMount() {
        this.onMenuList()
        this.onSort()
    }

    //拖动排序
    onSort() {
        let el:any = document.getElementById('shopLeft');
        let sort = Sortablejs.create(el, {
            handle: "div",//设置div支持拖拽
            onStart: (evt: any) => {
                console.log('onStart:',evt)
            },
            onEnd: (evt: any) => {
                console.log('onEnd:',evt)
                this.onSortEnd(evt.oldIndex, evt.newIndex)
            }
        })
    }

    //菜品列表
    async onMenuList() {
        let param = {
            page: 1,
            pageSize: 200
        }
        const res: any = await getRequest(apiUrl.goodSort, param);
        if (res.code === 1) {
            console.log('res.data[0].id:',res.data[0].id)
            this.props.addEvent(res.data[0].id)
            this.props.inputEvent(res.data)
            this.setState({
                sortList: res.data,
            })
        }
    }

    onSortEnd = ( oldIndex: any, newIndex : any) => {
        let dataSource: any = '';
        if(this.state.index == 0) {
            dataSource = this.state.sortList;
        } else {
            dataSource = this.state.copy_sortList;
        }
        if (oldIndex !== newIndex) {
            console.log('Sorted items1: ', dataSource);
            const newData = arrayMove([].concat(dataSource), oldIndex, newIndex).filter(el => !!el);
            console.log('Sorted items2: ', newData);
            this.setState(() => {
                return {
                    copy_sortList: newData,
                    index: 2
                }
            });
            //this.onSortTable(newData)
            this.props.addEvent(newData[0]['id'])
            this.props.inputEvent(newData)
        }
    };


    //排序接口
    onSortTable = async (arrayList: any) => {
        let count = arrayList.length;
        let temp = arrayList.map((item: any, index: any) => {
            let obj: any = {};
            obj.id = item.id;
            obj.order_by = count - index;
            return (
                obj
            )
        })
        console.log(temp)
        const res:any = await getRequest(apiUrl.setCatSort,{arr:JSON.stringify(temp)});
        if(res.code === 1){
            //this.onMenuList()
        }
    }


    render() {

        const { sortList } = this.state;

        return (
            <div className="shopLeft l_content" id="shopLeft">
                {
                    sortList.map((item: any, index: any) => {
                        return (
                            <div className="shopLeft-item" key={item + index} onClick={() => this.props.addEvent(item.id) }>
                                {item.name}<Icon type="drag" style={{marginLeft: '10px'}} />
                            </div>
                        )
                    })
                }


            </div>
        )

    }
}

export default ShopSortLeft