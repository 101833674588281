import React from "react";
import {Button, Input, message, Row, Col, Cascader, TimePicker, Radio} from "antd";
import { postRequest, getRequest } from '../../api/http';
// import QQMap from 'qqmap'
import axios from 'axios'

import { apiUrl } from '../../api/api_url';
import { Map, Marker,  } from 'react-amap';
import Position from './region'

const key = 'updatable';
const amap_key = 'dcadbb30614773f14a5f0d479ed3f305';

class ShopAdd extends React.Component<any> {

    state = {
        loading: false,
        name: '',
        province:'',
        city: '',
        district:'',
        address: '',
        phone: '',
        lunch_time_start: '',
        lunch_time_end: '',
        dinner_time_start: '',
        dinner_time_end: '',
        status: 1,

        position: { longitude: 0, latitude: 0 },
        defaultAddress: ''


    }

    componentDidMount () {
        const {location} = this.props;
        if(location.state !== undefined){
            this.initEdit(location.state.id);
        }
        this._init()
    }

    _init = async () => {
       // 为给定 ID 的 user 创建请求
        axios.get('https://restapi.amap.com/v3/ip?key=' + amap_key)
        .then((response) => {
        console.log(response);
        this.setState({
            defaultAddress: response.data.province + response.data.city
        })
        })
        .catch(function (error) {
        console.log(error);
        });
    }

    // 初始化编辑
    initEdit = async (id:number) => {
        const params = {
            id: id
        };
        const res:any = await getRequest(apiUrl.tableInfo,params);
        if(res.code === 1){
            this.setState({
                name: res.data.name,
                region_name: res.data.region_name,
                people_num: res.data.people_num,
                region_id: res.data.region_id,
                qrcode: res.data.qrcode
            })
        }
    }

    inputChange = (e:any) => {
        let name = e.target.name;
        let value = e.target.value.trim();
        this.setState({
            [name]: value
        })
    }


    handleSubmit = async (e:any) => {
        message.loading({ content: '发布中', key });
        e.preventDefault();

        const {location,} = this.props;
        let {  name, province, city, district, address, phone, lunch_time_start, lunch_time_end, dinner_time_start, dinner_time_end, position, status } = this.state;

        var tableInfo:any = {};

        if(location.state !== undefined){
            tableInfo.id = location.state.id;
        }

        if(province == ''){
            message.warning('请选择省市区')
            return
        }

        if(address == ''){
            message.warning('请输入详细地址')
            return
        }

        if(name == ''){
            message.warning('请输入门店名称')
            return
        }

        if(phone == ''){
            message.warning('请输入联系电话')
            return
        }

        if(position.longitude == 0){
            message.warning('请选择准确位置')
            return
        }

        tableInfo.name = name;
        tableInfo.province = province;
        tableInfo.city = city;
        tableInfo.district = district;
        tableInfo.address = address;
        tableInfo.phone = phone;
        tableInfo.lunch_time_start = lunch_time_start;
        tableInfo.lunch_time_end = lunch_time_end;
        tableInfo.dinner_time_start = dinner_time_start;
        tableInfo.dinner_time_end = dinner_time_end;
        tableInfo.status = status;
        tableInfo.longitude = position.longitude;
        tableInfo.latitude = position.latitude;

        if(location.state !== undefined){
            const res:any = await postRequest(apiUrl.tableEdit,tableInfo);
            if(res['code'] === 1){
                message.success({ content: '修改成功!', key, duration: 2 });
                this.props.history.goBack();
            }else{
                message.error({ content: '修改失败!', key, duration: 2 });
            }
        }else{
            const res:any = await postRequest(apiUrl.shopCreate,tableInfo);
            if(res['code'] === 1){
                message.success({ content: '发布成功!', key, duration: 2 });
                this.props.history.goBack()
            }else{
                message.error({ content: '发布失败!', key, duration: 2 });
            }
        }
    };

    // 返回上一页
    goBack = () => {
        this.props.history.goBack();
    }

    // 地区选择
    onChange = (e:any) => {
        this.setState({
            province:e[0],
            city: e[1],
            district:e[2]
        })
    }

    timeChange = (time: any, timeString: string, str: string) => {
        this.setState({
            [str]: timeString
        }, ()=>{
            console.log(this.state.lunch_time_start);
        })
    }


    mapEvents = {
        created: (mapInstance:any) => {
          console.log(mapInstance);
        },
        click: (e:any) => {
          let position = { longitude: e.lnglat.lng, latitude: e.lnglat.lat }
          let location = e.lnglat.lng+','+e.lnglat.lat
          this.getAddress(location)
          this.setState({
            position
          })
        }
    }

    markerEvents = {
        click: (markerInstance:any) => {
            let position = { longitude: markerInstance.lnglat.lng, latitude: markerInstance.lnglat.lat }
            this.setState({
                position
            })
        },
    };

    getAddress = async (location: any) => {
        axios.get(`https://restapi.amap.com/v3/geocode/regeo?key=${amap_key}&location=${location}`)
        .then((response: any) => {
            this.setState({
                defaultAddress: response.data.regeocode.formatted_address
            })
        })
        .catch(function (error) {
        console.log(error);
        });
    }

    foramtAddress = () => {
        axios.get(`https://restapi.amap.com/v3/geocode/geo?key=${amap_key}&address=${this.state.defaultAddress}`)
        .then((response: any) => {
            console.log(response,);
            let num = response.data.geocodes[0].location
            this.setState({
                position: { longitude: num.split(',')[0], latitude: num.split(',')[1] }
            })
        })
        .catch(function (error) {
        console.log(error);
        });
    }

    render(){
        const { name, phone, address, status, defaultAddress} = this.state;


        return <div className='l_content'>
                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">地区</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <Cascader options={Position} onChange={this.onChange} />
                    </Col>
                </Row>
                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">详细地址</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <Input placeholder="请输入详细地址" value={address} name='address' onChange={(e) => this.inputChange(e)}/>
                    </Col>
                </Row>

                <Row className="mb30" type="flex" align="top">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">位置标志</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <Input id="tipinput" value={defaultAddress} onChange={(e) => {this.setState({
                                defaultAddress: e.target.value
                            })}} />
                    </Col>
                    <Col>
                        <Button onClick={() => this.foramtAddress()}>搜索</Button>
                    </Col>
                </Row>

                <Row className="mb30" type="flex" align="top">
                    <Col span={6} offset={2}>注意：这只是模糊定位，准确定位请在地图上移动蓝点标注！</Col>
                </Row>
                <Row>
                    <Col offset={2}>
                        <div style={{width: 600, height: 400}}>
                            <Map amapkey={amap_key} zoom={10} events={this.mapEvents} >
                                <Marker draggable events={this.markerEvents} position={this.state.position} />
                            </Map>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col offset={2} style={{marginTop: '10px', marginBottom: '10px'}}>
                        经度：{this.state.position.longitude} 纬度：{this.state.position.latitude}
                    </Col>
                </Row>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">门店名称</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <Input placeholder="请输入门店名称" maxLength={10} name='name' value={name} onChange={(e) => this.inputChange(e)}/>
                    </Col>
                </Row>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">联系电话</span>
                        </div>
                    </Col>
                    <Col span={6} className='mr30'>
                        <Input placeholder="请输入联系电话" value={phone} name='phone' onChange={(e) => this.inputChange(e)} />
                    </Col>
                    <Col>固定电话需加区号；区号、分机号均用“-”连接</Col>
                </Row>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="color_black">营业时间</span>
                        </div>
                    </Col>
                    {/* <Col span={6} className='mr30'>
                        <Button>增加</Button>
                    </Col> */}

                    <Col>
                        <TimePicker className='mr30' placeholder='上班时间' onChange={(time, timeString) => this.timeChange(time,timeString, 'lunch_time_start')}/>
                        <TimePicker className='mr30' placeholder='下班时间' onChange={(time, timeString) => this.timeChange(time,timeString, 'lunch_time_end')}/>
                        {/* <Button>删除</Button> */}
                    </Col>
                </Row>

                {/* <Row>

                </Row> */}
                 <Row className="mb30" type="flex" align="middle">
                    <Col offset={2}>
                        <TimePicker className='mr30' placeholder='上班时间' onChange={(time, timeString) => this.timeChange(time,timeString, 'dinner_time_start')}/>
                        <TimePicker className='mr30' placeholder='下班时间' onChange={(time, timeString) => this.timeChange(time,timeString, 'dinner_time_end')}/>
                        {/* <Button>删除</Button> */}
                    </Col>
                </Row>

                <Row className="mb30" type="flex" align="middle">
                    <Col span={2}>
                        <div>
                            <span className="star"></span>
                            <span className="color_black">状态</span>
                        </div>
                    </Col>
                    <Col span={22}>
                        <Radio.Group
                            name='status'
                            value={status}
                            onChange={(e) => this.inputChange(e)}
                        >
                            <Radio value={1}>开启</Radio>
                            <Radio value={0}>暂停</Radio>
                        </Radio.Group>
                    </Col>
                </Row>

                <Row>
                    <Col span={12}>
                        <div className="bottom_wrap">
                            <Button type="primary" ghost onClick={this.goBack}>
                                取消
                            </Button>
                            <Button type="primary" onClick={this.handleSubmit}>
                                保存
                            </Button>
                        </div>
                    </Col>
                </Row>

        </div>;
    }

}
export default ShopAdd