import React from 'react';

import './systemRole.scss';
import { Button, Row, Col ,Input, Divider, Table, Tag, message} from 'antd';
import { Link } from 'react-router-dom';
import { getRequest } from '../../../api/http';
import { apiUrl } from '../../../api/api_url';
import { getIsPermisson, messageCustom } from '../../../utils/permisstionUtil';

type IProps = {
    history: any
}

type IState = {
    roleList: any,
    loading: boolean,
    total: number
}
const { Search } = Input;
class SystemRole extends React.Component<IProps,IState> {

    constructor(props:IProps) {
        super(props);
        this.state = {
            roleList: [],
            loading: true,
            total: 0,
        };
    }
    roleName = '';
    current = 1;
    pageSize =  10;
    componentDidMount(){
        this.requestGetRoleList();
    }

    // 获取角色列表
    requestGetRoleList = async () => {

        const res:any = await getRequest(apiUrl.roleList,{
            name: this.roleName,
            current: this.current,
            pageSize: this.pageSize
        });
        if(res.code === 1){
            this.setState({
                roleList: res.data.list,
                total: res.data.count,
                loading: false,
            })
          }else{
              message.error('服务器异常'+res.message);
          }
    }


    // 删除角色
    requestDelRole = async (roleId: any) =>{
        const res:any = await getRequest(apiUrl.roleDel,{role_id: roleId});
        if(res.code === 1){
            this.requestGetRoleList();
            message.success('删除角色成功');

        }else{
          message.error('服务器异常'+res.message);
        }
      }

    // 查看角色相关
    catRoleInfo = (item: any) =>{
        if(!getIsPermisson('2_5_2_2')){
            messageCustom();
            return;
          }

    }


    creatTable = (roleList:any)=>{
        let {loading,total} = this.state;
        const columns = [
            {
              title: '角色名称',
              dataIndex: 'display_name',
              key: 'display_name',
            },
            {
              title: '人数',
              dataIndex: 'number',
              key: 'description',
              render: (text: any) => <div>{12}</div>
            },
            
            {
              title: '操作',
              key: 'action',
              render: (text:any, record:any) => (
                record.is_super_admin ? <span>不可操作</span>
                :
                <span>
                  <a onClick={() =>{
                    if(!getIsPermisson('2_5_2_1')){
                        messageCustom();
                        return;
                      }
                      this.props.history.push({pathname: '/sidebar/addRole',state: {role: record}})
                  }}>
                    编辑
                  </a>
                  <Divider type="vertical" />
                  {/* <a onClick={() => this.requestDelRole(record.role_id)}>删除</a> */}
                  <a onClick={() => this.catRoleInfo(record)}>查看</a>
                </span>
              ),
            },
          ];
       return <Table
            columns={columns}
            loading={loading}
            dataSource={roleList}
            rowKey= "role_id"
            expandedRowRender={record => (
                <div className="permission">
                    {
                        [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19].map((item,index) =>{
                            return (
                                <div className="permission_item" key={item+index}>
                                    <Tag color="#2db7f5" key={item+index}>实时管理</Tag>
                                </div>
                            );
                        })
                    }
                </div>
            )}

            pagination={{
              pageSize: this.pageSize,
              total: total,
              onChange: (e: number) => {
                  this.current = e;
                  this.setState({loading: true});
                  this.requestGetRoleList();
              }
            }}
       />;
    }

    render() {

        let {roleList} = this.state;
        return (
            <div className="role_page l_content">
                <Row>
                    {/* <Col span={4}>
                        <Link to={'/sidebar/addRole'}>
                            <Button icon="plus" type="primary">添加</Button>
                        </Link>
                    </Col> */}
                    <Col span={6}>
                        <div className="serach_view">
                            <div className="serach_text">角色名称:</div>
                            <Search
                                placeholder="输入角色..."
                                onSearch={value => {
                                    this.roleName = value;
                                    this.current = 1;
                                    this.requestGetRoleList();
                                }}
                                />
                        </div>
                    </Col>
                </Row>
                <div className="table_view">
                    {this.creatTable(roleList)}
                </div>
            </div>
        )
    }
}

export default SystemRole;