import React, { useState, useEffect } from "react"
import { Input, Radio, Row, Table, Button, Checkbox, Popover, Icon, Tooltip, message } from 'antd'
import { SketchPicker, ColorResult } from 'react-color'
import { Observer } from 'mobx-react';
// import { toJS } from 'mobx'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import shopDecorationStore from '../../../../stores/shopDecoration.store'
import UploadImg from './uploadImg/index'
import BraftEditor from 'braft-editor'
import arrayMove from 'array-move';
// 引入编辑器样式
import 'braft-editor/dist/index.css'
import './decorationHomeRight.scss'

interface IProps {
    pathList: Array<any>
}

interface IState {

}

const DecorationHomeLeft = (props:IProps) => {

    const { pathList } = props
    const SortableItem = SortableElement((props: any) => <tr className="sortable_item_tr" {...props} />);
    const SortableContainerTab = SortableContainer((props: any) => <tbody {...props} />);
    const DragHandle = SortableHandle(() => (
        <div className="form_sort">
            <Icon type="caret-up" />
            <Icon type="caret-down" />
        </div>
    ));

    const columns = [
        {
            title: '项名称',
            dataIndex: 'name',
        },
        {
            title: '显示',
            dataIndex: 'show',
            render: (text:any,record:any,index:number)=> text ? <Icon type="check" onClick={()=>{showMenu(index)}}/> : <Icon type="close" onClick={()=>{showMenu(index)}}/>
        },
        {
            title: () => {
                return (
                    <div>
                        <span>排序</span>
                        <Tooltip title={'拖动这一列的图标进行排序'} className="tip">
                            <Icon type="question-circle" theme="filled" />
                        </Tooltip>
                    </div>
                )
            },
            dataIndex: 'sort',
            render: () => <DragHandle />,
        },
        {
            title: '操作',
            dataIndex: 'option',
            render: (text:any,record:any,index:number)=> 
                <Tooltip title="删除">
                    <img src={require('../../../../assret/images/decoration_delete.png')} alt="" className="decoration_delete" onClick={()=> {delMenu(index)}}/>
                </Tooltip>
        },        
    ];
    const DraggableContainer = (props: any) => (
        <SortableContainerTab
            useDragHandle
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
            {...props}
        />
    );

    const onSortEnd = ({ oldIndex, newIndex }: any) => {
        if (oldIndex !== newIndex) {
            let data = JSON.parse(JSON.stringify(shopDecorationStore.shop_decoration_home))
            let dataOther = shopDecorationStore.shop_decoration_home_other
            let dataTemp = data[shopDecorationStore.shop_decoration_home_other.current]
            const newData = arrayMove([].concat(dataTemp.list), oldIndex, newIndex).filter(el => !!el);
            shopDecorationStore.updateShopDecorationHomeObjProps(dataOther.current,'list',newData)
        }
    };

    const DraggableBodyRow = ({ className, style, ...restProps }: any,list:any) => {
        return <SortableItem index={restProps['data-row-key']} {...restProps} />;
    };

    const [ linkShow, setLinkShow ] = useState(false)

    const content = (
        <div>
            {
                pathList.map((item,index)=>{
                    return <div key={index} className="link_text" onClick={()=>{selectLink(item)}}>{item.name}</div>
                })
            }
        </div>
    );

    useEffect(()=>{

    },[])

    const _initHtmls = () => {
        let data = shopDecorationStore.shop_decoration_home[shopDecorationStore.shop_decoration_home_other.current]
        let dataOther = shopDecorationStore.shop_decoration_home_other
        if(shopDecorationStore.shop_decoration_home_other.editMofangCurrent !== -1){
            //编辑导航
            return templateMofangEdit(data,dataOther)
        }else{
            if(shopDecorationStore.shop_decoration_home_other.current !== -1){
                let htmls:any
                //左侧菜单编辑
                switch(data.module){
                    case 'muti':
                        htmls = templateMuti(data,dataOther)
                        break;
                    case 'equityCard':
                    case 'invitation':
                        htmls = templateInvitation(data,dataOther)
                        break;
                    case 'moFang':
                        htmls = templateMofang(data,dataOther)
                        break;
                    case 'richText':
                        htmls = templateRichText(data,dataOther)
                        break;
                    default:
                        //点餐，地址不做操作
                        break
                }
                return htmls
            }
        }
    }

    //删除魔方导航项
    const delMenu = (index:number) => {
        let data = JSON.parse(JSON.stringify(shopDecorationStore.shop_decoration_home))
        let dataOther = shopDecorationStore.shop_decoration_home_other
        let dataTemp = data[shopDecorationStore.shop_decoration_home_other.current]
        if(dataTemp.list.length>1){
            dataTemp.list.splice(index,1)
            shopDecorationStore.updateShopDecorationHomeObjProps(dataOther.current,'list',dataTemp.list)  
        }else{
            data.splice(dataOther.current,1)
            shopDecorationStore.updateShopDecorationHome(data)
            shopDecorationStore.updateShopDecorationHomeOther({
                module:'' , //当前选中模版
                showRight:false , //是否显示右侧编辑
                current: -1, //当前板块
                hoverCurrent: -1, //鼠标悬停模块
                editMofangCurrent: -1 , //当前编辑的魔方导航
                hoverEditMofangCurrent: -1 , //当前悬停的编辑的魔方导航
                editMutiCurrent: 0 , //当前编辑的多图文导航
                hoverMutiCurrent: -1, //当前悬停编辑的多图文导航
            })
        }
    }
    //添加魔方导航
    const addMenu = () => {
        let dataSource = JSON.parse(JSON.stringify(shopDecorationStore.shop_decoration_home))
        let dataOther = shopDecorationStore.shop_decoration_home_other
        let data = dataSource[dataOther.current]
        data.list.push({
                name:'新导航项',
                show:1,
                styleType: 0, //标题样式 0默认，1自定义
                style: {
                    fontSize: 1, //字体 ，1为16号，2为14号， 3为14号
                    color: 'rgba(0,0,0)', //字体颜色
                    fontWeight: false, //字体是否加粗
                },
                imgType: 0, //图标附图 0默认，1自定义
                imgUrl:'https://ydn-siyu.cdn.shuachi.com/card_charge.png' , //图标附图url,
                defaultImgUrl:'https://ydn-siyu.cdn.shuachi.com/card_charge.png' , //图标附图url,
                linkUrl: '',
                linkName: '',
        })
        shopDecorationStore.updateShopDecorationHomeObjProps(dataOther.current,'list',data.list)
        shopDecorationStore.updateShopDecorationHomeOtherObjProps('editMofangCurrent',data.list.length-1)
    }

    //显示魔方导航
    const showMenu = (index:number) => {
        let data = JSON.parse(JSON.stringify(shopDecorationStore.shop_decoration_home))
        let dataOther = shopDecorationStore.shop_decoration_home_other
        let dataTemp = data[shopDecorationStore.shop_decoration_home_other.current]
        dataTemp.list[index].show = dataTemp.list[index].show === 1 ? 0 : 1
        shopDecorationStore.updateShopDecorationHomeObjProps(dataOther.current,'list',dataTemp.list)  
    }

    //选择魔方导航项链接
    const selectLink = (item:any) => {
        setLinkShow(false)
        let data = shopDecorationStore.shop_decoration_home[shopDecorationStore.shop_decoration_home_other.current]
        let dataOther = shopDecorationStore.shop_decoration_home_other
        let dataTemp = JSON.parse(JSON.stringify(data))
        dataTemp.list[dataOther.editMofangCurrent].type = item.type
        dataTemp.list[dataOther.editMofangCurrent].linkUrl = item.path
        dataTemp.list[dataOther.editMofangCurrent].linkName = item.name
        shopDecorationStore.updateShopDecorationHomeObjProps(dataOther.current,'list',dataTemp.list)
    }

    //富文本
    const templateRichText = (data:any={},dataOther:any={}) => {
        let richText = data.richText?BraftEditor.createEditorState(data.richText):null
        return (
            <div className="form_bg">
                <Row type="flex" align="middle" className="form_item">
                    <div className="form_label">模块名称：</div>
                    <Input type="text" maxLength={5} value={data.title} placeholder="最多可输入5个字" className="form_input" onChange={(e)=>{changeField('title',e.currentTarget.value,data,dataOther)}}/>
                    <Radio checked={data.showTitle} onClick={(e)=>{changeField('showTitle',!data.showTitle,data,dataOther)}}/>
                </Row>
                <Row type="flex" align="middle" className="form_item">
                    <div className="form_label">副标题：</div>
                    <Input type="text" maxLength={5} value={data.subTitle} placeholder="最多可输入5个字" className="form_input" onChange={(e)=>{changeField('subTitle',e.currentTarget.value,data,dataOther)}}/>
                    <Radio checked={data.showSubTitle} onClick={(e)=>{changeField('showSubTitle',!data.showSubTitle,data,dataOther)}}/>
                </Row> 
                <div className="form_editor">
                    <BraftEditor
                        key={dataOther.current}
                        defaultValue={richText}
                        // onChange={(e)=>{handleEditorChange(e)}}
                        onChange={(e)=>{changeField('richText',e.toHTML(),data,dataOther)}}
                        excludeControls={['media']}
                    />
                </div>                        
            </div>
        )
    }

    //多图文
    const templateMuti = (data:any={},dataOther:any={}) => {
        return(
            <div className="form_bg">
                <Row type="flex" align="middle" className="form_item">
                    <div className="form_label">模块名称：</div>
                    <Input type="text" maxLength={5} value={data.title} placeholder="最多可输入5个字" className="form_input" onChange={(e)=>{changeField('title',e.currentTarget.value,data,dataOther)}}/>
                    <Radio checked={data.showTitle} onClick={(e)=>{changeField('showTitle',!data.showTitle,data,dataOther)}}/>
                </Row>
                <Row type="flex" align="middle" className="form_item">
                    <div className="form_label">副标题：</div>
                    <Input type="text" maxLength={5} value={data.subTitle} placeholder="最多可输入5个字" className="form_input" onChange={(e)=>{changeField('subTitle',e.currentTarget.value,data,dataOther)}}/>
                    <Radio checked={data.showSubTitle} onClick={(e)=>{changeField('showSubTitle',!data.showSubTitle,data,dataOther)}}/>
                </Row> 
                <div className="form_item">
                    <div className="form_label">模块样式：</div>
                    <Row type="flex" align="middle" justify="space-between" className="form_templateIcon">
                        <div onClick={(e)=>{changeField('type',1,data,dataOther)}}>
                            {
                                data.type === 1 && <div className="form_templateIcon_select" />
                            }
                            <img src={require('../../../../assret/images/decoration_template_icon_1.png')}/>
                        </div>
                        <div onClick={(e)=>{changeField('type',2,data,dataOther)}}>
                            {
                                data.type === 2 && <div className="form_templateIcon_select" />
                            }
                            <img src={require('../../../../assret/images/decoration_template_icon_2.png')}/>
                        </div>
                        <div onClick={(e)=>{changeField('type',3,data,dataOther)}}>
                            {
                                data.type === 3 && <div className="form_templateIcon_select" />
                            }
                            <img src={require('../../../../assret/images/decoration_template_icon_3.png')}/>
                        </div>
                    </Row>
                </div>     
                <div className="form_item">
                    <div className="form_label">图片设置：</div>
                    <UploadImg imgList={data.imgList.map((item:any,index:number)=>{return item.imgUrl})} module={data.module} field="imgList"/>
                </div>     
                <Row type="flex" align="middle" className="form_item">
                    <div className="form_label">名称：</div>
                    <Input value={data.imgList[dataOther.editMutiCurrent].desc} placeholder="最多可输入5个字" className="form_input" onChange={(e)=>{changeField('imgList',e.currentTarget.value,data,dataOther,'desc')}}/>
                </Row>        
                <Row type="flex" align="middle" className="form_item">
                    <div className="form_label">价格：</div>
                    <Input value={data.imgList[dataOther.editMutiCurrent].subDesc} placeholder="最多可输入5个字" className="form_input" onChange={(e)=>{changeField('imgList',e.currentTarget.value,data,dataOther,'subDesc')}}/>
                </Row>        
            </div>            
        )        
    }

    //魔方导航
    const templateMofang = (data:any={},dataOther:any={}) => {
        return (
            <div className="form_bg">
                <Row type="flex" align="middle" className="form_item">
                    <div className="form_label">模块名称：</div>
                    <Input type="text" maxLength={5} value={data.title} placeholder="最多可输入5个字" className="form_input"  onChange={(e)=>{changeField('title',e.currentTarget.value,data,dataOther)}}/>
                    <Radio checked={data.showTitle} onClick={(e)=>{changeField('showTitle',!data.showTitle,data,dataOther)}}/>
                </Row>
                <Row type="flex" align="middle" className="form_item">
                    <div className="form_label">副标题：</div>
                    <Input type="text" maxLength={5} value={data.subTitle} placeholder="最多可输入5个字" className="form_input" onChange={(e)=>{changeField('subTitle',e.currentTarget.value,data,dataOther)}}/>
                    <Radio checked={data.showSubTitle} onClick={(e)=>{changeField('showSubTitle',!data.showSubTitle,data,dataOther)}}/>
                </Row> 
                <div className="form_item">
                    <div className="form_label">模块样式：</div>
                    <Row type="flex" align="middle" className="form_templateIcon2">
                        <div onClick={(e)=>{changeField('type',1,data,dataOther)}}>
                            {
                                data.type === 1 && <div className="form_templateIcon2_select" />
                            }
                            <img src={require('../../../../assret/images/decoration_template_icon_4.png')}/>
                        </div>
                        <div onClick={(e)=>{changeField('type',2,data,dataOther)}}>
                            {
                                data.type === 2 && <div className="form_templateIcon2_select" />
                            }
                            <img src={require('../../../../assret/images/decoration_template_icon_5.png')}/>
                        </div>
                    </Row>
                </div>
                {
                    data.type === 1 && (
                        <Row type="flex" align="middle" className="form_item">
                            <div className="form_label">列数：</div>
                            <Radio.Group value={data.column} onChange={(e:any)=>{changeField('column',e.target.value,data,dataOther)}}>
                                <Radio value={3}>三列</Radio>
                                <Radio value={4}>四列</Radio>
                                <Radio value={5}>五列</Radio>
                            </Radio.Group>
                        </Row>
                    ) 
                }
                <div className="form_item">
                    <div className="form_label mb10">编辑导航：</div>
                    <Table
                        pagination={false}
                        style={{width: '100%'}}
                        dataSource={data.list} 
                        columns={columns} 
                        rowKey={"key"} 
                        locale={{emptyText: '暂无数据'}}
                        components={{
                            body: {
                                wrapper: DraggableContainer,
                                row: (e)=> DraggableBodyRow(e,data.list),
                            },
                        }}
                    />

                    {/* <Table rowKey={'key'} dataSource={data.list} columns={columns} bordered pagination={false}/> */}
                    <Row className="form_add" type="flex" align="middle" justify="center">
                        <Button onClick={()=>{addMenu()}}>添加导航</Button>
                    </Row>
                </div>
            </div>
        )
    }

    //邀请有礼or权益卡
    const templateInvitation = (data:any={},dataOther:any={}) => {
        return(
            <div className="form_bg">
                <Row type="flex" align="top" className="form_item mt3">
                    <div className="form_label">模块样式：</div>
                    <div>
                        <Radio.Group value={data.type} className="mb20" onChange={(e:any)=>{changeField('type',e.target.value,data,dataOther)}}>
                            <Radio value={0}>默认</Radio>
                            <Radio value={1}>自定义</Radio>
                        </Radio.Group>
                        {
                            data.type === 1 && <Row type="flex" align="bottom">
                                <UploadImg isSingle={true} imgList={[data.imgUrl]} module={data.module} field="imgUrl"/>
                                <span className="mb10">最佳尺寸：335*90</span>
                            </Row>
                        }
                    </div>
                </Row> 
                <Row type="flex" align="middle" className="form_item">
                    <div className="form_label">链接：</div>
                    <Input value={data.title} placeholder="" className="form_input" disabled/>
                </Row>     
            </div> 
        )
    }
    //编辑魔方导航
    const templateMofangEdit = (data:any={},dataOther:any={}) => {
        let dataSource = data.list[dataOther.editMofangCurrent]
        return (
            <div>
                <div className="form_bg">
                    <Row type="flex" align="middle" className="form_item">
                        <div className="form_label">标题：</div>
                        <Input type="text" maxLength={5} value={dataSource.name} placeholder="最多可输入5个字" className="form_input" onChange={(e:any)=>{changeMoFangField('name',e.currentTarget.value,data,dataOther)}}/>
                    </Row>
                </div>
                <div className="form_bg">
                    <Row type="flex" align="middle" className="form_item">
                        <div className="form_label">标题样式：</div>
                        <Radio.Group value={dataSource.styleType} onChange={(e:any)=>{changeMoFangField('styleType',e.target.value,data,dataOther)}}>
                            <Radio value={0}>默认</Radio>
                            <Radio value={1}>自定义</Radio>
                        </Radio.Group>                            
                    </Row>
                    {
                        dataSource.styleType === 1 && (
                            <>
                                <Row type="flex" align="middle" className="form_item">
                                    <div className="form_label">标题样式：</div>
                                    <Row type="flex" align="middle" justify="space-between" className="form_size">
                                        <div className={`form_size_item ${dataSource.style.fontSize===1&&'form_size_item_active'}`} onClick={()=>{ changeMoFangField('style',1,data,dataOther,'fontSize') }}>大（16）</div>
                                        <div className={`form_size_item ${dataSource.style.fontSize===2&&'form_size_item_active'}`} onClick={()=>{ changeMoFangField('style',2,data,dataOther,'fontSize') }}>中（14）</div>
                                        <div className={`form_size_item ${dataSource.style.fontSize===3&&'form_size_item_active'}`} onClick={()=>{ changeMoFangField('style',3,data,dataOther,'fontSize') }}>小（12）</div>
                                    </Row>                           
                                </Row>                        
                                <Row type="flex" align="middle" className="form_item">
                                    <div className="form_label">颜色：</div>
                                    <Popover 
                                        placement="left" 
                                        content={<SketchPicker
                                            color={dataSource.style.color}
                                            onChangeComplete={(e: ColorResult) =>{
                                                console.log(e)
                                                // changeColor(e.rgb)
                                                let color = 'rgba(' + e.rgb.r + ', ' + e.rgb.g + ', ' + e.rgb.b + ', ' + e.rgb.a + ')'
                                                changeMoFangField('style',color,data,dataOther,'color')
                                            }}
                                            // onChange={(e: ColorResult)=>{changeNickNameColor(e)}}
                                        />} 
                                        title="颜色选择器" 
                                        trigger="click"
                                    >
                                        <div className="form_color" style={{backgroundColor:dataSource.style.color}} />
                                    </Popover>  
                                </Row>            
                                <Row type="flex" align="middle" className="form_item">
                                    <div className="form_label">加粗：</div>
                                    <Checkbox checked={dataSource.style.fontWeight} onChange={(e)=>{changeMoFangField('style',e.target.checked,data,dataOther,'fontWeight')}}/>                   
                                </Row>                               
                            </>
                        )
                    }
                </div>
                <div className="form_bg">
                    <Row type="flex" align="top" className="form_item">
                        <div className="form_label mt3">图标附图：</div>
                        <div>
                            <Radio.Group value={dataSource.imgType} className="mb20" onChange={(e)=>{changeMoFangField('imgType',e.target.value,data,dataOther)}}>
                                <Radio value={0}>默认</Radio>
                                <Radio value={1}>自定义</Radio>
                            </Radio.Group>
                            {
                                dataSource.imgType === 1 && (
                                    <Row type="flex" align="bottom">
                                        <UploadImg isSingle={true} module={data.module} imgList={[dataSource.imgUrl]} field="list"/>
                                        <span className="mb10">最佳尺寸：60*60</span>
                                    </Row>
                                )
                            }
                        </div>
                    </Row>                        
                </div> 
                <div className="form_bg">
                    <Row type="flex" align="middle" className="form_item">
                        <div className="form_label">链接：</div>
                        <Popover onVisibleChange={visible=>setLinkShow(visible)} getPopupContainer={(triggerNode:any)=>triggerNode.parentNode} visible={linkShow} content={content} title="链接" trigger="click">
                            <div className="form_link" onClick={()=>{setLinkShow(!linkShow)}}>
                                {dataSource.linkName? dataSource.linkName : '链接到的页面地址'}<Icon type="up" className="ml10" style={{color:'#5D6BFF'}}/>
                            </div>
                        </Popover>                            
                    </Row>                         
                </div>                
            </div>            
        )
    }

    //修改字段
    const changeField = (field:string,value:any,data:any={},dataOther:any={},childeField?:string) => {
        let dataTemp = JSON.parse(JSON.stringify(data))
        if(childeField){
            if(field === 'imgList'){
                // if((childeField === 'desc' || childeField === 'subDesc') && value.length>5){
                //     message.error('最多可输入五个字')
                //     return false
                // }
                dataTemp[field][dataOther.editMutiCurrent][childeField] = value
                //不调用方法也能更新
                shopDecorationStore.updateShopDecorationHomeObjProps(dataOther.current,field,dataTemp[field])
            }
        }else{
            // if((field === 'title' || field === 'subTitle') && value.length>5){
            //     message.error('最多可输入五个字')
            //     return false
            // }
            shopDecorationStore.updateShopDecorationHomeObjProps(dataOther.current,field,value)
        }
    }

    //魔方导航编辑修改字段
    const changeMoFangField = (field:string,value:any,data:any={},dataOther:any={},childeField?:string) => {
        let dataTemp = JSON.parse(JSON.stringify(data))
        if(!childeField){
            // dataTemp[field][dataOther.editMutiCurrent][childeField] = value
            // //不调用方法也能更新
            // shopDecorationStore.updateShopDecorationHomeObjProps(dataOther.current,field,dataTemp[field])
            if(field === 'styleType' && value ===0){
                dataTemp.list[dataOther.editMofangCurrent].style = {
                    fontSize: 1, //字体 ，1为16号，2为14号， 3为12号
                    color: 'rgba(0,0,0)', //字体颜色
                    fontWeight: false, //字体是否加粗
                }
            }
            // if(field === 'name' && value.length>5){
            //     message.error('最多可输入五个字')
            //     return false
            // }
            dataTemp.list[dataOther.editMofangCurrent][field] = value
            shopDecorationStore.updateShopDecorationHomeObjProps(dataOther.current,'list',dataTemp.list)
        }else{
            dataTemp.list[dataOther.editMofangCurrent][field][childeField] = value
            shopDecorationStore.updateShopDecorationHomeObjProps(dataOther.current,'list',dataTemp.list)
        }
    }

    return (<Observer render={() =>
        <div className="decoration_home_right_form">
            <div className={`form ${shopDecorationStore.shop_decoration_home_other.showRight&&'form_active'}`} onClick={(e)=>{e.stopPropagation()}}>
                <div className="form_title">{shopDecorationStore.shop_decoration_home_other.current === -1 && !shopDecorationStore.shop_decoration_home[shopDecorationStore.shop_decoration_home_other.current] ? '导航项' : shopDecorationStore.shop_decoration_home[shopDecorationStore.shop_decoration_home_other.current].moduleName}</div>
                {
                    _initHtmls()
                }
            </div>
        </div>
    }/>)
}

export default DecorationHomeLeft