import React from 'react';
import { Row, Col } from 'antd';
import '../management.scss';

import winAddDiscount1 from '../../../../../../assret/images/winAddDiscount1.png';
import winAddDiscount2 from '../../../../../../assret/images/winAddDiscount2.png';
import winAddDiscount3 from '../../../../../../assret/images/winAddDiscount3.png';

interface HelpMbaDiscount {
    history: any,
}

class HelpMbaDiscount extends React.Component<HelpMbaDiscount> {

    render() {
        return  <Row className="typeDetail">
                    <Col className="typeDetail-title">一、功能介绍</Col>
                    <Col className="typeDetail-col">所有需要投放的优惠券，必须要在优惠券管理列表中添加优惠券，主要帮助商家拉新促活促复购。</Col>
                    <Col className="typeDetail-title">二、功能路径</Col>
                    <Col className="typeDetail-col">电脑端登录后台-营销管理-优惠券列表-添加优惠券。</Col>
                    <Col className="typeDetail-col">
                        <img src={winAddDiscount1} />
                    </Col>
                    <Col className="typeDetail-col">在优惠券列表里可以查看已设置好的优惠券名称、优惠券类型、有效期、库存等</Col>
                    <Col className="typeDetail-title">三、操作流程</Col>
                    <Col className="typeDetail-col">1、<span className="txt-bold">添加优惠券</span>，在“优惠券列表”界面下点击“添加”</Col>
                    <Col className="typeDetail-col">
                        <img src={winAddDiscount2} />
                    </Col>
                    <Col className="typeDetail-col">
                        <img src={winAddDiscount3} />
                    </Col>
                    <Col className="typeDetail-col">1、目前可添加的优惠券类型有：折扣券、优惠券</Col>
                    <Col className="typeDetail-col">2、推广渠道有：首页、领取中心、礼包渠道、内部发行，选择首页只在点餐小程序显示，选择领取中心，只在点餐小程序领取中心领取，选择礼包渠道，只有在创建权益卡或进店有礼可使用，选择内部发行，只能在直接发券起效果</Col>
                    <Col className="typeDetail-col">3、优惠券名称：填写名称</Col>
                    <Col className="typeDetail-col">4、有效期：可以填写固定日期或领取后使用日期</Col>
                    <Col className="typeDetail-col">5、抵扣条件：最低消费满多少元（如不选择，则默认“无最低消费显示”）</Col>
                    <Col className="typeDetail-col">6、发行数量：填写发行数量</Col>
                    <Col className="typeDetail-col">7、每人领取：可以显示消费者领取优惠券数量</Col>
                    <Col className="typeDetail-col">8、排序：数字越大，排序越靠前</Col>
                    <Col className="typeDetail-col">9、状态：启用和停用两种状态，默认选择启用，当选择停用，则用户不可领取该优惠券</Col>
                    <Col className="typeDetail-col">10、适用门店：如没有选择适用门店，默认选择全部门店，选择部分门店时，只能在所选择的门店使用该优惠券</Col>
                    <Col className="typeDetail-col">11、不可使用时段：优惠券可以设置所有日期都可用，部分日期不可用，当选择所有时间都可以使用时，任意时间都可以使用该优惠券,当选择部分日期不可用时，所选择的那一部分时间不可使用该优惠券</Col>
                    <Col className="typeDetail-col">12、消费继续领券：每次消费，选择每次消费时，用户每次买完单可继续领取，选择消费满多少钱，用户满足条件，买完单可继续领取、选择一次领取，用户领取一次后，不可继续领取</Col>
                    <Col className="typeDetail-col">13、是否支持赠送：默认为不支持赠，优惠券只能自己消费使用，当选择支持赠送，用户领取优惠券，自己不使用时，可赠送给朋友到店消费使用</Col>
                    <Col className="typeDetail-col">14、可见用户：当选择新用户，则还未注册的用户或已经注册还没有下单的用户，可在相关渠道看到该优惠券，当选择老顾客，则交易次数>=1次的交易次数用户，可在相关渠道看到该优惠券，当全部选择时，则所有用户，可在相关渠道看到该优惠券</Col>
                    <Col className="typeDetail-col">15、使用须知：填写使用须知，在用户领取优惠券后，用户点击使用规则显示</Col>
                </Row>
    }
}

export default HelpMbaDiscount;