import React, { useState, useRef, useEffect } from "react";
import {Row,Col,Tooltip,Button,Icon,Switch,Radio,Select,Upload, message} from "antd";
import { SketchPicker, ColorResult } from "react-color";
import { IFormData } from "../create_active";
import "fabric";
import { apiUrl } from "../../../../api/api_url";
import { getRequest, postRequest } from "../../../../api/http";
import { nickNameText } from "../../util/fabric_utils";
import { withRouter, RouteComponentProps } from "react-router-dom";
declare let fabric: any;

const { Option } = Select;

interface IProps extends RouteComponentProps {
  update(d: IFormData): void;
  formData: IFormData;
  workStep: number;
  setworkStep(step: number): void;
  isEdit: boolean
}

// 画布 内容 唯一标识
const uniqueKeys = {
  nickName: "nickName",
  avatarUrl: "avatarUrl",
  qrCode: "qrCode",
  backImage: "backImage"
};

let myCanvas:any;

const CreateStep03 = withRouter((props: IProps) => {
  const canvasConfig = {
    width: 340,
    height: 340
  };
  

  const [showColorPicker, setshowColorPicker] = useState(false);
  const [nickNameColor, setnickNameColor] = useState("#000000");
  const [nickNameSize, setnickNameSize] = useState(12);
  const canvasRef = useRef(null);
  const QINIU_SERVER = "https://up-z2.qiniup.com";
  const [token, setToken] = useState("");
  const [fileList, setFileList] = useState([]);
  const [imageKey, setimageKey] = useState("");
  // const [myCanvas, setmyCanvas] = useState();
  const [nickNameTextAlign, setnickNameTextAlign] = useState("left");
  const [avatarRadius, setavatarRadius] = useState("方形");
  const [showAvatar, setshowAvatar] = useState(true);
  const [showNickname, setshowNickname] = useState(true);
  useEffect(() => {
    if(!props.isEdit){
      const canvas = new fabric.Canvas(canvasRef.current, {
        backgroundColor: "#eef0f3"
      });
      // setmyCanvas(canvas);
      myCanvas = canvas;
      initCanvas(canvas);
    } else {
      const canvasData = JSON.parse(props.formData.poster);
      myCanvas = new fabric.Canvas(canvasRef.current, {
        backgroundColor: "#eef0f3",
        width: canvasData.width,
        height: canvasData.height
      });

      myCanvas.loadFromJSON(canvasData,() => {
        myCanvas.renderAll();
        // setmyCanvas(canvas);
        setshowAvatar(canvasData.showAvatar)
        setshowNickname(canvasData.showNickname);
        const index = getCanvasItemIndex(uniqueKeys.nickName);
        if(canvasData['objects'][index]['fill']) {
          setnickNameColor(canvasData['objects'][index]['fill']);
        }
        setnickNameSize(canvasData['objects'][index]['fontSize']);
        setnickNameTextAlign(canvasData['objects'][index]['textAlign']);
      });
    }
  });

  const avatarOnChange = (val: boolean) => {
    const index = getCanvasItemIndex(uniqueKeys.avatarUrl);
    if (index !== -1) {
      setshowAvatar(val);
      myCanvas.item(index).set("opacity", val ? 1 : 0);
      myCanvas.renderAll();
    }
  };

  const nickNameOnChange = (val: boolean) => {
    const index = getCanvasItemIndex(uniqueKeys.nickName);
    if (index !== -1) {
      setshowNickname(val);
      myCanvas.item(index).set("opacity", val ? 1 : 0);
      myCanvas.renderAll();
    }
  };

  // item(1).item(0) 是方形头像
  // item(1).item(1) 是圆形头像
  const handleAvatarShapChange = (shap: string) => {
    const index = getCanvasItemIndex(uniqueKeys.avatarUrl);
    if (index !== -1) {
      if (shap === "方形") {
        myCanvas.item(index).item(0).set("opacity", 1);
        myCanvas.item(index).item(1).set("opacity", 0);
      }
      if (shap === "圆形") {
        myCanvas.item(index).item(0).set("opacity", 0);
        myCanvas.item(index).item(1).set("opacity", 1);
      }
      setavatarRadius(shap);
      myCanvas.renderAll();
    }
  };

  const uploadPoster = () => {};

  // 点击完成
  const carryOut = async () => {
    const canvasData = myCanvas.toJSON();
    canvasData.showAvatar = showAvatar;
    canvasData.showNickname = showNickname;
    const data = props.formData;
    let nickNameIndex = getCanvasItemIndex(uniqueKeys.nickName);
    let avatarUrlIndex = getCanvasItemIndex(uniqueKeys.avatarUrl);
    let qrCodeIndex = getCanvasItemIndex(uniqueKeys.qrCode);
    let backImageIndex = getCanvasItemIndex(uniqueKeys.backImage);
    if (nickNameIndex !== -1) canvasData.objects[nickNameIndex].uniqueKey = uniqueKeys.nickName;
    if (avatarUrlIndex !== -1) canvasData.objects[avatarUrlIndex].uniqueKey = uniqueKeys.avatarUrl;
    if (qrCodeIndex !== -1) canvasData.objects[qrCodeIndex].uniqueKey = uniqueKeys.qrCode;
    if (backImageIndex !== -1) canvasData.objects[backImageIndex].uniqueKey = uniqueKeys.backImage;
    const canvsdom:any = document.getElementById('canvas');
      canvasData.width = canvsdom.width;
      canvasData.height = canvsdom.height;
    data.poster = JSON.stringify(canvasData);
    const id = data.id;
    if(data['shop']) delete data['shop'];
    if(data['food']) delete data['food'];
    if(data.id != null) delete data.id;
    if(data.updated_at != null) delete data.updated_at;
    if(props.isEdit) {
      const res:any = await postRequest(apiUrl.updateActivity, {
        id: id,
        update: data
      });
      if(res && res.data) {
        message.success('活动已更新！');
        setTimeout(() => {
          props.history.push('/sidebar/distribution');
        }, 1000);
      }
    } else {
      const res:any = await postRequest(apiUrl.createShareActivities, data);
      if(res && res.data) {
        message.success('活动创建成功！');
        setTimeout(() => {
          props.history.push('/sidebar/distribution');
        }, 1000);
      }
    }
    
  };

  const handlebeforeUpload = async () => {
    const res: any = await getRequest(apiUrl.getUploadToken, {
      type: "poster"
    });
    if (res && res.data) {
      const key = `${Math.random().toString(36).slice(-8)}${new Date().valueOf()}.png`;
      setimageKey(key);
      setToken(res.data.upload_token);
    }
  };

  const handleChange = (e: any) => {
    if (e.file && e.file.status === "done") {
      setFileList(e.fileList);
      drawImage(e.file.response.url);
    }
  };

  const changeNickNameColor = (color: string) => {
    const index = getCanvasItemIndex(uniqueKeys.nickName);
    if (index !== -1) {
      setnickNameColor(color);
      myCanvas.item(index).set("fill", color);
      myCanvas.renderAll();
    }
  };

  const changeNickNameFz = (size: number) => {
    const index = getCanvasItemIndex(uniqueKeys.nickName);
    if (index !== -1) {
      myCanvas.item(index).set("fontSize", size);
      setnickNameSize(size);
      myCanvas.renderAll();
    }
  };

  const textAlignChange = (val: string) => {
    const index = getCanvasItemIndex(uniqueKeys.nickName);
    if (index !== -1) {
      myCanvas.item(index).set("textAlign", val);
      setnickNameTextAlign(val);
      myCanvas.renderAll();
    }
  };

  // 初始化canvas
  const initCanvas = (canvas: any) => {
    canvas.setWidth(canvasConfig.width);
    canvas.setHeight(576);
    canvas.add(nickNameText); // item00 用户名
    const dufaultAvatarUrl =
      "http://ydn-siyu.cdn.shuachi.com/poster/dufault_avatar_bg.png";
    const defaultQrCodeUrl =
      "http://ydn-siyu.cdn.shuachi.com/poster/default_qr_code.png";

    const defaultAvatarImg = new Image();
    const defaultQrCode = new Image();
    defaultQrCode.src = defaultQrCodeUrl;
    defaultQrCode.crossOrigin = 'anonymous';
    defaultAvatarImg.src = dufaultAvatarUrl;
    defaultAvatarImg.crossOrigin = 'anonymous';
    defaultQrCode.onload = () => {
      defaultAvatarImg.onload = () => {
        const imgW = defaultAvatarImg.width;
        // const imgH = defaultAvatarImg.height;
        const avatarW = 70;
        const _scale = imgW > avatarW ? avatarW / imgW : imgW / avatarW;
        const _defaultAvatar = new fabric.Image(
          defaultAvatarImg,
          {
            left: 0,
            top: 0,
            angle: 0,
            opacity: 1,
            scaleX: _scale,
            scaleY: _scale,
            hasControls: true,
            selectable: true,
            crossOrigin: 'anonymous'
          }
        );

        fabric.Image.fromURL(dufaultAvatarUrl, (img: any) => {
          fabric.Image.fromURL(defaultQrCodeUrl, (imgQrcode: any) => {
            img.scale(_scale).set({
              left: 0,
              top: 0,
              angle: 0,
              crossOrigin: 'anonymous'
              // clipTo: (ctx: any) => {
              //   ctx.arc(0, 0, 130, 0, Math.PI * 2, true);
              // }
            });

            imgQrcode.scale(_scale).set({
              left: 50,
              top: 100,
              angle: 0,
              crossOrigin: 'anonymous'
            });

            const avatarGroup = new fabric.Group([_defaultAvatar, img], {
              left: 40,
              top: 40,
              angle: 0,
              opcity: 0,
              crossOrigin: 'anonymous'
            });
            nickNameText["uniqueKey"] = uniqueKeys.nickName;
            imgQrcode["uniqueKey"] = uniqueKeys.qrCode; // 添加画布二维码唯一标识
            avatarGroup["uniqueKey"] = uniqueKeys.avatarUrl;
            canvas.add(avatarGroup);
            canvas.add(imgQrcode);
          });
        });
      };
    };
    defaultQrCode.onerror = e => {
      console.log("二维码加载错误", e);
    };
    defaultAvatarImg.onerror = e => {
      console.log("图片加载错误", e);
    };
  };

  const drawImage = (url: string) => {
    const index = getCanvasItemIndex(uniqueKeys.backImage);
    if (index !== -1) {
      myCanvas.remove(myCanvas.item(index));
    }
    const img = new Image();
    img.src = url;
    img.onload = () => {
      myCanvas.setWidth(canvasConfig.width);
      const scaleImg =
        img.width > canvasConfig.width
          ? canvasConfig.width / img.width
          : img.width / canvasConfig.width;

      const _height = img.height * scaleImg;

      const index = getCanvasItemIndex(uniqueKeys.qrCode);
      if (index !== -1) {
        // qrCodeObg.set({top : 20, selectable: true, hasControls: true});
        myCanvas.renderAll();
      }

      myCanvas.setHeight(_height);

      const imgInstance = new fabric.Image(img, {
        left: 0, // 图片相对画布的左侧距离
        top: 0, // 图片相对画布的顶部距离
        angle: 0, // 图片旋转角度
        opacity: 1, // 图片透明度
        scaleX: scaleImg,
        scaleY: scaleImg,
        hasControls: false,
        selectable: false
      });

      imgInstance["uniqueKey"] = uniqueKeys.backImage;
      // myCanvas.add(imgInstance);  // item00 背景图
      myCanvas.setBackgroundImage(
        imgInstance,
        myCanvas.renderAll.bind(myCanvas),
        {
          scaleX: scaleImg,
          scaleY: scaleImg,
          crossOrigin: 'anonymous'
        }
      );
      myCanvas.renderAll();
      // myCanvas.moveTo(imgInstance, 0);
    };
  };

  // 获取 当前操作 内容在画布下的下标
  const getCanvasItemIndex = (uniqueKey: string) => {
    const items = myCanvas._objects;
    let index = -1;
    for (let i = 0; i < items.length; i++) {
      let flag: boolean = items[i].hasOwnProperty("uniqueKey");
      if (flag) {
        if (items[i].uniqueKey === uniqueKey) {
          index = i;
          break;
        }
      }
    }
    return index;
  };

  return (
    <div>
      <Row
        type="flex"
        style={{ marginTop: 30 }}
        onClick={e => {
          e.stopPropagation();
          setshowColorPicker(false);
        }}
      >
        <Col span={12}>
          <Row type="flex" align="top" style={{ marginTop: 30 }}>
            <Col span={3} style={{ textAlign: "right" }}>
              上传海报：
            </Col>
            <Tooltip placement="topLeft" title={"我是提示内从"}>
              <Icon type="question-circle" />
            </Tooltip>
            <Upload
              showUploadList={false}
              action={QINIU_SERVER}
              accept="image/*"
              beforeUpload={handlebeforeUpload}
              onChange={e => handleChange(e)}
              data={{
                token,
                region: `https://up-z2.qiniu.com`,
                key: imageKey,
                "x:type": "poster"
              }}
            >
              <Button
                type="primary"
                style={{ marginLeft: 20 }}
                onClick={uploadPoster}
              >
                上传海报
              </Button>
            </Upload>
            <Col span={6} style={{ marginLeft: 10 }}>
              {fileList.length > 0 &&
                fileList[0] &&
                fileList[0]["file"] &&
                fileList[0]["file"]["response"] &&
                fileList[0]["file"]["response"]["url"]}
            </Col>
          </Row>

          <Row type="flex" align="middle" style={{ marginTop: 30 }}>
            <Col span={3} style={{ textAlign: "right" }}>头像：</Col>
            <Switch
              defaultChecked={showAvatar}
              onChange={(val: boolean) => avatarOnChange(val)}
            />
            <Radio.Group
              value={avatarRadius}
              onChange={e => handleAvatarShapChange(e.target.value)}
              style={{ marginLeft: 20 }}
            >
              <Radio.Button disabled value={"圆形"}>圆形</Radio.Button>
              <Radio.Button value={"方形"}>方形</Radio.Button>
            </Radio.Group>
          </Row>

          <Row type="flex" align="middle" style={{ marginTop: 30 }}>
            <Col span={3} style={{ textAlign: "right" }}>
              昵称：
            </Col>
            <Switch
              defaultChecked={showNickname}
              onChange={(val: boolean) => nickNameOnChange(val)}
            />
            <Col
              span={3}
              style={{ marginLeft: 10, display: "flex", alignItems: "center" }}
            >
              颜色
              <div
                style={{
                  width: 20,
                  height: 20,
                  backgroundColor: nickNameColor,
                  display: "inline-block",
                  border: "1px solid rgba(0,0,0,.1)",
                  marginLeft: 10
                }}
                onClick={e => {
                  e.stopPropagation();
                  setshowColorPicker(true);
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    left: 80,
                    top: 0,
                    zIndex: 100
                  }}
                >
                  {showColorPicker && (
                    <SketchPicker
                      color={nickNameColor}
                      onChangeComplete={(e: ColorResult) =>
                        changeNickNameColor(e.hex)
                      }
                    />
                  )}
                </div>
              </div>
            </Col>
            <Col>
              大小
              <Select
                defaultValue={nickNameSize}
                style={{ width: 60, marginLeft: 10 }}
                onChange={(e: number) => changeNickNameFz(e)}
              >
                {[12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22].map(size => (
                  <Option key={size} value={size}>
                    {size}
                  </Option>
                ))}
              </Select>
            </Col>

            <Col>
              <Radio.Group
                value={nickNameTextAlign}
                onChange={val => textAlignChange(val.target.value)}
                style={{ marginLeft: 20 }}
              >
                <Radio.Button value="left">左对齐</Radio.Button>
                <Radio.Button value="center">居中</Radio.Button>
                <Radio.Button value="right">右对齐</Radio.Button>
              </Radio.Group>
            </Col>
          </Row>
        </Col>

        <Col span={4}>
          <div style={{ width: 500, height: 600 }}>
            <canvas
              id="canvas"
              ref={canvasRef}
              style={{ touchAction: "none", userSelect: "none" }}
            ></canvas>
          </div>
        </Col>
      </Row>

      <Row
        type="flex"
        align="middle"
        justify="center"
        style={{ marginTop: 50 }}
      >
        {props.workStep > 0 && props.workStep < 3 && (
          <Button
            type="primary"
            onClick={() => props.setworkStep(props.workStep - 1)}
          >
            上一步
          </Button>
        )}
        {props.workStep === 2 && (
          <Button type="primary" style={{ marginLeft: 50 }} onClick={carryOut}>
            完成
          </Button>
        )}
      </Row>
    </div>
  );
});

export default CreateStep03;
