import React, {useEffect, useState} from "react";
import { Upload, Icon, Modal } from 'antd';
import { postRequest, getRequest } from '../../../../api/http';
import { apiUrl } from '../../../../api/api_url';

const UploadImg = (props:any) => {
    const { onChange, imgUrl, callBack, label, notRequire } = props

    const [ updateObj, setUpdateObj ] = useState<any>({})
    const [ loading, setLoading ] = useState(false)
    // const [ imageObj, setImageObj ] = useState<any>({})
    const [ imageUrl, setImageUrl ] = useState('')
    const [ previewImage, setPreviewImage ] = useState('')
    const [ previewVisible, setpreviewVisible ] = useState(false)
    const [ fileList, setFileList ] = useState<any>([])

    useEffect(()=>{
        setImageUrl(imgUrl)
        if(imgUrl){
            setFileList([{
                uid: '-1',
                name: new Date()+'.png',
                status: 'done',
                url: imgUrl,
                thumbUrl: imgUrl,
            }])
        }
        // setFileList([])
    },[imgUrl])

    const QINIU_SERVER = 'https://up-z2.qiniup.com';
    // 获取图片token
    const beforeUpload = async (file:any) => {
        const res:any = await getRequest(apiUrl.getUploadToken, { type: 'food' });
        if(res && res.data) {
            const key = `food/${Math.random().toString(36).slice(-8)}${(new Date()).valueOf()}.png`
            const obj = { token: res.data.upload_token, region: `https://up-z2.qiniu.com`, key: key, 'x:type': "food" }
            setUpdateObj(obj)
        }
    }
    const handleChange = async(info:any) => {
        setFileList(info.fileList)
        if(info.fileList.length<=0)setImageUrl('')
        if (info.file.status === 'uploading') {
            setLoading(true)
            return;
        }
        if (info.file.status === 'done') {
            let file:any = await getBase64(info.file.originFileObj);
            // setImageObj(info.file.response)
            setImageUrl(info.file.response.url)
            setLoading(false)
            onChange(info.file.response.url)
            if(callBack){
                callBack(file.replace(/data:image\/.*;base64,/,''),info.file.response.url)
            }
        }
    };
    const handlePreview = async (file:any) => {
        if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
        }
        setpreviewVisible(true)
        setPreviewImage(file.url || file.preview)
    };

    const getBase64 = (file:any) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }    

    const handleCancel = () => setpreviewVisible(false);

    return(
        <div className="row_view mbt16">
            <div className="sub_title">{!notRequire&&<span className="color_red mr5">*</span>}{label} :</div>
            <Upload
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={!loading}
                action={QINIU_SERVER}
                beforeUpload={beforeUpload}
                onChange={handleChange}
                data={updateObj}
                onPreview={handlePreview}
                fileList={fileList}
                >                  
                {
                    !imageUrl&&<div className="">
                        <Icon type={loading ? 'loading' : 'plus'} style={{fontSize: '40px'}}/>
                    </div>
                }
            </Upload>                    
            <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
        </div>
    )
}

export default UploadImg