import React, { useEffect, useState } from 'react'
import { Button, Table } from 'antd'
import { useHistory } from 'react-router-dom';
import { getRequest } from '../../../api/http'
import { apiUrl } from '../../../api/api_url'
import './index.scss'
import { getIsPermisson, messageCustom } from '../../../utils/permisstionUtil';

const WechatSmsIndex = ()=>{

    const history = useHistory()
    const [data, setData] = useState([]);

    useEffect(() => {
        getOfficialTemplateList()
    }, [])

    const getOfficialTemplateList = async () => {
        const res:any = await getRequest(apiUrl.getOfficialTemplate)
        if(res && res['code'] === 1) {
            setData(res.data);
        }
    }

    const creatTable = ()=>{
            
        const columns:any = [
            {
                title: '模板名称',
                dataIndex: 'title',
            },
            {
                title: '编号',
                dataIndex: 'code',
            },
            {
                title: '模板id',
                dataIndex: 'template_id',
            },
            // {
            //     title: '操作',
            //     dataIndex: 'option',
            //     render: ( text:string, record:any, index:number )=>
            //         <Button type="link" onClick={()=>{history.push('/sidebar/marketingDetail',{id:record.id})}}>编辑</Button>
            // },
        ];

        return (
            <Table 
                dataSource={data} 
                columns={columns} 
                rowKey={'id'}    
                pagination={false}
            />
        )
    }

    return(
        <div className="wechat_sms_index l_content">
            <div className="title_text">
                公众号消息模板
                <a target="target" type="link" className="ml20" href='https://mp.weixin.qq.com/'>还没有添加消息模板？</a>
                <Button type="primary" onClick={()=>{history.push('/sidebar/wechatSmsGuide')}}>查看教程</Button>
            </div>
            <div className="mt20 mb20 fz18">添加微信公众号消息模板，把门店和微信打通</div>
            <div className="mb20">添加微信消息模板后，可以结合营销活动进行推广</div>
            <Button type="primary" onClick={()=>{
                if(!getIsPermisson('2_4_5_1')){
                    messageCustom();
                    return;
                  }
                history.push('/sidebar/wechatSmsAdd')
            }} className="mb20">批量修改</Button>
            {
                creatTable()
            }
        </div>
    )
}

export default WechatSmsIndex