import React from 'react';
import { Row, Col } from 'antd';
import '../management.scss';

import winAddSysNum from '../../../../../../assret/images/winAddSysNum.png';
import winAddSysEdit from '../../../../../../assret/images/winAddSysEdit.png';

interface ShopManagementDetail {
    history: any,
}

class ShopManagementDetail extends React.Component<ShopManagementDetail> {

    render() {
        return  <Row className="typeDetail">
                    <Col className="typeDetail-title">一、功能介绍</Col>
                    <Col className="typeDetail-col">每个角色所拥有的后台操作权限不尽相同。</Col>
                    <Col className="typeDetail-title">二、功能路径</Col>
                    <Col className="typeDetail-col">电脑端登录后台-权限管理-系统角色-添加角色。</Col>
                    <Col className="typeDetail-col">
                        <img src={winAddSysNum} />
                    </Col>
                    <Col className="typeDetail-title">三、操作流程</Col>
                    <Col className="typeDetail-col">1、<span className="txt-bold">添加角色</span>，在“系统角色”界面下点击“添加”填写角色名称、角色描述、权限配置</Col>
                    <Col className="typeDetail-col">
                        <img src={winAddSysEdit} />
                    </Col>
                    <Col className="typeDetail-col">点击“确定”，完成角色的添加。返回角色列表页，在“操作”类目中可以进行编辑。</Col>
                </Row>
    }
}

export default ShopManagementDetail;