import React, { useEffect, memo, forwardRef, Ref } from "react"
import { Carousel, Row } from 'antd';
import { Observer } from 'mobx-react';
import shopDecorationStore from '../../../../stores/shopDecoration.store'
import eidt_icon from '../../../../assret/images/decoration_edit.png'
import iPhone12Pro from '../../../../assret/images/iPhone12Pro.png'
import Swiper from "swiper";
import "swiper/swiper-bundle.css";
import  './decorationHomeCenterPreview.scss'

interface IProps {
    template:number
}

interface IState {

}
const DecorationHomeCenterPreview = memo(forwardRef((props:IProps,ref: Ref<any>) => {

    let template = props.template 

    useEffect(()=>{

    },[])

    const initHtmls = () => {
        let htmlsTemp:any = []
        shopDecorationStore.shop_decoration_home.map((item:any,index:number)=>{
            switch(item.module){
                case 'muti':
                    if(item.type === 1){
                        if(template === 1){
                            htmlsTemp.push(templateCarousel({...item,index:index}))
                        }else{
                            htmlsTemp.push(templateOldCarousel({...item,index:index}))
                        }
                    }else if(item.type === 2){
                        setTimeout(()=>{
                            new Swiper(".swiper-container-"+index, {
                                slidesPerView: 2.3,
                                centeredSlides: false,
                                virtual: {
                                    slides: item.imgList,
                                },
                                nested:true,
                            });
                        },100)
                        htmlsTemp.push(templateMultiScroll({...item,index:index}))
                    }else{
                        htmlsTemp.push(templateMulti({...item,index:index}))
                    }

                    break;
                case 'action':
                    if(template === 1){
                        htmlsTemp.push(templateAction({...item,index:index}))
                    }else{
                        htmlsTemp.push(templateOldAction({...item,index:index}))
                    }
                    break;
                case 'address':
                    if(template === 1){
                        htmlsTemp.push(templateAddress({...item,index:index}))
                    }else{
                        htmlsTemp.push(templateOldAddress({...item,index:index}))
                    }
                    break;
                case 'equityCard':
                    htmlsTemp.push(templateEquityCard({...item,index:index}))
                    break;
                case 'moFang':
                    if(item.type===2){
                        htmlsTemp.push(templateMoFang2({...item,index:index}))
                    }else{
                        htmlsTemp.push(templateMoFang1({...item,index:index}))
                    }
                    break;
                case 'invitation':
                    htmlsTemp.push(templateInvitation({...item,index:index}))
                    break;
                case 'richText':
                    htmlsTemp.push(templateRichText({...item,index:index}))
                    break;
            }
        })
        return htmlsTemp
    }


    //多图文-轮播图-模版1
    const templateCarousel = (props:any) => {
        return (
            <Carousel autoplay className="template_preview_carousel">
                {
                    props.imgList.map((item:any,index:number)=>{
                        return <div className="template_preview_carousel_item" key={index}>
                            <img src={item.imgUrl} alt=""/>
                        </div>
                    })
                }
            </Carousel> 
        )
    }
    //排队点餐操作-模版1
    const templateAction = (props:any) => {
        return (
            <Row type="flex" align="middle" className="template_preview_action">
                <div className="template_preview_action_item">
                    <img src="https://ydn-siyu.cdn.shuachi.com/line_up.png" alt=""/>
                    <div>排队取号</div>
                </div>
                <div className="template_preview_action_item">
                    <img src="https://ydn-siyu.cdn.shuachi.com/dishes_icon.png" alt=""/>
                    <div>提前点餐</div>
                </div>
            </Row>   
        )
    }
    //商家信息-地址-模版1
    const  templateAddress = (props:any) => {
        return (
            <Row type="flex" align="middle" justify="space-between" className="template_preview_address">
                <div>{'拈江湖鸭肠火锅深圳店 >'}</div>
                <div>
                    <img src="https://ydn-siyu.cdn.shuachi.com/home_location.png" alt=""/>
                    距离您9.75km
                    {/* <DragHandle /> */}
                </div>
            </Row> 
        )
    }
    //商家信息-地址-模版2
    const  templateOldAddress = (props:any) => {
        return (
            <div className="template_preview_oldAddress">
                <div className="template_preview_oldAddress_name">{'拈江湖鸭肠火锅深圳店 >'}</div>
                <Row>
                    <img src="https://ydn-siyu.cdn.shuachi.com/home_location.png" alt=""/>
                    距离您9.75km
                </Row>
            </div> 
        )
    }
    //多图文-轮播图-模版2
    const templateOldCarousel = (props:any) => {
        return (
            <Carousel autoplay className="template_preview_oldCarousel">
                {
                    props.imgList.map((item:any,index:number)=>{
                        return <div className="template_preview_oldCarousel_item" key={index}>
                            <img src={item.imgUrl} alt=""/>
                        </div>
                    })
                }
            </Carousel> 
        )
    }
    //排队点餐操作-模版2
    const templateOldAction = (props:any) => {
        return (
            <Row type="flex" align="middle" className="template_preview_oldAction">
                <Row type="flex" align="middle" justify="center" className="template_preview_oldAction_item">
                    <img src="https://ydn-siyu.cdn.shuachi.com/line_up_2.jpg" alt=""/>
                    <div>排队取号</div>
                </Row>
                <Row type="flex" align="middle" justify="center" className="template_preview_oldAction_item">
                    <img src="https://ydn-siyu.cdn.shuachi.com/dishes_icon.png" alt=""/>
                    <div>提前点餐</div>
                </Row>
            </Row>  
        )
    }
    //多图文-横向拖拽
    const templateMultiScroll = (props:any) => {
        return(
            <div className="template_preview_module">
                {
                    props.showTitle&&<div className="template_preview_module_title"><img src={require('../../../../assret/images/decoration_title_line.png')} alt=""/>{props.title}</div>
                }
                {
                    props.showSubTitle&&<div className="template_preview_module_subTitle">{props.subTitle}</div>     
                }
                <div
                    className={`swiper-container-${props.index}`}
                    style={{
                        width: "100%",
                        overflow:'hidden'
                    }}
                    onTouchEnd = {(e)=>{e.stopPropagation()}}
                    onTouchMove = {(e)=> {e.stopPropagation()}}
                    onTouchStart = {(e)=> {e.stopPropagation()}}
                >
                    <div className="swiper-wrapper">
                        {props.imgList.map((item:any,index:number) => {
                            return (
                                <div className="swiper-slide" key={index}>
                                    <div className="template_preview_module_swiper_item">
                                        <div className="template_preview_module_swiper_img">
                                            <img
                                                src={item.imgUrl}
                                                alt=""
                                            />
                                        </div>
                                        <div className="template_preview_module_swiper_title">{item.desc}</div>
                                        <div className="template_preview_module_swiper_desc">{item.subDesc}</div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        )
    }
    //多图文-竖排
    const templateMulti = (props:any) => {
        return(
            <div className="template_preview_module">
                {
                    props.showTitle&&<div className="template_preview_module_title"><img src={require('../../../../assret/images/decoration_title_line.png')} alt=""/>{props.title}</div>
                }
                {
                    props.showSubTitle&&<div className="template_preview_module_subTitle">{props.subTitle}</div> 
                }
                <Row type="flex" align="middle" className="template_preview_module_muti">
                    {props.imgList.map((item:any,index:number) => {
                        return (
                            <Row type="flex" align="middle" justify="center" style={{width:'33%'}}>
                                <div className="template_preview_module_muti_item" key={index}>
                                    <div className="template_preview_module_muti_img">
                                        <img
                                            src={item.imgUrl}
                                            alt=""
                                        />
                                    </div>
                                    <div className="template_preview_module_muti_title">{item.desc}</div>
                                    <div className="template_preview_module_muti_desc">{item.subDesc}</div>
                                </div>
                            </Row>
                        );
                    })}
                </Row>
            </div>
        )
    }

    //魔方1-横排
    const templateMoFang1 = (props:any) =>{
        return (
            <div className="template_preview_module">
                {
                    props.showTitle&&<div className="template_preview_module_title"><img src={require('../../../../assret/images/decoration_title_line.png')} alt=""/>{props.title}</div>
                }
                {
                    props.showSubTitle&&<div className="template_preview_module_subTitle">{props.subTitle}</div>
                }
                <Row type="flex" align="middle" className="template_preview_module_list">
                    {
                        props.list.map((item:any,index:number)=>{
                            const fontSize = item.style.fontSize === 1 ? 16 : item.style.fontSize === 2 ? 14 : 12
                            const color = item.style.color ? item.style.color : '#333333'
                            const fontWeight = item.style.fontWeight ? 'bold' : 'normal' 
                            return item.show&&(
                                <div 
                                    className="template_preview_module_list_item" 
                                    key={index} 
                                    style={{width:(props.column?100/props.column:25)+'%'}}
                                >
                                    <img src={item.imgType === 0 ? item.defaultImgUrl : item.imgUrl} alt=""/>  
                                    {
                                        shopDecorationStore.shop_decoration_home_other.hoverEditMofangCurrent === index && <img src={eidt_icon} alt="" className="template_preview_module_list_item_edit"/> 
                                    }
                                    <div style={{fontSize:fontSize,color:color,fontWeight:fontWeight}}>{item.name}</div> 
                                </div>
                            )
                        })
                    }
                </Row>
            </div>  
        )
    }
    //魔方2-异性排布-嵌套
    const templateMoFang2Child = (props:any) => {
        let htmls:any = []
        let { list } = props   
        let listTemp:any = []
        list.map((item:any,index:number)=>{
            if(item.show){
                item.key = index
                listTemp.push(item)
            }
        })     
        listTemp.map((item:any,index:number)=>{
            const fontSize = item.style.fontSize === 1 ? 16 : item.style.fontSize === 2 ? 14 : 12
            const color = item.style.color ? item.style.color : '#333333'
            const fontWeight = item.style.fontWeight ? 'bold' : 'normal' 
    
            switch(index%3){
                case 0 : 
                    listTemp[index].show&&htmls.push(
                        <div className="template_preview_card_left">
                            {/* <div className="template_preview_card_tip">充值500送50</div> */}
                            <div className="template_preview_card_num">0.00</div>
                            <div className="template_preview_card_title" style={{fontSize:fontSize,color:color,fontWeight:fontWeight}}>{listTemp[index].name}</div>
                            <img src={listTemp[index].imgType === 0 ? listTemp[index].defaultImgUrl : listTemp[index].imgUrl} alt=""/>    
                            {
                                shopDecorationStore.shop_decoration_home_other.hoverEditMofangCurrent === item.key && <img src={eidt_icon} alt="" className="template_preview_card_left_edit"/>  
                            }                            
                        </div>
                    )
                    break
                case 1 : 
                    htmls.push(
                        <div className="template_preview_card_right">
                            {
                                listTemp[index].show&&<div className="template_preview_card_right_item">
                                    <div className="template_preview_card_num">10</div>
                                    <div className="template_preview_card_title" style={{fontSize:fontSize,color:color,fontWeight:fontWeight}}>{listTemp[index].name}</div>
                                    <img src={listTemp[index].imgType === 0 ? listTemp[index].defaultImgUrl : listTemp[index].imgUrl} alt=""/>   
                                    {
                                        shopDecorationStore.shop_decoration_home_other.hoverEditMofangCurrent === item.key && <img src={eidt_icon} alt="" className="template_preview_card_right_item_edit"/>     
                                    }   
                                </div>
                            }
                            {
                                listTemp[index+1]&&listTemp[index+1].show&&<div className="template_preview_card_right_item">
                                    <div className="template_preview_card_num">10<span>积分</span></div>
                                    <div 
                                        className="template_preview_card_title" 
                                        style={{
                                            fontSize:listTemp[index+1].style.fontSize === 1 ? 16 : listTemp[index+1].style.fontSize === 2 ? 14 : 12,
                                            color:listTemp[index+1].style.color ? listTemp[index+1].style.color : '#333333',
                                            fontWeight:listTemp[index+1].style.fontWeight ? 'bold' : 'normal',
                                        }}>
                                        {listTemp[index+1].name}
                                    </div>
                                    <img src={listTemp[index+1].imgType === 0 ? listTemp[index+1].defaultImgUrl : listTemp[index+1].imgUrl} alt=""/> 
                                    {
                                        shopDecorationStore.shop_decoration_home_other.hoverEditMofangCurrent === listTemp[index+1].key && <img src={eidt_icon} alt="" className="template_preview_card_right_item_edit"/>   
                                    }                                 
                                </div>
                            }
                        </div>
                    )
                    break
            }
        })
        return htmls
    } 

    //魔方2-异性排布
    const templateMoFang2 = (props:any) =>{
        return  (
            <Row type="flex" align="middle" justify="space-between" className="template_preview_card">
                {
                    templateMoFang2Child(props)
                }
            </Row>
        )

    }

    //富文本
    const templateRichText = (props:any) =>{
        return (
            <div className="template_preview_module">
                {
                    props.showTitle&&<div className="template_preview_module_title"><img src={require('../../../../assret/images/decoration_title_line.png')} alt=""/>{props.title}</div>
                }
                {
                    props.showSubTitle&&<div className="template_preview_module_subTitle">{props.subTitle}</div>
                }                    
                <div className="template_preview_editor" dangerouslySetInnerHTML={{__html:props.richText?props.richText:'富文本输入，最多可输入8000字'}}>
                </div>
            </div>   
        )
    }
    //权益卡
    const templateEquityCard = (props:any) =>{
        return(
            <div className="template_preview_banner">
                <img src={props.type === 0 ? props.defaultImgUrl:props.imgUrl} alt="邀请好友"/>     
            </div>
        )
    }
    //邀请
    const templateInvitation = (props:any) =>{
        return(
            <div className="template_preview_banner">
                <img src={props.type === 0 ? props.defaultImgUrl:props.imgUrl} alt="邀请好友"/>     
            </div>
        )
    }    

    return (<Observer render={() => 
        <div className="decoration_home_center_preview" ref={ref}>
            <div className="decoration_home_center_preview_wrap">
                <div className="decoration_home_center_preview_img"><img src={iPhone12Pro} alt=""/></div>
                <div className={`decoration_home_center_preview_seat ${template === 1 ? 'decoration_home_center_preview_seat_hidden' : ''}`}>
                    <Row type="flex" align="middle" justify="space-between" className="mb15 mt15">
                        <div className="decoration_home_center_preview_seat_date">9:16</div>
                        <img src={require('../../../../assret/images/decoration_wode_signal_block.png')} alt="" className="decoration_home_center_preview_seat_signal"/>
                    </Row>
                    <img src={require('../../../../assret/images/decoration_wode_icon.png')} alt="" className="decoration_home_center_preview_seat_icon"/>
                </div>
                <div className="template_preview" style={{borderRadius:template===1?50:90,paddingTop:template===1?0:85}}>
                    {
                        initHtmls()
                    }
                </div>
                <div className={`template_preview_footer`}>
                    <img className="template_preview_footer_img" src="https://ydn-siyu.cdn.shuachi.com/wechat_tabbar.pic.jpg" alt=""/>
                </div>
            </div>
        </div>
    }/>)
}))

export default DecorationHomeCenterPreview