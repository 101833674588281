import React from 'react'
import { Row, Button, Col, Table, Select, Input, DatePicker, Modal, message, Popover} from 'antd'
import { Link } from 'react-router-dom';
import {getRequest, postRequest} from "../../api/http";
import {apiUrl} from "../../api/api_url";
import copy from 'copy-to-clipboard';
import './turnplate.scss';
const { Option } = Select;
const { Search } = Input;
const { RangePicker } = DatePicker;
const QRCode = require('qrcode.react');

interface TurnplateProps {

}

interface TurnplateState {

}

class Turnplate extends React.Component<TurnplateProps, TurnplateState> {

    state = {
        count: 0,
        pageSize: 10,
        activityList: [
            {
                'name': '',
                'status': '',
                'view_count': '',
                'join_people_num': '',
                'created_at': '',
                'start_time': '',
                'end_time': '',
                'action': '',
            }
        ],
        visible: false,
        promoteParam: {
            title: '',
            promoteUrl: ''
        },
        mpList: [
            {
                'id': 3,
                'nick_name': "刷吃",//公众号名字
                'head_img': "http://wx.qlogo.cn/mmopen/ibGfE56ojvldKREgRjj6MHFVF6ic2OkFdRvmYDmUOwRE1k7V5EmBPzQhaT2XxcyT29ZicyE4X7oZO4Salw61ia86EgH1Gf3PH1Ga/0 ",//公众号头像
                'service_type_info':- {
                    'id': 1 //公众号类型 0-订阅号 1-由历史老帐号升级后的订阅号 2-服务号 小程序默认值为0
                },
                'verify_type_info':- {
                    'id': 0//公众号或小程序认证类型 (小程序：-1-未认证 0-微信认证) (公众号：-1-未认证 0-微信认证 1-新浪微博认证 2-腾讯微博认证 3-已资质认证通过但还未通过名称认证 4-已资质认证通过、还未通过名称认证，但通过了新浪微博认证 5-已资质认证通过、还未通过名称认证，但通过了腾讯微博认证)
                },
                'create_time': 1576748000
            }
        ],
        statusList: [
            {
                'id': 1,
                'status': '进行中'
            },
            {
                'id': 2,
                'status': '暂停中'
            },
            {
                'id': 3,
                'status': '已结束'
            },
            {
                'id': 4,
                'status': '即将开始'
            }
        ]
    }

    columns = [
        {
          title: '活动标题',
          dataIndex: 'name',
          key: 'name',
          className: 'list_center',
        },
        {
            title: '活动状态',
            dataIndex: 'status',
            key: 'status',
            className: 'list_center',
        },
        {
            title: '浏览量',
            dataIndex: 'look_num',
            key: 'look_num',
            className: 'list_center',
        },
        {
            title: '参与用户量',
            dataIndex: 'join_people_num',
            key: 'join_people_num',
            className: 'list_center',
        },
        {
            title: '活动创建时间',
            dataIndex: 'created_at',
            key: 'created_at',
            className: 'list_center',
        },
        {
            title: '活动开始时间',
            dataIndex: 'start_time',
            key: 'start_time',
            className: 'list_center',
        },
        {
            title: '活动结束时间',
            dataIndex: 'end_time',
            key: 'end_time',
            className: 'list_center',
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            className: 'list_center',
            render: (text:any,record:any,) => (
                <div className="btn_wrap">
                    <div className="list_btn" onClick={() => this.handlePromote(record)}>推广</div>
                    <Link className="list_btn" to={{pathname:'/sidebar/turnplateCreate', state:{id: record.id}}}>编辑</Link>
                    {
                        record.status === '即将开始' || record.status === '已结束'
                        ? <div className="list_btn">{record.status === '进行中'? '暂停' : '开启'}</div>
                        : record.status === '进行中' ?
                            <div className="list_btn" onClick={() => this.pauseList(record.id)}>暂停</div>
                            :<div className="list_btn" onClick={() => this.startList(record.id)}>开启</div>

                    }

                    <Popover placement="bottomRight" title={null} content={
                        <div>
                            <Link to={{pathname:'/sidebar/turnplatePerson', state:{id: record.id}}}>
                                <div className="pop_style pop_style_bb">参与用户</div>
                            </Link>
                            {/* <div className='pop_style pop_style_bb'>活动评价</div> */}
                            <div className='pop_style pop_style_bb' onClick={() => this.copyList(record)}>复制</div>
                            <div className='pop_style' onClick={() => this.deleteList(record)}>删除</div>
                        </div>
                    } trigger="click">
                        <div className="list_btn">更多</div>
                    </Popover>

                </div>
            ),
        },
    ];

    componentDidMount(): void {
        this._initList();
        this._initMpList();
        this._initCount();
    }

    // 列表
    _initList = async () => {
        const param = {
            // page:
        };
        const res:any = await getRequest(apiUrl.luckList,param);
        if(res && res.code === 1){
            this.setState({
                activityList: res.data
            })
        }
    }

    // 公众号列表
    _initMpList = async () => {
        const param = {
            pageSize: 100
        };
        const res:any = await getRequest(apiUrl.authAccountList,param);
        if(res && res.code === 1){
            this.setState({
                mpList: res.data.list
            })
        }
    }

    _initCount = async () => {
        const res:any = await getRequest(apiUrl.luckCount,);
        if(res && res.code === 1){
            this.setState({
                count: res.data.num
            })
        }
    }

    // 开启活动
    startList = async (id: number) => {
        const param = {
            id
        };
        const res:any = await postRequest(apiUrl.luckStart,param);
        if(res && res.code === 1){
            this._initList();
            this._initCount();
            message.success("活动已开启");
        }
    }

    // 暂停活动
    pauseList = async (id: number) => {
        const param = {
            id
        };
        const res:any = await postRequest(apiUrl.luckPause,param);
        if(res && res.code === 1){
            this._initList();
            this._initCount();
            message.success("活动已暂停");
        }
    }

    // 复制
    copyList = (record:any) => {
        let that = this;
        Modal.confirm({
            title: '复制活动',
            content: `确认复制活动 [${record.name}] 吗?`,
            okText: '确认',
            cancelText: '取消',
            onOk: () =>that.handleCopy(record.id)
        });
    }

    handleCopy = async (id:number) => {
        const param = {
            id
        };
        const res:any = await postRequest(apiUrl.luckCopy,param);
        if(res && res.code === 1){
            this._initList();
            this._initCount();
            message.success("活动已复制");
        }else{
            message.error(res.message);
        }
    }

    // 删除
    deleteList = (record:any) => {
        let that = this;
        Modal.confirm({
            title: '删除活动',
            content: `确认删除活动 [${record.name}] 吗?`,
            okText: '确认',
            cancelText: '取消',
            onOk: () =>that.handleDelete(record.id)
        });
    }

    handleDelete = async (id: number) => {
        const param = {
            id
        };
        const res:any = await postRequest(apiUrl.luckDel,param);
        if(res && res.code === 1){
            message.success("活动已删除");
            this._initList();
            this._initCount();
        }else{
            message.error(res.message);
        }
    }

    // 公众号筛选
    mpChange = async (id:number) => {
        const param = {
            page: 1,
            mp_id: id
        };
        const res:any = await getRequest(apiUrl.luckList,param);
        if(res && res.code === 1){
            this.setState({
                activityList: res.data
            })
        }
    }

    // 活动状态
    statusChange = async (id: any) => {
        const { pageSize } = this.state;
        const param = {
            current: 1,
            status: id,
            pageSize,
        };
        const res:any = await getRequest(apiUrl.luckList,param);
        if(res && res.code === 1){
            this.setState({
                activityList: res.data
            })
        }
    }

    nameChange = async (name: any) => {
        const param = {
            name
        };
        const res:any = await getRequest(apiUrl.luckList,param);
        if(res && res.code === 1){
            this.setState({
                activityList: res.data
            })
        }
    }

    timeChange = async (time: any, timeString: [string, string]) => {
        const param:any = {
        };

        if(timeString.length > 0 && timeString[0] !== ''){
            param.created_at_start = timeString[0];
            param.created_at_end = timeString[1];
        }
        const res:any = await getRequest(apiUrl.luckList,param);
        if(res && res.code === 1){
            this.setState({
                activityList: res.data
            })
        }
    }

    pageChange = async (page:any) =>{
        const param:any = {
            page
        };
        const res:any = await getRequest(apiUrl.luckList,param);
        if(res && res.code === 1){
            this.setState({
                activityList: res.data
            })
        }
    }

    // 推广
    handlePromote = (item: any) => {
        const { promoteParam } = this.state;
        promoteParam.title = item.name;
        promoteParam.promoteUrl = `${process.env.REACT_APP_BASEURL}/v1/jump/h5-go?jump_url=${encodeURI(`turntable/${item.id}?alias_id=${item.alias_id}`)}`;
        this.setState({
            promoteUrl: promoteParam
        },()=>{
            this.handlePromoteModal();
        })
    }

    // 控制推广弹窗 显示隐藏
    handlePromoteModal = () =>{
        let visible = this.state.visible;
        this.setState({visible : !visible})
    };

    render() {

        const { activityList, pageSize, visible, promoteParam, mpList, statusList, count } = this.state;

        return (
            <div className='l_content'>
                <div className="top_wrap">
                    <Row>
                        <Col span={24}>
                            <Link to={'/sidebar/turnplateCreate'}>
                                <Button type="primary" size="large" icon="plus">
                                    新建活动
                                </Button>
                            </Link>
                        </Col>
                    </Row>
                </div>

                <div className='search_wrap'>
                    <Row type="flex" align="middle" justify="start" >
                        <Col className="mr30">
                            <span className="col_title">公众号：</span>
                            <Select style={{ width: 120 }} onChange={this.mpChange}>
                                {
                                    mpList.map((item,idx) => {
                                        return <Option value={item.id} key={item.id}>{item.nick_name}</Option>
                                    })
                                }
                            </Select>
                        </Col>

                        <Col>
                            <div className="col_title">活动状态：</div>
                        </Col>
                        <Col className="mr30">
                            <Select defaultValue="全部" style={{ width: 120 }} onChange={this.statusChange}>
                                {
                                    statusList.map((item) => {
                                        return <Option value={item.id} key={item.id}>{item.status}</Option>
                                    })
                                }
                            </Select>
                        </Col>
                        <Col>
                            <div className="col_title">
                                活动名称：
                            </div>
                        </Col>
                        <Col className="mr30">
                            <Search placeholder="请输入活动名称" onSearch={this.nameChange}/>
                        </Col>
                        <Col>
                            <div className="col_title">创建时间：</div>
                        </Col>
                        <Col>
                            <RangePicker onChange={this.timeChange} placeholder={["开始时间","结束时间"]} format="YYYY-MM-DD HH:mm:ss"/>
                        </Col>
                    </Row>
                </div>

                <div>
                    <Table dataSource={activityList} size="middle" columns={this.columns} rowKey={"id"} locale={{emptyText: '暂无数据'}} pagination={{
                        showQuickJumper:true,
                        defaultCurrent: 1,
                        total: count,
                        pageSize: pageSize,
                        hideOnSinglePage: true,
                        onChange: this.pageChange
                    }}/>
                </div>

                {/* <Row type="flex" justify="center" align="middle" style={{height: '80vh', width: '100%', flexDirection: 'column'}}>
                    <Text>你还没创建过活动</Text>
                    <Link to="/sidebar/turnplateCreate">
                        <Button type="primary" style={{marginTop: 10}}>新建活动</Button>
                    </Link>
                </Row> */}

                <Modal
                    visible={visible}
                    footer={null}
                    title={null}
                    onCancel={this.handlePromoteModal}
                >
                    <div className="model_view">
                        <div className='model_title'>{promoteParam.title}</div>
                        <div className="model_input" style={{width: '90%'}}>
                            <Input value={promoteParam.promoteUrl} disabled={true} width="100%"/>
                            <Button type="primary" style={{marginLeft: '10px'}} onClick={() =>{
                                copy(promoteParam.promoteUrl);
                                message.success("链接已复制");
                            }}>复制</Button>
                        </div>
                        <QRCode value={promoteParam.promoteUrl} size={150} />
                        <div className='model_result_tips'>可用手机扫码查看排行榜</div>
                    </div>
                </Modal>
            </div>

        );
    }
}

export default Turnplate;



