import React from 'react';
import { Table, Row, Col, Button, DatePicker, Modal, message, Menu, Dropdown, Icon } from 'antd'
import { RangePickerValue } from "antd/lib/date-picker/interface";
import { getRequest } from '../../../../../api/http'
import { apiUrl } from '../../../../../api/api_url'
import './dining_record.scss'

import boy_wz from '../../../../../assret/images/boy_wz.png'
import boy_icon from '../../../../../assret/images/boy_icon.png'
import gril_icon from '../../../../../assret/images/gril_icon.png'

const { RangePicker } = DatePicker

interface DiningRecord {
    history: any,
}

class diningRecord extends React.Component<any> {

    state = {
        list: [

        ],
        meeting_day: '', //相识多少天
        eat_time: '',//就餐次数
        nickname: '',//用户名
        head_img_url: '',//用户头像
        is_popup: false,
        is_delpopup: false, //删除弹窗
        own_year: '',
        ymd: '', //同桌人员时间
        hms: '',
        all_user_list: [], //同桌人员列表
        array_date: ['2019', '2020'],
        array_index: 1,
        enlarge: false, //月份放大
        enlarge_active: 1, //放大之后的月份
        enlarge_list: [], //点击放大的月份list
        fan_id: 0, // 查看当前用户id
    }

    componentDidMount() {
        const fanId = this.props.send[0]['id'];
        //console.log(fanId)

        this.setState({
            fan_id: fanId,
            array_date: this.onDateYear(),
            own_year: new Date().getFullYear()
        }, () => {
            this.eatingRecord({date: this.state.own_year, fan_id: fanId})
        })
    }


    //年份生成
    onDateYear() {
        let date = new Date();
        let own_year = date.getFullYear();
        let difference = own_year - 2019;
        let add_year = 2019;
        let year_arr = [2019];
        for (let index = 0; index < difference; index++) {
            add_year += 1;
            year_arr.push(add_year)
        }
        return year_arr;
    }

    // 就餐记录
    async eatingRecord(obj :any) {
        const result:any = await getRequest(apiUrl.userEatingRecord, obj);
        if (result.code === 1) {
            let { meeting_day, eat_time, nickname, head_img_url, list } = result.data;

            console.log(list)

            this.setState({
                meeting_day,
                eat_time,
                nickname,
                head_img_url,
                list
            })
        }
    }

    //同桌人员
    async getEatingUser(date:any) {
        console.log(date)
        const fanId = this.props.send[0]['id'];
        let obj = {
            date: date,
            fan_id: fanId
        }
        const result:any = await getRequest(apiUrl.getEatingUser, obj);
        console.log(result)
        if (result.code === 1) {
            console.log(result.data);
            this.setState({
                ymd: result.data[0].ymd,
                all_user_list: result.data
            })
        }
    }

    //删除就餐记录
    async deleteEatRecord(date:any) {
        let obj = {
            date: date
        }
        const result:any = await getRequest(apiUrl.deleteEatRecord, obj);
        console.log(result)
        if (result.code === 1) {
            this.onHidnPopUp()
            this.eatingRecord('2020')
        }
    }

    //显示同桌人员
    onShowPopUp(date:any) {
        this.getEatingUser(date)
        this.setState({
            is_popup: true,
            enlarge: false
        })
    }

    //显示同桌人员弹窗
    onHidnDelPopUp() {
        this.setState({
            is_delpopup: true
        })
    }

    onHidnPopUp() {
        this.setState({
            is_popup: false
        })
    }

    //隐藏同桌人员
    onHideDelPopUp(date:any) {
        this.deleteEatRecord(date)
        this.setState({
            is_delpopup: false,
            is_popup: false
        })
    }

    onCanelDelPopUp() {
        this.setState({
            is_delpopup: false
        })
    }

    onPickerChange(e:any) {
        const { fan_id, array_date } = this.state;
        this.eatingRecord({'date': e,'fan_id': fan_id})
        this.setState({
            array_index: array_date.indexOf(e)
        })
    }

    onEnlargeList(month:any) {
        this.setState({
            enlarge_list: this.state.list[month - 1],
            enlarge_active: month,
            enlarge: true
        })
    }

    onCancelEnlarge() {
        this.setState({
            enlarge: false,
        })
    }

    formatData (num: number) {
        return num > 9 ? num : `0${num}`
    }

    render() {
        const { list, array_date, array_index, meeting_day, eat_time, nickname, head_img_url, is_popup, own_year, ymd, hms, all_user_list, is_delpopup, enlarge_list, enlarge_active, enlarge } = this.state;
        const { fanId } = this.props;

        const menu = (
            <Menu>
                {
                   array_date.map((item, index) => {
                        return (
                            <Menu.Item key={index + item} onClick={() => {this.onPickerChange(item)}}>
                                { item }
                            </Menu.Item>
                        )
                   })
                }
            </Menu>
        );

        return (
            <div className="myPage l_content" >
                <div className="date">
                    <div className="date-icon">
                        <div className="date-icon-left">
                            <div className="date-icon-left-img"></div>
                            就餐日
                    </div>
                    <div className="date-info">
                        <div className="date-info-top">就餐次数：</div>
                        <div className="date-info-bottom">{eat_time}次</div>
                    </div>
                    <div className="date-icon-right">
                        <Dropdown overlay={menu}>
                            <div>
                                {array_date[array_index]}<Icon type="down" />
                            </div>
                        </Dropdown>
                    </div>
                </div>
                    <div className="date-list">
                        {
                            list.map((item:any, index:any) => {
                                return (
                                    <div className="date-list-content" key={index}>

                                        <div className="date-list-tip">{index + 1}月</div>
                                        <div className="date-list-detail">
                                            {
                                                item.map((sub_item:any, sub_index:any) => {
                                                    return (
                                                        sub_item.select_day
                                                            ? <div className="date-list-detail-main-item-active" key={sub_item.day} onClick={() => this.onShowPopUp(`${own_year}-${this.formatData(Number(index+1))}-${this.formatData(Number(sub_item.day))}`)}>
                                                                {sub_item.day}
                                                            </div>
                                                            : <div className="date-list-detail-main-item" key={sub_item.day}>
                                                                {sub_item.day}
                                                            </div>

                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                {
                    is_popup
                        ? <div className="popup">
                            <div className="popup-main">
                                <div className="popup-date">
                                    <div className="popup-date-wz">{ymd}</div>
                                </div>
                                <div className="popup-longlist">
                                    {
                                        all_user_list.map((item:any, index:any) => {
                                            return (
                                                <div key={index}>
                                                    <div className="popup-personnel">
                                                        <div className="popup-personnel-left">同桌人员</div>
                                                        <div className="popup-personnel-right">下单时间：{item.hms}</div>
                                                    </div>
                                                    <div className="popup-info">
                                                        {
                                                            item.user_list.map((sub_item:any, sub_index:any) => {
                                                                return (
                                                                    <div className="popup-info-list" key={sub_index}>
                                                                        <div className="popup-info-list-left">
                                                                            <img src={sub_item.head_img_url} className="popup-info-list-left-img" />
                                                                            {
                                                                                sub_item.sex == 0
                                                                                ? <img src={boy_wz} className="popup-info-list-left-boyimg" />
                                                                                : <img src={sub_item.sex == 1 ? boy_icon : gril_icon} className="popup-info-list-left-boyimg" />
                                                                            }
                                                                        </div>
                                                                        <div className="popup-info-list-right">
                                                                            <div>
                                                                                {
                                                                                    sub_item.pay_user
                                                                                    ? sub_item.nickname + ' | 买单'
                                                                                    : sub_item.nickname
                                                                                }
                                                                            </div>
                                                                            <div>
                                                                                {sub_item.phone_number}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className="popup-btn" onClick={() => this.onHidnPopUp()}>我知道了</div>
                            </div>
                        </div>
                        : ''
                }
                {/* {
                    enlarge
                    ? <div className="enlarge" onClick={() => { this.onCancelEnlarge() }}>
                        <div className="enlarge-main">
                        <div className="enlarge-main-title">{enlarge_active}月</div>
                            <div className="enlarge-main-list">
                            {
                                enlarge_list.map((item:any, index:any) => {
                                    return (
                                        item.select_day
                                        ? <div className="enlarge-main-list-item active" key={index} onClick={() => this.onShowPopUp(`${own_year}-${Number(enlarge_active) > 9 ? Number(enlarge_active) : '0' + Number(enlarge_active)}-${Number(item.day) > 9 ? Number(item.day) : '0' + Number(item.day)}`)}>{item.day}</div>
                                        : <div className="enlarge-main-list-item" key={index}>{item.day}</div>
                                    )
                                })
                            }
                            </div>
                        </div>
                    </div>
                    : ''
                } */}
            </div>
        )
    }
}

export default diningRecord;