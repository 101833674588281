import React, { useEffect, useState } from 'react'
import { Table, Badge } from 'antd'
import { getRequest } from '../../../../api/http'
import { apiUrl } from '../../../../api/api_url'

const SmsList = ()=>{
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [paramsObj, setParamsObj] = useState<any>({
        page: 1,
        pageSize: 10,
    });
    const statusList:any = { 1:'正常', 2:'发送失败'}
    const badgeList:any = { 1:'success', 2:'error' }

    useEffect(()=>{
        getSmsList({...paramsObj})
    },[])

    const getSmsList = async (data:any = {}) => {
        let param = {
            ...data
        }
        if(param.sortType)param.sortType=param.sortType=='ascend'?'asc':'desc'
        setParamsObj({...param})
        const res:any = await getRequest(apiUrl.getSmsSendList, param);
        if(res && res['code'] === 1) {
            setData(res.data.data);
            setTotal(res.data.total)
        }
    }

    const handleTableChange = (pagination:any, filters:any, sorter:any) => {
        getSmsList({
            ...paramsObj,
            page:pagination.current,
            pageSize:pagination.pageSize,
            sortField:sorter.field,
            sortType:sorter.order
        })
    }

    const creatTable = ()=>{

        const columns:any = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: '活动ID',
                dataIndex: 'operation_id',
                key: 'operation_id',
            },            {
                title: '活动名称',
                dataIndex: 'name',
                key: 'name',
            },            {
                title: '短信内容',
                dataIndex: 'content',
                key: 'content',
                width:400,
            },
            {
                title: '发送时间',
                dataIndex: 'created_at',
                key: 'created_at',
            },
            {
                title: '发送状态',
                dataIndex: 'status',
                key: 'status',
                render:( text:number, record:any, index:number )=>
                    <>
                        <Badge status={badgeList[text]} />
                        <span>{statusList[text]}</span>
                    </>
            },
            {
                title: '发送短信人数',
                dataIndex: 'people_num',
                key: 'people_num',
                sorter: true,
            },
            {
                title: '发送短信条数',
                dataIndex: 'send_num',
                key: 'send_num',
                sorter: true,
            },
            {
                title: '失败条数',
                dataIndex: 'send_failure_num',
                key: 'send_failure_num',
                sorter: true,
            },
        ];

        return (
            <Table
                dataSource={data}
                columns={columns}
                onChange={handleTableChange}
                rowKey={'id'}
                pagination={{
                    defaultCurrent: 1,
                    current:paramsObj.page,
                    pageSize: paramsObj.pageSize,
                    total: total,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    showTotal: (total: number) => `共${total}条`,
                }}
            />
        );
    }

    return(
        <div className='l_content'>
            <div className="fz16 color333 fweight mb20">群发记录</div>
            {
                creatTable()
            }
        </div>
    )
}

export default SmsList