import React, { useState, useLayoutEffect, useEffect } from "react"

import { Drawer, Form, Input, Select, Radio, Button, Spin } from "antd";
import useForm from 'rc-form-hooks'
import { postRequest } from "./../../api/http";
import { apiUrl } from "./../../api/api_url";
import {searchTemplateData,searchUserRequestData,} from './../message/components/dataType'
import {messageUserSearch} from './../message/components/request'
import debounce from "lodash/debounce";
const { Option } = Select;
export interface partnerAdd {
    visible?:boolean,
    setVisible:Function,
    data?:any,
}


function RechargeManageAddOrEdit (props:partnerAdd) {
    const [data,setData] = useState(props.data)

    const [userFetching,setUserFetching] = useState(false)
    const [userDate,setUserData] = useState<searchTemplateData[]>([])
    const [userValue,setUserValue] = useState<number[]>()

    const userHandleChange = (value:any) => {
        let reg=/^\d{1,}$/
        let pattern=new RegExp(reg);
        console.log('value', value);
        setUserValue(value)
        if(value.length > 0){
            let fans_ids: any[] = [];
            value.map((item: any) => {
                fans_ids.push(item.key);
            })
            console.log(fans_ids)
            setFieldsValue({fans_ids:fans_ids.join(',')})
        }

        setUserData([])
        setUserFetching(false)
    };

    const userSearch = (value:any) =>{
        setUserData([])
        setUserFetching(true)
        let reg=/^\d{1,}$/
        let pattern=new RegExp(reg);
        let param:searchUserRequestData = {}
        if(pattern.test(value)){
            param['phone_number'] = value
        }else{
            param['nickname'] = value
        }
        messageUserSearch(param).then((res) => {

            let data:Array<searchTemplateData> = [];
            if(res.code === 1){
                res.data.map((item:any) => {
                    data.push({id:item.id,text:item.nickname})
                })
            }else{
                //data.push({id:'0',text:'全部用户'})
            }
            setUserData(data)
            setUserFetching(false)
        }).catch((e)=>{
            let data:Array<searchTemplateData> = [];
            //data.push({id:'0',text:'全部用户'})
            setUserData(data)
            setUserFetching(false)
        })
    }

    useLayoutEffect(()=> {
        setData(props.data)
    })

    useEffect(()=> {
        resetFields()
    },[data])

    const formItemLayout = {
        labelCol: {
          xs: { span: 16 },
          sm: { span: 6 },
        },
        wrapperCol: {
          xs: { span: 6 },
          sm: { span: 16 },
        },
    };
    const handleSubmit = (e:any)=>{
        e.preventDefault();
        validateFields().then((e)=>{
            console.log(e)
            postRequest(`${apiUrl.partnerCreate}`,e).then((res:any) => {
                console.log(res)
                if(res.code === 1){
                    props.setVisible(false,true)
                    setUserValue([])
                }
            });
        })
        .catch(e => console.error(e.message));
    }

    const { getFieldDecorator, validateFields, resetFields,setFieldsValue,getFieldValue } = useForm<any>();
    return (
        <Drawer
            placement="right"
            closable={false}
            onClose={()=>{
                resetFields();
                props.setVisible(false);

            }}
            visible={props.visible}
            width={600}>
            <Form {...formItemLayout} className="login-form" onSubmit={handleSubmit}>

                <Form.Item label="用户昵称或手机号码">
                    <Select
                        showSearch
                        labelInValue
                        value={userValue}
                        mode="multiple"
                        placeholder="输入用户昵称或手机号码"
                        notFoundContent={userFetching ? <Spin size="small" /> : null}
                        filterOption={false}
                        onSearch={debounce(userSearch,500)}
                        onChange={userHandleChange}
                        style={{ width: '100%' }}
                      >
                          {
                              userDate ?
                              userDate.map(d => (
                                <Option key={d.id} value={d.id}>{d.text}</Option>
                              ))
                              :
                              <Option key={0}>全部用户</Option>
                          }
                      </Select>
                </Form.Item>

                <div className='none'>
                    <Form.Item label="用户昵称或手机号码">
                        {
                            getFieldDecorator('fans_ids', {
                                initialValue: data !== undefined ? data.fans_ids : '',
                                rules: [{ required: true, message: '填写跳转url!' }]
                            })(<Input placeholder="填写跳转url"  />)
                        }
                    </Form.Item>
                </div>

                {/* <Form.Item label="是否启用">
                    {getFieldDecorator('is_effect', {
                        initialValue: data && data.is_effect ? data.is_effect : 1,
                        rules: [{ required: true, message: '设置是否启用!' }]
                    })(<Radio.Group >
                        <Radio value={1}>启用</Radio>
                        <Radio value={2}>不启用</Radio>
                        </Radio.Group>)}
                </Form.Item> */}


                <Button type="primary" htmlType="submit"  className="login-form-button" >
                    提交
                </Button>
            </Form>
        </Drawer>
    )
}

export default RechargeManageAddOrEdit